import React from "react";
import { Link } from "react-router-dom";

import "./AuthFooter.scss";

const AuthFooter = ({ title, path, pathTitle }) => {
  return (
    <div className="ibank-login__register">
      <span>{title}</span>
      <Link to={path}>{pathTitle}</Link>
    </div>
  );
};

export default AuthFooter;
