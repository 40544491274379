import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import accountIcon from './../../img/account-icon.svg';
import accountExtractIcon from './../../img/account-extract-icon.png';
import phoneCompanyIcon from './../../img/phone-company-icon.svg';
import { request, requestCardStorage } from './../../../../config';
import masterCardIcon from './../../img/card-icon.svg';
import visaCardIcon from './../../img/visa-icon.svg'
import DropdownWithChevron from './../../../../copms/DropdownWithChevron/DropdownWithChevron';
import { translate } from 'react-i18next';
import $ from 'jquery';
import Loading from './../../Loading/Loading';
import { cardTypes, maskCardNumber, setPaymentTypeIcon } from '../../../../helpers/cards';
import CashbackStatementModal from '../../../../copms/Modal/CashbackStatementModal';

class CardExtract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iban: null,
            statements: [],
            card: null,
            isLoading: false,
            currentPage: 1,
            lastPage: null
        }
    }

    showStatements = () => {
        this.setState({ isLoading: true })
        const { location } = this.props;
        this.setState({ card: location.state?.card })

        let getCardStatementForCustomerRequestBody = {
            token: localStorage.getItem('token'),
            cardId: location.state?.card.cardId,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            pageNumber: this.state.currentPage
        }

        fetch(request("bonus/getCashBackOperationList", getCardStatementForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardStatementForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardStatementForCustomerResponse = JSON.parse(getCardStatementForCustomerResponseJSON);
                if (getCardStatementForCustomerResponse.status.statusCode !== 1 && getCardStatementForCustomerResponse.status.statusCode !== 165) {
                    if (getCardStatementForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardStatementForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false })

                    }
                }
                else {
                    if (getCardStatementForCustomerResponse.status.statusCode === 165) {
                        getCardStatementForCustomerResponse.cardStamentList = [];
                    }
                    this.setState({ lastPage: getCardStatementForCustomerResponse.pageCount ? getCardStatementForCustomerResponse.pageCount : 0 })
                    this.setState({ statements: getCardStatementForCustomerResponse.cashBackOperations });
                    this.setState({ isLoading: false })

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    componentWillMount() {
        this.showStatements();
    }

    selectPage(e, page, cardType) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => this.showStatements())
    }

    render() {
        let { statements, card, isLoading, statementDetails, statement } = this.state;
        let { t } = this.props;

        return (
            <>
                <CashbackStatementModal open={statementDetails} close={() => this.setState({ statementDetails: false })} data={statement} />

                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('extract')}</h2>
                    </div>
                    <Link to="/dashboard/finance/cards" className="dashboard-header-route-link">{t('ALL CARDS')}</Link>
                </div>
                <div className="dashboard-container-main-section dashboard-container-main-section--column dashboard-container-main-section--white">
                    {
                        isLoading ?
                            <Loading />
                            :
                            <div className="dashboard-finance-section-account-extract-section">
                                <div className="dashboard-finance-section-account-extract-section-main-section">
                                    <div className="dashboard-finance-section-account-extract-section-main-section-header">
                                        <div className="dashboard-finance-section-account-extract-section-main-section-header--left-side">
                                            {
                                                card !== null && card.cardId !== undefined ?
                                                    <div className="dashboard-finance-section-card-section-details-section-header-left-side">
                                                        <img src={setPaymentTypeIcon(card.cardPaymentType, masterCardIcon, visaCardIcon)} className="dashboard-finance-section-card-section-details-section-header-image" alt="cardIcon" />
                                                        <h3 className="dashboard-finance-section-card-section-details-section-header-card-number">{card.cardType === cardTypes.CARD_STORAGE ? maskCardNumber(card.cardNumber) : card.organizedCardNumber}</h3>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    {
                                        statements.length === 0 ?
                                            <div className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section">
                                                <img src={accountExtractIcon} className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section-image" />
                                                <div className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section-paragraph">
                                                    <p className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section-text">{t('Search no matching operation found')}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="dashboard-finance-section-account-extract-section-main-section-visible-content-section mt-3">
                                                <ul className='dashboard-finance-section-card-section-operation-section-content-type'>
                                                    <li><span style={{ background: '#62B865' }}></span>{t('Confirmed')}</li>
                                                    <li><span style={{ background: '#FFAF37' }}></span>{t('Waiting')}</li>
                                                    <li><span style={{ background: '#FF5A5A' }}></span>{t('Abolished')}</li>
                                                </ul>
                                                <ul className="dashboard-finance-section-account-extract-section-list">
                                                    {
                                                        statements.map((statement, index) => (
                                                            <li className="dashboard-last-operations-section-item" key={index} onClick={() => this.setState({  statement: statement }, () => {
                                                                this.setState({statementDetails: true})
                                                              })} style={{cursor: 'pointer'}}>
                                                                <div className="dashboard-last-operations-section-item-left-side">
                                                                    {/* <div className="dashboard-last-operations-section-item-icon-container"> */}
                                                                    <div className='dashboard-finance-section-card-section-operation-section-content-type'>
                                                                        <span style={{ background: statement?.color }}></span>
                                                                    </div>
                                                                    {/* </div> */}
                                                                    <div className="dashboard-last-operations-section-item-content">
                                                                        <div className="dashboard-last-operations-section-item-company">{statement?.categoryName}</div>
                                                                        <div className="dashboard-last-operations-section-item-description">
                                                                            {new Date(statement?.paymentDate).getDate().toString().padStart(2, '0')}.{(new Date(statement?.paymentDate).getMonth() + 1).toString().padStart(2, '0')}.{new Date(statement?.paymentDate).getFullYear()}, {new Date(statement?.paymentDate).getHours().toString().padStart(2, '0')}:{new Date(statement?.paymentDate).getMinutes().toString().padStart(2, '0')}, {statement?.cardNumber}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="dashboard-last-operations-section-item-rigth-side">
                                                                    {statement?.amount && <div className="dashboard-last-operations-section-item-amount">
                                                                        <span className="dashboard-last-operations-section-item-amount-whole-part">-{Math.floor(statement?.amount)}</span>
                                                                        <span className="dashboard-last-operations-section-item-amount-fractional-part">{(statement?.amount - Math.floor(statement?.amount)).toFixed(2).slice(2)}</span>
                                                                        <span className="dashboard-last-operations-section-item-amount-currency">{statement?.currency}</span>
                                                                    </div>}

                                                                    <p className="dashboard-last-operations-section-item-category d-flex align-items-center">
                                                                        {statement?.cashBackAmount && <div className="dashboard-last-operations-section-item-amount mr-1" style={{ color: statement?.color }}>
                                                                            <span className="dashboard-last-operations-section-item-amount-whole-part">+{Math.floor(statement?.cashBackAmount)}</span>
                                                                            <span className="dashboard-last-operations-section-item-amount-fractional-part">{(statement?.cashBackAmount - Math.floor(statement?.cashBackAmount)).toFixed(2).slice(2)}</span>
                                                                            <span className="dashboard-last-operations-section-item-amount-currency">{statement?.currency}</span>
                                                                        </div>}

                                                                        {statement?.checkStar ? (
                                                                            <div className="dashboard-last-operations-section-item-icon-container m-0" style={{ height: '30px', fontSize: '14px' }}>
                                                                                {statement?.cashBackInterest}
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                                                    <path d="M8.62308 3.53602L11.8702 4.02778C12.1426 4.07462 12.3697 4.26196 12.4605 4.54297C12.5514 4.80056 12.4832 5.10498 12.2789 5.29232L9.91737 7.70431L10.485 11.1232C10.5305 11.4043 10.4169 11.6853 10.1898 11.8492C9.96278 12.0365 9.66759 12.0365 9.41782 11.9194L6.51135 10.3036L3.58218 11.9194C3.35511 12.0365 3.03722 12.0365 2.83286 11.8492C2.60579 11.6853 2.49225 11.4043 2.53767 11.1232L3.08263 7.70431L0.721127 5.29232C0.516766 5.10498 0.448645 4.80056 0.539472 4.54297C0.630299 4.26196 0.857367 4.07462 1.12985 4.02778L4.39962 3.53602L5.85286 0.421512C5.96639 0.163921 6.21617 0 6.51135 0C6.78383 0 7.03361 0.163921 7.14714 0.421512L8.62308 3.53602Z" fill="#027AD3" />
                                                                                </svg>
                                                                            </div>
                                                                        )
                                                                            :
                                                                            <div className="dashboard-last-operations-section-item-icon-container m-0" style={{ height: '30px' }}>
                                                                                {statement?.cashBackInterest}%
                                                                            </div>
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                    <ul className="dashboard-pagination">
                                                        {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1, card.cardType)}>{t('previous-page')}</a></li>}
                                                        {
                                                            Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                                if (this.state.lastPage > 3) {
                                                                    if (this.state.currentPage - 1 == index + 1) {
                                                                        return (
                                                                            <>
                                                                                {this.state.currentPage > 2 && <li ><a href="#" onClick={(e) => this.selectPage(e, 1, card.cardType)}>1</a></li>}

                                                                                {this.state.currentPage > 3 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                                <li ><a href="#" onClick={(e) => this.selectPage(e, index + 1, card.cardType)}>{index + 1}</a></li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    if (this.state.currentPage == index + 1) {
                                                                        return (
                                                                            <>
                                                                                <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    if (this.state.currentPage + 1 == index + 1) {
                                                                        return (
                                                                            <>
                                                                                <li ><a href="#" onClick={(e) => this.selectPage(e, index + 1, card.cardType)}>{index + 1}</a></li>
                                                                                {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                                {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage, card.cardType)}>{this.state.lastPage}</a></li>}
                                                                            </>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1, card.cardType)}>{index + 1}</a></li>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1, card.cardType)}>{t('next-page')}</a></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                </div>
            </>
        )
    }
}

export default translate("translation")(CardExtract);