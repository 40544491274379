import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { YMaps, Map, Placemark, GeolocationControl, FullscreenControl, ZoomControl, RulerControl, Clusterer, } from 'react-yandex-maps';
import { Link, NavLink, Switch, Route, Redirect, } from 'react-router-dom';
import { request } from '../../../../config';
import bankIcon from './../../img/bank_icon.png';
import currentIcon from './../../img/current-icon.jpg';
import markerBoldIcon from '../../img/marker_bold_icon.svg';
import searchIcon from '../../img/search-icon.svg';
import atmIcon from './../../img/atm_icon.png';
import BranchList from './BranchList';
import OutsideClickHandler from 'react-outside-click-handler';

class Branch extends Component {
    state = {
        branchs: [],
        atms: [],
        searchArr: [],
        searchType: 0,
        locatinAvailable: false,
        location: {
            lat: 40.105482,
            long: 47.869358,
            zoom: 7
        },
        selectElement: null,
        pathname: this.props.location.pathname,
    }
    componentDidMount() {
        this.showBranchs();
        this.currentLocation()
    }

    currentLocation() {
        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition((position) => {

                this.setState({ location: { lat: position.coords.latitude, long: position.coords.longitude, zoom: 10 }, locatinAvailable: true })
            }, () => {

            });
        } else {

        }
    }

    filterLangMap(val) {
        switch (val) {
            case "az":
                return "az_AZ";
            case "ru":
                return "ru_RU";
            case "en":
                return "en_US";
            default:
                return "az_AZ";
        }
    }

    async showBranchs() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBranchMapListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        await fetch(request("util/getBranchMapList/" + localStorage.getItem('i18nextLng').toUpperCase(), getBranchMapListRequestBody))
            .then(res => res.text())
            .then((getBranchMapListrResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBranchMapListrResponse = JSON.parse(getBranchMapListrResponseJSON);
                if (getBranchMapListrResponse.status.statusCode !== 1) {
                    if (getBranchMapListrResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBranchMapListrResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ branchs: getBranchMapListrResponse.respBranchMapList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

        await fetch(request("util/getAtmMapList/" + localStorage.getItem('i18nextLng').toUpperCase(), getBranchMapListRequestBody))
            .then(res => res.text())
            .then((getBranchMapListrResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBranchMapListrResponse = JSON.parse(getBranchMapListrResponseJSON);
                if (getBranchMapListrResponse.status.statusCode !== 1) {
                    if (getBranchMapListrResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBranchMapListrResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ atms: getBranchMapListrResponse.respBranchMapList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        this.props.updateAppState('isLoadingTransparentBackground', false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ pathname: this.props.location.pathname })
        }
    }

    searchOperation(text) {
        var arr = this.state.searchType === 0 ? this.state.branchs : this.state.atms;
        this.setState({ searchArr: arr.filter(el => (el.address.toLowerCase()).includes(text.toLowerCase()) || (el.branchName ? el.branchName.toLowerCase() : el.name.toLowerCase()).includes(text.toLowerCase())) });
    }

    render() {
        let { t, history, i18n, updateAppState, alertState, type } = this.props;
        let { branchs, atms, locatinAvailable, pathname, location, id, searchType, searchArr, selectElement } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{branchs && branchs.length > 0 && branchs.find(el => el.branchId == this.props.match.params.id) && branchs.find(el => el.branchId == this.props.match.params.id).branchName}</h2>
                    </div>
                </div>
                <div className="dashboard-container-main-section">
                    <div className="dashboard-finance-section-cards-section">
                        <div className="dashboard-finance-section-cards-section-table-section" style={{ padding: '20px' }}>
                            <div className="dashboard-service-network-branch-head">
                                <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                    <div className="dashboard-service-network-branch-head-search">
                                        <input placeholder={t('Search') + '...'} onChange={e => this.searchOperation(e.target.value)} />
                                        <img src={searchIcon} />
                                    </div>
                                    <OutsideClickHandler onOutsideClick={() => this.setState({ searchArr: [] })}>
                                        <div></div>
                                    </OutsideClickHandler>
                                    {
                                        searchArr && searchArr.length > 0 &&
                                        <ul className={"dashboard-payment-container-data-section-content-container-form-select-box-list"}>

                                            {
                                                searchArr.map((item, index) => {
                                                    return (
                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" >
                                                            <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={() => this.setState({ selectElement: item }, () => this.mapRef.setCenter([item.latitude, item.longitude]))}>{item.branchName ? item.branchName + ' / ' + item.address : item.address}</button>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                                <div className="dashboard-service-network-branch-head-right">
                                    <button onClick={() => this.setState({ searchType: 0 })} className={`dashboard-service-network-branch-head-button${searchType === 0 ? '-active' : ''}`}>{t('branch')}</button>
                                    <button onClick={() => this.setState({ searchType: 1 })} className={`dashboard-service-network-branch-head-button${searchType === 1 ? '-active' : ''}`}>{t('atm')}</button>
                                </div>
                            </div>
                            {
                                selectElement ?
                                    <Fragment>
                                        {
                                            selectElement.branchName &&
                                            <Fragment>
                                                <div className="dashboard-service-network-branch-desc">
                                                    <p className="dashboard-service-network-branch-desc-element">
                                                        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.45312 14.4766C5.69922 14.8594 6.27344 14.8594 6.51953 14.4766C10.5117 8.73438 11.25 8.13281 11.25 6C11.25 3.10156 8.89844 0.75 6 0.75C3.07422 0.75 0.75 3.10156 0.75 6C0.75 8.13281 1.46094 8.73438 5.45312 14.4766ZM6 8.1875C4.76953 8.1875 3.8125 7.23047 3.8125 6C3.8125 4.79688 4.76953 3.8125 6 3.8125C7.20312 3.8125 8.1875 4.79688 8.1875 6C8.1875 7.23047 7.20312 8.1875 6 8.1875Z" fill="#0179D2" />
                                                        </svg>
                                                        <span>{selectElement.address}</span>
                                                    </p>
                                                    <p className="dashboard-service-network-branch-desc-element">
                                                        <a href={`mailto:${selectElement.email}`}>
                                                            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.7266 3.97266C13.0977 4.46484 12.3047 5.06641 9.51562 7.08984C8.96875 7.5 7.95703 8.40234 7 8.40234C6.01562 8.40234 5.03125 7.5 4.45703 7.08984C1.66797 5.06641 0.875 4.46484 0.246094 3.97266C0.136719 3.89062 0 3.97266 0 4.10938V9.6875C0 10.4258 0.574219 11 1.3125 11H12.6875C13.3984 11 14 10.4258 14 9.6875V4.10938C14 3.97266 13.8359 3.89062 13.7266 3.97266ZM7 7.5C7.62891 7.52734 8.53125 6.70703 8.99609 6.37891C12.6328 3.75391 12.9062 3.50781 13.7266 2.85156C13.8906 2.74219 14 2.55078 14 2.33203V1.8125C14 1.10156 13.3984 0.5 12.6875 0.5H1.3125C0.574219 0.5 0 1.10156 0 1.8125V2.33203C0 2.55078 0.0820312 2.74219 0.246094 2.85156C1.06641 3.50781 1.33984 3.75391 4.97656 6.37891C5.44141 6.70703 6.34375 7.52734 7 7.5Z" fill="#0179D2" />
                                                            </svg>
                                                            <span>{selectElement.email}</span>
                                                        </a>
                                                    </p>
                                                </div>
                                                <div className="dashboard-service-network-branch-desc" style={{ marginBottom: '25px' }}>
                                                    <p className="dashboard-service-network-branch-desc-element">
                                                        <a href={`tel:${selectElement.phoneNumber}`}>
                                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.519531 1.43359L3.36328 0.777344C3.66406 0.695312 3.99219 0.859375 4.12891 1.16016L5.44141 4.22266C5.55078 4.49609 5.46875 4.79688 5.25 4.98828L3.58203 6.35547C4.56641 8.43359 6.28906 10.1836 8.42188 11.1953L9.78906 9.52734C9.98047 9.30859 10.2812 9.22656 10.5547 9.33594L13.6172 10.6484C13.918 10.7852 14.0547 11.1133 14 11.4141L13.3438 14.2578C13.2617 14.5586 13.0156 14.75 12.6875 14.75C5.6875 14.75 0 9.08984 0 2.0625C0 1.76172 0.21875 1.51562 0.519531 1.43359Z" fill="#0179D2" />
                                                            </svg>
                                                            <span>{selectElement.phoneNumber}</span>
                                                        </a>
                                                    </p>
                                                    <p className="dashboard-service-network-branch-desc-element">
                                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7 7.75C8.91406 7.75 10.5 6.19141 10.5 4.25C10.5 2.33594 8.91406 0.75 7 0.75C5.05859 0.75 3.5 2.33594 3.5 4.25C3.5 6.19141 5.05859 7.75 7 7.75ZM9.43359 8.625H8.96875C8.36719 8.92578 7.71094 9.0625 7 9.0625C6.28906 9.0625 5.60547 8.92578 5.00391 8.625H4.53906C2.51562 8.625 0.875 10.293 0.875 12.3164V13.4375C0.875 14.1758 1.44922 14.75 2.1875 14.75H11.8125C12.5234 14.75 13.125 14.1758 13.125 13.4375V12.3164C13.125 10.293 11.457 8.625 9.43359 8.625Z" fill="#0179D2" />
                                                        </svg>
                                                        <span>{selectElement.director} / {selectElement.directorPosition}</span>
                                                    </p>
                                                </div>
                                            </Fragment>
                                        }
                                        {
                                            !selectElement.branchName &&
                                            <div className="dashboard-service-network-branch-desc" style={{ marginBottom: '30px' }}>
                                                <p className="dashboard-service-network-branch-desc-element">
                                                    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.45312 14.4766C5.69922 14.8594 6.27344 14.8594 6.51953 14.4766C10.5117 8.73438 11.25 8.13281 11.25 6C11.25 3.10156 8.89844 0.75 6 0.75C3.07422 0.75 0.75 3.10156 0.75 6C0.75 8.13281 1.46094 8.73438 5.45312 14.4766ZM6 8.1875C4.76953 8.1875 3.8125 7.23047 3.8125 6C3.8125 4.79688 4.76953 3.8125 6 3.8125C7.20312 3.8125 8.1875 4.79688 8.1875 6C8.1875 7.23047 7.20312 8.1875 6 8.1875Z" fill="#0179D2" />
                                                    </svg>
                                                    <span>{selectElement.address}</span>
                                                </p>

                                            </div>
                                        }

                                        <YMaps key={this.filterLangMap(i18n.language)} query={{ lang: this.filterLangMap(i18n.language) }}>
                                            <Map width={window.screen.width > 768 ? '100%' : window.screen.width -64} style={{ height: window.screen.width > 768 ? '62vw' : window.screen.width }} modules={["geolocation", "geocode"]} instanceRef={ref => this.mapRef = ref}  onLoad={e => {} } defaultState={selectElement ? { center: [selectElement.latitude, selectElement.longitude], zoom: 10 } : {}} >


                                                {
                                                    selectElement &&
                                                    <Placemark
                                                        properties={{
                                                            hintContent: selectElement.branchName,
                                                            balloonContent: selectElement.branchName,
                                                            balloonPanelMaxMapArea: 1,
                                                            // openEmptyBalloon: true
                                                        }}
                                                        geometry={[selectElement.latitude, selectElement.longitude]}
                                                        options={{
                                                            iconLayout: 'default#image',

                                                            iconImageHref: markerBoldIcon,
                                                            iconImageSize: [60, 60],

                                                        }}

                                                        modules={["geoObject.addon.balloon"]}
                                                    />
                                                }
                                                {
                                                    locatinAvailable === true ?
                                                        <Placemark
                                                            properties={{

                                                                // openEmptyBalloon: true
                                                            }}
                                                            geometry={[this.state.location.lat, this.state.location.long]}
                                                            options={{
                                                                iconLayout: 'default#image',

                                                                iconImageHref: currentIcon,
                                                                iconImageSize: [35, 35],
                                                                zIndex: 1000
                                                            }}

                                                            modules={["geoObject.addon.balloon"]}
                                                        />
                                                        : null
                                                }
                                                <GeolocationControl options={{ float: 'left' }} onClick={e => this.currentLocation()} />
                                                <FullscreenControl />
                                                <RulerControl options={{ float: 'right' }} />
                                                <ZoomControl options={{ float: 'right' }} />
                                            </Map>
                                        </YMaps>
                                    </Fragment>
                                    :
                                    branchs && branchs.length && branchs.find(el => el.branchId == this.props.match.params.id) &&
                                    <Fragment>
                                        <div className="dashboard-service-network-branch-desc">
                                            <p className="dashboard-service-network-branch-desc-element">
                                                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.45312 14.4766C5.69922 14.8594 6.27344 14.8594 6.51953 14.4766C10.5117 8.73438 11.25 8.13281 11.25 6C11.25 3.10156 8.89844 0.75 6 0.75C3.07422 0.75 0.75 3.10156 0.75 6C0.75 8.13281 1.46094 8.73438 5.45312 14.4766ZM6 8.1875C4.76953 8.1875 3.8125 7.23047 3.8125 6C3.8125 4.79688 4.76953 3.8125 6 3.8125C7.20312 3.8125 8.1875 4.79688 8.1875 6C8.1875 7.23047 7.20312 8.1875 6 8.1875Z" fill="#0179D2" />
                                                </svg>
                                                <span>{branchs.find(el => el.branchId == this.props.match.params.id).address}</span>
                                            </p>
                                            <p className="dashboard-service-network-branch-desc-element">
                                                <a href={`mailto:${branchs.find(el => el.branchId == this.props.match.params.id).email}`}>
                                                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.7266 3.97266C13.0977 4.46484 12.3047 5.06641 9.51562 7.08984C8.96875 7.5 7.95703 8.40234 7 8.40234C6.01562 8.40234 5.03125 7.5 4.45703 7.08984C1.66797 5.06641 0.875 4.46484 0.246094 3.97266C0.136719 3.89062 0 3.97266 0 4.10938V9.6875C0 10.4258 0.574219 11 1.3125 11H12.6875C13.3984 11 14 10.4258 14 9.6875V4.10938C14 3.97266 13.8359 3.89062 13.7266 3.97266ZM7 7.5C7.62891 7.52734 8.53125 6.70703 8.99609 6.37891C12.6328 3.75391 12.9062 3.50781 13.7266 2.85156C13.8906 2.74219 14 2.55078 14 2.33203V1.8125C14 1.10156 13.3984 0.5 12.6875 0.5H1.3125C0.574219 0.5 0 1.10156 0 1.8125V2.33203C0 2.55078 0.0820312 2.74219 0.246094 2.85156C1.06641 3.50781 1.33984 3.75391 4.97656 6.37891C5.44141 6.70703 6.34375 7.52734 7 7.5Z" fill="#0179D2" />
                                                    </svg>
                                                    <span>{branchs.find(el => el.branchId == this.props.match.params.id).email}</span>
                                                </a>
                                            </p>
                                        </div>
                                        <div className="dashboard-service-network-branch-desc" style={{ marginBottom: '25px' }}>
                                            <p className="dashboard-service-network-branch-desc-element">
                                                <a href={`tel:${branchs.find(el => el.branchId == this.props.match.params.id).phoneNumber}`}>
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.519531 1.43359L3.36328 0.777344C3.66406 0.695312 3.99219 0.859375 4.12891 1.16016L5.44141 4.22266C5.55078 4.49609 5.46875 4.79688 5.25 4.98828L3.58203 6.35547C4.56641 8.43359 6.28906 10.1836 8.42188 11.1953L9.78906 9.52734C9.98047 9.30859 10.2812 9.22656 10.5547 9.33594L13.6172 10.6484C13.918 10.7852 14.0547 11.1133 14 11.4141L13.3438 14.2578C13.2617 14.5586 13.0156 14.75 12.6875 14.75C5.6875 14.75 0 9.08984 0 2.0625C0 1.76172 0.21875 1.51562 0.519531 1.43359Z" fill="#0179D2" />
                                                    </svg>
                                                    <span>{branchs.find(el => el.branchId == this.props.match.params.id).phoneNumber}</span>
                                                </a>
                                            </p>
                                            <p className="dashboard-service-network-branch-desc-element">
                                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 7.75C8.91406 7.75 10.5 6.19141 10.5 4.25C10.5 2.33594 8.91406 0.75 7 0.75C5.05859 0.75 3.5 2.33594 3.5 4.25C3.5 6.19141 5.05859 7.75 7 7.75ZM9.43359 8.625H8.96875C8.36719 8.92578 7.71094 9.0625 7 9.0625C6.28906 9.0625 5.60547 8.92578 5.00391 8.625H4.53906C2.51562 8.625 0.875 10.293 0.875 12.3164V13.4375C0.875 14.1758 1.44922 14.75 2.1875 14.75H11.8125C12.5234 14.75 13.125 14.1758 13.125 13.4375V12.3164C13.125 10.293 11.457 8.625 9.43359 8.625Z" fill="#0179D2" />
                                                </svg>
                                                <span>{branchs.find(el => el.branchId == this.props.match.params.id).director} / {branchs.find(el => el.branchId == this.props.match.params.id).directorPosition}</span>
                                            </p>
                                        </div>
                                        <YMaps preload={true} key={this.filterLangMap(i18n.language)} query={{ lang: this.filterLangMap(i18n.language) }}>
                                            <Map width={window.screen.width > 768 ? '100%' : window.screen.width -64} style={{ height: window.screen.width > 768 ? '62vw' : window.screen.width }} modules={["geolocation", "geocode"]} onLoad={e => {}} defaultState={{ center: [branchs.find(el => el.branchId == this.props.match.params.id).latitude, branchs.find(el => el.branchId == this.props.match.params.id).longitude], zoom: 10 }} >
                                                <Clusterer
                                                    options={{
                                                        preset: 'islands#invertedVioletClusterIcons',
                                                        groupByCoordinates: false,
                                                    }}
                                                >
                                                    <Placemark
                                                        properties={{
                                                            hintContent: branchs.find(el => el.branchId == this.props.match.params.id).branchName,
                                                            balloonContent: branchs.find(el => el.branchId == this.props.match.params.id).branchName,
                                                            balloonPanelMaxMapArea: 1,
                                                            // openEmptyBalloon: true
                                                        }}
                                                        geometry={[branchs.find(el => el.branchId == this.props.match.params.id).latitude, branchs.find(el => el.branchId == this.props.match.params.id).longitude]}
                                                        options={{
                                                            iconLayout: 'default#image',

                                                            iconImageHref: markerBoldIcon,
                                                            iconImageSize: [60, 60],

                                                        }}

                                                        modules={["geoObject.addon.balloon"]}
                                                    />
                                                </Clusterer>
                                                {
                                                    locatinAvailable === true ?
                                                        <Placemark
                                                            properties={{

                                                                // openEmptyBalloon: true
                                                            }}
                                                            geometry={[this.state.location.lat, this.state.location.long]}
                                                            options={{
                                                                iconLayout: 'default#image',

                                                                iconImageHref: currentIcon,
                                                                iconImageSize: [35, 35],
                                                                zIndex: 1000
                                                            }}

                                                            modules={["geoObject.addon.balloon"]}
                                                        />
                                                        : null
                                                }
                                                <GeolocationControl options={{ float: 'left' }} onClick={e => this.currentLocation()} />
                                                <FullscreenControl />
                                                <RulerControl options={{ float: 'right' }} />
                                                <ZoomControl options={{ float: 'right' }} />
                                            </Map>
                                        </YMaps>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default translate("translation")(Branch);
