import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import AuthHeader from "../../Auth/AuthHeader/AuthHeader";
import { translate } from 'react-i18next';
import "../../Auth/OTP/OTP.scss";
import headerLogo from "../../AsanLoginConfirmation/img/header-logo.svg";
import { request } from "../../../config";

const LoginWithAsanIndividualOtp = ({ t, updateAppState, }) => {
  const [OTPCode, setOTPCode] = useState();
  const [errMessage, setErrMessage] = useState();
  const [isVisible, setIsVisible] = useState(true);


  const initialMinute = 3,
    initialSeconds = 0,
    numInputs = 6;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isDisabled, setIsDisabled] = useState(true);
  const case1 = "00:00";

  const resetTime = () => {
    setErrMessage("");
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
    setIsDisabled(true);
    sendOtpCode();
    // console.log("resettime---");
  };

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  useEffect(() => {
    let timeInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timeInterval);
          // console.log("test");
          setIsDisabled(false);
        } else {
          setMinutes(minutes - 1);

          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  });

  let history = useHistory();
  const { state } = useLocation();
  const location = useLocation();

  if (location.state === undefined) {
    // this.props.history.push('/login/juridical/asan/');
    window.location.href = '/login/individual/asan/';
    // console.log("error")
  }

  const handleOTP = (otp) => setOTPCode(otp);

  let { getAuthInfoIndividualLoginRequestBody, getOtpFromAsanResponse } = location.state;
  const verifyToken = getOtpFromAsanResponse.verifyToken
  const mobile = getAuthInfoIndividualLoginRequestBody.phoneNumber
  // let { selectCustCompanyRequestBody, token, verifyToken } = location.state;
  const [verify, setIsVerify] = useState(verifyToken);

  useEffect(() => {
    setIsVerify(verifyToken);
  }, [verifyToken]);

  const sendOtpCode = (e) => {
    updateAppState("isLoadingTransparentBackground", true);
    fetch(request("register/getAuthInfoIndividualLogin", getAuthInfoIndividualLoginRequestBody))
      .then((res) => res.json())
      .then(
        (getAuthInfoIndividualLoginRequestResponse) => {
          if (getAuthInfoIndividualLoginRequestResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              (getAuthInfoIndividualLoginRequestResponse.status.statusMessage)
            );
            setIsVisible(false)
          } else {
            setIsVerify(getAuthInfoIndividualLoginRequestResponse.verifyToken)
            // console.log("əlse")
            updateAppState("isLoadingTransparentBackground", false);
            // console.log(getAuthInfoIndividualLoginRequestResponse)
            // history.push("/sign-up/not-a-bank-customer/create-password", {
            //   custId: state.custId,
            // });
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };
  // console.log(getOtpFromAsanResponse)

  const verifyOtpCode = (e) => {
    e.preventDefault();

    updateAppState("isLoadingTransparentBackground", true);
    setErrMessage("");

    fetch(request("register/getAuthInfoIndividualLogin", { ...getAuthInfoIndividualLoginRequestBody, otpCode: OTPCode, verifyToken: verify }))
      .then((res) => res.json())
      .then(
        (getAuthInfoIndividualLoginRequestResponse) => {
          if (getAuthInfoIndividualLoginRequestResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              (getAuthInfoIndividualLoginRequestResponse.status.statusMessage)
            );
            // setIsVisible(false)
            // console.log('5 time')
          } else {
            updateAppState("isLoadingTransparentBackground", false);

            let loginIndividualForAsanRequestBody = {
              verificationCode: getAuthInfoIndividualLoginRequestResponse.verificationCode,
              phoneNumber: getAuthInfoIndividualLoginRequestBody.phoneNumber,
              userId: getAuthInfoIndividualLoginRequestBody.userId,
              sessionId: getAuthInfoIndividualLoginRequestResponse.sessionId,
              token: getAuthInfoIndividualLoginRequestResponse.token,
              device: "device",
              location: "location",
              lang: getAuthInfoIndividualLoginRequestBody.lang
            }
            // console.log(loginIndividualForAsanRequestBody)
            localStorage.setItem('userId', getAuthInfoIndividualLoginRequestBody.userId);
            localStorage.setItem('verificationCode', getAuthInfoIndividualLoginRequestResponse.verificationCode);
            localStorage.setItem('phoneNumber', getAuthInfoIndividualLoginRequestBody.phoneNumber);

            // console.log("success")
            history.push('/login/individual/asan/confirmation', { loginIndividualForAsanRequestBody });
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };


  // useEffect(() => {
  //   if (location.state === undefined) {
  //     history.push("/sign-up/not-a-bank-customer")
  //   }
  //   else {
  //     updateAppState("isLoadingTransparentBackground", false)
  //   };
  // }, []);
  // console.log(initialMinute)
  // const { t } = translate();





  return (
    <div className="registration">
      <Link to="/">
        <img src={headerLogo} className="registration-logo" alt="headerLogo" />
      </Link>
      <div className="registration-container registration-container--top-border">
        <h2 className="registration-container-title registration-container-title--center">{t('otp code confirmation')}</h2>
      <p className="registration-container-title registration-container-title--center">{mobile}</p>
        <section className="ibank-otp header-sign-section">
          <div className="header-sign-section-container">
            <AuthHeader
              title={t("Enter the one-time code")}
              desc={[
                t("enter the 6-digit confirmation code sent to your phone"),
              ]}
            />
            <form onSubmit={verifyOtpCode}>
              <OtpInput
                className="ibank-otp__body"
                value={OTPCode}
                onChange={handleOTP}
                shouldAutoFocus={true}
                {...{ initialMinute, initialSeconds, numInputs }}
              />
              {isVisible &&
                (
                  <div className="ibank-otp__resend">
                    <button
                      disabled={isDisabled}
                      onClick={() => {
                        resetTime();
                      }}
                      type="button"
                    >
                      {t("resend the code")}
                    </button>
                    <p className="ibank-otp__resend__timer">
                      {minutes === 0 && seconds === 0
                        ? case1
                        : `${formatTime(minutes)} : ${formatTime(seconds)}`}
                    </p>
                  </div>
                )
                // <TimerCountdown
                //   resendTitle={t("resend the code")}
                //   {...{
                //     initialMinute,
                //     initialSeconds,
                //     sendOtpCode,
                //     setErrMessage,
                //   }}
                // />
              }

              <div className="registration-container-validation-feedback">
                {errMessage ? (
                  <p className="registration-container-validation-feedback-message">
                    {errMessage}
                  </p>
                ) : null}
              </div>
              <button
              type="submit"
                className="header-sign-section-container-form-button"
                disabled={OTPCode?.length !== numInputs || (minutes === 0 && seconds === 0)}
              >
                {t("continue")}
              </button>
            </form>
          </div>
        </section>
        {/* <div className="registration-container-asan-confirmation">
        <span className="registration-container-asan-confirmation-code">{verificationCode}</span>
    </div> */}
        <div className="registration-container-validation-feedback"></div>
        <div className="registration-container-footer">
          {/* <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.prevPage}>{t('back')}</button> */}
          {/* <button type="button" className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled}>{t('next')}</button> */}
        </div>
      </div>
    </div>
  );
};

export default translate("translation")(LoginWithAsanIndividualOtp);



