import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from '@material-ui/core/Button';
import '../scss/style.scss';
import threeDotsIcon from '../img/three-dots.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        maxWidth: 200,
    },
    dropdown: {
        position: 'absolute',
        top: 28,
        // padding: 10,
        zIndex: 1,  
    },
}));

export default function ClickAway(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    const handleClickAway = () => {
        setOpen(false);
    };
    const dropdownItemHandler = (value, index) => {
        props.updateSelected(value);
        if(props.updateIsCash) {
            props.updateIsCash(!Boolean(index));
        }
        handleClickAway();
    }
    return (
        <OutsideClickHandler onOutsideClick={handleClickAway}>
            <div className={classes.root + " " + props.className}>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <div className="dashboard-finance-section-account-extract-section-main-section-header-download-container">
                        <span className="dashboard-finance-section-account-extract-section-main-section-header-content" style={props.lowercase ? {textTransform: 'initial'} : {} }>{props.selected}</span>
                        <svg className="dashboard-finance-section-account-extract-section-main-section-header-download-container-icon" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.861607 0.926338C0.861607 0.837053 0.90625 0.736606 0.973213 0.669641L1.53125 0.111606C1.59821 0.0446417 1.69866 -9.00572e-07 1.78795 -8.92766e-07C1.87723 -8.84961e-07 1.97768 0.0446417 2.04464 0.111606L6.4308 4.49777L10.817 0.111606C10.8839 0.0446425 10.9844 -8.87888e-08 11.0737 -8.09832e-08C11.1741 -7.22019e-08 11.2634 0.0446425 11.3304 0.111607L11.8884 0.669642C11.9554 0.736606 12 0.837054 12 0.926339C12 1.01563 11.9554 1.11607 11.8884 1.18303L6.6875 6.38393C6.62053 6.45089 6.52009 6.49554 6.4308 6.49554C6.34152 6.49554 6.24107 6.45089 6.17411 6.38393L0.973213 1.18303C0.90625 1.11607 0.861607 1.01562 0.861607 0.926338Z" fill="#2E3131" />
                        </svg>
                    </div>                
                </Button>
                {open ?
                    <div className={classes.dropdown + " dropdown-button-operation-list"}>{
                        props.dropdownItems.map((dropdownItem, index) => (
                            <div className="dropdown-button-operation-link" onClick={() => dropdownItemHandler(dropdownItem, index)} key={index} >
                                <div className="dropdown-button-operation-item">{dropdownItem}</div>
                            </div>
                        ))
                    }
                    </div>
                    : null}
            </div>
        </OutsideClickHandler>
    );
}