import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { translate } from "react-i18next";

import { request } from "../../config";

import closeIcon from "./../../components/Dashboard/img/x-close-icon.svg";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // display: "inline-block",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
    width: "75%",
  },
}));

const CampaignPopup = (props) => {
  const classes = useStyles();
  let history = useHistory();

  const {
    open,
    setOpen,
    t,
    screenTypeId,
    campaignCustId,
    image,
    campaignId,
    note,
  } = props;

  const redirectToPage = (type) => {
    switch (type) {
      case 1:
        history.push(`/dashboard/service-network/campaigns/${campaignId}`);
        break;
      case 2:
        history.push(`/dashboard/finance/cards`);
        break;
      case 3:
        history.push(`/dashboard/finance/deposits`);
        break;
      case 4:
        history.push(
          `/dashboard/transfer/card-operation/friend-transfer/history/1`
        );
        break;
      case 5:
        history.push(`/dashboard/payment/payments`);
        break;
      case 6:
        history.push(`/dashboard/orders/credit`);
        break;
      case 7:
        history.push(`/dashboard/finance/bonuses/1`);
        break;
      case 8:
        history.push(`/dashboard/service-network/tariffs`);
        break;

      default:
        history.push("/dashboard/home");
        break;
    }
  };

  const checkCampaign = () => {
    const checkCampaignRequestBody = {
      campaignCustId: campaignCustId,
    };
    fetch(request("util/checkCampaignNotification", checkCampaignRequestBody))
      .then((res) => res.text())
      .then((checkCampaignResponseJSON) => {
        const JSON = require("true-json-bigint");
        const checkCampaignResponse = JSON.parse(checkCampaignResponseJSON);

        if (checkCampaignResponse?.status?.statusCode !== 1) {
          if (checkCampaignResponse?.status?.statusCode === 200) {
            this.props.logout();
          } else {
            this.props.alertState(
              true,
              "error",
              this.props.t("an error occurred"),
              this.props.t(checkCampaignResponse?.status?.statusCode)
            );
          }
        } else {
          redirectToPage(screenTypeId);
        }
      });
  };

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      classes={classes.modal}
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="dashboard-selected-campaign">
            <div
              className="dashboard-selected-campaign-close-btn"
              onClick={close}
            >
              <div className="dashboard-selected-campaign-close-btn-img">
                <img src={closeIcon} alt="close-btn-img" />
              </div>
            </div>
            <div className="dashboard-selected-campaign-item">
              <img className="dashboard-selected-campaign-image" src={image} />
            </div>
            <div className="dashboard-selected-campaign-actions center">
              <button
                className="dashboard-selected-campaign-actions-btn dashboard-selected-campaign-actions-btn-close"
                onClick={close}
              >
                {t("close")}
              </button>
              {screenTypeId !== null && (
                <button
                  onClick={checkCampaign}
                  className="dashboard-selected-campaign-actions-btn dashboard-selected-campaign-actions-btn-continue"
                >
                  {note}
                </button>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default translate("translation")(CampaignPopup);
