import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";

import DatePicker from "../../../../../copms/DatePicker/DatePicker";
import currencyFilter from "../../../../../helpers/currencyFilter";
import Loading from "../../../Loading/Loading";
import { request } from "../../../../../config";
import { getFormatedDate } from "../../../../../helpers/fotmatDate";

import "./CardLimitsForm.scss";

const CardLimitsForm = ({
  singleCardLimit: { name, defaultLimit, currencyLimit, maxLimit, limitCodeId },
  setOpenForm,
  logout,
  alertState,
  updateAppState,
  t,
}) => {
  const { id } = useParams();

  const [inputValue, setInputValue] = useState();
  const [loading, setLoading] = useState(true);
  const [cardLimit, setCardLimit] = useState();
  const [activationDate, setActivationDate] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [amountErrMsg, setAmountErrMsg] = useState();

  let today = new Date();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const cancelForm = () => {
    setOpenForm(false);
  };

  const getCardLimitData = () => {
    setLoading(true);
    const requestBody = {
      custId: parseInt(localStorage.getItem("custId")),
      cardId: parseInt(id),
      limitCodeId: limitCodeId,
      token: localStorage.getItem("token"),
    };

    fetch(request("card/getCardLimitData", requestBody))
      .then((res) => res.text())
      .then((getCardLimitDataResponseJSON) => {
        const JSON = require("true-json-bigint");
        let getCardLimitDataResponse = JSON.parse(getCardLimitDataResponseJSON);
        if (getCardLimitDataResponse?.status?.statusCode !== 1) {
          if (getCardLimitDataResponse?.status?.statusCode === 200) {
            logout();
          } else {
            setLoading(false);
            alertState(
              true,
              "error",
              t("an error occurred"),
              t(getCardLimitDataResponse?.status?.statusCode)
            );
            setOpenForm(false);
          }
        } else {
          setLoading(false);
          setCardLimit({ ...getCardLimitDataResponse });
          setActivationDate(getCardLimitDataResponse?.activationDate);
        }
      });
  };

  const closeSuccessFunction = () => {
    return window.location.reload();
  };

  const onSubmit = (data) => {
    updateAppState("isLoadingTransparentBackground", true);
    let requestBody = {
      custId: parseInt(localStorage.getItem("custId")),
      cardId: parseInt(id),
      limitCodeId: limitCodeId,
      currentAmount: parseInt(data?.amount),
      dateFrom: getFormatedDate(today, "-"),
      dateTo: selectedDate || null,
      token: localStorage.getItem("token"),
    };

    fetch(request("card/usageLimitactivation", requestBody))
      .then((res) => res.text())
      .then((limitActivationResponseJSON) => {
        const JSON = require("true-json-bigint");
        let limitActivationResponse = JSON.parse(limitActivationResponseJSON);
        if (limitActivationResponse?.status?.statusCode !== 1) {
          if (limitActivationResponse?.status?.statusCode === 200) {
            logout();
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            alertState(
              true,
              "error",
              t("an error occurred"),
              t(limitActivationResponse?.status?.statusCode)
            );
          }
        } else {
          updateAppState("isLoadingTransparentBackground", false);
          alertState(
            true,
            "success",
            t("successful operation"),
            t("Limit has been successfully changed"),
            null,
            false,
            closeSuccessFunction
          );
          reset();
        }
      });
  };

  const setAmount = (amount) => {
    if (amount == cardLimit?.currentAmount) {
      setAmountErrMsg(
        `* ${t("Enter a limit different from the current limit")}`
      );
    } else if (amount > maxLimit) {
      setAmountErrMsg(`* ${t("Unable to set maximum limit")}`);
    } else {
      setInputValue(amount);
      setAmountErrMsg("");
      setActivationDate(null);
    }
  };

  useEffect(() => {
    getCardLimitData();
    reset();
  }, [limitCodeId, reset]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="ibank-card-limits__form__content">
          <p className="ibank-card-limits__form__content__header">{name}</p>
          <div className="ibank-card-limits__form__content__types">
            <div className="ibank-card-limits__form__content__types__item">
              <p className="ibank-card-limits__form__content__types__item__title">
                {t("Standard limit")}
              </p>
              <p className="ibank-card-limits__form__content__types__item__amount">
                {defaultLimit} {currencyFilter(currencyLimit)}
              </p>
            </div>
            <div className="ibank-card-limits__form__content__types__item">
              <p className="ibank-card-limits__form__content__types__item__title">
                {t("Maximum limit")}
              </p>
              <p className="ibank-card-limits__form__content__types__item__amount">
                {maxLimit} {currencyFilter(currencyLimit)}
              </p>
            </div>
            <div className="ibank-card-limits__form__content__types__item">
              <p className="ibank-card-limits__form__content__types__item__title">
                {t("Current limit")}
              </p>
              <p className="ibank-card-limits__form__content__types__item__amount">
                {cardLimit?.currentAmount} {currencyFilter(currencyLimit)}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ibank-card-limits__form__content__inputs">
              <div className="dashboard-payment-container-data-section-content-container-form-input-group__grid dashboard-payment-container-data-section-content-container-form-input-group__grid">
                <label className="dashboard-payment-container-data-section-content-container-form-label">
                  {t("Define a new limit")}
                </label>
                <div
                  className={`dashboard-payment-container-data-section-content-container-form-input-box ${
                    amountErrMsg && "input-outline"
                  }`}
                >
                  <input
                    {...register("amount", { required: true })}
                    onChange={(e) => {
                      setAmount(e?.target.value);
                    }}
                    placeholder={t("Amount")}
                    type="number"
                    onKeyDown={(event) => {
                      if (event.key === ".") {
                        event.preventDefault();
                      }
                    }}
                    onInput={(event) =>
                      (event.target.value = event.target.value.replace(
                        /[^0-9]*/g,
                        ""
                      ))
                    }
                    className="dashboard-payment-container-data-section-content-container-form-input"
                  />
                  <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">
                    {currencyLimit.toUpperCase()}
                  </div>
                </div>
                {amountErrMsg && (
                  <p className="ibank-input__err__msg">{amountErrMsg}</p>
                )}
              </div>
              <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid ibank-card-limits__form__content__datepicker">
                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                  <Controller
                    control={control}
                    name={"activationDate"}
                    render={({ field }) => (
                      <DatePicker
                        format="dd-MM-yyyy"
                        disablePast={true}
                        error={false}
                        placeholder={t("Choose")}
                        fillColor="#0179D2"
                        selectedDate={activationDate}
                        updateDate={(e) => {
                          let date = getFormatedDate(e, "-");
                          setSelectedDate(date);
                        }}
                      />
                    )}
                  />
                </div>
                <p className="ibank-input__err__msg">
                  {t(
                    "If you do not select a date, then the limit is valid until the expiration date of the card"
                  )}
                </p>
              </div>
            </div>
            <div className="ibank-card-limits__form__content__actions">
              <button
                type="submit"
                className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__${
                  !inputValue || amountErrMsg ? "disable" : "blue"
                } dashboard-payment-container-data-section-content-container-form-footer-button__left`}
                disabled={!inputValue || amountErrMsg}
              >
                {t("save")}
              </button>
              <button
                type="button"
                className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right"
                onClick={cancelForm}
              >
                {t("refusal")}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CardLimitsForm;
