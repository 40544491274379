import React, { Component } from 'react';

export default class CompanyListItem extends Component {
    render() {
        let { companyId, companyName, companyTaxNo, updateCheckedCompany, compIdResponse, token } = this.props;
        if(companyId === compIdResponse) {
            return (
                <li className="registration-companies-container-item">
                    <span className="registration-companies-container-item-title">{companyName}</span>
                    <span className="registration-companies-container-item-number">{companyTaxNo}</span>
                    <input type="radio" name="registration-company" defaultChecked="true" className="registration-companies-container-item-input" onChange={updateCheckedCompany.bind(this, companyId, companyTaxNo, token)}/>
                    <span className="registration-companies-container-item-checkmark"></span>
                </li>
            )
        }
        else {
            return (
                <li className="registration-companies-container-item">
                    <span className="registration-companies-container-item-title">{companyName}</span>
                    <span className="registration-companies-container-item-number">{companyTaxNo}</span>
                    <input type="radio" name="registration-company" className="registration-companies-container-item-input" onChange={updateCheckedCompany.bind(this, companyId, companyTaxNo, token)}/>
                    <span className="registration-companies-container-item-checkmark"></span>
                </li>
            )
        }
    }
}
