import React, { useEffect } from 'react';
import {
    Link,
    Redirect
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import AsanConfirmationIcon from "./img/asan-confirmation-icon.svg";
import headerLogo from "./img/header-logo.svg";
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function AsanVerification(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [verificationCode, setVerificationCode] = React.useState(true);
    const close = () => {
        setOpen(false);
    }
    useEffect(() => {
        setOpen(props.open)
        setVerificationCode(props.verificationCode)
    }, [props.open, props.verificationCode])

    
    return (
        <Modal
            open={open}
            onClose={close}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            classes={classes.modal}
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description">
            <Fade in={open}>
                <div className="asan-verificatiion-modal">
                    <button className="asan-verificatiion-modal-close-button" onClick={close}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                        </svg>
                    </button>
                    <div className="asan-verificatiion-modal-content">
                        <div className={"asan-verificatiion-modal-content-header"}>
                            <img className="asan-verificatiion-modal-content-header-icon registration-container-icon" src={AsanConfirmationIcon} alt="AsanConfirmationIcon" />
                            <h3 className="asan-verificatiion-modal-content-header-title">{props.t('asan signature confirmation')}</h3>
                            <p className="asan-verificatiion-modal-content-header-subtitle">{props.t('please accept the request sent to your phone')}</p>
                        </div>
                        <div className="asan-verificatiion-modal-confirmation">
                            <span className="asan-verificatiion-modal-confirmation-code">{verificationCode}</span>
                        </div>
                        <div className="registration-container-validation-feedback">
                            {/* {
                                feedbackMessage ? <p className="registration-container-validation-feedback-message">{feedbackMessage}</p> : null
                            } */}
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
export default translate("translation")(AsanVerification);