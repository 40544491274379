import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { request } from '../../../config';
import CampaignSlider from './CampaignSlider';
import Loading from './../Loading/Loading';

class Campaign extends Component {
    state = {
        campaign: {},
        isLoading: false,
        campaigns: [],
    }

    async getCampaignById() {
        this.setState({ isLoading: true });
        let getCampaignByIdRequestBody = {
            id: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        await fetch(request("util/getCampaignById", getCampaignByIdRequestBody))
            .then(res => res.text())
            .then((getCampaignByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCampaignByIdResponse = JSON.parse(getCampaignByIdResponseJSON);
                if (getCampaignByIdResponse.status.statusCode !== 1) {
                    if (getCampaignByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCampaignByIdResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    getCampaignByIdResponse.image = 'data:image/;base64,' + getCampaignByIdResponse.image;
                    this.setState({ campaign: getCampaignByIdResponse });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

        getCampaignList() {
            this.setState({ campaigns: JSON.parse(localStorage.getItem("campaigns")).slice(0, 6) });

            // TODO
            // maybe it will be needed
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        // let getCampaignListRequestBody = {
        //     lang: localStorage.getItem('i18nextLng').toUpperCase()
        // }
        // if (localStorage.getItem('compId')) {
        //     getCampaignListRequestBody.compId = parseInt(localStorage.getItem('compId'));
        // }
        // await fetch(request("util/getCampaignList", getCampaignListRequestBody))
        //     .then(res => res.text())
        //     .then((getCampaignListResponseJSON) => {
        //         const JSON = require('true-json-bigint');
        //         let getCampaignListResponse = JSON.parse(getCampaignListResponseJSON);
        //         if (getCampaignListResponse.status.statusCode !== 1) {
        //             if (getCampaignListResponse.status.statusCode === 200) {
        //                 this.props.logout();
        //             } else {
        //                 this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCampaignListResponse.status.statusCode));
        //                 this.props.updateAppState('isLoadingTransparentBackground', false);
        //             }
        //         }
        //         else {
        //             getCampaignListResponse.respCampaignList.map((item, index) => {
        //                 getCampaignListResponse.respCampaignList[index].image = 'data:image/;base64,' + getCampaignListResponse.respCampaignList[index].image;
        //             });
        //             this.setState({ campaigns: getCampaignListResponse.respCampaignList.slice(0, 6) });
        //             this.props.updateAppState('isLoadingTransparentBackground', false);
        //         }
        //     },
        //         (error) => {
        //             this.props.history.push("/technical-break");
        //         });
    }
    async getData() {
        await this.getCampaignById();
        this.getCampaignList();
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.getData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.getData();
        }
    }
    render() {
        let { t, history } = this.props;
        let { campaign, campaigns, isLoading } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{campaign.name}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section-vertical">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <div className="dashboard-campaign-section">
                                <div className="dashboard-campaign-section-image" style={{ backgroundImage: `url(${campaign.image})` }}></div>
                                <p className="dashboard-campaign-section-subtitle">{campaign.note}</p>
                            </div>
                            <div className="dashboard-campaigns-slider">
                                <div className="dashboard-campaigns-slider-header">
                                    <h3 className="dashboard-campaigns-slider-header-title">{t('Recent campaigns')}</h3>
                                    <Link to="/dashboard/service-network/campaigns" className="dashboard-campaigns-slider-header-history">{t('all')}</Link>
                                </div>
                                {
                                    campaigns &&
                                    <div className="dashboard-campaigns-slider-content">
                                        <CampaignSlider campaigns={campaigns} />
                                        <div className="dashboard-campaigns-slider-content-border"></div>
                                    </div>
                                }
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}
export default translate("translation")(Campaign);
