import cardIcon from '../components/Dashboard/img/credit-cardcredit-card.svg';
import qrIcon from '../components/Dashboard/img/qrcode.svg';
import exchangeIcon from '../components/Dashboard/img/exchange_op.svg';
import friendIcon from '../components/Dashboard/img/friend-transfer-icon.svg';
import zkIcon from '../components/Dashboard/img/zk-transfer-icon.svg';
import expressIcon from '../components/Dashboard/img/express-icon.svg';

const lists = [
    {
        cardOperationType: 1,
        cardOperationTypeValue: "between-internal",
        name: "Between my own cards",
        iconName: exchangeIcon,
    },
    {
        cardOperationType: 2,
        cardOperationTypeValue: "between-external",
        name: "To another bank's card",
        iconName: cardIcon,
    },
    {
        cardOperationType: 3,
        cardOperationTypeValue: "code",
        name: "Cash by code",
        iconName: qrIcon,
    },
    {
        cardOperationType: 4,
        cardOperationTypeValue: "friend",
        name: "Friend transfer",
        iconName: friendIcon,
    },
    {
        cardOperationType: 5,
        cardOperationTypeValue: "zk-transfer",
        name: "Zolatoy Korona",
        iconName: zkIcon,
    },
    {
        cardOperationType: 6,
        cardOperationTypeValue: "express-transfer",
        name: "Express transfer",
        iconName: expressIcon,
    }
]


function cardOperationFilter(id) {
    return lists.find(elem => elem.cardOperationType === id)?lists.find(elem => elem.cardOperationType === id) : null;
}

export default cardOperationFilter;