import React, { Component, Fragment } from 'react';
import { Redirect } from "react-router-dom";

import { request, ipsRequest } from '../../../../config';
import { translate } from 'react-i18next';
import SimpleModal from '../../../../copms/Modal/SimpleModal';
import AsanVerification from '../../../../copms/Modal/AsanVerification';

class FatcaContract extends Component {
    state = {
        step: 0,
        open: false,
        verificationOpen: false,
        fileFatca: null,
        fileContract: null,
        request: undefined,
        timer: '00:00',
        minute: 3,
        second: 0,
        verificationCode: ''
    }

    startTimer = () => {
        let { minute, second } = this.state;
        this.setState({
            timerInterval: setInterval(() => {
                let secondPart, minutePart;
                if (second === 5 && minute === 0) {
                    clearInterval(this.state.timerInterval);
                    this.setState({
                        timer: null
                    });
                }
                else {
                    if (second !== 0) {
                        second--;
                    }
                    else {
                        if (minute !== 0) {
                            minute--;
                            second = 59;
                        }
                    }
                    if (second < 10) {
                        secondPart = "0" + second;
                    }
                    else {
                        secondPart = second;
                    }
                    if (minute < 10) {
                        minutePart = "0" + minute;
                    }
                    else {
                        minutePart = minute;
                    }
                    this.setState({
                        timer: minutePart + ":" + secondPart
                    })
                }
            }, 1000)
        })
    }

    createDepositFatca = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("deposit/createDepositFatca", this.state.request))
            .then(res => res.json())
            .then(createDepositFatcaResponse => {
                if (createDepositFatcaResponse.status.statusCode !== 1) {
                    if (createDepositFatcaResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(createDepositFatcaResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let bytesStr = `data:application/pdf;base64,${createDepositFatcaResponse.fileFatca}`;
                    this.setState({ fileFatca: bytesStr, step: 1 });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            }, (error) => {
                this.props.history.push("/technical-break");
            });
        this.props.history.replace();
    }

    createDepositContract = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("deposit/createDepositContract", this.state.request))
            .then(res => res.json())
            .then(createDepositContractResponse => {
                if (createDepositContractResponse.status.statusCode !== 1) {
                    if (createDepositContractResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(createDepositContractResponse.status.statusCode), '/dashboard/finance/deposits');
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let bytesStr = `data:application/pdf;base64,${createDepositContractResponse.fileContract}`;
                    this.setState({ fileContract: bytesStr, step: 2 });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            }, (error) => {
                this.props.history.push("/technical-break");
            });
    }

    signSubmit() {
        this.setState({ verificationCode: '' });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let phoneNumber = localStorage.getItem('phoneNumber');
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode;
        let getSignDepositFileRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            userId: localStorage.getItem('userId'),
            phoneNumber: phoneNumber,
            certCode: certCode,
            depositId: this.state.request.depositId
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            userId: localStorage.getItem('userId'),
            phoneNumber: phoneNumber,
            certCode: certCode,
            depositId: this.state.request.depositId
        };

        fetch(request("deposit/getSignDepositFile", getSignDepositFileRequestBody))
            .then(res => res.text())
            .then((getSignDepositFileResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSignDepositFileResponse = JSON.parse(getSignDepositFileResponseJSON);
                if (getSignDepositFileResponse.status.statusCode !== 1) {
                    if (getSignDepositFileResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSignDepositFileResponse.status.statusCode), '/dashboard/finance/deposits');
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ verificationOpen: true, verificationCode: getSignDepositFileResponse.verificationCode, step: 3 });

                    let signDepositFileRequestBody = customerType == 'Juridical' ? {
                        custId: localStorage.getItem('custId'),
                        compId: localStorage.getItem('compId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        userId: localStorage.getItem('userId'),
                        phoneNumber: phoneNumber,
                        certCode: certCode,
                        transactionId: getSignDepositFileResponse.transactionId,
                        failOperId: getSignDepositFileResponse.failOperId,
                        successOperId: getSignDepositFileResponse.successOperId,
                        depositId: this.state.request.depositId
                    } : {
                        custId: localStorage.getItem('custId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        userId: localStorage.getItem('userId'),
                        phoneNumber: phoneNumber,
                        certCode: certCode,
                        transactionId: getSignDepositFileResponse.transactionId,
                        failOperId: getSignDepositFileResponse.failOperId,
                        successOperId: getSignDepositFileResponse.successOperId,
                        depositId: this.state.request.depositId
                    };
                    fetch(request("deposit/signDepositFile", signDepositFileRequestBody))
                        .then(res => res.text())
                        .then((signDepositFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let signDepositFileResponse = JSON.parse(signDepositFileResponseJSON);
                            if (signDepositFileResponse.status.statusCode !== 1) {
                                if (signDepositFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.setState({
                                        verificationOpen: false,
                                    });
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(signDepositFileResponse.status.statusCode), '/dashboard/finance/deposits');

                                }
                            } else {
                                this.setState({ verificationOpen: false, open: true, title: this.props.t("success deposit") });
                            }
                        });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    changeFatca(val) {
        if (val) {
            this.setState({ open: true, title: this.props.t("approach the bank regarding the changes") });
        }
        else {
            this.createDepositContract();
        }
    }

    signDepositFile(val) {
        if (val) {
            this.signSubmit();
        }
        else {
            this.props.history.push('/dashboard/finance/deposits/order');
        }
    }

    closeModal = () => {
        this.setState({ open: false });
        if (this.state.step === 1) {
            this.props.history.push('/dashboard/finance/deposits', {status: 2});
        }
        if (this.state.step === 3) {
            this.props.history.push('/dashboard/finance/deposits', {status: 2});
        }
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            this.setState({
                request: this.props.location.state?.request,
                timer: `${('0' + this.props.location.state?.time?.toString()).slice(-2)}:00`,
                minute: this.props.location.state?.time,
                second: 0,
            }, () => {
                this.startTimer();
                this.createDepositFatca();
            });
        }
        else {
            this.props.history.push('/dashboard/finance/deposits/order');
        }
    }

    render() {
        let { open, title, timer, verificationOpen, verificationCode, fileFatca, fileContract, step } = this.state;

        let { t } = this.props;

        return (
            <Fragment>
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <SimpleModal open={open} close={() => { this.closeModal() }} title={title} />
                <div className="fatca">
                    {
                        step > 0 &&
                        <div className='fatca-container'>
                            <div className='fatca-container-content'>
                                {
                                    step < 2 && fileFatca ?
                                        <object data={`${fileFatca}#toolbar=0&view=fitH`} type='application/pdf' width='100%' height='100%'></object>
                                        :
                                        fileContract &&
                                        <object data={`${fileContract}#toolbar=0&view=fitH`} type='application/pdf' width='100%' height='100%'></object>
                                }
                            </div>
                            <div className='fatca-container-footer'>
                                <p className='fatca-container-footer-timer'>{timer}</p>
                                <p className='fatca-container-footer-content'>{t("deposit timer content")}</p>
                                {
                                    step < 2 ?
                                        <>
                                            <p className='fatca-container-footer-content'>{t("fatca confirm content")}</p>
                                            <div className="fatca-container-footer-buttons">
                                                <button className="fatca-container-footer-button fatca-container-footer-button-yes" onClick={this.changeFatca.bind(this, true)}>{t('Yes')}</button>
                                                <button className="fatca-container-footer-button fatca-container-footer-button-no" onClick={this.changeFatca.bind(this, false)}>{t('No')}</button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="fatca-container-footer-buttons">
                                                <button className="fatca-container-footer-button fatca-container-footer-button-yes" onClick={this.signDepositFile.bind(this, false)}>{t('reject')}</button>
                                                <button className="fatca-container-footer-button fatca-container-footer-button-no" onClick={this.signDepositFile.bind(this, true)}>{t('Sign')}</button>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        )
    }
}



export default translate("translation")(FatcaContract);