import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { request, ipsRequest } from './../../../../config';
import { translate } from 'react-i18next';
import Loading from '../../Loading/Loading';
import NotFound from './../../../../copms/NotFound/NotFound';
import CheckboxMinus from './../../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../../copms/Checkbox/CheckboxTick';
import RadioButton from './../../../../copms/RadioButton/RadioButton';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import GeneralModal from '../../../../copms/Modal/GeneralModal';
import { event } from 'jquery';

class LinkAlias extends Component {
    state = {
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
        createFeedbackMessage: false,
        open: false,
        type: null,
        selectedType: null,
        value: "",
        accounts: [],
        selectedId: -1,
        isLoading: false,
        hasAlias: false,
        aliasTypes: [],
        aliasTypeSelectIndex: -1
    }
    getFormatedDate = (date) => {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    getAliasTypes = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAliasTypesRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        await fetch(ipsRequest("getAliasTypes", getAliasTypesRequestBody))
            .then(res => res.text())
            .then((getAliasTypesResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAliasTypesResponse = JSON.parse(getAliasTypesResponseJSON);
                if (getAliasTypesResponse.status.statusCode !== 1) {
                    if (getAliasTypesResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAliasTypesResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ aliasTypes: getAliasTypesResponse.types })

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    getAccountsByAliasId = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountsByAliasIdRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasId: this.props.match.params.id
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                aliasId: this.props.match.params.id
            }

        await fetch(ipsRequest("getAccountsByAliasId", getAccountsByAliasIdRequestBody))
            .then(res => res.text())
            .then((getAccountsByAliasIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountsByAliasIdResponse = JSON.parse(getAccountsByAliasIdResponseJSON);
                if (getAccountsByAliasIdResponse.status.statusCode !== 1 && getAccountsByAliasIdResponse.status.statusCode !== 126) {
                    if (getAccountsByAliasIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                    else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountsByAliasIdResponse.status.statusCode));
                    }
                }
                else {
                    if (getAccountsByAliasIdResponse.status.statusCode === 126) {
                        this.setState({ accounts: [] });
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        let accounts = getAccountsByAliasIdResponse.accountList && getAccountsByAliasIdResponse.accountList.filter(account => (!account.isLinked || (account.isLinked && account.isLinked == this.state.value)) && account.currency === 'AZN').map((item) => {
                            item.isSelect = item.isLinked == this.state.value;
                            return item;
                        });
                        this.setState({
                            accounts: accounts,
                            selectedId: accounts ?
                                accounts.find(el => el.isDefault == 1) &&
                                accounts.find(el => el.isDefault == 1).accountId : -1
                        });
                    }

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }
    getAliasById = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAliasByIdRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasId: this.props.match.params.id
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                aliasId: this.props.match.params.id
            }

        await fetch(ipsRequest("getAliasById", getAliasByIdRequestBody))
            .then(res => res.text())
            .then((getAliasByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAliasByIdResponse = JSON.parse(getAliasByIdResponseJSON);
                if (getAliasByIdResponse.respStatus.statusCode !== 1 && getAliasByIdResponse.respStatus.statusCode !== 182) {
                    if (getAliasByIdResponse.respStatus.statusCode === 200) {
                        this.props.logout();
                    }
                    else {
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAliasByIdResponse.respStatus.statusCode));
                    }
                }
                else {

                    this.setState({
                        value: getAliasByIdResponse.ipsValue,
                        selectedType: getAliasByIdResponse.ipsType,
                        hasAlias: true,
                        aliasTypeSelectIndex: this.state.aliasTypes.findIndex((item, index) => (item.name === getAliasByIdResponse.ipsType))
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showAlias = async () => {
        this.setState({ isLoading: true });
        await this.getAliasTypes();
        await this.getAliasById();
        await this.getAccountsByAliasId();
        this.setState({ isLoading: false });
    }
    selectAll(val) {
        var accounts = this.state.accounts;
        for (let i = 0; i < accounts.length; i++) {
            accounts[i].isSelect = val;
        }
        this.setState({ accounts: accounts })
    }
    selectIsDefault = (event) => {
        this.setState({ selectedId: event.target.value });
    }
    linkAlias = (event) => {
        event.preventDefault();
        this.linkOperation();
    }

    async linkOperation() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            feedbackErrorModal: null,
            feedbackSuccessModal: null,
            createFeedbackMessage: false
        });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let LinkAliasRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasIdAndAccountIdList: this.state.accounts.filter(el => el.isSelect == true && (!el.compoundId || el.compoundId && el.isDefault != 1 && (this.state.selectedId == el.accountId ? true : false))).map((item) => {
                return {
                    aliasId: this.props.match.params.id,
                    accountId: item.accountId,
                    isDefault: this.state.selectedId == item.accountId ? 1 : 0,
                    isLinked: item.isLinked,
                    compoundId: item.compoundId
                };
            })
        }
        if (customerType === 'Juridical') {
            LinkAliasRequestBody.compId = localStorage.getItem('compId');
        }

        await this.unlinkAccount();

        await fetch(ipsRequest("linkAliasToAccount", LinkAliasRequestBody))
            .then(res => res.text())
            .then((linkAliasToAccountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let linkAliasToAccountResponse = JSON.parse(linkAliasToAccountResponseJSON);
                if (linkAliasToAccountResponse.status.statusCode !== 1) {
                    if (linkAliasToAccountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            createFeedbackMessage: false, open: true,
                            feedbackErrorModal: this.props.t(linkAliasToAccountResponse.status.statusCode)
                        });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(linkAliasToAccountResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({
                        createFeedbackMessage: true, open: true,
                        feedbackSuccessModal: this.props.t('Accounts are successfully linked to alias')
                    });
                    // this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('Accounts are successfully linked to alias'));
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    async unlinkAccount() {
        let accountList = (this.state.accounts && this.state.accounts.length > 0) ? this.state.accounts.filter(el => el.compoundId && !el.isSelect && el.isLinked == this.state.value) : [];
        if (accountList.length > 0) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let LinkAliasRequestBody = {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                aliasAccountIdList: accountList.map((item) => item.compoundId)
            }
            if (customerType === 'Juridical') {
                LinkAliasRequestBody.compId = localStorage.getItem('compId');
            }
            await fetch(ipsRequest("unlinkAliasFromAccount", LinkAliasRequestBody))
                .then(res => res.text())
                .then((linkAliasToAccountResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let linkAliasToAccountResponse = JSON.parse(linkAliasToAccountResponseJSON);
                    if (linkAliasToAccountResponse.status.statusCode !== 1) {
                        if (linkAliasToAccountResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                            this.setState({
                                createFeedbackMessage: false, open: true,
                                feedbackErrorModal: this.props.t(linkAliasToAccountResponse.status.statusCode)
                            });
                            // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(linkAliasToAccountResponse.status.statusCode));
                        }
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            createFeedbackMessage: true, open: true,
                            feedbackSuccessModal: this.props.t('Accounts are successfully linked to alias')
                        });
                        // this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('Accounts are successfully linked to alias'));
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }
    componentDidMount() {
        this.showAlias();
    }
    selectAccount(index) {
        var accounts = this.state.accounts;
        accounts[index].isSelect = !Boolean(accounts[index].isSelect);
        this.setState({ accounts: accounts })
    }
    selectAll(val) {
        let accounts = this.state.accounts;
        for (let i = 0; i < accounts.length; i++) {
            accounts[i].isSelect = val;
        }
        this.setState({ accounts: accounts });
    }
    render() {
        let { isLoading, feedbackErrorModal, feedbackSuccessModal, createFeedbackMessage, open, selectedId, accounts, hasAlias, value, aliasTypes, aliasTypeSelectIndex } = this.state;
        let { history, updateAppState, t } = this.props;
        return (
            <Fragment>
                <GeneralModal open={open} successClose={() => { history.push('/dashboard/transfer/ips/aliases') }} errorClose={() => this.setState({ open: false })} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} feedbackSuccessModal={feedbackSuccessModal} history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{value}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !hasAlias ?
                            <NotFound />
                            :
                            <div className="dashboard-payment-container">
                                <div className="dashboard-payment-container-data-section">
                                    <div className="dashboard-payment-container-data-section-header">
                                        <h3 className="dashboard-payment-container-data-section-header-title">{t('Link')}</h3>
                                    </div>
                                    <div className="dashboard-transfer-list-content">
                                        <form className="dashboard-payment-container-data-section-content" onSubmit={event => { event.preventDefault(); }}>
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Alias parameters')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form">
                                                <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Alias type')}</label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                            <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable"} disabled={true}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{aliasTypes && aliasTypes.length > 0 && aliasTypeSelectIndex >= 0 && aliasTypes[aliasTypeSelectIndex].description}</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{aliasTypes && aliasTypes.length > 0 && aliasTypeSelectIndex >= 0 && aliasTypes[aliasTypeSelectIndex].description}</label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                            <input value={value} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <table className="dashboard-transfer-list-table" style={{ marginTop: '32px' }}>
                                            <thead className="dashboard-transfer-list-table-head">
                                                <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head" style={(accounts && accounts.length > 0) ? {} : { borderBottom: '10px' }}>
                                                    {
                                                        (accounts && accounts.length > 0) &&
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head" style={{ width: '70px' }}>
                                                            <CheckboxMinus
                                                                onChecked={val => this.selectAll(val)} />
                                                        </th>
                                                    }
                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("ACCOUNT")}</th>
                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head" style={(accounts && accounts.length > 0) ? { textAlign: 'center' } : {}}>{t("CURRENCY")}</th>
                                                    <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head" style={(accounts && accounts.length > 0) ? { textAlign: 'center' } : {}}>{t("DEFAULT ACCOUNT")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className="dashboard-transfer-list-table-body">
                                                {
                                                    accounts && accounts.length > 0 ? accounts.map((item, index) => {
                                                        return (
                                                            <tr className="dashboard-transfer-list-table-row">
                                                                <td className="dashboard-transfer-list-table-cell">
                                                                    <CheckboxTick checked={Boolean(item.isSelect)} onChecked={val => this.selectAccount(index)} />
                                                                </td>
                                                                <td className="dashboard-transfer-list-table-cell">{`${item.iban} ${item.mainCardNumber !== null ? '/' + item.mainCardNumber : ""}`}</td>
                                                                <td className="dashboard-transfer-list-table-cell" style={{ textAlign: 'center' }}>{item.currency}</td>
                                                                <td className="dashboard-transfer-list-table-cell" style={{ textAlign: 'center' }}>
                                                                    <RadioButton value={item.accountId && item.accountId} selectedId={selectedId} onChange={(e) => this.setState({ selectedId: e.target.value })} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                        :
                                                        <tr className="dashboard-transfer-list-table-row">
                                                            <td className="dashboard-transfer-list-table-cell">
                                                                <div className="dashboard-payment-operation-section-content-item">
                                                                    <span className="dashboard-transfer-list-table-cell__blue">{t("No registered account found without link")}</span>
                                                                </div>
                                                            </td>
                                                            <td className="dashboard-transfer-list-table-cell">
                                                                <div className="dashboard-payment-operation-section-content-item">
                                                                    <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                        <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                        <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="dashboard-transfer-list-table-cell">
                                                                <div className="dashboard-payment-operation-section-content-item">
                                                                    <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                        <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                        <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="dashboard-payment-container-confirmation-section">
                                    <div className="dashboard-payment-container-confirmation-section-header">
                                        <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('What is the Instant Payment System?')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-confirmation-section-content">
                                        <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                        <div className="dashboard-payment-container-confirmation-section-content-description">
                                            <p>{t("ips content 1")}</p>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                            <button disabled={!accounts.filter(el => el.isSelect == 1).length > 0} className={`dashboard-payment-container-data-section-content-container-form-footer-button ${accounts.filter(el => el.isSelect == 1).length > 0 ? 'dashboard-payment-container-data-section-content-container-form-footer-button__blue' : 'dashboard-payment-container-data-section-content-container-form-footer-button__disable'} dashboard-payment-container-data-section-content-container-form-footer-button__left`} onClick={this.linkAlias}>{t('continue')}</button>
                                            <button type="button" onClick={() => this.props.history.goBack()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('Back')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                }
            </Fragment>
        )
    }
}


export default translate("translation")(LinkAlias);