import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import NotFoundImage from "./img/construction.svg";
import dashboardLogoIcon from "./img/dashboard-logo-icon.svg";
import dashboardLogoText from "./img/dashboard-logo-text.svg";
import phoneIconNavbar from "./img/phone-icon-navbar.svg";
import LangMenu from '../LangMenu';
import { request } from './../../config';
import blueLogo from './../../helpers/img/logo-blue.svg';

import "./scss/style.scss";

class NotFoundWithoutLogin extends Component {
    componentDidMount() {
        this.props.updateAppState('isLoading', false);
        this.props.updateAppState('isLoadingTransparentBackground', false);
        // fetch(request("register/checkTokenv1", {}))
        //     .then(res => {this.props.history.push('/')}).catch(() => {
                
        //     })
    }
    orderCredit = () => {
        window.open('http://www.turanbank.az/' + (localStorage.getItem('i18nextLng').toLowerCase() ? localStorage.getItem('i18nextLng').toLowerCase() : 'AZ') + '/pages/370', "_blank");
    }
    render() {
        let { t } = this.props;
        return (
            <div className="notfound">
                <div className="notfound-container">
                    <div className="notfound-header">
                        <div className="notfound-header-alt">
                            <Link to="/" className="notfound-menu-section-header-logo">
                                {/* OLD LOGO */}
                                {/* <img src={dashboardLogoIcon} className="dashboard-menu-section-header-logo-icon" alt="dashboardLogoIcon" />
                                <img src={dashboardLogoText} className="dashboard-menu-section-header-logo-text" alt="dashboardLogoText" /> */}

                                <img src={blueLogo} className="dashboard-menu-section-header-logo" alt="dashboardLogo" />
                            </Link>

                            <div className="notfound-menu-section-header-logo">
                                <a className="nav-right-side-phone" href="tel:+99412935">
                                    <img src={phoneIconNavbar} className="nav-right-side-phone-icon" alt="Phone icon navbar" />
                                    <span className="nav-right-side-phone-text" style={{ color: '#0179D2' }}>935</span>
                                </a>
                                <Link to="/login" className="nav-right-side-register hnav-login">{t('login')}</Link>
                                <Link to="/registration" className="nav-right-side-register">{t('registration')}</Link>
                                {/* <button className="nav-right-side-language">
                                <span className="nav-right-side-language-title">AZ</span>
                                <img src={chevronDown} className="nav-right-side-language-dropdown-icon" alt="Dropdown"/>
                            </button> */}
                                <LangMenu theme={"dark"} />
                            </div>
                        </div>
                    </div>
                    <div className="notfound-content">
                        <div className="background-notfound">
                            <img src={NotFoundImage} alt="NotFoundImage" />
                            <h3 className="notfound-header-title">{t('a 30-minute break was announced in the system due to technical works.')}</h3>

                            <h6 className="notfound-description">{t('If the technical pause page is reopened, please refresh the page by pressing Ctrl + F5 at the same time.')}</h6>

                            <Link to="/" className="dashboard-finance-section-invisible-cards-section-button" >{t('REFRESH PAGE')}</Link>
                        </div>
                    </div>

                </div>
                <footer className="dashboard-footer" style={{ position: 'inherit' }}>
                    <div className="dashboard-footer-copyright">{t('copyright')} | {t('all rights reserved')}</div>
                    {/* <img src={propaganda} className="dashboard-footer-propaganda" /> */}
                </footer>

            </div>

        )
    }
}

export default translate("translation")(NotFoundWithoutLogin);