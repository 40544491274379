import React, { Component } from "react";
import {
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import SmsConfirmation from "./SmsConfirmation/SmsConfirmation";
import SetUsernamePassword from "./SetUsernamePassword/SetUsernamePassword";
import RegisterSuccess from "./RegisterSuccess/RegisterSuccess";
import CheckCustomer from "./CheckCustomer/CheckCustomer";

export default class RegistrationIndividualMobile extends Component {
    render() {
        let { updateAppState } = this.props;
        return (
            <Switch>
                <Route exact path="/registration/individual/mobile/1" render={(routeProps) => <CheckCustomer {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/individual/mobile/2" render={(routeProps) => <SmsConfirmation {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/individual/mobile/3" render={(routeProps) => <SetUsernamePassword {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/individual/mobile/4" render={(routeProps) => <RegisterSuccess {...routeProps} updateAppState={updateAppState}/>}/>
                <Redirect to={"/registration/individual/mobile/1"}/>
            </Switch>
        )
    }
}
