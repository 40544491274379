import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { translate } from 'react-i18next';

import { request } from '../../config';

import './scss/style.scss';
import './scss/loading.css';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function CreditPayment(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [loanPaymentPlan, setLoanPaymentPlan] = useState();
    const close = () => {
        setOpen(false);
        props.onClose();
    }

    useEffect(() => {
        if (props.open === true) {
            setLoanPaymentPlan(props.loanPaymentPlan);
            setOpen(true);
            // fetch(request("loan/loanTempPaymentPlan", props.requestBody))
            //     .then(res => res.text())
            //     .then((getLoanTempPaymentPlanResponseJSON) => {
            //         const JSON = require('true-json-bigint');
            //         let getLoanTempPaymentPlanResponse = JSON.parse(getLoanTempPaymentPlanResponseJSON);
            //         if (getLoanTempPaymentPlanResponse.status.statusCode !== 1) {
            //             if (getLoanTempPaymentPlanResponse.status.statusCode === 200) {
            //                 this.props.logout();
            //             } else {
            //                 this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getLoanTempPaymentPlanResponse.status.statusCode));
            //                 this.props.updateAppState('isLoadingTransparentBackground', false);
            //         }
            //     }
            //     else {
            // // this.setState({ credit: getLoanByIdForCustomerResponse, paymentTypes: getLoanByIdForCustomerResponse.paymentTypes, defaultValue: getLoanByIdForCustomerResponse.payForMonth, amount: getLoanByIdForCustomerResponse.payForMonth });
            //         this.setState({ isLoading: false });
            //     }
            // },
            //     (error) => {
            //         this.props.history.push("/technical-break");
    
            //     });
        }
    }, [props.open])

    const t = props.t;
    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="credit-payment">
                            <div className="credit-payment-head">
                                <h2>{t('payments')}</h2>
                                <button onClick={close}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                    </svg>
                                </button>
                            </div>
                            <div className="credit-payment-container" style={{ width: '100%' }}>
                                <table className="credit-payment-table">
                                    <tr>
                                        <th>№</th>
                                        <th>{t('DATE')}</th>
                                        <th>{t('AMOUNT')}</th>
                                        <th>{t('THE REMAINING DEBT')}</th>
                                        <th>{t('PENALTY')}</th>
                                    </tr>
                                    {
                                        loanPaymentPlan && loanPaymentPlan.length > 0 &&
                                        loanPaymentPlan.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.payDate}</td>
                                                    <td>{item.totalPay}</td>
                                                    <td>{item.rest}</td>
                                                    <td>{item.penaltyAmount}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </table>
                                {
                                    !(loanPaymentPlan && loanPaymentPlan.length > 0) &&
                                    <div className="dashboard-finance-section-cards-section-commission-alert">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 1.03226C11.8065 1.03226 14.9677 4.16129 14.9677 8C14.9677 11.871 11.8387 14.9677 8 14.9677C4.12903 14.9677 1.03226 11.871 1.03226 8C1.03226 4.16129 4.12903 1.03226 8 1.03226ZM8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM6.83871 11.0968C6.6129 11.0968 6.45161 11.2903 6.45161 11.4839V11.7419C6.45161 11.9677 6.6129 12.129 6.83871 12.129H9.16129C9.35484 12.129 9.54839 11.9677 9.54839 11.7419V11.4839C9.54839 11.2903 9.35484 11.0968 9.16129 11.0968H8.77419V6.58065C8.77419 6.3871 8.58064 6.19355 8.3871 6.19355H6.83871C6.6129 6.19355 6.45161 6.3871 6.45161 6.58065V6.83871C6.45161 7.06452 6.6129 7.22581 6.83871 7.22581H7.22581V11.0968H6.83871ZM8 3.35484C7.41935 3.35484 6.96774 3.83871 6.96774 4.3871C6.96774 4.96774 7.41935 5.41935 8 5.41935C8.54839 5.41935 9.03226 4.96774 9.03226 4.3871C9.03226 3.83871 8.54839 3.35484 8 3.35484Z" fill="#2873F0" />
                                        </svg>
                                        <p>{t('You have no payments.')}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(CreditPayment);