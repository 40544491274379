import React, { Component } from 'react';

import cardIcon from './../../img/card-icon.svg';
import { request } from './../../../../config';
import { translate } from 'react-i18next';
import AntSwitch from '../../../../copms/Switch/AntSwitch';
import SmsActive from '../../../../copms/Modal/SmsActive';
import SmsService from '../../../../copms/Modal/SmsService';
import InputMask from 'react-input-mask';
import Loading from './../../Loading/Loading';

class CardSms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statements: [],
            switch: true,
            card: {},
            openSmsActive: false,
            openSmsService: false,
            phone: {},
            mobile: null,
            isDisable: true,
            loading: false,
            inputPhone: '',
            inputAgainPhone: '',
            selectLimit: 'daily',
            isLoading: false
        }
    }

    componentDidMount() {
        this.showCard();
    }
    showCard = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardByIdForCustomerRequestBody = (customerType === 'Individual') ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            cardId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                cardId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }


        fetch(request("card/getCardByIdFor" + customerType, getCardByIdForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardByIdForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardByIdForCustomerResponse = JSON.parse(getCardByIdForCustomerResponseJSON);
                if (getCardByIdForCustomerResponse.status.statusCode !== 1) {
                    if (getCardByIdForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardByIdForCustomerResponse.status.statusCode));
                    }
                }
                else {

                    // =========HOLDER=========
                    let holderArray = getCardByIdForCustomerResponse.holder.split(' ');
                    let lowercaseName = holderArray[0].toLowerCase();
                    let lowercaseSurname = holderArray[1].toLowerCase();
                    getCardByIdForCustomerResponse.organizedHolder = {
                        name: holderArray[0][0] + lowercaseName.substring(1, lowercaseName.length),
                        surname: holderArray[1][0] + lowercaseSurname.substring(1, lowercaseSurname.length)
                    };
                    // =========NUMBER=========
                    getCardByIdForCustomerResponse.organizedCardNumber = getCardByIdForCustomerResponse.cardNumber.substring(0, 4)
                        + " **** **** " + getCardByIdForCustomerResponse.cardNumber.substring(12, 16);

                    this.setState({ card: getCardByIdForCustomerResponse });


                    let getSmsNotificationNumberForCustomerRequestBody = (customerType === 'Individual') ? {
                        custId: localStorage.getItem('custId'),
                        token: localStorage.getItem('token'),
                        cardId: this.props.match.params.id,
                        lang: localStorage.getItem('i18nextLng').toUpperCase()
                    } : {
                            custId: localStorage.getItem('custId'),
                            compId: localStorage.getItem('compId'),
                            token: localStorage.getItem('token'),
                            cardId: this.props.match.params.id,
                            lang: localStorage.getItem('i18nextLng').toUpperCase()
                        }


                    fetch(request("card/getSmsNotificationNumberFor" + customerType + "Customer", getSmsNotificationNumberForCustomerRequestBody))
                        .then(res => res.text())
                        .then((getSmsNotificationNumberForCustomerResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let getSmsNotificationNumberForCustomerResponse = JSON.parse(getSmsNotificationNumberForCustomerResponseJSON);
                            if (getSmsNotificationNumberForCustomerResponse.status.statusCode !== 1) {
                                if (getSmsNotificationNumberForCustomerResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.setState({ isLoading: false });
                                }
                            }
                            else {
                                this.setState({ mobile: getSmsNotificationNumberForCustomerResponse.mobile });
                                this.setState({ isLoading: false });
                            }
                        }, (error) => {
                            this.props.history.push("/technical-break");

                        });



                }
            }, (error) => {
                this.props.history.push("/technical-break");

            });


    }
    switchFunc() {
        if (this.state.switch) {

        }
        this.setState({ openSmsActive: true })
    }
    onSwitchOpen = (val) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let active = this.state.switch ? 'disable' : 'enable';
        let addOrUpdateSmsNotifNumberForRequestBody = (customerType === 'Individual') ? {
            mobile: this.state.mobile,
            repeatMobile: this.state.mobile,
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            cardId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                mobile: this.state.mobile,
                repeatMobile: this.state.mobile,
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                cardId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("card/" + active + "SmsNotificationFor" + customerType + "Customer", addOrUpdateSmsNotifNumberForRequestBody))
            .then(res => res.text())
            .then((addOrUpdateSmsNotifNumberForResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSmsNotificationNumberForIndividualCustomerResponse = JSON.parse(addOrUpdateSmsNotifNumberForResponseJSON);
                if (getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode !== 1) {
                    if (getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode));
                    }
                }
                else {

                    this.setState({ switch: !this.state.switch })
                    //this.setState({ mobile: getSmsNotificationNumberForIndividualCustomerResponse.mobile });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            }, (error) => {
                this.props.history.push("/technical-break");

            });

    }
    inputPhoneChange(val) {
        if (val.length > 0 && this.state.inputAgainPhone.length > 0 && val.indexOf('_') < 0 && this.state.inputAgainPhone.indexOf('_') < 0) {
            this.setState({ inputPhone: val, isDisable: false })
        } else {
            this.setState({ inputPhone: val, isDisable: true })
        }
    }
    inputAgainPhoneChange(val) {
        if (val.length > 0 && this.state.inputPhone.length > 0 && val.indexOf('_') < 0 && this.state.inputPhone.indexOf('_') < 0) {
            this.setState({ inputAgainPhone: val, isDisable: false })
        } else {
            this.setState({ inputAgainPhone: val, isDisable: true })
        }
    }
    onSubmitForm = (e) => {
        e.preventDefault();
        if (this.state.inputAgainPhone.length > 0 && this.state.inputPhone.length > 0 && this.state.inputAgainPhone.indexOf('_') < 0 && this.state.inputPhone.indexOf('_') < 0) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let addOrUpdateSmsNotifNumberForRequestBody = (customerType === 'Individual') ? {
                mobile: this.clearCharacters(this.state.inputPhone),
                repeatMobile: this.clearCharacters(this.state.inputAgainPhone),
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                cardId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            } : {
                    mobile: this.clearCharacters(this.state.inputPhone),
                    repeatMobile: this.clearCharacters(this.state.inputAgainPhone),
                    custId: localStorage.getItem('custId'),
                    compId: localStorage.getItem('compId'),
                    token: localStorage.getItem('token'),
                    cardId: this.props.match.params.id,
                    lang: localStorage.getItem('i18nextLng').toUpperCase()
                }
            fetch(request("card/addOrUpdateSmsNotifNumberFor" + customerType + "Customer", addOrUpdateSmsNotifNumberForRequestBody))
                .then(res => res.text())
                .then((addOrUpdateSmsNotifNumberForResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getSmsNotificationNumberForIndividualCustomerResponse = JSON.parse(addOrUpdateSmsNotifNumberForResponseJSON);
                    if (getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode !== 1) {
                        if (getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode));
                        }
                    }
                    else {
                        window.location.reload();
                        //this.setState({ mobile: getSmsNotificationNumberForIndividualCustomerResponse.mobile });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }, (error) => {
                    this.props.history.push("/technical-break");

                });
        }
    }
    clearCharacters(val) {
        return val.replace(/\+/g, '').replace(/ /g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
    }
    onSmsUpdateSubmit(select, phone, againPhone) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let addOrUpdateSmsNotifNumberForRequestBody = (customerType === 'Individual') ? {
            mobile: this.clearCharacters(phone),
            repeatMobile: this.clearCharacters(againPhone),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            cardId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                mobile: this.clearCharacters(phone),
                repeatMobile: this.clearCharacters(againPhone),
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                cardId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("card/addOrUpdateSmsNotifNumberFor" + customerType + "Customer", addOrUpdateSmsNotifNumberForRequestBody))
            .then(res => res.text())
            .then((addOrUpdateSmsNotifNumberForResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSmsNotificationNumberForIndividualCustomerResponse = JSON.parse(addOrUpdateSmsNotifNumberForResponseJSON);
                if (getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode !== 1) {
                    if (getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSmsNotificationNumberForIndividualCustomerResponse.status.statusCode));
                    }
                }
                else {

                    window.location.reload();
                    //this.setState({ mobile: getSmsNotificationNumberForIndividualCustomerResponse.mobile });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            }, (error) => {
                this.props.history.push("/technical-break");

            });

    }

    render() {
        let {  openSmsActive, openSmsService, card, mobile, isDisable, loading, inputPhone, inputAgainPhone, isLoading } = this.state;
        let { t } = this.props;

        return (
            <>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('SMS service')}</h2>
                    </div>
                    <a href="http://turanbank.az/" className="dashboard-header-route-link">{t('ALL CARDS')}</a>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section dashboard-container-main-section--column dashboard-container-main-section--white">
                            <SmsActive checked={this.state.switch} open={openSmsActive} onClose={() => this.setState({ openSmsActive: false })} triggerFunc={this.onSwitchOpen} />
                            <SmsService onSmsUpdateSubmit={(select, phone, againPhone) => this.onSmsUpdateSubmit(select, phone, againPhone)} open={openSmsService} onClose={() => this.setState({ openSmsService: false })} />
                            <div className="dashboard-finance-section-account-extract-section">
                                <div className="dashboard-finance-section-account-extract-section-main-section">
                                    <div className="dashboard-finance-section-account-extract-section-main-section-header">
                                        {
                                            card.cardId !== undefined ?
                                                <div className="dashboard-finance-section-card-section-details-section-header-left-side">
                                                    <img src={cardIcon} className="dashboard-finance-section-card-section-details-section-header-image" alt="cardIcon" />
                                                    <h3 className="dashboard-finance-section-card-section-details-section-header-card-number">{card.organizedCardNumber}</h3>
                                                </div>
                                                : null
                                        }

                                    </div>

                                    <div className="dashboard-finance-section-account-extract-section-main-section-visible-content-section">
                                        <div className="dashboard-finance-section-cards-section-commission-alert">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 1.03226C11.8065 1.03226 14.9677 4.16129 14.9677 8C14.9677 11.871 11.8387 14.9677 8 14.9677C4.12903 14.9677 1.03226 11.871 1.03226 8C1.03226 4.16129 4.12903 1.03226 8 1.03226ZM8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM6.83871 11.0968C6.6129 11.0968 6.45161 11.2903 6.45161 11.4839V11.7419C6.45161 11.9677 6.6129 12.129 6.83871 12.129H9.16129C9.35484 12.129 9.54839 11.9677 9.54839 11.7419V11.4839C9.54839 11.2903 9.35484 11.0968 9.16129 11.0968H8.77419V6.58065C8.77419 6.3871 8.58064 6.19355 8.3871 6.19355H6.83871C6.6129 6.19355 6.45161 6.3871 6.45161 6.58065V6.83871C6.45161 7.06452 6.6129 7.22581 6.83871 7.22581H7.22581V11.0968H6.83871ZM8 3.35484C7.41935 3.35484 6.96774 3.83871 6.96774 4.3871C6.96774 4.96774 7.41935 5.41935 8 5.41935C8.54839 5.41935 9.03226 4.96774 9.03226 4.3871C9.03226 3.83871 8.54839 3.35484 8 3.35484Z" fill="#2873F0" />
                                            </svg>
                                            <p>{t('Monthly commission for SMS service')} - <span>1 AZN</span></p>
                                        </div>
                                        {
                                            mobile ? <table className="dashboard-finance-section-cards-section-sms-table">
                                                <tr>
                                                    <th>{t('MOBILE NUMBER')}</th>
                                                    {/* <th>{t('LIMIT TYPE')}</th> */}
                                                    <th>{t('STATUS')}</th>
                                                    <th>{t('CHANGE')}</th>
                                                </tr>
                                                <tr>
                                                    <td>+{mobile}</td>
                                                    {/* <td>{t('Daily')}</td> */}
                                                    <td><AntSwitch checked={this.state.switch} onChange={() => this.switchFunc()} name="checkedC" /></td>
                                                    <td>
                                                        <button onClick={() => this.setState({ openSmsService: true })}>
                                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.9243 16H8.3811C7.92013 16 7.54297 15.6228 7.54297 15.1619C7.54297 14.7009 7.92013 14.3237 8.3811 14.3237H15.9243C16.3853 14.3237 16.7625 14.7009 16.7625 15.1619C16.7625 15.6228 16.3853 16 15.9243 16Z" fill="#586268" />
                                                                <path d="M0.838299 16.0008C0.620384 16.0008 0.402469 15.917 0.243223 15.7578C0.0336894 15.5482 -0.0501241 15.2465 0.0253081 14.9615L0.863443 11.609C0.896969 11.4581 0.972401 11.324 1.08136 11.2234L11.558 0.746754C12.547 -0.242245 14.2736 -0.242245 15.2542 0.746754C16.2768 1.76928 16.2768 3.42879 15.2542 4.44293L4.78592 14.9196C4.67696 15.0286 4.54286 15.104 4.40037 15.1375L1.04783 15.9757C0.972401 15.9924 0.90535 16.0008 0.838299 16.0008ZM2.43076 12.2376L1.98654 14.0061L3.75501 13.5618L14.0641 3.25278C14.4329 2.884 14.4329 2.28892 14.0641 1.92014C13.7121 1.56813 13.0918 1.56813 12.7314 1.92014L2.43076 12.2376Z" fill="#586268" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table> :
                                                <form onSubmit={this.onSubmitForm} className="header-sign-section-container-form">
                                                    <table className="dashboard-finance-section-cards-section-sms-table">
                                                        <tr style={{ borderBottom: 0 }}>
                                                            <td>
                                                                <div className="header-sign-section-container-form-group">
                                                                    <label className="header-sign-section-container-form-group-label">{t('phone number')}</label>
                                                                    <div className="header-sign-section-container-form-group-box">
                                                                        <InputMask onChange={val => this.inputPhoneChange(val.target.value)} value={inputPhone} mask="+\9\9\4 ( 99 ) 999-99-99" className="header-sign-section-container-form-group-box-input" placeholder="+994 (__) ___-__-__" />
                                                                        <div className="header-sign-section-container-form-group-box-icon">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="header-sign-section-container-form-group">
                                                                    <label className="header-sign-section-container-form-group-label">{t('Repeat phone number')}</label>
                                                                    <div className="header-sign-section-container-form-group-box">
                                                                        <InputMask onChange={val => this.inputAgainPhoneChange(val.target.value)} value={inputAgainPhone} mask="+\9\9\4 ( 99 ) 999-99-99" className="header-sign-section-container-form-group-box-input" placeholder="+994 (__) ___-__-__" />
                                                                        <div className="header-sign-section-container-form-group-box-icon">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td >
                                                                <div className="modal-sms-service-shange-button">
                                                                    <button className="header-sign-section-container-form-button" style={{ minWidth: '120px', borderRadius: '2px', height: '50px', marginTop: '38px' }} disabled={isDisable} >{loading ? <div class="spinner spinner--steps icon-spinner" aria-hidden="true"></div> : t('Add to')}</button>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </table>
                                                </form>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}

export default translate("translation")(CardSms);