import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { request } from './../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import TransferModal from './../../../../copms/Modal/TransferModal';
import CreateTransferTemplate from './../../../../copms/Modal/CreateTransferTemplate';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from './../../Loading/Loading';
import PaymentModalSucces from '../../../../copms/Modal/PaymentModalSucces';
import PaymentModalError from '../../../../copms/Modal/PaymentModalError';
import ConvertationCashbackModal from '../../../../copms/Modal/ConvertationCashbackModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import GeneralModal from '../../../../copms/Modal/GeneralModal';

function roundNumber(number) { // Arguments: number to round, number of decimal places
    return Math.round(number * 100) / 100;
}

class CreateTransferConvertation extends Component {
    state = {
        confirmedOperation: false,
        confirmedOperationCard: true,
        dropdownVisibilityBank: false,
        dropdownVisibilityBuyingAccount: false,
        dropdownVisibilitySellingAccount: false,
        banks: [],
        accounts: [],
        selectedBuyingAccount: null,
        selectedSellingAccount: null,
        selectedBank: {},
        bankFilter: "",
        crCustomerName: "",
        crCustomerNameValidation: false,
        crIban: "",
        crIbanValidation: false,
        crTaxNo: "",
        crTaxNoValidation: false,
        amount: "",
        amountValidation: false,
        purpose: "",
        purposeValidation: false,
        dtAccountId: null,
        buyingAccounts: [],
        sellingAccounts: [],
        currencyBuying: '',
        currencySelling: '',
        convertationValue: "",
        exchangeCashlessList: [],
        exchangeRate: '',
        step: 1,
        sellingIndex: -1,
        buyingIndex: -1,
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
        openTemp: false,
        operationId: 0,
        tempHave: false,
        exchangeEditable: false,
        converNumber: "",
        buyingAccountStatus: 0,
        sellingAccountStatus: 0,
        cards: [],
        dropdownVisibilityCard: false,
        requestorCardIdCount: 0,
        requestorCardIdValidation: false,
        cardData: false,
        card: null,
        earnedCashback: this.props.location.state.earnedCashback,
        calculatedCashback: this.props.location.state.calculatedCashback,
        isLoading: false
    }

    componentDidMount() {
        this.showCards();
    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    showCards = () => {
        this.setState({ isLoading: true })
        let getCardListCustomerRequestBody = {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("card/getCardListForIndividualCustomer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false })
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }
                    this.setState({ cards: getCardListForCustomerResponse.cardList, cardData: true });
                    this.setState({ isLoading: false })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    onSubmitForm() {
        this.setState({ feedbackErrorModal: null });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let createExchangeOperationRequestBody = {
            cardId: this.state.card.cardId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            amount: this.state.convertationValue
        }

        fetch(request("bonus/cashBackToCard", createExchangeOperationRequestBody))
            .then(res => res.text())
            .then((createExchangeOperationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createExchangeOperationResponse = JSON.parse(createExchangeOperationResponseJSON);
                if (createExchangeOperationResponse.status.statusCode !== 1) {
                    if (createExchangeOperationResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ open: true, feedbackErrorModal: this.props.t(createExchangeOperationResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ open: true, feedbackSuccessModal: this.props.t(createExchangeOperationResponse.status.statusCode), feedbackErrorModal: null });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateCard(card, event) {
        event.preventDefault();
        this.setState({ card: card, selectedCard: event.target.textContent, dropdownVisibilityCard: false, requestorCardIdValidation: true, confirmedOperationCard: true });
    }

    changeDropdownVisibilityCard = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCard: !this.state.dropdownVisibilityCard }, () => {
            if (!this.state.requestorCardIdValidation) {
                this.setState({ requestorCardIdCount: ++this.state.requestorCardIdCount });
            }
        });
    }

    handleInputChange = (e) => {
        const inputValue = e.target.value;
    
        // Check if the input is a valid number with up to 2 decimal places
        const regex = /^\d+(\.\d{0,2})?$/;
    
        if (regex.test(inputValue) || inputValue === '') {
          // Update the state with the modified input
          this.setState({ convertationValue: inputValue });
        }
    };
    
    render() {
        let { t, history } = this.props;
        let { cards, card, selectedCard, requestorCardIdCount, requestorCardIdValidation, cardData, dropdownVisibilityCard, earnedCashback, calculatedCashback,
            step, convertationValue, open, feedbackErrorModal, feedbackSuccessModal, isLoading } = this.state;
        return (
            <Fragment>
                <GeneralModal 
                open={open} 
                successClose={() => { history.push(`/dashboard/finance/cards/${this.props.match.params.id}`) }} 
                errorClose={() => this.setState({open: false})} 
                feedbackErrorModal={feedbackErrorModal} 
                feedbackSuccessModal={feedbackSuccessModal} 
                history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <Link to={`/dashboard/finance/cards/${this.props.match.params.id}`}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </Link>
                        <h2>{t('Cashback card')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-payment-container">
                            <div className="dashboard-payment-container-data-section">
                                <div className="dashboard-payment-container-data-section-header">
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Transfer bonuses to card')}</h3>
                                        {/* <Link to={`/dashboard/transfer/template/transfer/convertation/1/${this.props.match.params.id}`} style={{ borderBottom: this.props.match.params.type == '1' && '2px solid #0179D2', height: '56px', lineHeight: '56px', color: this.props.match.params.type == '2' && '#959FA5', textDecoration: 'none', }} className="dashboard-payment-container-data-section-header-title">{t('Purchase of foreign currency')}</Link> */}
                                        {/* <Link to={`/dashboard/transfer/template/transfer/convertation/2/${this.props.match.params.id}`} style={{ borderBottom: this.props.match.params.type == '2' && '2px solid #0179D2', height: '56px', lineHeight: '56px', color: this.props.match.params.type == '1' && '#959FA5', textDecoration: 'none', marginLeft: '12px', }} className="dashboard-payment-container-data-section-header-title">{t('Sale of foreign currency')}</Link> */}
                                    </div>
                                </div>
                                {
                                    this.props.match.params.type != '1' &&
                                    <form className="dashboard-payment-container-data-section-content">
                                        <div className="dashboard-payment-container-data-section-content-container dashboard-payment-container-data-section-content-container-redesign">
                                            <div className='dashboard-payment-container-data-section-content-container-redesign-cashback-info flex-wrap'>
                                                <div className='col-2'>
                                                    <p style={{ minHeight: '30px' }}>{t('Earned total cashback')}</p>
                                                    <div class="dashboard-transfer-history-content-item-container-amount">
                                                        <span class="dashboard-transfer-history-content-item-container-amount-whole fw-bolder">{earnedCashback}</span>
                                                        <span class="dashboard-transfer-history-content-item-container-amount-currency">AZN</span>
                                                    </div>
                                                </div>
                                                <div className='col-2'>
                                                    <p style={{ minHeight: '30px' }}>{t('Calculated cashback')}</p>
                                                    <div class="dashboard-transfer-history-content-item-container-amount">
                                                        <span class="dashboard-transfer-history-content-item-container-amount-whole fw-bolder">{calculatedCashback}</span>
                                                        <span class="dashboard-transfer-history-content-item-container-amount-currency">AZN</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form">
                                                <div className="dashboard-payment-container-data-section-content-container-form-container flex-column">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select card')}</label>
                                                        <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCard: false }) }}>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (requestorCardIdCount > 1 && !requestorCardIdValidation || requestorCardIdCount === 1 && !requestorCardIdValidation && !dropdownVisibilityCard ? "input-outline" : "")}>
                                                                <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(step > 1)} onClick={this.changeDropdownVisibilityCard}>
                                                                    {
                                                                        selectedCard ?
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCard}</div>
                                                                            :
                                                                            (cards && cards.length === 0 && cardData) ?
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{t('The card list is empty')}</div>
                                                                                :
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                    }
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </button>
                                                                <ul className={(dropdownVisibilityCard === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        cards && cards.length > 0 &&
                                                                        cards.map((card, index) => {
                                                                            return (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button onClick={this.updateCard.bind(this, card)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" disabled={!Boolean(card.cardStatus)}>{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }

                                                                </ul>
                                                            </div>
                                                        </OutsideClickHandler>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')}</label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                            <input type="text" className=
                                                                "dashboard-payment-container-data-section-content-container-form-input" disabled={Boolean(step > 1)}
                                                                value={convertationValue} placeholder="0" onChange={e => /^\d*\.?\d*$/.test(e.target.value) && this.handleInputChange(e)} />
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                            {
                                step === 1 ?
                                    <div className="dashboard-payment-container-confirmation-section">
                                        <div className="dashboard-payment-container-confirmation-section-header">
                                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How to convert?')}</h3>
                                        </div>
                                        <div className="dashboard-payment-container-confirmation-section-content">
                                            <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" alt="transferConfirmationImg" />
                                            <div className="dashboard-payment-container-confirmation-section-content-description">
                                                <p>{t('convertation content')}</p>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                <button onClick={e => this.setState({ step: 2 })}
                                                    disabled={!card || convertationValue == ""}
                                                    className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${(!card || convertationValue == "") && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('continue')}</button>
                                                <button onClick={e => this.setState({ card: null, selectedCard: null, convertationValue: "" })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="dashboard-payment-container-confirmation-section">
                                        <div className="dashboard-payment-container-confirmation-section-header">
                                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                        </div>
                                        <div className="dashboard-payment-container-confirmation-section-content">
                                            {
                                                step > 1 &&
                                                <Fragment>
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                        <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter('AZN')}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(convertationValue).part1}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(convertationValue).part2}</span>
                                                    </h3>
                                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Category')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('transfers')}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Card number')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{card?.cardNumber}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('AZN amount')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{convertationValue} AZN</p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                <button onClick={e => this.onSubmitForm()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                                <button onClick={e => this.setState({ step: 1 })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                }
            </Fragment>
        )
    }
}
export default translate("translation")(CreateTransferConvertation);
