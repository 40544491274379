import React, { Component } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Link } from 'react-router-dom';
import cardIcon from './../../img/card-icon.svg';
import creditIcon from './../../img/credit-icon.svg';

import cardsIcon from './../../img/cards-icon.png';
import cardBlueBackground from './../../img/card-blue-background.svg';
import cardTransparentBackground from './../../img/card-transparent-background.svg';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import { request } from './../../../../config';
import currencyFilter from './../../../../helpers/currencyFilter';
import { translate } from 'react-i18next';
import CreditSchedule from '../../../../copms/Modal/CreditSchedule';
import CreditPayment from '../../../../copms/Modal/CreditPayments';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from './../../Loading/Loading';

class Credit extends Component {
    state = {
        credit: null,
        openCreditSchedule: false,
        openCreditPayment: false,
        isLoading: false,
        statements: []
    }
    showCredit = () => {
        this.setState({ isLoading: true });
        // this.props.updateAppState('isLoading', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getLoanByIdForCustomerRequestBody = (customerType === 'Individual') ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            loanId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            loanId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("loan/getLoan" + customerType + "ById", getLoanByIdForCustomerRequestBody))
            .then(res => res.text())
            .then((getLoanByIdForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getLoanByIdForCustomerResponse = JSON.parse(getLoanByIdForCustomerResponseJSON);
                if (getLoanByIdForCustomerResponse.status.statusCode !== 1) {
                    if (getLoanByIdForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        // this.props.updateAppState('isLoading', false);
                        this.setState({ isLoading: false });
                    }
                }
                else {

                    // =========CURRENCY=========
                    getLoanByIdForCustomerResponse.currencyIcon = currencyFilter(getLoanByIdForCustomerResponse.currency);
                    // =========LOANAMOUNT=========
                    if (getLoanByIdForCustomerResponse.loanAmount.toString() === "0") {
                        getLoanByIdForCustomerResponse.organizedLoanAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getLoanByIdForCustomerResponse.loanAmount.toString().indexOf('.') !== -1) {
                            let loanAmountArray = getLoanByIdForCustomerResponse.loanAmount.toString().split('.');
                            getLoanByIdForCustomerResponse.organizedLoanAmount = {
                                wholePart: loanAmountArray[0],
                                fractionalPart: (loanAmountArray[1].length === 1) ? (loanAmountArray[1] + '0') : loanAmountArray[1]
                            }
                        }
                        else {
                            getLoanByIdForCustomerResponse.organizedLoanAmount = {
                                wholePart: getLoanByIdForCustomerResponse.loanAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    // =========delayPercentRest=========
                    if (getLoanByIdForCustomerResponse.delayPercentRest === null) {
                        getLoanByIdForCustomerResponse.organizedInterestRate = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getLoanByIdForCustomerResponse.delayPercentRest.toString() === "0") {
                            getLoanByIdForCustomerResponse.organizedInterestRate = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getLoanByIdForCustomerResponse.delayPercentRest.toString().indexOf('.') !== -1) {
                                let interestRateArray = getLoanByIdForCustomerResponse.delayPercentRest.toString().split('.');
                                getLoanByIdForCustomerResponse.organizedInterestRate = {
                                    wholePart: interestRateArray[0],
                                    fractionalPart: (interestRateArray[1].length === 1) ? (interestRateArray[1] + '0') : interestRateArray[1]
                                }
                            }
                            else {
                                getLoanByIdForCustomerResponse.organizedInterestRate = {
                                    wholePart: getLoanByIdForCustomerResponse.delayPercentRest.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    // =========REST=========
                    if (getLoanByIdForCustomerResponse.rest === null) {
                        getLoanByIdForCustomerResponse.rest = 0;
                        getLoanByIdForCustomerResponse.organizedRest = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getLoanByIdForCustomerResponse.rest.toString() === "0") {
                            getLoanByIdForCustomerResponse.organizedRest = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getLoanByIdForCustomerResponse.rest.toString().indexOf('.') !== -1) {
                                let restArray = getLoanByIdForCustomerResponse.rest.toString().split('.');
                                getLoanByIdForCustomerResponse.organizedRest = {
                                    wholePart: restArray[0],
                                    fractionalPart: (restArray[1].length === 1) ? (restArray[1] + '0') : restArray[1]
                                }
                            }
                            else {
                                getLoanByIdForCustomerResponse.organizedRest = {
                                    wholePart: getLoanByIdForCustomerResponse.rest.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    // =========PENALTYREST=========
                    if (getLoanByIdForCustomerResponse.penaltyRest === undefined || getLoanByIdForCustomerResponse.penaltyRest === null) {
                        getLoanByIdForCustomerResponse.organizedPenaltyRest = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getLoanByIdForCustomerResponse.penaltyRest.toString() === "0") {
                            getLoanByIdForCustomerResponse.organizedPenaltyRest = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getLoanByIdForCustomerResponse.penaltyRest.toString().indexOf('.') !== -1) {
                                let penaltyRestArray = getLoanByIdForCustomerResponse.penaltyRest.toString().split('.');
                                getLoanByIdForCustomerResponse.organizedPenaltyRest = {
                                    wholePart: penaltyRestArray[0],
                                    fractionalPart: (penaltyRestArray[1].length === 1) ? (penaltyRestArray[1] + '0') : penaltyRestArray[1]
                                }
                            }
                            else {
                                getLoanByIdForCustomerResponse.organizedPenaltyRest = {
                                    wholePart: getLoanByIdForCustomerResponse.penaltyRest.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    // =========minPaymentAmount=========
                    if (getLoanByIdForCustomerResponse.minPaymentAmount === undefined || getLoanByIdForCustomerResponse.minPaymentAmount === null) {
                        getLoanByIdForCustomerResponse.organizedMinPaymentAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getLoanByIdForCustomerResponse.minPaymentAmount.toString() === "0") {
                            getLoanByIdForCustomerResponse.organizedMinPaymentAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getLoanByIdForCustomerResponse.minPaymentAmount.toString().indexOf('.') !== -1) {
                                let minPaymentAmountArray = getLoanByIdForCustomerResponse.minPaymentAmount.toString().split('.');
                                getLoanByIdForCustomerResponse.organizedMinPaymentAmount = {
                                    wholePart: minPaymentAmountArray[0],
                                    fractionalPart: (minPaymentAmountArray[1].length === 1) ? (minPaymentAmountArray[1] + '0') : minPaymentAmountArray[1]
                                }
                            }
                            else {
                                getLoanByIdForCustomerResponse.organizedMinPaymentAmount = {
                                    wholePart: getLoanByIdForCustomerResponse.minPaymentAmount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    // =========PAYEDAMOUNT=========
                    if (getLoanByIdForCustomerResponse.payedAmount === null) {
                        getLoanByIdForCustomerResponse.payedAmount = 0;
                        getLoanByIdForCustomerResponse.organizedPayedAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getLoanByIdForCustomerResponse.payedAmount.toString() === "0") {
                            getLoanByIdForCustomerResponse.organizedPayedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getLoanByIdForCustomerResponse.payedAmount.toString().indexOf('.') !== -1) {
                                let organizedPayedAmount = getLoanByIdForCustomerResponse.payedAmount.toString().split('.');
                                getLoanByIdForCustomerResponse.organizedPayedAmount = {
                                    wholePart: organizedPayedAmount[0],
                                    fractionalPart: (organizedPayedAmount[1].length === 1) ? (organizedPayedAmount[1] + '0') : organizedPayedAmount[1]
                                }
                            }
                            else {
                                getLoanByIdForCustomerResponse.organizedPayedAmount = {
                                    wholePart: getLoanByIdForCustomerResponse.payedAmount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }

                    // =========STATISTICS=========
                    getLoanByIdForCustomerResponse.restAmountByPercent = Math.round(getLoanByIdForCustomerResponse.rest * 100 / getLoanByIdForCustomerResponse.loanAmount);
                    getLoanByIdForCustomerResponse.payedAmountByPercent = Math.round(getLoanByIdForCustomerResponse.payedAmount * 100 / getLoanByIdForCustomerResponse.loanAmount);
                    this.setState({ credit: getLoanByIdForCustomerResponse });
                    fetch(request("loan/loanPaymentList" + customerType, getLoanByIdForCustomerRequestBody))
                        .then(res => res.text())
                        .then((getLoanByIdForCustomerResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let getLoanByIdForCustomerResponse = JSON.parse(getLoanByIdForCustomerResponseJSON);
                            if (getLoanByIdForCustomerResponse.status.statusCode !== 1 && getLoanByIdForCustomerResponse.status.statusCode !== 134) {
                                if (getLoanByIdForCustomerResponse.status.statusCode === 200) {
                                    this.props.logout();
                                }
                                else {
                                    this.setState({ isLoading: false });
                                }
                            }
                            else {
                                for (let i = 0; i < getLoanByIdForCustomerResponse.paymentList.length; i++) {
                                    getLoanByIdForCustomerResponse.paymentList[i].organizedCardNumber =
                                        getLoanByIdForCustomerResponse.paymentList[i].cardNumber.substring(0, 4)
                                        + " **** **** " + getLoanByIdForCustomerResponse.paymentList[i].cardNumber.substring(12, 16);
                                    // =========AMOUNT=========
                                    if (getLoanByIdForCustomerResponse.paymentList[i].amount.toString() === "0") {
                                        getLoanByIdForCustomerResponse.paymentList[i].organizedAmount = {
                                            wholePart: "0",
                                            fractionalPart: "00"
                                        }
                                    }
                                    else {
                                        if (getLoanByIdForCustomerResponse.paymentList[i].amount.toString().indexOf('.') !== -1) {
                                            let amountArray = getLoanByIdForCustomerResponse.paymentList[i].amount.toString().split('.');
                                            getLoanByIdForCustomerResponse.paymentList[i].organizedAmount = {
                                                wholePart: amountArray[0],
                                                fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                            }
                                        }
                                        else {
                                            let amountArray = getLoanByIdForCustomerResponse.paymentList[i].amount.toString().split('.');
                                            getLoanByIdForCustomerResponse.paymentList[i].organizedAmount = {
                                                wholePart: getLoanByIdForCustomerResponse.paymentList[i].amount.toString(),
                                                fractionalPart: "00"
                                            }
                                        }
                                    }
                                    this.setState({ statements: getLoanByIdForCustomerResponse.paymentList });
                                    this.setState({ isLoading: false });
                                }
                                this.setState({ statements: getLoanByIdForCustomerResponse.paymentList });
                            }

                        },
                            (error) => {
                                this.props.history.push("/technical-break");

                            });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");

                });
    }

    loading(val) {
        this.props.updateAppState('isLoading', val);
    }
    showCreditProduct = () => {
        window.open('http://www.turanbank.az/' + localStorage.getItem('i18nextLng').toLowerCase() + '/pages/22/90', "_blank");
    }
    componentDidMount() {
        this.showCredit();
    }

    render() {
        let { t } = this.props;
        let { credit } = this.state;
        let { openCreditSchedule, openCreditPayment, isLoading, statements } = this.state;
        return (
            <>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{credit ? credit.loanType && credit.loanType : t('loans')}</h2>
                    </div>
                    <Link to="/dashboard/orders/credit" className="dashboard-header-route-link">{t('ORDER A CREDIT')}</Link>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !credit ?
                            <NotFound />
                            :
                            <>
                                <CreditSchedule {...this.props} open={openCreditSchedule} id={this.props.match.params.id} onClose={() => this.setState({ openCreditSchedule: false })} />
                                <CreditPayment {...this.props} open={openCreditPayment} id={this.props.match.params.id} onClose={() => this.setState({ openCreditPayment: false })} />
                                <div className="dashboard-container-main-section">
                                    <div className="dashboard-finance-section-card-section-details-section">
                                        <div className="dashboard-finance-section-card-section-details-section-background">
                                            <div className="dashboard-finance-section-card-section-details-section-header">
                                                {
                                                    credit ?
                                                        <div className="dashboard-finance-section-card-section-details-section-header-left-side">
                                                            <img src={creditIcon} className="dashboard-finance-section-card-section-details-section-header-image" alt="accountIcon" />
                                                            <h3 className="dashboard-finance-section-card-section-details-section-header-card-number">{credit.iban}</h3>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    credit.organizedLoanAmount ?
                                                        <div className="dashboard-finance-section-card-section-details-section-header-right-side">
                                                            <span className={"dashboard-finance-section-card-section-details-section-header-amount-currency dashboard-finance-section-card-section-details-section-header-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                            <span className="dashboard-finance-section-card-section-details-section-header-amount-whole-part">{credit.organizedLoanAmount.wholePart}</span>
                                                            <span className="dashboard-finance-section-card-section-details-section-header-amount-fractional-part">{credit.organizedLoanAmount.fractionalPart}</span>
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                            <div className="dashboard-finance-section-card-section-details-section-content">
                                                <div className="dashboard-finance-section-card-section-details-section-content-card">
                                                    {
                                                        credit ?
                                                            <img src={cardBlueBackground} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="cardBlueBackground" />
                                                            :
                                                            <img src={cardTransparentBackground} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="cardTransparentBackground" />
                                                    }
                                                    <div className="dashboard-finance-section-card-section-details-section-content-card-item">
                                                        {
                                                            credit.annualInterestRate ?
                                                                <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-number-section">
                                                                    <h4 className="dashboard-finance-section-card-section-details-section-content-card-item-card-credit-type">{credit.annualInterestRate}% {credit.loanType}</h4>
                                                                    <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-number-title">{t('credit type')}</p>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            credit.currency ?
                                                                <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-details-section">
                                                                    <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-owner-section">
                                                                        <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-owner-name">{credit.closeDate}</p>
                                                                        <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-owner-title">{t("expiration date")}</p>
                                                                    </div>
                                                                    <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-valid-section">
                                                                        <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-valid-date">
                                                                            <span className={"dashboard-finance-section-card-section-details-section-header-amount-currency dashboard-finance-section-card-section-details-section-header-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                            <span className="dashboard-finance-section-card-section-details-section-header-amount-whole-part">{credit.organizedLoanAmount.wholePart}</span>
                                                                            <span className="dashboard-finance-section-card-section-details-section-header-amount-fractional-part">{credit.organizedLoanAmount.fractionalPart}</span>
                                                                        </p>
                                                                        <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-valid-title">{t('loan amount')}</p>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="dashboard-finance-section-card-section-details-section-content-button-section">
                                                    {
                                                        // TODO
                                                        credit.creditSourceType === 0 &&
                                                        <Link to={`/dashboard/finance/credits/pay/${this.props.match.params.id}/${this.props.match.params.iban}`} className="dashboard-finance-section-card-section-details-section-content-button-container">
                                                            <button className="dashboard-finance-section-card-section-details-section-content-button">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M14.518 0.143901L0.505898 8.20172C-0.213471 8.60774 -0.150917 9.66962 0.599729 9.98194L5.00977 11.7934V14.979C5.00977 15.9472 6.16702 16.3532 6.76128 15.6349L8.66917 13.3237L12.6101 14.9478C13.2043 15.1977 13.8924 14.8229 13.9862 14.167L15.988 1.14332C16.1131 0.331292 15.2373 -0.293345 14.518 0.143901ZM6.01063 14.979V12.2306L7.69959 12.9177L6.01063 14.979ZM13.0167 14.0421L6.54234 11.3561L12.7977 4.01665C12.9541 3.82926 12.7039 3.57941 12.5162 3.73557L4.54062 10.5441L1.00633 9.07621L15.0184 0.987162L13.0167 14.0421Z" fill="#849097" />
                                                                </svg>
                                                            </button>
                                                            <p className="dashboard-finance-section-card-section-details-section-content-title">{t('make a payment')}</p>
                                                        </Link>
                                                    }
                                                    <button onClick={() => { this.props.updateAppState('isLoadingTransparentBackground', true); setTimeout(() => this.setState({ openCreditSchedule: true }), 800) }} className="dashboard-finance-section-card-section-details-section-content-button-container">
                                                        <div className="dashboard-finance-section-card-section-details-section-content-button">
                                                            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.8752 3.67164C16.0312 3.50882 16.0312 3.28087 15.8752 3.11805L13.0044 0.122117C12.8484 -0.0407056 12.5987 -0.0407056 12.4739 0.122117L12.2243 0.350068C12.0995 0.51289 12.0995 0.740841 12.2243 0.903664L14.1277 2.85753H0.397855C0.179425 2.85753 0.0234032 3.05292 0.0234032 3.2483V3.50882C0.0234032 3.73677 0.179425 3.89959 0.397855 3.89959H14.1277L12.2243 5.88602C12.0995 6.04885 12.0995 6.2768 12.2243 6.43962L12.4739 6.66757C12.5987 6.83039 12.8484 6.83039 13.0044 6.66757L15.8752 3.67164ZM0.117016 8.88195L3.01902 11.8779C3.14383 12.0407 3.39347 12.0407 3.51828 11.8779L3.76792 11.6499C3.89274 11.4871 3.89274 11.2592 3.76792 11.0963L1.86446 9.10991H15.6255C15.8128 9.10991 16 8.94708 16 8.71913V8.45862C16 8.26323 15.8128 8.06784 15.6255 8.06784H1.86446L3.76792 6.11398C3.89274 5.95115 3.89274 5.7232 3.76792 5.56038L3.51828 5.33243C3.39347 5.16961 3.14383 5.16961 3.01902 5.33243L0.117016 8.32836C-0.0390054 8.49118 -0.0390054 8.71913 0.117016 8.88195Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                        <p className="dashboard-finance-section-card-section-details-section-content-title">{t('payment schedule')}</p>
                                                    </button>
                                                    <button onClick={() => { this.props.updateAppState('isLoadingTransparentBackground', true); setTimeout(() => this.setState({ openCreditPayment: true }), 800) }} className="dashboard-finance-section-card-section-details-section-content-button-container">
                                                        <div className="dashboard-finance-section-card-section-details-section-content-button">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                        <p className="dashboard-finance-section-card-section-details-section-content-title">{t('payments')}</p>
                                                    </button>
                                                </div>
                                                <ul className="dashboard-finance-section-card-section-details-section-content-list">
                                                    <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('currency')}</p>
                                                        {
                                                            credit ?
                                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{credit.currency}</p>
                                                                : null
                                                        }
                                                    </li>
                                                    <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('name of the branch')}</p>
                                                        {
                                                            credit ?
                                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{credit.branch}</p>
                                                                : null
                                                        }
                                                    </li>
                                                    <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('Penalty amount')}</p>
                                                        {
                                                            credit.currency ?
                                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">
                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedPenaltyRest.wholePart}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedPenaltyRest.fractionalPart}</span>
                                                                </p>
                                                                : null
                                                        }
                                                    </li>
                                                    <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('Interest debt to date')}</p>
                                                        {
                                                            credit.currency ?
                                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">
                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedInterestRate.wholePart}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedInterestRate.fractionalPart}</span>
                                                                </p>
                                                                : null
                                                        }

                                                    </li>
                                                    <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('Required amount of principal balance payment - 5%')}</p>
                                                        {
                                                            credit.currency ?
                                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">
                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedMinPaymentAmount.wholePart}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedMinPaymentAmount.fractionalPart}</span>
                                                                </p>
                                                                : null
                                                        }
                                                    </li>
                                                    <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('Balance on principal debt')}</p>
                                                        {
                                                            credit.currency ?
                                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">
                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedRest.wholePart}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedRest.fractionalPart}</span>
                                                                </p>
                                                                : null
                                                        }
                                                    </li>
                                                    <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('Opening date')}</p>
                                                        {
                                                            credit ?
                                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{credit.openDate}</p>
                                                                : null
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-finance-section-card-section-operation-section">
                                        <div className="dashboard-card-section">
                                            <div className="dashboard-card-section-background">
                                                <div className="dashboard-finance-section-card-section-details-section-header">
                                                    <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('payments')} </h3>
                                                </div>
                                                <div className="dashboard-last-operations-section-content">
                                                    {
                                                        statements.length === 0 ?
                                                            <>
                                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                                <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active payment.')}</p>
                                                            </>
                                                            :
                                                            <ul className="dashboard-last-operations-section-list">
                                                                {
                                                                    statements.slice(0, 10).map((statement, index) =>
                                                                        <li className="dashboard-last-operations-section-item" key={index}>
                                                                            <div className="dashboard-last-operations-section-item-left-side">
                                                                                <div className="dashboard-last-operations-section-item-icon-container">
                                                                                    <svg className="dashboard-last-operations-section-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="dashboard-last-operations-section-item-content">
                                                                                    <div className="dashboard-last-operations-section-item-company">{statement.organizedCardNumber}, {statement.receiptNumber}</div>
                                                                                    <div className="dashboard-last-operations-section-item-description">{statement.merchantName}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dashboard-last-operations-section-item-rigth-side">
                                                                                <div className="dashboard-last-operations-section-item-amount">
                                                                                    <span className="dashboard-last-operations-section-item-amount-whole-part">{statement.organizedAmount && statement.organizedAmount.wholePart}</span>
                                                                                    <span className="dashboard-last-operations-section-item-amount-fractional-part">{statement.organizedAmount && statement.organizedAmount.fractionalPart}</span>
                                                                                    <span className="dashboard-last-operations-section-item-amount-currency">{statement.currency}</span>
                                                                                </div>
                                                                                <p className="dashboard-last-operations-section-item-category">{statement.billingDate.split(' ')[0]}</p>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard-account-section">
                                            <div className="dashboard-finance-section-card-section-details-section-header">
                                                <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('Statistics')} </h3>
                                                {/* <button className="dashboard-finance-section-card-section-details-section-header-show-all dashboard-last-operations-section-header-history">{t('all history')}</button> */}
                                            </div>
                                            <div className="dashboard-last-operations-section-content">
                                                <div className="dashboard-statistics-section">
                                                    <div className="dashboard-statistics-section-content dashboard-statistics-section-content--left-side">
                                                        <ul className="dashboard-statistics-section-content-list">
                                                            <li className="dashboard-statistics-section-content-list-item">
                                                                <span className="dashboard-statistics-section-content-list-item-color dashboard-statistics-section-content-list-item-color__rest"></span>
                                                                <span className="dashboard-statistics-section-content-list-item-title">{t('Balance on principal debt')}</span>
                                                            </li>
                                                            <li className="dashboard-statistics-section-content-list-item">
                                                                <span className="dashboard-statistics-section-content-list-item-color dashboard-statistics-section-content-list-item-color__paid"></span>
                                                                <span className="dashboard-statistics-section-content-list-item-title">{t('amount paid')}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="dashboard-statistics-section-content dashboard-statistics-section-content--right-side">
                                                        {
                                                            // credit.restAmountByPercent ?
                                                            credit && credit.organizedRest && credit.organizedPayedAmount &&
                                                            <PieChart
                                                                animation
                                                                animationDuration={500}
                                                                animationEasing="ease-out"
                                                                center={[50, 50]}
                                                                label={({ dataEntry }) => {
                                                                    return (credit.currencyIcon + dataEntry.label);
                                                                }}
                                                                // label={({ dataEntry }) => (<span className="dashboard-statistics-section-content-title">{credit.currencyIcon + dataEntry.label}</span>)}
                                                                labelStyle={{
                                                                    fontSize: "6px",
                                                                    fill: "#FFFFFF",
                                                                    padding: "6px 16px",
                                                                }}
                                                                labelPosition={80}
                                                                data={[
                                                                    { label: credit.organizedRest.wholePart + "." + credit.organizedRest.fractionalPart, value: credit.restAmountByPercent, color: '#FFAB2B' },
                                                                    { label: credit.organizedPayedAmount.wholePart + "." + credit.organizedPayedAmount.fractionalPart, value: credit.payedAmountByPercent, color: '#0179D2' },
                                                                ]}
                                                                lengthAngle={360}
                                                                lineWidth={40}
                                                                paddingAngle={0}
                                                                radius={50}
                                                                startAngle={0}
                                                                viewBoxSize={[100, 100]}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                }
            </>
        )
    }
}
export default translate("translation")(Credit);
