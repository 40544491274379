import React from "react";

import "./AuthHeader.scss";

const AuthHeader = ({ title, desc }) => {
  return (
    <div className="ibank-auth__header">
      <p className="ibank-auth__header__title">{title}</p>
      {desc &&
        desc.map((item, index) => {
          return (
            <p key={index} className="ibank-auth__header__desc">
              {item}
            </p>
          );
        })}
    </div>
  );
};

export default AuthHeader;
