import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function SendRepeatMail(props) {
    const classes = useStyles();

    return (
        <Modal
            open={props.open}
            onClose={props.close}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            classes={classes.modal}
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description">
            <Fade in={props.open}>
                <div className={classes.paper}>
                    {
                        <div className="dashboard-change-account-name-section-modal">
                            <button className="dashboard-change-account-name-section-modal-close-button" onClick={props.close}>
                                <svg className="dashboard-change-account-name-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                </svg>
                            </button>
                            <div className="dashboard-change-account-name-section-modal-content" style={{ marginTop: 32 }}>
                                <p className="dashboard-change-account-name-section-modal-content-details">{props.t('Enter your email address')}</p>
                                <form className="dashboard-change-account-name-section-modal-content-form" style={{ maxWidth: 332 }}>
                                    <input type='email' value={props.email} onChange={props.updateEmail} className="dashboard-change-account-name-section-modal-content-form-input" placeholder={props.t('Enter your email address')} />
                                    <div className="dashboard-change-account-name-section-modal-content-form-button-section">
                                        <button type='button' disabled={props.emailValidation == 0} className="dashboard-change-account-name-section-modal-content-form-button dashboard-change-account-name-section-modal-content-form-button--right-side" onClick={() => { props.sendEmail() }}>{props.t('continue')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
            </Fade>
        </Modal>
    )
}

export default translate("translation")(SendRepeatMail);