import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import headerLogo from "./../../Registration/img/header-logo.svg";
import forgotPasswordIcon from "./../../Registration/img/forgot-password.svg";
import { translate } from 'react-i18next';

class SuccessChange extends Component {
    state = {
        customerType: 'Individual'
    }
    backLogin = (event) => {
        event.preventDefault();
        if(this.state.customerType === 'Juridical') {
            this.props.history.push('/login/juridical/mobile');
        }
        else {
            this.props.history.push('/login/individual/mobile');
        }
    }
    componentDidMount() {
        if(this.props.location.state === undefined) {
            if(this.props.match.url === '/login/juridical/mobile/forgot-password/4') {
                this.props.history.push('/login/juridical/mobile/forgot-password/1');
            }
            else {
                this.props.history.push('/login/individual/mobile/forgot-password/1');
            }
        }
        else {
            if(this.props.match.url === '/login/juridical/mobile/forgot-password/4') {
                this.setState({customerType: 'Juridical'});
            }
            if(this.props.match.url === '/login/individual/mobile/forgot-password/4') {
                this.setState({customerType: 'Individual'});
            }
        }
    }
    render() {
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title" style={{textAlign: 'center'}}>{t('successful operation')}</h2>
                    <p className="registration-container-description">{t('your password has been successfully changed. Please return to the main page to continue operations')}</p>
                    <img src={forgotPasswordIcon} className="registration-container-icon" alt="forgotPasswordIcon" />
                    {/* <p className="registration-container-description registration-container-description--center">{t('your password has been successfully changed. Please return to the main page to continue operations')}</p> */}
                    <div className="registration-container-footer">
                        <button className="registration-container-footer-button registration-container-footer-button__forgot-password--center" onClick={this.backLogin}>{t('home')}</button>
                    </div>
                </form>
            </div>
        )
    }
}


export default translate("translation")(SuccessChange);