import React, { Component } from 'react';
import userGuide from './../../img/user-guide.png';
import { translate } from 'react-i18next';

class UserGuide extends Component {

    componentDidMount() {
        this.props.updateSettingsState('settingsTitle', null);
    }

    render() {
        let { t } = this.props;
        return (
            <div className="dashboard-settings-section-other-settings">
                <img src={userGuide} className="dashboard-settings-section-other-settings-image" />
                <h3 className="dashboard-settings-section-other-settings-title">{t('instructions for use title')}</h3>
                <div className="dashboard-settings-section-other-settings-content">
                    <p>{t('instructions for use content')}</p>
                </div>
            </div>
        )
    }
}


export default translate("translation")(UserGuide);