import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import BlockCard from '../../components/Dashboard/CardModal/BlockCard/BlockCard';
import UnblockCard from '../../components/Dashboard/CardModal/UnblockCard/UnblockCard';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'inline-block',
    maxHeight: '100vh',
    overflow: 'auto'
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [feedbackModal, setFeedbackModal] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);

  const close = () => {
    setOpen(false);
    setFeedbackModal(false);
    setErrorModal(false);
    props.updateOpenModal(false);
    if(props.showCards !== undefined && feedbackModal === true) {
      props.showCards();
    }
    if(props.showCard !== undefined && feedbackModal === true) {
      props.showCard();
    }
  }
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
      <Modal
        open={open}
        onClose={close}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        classes={classes.modal}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description">
        <Fade in={open}>
          <div className={classes.paper}>
            {
              props.modalCardObj.cardStatus === 1 ?
              <BlockCard close={close} updateAppState={props.updateAppState} modalCardObj={props.modalCardObj} feedbackModal={feedbackModal} setFeedbackModal={setFeedbackModal} errorModal={errorModal} setErrorModal={setErrorModal} showCards={props.showCards}/>
              :
              <UnblockCard close={close} updateAppState={props.updateAppState} modalCardObj={props.modalCardObj} feedbackModal={feedbackModal} setFeedbackModal={setFeedbackModal} errorModal={errorModal} setErrorModal={setErrorModal} showCards={props.showCards}/>
            }
          </div> 
        </Fade>
      </Modal>
  );
}