import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { translate } from 'react-i18next';
import NotFound from './../../../copms/NotFound/NotFound';
import Payments from './Payments/Payments';
import Provider from './Payments/Provider/Provider';
import Templates from './Templates/Templates';
import NewTemplate from './Templates/NewTemplate/NewTemplate';

import History from './History/History';
import Categorys from './Categorys/Categorys';
import Category from './Category/Category';
import CreateTemplate from './Templates/NewTemplate/CreteTemplate';
import TemplatePay from './Templates/TemplatePay/TemplatePay';


class Payment extends Component {
    render() {
        let { updateAppState, updateDashboardState, alertState, logout } = this.props;
        return (
            <Switch>
                {/* <Redirect exact from="/dashboard/payment" to="/dashboard/payment/payments"/>
                <Route exact path="/dashboard/payment/payments" render={(routeProps) => <Payments logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/>  } />
                <Route exact path="/dashboard/payment/payments/categories" render={(routeProps) => <Categorys logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/>  } />
                <Route exact path="/dashboard/payment/payments/categories/:id" render={(routeProps) => <Category logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/>  } />
                <Route exact path="/dashboard/payment/payments/categories/:id/providers/:merchant" render={(routeProps) => <Provider logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/>  } /> */}

                <Redirect exact from="/dashboard/payment" to="/dashboard/payment/payments" />

                <Route path="/dashboard/payment/payments/categories/:id/providers/:merchant" render={(routeProps) => <Provider logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/payments/categories/:id" render={(routeProps) => <Category logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/payments/categories" render={(routeProps) => <Categorys logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/payments" render={(routeProps) => <Payments logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                {/* =============WITH BONUS============= */}
                <Route path="/dashboard/payment/bonus/payments/categories/:id/providers/:merchant" render={(routeProps) => <Provider bonus={true} logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/bonus/payments/categories/:id" render={(routeProps) => <Category bonus={true} logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/bonus/payments/categories" render={(routeProps) => <Categorys bonus={true} logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/bonus/payments" render={(routeProps) => <Payments bonus={true} logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                <Route path="/dashboard/payment/templates/template-pay/:id/providers/:merchant/temp/:temp" render={(routeProps) => <TemplatePay logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/templates/new-template/categories/:id/providers/:merchant" render={(routeProps) => <CreateTemplate logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/templates/new-template/categories/:id" render={(routeProps) => <NewTemplate logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/templates/new-template" render={(routeProps) => <NewTemplate logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/templates" render={(routeProps) => <Templates logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                {/* =============WITH BONUS============= */}
                <Route path="/dashboard/payment/bonus/templates/template-pay/:id/providers/:merchant/temp/:temp" render={(routeProps) => <TemplatePay bonus={true} logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/payment/bonus/templates" render={(routeProps) => <Templates bonus={true} logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                <Route exact path="/dashboard/payment/history" render={(routeProps) => <History logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route render={(routeProps) => (<NotFound />)} />
            </Switch>
        )
    }
}


export default translate("translation")(Payment);