import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import accountIcon from './../../img/account-icon.svg';
import cardsIcon from './../../img/cards-icon.png';
import { request } from './../../../../config';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import Modal from './../../../../copms/Modal/AccountModal.js';
import { translate } from 'react-i18next';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';
import $ from 'jquery';

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

class Accounts extends Component {
    state = {
        accounts: [],
        totalAmountAZN: 0,
        totalAmountUSD: 0,
        totalAmountEUR: 0,
        open: false,
        modalAccountObj: {},
        isLoading: false
    }
    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    numberToString = (number) => {
        if (number.toString() === "0") {
            return "0.00"
        }
        else {
            if (number.toString().indexOf('.') !== -1) {
                let numberArray = number.toString().split('.');
                let x = (numberArray[1].length === 1) ? (numberArray[1] + '0') : numberArray[1];
                return numberArray[0] + "." + x;
            }
            else {
                return number.toString() + ".00"
            }
        }
    }
    dropdownItemHandler = (index, account) => {
        switch (index) {
            case 1:
                this.showRequisites(account.accountId);
                break;
            case 2:
                this.setState({
                    open: true,
                    modalAccountObj: account
                });
                break;
        }
    }
    showAccounts = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                isBalanceAccount: 0
            }

        fetch(request("account/getAccountListFor" + customerType + "Customer", getAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                if (getAccountListForCustomerResponse.status.statusCode !== 1 && getAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getAccountListForCustomerResponse.status.statusCode === 126) {
                        getAccountListForCustomerResponse.accountList = [];
                    }
                    let totalAmount = {
                        azn: 0,
                        usd: 0,
                        eur: 0
                    }
                    for (let i = 0; i < getAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        getAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getAccountListForCustomerResponse.accountList[i].currName);
                        // =========TOTALAMOUNT=========
                        if (getAccountListForCustomerResponse.accountList[i].currName === "AZN") {
                            totalAmount.azn += getAccountListForCustomerResponse.accountList[i].currentBalance;
                        }
                        if (getAccountListForCustomerResponse.accountList[i].currName === "USD") {
                            totalAmount.usd += getAccountListForCustomerResponse.accountList[i].currentBalance;
                        }
                        if (getAccountListForCustomerResponse.accountList[i].currNames === "EUR") {
                            totalAmount.eur += getAccountListForCustomerResponse.accountList[i].currentBalance;
                        }
                    }
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });
                    this.setState({ accounts: getAccountListForCustomerResponse.accountList });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showRequisites = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountRequisiteIndividualByteArrayRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("account/getAccountRequisite" + customerType + "ByteArray", getAccountRequisiteIndividualByteArrayRequestBody))
            .then(res => res.json())
            .then((getAccountRequisiteByteArrayResponse) => {
                if (getAccountRequisiteByteArrayResponse.status.statusCode !== 1) {
                    if (getAccountRequisiteByteArrayResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(getAccountRequisiteByteArrayResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Account_Requisites') + "_" + this.getFormatedDate(this.state.startDate) + "-" + this.getFormatedDate(this.state.endDate) + ".pdf", "application/pdf", arrrayBuffer)
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }
    componentDidMount() {
        this.showAccounts();
    }
    render() {
        let { accounts, dropdownItems, totalAmountAZN, totalAmountUSD, totalAmountEUR, open, modalAccountObj, isLoading } = this.state;
        let { t, updateAppState } = this.props;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('accounts')}</h2>
                    </div>
                    {/* <a href="http://turanbank.az/" className="dashboard-header-route-link">{t('ORDER A NEW CARD')}</a> */}
                </div>
                <Modal open={open} modalAccountObj={modalAccountObj} updateOpenModal={this.updateOpenModal} updateAppState={updateAppState} showAccounts={this.showAccounts} />
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            (accounts.length === 0) ?
                                <div className="dashboard-finance-section-invisible-cards-section">
                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have an active account.')}</p>
                                        {/* <p className="dashboard-finance-section-invisible-cards-section-text">{t('if you want a new account, please order.')}</p> */}
                                    </div>

                                </div>
                                :
                                <div className="dashboard-finance-section-cards-section">
                                    <div className="dashboard-finance-section-cards-section-statistics-section">

                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon dashboard-finance-section-cards-section-statistics-icon__azn">₼</span>{totalAmountAZN}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("account balance")} (AZN)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#0066B2" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#0066B2" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">

                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">$</span>{totalAmountUSD}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("account balance")} (USD)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#6DD230" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#6DD230" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">€</span>{totalAmountEUR}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("account balance")} (EUR)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#FFAB2B" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FFAB2B" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-finance-section-cards-section-table-section">
                                        <table className="dashboard-finance-section-cards-section-table">
                                            <tbody>
                                                {
                                                    accounts.map((account, index) =>
                                                        <Fragment key={index}>
                                                            <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <img src={accountIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                    <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                        <Link to={"/dashboard/finance/accounts/" + account.accountId} className="dashboard-finance-section-cards-section-cell-content">{account.accountName}</Link>
                                                                        <Link to={"/dashboard/finance/accounts/" + account.accountId} className="dashboard-finance-section-cards-section-cell-title">{account.iban}</Link>
                                                                    </div>

                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content">
                                                                        <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + account.currName.toLowerCase()}>{account.currencyIcon}</span>
                                                                        <span className="dashboard-finance-section-cards-section-amount-whole-part">{account.organizedCurrentBalance.wholePart}</span>
                                                                        <span className="dashboard-finance-section-cards-section-amount-fractional-part">{account.organizedCurrentBalance.fractionalPart}</span>
                                                                    </p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t('balance')}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <div className="dashboard-finance-section-cards-section-button-group">
                                                                        <Dropdown
                                                                            dropdownItems={[
                                                                                { title: this.props.t('extract'), url: "/dashboard/finance/accounts/extract/" + account.accountId },
                                                                                { title: this.props.t('details') },
                                                                                { title: this.props.t('change name') },
                                                                            ]}
                                                                            dropdownItemHandler={(i) => this.dropdownItemHandler(i, account)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr className="dashboard-finance-row-line">
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>

                                                            </tr>
                                                        </Fragment>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                    }
                </div>
            </Fragment>
        )
    }
}

export default translate("translation")(Accounts);