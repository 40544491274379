import azn from './img/aze.svg';
import eur from './img/eur.png';
import aed from './img/aed.svg';
import chf from './img/chf.svg';
import cny from './img/cny.svg';
import dem from './img/dem.svg';
import frf from './img/frf.svg';
import gbp from './img/gbp.svg';
import gel from './img/gel.svg';
import jyp from './img/jyp.svg';
import rub from './img/rub.svg';
import tr from './img/try.svg';
import usd from './img/usd.svg';

const lists = [
    {
        currencyName: 'AZN',
        iconName: azn,
    },
    {
        currencyName: 'AED',
        iconName: aed,
    },
    {
        currencyName: 'CHF',
        iconName: chf,
    },
    {
        currencyName: 'CNY',
        iconName: cny,
    },
    {
        currencyName: 'DEM',
        iconName: dem,
    },
    {
        currencyName: 'FRF',
        iconName: frf,
    },
    {
        currencyName: 'GBP',
        iconName: gbp,
    },
    {
        currencyName: 'GEL',
        iconName: gel,
    },
    {
        currencyName: 'JYP',
        iconName: jyp,
    },
    {
        currencyName: 'RUB',
        iconName: rub,
    },
    {
        currencyName: 'TRY',
        iconName: tr,
    },
    {
        currencyName: 'USD',
        iconName: usd,
    },
    {
        currencyName: 'EUR',
        iconName: eur,
    },
]


function flagFilter(currency) {
    return lists.find(elem => elem.currencyName === currency) ? lists.find(elem => elem.currencyName === currency).iconName : "";
}

export default flagFilter;