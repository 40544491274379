import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { translate } from "react-i18next";

import "./DigitalCardRulesModal.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
    width: "50%",
  },
}));

const DigitalCardRulesModal = ({
  open,
  translate,
  toggleDigitalCardRulesModal,
  digitalCardRulesPdf,
  handleAgreeWithRules,
  handleDisagreeWithRules,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={toggleDigitalCardRulesModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      classes={classes.modal}
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="dashboard-card-modal dashboard-digital-card-rules-modal">
            <object
              data={`${digitalCardRulesPdf}#toolbar=0`}
              type="application/pdf"
              width="100%"
            ></object>
            <div className="dashboard-close-modal">
              <button
                type="button"
                className="disagree-btn"
                onClick={handleDisagreeWithRules}
              >
                {translate("i do not agree")}
              </button>
              <button
                type="button"
                className="agree-btn"
                onClick={handleAgreeWithRules}
              >
                {translate("agree")}
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default translate("translation")(DigitalCardRulesModal);
