import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next';

import empty from './../../../img/last-operations-icon.png';
import { request } from "./../../../../../config";
import PaymentModalError from '../../../../../copms/Modal/PaymentModalError';
import TemplateModalSuccess from '../../../../../copms/Modal/TemplateModalSuccess';
import NotFound from '../../../../../copms/NotFound/NotFound';
import Loading from './../../../Loading/Loading';
import {roundNumberWithDecimal} from '../../../../../helpers/payments'

class CreateTemplate extends Component {
    state = {
        dropdownVisibility: false,
        userInfo: null,
        cards: [],
        selectedCard: 0,
        selectedValue: -1,
        selectedIndex: -1,
        selectedShow: false,
        checkPayment: true,
        checkPaymentInvoice: [],
        selectedPaymentType: 0,
        selectedPaymentIndex: -1,
        checkPaymentAvans: [],
        allDisabled: false,
        step: 1,
        items: [],
        transactionId: null,
        succesModal: false,
        succesModalData: null,
        errorModal: false,
        errorModalData: null,
        avansIndex: -1,
        invoiceIndex: -1,
        temp_name: '',
        isLoading: false
    }

    componentWillMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        let getMerchantCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            merchantId: this.props.match.params.merchant,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            merchantId: this.props.match.params.merchant,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("pg/getMerchantParams" + customerType, getMerchantCustomerRequestBody))
            .then(res => res.text())
            .then((getMerchantCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getMerchantCustomerResponse = JSON.parse(getMerchantCustomerResponseJSON);

                if (getMerchantCustomerResponse.status.statusCode !== 1) {
                    if (getMerchantCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    var items = [];
                    for (var i = 0; i < getMerchantCustomerResponse.merchantParamList.length; i++) {

                        if (items.find(el => el.merchantDescName === getMerchantCustomerResponse.merchantParamList[i].merchantDescName)) {
                            getMerchantCustomerResponse.merchantParamList[i].params = [];
                            getMerchantCustomerResponse.merchantParamList[i].isShow = false;
                            if (getMerchantCustomerResponse.merchantParamList[i].paramValue && getMerchantCustomerResponse.merchantParamList[i].paramValue !== "" && getMerchantCustomerResponse.merchantParamList[i].paramValue !== ",") {
                                var paramArr = getMerchantCustomerResponse.merchantParamList[i].paramValue.split(',');
                                var paramLabelArr = getMerchantCustomerResponse.merchantParamList[i].paramDesc.split(',');
                                for (var j = 0; j < paramArr.length - 1; j++) {
                                    if (j == 0) {
                                        getMerchantCustomerResponse.merchantParamList[i].defaultSelect = paramArr[j];
                                    }
                                    getMerchantCustomerResponse.merchantParamList[i].params.push({
                                        value: paramArr[j],
                                        label: paramLabelArr[j]
                                    })
                                }
                            }
                            items[items.findIndex(el => el.merchantDescName = getMerchantCustomerResponse.merchantParamList[i].merchantDescName)].elements.push(getMerchantCustomerResponse.merchantParamList[i]);
                        } else {
                            getMerchantCustomerResponse.merchantParamList[i].params = [];
                            getMerchantCustomerResponse.merchantParamList[i].isShow = false;
                            if (getMerchantCustomerResponse.merchantParamList[i].paramValue && getMerchantCustomerResponse.merchantParamList[i].paramValue !== "" && getMerchantCustomerResponse.merchantParamList[i].paramValue !== ",") {
                                var paramArr = getMerchantCustomerResponse.merchantParamList[i].paramValue.split(',');
                                var paramLabelArr = getMerchantCustomerResponse.merchantParamList[i].paramDesc.split(',');
                                for (var j = 0; j < paramArr.length - 1; j++) {
                                    if (j == 0) {
                                        getMerchantCustomerResponse.merchantParamList[i].defaultSelect = paramArr[j];
                                    }
                                    getMerchantCustomerResponse.merchantParamList[i].params.push({
                                        value: paramArr[j],
                                        label: paramLabelArr[j]
                                    })
                                }
                            }
                            items.push({
                                merchantDescName: getMerchantCustomerResponse.merchantParamList[i].merchantDescName,
                                isShow: false,
                                elements: [getMerchantCustomerResponse.merchantParamList[i]]
                            })
                        }

                    }

                    this.setState({ items: items, selectedIndex: 0 })
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });


        fetch(request("card/getCardListFor" + customerType + "Customer", getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }

                    this.setState({ cards: getCardListForCustomerResponse.cardList, selectedValue: getCardListForCustomerResponse.cardList && getCardListForCustomerResponse.cardList.length > 0 && 0 });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    changeDropdownVisibility = (event) => {
        event.preventDefault();
        if (this.state.step > 2) {
            this.setState({ dropdownVisibility: false });
        } else {
            this.setState({ dropdownVisibility: !this.state.dropdownVisibility });
        }
    }

    onSelect = (event, val) => {
        event.preventDefault();
        this.setState({ dropdownVisibility: false, selectedValue: val });
    }
    onChangeParamsDropdown = (e, index) => {
        e.preventDefault();
        var items = this.state.items;
        if (this.state.step > 1) {
            items[this.state.selectedIndex].elements[index].isShow = false;
        } else {
            items[this.state.selectedIndex].elements[index].isShow = !items[index].elements[index].isShow;
        }
        this.setState({ items: items })
    }
    onSelectParamsDropdown = (e, val, index) => {
        e.preventDefault();
        var items = this.state.items;
        items[this.state.selectedIndex].elements[index].defaultSelect = val;
        items[this.state.selectedIndex].elements[index].isShow = false;
        this.setState({ items: items })
    }

    onChnageInputDropdown = (val, index) => {
        var items = this.state.items;
        items[this.state.selectedIndex].elements[index].defaultInput = val;
        this.setState({ items: items })
    }

    onSubmitForm(e) {
        e.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var params = [];
        for (var i = 0; i < item.elements.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }
        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            identificationType: item.merchantDescName,
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item.elements[0].providerId,
            identificationCode: params,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            identificationType: item.merchantDescName,
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item.elements[0].providerId,
            identificationCode: params,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("pg/checkMerchantV1",
        //  + (customerType === 'Juridical' ? 'Juridical' : ''), 
         getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ checkPayment: true, checkPaymentInvoice: getCardListForCustomerResponse.invoice ? getCardListForCustomerResponse.invoice : [], checkPaymentAvans: getCardListForCustomerResponse.avans ? getCardListForCustomerResponse.avans : [], transactionId: getCardListForCustomerResponse.transactionId, step: 2, })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    createTemplate() {

        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var params = [];
        for (var i = 0; i < item.elements.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }
        let dataList = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item.elements[0].providerId,
            tempName: this.state.temp_name,
            identificationCode: params,
            identificationType: item.merchantDescName,

            amount: parseFloat(dataList.amount),
            currency :  this.state.cards[this.state.selectedValue].currency,
            reqPaymentDataList: [{
                amount: parseFloat(dataList.amount),
                transactionNumber: dataList.transactionNumber,
                parentTrNumber: dataList.transactionNumber,
                paymentReceiver: dataList.paymentReceiver,
                paymentReceiverDescription: dataList.paymentReceiverDescription,
                feeCalculationMethod: dataList.feeCalculationMethod,
                feeAmount: this.commissionCalculator(),
                subscriberName: dataList.fullName,
                serviceName: dataList.serviceName,
                budgetCode: dataList.serviceCode,
                invoiceNumber: dataList.invoiceNumber,
                identificationCode: params,
                identificationType: item.merchantDescName,
            }],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            transactionId: this.state.transactionId,
            merchantId: this.props.match.params.merchant,
            providerId: item.elements[0].providerId,
            currency :  this.state.cards[this.state.selectedValue].currency,
            tempName: this.state.temp_name,
            identificationCode: params,
            identificationType: item.merchantDescName,

            amount: parseFloat(dataList.amount),
            reqPaymentDataList: [{
                amount: parseFloat(dataList.amount),
                transactionNumber: dataList.transactionNumber,
                parentTrNumber: dataList.transactionNumber,
                paymentReceiver: dataList.paymentReceiver,
                paymentReceiverDescription: dataList.paymentReceiverDescription,
                feeCalculationMethod: dataList.feeCalculationMethod,
                feeAmount: this.commissionCalculator(),
                subscriberName: dataList.fullName,
                serviceName: dataList.serviceName,
                budgetCode: dataList.serviceCode,
                invoiceNumber: dataList.invoiceNumber,
                identificationCode: params,
                identificationType: item.merchantDescName,
            }],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("pg/createPaymentTemp" + customerType, getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {

                    this.setState({ checkPayment: true, succesModal: true, succesModalData: null, checkPaymentInvoice: getCardListForCustomerResponse.invoice ? getCardListForCustomerResponse.invoice : [], checkPaymentAvans: getCardListForCustomerResponse.avans ? getCardListForCustomerResponse.avans : [], step: 2, })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    createTemplateWithChild() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var params = [];
        for (var i = 0; i < item.elements.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }
        let dataList = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        var childArr = [];
        var totalAmout = 0.0;
        if (this.state.selectedPaymentType === 1) {
            for (var i = 0; i < dataList.respChildAvansList.length; i++) {
                totalAmout += parseFloat(dataList.respChildAvansList[i].amount);
                childArr.push({
                    amount: parseFloat(dataList.respChildAvansList[i].amount),
                    transactionNumber: dataList.respChildAvansList[i].transactionNumber,
                    parentTrNumber: dataList.transactionNumber,
                    paymentReceiver: dataList.respChildAvansList[i].paymentReceiver,
                    paymentReceiverDescription: dataList.respChildAvansList[i].paymentReceiverDescription,
                    feeCalculationMethod: dataList.respChildAvansList[i].feeCalculationMethod,
                    feeAmount: this.commissionChildCalculator(i),
                    subscriberName: dataList.respChildAvansList[i].fullName,
                    serviceName: dataList.respChildAvansList[i].serviceName,
                    budgetCode: dataList.respChildAvansList[i].serviceCode,
                    invoiceNumber: dataList.respChildAvansList[i].invoiceNumber,
                    identificationCode: params,
                    identificationType: item.merchantDescName,
                })
            }

        } else {
            for (var i = 0; i < dataList.respChildInvoiceList.length; i++) {
                totalAmout += parseFloat(dataList.respChildInvoiceList[i].amount);
                childArr.push({
                    amount: parseFloat(dataList.respChildInvoiceList[i].amount),
                    transactionNumber: dataList.respChildInvoiceList[i].transactionNumber,
                    parentTrNumber: dataList.transactionNumber,
                    paymentReceiver: dataList.respChildInvoiceList[i].paymentReceiver,
                    paymentReceiverDescription: dataList.respChildInvoiceList[i].paymentReceiverDescription,
                    feeCalculationMethod: dataList.respChildInvoiceList[i].feeCalculationMethod,
                    feeAmount: this.commissionChildCalculator(i),
                    subscriberName: dataList.respChildInvoiceList[i].fullName,
                    serviceName: dataList.respChildInvoiceList[i].serviceName,
                    budgetCode: dataList.respChildInvoiceList[i].serviceCode,
                    invoiceNumber: dataList.respChildInvoiceList[i].invoiceNumber,
                    identificationCode: params,
                    identificationType: item.merchantDescName,
                })
            }
        }

        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item.elements[0].providerId,
            tempName: this.state.temp_name,
            identificationCode: params,
            identificationType: item.merchantDescName,
            currency: "AZN",

            reqPaymentDataList: childArr,
            amount: totalAmout,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item.elements[0].providerId,
            tempName: this.state.temp_name,
            identificationCode: params,
            identificationType: item.merchantDescName,
            currency: "AZN",

            reqPaymentDataList: childArr,
            amount: totalAmout,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("pg/createPaymentTemp" + customerType, getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {

                    this.setState({ checkPayment: true, succesModal: true, succesModalData: null, checkPaymentInvoice: getCardListForCustomerResponse.invoice ? getCardListForCustomerResponse.invoice : [], checkPaymentAvans: getCardListForCustomerResponse.avans ? getCardListForCustomerResponse.avans : [], step: 2, })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    paramsList() {
        var item = this.state.items[this.state.selectedIndex];
        var params = [];
        for (var i = 0; i < item.elements.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput,
                    paramLabel: item.elements[i].merchantDescValue,
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput,
                    paramLabel: item.elements[i].merchantDescValue,
                })
            }
        }
        return params;
    }

    getSuccessData(receiptNumber, billingDate, amount, feeAmout) {
        var checkData = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        return {
            receiptNumber: receiptNumber,
            providerName: this.state.items[0].elements[0].merchantDisplayName,
            abonentName: checkData.fullName,//electedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].fullName : checkPaymentInvoice[selectedPaymentIndex].fullName,
            operationDate: billingDate,
            cardnumber: this.state.cards[this.state.selectedValue].organizedCardNumber,
            category: 'Telefon',
            amount: this.amountSplit(amount.toString()),
            commission: this.amountSplit(feeAmout.toString())
        }
    }

    commissionCalculator() {
        if (this.state.selectedPaymentType === 1) {
            var avans = this.state.checkPaymentAvans[this.state.selectedPaymentIndex];
            if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 < parseFloat(avans.feeMinAmount)) {
                // return parseFloat(avans.feeMinAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(avans.feeMinAmount), 2);
            } else if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 > parseFloat(avans.feeMaxAmount)) {
                // return parseFloat(avans.feeMaxAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(avans.feeMaxAmount), 2);
            } else {
                // return (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100).toFixed(2);
                let total= (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100);
                return roundNumberWithDecimal(total, 2)
            }
        } else {
            var invoice = this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
            if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 < parseFloat(invoice.feeMinAmount)) {
                // return parseFloat(invoice.feeMinAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(invoice.feeMinAmount), 2);
            } else if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 > parseFloat(invoice.feeMaxAmount)) {
                // return parseFloat(invoice.feeMaxAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(invoice.feeMaxAmount), 2);
            } else {
                // return (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100).toFixed(2);
                let total = (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100);       
                return  roundNumberWithDecimal(total, 2);
            }
        }
    }

    commissionChildCalculator(index) {
        if (this.state.selectedPaymentType === 1) {
            var avans = this.state.checkPaymentAvans[this.state.selectedPaymentIndex].respChildInvoiceList[index];
            if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 < parseFloat(avans.feeMinAmount)) {
                // return parseFloat(avans.feeMinAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(avans.feeMinAmount), 2);
            } else if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 > parseFloat(avans.feeMaxAmount)) {
                // return parseFloat(avans.feeMaxAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(avans.feeMaxAmount), 2);
            } else {
                // return (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100).toFixed(2);
                let total = (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100);
                return roundNumberWithDecimal(total, 2);
            }
        } else {
            var invoice = this.state.checkPaymentInvoice[this.state.selectedPaymentIndex].respChildInvoiceList[index];
            if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 < parseFloat(invoice.feeMinAmount)) {
                // return parseFloat(invoice.feeMinAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(invoice.feeMinAmount), 2);
            } else if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 > parseFloat(invoice.feeMaxAmount)) {
                // return parseFloat(invoice.feeMaxAmount).toFixed(2);
                return roundNumberWithDecimal(parseFloat(invoice.feeMaxAmount), 2);
            } else {
                // return (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100).toFixed(2);
                let total = (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100);
                return roundNumberWithDecimal(total, 2);
            }
        }
    }

    childTotalCommision() {
        if (this.state.selectedPaymentType === 1) {
            var childs = this.state.checkPaymentAvans[this.state.selectedPaymentIndex].respChildInvoiceList;
            var total = parseFloat("0.00");
            for (var i = 0; i < childs.length; i++) {
                var avans = childs[i];
                if (parseFloat(avans.amount.toString()) * parseFloat(avans.feePercent.toString()) / 100 < parseFloat(avans.feeMinAmount.toString())) {
                    total = total + parseFloat(avans.feeMinAmount.toString());
                } else if (parseFloat(avans.amount.toString()) * parseFloat(avans.feePercent.toString()) / 100 > parseFloat(avans.feeMaxAmount.toString())) {
                    total = total + parseFloat(avans.feeMaxAmount.toString());
                } else {
                    total = total + (parseFloat(avans.amount.toString()) * parseFloat(avans.feePercent.toString()) / 100);
                }
            }

            // return total.toFixed(2);
            return roundNumberWithDecimal(total, 2);
        } else {
            var childs = this.state.checkPaymentInvoice[this.state.selectedPaymentIndex].respChildInvoiceList;
            var total = parseFloat("0.00");
            for (var i = 0; i < childs.length; i++) {
                var invoice = childs[i];
                if (parseFloat(invoice.amount.toString()) * parseFloat(invoice.feePercent.toString()) / 100 < parseFloat(invoice.feeMinAmount.toString())) {
                    total = total + parseFloat(invoice.feeMinAmount.toString());
                } else if (parseFloat(invoice.amount.toString()) * parseFloat(invoice.feePercent.toString()) / 100 > parseFloat(invoice.feeMaxAmount.toString())) {
                    total = total + parseFloat(invoice.feeMaxAmount.toString());
                } else {
                    total = total + (parseFloat(invoice.amount.toString()) * parseFloat(invoice.feePercent.toString()) / 100);
                }
            }
            // return total.toFixed(2);
            return roundNumberWithDecimal(total, 2);
        }

    }

    showAvansInfo = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let showAvansInfoRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            abonCode: this.state.checkPaymentAvans[this.state.avansIndex].abonCode,
            merchantCode: this.state.checkPaymentAvans[this.state.avansIndex].merchantCode,
            serviceCode: this.state.checkPaymentAvans[this.state.avansIndex].serviceCode,
            subType: this.state.checkPaymentAvans[this.state.avansIndex].subType,
            transactionId: this.state.checkPaymentAvans[this.state.avansIndex].transactionId
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            abonCode: this.state.checkPaymentAvans[this.state.avansIndex].abonCode,
            merchantCode: this.state.checkPaymentAvans[this.state.avansIndex].merchantCode,
            serviceCode: this.state.checkPaymentAvans[this.state.avansIndex].serviceCode,
            subType: this.state.checkPaymentAvans[this.state.avansIndex].subType,
            transactionId: this.state.checkPaymentAvans[this.state.avansIndex].transactionId
        }
        fetch(request("pg/showAvansInfo", showAvansInfoRequestBody))
            .then(res => res.text())
            .then((showAvansInfoResponseJSON) => {
                const JSON = require('true-json-bigint');
                let showAvansInfoResponse = JSON.parse(showAvansInfoResponseJSON);
                if (showAvansInfoResponse.status.statusCode !== 1) {
                    if (showAvansInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let checkPaymentAvans = this.state.checkPaymentAvans;
                    if (showAvansInfoResponse.respAvansList && showAvansInfoResponse.respAvansList.length > 0) {
                        checkPaymentAvans[this.state.avansIndex].amount = Number(showAvansInfoResponse.respAvansList[0].amount) <= 0 ? 0 : showAvansInfoResponse.respAvansList[0].amount;
                        checkPaymentAvans[this.state.avansIndex].minAllowed = showAvansInfoResponse.respAvansList[0].minAllowed ? showAvansInfoResponse.respAvansList[0].minAllowed : 0;
                        checkPaymentAvans[this.state.avansIndex].maxAllowed = showAvansInfoResponse.respAvansList[0].maxAllowed ? showAvansInfoResponse.respAvansList[0].maxAllowed : 9999999;
                        checkPaymentAvans[this.state.avansIndex].transactionNumber = showAvansInfoResponse.respAvansList[0].transactionNumber;
                    }
                    else {
                        checkPaymentAvans[this.state.avansIndex].amount = 0;
                        checkPaymentAvans[this.state.avansIndex].minAllowed = 0;
                        checkPaymentAvans[this.state.avansIndex].maxAllowed = 0;
                    }
                    if(showAvansInfoResponse.respAvansList && showAvansInfoResponse.respAvansList?.length > 0) {
                        checkPaymentAvans[this.state.avansIndex].paymentReceiverCodeName = showAvansInfoResponse.respAvansList[0].paymentReceiverCodeName;
                        checkPaymentAvans[this.state.avansIndex].paymentReceiverDescription = showAvansInfoResponse.respAvansList[0].paymentReceiverDescription;
                        this.setState({ checkPaymentAvans });
                    }
                    else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(175));
                        this.setState({ avansIndex: -1 });
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { t } = this.props;
        let { isLoading, dropdownVisibility, userInfo, cards, selectedValue, items, succesModal, succesModalData, errorModal, errorModalData, selectedIndex, selectedShow, checkPayment, checkPaymentAvans, checkPaymentInvoice, invoiceIndex, avansIndex, selectedPaymentType, selectedPaymentIndex, step, allDisabled } = this.state;
        let custObject = JSON.parse(localStorage.getItem('custObject')).company ? JSON.parse(localStorage.getItem('custObject')).customer : JSON.parse(localStorage.getItem('custObject'));
        return (
            <Fragment>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !(items && items.length > 0) ?
                            <NotFound />
                            :
                            <div className="dashboard-payment-container">
                                <PaymentModalError history={this.props.history} data={errorModalData} open={errorModal} />
                                <TemplateModalSuccess history={this.props.history} data={succesModalData} open={succesModal} />
                                <div className="dashboard-payment-container-data-section">
                                    <div className="dashboard-payment-container-data-section-header">
                                        <h3 className="dashboard-payment-container-data-section-header-title">{t('Pay')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content">
                                    {
                                            (checkPaymentAvans.length > 0 || checkPaymentInvoice.length > 0) &&
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Subscriber information')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">

                                                    <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Subscriber')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{
                                                                checkPaymentAvans.length > 0 ?
                                                                    checkPaymentAvans[0].fullName :
                                                                    checkPaymentInvoice[0].fullName
                                                            }</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        (checkPaymentAvans.length + checkPaymentInvoice.length === 1) || (avansIndex >= 0 || invoiceIndex >= 0) &&
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Type of payment service')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                                                                    {
                                                                        checkPaymentAvans.length + checkPaymentInvoice.length === 1 ?
                                                                            (checkPaymentAvans.length > 0 ?
                                                                                checkPaymentAvans[0].serviceName
                                                                                :
                                                                                checkPaymentInvoice[0].serviceName
                                                                            )
                                                                            :
                                                                            avansIndex >= 0 ?
                                                                                checkPaymentAvans[avansIndex].serviceName
                                                                                :
                                                                                checkPaymentInvoice[invoiceIndex].serviceName

                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.props.bonus && this.props.bonusTariffInfoList &&
                                                        (checkPaymentAvans.length > 0 || checkPaymentInvoice.length > 0) &&
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group" style={{ marginLeft: '12px' }}>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title dashboard-payment-container-data-section-content-container-form-group-title--light">{t('Mobile payment')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                                                                    <span>
                                                                        {/* 1 AZN = {Number.parseFloat(bonusTariffInfoList.quantity).toFixed(2).toString().replace('.', ',')} */}
                                                                        1 AZN = {this.props.bonusTariffInfoList.quantity}
                                                                    </span>
                                                                    <svg className='dashboard-bonuses-convertation-details-item-content-icon' width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8 0L10.3511 4.76393L15.6085 5.52786L11.8042 9.23607L12.7023 14.4721L8 12L3.29772 14.4721L4.19577 9.23607L0.391548 5.52786L5.64886 4.76393L8 0Z" fill="#909090" />
                                                                    </svg>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                            <form className="dashboard-payment-container-data-section-content-container-form">
                                                <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('template name')}</label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                            <input className="dashboard-payment-container-data-section-content-container-form-input" onChange={e => this.setState({ temp_name: e.target.value })} placeholder={t('template name')} />
                                                        </div>
                                                    </div>
                                                    {
                                                        step === 1 && items && selectedIndex >= 0 && items.length > 1 &&
                                                        <div style={{ width: '100%' }} className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">Əməliyyat növün seç</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                                <button onClick={e => { e.preventDefault(); this.setState({ selectedShow: !selectedShow }); }} className="dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" >
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{items[selectedIndex].elements[0].merchantDescValue}</div>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </button>
                                                                <ul style={{ zIndex: 1000 }} className={(!selectedShow) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {items.map((item, index) => {
                                                                        return (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                <button style={{ width: '100%' }} onClick={e => { e.preventDefault(); this.setState({ selectedShow: false, selectedIndex: index }) }} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{item.elements[0].merchantDescValue}</button>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        items.length > 0 && selectedIndex >= 0 && items[selectedIndex].elements.map((item, index) => {
                                                            if (item.format === "string") {
                                                                if (item.params.length > 0) {
                                                                    return (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{item.merchantDescValue}</label>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                                                    <button className="dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" onClick={(e) => this.onChangeParamsDropdown(e, index)}>
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{item.params.find(el => el.value === item.defaultSelect).label}</div>
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                            </svg>
                                                                                        </div>
                                                                                    </button>
                                                                                    <ul style={{ zIndex: 1000 }} className={(item.isShow === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                        {

                                                                                            item.params.map((item_in, index_in) => {
                                                                                                return (
                                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                                        <button onClick={e => this.onSelectParamsDropdown(e, item_in.value, index)} style={{ width: '100%' }} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{item_in.label}</button>
                                                                                                    </li>
                                                                                                );
                                                                                            })
                                                                                        }

                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{item.paramDisplayName}</label>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                    <input type={`${item.format === "string" && 'text'}${item.format === "number" && 'number'}`} disabled={allDisabled} className="dashboard-payment-container-data-section-content-container-form-input" onChange={e => this.onChnageInputDropdown(e.target.value, index)} placeholder={item.paramDisplayName} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{item.paramDisplayName}</label>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                <input className="dashboard-payment-container-data-section-content-container-form-input" disabled={allDisabled} onChange={e => this.onChnageInputDropdown(e.target.value, index)} placeholder={item.paramDisplayName} />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        })
                                                    }

                                                    {
                                                        checkPayment ? ((checkPaymentAvans.length === 1 && checkPaymentInvoice.length === 0) || (checkPaymentInvoice.length === 1 && checkPaymentAvans.length === 0)) ?
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>



                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')}</label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="number" onChange={val => {
                                                                            if (checkPaymentAvans.length === 1) {
                                                                                var arrAvans = checkPaymentAvans;
                                                                                arrAvans[0].amount = val.target.value;
                                                                                this.setState({ checkPaymentAvans: arrAvans });
                                                                            } else {
                                                                                var arrInvoice = checkPaymentInvoice;
                                                                                arrInvoice[0].amount = val.target.value;
                                                                                this.setState({ checkPaymentInvoice: arrInvoice });
                                                                            }
                                                                        }} disabled={allDisabled ? true : !(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).partialPayment} 
                                                                        defaultValue={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).amount} min={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).minAllowed ? (checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).minAllowed : 0} max={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).maxAllowed !== 0 ? (checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).maxAllowed : 99999999} 
                                                                        className={`dashboard-payment-container-data-section-content-container-form-input ${allDisabled ? true : !(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).partialPayment ? 'dashboard-payment-container-data-section-content-container-form-input__disable' : ''}`} placeholder="0" />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>

                                                                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', maxHeight: '500px', overflowY: 'auto', marginTop: '15px', marginBottom: '10px' }}>
                                                                    {
                                                                        step <= 2 &&
                                                                        checkPaymentAvans.map((avans, index) => {
                                                                            return (
                                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '12px', paddingBottom: '12px', borderBottom: '1px solid #E8ECEF', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <div>{avans.serviceName}</div>
                                                                                    <div style={{ minWidth: '35%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                            <input disabled={true} type="number" defaultValue={avans.amount} min={avans.minAllowed ? avans.minAllowed : 0} max={avans.maxAllowed ? avans.maxAllowed : 9999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" />
                                                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                        {/* <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: 1, selectedPaymentIndex: index, step: 3, allDisabled: true }) }} style={{ alignSelf: 'flex-end', marginLeft: '15px' }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('pay')}</button> */}
                                                                                        <input type="checkbox" checked={index === avansIndex} onChange={() => {
                                                                                            this.setState({ avansIndex: index, invoiceIndex: -1 }, () => {
                                                                                                this.showAvansInfo();
                                                                                            });
                                                                                        }} style={{ width: '20px', height: '20px', marginLeft: '10px', marginRight: '10px' }} />
                                                                                    </div>

                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        step <= 2 &&
                                                                        checkPaymentInvoice.map((invoice, index) => {
                                                                            return (
                                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '12px', paddingBottom: '12px', borderBottom: '1px solid #E8ECEF', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <div>{invoice.serviceName}</div>
                                                                                    <div style={{ minWidth: '35%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                                {/* <input onChange={val => {
                                                                                    var arrInvoice = checkPaymentInvoice;
                                                                                    arrInvoice[index].amount = val.target.value;
                                                                                    this.setState({ checkPaymentInvoice: arrInvoice });
                                                                                }} disabled={true} type="number" defaultValue={invoice.amount} min={invoice.minAllowed ? invoice.minAllowed : 0} max={invoice.maxAllowed ? invoice.maxAllowed : 9999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" /> */}
                                                                                                <input disabled={true} type="number" defaultValue={invoice.amount} min={invoice.minAllowed ? invoice.minAllowed : 0} max={invoice.maxAllowed ? invoice.maxAllowed : 9999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" />
                                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: 2, selectedPaymentIndex: index, step: 3, allDisabled: true }) }} style={{ alignSelf: 'flex-end', marginLeft: '15px' }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('pay')}</button> */}
                                                                                        <input type="checkbox" checked={index === invoiceIndex} onChange={() => {
                                                                                            this.setState({ invoiceIndex: index, avansIndex: -1 });
                                                                                        }} style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                                                                                    </div>

                                                                                </div>
                                                                            );
                                                                        })
                                                                    }

                                                                </div>
                                                                {
                                                                    step >= 2 && (invoiceIndex >= 0 || avansIndex >= 0) &&
                                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')}</label>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                <input onChange={val => {
                                                                                    if (avansIndex >= 0) {
                                                                                        var arrAvans = checkPaymentAvans;
                                                                                        arrAvans[avansIndex].amount = val.target.value;
                                                                                        this.setState({ checkPaymentAvans: arrAvans });
                                                                                    } else {
                                                                                        var arrInvoice = checkPaymentInvoice;
                                                                                        arrInvoice[invoiceIndex].amount = val.target.value;
                                                                                        this.setState({ checkPaymentInvoice: arrInvoice });
                                                                                    }
                                                                                }} type="number" disabled={allDisabled ? true : !Boolean((avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).partialPayment)} value={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]) && (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).amount} min={0} 
                                                                                className={`dashboard-payment-container-data-section-content-container-form-input ${allDisabled ? true : !Boolean((avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).partialPayment) ? 'dashboard-payment-container-data-section-content-container-form-input__disable' : ''}`} placeholder="0" />
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                {
                                                    step === 1 &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={e => this.onSubmitForm(e)} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                }

                                                {
                                                    step === 2 && ((checkPaymentAvans.length === 1 && checkPaymentInvoice.length === 0) || (checkPaymentInvoice.length === 1 && checkPaymentAvans.length === 0)) &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={e => {
                                                            e.preventDefault(); this.setState({ selectedPaymentType: checkPaymentAvans.length === 1 ? 1 : 2, selectedPaymentIndex: 0, step: 3, allDisabled: true }, () => {
                                                                if ((checkPaymentAvans.length === 1 ? checkPaymentAvans[0].respChildAvansList : checkPaymentInvoice[0].respChildInvoiceList)) {
                                                                    this.createTemplateWithChild()
                                                                } else {
                                                                    this.createTemplate()
                                                                }
                                                            })
                                                        }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                }
                                                {
                                                    (invoiceIndex >= 0 || avansIndex >= 0) && step === 2 &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={e => {
                                                            e.preventDefault(); this.setState({ selectedPaymentType: avansIndex >= 0 ? 1 : 2, selectedPaymentIndex: avansIndex >= 0 ? avansIndex : invoiceIndex, step: 3, allDisabled: true }, () => {
                                                                if ((avansIndex >= 0 ? checkPaymentAvans[avansIndex >= 0 ? avansIndex : invoiceIndex].respChildAvansList : checkPaymentInvoice[avansIndex >= 0 ? avansIndex : invoiceIndex].respChildInvoiceList)) {
                                                                    this.createTemplateWithChild()
                                                                } else {
                                                                    this.createTemplate()
                                                                }
                                                            })
                                                        }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section">
                                    <div className="dashboard-payment-container-confirmation-section-header">
                                        <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Payment confirmation')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-confirmation-section-content">

                                        <div className="dashboard-payment-container-confirmation-section-empty">
                                            <img src={empty} className="dashboard-payment-container-confirmation-section-content-image" />
                                            <p className="dashboard-payment-container-confirmation-section-content-description">{t('To complete the payment, please enter the payment settings and continue.')}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(CreateTemplate);
