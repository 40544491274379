import React from "react";
import { Link, useHistory } from "react-router-dom";

import arrowLeftIcon from "./../img/arrow-left.svg";

import "./RouteHeader.scss";

const RouteHeader = ({ title, redirectUrl, redirectUrlTitle }) => {
  let history = useHistory();

  return (
    <div className="dashboard-header-route">
      <div className="dashboard-header-route-left">
        <button onClick={() => history.goBack()}>
          <img src={arrowLeftIcon} alt="go-back" />
        </button>
        <h2>{title}</h2>
      </div>
      {redirectUrl && (
        <Link to={redirectUrl} className="dashboard-header-route-link">
          {redirectUrlTitle}
        </Link>
      )}
    </div>
  );
};

export default RouteHeader;
