import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { translate } from 'react-i18next';

import { request } from '../../config';

import './scss/style.scss';
import './scss/loading.css';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function DepositWithdrawalRates(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [loanPaymentPlan, setLoanPaymentPlan] = React.useState([]);
    const close = () => {
        setOpen(false);
        props.onClose();
    }

    useEffect(() => {
        setOpen(props.open)
        if (props.open === true) {
            props.updateAppState('isLoadingTransparentBackground', true);
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let getAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                depositId: props.id,
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            } : {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    depositId: props.id,
                    lang: localStorage.getItem('i18nextLng').toUpperCase()
                }

            fetch(request("deposit/" + customerType.toLowerCase() + "EarlyPayPercentsById", getAccountListForCustomerRequestBody))
                .then(res => res.text())
                .then((getAccountListForCustomerResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                    if (getAccountListForCustomerResponse.status.statusCode !== 1) {
                        props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        setLoanPaymentPlan(getAccountListForCustomerResponse.respDepositEarlyPays)
                        props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        props.history.push("/technical-break");
                    });
        }
    }, [props.open])

    const t = props.t;
    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="credit-payment">
                            <div className="credit-payment-head">
                                <h2>{props.t("Withdrawal rates")}</h2>
                                <button onClick={close}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                    </svg>
                                </button>
                            </div>
                            <div className="credit-payment-container" style={{width: '100%'}}>
                                <table className="credit-payment-table">
                                    <tr>
                                        <th width="50%">{props.t("HISTORY")}</th>
                                        <th width="50%">{props.t("INTEREST RATE")}</th>
                                    </tr>
                                    {
                                        loanPaymentPlan.length > 0 &&
                                        loanPaymentPlan.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td width="50%">{item.fromPeriod}-{item.toPeriod} ay</td>
                                                    <td width="50%">{item.percent}%</td>
                                                </tr>
                                            );
                                        })
                                    }


                                </table>
                            </div>

                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(DepositWithdrawalRates);