import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { request, ipsRequest, generalRequest } from './../../../../config';
import TransferModal from './../../../../copms/Modal/TransferModal';
import Dropdown from '../../../../copms/Dropdown/Dropdown';
import { translate } from 'react-i18next';
import Loading from '../../Loading/Loading';
import CheckboxMinus from './../../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../../copms/Checkbox/CheckboxTick';
import RadioButton from './../../../../copms/RadioButton/RadioButton';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import { event } from 'jquery';
import GeneralModal from '../../../../copms/Modal/GeneralModal';
import OutsideClickHandler from 'react-outside-click-handler';
import SendOtpModalForAlias from './../../../../copms/Modal/SendOtpModalForAlias';
class CreateAlias extends Component {
    state = {
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
        createFeedbackMessage: false,
        open: false,
        value: "",
        dropdownVisibilityType: false,
        aliasTypes: [],
        aliasTypeSelectIndex: -1,
        accounts: [],
        defaultAccountSelectIndex: -1,
        dropdownVisibilityDefaultAccount: false,
        dropdownVisibilitySelectAccount: false,

        valueOutline: false,

        aliasTypeCount: 0,
        defaultAccountCount: 0,
        selectAccountCount: 0,

        openOtp: false,
        verifyToken: "",
    }

    changeDropdownVisibilityType = () => {
        this.setState({ dropdownVisibilityType: !this.state.dropdownVisibilityType }, () => {
            if (this.state.aliasTypeSelectIndex < 0) {
                this.setState({ aliasTypeCount: ++this.state.aliasTypeCount });
            }
        });
    }
    changeDropdownVisibilityDefaultAccount = () => {
        this.setState({ dropdownVisibilityDefaultAccount: !this.state.dropdownVisibilityDefaultAccount }, () => {
            if (this.state.defaultAccountSelectIndex < 0) {
                this.setState({ defaultAccountCount: ++this.state.defaultAccountCount });
            }
        });
    }
    changeDropdownVisibilitySelectAccount = () => {
        this.setState({ dropdownVisibilitySelectAccount: !this.state.dropdownVisibilitySelectAccount }, () => {
            if (!this.state.accounts.filter(el => el.isSelect == 1).length > 0) {
                this.setState({ selectAccountCount: ++this.state.selectAccountCount });
            }
        });
    }
    selectAccount = (index) => {
        var accounts = this.state.accounts;
        accounts[index].isSelect = !Boolean(accounts[index].isSelect);
        this.setState({ accounts: accounts })
    }
    updateType = (index) => {
        this.setState({ aliasTypeSelectIndex: index, dropdownVisibilityType: false, value: "" });
    }
    updateValue = (size, event) => {
        this.setState({ value: event.target.value.trim().slice(0, size) });
    }
    updateDefaultAccount = (index) => {
        this.setState({ defaultAccountSelectIndex: index, dropdownVisibilityDefaultAccount: false });
    }
    getFormatedDate = (date) => {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '/' + formatedDate.month + '/' + formatedDate.year;
        }
        return null;
    }
    getAliasTypes = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAliasTypesRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        getAliasTypesRequestBody.custType = customerType == 'Juridical' ? 3 : 1;
        fetch(ipsRequest("getAliasTypes", getAliasTypesRequestBody))
            .then(res => res.text())
            .then((getAliasTypesResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAliasTypesResponse = JSON.parse(getAliasTypesResponseJSON);
                if (getAliasTypesResponse.status.statusCode !== 1) {
                    if (getAliasTypesResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAliasTypesResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ isLoading: false });

                    let aliasTypes = customerType === 'Juridical' ?
                        getAliasTypesResponse.types.filter(item => item.name === 'MOBILE' || item.name === 'EMAIL' || item.name === 'MERCHANTID' || item.name === 'OBJECTCODE')
                        :
                        getAliasTypesResponse.types.filter(item => item.name === 'MOBILE' || item.name === 'EMAIL');
                    aliasTypes.map((item, index) => {
                        if (item.name === 'MOBILE') {
                            aliasTypes[index].placeholder = '+994 xx xxx xx xx';
                            aliasTypes[index].size = 13;
                        }
                        else {
                            if (item.name === 'EMAIL') {
                                aliasTypes[index].placeholder = 'example@mail.com';
                                aliasTypes[index].size = 50;
                            }
                            else {
                                aliasTypes[index].size = 15;
                            }
                        }
                    })
                    this.setState({ aliasTypes: aliasTypes, aliasTypeSelectIndex: 0 })

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    submitForm = (event) => {
        event.preventDefault();
        this.setState({
            feedbackErrorModal: null,
            feedbackSuccessModal: null,
            createFeedbackMessage: false
        });
        if (this.state.aliasTypes[this.state.aliasTypeSelectIndex].name !== 'MOBILE' && this.state.aliasTypes[this.state.aliasTypeSelectIndex].name !== 'EMAIL') {
            this.createAlias();
        }
        else {
            // ================
            this.props.updateAppState('isLoadingTransparentBackground', true);
            let value = this.state.value.trim();
            if (this.state.aliasTypes[this.state.aliasTypeSelectIndex].name === 'MOBILE') {
                if (value[0] == '0') {
                    value = value.substring(1);
                }
                value = '+994' + value.replaceAll('-', '').replaceAll('+', '').replaceAll('9940', '').replaceAll('994', '');
                this.setState({ value: value });
            }
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let checkAliasForValueRequestBody = {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                value: value
            }
            if (customerType === 'Juridical') {
                checkAliasForValueRequestBody.compId = localStorage.getItem('compId');
            }
            fetch(ipsRequest("checkAliasForValue", checkAliasForValueRequestBody))
                .then(res => res.text())
                .then((checkAliasForValueResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let checkAliasForValueResponse = JSON.parse(checkAliasForValueResponseJSON);
                    if (checkAliasForValueResponse.status.statusCode !== 1) {
                        if (checkAliasForValueResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                            this.setState({
                                createFeedbackMessage: false, open: true,
                                feedbackErrorModal: this.props.t(checkAliasForValueResponse.status.statusCode)
                            });
                        }
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.sendOtpCode(this.state.aliasTypes[this.state.aliasTypeSelectIndex].name);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }
    createAlias = () => {
        let value = this.state.value.trim();
        if (this.state.aliasTypes[this.state.aliasTypeSelectIndex].name === 'MOBILE') {
            if (value[0] == '0') {
                value = value.substring(1);
            }
            value = '+994' + value.replaceAll('-', '').replaceAll('+', '').replaceAll('9940', '').replaceAll('994', '');
            this.setState({ value: value });
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createAliasRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            verifyToken: this.state.verifyToken,
            aliases: [{
                value: value,
                status: 1,
                type: this.state.aliasTypes[this.state.aliasTypeSelectIndex].name
            }]
        }
        if (customerType === 'Juridical') {
            createAliasRequestBody.compId = localStorage.getItem('compId');
        }
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(ipsRequest("createAliasv1", createAliasRequestBody))
            .then(res => res.text())
            .then((createAliasResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createAliasResponse = JSON.parse(createAliasResponseJSON);
                if (createAliasResponse.status.statusCode !== 1) {
                    if (createAliasResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            createFeedbackMessage: false, open: true,
                            feedbackErrorModal: this.props.t(createAliasResponse.status.statusCode)
                        });
                    }
                }
                else {
                    this.linkAlias(createAliasResponse.aliases[0].id);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    linkAlias = (aliasId) => {
        this.setState({
            feedbackErrorModal: null,
            feedbackSuccessModal: null,
            createFeedbackMessage: false
        });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let today = new Date();
        // let accounts = this.state.accounts.filter(el => el.isSelect == true);
        // accounts.map((account, index) => {
        //     if (account.accountId == this.state.selectedId) {
        //         accounts[index].isDefault = 1;
        //     }
        // })
        let linkAliasRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasIdAndAccountIdList: this.state.accounts.filter(el => el.isSelect == true).map((item) => {
                return {
                    aliasId: aliasId,
                    accountId: item.id,
                    isDefault: this.state.defaultAccountSelectIndex >= 0 && this.state.accounts[this.state.defaultAccountSelectIndex].id === item.id ? 1 : 0
                };
            })
        }
        if (customerType === 'Juridical') {
            linkAliasRequestBody.compId = localStorage.getItem('compId');
        }

        fetch(ipsRequest("linkAliasToAccount", linkAliasRequestBody))
            .then(res => res.text())
            .then((linkAliasToAccountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let linkAliasToAccountResponse = JSON.parse(linkAliasToAccountResponseJSON);
                if (linkAliasToAccountResponse.status.statusCode !== 1) {
                    if (linkAliasToAccountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            createFeedbackMessage: false, open: true,
                            feedbackErrorModal: this.props.t(linkAliasToAccountResponse.status.statusCode)
                        });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(linkAliasToAccountResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({
                        createFeedbackMessage: true, open: true,
                        feedbackSuccessModal: this.props.t('The alias was created successfully')
                    });
                    // this.setState({
                    //     createFeedbackMessage: true, open: true,
                    //     feedbackSuccessModal: this.props.t('The alias was created successfully')
                    // });
                    // // this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('Accounts are successfully linked to alias'));
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showAccounts = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountsRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("getAccounts", getAccountsRequestBody))
            .then(res => res.text())
            .then((getAccountsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountsResponse = JSON.parse(getAccountsResponseJSON);
                if (getAccountsResponse.status.statusCode !== 1 && getAccountsResponse.status.statusCode !== 126) {
                    if (getAccountsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountsResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getAccountsResponse.status.statusCode === 126) {
                        getAccountsResponse.accounts = [];
                    }
                    else {
                        getAccountsResponse.accounts = getAccountsResponse.accounts.filter(item => item.currency === 'AZN')
                    }
                    getAccountsResponse.accounts.map((account, index) => {
                        getAccountsResponse.accounts[index].isSelect = false;
                    });
                    this.setState({ accounts: getAccountsResponse.accounts });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    sendOtpCode = (type) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ openOtp: false });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let sendOtpCodeRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        if (type === 'MOBILE') {
            let mobile = this.state.value.trim();
            if (mobile[0] == '0') {
                mobile = mobile.substring(1);
            }
            mobile = '994' + mobile.replaceAll('-', '').replaceAll('+', '').replaceAll('9940', '').replaceAll('994', '');
            sendOtpCodeRequestBody.mobile = mobile;
        }
        else if (type === 'EMAIL') {
            sendOtpCodeRequestBody.email = this.state.value.trim();
        }
        if (customerType === 'Juridical') {
            sendOtpCodeRequestBody.compId = localStorage.getItem('compId');
        }
        fetch(generalRequest("sendOtp" + (type === 'EMAIL' ? 'Email' : '') + "ForIps", sendOtpCodeRequestBody))
            .then(res => res.text())
            .then((sendOtpCodeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let sendOtpCodeResponse = JSON.parse(sendOtpCodeResponseJSON);
                if (sendOtpCodeResponse.status.statusCode !== 1) {
                    if (sendOtpCodeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            createFeedbackMessage: false, open: true,
                            feedbackErrorModal: this.props.t(sendOtpCodeResponse.status.statusCode),
                        });
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ openOtp: true, verifyToken: sendOtpCodeResponse.verifyToken });
                    
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    verifyOtpCode = (type, val) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let verifyOtpCodeRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            confirmCode: val,
            verifyToken: this.state.verifyToken
        }
        if (customerType === 'Juridical') {
            verifyOtpCodeRequestBody.compId = localStorage.getItem('compId');
        }
        fetch(generalRequest("verifyOtp" + (type === 'EMAIL' ? 'Email' : '') + 'ForIps', verifyOtpCodeRequestBody))
            .then(res => res.text())
            .then((verifyOtpCodeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let verifyOtpCodeResponse = JSON.parse(verifyOtpCodeResponseJSON);
                if (verifyOtpCodeResponse.status.statusCode !== 1) {
                    if (verifyOtpCodeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            createFeedbackMessage: false, open: true,
                            feedbackErrorModal: this.props.t(verifyOtpCodeResponse.status.statusCode)
                        });
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ openOtp: false });
                    this.createAlias();
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount() {
        this.getAliasTypes();
        this.showAccounts();
    }
    render() {
        let { isLoading, feedbackErrorModal, feedbackSuccessModal, createFeedbackMessage, open, type, value, openOtp,
            aliasTypes, aliasTypeSelectIndex, dropdownVisibilityType,
            accounts, defaultAccountSelectIndex, dropdownVisibilityDefaultAccount, dropdownVisibilitySelectAccount, aliasTypeCount, defaultAccountCount, selectAccountCount, valueOutline } = this.state;
        let { updateAppState, t, history } = this.props;
        return (
            <Fragment>
                <SendOtpModalForAlias open={openOtp} type={(this.state.aliasTypes && aliasTypeSelectIndex >= 0 && this.state.aliasTypes[this.state.aliasTypeSelectIndex] && this.state.aliasTypes[this.state.aliasTypeSelectIndex].name)} sendOtpCode={
                    this.sendOtpCode.bind(this, (this.state.aliasTypes && aliasTypeSelectIndex >= 0 && this.state.aliasTypes[this.state.aliasTypeSelectIndex] && this.state.aliasTypes[this.state.aliasTypeSelectIndex].name))
                }
                    signSubmit={
                        (val) => this.verifyOtpCode((this.state.aliasTypes && aliasTypeSelectIndex >= 0 && 
                            this.state.aliasTypes[this.state.aliasTypeSelectIndex] && this.state.aliasTypes[this.state.aliasTypeSelectIndex].name), val)
                    } onClose={() => this.setState({ openOtp: false })} />
                <GeneralModal open={open} successClose={() => { history.push('/dashboard/transfer/ips/aliases') }} errorClose={() => this.setState({ open: false })} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} feedbackSuccessModal={feedbackSuccessModal} history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Create alias')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-payment-container">
                            <div className="dashboard-payment-container-data-section">
                                <div className="dashboard-payment-container-data-section-header">
                                    <h3 className="dashboard-payment-container-data-section-header-title">{t('New')}</h3>
                                </div>
                                <form className="dashboard-payment-container-data-section-content" onSubmit={event => { event.preventDefault(); }}>
                                    <div className="dashboard-payment-container-data-section-content-container">
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Alias parameters')}</h3>
                                        <div className="dashboard-payment-container-data-section-content-container-form">
                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t("Alias type")} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityType: false }) }}>

                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (aliasTypeCount > 1 && aliasTypeSelectIndex < 0 || aliasTypeCount === 1 && aliasTypeSelectIndex < 0 && !dropdownVisibilityType ? "input-outline" : "")}>
                                                            <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={this.changeDropdownVisibilityType}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{aliasTypes && aliasTypes.length > 0 && aliasTypeSelectIndex >= 0 ? aliasTypes[aliasTypeSelectIndex].description : t('Choose')}</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            {
                                                                aliasTypes.length !== 0 &&
                                                                <ul className={(dropdownVisibilityType === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        aliasTypes.map((aliasType, index) => (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateType.bind(this, index)}>{aliasType.description}</button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                                {/* =============== */}
                                                {
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        {
                                                            aliasTypes && aliasTypes.length > 0 && aliasTypeSelectIndex >= 0 &&
                                                            <>
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{aliasTypes && aliasTypeSelectIndex >= 0 && aliasTypes[aliasTypeSelectIndex].description} <span style={{ color: 'red' }}>*</span></label>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (valueOutline && !value ? "input-outline" : "")}>
                                                                    <input value={value} placeholder={aliasTypes[aliasTypeSelectIndex].placeholder} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={this.updateValue.bind(this, aliasTypes[aliasTypeSelectIndex].size)} onBlur={() => { this.setState({ valueOutline: true }) }} />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                }
                                                {/* ================ */}
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t("The account you want to link to alias")} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilitySelectAccount: false }) }}>

                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectAccountCount > 1 && !accounts.filter(el => el.isSelect == 1).length > 0 || selectAccountCount === 1 && !accounts.filter(el => el.isSelect == 1).length > 0 && !dropdownVisibilitySelectAccount ? "input-outline" : "")}>
                                                            {
                                                                accounts && accounts.length > 0 && accounts.find(account => (account.isSelect === true)) ?
                                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} style={{ cursor: 'auto' }}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ height: 'auto', minHeight: '48px', padding: '8px' }}>
                                                                            <div className="tag-container">
                                                                                {
                                                                                    accounts.map((account, index) => (
                                                                                        (account.isSelect === true) &&
                                                                                        <div className="tag-item" key={index}>
                                                                                            <p className="tag-content">{account.iban}</p>
                                                                                            <button className="tag-icon" onClick={this.selectAccount.bind(this, index)}>
                                                                                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M5.07031 4.5L7.97656 7.40625C8.05469 7.48438 8.05469 7.57031 7.97656 7.66406L7.4375 8.20312C7.34375 8.28125 7.25781 8.28125 7.17969 8.20312L6.6875 7.6875L4.27344 5.29688L1.36719 8.20312C1.28906 8.28125 1.20312 8.28125 1.10938 8.20312L0.570312 7.66406C0.492188 7.57031 0.492188 7.48438 0.570312 7.40625L3.47656 4.5L0.570312 1.59375C0.492188 1.51562 0.492188 1.42969 0.570312 1.33594L1.10938 0.796875C1.20312 0.71875 1.28906 0.71875 1.36719 0.796875L4.27344 3.70312L7.17969 0.796875C7.25781 0.71875 7.34375 0.71875 7.4375 0.796875L7.97656 1.33594C8.05469 1.42969 8.05469 1.51562 7.97656 1.59375L7.46094 2.08594L5.07031 4.5Z" fill="white" />
                                                                                                </svg>
                                                                                            </button>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilitySelectAccount}>
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    :
                                                                    <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={this.changeDropdownVisibilitySelectAccount}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{accounts && accounts.length > 0 ? t('Choose') : t('126')}</div>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                            }
                                                            {
                                                                accounts.length !== 0 &&
                                                                <ul className={(dropdownVisibilitySelectAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        accounts.map((account, index) => (
                                                                            <li className={"dashboard-payment-container-data-section-content-container-form-select-box-list-item  tag-select-box" + (Boolean(account.isSelect) ? " tag-select-box__checked" : "")} key={index} style={{ padding: '0 16px' }}>
                                                                                <button type="button" style={{ display: 'flex', alignItems: 'center' }} onClick={this.selectAccount.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">
                                                                                    <CheckboxTick checked={Boolean(account.isSelect)} onChecked={(event) => { }} />
                                                                                    <span>{`${account.iban} ${account.mainCardNumber !== null ? '/' + account.mainCardNumber : ""}`}</span>
                                                                                </button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>

                                                {/* ================ */}
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t("Linked default alias")}</label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityDefaultAccount: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (defaultAccountCount > 1 && defaultAccountSelectIndex < 0 || defaultAccountCount === 1 && defaultAccountSelectIndex < 0 && !dropdownVisibilityDefaultAccount ? "input-outline" : "")}>
                                                            <button className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={this.changeDropdownVisibilityDefaultAccount}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{
                                                                    accounts && accounts.length > 0 ? (defaultAccountSelectIndex >= 0 ? `${accounts[defaultAccountSelectIndex].iban} ${accounts[defaultAccountSelectIndex].mainCardNumber !== null ? '/' + accounts[defaultAccountSelectIndex].mainCardNumber : ""}`  : t('Choose')) : t('126')
                                                                }</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            {
                                                                accounts.length !== 0 &&
                                                                <ul className={(dropdownVisibilityDefaultAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        accounts.map((account, index) => (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDefaultAccount.bind(this, index)}>{`${account.iban} ${account.mainCardNumber !== null ? '/' + account.mainCardNumber : ""}`}</button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="dashboard-payment-container-confirmation-section">
                                <div className="dashboard-payment-container-confirmation-section-header">
                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('What is the Instant Payment System?')}</h3>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section-content">
                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                        <p>{t("ips content 1")}</p>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        <button disabled={
                                            aliasTypeSelectIndex < 0 || !(Boolean(value)) || !accounts.filter(el => el.isSelect == 1).length > 0
                                        } className={`dashboard-payment-container-data-section-content-container-form-footer-button ${!(aliasTypeSelectIndex < 0 || !(Boolean(value)) || !accounts.filter(el => el.isSelect == 1).length > 0) ? 'dashboard-payment-container-data-section-content-container-form-footer-button__blue' : 'dashboard-payment-container-data-section-content-container-form-footer-button__disable'} dashboard-payment-container-data-section-content-container-form-footer-button__left`} onClick={this.submitForm}>{t('continue')}</button>

                                        <button type="button" onClick={() => this.props.history.push('/dashboard/transfer/ips/home')} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('Skip')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}


export default translate("translation")(CreateAlias);