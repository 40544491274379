export const formatPhoneNumber = (phoneNumber) => {
  ["+", "-", "(", ")", " "].forEach((char) => {
    while (phoneNumber.indexOf(char) !== -1) {
      phoneNumber =
        phoneNumber.slice(0, phoneNumber.indexOf(char)) +
        phoneNumber.slice(phoneNumber.indexOf(char) + 1, phoneNumber.length);
    }
  });
  return phoneNumber;
};
