import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next';
import TransferModal from './../../../../copms/Modal/TransferModal';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import { request, ipsRequest } from './../../../../config';
import CreateTransferTemplate from './../../../../copms/Modal/CreateTransferTemplate';
import CheckboxMinus from './../../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../../copms/Checkbox/CheckboxTick';

class RegistrationIPS extends Component {
    state = {
        feedbackErrorModal: null,
        createFeedbackMessage: false,
        open: false,
        confirmedOperation: false,
        accounts: [],
        feedbackMessage: null,
        authMethods: [],
        selectedAuthMethodIndex: -1,
        authMethodVisible: false,
    }

    showAccounts = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("getUnregAccounts", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                    }
                    // getNoCardAccountListForCustomerResponse.accountList = getNoCardAccountListForCustomerResponse.accountList.filter(account => account.currName === this.state.currency);
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        // getNoCardAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getNoCardAccountListForCustomerResponse.accountList[i].currName);
                    }
                    this.setState({ accounts: getNoCardAccountListForCustomerResponse.accountList && getNoCardAccountListForCustomerResponse.accountList.filter(el => el.currName == "AZN"), accountData: true });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }



    componentDidMount() {
        this.showAccounts();
        this.getIpsMethods();
    }

    getIpsMethods() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getIpsAuthMethodsRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("getIpsAuthMethods", getIpsAuthMethodsRequestBody))
            .then(res => res.text())
            .then((getIpsAuthMethodsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getIpsAuthMethodsResponse = JSON.parse(getIpsAuthMethodsResponseJSON);
                if (getIpsAuthMethodsResponse.status.statusCode !== 1) {
                    if (getIpsAuthMethodsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getIpsAuthMethodsResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getIpsAuthMethodsResponse.authMethodList.length === 1) {
                        this.setState({selectedAuthMethodIndex: 0});
                    }
                    this.setState({ authMethods: getIpsAuthMethodsResponse.authMethodList });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    submitIps() {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createAccountRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.state.accounts.filter(el => el.isSelect == true).map((item) => (item.accountId)),
            authType: this.state.authMethods[this.state.selectedAuthMethodIndex].type,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.state.accounts.filter(el => el.isSelect == true).map((item) => (item.accountId)),
                authType: this.state.authMethods[this.state.selectedAuthMethodIndex].type,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("createAccount", createAccountRequestBody))
            .then(res => res.text())
            .then((createAccountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createAccountResponse = JSON.parse(createAccountResponseJSON);
                if (createAccountResponse.status.statusCode !== 1) {
                    if (createAccountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(createAccountResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    setTimeout(() => {
                        this.props.history.push('/dashboard/transfer/ips/create-alias')
                    }, 100);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    selectAccount(index) {
        var accounts = this.state.accounts;
        accounts[index].isSelect = !Boolean(accounts[index].isSelect);
        this.setState({ accounts: accounts })
    }

    selectAll(val) {
        var accounts = this.state.accounts;
        for (let i = 0; i < accounts.length; i++) {
            accounts[i].isSelect = val;
        }
        this.setState({ accounts: accounts })
    }

    checkAccountSelect() {
        return this.state.accounts.filter(el => el.isSelect == true).length > 0 && this.state.selectedAuthMethodIndex >= 0 ? true : false;
    }

    render() {
        let { t, history } = this.props;
        let { open, openTemp, taxNumber, iban, authMethods, authMethodVisible, selectedAuthMethodIndex, accounts, createFeedbackMessage, feedbackErrorModal, confirmedOperation } = this.state;
        let requestBody = localStorage.getItem('compId') === null ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: this.state.operationId,
        } : {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: this.state.operationId,
            };
        return (
            <Fragment>
                <CreateTransferTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={localStorage.getItem('compId') ? 'addOperationTempJuridical' : 'addOperationTempIndividual'} requestBody={requestBody} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} />
                <TransferModal open={open} createTemplate={() => this.setState({ confirmedOperation: false, openTemp: true })} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} errorClose={() => this.setState({ confirmedOperation: false })} updateOpenModal={this.updateOpenModal} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Instant payments')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section">
                        <div className="dashboard-payment-container-data-section-header">
                            <h3 className="dashboard-payment-container-data-section-header-title">{t('Select the account you want to register')}</h3>
                        </div>
                        <div className="dashboard-transfer-list-content">
                            <form className="dashboard-payment-container-data-section-content" onSubmit={event => { event.preventDefault(); }}>
                                <div className="dashboard-payment-container-data-section-content-container-form-container">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Authorization method')} <span style={{ color: 'red' }}>*</span></label>
                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                            <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation} onClick={() => this.setState({ authMethodVisible: !authMethodVisible })}>
                                                <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                    {
                                                        authMethods && authMethods.length != 0 && selectedAuthMethodIndex >= 0 ? authMethods[selectedAuthMethodIndex].name : t('Choose')
                                                    }
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                    </svg>
                                                </div>
                                            </button>
                                            {
                                                authMethods.length !== 0 &&
                                                <ul className={(authMethodVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                    {
                                                        authMethods.map((method, index) => (
                                                            <li style={{ width: '100%' }} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={() => this.setState({ authMethodVisible: false, selectedAuthMethodIndex: index })}>{method.name}</button>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </form>
                            <table className="dashboard-transfer-list-table" style={{marginTop: '32px'}}>
                                <thead className="dashboard-transfer-list-table-head">
                                    <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head" style={{ width: '70px' }}>
                                            <CheckboxMinus
                                                onChecked={val => this.selectAll(val)} />
                                        </th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("ACCOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                    </tr>
                                </thead>
                                <tbody className="dashboard-transfer-list-table-body">
                                    {
                                        accounts.map((item, index) => {
                                            return (
                                                <tr className="dashboard-transfer-list-table-row">
                                                    <td className="dashboard-transfer-list-table-cell">
                                                        <CheckboxTick checked={Boolean(item.isSelect)} onChecked={val => this.selectAccount(index)} />
                                                    </td>
                                                    <td className="dashboard-transfer-list-table-cell">{`${item.iban} ${item.mainCardNumber !== null ? '/ ' + item.mainCardNumber : ""}`}</td>
                                                    <td className="dashboard-transfer-list-table-cell">{item.currName}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>

                    </div>
                    <div className="dashboard-payment-container-confirmation-section">
                        <div className="dashboard-payment-container-confirmation-section-header">
                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('What is the Instant Payment System?')}</h3>
                        </div>
                        <div className="dashboard-payment-container-confirmation-section-content">
                            <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                            <div className="dashboard-payment-container-confirmation-section-content-description">
                                <p>{t("ips content 1")}</p>
                            </div>
                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                <button disabled={!this.checkAccountSelect()} className={`dashboard-payment-container-data-section-content-container-form-footer-button ${this.checkAccountSelect() ? 'dashboard-payment-container-data-section-content-container-form-footer-button__blue' : 'dashboard-payment-container-data-section-content-container-form-footer-button__disable'} dashboard-payment-container-data-section-content-container-form-footer-button__left`} onClick={() => this.submitIps()}>{t('continue')}</button>

                                <button onClick={() => this.props.history.push('/dashboard/transfer/ips/create-alias')} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('Skip')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default translate("translation")(RegistrationIPS);