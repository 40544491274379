import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import lastOperationsIcon from './../../img/cards-icon.png';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import { request } from './../../../../config';
import { isFuture } from 'date-fns';
import Loading from './../../Loading/Loading';
import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

const initialDate = new Date();
initialDate.setDate(1)

class FriendTransfer extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            transfers: [],
            newTransfer: 0,
            transfer: null,
            startDate: initialDate,
            endDate: new Date(),
            visibleStartDate: null,
            visibleEndDate: null,
            currentPage: 1,
            lastPage: 0
        }
        this.updateDate = this.updateDate.bind(this);
    }

    updateDate(name, value) {
        this.setState({ [name]: value });
    }

    downloadTariffPdf = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadTariffPdf", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Qaydalar') + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getTransfers = () => {
        if (this.props.match.params.type) {
            this.getTransferToFriendList(Number(this.props.match.params.type));
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let getCountTransferToMeRequestBody = customerType == 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),

            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),

            }
            fetch(request("card/getCountTransferToMe", getCountTransferToMeRequestBody))
                .then(res => res.text())
                .then((getCountTransferToMeResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getCountTransferToMeResponse = JSON.parse(getCountTransferToMeResponseJSON);
                    if (getCountTransferToMeResponse.status.statusCode !== 1) {
                        if (getCountTransferToMeResponse.status.statusCode === 200) {
                            this.props.logout();
                        }
                    }
                    else {
                        this.setState({ newTransfer: getCountTransferToMeResponse.quantity });
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    changeDateType = (date) => `${date.split('-')[2]}.${date.split('-')[1]}.${date.split('-')[0]}`;

    getTransferToFriendList = (type) => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getTransferListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            pageNumber: this.state.currentPage,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            pageNumber: this.state.currentPage,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }

        fetch(request(`card/v1/${type === 2 ? 'getTransferToMeList' : 'getTransferToFriendList'}`, getTransferListRequestBody))
            .then(res => res.text())
            .then((getTransferListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferListResponse = JSON.parse(getTransferListResponseJSON);
                if (getTransferListResponse.status.statusCode !== 1 && getTransferListResponse.status.statusCode !== 357) {
                    if (getTransferListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getTransferListResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ isLoading: false });
                    if (getTransferListResponse.status.statusCode === 357) {
                        getTransferListResponse.transferToFriendList = [];
                    } else {
                        this.setState({ lastPage: getTransferListResponse.pageCount });
                        getTransferListResponse.transferToFriendList.map((item, index) => {
                            getTransferListResponse.transferToFriendList[index].type = type;
                            // =========AMOUNT=========
                            // if (getTransferListResponse.transferToFriendList[index].amount.toString() === "0") {
                            if (getTransferListResponse.transferToFriendList[index].dtAmount.toString() === "0") {
                                getTransferListResponse.transferToFriendList[index].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (getTransferListResponse.transferToFriendList[index].dtAmount.toString().indexOf('.') !== -1) {
                                    let amountArray = getTransferListResponse.transferToFriendList[index].dtAmount.toString().split('.');
                                    // getTransferListResponse.transferToFriendList[index].amount.toString().split('.');
                                    getTransferListResponse.transferToFriendList[index].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    getTransferListResponse.transferToFriendList[index].organizedAmount = {
                                        wholePart: getTransferListResponse.transferToFriendList[index].dtAmount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }


                        })
                    }
                    // let today = new Date();
                    // this.setState({ startDate: today.setDate(1), endDate: today, visibleStartDate: today, visibleEndDate: today });
                    this.setState({ transfers: getTransferListResponse.transferToFriendList.filter(item => (item.operStatus !== 0 && item.operStatus !== 2 && item.operStatus !== 3)) });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    confirmReject = (value) => {
        if (value) {
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let rejectTransferToFrienddRequestBody = customerType === 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                cardOperId: this.state.selectedTransfer.cardOperId,
                cardId: this.state.selectedTransfer.dtCardId,
                destCustId: this.state.selectedTransfer.type === 2 ? this.state.selectedTransfer.dtCustId : this.state.selectedTransfer.crCustId
            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                cardOperId: this.state.selectedTransfer.cardOperId,
                cardId: this.state.selectedTransfer.dtCardId,
                destCustId: this.state.selectedTransfer.type === 2 ? this.state.selectedTransfer.dtCustId : this.state.selectedTransfer.crCustId
            }
            fetch(request("card/rejectTransferToFriend", rejectTransferToFrienddRequestBody))
                .then(res => res.text())
                .then((rejectTransferToFrienddResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let rejectTransferToFrienddResponse = JSON.parse(rejectTransferToFrienddResponseJSON);
                    if (rejectTransferToFrienddResponse.status.statusCode !== 1) {
                        if (rejectTransferToFrienddResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                            this.setState({ open: true, popupType: 'error', title: this.props.t('an error occurred'), feedbackMessage: this.props.t(rejectTransferToFrienddResponse.status.statusCode) });
                        }
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        // this.setState({ deletedIndex: this.state. }, () => {
                        this.setState({ open: true, popupType: 'success', title: this.props.t('successful operation'), feedbackMessage: this.props.t('The transfer was successfully deleted') });
                        // });
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => {
            this.getTransferToFriendList(Number(this.props.match.params.type));
        });
    }

    componentDidMount() {
        this.getTransfers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.type !== this.props.match.params.type) {

            this.setState({ currentPage: 1, lastPage: 0 }, () => {
                // this.getTransferToFriendList(Number(this.props.match.params.type));
                this.getTransfers();
            });

            // this.setState({ currentPage: 1, lastPage: 0 });
            // this.getTransfers();
        }
    }

    searchStatements = () => {
        this.setState({ currentPage: 1, lastPage: 0 }, () => this.getTransferToFriendList(Number(this.props.match.params.type)));
    }

    render() {
        let { alertState, t, logout, updateAppState } = this.props;
        let { isLoading, transfers, newTransfer, visibleStartDate, visibleEndDate, } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Friend transfer')}</h2>
                    </div>
                    <button onClick={() => this.downloadTariffPdf()} className="dashboard-header-route-link">{t('Tariffs')}</button>
                </div>

                <div className="dashboard-container-main-section-vertical">
                    <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                        <div style={{ width: '100%', marginBottom: '22px' }}>
                            <div className="dashboard-finance-section-account-extract-section-search-section">
                                <div className=" dashboard-finance-section-account-extract-section-search-section-box">
                                    <StartDatePicker placeholder="Başlama tarixi" updateDate={this.updateDate} />
                                </div>
                                <div className=" dashboard-finance-section-account-extract-section-search-section-box">
                                    <EndDatePicker placeholder="Bitmə tarixi" updateDate={this.updateDate} />
                                </div>
                                <button className="dashboard-finance-section-account-extract-section-search-section-box-button" onClick={this.searchStatements}>{t("Search")}</button>
                            </div>
                            <div className="dashboard-transfer-list-header">
                                <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                    <NavLink exact to="/dashboard/transfer/card-operation/friend-transfer/history/1" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("From me")}</NavLink>
                                    <NavLink exact to="/dashboard/transfer/card-operation/friend-transfer/history/2" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("To me")}
                                        {
                                            Boolean(newTransfer) && <span className='red-circle'></span>
                                        }
                                    </NavLink>
                                </div>
                                <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__right">
                                    <Link to="/dashboard/transfer/card-operation/friend-transfer/create" className="dashboard-transfer-list-header-button" style={{ color: '#FFFFFF' }}>{t("New transfer")}</Link>
                                </div>
                            </div>
                        </div>
                        {
                            isLoading ?
                                <div className="dashboard-finance-section-account-extract-section-main-section-visible-content-section">
                                    <Loading />
                                </div>
                                :
                                transfers && transfers.length !== 0 ?
                                    <div className="dashboard-finance-section-cards-section">
                                        <div className="dashboard-finance-section-cards-section-table-section" style={{ paddingTop: 0 }}>
                                            <table className="dashboard-finance-section-cards-section-table">
                                                <tbody>
                                                    {
                                                        transfers?.map((transfer, index) =>
                                                            <Fragment key={index}>
                                                                <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                    <td className="dashboard-finance-section-cards-section-cell">
                                                                        <Link to={`/dashboard/transfer/card-operation/friend-transfer/${transfer.type === 2 ? 'tome' : 'tofriend'}/${transfer.cardOperId}`} style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-icon-container" style={{ backgroundColor: '#0179D2' }}>
                                                                                {
                                                                                    transfer.type && transfer.type === 2 ?
                                                                                        <svg width="17" height="7" viewBox="0 0 17 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M13.4688 0.34375C13.3438 0.5 13.3438 0.71875 13.4688 0.875L15.0938 2.46875H2.875L4.5 0.875C4.625 0.71875 4.625 0.5 4.5 0.34375L4.25 0.125C4.125 -0.03125 3.875 -0.03125 3.75 0.125L1.09375 2.75C0.9375 2.90625 0.9375 3.125 1.09375 3.28125L3.75 5.90625C3.875 6.0625 4.125 6.0625 4.25 5.90625L4.5 5.6875C4.625 5.53125 4.625 5.3125 4.5 5.15625L2.875 3.53125H15.0938L13.4688 5.15625C13.3438 5.3125 13.3438 5.53125 13.4688 5.6875L13.7188 5.90625C13.8438 6.0625 14.0938 6.0625 14.2188 5.90625L16.875 3.28125C17.0312 3.125 17.0312 2.90625 16.875 2.75L14.2188 0.125C14.0938 -0.03125 13.8438 -0.03125 13.7188 0.125L13.4688 0.34375Z" fill="white" />
                                                                                        </svg>
                                                                                        :
                                                                                        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M16.875 4.28125C17.0312 4.125 17.0312 3.90625 16.875 3.75L13.75 0.625C13.5938 0.46875 13.3438 0.46875 13.2188 0.625L12.5938 1.25C12.4375 1.375 12.4375 1.625 12.5938 1.78125L14.1875 3.25H1.375C1.15625 3.25 1 3.4375 1 3.625V4.375C1 4.59375 1.15625 4.75 1.375 4.75H14.1875L12.5938 6.25C12.4375 6.40625 12.4375 6.65625 12.5938 6.78125L13.2188 7.40625C13.3438 7.5625 13.5938 7.5625 13.75 7.40625L16.875 4.28125ZM1.09375 10.2812L4.21875 13.4062C4.375 13.5625 4.625 13.5625 4.75 13.4062L5.375 12.7812C5.53125 12.6562 5.53125 12.4062 5.375 12.25L3.78125 10.75H16.625C16.8125 10.75 17 10.5938 17 10.375V9.625C17 9.4375 16.8125 9.25 16.625 9.25H3.78125L5.375 7.78125C5.53125 7.625 5.53125 7.375 5.375 7.25L4.75 6.625C4.625 6.46875 4.375 6.46875 4.21875 6.625L1.09375 9.75C0.9375 9.90625 0.9375 10.125 1.09375 10.2812Z" fill="white" />
                                                                                        </svg>
                                                                                }
                                                                            </div>
                                                                            <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                <div className="dashboard-finance-section-account-extract-section-list-extract-item-date-container">
                                                                                    <div className="dashboard-finance-section-account-extract-section-list-extract-item-title">{transfer?.dataDate}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </td>
                                                                    <td className="dashboard-finance-section-cards-section-cell">
                                                                        <Link to={`/dashboard/transfer/card-operation/friend-transfer/${transfer.type === 2 ? 'tome' : 'tofriend'}/${transfer.cardOperId}`} >
                                                                            <p className="dashboard-transfer-history-content-item-container-name">{transfer.type === 2 ? (transfer.dtCustName && transfer.dtCustName) : (transfer.contactName && transfer.contactName)}</p>
                                                                            <p className="dashboard-transfer-history-content-item-container-iban">{transfer.type === 2 ? transfer.dtMobileNumber && `+${transfer.dtMobileNumber}` : (transfer.crMobileNumber && `+${transfer.crMobileNumber}`)}</p>
                                                                        </Link>
                                                                    </td>
                                                                    <td className="dashboard-finance-section-cards-section-cell">
                                                                        <Link to={`/dashboard/transfer/card-operation/friend-transfer/${transfer.type === 2 ? 'tome' : 'tofriend'}/${transfer.cardOperId}`} >
                                                                            <div className="dashboard-transfer-history-content-item-container__right-side">
                                                                                <div className="dashboard-transfer-history-content-item-container-content">
                                                                                    <div className="dashboard-transfer-history-content-item-container-amount">
                                                                                        <span className="dashboard-transfer-history-content-item-container-amount-whole">{transfer.organizedAmount.wholePart && transfer.organizedAmount.wholePart}</span>
                                                                                        <span className="dashboard-transfer-history-content-item-container-amount-fractional">{transfer.organizedAmount.fractionalPart && transfer.organizedAmount.fractionalPart}</span>
                                                                                        <span className="dashboard-transfer-history-content-item-container-amount-currency">{transfer?.dtCCY}</span>
                                                                                    </div>
                                                                                    {
                                                                                        transfer.operStatus === 1 && <p style={{ color: '#61BA66' }} className="dashboard-transfer-history-content-item-container-type">{t('submitted')}</p>
                                                                                    }
                                                                                    {
                                                                                        transfer.operStatus === 4 && <p style={{ color: '#F4B34D' }} className="dashboard-transfer-history-content-item-container-type">{t('in progress')}</p>
                                                                                    }
                                                                                    {
                                                                                        transfer.operStatus === 5 && <p style={{ color: '#EF3E42' }} className="dashboard-transfer-history-content-item-container-type">{t('reject cust')}</p>
                                                                                    }
                                                                                    {
                                                                                        transfer.operStatus === 6 && <p style={{ color: '#EF3E42' }} className="dashboard-transfer-history-content-item-container-type">{t('reject dest cust')}</p>
                                                                                    }
                                                                                    {
                                                                                        transfer.operStatus === 7 && <p style={{ color: '#EF3E42' }} className="dashboard-transfer-history-content-item-container-type">{t('expire date')}</p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </td>
                                                                </tr>

                                                                <tr className="dashboard-finance-row-line">
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                <ul className="dashboard-pagination">
                                                    {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                    {
                                                        Array.from({ length: this.state.lastPage })?.map((item, index) => {
                                                            if (this.state.lastPage > 3) {
                                                                if (this.state.currentPage - 1 == index + 1) {
                                                                    return (
                                                                        <>
                                                                            {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                            {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                            <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                        </>
                                                                    )
                                                                }
                                                                if (this.state.currentPage == index + 1) {
                                                                    return (
                                                                        <>
                                                                            <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                        </>
                                                                    )
                                                                }
                                                                if (this.state.currentPage + 1 == index + 1) {
                                                                    return (
                                                                        <>
                                                                            <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                            {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                            {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                        </>
                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                )
                                                            }
                                                        })

                                                    }
                                                    {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="dashboard-last-operations-section-content" style={{ minHeight: '70vh', paddingBottom: '48px' }}>
                                        <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                        <p style={{ width: '70vh' }} className="dashboard-last-operations-section-content-status">{t('no friend transfer content')}</p>
                                        <Link to='/dashboard/transfer/card-operation/friend-transfer/create' className="dashboard-finance-section-invisible-cards-section-button">{t('Transfer to friend')}</Link>
                                    </div>
                        }
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default translate("translation")(FriendTransfer);
