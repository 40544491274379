import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import cardsIcon from './../../../img/cards-icon.png';
import Dropdown from './../../../../../copms/Dropdown/Dropdown';
import AsanVerification from './../../../../../copms/Modal/AsanVerification';
import SignOperation from './../../../../../copms/Modal/SignOperation';
import { request } from './../../../../../config';
import YesNoModal from '../../../../../copms/Modal/YesNoModal';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class Operations extends Component {
    state = {
        asanVerificationVisibility: true,
        signOperationVisibility: false,
        dropdownItems: [
            { title: this.props.t('Sign with Asan') },
            { title: this.props.t('Delete') }
        ],
        verificationOpen: false,
        verificationCode: '',
        failOperId: [],
        operationOpen: false,
        operationType: 'error',
        operationData: [],
        successOperId: [],
        feedbackMessage: "",
        operation: null,
        signModalOpen: false,
    }
    amountSplit = (amount) => {
        let splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    sendVerification = (batch) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ asanVerificationVisibility: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let phoneNumber = localStorage.getItem('phoneNumber');
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode;
        let getFirstSignInfoRequestBody = customerType == 'Juridical' ? {
            batchId: batch.batchId,
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            userId: localStorage.getItem('userId'),
            phoneNumber: phoneNumber,
            certCode: certCode,
            
        } : {
                batchId: batch.batchId,
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                userId: localStorage.getItem('userId'),
                phoneNumber: phoneNumber,
                certCode: certCode,
                
            };
        fetch(request("operation/getFirstSignInfo", getFirstSignInfoRequestBody))
            .then(res => res.text())
            .then((getFirstSignInfoResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getFirstSignInfoResponse = JSON.parse(getFirstSignInfoResponseJSON);
                if (getFirstSignInfoResponse.status.statusCode !== 1) {
                    if (getFirstSignInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getFirstSignInfoResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ verificationOpen: true, verificationCode: getFirstSignInfoResponse.verificationCode, failOperId: getFirstSignInfoResponse.failOperId, successOperId: getFirstSignInfoResponse.successOperId });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let SignFileRequestBody = customerType == 'Juridical' ? {
                        custId: localStorage.getItem('custId'),
                        compId: localStorage.getItem('compId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        batchId: batch.batchId,
                        userId: localStorage.getItem('userId'),
                        phoneNumber: phoneNumber,
                        certCode: certCode,
                        transactionId: getFirstSignInfoResponse.transactionId,
                        failOperId: getFirstSignInfoResponse.failOperId,
                        successOperId: getFirstSignInfoResponse.successOperId
                    } : {
                            custId: localStorage.getItem('custId'),
                            token: localStorage.getItem('token'),
                            lang: localStorage.getItem('i18nextLng').toUpperCase(),
                            batchId: batch.batchId,
                            userId: localStorage.getItem('userId'),
                            phoneNumber: phoneNumber,
                            certCode: certCode,
                            transactionId: getFirstSignInfoResponse.transactionId,
                            failOperId: getFirstSignInfoResponse.failOperId,
                            successOperId: getFirstSignInfoResponse.successOperId
                        };
                    fetch(request("operation/signFileFirstAuth", SignFileRequestBody))
                        .then(res => res.text())
                        .then((SignFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let SignFileResponse = JSON.parse(SignFileResponseJSON);
                            if (SignFileResponse.status.statusCode !== 1) {
                                if (SignFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.setState({ verificationOpen: false, verificationCode: '', });
                                    this.setState({ operationOpen: true, verificationOpen: false, operationType: 'error', feedbackMessage: this.props.t(SignFileResponse.status.statusCode), operationData: [{ operPurpose: batch.batchName }] });
                                }
                            } else {
                                this.setState({ operationOpen: true, verificationOpen: false, operationType: 'success', operationData: [{ operPurpose: batch.batchName, type: 'success' }] });
                                let oldData = this.props.data;
                                if (oldData.length > 1) {
                                    oldData.splice(oldData.findIndex(el => el.operId === SignFileResponse.successStatus[0].operId - 1), 1);
                                    this.props.updateData(oldData);
                                } else {
                                    this.props.updateData([]);
                                }
                            }
                        });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }
    showPdf = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getPaymentDocByOperIdRequestBody = {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: id
        }
        fetch(request("operation/getPaymentDocByOperId", getPaymentDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getPaymentDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getPaymentDocByOperIdResponse = JSON.parse(getPaymentDocByOperIdResponseJSON);
                if (getPaymentDocByOperIdResponse.status.statusCode !== 1) {
                    if (getPaymentDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getPaymentDocByOperIdResponse.status.statusCode));
                    }
                } else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getPaymentDocByOperIdResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    window.open(link, '');
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    downloadAdoc = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getAsanDocByBatchIdRequestBody = {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            batchId: id
        }
        fetch(request("operation/getAsanDocByBatchId", getAsanDocByBatchIdRequestBody))
            .then(res => res.text())
            .then((getAsanDocByBatchIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAsanDocByBatchIdResponse = JSON.parse(getAsanDocByBatchIdResponseJSON);
                if (getAsanDocByBatchIdResponse.status.statusCode !== 1) {
                    if (getAsanDocByBatchIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAsanDocByBatchIdResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getAsanDocByBatchIdResponse.bytes); //data is the base64 encoded string

                    saveFile(this.props.data.find(el => el.batchId === id).batchName + ".adoc", "application/adoc", arrrayBuffer)
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    dropdownItemHandler = (index, batch) => {
        switch (index) {
            case 0:
                this.sendVerification(batch);
                break;
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.t !== prevProps.t) {
            var vardropdownItems = this.state.dropdownItems;
            vardropdownItems[0].title = this.props.t('Sign with Asan');
            vardropdownItems[1].title = this.props.t('Delete');
            this.setState({ dropdownItems: vardropdownItems });
        }
    }
    confirmSign() {
        this.sendVerification(this.state.operation);
    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    render() {
        let { t, data } = this.props;
        let { dropdownItems, verificationOpen, verificationCode, operationOpen, operationType, operationData, feedbackMessage, signModalOpen } = this.state;
        return (
            <Fragment>
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <SignOperation feedbackMessage={feedbackMessage} open={operationOpen} auth={2} type={operationType} data={operationData} />
                <YesNoModal open={signModalOpen} title={t('Are you sure you want to sign?')} onClose={() => this.setState({ signModalOpen: false })} triggerFunc={val => val && this.confirmSign()} />
                {
                    data && data.length > 0 ?
                        <Fragment>
                            <div className="dashboard-transfer-list-content">
                                <table className="dashboard-transfer-list-table">
                                    <thead className="dashboard-transfer-list-table-head">
                                        <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("DOCUMENT NAME")}</th>
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("DOCUMENT TYPE")}</th>
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("DOCUMENT COUNT")}</th>
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">ADOC</th>
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>

                                        </tr>
                                    </thead>
                                    <tbody className="dashboard-transfer-list-table-body">
                                        {
                                            data.map((operation, index) => {
                                                return (
                                                    <tr className="dashboard-transfer-list-table-row" key={index}>
                                                        <td className="dashboard-transfer-list-table-cell">{operation.createdDate.split(" ")[0]}</td>
                                                        <td className="dashboard-transfer-list-table-cell">{operation.batchName}</td>
                                                        <td className="dashboard-transfer-list-table-cell">{operation.batchType}</td>
                                                        <td className="dashboard-transfer-list-table-cell">{operation.docCount}</td>
                                                        <td className="dashboard-transfer-list-table-cell">
                                                            <button onClick={this.downloadAdoc.bind(this, operation.batchId)}>
                                                                <svg className="dashboard-transfer-list-table-icon dashboard-transfer-list-table-icon__download" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#586268" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                        <td className="dashboard-transfer-list-table-cell">
                                                            <button onClick={() => this.setState({operation: operation, signModalOpen: true})}>{t('Sign')}</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                        :
                        <div className="dashboard-finance-section-invisible-cards-section">
                            <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                            <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any active transactions.')}</p>
                            </div>
                            <Link to="/dashboard/transfer/transfers/all" style={{ textDecoration: 'none' }} className="dashboard-finance-section-invisible-cards-section-button">{t('transfers')}</Link>
                        </div>
                }

            </Fragment>
        )
    }
}
export default translate("translation")(Operations);