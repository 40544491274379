import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { request } from "./../../../../config";
import headerLogo from './../../img/header-logo.svg';
import userIconForm from "./../../img/user-icon-form.svg";
import eyeIconForm from "./../../img/eye-icon-form.svg";
import eyeSlashIconForm from "./../../img/eye-slash-icon-form.svg";
import { translate } from 'react-i18next';

class SetUsernamePassword extends Component {
    state = {
        isPasswordVisibility: false,
        isRepeatPasswordVisibility: false,
        isDisabled: true,
        feedbackMessage: null
    }
    backCheckCustomer = () => {
        this.props.history.push('/registration/juridical/mobile/1');
    }
    checkIsNotNull = () => {
        if (this.username.value.trim() !== "" && this.password.value.trim() !== "" && this.repeatPassword.value.trim() !== "") {
            this.setState({ isDisabled: false });
        }
        else {
            this.setState({ isDisabled: true });
        }
    }

    setPasswordVisibility = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    registerJuridicalMobile = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let registerJuridicalMobileRequestBody = {
            username: this.username.value,
            password: this.password.value,
            repeatPassword: this.repeatPassword.value,
            pin: this.props.location.state.pin,
            taxNo: this.props.location.state.taxNo,
            verifyToken: this.props.location.state.verifyToken
        };
        fetch(request("register/registerJuridicalCustomerv1", registerJuridicalMobileRequestBody))
        .then(res => res.json())
        .then((registerJuridicalMobileResponse) => {
            if (registerJuridicalMobileResponse.status.statusCode === 1) {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.props.history.push('/registration/juridical/mobile/4', {});
            }
            else {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({ feedbackMessage: this.props.t(registerJuridicalMobileResponse.status.statusCode)});
            }
        },
        (error) => {
            this.props.history.push("/technical-break");
        });
    }

    componentDidMount = () => {
        if(this.props.location.state === undefined) {
            this.props.history.push('/registration/juridical/mobile/1');
        }
    }

    render() {
        let { isPasswordVisibility, isRepeatPasswordVisibility, feedbackMessage, isDisabled } = this.state;
        let { t } = this.props;

        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container">
                    <h2 className="registration-container-title">{t('complate registration')}</h2>
                    <p className="registration-container-description">{t('enter your username and password to complete the registration')}</p>
                    <p className="registration-container-description">{t('password content')}</p>
                    <div className="registration-container-form">
                        <div className="registration-container-form-group">
                            <label className="registration-container-form-group-label">{t('username')}</label>
                            <div className="registration-container-form-group-box">
                                <input type="text" autoComplete="off" className="registration-container-form-group-box-input" placeholder={t('enter the username')} ref={(input) => { this.username = input }} onInput={() => { this.username.value = this.username.value.trim(); this.checkIsNotNull()}} />
                                <div className="registration-container-form-group-box-icon">
                                    <img src={userIconForm} className="registration-container-form-group-box-icon-img  registration-container-form-group-box-icon-img__user" alt="userIconForm" />
                                </div>
                            </div>
                        </div>
                        <div className="registration-container-form-group" onClick={this.setPasswordVisibility}>
                            <label className="registration-container-form-group-label">{t('password')}</label>
                            <div className="registration-container-form-group-box">
                                <input type={"text"} autoComplete="off" className={`registration-container-form-group-box-input ${isPasswordVisibility === false && "registration-container-form-group-box-input-password"}`} placeholder={t('enter the password')} ref={(input) => { this.password = input }} onInput={this.checkIsNotNull} />
                                <div className="registration-container-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isPasswordVisibility')}>
                                {
                                    isPasswordVisibility === false ?
                                    <img src={eyeIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password" alt="" />
                                    :
                                    <img src={eyeSlashIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password-slash" alt="" />
                                }
                                </div>
                            </div>
                        </div>
                        <div className="registration-container-form-group" onClick={this.setPasswordVisibility}>
                            <label className="registration-container-form-group-label">{t('repeat password')}</label>
                            <div className="registration-container-form-group-box">
                                <input type={"text"} autoComplete="off" className={`registration-container-form-group-box-input ${isRepeatPasswordVisibility === false && "registration-container-form-group-box-input-password"}`} placeholder={t('enter the password again')} ref={(input) => { this.repeatPassword = input }} onInput={this.checkIsNotNull} />
                                <div className="registration-container-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isRepeatPasswordVisibility')}>
                                {
                                    isRepeatPasswordVisibility === false ?
                                    <img src={eyeIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password" alt="" />
                                    :
                                    <img src={eyeSlashIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password-slash" alt="" />
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="registration-container-validation-feedback">
                        {
                            feedbackMessage ? <p className="registration-container-validation-feedback-message">{feedbackMessage}</p> : null
                        }
                    </div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backCheckCustomer}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled} onClick={this.registerJuridicalMobile}>{t('next')}</button>
                    </div>
                </form>
                <div className="registration-range">
                    <p className="registration-range-fraction">
                        <span className="registration-range-fraction-numerator">2</span>/3
                </p>
                    <div className="registration-range-container">
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                            <div className="registration-range-container-step-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(SetUsernamePassword);