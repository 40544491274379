import React, { Component } from 'react';
import {
    Link,
    Redirect
} from "react-router-dom";
import AsanConfirmationIcon from "./img/asan-confirmation-icon.svg";
import headerLogo from "./img/header-logo.svg";
import { request } from "../../config";
import { translate } from 'react-i18next';

class AsanJuridicalLoginConfirmation extends Component {
    state = {
        isDisabled: true,
        verificationCode: null
    }

    prevPage = () => {
        // this.props.history.push('/login/juridical/asan/');
        window.location.href = '/login/juridical/asan/';
    }

    componentDidMount() {
        this.props.updateAppState('isLoadingTransparentBackground', false);
        if(this.props.location.state === undefined) {
            // this.props.history.push('/login/juridical/asan/');
            window.location.href = '/login/juridical/asan/';
        }
        else {
            let {loginJuridicalForAsanRequestBody, getAuthInfoJuridicalLoginRequestBody} = this.props.location.state;
            // let getAuthInfoJuridicalLoginRequestBody = this.props.location.state.getAuthInfoJuridicalLoginRequestBody;
            this.setState({ verificationCode: loginJuridicalForAsanRequestBody.verificationCode });
            fetch(request("register/loginJuridicalForAsan", loginJuridicalForAsanRequestBody))
                .then(res => res.text())
                .then((loginJuridicalForAsanResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let loginJuridicalForAsanResponse = JSON.parse(loginJuridicalForAsanResponseJSON);
                    if (loginJuridicalForAsanResponse.status.statusCode !== 1) {
                        localStorage.removeItem('userId');
                        localStorage.removeItem('phoneNumber');
                        this.props.history.push('/login/juridical/asan/', this.props.t(loginJuridicalForAsanResponse.status.statusCode));
                   
                    }
                    else {
                        let selectCustCompanyRequestBody = {
                            location: 'location',
                            device: 'device',
                            lang: getAuthInfoJuridicalLoginRequestBody.lang,
                            mobile: getAuthInfoJuridicalLoginRequestBody.phoneNumber,
                            userId: getAuthInfoJuridicalLoginRequestBody.userId,
                        }
                        this.props.history.push('/login/juridical/asan/select-company', {selectCustCompanyRequestBody, loginJuridicalForAsanRequestBody, loginJuridicalForAsanResponse});
                    }
                },
                    (error) => {
                        localStorage.removeItem('userId');
                        localStorage.removeItem('phoneNumber');
                        this.props.history.push("/technical-break");
                    }
                );
        }
    }

    render() {
        let { isAuthed, t } = this.props;
        let { isDisabled, verificationCode } = this.state;

        if (isAuthed) {
            return <Redirect to='/dashboard/home' />
        }

        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <div className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title registration-container-title--center">{t('asan signature confirmation')}</h2>
                    <p className="registration-container-description registration-container-description--center">{t('please accept the request sent to your phone')}</p>
                    <img src={AsanConfirmationIcon} className="registration-container-icon" alt="AsanConfirmationIcon" />
                    <div className="registration-container-asan-confirmation">
                        <span className="registration-container-asan-confirmation-code">{verificationCode}</span>
                    </div>
                    <div className="registration-container-validation-feedback"></div>
                    <div className="registration-container-footer">
                        <button className="registration-container-footer-button registration-container-footer-button--left" onClick={this.prevPage}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled}>{t('next')}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(AsanJuridicalLoginConfirmation);