import React, { Component } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { daysInMonth, firstDayOfMonth, months } from "./date";
import DatePicker from './DatePicker';
import "./style.scss";

class Calendar extends Component {
    constructor(props) {
        super(props);

        const now = new Date();
        this.state = {
            month: now.getMonth(),
            now: now,
            displayMonthSelector: false,
            now_old: new Date(),
        };
    }

    setDateClick(day) {
        var now = this.state.now;
        now.setDate(day);
        this.runningService();
    }

    runningService() {
        this.props.onSelect(this.state.now);
    }

    filterCase(index) {
        switch (index) {
            case 0:
                return this.props.t("january");
            case 1:
                return this.props.t("february");
            case 2:
                return this.props.t("march");
            case 3:
                return this.props.t("april");
            case 4:
                return this.props.t("may");
            case 5:
                return this.props.t("june");
            case 6:
                return this.props.t("july");
            case 7:
                return this.props.t("august");
            case 8:
                return this.props.t("september");
            case 9:
                return this.props.t("october");
            case 10:
                return this.props.t("november");
            case 11:
                return this.props.t("december");
        }
    }

    render() {
        const days = Array.from({ length: daysInMonth(this.state.now, this.state.month) }, (k, v) => v + 1);
        const dayToBeginTheMonthFrom = firstDayOfMonth(this.state.now, this.state.month);
        const currentDate = this.state.now.getDate();
        const style = { gridColumnStart: dayToBeginTheMonthFrom, };
        let { t } = this.props;
        return (
            <div className="custom-calendar ">
                <div className="head">
                    <h3>{this.state.now.getDate()} {this.filterCase(this.state.now.getMonth())} {this.state.now.getFullYear()}</h3>

                    <DatePicker updateDate={(e, val) => this.setState({ now: val, month: val.getMonth() }, () => this.runningService())} />
                    {/* <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.625 9C4.8125 9 5 8.84375 5 8.625V7.375C5 7.1875 4.8125 7 4.625 7H3.375C3.15625 7 3 7.1875 3 7.375V8.625C3 8.84375 3.15625 9 3.375 9H4.625ZM8 8.625V7.375C8 7.1875 7.8125 7 7.625 7H6.375C6.15625 7 6 7.1875 6 7.375V8.625C6 8.84375 6.15625 9 6.375 9H7.625C7.8125 9 8 8.84375 8 8.625ZM11 8.625V7.375C11 7.1875 10.8125 7 10.625 7H9.375C9.15625 7 9 7.1875 9 7.375V8.625C9 8.84375 9.15625 9 9.375 9H10.625C10.8125 9 11 8.84375 11 8.625ZM8 11.625V10.375C8 10.1875 7.8125 10 7.625 10H6.375C6.15625 10 6 10.1875 6 10.375V11.625C6 11.8438 6.15625 12 6.375 12H7.625C7.8125 12 8 11.8438 8 11.625ZM5 11.625V10.375C5 10.1875 4.8125 10 4.625 10H3.375C3.15625 10 3 10.1875 3 10.375V11.625C3 11.8438 3.15625 12 3.375 12H4.625C4.8125 12 5 11.8438 5 11.625ZM11 11.625V10.375C11 10.1875 10.8125 10 10.625 10H9.375C9.15625 10 9 10.1875 9 10.375V11.625C9 11.8438 9.15625 12 9.375 12H10.625C10.8125 12 11 11.8438 11 11.625ZM14 3.5C14 2.6875 13.3125 2 12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H9.375C9.15625 0 9 0.1875 9 0.375V2H5V0.375C5 0.1875 4.8125 0 4.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5ZM12.5 14.3125C12.5 14.4375 12.4062 14.5 12.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V5H12.5V14.3125Z" fill="#586268" />
                    </svg> */}
                </div>
                <div className="body">
                    <div className="weeks days-grid">
                        <span className="day">{t('Mo')}</span>
                        <span className="day">{t('Tu')}</span>
                        <span className="day">{t('We')}</span>
                        <span className="day">{t('Th')}</span>
                        <span className="day">{t('Fr')}</span>
                        <span className="day">{t('Sa')}</span>
                        <span className="day">{t('Su')}</span>
                    </div>
                    <div className="days-grid">
                        {
                            days.map((day, i) => {
                                return (
                                    <button
                                        // disabled={this.state.now.getTime() >= this.state.now_old.getTime() }
                                        onClick={e => this.setDateClick(day)}
                                        key={i}
                                        style={i === 0 ? { cursor: 'default', gridColumnStart: dayToBeginTheMonthFrom } : {}}
                                        className={`day ${i === 0 ? "first-day" : ""}
                                      ${day === currentDate ? "today" : ""}
                                      ${
                                            (i + dayToBeginTheMonthFrom) % 7 === 0 ||
                                                (i + dayToBeginTheMonthFrom) % 7 === 6
                                                ? "holiday"
                                                : ""
                                            }
                                      `}
                                    // style={i === 0 ? style : {}}
                                    >
                                        {day}
                                    </button>
                                );
                            })
                        }
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate("translation")(Calendar);