import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import StatementCard from './StatementCard';
import StatementAccount from './StatementAccount';
import OrderStatement from './OrderStatement';
import OrderRequisite from './OrderRequisite';
import History from './History';
import NotFound from './../../../copms/NotFound/NotFound';

export default class AccountStatementRequisites extends Component {
    render() {
        let { updateAppState, updateDashboardState, alertState, logout } = this.props;
        return (
            <Switch>
                <Redirect exact from="/dashboard/services/account-statement-requisites" to="/dashboard/services/account-statement-requisites/card"/>
                <Route path="/dashboard/services/account-statement-requisites/card" render={(routeProps) => <StatementCard logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route path="/dashboard/services/account-statement-requisites/account" render={(routeProps) => <StatementAccount logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route path="/dashboard/services/account-statement-requisites/order-statement/:type" render={(routeProps) => <OrderStatement logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route path="/dashboard/services/account-statement-requisites/order-requisite/:type" render={(routeProps) => <OrderRequisite logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route path="/dashboard/services/account-statement-requisites/history/:type" render={(routeProps) => <History logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route render={(routeProps) => (<NotFound />)} />
            </Switch>
        )
    }
}
