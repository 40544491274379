
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from './../../../config';
import Loading from './../Loading/Loading';
class Tariffs extends Component {
    state = {
        isLoading: false,
        tariffInfoList: [],
        title: null
    }

    recFunc(list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === Number(this.props.match.params.id)) {
                this.setState({ 
                    tariffInfoList: list[i].childInfos !== null ? list[i].childInfos : list[i],
                    title: list[i].name
                 });
                break;
            }
            if (list[i].childInfos !== null && list[i].childInfos.length > 0) {
                this.recFunc(list[i].childInfos);
            }
        }
    }

    getTariffInfoList = async () => {
        this.setState({ isLoading: true, title: this.props.t('tariffs and limits') });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getTariffInfoListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        await fetch(request("util/getTariffInfoList", getTariffInfoListRequestBody))
            .then(res => res.text())
            .then((getTariffInfoListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTariffInfoListResponse = JSON.parse(getTariffInfoListResponseJSON);
                if (getTariffInfoListResponse.status.statusCode !== 1) {
                    if (getTariffInfoListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getTariffInfoListResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ tariffInfoList: getTariffInfoListResponse.tariffInfoList }, () => {
                        if (this.props.match.params.id !== undefined) {
                            this.recFunc(this.state.tariffInfoList);
                        }
                    });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount() {
        this.getTariffInfoList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getTariffInfoList();
        }
    }

    replaceAll(str) {
        if (str) {
            return str.replace(new RegExp("<p>&nbsp;</p>", 'g'), '').replace(new RegExp("<br />", 'g'), '');
        }
        else {
            return "";
        }
    }

    render() {
        let { t } = this.props;
        let { isLoading, tariffInfoList, title } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{!isLoading && title}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">
                            <ul className="dashboard-tariff-list">
                                {
                                    tariffInfoList && tariffInfoList?.length > 0 ?
                                        tariffInfoList.map((item, index) => (
                                            <li className="dashboard-tariff-list-item dashboard-tariff-list-item--clickable" key={index}>
                                                <Link to={`/dashboard/service-network/tariffs/${item.id}`}>
                                                    <p className="dashboard-tariff-list-item-title">{item.name}</p>
                                                    <p className="dashboard-tariff-list-item-description">
                                                        {
                                                            item.childInfos &&
                                                            item.childInfos.map((childItem, childIndex) => (`${childItem.name}${childIndex + 1 < item.childInfos.length ? ', ' : ''}`))
                                                        }
                                                    </p>
                                                </Link>
                                            </li>
                                        ))
                                        :
                                        <>
                                            <li className="dashboard-tariff-list-item">
                                                <p className="dashboard-tariff-list-item-title dashboard-tariff-list-item-title--bold">{t('tariff')}</p>
                                                <p className="dashboard-tariff-list-item-description" dangerouslySetInnerHTML={{ __html: this.replaceAll(tariffInfoList.tariff) }}></p>
                                            </li>
                                            <li className="dashboard-tariff-list-item">
                                                <p className="dashboard-tariff-list-item-title dashboard-tariff-list-item-title--bold">{t('limit')}</p>
                                                <p className="dashboard-tariff-list-item-description" dangerouslySetInnerHTML={{ __html: this.replaceAll(tariffInfoList.limit) }}></p>
                                            </li>
                                        </>
                                }
                            </ul>
                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(Tariffs);