import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from './../../../config';
import AlertPopup from './../../../copms/AlertPopup/AlertPopup';
import OutsideClickHandler from 'react-outside-click-handler';
import transferConfirmation from './../img/transfer-confirmation.svg';
import cardImg from './../img/newVisa.svg';
import branchImg from './../img/branch.svg';
import { statusCodes } from '../../../helpers/statusCodes';
import RadioButton from "../../../copms/RadioButton/RadioButtonSolid";
import CardOrderModal from '../../../copms/Modal/CardOrderModal';
import FatchaInfoModal from '../../../copms/Modal/FatchaInfoModal';
import pdfImg from '../img/pdf-new.svg'
import $ from 'jquery';
import AsanVerification from '../../../copms/Modal/AsanVerificationOrderDebitCard';
import OrderSuccessModal from '../../../copms/Modal/OrderSuccessModal';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

class PlasticCard extends Component {
    state = {
        dropdownVisibilityBranch: false,
        dropdownVisibilityPeriod: false,
        dropdownVisibilityCardType: false,
        dropdownVisibilityCardKind: false,
        dropdownVisibilityCurrency: false,
        dropdownVisibilityPaymentType: false,
        branchs: [],
        periods: this.props.location.state?.periods,
        cardTypes: [],
        cardKinds: [],
        currencies: this.props.location.state?.currencies,
        selectedBranch: this.props.location.state?.selectedBranch,
        selectedCardType: null,
        selectedCardKind: "",
        selectedCardKindImg: null,
        selectedCurrency: "",
        card: this.props.location.state?.card,
        step: this.props.location.state?.step || 1,
        selectedCardTypeId: null,
        branchId: null,
        periodId: null,
        cardTypeId: null,
        cardKindId: null,
        open: false,
        paymentMethods: this.props.location.state?.paymentMethods,
        deliveryTypes: this.props.location.state?.deliveryTypes,
        branches: this.props.location.state?.branches,
        branchIdCont: 0,
        periodIdCont: 0,
        paymentTypeCont: 0,
        cardTypeIdCont: 0,
        cardKindIdCont: 0,
        currencyIdCont: 0,
        paymentCardOrAccountIdCont: 0,

        // new
        openCardSelection: false,
        selectedDeliveryType: this.props.location.state?.deliveryTypes?.[0] || this.props.location.state?.selectedDeliveryType || null,
        orderId: this.props.location.state?.orderId,
        contract: this.props.location.state?.contract,
        fatcha: this.props.location.state?.fatcha,
        dropdownVisibilityPayment: false,
        paymentTypes: this.props.location.state?.paymentTypes,
        cards: this.props.location.state?.cards,
        accounts: this.props.location.state?.accounts,
        selectedTypeForPayment: this.props.location.state?.paymentTypes?.[0],
        showInfo: false,
        successModal: this.props.location.state?.successModal,
        successModalData: this.props.location.state?.successModalData,
        showCardInfo: false,
        showCardPrice: this.props.location.state?.showCardPrice,
        showCardPeriod: this.props.location.state?.showCardPeriod,
        tariffDesc: this.props.location.state?.tariffDesc,
        showCardCurrency: this.props.location.state?.showCardCurrency,

        currencyId: this.props.location.state?.currency || null,
        selectedPeriod: this.props.location.state?.period || null,
        selectedPaymentType: this.props.location.state?.paymentMethod || null,
        secretWord: this.props.location.state?.secretWord || null,
        pin: this.props.location.state?.pin || false,
        address: this.props.location.state?.address || null,
        currencyName: this.props.location.state?.currencyName || null,
    }
    resetForm = () => {
        this.setState({
            selectedBranch: "",
            selectedPeriod: "",
            selectedCardType: "",
            selectedCardKind: "",
            selectedCurrency: "",
            selectedCardTypeId: null,
            selectedCardKindImg: null,
            card: null
        });
    }

    updateBranch(index, event) {
        this.setState({ branchId: index, selectedBranch: event.target.textContent, dropdownVisibilityBranch: false });
    }

    updatePeriod(index, event) {
        this.setState({ periodId: index, selectedPeriod: event.target.textContent, dropdownVisibilityPeriod: false });
    }

    updateCardType() {
        // this.setState({ cardKindId: null, selectedCardKind: "", periodId: null, selectedPeriod: "", selectedCardTypeId: event.target.value });
        // this.setState({  selectedCardType: event.target.textContent, dropdownVisibilityCardType: false }, () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        if (this.state.selectedCardType.cardType == 1) {
            let getOrderPeriodListRequestBody = {
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                orderKindType: this.state.selectedCardType.id
            }
            fetch(request("order/cards", getOrderPeriodListRequestBody))
                .then(res => res.text())
                .then((getOrderPeriodListResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getOrderPeriodListResponse = JSON.parse(getOrderPeriodListResponseJSON);
                    if (getOrderPeriodListResponse.status.statusCode !== 1) {
                        if (getOrderPeriodListResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            getOrderPeriodListResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
                                this.props.alertState(
                                    true,
                                    "warning",
                                    this.props.t("Information"),
                                    this.props.t(getOrderPeriodListResponse.status.statusCode),
                                    null,
                                    true
                                ) :
                                this.props.alertState(
                                    true,
                                    'error',
                                    this.props.t('an error occurred'),
                                    this.props.t(getOrderPeriodListResponse.status.statusCode),
                                    null,
                                    true
                                )
                        }
                    }
                    else {
                        this.setState({ cardKinds: getOrderPeriodListResponse.cards });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
        else {
            let getOrderPeriodListRequestBody = {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                orderType: 3,
                kindType: this.state.selectedCardType.id
            }
            if (localStorage.getItem('compId')) {
                getOrderPeriodListRequestBody.compId = localStorage.getItem('compId');
            }
            fetch(request("order/getOrderPeriodList/", getOrderPeriodListRequestBody))
                .then(res => res.text())
                .then((getOrderPeriodListResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getOrderPeriodListResponse = JSON.parse(getOrderPeriodListResponseJSON);
                    if (getOrderPeriodListResponse.status.statusCode !== 1) {
                        if (getOrderPeriodListResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            getOrderPeriodListResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
                                this.props.alertState(
                                    true,
                                    "warning",
                                    this.props.t("Information"),
                                    this.props.t(getOrderPeriodListResponse.status.statusCode),
                                    null,
                                    true
                                ) :
                                this.props.alertState(
                                    true,
                                    'error',
                                    this.props.t('an error occurred'),
                                    this.props.t(getOrderPeriodListResponse.status.statusCode),
                                    null,
                                    true
                                )
                        }
                    }
                    else {
                        this.setState({ periodsCredit: getOrderPeriodListResponse.respOrderPeriodList });
                        fetch(request("order/getOrderKindList/", getOrderPeriodListRequestBody))
                            .then(res => res.text())
                            .then((getOrderKindListResponseJSON) => {
                                const JSON = require('true-json-bigint');
                                let getOrderKindListResponse = JSON.parse(getOrderKindListResponseJSON);
                                if (getOrderKindListResponse.status.statusCode !== 1) {
                                    if (getOrderKindListResponse.status.statusCode === 200) {
                                        this.props.logout();
                                    } else {
                                        this.props.updateAppState('isLoadingTransparentBackground', false);
                                        getOrderKindListResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
                                            this.props.alertState(
                                                true, 'warning',
                                                this.props.t('Information'),
                                                this.props.t(getOrderKindListResponse.status.statusCode),
                                                null,
                                                true
                                            ) :
                                            this.props.alertState(
                                                true, 'error',
                                                this.props.t('an error occurred'),
                                                this.props.t(getOrderKindListResponse.status.statusCode),
                                            )
                                    }
                                }
                                else {
                                    this.setState({ cardKinds: getOrderKindListResponse.respOrderTypeList });
                                    this.props.updateAppState('isLoadingTransparentBackground', false);
                                }
                            },
                                (error) => {
                                    this.props.history.push("/technical-break");
                                });
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
        // });
    }
    updateCardKindDebet = (cardInfo) => {
        // const newCard = this.state.cardKinds.find((card) => card.cardId == cardInfo.cardId)
        this.setState({ card: cardInfo, openCardSelection: false });
    }
    // updateCardKind(index, event) {
    //     this.setState({ card: null, cardKindId: index, selectedCardKind: event.target.textContent, selectedCardKindImg: null, dropdownVisibilityCardKind: false });
    // }
    updateCurrency(index, event) {
        this.setState({ currencyId: index, selectedCurrency: event.target.textContent, dropdownVisibilityCurrency: false });
    }
    // changeDropdownVisibilityBranch = (event) => {

    // }
    // changeDropdownVisibilityPeriod = (event) => {

    // }
    // changeDropdownVisibilityCardType = (event) => {

    // }
    // changeDropdownVisibilityCardKind = (event) => {

    // }
    // changeDropdownVisibilityCurrency = (event) => {

    // }
    // ==

    async getDropdownsData() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getBranchMapListequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (localStorage.getItem('compId')) {
            getBranchMapListequestBody.compId = localStorage.getItem('compId');
        }
        await fetch(request("util/getBranchMapList/" + localStorage.getItem('i18nextLng').toUpperCase(), getBranchMapListequestBody))
            .then(res => res.text())
            .then((getBranchMapListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBranchMapListResponse = JSON.parse(getBranchMapListResponseJSON);
                if (getBranchMapListResponse.status.statusCode !== 1) {
                    if (getBranchMapListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBranchMapListResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ branchs: getBranchMapListResponse.respBranchMapList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        let getOrderParamsListRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            orderType: 3
        }
        if (localStorage.getItem('compId')) {
            getOrderParamsListRequestBody.compId = localStorage.getItem('compId');
        }
        await fetch(request("order/getOrderKindTypeList/", getOrderParamsListRequestBody))
            .then(res => res.text())
            .then((getOrderKindTypeListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOrderKindTypeListResponse = JSON.parse(getOrderKindTypeListResponseJSON);
                if (getOrderKindTypeListResponse.status.statusCode !== 1) {
                    if (getOrderKindTypeListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getOrderKindTypeListResponse.status.statusCode));
                    }
                }
                else {
                    let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
                    let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode
                    this.setState(certCode ? { cardTypes: getOrderKindTypeListResponse.respOrderTypeList.filter(card => card.cardType != 2) } : { cardTypes: getOrderKindTypeListResponse.respOrderTypeList.filter(card => card.cardType != 1 && card.cardType != 2) });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        let exchangeRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            type: 0
        }
        if (localStorage.getItem('compId')) {
            exchangeRequestBody.compId = localStorage.getItem('compId');
        }
        await fetch(request("exchange/cashlessCurrency/", exchangeRequestBody))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (exchangeResponse.exchangeCashlessList.findIndex((item, index) => item.currency === 'AZN') === -1) {
                        exchangeResponse.exchangeCashlessList.push({ currency: 'AZN' });
                    }
                    exchangeResponse.exchangeCashlessList.sort((prev, next) => {
                        return (prev.currency < next.currency) ? -1 : (prev.currency > next.currency) ? 1 : 0;
                    })
                    this.setState({ currencies: exchangeResponse.exchangeCashlessList });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

        this.props.updateAppState('isLoadingTransparentBackground', false);

    }

    orderCard = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createOrderRequestBody = (customerType === 'Individual') ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            branchId: this.state.branchs[this.state.branchId]?.branchId,
            orderType: 3,
            kindId: this.state.card?.id,
            periodId: this.state.periodsCredit[this.state.periodId]?.id,
            ccy: this.state.currencies[this.state.currencyId]?.currency
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            branchId: this.state.branchs[this.state.branchId]?.branchId,
            orderType: 3,
            kindId: this.state.card?.id,
            periodId: this.state.periodsCredit[this.state.periodId]?.id,
            ccy: this.state.currencies[this.state.currencyId]?.currency
        }
        fetch(request("order/createOrder" + customerType, createOrderRequestBody))
            .then(res => res.text())
            .then((createOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createOrderResponse = JSON.parse(createOrderResponseJSON);
                if (createOrderResponse.status.statusCode !== 1) {
                    if (createOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({
                            open: true,
                            popupType: 'error',
                            title: this.props.t('an error occurred'),
                            feedbackMessage: this.props.t(createOrderResponse.status.statusCode)
                        });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({
                        open: true,
                        popupType: 'success',
                        title: this.props.t('successful operation'),
                        feedbackMessage: this.props.t('Your order has been sent successfully')
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    closeSuccessFunction = () => {
        this.props.history.push('/');

        // this.setState({
        //     dropdownVisibilityBranch: false,
        //     dropdownVisibilityPeriod: false,
        //     dropdownVisibilityCardType: false,
        //     dropdownVisibilityCardKind: false,
        //     dropdownVisibilityCurrency: false,
        //     periods: [],
        //     cardKinds: [],
        //     selectedBranch: "",
        //     selectedPeriod: "",
        //     selectedCardType: "",
        //     selectedCardKind: "",
        //     selectedCurrency: "",
        //     branchId: null,
        //     periodId: null,
        //     cardTypeId: null,
        //     cardKindId: null,
        //     currencyId: null,
        // });
    }

    componentDidMount() {
        this.setState({ step: this.props.location.state?.step || 1 }, () => {
            if (this.state.step == 1) {
                this.getDropdownsData();
            }
            else if (this.state.step == 2) {
                this.setState({
                    questions: this.props.location.state?.questions, answers: this.props.location.state?.questions?.map(question => ({
                        id: question.id,
                        answer: false
                    })),
                })
            }

        })
    }
    // setAmountOutline = () => {
    //     if (!this.state.amount.length > 0) {
    //         this.setState({ amountOutline: true });
    //     } else {
    //         this.setState({ amountOutline: false });
    //     }
    // };

    addCardOrder = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getAddCardOrderRequestBody = {
            cardId: this.state.card?.cardId,
            token: localStorage.getItem('token'),
        }
        fetch(request("order/addCardOrder", getAddCardOrderRequestBody))
            .then(res => res.text())
            .then((getAddCardOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAddCardOrderResponse = JSON.parse(getAddCardOrderResponseJSON);
                if (getAddCardOrderResponse.status.statusCode !== 1) {
                    if (getAddCardOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAddCardOrderResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);

                    }
                }
                else {
                    this.setState({ orderId: getAddCardOrderResponse.orderId }, () =>
                        this.props.history.push("plastic-card/otp", { orderId: this.state.orderId, selectedCardTypeId: this.state.selectedCardType.id, card: this.state.card })
                    );
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    addFatcha = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getAddCardOrderRequestBody = {
            orderId: this.state.orderId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            answers: this.state.answers
        }
        fetch(request("order/fatcaQuestionnaire", getAddCardOrderRequestBody))
            .then(res => res.text())
            .then((getAddCardOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAddCardOrderResponse = JSON.parse(getAddCardOrderResponseJSON);
                if (getAddCardOrderResponse.status.statusCode !== 1) {
                    if (getAddCardOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else if (getAddCardOrderResponse.status.statusCode === 20000) {
                        this.setState({ fatchaInfoTitle: getAddCardOrderResponse.status.statusMessage, fatchaInfo: true })
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAddCardOrderResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ step: 3, currencies: getAddCardOrderResponse.payload.currencies, periods: getAddCardOrderResponse.payload.periods, paymentMethods: getAddCardOrderResponse.payload.paymentMethods, deliveryTypes: getAddCardOrderResponse.payload.deliveryTypes, selectedDeliveryType: getAddCardOrderResponse.payload.deliveryTypes[0], branches: getAddCardOrderResponse.payload.branches })
                    this.props.history.replace(this.props.location?.pathname, { ...this.props.location.state, step: 3, currencies: getAddCardOrderResponse.payload.currencies, periods: getAddCardOrderResponse.payload.periods, paymentMethods: getAddCardOrderResponse.payload.paymentMethods, deliveryTypes: getAddCardOrderResponse.payload.deliveryTypes, selectedDeliveryType: getAddCardOrderResponse.payload.deliveryTypes[0], branches: getAddCardOrderResponse.payload.branches });
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    addStep = (showCardInfo) => {
        if (showCardInfo) {
            this.addCardOrderDeliveryAndPayment()

            // this.setState({ step: 4 }, () => {
            //     this.props.history.replace(this.props.location?.pathname, {
            //         ...this.props.location.state,
            //         orderId: this.state.orderId,
            //         currency: this.state.currencyId,
            //         currencyName: this.state.currencyName,
            //         period: this.state.selectedPeriod,
            //         paymentMethod: this.state.selectedPaymentType,
            //         secretWord: this.state.secretWord,
            //         // deliveryType: this.state.selectedDeliveryType,
            //         // branchId: this.state.selectedBranch?.id,
            //         pin: this.state.pin,
            //         // address: this.state.address,
            //         step: 4
            //     })
            // })
        }
        else {
            this.props.updateAppState('isLoadingTransparentBackground', true);
            let getCardPrice = {
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                period: this.state.selectedPeriod?.id,
                currency: this.state.currencyId,
                paymentMethod: this.state.selectedPaymentType?.id,
                cardId: this.state.card?.cardId,
                branchId: this.state.selectedBranch?.id
            }
            fetch(request("order/cardPrice", getCardPrice))
                .then(res => res.text())
                .then((getCardPriceResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getCardPriceResponse = JSON.parse(getCardPriceResponseJSON);
                    if (getCardPriceResponse.status.statusCode !== 1) {
                        if (getCardPriceResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardPriceResponse.status.statusCode));
                            this.props.updateAppState('isLoadingTransparentBackground', false);

                        }
                    }
                    else {
                        this.setState({ showCardInfo: true, showCardPrice: getCardPriceResponse.price, showCardPeriod: getCardPriceResponse.period, tariffDesc: getCardPriceResponse.tariffDesc, showCardCurrency: getCardPriceResponse.currency })
                        this.props.history.replace(this.props.location?.pathname, { ...this.props.location.state, showCardInfo: true, showCardPrice: getCardPriceResponse.price, showCardPeriod: getCardPriceResponse.period, tariffDesc: getCardPriceResponse.tariffDesc, showCardCurrency: getCardPriceResponse.currency });

                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    addCardOrderDeliveryAndPayment = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getAddCardOrderDeliveryAndPayment = {
            orderId: this.state.orderId,
            token: localStorage.getItem('token'),
            currency: this.props.location.state?.currency,
            period: this.props.location.state?.period?.id,
            paymentMethod: this.props.location.state?.paymentMethod?.id,
            secretWord: this.props.location.state?.secretWord,
            deliveryType: this.state.selectedDeliveryType?.id,
            branchId: this.state.selectedBranch?.id,
            pin: this.props.location.state?.pin,
            address: this.state.address || null
        }
        fetch(request("order/cardOrderDeliveryAndPayment", getAddCardOrderDeliveryAndPayment))
            .then(res => res.text())
            .then((getAddCardOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAddCardOrderResponse = JSON.parse(getAddCardOrderResponseJSON);
                if (getAddCardOrderResponse.status.statusCode !== 1) {
                    if (getAddCardOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAddCardOrderResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);

                    }
                }
                else {
                    // this.props.history.replace(this.props.location?.pathname, {...this.props.location.state, address: this.state.address, selectedDeliveryType: this.state.selectedDeliveryType, selectedBranch: this.state.selectedBranch }, ()=>{
                    this.cardOrderContract()
                    // })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    cardOrderContract = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.props.history.replace(this.props.location?.pathname, { ...this.props.location.state, address: this.state.address, selectedDeliveryType: this.state.selectedDeliveryType, selectedBranch: this.state.selectedBranch })
        let getCardOrderContract = {
            orderId: this.state.orderId,
            token: localStorage.getItem('token')
        }
        fetch(request("order/cardOrderContract", getCardOrderContract))
            .then(res => res.text())
            .then((getCardOrderContractResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardOrderContractResponse = JSON.parse(getCardOrderContractResponseJSON);
                if (getCardOrderContractResponse.status.statusCode !== 1) {
                    if (getCardOrderContractResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardOrderContractResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ step: 5, contract: getCardOrderContractResponse.contract, fatcha: getCardOrderContractResponse.fatca })
                    this.props.history.replace(this.props.location?.pathname, { ...this.props.location?.state, step: 5, contract: getCardOrderContractResponse.contract, fatcha: getCardOrderContractResponse.fatca });

                    // this.setState({ 
                    //     // contract: getCardOrderContractResponse.contract, 
                    //     // fatcha: getCardOrderContractResponse.fatca 


                    // })
                    // // this.setState({ step: 5 });
                    // this.props.history.replace(this.props.location?.pathname, { ...this.props.location?.state, step: 5, 


                    // });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });


    }

    updateFatchaAnswer = (questionId, answer) => {
        const updatedAnswers = [...this.state.answers];
        const answerIndex = updatedAnswers.findIndex(item => item.id === questionId);

        if (answerIndex !== -1) {
            updatedAnswers[answerIndex].answer = answer;
            this.setState({ answers: updatedAnswers });
        }
    };

    downloadPdf = (file, filename) => {
        let arrrayBuffer = base64ToArrayBuffer(file); //data is the base64 encoded string
        let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
        let link = window.URL.createObjectURL(blob);
        window.open(link, filename);
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename || "filename.pdf"; // Set the desired filename
        a.click();
        window.URL.revokeObjectURL(a.href);
    }

    getSignFile = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode

        let getSignFileRequestBody = {
            orderId: this.state.orderId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            certCode: certCode,
            contract: this.state.contract,
            fatca: this.state.fatcha,
            userId: localStorage.getItem('userId'),
            phoneNumber: localStorage.getItem('phoneNumber')
        }

        fetch(request("order/getSignFile", getSignFileRequestBody))
            .then(res => res.text())
            .then((getSignFileResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSignFileResponse = JSON.parse(getSignFileResponseJSON);
                if (getSignFileResponse.status.statusCode !== 1) {
                    if (getSignFileResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSignFileResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                    this.setState({ verificationOpen: true, verificationCode: getSignFileResponse.verificationCode });
                    let signFileRequestBody = {
                        token: localStorage.getItem('token'),
                        transactionId: getSignFileResponse.transactionId,
                        userId: localStorage.getItem('userId'),
                        orderId: this.state.orderId,
                        certCode: certCode,
                        phoneNumber: localStorage.getItem('phoneNumber')
                    }
                    fetch(request("order/signFile", signFileRequestBody))
                        .then(res => res.text())
                        .then((signDepositFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let signDepositFileResponse = JSON.parse(signDepositFileResponseJSON);
                            if (signDepositFileResponse.status.statusCode !== 1) {
                                if (signDepositFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.setState({
                                        verificationOpen: false,
                                    });
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(signDepositFileResponse.status.statusCode), '/dashboard/home');
                                }
                            } else {
                                this.props.updateAppState('isLoadingTransparentBackground', true);

                                this.setState({ verificationOpen: false }, () => {
                                    this.props.history.replace(this.props.location?.pathname, { ...this.props.location?.state });
                                    this.cardsAndAccounts()
                                });
                            }
                        });
                    // this.setState({ contract: getSignFileResponse.contract, fatca: getSignFileResponse.fatca })
                    // this.setState({ step: 5 });
                    // this.props.history.replace(this.props.location?.pathname, { ...this.props.location?.state, step: 5 });
                    // this.cardOrderContract()
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    cardsAndAccounts = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getCardsAndAccounts = {
            orderId: this.state.orderId,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("order/cardsAndAccounts", getCardsAndAccounts))
            .then(res => res.text())
            .then((getCardsAndAccountsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardsAndAccountsResponse = JSON.parse(getCardsAndAccountsResponseJSON);
                if (getCardsAndAccountsResponse.status.statusCode !== 1) {
                    if (getCardsAndAccountsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardsAndAccountsResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ step: 6, paymentTypes: getCardsAndAccountsResponse.paymentTypes, cards: getCardsAndAccountsResponse.cards, accounts: getCardsAndAccountsResponse.accounts, selectedTypeForPayment: getCardsAndAccountsResponse.paymentTypes[0] })
                    this.props.history.replace(this.props.location?.pathname, { ...this.props.location?.state, step: 6, paymentTypes: getCardsAndAccountsResponse.paymentTypes, cards: getCardsAndAccountsResponse.cards, accounts: getCardsAndAccountsResponse.accounts, selectedTypeForPayment: getCardsAndAccountsResponse.paymentTypes[0] });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    // payCardOrder = () => {
    //     this.props.updateAppState('isLoadingTransparentBackground', true);
    //     let getAddCardOrderRequestBody = {
    //         cardId: this.state.cardKindId,
    //         token: localStorage.getItem('token'),
    //         orderId: this.state.orderId,
    //         accountId: 123456,
    //         "cardId": 123456,
    //         "cardKind": 1,
    //         "paymentType": "03FB6259D00E91A0E0630100007F1FFF",
    //         "lang": "AZ",
    //         "amount": 10,
    //         "confirmCode": "otp confirm code",
    //         "verifyToken": "otp verify token"
    //     }
    //     fetch(request("order/addCardOrder", getAddCardOrderRequestBody))
    //         .then(res => res.text())
    //         .then((getAddCardOrderResponseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let getAddCardOrderResponse = JSON.parse(getAddCardOrderResponseJSON);
    //             if (getAddCardOrderResponse.status.statusCode !== 1) {
    //                 if (getAddCardOrderResponse.status.statusCode === 200) {
    //                     this.props.logout();
    //                 } else {
    //                     this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAddCardOrderResponse.status.statusCode));
    //                 }
    //             }
    //             else {
    //                 this.setState({ orderId: getAddCardOrderResponse.orderId }, () =>
    //                     this.props.history.push("plastic-card/otp", { orderId: this.state.orderId })
    //                 );
    //             }
    //         },
    //             (error) => {
    //                 this.props.history.push("/technical-break");
    //             });

    //     this.props.updateAppState('isLoadingTransparentBackground', false);
    // }

    handleKeyDown = (e) => {
        const allowedCharacters = /^[0-9a-zA-Z]*$/;

        // Allow only numbers and letters
        if (!allowedCharacters.test(e.key)) {
            e.preventDefault();
        }
    };

    handleChange = (e) => {
        // Convert letters to uppercase
        const updatedValue = e.target.value.toUpperCase();
        this.setState({ secretWord: updatedValue })
        // setSecretWord(updatedValue);
    };
    

    render() {
        let { t, history } = this.props;
        let { open, popupType, title, feedbackMessage, dropdownVisibilityBranch, dropdownVisibilityPeriod, dropdownVisibilityCardType, dropdownVisibilityCardKind, dropdownVisibilityCurrency, branchs, periods, periodsCredit, cardTypes, cardKinds, currencies, selectedBranch, selectedPeriod, selectedCardType, selectedCardKind, selectedCurrency,
            periodIdCont, branchIdCont, cardKindIdCont, cardTypeIdCont, currencyIdCont, dropdownVisibilityPaymentType, paymentTypeCont, selectedPaymentType, currencyId, openCardSelection, cardKindId, selectedCardKindImg, card, step, questions, selectedAnswer, answers, fatchaInfo, fatchaInfoTitle, paymentMethods,
            pin, secretWord, deliveryTypes, selectedDeliveryType, branches, address, contract, fatcha, verificationOpen, verificationCode, paymentTypes, selectedTypeForPayment, cards, paymentCardOrAccountIdCont, paymentCardOrAccount, dropdownVisibilityPayment, accounts, showInfo, successModal, successModalData, showCardInfo, showCardPrice, tariffDesc, showCardCurrency, showCardPeriod, currencyName } = this.state;
        return (
            <>
                <AlertPopup open={open} closeFunction={() => this.setState({ open: false })} popupType={popupType} title={title} feedbackMessage={feedbackMessage} closeSuccessFunction={this.closeSuccessFunction} />
                <CardOrderModal downloadFile={this.downloadPdf} card={card} triggerFunc={this.updateCardKindDebet} onClose={() => this.setState({ openCardSelection: false })} cardKinds={cardKinds} open={openCardSelection} triggerFuncClose={() => this.setState({ openCardSelection: false })} />
                <FatchaInfoModal open={fatchaInfo} close={() => { this.setState({ fatchaInfo: false }) }} title={fatchaInfoTitle} />
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <OrderSuccessModal closeFunction={() => history.push("/dashboard/home")} data={successModalData} open={successModal} />

                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => history.push("/dashboard/home")}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('orders')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className={`dashboard-payment-container-data-section ${showInfo ? "w-75" : "w-100"}`} style={{ marginTop: '1px' }}>
                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink exact to="/dashboard/orders/credit" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("credit")}</NavLink>
                                <NavLink exact to="/dashboard/orders/plastic-card" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Plastic card")}</NavLink>
                            </div>
                        </div>
                        <div className="dashboard-payment-container-data-section-content">
                            {showInfo && <div className="dashboard-payment-container-data-section-content-disabled"></div>}

                            <div className="dashboard-payment-container-data-section-content-container">
                                <div className="dashboard-payment-container-data-section-content-container-form p-0 w-50">
                                    <div className="dashboard-payment-container-data-section-content-container-form-container">

                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('branch')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityBranch: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (branchIdCont > 1 && !selectedBranch || branchIdCont === 1 && !selectedBranch && !dropdownVisibilityBranch ? "input-outline" : "")}>
                                                    <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityBranch: !dropdownVisibilityBranch }); if (!selectedBranch) this.setState({ branchIdCont: ++branchIdCont }) }}>
                                                        {
                                                            selectedBranch ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedBranch}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    <ul className={(dropdownVisibilityBranch === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                        {
                                                            branchs && branchs.length > 0 &&
                                                            branchs.map((branch, index) => {
                                                                return (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" onClick={this.updateBranch.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{branch.branchName}</button>
                                                                    </li>
                                                                );
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </OutsideClickHandler>
                                        </div> */}
                                        {(step == 1 && !showInfo) && <>
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Order details')}</h3>

                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group d-flex flex-wrap">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label w-100">{t('Card type')}</label>
                                                {
                                                    cardTypes && cardTypes.length > 0 &&
                                                    cardTypes.map((cardType, index) => {
                                                        return (
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group mr-3 align-items-center" key={index}>
                                                                <RadioButton
                                                                    value={cardType?.id}
                                                                    // selectedId={selectedType?.id}
                                                                    selectedId={selectedCardType?.id}
                                                                    className={`dashboard-payment-container-data-section-content-container-form-group-radio`}
                                                                    onChange={() => this.setState({ selectedCardType: cardType, card: null, selectedBranch: null, selectedPeriod: null, selectedCurrency: null }, () => this.updateCardType())}
                                                                />
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{cardType?.name}</label>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Card kind')}</label>
                                                <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCardKind: false }) }}>
                                                    <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (cardKindIdCont > 1 && !card || cardKindIdCont === 1 && !card && !dropdownVisibilityCardKind ? "input-outline" : "")}>
                                                        <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button" + (!cardKinds || cardKinds.length === 0 ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "")} disabled={!cardKinds || cardKinds.length === 0} onClick={(event) => { event.preventDefault(); selectedCardType.cardType == 1 ? this.setState({ openCardSelection: !this.state.openCardSelection }) : this.setState({ dropdownVisibilityCardKind: !this.state.dropdownVisibilityCardKind }); if (!card) this.setState({ cardKindIdCont: ++cardKindIdCont }) }}>
                                                            {
                                                                card ?
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{card?.image && <img src={`data:image/;base64,${card?.image}`} alt="" width={40} height={24} className='mr-2' />} {card?.cardName || card?.name}</div>
                                                                    :
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                            }
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        <ul className={(dropdownVisibilityCardKind === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                cardKinds && cardKinds.length > 0 &&
                                                                cardKinds.map((cardKind, index) => {
                                                                    return (
                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                            <button type="button" onClick={() => this.setState({ card: cardKind, dropdownVisibilityCardKind: false })} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{cardKind.name}</button>
                                                                        </li>
                                                                    );
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </OutsideClickHandler>
                                            </div>

                                            {selectedCardType && selectedCardType?.cardType != 1 &&
                                                <>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('branch')} <span style={{ color: 'red' }}>*</span></label>
                                                        <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityBranch: false }) }}>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (branchIdCont > 1 && !selectedBranch || branchIdCont === 1 && !selectedBranch && !dropdownVisibilityBranch ? "input-outline" : "")}>
                                                                <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityBranch: !dropdownVisibilityBranch }); if (!selectedBranch) this.setState({ branchIdCont: ++branchIdCont }) }}>
                                                                    {
                                                                        selectedBranch ?
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedBranch}</div>
                                                                            :
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                    }
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </button>
                                                                <ul className={(dropdownVisibilityBranch === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        branchs && branchs.length > 0 &&
                                                                        branchs.map((branch, index) => {
                                                                            return (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type="button" onClick={this.updateBranch.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{branch.branchName}</button>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }

                                                                </ul>
                                                            </div>
                                                        </OutsideClickHandler>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Card period')} <span style={{ color: 'red' }}>*</span></label>
                                                        <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityPeriod: false }) }}>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (periodIdCont > 1 && !selectedPeriod || periodIdCont === 1 && !selectedPeriod && !dropdownVisibilityPeriod ? "input-outline" : "")}>
                                                                <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button" + (periodsCredit?.length === 0 ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "")} disabled={!periodsCredit || periodsCredit?.length === 0} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityPeriod: !dropdownVisibilityPeriod }); if (!selectedPeriod) this.setState({ periodIdCont: ++periodIdCont }) }}>
                                                                    {
                                                                        selectedPeriod ?
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedPeriod}</div>
                                                                            :
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                    }
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </button>
                                                                <ul className={(dropdownVisibilityPeriod === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        periodsCredit && periodsCredit.length > 0 &&
                                                                        periodsCredit.map((period, index) => {
                                                                            return (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type="button" onClick={this.updatePeriod.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{period.name}</button>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }

                                                                </ul>
                                                            </div>
                                                        </OutsideClickHandler>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')} <span style={{ color: 'red' }}>*</span></label>
                                                        <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCurrency: false }) }}>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (currencyIdCont > 1 && !selectedCurrency || currencyIdCont === 1 && !selectedCurrency && !dropdownVisibilityCurrency ? "input-outline" : "")}>
                                                                <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityCurrency: !this.state.dropdownVisibilityCurrency }); if (!selectedCurrency) this.setState({ currencyIdCont: ++currencyIdCont }) }}>
                                                                    {
                                                                        selectedCurrency ?
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCurrency}</div>
                                                                            :
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                    }
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </button>
                                                                <ul className={(dropdownVisibilityCurrency === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        currencies && currencies.length > 0 &&
                                                                        currencies.map((currency, index) => {
                                                                            return (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type="button" onClick={this.updateCurrency.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{currency.currency}</button>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }

                                                                </ul>
                                                            </div>
                                                        </OutsideClickHandler>
                                                    </div>
                                                </>
                                            }

                                        </>}

                                        {step == 2 && <>
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('FATCA')}</h3>
                                            <label className="dashboard-payment-container-data-section-content-container-form-label w-100 m-0">{t('Self-assessment questionnaire for determining the tax residency of natural persons')}</label>

                                            <ul className='p-0'>
                                                {
                                                    questions && questions.length > 0 &&
                                                    questions.map((question, index) => {
                                                        return (
                                                            <li className='d-flex align-items-start my-3'>
                                                                <span className="font-weight-light">{index + 1}.</span>
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label my-0 ml-3 mr-5 font-weight-light w-75">{question?.question}</label>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-group mr-3 align-items-center">
                                                                    <RadioButton
                                                                        value={true}
                                                                        selectedId={answers[index].answer}
                                                                        // selectedId={selectedCardTypeId}
                                                                        className="dashboard-payment-container-data-section-content-container-form-group-radio py-0"
                                                                        onChange={() => this.updateFatchaAnswer(question?.id, true)}
                                                                    />
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{t('Yes')}</label>
                                                                </div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-group mr-3 align-items-center">
                                                                    <RadioButton
                                                                        value={false}
                                                                        selectedId={answers[index].answer}
                                                                        // selectedId={selectedCardTypeId}
                                                                        className="dashboard-payment-container-data-section-content-container-form-group-radio py-0"
                                                                        onChange={() => this.updateFatchaAnswer(question?.id, false)}
                                                                    />
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{t('No')}</label>
                                                                </div>
                                                            </li>
                                                        );
                                                    })
                                                }

                                            </ul>


                                        </>}

                                        {(step == 3 || showInfo) && <div>
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Order details')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group d-flex flex-wrap">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label w-100">{t('currency')}</label>
                                                {
                                                    currencies && currencies.length > 0 &&
                                                    currencies.map((currency, index) => {

                                                        return (
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group mr-3 align-items-center" key={index}>
                                                                <RadioButton
                                                                    value={currency?.currCode}
                                                                    selectedId={currencyId}
                                                                    // selectedId={selectedCardTypeId}
                                                                    className={`dashboard-payment-container-data-section-content-container-form-group-radio`}
                                                                    onChange={() => this.setState({ currencyId: currency?.currCode, currencyName: currency?.currency })}
                                                                />
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{currency?.currency}</label>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Card period')}</label>
                                                <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityPeriod: false }) }}>
                                                    <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (periodIdCont > 1 && !selectedPeriod || periodIdCont === 1 && !selectedPeriod && !dropdownVisibilityPeriod ? "input-outline" : "")}>
                                                        <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button" + (periods?.length === 0 ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "")} disabled={!periods || periods.length === 0} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityPeriod: !dropdownVisibilityPeriod }); if (!selectedPeriod) this.setState({ periodIdCont: ++periodIdCont }) }}>
                                                            {
                                                                selectedPeriod ?
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedPeriod.name}</div>
                                                                    :
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                            }
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        <ul className={(dropdownVisibilityPeriod === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                periods && periods.length > 0 &&
                                                                periods.map((period, index) => {
                                                                    return (
                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                            <button type="button" onClick={() => this.setState({ selectedPeriod: { id: period?.id, name: period?.name }, dropdownVisibilityPeriod: false })} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{period?.name}</button>
                                                                        </li>
                                                                    );
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </OutsideClickHandler>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select loan pay type')}</label>
                                                <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityPaymentType: false }) }}>
                                                    <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (paymentTypeCont > 1 && !selectedPaymentType || paymentTypeCont === 1 && !selectedPaymentType && !dropdownVisibilityPaymentType ? "input-outline" : "")}>
                                                        <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button" + (paymentMethods?.length === 0 ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "")} disabled={!paymentMethods || paymentMethods.length === 0} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityPaymentType: !dropdownVisibilityPaymentType }); if (!selectedPaymentType) this.setState({ paymentTypeCont: ++paymentTypeCont }) }}>
                                                            {
                                                                selectedPaymentType ?
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedPaymentType.description}</div>
                                                                    :
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                            }
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        <ul className={(dropdownVisibilityPaymentType === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                paymentMethods && paymentMethods.length > 0 &&
                                                                paymentMethods.map((method, index) => {
                                                                    return (
                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                            <button type="button" onClick={() => this.setState({ selectedPaymentType: { id: method?.id, description: method?.description }, dropdownVisibilityPaymentType: false })} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{method?.description}</button>
                                                                        </li>
                                                                    );
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </OutsideClickHandler>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t("Secret word")}</label>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        value={secretWord}
                                                        //   disabled={step > 0}
                                                        // onChange={(e) => this.setState({ secretWord: e.target.value })}
                                                        onChange={(e) => this.handleChange(e)}
                                                        onKeyDown={(e) => this.handleKeyDown(e)}
                                                        maxLength={8}
                                                        // placeholder="0.00"
                                                        className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                                                    // onBlur={this.setAmountOutline}
                                                    />
                                                    <span style={{ fontSize: '12px', width: '120px' }}>
                                                        <span style={secretWord?.length > 2 ? { color: '#0179D2' } : {}}> {secretWord?.length || 0} </span>
                                                        <span style={{ color: '#0179D2' }}>/ 8 simvol</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group d-flex flex-wrap my-3">
                                                <div className="dashboard-payment-container-data-section-content-container-form-group mr-5 align-items-center">
                                                    <RadioButton
                                                        value={true}
                                                        selectedId={pin}
                                                        // selectedId={selectedCardTypeId}
                                                        className="dashboard-payment-container-data-section-content-container-form-group-radio py-0"
                                                        onChange={() => this.setState({ pin: true })}
                                                    />
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{t("With PIN-code")}</label>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-group align-items-center">
                                                    <RadioButton
                                                        value={false}
                                                        selectedId={pin}
                                                        // selectedId={selectedCardTypeId}
                                                        className="dashboard-payment-container-data-section-content-container-form-group-radio py-0"
                                                        onChange={() => this.setState({ pin: false })}
                                                    />
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{t("No PIN-code")}</label>
                                                </div>
                                            </div>


                                        </div>}
                                        {showInfo && <hr className='w-100' />}

                                        {(step == 4 || showInfo) && <div>
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Delivery information')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group d-flex flex-wrap">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label w-100">{t('Select a card delivery method')}</label>
                                                {
                                                    deliveryTypes && deliveryTypes.length > 0 &&
                                                    deliveryTypes.map((type, index) => {
                                                        return (
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group mr-3 align-items-center w-100" key={index}>
                                                                <RadioButton
                                                                    value={type?.id}
                                                                    selectedId={selectedDeliveryType?.id}
                                                                    // selectedId={selectedCardTypeId}
                                                                    className={`dashboard-payment-container-data-section-content-container-form-group-radio`}
                                                                    onChange={() => this.setState({ selectedDeliveryType: type, address: null, selectedBranch: null, showCardInfo: false })}
                                                                />
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{type?.description}</label>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            {selectedDeliveryType?.isSelect ?
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('select branch')}</label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityBranch: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (branchIdCont > 1 && !selectedBranch || branchIdCont === 1 && !selectedBranch && !dropdownVisibilityBranch ? "input-outline" : "")}>
                                                            <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button" + (periods?.length === 0 ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "")} disabled={!branches || branches.length === 0} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityBranch: !dropdownVisibilityBranch }); if (!selectedBranch) this.setState({ branchIdCont: ++branchIdCont }) }}>
                                                                {
                                                                    selectedBranch ?
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                                            <img src={branchImg} alt="" className='mr-2' />{selectedBranch.branchName}</div>
                                                                        :
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>
                                                                            <img src={branchImg} alt="" className='mr-2' />{t("select branch")}</div>
                                                                }
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(dropdownVisibilityBranch === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    branches && branches.length > 0 &&
                                                                    branches.map((branch, index) => {
                                                                        return (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item dashboard-payment-container-data-section-content-container-form-select-box-list-item-order" key={index}>
                                                                                <button type="button" onClick={() => this.setState({ selectedBranch: { id: branch?.id, branchName: branch?.branchName }, dropdownVisibilityBranch: false, showCardInfo: false })} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{branch?.branchName} <p>{branch?.branchAddress}</p></button>
                                                                            </li>
                                                                        );
                                                                    })
                                                                }

                                                            </ul>
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                                :
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t("Address")}</label>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            placeholder={t("Enter the address")}
                                                            value={address}
                                                            //   disabled={step > 0}
                                                            onChange={(e) => this.setState({ address: e.target.value, showCardInfo: false })}
                                                            // maxLength={8}
                                                            className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                                                        // onBlur={this.setAmountOutline}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {selectedDeliveryType?.isText && <div style={{ fontSize: "14px" }} className='mt-2'>
                                                <p className='mb-0'>{selectedDeliveryType?.message}</p>
                                            </div>}
                                            {showCardInfo && !showInfo &&
                                                <div style={{ fontSize: "14px", color: "#586268" }} className='mt-4'>
                                                    {showCardPrice && <p className='mb-0'>{tariffDesc} - {showCardPrice} {showCardCurrency}</p>}
                                                    {showCardPeriod && <p className='mb-0'>Müddət - {showCardPeriod}</p>}
                                                </div>
                                            }
                                        </div>}

                                        {showInfo && <hr className='w-100' />}


                                        {(step == 5 || showInfo) && <div>
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Documents')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group d-flex flex-wrap">
                                                {
                                                    contract &&
                                                    <button className='m-3 p-3 bg-white' style={{
                                                        filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.08))',
                                                        background: 'white',
                                                        borderRadius: '10px'
                                                    }} onClick={() => { this.downloadPdf(contract, "Müqavilə.pdf") }}>
                                                        <img src={pdfImg} alt="" srcset="" className='mr-3' />
                                                        {t('Contract')}
                                                    </button>
                                                }
                                                {
                                                    fatcha &&
                                                    <button className='m-3 p-3 bg-white' style={{
                                                        filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.08))',
                                                        background: 'white',
                                                        borderRadius: '10px'
                                                    }} onClick={() => { this.downloadPdf(fatcha, "FATCA.pdf") }}>
                                                        <img src={pdfImg} alt="" srcset="" className='mr-3' />
                                                        {t('FATCA')}
                                                    </button>
                                                }
                                            </div>
                                        </div>}

                                        {showInfo && <hr className='w-100' />}

                                        {(step == 6 || showInfo) && <div>
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Select a payment method')}</h3>

                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group d-flex flex-wrap">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label w-100">{t('Select loan pay type')}</label>
                                                {
                                                    paymentTypes && paymentTypes.length > 0 &&
                                                    paymentTypes.map((type, index) => {
                                                        return (
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group mr-3 align-items-center" key={index}>
                                                                <RadioButton
                                                                    value={type?.id}
                                                                    // selectedId={selectedType?.id}
                                                                    selectedId={selectedTypeForPayment?.id}
                                                                    className={`dashboard-payment-container-data-section-content-container-form-group-radio`}
                                                                    onChange={() => this.setState({ selectedTypeForPayment: type, paymentCardOrAccount: null, paymentCardOrAccountIdCont: 0 })}
                                                                />
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label m-0">{type?.description}</label>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            {selectedTypeForPayment &&
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid w-75">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{selectedTypeForPayment?.iscard ? t('Select card') : t('Select an account to withdraw funds from')}</label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityPayment: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (paymentCardOrAccountIdCont > 1 && !paymentCardOrAccount || paymentCardOrAccountIdCont === 1 && !paymentCardOrAccount && !dropdownVisibilityPayment ? "input-outline" : "")}>
                                                            <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button" + (!cards || cards.length === 0 ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "")} disabled={!cards || cards.length === 0} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityPayment: !this.state.dropdownVisibilityPayment }); if (!paymentCardOrAccount) this.setState({ paymentCardOrAccountIdCont: ++paymentCardOrAccountIdCont }) }}>
                                                                {selectedTypeForPayment &&
                                                                    paymentCardOrAccount ?
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{paymentCardOrAccount?.cardNumber || paymentCardOrAccount?.iban}, {paymentCardOrAccount?.balance} {paymentCardOrAccount?.currency}</div>
                                                                    :
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                }
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(dropdownVisibilityPayment === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {selectedTypeForPayment?.card ?
                                                                    (cards && cards.length > 0 &&
                                                                        cards.map((card, index) => {
                                                                            return (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item dashboard-payment-container-data-section-content-container-form-select-box-list-item-order" key={index}>
                                                                                    <button type="button" onClick={() => this.setState({ paymentCardOrAccount: card, dropdownVisibilityPayment: false })} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{card?.cardNumber} <p>{card?.balance} {card?.currency}</p> </button>
                                                                                </li>
                                                                            );
                                                                        }))
                                                                    : selectedTypeForPayment?.account &&
                                                                    (accounts && accounts.length > 0 &&
                                                                        accounts.map((account, index) => {
                                                                            return (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item dashboard-payment-container-data-section-content-container-form-select-box-list-item-order" key={index}>
                                                                                    <button type="button" onClick={() => this.setState({ paymentCardOrAccount: account, dropdownVisibilityPayment: false })} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{account?.iban} <p>{account?.balance} {account?.currency}</p> </button>
                                                                                </li>
                                                                            );
                                                                        }))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                            }
                                        </div>}


                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Card type')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCardType: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (cardTypeIdCont > 1 && !selectedCardType || cardTypeIdCont === 1 && !selectedCardType && !dropdownVisibilityCardType ? "input-outline" : "")}>
                                                    <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityCardType: !this.state.dropdownVisibilityCardType }); if (!selectedCardType) this.setState({ cardTypeIdCont: ++cardTypeIdCont }) }}>
                                                        {
                                                            selectedCardType ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCardType}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    <ul className={(dropdownVisibilityCardType === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                        {
                                                            cardTypes && cardTypes.length > 0 &&
                                                            cardTypes.map((cardType, index) => {
                                                                return (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" onClick={this.updateCardType.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{cardType.name}</button>
                                                                    </li>
                                                                );
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </OutsideClickHandler>
                                        </div> */}


                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCurrency: false }) }}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (currencyIdCont > 1 && !selectedCurrency || currencyIdCont === 1 && !selectedCurrency && !dropdownVisibilityCurrency ? "input-outline" : "")}>
                                                    <button type="button" className={"dashboard-payment-container-data-section-content-container-form-select-box-button"} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityCurrency: !this.state.dropdownVisibilityCurrency }); if (!selectedCurrency) this.setState({ currencyIdCont: ++currencyIdCont }) }}>
                                                        {
                                                            selectedCurrency ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCurrency}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    <ul className={(dropdownVisibilityCurrency === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                        {
                                                            currencies && currencies.length > 0 &&
                                                            currencies.map((currency, index) => {
                                                                return (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" onClick={this.updateCurrency.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{currency.currency}</button>
                                                                    </li>
                                                                );
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </OutsideClickHandler>
                                        </div> */}
                                    </div>
                                    <div className={`dashboard-payment-container-data-section-content-container-form-footer ${step === 2 && `d-flex justify-content-end`}`} style={showInfo ? { zIndex: 1000 } : {}}>
                                        {(
                                            (step == 1 && (selectedCardType?.cardType != 1 ? (selectedCurrency && selectedBranch && selectedPeriod) : card)) ||
                                            (step == 2 && !answers?.find((answer) => answer.answer == null)) ||
                                            (step == 3 && secretWord?.length > 2 && selectedPeriod && selectedPaymentType && currencyId) ||
                                            (step == 4 && (selectedBranch || address)) ||
                                            (step == 5) ||
                                            (step == 6 && paymentCardOrAccount) ||
                                            (step == 7)
                                        )
                                            // selectedBranch && selectedPeriod && 
                                            // selectedCardType && 
                                            // selectedCardKind
                                            // && selectedCurrency 
                                            ?
                                            <button onClick={() => ((step == 1 && (selectedCardType?.cardType == 1 ? this.addCardOrder() : this.orderCard()))
                                                || (step == 2 && this.addFatcha())
                                                || (step == 3 && this.setState({ step: 4 }, () =>
                                                    this.setState({ step: 4 }, () => {
                                                        this.props.history.replace(this.props.location?.pathname, {
                                                            ...this.props.location.state,
                                                            orderId: this.state.orderId,
                                                            currency: this.state.currencyId,
                                                            currencyName: this.state.currencyName,
                                                            period: this.state.selectedPeriod,
                                                            paymentMethod: this.state.selectedPaymentType,
                                                            secretWord: this.state.secretWord,
                                                            // deliveryType: this.state.selectedDeliveryType,
                                                            // branchId: this.state.selectedBranch?.id,
                                                            pin: this.state.pin,
                                                            // address: this.state.address,
                                                            step: 4
                                                        })
                                                    })))
                                                || (step == 4 && this.addStep(showCardInfo))
                                                || (step == 5 && this.getSignFile())
                                                || (step == 6 && this.setState({ showInfo: true, step: 7 }) && this.props.history.replace(this.props.location?.pathname, { ...this.props.location.state, step: 7, showInfo: true }))
                                                || (step == 7 && this.props.history.push("plastic-card/otp", { ...this.props.location.state, orderId: this.state.orderId, cardId: this.state.paymentCardOrAccount?.id, accountId: this.state.paymentCardOrAccount?.id, paymentType: this.state.selectedTypeForPayment?.id, cardKind: this.state.paymentCardOrAccount?.cardKind, amount: this.state.showCardPrice }))
                                               
                                            )}
                                                className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{step == 5 ? t('Sign with Asan imza') : t('continue')}</button>
                                            :
                                            <button type="button" disabled className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                        }
                                        <button type="button" onClick={e => { this.setState({ step: Number(this.state.step) - 1, showInfo: false, showCardInfo: false }, () => this.props.history.replace(this.props.location?.pathname, { ...this.props.location.state, step: this.state.step })) }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('Back')}</button>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    {showInfo &&
                        <div className="dashboard-payment-container-confirmation-section">
                            <div className="dashboard-payment-container-confirmation-section-header">
                                <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Order confirmation')}</h3>
                            </div>
                            <div class="dashboard-payment-container-confirmation-section-content">
                                <h3 class="dashboard-payment-container-confirmation-section-amount">
                                    <span class="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{showCardCurrency == 'AZN' ? "₼" : showCardCurrency}</span>
                                    <span class="dashboard-payment-container-confirmation-section-amount-whole">{showCardPrice}</span>
                                    {/* <span class="dashboard-payment-container-confirmation-section-amount-fractional">00</span> */}
                                </h3>
                                <ul class="dashboard-payment-container-confirmation-section-list">
                                    <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('Card currency')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{currencyName}</p>
                                    </li>
                                    <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('period')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{selectedPeriod?.name}</p>
                                    </li>
                                    <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('Payment type')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{selectedTypeForPayment?.description}</p>
                                    </li>
                                    <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{showCardPrice} {showCardCurrency}</p>
                                    </li>
                                    <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('PIN information')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{pin ? "PİN-kod ilə" : "PİN-kodsuz"}</p>
                                    </li>
                                    <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('Delivery method')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{selectedDeliveryType?.description}</p>
                                    </li>
                                    {address && <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('Address')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{address || selectedBranch?.branchName}</p>
                                    </li>}

                                    <li class="dashboard-payment-container-confirmation-section-list-item">
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-title">{t('Payment card/account')}</p>
                                        <p class="dashboard-payment-container-data-section-content-container-form-group-data">{paymentCardOrAccount?.iban || paymentCardOrAccount?.cardNumber}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default translate("translation")(PlasticCard);