import React, { useEffect, useRef, useState } from 'react';
import {
    Link,
    Redirect
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import forgotPasswordIcon from './../../components/Registration/img/forgot-password.svg';
import commentAltLines from './../../components/Registration/img/comment-alt-lines.svg';
// import AsanConfirmationIcon from "./img/asan-confirmation-icon.svg";
// import headerLogo from "./img/header-logo.svg";
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function SendOtpModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDisabledResend, setIsDisabledResend] = useState(true);
    const [verificationCode, setVerificationCode] = React.useState(true);
    const [timer, setTimer] = useState(180);
    const [val1, setVal1] = useState("");
    const [val2, setVal2] = useState("");
    const [val3, setVal3] = useState("");
    const [val4, setVal4] = useState("");
    const [val5, setVal5] = useState("");
    const [val6, setVal6] = useState("");
    const code1 = useRef();
    const code2 = useRef();
    const code3 = useRef();
    const code4 = useRef();
    const code5 = useRef();
    const code6 = useRef();



    const close = () => {
        props.onClose()
    }

    useEffect(() => {
        setOpen(props.open)
        if (props.open) {
            setVal1("");
            setVal2("");
            setVal3("");
            setVal4("");
            setVal5("");
            setVal6("");
            setTimer(180);

        }
        // setVerificationCode(props.verificationCode)
    }, [props.open])


    const deleteCodeNumber = async (number, event) => {

        if (event.keyCode === 8) {

            if (event.target.previousElementSibling && number > 1) {
                event.target.previousElementSibling.focus();
            }
            if (number == 1) {
                setVal1("");
            } else if (number == 2) {
                setVal2("");
            } else if (number == 3) {
                setVal3("");
            } else if (number == 4) {
                setVal4("");
            } else if (number == 5) {
                setVal5("");
            } else if (number == 6) {
                setVal6("");
            }
        } else if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
            if (number < 6) {
                event.target.nextElementSibling.focus();
            }
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
                if (number == 1) {
                    setVal1(Number(event.key).toString());
                } else if (number == 2) {
                    setVal2(Number(event.key).toString());
                } else if (number == 3) {
                    setVal3(Number(event.key).toString());
                } else if (number == 4) {
                    setVal4(Number(event.key).toString());
                } else if (number == 5) {
                    setVal5(Number(event.key).toString());
                } else if (number == 6) {
                    setVal6(Number(event.key).toString());
                }
            }
        }
    }


    function fancyTimeFormat(duration) {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";


        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    useEffect(() => {
        // setTimeout(() => {
        //     if (timer > 0) {
        //         setTimer(timer - 1);
        //     }
        // }, 1000);
        const timerInt = setInterval(() => {
            if (timer > 0)
                setTimer(timer - 1);
        }, 1000);
        // clearing interval
        return () => clearInterval(timerInt);
    }, [timer])

    useEffect(() => {
        if (val1 !== "" && val2 !== "" && val3 !== "" && val4 !== "" && val5 !== "" && val6 !== "" && timer != 0) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [val1, val2, val3, val4, val5, val6, timer])

    useEffect(() => {
        if (timer == 0) {
            setIsDisabledResend(false);
        } else {
            setIsDisabledResend(true);
        }
    }, [timer])

    return (
        <Modal
            open={open}
            onClose={close}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            classes={classes.modal}
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description">
            <Fade in={open}>
                <div className="asan-verificatiion-modal">
                    <button className="asan-verificatiion-modal-close-button" onClick={close}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                        </svg>
                    </button>
                    <div className="asan-verificatiion-modal-content">
                        <form className="registration-container" onSubmit={(e) => { e.preventDefault(); props.signSubmit(val1 + val2 + val3 + val4 + val5 + val6, e); }}>
                            <h2 className="registration-container-title" style={{textAlign: 'left'}}>
                                { props.t('Enter the one-time code')
                                }
                            </h2>
                            <p className="registration-container-description pb-5" style={{textAlign: 'left'}}>
                            { props.t("Enter the 6-digit confirmation code sent to your email address")
                                }
                            </p>
                            
                            {/* <img src={forgotPasswordIcon} className="registration-container-icon" alt="forgotPasswordIcon" /> */}
                            <div className="registration-container-sms-confirmation">
                                <input maxLength={1} value={val1} className="registration-container-sms-confirmation-numbers otp-input" ref={code1} placeholder="0" autoFocus={true} onKeyDown={e => deleteCodeNumber(1, e)} />
                                <input maxLength={1} value={val2} className="registration-container-sms-confirmation-numbers otp-input" ref={code2} placeholder="0" onKeyDown={e => deleteCodeNumber(2, e)} />
                                <input maxLength={1} value={val3} className="registration-container-sms-confirmation-numbers otp-input" ref={code3} placeholder="0" onKeyDown={e => deleteCodeNumber(3, e)} />
                                <input maxLength={1} value={val4} className="registration-container-sms-confirmation-numbers otp-input" ref={code4} placeholder="0" onKeyDown={e => deleteCodeNumber(4, e)} />
                                <input maxLength={1} value={val5} className="registration-container-sms-confirmation-numbers otp-input" ref={code5} placeholder="0" onKeyDown={e => deleteCodeNumber(5, e)} />
                                <input maxLength={1} value={val6} className="registration-container-sms-confirmation-numbers otp-input" ref={code6} placeholder="0" onKeyDown={e => deleteCodeNumber(6, e)} />
                            </div>
                            <div className="registration-container-validation-feedback">
                                {
                                    props.feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{props.feedbackMessage}</p> : null
                                }
                            </div>
                            <div className="registration-container-resend">
                                <div className="registration-container-resend-container">
                                    <div className="registration-container-resend-container-icon">
                                        <img src={commentAltLines} className="registration-container-resend-container-icon-img" alt="commentAltLines" />
                                    </div>
                                    <button type="button" className="registration-container-resend-container-button" disabled={isDisabledResend} onClick={(e) => { e.preventDefault(); props.signSubmit(null, e); setTimer(180); setIsDisabledResend(true);}}>{props.t('resend the code')}</button>
                                </div>
                                <span className="registration-container-resend-timer">{fancyTimeFormat(timer)}</span>
                            </div>
                            <div className="registration-container-footer">
                                <button className="registration-container-footer-button registration-container-footer-button--center w-100" type="submit" disabled={isDisabled}>{props.t('Confirm')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
export default translate("translation")(SendOtpModal);