import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import Operations from './Operations/Operations';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import cardsIcon from './../../img/cards-icon.png';
import AsanVerification from '../../../../copms/Modal/AsanVerification';
import SignOperation from '../../../../copms/Modal/SignOperation';
import YesNoModal from '../../../../copms/Modal/YesNoModal';
import Loading from '../../Loading/Loading';

class FirstAuthorisation extends Component {
    state = {
        convertations: [],
        transfers: [],
        selectedList: [],
        buttonDisabled: true,
        verificationOpen: false,
        verificationCode: '',
        failOperId: [],
        operationOpen: false,
        operationType: 'error',
        operationData: [],
        successOperId: [],
        feedbackMessage: "",
        signModalOpen: false,
        isLoading: false,
        signLevel: null,
        signCount: null
    }
    dropdownItemHandler = (index, account) => {
        switch (index) {
            case 2:
                this.setState({
                    open: true,
                    modalAccountObj: account
                });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location != this.props.location) {
            if (this.props.location.pathname == '/dashboard/transfer/2nd-authorization/transfers') {
                //this.setState({ buttonDisabled: !Boolean(this.state.transfers.find(el => el.isSelect === true)) });
            } else if (this.props.location.pathname == '/dashboard/transfer/2nd-authorization/exchange') {
                //this.setState({ buttonDisabled: !Boolean(this.state.convertations.find(el => el.isSelect === true)) });
            }
        }
    }
    selectListFunc(index, val) {
        var convertations = this.state.convertations;
        var transfers = this.state.transfers;
        if (this.props.location.pathname == '/dashboard/transfer/2nd-authorization/transfers') {

            transfers[index].isSelect = val;
            this.setState({ transfers: transfers, buttonDisabled: !(Boolean(transfers.find(el => el.isSelect === true)) || Boolean(convertations.find(el => el.isSelect === true))) });
        } else if (this.props.location.pathname == '/dashboard/transfer/2nd-authorization/exchange') {

            convertations[index].isSelect = val;
            this.setState({ convertations: convertations, buttonDisabled: !(Boolean(transfers.find(el => el.isSelect === true)) || Boolean(convertations.find(el => el.isSelect === true))) });
        }
    }

    selectAll(type, val) {
        if (type === 1) {
            this.setState({
                transfers: this.state.transfers.map((item, index) => {
                    item.isSelect = val;
                    return item;
                }), buttonDisabled: !Boolean(val || Boolean(this.state.convertations.find(el => el.isSelect === true)))
            });
        } else if (type === 0) {
            this.setState({
                convertations: this.state.convertations.map((item, index) => {
                    item.isSelect = val;
                    return item;
                }), buttonDisabled: !Boolean(val || Boolean(this.state.transfers.find(el => el.isSelect === true)))
            });
        } else {
            this.setState({
                convertations: this.state.convertations.map((item, index) => {
                    item.isSelect = val;
                    return item;
                }),
                transfers: this.state.transfers.map((item, index) => {
                    item.isSelect = val;
                    return item;
                }),
                buttonDisabled: !Boolean(val || Boolean(this.state.transfers.find(el => el.isSelect === true)))
            });
        }
    }

    componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({signLevel: custObject.company.signLevel});
            this.setState({signCount: custObject.company.signCount});
        }
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAuthOperationListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("operation/getSecondAuthOperationList", getAuthOperationListRequestBody))
            .then(res => res.text())
            .then((getAuthOperationListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAuthOperationListResponse = JSON.parse(getAuthOperationListResponseJSON);
                if (getAuthOperationListResponse.status.statusCode === 200) {
                    this.props.logout();
                } else {
                    var convertations = [];
                    var transfers = [];
                    if (getAuthOperationListResponse.operationList) {
                        for (var i = 0; i < getAuthOperationListResponse.operationList.length; i++) {
                            if (getAuthOperationListResponse.operationList[i].operNameId === 4) {
                                convertations.push(getAuthOperationListResponse.operationList[i]);
                            } else {
                                transfers.push(getAuthOperationListResponse.operationList[i]);
                            }
                        }
                        this.setState({ convertations, transfers })
                    }
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    signSubmit() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let listObjects = this.state.transfers.concat(this.state.convertations);
        let selectList = (this.state.transfers.concat(this.state.convertations)).filter(el => el.isSelect === true);
        let phoneNumber = localStorage.getItem('phoneNumber');
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode;
        let getSecondSignInfoRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operationIds: selectList.map(item => { return item.operId }),
            userId: localStorage.getItem('userId'),
            phoneNumber: phoneNumber,
            certCode: certCode,
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operationIds: selectList.map(item => { return item.operId }),
                userId: localStorage.getItem('userId'),
                phoneNumber: phoneNumber,
                certCode: certCode,
            };

        fetch(request("operation/getSecondSignInfo", getSecondSignInfoRequestBody))
            .then(res => res.text())
            .then((getSecondSignInfoResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSecondSignInfoResponse = JSON.parse(getSecondSignInfoResponseJSON);
                if (getSecondSignInfoResponse.status.statusCode !== 1) {
                    if (getSecondSignInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSecondSignInfoResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ verificationOpen: true, verificationCode: getSecondSignInfoResponse.verificationCode, failOperId: getSecondSignInfoResponse.failOperId, successOperId: getSecondSignInfoResponse.successOperId });
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                    let SignFileRequestBody = customerType == 'Juridical' ? {
                        custId: localStorage.getItem('custId'),
                        compId: localStorage.getItem('compId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        operationIds: selectList.map(item => { return item.operId }),
                        userId: localStorage.getItem('userId'),
                        phoneNumber: phoneNumber,
                        certCode: certCode,
                        transactionId: getSecondSignInfoResponse.transactionId,
                        failOperId: getSecondSignInfoResponse.failOperId,
                        successOperId: getSecondSignInfoResponse.successOperId
                    } : {
                            custId: localStorage.getItem('custId'),
                            token: localStorage.getItem('token'),
                            lang: localStorage.getItem('i18nextLng').toUpperCase(),
                            operationIds: selectList.map(item => { return item.operId }),
                            userId: localStorage.getItem('userId'),
                            phoneNumber: phoneNumber,
                            certCode: certCode,
                            transactionId: getSecondSignInfoResponse.transactionId,
                            failOperId: getSecondSignInfoResponse.failOperId,
                            successOperId: getSecondSignInfoResponse.successOperId
                        };
                    fetch(request("operation/signFileSecondAuth", SignFileRequestBody))
                        .then(res => res.text())
                        .then((SignFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let SignFileResponse = JSON.parse(SignFileResponseJSON);
                            if (SignFileResponse.status.statusCode !== 1) {
                                if (SignFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } 
                                else {
                                    this.setState({ verificationOpen: false, verificationCode: '', });
                                    var oprationList = selectList.map((item) => {
                                        var inObj = item
                                        inObj.type = 'error';
                                        return inObj;
                                    });
                                    this.setState({ operationOpen: true, verificationOpen: false, selectedList: [], operationType: 'error', feedbackMessage: this.props.t(SignFileResponse.status.statusCode), operationData: oprationList }, () => this.selectAll(2, false));
                                }
                            } else {
                                var successList = SignFileResponse.successStatus ? SignFileResponse.successStatus.map((item) => {
                                    listObjects.splice(listObjects.findIndex(el => el.operId === item.operId), 1);
                                    var inObj = selectList.find(el => el.operId === item.operId);
                                    inObj.type = 'success';
                                    return inObj;
                                }) : [];
                                var errorList = SignFileResponse.failStatus ? SignFileResponse.failStatus.map((item) => {
                                    var inObj = selectList.find(el => el.operId === item.operId);
                                    inObj.type = 'error';
                                    return inObj;
                                }) : [];

                                // this.setState({ operationOpen: true, verificationOpen: false, operationType: getSecondSignInfoResponse.failOperId.length === 0 ? 'success' : 'warning', operationData: successList.concat(errorList), selectedList: [] },
                                //     () => this.setState({ transfers: listObjects.filter(el => el.operNameId !== 4), convertations: listObjects.filter(el => el.operNameId === 4) }, () => () => this.selectAll(2, false)));
                           
                                this.setState({ operationOpen: true, verificationOpen: false, operationType: SignFileResponse.failStatus.length === 0 ? 'success' : 'error', feedbackMessage: this.props.t(SignFileResponse.failStatus.length ? SignFileResponse.failStatus[0]?.statusCode : SignFileResponse.successStatus[0]?.statusCode),  operationData: successList.concat(errorList), selectedList: [] },
                                () => this.setState({ transfers: listObjects.filter(el => el.operNameId !== 4), convertations: listObjects.filter(el => el.operNameId === 4) }, () => () => this.selectAll(2, false)));
                            }
                        });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    handleChange = (moment) => {
        this.setState({
            moment
        });
    }

    render() {
        let { t, alertState, updateAppState, logout } = this.props;
        let { signLevel, signCount, convertations, transfers, buttonDisabled, verificationOpen, signModalOpen, verificationCode, operationOpen, operationType, operationData, feedbackMessage, isLoading } = this.state;
        return (
            <Fragment>
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <SignOperation feedbackMessage={feedbackMessage} open={operationOpen} auth={2} type={operationType} data={operationData} />
                <YesNoModal open={signModalOpen} title={t('Are you sure you want to sign?')} onClose={() => this.setState({ signModalOpen: false })} triggerFunc={val => val && this.signSubmit()} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>
                            {
                                (signLevel === 1 && signCount === 1) ?
                                    t('authorization') 
                                    :
                                    t('2nd authorization') 
                            }
                        </h2>

                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">

                            <div className="dashboard-transfer-list">
                                <div className="dashboard-transfer-list-header">
                                    <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                        <NavLink exact to="/dashboard/transfer/2nd-authorization/transfers" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("transfers")}</NavLink>
                                        <NavLink exact to="/dashboard/transfer/2nd-authorization/exchange" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Conversion")}</NavLink>
                                    </div>
                                    <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__right">
                                        <button onClick={() => this.setState({ signModalOpen: true })} disabled={buttonDisabled} className="dashboard-transfer-list-header-button">{t("Sign with Asan")}</button>
                                    </div>
                                </div>
                                <Switch>
                                    <Redirect exact from="/dashboard/transfer/2nd-authorization" to="/dashboard/transfer/2nd-authorization/transfers" />
                                    <Route path="/dashboard/transfer/2nd-authorization/transfers" render={(routeProps) => <Operations logout={() => logout()} selectAll={val => this.selectAll(1, val)} selectListFunc={(index, val) => this.selectListFunc(index, val)} data={this.state.transfers} {...routeProps} type={0} updateAppState={updateAppState} alertState={alertState} />} />
                                    <Route path="/dashboard/transfer/2nd-authorization/exchange" render={(routeProps) => <Operations logout={() => logout()} selectAll={val => this.selectAll(0, val)} selectListFunc={(index, val) => this.selectListFunc(index, val)} data={this.state.convertations} {...routeProps} type={1} updateAppState={updateAppState} alertState={alertState} />} />

                                </Switch>
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(FirstAuthorisation);