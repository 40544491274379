import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import './scss/loading.css';
import InputMask from 'react-input-mask';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function SmsService(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [isDisable, setIsDisable] = React.useState(true);
    const [inputPhone, setInputPhone] = React.useState('');
    const [inputAgainPhone, setInputAgainPhone] = React.useState('');
    const [selectLimit, setSelectLimit] = React.useState('daily');

    const close = () => {
        setOpen(false);
        props.onClose();
    }
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSmsUpdateSubmit('daily', inputPhone, inputAgainPhone);
    }

    const inputPhoneChange = (val) => {
        if (val.length > 0 && inputAgainPhone.length > 0 && val.indexOf('_') < 0 && inputAgainPhone.indexOf('_') < 0) {
            setInputPhone(val)
            setIsDisable(false)
        } else {
            setInputPhone(val)
            setIsDisable(true)
        }
    }

    const inputAgainPhoneChange = (val) => {
        if (val.length > 0 && inputPhone.length > 0 && val.indexOf('_') < 0 && inputPhone.indexOf('_') < 0) {
            setInputAgainPhone(val)
            setIsDisable(false)
        } else {
            setInputAgainPhone(val)
            setIsDisable(true)
        }
    }
    const t = props.t;
    return (
        <>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="modal-sms-service-shange">
                            <div className="modal-sms-service-shange-head">
                                <h2>{t('SMS service')}</h2>
                                <button onClick={() => close()}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                    </svg>
                                </button>
                            </div>
                            <h6>{t('Daily SMS service')}</h6>
                            <form onSubmit={onSubmit} className="header-sign-section-container-form">
                                {/* <div className="header-sign-section-container-form-group">
                                    <label className="header-sign-section-container-form-group-label">{t('LIMIT TYPE')}</label>
                                    <select className="modal-sms-service-shange-select" name="country">
                                        <option value="australia">{t('Daily')}</option>
                                    </select>
                                </div> */}

                                <div className="header-sign-section-container-form-group">
                                    <label className="header-sign-section-container-form-group-label">{t('phone number')}</label>
                                    <div className="header-sign-section-container-form-group-box">
                                        <InputMask onChange={e => inputPhoneChange(e.target.value)} mask="+\9\9\4 ( 99 ) 999-99-99" className="header-sign-section-container-form-group-box-input" placeholder="+994 (__) ___-__-__" />
                                        <div className="header-sign-section-container-form-group-box-icon">

                                        </div>
                                    </div>
                                </div>

                                <div className="header-sign-section-container-form-group">
                                    <label className="header-sign-section-container-form-group-label">{t('Repeat phone number')}</label>
                                    <div className="header-sign-section-container-form-group-box">
                                        <InputMask onChange={e => inputAgainPhoneChange(e.target.value)} mask="+\9\9\4 ( 99 ) 999-99-99" className="header-sign-section-container-form-group-box-input" placeholder="+994 (__) ___-__-__" />
                                        <div className="header-sign-section-container-form-group-box-icon">

                                        </div>
                                    </div>
                                </div>
                                <p>{t('You will be charged for this service. Monthly commission')} - <span>1 AZN</span></p>

                                <div className="modal-sms-service-shange-button">
                                    <button className="header-sign-section-container-form-button" disabled={isDisable} >{loading ? <div class="spinner spinner--steps icon-spinner" aria-hidden="true"></div> : t('save')}</button>
                                    <p></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default translate("translation")(SmsService);