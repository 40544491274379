import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';
import RadioButton from '../RadioButton/RadioButtonSolid';
import cardImg from '../../components/Dashboard/img/newVisa.svg';
import pdfImg from '../../components/Dashboard/img/pdf-new.svg'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function YesNoModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [selectedCard, setSelectedCard] = React.useState();

    const close = () => {
        setOpen(false);
        props.onClose();
    }

    useEffect(() => {
        setSelectedCard(props.card || props.cardKinds[0])
        setOpen(props.open)
    }, [props.open])

    const triggerFuncClose = (val) => {
        close()
        if (val) {
            props.triggerFuncClose(val);
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="card-order">
                            <div className="card-order-head">
                                <h2>{translate('Select card')}</h2>
                            </div>
                            <div className="card-order-left col-6">
                                {
                                    props.cardKinds && props.cardKinds.length > 0 &&
                                    props.cardKinds.map((cardKind, index) => {
                                        return (
                                            <div className="card-order-left-select" key={index}>
                                                <RadioButton
                                                    value={cardKind?.cardId}
                                                    selectedId={selectedCard?.cardId}
                                                    className="p-0"
                                                    // disabled={Boolean(step > 1)}
                                                    onChange={() => setSelectedCard(cardKind)}
                                                />
                                                {cardKind?.image && <img src={`data:image/;base64,${cardKind?.image}`} alt="cardImg" srcset="" />}
                                                <div className="d-flex flex-column">
                                                    <h6>{cardKind?.cardName}</h6>
                                                    <label>{cardKind?.cardType}</label>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="card-order-right col-6">
                                <div className="card-order-right-container">
                                    <div className="card-order-right-container-head">
                                        {selectedCard?.image && <img src={`data:image/;base64,${selectedCard?.image}`} alt="" srcset="" />}
                                        <div className="d-flex flex-column">
                                            <h6>{selectedCard?.cardName}</h6>
                                            <label>{selectedCard?.cardType}</label>
                                        </div>
                                    </div>
                                    <div className="card-order-right-container-body mt-3">
                                        <div className="d-flex flex-wrap">
                                            <div className="w-50 mb-3">
                                                {selectedCard?.currency && <><label>{props.t("currency")}</label><p>{selectedCard?.currency}</p></>}
                                            </div>
                                            <div className="w-100">
                                                {selectedCard?.about && <><label>{props.t("About card")}</label><p>{selectedCard?.about}</p></>}
                                            </div>
                                        </div>
                                        {selectedCard?.tariffs && <> <hr />
                                            <div className="d-flex flex-column">
                                                <label>{props.t("Card validity period and issue fee")}</label>
                                                <ul className='m-0'>
                                                    {selectedCard?.tariffs?.map((tariff) => (
                                                        <li>{tariff?.period} - {tariff?.price} {tariff?.currency}</li>

                                                    ))}
                                                </ul>
                                            </div></>}
                                        {selectedCard?.tariffFile &&
                                            <button className='bg-white w-100 p-2 mt-3' onClick={() => { props.downloadFile(selectedCard?.tariffFile, "Tarif.pdf") }}><img src={pdfImg} alt="" width={23} height={23} /> {props.t("tariffs")}</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-sms-service-buttons">
                                <button className="modal-sms-service-button-yes" onClick={() => { props.triggerFunc(selectedCard) }} style={{background: "#0179D2"}}>{props.t('continue')}</button>
                                <button className="modal-sms-service-button-no" onClick={() => { triggerFuncClose(false); setSelectedCard(null) }}>{props.t('cancel')}</button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(YesNoModal);