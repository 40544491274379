import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import masterCardIcon from './../../img/card-icon.svg';
import visaCardIcon from './../../img/visa-icon.svg';
import visaCardIconWhite from './../../img/visa-icon-white.svg';
import cardsIcon from './../../img/cards-icon.png';
import cardBlueBackground from './../../img/card-blue-background.svg';
import cardTransparentBackground from './../../img/card-transparent-background.svg';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import { request, requestCardStorage } from './../../../../config';
import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import Modal from '../../../../copms/Modal/CardModal.js';
import { translate } from 'react-i18next';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import NotFound from '../../../../copms/NotFound/NotFound';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';
import PinUnblockSuccess from '../../../../copms/Modal/PinUnblockSuccess';
import Dropdown from '../../../../copms/Dropdown/Dropdown';
import digitalCard from './../../img/digital_card.svg';
import otherCard from './../../img/other-bank-card.svg';
import { cardTypes, getDigitalCardInfo, maskCardNumber, setPaymentTypeIcon, deleteOtherBankCard } from '../../../../helpers/cards';
import DigitalCardInfoModal from '../DigitalCard/DigitalCardInfoModal/DigitalCardInfoModal';
import SimpleModalCashback from '../../../../copms/Modal/SimpleModalCashback';
import CashbackStatementModal from '../../../../copms/Modal/CashbackStatementModal';

class Card extends Component {
  state = {
    card: null,
    statements: [],
    startDate: "",
    endDate: "",
    open: false,
    blockCardId: null,
    isLoading: false,
    successModal: false,
    successModalData: null,
    unblockPinModalOpen: false,
    openDigitalCardInfoModal: false,
    openSimpleModal: false,
    pageNumber: 1,
    // cashBackStatements: null,
    cashBackStatements: [],
    openDeleteOtherCardModal: false,
    // cashbackInfo: null,
    cashbackInfo: null,
    title: '',
    body: '',
    formattedDate: "",
    visibleCashBack: false,
    statementDetails: false,
    statement: null
  }

  updateOpenModal = (value) => {
    this.setState({ open: value });
  }
  toggleDigitalCardInfoModal = () => {
    this.setState({ openDigitalCardInfoModal: !this.state.openDigitalCardInfoModal })
  }

  getFormatedDate = (date) => {
    if (date) {
      let formatedDate = {
        day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
        month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
        year: date.getFullYear().toString()
      }
      return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
    }
    return null;
  }

  showCard = () => {
    this.setState({ isLoading: true });
    let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
    let getCardByIdForCustomerRequestBody = (customerType === 'Individual') ? {
      custId: localStorage.getItem('custId'),
      token: localStorage.getItem('token'),
      cardId: this.props.match.params.id,
      lang: localStorage.getItem('i18nextLng').toUpperCase()
    } : {
      custId: localStorage.getItem('custId'),
      compId: localStorage.getItem('compId'),
      token: localStorage.getItem('token'),
      cardId: this.props.match.params.id,
      lang: localStorage.getItem('i18nextLng').toUpperCase()
    }

    fetch(request("card/getCardByIdFor" + customerType, getCardByIdForCustomerRequestBody))
      .then(res => res.text())
      .then((getCardByIdForCustomerResponseJSON) => {
        const JSON = require('true-json-bigint');
        let getCardByIdForCustomerResponse = JSON.parse(getCardByIdForCustomerResponseJSON);
        if (getCardByIdForCustomerResponse.status.statusCode !== 1) {
          if (getCardByIdForCustomerResponse.status.statusCode === 200) {
            this.props.logout();
          } else {
            this.setState({ isLoading: false });
          }
        }
        else {
          if (getCardByIdForCustomerResponse?.otherCard) {
            this.setState({ card: getCardByIdForCustomerResponse.otherCard });
            this.setState({ isLoading: false });
            this.showOtherCardStatement(true);
          } else {
            // =========HOLDER=========
            let holderArray = getCardByIdForCustomerResponse.holder.split(' ');
            let lowercaseName = holderArray[0].toLowerCase();
            let lowercaseSurname = holderArray[1].toLowerCase();
            getCardByIdForCustomerResponse.organizedHolder = {
              name: holderArray[0][0] + lowercaseName.substring(1, lowercaseName.length),
              surname: holderArray[1][0] + lowercaseSurname.substring(1, lowercaseSurname.length)
            };
            // =========NUMBER=========
            getCardByIdForCustomerResponse.organizedCardNumber = getCardByIdForCustomerResponse.cardNumber.substring(0, 4)
              + " **** **** " + getCardByIdForCustomerResponse.cardNumber.substring(12, 16);
            // =========EXPIRYDATE=========
            let expiryDate = new Date(getCardByIdForCustomerResponse.expiryDate);
            getCardByIdForCustomerResponse.organizedExpiryDate = {
              month: (expiryDate.getMonth() < 9) ? ('0' + (expiryDate.getMonth() + 1)) : (expiryDate.getMonth() + 1),
              year: expiryDate.getFullYear().toString().substring(2, 4),
              fullYear: expiryDate.getFullYear(),
              day: expiryDate.getDate() < 10 ? '0' + expiryDate.getDate().toString() : expiryDate.getDate().toString()
            }
            // =========CURRENCY=========
            getCardByIdForCustomerResponse.currencyIcon = currencyFilter(getCardByIdForCustomerResponse.currency);
            // =========BALANCE=========
            if (getCardByIdForCustomerResponse.balance.toString() === "0") {
              getCardByIdForCustomerResponse.organizedCurrentBalance = {
                wholePart: "0",
                fractionalPart: "00"
              }
            }
            else {
              if (getCardByIdForCustomerResponse.balance.toString().indexOf('.') !== -1) {
                let currentBalanceArray = getCardByIdForCustomerResponse.balance.toString().split('.');
                getCardByIdForCustomerResponse.organizedCurrentBalance = {
                  wholePart: currentBalanceArray[0],
                  fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                }
              }
              else {
                getCardByIdForCustomerResponse.organizedCurrentBalance = {
                  wholePart: getCardByIdForCustomerResponse.balance.toString(),
                  fractionalPart: "00"
                }
              }
            }
            this.setState({ card: getCardByIdForCustomerResponse });
            if (getCardByIdForCustomerResponse.cardStatus !== 0) {
              if (this.state.visibleCashBack == 'true') {
                this.showCashbackStatements();
              } else {
                this.showStatements(new Date(), new Date(), true);
              }
            }
            else {
              this.setState({ isLoading: false });
            }
          }
        }
      },
        (error) => {
          this.props.history.push("/technical-break");

        });
  }

  showStatements = (startDate = this.state.startDate, endDate = this.state.endDate, initial = false) => {
    if (initial) {
      this.setState({ isLoading: false });
    }
    else {
      this.props.updateAppState('isLoadingTransparentBackground', true);
    }
    this.setState({ startDate, endDate });
    let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
    let getCardStatementForCustomerRequestBody = (customerType === 'Juridical') ? {
      custId: localStorage.getItem('custId'),
      compId: localStorage.getItem('compId'),
      token: localStorage.getItem('token'),
      cardNumber: this.state.card.cardNumber,
      cardId: this.state.card.cardId,
      startDate: this.getFormatedDate(startDate),
      endDate: this.getFormatedDate(endDate),
      lang: localStorage.getItem('i18nextLng').toUpperCase()
    } : {
      custId: localStorage.getItem('custId'),
      token: localStorage.getItem('token'),
      cardNumber: this.state.card.cardNumber,
      cardId: this.state.card.cardId,
      startDate: this.getFormatedDate(startDate),
      endDate: this.getFormatedDate(endDate),
      lang: localStorage.getItem('i18nextLng').toUpperCase()
    };
    fetch(request("card/getCardStatementFor" + customerType + "Customer", getCardStatementForCustomerRequestBody))
      .then(res => res.text())
      .then((getCardStatementForCustomerResponseJSON) => {
        const JSON = require('true-json-bigint');
        let getCardStatementForCustomerResponse = JSON.parse(getCardStatementForCustomerResponseJSON);
        if (getCardStatementForCustomerResponse.status.statusCode !== 1 && getCardStatementForCustomerResponse.status.statusCode !== 165) {
          if (getCardStatementForCustomerResponse.status.statusCode === 200) {
            this.props.logout();
          } else {
            if (initial) {
              this.setState({ isLoading: false });
            }
            else {
              this.props.updateAppState('isLoadingTransparentBackground', false);
            }
          }
        }
        else {
          if (getCardStatementForCustomerResponse.status.statusCode === 165) {
            getCardStatementForCustomerResponse.cardStamentList = [];
            if (initial) {
              this.setState({ isLoading: false });
            }
            else {
              this.props.updateAppState('isLoadingTransparentBackground', false);
            }
          }
          for (let i = 0; i < getCardStatementForCustomerResponse.cardStamentList.length; i++) {
            // =========NUMBER=========
            getCardStatementForCustomerResponse.cardStamentList[i].organizedCardNumber = getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(0, 4)
              + " **** **** " + getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(12, 16);
            // =========DATE=========
            getCardStatementForCustomerResponse.cardStamentList[i].organizedDate = getCardStatementForCustomerResponse.cardStamentList[i].trDate.substring(0, getCardStatementForCustomerResponse.cardStamentList[i].trDate.length - 3);
            // =========AMOUNT=========
            if (getCardStatementForCustomerResponse.cardStamentList[i].dtAmount === 0) {
              getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].crAmount;
            }
            else {
              if (getCardStatementForCustomerResponse.cardStamentList[i].crAmount === 0) {
                getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].dtAmount;
              }
            }
            if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString() === "0") {
              getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                wholePart: "0",
                fractionalPart: "00"
              }
            }
            else {
              if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().indexOf('.') !== -1) {
                let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                  wholePart: amountArray[0],
                  fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                }
              }
              else {
                let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                  wholePart: getCardStatementForCustomerResponse.cardStamentList[i].amount.toString(),
                  fractionalPart: "00"
                }
              }
            }
          }
          this.setState({ statements: getCardStatementForCustomerResponse.cardStamentList });
          if (initial) {
            this.setState({ isLoading: false });
          }
          else {
            this.props.updateAppState('isLoadingTransparentBackground', false);
          }
        }
      },
        (error) => {
          this.props.history.push("/technical-break");
        });
  }

  showCashbackStatements = () => {
    let getCashBackOperationListRequestBody = {
      token: localStorage.getItem('token'),
      cardId: this.state.card.cardId,
      lang: localStorage.getItem('i18nextLng').toUpperCase(),
      pageNumber: this.state.pageNumber
    };
    fetch(request("bonus/getCashBackOperationList", getCashBackOperationListRequestBody))
      .then(res => res.text())
      .then((getCashBackOperationListResponseJSON) => {
        const JSON = require('true-json-bigint');
        let getCashBackOperationListResponse = JSON.parse(getCashBackOperationListResponseJSON);

        if (getCashBackOperationListResponse.status.statusCode !== 1) {
          if (getCashBackOperationListResponse.status.statusCode === 200) {
            this.props.logout();
          } else {
            this.setState({ isLoading: false });
          }
        }
        else {
          this.setState({ cashBackStatements: getCashBackOperationListResponse.cashBackOperations });
          this.setState({ isLoading: false });
        }
      },
        (error) => {
          this.props.history.push("/technical-break");
        });
  }

  showOtherCardStatement = (initial) => {
    if (initial) {
      this.setState({ isLoading: false });
    }
    else {
      this.props.updateAppState('isLoadingTransparentBackground', true);
    }

    let requestBody = {
      token: localStorage.getItem('token'),
      pageNumber: this.state.pageNumber,
      cardId: this.props.match.params.id
    }
    fetch(requestCardStorage("storage/getCardStorageOperationHistory", requestBody))
      .then(res => res.text())
      .then((getCardStatementForCustomerResponseJSON) => {
        const JSON = require('true-json-bigint');
        let getCardStatementForCustomerResponse = JSON.parse(getCardStatementForCustomerResponseJSON);
        if (getCardStatementForCustomerResponse.status.statusCode !== 1) {
          if (getCardStatementForCustomerResponse.status.statusCode === 200) {
            this.props.logout();
          } else {
            if (initial) {
              this.setState({ isLoading: false });
            }
            else {
              this.props.updateAppState('isLoadingTransparentBackground', false);
            }
          }
        }
        else {
          if (getCardStatementForCustomerResponse.status.statusCode === 165) {
            getCardStatementForCustomerResponse.cardStamentList = [];
            if (initial) {
              this.setState({ isLoading: false });
            }
            else {
              this.props.updateAppState('isLoadingTransparentBackground', false);
            }
          }
          for (let i = 0; i < getCardStatementForCustomerResponse.cardStorageOperInfos.length; i++) {
            // =========NUMBER=========
            getCardStatementForCustomerResponse.cardStorageOperInfos[i].organizedCardNumber = getCardStatementForCustomerResponse.cardStorageOperInfos[i].cardNumber.substring(0, 4)
              + " **** **** " + getCardStatementForCustomerResponse.cardStorageOperInfos[i].cardNumber.substring(12, 16);
            // =========DATE=========
            getCardStatementForCustomerResponse.cardStorageOperInfos[i].organizedDate = getCardStatementForCustomerResponse.cardStorageOperInfos[i].operDate.substring(0, getCardStatementForCustomerResponse.cardStorageOperInfos[i].operDate.length - 3);
            // =========AMOUNT=========
            if (getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString() === "0") {
              getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount = {
                wholePart: "0",
                fractionalPart: "00"
              }
            }
            else {
              if (getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString().indexOf('.') !== -1) {
                let amountArray = getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString().split('.');
                getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount = {
                  wholePart: amountArray[0],
                  fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                }
              }
              else {
                let amountArray = getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString().split('.');
                getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount = {
                  wholePart: getCardStatementForCustomerResponse.cardStorageOperInfos[i].amount.toString(),
                  fractionalPart: "00"
                }
              }
            }
          }
          this.setState({ statements: getCardStatementForCustomerResponse.cardStorageOperInfos });
          if (initial) {
            this.setState({ isLoading: false });
          }
          else {
            this.props.updateAppState('isLoadingTransparentBackground', false);
          }
        }
      },
        (error) => {
          this.props.history.push("/technical-break");
        });
  }

  componentDidMount() {
    this.setState({ visibleCashBack: localStorage.getItem('visibleCashBack') }, () => {
      if (this.state.visibleCashBack == 'true') {
        let getCashBackRequestBody = {
          token: localStorage.getItem("token"),
          lang: localStorage.getItem("i18nextLng").toUpperCase()
        };
        fetch(request("bonus/getCustCashBackInfo", getCashBackRequestBody))
          .then((res) => res.text())
          .then((cashbackResponseJSON) => {
            const JSON = require("true-json-bigint");
            let cashbackResponse = JSON.parse(cashbackResponseJSON);
            if (cashbackResponse.status.statusCode !== 1) {
              if (cashbackResponse.status.statusCode === 200) {
                this.props.logout();
              } else {
                // this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createExchangeOperationResponse.status.statusCode) });
                // this.props.updateAppState('isLoadingTransparentBackground', false);
              }
            }
            else {
              const inputDate = new Date(cashbackResponse?.startDate);
              const day = inputDate.getUTCDate();
              const month = inputDate.getUTCMonth() + 1; // Months are 0-indexed
              const year = inputDate.getUTCFullYear();
              const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;

              this.setState({ cashbackInfo: cashbackResponse, formattedDate });
              // this.props.updateAppState('isLoadingTransparentBackground', false);
            }
          });
      };
      this.showCard();
    })
    // this.props.updateAppState('isLoadingTransparentBackground', true);


    // fetch(request("bonus/getCashBackOperationList", getCashBackRequestBody))
    //   .then((res) => res.text())
    //   .then((cashbackResponseJSON) => {
    //     const JSON = require("true-json-bigint");
    //     let cashbackResponse = JSON.parse(cashbackResponseJSON);
    //     if (cashbackResponse.status.statusCode !== 1) {
    //       if (cashbackResponse.status.statusCode === 200) {
    //         this.props.logout();

    //       } else {
    //         // this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createExchangeOperationResponse.status.statusCode) });
    //         this.props.updateAppState('isLoadingTransparentBackground', false);
    //       }
    //     }
    //     else {
    //       const formattedDate = `${new Date(cashbackResponse.startDate).getDate().toString().padStart(2, '0')}.${(new Date(cashbackResponse.startDate).getMonth() + 1).toString().padStart(2, '0')}.${new Date(cashbackResponse.startDate).getFullYear()}`;

    //       this.setState({
    //         cashbackInfo: cashbackResponse,
    //         formattedDate
    //       });

    //       this.props.updateAppState('isLoadingTransparentBackground', false);
    //     }
    //   });
  }

  unblockFunc = () => {
    this.props.updateAppState('isLoadingTransparentBackground', true);
    let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
    let cardPinCodeUnblockRequestBody = customerType == 'Juridical' ? {
      custId: localStorage.getItem('custId'),
      compId: localStorage.getItem('compId'),
      token: localStorage.getItem('token'),
      lang: localStorage.getItem('i18nextLng').toUpperCase(),
      cardNumber: this.state.card.cardNumber,
      cardId: this.state.card.cardId,
      currency: this.state.card.currency
    } : {
      custId: localStorage.getItem('custId'),
      token: localStorage.getItem('token'),
      lang: localStorage.getItem('i18nextLng').toUpperCase(),
      cardNumber: this.state.card.cardNumber,
      cardId: this.state.card.cardId,
      currency: this.state.card.currency
    }
    fetch(request("card/cardPinCodeUnblock", cardPinCodeUnblockRequestBody))
      .then(res => res.text())
      .then((cardPinCodeUnblockResponseJSON) => {
        const JSON = require('true-json-bigint');
        let cardPinCodeUnblockResponse = JSON.parse(cardPinCodeUnblockResponseJSON);
        if (cardPinCodeUnblockResponse.status.statusCode === 1) {
          // =========FEE=========
          let organizedFeeAmount;
          if (cardPinCodeUnblockResponse.feeAmount.toString() === "0") {
            organizedFeeAmount = {
              wholePart: "0",
              fractionalPart: "00"
            }
          }
          else {
            if (cardPinCodeUnblockResponse.feeAmount.toString().indexOf('.') !== -1) {
              let currentBalanceArray = cardPinCodeUnblockResponse.feeAmount.toString().split('.');
              organizedFeeAmount = {
                wholePart: currentBalanceArray[0],
                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
              }
            }
            else {
              organizedFeeAmount = {
                wholePart: cardPinCodeUnblockResponse.feeAmount.toString(),
                fractionalPart: "00"
              }
            }
          }
          // ===============
          let data = {
            cardNumber: this.state.card?.organizedCardNumber,
            rrn: cardPinCodeUnblockResponse.rrn,
            feeAmount: organizedFeeAmount,
            billingDate: cardPinCodeUnblockResponse.billingDate,
            pinUnblockAmount: cardPinCodeUnblockResponse.feeAmount
          };
          this.setState({ successModal: true, successModalData: data, isError: false, successTitle: this.props.t('Unblock pin success') });
        }
        else {
          if (cardPinCodeUnblockResponse.status.statusCode === 200) {
            this.props.logout();
          } else {
            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(cardPinCodeUnblockResponse.status.statusCode));
          }
        }
        this.props.updateAppState('isLoadingTransparentBackground', false);
      });
  }

  unblockConfirm(val) {
    if (val) {
      this.unblockFunc();
    }
  }

  unlockPin = () => {
    if (this.state.card?.pinUnblockAmount) {
      this.setState({ unblockPinModalOpen: true });
    }
    else {
      this.unblockFunc();
    }
  }
  setCardsBackground = (status, type) => {
    if (status) {
      if (type === cardTypes.DIGITAL_CARD) {
        return <img src={digitalCard} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="active-digital-card" />
      } else if (type === cardTypes.CARD_STORAGE) {
        return <img src={otherCard} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="card-storage" />
      }
      else {
        return <img src={cardBlueBackground} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="cardBlueBackground" />
      }
    } else {
      if (type === cardTypes.DIGITAL_CARD) {
        return <img src={digitalCard} className="dashboard-finance-section-card-section-details-section-content-card-background deactive-card" alt="deactive-digital-card" />
      } else if (type === cardTypes.CARD_STORAGE) {
        return <img src={otherCard} className="dashboard-finance-section-card-section-details-section-content-card-background deactive-card" alt="card-storage" />
      }
      else {
        return <img src={cardTransparentBackground} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="cardTransparentBackground" />
      }
    }
  }
  dropdownItemHandlerForDigital = (index, card) => {
    switch (index) {
      case 0:
        this.setState({
          openDigitalCardInfoModal: true,
          card: card
        });
        break;
    }
  }

  dropdownItemHandler = (i, card) => {
    // Navigate to the appropriate route with the card prop
    if (i === 0) {
      this.props.history.push(`/dashboard/finance/cards/${card.cardId}/conditions`, { card, categoryId: this.state.cashbackInfo.categoryId });
    }
    // Handle other dropdown items
  }
  sendDigitalCardInfoSms = () => {
    getDigitalCardInfo(
      this.props.updateAppState,
      this.state.card.cardId,
      this.props.logout,
      this.props.alertState,
      this.props.t);
  }

  activeCardButtonSection = (status, type) => {
    if (type === cardTypes.CARD_STORAGE) {
      if (status === 1) {
        return (
          <>
            <Link
              to="/dashboard/payment/payments"
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.518 0.143901L0.505898 8.20172C-0.213471 8.60774 -0.150917 9.66962 0.599729 9.98194L5.00977 11.7934V14.979C5.00977 15.9472 6.16702 16.3532 6.76128 15.6349L8.66917 13.3237L12.6101 14.9478C13.2043 15.1977 13.8924 14.8229 13.9862 14.167L15.988 1.14332C16.1131 0.331292 15.2373 -0.293345 14.518 0.143901ZM6.01063 14.979V12.2306L7.69959 12.9177L6.01063 14.979ZM13.0167 14.0421L6.54234 11.3561L12.7977 4.01665C12.9541 3.82926 12.7039 3.57941 12.5162 3.73557L4.54062 10.5441L1.00633 9.07621L15.0184 0.987162L13.0167 14.0421Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("make a payment")}
              </p>
            </Link>
            <Link
              to={`/dashboard/finance/cards/income/${this.props.match.params.id}`}
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M11.75 5.25C11.875 5.25 12 5.375 12 5.5V6.5C12 6.65625 11.875 6.75 11.75 6.75H6.75V11.75C6.75 11.9062 6.625 12 6.5 12H5.5C5.34375 12 5.25 11.9062 5.25 11.75V6.75H0.25C0.09375 6.75 0 6.65625 0 6.5V5.5C0 5.375 0.09375 5.25 0.25 5.25H5.25V0.25C5.25 0.125 5.34375 0 5.5 0H6.5C6.625 0 6.75 0.125 6.75 0.25V5.25H11.75Z" fill="#849097" />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("Income")}
              </p>
            </Link>
            <Link
              to="/dashboard/transfer/card-operation"
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8752 3.67164C16.0312 3.50882 16.0312 3.28087 15.8752 3.11805L13.0044 0.122117C12.8484 -0.0407056 12.5987 -0.0407056 12.4739 0.122117L12.2243 0.350068C12.0995 0.51289 12.0995 0.740841 12.2243 0.903664L14.1277 2.85753H0.397855C0.179425 2.85753 0.0234032 3.05292 0.0234032 3.2483V3.50882C0.0234032 3.73677 0.179425 3.89959 0.397855 3.89959H14.1277L12.2243 5.88602C12.0995 6.04885 12.0995 6.2768 12.2243 6.43962L12.4739 6.66757C12.5987 6.83039 12.8484 6.83039 13.0044 6.66757L15.8752 3.67164ZM0.117016 8.88195L3.01902 11.8779C3.14383 12.0407 3.39347 12.0407 3.51828 11.8779L3.76792 11.6499C3.89274 11.4871 3.89274 11.2592 3.76792 11.0963L1.86446 9.10991H15.6255C15.8128 9.10991 16 8.94708 16 8.71913V8.45862C16 8.26323 15.8128 8.06784 15.6255 8.06784H1.86446L3.76792 6.11398C3.89274 5.95115 3.89274 5.7232 3.76792 5.56038L3.51828 5.33243C3.39347 5.16961 3.14383 5.16961 3.01902 5.33243L0.117016 8.32836C-0.0390054 8.49118 -0.0390054 8.71913 0.117016 8.88195Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("transfer")}
              </p>
            </Link>
            <button className="dashboard-finance-section-card-section-details-section-content-button-container">
              <button
                onClick={() => this.setState({ openDeleteOtherCardModal: true })}
                className="dashboard-finance-section-card-section-details-section-content-button"
              >
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z"
                    fill="#586268"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("Delete")}
              </p>
            </button>
          </>
        );
      } else {
        return (
          <>
            <div className="dashboard-finance-section-card-section-details-section-content-button-container dashboard-finance-section-card-section-details-section-content-button-container__disable">
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__disable">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.518 0.143901L0.505898 8.20172C-0.213471 8.60774 -0.150917 9.66962 0.599729 9.98194L5.00977 11.7934V14.979C5.00977 15.9472 6.16702 16.3532 6.76128 15.6349L8.66917 13.3237L12.6101 14.9478C13.2043 15.1977 13.8924 14.8229 13.9862 14.167L15.988 1.14332C16.1131 0.331292 15.2373 -0.293345 14.518 0.143901ZM6.01063 14.979V12.2306L7.69959 12.9177L6.01063 14.979ZM13.0167 14.0421L6.54234 11.3561L12.7977 4.01665C12.9541 3.82926 12.7039 3.57941 12.5162 3.73557L4.54062 10.5441L1.00633 9.07621L15.0184 0.987162L13.0167 14.0421Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("make a payment")}
              </p>
            </div>
            <Link
              to={`/dashboard/finance/cards/income/${this.props.match.params.id}`}
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M11.75 5.25C11.875 5.25 12 5.375 12 5.5V6.5C12 6.65625 11.875 6.75 11.75 6.75H6.75V11.75C6.75 11.9062 6.625 12 6.5 12H5.5C5.34375 12 5.25 11.9062 5.25 11.75V6.75H0.25C0.09375 6.75 0 6.65625 0 6.5V5.5C0 5.375 0.09375 5.25 0.25 5.25H5.25V0.25C5.25 0.125 5.34375 0 5.5 0H6.5C6.625 0 6.75 0.125 6.75 0.25V5.25H11.75Z" fill="#849097" />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("Income")}
              </p>
            </Link>
            <div className="dashboard-finance-section-card-section-details-section-content-button-container dashboard-finance-section-card-section-details-section-content-button-container__disable">
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__disable">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8752 3.67164C16.0312 3.50882 16.0312 3.28087 15.8752 3.11805L13.0044 0.122117C12.8484 -0.0407056 12.5987 -0.0407056 12.4739 0.122117L12.2243 0.350068C12.0995 0.51289 12.0995 0.740841 12.2243 0.903664L14.1277 2.85753H0.397855C0.179425 2.85753 0.0234032 3.05292 0.0234032 3.2483V3.50882C0.0234032 3.73677 0.179425 3.89959 0.397855 3.89959H14.1277L12.2243 5.88602C12.0995 6.04885 12.0995 6.2768 12.2243 6.43962L12.4739 6.66757C12.5987 6.83039 12.8484 6.83039 13.0044 6.66757L15.8752 3.67164ZM0.117016 8.88195L3.01902 11.8779C3.14383 12.0407 3.39347 12.0407 3.51828 11.8779L3.76792 11.6499C3.89274 11.4871 3.89274 11.2592 3.76792 11.0963L1.86446 9.10991H15.6255C15.8128 9.10991 16 8.94708 16 8.71913V8.45862C16 8.26323 15.8128 8.06784 15.6255 8.06784H1.86446L3.76792 6.11398C3.89274 5.95115 3.89274 5.7232 3.76792 5.56038L3.51828 5.33243C3.39347 5.16961 3.14383 5.16961 3.01902 5.33243L0.117016 8.32836C-0.0390054 8.49118 -0.0390054 8.71913 0.117016 8.88195Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("transfer")}
              </p>
            </div>
            <div className="dashboard-finance-section-card-section-details-section-content-button-container dashboard-finance-section-card-section-details-section-content-button-container__disable">
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__disable">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z"
                    fill="#586268"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("Delete")}
              </p>
            </div>
          </>
        );
      }
    } else {
      if (status === 1) {
        return (
          <>
            <Link
              to="/dashboard/payment/payments"
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.518 0.143901L0.505898 8.20172C-0.213471 8.60774 -0.150917 9.66962 0.599729 9.98194L5.00977 11.7934V14.979C5.00977 15.9472 6.16702 16.3532 6.76128 15.6349L8.66917 13.3237L12.6101 14.9478C13.2043 15.1977 13.8924 14.8229 13.9862 14.167L15.988 1.14332C16.1131 0.331292 15.2373 -0.293345 14.518 0.143901ZM6.01063 14.979V12.2306L7.69959 12.9177L6.01063 14.979ZM13.0167 14.0421L6.54234 11.3561L12.7977 4.01665C12.9541 3.82926 12.7039 3.57941 12.5162 3.73557L4.54062 10.5441L1.00633 9.07621L15.0184 0.987162L13.0167 14.0421Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("make a payment")}
              </p>
            </Link>
            <Link
              to="/dashboard/transfer/card-operation"
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8752 3.67164C16.0312 3.50882 16.0312 3.28087 15.8752 3.11805L13.0044 0.122117C12.8484 -0.0407056 12.5987 -0.0407056 12.4739 0.122117L12.2243 0.350068C12.0995 0.51289 12.0995 0.740841 12.2243 0.903664L14.1277 2.85753H0.397855C0.179425 2.85753 0.0234032 3.05292 0.0234032 3.2483V3.50882C0.0234032 3.73677 0.179425 3.89959 0.397855 3.89959H14.1277L12.2243 5.88602C12.0995 6.04885 12.0995 6.2768 12.2243 6.43962L12.4739 6.66757C12.5987 6.83039 12.8484 6.83039 13.0044 6.66757L15.8752 3.67164ZM0.117016 8.88195L3.01902 11.8779C3.14383 12.0407 3.39347 12.0407 3.51828 11.8779L3.76792 11.6499C3.89274 11.4871 3.89274 11.2592 3.76792 11.0963L1.86446 9.10991H15.6255C15.8128 9.10991 16 8.94708 16 8.71913V8.45862C16 8.26323 15.8128 8.06784 15.6255 8.06784H1.86446L3.76792 6.11398C3.89274 5.95115 3.89274 5.7232 3.76792 5.56038L3.51828 5.33243C3.39347 5.16961 3.14383 5.16961 3.01902 5.33243L0.117016 8.32836C-0.0390054 8.49118 -0.0390054 8.71913 0.117016 8.88195Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("transfer")}
              </p>
            </Link>
            <Link
              to={`/dashboard/finance/cards/extract/${this.props.match.params.id}`}
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("extract")}
              </p>
            </Link>
            {type !== cardTypes.DIGITAL_CARD &&
              <Link
                to={`/dashboard/finance/cards/income/${this.props.match.params.id}`}
                className="dashboard-finance-section-card-section-details-section-content-button-container"
              >
                <button className="dashboard-finance-section-card-section-details-section-content-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M11.75 5.25C11.875 5.25 12 5.375 12 5.5V6.5C12 6.65625 11.875 6.75 11.75 6.75H6.75V11.75C6.75 11.9062 6.625 12 6.5 12H5.5C5.34375 12 5.25 11.9062 5.25 11.75V6.75H0.25C0.09375 6.75 0 6.65625 0 6.5V5.5C0 5.375 0.09375 5.25 0.25 5.25H5.25V0.25C5.25 0.125 5.34375 0 5.5 0H6.5C6.625 0 6.75 0.125 6.75 0.25V5.25H11.75Z" fill="#849097" />
                  </svg>
                </button>
                <p className="dashboard-finance-section-card-section-details-section-content-title">
                  {this.props.t("Income")}
                </p>
              </Link>
            }
            <Link
              to={`/dashboard/finance/cards/sms/${this.props.match.params.id}`}
              className="dashboard-finance-section-card-section-details-section-content-button-container"
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H2C0.875 0 0 0.90625 0 2V11C0 12.125 0.875 13 2 13H5V15.625C5 15.875 5.15625 16 5.375 16C5.4375 16 5.5 16 5.59375 15.9375L9.5 13H14C15.0938 13 16 12.125 16 11V2C16 0.90625 15.0938 0 14 0ZM15 11C15 11.5625 14.5312 12 14 12H9.15625L8.875 12.2188L6 14.375V12H2C1.4375 12 1 11.5625 1 11V2C1 1.46875 1.4375 1 2 1H14C14.5312 1 15 1.46875 15 2V11ZM8.75 7.5H4.25C4.09375 7.5 4 7.625 4 7.75V8.25C4 8.40625 4.09375 8.5 4.25 8.5H8.75C8.875 8.5 9 8.40625 9 8.25V7.75C9 7.625 8.875 7.5 8.75 7.5ZM11.75 4.5H4.25C4.09375 4.5 4 4.625 4 4.75V5.25C4 5.40625 4.09375 5.5 4.25 5.5H11.75C11.875 5.5 12 5.40625 12 5.25V4.75C12 4.625 11.875 4.5 11.75 4.5Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                SMS
              </p>
            </Link>
            <div
              className="dashboard-finance-section-card-section-details-section-content-button-container"
              onClick={() => {
                this.setState({ open: true });
              }}
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8C15.75 3.75 12.25 0.25 8 0.25ZM3.21875 12.7812C0.6875 10.2812 0.59375 6.25 2.875 3.59375L12.4062 13.125C9.75 15.4062 5.71875 15.3125 3.21875 12.7812ZM13.0938 12.4375L3.5625 2.90625C6.21875 0.625 10.25 0.71875 12.75 3.25C15.2812 5.75 15.375 9.78125 13.0938 12.4375Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("block")}
              </p>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="dashboard-finance-section-card-section-details-section-content-button-container dashboard-finance-section-card-section-details-section-content-button-container__disable">
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__disable">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.518 0.143901L0.505898 8.20172C-0.213471 8.60774 -0.150917 9.66962 0.599729 9.98194L5.00977 11.7934V14.979C5.00977 15.9472 6.16702 16.3532 6.76128 15.6349L8.66917 13.3237L12.6101 14.9478C13.2043 15.1977 13.8924 14.8229 13.9862 14.167L15.988 1.14332C16.1131 0.331292 15.2373 -0.293345 14.518 0.143901ZM6.01063 14.979V12.2306L7.69959 12.9177L6.01063 14.979ZM13.0167 14.0421L6.54234 11.3561L12.7977 4.01665C12.9541 3.82926 12.7039 3.57941 12.5162 3.73557L4.54062 10.5441L1.00633 9.07621L15.0184 0.987162L13.0167 14.0421Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("make a payment")}
              </p>
            </div>
            <div className="dashboard-finance-section-card-section-details-section-content-button-container dashboard-finance-section-card-section-details-section-content-button-container__disable">
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__disable">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8752 3.67164C16.0312 3.50882 16.0312 3.28087 15.8752 3.11805L13.0044 0.122117C12.8484 -0.0407056 12.5987 -0.0407056 12.4739 0.122117L12.2243 0.350068C12.0995 0.51289 12.0995 0.740841 12.2243 0.903664L14.1277 2.85753H0.397855C0.179425 2.85753 0.0234032 3.05292 0.0234032 3.2483V3.50882C0.0234032 3.73677 0.179425 3.89959 0.397855 3.89959H14.1277L12.2243 5.88602C12.0995 6.04885 12.0995 6.2768 12.2243 6.43962L12.4739 6.66757C12.5987 6.83039 12.8484 6.83039 13.0044 6.66757L15.8752 3.67164ZM0.117016 8.88195L3.01902 11.8779C3.14383 12.0407 3.39347 12.0407 3.51828 11.8779L3.76792 11.6499C3.89274 11.4871 3.89274 11.2592 3.76792 11.0963L1.86446 9.10991H15.6255C15.8128 9.10991 16 8.94708 16 8.71913V8.45862C16 8.26323 15.8128 8.06784 15.6255 8.06784H1.86446L3.76792 6.11398C3.89274 5.95115 3.89274 5.7232 3.76792 5.56038L3.51828 5.33243C3.39347 5.16961 3.14383 5.16961 3.01902 5.33243L0.117016 8.32836C-0.0390054 8.49118 -0.0390054 8.71913 0.117016 8.88195Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("transfer")}
              </p>
            </div>
            <div className="dashboard-finance-section-card-section-details-section-content-button-container dashboard-finance-section-card-section-details-section-content-button-container__disable">
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__disable">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("extract")}
              </p>
            </div>
            <div className="dashboard-finance-section-card-section-details-section-content-button-container dashboard-finance-section-card-section-details-section-content-button-container__disable">
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__disable">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H2C0.875 0 0 0.90625 0 2V11C0 12.125 0.875 13 2 13H5V15.625C5 15.875 5.15625 16 5.375 16C5.4375 16 5.5 16 5.59375 15.9375L9.5 13H14C15.0938 13 16 12.125 16 11V2C16 0.90625 15.0938 0 14 0ZM15 11C15 11.5625 14.5312 12 14 12H9.15625L8.875 12.2188L6 14.375V12H2C1.4375 12 1 11.5625 1 11V2C1 1.46875 1.4375 1 2 1H14C14.5312 1 15 1.46875 15 2V11ZM8.75 7.5H4.25C4.09375 7.5 4 7.625 4 7.75V8.25C4 8.40625 4.09375 8.5 4.25 8.5H8.75C8.875 8.5 9 8.40625 9 8.25V7.75C9 7.625 8.875 7.5 8.75 7.5ZM11.75 4.5H4.25C4.09375 4.5 4 4.625 4 4.75V5.25C4 5.40625 4.09375 5.5 4.25 5.5H11.75C11.875 5.5 12 5.40625 12 5.25V4.75C12 4.625 11.875 4.5 11.75 4.5Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                SMS
              </p>
            </div>
            <div
              className="dashboard-finance-section-card-section-details-section-content-button-container"
              onClick={() => {
                this.setState({ open: true });
              }}
            >
              <button className="dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__block">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8C15.75 3.75 12.25 0.25 8 0.25ZM3.21875 12.7812C0.6875 10.2812 0.59375 6.25 2.875 3.59375L12.4062 13.125C9.75 15.4062 5.71875 15.3125 3.21875 12.7812ZM13.0938 12.4375L3.5625 2.90625C6.21875 0.625 10.25 0.71875 12.75 3.25C15.2812 5.75 15.375 9.78125 13.0938 12.4375Z"
                    fill="#849097"
                  />
                </svg>
              </button>
              <p className="dashboard-finance-section-card-section-details-section-content-title">
                {this.props.t("unblock")}
              </p>
            </div>
          </>
        );
      }
    }
  }
  render() {
    let { card, cashbackInfo, cashBackStatements, formattedDate, statements, modalCardId, open, title, body, isLoading, unblockPinModalOpen, successModal, successModalData, openDigitalCardInfoModal, openSimpleModal, openDeleteOtherCardModal, visibleCashBack, statementDetails, statement } = this.state;
    let { t, updateAppState, logout, alertState, history } = this.props;
    return (
      <>
        <DigitalCardInfoModal open={openDigitalCardInfoModal} translate={t} toggleDigitalCardInfoModal={this.toggleDigitalCardInfoModal} sendDigitalCardInfoSms={this.sendDigitalCardInfoSms} />
        <SimpleModalCashback open={openSimpleModal} close={() => this.setState({ openSimpleModal: false })} title={title} body={body} />
        <CashbackStatementModal open={statementDetails} close={() => this.setState({ statementDetails: false })} data={statement} />

        <div className="dashboard-header-route">
          <div className="dashboard-header-route-left">
            <button onClick={() => this.props.history.push("/dashboard/finance/cards")}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
              </svg>
            </button>
            <h2>{card && card?.cardType !== cardTypes.CARD_STORAGE ? card.cardType : card?.cardType === cardTypes.CARD_STORAGE ? card?.holder : t('cards')}</h2>
          </div>
          <Link to="/dashboard/finance/cards" className="dashboard-header-route-link">{t('ALL CARDS')}</Link>
        </div>
        {
          isLoading ?
            <div className="dashboard-container-main-section">
              <Loading />
            </div>
            :
            !card ?
              <NotFound />
              :
              <>
                <PinUnblockSuccess history={this.props.history} data={successModalData} open={successModal} close={() => { this.setState({ successModal: false }) }} />
                <YesNoModal onClose={() => this.setState({ unblockPinModalOpen: false })} title={t('Unblock pin content', { pinUnblockAmount: card?.pinUnblockAmount })} open={unblockPinModalOpen} triggerFunc={val => this.unblockConfirm(val)} />
                <YesNoModal onClose={() => this.setState({ openDeleteOtherCardModal: false })} title={t("Delete card?")} open={openDeleteOtherCardModal} triggerFunc={() => deleteOtherBankCard(updateAppState, card.cardId, logout, alertState, t, true, null, history)} />
                {card && <Modal open={open} modalCardObj={card} modalCardId={modalCardId} updateOpenModal={this.updateOpenModal} updateAppState={updateAppState} showCard={this.showCard} />}
                <div className="dashboard-container-main-section">
                  <div className="dashboard-finance-section-card-section">
                    <div className="dashboard-finance-section-card-section-details-section">
                      <div className="dashboard-finance-section-card-section-details-section-background">
                        <div className="dashboard-finance-section-card-section-details-section-header">
                          {
                            card && card.cardId !== undefined &&
                            <div className="dashboard-finance-section-card-section-details-section-header-left-side">
                              <img src={setPaymentTypeIcon(card.cardPaymentType, masterCardIcon, visaCardIcon)} className="dashboard-finance-section-card-section-details-section-header-image" alt="cardIcon" />
                              <h3 className="dashboard-finance-section-card-section-details-section-header-card-number">{card.cardType === cardTypes.CARD_STORAGE ? maskCardNumber(card.cardNumber) : card.organizedCardNumber}</h3>
                            </div>
                          }
                          {
                            card && card.cardId !== undefined && card.cardType !== cardTypes.CARD_STORAGE &&
                            <div className="dashboard-finance-section-card-section-details-section-header-right-side">
                              <span className={"dashboard-finance-section-card-section-details-section-header-amount-currency dashboard-finance-section-card-section-details-section-header-amount-currency__" + card.currency.toLowerCase()}>{card.currencyIcon}</span>
                              <span className="dashboard-finance-section-card-section-details-section-header-amount-whole-part">{card.organizedCurrentBalance.wholePart}</span>
                              <span className="dashboard-finance-section-card-section-details-section-header-amount-fractional-part">{card.organizedCurrentBalance.fractionalPart}</span>
                            </div>
                          }

                        </div>
                        <div className="dashboard-finance-section-card-section-details-section-content">
                          <div className="dashboard-finance-section-card-section-details-section-content-card">
                            {this.setCardsBackground(card.cardStatus, card.cardType)}
                            <div className="dashboard-finance-section-card-section-details-section-content-card-item dashboard-finance-section-card-section-details-section-content-other-card-item">
                              {
                                card && card.cardId !== undefined &&
                                <img src={setPaymentTypeIcon(card.cardPaymentType, masterCardIcon, visaCardIconWhite)} className="dashboard-finance-section-card-section-details-section-content-card-item-logo" alt="cardIcon" />

                              }
                              {
                                card && card.cardId !== undefined &&
                                <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-number-section">
                                  <h4 className="dashboard-finance-section-card-section-details-section-content-card-item-card-number-content">{card.cardType === cardTypes.CARD_STORAGE ? maskCardNumber(card.cardNumber) : card.organizedCardNumber}</h4>
                                  <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-number-title">{t('card number')}</p>
                                </div>
                              }
                              {
                                card && card.cardId !== undefined && card.cardType !== cardTypes.CARD_STORAGE &&
                                <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-details-section mt-4">
                                  <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-owner-section">
                                    <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-owner-name">{card.organizedHolder.name} {card.organizedHolder.surname}</p>
                                    <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-owner-title">{t("owner's name")}</p>
                                  </div>
                                  <div className="dashboard-finance-section-card-section-details-section-content-card-item-card-valid-section">
                                    <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-valid-date">{card.organizedExpiryDate.month} / {card.organizedExpiryDate.year}</p>
                                    <p className="dashboard-finance-section-card-section-details-section-content-card-item-card-valid-title">{t('it is reliable')}</p>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="dashboard-finance-section-card-section-details-section-content-button-section">
                            {
                              this.activeCardButtonSection(card.cardStatus, card.cardType)
                            }
                            {
                              (card && card?.cardStatus === 1 && card.cardType !== cardTypes.DIGITAL_CARD && card.cardType !== cardTypes.CARD_STORAGE) ?
                                <div className="dashboard-finance-section-card-section-details-section-content-button-container">
                                  <Dropdown
                                    className={"dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__more"}
                                    dropdownItems={
                                      visibleCashBack === 'true' ?
                                        [
                                          { title: this.props.t("Select the condition") },
                                          { title: this.props.t("pin_settings"), url: `/dashboard/finance/cards/pin/settings/${card?.cardId}` },
                                          { title: this.props.t("Limits management"), url: `/dashboard/finance/cards/limits/${card?.currency}/${card?.cardId}` }
                                        ] :
                                        [
                                          { title: this.props.t("pin_settings"), url: `/dashboard/finance/cards/pin/settings/${card?.cardId}` },
                                          { title: this.props.t("Limits management"), url: `/dashboard/finance/cards/limits/${card?.currency}/${card?.cardId}` }
                                        ]}
                                    cardId={card?.cardId}
                                    dropdownItemHandler={(i) => this.dropdownItemHandler(i, card)} />
                                  <p className="dashboard-finance-section-card-section-details-section-content-title">{t('more')}</p>
                                </div>
                                : card.cardStatus === 1 && card.cardType === cardTypes.DIGITAL_CARD ?
                                  <div className="dashboard-finance-section-card-section-details-section-content-button-container">
                                    <Dropdown
                                      className={"dashboard-finance-section-card-section-details-section-content-button dashboard-finance-section-card-section-details-section-content-button__more"}
                                      dropdownItems={
                                        visibleCashBack === 'true' ?
                                          [{ title: this.props.t('Select the condition') },
                                          { title: this.props.t('Card information') }]
                                          :
                                          [{ title: this.props.t('Card information') }]
                                      }
                                      cardId={card?.cardId}
                                      dropdownItemHandler={(i) => this.dropdownItemHandlerForDigital(i, card)} />
                                    <p className="dashboard-finance-section-card-section-details-section-content-title">{t('more')}</p>
                                  </div>
                                  : null
                            }
                          </div>
                          <ul className="dashboard-finance-section-card-section-details-section-content-list">
                            {
                              card &&
                              card.cardId !== undefined && card.cardType !== cardTypes.CARD_STORAGE &&
                              <>
                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('name of the card')}</p>
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{card.cardType}</p>
                                </li>
                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('card linked account')}</p>
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{card.correspondentAccount}</p>
                                </li>
                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('card number')}</p>
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{card.organizedCardNumber}</p>
                                </li>
                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t("cardholder's name")}</p>
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{card.organizedHolder.name} {card.organizedHolder.surname}</p>
                                </li>
                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('expiration date')}</p>
                                  <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{card.organizedExpiryDate.day}.{card.organizedExpiryDate.month}.{card.organizedExpiryDate.fullYear}</p>
                                </li>
                              </>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                    {visibleCashBack === 'true' ?
                      <div className="dashboard-finance-section-card-section-details-section dashboard-finance-section-card-section-operation-sections">
                        <div className="dashboard-finance-section-card-section-operation-section dashboard-finance-section-card-section-operation-section-redesign">
                          <div className="dashboard-finance-section-card-section-operation-section-redesign-background">
                            <div className="dashboard-finance-section-card-section-details-section-header">
                              <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{cashbackInfo?.categoryName}  {formattedDate && <span>{t('from the date of')} {formattedDate} </span>}
                                <button onClick={() => {
                                  this.setState({ title: cashbackInfo?.categoryName, body: t("You can change the cash back offer once a month."), openSimpleModal: true });
                                }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                    <path d="M8.5 12V9M8.5 6H8.5075M16 9C16 13.1421 12.6421 16.5 8.5 16.5C4.35786 16.5 1 13.1421 1 9C1 4.85786 4.35786 1.5 8.5 1.5C12.6421 1.5 16 4.85786 16 9Z" stroke="#0179D2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                </button>
                              </h3>
                              <button onClick={() => this.dropdownItemHandler(0, card)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                  <path d="M7.03571 14H13.0714M8.92187 2.57576L11.5625 4.93939M2.1317 9.66667L9.57032 1.6273C10.3712 0.790901 11.6698 0.790899 12.4707 1.6273C13.2717 2.4637 13.2717 3.81977 12.4707 4.65617L4.77232 12.4242L1 13.6061L2.1317 9.66667Z" stroke="#0179D2" stroke-width="1.04167" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </button>
                            </div>
                            {
                              // statements.length === 0 ?
                              !cashbackInfo ?
                                <div className="dashboard-last-operations-section-content">
                                  <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                  <p className="dashboard-last-operations-section-content-status">
                                    {
                                      card.cardStatus === 0 ?
                                        t('The card is blocked')
                                        :
                                        t('you do not currently have any active transactions')

                                    }
                                  </p>
                                </div>
                                :
                                <div className="dashboard-finance-section-card-section-operation-section-content">
                                  <ul className="dashboard-last-operations-section-list">
                                    <li className="dashboard-last-operations-section-item">
                                      <div className="dashboard-last-operations-section-item-left-side">
                                        <div className="dashboard-last-operations-section-item-content">
                                          <div className="dashboard-last-operations-section-item-company dashboard-last-operations-section-item-company-redesign">{t('Circulation requirement')}</div></div>
                                      </div>
                                      <div className="dashboard-last-operations-section-item-rigth-side">
                                        <div className="dashboard-last-operations-section-item-amount">
                                          <span className="dashboard-last-operations-section-item-amount-whole-part">
                                            <span className="dashboard-last-operations-section-item-amount-whole-part">{cashbackInfo?.minTurnoverAmount || '0'} </span>₼‎</span>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="dashboard-last-operations-section-item">
                                      <div className="dashboard-last-operations-section-item-left-side">
                                        <div className="dashboard-last-operations-section-item-content">
                                          <div className="dashboard-last-operations-section-item-company dashboard-last-operations-section-item-company-redesign">{t('Spent')}</div></div>
                                      </div>
                                      <div className="dashboard-last-operations-section-item-rigth-side">
                                        <div className="dashboard-last-operations-section-item-amount">
                                          <span className="dashboard-last-operations-section-item-amount-whole-part">
                                            <span className="dashboard-last-operations-section-item-amount-whole-part">{cashbackInfo?.turnoverAmount || '0'} </span>₼‎</span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                            }
                          </div>
                        </div>
                        <div className="dashboard-finance-section-card-section-operation-section dashboard-finance-section-card-section-operation-section-redesign">
                          <div className="dashboard-finance-section-card-section-operation-section-redesign-background">
                            {/* {
                            statements.length === 0 ?
                              <div className="dashboard-last-operations-section-content">
                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                <p className="dashboard-last-operations-section-content-status">
                                  {
                                    card.cardStatus === 0 ?
                                      t('The card is blocked')
                                      :
                                      t('you do not currently have any active transactions')

                                  }
                                </p>
                              </div>
                              : */}
                            <div className="dashboard-finance-section-card-section-operation-section-content">
                              <ul className="dashboard-last-operations-section-list">
                                <li className="dashboard-last-operations-section-item">
                                  <div className="dashboard-last-operations-section-item-left-side dashboard-last-operations-section-item-company-redesign">
                                    {t('Earned total stars')}
                                    {/* <button onClick={() => {
                                    this.setState({ title: t('Earned total stars'), body: t('Earned total stars'), openSimpleModal: true });
                                  }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" className='ml-1'>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 0.75C9.70225 0.75 12.5 3.54775 12.5 7C12.5 10.4522 9.70225 13.25 6.25 13.25C2.79775 13.25 0 10.4522 0 7C0 3.54775 2.79775 0.75 6.25 0.75ZM4.60601 6.12821C4.59696 5.50217 6.02882 5.15811 6.67814 5.33014C8.32471 5.76604 5.94216 9.59597 6.35348 10.1625C6.58242 10.4781 7.62365 10.2298 7.62365 10.5156C7.62365 10.8028 6.91743 11.5465 5.71451 11.2995C4.5103 11.0511 4.83495 10.2104 4.99793 9.5326C5.15961 8.85482 5.85679 6.98189 5.85679 6.65723C5.85679 5.63928 4.59049 6.39595 4.60601 6.12821ZM6.95364 2.65139C7.47361 2.65139 7.89399 3.07176 7.89399 3.59173C7.89399 4.1117 7.47361 4.53208 6.95364 4.53208C6.43367 4.53208 6.0133 4.1117 6.0133 3.59173C6.0133 3.07176 6.43367 2.65139 6.95364 2.65139Z" fill="#0179D2" />
                                    </svg>
                                  </button> */}
                                    <span className="dashboard-last-operations-section-item-amount-whole-part text-dark ml-3 mr-1">{cashbackInfo?.bonusBalance}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                      <path d="M8.62308 3.53602L11.8702 4.02778C12.1426 4.07462 12.3697 4.26196 12.4605 4.54297C12.5514 4.80056 12.4832 5.10498 12.2789 5.29232L9.91737 7.70431L10.485 11.1232C10.5305 11.4043 10.4169 11.6853 10.1898 11.8492C9.96278 12.0365 9.66759 12.0365 9.41782 11.9194L6.51135 10.3036L3.58218 11.9194C3.35511 12.0365 3.03722 12.0365 2.83286 11.8492C2.60579 11.6853 2.49225 11.4043 2.53767 11.1232L3.08263 7.70431L0.721127 5.29232C0.516766 5.10498 0.448645 4.80056 0.539472 4.54297C0.630299 4.26196 0.857367 4.07462 1.12985 4.02778L4.39962 3.53602L5.85286 0.421512C5.96639 0.163921 6.21617 0 6.51135 0C6.78383 0 7.03361 0.163921 7.14714 0.421512L8.62308 3.53602Z" fill="#027AD3" />
                                    </svg>
                                  </div>
                                  <div className="dashboard-last-operations-section-item-rigth-side">
                                    <Link to="/dashboard/payment/bonus/payments" className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue">{t('make a payment')}</Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            {/* } */}
                          </div>
                        </div>

                        <div className="dashboard-finance-section-card-section-operation-section dashboard-finance-section-card-section-operation-section-redesign">
                          <div className="dashboard-finance-section-card-section-operation-section-redesign-background">
                            {/* {
                            statements.length === 0 ?
                              <div className="dashboard-last-operations-section-content">
                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                <p className="dashboard-last-operations-section-content-status">
                                  {
                                    card.cardStatus === 0 ?
                                      t('The card is blocked')
                                      :
                                      t('you do not currently have any active transactions')

                                  }
                                </p>
                              </div>
                              : */}
                            <div className="dashboard-finance-section-card-section-operation-section-content">
                              <ul className="dashboard-last-operations-section-list">

                                <li className="dashboard-last-operations-section-item py-3">
                                  <div className="dashboard-last-operations-section-item-left-side">
                                    <div>
                                      <div className="dashboard-last-operations-section-item-company dashboard-last-operations-section-item-company-redesign">{t('Earned cashback')}
                                        {/* <button
                                        onClick={() => {
                                          this.setState({ title: t('Earned cashback'), body: t('Earned cashback'), openSimpleModal: true });
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" className='ml-1'>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 0.75C9.70225 0.75 12.5 3.54775 12.5 7C12.5 10.4522 9.70225 13.25 6.25 13.25C2.79775 13.25 0 10.4522 0 7C0 3.54775 2.79775 0.75 6.25 0.75ZM4.60601 6.12821C4.59696 5.50217 6.02882 5.15811 6.67814 5.33014C8.32471 5.76604 5.94216 9.59597 6.35348 10.1625C6.58242 10.4781 7.62365 10.2298 7.62365 10.5156C7.62365 10.8028 6.91743 11.5465 5.71451 11.2995C4.5103 11.0511 4.83495 10.2104 4.99793 9.5326C5.15961 8.85482 5.85679 6.98189 5.85679 6.65723C5.85679 5.63928 4.59049 6.39595 4.60601 6.12821ZM6.95364 2.65139C7.47361 2.65139 7.89399 3.07176 7.89399 3.59173C7.89399 4.1117 7.47361 4.53208 6.95364 4.53208C6.43367 4.53208 6.0133 4.1117 6.0133 3.59173C6.0133 3.07176 6.43367 2.65139 6.95364 2.65139Z" fill="#0179D2" />
                                        </svg>
                                      </button> */}
                                        <span className="dashboard-last-operations-section-item-amount-whole-part ml-3 mr-1">
                                          <span className="dashboard-last-operations-section-item-amount-whole-part text-dark">{cashbackInfo?.balance} </span>
                                          ₼‎
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="dashboard-last-operations-section-item-rigth-side">

                                    <Link to={{ pathname: `/dashboard/finance/cards/card/convertation/${this.props.match.params.id}`, state: { earnedCashback: cashbackInfo?.balance, calculatedCashback: cashbackInfo?.tempBalance } }}
                                      className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue">{t('Transfer to card')}</Link>
                                  </div>
                                </li>

                                <li className="dashboard-last-operations-section-item py-3">
                                  <div className="dashboard-last-operations-section-item-left-side">
                                    <div>
                                      <div className="dashboard-last-operations-section-item-company dashboard-last-operations-section-item-company-redesign">{t('Calculated cashback')}
                                        {/* <button onClick={() => {
                                        this.setState({ title: t('Earned cashback'), body: t('Earned cashback'), openSimpleModal: true });
                                      }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" className='ml-1'>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 0.75C9.70225 0.75 12.5 3.54775 12.5 7C12.5 10.4522 9.70225 13.25 6.25 13.25C2.79775 13.25 0 10.4522 0 7C0 3.54775 2.79775 0.75 6.25 0.75ZM4.60601 6.12821C4.59696 5.50217 6.02882 5.15811 6.67814 5.33014C8.32471 5.76604 5.94216 9.59597 6.35348 10.1625C6.58242 10.4781 7.62365 10.2298 7.62365 10.5156C7.62365 10.8028 6.91743 11.5465 5.71451 11.2995C4.5103 11.0511 4.83495 10.2104 4.99793 9.5326C5.15961 8.85482 5.85679 6.98189 5.85679 6.65723C5.85679 5.63928 4.59049 6.39595 4.60601 6.12821ZM6.95364 2.65139C7.47361 2.65139 7.89399 3.07176 7.89399 3.59173C7.89399 4.1117 7.47361 4.53208 6.95364 4.53208C6.43367 4.53208 6.0133 4.1117 6.0133 3.59173C6.0133 3.07176 6.43367 2.65139 6.95364 2.65139Z" fill="#0179D2" />
                                        </svg>
                                      </button> */}
                                        <span className="dashboard-last-operations-section-item-amount-whole-part ml-3 mr-1">
                                          <span className="dashboard-last-operations-section-item-amount-whole-part text-dark">{cashbackInfo?.tempBalance} </span>
                                          ₼‎
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            {/* } */}
                          </div>
                        </div>

                        <div className="dashboard-finance-section-card-section-operation-section dashboard-finance-section-card-section-operation-section-redesign">
                          <div className="dashboard-finance-section-card-section-operation-section-background">
                            <div className="dashboard-finance-section-card-section-details-section-header">
                              <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('recent operations')}</h3>
                              <Link to={{
                                pathname: `/dashboard/finance/cards/cashback/extract/${this.props.match.params.id}`,
                                state: { card: card }
                              }}
                                className="dashboard-finance-section-card-section-details-section-header-show-all dashboard-last-operations-section-header-history" style={{ color: '#0179D2' }}>{t('all history')}</Link>
                            </div>
                            {
                              // statements.length === 0 ?
                              cashBackStatements.length === 0 ?
                                <div className="dashboard-last-operations-section-content">
                                  <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                  <p className="dashboard-last-operations-section-content-status">
                                    {
                                      card.cardStatus === 0 ?
                                        t('The card is blocked')
                                        :
                                        t('you do not currently have any active transactions')

                                    }
                                  </p>
                                  <ul className="dashboard-last-operations-section-content-invisible-list">
                                    <li className="dashboard-last-operations-section-content-invisible-list-item">
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                      </div>
                                    </li>
                                    <li className="dashboard-last-operations-section-content-invisible-list-item">
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                :
                                <div className="dashboard-finance-section-card-section-operation-section-content dashboard-finance-section-card-section-operation-section-content-redesign">
                                  <ul className='dashboard-finance-section-card-section-operation-section-content-type'>
                                    <li><span style={{ background: '#62B865' }}></span>{t('Confirmed')}</li>
                                    <li><span style={{ background: '#FFAF37' }}></span>{t('Waiting')}</li>
                                    <li><span style={{ background: '#FF5A5A' }}></span>{t('Abolished')}</li>
                                  </ul>
                                  <ul className="dashboard-last-operations-section-list">
                                    {
                                      cashBackStatements?.slice(0, 10).map((statement, index) =>
                                        <li className="dashboard-last-operations-section-item" key={index} onClick={() => this.setState({ statement: statement }, () => {
                                          this.setState({ statementDetails: true })
                                        })} style={{ cursor: 'pointer' }}>
                                          <div className="dashboard-last-operations-section-item-left-side">
                                            <div className='dashboard-finance-section-card-section-operation-section-content-type'>
                                              <span style={{ background: statement?.color }}></span>
                                            </div>
                                            <div className="dashboard-last-operations-section-item-content">
                                              <div className="dashboard-last-operations-section-item-company">{statement?.categoryName}</div>
                                              <div className="dashboard-last-operations-section-item-description">
                                                {new Date(statement?.paymentDate).getDate().toString().padStart(2, '0')}.{(new Date(statement?.paymentDate).getMonth() + 1).toString().padStart(2, '0')}.{new Date(statement?.paymentDate).getFullYear()}, {new Date(statement?.paymentDate).getHours().toString().padStart(2, '0')}:{new Date(statement?.paymentDate).getMinutes().toString().padStart(2, '0')}, {statement?.cardNumber}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="dashboard-last-operations-section-item-rigth-side">
                                            {statement?.amount && <div className="dashboard-last-operations-section-item-amount">
                                              <span className="dashboard-last-operations-section-item-amount-whole-part">-{Math.floor(statement?.amount)}</span>
                                              <span className="dashboard-last-operations-section-item-amount-fractional-part">{(statement?.amount - Math.floor(statement?.amount)).toFixed(2).slice(2)}</span>
                                              <span className="dashboard-last-operations-section-item-amount-currency">{statement?.currency}</span>
                                            </div>}

                                            <p className="dashboard-last-operations-section-item-category d-flex align-items-center">
                                              {statement?.cashBackAmount && <div className="dashboard-last-operations-section-item-amount mr-1" style={{ color: statement?.color }}>
                                                <span className="dashboard-last-operations-section-item-amount-whole-part">+{Math.floor(statement?.cashBackAmount)}</span>
                                                <span className="dashboard-last-operations-section-item-amount-fractional-part">{(statement?.cashBackAmount - Math.floor(statement?.cashBackAmount)).toFixed(2).slice(2)}</span>
                                                <span className="dashboard-last-operations-section-item-amount-currency">{statement?.currency}</span>
                                              </div>}

                                              {statement?.checkStar ? (
                                                <div className="dashboard-last-operations-section-item-icon-container m-0" style={{ height: '30px', fontSize: '14px' }}>
                                                  {statement?.cashBackInterest}
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                    <path d="M8.62308 3.53602L11.8702 4.02778C12.1426 4.07462 12.3697 4.26196 12.4605 4.54297C12.5514 4.80056 12.4832 5.10498 12.2789 5.29232L9.91737 7.70431L10.485 11.1232C10.5305 11.4043 10.4169 11.6853 10.1898 11.8492C9.96278 12.0365 9.66759 12.0365 9.41782 11.9194L6.51135 10.3036L3.58218 11.9194C3.35511 12.0365 3.03722 12.0365 2.83286 11.8492C2.60579 11.6853 2.49225 11.4043 2.53767 11.1232L3.08263 7.70431L0.721127 5.29232C0.516766 5.10498 0.448645 4.80056 0.539472 4.54297C0.630299 4.26196 0.857367 4.07462 1.12985 4.02778L4.39962 3.53602L5.85286 0.421512C5.96639 0.163921 6.21617 0 6.51135 0C6.78383 0 7.03361 0.163921 7.14714 0.421512L8.62308 3.53602Z" fill="#027AD3" />
                                                  </svg>
                                                </div>
                                              )
                                                :
                                                <div className="dashboard-last-operations-section-item-icon-container m-0" style={{ height: '30px' }}>
                                                  {statement?.cashBackInterest}%
                                                </div>
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      )
                                    }
                                  </ul>
                                </div>
                            }
                          </div>
                        </div>
                      </div>

                      :

                      <div className="dashboard-finance-section-card-section-operation-section">
                        <div className="dashboard-finance-section-card-section-operation-section-background">
                          <div className="dashboard-finance-section-card-section-details-section-header">
                            <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('recent operations')}</h3>
                            <Link to={`/dashboard/finance/cards/extract/${this.props.match.params.id}`} className="dashboard-finance-section-card-section-details-section-header-show-all dashboard-last-operations-section-header-history">{t('all history')}</Link>
                          </div>
                          {
                            statements.length === 0 ?
                              <div className="dashboard-last-operations-section-content">
                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                <p className="dashboard-last-operations-section-content-status">
                                  {
                                    card.cardStatus === 0 ?
                                      t('The card is blocked')
                                      :
                                      t('you do not currently have any active transactions')

                                  }
                                </p>
                                <ul className="dashboard-last-operations-section-content-invisible-list">
                                  <li className="dashboard-last-operations-section-content-invisible-list-item">
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                    </div>
                                  </li>
                                  <li className="dashboard-last-operations-section-content-invisible-list-item">
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                    </div>
                                  </li>
                                  <li className="dashboard-last-operations-section-content-invisible-list-item">
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                      <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              :
                              <div className="dashboard-finance-section-card-section-operation-section-content">
                                {card.cardType !== cardTypes.CARD_STORAGE &&
                                  <div className="dashboard-finance-section-card-section-operation-section-content-pick-date">
                                    <p className="dashboard-finance-section-card-section-operation-section-content-pick-date-title">{t('select the output interval')}</p>
                                    <div className="dashboard-finance-section-card-section-operation-section-content-pick-date-input-section">
                                      <StartDatePicker placeholder="Başlama tarixi" showStatements={this.showStatements} />
                                      <EndDatePicker placeholder="Bitmə tarixi" showStatements={this.showStatements} />
                                    </div>
                                  </div>
                                }
                                <ul className="dashboard-last-operations-section-list">
                                  {
                                    statements.slice(0, 10).map((statement, index) =>
                                      <li className="dashboard-last-operations-section-item" key={index}>
                                        <div className="dashboard-last-operations-section-item-left-side">
                                          <div className="dashboard-last-operations-section-item-icon-container">
                                            <svg className="dashboard-last-operations-section-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                            </svg>
                                          </div>
                                          <div className="dashboard-last-operations-section-item-content">
                                            <div className="dashboard-last-operations-section-item-company">{statement.organizedCardNumber}</div>
                                            <div className="dashboard-last-operations-section-item-description">{card.cardType === cardTypes.CARD_STORAGE ? statement.name : statement.purpose}</div>
                                          </div>
                                        </div>
                                        <div className="dashboard-last-operations-section-item-rigth-side">
                                          <div className="dashboard-last-operations-section-item-amount">
                                            <span className="dashboard-last-operations-section-item-amount-whole-part">{statement.amount.wholePart}</span>
                                            <span className="dashboard-last-operations-section-item-amount-fractional-part">{statement.amount.fractionalPart}</span>
                                            <span className="dashboard-last-operations-section-item-amount-currency">{statement.currency}</span>
                                          </div>
                                          <p className="dashboard-last-operations-section-item-category">{statement.organizedDate.split(' ')[0]}</p>
                                        </div>
                                      </li>
                                    )
                                  }
                                </ul>
                              </div>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>

              </>
        }
      </>
    )
  }
}


export default translate("translation")(Card);