import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { translate } from "react-i18next";

import PINSettingsHeader from "../PINSettingsHeader/PINSettingsHeader";

import "./PINInstruction.scss";

const PINInstruction = ({ translate }) => {
  let history = useHistory();
  let { instructionType } = useParams();
  const iframeUrl = localStorage.getItem("iframe");

  return (
    <section className="ibank-pin-instruction">
      <PINSettingsHeader type={instructionType} {...{ translate }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={5}>
          <div className="ibank-pin-instruction__content">
            <div className="ibank-pin-instruction__content__header">
              <h6>{translate("Instruction")}</h6>
            </div>
            <div className="ibank-pin-instruction__content__step">
              <h6>{translate("STEP 1")}</h6>
              <p>
                {translate("You received a code via SMS to set the PIN code")}
              </p>
              <div className="ibank-pin-instruction__content__step__card">
                <div className="ibank-pin-instruction__content__step__card__details">
                  <p>{translate("Sample code received via SMS")}</p>
                  <p className="spacing-1">5*1*62*84*</p>
                </div>
              </div>
            </div>
            <div className="ibank-pin-instruction__content__step">
              <h6>{translate("STEP 2")}</h6>
              <p>
                {translate(
                  "On the page that opens, replace the **** characters with the numbers of the new PIN code"
                )}
              </p>
              <div className="ibank-pin-instruction__content__step__card">
                <div className="ibank-pin-instruction__content__step__card__details">
                  <p>
                    {translate(
                      "You received a code via SMS to set the PIN code"
                    )}
                  </p>
                  <p>
                    {translate(
                      "On the page that opens, replace the **** characters with the numbers of the new PIN code"
                    )}
                  </p>
                </div>
                <div className="ibank-pin-instruction__content__step__card__details">
                  <p>{translate("Your new PIN code")}</p>
                  <p className="color-blue weight-500 spacing-1">1234</p>
                </div>
                <div className="ibank-pin-instruction__content__step__card__details">
                  <p>
                    {translate(
                      "Enter *** characters by replacing them with new PIN code numbers, then continue"
                    )}
                  </p>
                  <p className="weight-500 spacing-1">
                    5<span className="color-blue weight-500">1</span>1
                    <span className="color-blue weight-500">2</span>62
                    <span className="color-blue weight-500">3</span>84
                    <span className="color-blue weight-500">4</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <div className="ibank-pin-instruction__actions">
            <button
              type="button"
              className="ibank-pin-instruction__actions__continue"
              onClick={() => window.open(iframeUrl)}
            >
              {translate("continue")}
            </button>
            <button
              className="ibank-pin-instruction__actions__cancel"
              type="button"
              onClick={() => {
                history.goBack();
                localStorage.removeItem("iframe");
              }}
            >
              {translate("refusal")}
            </button>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default translate("translation")(PINInstruction);
