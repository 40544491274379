import React, { Component } from "react";
import loading from "./img/loading.svg";
import "./scss/style.scss";

export default class LoadingBeforeResponse extends Component {
    // componentWillMount() {
    //     window.scrollTo(0, 0);
    //     document.body.style.overflow = 'hidden';
    // }
    // componentWillUnmount() {
    //     document.body.style.overflow = 'auto';
    // }
    render() {
        return (
            <div className="background-loading-request" style={{position: 'fixed', width: '100%', height: '100%', overflow: 'hidden'}}>
                <img src={loading} className="background-loading-request-img" alt="Loading"/>
            </div>
        )
    }
}
