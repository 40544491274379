import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { request, ipsRequest } from './../../../../config';
import Dropdown from '../../../../copms/Dropdown/Dropdown';
import { translate } from 'react-i18next';
import Loading from '../../Loading/Loading';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import GeneralModal from './../../../../copms/Modal/GeneralModal';
import ReverseModal from './../../../../copms/Modal/ReverseModal';
import lastOperationsIcon from './../../img/last-operations-icon.png';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
class Income extends Component {

    state = {
        receivePayments: [],
        index: null,
        incomeIndex: null,
        income: null,
        openAlert: false,
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
        createFeedbackMessage: false,
        open: false,
        reverseAmount: '',
        reverseReason: ''
    }

    setOpen = (val) => {this.setState({open: val});}
    setOpenAlert = (val) => {this.setState({openAlert: val});}
    setReverseAmount = (val) => {this.setState({reverseAmount: val});}
    setReverseReason = (val) => {this.setState({reverseReason: val});}
    setFeedbackErrorModal = (val) => {this.setState({feedbackErrorModal: val});}
    setFeedbackSuccessModal = (val) => {this.setState({feedbackSuccessModal: val});}
    setCreateFeedbackMessage = (val) => {this.setState({createFeedbackMessage: val});}
    setReceivePayments = (val) => {this.setState({receivePayments: val})};
    showIncome = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let receivePaymentListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                
            }

        fetch(ipsRequest("receivePaymentList" + customerType, receivePaymentListRequestBody))
            .then(res => res.text())
            .then((receivePaymentListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let receivePaymentListResponse = JSON.parse(receivePaymentListResponseJSON);
                if (receivePaymentListResponse.status.statusCode !== 1 && receivePaymentListResponse.status.statusCode !== 214) {
                    if (receivePaymentListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(receivePaymentListResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (receivePaymentListResponse.status.statusCode === 214) {
                        receivePaymentListResponse.receivePayments = [];
                    }
                    this.setState({ receivePayments: receivePaymentListResponse.receivePayments });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showPdf = (item) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getPaymentDocByOperIdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: item.operId
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: item.operId
            }
        fetch(request("operation/getPaymentDocByOperId", getPaymentDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getPaymentDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getPaymentDocByOperIdResponse = JSON.parse(getPaymentDocByOperIdResponseJSON);
                if (getPaymentDocByOperIdResponse.status.statusCode !== 1) {
                    if (getPaymentDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getPaymentDocByOperIdResponse.status.statusCode));
                    }
                } else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getPaymentDocByOperIdResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    window.open(link, '');
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    dropdownItemHandler = (index, incomeIndex, income, url) => {
        switch (index) {
            case 0:
                this.showPdf(income);
                // this.setState({ incomeIndex: incomeIndex, income: income, index: index })
                break;
            case 1:
                this.setState({ incomeIndex: incomeIndex, income: income, index: index, open: true, reverseAmount: income.amount ? income.amount.toFixed(2) : '0.00'})
                break;
        }
    }
    componentDidMount() {
        this.showIncome();
    }
    render() {
        let { isLoading, open, index, incomeIndex, income, receivePayments,  reverseAmount, reverseReason,
            openAlert, createFeedbackMessage, feedbackErrorModal, feedbackSuccessModal } = this.state;
        let { updateAppState, logout, history, t } = this.props;
        return (
            <Fragment>
                <ReverseModal 
                open={open} setOpen={this.setOpen} 
                setOpenAlert={this.setOpenAlert} setIncome={this.setIncome}
                setCreateFeedbackMessage={this.setCreateFeedbackMessage}
                setFeedbackSuccessModal={this.setFeedbackSuccessModal}
                setFeedbackErrorModal={this.setFeedbackErrorModal}
                amount={reverseAmount} setAmount={this.setReverseAmount}
                reason={reverseReason} setReason={this.setReverseReason}
                updateAppState={updateAppState} logout={logout}
                receivePayments={receivePayments} setReceivePayments={this.setReceivePayments}
                income={income} 
                index={incomeIndex} t={t}/>
                <GeneralModal 
                open={openAlert} successClose={() => this.setState({ openAlert: false })}
                errorClose={() => this.setState({ openAlert: false })}
                createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal}
                feedbackSuccessModal={feedbackSuccessModal} history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Income')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                                <div className="dashboard-transfer-list-header" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                    <h3 className="dashboard-template-section-header-title">{t("Payments made to my account")}</h3>
                                </div>
                                <div className="dashboard-transfer-list-content" style={{ padding: 0, height: '100%', minHeight: '100vh' }}>
                                    {
                                        receivePayments && receivePayments.length !== 0 ?
                                            <table className="dashboard-transfer-list-table">
                                                <thead className="dashboard-transfer-list-table-head">
                                                    <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("SENDER PERSON")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("RECİPİENTS PERSON")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("AMOUNT")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("REVERSED AMOUNT")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="dashboard-transfer-list-table-body">
                                                    {
                                                        receivePayments && receivePayments.map((receivePayment, index) => {
                                                            return (
                                                                <tr className="dashboard-transfer-list-table-row" key={index}>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        <div className="dashboard-last-operations-section-item-amount" style={{textAlign: 'left'}}>{receivePayment.createdDate && receivePayment.createdDate.split(' ')[0]}</div>
                                                                        <div class="dashboard-last-operations-section-item-category" style={{textAlign: 'left'}}>{receivePayment.createdDate && receivePayment.createdDate.split(' ')[1]}</div>
                                                                    </td>
                                                                    <td className="dashboard-transfer-list-table-cell">{receivePayment.dtCustName}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{receivePayment.crCustName}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{receivePayment.amount ? receivePayment.amount.toFixed(2) : '0.00'}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{receivePayment.reverseAmount !== null && (receivePayment.reverseAmount ? receivePayment.reverseAmount.toFixed(2) : '0.00')}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{receivePayment.currency}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        <Dropdown
                                                                            dropdownItems={[
                                                                                { title: t('Payment order') },
                                                                                { title: t('Undo') }
                                                                            ]}
                                                                            dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, receivePayment)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            :
                                            <div className="dashboard-last-operations-section-content">
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p className="dashboard-last-operations-section-content-status">{t('You do not currently have any income.')}</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </Fragment >
        )
    }
}


export default translate("translation")(Income);