import React, { Component } from 'react';
import {
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import CheckCustomer from "./CheckCustomer/CheckCustomer";
import AsanConfirmation from "./AsanConfirmation/AsanConfirmation";
import SetUsernamePassword from "./SetUsernamePassword/SetUsernamePassword";
import RegisterSuccess from "./RegisterSuccess/RegisterSuccess";

export default class RegistrationIndividualAsan extends Component {
    render() {
        let { updateAppState, alertState } = this.props;
        return (
            <Switch>
                <Route exact path="/registration/individual/asan/1" render={(routeProps) => <CheckCustomer {...routeProps} updateAppState={updateAppState} alertState={alertState}/>}/>
                <Route exact path="/registration/individual/asan/2" render={(routeProps) => <AsanConfirmation {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/individual/asan/3" render={(routeProps) => <SetUsernamePassword {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/individual/asan/4" render={(routeProps) => <RegisterSuccess {...routeProps} updateAppState={updateAppState}/>}/>
                <Redirect to={"/registration/individual/asan/1"}/>
            </Switch>
        )
    }
}
