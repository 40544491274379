import React, { Component } from 'react';
import {
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import CheckCustomer from "./CheckCustomer/CheckCustomer";
import AsanConfirmation from "./AsanConfirmation/AsanConfirmation";
import SelectCompany from "./SelectCompany/SelectCompany";
import SetUsernamePassword from "./SetUsernamePassword/SetUsernamePassword";
import RegisterSuccess from "./RegisterSuccess/RegisterSuccess";

export default class RegistrationJuridicalAsan extends Component {
    render() {
        let { updateAppState, alertState } = this.props;
        return (
            <Switch>
                <Route exact path="/registration/juridical/asan/1" render={(routeProps) => <CheckCustomer {...routeProps} updateAppState={updateAppState} alertState={alertState}/>}/>
                <Route exact path="/registration/juridical/asan/2" render={(routeProps) => <AsanConfirmation {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/juridical/asan/3" render={(routeProps) => <SelectCompany {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/juridical/asan/4" render={(routeProps) => <SetUsernamePassword {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/registration/juridical/asan/5" render={(routeProps) => <RegisterSuccess {...routeProps} updateAppState={updateAppState}/>}/>
                <Redirect to={"/registration/juridical/asan/1"}/>
            </Switch>        
        )
    }
}