import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import $ from 'jquery';
import OutsideClickHandler from 'react-outside-click-handler';

import DatePickerInput from './DatePickerInput';
import { request } from './../../../../config';
import AlertPopup from './../../../../copms/AlertPopup/AlertPopup';
import DownloadFileModal from './../../../../copms/Modal/DownloadFileModal';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import Loading from '../../Loading/Loading';
import { statusCodes } from '../../../../helpers/statusCodes';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class Transfers extends Component {
    state = {
        signLevel: null,
        signCount: null,
        searchedValidation: false,
        minAmount: null,
        maxAmount: null,
        startDate: null,
        endDate: null,
        dtIban: null,
        operStateId: null,
        selectedStatus: "",
        selectedAccount: "",
        selectedCurrency: "",
        currency: null,
        dropdownVisibilityCurrency: false,
        currencies: ["GEL", "CNY", "CHF", "GBP", "DEM", "FRF", "AZN", "USD", "YEN", "EUR", "RUB", "TRY"].sort(),
        dropdownVisibilityStatus: false,
        dropdownVisibilityAccount: false,
        accounts: [],
        transfers: [],
        deleteItem: {},
        open: false,
        deleteOpen: false,
        accountData: false,

        currentPage: 1,
        lastPage: 0
    }
    updateAccount = (dtIban, event) => {
        this.setState({ dtIban: dtIban, selectedAccount: event.target.textContent, dropdownVisibilityAccount: false });
    }
    updateDate = (name, value) => {
        this.setState({ [name]: value });
    }
    updateStatus = (id) => {
        let signCount;
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            signCount = custObject.company.signCount;
        }
        switch (id) {
            case null: this.setState({ selectedStatus: this.props.t('all') }); break;
            case 1:
                if (signCount === 1 || !localStorage.getItem('compId')) {
                    this.setState({ selectedStatus: this.props.t('authorization') });
                }
                else {
                    this.setState({ selectedStatus: this.props.t('2nd authorization') });
                }
                break;
            case 2: this.setState({ selectedStatus: this.props.t('1st authorization') }); break;
            case 3: this.setState({ selectedStatus: this.props.t('Confirmation is expected') }); break;
            case 4: this.setState({ selectedStatus: this.props.t('Approved') }); break;
            case 5: this.setState({ selectedStatus: this.props.t('refusal') }); break;
            default: this.setState({ selectedStatus: this.props.t('all') }); break;
        }
        this.setState({ dropdownVisibilityStatus: false, operStateId: id });
    }
    updateAmount = (name, event) => {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({ [name]: event.target.value });
        }
    }
    updateCurrency = (index, event) => {
        if (index === null) {
            this.setState({ selectedCurrency: event.target.textContent, dropdownVisibilityCurrency: false, currency: null });
        }
        else {
            this.setState({ selectedCurrency: event.target.textContent, dropdownVisibilityCurrency: false, currency: this.state.currencies[index] });
        }
    }
    changeDropdownVisibilityCurrency = () => {
        this.setState({ dropdownVisibilityCurrency: !this.state.dropdownVisibilityCurrency });
    }
    changeDropdownVisibilityStatus = () => {
        this.setState({ dropdownVisibilityStatus: !this.state.dropdownVisibilityStatus });
    }
    changeDropdownVisibilityAccount = () => {
        this.setState({ dropdownVisibilityAccount: !this.state.dropdownVisibilityAccount });
    }
    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    closeSuccessFunction = () => {
        let index = this.state.deleteItem.index;
        let transfers = this.state.transfers;
        this.setState({ transfers: transfers.slice(0, index).concat(transfers.slice(index + 1, transfers.length)) });
    }
    downloadAdoc = (item) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAsanDocByOperIdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: item.operId
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: item.operId
            }
        fetch(request("operation/getAsanDocByOperId", getAsanDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getAsanDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAsanDocByOperIdResponse = JSON.parse(getAsanDocByOperIdResponseJSON);
                if (getAsanDocByOperIdResponse.status.statusCode !== 1) {
                    if (getAsanDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAsanDocByOperIdResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getAsanDocByOperIdResponse.bytes); //data is the base64 encoded string

                    saveFile(getAsanDocByOperIdResponse.fileName + ".adoc", "application/adoc", arrrayBuffer)
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showPdf = (item) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getPaymentDocByOperIdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: item.operId
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: item.operId
            }
        fetch(request("operation/getPaymentDocByOperId", getPaymentDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getPaymentDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getPaymentDocByOperIdResponse = JSON.parse(getPaymentDocByOperIdResponseJSON);
                if (getPaymentDocByOperIdResponse.status.statusCode !== 1) {
                    if (getPaymentDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getPaymentDocByOperIdResponse.status.statusCode));
                    }
                } else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getPaymentDocByOperIdResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    window.open(link, '');
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    downloadAttachment = (item) => {
        this.setState({ errorMessageDownload: false });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAsanDocByOperIdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: item.operId
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: item.operId
            }
        fetch(request("util/downloadOperFile", getAsanDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getAsanDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAsanDocByOperIdResponse = JSON.parse(getAsanDocByOperIdResponseJSON);
                if (getAsanDocByOperIdResponse.status.statusCode !== 1) {
                    if (getAsanDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        if (getAsanDocByOperIdResponse.status.statusCode === 344) {
                            if (item.operStateId === 1) {
                                this.setState({ errorMessageDownload: this.props.t('You can edit the transaction to add a contract or invoice') });
                            }
                            else {
                                this.setState({ errorMessageDownload: this.props.t('You do not have a contract or invoice') });
                            }
                            this.setState({ openDownload: true });
                        }
                        else {
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAsanDocByOperIdResponse.status.statusCode));
                        }
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getAsanDocByOperIdResponse.bytes); //data is the base64 encoded string
                    saveFile("files.zip", null, arrrayBuffer);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    deleteTransfer = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let deleteOperationdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: this.state.deleteItem.item.operId
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: this.state.deleteItem.item.operId
            }
        fetch(request("operation/deleteOperation", deleteOperationdRequestBody))
            .then(res => res.text())
            .then((deleteOperationdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let deleteOperationdResponse = JSON.parse(deleteOperationdResponseJSON);
                if (deleteOperationdResponse.status.statusCode !== 1) {
                    if (deleteOperationdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({ open: true, popupType: 'error', title: this.props.t('an error occurred'), feedbackMessage: this.props.t(deleteOperationdResponse.status.statusCode) });
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    // this.setState({ deletedIndex: this.state. }, () => {
                    this.setState({ open: true, popupType: 'success', title: this.props.t('successful operation'), feedbackMessage: this.props.t('The transfer was successfully deleted') });
                    // });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    deleteConfirm(val) {
        if (val) {
            this.deleteTransfer();
        }
    }
    async showAccounts() {
        this.setState({ accountData: false });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
            }

        await fetch(request("account/getAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                    }
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        getNoCardAccountListForCustomerResponse.accountList[i].currentBalance = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toFixed(2);
                    }
                    this.setState({ accounts: getNoCardAccountListForCustomerResponse.accountList, accountData: true });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    async searchTransfers(initial) {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operStateId: this.state.operStateId,
            dtIban: this.state.dtIban,
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            operNameId: null,
            currencyChange: this.state.currency,
            pageNumber: this.state.currentPage
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operStateId: this.state.operStateId,
                dtIban: this.state.dtIban,
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                startDate: this.getFormatedDate(this.state.startDate),
                endDate: this.getFormatedDate(this.state.endDate),
                operNameId: null,
                currencyChange: this.state.currency,
                pageNumber: this.state.currentPage
            }
        if (
            this.state.operStateId === null && this.state.dtIban === null && this.state.minAmount === null &&
            this.state.maxAmount === null && this.state.startDate === null &&
            this.state.endDate === null && this.state.currency === null) {
            let now = new Date();
            let threeDaysBefore = new Date();
            threeDaysBefore.setDate(now.getDate() - 2);
            operationAdvancedSearchRequestBody.startDate = this.getFormatedDate(threeDaysBefore);
            operationAdvancedSearchRequestBody.endDate = this.getFormatedDate(now);
        }
        fetch(request("operation/operAdvancedSearchInt", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((operationAdvancedSearchResponseJSON) => {
                const JSON = require('true-json-bigint');
                let operationAdvancedSearchResponse = JSON.parse(operationAdvancedSearchResponseJSON);
                if (operationAdvancedSearchResponse.status.statusCode !== 1 && operationAdvancedSearchResponse.status.statusCode !== 240) {
                    if (operationAdvancedSearchResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        operationAdvancedSearchResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
                        this.props.alertState(
                            true, 
                            'warning', 
                            this.props.t('Information'), 
                            this.props.t(operationAdvancedSearchResponse.status.statusCode),  
                            null,
                            true
                        ) : 
                        this.props.alertState(
                            true, 
                            'error', 
                            this.props.t('an error occurred'), 
                            this.props.t(operationAdvancedSearchResponse.status.statusCode),  
                        )
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    this.setState({ lastPage: operationAdvancedSearchResponse.pageCount ? operationAdvancedSearchResponse.pageCount : 0 })
                    if (operationAdvancedSearchResponse.status.statusCode === 240) {
                        operationAdvancedSearchResponse.operationList = [];
                        this.setState({ transfers: [] });
                        if (!initial) {
                            this.setState({ searchedValidation: true });
                        }
                    }
                    else {
                        let transfers = [];
                        for (let i = 0; i < operationAdvancedSearchResponse.operationList.length; i++) {
                            // =========AMOUNT=========
                            if (operationAdvancedSearchResponse.operationList[i].amount) {
                                if (operationAdvancedSearchResponse.operationList[i].amount.toString() === "0") {
                                    operationAdvancedSearchResponse.operationList[i].organizedAmount = {
                                        wholePart: "0",
                                        fractionalPart: "00"
                                    }
                                }
                                else {
                                    if (operationAdvancedSearchResponse.operationList[i].amount.toString().indexOf('.') !== -1) {
                                        let currentBalanceArray = operationAdvancedSearchResponse.operationList[i].amount.toString().split('.');
                                        operationAdvancedSearchResponse.operationList[i].organizedAmount = {
                                            wholePart: currentBalanceArray[0],
                                            fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                        }
                                    }
                                    else {
                                        operationAdvancedSearchResponse.operationList[i].organizedAmount = {
                                            wholePart: operationAdvancedSearchResponse.operationList[i].amount.toString(),
                                            fractionalPart: "00"
                                        }
                                    }
                                }
                            }
                            if (operationAdvancedSearchResponse.operationList[i].operNameId !== 4) {
                                transfers.push(operationAdvancedSearchResponse.operationList[i]);
                            }
                            if (operationAdvancedSearchResponse.pageCount) {
                                this.setState({ pageCount: operationAdvancedSearchResponse.pageCount });
                            }
                        }
                        this.setState({ transfers });
                    }
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async downloadTransfers(initial) {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operStateId: this.state.operStateId,
            dtIban: this.state.dtIban,
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            operNameId: null,
            currencyChange: this.state.currency
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operStateId: this.state.operStateId,
                dtIban: this.state.dtIban,
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                startDate: this.getFormatedDate(this.state.startDate),
                endDate: this.getFormatedDate(this.state.endDate),
                operNameId: null,
                currencyChange: this.state.currency
            }
        fetch(request("operation/getOperationHistoryExcelByteArray", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((operationAdvancedSearchResponseJSON) => {
                const JSON = require('true-json-bigint');
                let operationAdvancedSearchResponse = JSON.parse(operationAdvancedSearchResponseJSON);
                if (operationAdvancedSearchResponse.status.statusCode !== 1 && operationAdvancedSearchResponse.status.statusCode !== 240) {
                    if (operationAdvancedSearchResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(operationAdvancedSearchResponse.status.statusCode));
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(operationAdvancedSearchResponse.bytes); //data is the base64 encoded string
                    //let name = `${this.props.t('Transfers')}_${this.props.t('History')}${this.state.startDate && "_" + this.state.startDate && this.getFormatedDate(this.state.startDate)}`;

                    let name = `${this.props.t('transfers')}_${this.props.t('history')}_${this.state.startDate ? this.getFormatedDate(this.state.startDate) : ''}_${this.state.endDate ? this.getFormatedDate(this.state.endDate) : ''}`;
                    saveFile(name + ".xlsx", "application/xlsx", arrrayBuffer)
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async getDate() {
        this.setState({ isLoading: true });
        await this.searchTransfers(true);
        await this.showAccounts();
        this.setState({ isLoading: false });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.getDate();
        }
    }
    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => {
            this.searchTransfers(false);
        });
    }
    componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel, signCount: custObject.company.signCount });
        }
        this.getDate();
    }

    render() {
        let { t } = this.props;
        let { signCount, searchedValidation, open, deleteOpen, popupType, title, feedbackMessage, minAmount, maxAmount,
            selectedStatus, selectedAccount, dropdownVisibilityStatus, dropdownVisibilityAccount,
            operStateId, dtIban, accounts, currency, transfers,
            selectedCurrency, currencies, dropdownVisibilityCurrency, isLoading, accountData, openDownload } = this.state;

        return (
            <>
                <DownloadFileModal open={openDownload} close={() => this.setState({ openDownload: false })} errorMessage={this.state.errorMessageDownload} />
                {
                    isLoading ?
                        <div className="dashboard-transfer-search-form-section">
                            <Loading />
                        </div>
                        :
                        <>
                            <div className="dashboard-transfer-search-form-section">
                                <div className="dashboard-transfer-search-form-section-item">
                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityAccount: false }) }}>
                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                            <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityAccount}>
                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={!dtIban && !(accounts && accounts.length === 0 && accountData) ? { color: '#BDC3C7' } : {}}>
                                                    {
                                                        dtIban ? selectedAccount : accounts && accounts.length === 0 && accountData ? t('126') : t("all")
                                                    }
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                    </svg>
                                                </div>
                                            </button>
                                            {
                                                accounts.length !== 0 &&
                                                <ul className={(dropdownVisibilityAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateAccount.bind(this, null)}>{t("all")}</button>
                                                    </li>
                                                    {
                                                        accounts.map(account => (
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateAccount.bind(this, account.iban)}>{account.iban} / {account.currentBalance} {account.currName}</button>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    </OutsideClickHandler>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <DatePickerInput placeholder={t("Start date")} updateDate={this.updateDate.bind(this, 'startDate')} />
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <DatePickerInput placeholder={t("End date")} updateDate={this.updateDate.bind(this, 'endDate')} />
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("From the amount")} onChange={this.updateAmount.bind(this, 'minAmount')} value={minAmount} />
                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency}</div>
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("To the amount")} onChange={this.updateAmount.bind(this, 'maxAmount')} value={maxAmount} />
                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency}</div>
                                    </div>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCurrency: false }) }}>
                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                            <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityCurrency}>
                                                {
                                                    currency ?
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCurrency}</div>
                                                        :
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("all")}</div>
                                                }
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                    </svg>
                                                </div>
                                            </button>
                                            {
                                                currencies.length !== 0 &&
                                                <ul className={(dropdownVisibilityCurrency === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateCurrency.bind(this, null)}>{t("all")}</button>
                                                    </li>
                                                    {
                                                        currencies.map((item, index) => (
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateCurrency.bind(this, index)}>{currencies[index]}</button>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    </OutsideClickHandler>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityStatus: false }) }}>
                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                            <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityStatus}>
                                                {
                                                    operStateId ?
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedStatus}</div>
                                                        :
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("all")}</div>
                                                }
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                    </svg>
                                                </div>
                                            </button>
                                            <ul className={(dropdownVisibilityStatus === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, null)}>{t('all')}</button>
                                                </li>
                                                {
                                                    localStorage.getItem('compId') && signCount === 2 &&
                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 2)}>{t('1st authorization')}</button>
                                                    </li>
                                                }
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 1)}>
                                                        {
                                                            signCount === 1 || !localStorage.getItem('compId') ?
                                                                t('authorization')
                                                                :
                                                                t('2nd authorization')
                                                        }
                                                    </button>
                                                </li>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 3)}>{t('Confirmation is expected')}</button>
                                                </li>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 4)}>{t('Approved')}</button>
                                                </li>
                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, 5)}>{t('refusal')}</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </OutsideClickHandler>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <button className="dashboard-transfer-search-form-section-item-button" onClick={() => { this.searchTransfers(false); this.setState({ currentPage: 1, lastPage: 0 }); }}>{t("Search")}</button>
                                </div>
                                <div className="dashboard-transfer-search-form-section-item">
                                    <button className={`dashboard-transfer-search-form-section-item-button ${(!transfers || transfers.length === 0) ? "dashboard-transfer-search-form-section-item-button__disable" : ""}`} disabled={(!transfers || transfers.length === 0)} onClick={this.downloadTransfers.bind(this, false)}>{t("Download Excel")}</button>
                                </div>
                            </div>
                            <YesNoModal onClose={() => this.setState({ deleteOpen: false })} title={t('Are you sure you want to delete the operation?')} open={deleteOpen} triggerFunc={val => this.deleteConfirm(val)} />
                            <AlertPopup open={open} closeFunction={() => this.setState({ open: false })} popupType={popupType} title={title} feedbackMessage={feedbackMessage} closeSuccessFunction={this.closeSuccessFunction} />
                            <div className="dashboard-transfer-list-content" style={{ padding: 0 }}>
                                {
                                    transfers.length !== 0 ?
                                        <>
                                            <table className="dashboard-transfer-list-table">
                                                <thead className="dashboard-transfer-list-table-head">
                                                    <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("SENDER ACCOUNT")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("RECİPİENTS NAME")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("AMOUNT")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("PAYMENT TYPE")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("STATUS")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">ADOC</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">PDF</th>
                                                        {/* <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th> */}
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="dashboard-transfer-list-table-body">
                                                    {
                                                        transfers.map((item, index) => {
                                                            return (
                                                                <tr className="dashboard-transfer-list-table-row" key={index}>
                                                                    <td className="dashboard-transfer-list-table-cell">{item.createdDate.split(" ")[0]}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{item.dtIban}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{item.crName}</td>
                                                                    <td>
                                                                        {
                                                                            item.amount ? item.amount.toFixed(2) : (
                                                                                item.dtAmt && item.dtCcy !== 'AZN' ? item.dtAmt.toFixed(2) : (item.crAmt ? item.crAmt.toFixed(2) : '0.00')
                                                                            )
                                                                        }

                                                                    </td>
                                                                    <td>{item.currency ? item.currency : item.dtCcy !== 'AZN' ? item.dtCcy : item.crCcy}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{item.operName}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        {
                                                                            item.operStateId === 1 && (signCount === 1 || !localStorage.getItem('compId')) && t('authorization')
                                                                        }
                                                                        {
                                                                            item.operStateId === 1 && (signCount === 2 && localStorage.getItem('compId')) && t('2nd authorization')
                                                                        }
                                                                        {
                                                                            item.operStateId === 2 && t('1st authorization')
                                                                        }
                                                                        {
                                                                            item.operStateId === 3 && t('Confirmation is expected')
                                                                        }
                                                                        {
                                                                            item.operStateId === 4 && t('Approved')
                                                                        }
                                                                        {
                                                                            item.operStateId === 5 && t('refusal')
                                                                        }
                                                                    </td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        {
                                                                            item.operStateId !== 1 &&
                                                                            <button onClick={this.downloadAdoc.bind(this, item)}>
                                                                                <svg className="dashboard-transfer-list-table-icon" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M11.5312 3.0625L8.9375 0.4375C8.65625 0.15625 8.25 0 7.875 0H1.5C0.65625 0 0 0.6875 0 1.5V14.5C0 15.3438 0.65625 16 1.5 16H10.5C11.3125 16 12 15.3438 12 14.5V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM10.375 4H8V1.625L10.375 4ZM1.5 14.5V1.5H6.5V4.75C6.5 5.1875 6.8125 5.5 7.25 5.5H10.5V14.5H1.5ZM9.3125 10.0312C8.9375 9.65625 7.84375 9.75 7.28125 9.8125C6.75 9.5 6.40625 9.03125 6.15625 8.375C6.25 7.875 6.46875 7.09375 6.3125 6.625C6.1875 5.8125 5.125 5.875 4.96875 6.4375C4.84375 6.9375 4.96875 7.65625 5.1875 8.53125C4.875 9.28125 4.40625 10.2812 4.09375 10.875C3.46875 11.1875 2.625 11.6875 2.5 12.3125C2.40625 12.8125 3.3125 14.0312 4.875 11.3438C5.5625 11.0938 6.34375 10.8125 7 10.6875C7.59375 11.0312 8.28125 11.2188 8.75 11.2188C9.5625 11.2188 9.625 10.3438 9.3125 10.0312ZM3.125 12.4688C3.28125 12.0312 3.875 11.5312 4.0625 11.375C3.46875 12.3125 3.125 12.4688 3.125 12.4688ZM5.65625 6.5C5.90625 6.5 5.875 7.5 5.71875 7.78125C5.59375 7.34375 5.59375 6.5 5.65625 6.5ZM4.90625 10.7812C5.21875 10.25 5.46875 9.625 5.6875 9.0625C5.9375 9.53125 6.25 9.90625 6.625 10.1562C5.96875 10.3125 5.40625 10.5625 4.90625 10.7812ZM9 10.625C9 10.625 8.84375 10.8125 7.84375 10.375C8.9375 10.2812 9.125 10.5312 9 10.625Z" fill="#586268" />
                                                                                </svg>
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        <button onClick={this.showPdf.bind(this, item)}>
                                                                            <svg className="dashboard-transfer-list-table-icon" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#586268" />
                                                                            </svg>
                                                                        </button>
                                                                    </td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        {
                                                                            (item.operNameId === 2 || item.operNameId === 3) &&
                                                                            <button onClick={this.downloadAttachment.bind(this, item)}>
                                                                                <svg className="dashboard-transfer-list-table-icon" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M2.09375 14.6562C3.90625 16.4688 6.84375 16.4688 8.65625 14.6562C10.3438 12.9688 13.0938 10.2188 14.7812 8.53125C14.9375 8.375 14.9375 8.15625 14.7812 8L14.25 7.46875C14.0938 7.3125 13.875 7.3125 13.7188 7.46875C12.0312 9.15625 9.28125 11.9062 7.59375 13.5938C6.375 14.8125 4.375 14.8125 3.15625 13.5938C1.9375 12.375 1.9375 10.375 3.15625 9.15625L10.1875 2.125C11 1.3125 12.3125 1.3125 13.125 2.125C13.9375 2.9375 13.9375 4.25 13.125 5.0625L7.21875 10.9688C6.8125 11.375 6.15625 11.375 5.75 10.9688C5.375 10.5938 5.375 9.9375 5.75 9.53125L10.7812 4.53125C10.9375 4.375 10.9375 4.15625 10.7812 4L10.25 3.46875C10.0938 3.3125 9.875 3.3125 9.71875 3.46875L4.71875 8.46875C3.71875 9.46875 3.71875 11.0625 4.71875 12.0312C5.6875 13.0312 7.28125 13.0312 8.25 12.0312L14.1875 6.125C15.5938 4.71875 15.5938 2.46875 14.1875 1.0625C12.7812 -0.34375 10.5312 -0.34375 9.125 1.0625L2.09375 8.09375C0.28125 9.90625 0.28125 12.8438 2.09375 14.6562Z" fill="#586268" />
                                                                                </svg>
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        {
                                                                            item.operStateId === 1 &&
                                                                            <button onClick={() => { this.setState({ deleteItem: { item: item, index: index }, deleteOpen: true }) }}>
                                                                                <svg className="dashboard-transfer-list-table-icon" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z" fill="#586268" />
                                                                                </svg>
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        {
                                                                            item.operStateId === 1 && item.operNameId !== 5 ?
                                                                                <Link to={
                                                                                    item.operNameId === 1 && item.operTypeId !== 15 ? `/dashboard/transfer/edit/internal/${item.operId}` :
                                                                                        item.operNameId === 1 && item.operTypeId === 15 ? `/dashboard/transfer/edit/between/${item.operId}` :
                                                                                            item.operNameId === 2 ? `/dashboard/transfer/edit/inland/${item.operId}` :
                                                                                                item.operNameId === 3 ? `/dashboard/transfer/edit/abroad/${item.operId}` : ``
                                                                                }>
                                                                                    <svg className="dashboard-transfer-list-table-icon" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M15.9243 16H8.3811C7.92013 16 7.54297 15.6228 7.54297 15.1619C7.54297 14.7009 7.92013 14.3237 8.3811 14.3237H15.9243C16.3853 14.3237 16.7625 14.7009 16.7625 15.1619C16.7625 15.6228 16.3853 16 15.9243 16Z" fill="#586268" />
                                                                                        <path d="M0.838299 16.0008C0.620384 16.0008 0.402469 15.917 0.243223 15.7578C0.0336894 15.5482 -0.0501241 15.2465 0.0253081 14.9615L0.863443 11.609C0.896969 11.4581 0.972401 11.324 1.08136 11.2234L11.558 0.746754C12.547 -0.242245 14.2736 -0.242245 15.2542 0.746754C16.2768 1.76928 16.2768 3.42879 15.2542 4.44293L4.78592 14.9196C4.67696 15.0286 4.54286 15.104 4.40037 15.1375L1.04783 15.9757C0.972401 15.9924 0.90535 16.0008 0.838299 16.0008ZM2.43076 12.2376L1.98654 14.0061L3.75501 13.5618L14.0641 3.25278C14.4329 2.884 14.4329 2.28892 14.0641 1.92014C13.7121 1.56813 13.0918 1.56813 12.7314 1.92014L2.43076 12.2376Z" fill="#586268" />
                                                                                    </svg>
                                                                                </Link>
                                                                                :
                                                                                (item.operStateId === 2 || item.operStateId === 3) && (item.operNameId === 2 || item.operNameId === 3) &&
                                                                                <Link to={
                                                                                    item.operNameId === 2 ? `/dashboard/transfer/edit-factura/inland/${item.operId}` :
                                                                                        item.operNameId === 3 ? `/dashboard/transfer/edit-factura/abroad/${item.operId}` : ``
                                                                                }>
                                                                                    <svg className="dashboard-transfer-list-table-icon" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M15.9243 16H8.3811C7.92013 16 7.54297 15.6228 7.54297 15.1619C7.54297 14.7009 7.92013 14.3237 8.3811 14.3237H15.9243C16.3853 14.3237 16.7625 14.7009 16.7625 15.1619C16.7625 15.6228 16.3853 16 15.9243 16Z" fill="#586268" />
                                                                                        <path d="M0.838299 16.0008C0.620384 16.0008 0.402469 15.917 0.243223 15.7578C0.0336894 15.5482 -0.0501241 15.2465 0.0253081 14.9615L0.863443 11.609C0.896969 11.4581 0.972401 11.324 1.08136 11.2234L11.558 0.746754C12.547 -0.242245 14.2736 -0.242245 15.2542 0.746754C16.2768 1.76928 16.2768 3.42879 15.2542 4.44293L4.78592 14.9196C4.67696 15.0286 4.54286 15.104 4.40037 15.1375L1.04783 15.9757C0.972401 15.9924 0.90535 16.0008 0.838299 16.0008ZM2.43076 12.2376L1.98654 14.0061L3.75501 13.5618L14.0641 3.25278C14.4329 2.884 14.4329 2.28892 14.0641 1.92014C13.7121 1.56813 13.0918 1.56813 12.7314 1.92014L2.43076 12.2376Z" fill="#586268" />
                                                                                    </svg>
                                                                                </Link>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                <ul className="dashboard-pagination">
                                                    {this.state.currentPage !== 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                    {
                                                        Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                            if (this.state.lastPage > 3) {
                                                                if (this.state.currentPage - 1 === index + 1) {
                                                                    return (
                                                                        <>
                                                                            {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                            {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                            <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                        </>
                                                                    )
                                                                }
                                                                if (this.state.currentPage === index + 1) {
                                                                    return (
                                                                        <>
                                                                            <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                        </>
                                                                    )
                                                                }
                                                                if (this.state.currentPage + 1 === index + 1) {
                                                                    return (
                                                                        <>
                                                                            <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                            {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                            {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                        </>
                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <li className={index + 1 === this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage === 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                )
                                                            }
                                                        })

                                                    }
                                                    {this.state.currentPage !== this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                </ul>
                                            </div>
                                        </>
                                        :
                                        searchedValidation === false ?
                                            <div className="dashboard-last-operations-section-content">
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                            </div>
                                            :
                                            <div className="dashboard-finance-section-cards-section-commission-alert">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 1.03226C11.8065 1.03226 14.9677 4.16129 14.9677 8C14.9677 11.871 11.8387 14.9677 8 14.9677C4.12903 14.9677 1.03226 11.871 1.03226 8C1.03226 4.16129 4.12903 1.03226 8 1.03226ZM8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM6.83871 11.0968C6.6129 11.0968 6.45161 11.2903 6.45161 11.4839V11.7419C6.45161 11.9677 6.6129 12.129 6.83871 12.129H9.16129C9.35484 12.129 9.54839 11.9677 9.54839 11.7419V11.4839C9.54839 11.2903 9.35484 11.0968 9.16129 11.0968H8.77419V6.58065C8.77419 6.3871 8.58064 6.19355 8.3871 6.19355H6.83871C6.6129 6.19355 6.45161 6.3871 6.45161 6.58065V6.83871C6.45161 7.06452 6.6129 7.22581 6.83871 7.22581H7.22581V11.0968H6.83871ZM8 3.35484C7.41935 3.35484 6.96774 3.83871 6.96774 4.3871C6.96774 4.96774 7.41935 5.41935 8 5.41935C8.54839 5.41935 9.03226 4.96774 9.03226 4.3871C9.03226 3.83871 8.54839 3.35484 8 3.35484Z" fill="#2873F0" />
                                                </svg>
                                                <p>{t('No results were found matching the information you entered. Please try again.')}</p>
                                            </div>
                                }
                            </div>
                        </>
                }</>
        )
    }
}

export default translate("translation")(Transfers);
