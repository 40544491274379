import infoLogo from "./../components/Dashboard/img/info-icon.svg";

export const tabTypes = {
  EXTENSION: "extension",
  RENEWAL: "renewal",
};

export const tabs = [
  {
    id: 1,
    path: "/dashboard/services/card-extension-renewal/extension",
    title: "Card exention",
    icon: infoLogo,
    alt: "info_logo",
    warningPopup:
      "You can renew your card for a new period until the expiration date of your card. Please note that you need to apply to the branch to renew expired cards.",
  },
  {
    id: 2,
    path: "/dashboard/services/card-extension-renewal/renewal",
    title: "Card renewal",
    icon: infoLogo,
    alt: "info_logo",
    warningPopup:
      "If your card is lost or damaged, you can renew your card without visiting the branch. The lost card must be blocked before being renewed.",
  },
];

export const orderCardPeriodType = {
  UNTIL_CURRENT_PERIOD: 1,
  EXTENSION_NEW_PERIOD: 2,
};

export const customerOrderType = {
  REKVIZIT: 1,
  CARD_STATEMENT: 2,
  CARD_DURATION_EXTENDING: 3,
  CARD_RENEWALING: 5,
};
