import React, { Component, Fragment } from 'react';
import { Switch, Link, NavLink, Redirect, Route } from 'react-router-dom';
import { translate } from 'react-i18next';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import transferInternalIcon from './../../img/transfer-internal-icon.svg';
import betweenIcon from './../../img/between.svg';
import internalIcon from './../../img/internal.svg';
import inlandIcon from './../../img/inland.svg';
import abroadIcon from './../../img/abroad.svg';
import exchangeIcon from './../../img/exchange.svg';
import Statements from "./Statements/Statements";
import { request } from './../../../../config';
import transferFilter from '../../../../helpers/transferFilter';
import currencyFilter from '../../../../helpers/currencyFilter';
import cardOperationFilter from '../../../../helpers/cardOperationFilter';
import YesNoModal from '../../../../copms/Modal/YesNoModal';
import Loading from './../../Loading/Loading';

class Transfers extends Component {
    state = {
        signLevel: null,
        signCount: null,
        open: false,
        modalCardObj: {},
        templates: [],
        operations: [],
        isLoading: false
    }


    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    dropdownItemHandler = (index, tempIndex, url) => {
        switch (index) {
            case 1:
                this.setState({ deleteIndex: tempIndex, open: true })
                break;
        }
    }

    deleteTransferTemplate(index) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let removePaymentTempCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.templates[index].id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                tempId: this.state.templates[index].id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("operation/deleteOperationTemp", removePaymentTempCustomerRequestBody))
            .then(res => res.text())
            .then((removePaymentTempCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let removePaymentTempCustomerResponse = JSON.parse(removePaymentTempCustomerResponseJSON);
                if (removePaymentTempCustomerResponse.status.statusCode !== 1) {
                    if (removePaymentTempCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removePaymentTempCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var tempArr = this.state.templates;
                    tempArr.splice(index, 1);
                    this.setState({ templates: tempArr });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    componentWillMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardOperationTempListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("operation/getOperationTempList" + customerType, getCardOperationTempListRequestBody))
            .then(res => res.text())
            .then((getCardOperationTempListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardOperationTempListResponse = JSON.parse(getCardOperationTempListResponseJSON);
                if (getCardOperationTempListResponse.status.statusCode !== 1 && getCardOperationTempListResponse.status.statusCode !== '????????????') {
                    if (getCardOperationTempListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardOperationTempListResponse.status.statusCode === 1) {
                        for (let i = 0; i < getCardOperationTempListResponse.respOperationTempList.length; i++) {
                            // =========AMOUNT=========
                            let amount, currency;
                            if (getCardOperationTempListResponse.respOperationTempList[i].operNameId !== 4) {
                                amount = getCardOperationTempListResponse.respOperationTempList[i].amt;
                                currency = getCardOperationTempListResponse.respOperationTempList[i].amtCcy;
                            }
                            else {
                                if (getCardOperationTempListResponse.respOperationTempList[i].operationTypeId === 5) {
                                    amount = getCardOperationTempListResponse.respOperationTempList[i].crAmt;
                                    currency = getCardOperationTempListResponse.respOperationTempList[i].crCcy;
                                }
                                else {
                                    if (getCardOperationTempListResponse.respOperationTempList[i].operationTypeId === 6) {
                                        amount = getCardOperationTempListResponse.respOperationTempList[i].dtAmt;
                                        currency = getCardOperationTempListResponse.respOperationTempList[i].dtCcy;
                                    }
                                    else {
                                        amount = 0;
                                        currency = getCardOperationTempListResponse.respOperationTempList[i].amtCcy;
                                    }
                                }
                            }
                            getCardOperationTempListResponse.respOperationTempList[i].currency = currency;
                            if (amount) {
                                if (amount.toString() === "0") {
                                    getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                        wholePart: "0",
                                        fractionalPart: "00"
                                    }
                                }
                                else {
                                    if (amount.toString().indexOf('.') !== -1) {
                                        let amountArray = amount.toString().split('.');
                                        getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                            wholePart: amountArray[0],
                                            fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                        }
                                    }
                                    else {
                                        getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                            wholePart: amount.toString(),
                                            fractionalPart: "00"
                                        }
                                    }
                                }
                            }
                            else {
                                getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                        }
                        this.setState({ templates: getCardOperationTempListResponse.respOperationTempList });
                    }
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }
    componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel, signCount: custObject.company.signCount });
        }
    }
    render() {
        let { updateAppState, alertState, t, logout } = this.props;
        let { open, templates, modalCardObj, dropdownItems, signLevel, signCount, deleteIndex, isLoading } = this.state;

        return (
            <Fragment>
                <YesNoModal onClose={() => this.setState({ open: false })} title={t('Are you sure you want to delete the template?')} open={open} triggerFunc={() => this.deleteTransferTemplate(deleteIndex)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('transfers')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">

                            <div className="dashboard-new-transfer-template-section responsive-width-full" style={{ width: "calc(55% - 16px)" }}>
                                <div className="dashboard-new-transfer-template-section-background">
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('New transfer')}</h3>
                                        </div>
                                        <ul className="dashboard-new-transfer-section-content">
                                            <Link to="/dashboard/transfer/transfers/between" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={betweenIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" style={{width: '25px'}} />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Between')}</p>
                                            </Link>
                                            <Link to="/dashboard/transfer/transfers/internal" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={internalIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Internal')}</p>
                                            </Link>
                                            <Link to="/dashboard/transfer/transfers/inland" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={inlandIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Inland')}</p>
                                            </Link>
                                            <Link to="/dashboard/transfer/transfers/abroad" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={abroadIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Abroad')}</p>
                                            </Link>
                                            <Link to="/dashboard/transfer/transfers/convertation/1" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={exchangeIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Exchange')}</p>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="dashboard-transfer-history" style={{ width: "100%" }}>
                                        <div className="dashboard-transfer-history-header">
                                            <NavLink to="/dashboard/transfer/transfers/all" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('all')}</NavLink>
                                            {
                                                signCount === 2 && localStorage.getItem('compId') &&
                                                <>
                                                    <NavLink to="/dashboard/transfer/transfers/first-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('2nd authorization')}</NavLink>
                                                    <NavLink to="/dashboard/transfer/transfers/second-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('1st authorization')}</NavLink>
                                                </>
                                            }
                                            {
                                                signLevel === 1 && signCount === 1 && localStorage.getItem('compId') &&
                                                <NavLink to="/dashboard/transfer/transfers/first-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('authorization')}</NavLink>
                                            }
                                            {
                                                !localStorage.getItem('compId') &&
                                                <NavLink to="/dashboard/transfer/transfers/first-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('authorization')}</NavLink>
                                            }
                                            <NavLink to="/dashboard/transfer/transfers/waiting" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('Confirmation is expected')}</NavLink>
                                            <NavLink to="/dashboard/transfer/transfers/confirmed" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('Approved')}</NavLink>
                                            <NavLink to="/dashboard/transfer/transfers/refused" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('refusal')}</NavLink>
                                        </div>
                                        <Switch>
                                            <Redirect exact from="/dashboard/transfer/transfers/" to="/dashboard/transfer/transfers/all" />
                                            <Route path="/dashboard/transfer/transfers/all" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/transfer/transfers/confirmed" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/transfer/transfers/waiting" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/transfer/transfers/first-authorization" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/transfer/transfers/second-authorization" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/transfer/transfers/refused" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-new-transfer-section responsive-width-full-top" style={{ width: "calc(45% - 16px)", marginBottom: '0' }}>
                                <div className="dashboard-new-transfer-section-header">
                                    <h3 className="dashboard-new-transfer-section-header-title">{t('Templates')}</h3>
                                    <Link to="/dashboard/transfer/templates/transfers" className="dashboard-new-transfer-section-header-history">{t('all')}</Link>
                                </div>
                                {
                                    templates.length !== 0 ?
                                        <div className="dashboard-finance-section-cards-section-table-section">
                                            <table className="dashboard-finance-section-cards-section-table">
                                                <tbody>
                                                    {
                                                        templates.slice(0, 5).map((template, index) =>
                                                            <Fragment key={index}>
                                                                <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                    <td className="dashboard-finance-section-cards-section-cell">
                                                                        <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                            <img src={transferFilter(template.operNameId) && ((template.operNameId === 1 && template.operationTypeId === 15) ? transferFilter(15).iconName : transferFilter(template.operNameId).iconName)} style={{ height: '15px' }} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                        </div>
                                                                        <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                            <Link to={`/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) &&
                                                                                template.operationTypeId === 15 ?
                                                                                'between'
                                                                                :
                                                                                transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`} className="dashboard-finance-section-cards-section-cell-content">{template.tempName}</Link>
                                                                            <Link to={`/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) &&
                                                                                template.operationTypeId === 15 ?
                                                                                'between'
                                                                                :
                                                                                transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`} className="dashboard-finance-section-cards-section-cell-title" style={{ display: 'inline', wordBreak: 'break-all' }}>{template.crIban}</Link>
                                                                        </div>
                                                                    </td>
                                                                    <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                        <p className="dashboard-finance-section-cards-section-cell-content">
                                                                            <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + template.currency}>{currencyFilter(template.currency)}</span>
                                                                            <span className="dashboard-finance-section-cards-section-amount-whole-part">{template.organizedAmount.wholePart}</span>
                                                                            <span className="dashboard-finance-section-cards-section-amount-fractional-part">{template.organizedAmount.fractionalPart}</span>
                                                                        </p>
                                                                        <p className="dashboard-finance-section-cards-section-cell-title">{template.cardOperationTypeValue}</p>
                                                                    </td>
                                                                    {/* <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                        <div className="dashboard-finance-section-cards-section-button-group">
                                                                            <Dropdown
                                                                                dropdownItems={[
                                                                                    { title: this.props.t('Create a payment'), url: "/dashboard/transfer/template/transfer/" + (transferFilter(template.operNameId) && transferFilter(template.operNameId).cardOperationTypeValue) + "/" + (template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : '') + template.id },
                                                                                    { title: this.props.t('Delete') },
                                                                                ]}
                                                                                dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, `/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) && transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`)}
                                                                            />
                                                                        </div>
                                                                    </td> */}
                                                                </tr>

                                                                <tr className="dashboard-finance-row-line">
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div className="dashboard-payment-operation-section-content">
                                            {/* <Link to="" className="dashboard-payment-operation-section-content-item">
                                                        <div className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                <img src={transferInternalIcon} style={{ height: 'auto' }} className="dashboard-payment-operation-section-content-item-icon" />
                                                            </div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title">{t('Create a new template')}</div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle">{t('card number')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                        </div>
                                                    </Link> */}
                                            {
                                                [0, 1, 2].map(index => (
                                                    <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                        <div className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container"></div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </div>
                                }
                            </div>

                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(Transfers);