import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import Loading from './../../Loading/Loading';
import ReceiptModal from './../../../../copms/Modal/ReceiptModalC2C';
import DatePickerInput from '../History/DatePickerInput';
import cardToCardIcon from '../../img/card-to-card.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import lastOperationsIcon from './../../img/last-operations-icon.png';

class History extends Component {
    state = {
        searchedValidation: false,
        startDate: null,
        endDate: null,
        payments: [],
        isLoading: false,
        succesModalData: null,
        succesModal: false,
        secondDesc: '',
        item: null,
        c2cStatusesSelectedIndex: 0,
        c2cStatuses: [
            {
                c2cStatus: null,
                name: 'all',
            },
            {
                c2cStatus: 1,
                name: 'successful operation'
            },
            {
                c2cStatus: 0,
                name: 'Incorrect'
            },
            {
                c2cStatus: 2,
                name: 'on hold'
            }
        ],
        dropdownVisibilityC2cStatuses: false,
        currentPage: 1,
        lastPage: 0
    }
    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => {
            this.searchTransfers(false);
        });
    }
    updateDate = (name, value) => {
        this.setState({ [name]: value });
    }
    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    formatDateTime = (dateTimeString) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        };

        const formattedDate = new Date(dateTimeString).toLocaleDateString('en-GB', options).replace(/\//g, '.');

        return `${formattedDate}`;
    };
    searchTransfers = async (initial) => {
        if (initial) {
            this.setState({ isLoading: true });
        }
        else {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let operationAdvancedSearchRequestBody = {
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: this.state.currentPage,
            c2cStatus: this.state.c2cStatuses[this.state.c2cStatusesSelectedIndex].c2cStatus
        }
        await fetch(request("card/getC2cOperationSearchList", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((paymentListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let paymentListCustomerResponse = JSON.parse(paymentListCustomerResponseJSON);
                if (paymentListCustomerResponse.status.statusCode !== 1 && paymentListCustomerResponse.status.statusCode !== 282) {
                    if (paymentListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ payments: [] });
                    }
                }
                else {
                    this.setState({ lastPage: paymentListCustomerResponse.pageCount ? paymentListCustomerResponse.pageCount : 0 })

                    if (paymentListCustomerResponse.status.statusCode === 1) {
                        for (let i = 0; i < paymentListCustomerResponse.c2cHistoryList.length; i++) {
                            // =========AMOUNT=========
                            if (paymentListCustomerResponse.c2cHistoryList[i].amount.toString() === "0") {
                                paymentListCustomerResponse.c2cHistoryList[i].amount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (paymentListCustomerResponse.c2cHistoryList[i].amount.toString().indexOf('.') !== -1) {
                                    let amountArray = paymentListCustomerResponse.c2cHistoryList[i].amount.toString().split('.');
                                    paymentListCustomerResponse.c2cHistoryList[i].amount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    paymentListCustomerResponse.c2cHistoryList[i].amount = {
                                        wholePart: paymentListCustomerResponse.c2cHistoryList[i].amount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                            // =========NUMBER=========
                            if (paymentListCustomerResponse.c2cHistoryList[i].cardNumber) {
                                paymentListCustomerResponse.c2cHistoryList[i].organizedCardNumber = paymentListCustomerResponse.c2cHistoryList[i].cardNumber.substring(0, 4)
                                    + " **** **** " + paymentListCustomerResponse.c2cHistoryList[i].cardNumber.substring(12, 16);
                            }
                        }
                        this.setState({ payments: paymentListCustomerResponse.c2cHistoryList });

                    } else {
                        this.setState({ payments: [] });
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({ isLoading: false });
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    componentDidMount() {
        this.searchTransfers(true);
    }

    render() {
        let { payments, searchedValidation, isLoading, succesModal, item, secondDesc, c2cStatusesSelectedIndex, c2cStatuses, dropdownVisibilityC2cStatuses, succesModalData } = this.state;
        let { t, updateAppState, alertState, } = this.props;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('history')}</h2>
                    </div>
                </div>

                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <div className="dashboard-transfer-search-list-section" style={{ marginTop: 0, minHeight: '70vh' }}>
                                <Fragment>
                                    <ReceiptModal history={this.props.history} templateSave={() => this.setState({ templateModal: true, succesModal: false })} item={item} secondDesc={secondDesc} onClose={() => this.setState({ succesModal: false })} data={succesModalData} open={succesModal} />
                                    <div className="dashboard-transfer-list-content">
                                        <div className="dashboard-transfer-search-form-section" style={{ padding: 0, marginTop: '10px', }}>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityC2cStatuses: false }) }}>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                        <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={() => this.setState({ dropdownVisibilityC2cStatuses: !dropdownVisibilityC2cStatuses })}>
                                                            {
                                                                c2cStatusesSelectedIndex >= 0 ?
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{t(c2cStatuses[c2cStatusesSelectedIndex]?.name)}</div>
                                                                    :
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("all")}</div>
                                                            }
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        {
                                                            c2cStatuses.length !== 0 &&
                                                            <ul className={(dropdownVisibilityC2cStatuses === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    c2cStatuses.map((status, index) => (
                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                            <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={() => this.setState({ c2cStatusesSelectedIndex: index, dropdownVisibilityC2cStatuses: false })}>{t(status?.name)}</button>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </div>
                                                </OutsideClickHandler>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <DatePickerInput placeholder={t("Start date")} updateDate={this.updateDate.bind(this, 'startDate')} />
                                                </div>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <DatePickerInput placeholder={t("End date")} updateDate={this.updateDate.bind(this, 'endDate')} />
                                                </div>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <button className="dashboard-transfer-search-form-section-item-button" onClick={() => { this.searchTransfers(false); this.setState({ currentPage: 1, lastPage: 0 }); }}>{t("Search")}</button>
                                            </div>
                                        </div>
                                        {
                                            payments.length !== 0 ?
                                                <>
                                                    <table className="dashboard-transfer-list-table">

                                                        <tbody className="dashboard-transfer-list-table-body">
                                                            {
                                                                payments.map((payment, index) => {
                                                                    return (
                                                                        <tr className="dashboard-transfer-list-table-row" onClick={() => this.setState({ succesModalData: payment, succesModal: true })} style={{ cursor: 'pointer' }} key={index}>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                                    <img src={cardToCardIcon} />
                                                                                </div>
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <span className="dashboard-finance-section-cards-section-cell-content">{payment.reqCardNumber}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-cell-title">{this.formatDateTime(payment.operDate)}, {payment.currency}, {payment.destCardNumber}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                {payment?.c2cStatus !== undefined && <p className="dashboard-finance-section-cards-section-cell-title">{t('Status')} : {payment.c2cStatus == 0 ? t('Incorrect') : payment.c2cStatus == 2 ? t('on hold') : payment.c2cStatus == 1 ? t('successful operation') : ''}</p>}
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className="dashboard-payment-operation-section-content-item-amount-whole-part">{payment.amount.wholePart}</span>
                                                                                    <span className="dashboard-payment-operation-section-content-item-amount-fractional-part">{payment.amount.fractionalPart}</span>
                                                                                    <span className="dashboard-payment-operation-section-content-item-amount-currency">{payment.currency}</span>
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                        <ul className="dashboard-pagination">
                                                            {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                            {
                                                                Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                                    if (this.state.lastPage > 3) {
                                                                        if (this.state.currentPage - 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}
                                                                                    {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage + 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                    {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                                    {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                                </>
                                                                            )
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                        </ul>
                                                    </div>
                                                </>
                                                :
                                                searchedValidation === false ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div className="dashboard-last-operations-section-content">
                                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="dashboard-finance-section-cards-section-commission-alert">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 1.03226C11.8065 1.03226 14.9677 4.16129 14.9677 8C14.9677 11.871 11.8387 14.9677 8 14.9677C4.12903 14.9677 1.03226 11.871 1.03226 8C1.03226 4.16129 4.12903 1.03226 8 1.03226ZM8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM6.83871 11.0968C6.6129 11.0968 6.45161 11.2903 6.45161 11.4839V11.7419C6.45161 11.9677 6.6129 12.129 6.83871 12.129H9.16129C9.35484 12.129 9.54839 11.9677 9.54839 11.7419V11.4839C9.54839 11.2903 9.35484 11.0968 9.16129 11.0968H8.77419V6.58065C8.77419 6.3871 8.58064 6.19355 8.3871 6.19355H6.83871C6.6129 6.19355 6.45161 6.3871 6.45161 6.58065V6.83871C6.45161 7.06452 6.6129 7.22581 6.83871 7.22581H7.22581V11.0968H6.83871ZM8 3.35484C7.41935 3.35484 6.96774 3.83871 6.96774 4.3871C6.96774 4.96774 7.41935 5.41935 8 5.41935C8.54839 5.41935 9.03226 4.96774 9.03226 4.3871C9.03226 3.83871 8.54839 3.35484 8 3.35484Z" fill="#2873F0" />
                                                        </svg>
                                                        <p>{t('No results were found matching the information you entered. Please try again.')}</p>
                                                    </div>
                                        }
                                    </div>
                                </Fragment>
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}
export default translate("translation")(History);
