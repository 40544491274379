import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from '@material-ui/core/Button';
import '../scss/style.scss';
import threeDotsIcon from '../img/three-dots.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        maxWidth: 200,
    },
    dropdown: {
        position: 'absolute',
        top: 28,
        // padding: 10,
        zIndex: 1,  
    },
}));

export default function ClickAway(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    const handleClickAway = () => {
        setOpen(false);
    };
    const dropdownItemHandler = (indexDropdownItem) => {
        handleClickAway();
        props.dropdownItemHandler(indexDropdownItem);
    }
    return (
        <OutsideClickHandler onOutsideClick={handleClickAway}>
            <div className={classes.root + " " + props.className}>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <img src={threeDotsIcon} className="dropdown-icon" />
                </Button>
                {open ?
                    <div className={classes.dropdown + " dropdown-button-operation-list"}>{
                        props.dropdownItems.map((dropdownItem, index) => (
                            (dropdownItem.url === undefined) ?
                                <button className={ Boolean(dropdownItem.disable) ? "dropdown-button-operation-link dropdown-button-operation-link__disable" :"dropdown-button-operation-link"} disabled={Boolean(dropdownItem.disable)}  onClick={() => dropdownItemHandler(index)} key={index} >
                                    <div className="dropdown-button-operation-item" >{dropdownItem.title}</div>
                                </button>
                                :
                                <Link to={dropdownItem.url} className="dropdown-button-operation-link" key={index}>
                                    <div className="dropdown-button-operation-item" >{dropdownItem.title}</div>
                                </Link>
                        ))
                    }
                    </div>
                    : null}
            </div>
        </OutsideClickHandler>
    );
}