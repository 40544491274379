
import React, { Component, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import ExchangeRates from './ExchangeRates';
import Branchs from './Branchs/Branchs';
import Branch from './Branchs/Branch';
import Tariffs from './Tariffs';
import Campaigns from './Campaigns';
import Campaign from './Campaign';
import Innovations from './Innovations';
import Contact from './Contact';
import NotFound from './../../../copms/NotFound/NotFound';



export default class Transfer extends Component {
    render() {
        let { updateAppState, updateDashboardState, alertState, signLevel, signCount, logout, } = this.props;
        return (
            <Switch>
                <Redirect exact from="/dashboard/service-network/" to="/dashboard/transfer/exchange-rates"/>
                <Route path="/dashboard/service-network/exchange-rates" render={(routeProps) => <ExchangeRates logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route path="/dashboard/service-network/branchs" render={(routeProps) => <Branchs logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/service-network/branch/:id" render={(routeProps) => <Branch logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/service-network/tariffs" render={(routeProps) => <Tariffs logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/service-network/tariffs/:id" render={(routeProps) => <Tariffs logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/service-network/campaigns" render={(routeProps) => <Campaigns logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/service-network/campaigns/:id" render={(routeProps) => <Campaign logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route path="/dashboard/service-network/innovations" render={(routeProps) => <Innovations logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route path="/dashboard/service-network/contact" render={(routeProps) => <Contact logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route render={(routeProps) => (<NotFound />)} />
            </Switch>
        )
    }
}
