import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next';
import TransferModal from './../../../../copms/Modal/TransferModal';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import { request, ipsRequest } from './../../../../config';
import CreateTransferTemplate from './../../../../copms/Modal/CreateTransferTemplate';
import CheckboxMinus from './../../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../../copms/Checkbox/CheckboxTick';
import RadioButton from '../../../../copms/RadioButton/RadioButton';


class SetDefaultIPS extends Component {
    state = {
        feedbackErrorModal: null,
        createFeedbackMessage: false,
        open: false,
        confirmedOperation: false,
        accounts: [],
        feedbackMessage: null,
        authMethods: [],
        selectedAuthMethodIndex: -1,
        authMethodVisible: false,
        alias: {},
        selectedId: -1,
    }

    showAccounts = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasId: id
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                aliasId: id
            }

        fetch(ipsRequest("getAccountsByAliasId", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                    }
                    // getNoCardAccountListForCustomerResponse.accountList = getNoCardAccountListForCustomerResponse.accountList.filter(account => account.currName === this.state.currency);
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.accountList[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.accountList[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========

                        // =========CURRENCY=========
                        // getNoCardAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getNoCardAccountListForCustomerResponse.accountList[i].currName);
                    }
                    this.setState({ accounts: getNoCardAccountListForCustomerResponse.accountList && getNoCardAccountListForCustomerResponse.accountList.filter(el => el.currName == "AZN"), accountData: true });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }



    componentDidMount() {
        this.getAliases();
    }

    getAliases() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getIpsAuthMethodsRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("getAliases", getIpsAuthMethodsRequestBody))
            .then(res => res.text())
            .then((getIpsAuthMethodsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getIpsAuthMethodsResponse = JSON.parse(getIpsAuthMethodsResponseJSON);
                if (getIpsAuthMethodsResponse.status.statusCode !== 1) {
                    if (getIpsAuthMethodsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getIpsAuthMethodsResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ alias: getIpsAuthMethodsResponse.aliases && getIpsAuthMethodsResponse.aliases[0] })
                    this.showAccounts(getIpsAuthMethodsResponse.aliases[0].id)
                    // this.setState({ authMethods: getIpsAuthMethodsResponse.authMethodList });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    submitIps() {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createAccountRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            aliasIdAndAccountIdList: this.state.accounts.filter(el => el.accountId == this.state.selectedId).map((item) => {
                return {
                    aliasId: this.state.alias.id,
                    accountId: item.accountId,
                    isDefault: true,
                };
            }),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                aliasIdAndAccountIdList: this.state.accounts.filter(el => el.accountId == this.state.selectedId).map((item) => {
                    return {
                        aliasId: this.state.alias.id,
                        accountId: item.accountId,
                        isDefault: true,
                    };
                }),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("linkAliasToAccount", createAccountRequestBody))
            .then(res => res.text())
            .then((createAccountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createAccountResponse = JSON.parse(createAccountResponseJSON);
                if (createAccountResponse.status.statusCode !== 1) {
                    if (createAccountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(createAccountResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {

                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    selectAccount(index) {
        var accounts = this.state.accounts;
        accounts.map((item, index) => {
            accounts[index] = false;
        })
        accounts[index].isSelect = true;
        this.setState({ accounts: accounts })
    }

    selectAll(val) {
        var accounts = this.state.accounts;
        for (let i = 0; i < accounts.length; i++) {
            accounts[i].isSelect = val;
        }
        this.setState({ accounts: accounts })
    }

    checkAccountSelect() {
        return this.state.accounts.filter(el => el.isSelect == true).length;
    }

    render() {
        let { t, history } = this.props;
        let { open, openTemp, taxNumber, iban, alias, authMethods, selectedId, authMethodVisible, selectedAuthMethodIndex, accounts, createFeedbackMessage, feedbackErrorModal, confirmedOperation } = this.state;
        let requestBody = localStorage.getItem('compId') === null ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: this.state.operationId,
        } : {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: this.state.operationId,
            };
        return (
            <Fragment>
                <CreateTransferTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={localStorage.getItem('compId') ? 'addOperationTempJuridical' : 'addOperationTempIndividual'} requestBody={requestBody} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} />
                <TransferModal open={open} createTemplate={() => this.setState({ confirmedOperation: false, openTemp: true })} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} errorClose={() => this.setState({ confirmedOperation: false })} updateOpenModal={this.updateOpenModal} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Instant payments')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section">
                        <div className="dashboard-payment-container-data-section-header">
                            <h3 className="dashboard-payment-container-data-section-header-title">{alias && alias.ipsValue} - {t('Select the account you want to link to alias')}</h3>
                        </div>
                        <form className="dashboard-payment-container-data-section-content">
                            < div className="dashboard-transfer-list-content">
                                <table className="dashboard-transfer-list-table">
                                    <thead className="dashboard-transfer-list-table-head">
                                        <tr className="dashboard-transfer-list-table-row">
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head" style={{ width: '70px' }}>

                                            </th>
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("ACCOUNT")}</th>
                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="dashboard-transfer-list-table-body">
                                        {
                                            accounts.map((item, index) => {
                                                return (
                                                    <tr className="dashboard-transfer-list-table-row">
                                                        <td className="dashboard-transfer-list-table-cell">
                                                            <RadioButton value={item.accountId} selectedId={selectedId} onChange={(e) => this.setState({ selectedId: e.target.value })} />
                                                        </td>
                                                        <td className="dashboard-transfer-list-table-cell">{item.iban}</td>
                                                        <td className="dashboard-transfer-list-table-cell">{item.currency}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>

                    <div className="dashboard-payment-container-confirmation-section">
                        <div className="dashboard-payment-container-confirmation-section-header">
                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('What is the Instant Payment System?')}</h3>
                        </div>
                        <div className="dashboard-payment-container-confirmation-section-content">
                            <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                            <div className="dashboard-payment-container-confirmation-section-content-description">
                                <p>{t("ips content 1")}</p>
                            </div>
                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                <button disabled={selectedId == -1} className={`dashboard-payment-container-data-section-content-container-form-footer-button ${selectedId != -1 ? 'dashboard-payment-container-data-section-content-container-form-footer-button__blue' : 'dashboard-payment-container-data-section-content-container-form-footer-button__disable'} dashboard-payment-container-data-section-content-container-form-footer-button__left`} onClick={() => this.submitIps()}>{t('continue')}</button>

                                <button onClick={() => this.props.history.push('/dashboard/transfer/ips/set-default')} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('Skip')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default translate("translation")(SetDefaultIPS);