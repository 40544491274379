import React, { Component } from 'react';
import {
    Link,
    Redirect
} from "react-router-dom";
import AsanConfirmationIcon from "./img/asan-confirmation-icon.svg";
import headerLogo from "./img/header-logo.svg";
import { request } from "../../config";
import { translate } from 'react-i18next';

class AsanLoginConfirmation extends Component {
    state = {
        isDisabled: true,
        verificationCode: null
    }

    prevPage = () => {
        //this.props.history.push('/login/individual/asan/');
        window.location.href = '/login/individual/asan/';
    }
    componentDidMount() {
        this.props.updateAppState('isLoadingTransparentBackground', false);
        if(this.props.location.state === undefined) {
            // this.props.history.push('/login/individual/asan/');
            window.location.href = '/login/individual/asan/';
        }
        else {
            let {loginIndividualForAsanRequestBody}= this.props.location.state;
            this.setState({ verificationCode: loginIndividualForAsanRequestBody.verificationCode });

            fetch(request("register/loginIndividualForAsanv2", loginIndividualForAsanRequestBody))
                .then(res => res.text())
                .then((loginIndividualForAsanResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let loginIndividualForAsanResponse = JSON.parse(loginIndividualForAsanResponseJSON);
                    if (loginIndividualForAsanResponse.status.statusCode !== 1) {
                        localStorage.removeItem('userId');
                        localStorage.removeItem('phoneNumber');
                        this.props.history.push('/login/individual/asan/', this.props.t(loginIndividualForAsanResponse.status.statusCode));
                    }
                    else {
                        // =========ID=========
                        let custId = "";
                        if (typeof (loginIndividualForAsanResponse.custId) === "object" && loginIndividualForAsanResponse.custId.hasOwnProperty('c')) {
                            loginIndividualForAsanResponse.custId.c.forEach(part => {
                                custId += part;
                            });
                        }
                        else {
                            custId += loginIndividualForAsanResponse.custId;
                        }
                        // =========FULLNAME=========
                        // let fullname = loginIndividualForAsanResponse.name[0].toUpperCase() + loginIndividualForAsanResponse.name.substring(1, loginIndividualForAsanResponse.name.length).toLowerCase() +
                        //     ' ' + loginIndividualForAsanResponse.surname[0].toUpperCase() + loginIndividualForAsanResponse.surname.substring(1, loginIndividualForAsanResponse.surname.length).toLowerCase() +
                        //     ' ' + loginIndividualForAsanResponse.patronymic[0].toUpperCase() + loginIndividualForAsanResponse.patronymic.substring(1, loginIndividualForAsanResponse.patronymic.length).toLowerCase();
                        let fullname = loginIndividualForAsanResponse.surname + ' ' + loginIndividualForAsanResponse.name + ' ' + loginIndividualForAsanResponse.patronymic;
                        localStorage.setItem("custId", custId);
                        localStorage.setItem("token", loginIndividualForAsanResponse.token);
                        localStorage.setItem("fullname", fullname);
                        localStorage.setItem("custObject", JSON.stringify(loginIndividualForAsanResponse));
                        localStorage.setItem("checkResident", JSON.stringify(loginIndividualForAsanResponse.checkResident)); // TODO
                        localStorage.setItem("custIdEncrypt", loginIndividualForAsanResponse.custIdEncrypt);
                        localStorage.setItem("bankCustType", loginIndividualForAsanResponse.bankCustType);
                        localStorage.setItem("custTypeId", loginIndividualForAsanResponse.custTypeId);
                        localStorage.setItem("secretWord", loginIndividualForAsanResponse.secretWord);
                        localStorage.setItem("custType", loginIndividualForAsanResponse.custType);
                        this.props.updateAppState('isAuthed', true);
                        localStorage.setItem("bankCustomerType",loginIndividualForAsanResponse.bankCustomerType)
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    }
                );
        }
    }

    render() {
        let { isAuthed, t } = this.props;
        let { isDisabled, verificationCode } = this.state;

        if (isAuthed) {
            return <Redirect to='/dashboard/home' />
        }
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <div className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title registration-container-title--center">{t('asan signature confirmation')}</h2>
                    <p className="registration-container-description registration-container-description--center">{t('please accept the request sent to your phone')}</p>
                    <img src={AsanConfirmationIcon} className="registration-container-icon" alt="AsanConfirmationIcon" />
                    <div className="registration-container-asan-confirmation">
                        <span className="registration-container-asan-confirmation-code">{verificationCode}</span>
                    </div>
                    <div className="registration-container-validation-feedback"></div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.prevPage}>{t('back')}</button>
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled}>{t('next')}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(AsanLoginConfirmation);