import React, { Component, Fragment } from 'react';
import { Link, NavLink, Switch, Redirect, Route } from 'react-router-dom';
import accountIcon from './../../img/account-icon.svg';
import cardsIcon from './../../img/cards-icon.png';
import { request } from './../../../../config';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import { translate } from 'react-i18next';
import cardOperationFilter from '../../../../helpers/cardOperationFilter';
import transferFilter from '../../../../helpers/transferFilter';
import currencyFilter from '../../../../helpers/currencyFilter';
import YesNoModal from '../../../../copms/Modal/YesNoModal';
import Loading from './../../Loading/Loading';

class Templates extends Component {

    state = {
        open: false,
        modalCardObj: {},
        transfer_templates: [],
        card_operation_templates: [],
        deleteIndex: -1,
        deleteType: 1,
        minAmount: '',
        maxAmount: ''
    }

    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    dropdownItemHandler = (index, tempIndex, type, url) => {
        switch (index) {
            case 1:
                if (type === 1) {
                    this.setState({ deleteType: 1, deleteIndex: tempIndex, open: true })
                } else {
                    this.setState({ deleteType: 2, deleteIndex: tempIndex, open: true })
                }
                break;
        }
    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    deleteTransferTemplate(index) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let removePaymentTempCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.transfer_templates[index].id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.transfer_templates[index].id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("operation/deleteOperationTemp", removePaymentTempCustomerRequestBody))
            .then(res => res.text())
            .then((removePaymentTempCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let removePaymentTempCustomerResponse = JSON.parse(removePaymentTempCustomerResponseJSON);
                if (removePaymentTempCustomerResponse.status.statusCode !== 1) {
                    if (removePaymentTempCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removePaymentTempCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var tempArr = this.state.transfer_templates;
                    tempArr.splice(index, 1);
                    this.setState({ transfer_templates: tempArr });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    deleteCardTemplate(index) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let removePaymentTempCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.card_operation_templates[index].tempId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.card_operation_templates[index].tempId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("card/deleteCardOperationTemp", removePaymentTempCustomerRequestBody))
            .then(res => res.text())
            .then((removePaymentTempCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let removePaymentTempCustomerResponse = JSON.parse(removePaymentTempCustomerResponseJSON);
                if (removePaymentTempCustomerResponse.status.statusCode !== 1) {
                    if (removePaymentTempCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removePaymentTempCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var tempArr = this.state.card_operation_templates;
                    tempArr.splice(index, 1);
                    this.setState({ card_operation_templates: tempArr });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    deleteConfirm(val) {
        if (val) {
            if (this.state.deleteType === 1) {
                this.deleteTransferTemplate(this.state.deleteIndex);
            } else {
                this.deleteCardTemplate(this.state.deleteIndex);
            }
        }
    }

    componentDidMount() {
        if (this.props.location.pathname == "/dashboard/transfer/templates/card-operation") {
            this.searchTransfersCardOperation(true);
        } else {
            this.searchTransfers(true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                open: false,
                modalCardObj: {},
                // transfer_templates: [],
                // card_operation_templates: [],
                deleteIndex: -1,
                deleteType: 1,
                minAmount: '',
                maxAmount: '',
                tempName: ''
            }, () => {
                if (this.props.location.pathname == "/dashboard/transfer/templates/card-operation") {
                    this.searchTransfersCardOperation(false);
                } else {
                    this.searchTransfers(false);
                }
            })
        }
    }

    searchTransfers(initial) {
        // if (!initial) {
        //     this.props.updateAppState('isLoadingTransparentBackground', true);
        // }
        // else {
        this.setState({ isLoading: true });
        // }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            tempName: this.state.tempName,
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                tempName: this.state.tempName,
            }
        fetch(request("operation/getOperationSearchTempList" + customerType, operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((getCardOperationTempListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardOperationTempListResponse = JSON.parse(getCardOperationTempListResponseJSON);

                if (getCardOperationTempListResponse.status.statusCode === 1) {
                    for (let i = 0; i < getCardOperationTempListResponse.respOperationTempList.length; i++) {
                        // =========AMOUNT=========
                        if (getCardOperationTempListResponse.respOperationTempList[i].amt) {
                            if (getCardOperationTempListResponse.respOperationTempList[i].amt.toString() === "0") {
                                getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (getCardOperationTempListResponse.respOperationTempList[i].amt.toString().indexOf('.') !== -1) {
                                    let amountArray = getCardOperationTempListResponse.respOperationTempList[i].amt.toString().split('.');
                                    getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                        wholePart: getCardOperationTempListResponse.respOperationTempList[i].amt.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                        } else {
                            getCardOperationTempListResponse.respOperationTempList[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                    }
                    this.setState({ transfer_templates: getCardOperationTempListResponse.respOperationTempList && getCardOperationTempListResponse.respOperationTempList.length > 0 ? getCardOperationTempListResponse.respOperationTempList : [] });
                } else {
                    this.setState({ transfer_templates: [] })
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({ isLoading: false });
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    searchTransfersCardOperation(initial) {
        // if (!initial) {
        //     this.props.updateAppState('isLoadingTransparentBackground', true);
        // }
        // else {
        this.setState({ isLoading: true });
        // }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            tempName: this.state.tempName,
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                tempName: this.state.tempName,
            }
        fetch(request("card/getCardOperationSearchTemp", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((getCardOperationTempListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardOperationTempListResponse = JSON.parse(getCardOperationTempListResponseJSON);

                if (getCardOperationTempListResponse.status.statusCode === 1) {
                    for (let i = 0; i < getCardOperationTempListResponse.cardTempList.length; i++) {
                        // =========AMOUNT=========
                        if (getCardOperationTempListResponse.cardTempList[i].amount.toString() === "0") {
                            getCardOperationTempListResponse.cardTempList[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getCardOperationTempListResponse.cardTempList[i].amount.toString().indexOf('.') !== -1) {
                                let amountArray = getCardOperationTempListResponse.cardTempList[i].amount.toString().split('.');
                                getCardOperationTempListResponse.cardTempList[i].organizedAmount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                getCardOperationTempListResponse.cardTempList[i].organizedAmount = {
                                    wholePart: getCardOperationTempListResponse.cardTempList[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========NUMBER=========
                        if (getCardOperationTempListResponse.cardTempList[i].destinationCardNumber && getCardOperationTempListResponse.cardTempList[i].destinationCardNumber.length === 16) {
                            getCardOperationTempListResponse.cardTempList[i].organizedDestinationCardNumber = getCardOperationTempListResponse.cardTempList[i].destinationCardNumber.substring(0, 4)
                                + " **** **** " + getCardOperationTempListResponse.cardTempList[i].destinationCardNumber.substring(12, 16);
                        }
                        else {
                            getCardOperationTempListResponse.cardTempList[i].organizedDestinationCardNumber = getCardOperationTempListResponse.cardTempList[i].destinationCardNumber;
                        }

                    }
                    this.setState({ card_operation_templates: getCardOperationTempListResponse.cardTempList && getCardOperationTempListResponse.cardTempList.length > 0 ? getCardOperationTempListResponse.cardTempList : [] });
                } else {
                    this.setState({ card_operation_templates: [] })
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({ isLoading: false });
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    search(initial) {
        if (this.props.location.pathname == "/dashboard/transfer/templates/card-operation") {
            this.searchTransfersCardOperation(initial);
        } else {
            this.searchTransfers(initial);
        }
    }

    render() {
        let { open, transfer_templates, card_operation_templates, modalCardObj, tempName, isLoading } = this.state;
        let { updateAppState, t } = this.props;
        return (
            <Fragment>
                <YesNoModal onClose={() => this.setState({ open: false })} title={t('Are you sure you want to delete the template?')} open={open} triggerFunc={val => this.deleteConfirm(val)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Templates')}</h2>
                    </div>
                </div>

                <div className="dashboard-container-main-section">
                    <div className="dashboard-transfer-list">
                        <div className="dashboard-transfer-search-form-section" >
                            <div className="dashboard-transfer-search-form-section-item">
                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                    <input value={tempName} type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("Template name")} onChange={e => this.setState({ tempName: e.target.value })} />
                                </div>
                            </div>
                            <div className="dashboard-transfer-search-form-section-item">
                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                    <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={this.state.minAmount} placeholder={t("From the amount")} onChange={e => /^\d*\.{0,1}(\d{1,2})?$/.test(e.target.value) && this.setState({ minAmount: e.target.value })} />
                                    {/* <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div> */}
                                </div>
                            </div>
                            <div className="dashboard-transfer-search-form-section-item">
                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                    <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={this.state.maxAmount} placeholder={t("To the amount")} onChange={e => /^\d*\.{0,1}(\d{1,2})?$/.test(e.target.value) && this.setState({ maxAmount: e.target.value })} />
                                    {/* <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div> */}
                                </div>
                            </div>

                            <div className="dashboard-transfer-search-form-section-item">
                                <button className="dashboard-transfer-search-form-section-item-button" onClick={this.search.bind(this, false)}>{t("Search")}</button>
                            </div>
                        </div>


                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink exact to="/dashboard/transfer/templates/transfers" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("transfers")}</NavLink>
                                {
                                    !JSON.parse(localStorage.getItem('custObject')).company && <NavLink exact to="/dashboard/transfer/templates/card-operation" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Card operations")}</NavLink>
                                }
                            </div>
                        </div>
                        <Switch>
                            <Redirect exact from="/dashboard/transfer/templates" to="/dashboard/transfer/templates/transfers" />
                            <Route path="/dashboard/transfer/templates/transfers" >
                                <Fragment>
                                    {
                                        isLoading ?
                                            <Loading />
                                            :
                                            transfer_templates.length > 0 ?
                                                <div className="dashboard-finance-section-cards-section">
                                                    <div className="dashboard-finance-section-cards-section-table-section">
                                                        <table className="dashboard-finance-section-cards-section-table">
                                                            <tbody>
                                                                {
                                                                    transfer_templates.map((template, index) =>
                                                                        <Fragment key={index}>
                                                                            <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                                    <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                                        <img src={transferFilter(template.operNameId) && ((template.operNameId === 1 && template.operationTypeId === 15) ? transferFilter(15).iconName : transferFilter(template.operNameId).iconName)} style={{ height: '15px' }} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                                        {/* <img src={transferFilter(template.operNameId) && transferFilter(template.operNameId).iconName} style={{ height: '15px' }} className="dashboard-finance-section-cards-section-cell-icon__circle-img" /> */}
                                                                                    </div>
                                                                                    <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                        <Link to={`/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) &&
                                                                                            template.operationTypeId === 15 ?
                                                                                            'between'
                                                                                            :
                                                                                            transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`}
                                                                                            className="dashboard-finance-section-cards-section-cell-content">{template.tempName}</Link>
                                                                                        <Link to={`/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) &&
                                                                                            template.operationTypeId === 15 ?
                                                                                            'between'
                                                                                            :
                                                                                            transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`}
                                                                                            className="dashboard-finance-section-cards-section-cell-title">{template.crIban}</Link>
                                                                                    </div>

                                                                                </td>
                                                                                <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                    <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                        <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + template.amtCcy}>{template.amtCcy ? currencyFilter(template.amtCcy) : template.operationTypeId == 5 ? currencyFilter(template.crCcy) : currencyFilter(template.dtCcy)}</span>
                                                                                        <span className="dashboard-finance-section-cards-section-amount-whole-part">{template.amt ? this.amountSplit(template.amt.toString()).part1 : template.operationTypeId == 5 ? this.amountSplit(template.crAmt.toString()).part1 : this.amountSplit(template.dtAmt.toString()).part1}</span>
                                                                                        <span className="dashboard-finance-section-cards-section-amount-fractional-part">{template.amt ? this.amountSplit(template.amt.toString()).part2 : template.operationTypeId == 5 ? this.amountSplit(template.crAmt.toString()).part2 : this.amountSplit(template.dtAmt.toString()).part2}</span>
                                                                                    </p>
                                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t(transferFilter(template.operNameId) && transferFilter(template.operNameId).name)}</p>
                                                                                </td>
                                                                                <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                    <div className="dashboard-finance-section-cards-section-button-group">
                                                                                        <Dropdown
                                                                                            dropdownItems={[
                                                                                                { title: this.props.t('Create a payment'), url: "/dashboard/transfer/template/transfer/" + (transferFilter(template.operNameId) && transferFilter(template.operNameId).cardOperationTypeValue) + "/" + (template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : '') + template.id },
                                                                                                { title: this.props.t('Delete') },
                                                                                            ]}
                                                                                            dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, 1, `/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) && transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`)}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                            <tr className="dashboard-finance-row-line">
                                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className="dashboard-finance-section-invisible-cards-section" style={{ paddingBottom: '32px' }}>
                                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any templates.')}</p>
                                                    </div>
                                                    <Link to="/dashboard/transfer/transfers/all" style={{ textDecoration: 'none', color: '#FFFFFF' }} className="dashboard-finance-section-invisible-cards-section-button">{t('transfers')}</Link>
                                                </div>
                                    }
                                </Fragment>
                            </Route>
                            <Route path="/dashboard/transfer/templates/card-operation">
                                <Fragment>
                                    {
                                        isLoading ?
                                            <Loading />
                                            :
                                            card_operation_templates.length > 0 ?
                                                <div className="dashboard-finance-section-cards-section">
                                                    <div className="dashboard-finance-section-cards-section-table-section">
                                                        <table className="dashboard-finance-section-cards-section-table">
                                                            <tbody>
                                                                {
                                                                    card_operation_templates.map((template, index) =>
                                                                        <Fragment key={index}>
                                                                            <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                                    <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                                        <img src={cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).iconName} style={{ height: '15px' }} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                                    </div>
                                                                                    <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                        <Link to={`/dashboard/transfer/template/card-operation/${cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue}/${template.tempId}`} className="dashboard-finance-section-cards-section-cell-content">{template.tempName}</Link>
                                                                                        <Link to={`/dashboard/transfer/template/card-operation/${cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue}/${template.tempId}`} className="dashboard-finance-section-cards-section-cell-title">{template.organizedDestinationCardNumber}</Link>
                                                                                    </div>

                                                                                </td>
                                                                                <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                    <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                        <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + template.currency}>{currencyFilter(template.currency)}</span>
                                                                                        <span className="dashboard-finance-section-cards-section-amount-whole-part">{template.organizedAmount.wholePart}</span>
                                                                                        <span className="dashboard-finance-section-cards-section-amount-fractional-part">{template.organizedAmount.fractionalPart}</span>
                                                                                    </p>
                                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t(cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).name)}</p>
                                                                                </td>
                                                                                <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                    <div className="dashboard-finance-section-cards-section-button-group">
                                                                                        <Dropdown
                                                                                            dropdownItems={[
                                                                                                { title: this.props.t('Create a payment'), url: "/dashboard/transfer/template/card-operation/" + (cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue) + '/' + template.tempId },
                                                                                                { title: this.props.t('Delete') },
                                                                                            ]}
                                                                                            dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, 2, `/dashboard/transfer/template/card-operation/${cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue}/${template.tempId}`)}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                            <tr className="dashboard-finance-row-line">
                                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className="dashboard-finance-section-invisible-cards-section">
                                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any templates.')}</p>
                                                    </div>
                                                    <Link to="/dashboard/transfer/transfers/all" style={{ textDecoration: 'none' }} className="dashboard-finance-section-invisible-cards-section-button">{t('transfers')}</Link>
                                                </div>
                                    }

                                </Fragment>
                            </Route>
                        </Switch>

                    </div>

                </div>

            </Fragment>
        )
    }
}


export default translate("translation")(Templates);