import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import { request } from "../../../../../config";
import headerLogo from '../../../img/header-logo.svg';
import smsConfirmationIcon from '../../../img/sms-confirmation-icon.svg';
import commentAltLines from '../../../img/comment-alt-lines.svg';
import { translate } from 'react-i18next';
import GeneralModal from '../../../../../copms/Modal/GeneralModal';
import { formPost } from '../../../../../helpers/formPost';

class CreateTransferInternalOtp extends Component {
    state = {
        timer: "03:00",
        minute: 3,
        second: 0,
        timerInterval: null,
        feedbackMessage: null,
        feedbackMessageSuccess: null,
        isDisabled: true,
        openAlert: false,
    }

    backCheckCustomer = () => {
        this.props.history.push('/dashboard/finance/credits');
    }

    startTimer = () => {
        let { minute, second } = this.state;
        this.setState({
            timerInterval: setInterval(() => {
                let secondPart, minutePart;
                if (second === 0 && minute === 0) {
                    $('.registration-container-sms-confirmation-numbers').prop('disabled', true);
                    $('.registration-container-sms-confirmation-numbers').prop('value', null);
                    clearInterval(this.state.timerInterval);
                    this.setState({
                        timer: null
                    });
                }
                else {
                    if (second !== 0) {
                        second--;
                    }
                    else {
                        if (minute !== 0) {
                            minute--;
                            second = 59;
                        }
                    }
                    if (second < 10) {
                        secondPart = "0" + second;
                    }
                    else {
                        secondPart = second;
                    }
                    if (minute < 10) {
                        minutePart = "0" + minute;
                    }
                    else {
                        minutePart = minute;
                    }
                    this.setState({
                        timer: minutePart + ":" + secondPart
                    })
                }
            }, 1000)
        })
    }

    sendOtpCode = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ feedbackMessage: null, isDisabled: true });
        let sendOtpCodeRequestBody = {
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            mobile: JSON.parse(localStorage.getItem('custObject')).mobile || JSON.parse(localStorage.getItem('custObject')).customer.mobile,
        }
        fetch(request("loan/sendOtpForLoanPayment", sendOtpCodeRequestBody))
            .then(res => res.json())
            .then((sendOtpCodeRequestResponse) => {
                if (sendOtpCodeRequestResponse.status.statusCode !== 1) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ feedbackMessage: this.props.t(sendOtpCodeRequestResponse.status.statusCode) });
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ verifyToken: sendOtpCodeRequestResponse.verifyToken });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

        this.setState({
            timer: "03:00",
            minute: 3,
            second: 0,
        });
        clearInterval(this.state.timerInterval);
        this.startTimer();
        $('.registration-container-sms-confirmation-numbers').prop('disabled', false);
        $('.registration-container-sms-confirmation-numbers').prop('value', null);
        clearInterval(this.state.timerInterval);
        this.setState({
            timer: "03:00"
        });
    }

    insertCodeNumber = (number, event) => {
        let element = eval("this.code" + number);
        element.value = event.target.value.replace(/\D/g, '');
        if (event.target.value) {
            if (element.value.length > 1) {
                element.value = element.value.substring(0, 1);
            }
            if (event.target.nextElementSibling) {
                event.target.disabled = true;
                event.target.nextElementSibling.focus();
            }
        }
        else {
            event.target.value = null;
        }
        if (this.code1.value !== "" && this.code2.value !== "" && this.code3.value !== "" && this.code4.value !== "" && this.code5.value !== "" && this.code6.value !== "") {
            this.setState({ isDisabled: false });
        }
        else {
            this.setState({ isDisabled: true });
        }
    }

    deleteCodeNumber = (number, event) => {
        let element = eval("this.code" + number);
        let previousElement = eval("this.code" + (number - 1));
        if (event.keyCode === 8) {
            if (event.target.value === "") {
                if (event.target.previousElementSibling) {
                    previousElement.disabled = false;
                    event.target.previousElementSibling.value = "";
                    event.target.previousElementSibling.focus();
                }

            }
        }
    }

    formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            return `${day}.${month}.${year}`;
        }
        else {
            return null
        }
    }

    verifyOtpCode = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        clearInterval(this.state.timerInterval);
        this.setState({ timer: null });

        let otpCode = this.code1.value + this.code2.value + this.code3.value + this.code4.value + this.code5.value + this.code6.value

        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        fetch(request((this.props.location.state.payWith == 1 ? (this.props.location.state.selectedPaymentSource == 1 ? "pay/pay" + customerType + 'v1' : "operation/payLoanAccount" + customerType) : (this.props.location.state.payWith == 2 && "pay/payCardStorageIndividualv1")), { ...this.props.location.state.requestBody, confirmCode: otpCode, verifyToken: this.state.verifyToken}))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                        this.setState({ feedbackMessage: this.props.t(getCardListForCustomerResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                }
                else {
                    if(this.props.location.state.payWith == 2){
                        formPost(getCardListForCustomerResponse?.cardStorage?.tokenUrl, {
                            AMOUNT: getCardListForCustomerResponse?.cardStorage?.amount,
                            CURRENCY: getCardListForCustomerResponse?.cardStorage?.currency,
                            ORDER: getCardListForCustomerResponse?.cardStorage?.order,
                            DESC: getCardListForCustomerResponse?.cardStorage?.desc,
                            TRTYPE: getCardListForCustomerResponse?.cardStorage?.trtype,
                            TIMESTAMP: getCardListForCustomerResponse?.cardStorage?.timeStamp,
                            NONCE: getCardListForCustomerResponse?.cardStorage?.nonce,
                            BACKREF: getCardListForCustomerResponse?.cardStorage?.backref,
                            P_SIGN: getCardListForCustomerResponse?.cardStorage?.psign,
                            MERCH_NAME: getCardListForCustomerResponse?.cardStorage?.merchName,
                            MERCH_URL: getCardListForCustomerResponse?.cardStorage?.merchUrl,
                            TERMINAL: getCardListForCustomerResponse?.cardStorage?.terminal,
                            EMAIL: getCardListForCustomerResponse?.cardStorage?.email,
                            COUNTRY: getCardListForCustomerResponse?.cardStorage?.country,
                            MERCH_GMT: getCardListForCustomerResponse?.cardStorage?.merchGmt,
                            TOKEN: getCardListForCustomerResponse?.cardStorage?.token,
                            MERCHANT: getCardListForCustomerResponse?.cardStorage?.merchName,
                            LANG: localStorage.getItem('i18nextLng').toUpperCase()
                        });
                    }
                    if (this.props.location.state.selectedPaymentSource == 2 && customerType === "Juridical") {
                        this.props.history.push(`/dashboard/finance/credits/pay/${this.props.match.params.id}/${this.props.match.params.iban}`, {openTransferModal: true, feedbackErrorModal: null})    
                    }
                    else if (this.props.location.state.payWith == 1){
                        this.props.history.push(`/dashboard/finance/credits/pay/${this.props.match.params.id}/${this.props.match.params.iban}`, {
                            succesModal: true,
                            succesModalData: {
                                fullName: getCardListForCustomerResponse.fullName,
                                contractNumber: getCardListForCustomerResponse.contractNumber,
                                description: getCardListForCustomerResponse?.description,
                                purpose: getCardListForCustomerResponse?.purpose,
                                cardnumber: getCardListForCustomerResponse?.cardNumber,
                                dtIban: getCardListForCustomerResponse?.dtIban,
                                commission: getCardListForCustomerResponse.commission,
                                amount: getCardListForCustomerResponse.amount,
                                currency: getCardListForCustomerResponse.currency,
                                credit: getCardListForCustomerResponse.merchantName,
                                method: this.props.location.state.paymentTypes.find((item) => item.id == this.props.location.state.requestBody.paymentTypeId).name,
                                nextPaymentDate: this.formatDate(this.props.location.state.requestBody.nextPaymentDate)
                            }
                        })
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
}

componentDidMount = () => {
    if (this.props.location.state === undefined) {
        this.props.history.push('/dashboard/finance/credits');
    }
    else {
        this.sendOtpCode();
    }
}

render() {
    let { timer, feedbackMessage, isDisabled, openAlert, feedbackMessageSuccess } = this.state;
    let { t } = this.props;

    return (
        <div className="registration">
            <Link to="/">
                <img src={headerLogo} className="registration-logo" alt="headerLogo" />
            </Link>
            <GeneralModal open={openAlert}
                successClose={() => (this.setState({ openAlert: false }))}
                feedbackErrorModal={null}
                createFeedbackMessage={feedbackMessageSuccess}
                internalOtp={true} />
            <form className="registration-container registration-container--top-border">
                <h2 className="registration-container-title">{t('Enter the one-time code')}</h2>
                <p className="registration-container-description">{t('enter the 6-digit confirmation code sent to your phone')}</p>
                <img src={smsConfirmationIcon} className="registration-container-icon" alt="smsConfirmationIcon" />
                <div className="registration-container-sms-confirmation">
                    <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code1 = input }} placeholder="0" autoFocus={true} onInput={this.insertCodeNumber.bind(this, 1)} onKeyDown={this.deleteCodeNumber.bind(this, 1)} />
                    <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code2 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 2)} onKeyDown={this.deleteCodeNumber.bind(this, 2)} />
                    <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code3 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 3)} onKeyDown={this.deleteCodeNumber.bind(this, 3)} />
                    <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code4 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 4)} onKeyDown={this.deleteCodeNumber.bind(this, 4)} />
                    <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code5 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 5)} onKeyDown={this.deleteCodeNumber.bind(this, 5)} />
                    <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code6 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 6)} onKeyDown={this.deleteCodeNumber.bind(this, 6)} />
                </div>
                <div className="registration-container-validation-feedback">
                    {feedbackMessage ? <p className="registration-container-validation-feedback-message">{t(feedbackMessage)}</p> : null}
                </div>
                <div className="registration-container-resend">
                    <div className="registration-container-resend-container">
                        <div className="registration-container-resend-container-icon">
                            <img src={commentAltLines} className="registration-container-resend-container-icon-img" alt="commentAltLines" />
                        </div>
                        <button type="button" className="registration-container-resend-container-button" disabled={timer != null} onClick={this.sendOtpCode}>{t('resend the code')}</button>
                    </div>
                    <span className="registration-container-resend-timer">{timer}</span>
                </div>
                <div className="registration-container-footer">
                    <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backCheckCustomer}>{t('back')}</button>
                    <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled} onClick={this.verifyOtpCode}>{t('next')}</button>
                </div>
            </form>
        </div>
    )
}
}



export default translate("translation")(CreateTransferInternalOtp);