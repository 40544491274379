import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import userIconForm from "./../img/user-icon-form.svg";
import eyeIconForm from "./../img/eye-icon-form.svg";
import eyeSlashIconForm from "./../img/eye-slash-icon-form.svg";
import { request } from "./../../../config";
import { translate } from 'react-i18next';
import AlertPopup from './../../../copms/AlertPopup/AlertPopup';

class LoginWithUsrAndPwdIndividual extends Component {
    state = {
        feedbackMessage: null,
        username: "",
        password: ""
    }
    componentWillMount() {
        if (localStorage.getItem("errorExp")) {
            setTimeout(() => {
                this.setState({ feedbackMessage: localStorage.getItem("errorExp") })
            }, 700);
        }
    }
    setPasswordVisibility = (event) => {
        if ($(event.target).hasClass("header-sign-section-container-form-group-box-icon-img__password-slash")) {
            $(event.target).removeClass("header-sign-section-container-form-group-box-icon-img__password-slash");
            $(event.target).addClass("header-sign-section-container-form-group-box-icon-img__password");
            $(event.target).attr("src", eyeIconForm);
            $(event.currentTarget).find(".header-sign-section-container-form-group-box-input").attr("type", "password");
        }
        else {
            if ($(event.target).hasClass("header-sign-section-container-form-group-box-icon-img__password")) {
                $(event.target).removeClass("header-sign-section-container-form-group-box-icon-img__password");
                $(event.target).addClass("header-sign-section-container-form-group-box-icon-img__password-slash");
                $(event.target).attr("src", eyeSlashIconForm);
                $(event.currentTarget).find(".header-sign-section-container-form-group-box-input").attr("type", "text");
            }
        }
    }
    loginWithUsrAndPwdIndividualMethod = (event) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        event.preventDefault();
        let loginWithUsrAndPwdIndividualRequestBody = {
            username: this.state.username,
            password: this.state.password
        };
        fetch(request("register/loginWithUsrAndPwdIndividualV2", loginWithUsrAndPwdIndividualRequestBody))
            .then(res => res.text())
            .then((loginWithUsrAndPwdIndividualResponseJSON) => {
                const JSON = require('true-json-bigint');
                let loginWithUsrAndPwdIndividualResponse = JSON.parse(loginWithUsrAndPwdIndividualResponseJSON);
                if (loginWithUsrAndPwdIndividualResponse.status.statusCode !== 1) {
                    if (loginWithUsrAndPwdIndividualResponse.status.statusCode === 119) {
                        this.props.history.push('/login/individual/mobile/unblock-user/1', {});
                    }
                    else {
                        this.setState({
                            feedbackMessage: this.props.t(loginWithUsrAndPwdIndividualResponse.status.statusCode),
                        });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else if (loginWithUsrAndPwdIndividualResponse.otpVisible) {
                    // console.log(loginWithUsrAndPwdIndividualResponse)

                    // console.log(getOtpFromAsanResponse)

                    // localStorage.setItem('userId', loginWithUsrAndPwdIndividualRequestBody.userId);
                    // localStorage.setItem('verificationCode', loginWithUsrAndPwdIndividualResponse.verificationCode);
                    // localStorage.setItem('phoneNumber', loginWithUsrAndPwdIndividualRequestBody.phoneNumber);

                    // console.log("success")
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.props.history.push('/login/individual/mobile/otp', { loginWithUsrAndPwdIndividualRequestBody, loginWithUsrAndPwdIndividualResponse });
                }
                else {
                    // console.log(loginWithUsrAndPwdIndividualResponse)
                    // =========ID=========
                    let custId = "";
                    if (typeof (loginWithUsrAndPwdIndividualResponse.custId) === "object" && loginWithUsrAndPwdIndividualResponse.custId.hasOwnProperty('c')) {
                        loginWithUsrAndPwdIndividualResponse.custId.c.forEach(part => {
                            custId += part;
                        });
                    }
                    else {
                        custId += loginWithUsrAndPwdIndividualResponse.custId;
                    }
                    // =========FULLNAME=========
                    // let fullname = loginWithUsrAndPwdIndividualResponse.name[0].toUpperCase() + loginWithUsrAndPwdIndividualResponse.name.substring(1, loginWithUsrAndPwdIndividualResponse.name.length).toLowerCase() +
                    //     ' ' + loginWithUsrAndPwdIndividualResponse.surname[0].toUpperCase() + loginWithUsrAndPwdIndividualResponse.surname.substring(1, loginWithUsrAndPwdIndividualResponse.surname.length).toLowerCase() +
                    //     ' ' + loginWithUsrAndPwdIndividualResponse.patronymic[0].toUpperCase() + loginWithUsrAndPwdIndividualResponse.patronymic.substring(1, loginWithUsrAndPwdIndividualResponse.patronymic.length).toLowerCase();
                    let fullname = `${loginWithUsrAndPwdIndividualResponse.surname} ${loginWithUsrAndPwdIndividualResponse.name} ${loginWithUsrAndPwdIndividualResponse.patronymic ? loginWithUsrAndPwdIndividualResponse?.patronymic : ''}`
                    localStorage.setItem("custId", custId);
                    localStorage.setItem("token", loginWithUsrAndPwdIndividualResponse.token);
                    localStorage.setItem("secretWord", loginWithUsrAndPwdIndividualResponse.secretWord);
                    localStorage.setItem("custTypeId", loginWithUsrAndPwdIndividualResponse.custTypeId);
                    localStorage.setItem("fullname", fullname);
                    localStorage.setItem("checkResident", JSON.stringify(loginWithUsrAndPwdIndividualResponse.checkResident)); // TODO
                    localStorage.setItem("custObject", JSON.stringify(loginWithUsrAndPwdIndividualResponse));
                    localStorage.setItem("bankCustomerType",loginWithUsrAndPwdIndividualResponse.bankCustomerType);
                    localStorage.setItem("custIdEncrypt", loginWithUsrAndPwdIndividualResponse.custIdEncrypt);
                    this.props.updateAppState('isAuthed', true);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                }
            );
    }
    forgotPassword = () => {
        this.props.history.push('/login/individual/mobile/forgot-password/1');
    }
    componentDidMount() {
        this.username.click();
        let intervalUsername = setInterval(() => {
            if (this.username && this.username.value) {

                this.setState({ username: this.username.value });
                clearInterval(intervalUsername);
            }
        }, 100);
        let intervalPassword = setInterval(() => {
            if (this.password && this.password.value) {
                this.setState({ password: this.password.value });
                clearInterval(intervalPassword);
            }
        }, 100);
        this.props.updateIsAsan(false);
        this.props.updateIsJuridical(false);

    }

    render() {
        let { feedbackMessage, username, password } = this.state;
        let { t } = this.props;
        return (
            <form className="header-sign-section-container-form">
                <div className="header-sign-section-container-form-group">
                    <label className="header-sign-section-container-form-group-label">{t('Pin code')} / {t('username')}</label>
                    <div className="header-sign-section-container-form-group-box">
                        <input type="text" maxLength="7" className="header-sign-section-container-form-group-box-input" ref={(input) => { this.username = input }} placeholder={`${t('Pin code')} / ${t('username')}`} value={username} onChange={(event) => { this.setState({ feedbackMessage: null, username: event.target.value }) }} />
                        <div className="header-sign-section-container-form-group-box-icon">
                            <img src={userIconForm} className="header-sign-section-container-form-group-box-icon-img  header-sign-section-container-form-group-box-icon-img__user" alt="User icon" />
                        </div>
                    </div>
                </div>
                <div className="header-sign-section-container-form-group" onClick={this.setPasswordVisibility}>
                    <label className="header-sign-section-container-form-group-label">{t('password')}</label>
                    <div className="header-sign-section-container-form-group-box">
                        <input type="password" className="header-sign-section-container-form-group-box-input" ref={(input) => { this.password = input }} placeholder={t('password')} value={password} onChange={(event) => { this.setState({ feedbackMessage: null, password: event.target.value }) }} />
                        <div className="header-sign-section-container-form-group-box-icon">
                            <img src={eyeIconForm} className="header-sign-section-container-form-group-box-icon-img header-sign-section-container-form-group-box-icon-img__password" alt="User password" />
                        </div>
                    </div>
                </div>
                <div className="header-sign-section-container-form-validation-feedback">
                    {
                        feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage}</p> : null
                    }
                </div>
                <div className="header-sign-section-container-form-additional">
                    <div className="header-sign-section-container-form-additional-remember">
                        <label className="header-sign-section-container-form-additional-remember-label">{t('remember password')}
                            <input type="checkbox" className="header-sign-section-container-form-additional-remember-checkbox" />
                            <span className="header-sign-section-container-form-additional-remember-checkmark"></span>
                        </label>
                    </div>
                    <button type="button" className="header-sign-section-container-form-additional-forget-password" onClick={this.forgotPassword}>{t('forget password')}</button>
                </div>
                <button className="header-sign-section-container-form-button" disabled={
                    username.trim() === "" || password.trim() === ""
                } onClick={this.loginWithUsrAndPwdIndividualMethod}>{t('login')}</button>
            </form>
        )
    }
}


export default translate()(LoginWithUsrAndPwdIndividual);