import React, { Component, Fragment } from 'react';
import { Route, NavLink, Switch, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import Loading from './../../Loading/Loading';
import ReceiptModal from './../../../../copms/Modal/ReceiptModalMpay';
import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import $ from 'jquery';
import OutsideClickHandler from 'react-outside-click-handler';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import categoryFilter from "./../../../../helpers/categoryFilter";

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
class History extends Component {
    state = {
        searchedValidation: false,
        minAmount: null,
        maxAmount: null,
        startDate: null,
        endDate: null,
        dtIban: null,
        operStateId: null,
        selectedStatus: "",
        selectedAccount: "",
        currency: "AZN",
        dropdownVisibilityStatus: false,
        dropdownVisibilityAccount: false,
        accounts: [],
        convertations: [],
        payments: [],
        textareaText: "",
        textarea2Text: [],
        resultText: "",
        isLoading: false,

        succesModalData: null,
        succesModal: false,
        secondDesc: '',
        item: null,
        cardSelectedIndex: -1,
        cards: [],
        dropdownVisibilityCard: false,
        minAmount: null,
        maxAmount: null,
        startDate: null,
        endDate: null,

        currentPage: 1,
        lastPage: 0
    }
    queryPayment(payment) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        if (payment.categoryId) {

            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let getMerchantCustomerRequestBody = customerType == 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                merchantId: payment.merchantId,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                merchantId: payment.merchantId,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

            fetch(request("pg/getMerchantParams" + customerType, getMerchantCustomerRequestBody))
                .then(res => res.text())
                .then((getMerchantCustomerResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getMerchantCustomerResponse = JSON.parse(getMerchantCustomerResponseJSON);

                    if (getMerchantCustomerResponse.status.statusCode !== 1) {
                        if (getMerchantCustomerResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                    else {
                        var items = [];
                        var items2 = [];
                        for (var j = 0; j < getMerchantCustomerResponse.merchantParamList?.length; j++) {
                            for (var i = 0; i < getMerchantCustomerResponse.merchantParamList[j].length; i++) {
                                if (items.find(el => el.merchantDescName === getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName)) {
                                    getMerchantCustomerResponse.merchantParamList[j][i].params = [];
                                    getMerchantCustomerResponse.merchantParamList[j][i].isShow = false;
                                    if (getMerchantCustomerResponse.merchantParamList[j][i].paramValue && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== "" && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== ",") {
                                        var paramArr = getMerchantCustomerResponse.merchantParamList[j][i].paramValue.split(',');
                                        var paramLabelArr = getMerchantCustomerResponse.merchantParamList[j][i].paramDesc.split(',');
                                        for (var k = 0; k < paramArr.length - 1; k++) {
                                            if (k == 0) {
                                                getMerchantCustomerResponse.merchantParamList[j][i].defaultSelect = paramArr[k];
                                            }
                                            getMerchantCustomerResponse.merchantParamList[j][i].params.push({
                                                value: paramArr[k],
                                                label: paramLabelArr[k]
                                            });
                                        }
                                    }
                                    items[items.findIndex(el => el.merchantDescName === getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName)].elements.push(getMerchantCustomerResponse.merchantParamList[j][i]);
                                } else {
                                    getMerchantCustomerResponse.merchantParamList[j][i].params = [];
                                    getMerchantCustomerResponse.merchantParamList[j][i].isShow = false;
                                    if (getMerchantCustomerResponse.merchantParamList[j][i].paramValue && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== "" && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== ",") {
                                        var paramArr = getMerchantCustomerResponse.merchantParamList[j][i].paramValue.split(',');
                                        var paramLabelArr = getMerchantCustomerResponse.merchantParamList[j][i].paramDesc.split(',');
                                        for (var k = 0; k < paramArr.length - 1; k++) {
                                            if (k == 0) {
                                                getMerchantCustomerResponse.merchantParamList[j][i].defaultSelect = paramArr[k];
                                            }
                                            getMerchantCustomerResponse.merchantParamList[j][i].params.push({
                                                value: paramArr[k],
                                                label: paramLabelArr[k]
                                            });
                                        }
                                    }
                                    if (getMerchantCustomerResponse.merchantParamList[j].length > 1 || this.state.category?.categoryName == "Mobile") {
                                        items.push({
                                            merchantDescName: getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName,
                                            isShow: false,
                                            elements: [getMerchantCustomerResponse.merchantParamList[j][i]]
                                        });
                                    } else {
                                        items2.push({
                                            merchantDescName: getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName,
                                            isShow: false,
                                            elements: [getMerchantCustomerResponse.merchantParamList[j][i]]
                                        });
                                    }
                                }
                            }
                        }

                        this.reverseParams(items, payment.description);
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        } else {
            this.props.updateAppState('isLoadingTransparentBackground', false);
            this.setState({ succesModal: true, item: null, secondDesc: '' })

        }
        this.setState({ succesModalData: payment })
    }

    reverseParams(items, description) {
        var arr = description.split(';');
        this.setState({ items: items, succesModal: true, selectedIndex: items.findIndex(item => item.merchantDescName == arr[0]), item: items.find(item => item.merchantDescName == arr[0]), secondDesc: arr[1] });
    }

    getCardList() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCategoryListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }


        fetch(request("card/getCardListFor" + customerType + "Customer", getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }

                    this.setState({ cards: getCardListForCustomerResponse.cardList, selectedValue: getCardListForCustomerResponse.cardList && getCardListForCustomerResponse.cardList.length > 0 && 0 });
                    //this.props.updateAppState('isLoading', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => {
            this.searchTransfers(false);
        });
    }


    async downloadPayments() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ searchedValidation: false });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            dtAcountId: this.state.cardSelectedIndex >= 0 ? this.state.cards[this.state.cardSelectedIndex].cardId : null,
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                dtAcountId: this.state.cardSelectedIndex >= 0 ? this.state.cards[this.state.cardSelectedIndex].cardId : null,
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                startDate: this.getFormatedDate(this.state.startDate),
                endDate: this.getFormatedDate(this.state.endDate),
            }
        await fetch(request("pg/getPaymentHistoryExcelByteArray", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((paymentListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let paymentListCustomerResponse = JSON.parse(paymentListCustomerResponseJSON);
                if (paymentListCustomerResponse.status.statusCode !== 1 && paymentListCustomerResponse.status.statusCode !== 282) {
                    if (paymentListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(paymentListCustomerResponse.bytes);
                    let name = `${this.props.t('payments')}_${this.props.t('history')}_${this.state.startDate ? this.getFormatedDate(this.state.startDate) : ''}_${this.state.endDate ? this.getFormatedDate(this.state.endDate) : ''}`;
                    saveFile(name + ".xlsx", "application/xlsx", arrrayBuffer)
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateDate = (name, value) => {
        this.setState({ [name]: value });
    }
    updateAccount = (dtIban, currName, event) => {
        this.setState({ dtIban: dtIban, selectedAccount: event.target.textContent, dropdownVisibilityAccount: false, currency: currName });
    }
    updateDate = (name, value) => {
        this.setState({ [name]: value });
    }
    updateStatus = (id) => {
        switch (id) {
            case null: this.setState({ selectedStatus: this.props.t('all') }); break;
            case 1: this.setState({ selectedStatus: this.props.t('1st authorization') }); break;
            case 2: this.setState({ selectedStatus: this.props.t('2nd authorization') }); break;
            case 3: this.setState({ selectedStatus: this.props.t('Approved') }); break;
            case 4: this.setState({ selectedStatus: this.props.t('Confirmation is expected') }); break;
            case 5: this.setState({ selectedStatus: this.props.t('refusal') }); break;
        }
        this.setState({ dropdownVisibilityStatus: false, operStateId: id });
    }
    updateAmount = (name, event) => {
        let mask = /[^0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        this.setState({ [name]: event.target.value });
    }
    changeDropdownVisibilityStatus = () => {
        this.setState({ dropdownVisibilityStatus: !this.state.dropdownVisibilityStatus });
    }
    changeDropdownVisibilityAccount = () => {
        this.setState({ dropdownVisibilityAccount: !this.state.dropdownVisibilityAccount });
    }
    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }

    searchTransfers = async (initial) => {
        if (initial) {
            this.setState({ isLoading: true });
        }
        else {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            dtAcountId: this.state.cardSelectedIndex >= 0 ? this.state.cards[this.state.cardSelectedIndex].cardId : null,
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: this.state.currentPage
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                dtAcountId: this.state.cardSelectedIndex >= 0 ? this.state.cards[this.state.cardSelectedIndex].cardId : null,
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                startDate: this.getFormatedDate(this.state.startDate),
                endDate: this.getFormatedDate(this.state.endDate),
                pageNumber: this.state.currentPage
            }
        await fetch(request("pg/payAdvancedSearch", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((paymentListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let paymentListCustomerResponse = JSON.parse(paymentListCustomerResponseJSON);
                if (paymentListCustomerResponse.status.statusCode !== 1 && paymentListCustomerResponse.status.statusCode !== 282) {
                    if (paymentListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ payments: [] });
                    }
                }
                else {
                    this.setState({ lastPage: paymentListCustomerResponse.pageCount ? paymentListCustomerResponse.pageCount : 0 })

                    if (paymentListCustomerResponse.status.statusCode === 1) {
                        for (let i = 0; i < paymentListCustomerResponse.paymentList.length; i++) {
                            // =========AMOUNT=========
                            if (paymentListCustomerResponse.paymentList[i].amount.toString() === "0") {
                                paymentListCustomerResponse.paymentList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (paymentListCustomerResponse.paymentList[i].amount.toString().indexOf('.') !== -1) {
                                    let amountArray = paymentListCustomerResponse.paymentList[i].amount.toString().split('.');
                                    paymentListCustomerResponse.paymentList[i].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    paymentListCustomerResponse.paymentList[i].organizedAmount = {
                                        wholePart: paymentListCustomerResponse.paymentList[i].amount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                            // =========NUMBER=========
                            if(paymentListCustomerResponse.paymentList[i].cardNumber){
                                paymentListCustomerResponse.paymentList[i].organizedCardNumber = paymentListCustomerResponse.paymentList[i].cardNumber.substring(0, 4)
                                + " **** **** " + paymentListCustomerResponse.paymentList[i].cardNumber.substring(12, 16);
                            }

                        }
                        this.setState({ payments: paymentListCustomerResponse.paymentList });

                    } else {
                        this.setState({ payments: [] });
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({ isLoading: false });
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount() {
        this.getCardList();
        this.searchTransfers(true);
    }

    // componentDidMount() {
    //     this.showPaymentList();
    // }

    // showPaymentList() {
    //     this.setState({ isLoading: true });
    //     let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
    //     let getCategoryListCustomerRequestBody = customerType == 'Juridical' ? {
    //         custId: localStorage.getItem('custId'),
    //         compId: localStorage.getItem('compId'),
    //         token: localStorage.getItem('token'),
    //         lang: localStorage.getItem('i18nextLng').toUpperCase()
    //     } : {
    //             custId: localStorage.getItem('custId'),
    //             token: localStorage.getItem('token'),
    //             lang: localStorage.getItem('i18nextLng').toUpperCase()
    //         }

    //     fetch(request("pg/paymentList" + customerType, getCategoryListCustomerRequestBody))
    //         .then(res => res.text())
    //         .then((paymentListCustomerResponseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let paymentListCustomerResponse = JSON.parse(paymentListCustomerResponseJSON);
    //             if (paymentListCustomerResponse.status.statusCode !== 1 && paymentListCustomerResponse.status.statusCode !== 282) {
    //                 if (paymentListCustomerResponse.status.statusCode === 200) {
    //                     this.props.logout();
    //                 }
    //             }
    //             else {
    //                 if (paymentListCustomerResponse.status.statusCode === 1) {
    //                     for (let i = 0; i < paymentListCustomerResponse.paymentList.length; i++) {
    //                         // =========AMOUNT=========
    //                         if (paymentListCustomerResponse.paymentList[i].amount.toString() === "0") {
    //                             paymentListCustomerResponse.paymentList[i].organizedAmount = {
    //                                 wholePart: "0",
    //                                 fractionalPart: "00"
    //                             }
    //                         }
    //                         else {
    //                             if (paymentListCustomerResponse.paymentList[i].amount.toString().indexOf('.') !== -1) {
    //                                 let amountArray = paymentListCustomerResponse.paymentList[i].amount.toString().split('.');
    //                                 paymentListCustomerResponse.paymentList[i].organizedAmount = {
    //                                     wholePart: amountArray[0],
    //                                     fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
    //                                 }
    //                             }
    //                             else {
    //                                 paymentListCustomerResponse.paymentList[i].organizedAmount = {
    //                                     wholePart: paymentListCustomerResponse.paymentList[i].amount.toString(),
    //                                     fractionalPart: "00"
    //                                 }
    //                             }
    //                         }
    //                         // =========NUMBER=========
    //                         paymentListCustomerResponse.paymentList[i].organizedCardNumber = paymentListCustomerResponse.paymentList[i].cardNumber.substring(0, 4)
    //                             + " **** **** " + paymentListCustomerResponse.paymentList[i].cardNumber.substring(12, 16);

    //                     }
    //                     this.setState({ payments: paymentListCustomerResponse.paymentList });
    //                 }
    //             }
    //             this.setState({ isLoading: false });
    //         },
    //             (error) => {
    //                 this.props.history.push("/technical-break");
    //             });
    // }

    render() {
        let { selectedStatus, selectedAccount, dropdownVisibilityStatus, dropdownVisibilityAccount, operStateId,
            dtIban, accounts, currency, payments, convertations, searchedValidation, isLoading,
            uccesModalData, succesModal, item, secondDesc, cardSelectedIndex, cards, dropdownVisibilityCard, succesModalData } = this.state;
        let { t, updateAppState, alertState, } = this.props;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('history')}</h2>
                    </div>
                </div>

                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <textarea onChange={e => this.filterFunccc(e.target.value)} style={{width: '30%'}} />
                        <textarea onChange={e => this.combineFilter(e.target.value)} style={{width: '30%'}}/>
                        <textarea value={this.state.resultText} style={{width: '30%'}} />
                    </div> */}
                            <div className="dashboard-transfer-search-list-section" style={{ marginTop: 0, minHeight: '70vh' }}>
                                <Fragment>
                                    <ReceiptModal history={this.props.history} templateSave={() => this.setState({ templateModal: true, succesModal: false })} item={item} secondDesc={secondDesc} onClose={() => this.setState({ succesModal: false })} data={succesModalData} open={succesModal} />
                                    <div className="dashboard-transfer-list-content">
                                        <div className="dashboard-transfer-search-form-section" style={{ padding: 0, marginTop: '10px', }}>
                                            {/* <div className="dashboard-transfer-search-form-section-item">
                                                <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCard: false }) }}>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                        <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={() => this.setState({ dropdownVisibilityCard: !dropdownVisibilityCard })}>
                                                            {
                                                                cardSelectedIndex >= 0 ?
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{cards[cardSelectedIndex].organizedCardNumber}</div>
                                                                    :
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("all")}</div>
                                                            }
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        {
                                                            cards.length !== 0 &&
                                                            <ul className={(dropdownVisibilityCard === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={() => this.setState({ cardSelectedIndex: -1, dropdownVisibilityCard: false })}>{t("all")}</button>
                                                                </li>
                                                                {
                                                                    cards.map((card, index) => (
                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                            <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={() => this.setState({ cardSelectedIndex: index, dropdownVisibilityCard: false })}>{card.organizedCardNumber}</button>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </div>
                                                </OutsideClickHandler>
                                            </div> */}
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("From the amount")} onChange={e => /^\d*\.?\d*$/.test(e.target.value) && this.setState({ minAmount: e.target.value })} />
                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                </div>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("To the amount")} onChange={e => /^\d*\.?\d*$/.test(e.target.value) && this.setState({ maxAmount: e.target.value })} />
                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                </div>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <StartDatePicker placeholder={t("Start date")} updateDate={this.updateDate} />
                                                </div>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <EndDatePicker placeholder={t("End date")} updateDate={this.updateDate} />
                                                </div>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <button className="dashboard-transfer-search-form-section-item-button" onClick={() => { this.searchTransfers(false); this.setState({ currentPage: 1, lastPage: 0 }); }}>{t("Search")}</button>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <button className={`dashboard-transfer-search-form-section-item-button ${(!payments || payments.length === 0) ? "dashboard-transfer-search-form-section-item-button__disable" : ""}`} disabled={(!payments || payments.length === 0)} onClick={this.downloadPayments.bind(this, false)}>{t("Download Excel")}</button>
                                            </div>
                                        </div>
                                        {
                                            payments.length !== 0 ?
                                                <>
                                                    <table className="dashboard-transfer-list-table">

                                                        <tbody className="dashboard-transfer-list-table-body">
                                                            {
                                                                payments.map((payment, index) => {
                                                                    return (
                                                                        <tr className="dashboard-transfer-list-table-row" onClick={(e) => this.queryPayment(payment)} style={{ cursor: 'pointer' }} key={index}>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                                    <img src={categoryFilter(payment.catName)} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                                </div>
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <span className="dashboard-finance-section-cards-section-cell-content">{payment.merchantName}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-cell-title">{payment.billingDate}, {payment.currency}, {payment.organizedCardNumber}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                {payment?.billingStateId !== undefined && <p className="dashboard-finance-section-cards-section-cell-title">Status: {payment.billingStateId == 0 ? t('an error occurred') : payment.billingStateId == 6 ? t('on hold') : payment.billingStateId == 2 ? t('successful operation') : ''}</p>}

                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className="dashboard-payment-operation-section-content-item-amount-whole-part">{payment.organizedAmount.wholePart}</span>
                                                                                    <span className="dashboard-payment-operation-section-content-item-amount-fractional-part">{payment.organizedAmount.fractionalPart}</span>
                                                                                    <span className="dashboard-payment-operation-section-content-item-amount-currency">{payment.currency}</span>
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{payment.categoryName}</p>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                    <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                        <ul className="dashboard-pagination">
                                                            {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                            {
                                                                Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                                    if (this.state.lastPage > 3) {
                                                                        if (this.state.currentPage - 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                                    {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage + 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                    {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                                    {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                                </>
                                                                            )
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                        )
                                                                    }
                                                                })

                                                            }
                                                            {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                        </ul>
                                                    </div>
                                                </>
                                                :
                                                searchedValidation === false ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div className="dashboard-last-operations-section-content">
                                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="dashboard-finance-section-cards-section-commission-alert">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 1.03226C11.8065 1.03226 14.9677 4.16129 14.9677 8C14.9677 11.871 11.8387 14.9677 8 14.9677C4.12903 14.9677 1.03226 11.871 1.03226 8C1.03226 4.16129 4.12903 1.03226 8 1.03226ZM8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM6.83871 11.0968C6.6129 11.0968 6.45161 11.2903 6.45161 11.4839V11.7419C6.45161 11.9677 6.6129 12.129 6.83871 12.129H9.16129C9.35484 12.129 9.54839 11.9677 9.54839 11.7419V11.4839C9.54839 11.2903 9.35484 11.0968 9.16129 11.0968H8.77419V6.58065C8.77419 6.3871 8.58064 6.19355 8.3871 6.19355H6.83871C6.6129 6.19355 6.45161 6.3871 6.45161 6.58065V6.83871C6.45161 7.06452 6.6129 7.22581 6.83871 7.22581H7.22581V11.0968H6.83871ZM8 3.35484C7.41935 3.35484 6.96774 3.83871 6.96774 4.3871C6.96774 4.96774 7.41935 5.41935 8 5.41935C8.54839 5.41935 9.03226 4.96774 9.03226 4.3871C9.03226 3.83871 8.54839 3.35484 8 3.35484Z" fill="#2873F0" />
                                                        </svg>
                                                        <p>{t('No results were found matching the information you entered. Please try again.')}</p>
                                                    </div>
                                        }
                                    </div>
                                </Fragment>
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}
export default translate("translation")(History);
