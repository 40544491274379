
import React, { Component, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Transfers from './Transfers/Transfers';
import CreateTransferInternal from './Transfers/CreateTransfer/CreateTransferInternal/CreateTransferInternal';
import CreateTransferInland from './Transfers/CreateTransfer/CreateTransferInland/CreateTransferInland';
import CreateTransferAbroad from './Transfers/CreateTransfer/CreateTransferAbroad/CreateTransferAbroad';
import CreateTransferConvertation from './Transfers/CreateTransfer/CreateTransferConvertation/CreateTransferConvertation';
import Authorisation from './Authorisation/Authorisation';
import FirstAuthorisation from './FirstAuthorisation/FirstAuthorisation';
import SecondAuthorisation from './SecondAuthorisation/SecondAuthorisation';
import OTPFirstAuthorisation from './OTP/FirstAuthorisation/FirstAuthorisation';
import OTPSecondAuthorisation from './OTP/SecondAuthorisation/SecondAuthorisation';
import History from './History/History';
import NotFound from './../../../copms/NotFound/NotFound';
import CardOperation from './CardOperation/CardOperation';
import BetweenInternalCardOperation from './CardOperation/BetweenInternal';
import BetweenExternalCardOperation from './CardOperation/BetweenExternal';
import CodeCardOperation from './CardOperation/Code';
import ZkTransfer from './CardOperation/ZkTransfer';
import ZkTransferInfo from './CardOperation/ZkTransferInfo';
import CreateZkTransfer from './CardOperation/CreateZkTransfer';
// ====================
import ExpressTransfer from './CardOperation/ExpressTransfer';
import CreateExpressTransfer from './CardOperation/CreateExpressTransfer';
// ====================
import FastMoneyTransfer from './CardOperation/FastMoneyTransfer';
import FriendTransfer from './CardOperation/FriendTransfer';
import CreateFriendTransfer from './CardOperation/CreateFriendTransfer';
import FriendTransferToFriend from './CardOperation/FriendTransferToFriend';
import FriendTransferToMe from './CardOperation/FriendTransferToMe';
import Templates from './Templates/Templates';
import TransferTempalte from './Templates/Transfers/Transfers';
import CardOperationTemplate from './Templates/CardOperation/CardOperation';
import ConvertationEdit from './Edit/ConvertationEdit';
import InternalEdit from './Edit/InternalEdit';
import InlandEdit from './Edit/InlandEdit';
import IPS from './IPS/Main';
import RegistrationIPS from './IPS/RegistrationIPS';
import CreateIPS from './IPS/CreateAliasIPS';
import SetDefaultIPS from './IPS/SetDefaultIPS';
import Home from './IPS/Home';
import PayRegister from './IPS/PayRegister';
import PayUnRegister from './IPS/PayUnRegister';

import Aliases from './IPS/Aliases';
import CreateAlias from './IPS/CreateAlias';
import LinkAlias from './IPS/LinkAlias';
import Accounts from './IPS/Accounts';
import RegisterAccount from './IPS/RegisterAccount';
import Income from './IPS/Income';
import Payments from './IPS/Payments';

import AbroadEdit from './Edit/AbroadEdit'
import InlandEditFactura from './Edit/InlandEditFactura';
import AbroadEditFactura from './Edit/AbroadEditFactura';
import BetweenEdit from './Edit/BetweenEdit';
import CreateTransferBetween from './Transfers/CreateTransfer/CreateTransferBetween/CreateTransferBetween';
import CreateTransferInternalOtp from './Transfers/CreateTransfer/CreateTransferInternal/CreateTransferInternalOtp';
import CardToCardHistory from './CardOperation/CardToCardHistory';

export default class Transfer extends Component {
    render() {
        let { updateAppState, updateDashboardState, alertState, signLevel, signCount, logout, checkRegistration } = this.props;
        return (
            localStorage.getItem('compId') ?
                <Switch>
                    <Redirect exact from="/dashboard/transfer/" to="/dashboard/transfer/transfers" />
                    <Redirect exact from="/dashboard/transfer/transfers" to="/dashboard/transfer/transfers/all" />
                    <Redirect path="/dashboard/transfer/card-operation" to="/dashboard/transfer" />
                    <Redirect path="/dashboard/transfer/templates/card-operation/" to="/dashboard/transfer/templates" />
                    <Redirect path="/dashboard/transfer/template/card-operation/" to="/dashboard/transfer/template" />
                    <Route exact path="/dashboard/transfer/transfers/between" render={(routeProps) => <CreateTransferBetween {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/internal" render={(routeProps) => <CreateTransferInternal logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/inland" render={(routeProps) => <CreateTransferInland logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/abroad" render={(routeProps) => <CreateTransferAbroad logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/convertation/:type" render={(routeProps) => <CreateTransferConvertation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/transfers" render={(routeProps) => <Transfers logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/2nd-authorization" render={(routeProps) => <FirstAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/1st-authorization" render={(routeProps) => <SecondAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/authorization" render={(routeProps) => <Authorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips" render={(routeProps) => <IPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/registration" render={(routeProps) => <RegistrationIPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/create-alias" render={(routeProps) => <CreateIPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/set-default" render={(routeProps) => <SetDefaultIPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/home" render={(routeProps) => <Home logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/pay/registration/:type" render={(routeProps) => <PayRegister logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/pay/unregistration" render={(routeProps) => <PayUnRegister logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/aliases" render={(routeProps) => <Aliases logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/aliases/create" render={(routeProps) => <CreateAlias logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/aliases/link/:id" render={(routeProps) => <LinkAlias logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/accounts" render={(routeProps) => <Accounts logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/accounts/register" render={(routeProps) => <RegisterAccount logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/income" render={(routeProps) => <Income logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/payments" render={(routeProps) => <Payments logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/abroad/:id" render={(routeProps) => <AbroadEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/inland/:id" render={(routeProps) => <InlandEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    {
                        localStorage.getItem('custObject') && JSON.parse(localStorage.getItem('custObject')).customer.isOtpSign == 1 &&
                        <Route path="/dashboard/transfer/otp/2nd-authorization" render={(routeProps) => <OTPFirstAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    }
                    {
                        localStorage.getItem('custObject') && JSON.parse(localStorage.getItem('custObject')).customer.isOtpSign == 1 &&
                        <Route path="/dashboard/transfer/otp/1st-authorization" render={(routeProps) => <OTPSecondAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    }
                    <Route path="/dashboard/transfer/history" render={(routeProps) => <History logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/between/:id" render={(routeProps) => <BetweenEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/internal/:id" render={(routeProps) => <InternalEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/inland/:id" render={(routeProps) => <InlandEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/inland/:id" render={(routeProps) => <InlandEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/convertation/:id/:type" render={(routeProps) => <ConvertationEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/abroad/:id" render={(routeProps) => <AbroadEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/abroad/:id" render={(routeProps) => <AbroadEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/templates" render={(routeProps) => <Templates logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/template/transfer/" render={(routeProps) => <TransferTempalte logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route render={(routeProps) => (<NotFound />)} />
                </Switch>
                :
                <Switch>
                    <Redirect exact from="/dashboard/transfer/" to="/dashboard/transfer/transfers" />
                    <Redirect exact from="/dashboard/transfer/transfers" to="/dashboard/transfer/transfers/all" />
                    <Redirect exact from="/dashboard/transfer/card-operation/express-transfer" to="/dashboard/transfer/card-operation/express-transfer/history" />
                    <Route exact path="/dashboard/transfer/card-operation/express-transfer/history" render={(routeProps) => <ExpressTransfer {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/card-operation/express-transfer/create" render={(routeProps) => <CreateExpressTransfer {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                    {/* ============================================================================ */}
                    <Redirect exact from="/dashboard/transfer/card-operation/zk-transfer" to="/dashboard/transfer/card-operation/zk-transfer/history" />
                    <Redirect exact from="/dashboard/transfer/card-operation/friend-transfer" to="/dashboard/transfer/card-operation/friend-transfer/history" />
                    <Redirect exact from="/dashboard/transfer/card-operation/friend-transfer/history" to="/dashboard/transfer/card-operation/friend-transfer/history/1" />
                    <Route exact path="/dashboard/transfer/transfers/between" render={(routeProps) => <CreateTransferBetween {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/internal" render={(routeProps) => <CreateTransferInternal {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/inland" render={(routeProps) => <CreateTransferInland {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/abroad" render={(routeProps) => <CreateTransferAbroad {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/transfers/convertation/:type" render={(routeProps) => <CreateTransferConvertation {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/transfers" render={(routeProps) => <Transfers {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/2nd-authorization" render={(routeProps) => <FirstAuthorisation {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/1st-authorization" render={(routeProps) => <SecondAuthorisation {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/authorization" render={(routeProps) => <Authorisation {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/history" render={(routeProps) => <History {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path={["/dashboard/transfer/card-operation", "/dashboard/transfer/card-operation/card-to-card"]} render={(routeProps) => <CardOperation {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/card-operation/card-to-card/between-internal" render={(routeProps) => <BetweenInternalCardOperation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/card-operation/card-to-card/between-external" render={(routeProps) => <BetweenExternalCardOperation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/card-operation/card-to-card/history" render={(routeProps) => <CardToCardHistory logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/card-operation/code" render={(routeProps) => <CodeCardOperation {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/card-operation/friend-transfer/history/:type" render={(routeProps) => <FriendTransfer {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/card-operation/friend-transfer/create" render={(routeProps) => <CreateFriendTransfer {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/card-operation/friend-transfer/tofriend/:id" render={(routeProps) => <FriendTransferToFriend {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/card-operation/friend-transfer/tome/:id" render={(routeProps) => <FriendTransferToMe {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                    <Route path="/dashboard/transfer/card-operation/zk-transfer/history/:id" render={(routeProps) => <ZkTransferInfo {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/card-operation/zk-transfer/history" render={(routeProps) => <ZkTransfer {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/card-operation/zk-transfer/create" render={(routeProps) => <CreateZkTransfer {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                    <Route path="/dashboard/transfer/card-operation/fast-money-transfer" render={(routeProps) => <FastMoneyTransfer {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />

                    <Route path="/dashboard/transfer/template/card-operation/" render={(routeProps) => <CardOperationTemplate {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips" render={(routeProps) => <IPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/registration" render={(routeProps) => <RegistrationIPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/create-alias" render={(routeProps) => <CreateIPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/set-default" render={(routeProps) => <SetDefaultIPS logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/home" render={(routeProps) => <Home logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/pay/registration/:type" render={(routeProps) => <PayRegister logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/pay/unregistration" render={(routeProps) => <PayUnRegister logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/aliases" render={(routeProps) => <Aliases logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/aliases/create" render={(routeProps) => <CreateAlias logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/ips/aliases/link/:id" render={(routeProps) => <LinkAlias logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/accounts" render={(routeProps) => <Accounts logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/accounts/register" render={(routeProps) => <RegisterAccount logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/income" render={(routeProps) => <Income logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route exact path="/dashboard/transfer/ips/payments" render={(routeProps) => <Payments logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/abroad/:id" render={(routeProps) => <AbroadEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/inland/:id" render={(routeProps) => <InlandEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/between/:id" render={(routeProps) => <BetweenEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/internal/:id" render={(routeProps) => <InternalEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/inland/:id" render={(routeProps) => <InlandEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/inland/:id" render={(routeProps) => <InlandEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/convertation/:id/:type" render={(routeProps) => <ConvertationEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit/abroad/:id" render={(routeProps) => <AbroadEdit logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/edit-factura/abroad/:id" render={(routeProps) => <AbroadEditFactura logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/templates" render={(routeProps) => <Templates {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/template/transfer/" render={(routeProps) => <TransferTempalte {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route path="/dashboard/transfer/template/card-operation/" render={(routeProps) => <CardOperationTemplate {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                    <Route render={(routeProps) => (<NotFound />)} />
                </Switch>
        )
    }
}
