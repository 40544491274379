import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import accountIcon from './../../img/account-icon.svg';
import cardsIcon from './../../img/cards-icon.png';
import searchIcon from './../../img/search-icon.svg';
import { request } from './../../../../config';
import Dropdown from './../../../../copms/DropdownPayment/DropdownPayment';
import { translate } from 'react-i18next';
import categoryFilter from '../../../../helpers/categoryFilter';
import currencyFilter from '../../../../helpers/currencyFilter';
import YesNoModal from '../../../../copms/Modal/YesNoModal';
import Loading from './../../Loading/Loading';
import add from '../../../../helpers/img/new-icons/add.svg'

class Templates extends Component {

    state = {
        open: false,
        modalCardObj: {},
        templates: [],
        dropdownItems: [
            // { title: this.props.t('Create a payment'), },
            { title: this.props.t('Delete') },
        ],
        deleteIndex: -1,
        minAmount: null,
        maxAmount: null,
        tempName: null,
        isLoading: false
    }

    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    dropdownItemHandler = (index, tempIndex, url) => {
        switch (index) {
            case 0:
                this.props.history.push(url)
                break;
            case 1:
                this.setState({ open: true, deleteIndex: tempIndex })
                break;
        }
    }

    deleteTemplate(index) {
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let removePaymentTempCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.templates[index].id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.templates[index].id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("pg/removePaymentTemp" + customerType, removePaymentTempCustomerRequestBody))
            .then(res => res.text())
            .then((removePaymentTempCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let removePaymentTempCustomerResponse = JSON.parse(removePaymentTempCustomerResponseJSON);
                if (removePaymentTempCustomerResponse.status.statusCode !== 1) {
                    if (removePaymentTempCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removePaymentTempCustomerResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var tempArr = this.state.templates;
                    tempArr.splice(index, 1);
                    this.setState({ templates: tempArr });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }
    deleteConfirm(val) {
        if (val) {
            this.deleteTemplate(this.state.deleteIndex)
        }
    }

    componentDidMount() {
        this.getTemplates()
    }

    getTemplates() {
        this.setState({ isLoading: true });
        let getCategoryListCustomerRequestBody = {
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            pageNumber: 1
        }
        fetch(request("pg/paymentTempList", getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((paymentTempListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let paymentTempListCustomerResponse = JSON.parse(paymentTempListCustomerResponseJSON);
                if (paymentTempListCustomerResponse.status.statusCode !== 1 && paymentTempListCustomerResponse.status.statusCode !== '????????????') {
                    if (paymentTempListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (paymentTempListCustomerResponse.status.statusCode === 1) {
                        for (let i = 0; i < paymentTempListCustomerResponse.paymentTempList.length; i++) {
                            // =========AMOUNT=========
                            if (paymentTempListCustomerResponse.paymentTempList[i].amount.toString() === "0") {
                                paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (paymentTempListCustomerResponse.paymentTempList[i].amount.toString().indexOf('.') !== -1) {
                                    let amountArray = paymentTempListCustomerResponse.paymentTempList[i].amount.toString().split('.');
                                    paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
                                        wholePart: paymentTempListCustomerResponse.paymentTempList[i].amount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                        }
                        this.setState({ templates: paymentTempListCustomerResponse.paymentTempList });
                    }
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async searchTransfers(initial) {
        if (!initial) {
            this.setState({ isLoadingTemplates: true });

            // this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        this.setState({ searchedValidation: false });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            tempName: this.state.tempName,
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                minAmt: this.state.minAmount,
                maxAmt: this.state.maxAmount,
                tempName: this.state.tempName,
            }
        await fetch(request("pg/paymentSearchTempList" + customerType, operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((paymentListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let paymentTempListCustomerResponse = JSON.parse(paymentListCustomerResponseJSON);
                if (paymentTempListCustomerResponse.status.statusCode !== 1 && paymentTempListCustomerResponse.status.statusCode !== 284) {
                    if (paymentTempListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (paymentTempListCustomerResponse.status.statusCode === 1) {
                        for (let i = 0; i < paymentTempListCustomerResponse.paymentTempList.length; i++) {
                            // =========AMOUNT=========
                            if (paymentTempListCustomerResponse.paymentTempList[i].amount.toString() === "0") {
                                paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (paymentTempListCustomerResponse.paymentTempList[i].amount.toString().indexOf('.') !== -1) {
                                    let amountArray = paymentTempListCustomerResponse.paymentTempList[i].amount.toString().split('.');
                                    paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
                                        wholePart: paymentTempListCustomerResponse.paymentTempList[i].amount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                        }
                        this.setState({ templates: paymentTempListCustomerResponse.paymentTempList });
                    }
                    else {
                        if (paymentTempListCustomerResponse.status.statusCode === 284) {
                            this.setState({ templates: [] });
                        }
                    }
                    this.setState({ isLoadingTemplates: false });

                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { open, templates, modalCardObj, dropdownItems, isLoading, isLoadingTemplates } = this.state;
        let { updateAppState, t } = this.props;
        return (
            <Fragment>
                <YesNoModal onClose={() => this.setState({ open: false })} title={t('Are you sure you want to delete the template?')} open={open} triggerFunc={val => this.deleteConfirm(val)} />

                {
                    isLoading ?
                        <Loading />
                        :
                        <>
                            <div style={{ overflow: 'hidden' }} className="dashboard-finance-section-cards-section">
                                <div className="dashboard-transfer-search-form-section-redesign row" style={{ display: !this.props.searchVisibility && 'none', transition: '.2s' }} >
                                    <div className="col-12">
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box d-flex">
                                            <img src={searchIcon} alt="" style={{ paddingLeft: '12px' }} />
                                            <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("Template name")} onChange={e => this.setState({ tempName: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                            <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("From the amount")} onChange={e => this.setState({ minAmount: e.target.value })} />
                                            {/* <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div> */}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                            <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("To the amount")} onChange={e => this.setState({ maxAmount: e.target.value })} />
                                            {/* <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div> */}
                                        </div>
                                    </div>
                                    <div className="col-6 m-auto">
                                        <button className="dashboard-transfer-search-form-section-item-button" onClick={this.searchTransfers.bind(this, false)}>{t("Search")}</button>
                                    </div>
                                </div>
                                {
                                    templates.length === 0 ?
                                        <>
                                            <div style={{ textAlign: 'center', paddingBottom: '32px' }}>
                                                <div className="dashboard-last-operations-section-content">
                                                    <img src={cardsIcon} className="dashboard-last-operations-section-content-image" />
                                                    <p className="dashboard-last-operations-section-content-status">{t('You do not currently have any templates.')}</p>
                                                    {/* <Link to="/dashboard/payment/templates/new-template" style={{ textDecoration: 'none', color: '#FFFFFF' }} className="dashboard-finance-section-invisible-cards-section-button">{t('New templates')}</Link> */}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="dashboard-finance-section-cards-section-table-section-redesign mt-4">
                                                <table className="dashboard-finance-section-cards-section-table">
                                                    <tbody>
                                                        <tr>
                                                            <Link to="/dashboard/payment/templates/new-template" className="dashboard-template-section-content-button-redesign">
                                                                <div className="dashboard-template-section-content-button__left-side dashboard-template-section-content-button-icon-section__new dashboard-template-section-content-button-redesign-icon">
                                                                    <img src={add} alt="" />
                                                                </div>
                                                                <div className="dashboard-template-section-content-button__right-side">
                                                                    <p className="dashboard-template-section-content-title">{t("New templates")}</p>
                                                                </div>
                                                            </Link>
                                                        </tr>
                                                        {isLoadingTemplates ?
                                                            <Loading /> :
                                                            templates.map((template, index) =>
                                                                <Fragment key={index}>
                                                                    <tr className="dashboard-finance-section-cards-section-row dashboard-finance-section-cards-section-row-redesign d-flex justify-content-between" key={index}>
                                                                        <Link to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}templates/template-pay/${template.categoryId}/providers/${template.merchantId}/temp/${template.id}`} className="w-100">
                                                                            <td className="dashboard-finance-section-cards-section-cell m-0 align-items-center">
                                                                                <div className="dashboard-template-section-content-button__left-side dashboard-template-section-content-button-icon-section__new dashboard-template-section-content-button-redesign-icon">
                                                                                    <img src={categoryFilter(template.catName)} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                                    {/* template?.merchantImage */}
                                                                                </div>
                                                                                <p className="dashboard-payment-section-content-title">{template.tempName}</p>
                                                                                {/* <Link to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}templates/template-pay/${template.categoryId}/providers/${template.merchantId}/temp/${template.id}`} className="dashboard-finance-section-cards-section-cell-title">{template.merchantName}</Link> */}
                                                                            </td>
                                                                        </Link>
                                                                        {/* <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + template.currency}>{currencyFilter(template.currency)}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{template.organizedAmount.wholePart}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{template.organizedAmount.fractionalPart}</span>
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{template.categoryName}</p>
                                                                            </td> */}
                                                                        <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right m-0">
                                                                            <div className="dashboard-finance-section-cards-section-button-group">
                                                                                <Dropdown
                                                                                    dropdownItems={dropdownItems}
                                                                                    dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, `/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}templates/template-pay/${template.categoryId}/providers/${template.merchantId}/temp/${template.id}`)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                    {/* <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        </tr> */}
                                                                </Fragment>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Link to="/dashboard/payment/templates" class="dashboard-template-section-content-button__left-side dashboard-template-section-content-button-redesign-icon mx-auto w-50 my-4 text-white" style={{background: "#0179d2"}}>
                                                    {t("more")}
                                                </Link>
                                            </div>
                                        </>
                                }
                            </div>
                        </>
                }

            </Fragment>
        )
    }
}


export default translate("translation")(Templates);