import React, { Component } from 'react';
import {
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import { request } from "./../../config";
import CompanyListItem from "./CompanyListItem/CompanyListItem";
import headerLogo from "./img/header-logo.svg";
import { translate } from 'react-i18next';
import { error } from 'jquery';

class AsanJuridicalLoginSelectCompany extends Component {
    state = {
        companies: [],
        checkedCompany: null,
        compId: null,
        token: null,
        taxNo: null,
        isDisabled: true
    }

    updateCheckedCompany = (id, taxNo, token) => {
        this.setState({ isDisabled: false });
        this.setState({ compId: id });
        this.setState({ token: token });
        this.setState({ taxNo: taxNo });
        // console.log(token)
    }

    prevPage = () => {
        // this.props.history.push('/login/juridical/asan/');
        window.location.href = '/login/juridical/asan/';
    }

    registerJuridicalAsan = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        if (this.props.location.state === undefined) {
            // this.props.history.push('/login/juridical/asan/');
            window.location.href = '/login/juridical/asan/';
            // console.log("error")
        }
        else {
            let { selectCustCompanyRequestBody, loginJuridicalForAsanRequestBody } = this.props.location.state;
            // let selectCustCompanyRequestBody = this.props.location.state;

            fetch(request("register/selectCustomerCompany", { ...selectCustCompanyRequestBody, otpCode: null, token: this.state.token}))
                .then(res => res.text())
                .then((selectCustCompanyResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let selectCustCompanyResponse = JSON.parse(selectCustCompanyResponseJSON);
                    if (selectCustCompanyResponse.status.statusCode !== 1) {
                        localStorage.removeItem('userId');
                        localStorage.removeItem('phoneNumber');
                        // console.log({ ...selectCustCompanyRequestBody, otpCode: null, token: this.state.token })
                        // console.log(selectCustCompanyHeaders)
                        // console.log(selectCustCompanyResponse)

                        // this.props.history.push('/login/juridical/asan/', this.props.t(selectCustCompanyResponse.status.statusCode));    
                    }

                    else if (selectCustCompanyResponse.customer.otpVisible) {
                        // console.log(selectCustCompanyResponse)

                        localStorage.setItem('userId', selectCustCompanyRequestBody.userId);
                        localStorage.setItem('verificationCode', loginJuridicalForAsanRequestBody.verificationCode);
                        localStorage.setItem('phoneNumber', selectCustCompanyRequestBody.mobile);

                        this.props.history.push('/login/juridical/asan/otp', { selectCustCompanyRequestBody, token: this.state.token, verifyToken: selectCustCompanyResponse.customer.verifyToken, mobile: selectCustCompanyResponse.customer.mobile });
                        // console.log(getAuthInfoIndividualLoginRequestBody)
                        // console.log(getAuthInfoIndividualLoginHeaders)
                        // console.log(getOtpFromAsanRequestBodyWithoutOtp)
                    }

                    else {
                       
                            // =========ID=========
                            let custId = "";
                            if (typeof (selectCustCompanyResponse.customer.custId) === "object" && selectCustCompanyResponse.customer.custId.hasOwnProperty('c')) {
                                selectCustCompanyResponse.customer.custId.c.forEach(part => {
                                    custId += part;
                                });
                            }
                            else {
                                custId += selectCustCompanyResponse.customer.custId;
                            }
                            // =========COMPID=========
                            let compId = "";
                            if (typeof (selectCustCompanyResponse.company.id) === "object" && selectCustCompanyResponse.company.id.hasOwnProperty('c')) {
                                selectCustCompanyResponse.company.id.c.forEach(part => {
                                    compId += part;
                                });
                            }
                            else {
                                compId += selectCustCompanyResponse.company.id;
                            }


                            // const JSON = require('true-json-bigint');
                            // let getCompaniesCertResponse = JSON.parse(getCompaniesCertResponseJSON);
                            // if (selectCustCompanyResponse.status.statusCode !== 1) {
                            //     localStorage.removeItem('userId');
                            //     localStorage.removeItem('phoneNumber');
                            //     // this.props.history.push('/login/juridical/asan/', this.props.t(getCompaniesCertResponse.status.statusCode));
                            //     console.log('error')
                            // }
                            // else {
                            //     for (let i = 0; i < selectCustCompanyResponse.company.length; i++) {
                            //         let companyId = "";
                            //         if (typeof (selectCustCompanyResponse.company[i].id) === 'object' && selectCustCompanyResponse.company[i].id.hasOwnProperty('c')) {
                            //             selectCustCompanyResponse.company[i].id.c.forEach(part => {
                            //                 companyId += part;
                            //             });
                            //         }
                            //         else {
                            //             companyId += selectCustCompanyResponse.company[i].id;
                            //         }
                            //         selectCustCompanyResponse.company[i].id = companyId;
                            //     }
                            //     this.setState({ companies: selectCustCompanyResponse.company });
                            //     this.props.updateAppState('isLoading', false);
                            // }
                            // =========FULLNAME=========
                            // let fullname = selectCustCompanyResponse.customer.name[0].toUpperCase() + selectCustCompanyResponse.customer.name.substring(1, selectCustCompanyResponse.customer.name.length).toLowerCase() +
                            //     ' ' + selectCustCompanyResponse.customer.surname[0].toUpperCase() + selectCustCompanyResponse.customer.surname.substring(1, selectCustCompanyResponse.customer.surname.length).toLowerCase() +
                            //     ' ' + selectCustCompanyResponse.customer.patronymic[0].toUpperCase() + selectCustCompanyResponse.customer.patronymic.substring(1, selectCustCompanyResponse.customer.patronymic.length).toLowerCase();
                            let fullname = selectCustCompanyResponse.customer.surname + ' ' + selectCustCompanyResponse.customer.name + ' ' + selectCustCompanyResponse.customer.patronymic;
                            localStorage.setItem("custId", custId);
                            localStorage.setItem("compId", compId);
                            localStorage.setItem("token", selectCustCompanyResponse.customer.token);
                            localStorage.setItem("fullname", fullname);
                            localStorage.setItem("custObject", JSON.stringify(selectCustCompanyResponse));
                            localStorage.setItem("isOperNo", selectCustCompanyResponse.company.isOperNo);
                            localStorage.setItem("compIdEncrypt", selectCustCompanyResponse.company.compIdEncrypt);
                            localStorage.setItem("bankCustomerType", selectCustCompanyResponse.customer.bankCustomerType);
                            localStorage.setItem("compName", selectCustCompanyResponse.company.name);

                            // localStorage.setItem("bankCustType", selectCustCompanyResponse.company.bankCustType);

                            localStorage.setItem("secretWord", selectCustCompanyResponse.customer.secretWord);
                            localStorage.setItem("custTypeId", selectCustCompanyResponse.customer.custTypeId);

                            this.props.updateAppState('isAuthed', true);
                            this.props.updateAppState('isLoadingTransparentBackground', false);

                    }
                },
                    (error) => {
                        localStorage.removeItem('userId');
                        localStorage.removeItem('phoneNumber');
                        this.props.history.push("/technical-break");
                    }
                );
        }
    }

    componentDidMount() {
        this.props.updateAppState('isLoading', false);
        // let selectCustCompanyRequestBody = this.props.location.state;

        if (this.props.location.state === undefined) {
            // this.props.history.push('/login/juridical/asan/');
            window.location.href = '/login/juridical/asan/';
            // console.log(this.props.location.state)
        }
        else {
        let { loginJuridicalForAsanResponse } = this.props.location.state;

            // let getCompaniesCertRequestBody = this.props.location.state;
            // this.props.updateAppState('isLoading', true);
            // console.log(loginJuridicalForAsanResponse)
            // fetch(request("register/selectCustomerCompany",  ))
            // .then(res => res.text())
            // .then((getCompaniesCertResponseJSON) => {
            // const JSON = require('true-json-bigint');
            // let getCompaniesCertResponse = JSON.parse(getCompaniesCertResponseJSON);
            if (loginJuridicalForAsanResponse.status.statusCode !== 1) {
                localStorage.removeItem('userId');
                localStorage.removeItem('phoneNumber');
                this.props.history.push('/login/juridical/asan/', this.props.t(loginJuridicalForAsanResponse.status.statusCode));
                // console.log('error')
            }
            else {
                for (let i = 0; i < loginJuridicalForAsanResponse.company.length; i++) {
                    let companyId = "";
                    let token = "";
                    if (typeof (loginJuridicalForAsanResponse.company[i].id) === 'object' && loginJuridicalForAsanResponse.company[i].id.hasOwnProperty('c')) {
                        loginJuridicalForAsanResponse.company[i].id.c.forEach(part => {
                            companyId += part;
                            token += part;
                        });
                    }
                    else {
                        companyId += loginJuridicalForAsanResponse.company[i].id;
                        token += loginJuridicalForAsanResponse.company[i].id;
                    }
                    loginJuridicalForAsanResponse.company[i].id = companyId;
                    loginJuridicalForAsanResponse.company[i].id = token;
                }
                this.setState({ companies: loginJuridicalForAsanResponse.company });
                this.props.updateAppState('isLoading', false);
                // console.log(loginJuridicalForAsanResponse)
            }
            // },
            // (error) => {
            //     localStorage.removeItem('userId');
            //     localStorage.removeItem('phoneNumber');
            //     this.props.history.push("/technical-break");
            // }
            // );
        }
    }

    render() {
        let { isDisabled, companies } = this.state;
        let { isAuthed, t } = this.props;

        if (isAuthed) {
            return <Redirect to='/dashboard/home' />
        }

        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <div className="registration-companies-container registration-container">
                    <div className="registration-companies-container-header">
                        <h2 className="registration-container-title">{t('choose a company')}</h2>
                        <p className="registration-container-description">{t('please select the company you want to login')}</p>
                    </div>
                    <div className="registration-companies-container-content">
                        <ul className="registration-companies-container-list">
                            {
                                companies.map(comp => <CompanyListItem key={comp.token} companyId={comp.id} token={comp.token} companyName={comp.name} companyTaxNo={comp.taxNo} updateCheckedCompany={this.updateCheckedCompany} />)

                                // companies.map( comp => <CompanyListItem key={comp.id} companyId={comp.id} companyName={comp.name} companyTaxNo={comp.taxNo} updateCheckedCompany={this.updateCheckedCompany} compIdResponse={compIdResponse}/>)
                            }
                        </ul>
                        <div className="registration-container-validation-feedback"></div>
                    </div>
                    <div className="registration-companies-container-footer registration-container-footer">
                        <button className="registration-container-footer-button registration-container-footer-button--left" onClick={this.prevPage}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" onClick={this.registerJuridicalAsan}>{t('next')}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(AsanJuridicalLoginSelectCompany);