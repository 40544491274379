import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from './../../../../../config';
import paperPlaneIcon from './../../../img/paper-plane.svg';
import categoryFilter from "./../../../../../helpers/categoryFilter";
import Loading from '../../../Loading/Loading';

class NewTemplate extends Component {
    state = {
        payments: [],
        categories: [],
        merchants: [],
        isLoading: false
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
        await this.getCategoryList();
        if (this.props.match.params.id) {
            await this.getMerchantList();
        }
        this.setState({ isLoading: false });
    }


    async getCategoryList() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCategoryListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        await fetch(request("pg/getCategoryList" + customerType + 'v1', getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCategoryListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCategoryListCustomerResponse = JSON.parse(getCategoryListCustomerResponseJSON);
                if (getCategoryListCustomerResponse.status.statusCode !== 1) {
                    if (getCategoryListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    this.setState({ categories: getCategoryListCustomerResponse.categoryList })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    async getMerchantList() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getMerchantListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,

            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,

            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        await fetch(request("pg/getMerchantList" + customerType, getMerchantListCustomerRequestBody))
            .then(res => res.text())
            .then((getMerchantListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getMerchantListCustomerResponse = JSON.parse(getMerchantListCustomerResponseJSON);
                if (getMerchantListCustomerResponse.status.statusCode !== 1) {
                    if (getMerchantListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ merchants: getMerchantListCustomerResponse.merchantList })
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    componentDidUpdate(prevPros, prevState) {
        if (prevPros.match.params.id !== this.props.match.params.id) {
            this.getMerchantList();
        }
    }
    render() {
        let { t } = this.props;
        let { payments, categories, merchants, isLoading } = this.state;
        let merchantId = this.props.match.params.id;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Create a new template')}</h2>
                    </div>
                </div>
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            <div className="dashboard-templates-providers-section" style={{ width: '100%' }}>
                                <div className="dashboard-templates-providers-section-header">
                                    <h3 className="dashboard-templates-providers-section-header-title">{t('Creating a template for a new service')}</h3>
                                </div>
                                <div className="dashboard-templates-providers-section-content">
                                    {
                                        merchantId ?
                                            <div className="dashboard-templates-providers-section-content-merchant">
                                                <ul className="dashboard-payment-section-categorys-alts">
                                                    {
                                                        merchants.map((merchant, index) => (
                                                            <li key={index}>
                                                                <Link to={`/dashboard/payment/templates/new-template/categories/${this.props.match.params.id}/providers/${merchant.merchantId}`}>
                                                                    <img src={merchant.img} />
                                                                    <p className="dashboard-payment-section-content-title">{merchant.displayName}</p>
                                                                </Link>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            :
                                            <div className="dashboard-templates-providers-section-content-provider p-0">
                                                {
                                                    // window.screen.width > 762 ? categories.map(category => (
                                                    //     <Link to={"/dashboard/payment/templates/new-template/categories/" + category.categoryId} style={{ maxWidth: '150px' }} className="dashboard-templates-providers-section-content-provider-button" key={category.categoryId}>
                                                    //         <img src={categoryFilter(category.categoryName)} className="dashboard-templates-providers-section-content-provider-icon" />
                                                    //         <p className="dashboard-templates-providers-section-content-provider-title">{category.displayName}</p>
                                                    //     </Link>
                                                    // ))
                                                    //     :
                                                    <ul className="dashboard-payment-section-categorys-cards">
                                                        {
                                                            categories.map(category => (
                                                                // <li class="d-flex align-items-center flex-column">
                                                                //     <Link to={`/dashboard/payment/payments/categories/${category.categoryId}`}>
                                                                //         <img src={categoryFilter(category.categoryName)} className="dashboard-payment-section-content-icon" />
                                                                //         <p className="dashboard-payment-section-content-title">{category.displayName}</p>
                                                                //     </Link>
                                                                // </li>
                                                                <li className='d-flex align-items-center flex-column'>
                                                                    <NavLink to={`/dashboard/payment/payments/categories/${category.categoryId}`}>
                                                                        <img src={categoryFilter(category.categoryName)} className="dashboard-payment-section-content-icon" />
                                                                    </NavLink>
                                                                    <p className="dashboard-payment-section-content-title">{category.displayName}</p>
                                                                </li>
                                                            ))


                                                        }
                                                    </ul>
                                                }

                                            </div>



                                    }
                                </div>
                            </div>
                    }
                </div>
            </Fragment>
        )
    }
}
export default translate("translation")(NewTemplate);