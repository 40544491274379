import transferInternalIcon from './img/transfer-internal-icon.svg';
import transferInlandIcon from './img/transfer-inland-icon.svg';
import transferAbroadIcon from './img/transfer-abroad-icon.svg';
import transferExchangeIcon from './img/transfer-exchange-icon.svg';
import transferIpsIcon from './img/transfer-ips-icon.svg';
import transferBetweenIcon from './img/transfer-between-icon.svg';

const lists = [
    {
        operationNameId: 1,
        operationIcon: transferInternalIcon
    },
    {
        operationNameId: 2,
        operationIcon: transferInlandIcon
    },
    {
        operationNameId: 3,
        operationIcon: transferAbroadIcon
    },
    {
        operationNameId: 4,
        operationIcon: transferExchangeIcon
    },
    {
        operationNameId: 5,
        operationIcon: transferIpsIcon
    },
    {
        operationNameId: 15,
        operationIcon: transferBetweenIcon
    }
]

function operationFilter(id) {
    return lists.find(elem => elem.operationNameId === id)?lists.find(elem => elem.operationNameId === id).operationIcon : "";
}

export default operationFilter;