const lists = [
  {
    type: "AZN",
    value: "₼",
  },
  {
    type: "EUR",
    value: "€",
  },
  {
    type: "USD",
    value: "$",
  },
  {
    type: "RUB",
    value: "₽",
  },
  {
    type: "azn",
    value: "AZN",
  },
];

function currencyFilter(currency) {
  return lists.find((elem) => elem.type === currency)
    ? lists.find((elem) => elem.type === currency).value
    : currency;
}

export default currencyFilter;
