import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import eyeIcon from './../../img/eye.svg';
// import eyeSlashIcon from './../../img/eye-slash.svg';
import pen from './../../img/pen.svg';
import rightArrow from './../../img/right-arrow.svg';

import checkCircleDisable from './../../img/check-circle-disable.svg';
import checkCircleSuccess from './../../img/check-circle-success.svg';
import checkCircleError from './../../img/check-circle-error.svg';
import { request } from '../../../../config';
import { translate } from 'react-i18next';

class Security extends Component {
    state = {
        password: '',
        devices: [],
        currentDevice: '',

        successMessage: null,
        feedbackMessage: null,
    }


    componentDidMount() {
        this.props.updateAppState('isLoadingTransparentBackground', false);
        this.props.updateSettingsState('settingsTitle', this.props.t('security'));
        this.props.updateSettingsState('feedback', this.props.t('security info'));



    }

    render() {
        const { devices } = this.state;
        const { t } = this.props;

        // const currentDevice = devices.find(device => device.currentDevice);
        const currentDevice = localStorage.getItem('device');


        return (
            <form className="dashboard-settings-section-form">
                {/* <div className="dashboard-settings-section-password"> */}
                {/* <div className="dashboard-settings-section-password-change-section"> */}
                <NavLink exact to="/dashboard/settings/security/change-password" activeClassName="dashboard-settings-section-navbar-section-item--active" className="dashboard-settings-section-navbar-section-item p-0">
                    <div className="dashboard-settings-section-form-group" >
                        <label className="dashboard-settings-section-form-group-label">{t('password')}</label>
                        <div className={"dashboard-settings-section-form-group-box"}>
                            <input type="password" readOnly defaultValue="***************" className="dashboard-settings-section-form-group-box-input" placeholder={t('enter the old password')} />
                            <div className="dashboard-settings-section-form-group-box-icon" >
                                <img src={pen} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password-slash" alt="" />
                            </div>
                        </div>
                    </div>
                </NavLink>
                {/* <div className="dashboard-settings-section-general-form-container">
                            <div className="dashboard-settings-section-general-form-group">
                                <div className="dashboard-settings-section-general-form-group-feedback">
                                    <p className="dashboard-settings-section-general-form-group-feedback-message dashboard-settings-section-general-form-group-feedback-message__info" style={{ position: 'static', height: 'auto' }}>{t('If you change the password, an information message will be sent to your mobile number.')}</p>
                                </div>
                            </div>
                        </div> */}
                {/* </div> */}
                {/* <div className="dashboard-settings-section-password-detail-section">
                        <div className="dashboard-settings-section-password-detail-section-item">
                            {
                                (symbolLength === null) ? <img src={checkCircleDisable} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (symbolLength === false) ? <img src={checkCircleError} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (symbolLength === true) ? <img src={checkCircleSuccess} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            <span className="dashboard-settings-section-password-detail-section-content">{t('must be at least 6 characters long')}</span>
                        </div>
                        <div className="dashboard-settings-section-password-detail-section-item">
                            {
                                (letterSymbol === null) ? <img src={checkCircleDisable} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (letterSymbol === false) ? <img src={checkCircleError} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (letterSymbol === true) ? <img src={checkCircleSuccess} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            <span className="dashboard-settings-section-password-detail-section-content">{t('must be at least one letter')}</span>
                        </div>
                        <div className="dashboard-settings-section-password-detail-section-item">
                            {
                                (numericSymbol === null) ? <img src={checkCircleDisable} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (numericSymbol === false) ? <img src={checkCircleError} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (numericSymbol === true) ? <img src={checkCircleSuccess} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            <span className="dashboard-settings-section-password-detail-section-content">{t('must be at least one digit')}</span>
                        </div>
                    </div> */}
                {/* </div> */}
                {/* {buttonVisibility && (
                    <div className="dashboard-settings-section-form-button-section">
                        <button className="dashboard-settings-section-form-button dashboard-settings-section-form-button__save" disabled={isDisabled} onClick={this.changePassword} >{t('save')}</button>
                        <Link to="/dashboard" className="dashboard-settings-section-form-button dashboard-settings-section-form-button__cancel" onClick={this.handleButtonClick}>{t('refusal')}</Link>
                    </div>
                )} */}

                {currentDevice && (
                    <NavLink exact
                        to={{
                            pathname: "/dashboard/settings/security/devices",
                            state: { devices: this.state.devices } 
                        }}
                        activeClassName="dashboard-settings-section-navbar-section-item--active"
                        className="dashboard-settings-section-navbar-section-item p-0"
                    >
                        <div className="dashboard-settings-section-form-group">
                            <label className="dashboard-settings-section-form-group-label">{t('devices')}</label>
                            <div className="dashboard-settings-section-form-group-box">
                                <input
                                    readOnly
                                    className="dashboard-settings-section-form-group-box-input"
                                    placeholder={t('devices')}
                                    defaultValue={currentDevice}
                                />
                                <div className="dashboard-settings-section-form-group-box-icon">
                                    <img src={rightArrow} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password-slash" alt=""/>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                )}
                {/* {deviceVisibility && (
                    <>
                        <div className="dashboard-settings-section-form-group" >
                            <label className="dashboard-settings-section-form-group-label">{t('current devices')}</label>
                            <div className={"dashboard-settings-section-form-group-box " + (prevPasswordOutline ? "input-outline" : "")}>
                                <input readonly type="text" className="dashboard-settings-section-form-group-box-input" placeholder={t('devices')} onChange={this.checkPrevPasswordNotEmpty} onBlur={this.setPrevPasswordOutline} />
                                <div className="dashboard-settings-section-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isPrevPasswordVisibility')} >

                                </div>
                            </div>
                        </div>
                        <div className="dashboard-settings-section-form-group">
                            <label className="dashboard-settings-section-form-group-label">{t('current devices')}</label>
                            <div className={"dashboard-settings-section-form-group-box " + (!newPasswordOutline ? "" : !(numericSymbol && letterSymbol && symbolLength) ? "input-outline" : "")}>
                                <input readonly type="text" className="dashboard-settings-section-form-group-box-input" placeholder={t('devices')} onChange={this.checkValidationNewPassword} onBlur={this.setNewPasswordOutline} />
                                <div className="dashboard-settings-section-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isNewPasswordVisibility')}>
                                </div>
                            </div>
                        </div>
                    </>
                )} */}

            </form>
        )
    }
}



export default translate("translation")(Security);