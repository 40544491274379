import React from 'react';
import { translate } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'inline-block',
    maxHeight: '100vh',
    overflow: 'auto'
  },
}));

function TransferModal(props) {
  const classes = useStyles();
  const close = () => {
    props.updateOpenModal(false);
    if (props.feedbackErrorModal) {
      props.errorClose();
    }
    else {
      props.successClose();
    }
  }
  const backListTransfers = () => {
    if (props.exchange) {
      props.history.push('/dashboard/transfer/history/exchange');
    }
    else {
      props.history.push('/dashboard/transfer/history/transfers');
    }
  }
  return (
    <Modal
      open={props.open}
      onClose={close}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      classes={classes.modal}
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description">
      <Fade in={props.open}>
        <div className={classes.paper}>
          {
            (props.feedbackErrorModal === null) ?
              <div className="create-transfer-modal">
                <button type="button" className="create-transfer-modal-close-button" onClick={() => { close() }}>
                  <svg className="dashboard-change-account-name-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                  </svg>
                </button>
                <div className="create-transfer-modal-content">
                  <div className={"create-transfer-modal-content-header"}>
                    <svg className="create-transfer-modal-content-header-icon" width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M76 152C117.974 152 152 117.974 152 76C152 34.0264 117.974 0 76 0C34.0264 0 0 34.0264 0 76C0 117.974 34.0264 152 76 152Z" fill="#F2F8FD" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M76.302 132.095C107.116 132.095 132.096 107.116 132.096 76.3016C132.096 45.4876 107.116 20.5079 76.302 20.5079C45.488 20.5079 20.5083 45.4876 20.5083 76.3016C20.5083 107.116 45.488 132.095 76.302 132.095Z" fill="#0179D2" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M68.4566 92.9029L53.166 77.6124C52.2474 76.6938 52.2474 75.2043 53.166 74.2856L56.4927 70.9588C57.4114 70.0401 58.9009 70.0401 59.8195 70.9588L70.1199 81.2592L92.1822 59.1969C93.1008 58.2783 94.5904 58.2783 95.509 59.1969L98.8357 62.5237C99.7543 63.4423 99.7543 64.9318 98.8357 65.8505L71.7833 92.903C70.8645 93.8217 69.3751 93.8217 68.4566 92.9029Z" fill="white" />
                    </svg>
                    <h3 className="create-transfer-modal-content-header-title">{props.t("The payment order was updated successfully")}</h3>
                    {
                      localStorage.getItem('userId') ?
                        <p className="create-transfer-modal-content-header-subtitle">{props.t("You will be required to sign with ASAN İmza to complete the transaction successfully")}</p>
                        :
                        <p className="create-transfer-modal-content-header-subtitle">{props.t("To successfully complete the transaction, you are required to log in with ASAN İmza and sign")}</p>
                    }
                  </div>
                </div>
                <div className="create-transfer-modal-footer">
                  <button className={"create-transfer-modal-footer-button create-transfer-modal-footer-button--success"} onClick={() => { backListTransfers() }}>{props.t('history')}</button>
                </div>
              </div>
              :
              <div className="create-transfer-modal">
                <button type="button" className="create-transfer-modal-close-button" onClick={() => { close() }}>
                  <svg className="dashboard-change-account-name-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                  </svg>
                </button>
                <div className="create-transfer-modal-content">
                  <div className={"create-transfer-modal-content-header"}>
                    <svg className="create-transfer-modal-content-header-icon" width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M76 152C117.974 152 152 117.974 152 76C152 34.0264 117.974 0 76 0C34.0264 0 0 34.0264 0 76C0 117.974 34.0264 152 76 152Z" fill="#FA312C" fillOpacity="0.05" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M76.3015 132.095C107.115 132.095 132.095 107.116 132.095 76.3016C132.095 45.4876 107.115 20.5079 76.3015 20.5079C45.4875 20.5079 20.5078 45.4876 20.5078 76.3016C20.5078 107.116 45.4875 132.095 76.3015 132.095Z" fill="#FA312C" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M83.8627 76.3958L95.0623 87.5954C96.4366 88.9708 96.4366 91.199 95.0623 92.5734L92.5732 95.0624C91.1988 96.4368 88.9695 96.4368 87.5952 95.0624L76.3956 83.8629L65.1961 95.0624C63.8218 96.4368 61.5924 96.4368 60.2181 95.0624L57.729 92.5734C56.3546 91.1979 56.3546 88.9696 57.729 87.5954L68.9286 76.3958L57.729 65.1963C56.3546 63.8208 56.3546 61.5926 57.729 60.2182L60.2181 57.7292C61.5935 56.3548 63.8218 56.3548 65.1961 57.7292L76.3956 68.9287L87.5952 57.7292C88.9707 56.3548 91.1988 56.3548 92.5732 57.7292L95.0623 60.2182C96.4366 61.5937 96.4366 63.822 95.0623 65.1963L83.8627 76.3958Z" fill="white" />
                    </svg>
                    <h3 className="create-transfer-modal-content-header-title">{props.t("An error occurred during the operation.")}</h3>
                    <p className="create-transfer-modal-content-header-subtitle">{props.feedbackErrorModal}</p>
                  </div>
                </div>
                <div className="create-transfer-modal-footer">
                  <button className={"create-transfer-modal-footer-button create-transfer-modal-footer-button--error"} onClick={() => { close() }}>{props.t('continue')}</button>
                </div>
              </div>
          }
        </div>
      </Fade>
    </Modal>
  );
}
export default translate("translation")(TransferModal);
