import React, { useState, useEffect } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { translate } from 'react-i18next';
import chevronDown from "../components/Login/img/chevron-down.svg";
const isBrowser = typeof window !== 'undefined';
const DropdownMenu = (props) => {
    const [active, setActive] = useState(null);
    const [lang, setLang] = useState(isBrowser ? localStorage.getItem("i18nextLng") : "" );
    useEffect(() => {
        if(lang == "") {
            setLang(localStorage.getItem("i18nextLng")) 
        }
    },[lang])
    const changeLanguageFunc = (val) => {
        props.i18n.changeLanguage(val);
        setLang(val);
        setActive(null);
        window.location.reload();
    }

    return (
        <div className={"nav-right-side-language nav-right-side-language-" + props.theme }>
            <button style={{ outline: 'none' }} className="nav-right-side-language" aria-controls="fade-menu" aria-haspopup="true" onClick={(e) => setActive(e.currentTarget)}>
                <span className="nav-right-side-language-title">{lang === 'az' && 'AZ'}{lang === 'ru' && 'RU'}{lang === 'en' && 'EN'}</span>
                <svg  className="nav-right-side-language-dropdown-icon" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill={props.color || "white"}/>
                </svg>
            </button>
            <Menu
                anchorEl={active}
                id="simple-menu"
                
                open={Boolean(active)}
                onClose={() => setActive(false)}
            >
                <MenuItem onClick={() => changeLanguageFunc("az")}>AZ</MenuItem>
                <MenuItem onClick={() => changeLanguageFunc("en")}>EN</MenuItem>
                <MenuItem onClick={() => changeLanguageFunc("ru")}>RU</MenuItem>
            </Menu>

        </div>
    );
}

export default translate("translation")(DropdownMenu);