import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { request } from "./../../../../config";
import AsanConfirmationIcon from "./../../img/asan-confirmation-icon.svg";
import headerLogo from "./../../img/header-logo.svg";
import { translate } from 'react-i18next';

class AsanConfirmation extends Component {
    state = {
        verificationCode: null,
        feedbackMessage: null
    }

    backCheckCustomer = () => {
        // this.props.history.push('/registration/individual/asan/1');
        window.location.href= '/registration/individual/asan/1';
    }

    registerWithAsan = () => {
        let registerWithAsanRequestBody = {
            
            sessionId: this.props.location.state.sessionId,
            token: this.props.location.state.token
            // pin: this.props.location.state.pin,
            // custCode: this.props.location.state.custCode,
            // challenge: this.props.location.state.challenge
        };
        fetch(request("register/registerWithAsanv1", registerWithAsanRequestBody))
        .then(res => res.text())
        .then((registerWithAsanResponseJSON) => {
            const JSON = require('true-json-bigint');
            let registerWithAsanResponse = JSON.parse(registerWithAsanResponseJSON);
            if(registerWithAsanResponse.status.statusCode === 1) {
                // console.log(registerWithAsanResponseJSON)
                // let custId = ""; 
                // if(typeof( registerWithAsanResponse.custId) === 'object' && registerWithAsanResponse.custId.hasOwnProperty('c')) {
                //     registerWithAsanResponse.custId.c.forEach(part => {
                //         custId += part;
                //     });
                // }
                // else {
                //     custId += registerWithAsanResponse.custId;
                // }
                this.props.history.push('/registration/individual/asan/3', {token: registerWithAsanResponse.token});
            }
            else {
                // console.log(registerWithAsanResponseJSON)
                this.props.history.push('/registration/individual/asan/1', {feedbackMessage: this.props.t(registerWithAsanResponse.status.statusCode)});
            }
        },
        (error) => {
            this.props.history.push("/technical-break");
        });
    }

    componentDidMount = () => {
        if(this.props.location.state === undefined) {
            this.props.history.push('/registration/individual/asan/1');
            // window.location.href= '/registration/individual/asan/1';
            // this.registerWithAsan();

        }
        else {
            this.registerWithAsan();
            this.setState({verificationCode: this.props.location.state.verificationCode});
        }
    }

    render() {
        let { verificationCode, feedbackMessage } = this.state;
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title">{t('asan signature confirmation')}</h2>
                    <p className="registration-container-description">{t('please accept the request sent to your phone')}</p>
                    <img src={AsanConfirmationIcon} className="registration-container-icon" alt="AsanConfirmationIcon" />
                    <div className="registration-container-asan-confirmation">
                        <span className="registration-container-asan-confirmation-code">{verificationCode}</span>
                    </div>
                    <div className="registration-container-validation-feedback">
                        {
                            feedbackMessage ? <p className="registration-container-validation-feedback-message">{feedbackMessage}</p> : null
                        }
                    </div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backCheckCustomer}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" disabled={false}>{t('next')}</button>
                    </div>
                </form>
                <div className="registration-range">
                    <p className="registration-range-fraction">
                        <span className="registration-range-fraction-numerator">1</span>/3
                    </p>
                    <div className="registration-range-container">
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                            <div className="registration-range-container-step-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(AsanConfirmation);