import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import { request } from '../../../config';
import AlertPopup from '../../../copms/AlertPopup/AlertPopup';
import {checkEmailFormat} from '../../../helpers/regExs';

class OrderStatement extends Component {
    state = {
        open: false,
        popupType: null,
        title: null,
        feedbackMessage: null,

        orderTypeId: 1,
        orderReceivingTypeId: this.props.match.params.type == "card" ? 1 : (this.props.match.params.type == "account" ? 2 : this.state.orderReceivingTypeId),

        cards: [],
        selectedCardCount: 0,
        selectedCardIndex: -1,
        dropdownVisibilityCard: false,
        cardData: false,

        langs: ["AZ", "EN"],
        selectedLangCount:0,
        selectedLangIndex:-1,
        dropdownVisibilityLang:false,

        accounts: [],
        selectedAccountCount: 0,
        selectedAccountIndex: -1,
        dropdownVisibilityAccount: false,
        accountData: false,

        email: "",
        emailOutline: false
    }

    resetForm = () => {
        this.setState({
            open: false,
            popupType: null,
            title: null,
            feedbackMessage: null,

            orderTypeId: 1,
            orderReceivingTypeId: this.props.match.params.type == "card" ? 1 : (this.props.match.params.type == "account" ? 2 : this.state.orderReceivingTypeId),

            selectedCardCount: 0,
            selectedCardIndex: -1,
            dropdownVisibilityCard: false,

            selectedAccountCount: 0,
            selectedAccountIndex: -1,
            dropdownVisibilityAccount: false,

            selectedLangCount:0,
            selectedLangIndex:-1,
            dropdownVisibilityLang:false,

            email: "",
            emailOutline: false
        });
    }

    showCards = async () => {
        let getCardListCustomerRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (localStorage.getItem('compId')) {
            getCardListCustomerRequestBody.compId = localStorage.getItem('compId');
        }
        return await fetch(request("card/getCardListFor" + (localStorage.getItem('compId') ? "Juridical" : "Individual") + "Customer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode == 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) == 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16) + " / " + getCardListForCustomerResponse.cardList[i].balance + " " + getCardListForCustomerResponse.cardList[i].currency;

                    }

                    this.setState({ cards: getCardListForCustomerResponse.cardList, cardData: true });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showAccounts = async () => {
        let getAccountListForCustomerRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (localStorage.getItem('compId')) {
            getAccountListForCustomerRequestBody.compId = localStorage.getItem('compId');
        }
        return await fetch(request("account/getAccountListFor" + (localStorage.getItem('compId') ? "Juridical" : "Individual") + "Customer", getAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                if (getAccountListForCustomerResponse.status.statusCode !== 1 && getAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getAccountListForCustomerResponse.status.statusCode === 126) {
                        getAccountListForCustomerResponse.accountList = [];
                    }
                    for (let i = 0; i < getAccountListForCustomerResponse.accountList.length; i++) {
                        // =========ID=========
                        let accountId = "";
                        if (typeof (getAccountListForCustomerResponse.accountList[i].accountId) == 'object' && getAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========NUMBER=========
                        getAccountListForCustomerResponse.accountList[i].organizedIban = getAccountListForCustomerResponse.accountList[i].iban + " / " + getAccountListForCustomerResponse.accountList[i].currentBalance + " " + getAccountListForCustomerResponse.accountList[i].currName;

                    }

                    this.setState({ accounts: getAccountListForCustomerResponse.accountList, accountData: true });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }


    updateCard(index) {
        this.setState({ selectedCardIndex: index, dropdownVisibilityCard: false });
    }

    updateLang(index) {
        this.setState({ selectedLangIndex: index, dropdownVisibilityLang: false });
    }

    updateAccount(index) {
        this.setState({ selectedAccountIndex: index, dropdownVisibilityAccount: false });
    }

    changeDropdownVisibilityCard = () => {
        this.setState({ dropdownVisibilityCard: !this.state.dropdownVisibilityCard }, () => {
            if (this.state.selectedCardIndex < 0) {
                this.setState({ selectedCardCount: ++this.state.selectedCardCount });
            }
        });
    }

    changeDropdownVisibilityLang = () => {
        this.setState({ dropdownVisibilityLang: !this.state.dropdownVisibilityLang }, () => {
            if (this.state.selectedLangIndex < 0) {
                this.setState({ selectedLangCount: ++this.state.selectedLangCount });
            }
        });
    }

    changeDropdownVisibilityAccount = () => {
        this.setState({ dropdownVisibilityAccount: !this.state.dropdownVisibilityAccount }, () => {
            if (this.state.selectedAccountIndex < 0) {
                this.setState({ selectedAccountCount: ++this.state.selectedAccountCount });
            }
        });
    }

    checkEmail = (event) => {
        this.setState({ email: event.target.value }, () => {
            if (this.state.email.length > 0) {
                this.setState({ emailOutline: false });
            }
        });
    }

    setEmailOutline = () => {
        if (this.state.email.length === 0) {
            this.setState({ emailOutline: true });
        }
        else {
            this.setState({ emailOutline: false });
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let createCustomerOrderRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
             // lang: localStorage.getItem('i18nextLng').toUpperCase(),
             lang: this.state.langs[this.state.selectedLangIndex],
            orderReceivingTypeId: this.state.orderReceivingTypeId,
            orderCardId: this.state.cards[this.state.selectedCardIndex]?.cardId,
            orderAccId: this.state.accounts[this.state.selectedAccountIndex]?.accountId,
            orderTypeId: this.state.orderTypeId,
            email: this.state.email
        };
        if (localStorage.getItem('compId')) {
            createCustomerOrderRequestBody.compId = localStorage.getItem('compId');
        }
        fetch(request("order/createCustomerOrder", createCustomerOrderRequestBody))
            .then(res => res.text())
            .then((createCustomerOrderResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createCustomerOrderResponse = JSON.parse(createCustomerOrderResponseJSON);
                if (createCustomerOrderResponse.status.statusCode !== 1) {
                    if (createCustomerOrderResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({
                            open: true,
                            popupType: 'error',
                            title: this.props.t('an error occurred'),
                            feedbackMessage: this.props.t(createCustomerOrderResponse.status.statusCode)
                        });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({
                        open: true,
                        popupType: 'success',
                        title: this.props.t('successful operation'),
                        feedbackMessage: this.props.t('You will be received an e-mail when your order is ready')
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    closeSuccessFunction = () => {
        this.props.history.push(`/dashboard/services/account-statement-requisites/${this.state.orderReceivingTypeId == 1 ? 'card' : this.state.orderReceivingTypeId == 2 ? 'account' : '' }`);
    }

    componentDidUpdate(previousProps) {
        if (previousProps.match.params.type !== this.props.match.params.type) {
            this.resetForm();
            this.setState({ orderReceivingTypeId: this.props.match.params.type == "card" ? 1 : (this.props.match.params.type == "account" ? 2 : this.state.orderReceivingTypeId) });
        }
    }

    componentDidMount = async () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        await this.showAccounts();
        await this.showCards();
        this.props.updateAppState('isLoadingTransparentBackground', false);
    }

    render() {
        let { open, popupType, title, feedbackMessage,
            cards, cardData, selectedCardCount, selectedCardIndex, dropdownVisibilityCard,
            accounts, selectedAccountCount, selectedAccountIndex, dropdownVisibilityAccount, accountData, orderReceivingTypeId,
            email, emailOutline, langs, selectedLangCount, selectedLangIndex, dropdownVisibilityLang } = this.state;
        let { t } = this.props;

        // console.log("test");
        return (
            <Fragment>
                <AlertPopup open={open} closeFunction={() => this.setState({ open: false })} popupType={popupType} title={title} feedbackMessage={feedbackMessage} closeSuccessFunction={this.closeSuccessFunction} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>
                            {orderReceivingTypeId == 1 && t('By card details')}
                            {orderReceivingTypeId == 2 && t('By current account details')}
                        </h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section dashboard-payment-container-data-section--empty">
                        <div style={{ width: '100%', marginBottom: '22px' }}>
                            <div className="dashboard-transfer-list-header">
                                <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                    <NavLink exact to="/dashboard/services/account-statement-requisites/order-requisite/card" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("By card")}</NavLink>
                                    <NavLink exact to="/dashboard/services/account-statement-requisites/order-requisite/account" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("By current account")}</NavLink>
                                </div>
                            </div>
                            <form className="dashboard-payment-container-data-section-content dashboard-payment-container-data-section-content--empty">
                                <div style={{ paddingTop: 0 }} className="dashboard-payment-container-data-section-content-container">
                                    <div className="dashboard-payment-container-data-section-content-container-form">
                                        <div className="dashboard-payment-container-data-section-content-container-form-container">
                                            {
                                                orderReceivingTypeId == 1 &&
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select card')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCard: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedCardCount > 1 && selectedCardIndex < 0 || selectedCardCount == 1 && selectedCardIndex < 0 && !dropdownVisibilityCard ? "input-outline" : "")}>
                                                            <button type='button' className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityCard}>
                                                                {
                                                                    selectedCardIndex >= 0 ?
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{cards[selectedCardIndex].organizedCardNumber}</div>
                                                                        :
                                                                        (cards && cards.length == 0 && cardData) ?
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{t('137')}</div>
                                                                            :
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                }
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(dropdownVisibilityCard == false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    cards && cards.length > 0 &&
                                                                    cards.map((card, index) => {
                                                                        return (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type='button' onClick={this.updateCard.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" disabled={!Boolean(card.cardStatus)}>{card.organizedCardNumber}</button>
                                                                            </li>
                                                                        );
                                                                    })
                                                                }

                                                            </ul>
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                            }
                                            {
                                                orderReceivingTypeId == 2 &&
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select account')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityAccount: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedAccountCount > 1 && selectedAccountIndex < 0 || selectedAccountCount == 1 && selectedAccountIndex < 0 && !dropdownVisibilityAccount ? "input-outline" : "")}>
                                                            <button type='button' className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityAccount}>
                                                                {
                                                                    selectedAccountIndex >= 0 ?
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{accounts[selectedAccountIndex].organizedIban}</div>
                                                                        :
                                                                        (accounts && accounts.length == 0 && accountData) ?
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{t('126')}</div>
                                                                            :
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                }
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(dropdownVisibilityAccount == false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    accounts && accounts.length > 0 &&
                                                                    accounts.map((account, index) => {
                                                                        return (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type='button' onClick={this.updateAccount.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{account.organizedIban}</button>
                                                                            </li>
                                                                        );
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                            }
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('choose extract lang')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityLang: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedLangCount > 1 && selectedLangIndex < 0 || selectedLangCount == 1 && selectedLangIndex < 0 && !dropdownVisibilityLang ? "input-outline" : "")}>
                                                        <button type='button' className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityLang}>
                                                                {
                                                                    selectedLangIndex >= 0 ?
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{langs[selectedLangIndex]}</div>
                                                                        :
                                                                        (langs && langs.length == 0) ?
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{t('137')}</div>
                                                                            :
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("choose lang")}</div>
                                                                }
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(dropdownVisibilityLang == false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    langs && langs.length > 0 &&
                                                                    langs.map((lang, index) => {
                                                                        return (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type='button' onClick={this.updateLang.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{lang}</button>
                                                                            </li>
                                                                        );
                                                                    })
                                                                }

                                                            </ul>
                                                        </div>
                                                    </OutsideClickHandler>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Send to my email address')} <span style={{ color: 'red' }}>*</span></label>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (emailOutline ? "input-outline" : "")}>
                                                    <input type="email" name='email' placeholder={t('email placeholder')} value={email} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkEmail} onBlur={this.setEmailOutline} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-container">
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                            {
                                                (orderReceivingTypeId == 1 ? selectedCardIndex >= 0 : (orderReceivingTypeId == 2 && selectedAccountIndex >= 0)) && checkEmailFormat(email) && selectedLangIndex >= 0?
                                                    <button onClick={this.submitForm} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                    :
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                            }
                                            <button type="button" onClick={this.resetForm} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default translate("translation")(OrderStatement);