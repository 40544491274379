import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from './../../../config';
import lastOperationsIcon from './../img/last-operations-icon.png';
import operationFilter from './../../../helpers/operationFilter';

class Statements extends Component {
    state = {
        operations: []
    }
    getFormatedDate = (date) => {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    showStates = () => {
        let now = new Date();
        let threeDaysBefore = new Date();
        threeDaysBefore.setDate(now.getDate() - 2);
        let startDate = this.getFormatedDate(threeDaysBefore);
        let endDate = this.getFormatedDate(now);
        if(this.props.location.pathname !== '/dashboard/home') {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let operStateId;
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        switch (this.props.location.pathname) {
            case '/dashboard/home': operStateId = null; break;
            case '/dashboard/home/confirmed': operStateId = 4; break;
            case '/dashboard/home/waiting': operStateId = 3; break;
            case '/dashboard/home/first-authorization': operStateId = 1; break;
            case '/dashboard/home/second-authorization': operStateId = 2; break;
            case '/dashboard/home/refused': operStateId = 5; break;
        }
        let operationAdvancedSearchRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operStateId: operStateId,
            dtIban: null,
            minAmt: null,
            maxAmt: null,
            startDate: startDate,
            endDate: endDate,
            operNameId: null
        } :
            {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operStateId: operStateId,
                dtIban: null,
                minAmt: null,
                maxAmt: null,
                startDate: startDate,
                endDate: endDate,
                operNameId: null
            }

        fetch(request("operation/operationAdvancedSearch", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((operationAdvancedSearchResponseJSON) => {
                const JSON = require('true-json-bigint');
                let operationAdvancedSearchResponse = JSON.parse(operationAdvancedSearchResponseJSON);
                if (operationAdvancedSearchResponse.status.statusCode !== 1 && operationAdvancedSearchResponse.status.statusCode !== 240) {
                    if (operationAdvancedSearchResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(operationAdvancedSearchResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (operationAdvancedSearchResponse.status.statusCode === 240) {
                        operationAdvancedSearchResponse.operationList = [];
                    }

                    for (let i = 0; i < operationAdvancedSearchResponse.operationList.length; i++) {
                        let amount, currency;
                        if (operationAdvancedSearchResponse.operationList[i].operNameId !== 4) {
                            amount = operationAdvancedSearchResponse.operationList[i].amount;
                            currency = operationAdvancedSearchResponse.operationList[i].currency;
                        }
                        else {
                            if (operationAdvancedSearchResponse.operationList[i].operTypeId === 5) {
                                amount = operationAdvancedSearchResponse.operationList[i].crAmt;
                                currency = operationAdvancedSearchResponse.operationList[i].crCcy;
                            }
                            else {
                                if (operationAdvancedSearchResponse.operationList[i].operTypeId === 6) {
                                    amount = operationAdvancedSearchResponse.operationList[i].dtAmt;
                                    currency = operationAdvancedSearchResponse.operationList[i].dtCcy;
                                }
                                else {
                                    amount = 0;
                                    currency = operationAdvancedSearchResponse.operationList[i].amtCcy;
                                }
                            }
                        }
                        operationAdvancedSearchResponse.operationList[i].currency = currency;
                        if (amount) {
                            if (amount.toString() === "0") {
                                operationAdvancedSearchResponse.operationList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (amount.toString().indexOf('.') !== -1) {
                                    let amountArray = amount.toString().split('.');
                                    operationAdvancedSearchResponse.operationList[i].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    operationAdvancedSearchResponse.operationList[i].organizedAmount = {
                                        wholePart: amount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                        } else {
                            operationAdvancedSearchResponse.operationList[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                    }
                    this.setState({ operations: operationAdvancedSearchResponse.operationList });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.showStates();
        }
    }
    componentDidMount() {
        this.showStates();
    }

    render() {
        let { t } = this.props;
        let { operations } = this.state;
        return (
            <>
                {
                    operations.length === 0 ?
                        <div className="dashboard-last-operations-section-content">
                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                            <ul className="dashboard-last-operations-section-content-invisible-list">
                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                    </div>
                                </li>
                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                    </div>
                                </li>
                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        :
                        <ul className="dashboard-transfer-history-content">
                            {
                                operations.slice(0, 8).map(operation => (
                                    <li className="dashboard-transfer-history-content-item" key={operation.operId}>
                                        <div className="dashboard-transfer-history-content-item-container__left-side">
                                            <div className="dashboard-transfer-history-content-item-container-icon-section">
                                                <img src={operationFilter(operation.operNameId)} className="dashboard-transfer-history-content-item-container-icon" />
                                            </div>
                                            <div className="dashboard-transfer-history-content-item-container-content">
                                                <p className="dashboard-transfer-history-content-item-container-name">{operation.operPurpose}, {operation.operName}</p>
                                                <p className="dashboard-transfer-history-content-item-container-iban">{operation.crIban}</p>
                                            </div>
                                        </div>
                                        <div className="dashboard-transfer-history-content-item-container__right-side">
                                            <div className="dashboard-transfer-history-content-item-container-content">
                                                <div className="dashboard-transfer-history-content-item-container-amount">
                                                    <span className="dashboard-transfer-history-content-item-container-amount-whole">{operation.organizedAmount && operation.organizedAmount.wholePart}</span>
                                                    <span className="dashboard-transfer-history-content-item-container-amount-fractional">{operation.organizedAmount && operation.organizedAmount.fractionalPart}</span>
                                                    <span className="dashboard-transfer-history-content-item-container-amount-currency">{operation.currency}</span>
                                                </div>
                                                <p className="dashboard-transfer-history-content-item-container-type">{operation.operState}</p>
                                            </div>
                                            {
                                                operation.operStateId === 1 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__confirmed"></div>
                                            }
                                            {
                                                operation.operStateId === 2 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__waiting"></div>
                                            }
                                            {
                                                operation.operStateId === 3 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__first-authorization"></div>
                                            }
                                            {
                                                operation.operStateId === 4 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__second-authorization"></div>
                                            }
                                            {
                                                operation.operStateId === 5 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__refused"></div>
                                            }
                                        </div>
                                    </li>
                                ))
                            }
                            {
                                operations && operations.length >0 &&
                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
                                    <Link to="/dashboard/transfer/transfers/all" className="dashboard-finance-section-invisible-cards-section-button">{t('List of transfers')}</Link>
                                </div>
                            }
                        </ul>
                }
            </>
        )
    }
}
export default translate("translation")(Statements);

