import React, { Component } from "react";
import { translate } from "react-i18next";
import { NavLink } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

import { request } from "../../../../config";
import AlertPopup from "../../../../copms/AlertPopup/AlertPopup";
import transferConfirmation from "./../../img/transfer-confirmation.svg";

const MAX_AMOUNT_LIMIT= '100000';
class Deposit extends Component {
  state = {
    step: 0,
    confirmedOperation: false,
    open: false,
    amount: "",
    amountOutline: false,
    hasAmountErr: false,

    products: [],
    selectedProductIndex: -1,
    dropdownVisibilityProducts: false,
    selectedProductIndexCount: 0,

    accounts: [],
    selectedAccountIndex: -1,
    dropdownVisibilityAccounts: false,
    selectedAccountIndexCount: 0,

    interestAccounts: [],
    selectedInterestAccountIndex: -1,
    dropdownVisibilityInterestAccounts: false,
    selectedInterestAccountIndexCount: 0,

    productInfoList: [],
    minTerm: null,
    maxTerm: null,

    selectedDepositIndex: -1,
    dropdownVisibilityDeposits: false,
    selectedDepositIndexCount: 0,

    periodFilter: "",
    selectedPeriodIndex: -1,
    dropdownVisibilityPeriods: false,
    selectedPeriodIndexCount: 0,

    percentAmount: null,
    totalAmount: null,
  };

  productList = async () => {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let getDepositProductNameRequestBody =
      customerType == "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
          };

    await fetch(
      request("deposit/getDepositProductName", getDepositProductNameRequestBody)
    )
      .then((res) => res.text())
      .then(
        (getDepositProductNameResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getDepositProductNameResponse = JSON.parse(
            getDepositProductNameResponseJSON
          );
          if (getDepositProductNameResponse.status.statusCode !== 1) {
            if (getDepositProductNameResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(getDepositProductNameResponse.status.statusCode)
              );
            }
          } else {
            this.setState({
              products: getDepositProductNameResponse.productName,
            });
            this.props.updateAppState("isLoadingTransparentBackground", false);
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  getAccountList = async () => {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let getAccountListRequestBody =
      customerType == "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
          };

    await fetch(request("deposit/getAccountList", getAccountListRequestBody))
      .then((res) => res.text())
      .then(
        (getAccountListResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getAccountListResponse = JSON.parse(getAccountListResponseJSON);
          if (getAccountListResponse.status.statusCode !== 1) {
            if (getAccountListResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(getAccountListResponse.status.statusCode)
              );
            }
          } else {
            this.setState({ accounts: getAccountListResponse.accountList });
            this.props.updateAppState("isLoadingTransparentBackground", false);
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  getAccountPerList = async () => {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let getAccountListRequestBody =
      customerType == "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
            branchId:
              this.state.accounts[this.state.selectedAccountIndex]?.branchId,
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
            branchId:
              this.state.accounts[this.state.selectedAccountIndex]?.branchId,
              curCode: this.state.accounts[this.state.selectedAccountIndex]?.curCode,
          };

    await fetch(request("deposit/getAccountPerList", getAccountListRequestBody))
      .then((res) => res.text())
      .then(
        (getAccountListResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getAccountListResponse = JSON.parse(getAccountListResponseJSON);
          if (getAccountListResponse.status.statusCode !== 1) {
            if (getAccountListResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(getAccountListResponse.status.statusCode)
              );
            }
          } else {
            this.setState({
              interestAccounts: getAccountListResponse.accountList,
            });
            this.getDepositProductInfoById();
            getAccountListResponse && this.props.updateAppState("isLoadingTransparentBackground", false);
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  getDepositProductInfoById = async () => {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let getDepositProductInfoByIdRequestBody =
      customerType == "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
          };

    await fetch(
      request(
        "deposit/getDepositProductInfoById",
        getDepositProductInfoByIdRequestBody
      )
    )
      .then((res) => res.text())
      .then(
        (getDepositProductInfoByIdResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getDepositProductInfoByIdResponse = JSON.parse(
            getDepositProductInfoByIdResponseJSON
          );
          if (getDepositProductInfoByIdResponse.status.statusCode !== 1) {
            if (getDepositProductInfoByIdResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(
                  getDepositProductInfoByIdResponse.status.statusCode
                )
              );
            }
          } else {
            this.setState(
              {
                productInfoList:
                  getDepositProductInfoByIdResponse.productInfoList.filter(
                    (item) =>
                      item.currency ===
                      this.state.accounts[this.state.selectedAccountIndex]
                        ?.currName
                  ),
              },
              () => {
                let { minTerm, maxTerm } = this.state.productInfoList[0];
                this.state.productInfoList.map((product) => {
                  if (product.minTerm < minTerm) minTerm = product.minTerm;
                  if (product.maxTerm > maxTerm) maxTerm = product.maxTerm;
                });
                this.setState({ minTerm: minTerm, maxTerm: maxTerm });
              }
            );
            getDepositProductInfoByIdResponse && this.props.updateAppState("isLoadingTransparentBackground", false);
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  calcDepositPercentAmount = async (e) => {
    e.preventDefault();
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let calcDepositRequestBody =
      customerType == "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            amount: this.state.amount,
            accountId:
              this.state.accounts[this.state.selectedAccountIndex]?.accountId,
            rate: this.state.productInfoList[this.state.selectedDepositIndex]
              ?.rate,
            term: this.state.selectedPeriodIndex + this.state.minTerm,
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            amount: this.state.amount,
            accountId:
              this.state.accounts[this.state.selectedAccountIndex]?.accountId,
            rate: this.state.productInfoList[this.state.selectedDepositIndex]
              ?.rate,
            term: this.state.selectedPeriodIndex + this.state.minTerm,
          };

    await fetch(
      request("deposit/calcDepositPercentAmount", calcDepositRequestBody)
    )
      .then((res) => res.text())
      .then(
        (calcDepositResponseJSON) => {
          const JSON = require("true-json-bigint");
          let calcDepositResponse = JSON.parse(calcDepositResponseJSON);
          if (calcDepositResponse.status.statusCode !== 1) {
            if (calcDepositResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(calcDepositResponse.status.statusCode)
              );
              this.props.updateAppState(
                "isLoadingTransparentBackground",
                false
              );
            }
          } else {
            this.setState({
              percentAmount: calcDepositResponse.percentAmount,
              totalAmount: calcDepositResponse.totalAmount,
              step: 1,
            });
            this.props.updateAppState("isLoadingTransparentBackground", false);
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  closeSuccessFunction = () => {
    this.props.history.push("/");
  };
  setAmountOutline = () => {
    if (!this.state.amount.length > 0) {
      this.setState({ amountOutline: true });
    } else {
      this.setState({ amountOutline: false });
    }
  };

  onChangeAmount = (val) => {
    if (/^\d*\.{0,2}?\d*$/.test(val) || val.length == 0) {
      this.setState({ amount: val }, () => {
        this.confirmAll();
      });
    }
    
    /^\d*\.{0,2}?\d*$/.test(val) && Number(val) <= Number(MAX_AMOUNT_LIMIT) 
    ? this.setState({hasAmountErr: false})  
    : this.setState({hasAmountErr: true})
  };

  updateProduct = (index, e) => {
    e.preventDefault();
    this.setState(
      {
        selectedProductIndex: index,
        dropdownVisibilityProducts: false,
        confirmedOperation: false,
        open: false,
        accounts: [],
        selectedAccountIndex: -1,
        dropdownVisibilityAccounts: false,
        selectedAccountIndexCount: 0,
        interestAccounts: [],
        selectedInterestAccountIndex: -1,
        dropdownVisibilityInterestAccounts: false,
        selectedInterestAccountIndexCount: 0,
        productInfoList: [],
        minTerm: null,
        maxTerm: null,
        selectedDepositIndex: -1,
        dropdownVisibilityDeposits: false,
        selectedDepositIndexCount: 0,
        periodFilter: "",
        selectedPeriodIndex: -1,
        dropdownVisibilityPeriods: false,
        selectedPeriodIndexCount: 0,
        percentAmount: null,
        totalAmount: null,
      },
      () => {
        this.getAccountList();
        this.confirmAll();
      }
    );
  };
  updateAccount = (index, e) => {
    e.preventDefault();
    this.setState(
      {
        selectedAccountIndex: index,
        dropdownVisibilityAccounts: false,
        confirmedOperation: false,
        open: false,
        productInfoList: [],
        minTerm: null,
        maxTerm: null,
        interestAccounts: [],
        selectedInterestAccountIndex: -1,
        dropdownVisibilityInterestAccounts: false,
        selectedInterestAccountIndexCount: 0,
        selectedDepositIndex: -1,
        dropdownVisibilityDeposits: false,
        selectedDepositIndexCount: 0,
        periodFilter: "",
        selectedPeriodIndex: -1,
        dropdownVisibilityPeriods: false,
        selectedPeriodIndexCount: 0,
        percentAmount: null,
        totalAmount: null,
      },
      () => {
        this.state.accounts[this.state.selectedAccountIndex]?.curCode &&  this.getAccountPerList();
        // this.getDepositProductInfoById();
        this.confirmAll();
      }
    );
  };

  updatePeriod = (index, e) => {
    e.preventDefault();
    this.setState(
      {
        periodFilter: `${index + this.state.minTerm} ${this.props.t("month")}`,
        selectedPeriodIndex: index,
        dropdownVisibilityPeriods: false,
        confirmedOperation: false,
        open: false,
        selectedDepositIndex: -1,
        dropdownVisibilityDeposits: false,
        selectedDepositIndexCount: 0,
        percentAmount: null,
        totalAmount: null,
      },
      () => {
        this.confirmAll();
      }
    );
  };

  updatePeriodFilter = (e) => {
    e.preventDefault();
    this.setState(
      {
        periodFilter: e.target.value,
        selectedPeriodIndex: -1,
        confirmedOperation: false,
        open: false,
        selectedDepositIndex: -1,
        dropdownVisibilityDeposits: false,
        selectedDepositIndexCount: 0,
        percentAmount: null,
        totalAmount: null,
      },
      () => {
        this.confirmAll();
      }
    );
  };

  updatePeriodCount = () => {
    this.setState({ dropdownVisibilityPeriods: true });
    if (this.state.selectedPeriodIndex < 0) {
      this.setState({
        selectedPeriodIndexCount: ++this.state.selectedPeriodIndexCount,
      });
    }
  };

  confirmAll() {
    let {
      amount,
      selectedProductIndex,
      selectedAccountIndex,
      selectedInterestAccountIndex,
      selectedDepositIndex,
      selectedPeriodIndex,
    } = this.state;
    if (
      amount?.length > 0 &&
      selectedProductIndex >= 0 &&
      selectedAccountIndex >= 0 &&
      selectedInterestAccountIndex >= 0 &&
      selectedDepositIndex >= 0 &&
      selectedPeriodIndex >= 0
    ) {
      this.setState({ confirmedOperation: true });
    } else {
      this.setState({ confirmedOperation: false });
    }
    if (amount?.length > 0) {
      this.setState({ amountOutline: false });
    }
  }

  cancel = () => {
    this.setState({
      createFeedbackMessage: false,
      feedbackErrorModal: null,
      confirmedOperation: false,
      // ==============
      step: 0,
      confirmedOperation: false,
      open: false,
      amount: "",
      amountOutline: false,

      selectedProductIndex: -1,
      dropdownVisibilityProducts: false,
      selectedProductIndexCount: 0,

      accounts: [],
      selectedAccountIndex: -1,
      dropdownVisibilityAccounts: false,
      selectedAccountIndexCount: 0,

      selectedInterestAccountIndex: -1,
      dropdownVisibilityInterestAccounts: false,
      selectedInterestAccountIndexCount: 0,

      productInfoList: [],
      minTerm: null,
      maxTerm: null,

      selectedDepositIndex: -1,
      dropdownVisibilityDeposits: false,
      selectedDepositIndexCount: 0,

      periodFilter: "",
      selectedPeriodIndex: -1,
      dropdownVisibilityPeriods: false,
      selectedPeriodIndexCount: 0,

      percentAmount: null,
      totalAmount: null,
    });
  };

  openDepositContact = (e) => {
    e.preventDefault();
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let openDepositContractRequestBody =
      customerType === "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            amount: this.state.amount,
            accountId:
              this.state.accounts[this.state.selectedAccountIndex]?.accountId,
            perAccountId:
              this.state.interestAccounts[this.state.selectedInterestAccountIndex]
                ?.accountId,
            rate: this.state.productInfoList[this.state.selectedDepositIndex]
              ?.rate,
            term: this.state.selectedPeriodIndex + this.state.minTerm,
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
            depSettProductId:
              this.state.productInfoList[this.state.selectedDepositIndex]?.id,
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            amount: this.state.amount,
            accountId:
              this.state.accounts[this.state.selectedAccountIndex]?.accountId,
            perAccountId:
              this.state.interestAccounts[this.state.selectedInterestAccountIndex]
                ?.accountId,
            rate: this.state.productInfoList[this.state.selectedDepositIndex]
              ?.rate,
            term: this.state.selectedPeriodIndex + this.state.minTerm,
            productNameId:
              this.state.products[this.state.selectedProductIndex]
                .productNameId,
            depSettProductId:
              this.state.productInfoList[this.state.selectedDepositIndex]?.id,
          };
      
    fetch(
      request("deposit/openDepositContract", openDepositContractRequestBody)
    )
      .then((res) => res.text())
      .then(
        (openDepositContractResponseJSON) => {
          const JSON = require("true-json-bigint");
          let openDepositContractResponse = JSON.parse(
            openDepositContractResponseJSON
          );
          if (openDepositContractResponse.status.statusCode !== 1) {
            if (openDepositContractResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.setState({
                open: true,
                popupType: "error",
                title: this.props.t("an error occurred"),
                feedbackMessage: this.props.t(
                  openDepositContractResponse.status.statusCode
                ),
              });
              this.props.updateAppState(
                "isLoadingTransparentBackground",
                false
              );
            }
          } else {
            this.createDepositFatca(openDepositContractResponse);
            // this.props.updateAppState('isLoadingTransparentBackground', false);
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  createDepositFatca = ({ depositId, documentId, time }) => {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let createDepositFatcaRequestBody =
      customerType == "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            depositId: depositId,
            documentId: documentId,
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            depositId: depositId,
            documentId: documentId,
          };
    this.props.history.push("/dashboard/finance/deposits/fatca-contract", {
      request: createDepositFatcaRequestBody,
      time: time,
    });
  };

  closeFunction = () => {
    this.setState({
      step: 0,
      open: false,
      percentAmount: null,
      totalAmount: null,
    });
  };

  componentDidMount() {
    this.productList();
  }

  render() {
    let {
      products,
      selectedProductIndex,
      selectedProductIndexCount,
      dropdownVisibilityProducts,
      accounts,
      selectedAccountIndex,
      dropdownVisibilityAccounts,
      selectedAccountIndexCount,
      interestAccounts,
      selectedInterestAccountIndex,
      dropdownVisibilityInterestAccounts,
      selectedInterestAccountIndexCount,
      productInfoList,
      selectedDepositIndex,
      dropdownVisibilityDeposits,
      selectedDepositIndexCount,
      selectedPeriodIndex,
      dropdownVisibilityPeriods,
      selectedPeriodIndexCount,
      minTerm,
      maxTerm,
      percentAmount,
      totalAmount,
      open,
      title,
      popupType,
      feedbackMessage,
      amount,
      amountOutline,
      confirmedOperation,
      step,
      periodFilter,
      hasAmountErr
    } = this.state;
    let { t, history } = this.props;
    return (
      <>
        <AlertPopup
          open={open}
          closeFunction={this.closeFunction}
          popupType={popupType}
          title={title}
          feedbackMessage={feedbackMessage}
          closeSuccessFunction={this.closeSuccessFunction}
        />
        <div className="dashboard-header-route">
          <div className="dashboard-header-route-left">
            <button onClick={() => history.goBack()}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z"
                  fill="#2E3131"
                />
              </svg>
            </button>
            <h2>{t("Deposit placement")}</h2>
          </div>
        </div>
        <div className="dashboard-payment-container">
          <div
            className="dashboard-payment-container-data-section"
            style={{ marginTop: "1px" }}
          >
            <div className="dashboard-payment-container-data-section-header">
              <h3 className="dashboard-payment-container-data-section-header-title">
                {t("order info")}
              </h3>
            </div>
            <form className="dashboard-payment-container-data-section-content">
              <div className="dashboard-payment-container-data-section-content-container">
                {/* <h3 className="dashboard-payment-container-data-section-content-container-title">{t('order info')}</h3> */}
                <div
                  className={`dashboard-payment-container-data-section-content-container-form ${
                    step > 0
                      ? "dashboard-payment-container-data-section-content-container-form__border-bottom"
                      : ""
                  }`}
                >
                  <div className="dashboard-payment-container-data-section-content-container-form-container">
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("product")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          this.setState({ dropdownVisibilityProducts: false });
                        }}
                      >
                        <div
                          className={
                            "dashboard-payment-container-data-section-content-container-form-select-box " +
                            ((selectedProductIndexCount > 1 &&
                              selectedProductIndex < 0) ||
                            (selectedProductIndexCount === 1 &&
                              selectedProductIndex < 0 &&
                              !dropdownVisibilityProducts)
                              ? "input-outline"
                              : "")
                          }
                        >
                          <button
                            className={
                              "dashboard-payment-container-data-section-content-container-form-select-box-button" +
                              (step > 0
                                ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                : "")
                            }
                            disabled={step > 0}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                dropdownVisibilityProducts:
                                  !dropdownVisibilityProducts,
                              });
                              if (selectedProductIndex < 0)
                                this.setState({
                                  selectedProductIndexCount: ++this.state
                                    .selectedProductIndexCount,
                                });
                            }}
                          >
                            <div
                              className="dashboard-payment-container-data-section-content-container-form-select"
                              style={{
                                color: selectedProductIndex < 0 && "#BDC3C7",
                              }}
                            >
                              {selectedProductIndex >= 0
                                ? products[selectedProductIndex].productName
                                : t("Choose")}
                            </div>
                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                              <svg
                                className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                width="13"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="chevron-down copy 2"
                                  d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                  fill="#849097"
                                />
                              </svg>
                            </div>
                          </button>
                          {products.length !== 0 && (
                            <ul
                              className={
                                dropdownVisibilityProducts === false
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                              }
                            >
                              {products.map((product, index) => (
                                <li
                                  className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                  key={index}
                                >
                                  <button
                                    type="button"
                                    className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                    onClick={this.updateProduct.bind(
                                      this,
                                      index
                                    )}
                                  >
                                    {product.productName}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("Select an account to withdraw funds from")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          this.setState({ dropdownVisibilityAccounts: false });
                        }}
                      >
                        <div
                          className={
                            "dashboard-payment-container-data-section-content-container-form-select-box " +
                            ((selectedAccountIndexCount > 1 &&
                              selectedAccountIndex < 0) ||
                            (selectedAccountIndexCount === 1 &&
                              selectedAccountIndex < 0 &&
                              !dropdownVisibilityAccounts)
                              ? "input-outline"
                              : "")
                          }
                        >
                          <button
                            className={
                              "dashboard-payment-container-data-section-content-container-form-select-box-button" +
                              (step > 0 || !accounts || accounts.length === 0
                                ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                : "")
                            }
                            disabled={
                              step > 0 || !accounts || accounts.length === 0
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                dropdownVisibilityAccounts:
                                  !dropdownVisibilityAccounts,
                              });
                              if (selectedAccountIndex < 0)
                                this.setState({
                                  selectedAccountIndexCount: ++this.state
                                    .selectedAccountIndexCount,
                                });
                            }}
                          >
                            <div
                              className="dashboard-payment-container-data-section-content-container-form-select"
                              style={{
                                color: selectedAccountIndex < 0 && "#BDC3C7",
                              }}
                            >
                              {selectedAccountIndex >= 0
                                ? `${
                                    accounts[selectedAccountIndex].iban
                                  } ${accounts[selectedAccountIndex].mainCardNumber !== null ? '/' + accounts[selectedAccountIndex].mainCardNumber : ""} / ${accounts[
                                    selectedAccountIndex
                                  ].currentBalance?.toFixed(2)} ${
                                    accounts[selectedAccountIndex].currName
                                  }`
                                : t("Choose")}
                            </div>
                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                              <svg
                                className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                width="13"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="chevron-down copy 2"
                                  d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                  fill="#849097"
                                />
                              </svg>
                            </div>
                          </button>
                          {accounts.length !== 0 && (
                            <ul
                              className={
                                dropdownVisibilityAccounts === false
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                              }
                            >
                              {accounts.map((account, index) => (
                                <li
                                  className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                  key={index}
                                >
                                  <button
                                    type="button"
                                    className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                    onClick={this.updateAccount.bind(
                                      this,
                                      index
                                    )}
                                  >{`${
                                    account.iban
                                  } ${account.mainCardNumber !== null ? '/' + account.mainCardNumber : ""} / ${account.currentBalance?.toFixed(2)} ${
                                    account.currName
                                  }`}</button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("currency")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                        <input
                          type="text"
                          disabled={true}
                          className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                          value={
                            selectedAccountIndex >= 0
                              ? accounts[selectedAccountIndex]?.currName
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("branch")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                        <input
                          type="text"
                          disabled={true}
                          className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                          value={
                            selectedAccountIndex >= 0
                              ? accounts[selectedAccountIndex]?.branchName
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("deposit interest account")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          this.setState({
                            dropdownVisibilityInterestAccounts: false,
                          });
                        }}
                      >
                        <div
                          className={
                            "dashboard-payment-container-data-section-content-container-form-select-box " +
                            ((selectedInterestAccountIndexCount > 1 &&
                              selectedInterestAccountIndex < 0) ||
                            (selectedInterestAccountIndexCount === 1 &&
                              selectedInterestAccountIndex < 0 &&
                              !dropdownVisibilityInterestAccounts)
                              ? "input-outline"
                              : "")
                          }
                        >
                          <button
                            className={
                              "dashboard-payment-container-data-section-content-container-form-select-box-button" +
                              (step > 0 ||
                              !interestAccounts ||
                              interestAccounts.length === 0
                                ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                : "")
                            }
                            disabled={
                              step > 0 ||
                              !interestAccounts ||
                              interestAccounts.length === 0
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                dropdownVisibilityInterestAccounts:
                                  !dropdownVisibilityInterestAccounts,
                              });
                              if (selectedInterestAccountIndex < 0)
                                this.setState({
                                  selectedInterestAccountIndexCount: ++this
                                    .state.selectedInterestAccountIndexCount,
                                    // selectedInterestAccountIndex: ++this
                                    // .state.selectedInterestAccountIndex,
                                });
                            }}
                          >
                            <div
                              className="dashboard-payment-container-data-section-content-container-form-select"
                              style={{
                                color:
                                  selectedInterestAccountIndex < 0 && "#BDC3C7",
                              }}
                            >
                              {selectedInterestAccountIndex >= 0
                                ? `${
                                    interestAccounts[
                                      selectedInterestAccountIndex
                                    ].iban
                                  } ${ interestAccounts[
                                    selectedInterestAccountIndex
                                  ].mainCardNumber !== null ? '/' +  interestAccounts[
                                    selectedInterestAccountIndex
                                  ].mainCardNumber : ""} / ${interestAccounts[
                                    selectedInterestAccountIndex
                                  ].currentBalance?.toFixed(2)} ${
                                    interestAccounts[
                                      selectedInterestAccountIndex
                                    ].currName
                                  }`
                                : t("Choose")}
                            </div>
                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                              <svg
                                className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                width="13"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="chevron-down copy 2"
                                  d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                  fill="#849097"
                                />
                              </svg>
                            </div>
                          </button>
                          {interestAccounts.length !== 0 && (
                            <ul
                              className={
                                dropdownVisibilityInterestAccounts === false
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                              }
                            >
                              {interestAccounts.map((account, index) => (
                                <li
                                  className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                  key={index}
                                >
                                  <button
                                    type="button"
                                    className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState(
                                        {
                                          selectedInterestAccountIndex: index,
                                          dropdownVisibilityInterestAccounts: false,
                                        },
                                        () => this.confirmAll()
                                      );
                                    }}
                                  >{`${
                                    account.iban
                                  } ${account.mainCardNumber !== null ? '/' + account.mainCardNumber : ""} / ${account.currentBalance} ${
                                    account.currName
                                  }`}</button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("period")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          this.setState({ dropdownVisibilityPeriods: false });
                        }}
                      >
                        <div
                          className={
                            "dashboard-payment-container-data-section-content-container-form-select-box " +
                            ((selectedPeriodIndexCount > 1 &&
                              selectedPeriodIndex < 0) ||
                            (selectedPeriodIndexCount === 1 &&
                              selectedPeriodIndex < 0 &&
                              !dropdownVisibilityPeriods)
                              ? "input-outline"
                              : "")
                          }
                        >
                          <button
                            className={
                              "dashboard-payment-container-data-section-content-container-form-select-box-button" +
                              (step > 0 || minTerm === null || maxTerm === null
                                ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                : "")
                            }
                            disabled={
                              step > 0 || minTerm === null || maxTerm === null
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                dropdownVisibilityPeriods: true,
                              });
                            }}
                          >
                            {/* <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: selectedPeriodIndex < 0 && '#BDC3C7' }}>{selectedPeriodIndex >= 0 ? `${selectedPeriodIndex + minTerm} ${t('month')}` : t('Choose')}</div> */}
                            <input
                              type="text"
                              className="dashboard-payment-container-data-section-content-container-form-input"
                              disabled={
                                step > 0 || minTerm === null || maxTerm === null
                              }
                              value={periodFilter}
                              placeholder={t("Choose")}
                              onChange={this.updatePeriodFilter}
                              onFocus={this.updatePeriodCount}
                            />
                            <button
                              type="button"
                              className="dashboard-payment-container-data-section-content-container-form-icon"
                              onClick={() => {
                                this.setState({
                                  dropdownVisibilityPeriods:
                                    !dropdownVisibilityPeriods,
                                });
                              }}
                            >
                              <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                <svg
                                  className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                  width="13"
                                  height="8"
                                  viewBox="0 0 13 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    id="chevron-down copy 2"
                                    d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                    fill="#849097"
                                  />
                                </svg>
                              </div>
                            </button>
                          </button>
                          {productInfoList.length !== 0 && (
                            <ul
                              className={
                                dropdownVisibilityPeriods === false
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                              }
                            >
                              {[...Array(maxTerm - minTerm + 1)].map(
                                (item, index) => {
                                    return (
                                      <li
                                        className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                        key={index}
                                      >
                                        <button
                                          type="button"
                                          className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                          onClick={this.updatePeriod.bind(
                                            this,
                                            index
                                          )}
                                        >
                                          {index + minTerm} {t("month")}
                                        </button>
                                      </li>
                                    );
                                  }
                              )}
                            </ul>
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("Frequency of interest payment")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          this.setState({ dropdownVisibilityDeposits: false });
                        }}
                      >
                        <div
                          className={
                            "dashboard-payment-container-data-section-content-container-form-select-box " +
                            ((selectedDepositIndexCount > 1 &&
                              selectedDepositIndex < 0) ||
                            (selectedDepositIndexCount === 1 &&
                              selectedDepositIndex < 0 &&
                              !dropdownVisibilityDeposits)
                              ? "input-outline"
                              : "")
                          }
                        >
                          <button
                            className={
                              "dashboard-payment-container-data-section-content-container-form-select-box-button" +
                              (step > 0 ||
                              !productInfoList ||
                              productInfoList?.length === 0 ||
                              selectedPeriodIndex < 0
                                ? " dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                : "")
                            }
                            disabled={
                              step > 0 ||
                              !productInfoList ||
                              productInfoList?.length === 0 ||
                              selectedPeriodIndex < 0
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                dropdownVisibilityDeposits:
                                  !dropdownVisibilityDeposits,
                              });
                              if (selectedDepositIndex < 0)
                                this.setState({
                                  selectedDepositIndexCount: ++this.state
                                    .selectedDepositIndexCount,
                                });
                            }}
                          >
                            <div
                              className="dashboard-payment-container-data-section-content-container-form-select"
                              style={{
                                color: selectedDepositIndex < 0 && "#BDC3C7",
                              }}
                            >
                              {selectedDepositIndex >= 0
                                ? productInfoList[selectedDepositIndex]
                                    .calcPercentTake
                                : t("Choose")}
                            </div>
                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                              <svg
                                className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                width="13"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="chevron-down copy 2"
                                  d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                  fill="#849097"
                                />
                              </svg>
                            </div>
                          </button>
                          {productInfoList.length !== 0 && (
                            <ul
                              className={
                                dropdownVisibilityDeposits === false
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                              }
                            >
                              {productInfoList.map(
                                (item, index) =>
                                  item.minTerm <=
                                    selectedPeriodIndex + minTerm &&
                                  item.maxTerm >=
                                    selectedPeriodIndex + minTerm && (
                                    <li
                                      className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                      key={index}
                                    >
                                      <button
                                        type="button"
                                        className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState(
                                            {
                                              selectedDepositIndex: index,
                                              dropdownVisibilityDeposits: false,
                                            },
                                            () => {
                                              this.confirmAll();
                                            }
                                          );
                                        }}
                                      >
                                        {item.calcPercentTake}
                                      </button>
                                    </li>
                                  )
                              )}
                            </ul>
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("Amount")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        className={
                          "dashboard-payment-container-data-section-content-container-form-input-box " +
                          (amountOutline ? "input-outline" : "")
                        }
                      >
                        <input
                          type="text"
                          value={amount}
                          disabled={step > 0}
                          onChange={(e) => this.onChangeAmount(e.target.value)}
                          placeholder="0.00"
                          className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                          onBlur={this.setAmountOutline}
                        />
                      </div>
                      {hasAmountErr &&  <p className="ibank-input__err__msg">
                       {t("Amount can not be more than 100 000")}
                      </p>}
                    </div>
                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                      <label className="dashboard-payment-container-data-section-content-container-form-label">
                        {t("interest rate")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        className={
                          "dashboard-payment-container-data-section-content-container-form-input-box"
                        }
                      >
                        <input
                          type="text"
                          disabled={true}
                          value={
                            selectedDepositIndex >= 0
                              ? productInfoList[selectedDepositIndex]?.rate
                              : ""
                          }
                          className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {step === 0 ? (
                <div className="dashboard-payment-container-data-section-content-container">
                  <div className="dashboard-payment-container-data-section-content-container-form">
                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                      <div className="dashboard-payment-container-data-section-content-container-form-footer">
                        <button
                          onClick={(e) => {
                            this.calcDepositPercentAmount(e);
                          }}
                          disabled={!confirmedOperation}
                          className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${
                            !confirmedOperation &&
                            "dashboard-payment-container-data-section-content-container-form-footer-button__disable"
                          }`}
                        >
                          {t("continue")}
                        </button>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push("/");
                          }}
                          className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right"
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="dashboard-payment-container-data-section-content-container">
                  <div className="dashboard-payment-container-data-section-content-container-form">
                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        <label className="dashboard-payment-container-data-section-content-container-form-label">
                          {t("interest income")}
                        </label>
                        <div
                          className={
                            "dashboard-payment-container-data-section-content-container-form-input-box"
                          }
                        >
                          <input
                            type="text"
                            disabled={true}
                            value={percentAmount}
                            className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                          />
                        </div>
                      </div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        <label className="dashboard-payment-container-data-section-content-container-form-label">
                          {t("Total amount")}
                        </label>
                        <div
                          className={
                            "dashboard-payment-container-data-section-content-container-form-input-box"
                          }
                        >
                          <input
                            type="text"
                            disabled={true}
                            value={totalAmount}
                            className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                          />
                        </div>
                      </div>
                      <div className="dashboard-payment-container-data-section-content-container-form-footer">
                        <button
                          onClick={(e) => {
                            this.openDepositContact(e);
                          }}
                          disabled={!confirmedOperation}
                          className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${
                            !confirmedOperation &&
                            "dashboard-payment-container-data-section-content-container-form-footer-button__disable"
                          }`}
                        >
                          {t("Order")}
                        </button>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.cancel();
                          }}
                          className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right"
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className="dashboard-payment-container-confirmation-section">
            <div className="dashboard-payment-container-confirmation-section-header">
              <h3 className="dashboard-payment-container-confirmation-section-header-title">
                {t("Convenient online deposit")}
              </h3>
            </div>
            <div
              className="dashboard-payment-container-confirmation-section-content"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <img
                src={transferConfirmation}
                className="dashboard-payment-container-confirmation-section-content-image"
                style={{ paddingLeft: "32px", paddingRight: "32px" }}
              />
              <div className="dashboard-payment-container-confirmation-section-content-description">
                <p>{t("deposit order content")}</p>
                <strong>{t("not complete deposit contant")}</strong>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default translate("translation")(Deposit);
