import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@mui/material";

import phoneIcon from "./../../../../Login/img/phone-icon-form.svg";
import { digitalCardRequest, request } from "../../../../../config";
import { customerType } from "../../../../../helpers/customer";
import { changeDigitalCardRulesByLang } from "../../../../../helpers/cards";
import DigitalCardRulesModal from "../DigitalCardRulesModal/DigitalCardRulesModal";

import "./DigitalCardActivation.scss";

const DigitalCardActivation = ({
  updateAppState,
  translate,
  digitalCardInfo,
  alertState,
  logout,
}) => {
  const [checked, setChecked] = useState(false);
  const [showDigitalCardRulesModal, setShowDigitalCardRulesModal] =
    useState(false);
  const [digitalCardRulesPdf, setDigitalCardRulesPdf] = useState(null);

  const { period, mobile, currency } = digitalCardInfo;
  const bankCustomerType = localStorage.getItem("bankCustomerType");

  const lang = localStorage.getItem("i18nextLng");

  let history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const closeSuccessFunction = () => {
    return history.push("/dashboard/home");
  };

  const onSubmit = (data) => {
    updateAppState("isLoadingTransparentBackground", true);
    let requestBody = {
      token: localStorage.getItem("token"),
      securityCode: data.securityCode,
      period: period,
      currency: currency,
      email: data.email || null,
    };

    fetch(digitalCardRequest("openDigitalCard", requestBody))
      .then((res) => res.text())
      .then((openDigitalCardResponseJSON) => {
        const JSON = require("true-json-bigint");
        let openDigitalCardResponse = JSON.parse(openDigitalCardResponseJSON);
        if (openDigitalCardResponse?.status?.statusCode !== 1) {
          if (openDigitalCardResponse?.status?.statusCode === 200) {
            logout();
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            alertState(
              true,
              "error",
              translate("an error occurred"),
              translate(openDigitalCardResponse?.status?.statusCode)
            );
          }
        } else {
          updateAppState("isLoadingTransparentBackground", false);
          alertState(
            true,
            "success",
            translate("successful operation"),
            translate("Digital card has been activated successfully"),
            null,
            false,
            closeSuccessFunction
          );
          reset();
        }
      });
  };

  const getDigitalCardRulesPdf = () => {
    updateAppState("isLoadingTransparentBackground", true);
    fetch(request("util/downloadDigitalCardPdf", null, "GET"))
      .then((res) => res.json())
      .then(
        (digitalCardRulesPdfResponse) => {
          if (digitalCardRulesPdfResponse.status.statusCode !== 1) {
            if (digitalCardRulesPdfResponse.status.statusCode === 200) {
              logout();
            } else {
              alertState(
                true,
                "error",
                translate("an error occurred"),
                translate(digitalCardRulesPdfResponse.status.statusCode)
              );
            }
          } else {
            let bytesStr = `data:application/pdf;base64,${digitalCardRulesPdfResponse.bytes}`;
            setDigitalCardRulesPdf(bytesStr);
            setShowDigitalCardRulesModal(true);
          }
          updateAppState("isLoadingTransparentBackground", false);
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const toggleDigitalCardRulesModal = () => {
    setShowDigitalCardRulesModal(!showDigitalCardRulesModal);
  };

  const handleAgreeWithRules = () => {
    setChecked(true);
    toggleDigitalCardRulesModal();
  };

  const handleDisagreeWithRules = () => {
    setChecked(false);
    toggleDigitalCardRulesModal();
  };

  return (
    <>
      <DigitalCardRulesModal
        open={showDigitalCardRulesModal}
        {...{
          translate,
          toggleDigitalCardRulesModal,
          digitalCardRulesPdf,
          handleAgreeWithRules,
          handleDisagreeWithRules,
        }}
      />
      <div className="ibank-digital-card-activation">
        <div className="ibank-digital-card-header">
          <h6>{translate("Digital card activation")}</h6>
        </div>
        <div className="ibank-digital-card-activation-desc">
          <p>{translate("Personal information")}</p>
        </div>
        <form
          className="ibank-digital-card-activation-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="header-sign-section-container-form-group">
            <label className="header-sign-section-container-form-group-label">
              {translate("phone number")}
            </label>
            <div className="header-sign-section-container-form-group-box">
              <Controller
                control={control}
                name={"phoneNumber"}
                render={({ field: { onBlur, ref } }) => (
                  <InputMask
                    mask="+\9\9\4 ( 99 ) 999-99-99"
                    className="header-sign-section-container-form-group-box-input"
                    onBlur={onBlur}
                    inputRef={ref}
                    disabled
                    defaultValue={mobile}
                    readOnly
                  />
                )}
              />
              <div className="header-sign-section-container-form-group-box-icon">
                <img
                  src={phoneIcon}
                  className="header-sign-section-container-form-group-box-icon-img  header-sign-section-container-form-group-box-icon-img__phone"
                  alt="Phone icon"
                />
              </div>
            </div>
          </div>
          <div className="header-sign-section-container-form-group">
            <label className="header-sign-section-container-form-group-label">
              {translate("Email")}
            </label>
            <div className="header-sign-section-container-form-group-box">
              <input
                type="text"
                className="header-sign-section-container-form-group-box-input"
                placeholder={translate("email placeholder")}
                {...register("email")}
              />
            </div>
          </div>
          {Number(bankCustomerType) === customerType.BANK_CUSTOMER && (
            <div className="header-sign-section-container-form-group">
              <label className="header-sign-section-container-form-group-label">
                <span style={{ color: "red", marginRight: "3px" }}>*</span>
                {translate("Secret code")}
              </label>
              <div className="header-sign-section-container-form-group-box">
                <input
                  type="text"
                  className="header-sign-section-container-form-group-box-input"
                  placeholder={translate("Secret code")}
                  {...register("securityCode", {
                    required: true,
                    minLength: 4,
                  })}
                />
              </div>
              <p className="ibank-input__info__msg">
                {translate(
                  "The secret code must contain at least 4 characters. This is for the security of your card"
                )}
              </p>
            </div>
          )}
          <div className="ibank-digital-card-activation-rules">
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                color: "#DADADA",
                "&.Mui-checked": {
                  color: "#0179D2",
                },
              }}
            />
            <div>
              {changeDigitalCardRulesByLang(lang, getDigitalCardRulesPdf)}
            </div>
          </div>
          <div className="ibank-digital-card-activation-form-actions">
            <button
              type="submit"
              className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__${
                !isValid || !checked ? "disable" : "blue"
              } dashboard-payment-container-data-section-content-container-form-footer-button__left`}
              disabled={!isValid || !checked}
            >
              {translate("continue")}
            </button>
            <button
              type="button"
              className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right"
              onClick={() => history.goBack()}
            >
              {translate("refusal")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DigitalCardActivation;
