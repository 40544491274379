import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function YesNoModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const close = () => {
        setOpen(false);
        props.onClose();
    }
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const triggerFunc = (val) => {
        close()
        if (val) {
            props.triggerFunc(val);
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="modal-sms-service">
                            <div className="modal-sms-service-head">
                                <h2>{props.title}</h2>
                            </div>
                            { props.body && 
                                <div className="modal-sms-service-body">
                                    <p>{props.body}</p>
                                </div>
                            }
                            <div className="modal-sms-service-buttons">
                                <button className="modal-sms-service-button-yes" onClick={() => triggerFunc(true)}>{props.ok || props.t('Yes')}</button>
                                <button className="modal-sms-service-button-no" onClick={() => triggerFunc(false)}>{props.notOk || props.t('No')}</button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(YesNoModal);