import React, { useState, useEffect } from "react";

import digitalCardImg from "./../../../img/digital-card-info.svg";
import { customerType } from "../../../../../helpers/customer";

import "./DigitalCardInfo.scss";

const DigitalCardInfo = ({ translate, digitalCardInfo }) => {
  const {
    periodType,
    currency,
    incomeAmount,
    expenseAmount,
    price,
    forbiddenOperationNames,
    maxMonthlyBalance,
  } = digitalCardInfo;

  const bankCustomerType = localStorage.getItem("bankCustomerType");

  const [digitalCardTarriffs, setDigitalCardTarriffs] = useState([
    { id: "currency", name: translate("currency"), value: currency },
    {
      id: "period",
      name: translate("period"),
      value: `${periodType}`,
    },
    { id: "cardPrice", name: translate("Card price"), value: price },
    {
      id: "annualCardncome",
      name: translate("Annual card income"),
      value: `${incomeAmount} ${currency}`,
    },
    {
      id: "annualCardExpenses",
      name: translate("Annual card expenses"),
      value: `${expenseAmount} ${currency}`,
    },
  ]);

  const generateDigitalCardTarriffsByCustomerType = () => {
    let tariffsForNotABankCustomer = [
      {
        id: "prohibitedTransactions",
        name: translate("Prohibited transactions"),
        value: `${forbiddenOperationNames}`,
      },
      {
        id: "cardMaximum monthly balance",
        name: translate("Card maximum monthly balance"),
        value: maxMonthlyBalance,
      },
    ];
    Number(bankCustomerType) === customerType.NON_CUSTOMER &&
      setDigitalCardTarriffs([
        ...digitalCardTarriffs,
        ...tariffsForNotABankCustomer,
      ]);
  };

  useEffect(() => {
    generateDigitalCardTarriffsByCustomerType();
  }, []);

  return (
    <div className="ibank-digital-card-info">
      <div className="ibank-digital-card-header">
        <h6>{translate("About card")}</h6>
      </div>
      <div className="ibank-digital-card-info-image">
        <img src={digitalCardImg} alt="digitalCardImg" />
      </div>
      <div className="ibank-digital-card-info-desc">
        <h5 className="ibank-digital-card-info-title">
          {translate("Information")}
        </h5>
        <p>
          {translate(
            "This card is a free virtual debit card intended for TuranBank users."
          )}
        </p>
      </div>
      <div className="ibank-digital-card-info-tariffs">
        <h5 className="ibank-digital-card-info-title">
          {translate("tariffs")}
        </h5>
        <ul className="ibank-digital-card-info-tariffs-list">
          {digitalCardTarriffs?.map(({ id, name, value }) => {
            return (
              <li
                key={id}
                className="ibank-digital-card-info-tariffs-list-item"
              >
                <p className="ibank-digital-card-info-tariffs-list-item-name">
                  {name}
                </p>
                <p className="ibank-digital-card-info-tariffs-list-item-value">
                  {value}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DigitalCardInfo;
