import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import lastOperationsIcon from './../../img/cards-icon.png';
import starIcon from './../../img/star.svg';
import { request } from '../../../../config';
import BonusPayModal from './../../../../copms/Modal/BonusPayModal';
import Loading from '../../Loading/Loading';
import DatePickerInput from './DatePickerInput';
class HistoryPay extends Component {
    state = {
        isLoading: false,
        bonuses: [],
        currentPage: 1,
        lastPage: 0,
        startDate: null,
        endDate: null,
        bonusBalance: null,
        selectedIndex: -1
    }

    updateDate = (name, value) => {
        this.setState({ [name]: value });
    }

    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }

    changeDateType = (date) => `${date.split('-')[2]}.${date.split('-')[1]}.${date.split('-')[0]}`;

    getBonusPaySearchList = (initial) => {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        else {
            this.setState({ isLoading: true });
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusPaySearchListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: this.state.currentPage
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: this.state.currentPage
        }

        fetch(request('bonus/getBonusPaySearchList', getBonusPaySearchListRequestBody))
            .then(res => res.text())
            .then((getBonusPaySearchListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusPaySearchListResponse = JSON.parse(getBonusPaySearchListResponseJSON);
                if (getBonusPaySearchListResponse.status.statusCode !== 1 && getBonusPaySearchListResponse.status.statusCode !== 282) {
                    if (getBonusPaySearchListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                        else {
                            this.setState({ isLoading: false });
                        }
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusPaySearchListResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ lastPage: getBonusPaySearchListResponse.pageCount ? getBonusPaySearchListResponse.pageCount : 0 })
                    if (getBonusPaySearchListResponse.status.statusCode === 282) {
                        getBonusPaySearchListResponse.paymentList = [];
                    }
                    for (let i = 0; i < getBonusPaySearchListResponse.paymentList.length; i++) {
                        // =========AMOUNT=========
                        getBonusPaySearchListResponse.paymentList[i].organizedAmount = getBonusPaySearchListResponse.paymentList[i].amount;
                        if (getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString() === "0") {
                            getBonusPaySearchListResponse.paymentList[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString().indexOf('.') !== -1) {
                                let amountArray = getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString().split('.');
                                getBonusPaySearchListResponse.paymentList[i].organizedAmount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                let amountArray = getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString().split('.');
                                getBonusPaySearchListResponse.paymentList[i].organizedAmount = {
                                    wholePart: getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ bonuses: getBonusPaySearchListResponse.paymentList });
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showBonusBalance = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusBalanceRequestBody = customerType === "Juridical" ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("bonus/getBonusBalance", getBonusBalanceRequestBody))
            .then(res => res.text())
            .then((getBonusBalanceResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusBalanceResponse = JSON.parse(getBonusBalanceResponseJSON);
                if (getBonusBalanceResponse.status.statusCode !== 1) {
                    if (getBonusBalanceResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusBalanceResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ bonusBalance: getBonusBalanceResponse.bonusBalance });
                }
            },
                (error) => {

                    this.props.history.push("/technical-break");
                });
    }

    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => {
            this.getBonusPaySearchList(false);
        });
    }

    newPayment = () => { this.props.history.push('/dashboard/payment/payments') };

    componentDidMount() {
        this.showBonusBalance();
        this.getBonusPaySearchList(true);
    }

    render() {
        let { alertState, t, logout, updateAppState } = this.props;
        let { isLoading, bonuses, bonusBalance, selectedIndex } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Bonuses')}</h2>
                    </div>
                </div>
                <div className="dashboard-container-main-section-vertical">
                    <BonusPayModal history={this.props.history} open={selectedIndex >= 0} onClose={() => this.setState({ selectedIndex: -1 })} data={bonuses[selectedIndex]}/>
                    <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                        <div style={{ width: '100%', marginBottom: '22px' }}>
                            <div className="dashboard-transfer-list-header">
                                <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                    <NavLink exact to="/dashboard/finance/bonuses/history/1" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Earned stars")}</NavLink>
                                    <NavLink exact to="/dashboard/finance/bonuses/history/2" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("Spending stars")}</NavLink>
                                </div>
                                {
                                    bonusBalance !== null &&
                                    <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__right">
                                        <div className="dashboard-new-transfer-section-header-title dashboard-new-transfer-section-header-title-flex">
                                            <span className='dashboard-new-transfer-section-header-title-light'>
                                                {t('balance')}
                                            </span>
                                            <span>
                                                {bonusBalance}
                                            </span>
                                            <svg className='dashboard-new-transfer-section-header-title-icon' width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z" fill="#0179D2" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            isLoading ?
                                <div className="dashboard-finance-section-account-extract-section-main-section-visible-content-section">
                                    <Loading />
                                </div>
                                :
                                <div className="dashboard-transfer-list-content">
                                    <div className="dashboard-transfer-search-form-section" style={{ padding: '0px', marginTop: '10px' }}>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <DatePickerInput placeholder={t("Start date")} updateDate={this.updateDate.bind(this, 'startDate')} />
                                            </div>
                                        </div>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <DatePickerInput placeholder={t("End date")} updateDate={this.updateDate.bind(this, 'endDate')} />
                                            </div>
                                        </div>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <button className="dashboard-transfer-search-form-section-item-button" onClick={() => { this.getBonusPaySearchList(false); this.setState({ currentPage: 1, lastPage: 0 }); }}>{t("Search")}</button>
                                        </div>
                                    </div>
                                    {
                                        bonuses?.length > 0 ?
                                            <div className="dashboard-finance-section-cards-section">
                                                <div className="dashboard-finance-section-cards-section-table-section" style={{ paddingTop: 0 }}>
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody>
                                                            {
                                                                bonuses.map((bonus, index) =>
                                                                    <li className="dashboard-transfer-history-content-item dashboard-transfer-history-content-item__clickable" onClick={() => { this.setState({ selectedIndex: index }) }} key={index}>
                                                                        <div className="dashboard-transfer-history-content-item-container__left-side">
                                                                            <div className="dashboard-transfer-history-content-item-container-icon-section">
                                                                                <img src={starIcon} className="dashboard-transfer-history-content-item-container-icon" />
                                                                            </div>
                                                                            <div className="dashboard-transfer-history-content-item-container-content">
                                                                                <p className="dashboard-transfer-history-content-item-container-name">{bonus.categoryName} {bonus.merchantName}</p>
                                                                                <p className="dashboard-transfer-history-content-item-container-iban">{bonus.billingDate && `${bonus.billingDate.split(' ')[0]}, ${bonus.billingDate.split(' ')[1]}`}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dashboard-transfer-history-content-item-container__right-side">
                                                                            <div className="dashboard-transfer-history-content-item-container-content">
                                                                                <div className="dashboard-transfer-history-content-item-container-bonus">
                                                                                    <span className="dashboard-transfer-history-content-item-container-bonus-text">-{bonus.payBonus}</span>
                                                                                    <svg className='dashboard-transfer-history-content-item-container-bonus-icon' width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z" fill="#909090" />
                                                                                    </svg>
                                                                                </div>
                                                                                <p className="dashboard-transfer-history-content-item-container-type">
                                                                                    <div className="dashboard-transfer-history-content-item-container-amount">
                                                                                        <span className="dashboard-transfer-history-content-item-container-amount-whole">{`${bonus?.organizedAmount.wholePart}`}</span>
                                                                                        <span className="dashboard-transfer-history-content-item-container-amount-fractional">{bonus?.organizedAmount.fractionalPart}</span>
                                                                                        <span className="dashboard-transfer-history-content-item-container-amount-currency">{bonus.currency ? bonus.currency : 'AZN'}</span>
                                                                                    </div>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                        <ul className="dashboard-pagination">
                                                            {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                            {
                                                                Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                                    if (this.state.lastPage > 3) {
                                                                        if (this.state.currentPage - 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                                    {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage + 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                    {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                                    {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                                </>
                                                                            )
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                        )
                                                                    }
                                                                })

                                                            }
                                                            {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="dashboard-last-operations-section-content" style={{ minHeight: '70vh', paddingBottom: '48px' }}>
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p style={{ marginBottom: '16px' }} className="dashboard-last-operations-section-content-status">{t('You do not currently have any active bonus payments')}</p>
                                                <button onClick={this.newPayment} className='dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left'>{t("New payment")}</button>
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default translate("translation")(HistoryPay);
