import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import CardLimitsTabsItem from "./CardLimitsTabsItem/CardLimitsTabsItem.js";

import "./CardLimitsTabs.scss";

const CardLimitsTabs = ({
  tabs,
  cardLimitList,
  getSingleCardLimit,
  setOpenForm,
  t
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.zoneType);

  const handleChange = (event, value) => {
    setSelectedTab(value);
    setOpenForm(false);
  };

  return (
    <div className="ibank__tabs">
      <Box sx={{ width: "100%" }}>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="tab labels">
              {tabs?.map(({ label, zoneType }, index) => {
                return <Tab value={zoneType} {...{ label }} key={index} />;
              })}
            </TabList>
          </Box>
          <TabPanel value={selectedTab}>
            {cardLimitList?.map(
              (
                { zoneType, name, limitCodeId, defaultLimit, currencyLimit },
                index
              ) => {
                return (
                  zoneType == selectedTab && (
                    <CardLimitsTabsItem
                      key={index}
                      {...{
                        name,
                        defaultLimit,
                        currencyLimit,
                        limitCodeId,
                        getSingleCardLimit,
                        setOpenForm,
                        t
                      }}
                    />
                  )
                );
              }
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default CardLimitsTabs;
