import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { request } from '../../../../../config';
import transferConfirmation from './../../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../../helpers/currencyFilter';
import CreateExpressOperationModal from '../../../../../copms/Modal/CreateExpressOperationModal';
import FriendOperationModal from './../../../../../copms/Modal/FriendOperationModal';
import SendOtpModal from './../../../../../copms/Modal/SendOtpModal';
import CreateCardOperationTemplate from './../../../../../copms/Modal/CreateCardOperationTemplate';
import NotFound from '../../../../../copms/NotFound/NotFound';
import Loading from '../../../Loading/Loading';
import OutsideClickHandler from 'react-outside-click-handler';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
export class CreateExpressTransfer extends Component {
    state = {
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        confirmedOperation: false,
        cards: [],
        selectedCard: "",
        dropdownVisibilityCard: false,
        cardIdCount: 0,
        cardId: null,
        cardNumber: null,
        cardIdValidation: false,
        cardData: false,
        mobileNumber: "",
        mobileNumberValidation: false,
        mobileNumberOutline: false,
        amount: "",
        amountOrganized: {},
        amountValidation: false,
        amountOutline: false,
        comment: "",

        openCreateModal: false,
        modalData: null,
        successTitle: null,
        errorTitle: null,
        isError: false,

        openTemp: false,

        crName: "",
        crSurname: "",
        crFname: "",
        crNameOutline: false,
        crSurnameOutline: false,
        crFnameOutline: false,

        openOtp: false,
        commission: null,
        info: null,
        verifyToken: null
    }

    checkCrName = (e) => {
        this.setState({ crName: e.target.value }, () => {
            if (this.state.crName.trim().length > 0) {
                this.setState({ crNameOutline: false });
            }
        });
    }

    setCrNameOutline = () => {
        if (this.state.crName.trim().length > 0) {
            this.setState({ crNameOutline: false });
        }
        else {
            this.setState({ crNameOutline: true });
        }
    }

    checkCrSurname = (e) => {
        this.setState({ crSurname: e.target.value }, () => {
            if (this.state.crSurname.trim().length > 0) {
                this.setState({ crSurnameOutline: false });
            }
        });
    }

    setCrSurnameOutline = () => {
        if (this.state.crSurname.trim().length > 0) {
            this.setState({ crSurnameOutline: false });
        }
        else {
            this.setState({ crSurnameOutline: true });
        }
    }

    checkCrFname = (e) => {
        this.setState({ crFname: e.target.value }, () => {
            if (this.state.crFname.trim().length > 0) {
                this.setState({ crFnameOutline: false });
            }
        });
    }

    setCrFnameOutline = () => {
        if (this.state.crFname.trim().length > 0) {
            this.setState({ crFnameOutline: false });
        }
        else {
            this.setState({ crFnameOutline: true });
        }
    }

    checkComment = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value.substring(0, 255);
        this.setState({ comment: value });
    }

    getPhoneNumber = (number) => {
        ['+', '-', '(', ')', ' '].forEach(char => {
            while (number.indexOf(char) !== -1) {
                number = number.slice(0, number.indexOf(char)) + number.slice(number.indexOf(char) + 1, number.length);
            }
        });
        return number;
    }

    checkMobileNumber = (event) => {
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null
        });
        this.setState({ mobileNumber: event.target.value, destCustName: "", destCustId: null });
        if (event.target.value.indexOf("_") === -1) {
            this.setState({ mobileNumberValidation: true });
            this.setState({ mobileNumberOutline: false });
        }
        else {
            this.setState({ mobileNumberValidation: false });
        }
    }

    setMobileNumberOutline = () => {
        if (!this.state.mobileNumberValidation) {
            this.setState({ mobileNumberOutline: true });
        }
        else {
            this.setState({ mobileNumberOutline: false });
        }
    }

    showCards = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        return await fetch(request("card/getCardListFor" + customerType + "Customer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }

                    this.setState({ cards: getCardListForCustomerResponse.cardList, cardData: true });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCurrencies = async () => {
        return await fetch(request("exchange/cashless", {}, "GET"))
            .then(res => res.text())
            .then((cashlessResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cashlessResponse = JSON.parse(cashlessResponseJSON);
                if (cashlessResponse.status.statusCode !== 1) {
                    if (cashlessResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(cashlessResponse.status.statusCode));
                    }
                }
                else {
                    let currencies = cashlessResponse.exchangeCashlessList;
                    currencies.push({ currency: "AZN", code: 944, cashlessBuy: 1, cashlessSell: 1 })
                    this.setState({ currencies: currencies });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    changeDropdownVisibilityCard = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCard: !this.state.dropdownVisibilityCard }, () => {
            if (!this.state.cardIdValidation) {
                this.setState({ cardIdCount: ++this.state.cardIdCount });
            }
        });
    }

    updateCard(card, event) {
        event.preventDefault();
        this.setState({
            cardId: card.cardId, cardNumber: card.cardNumber, selectedCard: event.target.textContent, dropdownVisibilityCard: false, cardIdValidation: true,
            currency: this.state.currencies.find(item => (item.currency === card.currency)), selectedCurrency: card.currency, currencyValidation: true
        });
    }

    checkAmount = (event) => {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({ amount: event.target.value }, () => {
                let amount = this.state.amount, amountOrganized = {};
                if (amount.toString() === "0") {
                    amountOrganized = {
                        wholePart: "0",
                        fractionalPart: "00"
                    }
                }
                else {
                    if (amount.toString().indexOf('.') !== -1) {
                        let amountArray = amount.toString().split('.');
                        amountOrganized = {
                            wholePart: amountArray[0],
                            fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                        }
                    }
                    else {
                        amountOrganized = {
                            wholePart: amount.toString(),
                            fractionalPart: "00"
                        }
                    }
                }
                this.setState({ amountOrganized });
                if (amount.length === 0 || amount == 0) {
                    this.setState({ amountValidation: false });
                }
                else {
                    this.setState({ amountValidation: true });
                    this.setState({ amountOutline: false });
                }
            })
        }
    }

    setAmountOutline = () => {
        if (!this.state.amountValidation) {
            this.setState({ amountOutline: true });
        }
        else {
            this.setState({ amountOutline: false });
        }
    }

    showExpressTransfer = () => {
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null,
            comment: this.state.comment.trim() === '' ? 'Maddi yardım' : this.state.comment,
        });
        this.setState({ confirmedOperation: true });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getFeeAmountRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: 6,
            senderType: 1,
            custType: 2,
            amount: this.state.amount,
            ccyCode: this.state.currency.code,
            dtCardNumber: this.state.cardNumber,
            cardCcyCode: this.state.currency.code,
            cardId: this.state.cardId
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: 6,
            senderType: 1,
            custType: 1,
            amount: this.state.amount,
            ccyCode: this.state.currency.code,
            dtCardNumber: this.state.cardNumber,
            cardCcyCode: this.state.currency.code,
            cardId: this.state.cardId
        }
        fetch(request("card/getFeeAmount", getFeeAmountRequestBody))
            .then(res => res.text())
            .then((getFeeAmountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getFeeAmountResponse = JSON.parse(getFeeAmountResponseJSON);
                if (getFeeAmountResponse.status.statusCode !== 1) {
                    if (getFeeAmountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getFeeAmountResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({
                        commission: getFeeAmountResponse.feeAmount
                    })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    createExpressTransfer = (confirmCode) => {
        this.setState({ confirmedOperation: true });
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null,

            openCreateModal: false,
            modalData: null,
            successTitle: null,
            errorTitle: null,
            isError: false
        });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createExpressOperationRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            amount: this.state.amount,
            reqCardId: this.state.cardId,
            cardNumber: this.state.cardNumber,
            bankFeeAmount: this.state.commission,
            currency: this.state.currency.currency,
            curCode: this.state.currency.code,
            transferTypeId: 2,
            crName: this.state.crName,
            crSurname: this.state.crSurname,
            crFname: this.state.crFname,
            crMobileNumber: this.getPhoneNumber(this.state.mobileNumber),
            purporse: this.state.comment,
            confirmCode: confirmCode,
            verifyToken: this.state.verifyToken
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            amount: this.state.amount,
            reqCardId: this.state.cardId,
            cardNumber: this.state.cardNumber,
            bankFeeAmount: this.state.commission,
            currency: this.state.currency.currency,
            curCode: this.state.currency.code,
            transferTypeId: 2,
            crName: this.state.crName,
            crSurname: this.state.crSurname,
            crFname: this.state.crFname,
            crMobileNumber: this.getPhoneNumber(this.state.mobileNumber),
            purporse: this.state.comment,
            confirmCode: confirmCode,
            verifyToken: this.state.verifyToken
        }
        fetch(request("express/createExpressOperationv1", createExpressOperationRequestBody))
            .then(res => res.text())
            .then((createExpressOperationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createExpressOperationResponse = JSON.parse(createExpressOperationResponseJSON);
                if (createExpressOperationResponse.status.statusCode !== 1) {
                    if (createExpressOperationResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                    else {
                        this.setState({ openOtp: false, createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createExpressOperationResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let organizedAmount, organizedFeeAmount, organizedCardNumber;
                    // =========AMOUNT=========
                    if (createExpressOperationResponse.amount.toString() === "0") {
                        organizedAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (createExpressOperationResponse.amount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = createExpressOperationResponse.amount.toString().split('.');
                            organizedAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            organizedAmount = {
                                wholePart: createExpressOperationResponse.amount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    organizedAmount.currency = this.state.currency.currency;

                    // =========FEE=========
                    if (createExpressOperationResponse.bankFeeAmount.toString() === "0") {
                        organizedFeeAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (createExpressOperationResponse.bankFeeAmount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = createExpressOperationResponse.bankFeeAmount.toString().split('.');
                            organizedFeeAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            organizedFeeAmount = {
                                wholePart: createExpressOperationResponse.bankFeeAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    organizedFeeAmount.currency = this.state.currency.currency;
                    // =========NUMBER=========
                    organizedCardNumber = createExpressOperationResponse.cardNumber.substring(0, 4)
                        + " **** **** " + createExpressOperationResponse.cardNumber.substring(12, 16);

                    let data = {
                        crName: createExpressOperationResponse?.crName,
                        crSurname: createExpressOperationResponse?.crSurname,
                        crFname: createExpressOperationResponse?.crFname,
                        crMobileNumber: createExpressOperationResponse?.crMobileNumber,
                        organizedAmount: organizedAmount,
                        organizedFeeAmount: organizedFeeAmount,
                        organizedCardNumber: organizedCardNumber,
                        operationCode: createExpressOperationResponse?.operationCode,
                        rrn: createExpressOperationResponse?.rrn
                    }

                    this.setState({ openOtp: false, openCreateModal: true, modalData: data, isError: false, successTitle: this.props.t('The payment order was created successfully') });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    cancel = () => {
        this.setState({
            createFeedbackMessage: false,
            feedbackErrorModal: null,
            confirmedOperation: false,
            dropdownVisibilityCard: false,
            selectedCard: "",
            cardIdCount: 0,
            cardId: null,
            cardNumber: null,
            cardIdValidation: false,
            mobileNumber: "",
            mobileNumberValidation: false,
            mobileNumberOutline: false,
            amount: "",
            amountOrganized: {},
            amountValidation: false,
            amountOutline: false,
            comment: "",
            crName: "",
            crSurname: "",
            crFname: "",
            crNameOutline: false,
            crSurnameOutline: false,
            crFnameOutline: false,
            commission: null
        })
    }

    downloadTariffPdf = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadTariffPdf", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Qaydalar') + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateOpen = (value) => {
        this.setState({ open: value });
    }

    updateOpenTemp = (value) => {
        this.setState({ openTemp: value });
    }

    sendOtpCode = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        this.props.updateAppState('isLoadingTransparentBackground', true);
        clearInterval(this.state.timerInterval);
        this.setState({ feedbackMessage: null, isDisabled: true, openOtp: false, buttonDisabled: true });
        let sendOtpCodeRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            mobile: JSON.parse(localStorage.getItem('custObject')).customer.mobile,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            token: localStorage.getItem('token'),

        } : {
            custId: localStorage.getItem('custId'),
            mobile: JSON.parse(localStorage.getItem('custObject')).mobile,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            token: localStorage.getItem('token'),

        }

        fetch(request("express/sendOtpForExpressOperation", sendOtpCodeRequestBody))
            .then(res => res.json())
            .then((sendOtpCodeRequestResponse) => {
                if (sendOtpCodeRequestResponse.status.statusCode === 1) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ openOtp: true });
                    this.setState({ verifyToken: sendOtpCodeRequestResponse.verifyToken})
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ feedbackMessage: this.props.t(sendOtpCodeRequestResponse.status.statusCode) });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getTempById = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        return await fetch(request("card/getCardOperationTempById", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardOperationTempByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardOperationTempByIdResponse = JSON.parse(getCardOperationTempByIdResponseJSON);
                if (getCardOperationTempByIdResponse.status.statusCode !== 1 && getCardOperationTempByIdResponse.status.statusCode !== 137) {
                    if (getCardOperationTempByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    this.setState({
                        tempHave: true,
                        destCustId: getCardOperationTempByIdResponse.crCustId,
                        amount: getCardOperationTempByIdResponse.amount,
                        cardNumber: getCardOperationTempByIdResponse.requestorCardNumber,
                        comment: getCardOperationTempByIdResponse.purpose,
                        currency: {
                            currency: getCardOperationTempByIdResponse.currency,
                            code: getCardOperationTempByIdResponse.dtCcyCode
                        },
                        contactName: getCardOperationTempByIdResponse.contactName,
                        destCustName: getCardOperationTempByIdResponse.contactName,
                        mobileNumber: getCardOperationTempByIdResponse.mobile,
                        cardId: this.state.cards.find(el => el.cardNumber === getCardOperationTempByIdResponse.requestorCardNumber).cardId,
                        selectedCard: this.state.cards.find(el => el.cardNumber === getCardOperationTempByIdResponse.requestorCardNumber).organizedCardNumber + ' / ' + this.state.cards.find(el => el.cardNumber === getCardOperationTempByIdResponse.requestorCardNumber).balance + ' ' + this.state.cards.find(el => el.cardNumber === getCardOperationTempByIdResponse.requestorCardNumber).currency,
                        selectedCurrency: getCardOperationTempByIdResponse.currency,
                        crName: getCardOperationTempByIdResponse.crName,
                        crSurname: getCardOperationTempByIdResponse.crSurname,
                        crFname: getCardOperationTempByIdResponse.crFname,
                        mobileNumberValidation: true,
                        amountValidation: true,
                        cardIdValidation: true
                    });
                    let amountOrganized = {};
                    if (getCardOperationTempByIdResponse.amount.toString() === "0") {
                        amountOrganized = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getCardOperationTempByIdResponse.amount.toString().indexOf('.') !== -1) {
                            let amountArray = getCardOperationTempByIdResponse.amount.toString().split('.');
                            amountOrganized = {
                                wholePart: amountArray[0],
                                fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                            }
                        }
                        else {
                            amountOrganized = {
                                wholePart: getCardOperationTempByIdResponse.amount.toString(),
                                fractionalPart: "00"
                            }
                        }
                        this.setState({ amountOrganized });
                    }
                }
            });

    }

    getCardOperationInfo = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardOperationInfoRequest = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "EXPRESS_OPERATION"
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "EXPRESS_OPERATION"
        }
        await fetch(request("util/getCardOperationInfo", getCardOperationInfoRequest))
            .then(res => res.json())
            .then((getCardOperationInfoResponse) => {
                if (getCardOperationInfoResponse.status.statusCode !== 1) {
                    if (getCardOperationInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardOperationInfoResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ info: getCardOperationInfoResponse.info });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        await this.getCardOperationInfo();
        await this.showCurrencies();
        await this.showCards();
        await this.getTempById();
        this.setState({ isLoading: false });
    }

    render() {
        let { t, history } = this.props;
        let { confirmedOperation, open, cards, currencies, selectedCard, selectedCurrency, dropdownVisibilityCard,
            dropdownVisibilityCurrency, createFeedbackMessage, feedbackErrorModal, amountOrganized, amount, currency,
            mobileNumberValidation, amountValidation, cardIdValidation, currencyValidation, cardData,
            amountOutline, cardIdCount, mobileNumber, mobileNumberOutline, comment,
            commission, openCreateModal, modalData, isError, errorTitle, successTitle,
            openTemp,
            crName, crSurname, crFname, crNameOutline, crSurnameOutline, crFnameOutline, cardNumber,
            openOtp, tempHave, isLoading, info, cardId
        } = this.state;
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let requestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            cardOperationType: 6,
            mobile: this.getPhoneNumber(mobileNumber),
            amount: amount,
            currency: currency?.currency,
            crName: crName,
            crSurname: crSurname,
            crFname: crFname,
            purpose: comment,
            requestorCardNumber: cardNumber,
            requestorCardId: Number(cardId),
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            cardOperationType: 6,
            mobile: this.getPhoneNumber(mobileNumber),
            amount: amount,
            currency: currency?.currency,
            crName: crName,
            crSurname: crSurname,
            crFname: crFname,
            purpose: comment,
            requestorCardNumber: cardNumber,
            requestorCardId: Number(cardId),
        }
        return (
            <Fragment>
                <CreateCardOperationTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={'saveCardOperationTemp'} requestBody={requestBody} />
                <FriendOperationModal open={open} updateOpen={this.updateOpen} successClose={() => { history.push('/dashboard/transfer/card-operation/express-transfer/history') }} errorClose={() => { this.setState({ confirmedOperation: false }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <CreateExpressOperationModal
                    data={modalData} open={openCreateModal}
                    close={() => this.setState({ openCreateModal: false })} isError={isError}
                    successTitle={successTitle} errorTitle={errorTitle}
                    errorClose={() => { this.setState({ confirmedOperation: false }) }}
                    successClose={() => { history.push('/dashboard/transfer/card-operation/express-transfer/history') }} />
                <SendOtpModal buttonTitle={t('Confirm')} open={openOtp} sendOtpCode={this.sendOtpCode} signSubmit={(val) => this.createExpressTransfer(val)} onClose={() => this.setState({ openOtp: false })} />
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !tempHave ?
                            <NotFound />
                            :
                            <Fragment>
                                <div className="dashboard-header-route">
                                    <div className="dashboard-header-route-left">
                                        <button onClick={() => history.goBack()}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                            </svg>
                                        </button>
                                        <h2>{t('New operation')}</h2>
                                    </div>
                                    {/* <button onClick={() => this.downloadTariffPdf()} className="dashboard-header-route-link">{t('Tariffs')}</button> */}
                                </div>
                                <div className="dashboard-payment-container">
                                    <div className="dashboard-payment-container-data-section">
                                        <div className="dashboard-payment-container-data-section-header">
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                <h2 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h2>
                                            </div>
                                            <button className="dashboard-payment-container-data-section-header-button" onClick={() => { this.setState({ openTemp: true }) }}
                                                disabled={!(mobileNumberValidation && amountValidation && cardIdValidation &&
                                                    crName.trim().length > 0 &&
                                                    crSurname.trim().length > 0 &&
                                                    crFname.trim().length > 0)}>{t("Save as template")}</button>
                                        </div>

                                        <form className="dashboard-payment-container-data-section-content">
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Receiving funds')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        {/* ============================= */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients mobile number')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (mobileNumberOutline ? "input-outline" : "")}>
                                                                <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" value={mobileNumber} disabled={Boolean(confirmedOperation)} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" placeholder="+994 (__) ___-__-__" onChange={this.checkMobileNumber} onBlur={this.setMobileNumberOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('First name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crNameOutline ? "input-outline" : "")}>
                                                                <input type="text" value={crName} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrName} onBlur={this.setCrNameOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Last name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crSurnameOutline ? "input-outline" : "")}>
                                                                <input type="text" value={crSurname} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrSurname} onBlur={this.setCrSurnameOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Ancestor name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crFnameOutline ? "input-outline" : "")}>
                                                                <input type="text" value={crFname} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrFname} onBlur={this.setCrFnameOutline} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Transfer settings')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">

                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by card')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCard: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (cardIdCount > 1 && !cardIdValidation || cardIdCount === 1 && !cardIdValidation && !dropdownVisibilityCard ? "input-outline" : "")}>
                                                                    <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation)} onClick={this.changeDropdownVisibilityCard}>
                                                                        {
                                                                            selectedCard ?
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCard}</div>
                                                                                :
                                                                                (cards && cards.length === 0 && cardData) ?
                                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{t('The card list is empty')}</div>
                                                                                    :
                                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                        }
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCard === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            cards && cards.length > 0 &&
                                                                            cards.map((card, index) => {
                                                                                return (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button onClick={this.updateCard.bind(this, card)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" disabled={!Boolean(card.cardStatus)}>{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                                    </li>
                                                                                );
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={amount} disabled={Boolean(confirmedOperation)} placeholder="0" onChange={this.checkAmount} onBlur={this.setAmountOutline} />
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency?.currency}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Appointment')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={comment} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkComment} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {
                                        confirmedOperation === false ?
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How to make an express transfer')}</h3>
                                                </div>
                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                                    <p dangerouslySetInnerHTML={{ __html: info }}></p>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        {

                                                            mobileNumberValidation && amountValidation && cardIdValidation &&
                                                                crName.trim().length > 0 &&
                                                                crSurname.trim().length > 0 &&
                                                                crFname.trim().length > 0 &&
                                                                comment.length > 0
                                                                ?
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.showExpressTransfer}>{t('continue')}</button>
                                                                :
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        }
                                                        <button onClick={e => this.props.history.push('/dashboard/transfer/card-operation')} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                                </div>
                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                        <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + currency?.currency?.toLocaleLowerCase()}>{currencyFilter(currency.currency)}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{amountOrganized.wholePart}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{amountOrganized.fractionalPart}</span>
                                                    </h3>
                                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Express transfer')}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Recipients name')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crSurname} {crName} {crFname}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Mobile number of the recipient')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{mobileNumber}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Withdrawal card')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedCard}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{amount} {currency.currency}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Appointment')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{comment}</p>
                                                        </li>
                                                        {
                                                            commission !== null &&
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{commission} {currency.currency}</p>
                                                            </li>
                                                        }
                                                    </ul>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={() => { this.sendOtpCode(); this.setState({ openOtp: true }) }}>{t('Create operation')}</button>
                                                        <button onClick={this.cancel} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>

                            </Fragment>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(CreateExpressTransfer);
