import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import { request } from './../../../../config';
import { isFuture } from 'date-fns';
import Loading from './../../Loading/Loading';
import OutsideClickHandler from 'react-outside-click-handler';
import $ from 'jquery';
import DatePickerInput from './../History/DatePickerInput';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
class ZkTransfer extends Component {
    state = {
        isLoading: false,
        transfers: [],
        newTransfer: 0,
        transfer: null,
        statusData: false,
        statusList: [],
        currencies: [],
        dropdownVisibilityStatus: false,
        dropdownVisibilityCurrency: false,
        selectedStatusIndex: -1,
        selectedCurrencyIndex: -1,
        currentPage: 1,
        lastPage: 0
    }
    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    updateDate = (name, value) => {
        this.setState({ [name]: value });
    }
    updateAmount = (name, event) => {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({ [name]: event.target.value });
        }
    }
    updateCurrency = (index) => {
        this.setState({ selectedCurrencyIndex: index === null ? -1 : index });
        this.setState({ dropdownVisibilityCurrency: false });
    }
    updateStatus = (index) => {
        this.setState({ selectedStatusIndex: index === null ? -1 : index });
        this.setState({ dropdownVisibilityStatus: false });
    }
    downloadTariffPdf = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadTariffPdf", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Qaydalar') + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    changeDateType = (date) => {
        return {
            date: `${date.split(' ')[0].split('-')[2]}.${date.split(' ')[0].split('-')[1]}.${date.split(' ')[0].split('-')[0]}`,
            time: `${date.split(' ')[1].split(':')[0]}:${date.split(' ')[1].split(':')[1]}`
        }

    };

    showStatus = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getZolotayaKoronaStatusRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("koronaPay/getZolotayaKoronaStatus", getZolotayaKoronaStatusRequestBody))
            .then(res => res.text())
            .then((getZolotayaKoronaStatusResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getZolotayaKoronaStatusResponse = JSON.parse(getZolotayaKoronaStatusResponseJSON);
                if (getZolotayaKoronaStatusResponse.status.statusCode !== 1 && getZolotayaKoronaStatusResponse.status.statusCode !== 357) {
                    if (getZolotayaKoronaStatusResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getZolotayaKoronaStatusResponse.status.statusCode));
                    }
                }
                else {
                    if (getZolotayaKoronaStatusResponse.status.statusCode === 357) {
                        getZolotayaKoronaStatusResponse.respZolotayaKoronaStatusList = [];
                    }


                    this.setState({ statusList: getZolotayaKoronaStatusResponse.respZolotayaKoronaStatusList, statusData: true });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCurrencies = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAllCurrencyListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        return await fetch(request("koronaPay/getAllCurrencyList", getAllCurrencyListRequestBody))
            .then(res => res.text())
            .then((getAllCurrencyListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAllCurrencyListResponse = JSON.parse(getAllCurrencyListResponseJSON);
                if (getAllCurrencyListResponse.status.statusCode !== 1 && getAllCurrencyListResponse.status.statusCode !== '????????') {
                    if (getAllCurrencyListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAllCurrencyListResponse.status.statusCode));
                    }
                }
                else {
                    if (getAllCurrencyListResponse.status.statusCode === '????????') {
                        getAllCurrencyListResponse.respMainCurrencyList = [];
                    }


                    this.setState({
                        currencies: getAllCurrencyListResponse.respMainCurrencyList.filter(currency => (
                            currency.valName === 'AZN' || currency.valName === 'USD' || currency.valName === 'EUR' || currency.valName === 'TRY' || currency.valName === 'RUB'
                        )), statusData: true
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCards = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        return await fetch(request("card/getCardListFor" + customerType + "Customer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }

                    this.setState({ cards: getCardListForCustomerResponse.cardList, cardData: true });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getTransferList = async (initial) => {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getTransferListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            pageNumber: this.state.currentPage,
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            mtStateId: this.state.selectedStatusIndex < 0 ? null : this.state.statusList[this.state.selectedStatusIndex].id,
            curCode: this.state.selectedCurrencyIndex < 0 ? null : this.state.currencies[this.state.selectedCurrencyIndex].tbName,
            transferTypeId: 1
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            pageNumber: this.state.currentPage,
            minAmt: this.state.minAmount,
            maxAmt: this.state.maxAmount,
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            mtStateId: this.state.selectedStatusIndex < 0 ? null : this.state.statusList[this.state.selectedStatusIndex].id,
            curCode: this.state.selectedCurrencyIndex < 0 ? null : this.state.currencies[this.state.selectedCurrencyIndex].tbName,
            transferTypeId: 1
        }

        return await fetch(request('koronaPay/getTransferList', getTransferListRequestBody))
            .then(res => res.text())
            .then((getTransferListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferListResponse = JSON.parse(getTransferListResponseJSON);
                if (getTransferListResponse.status.statusCode !== 1 && getTransferListResponse.status.statusCode !== 357) {
                    if (getTransferListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getTransferListResponse.status.statusCode));
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    if (getTransferListResponse.status.statusCode === 357) {
                        getTransferListResponse.respMainTransferList = [];
                    } else {
                        this.setState({ lastPage: getTransferListResponse.pageCount });
                        getTransferListResponse.respMainTransferList.map((item, index) => {
                            // =========AMOUNT=========
                            // if (getTransferListResponse.transferToFriendList[index].amount.toString() === "0") {
                            if (getTransferListResponse.respMainTransferList[index].amount.toString() === "0") {
                                getTransferListResponse.respMainTransferList[index].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (getTransferListResponse.respMainTransferList[index].amount.toString().indexOf('.') !== -1) {
                                    let amountArray = getTransferListResponse.respMainTransferList[index].amount.toString().split('.');
                                    // getTransferListResponse.transferToFriendList[index].amount.toString().split('.');
                                    getTransferListResponse.respMainTransferList[index].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    getTransferListResponse.respMainTransferList[index].organizedAmount = {
                                        wholePart: getTransferListResponse.respMainTransferList[index].amount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                        })
                    }
                    this.setState({ transfers: getTransferListResponse.respMainTransferList });
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    // this.setState({ transfers: getTransferListResponse.transferToFriendList.filter(item => (item.operStatus !== 0 && item.operStatus !== 2 && item.operStatus !== 3)) });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    selectPage = async (e, page) => {
        e.preventDefault();
        this.setState({ currentPage: page }, async () => {
            this.props.updateAppState('isLoadingTransparentBackground', true);
            await this.getTransferList(true);
            this.props.updateAppState('isLoadingTransparentBackground', false);
        });
    }

    changeDropdownVisibilityStatus = () => {
        this.setState({ dropdownVisibilityStatus: !this.state.dropdownVisibilityStatus });
    }

    changeDropdownVisibilityCurrency = () => {
        this.setState({ dropdownVisibilityCurrency: !this.state.dropdownVisibilityCurrency });
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        await this.getTransferList(true);
        await this.showCurrencies();
        await this.showStatus();
        await this.showCards();
        this.setState({ isLoading: false });
    }

    render() {
        let { alertState, t, logout, updateAppState } = this.props;
        let { isLoading, transfers, newTransfer,
            dtIban, accounts, accountData, selectedAccount, dropdownVisibilityAccount, minAmount, maxAmount, currency,
            statusList, dropdownVisibilityStatus, selectedStatusIndex, currencies, dropdownVisibilityCurrency, selectedCurrencyIndex
        } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Zolotaya Korona')}</h2>
                    </div>
                    {/* <button onClick={() => this.downloadTariffPdf()} className="dashboard-header-route-link">{t('Tariffs')}</button> */}
                </div>
                <div className="dashboard-container-main-section-vertical">

                    <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                        <div className="dashboard-payment-container-data-section-header">
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <h2 className="dashboard-payment-container-data-section-header-title">{t('history')}</h2>
                            </div>
                            <Link to='/dashboard/transfer/card-operation/zk-transfer/create' style={{ color: 'white' }} className="dashboard-payment-container-data-section-header-button">{t("New transfer")}</Link>
                        </div>
                        {
                            isLoading ?
                                <div className="dashboard-finance-section-account-extract-section-main-section-visible-content-section">
                                    <Loading />
                                </div>
                                :
                                <>
                                    <div className="dashboard-transfer-search-form-section">
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <DatePickerInput placeholder={t("Start date")} updateDate={this.updateDate.bind(this, 'startDate')} />
                                            </div>
                                        </div>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <DatePickerInput placeholder={t("End date")} updateDate={this.updateDate.bind(this, 'endDate')} />
                                            </div>
                                        </div>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityStatus: false }) }}>
                                                <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                    <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityStatus}>
                                                        {
                                                            selectedStatusIndex >= 0 ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{statusList[selectedStatusIndex].description}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("all")}</div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        statusList.length !== 0 &&
                                                        <ul className={(dropdownVisibilityStatus === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, null)}>{t("all")}</button>
                                                            </li>
                                                            {
                                                                statusList.map((item, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateStatus.bind(this, index)}>{item.description}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("From the amount")} onChange={this.updateAmount.bind(this, 'minAmount')} value={minAmount} />
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency}</div>
                                            </div>
                                        </div>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("To the amount")} onChange={this.updateAmount.bind(this, 'maxAmount')} value={maxAmount} />
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency}</div>
                                            </div>
                                        </div>

                                        <div className="dashboard-transfer-search-form-section-item">
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCurrency: false }) }}>
                                                <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                    <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityCurrency}>
                                                        {
                                                            selectedCurrencyIndex >= 0 ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{currencies[selectedCurrencyIndex].valName}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("all")}</div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        currencies.length !== 0 &&
                                                        <ul className={(dropdownVisibilityCurrency === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateCurrency.bind(this, null)}>{t("all")}</button>
                                                            </li>
                                                            {
                                                                currencies.map((item, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateCurrency.bind(this, index)}>{item.valName}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                        <div className="dashboard-transfer-search-form-section-item">
                                            <button className="dashboard-transfer-search-form-section-item-button" onClick={() => { this.getTransferList(); this.setState({ currentPage: 1, lastPage: 0 }); }}>{t("Search")}</button>
                                        </div>
                                    </div>
                                    {
                                        transfers && transfers?.filter(item => item.transferTypeId === 1).length !== 0 ?
                                            <div className="dashboard-finance-section-cards-section">
                                                <div className="dashboard-finance-section-cards-section-table-section" style={{ paddingTop: 0 }}>
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody>
                                                            {
                                                                transfers?.filter(item => item.transferTypeId === 1)?.map((transfer, index) =>
                                                                    <Fragment key={index}>
                                                                        <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                            <td>
                                                                                <Link to={`/dashboard/transfer/card-operation/zk-transfer/history/${transfer.id}`} className="dashboard-finance-section-cards-section-cell">
                                                                                    <div className="dashboard-finance-section-account-extract-section-list-extract-item-icon-container" style={{ backgroundColor: '#0179D2' }}>
                                                                                        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M16.875 4.28125C17.0312 4.125 17.0312 3.90625 16.875 3.75L13.75 0.625C13.5938 0.46875 13.3438 0.46875 13.2188 0.625L12.5938 1.25C12.4375 1.375 12.4375 1.625 12.5938 1.78125L14.1875 3.25H1.375C1.15625 3.25 1 3.4375 1 3.625V4.375C1 4.59375 1.15625 4.75 1.375 4.75H14.1875L12.5938 6.25C12.4375 6.40625 12.4375 6.65625 12.5938 6.78125L13.2188 7.40625C13.3438 7.5625 13.5938 7.5625 13.75 7.40625L16.875 4.28125ZM1.09375 10.2812L4.21875 13.4062C4.375 13.5625 4.625 13.5625 4.75 13.4062L5.375 12.7812C5.53125 12.6562 5.53125 12.4062 5.375 12.25L3.78125 10.75H16.625C16.8125 10.75 17 10.5938 17 10.375V9.625C17 9.4375 16.8125 9.25 16.625 9.25H3.78125L5.375 7.78125C5.53125 7.625 5.53125 7.375 5.375 7.25L4.75 6.625C4.625 6.46875 4.375 6.46875 4.21875 6.625L1.09375 9.75C0.9375 9.90625 0.9375 10.125 1.09375 10.2812Z" fill="white" />
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                        <div className="dashboard-finance-section-account-extract-section-list-extract-item-date-container">
                                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-title">{transfer.dataDate && this.changeDateType(transfer.dataDate).date}</div>
                                                                                            <p className="dashboard-transfer-history-content-item-container-iban">{transfer.dataDate && this.changeDateType(transfer.dataDate).time}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <Link to={`/dashboard/transfer/card-operation/zk-transfer/history/${transfer.id}`}>
                                                                                    <p className="dashboard-transfer-history-content-item-container-name">{transfer.crSurname} {transfer.crName} {transfer.crFname}</p>
                                                                                </Link>
                                                                                <Link to={`/dashboard/transfer/card-operation/zk-transfer/history/${transfer.id}`}>
                                                                                    <p className="dashboard-transfer-history-content-item-container-iban">{transfer.country}, {transfer.city}</p>
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={`/dashboard/transfer/card-operation/zk-transfer/history/${transfer.id}`} className="dashboard-finance-section-cards-section-cell">
                                                                                    <div className="dashboard-transfer-history-content-item-container">
                                                                                        <div className="dashboard-transfer-history-content-item-container-content">
                                                                                            <div className="dashboard-transfer-history-content-item-container-amount">
                                                                                                <span className="dashboard-transfer-history-content-item-container-amount-whole">{transfer.organizedAmount.wholePart && transfer.organizedAmount.wholePart}</span>
                                                                                                <span className="dashboard-transfer-history-content-item-container-amount-fractional">{transfer.organizedAmount.fractionalPart && transfer.organizedAmount.fractionalPart}</span>
                                                                                                <span className="dashboard-transfer-history-content-item-container-amount-currency">{transfer?.currency}</span>
                                                                                            </div>
                                                                                            <p style={{
                                                                                                color:
                                                                                                    transfer.mtStateId === 9 ? '#61BA66' : transfer.mtStateId === 7 ? '#F4B34D' : '#EF3E42'
                                                                                            }} className="dashboard-transfer-history-content-item-container-type">{transfer.description}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>

                                                                        <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                        <ul className="dashboard-pagination">
                                                            {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                            {
                                                                Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                                    if (this.state.lastPage > 3) {
                                                                        if (this.state.currentPage - 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                                    {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (this.state.currentPage + 1 == index + 1) {
                                                                            return (
                                                                                <>
                                                                                    <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                    {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                                    {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                                </>
                                                                            )
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                        )
                                                                    }
                                                                })

                                                            }
                                                            {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="dashboard-last-operations-section-content">
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default translate("translation")(ZkTransfer);
