import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import cardsIcon from './../../img/cards-icon.png';
import masterCardIcon from './../../img/card-icon.png';
import visaCardIcon from './../../img/visa-icon.svg'
import { request } from './../../../../config';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import CardModal from './../../../../copms/Modal/CardModal.js';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import PinUnblockSuccess from '../../../../copms/Modal/PinUnblockSuccess';
import { translate } from 'react-i18next';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';
import AddNewCardModal from './../CardStorage/AddNewCardModal/AddNewCardModal';
import DigitalCardInfoModal from '../DigitalCard/DigitalCardInfoModal/DigitalCardInfoModal';

import { cardTypes, getDigitalCardInfo , maskCardNumber, setPaymentTypeIcon, deleteOtherBankCard} from '../../../../helpers/cards';

class Cards extends Component {

    state = {
        cards: [],
        totalAmountAZN: 0,
        totalAmountUSD: 0,
        totalAmountEUR: 0,
        blockCardId: null,
        open: false,
        modalCardObj: {},
        isLoading: false,
        successModal: false,
        successModalData: null,
        unblockPinModalOpen: false,
        pinUnblockAmount: null,
        openAddNewCardModal: false,
        openDigitalCardInfoModal: false,
        otherBankCards : [],
        openDeleteOtherCardModal: false,
        visibleCashBack: false
    }

    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    numberToString = (number) => {
        if (number.toString() === "0") {
            return "0.00"
        }
        else {
            if (number.toString().indexOf('.') !== -1) {
                let numberArray = number.toString().split('.');
                let x = (numberArray[1].length === 1) ? (numberArray[1] + '0') : numberArray[1];
                return numberArray[0] + "." + x;
            }
            else {
                return number.toString() + ".00"
            }
        }
    }
    showCards = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListForCustomerRequestBody;
        if (customerType === 'Individual') {
            getCardListForCustomerRequestBody = {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        }
        else {
            getCardListForCustomerRequestBody = {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        }
        // this.setState({custId: localStorage.getItem('custId'), token: localStorage.getItem('token')});
        fetch(request("card/getCardListFor" + customerType + "Customer", getCardListForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    let totalAmount = {
                        azn: 0,
                        usd: 0,
                        eur: 0
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========HOLDER=========
                        let holderArray = getCardListForCustomerResponse.cardList[i].holder.split(' ');
                        let lowercaseName = holderArray[0].toLowerCase();
                        let lowercaseSurname = holderArray[1].toLowerCase();
                        getCardListForCustomerResponse.cardList[i].organizedHolder = {
                            name: holderArray[0][0] + lowercaseName.substring(1, lowercaseName.length),
                            surname: holderArray[1][0] + lowercaseSurname.substring(1, lowercaseSurname.length)
                        };
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);
                        // =========EXPIRYDATE=========
                        let expiryDate = new Date(getCardListForCustomerResponse.cardList[i].expiryDate);
                        getCardListForCustomerResponse.cardList[i].organizedExpiryDate = {
                            month: (expiryDate.getMonth() < 9) ? ('0' + (expiryDate.getMonth() + 1)) : (expiryDate.getMonth() + 1),
                            year: expiryDate.getFullYear().toString().substring(2, 4)
                        }
                        // =========BALANCE=========
                        if (getCardListForCustomerResponse.cardList[i].balance.toString() === "0") {
                            getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getCardListForCustomerResponse.cardList[i].balance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getCardListForCustomerResponse.cardList[i].balance.toString().split('.');
                                getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                    wholePart: getCardListForCustomerResponse.cardList[i].balance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        getCardListForCustomerResponse.cardList[i].currencyIcon = currencyFilter(getCardListForCustomerResponse.cardList[i].currency);
                        // =========TOTALAMOUNT=========
                        if (getCardListForCustomerResponse.cardList[i].currency === "AZN") {
                            totalAmount.azn += getCardListForCustomerResponse.cardList[i].balance;
                        }
                        if (getCardListForCustomerResponse.cardList[i].currency === "USD") {
                            totalAmount.usd += getCardListForCustomerResponse.cardList[i].balance;

                        }
                        if (getCardListForCustomerResponse.cardList[i].currency === "EUR") {
                            totalAmount.eur += getCardListForCustomerResponse.cardList[i].balance;
                        }
                    }
                    getCardListForCustomerResponse?.otherCardList && this.setState({ otherBankCards : getCardListForCustomerResponse?.otherCardList})
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });

                    this.setState({ cards: getCardListForCustomerResponse.cardList });
                    this.setState({ pinUnblockAmount: getCardListForCustomerResponse.pinUnblockAmount });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    handleCardClick = (visibleCashBack) => {
        localStorage.setItem('visibleCashBack', visibleCashBack);
    }

    dropdownItemHandler = (index, card) => {
        switch (index) {
            case 2:
                this.setState({
                    open: true,
                    modalCardObj: card
                });
                break;
                case 5:
                this.setState({
                    openDigitalCardInfoModal: true,
                    modalCardObj: card
                });
                break;
            // case 3:
            //     if(this.state.pinUnblockAmount) {
            //         this.setState({ modalCardObj: card, unblockPinModalOpen: true });
            //     }
            //     else {
            //         this.setState({ modalCardObj: card }, () => {
            //             this.unlockPin();
            //         });
            //     }
            //     break;
        }
    }
    dropdownItemHandlerForDigital = (index, card) => {
        switch (index) {
            case 2:
                this.setState({
                    open: true,
                    modalCardObj: card
                });
                break;
            case 3:
                this.setState({
                    openDigitalCardInfoModal: true,
                    modalCardObj: card
                });
                break;
        }
    }

    closeSuccessAlertFunction = () => {
        return window.location.reload();
    }

    dropdownItemHandlerForOtherBankCard = (index, card) => {
        switch (index) {
          case 2:
            this.setState({
                openDeleteOtherCardModal: true,
                modalCardObj: card
            });
            break;
        }
    }
    toggleAddNewCardModal=()=>{
        this.setState({openAddNewCardModal: !this.state.openAddNewCardModal})
    }
    toggleDigitalCardInfoModal=()=>{
        this.setState({openDigitalCardInfoModal: !this.state.openDigitalCardInfoModal})
    }
    sendDigitalCardInfoSms = () =>{
        getDigitalCardInfo(
          this.props.updateAppState, 
          this.state.modalCardObj.cardId, 
          this.props.logout, 
          this.props.alertState, 
          this.props.t);
     }
    setDropdownItems = (card) => {
       if (card?.cardStatus) {
         if (card?.cardType === cardTypes.DIGITAL_CARD) {
           return (
             <Dropdown
               dropdownItems={[
                 {
                   title: this.props.t("extract"),
                   url: "/dashboard/finance/cards/extract/" + card?.cardId,
                 },
                 {
                   title: this.props.t("details"),
                   url: "/dashboard/finance/cards/" + card?.cardId,
                 },
                 { title: this.props.t("block") },
                 { title: this.props.t("Card information") },
               ]}
               dropdownItemHandler={(i) =>
                 this.dropdownItemHandlerForDigital(i, card)
               }
             />
           );
         } else {
           return (
             <Dropdown
               dropdownItems={[
                 {
                   title: this.props.t("extract"),
                   url: "/dashboard/finance/cards/extract/" + card?.cardId,
                 },
                 {
                   title: this.props.t("details"),
                   url: "/dashboard/finance/cards/" + card?.cardId,
                 },
                 { title: this.props.t("block") },
                 {
                   title: this.props.t("pin_settings"),
                   url: "/dashboard/finance/cards/pin/settings/" + card?.cardId,
                 },
                 {
                   title: this.props.t("Limits management"),
                   url: `/dashboard/finance/cards/limits/${card?.currency}/${card?.cardId}`,
                 },
               ]}
               dropdownItemHandler={(i) => this.dropdownItemHandler(i, card)}
             />
           );
         }
       }else{
        return <Dropdown
                 dropdownItems={[
                     { title: this.props.t('extract'), disable: true },
                     { title: this.props.t('details'), disable: true },
                     { title: this.props.t('unblock')},
                     { title: this.props.t('Unblock pin'), disable: true },
                 ]}
                 dropdownItemHandler={(i) => this.dropdownItemHandler(i, card)} />
       }
    }

    // unlockPin = () => {
    //     if(this.state.pinUnblockAmount) {
    //         this.setState({ unblockPinModalOpen: true });
    //     }
    //     else {
    //         this.unblockFunc();
    //     }
    // }

    // unblockFunc = () => {
    //     this.props.updateAppState('isLoadingTransparentBackground', true);
    //     let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
    //     let cardPinCodeUnblockRequestBody = customerType == 'Juridical' ? {
    //         custId: localStorage.getItem('custId'),
    //         compId: localStorage.getItem('compId'),
    //         token: localStorage.getItem('token'),
    //         lang: localStorage.getItem('i18nextLng').toUpperCase(),
    //         cardNumber: this.state.modalCardObj.cardNumber,
    //         cardId: this.state.modalCardObj.cardId,
    //         currency: this.state.modalCardObj.currency
    //     } : {
    //         custId: localStorage.getItem('custId'),
    //         token: localStorage.getItem('token'),
    //         lang: localStorage.getItem('i18nextLng').toUpperCase(),
    //         cardNumber: this.state.modalCardObj.cardNumber,
    //         cardId: this.state.modalCardObj.cardId,
    //         currency: this.state.modalCardObj.currency
    //     }
    //     fetch(request("card/cardPinCodeUnblock", cardPinCodeUnblockRequestBody))
    //         .then(res => res.text())
    //         .then((cardPinCodeUnblockResponseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let cardPinCodeUnblockResponse = JSON.parse(cardPinCodeUnblockResponseJSON);
    //             if (cardPinCodeUnblockResponse.status.statusCode === 1) {
    //                 // =========FEE=========
    //                 let organizedFeeAmount;
    //                 if (cardPinCodeUnblockResponse.feeAmount.toString() === "0") {
    //                     organizedFeeAmount = {
    //                         wholePart: "0",
    //                         fractionalPart: "00"
    //                     }
    //                 }
    //                 else {
    //                     if (cardPinCodeUnblockResponse.feeAmount.toString().indexOf('.') !== -1) {
    //                         let currentBalanceArray = cardPinCodeUnblockResponse.feeAmount.toString().split('.');
    //                         organizedFeeAmount = {
    //                             wholePart: currentBalanceArray[0],
    //                             fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
    //                         }
    //                     }
    //                     else {
    //                         organizedFeeAmount = {
    //                             wholePart: cardPinCodeUnblockResponse.feeAmount.toString(),
    //                             fractionalPart: "00"
    //                         }
    //                     }
    //                 }
    //                 // ===============
    //                 let data = {
    //                     cardNumber: this.state.modalCardObj?.organizedCardNumber,
    //                     rrn: cardPinCodeUnblockResponse.rrn,
    //                     feeAmount: organizedFeeAmount,
    //                     billingDate: cardPinCodeUnblockResponse.billingDate,
    //                     pinUnblockAmount: cardPinCodeUnblockResponse.feeAmount
    //                 };
    //                 this.setState({ successModal: true, successModalData: data, isError: false, successTitle: this.props.t('Unblock pin success') });
    //             }
    //             else {
    //                 if (cardPinCodeUnblockResponse.status.statusCode === 200) {
    //                     this.props.logout();
    //                 } else {
    //                     this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(cardPinCodeUnblockResponse.status.statusCode));
    //                 }
    //             }
    //             this.props.updateAppState('isLoadingTransparentBackground', false);
    //         });
    // }

    // unblockConfirm(val) {
    //     if (val) {
    //         this.unblockFunc();
    //     }
    // }

    componentDidMount() {
        this.showCards();
    }

    render() {
        let { cards, otherBankCards, totalAmountAZN, totalAmountUSD, totalAmountEUR, open, modalCardObj, successModalData, successModal, unblockPinModalOpen, pinUnblockAmount, isLoading, openAddNewCardModal, openDigitalCardInfoModal, openDeleteOtherCardModal } = this.state;
        let { updateAppState, t ,logout, alertState, history} = this.props;
        return (
            <Fragment>
                <CardModal open={open} modalCardObj={modalCardObj} updateOpenModal={this.updateOpenModal} updateAppState={updateAppState} showCards={this.showCards} />
                <AddNewCardModal open={openAddNewCardModal} translate={t} toggleAddNewCardModal={this.toggleAddNewCardModal} />
                <DigitalCardInfoModal open={openDigitalCardInfoModal} translate={t} toggleDigitalCardInfoModal={this.toggleDigitalCardInfoModal} sendDigitalCardInfoSms = {this.sendDigitalCardInfoSms}/>
                <YesNoModal onClose={() => this.setState({ openDeleteOtherCardModal: false })} title={t("Delete card?")} open={openDeleteOtherCardModal} triggerFunc={() => deleteOtherBankCard(updateAppState, modalCardObj.cardId, logout, alertState, t, false, this.closeSuccessAlertFunction,history )} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('cards')}</h2>
                    </div>
                    <div className="dashboard-finance-cards-route"> 
                        { localStorage.getItem('compId') === null && <button className="dashboard-cards-add-new-card-btn" onClick={this.toggleAddNewCardModal}>+ {t("New card")} </button> }
                        <a href={`http://www.turanbank.az/${localStorage.getItem('i18nextLng').toLowerCase() ? localStorage.getItem('i18nextLng').toLowerCase() : 'AZ'}/pages/359`} target="blank" className="dashboard-header-route-link">{t('CARD PRODUCTS')}</a>
                    </div>
                </div>
                <div className="dashboard-container-main-section dashboard-container-main-section-cards">
                    {
                        isLoading ?
                            <Loading />
                            :
                            (cards.length === 0 && otherBankCards.length === 0) ?
                                <div className="dashboard-finance-section-invisible-cards-section">
                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have an active card.')}</p>
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('if you want a new card, please order it.')}</p>
                                    </div>
                                    <Link to="/dashboard/orders/plastic-card" className="dashboard-finance-section-invisible-cards-section-button">{t('ORDER A NEW CARD')}</Link>
                                </div>
                                :
                                <div className="dashboard-finance-section-cards-section">
                                    {
                                        cards.length > 0 && 
                                    <div className="dashboard-finance-section-cards-section-statistics-section">

                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon dashboard-finance-section-cards-section-statistics-icon__azn">₼</span>{totalAmountAZN}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("card balance")} (AZN)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#0066B2" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#0066B2" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">$</span>{totalAmountUSD}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("card balance")} (USD)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#6DD230" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#6DD230" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">€</span>{totalAmountEUR}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("card balance")} (EUR)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#FFAB2B" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FFAB2B" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className='dashboard-finance-section-cards'>
                                    {
                                        cards.length > 0 && 
                                    <div className="dashboard-finance-section-cards-section-table-section">
                                        <div className='dashboard-finance-section-cards-section-header'>
                                            <h6>Turan Bank kartlarım</h6>
                                        </div>
                                        <table className="dashboard-finance-section-cards-section-table">
                                            <tbody>
                                                {
                                                    cards.map((card, index) =>
                                                        <Fragment key={index}>
                                                            <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <img src={setPaymentTypeIcon(card.cardPaymentType, masterCardIcon, visaCardIcon)} className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-payment-type-icon" />

                                                                    {/* card.cardStatus === 1 ?  */}
                                                                    <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                        <Link to={{ pathname: "/dashboard/finance/cards/" + card.cardId}} onClick={() => this.handleCardClick(card?.visibleCashBack)} className="dashboard-finance-section-cards-section-cell-content">{card.organizedCardNumber}</Link>
                                                                        <Link to={{ pathname: "/dashboard/finance/cards/" + card.cardId}} onClick={() => this.handleCardClick(card?.visibleCashBack)} className="dashboard-finance-section-cards-section-cell-title">{card.cardType}</Link>
                                                                    </div>
                                                                    {/* : 
                                                            <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                <Link to={"/dashboard/finance/cards/" + card.cardId} className="dashboard-finance-section-cards-section-cell-content dashboard-finance-section-cards-section-cell-content__disable">{card.organizedCardNumber}</Link>
                                                                <Link to={"/dashboard/finance/cards/" + card.cardId} className="dashboard-finance-section-cards-section-cell-title dashboard-finance-section-cards-section-cell-title__disable">{card.cardType}</Link>
                                                            </div> */}

                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content">
                                                                        <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + card.currency.toLowerCase()}>{card.currencyIcon}</span>
                                                                        <span className="dashboard-finance-section-cards-section-amount-whole-part">{card.organizedCurrentBalance.wholePart}</span>
                                                                        <span className="dashboard-finance-section-cards-section-amount-fractional-part">{card.organizedCurrentBalance.fractionalPart}</span>
                                                                    </p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t('balance')}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content">{card.organizedExpiryDate.month}/{card.organizedExpiryDate.year}</p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t('it is reliable')}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content">
                                                                        {
                                                                            (card.cardStatus === 1) ?
                                                                                <span>{t('active')}</span>
                                                                                :
                                                                                <span>{t('blocked')}</span>
                                                                        }
                                                                    </p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t("Status")}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <div className="dashboard-finance-section-cards-section-button-group">
                                                                        {this.setDropdownItems(card)}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/* <tr className="dashboard-finance-row-line">
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                            </tr> */}
                                                        </Fragment>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    }
                                    {
                                        otherBankCards.length > 0 && 
                                        <div className="dashboard-finance-section-cards-section-other-bank-cards-table">
                                            <div className='dashboard-finance-section-cards-section-header'>
                                                <h6>Digər bank kartlarım</h6>
                                            </div>
                                            <table className="dashboard-finance-section-cards-section-table">
                                            <tbody>
                                            {
                                                    otherBankCards.map((card, index) =>
                                                        <Fragment key={index}>
                                                            <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <img src={setPaymentTypeIcon(card.cardPaymentType, masterCardIcon, visaCardIcon)} className="dashboard-finance-section-cards-section-cell-icon  dashboard-finance-section-cards-payment-type-icon" />
                                                                    <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                        <Link to={"/dashboard/finance/cards/" + card.cardId} className="dashboard-finance-section-cards-section-cell-content">{maskCardNumber(card.cardNumber)}</Link>
                                                                        <Link to={"/dashboard/finance/cards/" + card.cardId} className="dashboard-finance-section-cards-section-cell-title">{card.holder}</Link>
                                                                    </div>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content align-center">
                                                                        {
                                                                            (card.cardStatus === 1) ?
                                                                                <span>{t('active')}</span>
                                                                                :
                                                                                <span>{t('blocked')}</span>
                                                                        }
                                                                    </p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title align-center">{t("Status")}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <div className="dashboard-finance-section-cards-section-button-group">
                                                                        {
                                                                            (card.cardStatus === 1 ) ?
                                                                                <Dropdown className="center"
                                                                                    dropdownItems={[
                                                                                        { title: this.props.t('history'), url: '/dashboard/finance/cards/extract/' + card.cardId },
                                                                                        { title: this.props.t('payment'), url: "/dashboard/transfer/card-operation" },
                                                                                        { title: this.props.t('Delete')},                                                                                
                                                                                    ]}
                                                                                    dropdownItemHandler={(i) => this.dropdownItemHandlerForOtherBankCard(i, card)} />
                                                                                     :
                                                                                     <Dropdown
                                                                                     dropdownItems={[
                                                                                         { title: this.props.t('history'), disable: true },
                                                                                         { title: this.props.t('payment'), disable: true },
                                                                                         { title: this.props.t('Delete')},
                                                                                     ]}
                                                                                     dropdownItemHandler={(i) => this.dropdownItemHandlerForOtherBankCard(i, card)} />
                                                                                    
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/* <tr className="dashboard-finance-row-line">
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                            </tr> */}
                                                        </Fragment>
                                                    )
                                                }
                                            </tbody>
                                            </table>
                                        </div>
                                    }
                                    </div>
                                </div>
                    }
                </div>
            </Fragment>
        )
    }
}


export default translate("translation")(Cards);