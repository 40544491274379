import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import InputMask from "react-input-mask";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";

import { request } from "../../../config";
import AlertPopup from "./../../../copms/AlertPopup/AlertPopup";
import OutsideClickHandler from "react-outside-click-handler";
import CheckboxTick from "./../../../copms/Checkbox/CheckboxTickDefault";
import currencyFilter from "./../../../helpers/currencyFilter";
import RadioButton from "../../../copms/RadioButton/RadioButton";
import {
  tabTypes,
  tabs,
  orderCardPeriodType,
  customerOrderType,
} from "../../../helpers/services";
import { statusCodes } from "../../../helpers/statusCodes";

class CardExtensionRenewal extends Component {
  state = {
    open: false,
    popupType: null,
    title: null,
    feedbackMessage: null,

    confirmedOperation: false,
    organizedFeeAmount: {},
    orderTypeId: this.props.match.params.type == tabTypes.EXTENSION ? 3 : 5,
    smsNotificationTypeId: 1,
    idFrontStr: null,
    idBackStr: null,
    smsFeeAmount: null,
    commissionAmount: null,

    specialCards: [],
    selectedCardCount: 0,
    selectedCardIndex: -1,
    dropdownVisibilityCard: false,
    cardData: false,

    cards: [],
    selectedCommissionCardCount: 0,
    selectedCommissionCardIndex: -1,
    dropdownVisibilityCommissionCard: false,
    commissionCardData: false,

    branchs: [],
    selectedBranchCount: 0,
    selectedBranchIndex: -1,
    dropdownVisibilityBranch: false,
    branchData: false,

    mobileNumber: "",
    mobileNumberValidation: false,
    mobileNumberOutline: false,

    cardCondition: 0,
    periods: [],
    selectedPeriodIndex: -1,

    showText: null,
    minAmount: null,

    activeTabID:
      this.props.match.params.type == tabTypes.EXTENSION
        ? 1
        : this.props.match.params.type == tabTypes.RENEWAL
        ? 2
        : this.state.orderReceivingTypeId,

    orderCardPeriod: orderCardPeriodType.UNTIL_CURRENT_PERIOD,
    cardListType: 0,

    customerOrderType:
      this.props.match.params.type == tabTypes.EXTENSION ? 3 : 5,

    selectedRadioBtn: 1,
    selectedRadioBtnForRenewalReason: 1,
  };

  getPeriod = (type) => {
    switch (type) {
      case 1004:
        return "month";
      case 1005:
        return "day";
      case 1006:
        return "year";
      default:
        return null;
    }
  };

  resetForm = () => {
    this.setState(
      {
        open: false,
        popupType: null,
        title: null,
        feedbackMessage: null,

        confirmedOperation: false,
        organizedFeeAmount: {},
        smsNotificationTypeId: 1,
        idFrontStr: null,
        idBackStr: null,
        smsFeeAmount: null,
        commissionAmount: null,

        selectedCardCount: 0,
        selectedCardIndex: -1,
        dropdownVisibilityCard: false,

        selectedCommissionCardCount: 0,
        selectedCommissionCardIndex: -1,
        dropdownVisibilityCommissionCard: false,

        selectedBranchCount: 0,
        selectedBranchIndex: -1,
        dropdownVisibilityBranch: false,

        mobileNumber: "",
        mobileNumberValidation: false,
        mobileNumberOutline: false,

        cardCondition: 0,
        periods: [],
        selectedPeriodIndex: -1,

        showText: null,
        minAmount: null,
      },
      () => {
        this.showSpecialCards(true);
      }
    );
  };

  showCards = async () => {
    let getCardListCustomerRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
    };
    if (localStorage.getItem("compId")) {
      getCardListCustomerRequestBody.compId = localStorage.getItem("compId");
    }
    return await fetch(
      request(
        "card/getCardListFor" +
          (localStorage.getItem("compId") ? "Juridical" : "Individual") +
          "Customer",
        getCardListCustomerRequestBody
      )
    )
      .then((res) => res.text())
      .then(
        (getCardListForCustomerResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getCardListForCustomerResponse = JSON.parse(
            getCardListForCustomerResponseJSON
          );
          if (
            getCardListForCustomerResponse.status.statusCode !== 1 &&
            getCardListForCustomerResponse.status.statusCode !== 137
          ) {
            if (getCardListForCustomerResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(getCardListForCustomerResponse.status.statusCode)
              );
            }
          } else {
            if (getCardListForCustomerResponse.status.statusCode === 137) {
              getCardListForCustomerResponse.cardList = [];
            }
            for (
              let i = 0;
              i < getCardListForCustomerResponse.cardList.length;
              i++
            ) {
              // =========NUMBER BALANCE=========
              getCardListForCustomerResponse.cardList[
                i
              ].organizedCardNumberBalance =
                getCardListForCustomerResponse.cardList[i].cardNumber.substring(
                  0,
                  4
                ) +
                " **** **** " +
                getCardListForCustomerResponse.cardList[i].cardNumber.substring(
                  12,
                  16
                ) +
                " / " +
                getCardListForCustomerResponse.cardList[i].balance +
                getCardListForCustomerResponse.cardList[i].currency;
              // =========NUMBER=========
              getCardListForCustomerResponse.cardList[i].organizedCardNumber =
                getCardListForCustomerResponse.cardList[i].cardNumber.substring(
                  0,
                  4
                ) +
                " **** **** " +
                getCardListForCustomerResponse.cardList[i].cardNumber.substring(
                  12,
                  16
                );
            }
            this.setState({
              cards: getCardListForCustomerResponse.cardList,
              cardData: true,
            });
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  showSpecialCards = async (initial) => {
    if (initial) {
      this.props.updateAppState("isLoadingTransparentBackground", true);
    }
    let getCardListCustomerRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
      // cardListType: this.state.orderTypeId === 3 ? 1 : 0,
      cardListType:
        this.props.match.params.type == tabTypes.EXTENSION
          ? 1
          : this.state.cardListType,
    };
    if (localStorage.getItem("compId")) {
      getCardListCustomerRequestBody.compId = localStorage.getItem("compId");
    }
    return await fetch(
      request(
        "card/getSpecialCardListFor" +
          (localStorage.getItem("compId") ? "Juridical" : "Individual") +
          "Customer",
        getCardListCustomerRequestBody
      )
    )
      .then((res) => res.text())
      .then(
        (getCardListForCustomerResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getCardListForCustomerResponse = JSON.parse(
            getCardListForCustomerResponseJSON
          );
          if (
            getCardListForCustomerResponse.status.statusCode !== 1 &&
            getCardListForCustomerResponse.status.statusCode !== 137
          ) {
            if (getCardListForCustomerResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              getCardListForCustomerResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ? 
              this.props.alertState(
                true,
                "warning",
                this.props.t("Information"),
                this.props.t(getCardListForCustomerResponse.status.statusCode),
                null,
                true
              ) :  this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(getCardListForCustomerResponse.status.statusCode)
              ) 
            }
          } else {
            if (getCardListForCustomerResponse.status.statusCode === 137) {
              getCardListForCustomerResponse.cardList = [];
            }
            for (
              let i = 0;
              i < getCardListForCustomerResponse.cardList.length;
              i++
            ) {
              // =========ID=========
              let cardId = "";
              if (
                typeof getCardListForCustomerResponse.cardList[i].cardId ===
                  "object" &&
                getCardListForCustomerResponse.account[i].cardId.hasOwnProperty(
                  "c"
                )
              ) {
                getCardListForCustomerResponse.account[i].id.c.forEach(
                  (part) => {
                    cardId += part;
                  }
                );
              } else {
                cardId += getCardListForCustomerResponse.cardList[i].cardId;
              }
              getCardListForCustomerResponse.cardList[i].cardId = cardId;
              // =========NUMBER=========
              getCardListForCustomerResponse.cardList[i].organizedCardNumber =
                getCardListForCustomerResponse.cardList[i].cardNumber.substring(
                  0,
                  4
                ) +
                " **** **** " +
                getCardListForCustomerResponse.cardList[i].cardNumber.substring(
                  12,
                  16
                ) +
                " / " +
                getCardListForCustomerResponse.cardList[i].balance +
                getCardListForCustomerResponse.cardList[i].currency;
            }
            this.state.orderTypeId == 4
              ? this.setState({
                  specialCards: getCardListForCustomerResponse.cardList.filter(
                    (item) => item.cardStatus
                  ),
                  specialCardData: true,
                })
              : this.setState({
                  specialCards: getCardListForCustomerResponse.cardList,
                  specialCardData: true,
                });
          }
          this.props.updateAppState("isLoadingTransparentBackground", false);
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  showBranchs = async () => {
    let getBranchMapListRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
    };
    if (localStorage.getItem("compId")) {
      getBranchMapListRequestBody.compId = localStorage.getItem("compId");
    }
    return await fetch(
      request(
        "util/getBranchMapList/" +
          localStorage.getItem("i18nextLng").toUpperCase(),
        getBranchMapListRequestBody
      )
    )
      .then((res) => res.text())
      .then(
        (getBranchMapListResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getBranchMapListResponse = JSON.parse(
            getBranchMapListResponseJSON
          );
          if (getBranchMapListResponse.status.statusCode !== 1) {
            if (getBranchMapListResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(getBranchMapListResponse.status.statusCode)
              );
            }
          } else {
            this.setState({
              branchs: getBranchMapListResponse.respBranchMapList,
              branchData: true,
            });
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  showOrderCommission = async (initial) => {
    this.setState({ commissionAmount: null });
    if (initial) {
      this.props.updateAppState("isLoadingTransparentBackground", true);
    }
    let getCustomerOrderCommissionRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
      orderTypeId: this.state.orderTypeId,
      cardId: this.state.specialCards[this.state.selectedCardIndex].cardId,
      period: this.state.periods[this.state.selectedPeriodIndex].period,
      periodType: this.state.periods[this.state.selectedPeriodIndex].periodType,
      customerOrderType: this.state.customerOrderType,
      orderCardPeriodType:
        this.props.match.params.type == tabTypes.EXTENSION
          ? orderCardPeriodType.EXTENSION_NEW_PERIOD
          : this.state.orderCardPeriod,
    };
    if (localStorage.getItem("compId")) {
      getCustomerOrderCommissionRequestBody.compId =
        localStorage.getItem("compId");
    }
    return await fetch(
      request("card/getCardCommission", getCustomerOrderCommissionRequestBody)
    )
      .then((res) => res.text())
      .then(
        (getCustomerOrderCommissionResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getCustomerOrderCommissionResponse = JSON.parse(
            getCustomerOrderCommissionResponseJSON
          );
          if (getCustomerOrderCommissionResponse.status.statusCode !== 1) {
            if (getCustomerOrderCommissionResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(
                  getCustomerOrderCommissionResponse.status.statusCode
                )
              );
            }
          } else {
            this.setState({
              commissionAmount: {
                feeAmount: getCustomerOrderCommissionResponse.commissionAmount,
                currency: getCustomerOrderCommissionResponse.currency,
                curCode: getCustomerOrderCommissionResponse.curCode,
              },
              showText: getCustomerOrderCommissionResponse.showText,
              minAmount: getCustomerOrderCommissionResponse.minAmount,
              cardCondition:
                getCustomerOrderCommissionResponse.showText === 1 &&
                getCustomerOrderCommissionResponse.commissionAmount === 0
                  ? 1
                  : 0,
            });
          }
          this.props.updateAppState("isLoadingTransparentBackground", false);
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  showSmsOrderCommission = async () => {
    this.setState({ smsFeeAmount: null });
    let getCustomerOrderCommissionRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
      name: "azercardsms",
    };
    if (localStorage.getItem("compId")) {
      getCustomerOrderCommissionRequestBody.compId =
        localStorage.getItem("compId");
    }
    return await fetch(
      request(
        "order/getNotificationCommission",
        getCustomerOrderCommissionRequestBody
      )
    )
      .then((res) => res.text())
      .then(
        (getCustomerOrderCommissionResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getCustomerOrderCommissionResponse = JSON.parse(
            getCustomerOrderCommissionResponseJSON
          );
          if (getCustomerOrderCommissionResponse.status.statusCode !== 1) {
            if (getCustomerOrderCommissionResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              getCustomerOrderCommissionResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
              this.props.alertState(
                true,
                "warning",
                this.props.t("Information"),
                this.props.t(
                  getCustomerOrderCommissionResponse.status.statusCode
                ),
                null,
                true
              ) :
              this.props.alertState(
                true,
                "error",
                this.props.t("an error occurred"),
                this.props.t(
                  getCustomerOrderCommissionResponse.status.statusCode
                )
              ) 
            }
          } else {
            this.setState({
              smsFeeAmount: {
                feeAmount: getCustomerOrderCommissionResponse.feeAmount,
                currency: getCustomerOrderCommissionResponse.currencyName,
                curCode: getCustomerOrderCommissionResponse.curCode,
              },
            });
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  updateCard(index) {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    this.setState(
      {
        selectedCardCount: 0,
        selectedCardIndex: index,
        dropdownVisibilityCard: false,
        organizedFeeAmount: {},
        commissionAmount: null,
        cardCondition: 0,
        periods: [],
        selectedPeriodIndex: -1,
        showText: null,
        minAmount: null,
      },
      () => {
        let getCardInfoRequestBody = {
          custId: localStorage.getItem("custId"),
          token: localStorage.getItem("token"),
          lang: localStorage.getItem("i18nextLng").toUpperCase(),
          cardId: this.state.specialCards[this.state.selectedCardIndex].cardId,
        };
        if (localStorage.getItem("compId")) {
          getCardInfoRequestBody.compId = localStorage.getItem("compId");
        }
        return fetch(
          request("card/getCardOrderPeriodInfo", getCardInfoRequestBody)
        )
          .then((res) => res.text())
          .then(
            (getCardInfoResponseJSON) => {
              const JSON = require("true-json-bigint");
              let getCardInfoResponse = JSON.parse(getCardInfoResponseJSON);
              if (getCardInfoResponse.status.statusCode !== 1) {
                if (getCardInfoResponse.status.statusCode === 200) {
                  this.props.logout();
                } else {
                  this.props.alertState(
                    true,
                    "error",
                    this.props.t("an error occurred"),
                    this.props.t(getCardInfoResponse.status.statusCode)
                  );
                }
              } else {
                this.setState(
                  {
                    periods: getCardInfoResponse.periodInfoList,
                    selectedPeriodIndex: 0,
                  },
                  () => {
                    this.showOrderCommission();
                  }
                );
              }
            },
            (error) => {
              this.props.history.push("/technical-break");
            }
          );
      }
    );
  }

  updateCommissionCard(index) {
    this.setState({
      selectedCommissionCardIndex: index,
      dropdownVisibilityCommissionCard: false,
    });
  }

  updateBranch(index) {
    this.setState({
      selectedBranchIndex: index,
      dropdownVisibilityBranch: false,
    });
  }

  changeDropdownVisibilityCard = () => {
    this.setState(
      { dropdownVisibilityCard: !this.state.dropdownVisibilityCard },
      () => {
        if (this.state.selectedCardIndex < 0) {
          this.setState({ selectedCardCount: ++this.state.selectedCardCount });
        }
      }
    );
  };

  changeDropdownVisibilityCommissionCard = () => {
    this.setState(
      {
        dropdownVisibilityCommissionCard:
          !this.state.dropdownVisibilityCommissionCard,
      },
      () => {
        if (this.state.selectedCommissionCardIndex < 0) {
          this.setState({
            selectedCommissionCardCount: ++this.state
              .selectedCommissionCardCount,
          });
        }
      }
    );
  };

  changeDropdownVisibilityBranch = () => {
    this.setState(
      { dropdownVisibilityBranch: !this.state.dropdownVisibilityBranch },
      () => {
        if (this.state.selectedBranchIndex < 0) {
          this.setState({
            selectedBranchCount: ++this.state.selectedBranchCount,
          });
        }
      }
    );
  };

  getPhoneNumber = (number) => {
    ["+", "-", "(", ")", " "].forEach((char) => {
      while (number.indexOf(char) !== -1) {
        number =
          number.slice(0, number.indexOf(char)) +
          number.slice(number.indexOf(char) + 1, number.length);
      }
    });
    return number;
  };

  checkMobileNumber = (event) => {
    this.setState({ mobileNumber: event.target.value });
    if (event.target.value.indexOf("_") === -1) {
      this.setState({ mobileNumberValidation: true });
      this.setState({ mobileNumberOutline: false });
    } else {
      this.setState({ mobileNumberValidation: false });
    }
  };

  setMobileNumberOutline = () => {
    if (!this.state.mobileNumberValidation) {
      this.setState({ mobileNumberOutline: true });
    } else {
      this.setState({ mobileNumberOutline: false });
    }
  };

  calcCommission = () => {
    let organizedFeeAmount,
      totalFeeAmount = this.state.commissionAmount.feeAmount;
    if (totalFeeAmount.toString() === "0") {
      organizedFeeAmount = {
        wholePart: "0",
        fractionalPart: "00",
      };
    } else {
      if (totalFeeAmount.toString().indexOf(".") !== -1) {
        let currentBalanceArray = totalFeeAmount.toString().split(".");
        organizedFeeAmount = {
          wholePart: currentBalanceArray[0],
          fractionalPart:
            currentBalanceArray[1].length === 1
              ? currentBalanceArray[1] + "0"
              : currentBalanceArray[1],
        };
      } else {
        organizedFeeAmount = {
          wholePart: totalFeeAmount.toString(),
          fractionalPart: "00",
        };
      }
    }
    this.setState({ organizedFeeAmount: organizedFeeAmount });
  };

  submitForm = () => {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    let createCustomerOrderRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
      cardId: this.state.cards[this.state.selectedCommissionCardIndex]?.cardId,
      amount: this.state.commissionAmount.feeAmount,
      cardCondition: this.state.cardCondition,
      currency: this.state.commissionAmount.currency,
      curCode: this.state.commissionAmount.curCode,
      smsAmount:
        this.state.smsNotificationTypeId === 1
          ? this.state.smsFeeAmount.feeAmount
          : 0,
      cardAmount: this.state.commissionAmount.feeAmount,
      branchId: this.state.branchs[this.state.selectedBranchIndex].branchId,
      //   period: this.state.periods[this.state.selectedPeriodIndex].period,
      //   periodType: this.state.periods[this.state.selectedPeriodIndex].periodType,
      orderCardId:
        this.state.specialCards[this.state.selectedCardIndex]?.cardId,
      orderTypeId: this.state.orderTypeId,
      smsNotificationTypeId: this.state.smsNotificationTypeId,
      idFrontStr: this.state.idFrontStr,
      idBackStr: this.state.idBackStr,
      mobileNumber:
        this.state.smsNotificationTypeId === 1
          ? this.getPhoneNumber(this.state.mobileNumber)
          : null,
      orderCardPeriodType:
        this.props.match.params.type == tabTypes.EXTENSION
          ? orderCardPeriodType.EXTENSION_NEW_PERIOD
          : this.state.orderCardPeriod,
    };
    if (localStorage.getItem("compId")) {
      createCustomerOrderRequestBody.compId = localStorage.getItem("compId");
    }
    if (
      this.state.orderCardPeriod == orderCardPeriodType.EXTENSION_NEW_PERIOD ||
      this.props.match.params.type == tabTypes.EXTENSION
    ) {
      createCustomerOrderRequestBody.period =
        this.state.periods[this.state.selectedPeriodIndex].period;
      createCustomerOrderRequestBody.periodType =
        this.state.periods[this.state.selectedPeriodIndex].periodType;
    }
    fetch(request("order/createCustomerOrder", createCustomerOrderRequestBody))
      .then((res) => res.text())
      .then(
        (createCustomerOrderResponseJSON) => {
          const JSON = require("true-json-bigint");
          let createCustomerOrderResponse = JSON.parse(
            createCustomerOrderResponseJSON
          );
          if (createCustomerOrderResponse.status.statusCode !== 1) {
            if (createCustomerOrderResponse.status.statusCode === 200) {
              this.props.logout();
            } else {
              this.setState({
                open: true,
                popupType: "error",
                title: this.props.t("an error occurred"),
                feedbackMessage: this.props.t(
                  createCustomerOrderResponse.status.statusCode
                ),
              });
              this.props.updateAppState(
                "isLoadingTransparentBackground",
                false
              );
            }
          } else {
            this.setState({
              open: true,
              popupType: "success",
              title: this.props.t("successful operation"),
              feedbackMessage: this.props.t(
                "You will be notified when your order is ready"
              ),
            });
            this.props.updateAppState("isLoadingTransparentBackground", false);
          }
        },
        (error) => {
          this.props.history.push("/technical-break");
        }
      );
  };

  closeSuccessFunction = () => {
    this.props.history.push("/");
  };

  componentDidUpdate(previousProps) {
    if (previousProps.match.params.type !== this.props.match.params.type) {
      this.setState({
        activeTabID:
          this.props.match.params.type == tabTypes.EXTENSION
            ? 1
            : this.props.match.params.type == tabTypes.RENEWAL
            ? 2
            : this.state.activeTabID,
        orderTypeId: this.props.match.params.type == tabTypes.EXTENSION ? 3 : 5,
        // selectedRadioBtn: 1,
        // selectedRadioBtnForRenewalReason: 1,
        customerOrderType:
          this.props.match.params.type == tabTypes.EXTENSION ? 3 : 5,
      });
      this.resetForm();
      this.showSmsOrderCommission();
    }
  }

  componentDidMount = async () => {
    this.props.updateAppState("isLoadingTransparentBackground", true);
    await this.showCards();
    await this.showSpecialCards();
    await this.showBranchs();
    await this.showSmsOrderCommission();
    const readURL = (input, type) => {
      if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = (event) => {
          let bytes = event.target.result;
          let bytesStr = bytes.split("base64,")[1];
          this.setState({ [type]: bytesStr });
        };
        reader.readAsDataURL(input.files[0]);
      }
    };
    $("#idFrontStr").change(function () {
      readURL(this, "idFrontStr");
    });
    $("#idBackStr").change(function () {
      readURL(this, "idBackStr");
    });
    this.props.updateAppState("isLoadingTransparentBackground", false);
  };

  render() {
    let {
      open,
      popupType,
      title,
      feedbackMessage,
      confirmedOperation,
      orderTypeId,
      smsNotificationTypeId,
      mobileNumber,
      mobileNumberValidation,
      mobileNumberOutline,
      specialCards,
      specialCardData,
      cards,
      cardData,
      idFrontStr,
      idBackStr,
      smsFeeAmount,
      commissionAmount,
      organizedFeeAmount,
      selectedCardCount,
      selectedCardIndex,
      dropdownVisibilityCard,
      selectedCommissionCardCount,
      selectedCommissionCardIndex,
      dropdownVisibilityCommissionCard,
      branchs,
      selectedBranchCount,
      selectedBranchIndex,
      dropdownVisibilityBranch,
      branchData,
      periods,
      selectedPeriodIndex,
      showText,
      minAmount,
      activeTabID,
      orderCardPeriod,
      selectedRadioBtn,
      selectedRadioBtnForRenewalReason,
      customerOrderType,
    } = this.state;
    let { t } = this.props;

    return (
      <Fragment>
        <AlertPopup
          open={open}
          closeFunction={() =>
            this.setState({ open: false, confirmedOperation: false })
          }
          popupType={popupType}
          title={title}
          feedbackMessage={feedbackMessage}
          closeSuccessFunction={this.closeSuccessFunction}
        />
        <div className="dashboard-header-route">
          <div className="dashboard-header-route-left">
            <button onClick={() => this.props.history.goBack()}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z"
                  fill="#2E3131"
                />
              </svg>
            </button>
            <h2>{t("Card extension renewal")}</h2>
          </div>
        </div>
        <div className="dashboard-payment-container ibank-services">
          <div
            className={`dashboard-payment-container-data-section ${
              confirmedOperation
                ? ""
                : "dashboard-payment-container-data-section--empty"
            }`}
          >
            <div style={{ width: "100%", marginBottom: "22px" }}>
              <div className="dashboard-transfer-list-header">
                <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                  {tabs?.map(({ id, path, title, icon, alt, warningPopup }) => {
                    return (
                      <div
                        key={id}
                        className={`dashboard-services-header-link__tab ${
                          activeTabID === id
                            ? "dashboard-services-header-link__active__tab"
                            : ""
                        }`}
                      >
                        <NavLink
                          exact
                          to={path}
                          className="dashboard-transfer-list-header-link"
                          activeClassName="dashboard-transfer-list-header-container dashboard-services-header-link__active"
                        >
                          {t(title)}
                        </NavLink>
                        <button
                          onClick={() =>
                            this.props.alertState(
                              true,
                              "warning",
                              null,
                              t(warningPopup)
                            )
                          }
                        >
                          <img src={icon} {...{ alt }} />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <form
                className={`dashboard-payment-container-data-section-content ${
                  confirmedOperation
                    ? ""
                    : "dashboard-payment-container-data-section-content--empty"
                }`}
              >
                <div
                  style={{ paddingTop: 0 }}
                  className="dashboard-payment-container-data-section-content-container"
                >
                  <div className="dashboard-payment-container-data-section-content-container-form">
                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                      {activeTabID === 2 ? (
                        <>
                          <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                            <label className="dashboard-payment-container-data-section-content-container-form-label">
                              {t("Select the reason for updating the card")}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <div className="ibank-services__radio-btns">
                              <div className="dashboard-payment-container-data-section-content-container-form-radioBtn">
                                <RadioButton
                                  disabled={confirmedOperation}
                                  value={1}
                                  selectedId={selectedRadioBtnForRenewalReason}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        selectedCardCount: 0,
                                        selectedCardIndex: -1,
                                        orderTypeId: 5,
                                        selectedRadioBtnForRenewalReason: 1,
                                        customerOrderType: 5,
                                        commissionAmount: null,
                                      },
                                      () => {
                                        this.showSpecialCards(true);
                                      }
                                    );
                                  }}
                                />
                                <span className="dashboard-payment-container-data-section-content-container-form-radioBtn-title">
                                  {t("The card is lost")}
                                </span>
                              </div>
                              <div className="dashboard-payment-container-data-section-content-container-form-radioBtn">
                                <RadioButton
                                  disabled={confirmedOperation}
                                  value={2}
                                  selectedId={selectedRadioBtnForRenewalReason}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        selectedCardCount: 0,
                                        selectedCardIndex: -1,
                                        orderTypeId: 4,
                                        selectedRadioBtnForRenewalReason: 2,
                                        customerOrderType: 4,
                                        commissionAmount: null,
                                      },
                                      () => {
                                        this.showSpecialCards(true);
                                      }
                                    );
                                  }}
                                />
                                <span className="dashboard-payment-container-data-section-content-container-form-radioBtn-title">
                                  {t("The card is damaged")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                          <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                            <label className="dashboard-payment-container-data-section-content-container-form-label">
                              {t("Card validity period")}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <div className="ibank-services__radio-btns">
                              <div className="dashboard-payment-container-data-section-content-container-form-radioBtn">
                                <RadioButton
                                  disabled={confirmedOperation}
                                  value={1}
                                  selectedId={selectedRadioBtn}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        // orderTypeId: 4,
                                        selectedCardCount: 0,
                                        selectedCardIndex: -1,
                                        dropdownVisibilityCard: false,
                                        organizedFeeAmount: {},
                                        commissionAmount: null,
                                        cardCondition: 0,
                                        periods: [],
                                        selectedPeriodIndex: -1,
                                        showText: null,
                                        minAmount: null,
                                        orderCardPeriod:
                                          orderCardPeriodType.UNTIL_CURRENT_PERIOD,
                                        cardListType: 0,
                                        selectedRadioBtn: 1,
                                      },
                                      () => {
                                        this.showSpecialCards(true);
                                      }
                                    );
                                  }}
                                />
                                <span className="dashboard-payment-container-data-section-content-container-form-radioBtn-title">
                                  {t("Until the current period")}
                                </span>
                              </div>
                              <div className="dashboard-payment-container-data-section-content-container-form-radioBtn">
                                <RadioButton
                                  disabled={confirmedOperation}
                                  value={2}
                                  selectedId={selectedRadioBtn}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        // orderTypeId: 4,
                                        selectedCardCount: 0,
                                        selectedCardIndex: -1,
                                        dropdownVisibilityCard: false,
                                        organizedFeeAmount: {},
                                        commissionAmount: null,
                                        cardCondition: 0,
                                        periods: [],
                                        selectedPeriodIndex: -1,
                                        showText: null,
                                        minAmount: null,
                                        orderCardPeriod:
                                          orderCardPeriodType.EXTENSION_NEW_PERIOD,
                                        cardListType: 1,
                                        selectedRadioBtn: 2,
                                      },
                                      () => {
                                        this.showSpecialCards(true);
                                      }
                                    );
                                  }}
                                />
                                <span className="dashboard-payment-container-data-section-content-container-form-radioBtn-title">
                                  {t("Extension for a new term")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                      )}
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        <label className="dashboard-payment-container-data-section-content-container-form-label">
                          {t("Select card")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            this.setState({ dropdownVisibilityCard: false });
                          }}
                        >
                          <div
                            className={
                              "dashboard-payment-container-data-section-content-container-form-select-box " +
                              ((selectedCardCount > 1 &&
                                selectedCardIndex < 0) ||
                              (selectedCardCount === 1 &&
                                selectedCardIndex < 0 &&
                                !dropdownVisibilityCard)
                                ? "input-outline"
                                : "")
                            }
                          >
                            <button
                              type="button"
                              className={
                                confirmedOperation
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-button"
                              }
                              disabled={Boolean(confirmedOperation)}
                              onClick={this.changeDropdownVisibilityCard}
                            >
                              {selectedCardIndex >= 0 ? (
                                <div className="dashboard-payment-container-data-section-content-container-form-select">
                                  {
                                    specialCards[selectedCardIndex]
                                      .organizedCardNumber
                                  }
                                </div>
                              ) : specialCards &&
                                specialCards.length === 0 &&
                                specialCardData ? (
                                <div className="dashboard-payment-container-data-section-content-container-form-select">
                                  {t("137")}
                                </div>
                              ) : (
                                <div
                                  className="dashboard-payment-container-data-section-content-container-form-select"
                                  style={{ color: "#BDC3C7" }}
                                >
                                  {t("Choose")}
                                </div>
                              )}
                              <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                <svg
                                  className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                  width="13"
                                  height="8"
                                  viewBox="0 0 13 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    id="chevron-down copy 2"
                                    d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                    fill="#849097"
                                  />
                                </svg>
                              </div>
                            </button>
                            <ul
                              className={
                                dropdownVisibilityCard === false
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                              }
                            >
                              {specialCards &&
                                specialCards.length > 0 &&
                                specialCards.map((card, index) => {
                                  return (
                                    <li
                                      className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                      key={index}
                                    >
                                      <button
                                        type="button"
                                        onClick={() => {
                                          orderTypeId == 5 && card.cardStatus
                                            ? this.props.alertState(
                                                true,
                                                "warning",
                                                null,
                                                "İtirilmiş kartın yenilənməsi üçün kartın müştəri tərəfindən blok edilməsi mütləqdir. Kartı mobil tətbiqdən və ya filiala yaxınlaşaraq və ya Azərikarda müraciət edərək bloklaya bilərsiniz."
                                              )
                                            : this.updateCard(index);
                                        }}
                                        className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                        disabled={
                                          orderTypeId !== 5 &&
                                          !Boolean(card.cardStatus)
                                        }
                                      >
                                        {card.organizedCardNumber}
                                      </button>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </OutsideClickHandler>
                      </div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        <label className="dashboard-payment-container-data-section-content-container-form-label">
                          {t("select branch get card")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            this.setState({ dropdownVisibilityBranch: false });
                          }}
                        >
                          <div
                            className={
                              "dashboard-payment-container-data-section-content-container-form-select-box " +
                              ((selectedBranchCount > 1 &&
                                selectedBranchIndex < 0) ||
                              (selectedBranchCount === 1 &&
                                selectedBranchIndex < 0 &&
                                !dropdownVisibilityBranch)
                                ? "input-outline"
                                : "")
                            }
                          >
                            <button
                              type="button"
                              className={
                                confirmedOperation
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-button"
                              }
                              disabled={Boolean(confirmedOperation)}
                              onClick={this.changeDropdownVisibilityBranch}
                            >
                              {selectedBranchIndex >= 0 ? (
                                <div className="dashboard-payment-container-data-section-content-container-form-select">
                                  {branchs[selectedBranchIndex].branchName}
                                </div>
                              ) : branchs &&
                                branchs.length === 0 &&
                                branchData ? (
                                <div className="dashboard-payment-container-data-section-content-container-form-select">
                                  {t("242")}
                                </div>
                              ) : (
                                <div
                                  className="dashboard-payment-container-data-section-content-container-form-select"
                                  style={{ color: "#BDC3C7" }}
                                >
                                  {t("Choose")}
                                </div>
                              )}
                              <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                <svg
                                  className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                  width="13"
                                  height="8"
                                  viewBox="0 0 13 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    id="chevron-down copy 2"
                                    d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                    fill="#849097"
                                  />
                                </svg>
                              </div>
                            </button>
                            <ul
                              className={
                                dropdownVisibilityBranch === false
                                  ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                  : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                              }
                            >
                              {branchs &&
                                branchs.length > 0 &&
                                branchs.map((branch, index) => {
                                  return (
                                    <li
                                      className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                      key={index}
                                    >
                                      <button
                                        type="button"
                                        onClick={this.updateBranch.bind(
                                          this,
                                          index
                                        )}
                                        className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                      >
                                        {branch.branchName}
                                      </button>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </OutsideClickHandler>
                      </div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        {(orderCardPeriod ==
                          orderCardPeriodType.EXTENSION_NEW_PERIOD ||
                          activeTabID == 1) &&
                          periods?.length > 0 && (
                            <>
                              <label className="dashboard-payment-container-data-section-content-container-form-label">
                                {t("Card validity period")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="dashboard-payment-container-data-section-content-container-form-checkbox-container">
                                {periods.map((item, index) => (
                                  <div className="dashboard-payment-container-data-section-content-container-form-checkbox">
                                    <CheckboxTick
                                      disabled={confirmedOperation}
                                      checked={selectedPeriodIndex === index}
                                      onChange={() => {
                                        this.setState(
                                          { selectedPeriodIndex: index },
                                          () => {
                                            if (
                                              this.state.selectedCardIndex >= 0
                                            ) {
                                              this.showOrderCommission(true);
                                            }
                                          }
                                        );
                                      }}
                                    />
                                    <span className="dashboard-payment-container-data-section-content-container-form-checkbox-title">
                                      {item.period}{" "}
                                      {t(this.getPeriod(item.periodType))}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        <div
                          className="dashboard-payment-container-data-section-content-container-form-checkbox-description"
                          style={{ minHeight: 16 }}
                        >
                          {commissionAmount !== null && (
                            <>
                              <span>{t("amount for card")}</span>
                              <span className="dashboard-payment-container-data-section-content-container-form-checkbox-description--bold">
                                {commissionAmount?.feeAmount}{" "}
                                {commissionAmount?.currency}
                              </span>
                            </>
                          )}
                        </div>
                        {showText === 1 && commissionAmount?.feeAmount === 0 && (
                          <div
                            className="dashboard-payment-container-data-section-content-container-form-checkbox-description"
                            style={{ minHeight: 16, marginTop: 24 }}
                          >
                            <p
                              style={{
                                marginLeft: 0,
                                marginBottom: 10,
                                fontWeight: "bold",
                              }}
                              className="dashboard-payment-container-data-section-content-container-form-checkbox-description--bold"
                            >
                              {t("note")}
                            </p>
                            <p
                              className="dashboard-payment-container-data-section-content-container-form-checkbox-description--bold"
                              style={{ margin: 0, lineHeight: 1.5 }}
                            >
                              {t("no commision content", {
                                minAmount: minAmount,
                                currency:
                                  specialCards[selectedCardIndex]?.currency,
                              })}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        <label className="dashboard-payment-container-data-section-content-container-form-label">
                          {t("SMS service content")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="dashboard-payment-container-data-section-content-container-form-checkbox-container">
                          <div className="dashboard-payment-container-data-section-content-container-form-checkbox">
                            <CheckboxTick
                              disabled={confirmedOperation}
                              checked={smsNotificationTypeId === 1}
                              onChange={() => {
                                this.setState({ smsNotificationTypeId: 1 });
                              }}
                            />
                            <span className="dashboard-payment-container-data-section-content-container-form-checkbox-title">
                              {t("Yes")}
                            </span>
                          </div>
                          <div className="dashboard-payment-container-data-section-content-container-form-checkbox">
                            <CheckboxTick
                              disabled={confirmedOperation}
                              checked={smsNotificationTypeId === 0}
                              onChange={() => {
                                this.setState({ smsNotificationTypeId: 0 });
                              }}
                            />
                            <span className="dashboard-payment-container-data-section-content-container-form-checkbox-title">
                              {t("No")}
                            </span>
                          </div>
                        </div>
                        <div
                          className="dashboard-payment-container-data-section-content-container-form-checkbox-description"
                          style={{ minHeight: 16 }}
                        >
                          {smsNotificationTypeId === 1 &&
                            smsFeeAmount !== null && (
                              <>
                                <span>{t("Monthly commission")}</span>
                                <span className="dashboard-payment-container-data-section-content-container-form-checkbox-description--bold">
                                  {smsFeeAmount?.feeAmount}{" "}
                                  {smsFeeAmount?.currency}
                                </span>
                              </>
                            )}
                        </div>
                      </div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                      {smsNotificationTypeId === 1 && (
                        <>
                          <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                            <label className="dashboard-payment-container-data-section-content-container-form-label">
                              {t("Mobile number")}{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div
                              className={
                                "dashboard-payment-container-data-section-content-container-form-input-box " +
                                (mobileNumberOutline ? "input-outline" : "")
                              }
                            >
                              <InputMask
                                mask="+\9\9\4 ( 99 ) 999-99-99"
                                value={mobileNumber}
                                disabled={Boolean(confirmedOperation)}
                                className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"
                                placeholder="+994 (__) ___-__-__"
                                onChange={this.checkMobileNumber}
                                onBlur={this.setMobileNumberOutline}
                              />
                            </div>
                          </div>
                          <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                        </>
                      )}
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        <label className="dashboard-payment-container-data-section-content-container-form-label">
                          {t("Upload ID")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="dashboard-payment-container-data-section-content-container-form-file-container">
                          <div
                            className={`dashboard-payment-container-data-section-content-container-form-file-box ${
                              confirmedOperation
                                ? "dashboard-payment-container-data-section-content-container-form-file-box__disable"
                                : ""
                            }`}
                          >
                            <div className="dashboard-payment-container-data-section-content-container-form-file-content">
                              {idFrontStr !== null && (
                                <span className="dashboard-payment-container-data-section-content-container-form-file-description">
                                  {t("Uploaded")}
                                </span>
                              )}
                              <span className="dashboard-payment-container-data-section-content-container-form-file-title">
                                {t("Front side")}
                              </span>
                            </div>
                            <button
                              disabled={confirmedOperation}
                              type="button"
                              className="dashboard-payment-container-data-section-content-container-form-file-button"
                            >
                              <input
                                disabled={confirmedOperation}
                                className="dashboard-payment-container-data-section-content-container-form-file-input"
                                type="file"
                                id="idFrontStr"
                                accept="image/*"
                              />
                              {idFrontStr === null ? (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="24"
                                    height="24"
                                    rx="3.36842"
                                    fill="#75C958"
                                  />
                                  <path
                                    d="M16.6616 14.8027V16.6674H7.33829V14.8027H5.47363V18.5321H6.40596H7.33829H16.6616H17.5939H18.5263V14.8027H16.6616Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M11.0676 9.0501V14.8026H12.9323V9.0501L16.0556 12.1641V9.52559L13.3285 6.78453L12.014 5.47927H12V5.47461L10.6947 6.78453L7.94434 9.54889V12.1548L7.95366 12.1734L11.0676 9.0501Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <button
                                  className="dashboard-payment-container-data-section-content-container-form-file-delete"
                                  disabled={confirmedOperation}
                                  onClick={() => {
                                    this.setState({ idFrontStr: null });
                                  }}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      rx="3.36842"
                                      fill="#E65252"
                                    />
                                    <path
                                      d="M7.42871 9.1167L7.99485 16.5574C8.0272 17.1235 8.52863 17.5602 9.12712 17.5602H15.209C15.8075 17.5602 16.2928 17.1235 16.3251 16.5574L16.9075 9.1167H7.42871ZM10.2432 16.1044H10.227C10.0653 16.1044 9.91971 15.975 9.90354 15.7971L9.58003 10.9122C9.56385 10.7342 9.70943 10.5725 9.88736 10.5725C10.0653 10.5563 10.227 10.7019 10.227 10.8798L10.5506 15.7648C10.5667 15.9427 10.4211 16.0883 10.2432 16.1044ZM12.4916 15.7809C12.4916 15.9589 12.346 16.1044 12.1681 16.1044C11.9902 16.1044 11.8446 15.9589 11.8446 15.7809V10.896C11.8446 10.7181 11.9902 10.5725 12.1681 10.5725C12.346 10.5725 12.4916 10.7181 12.4916 10.896V15.7809ZM14.4326 15.7971C14.4165 15.9589 14.2871 16.1044 14.1091 16.1044H14.0929C13.915 16.0883 13.7856 15.9427 13.7856 15.7648L14.1091 10.8798C14.1253 10.7019 14.2709 10.5725 14.4488 10.5725C14.6267 10.5887 14.7561 10.7342 14.7561 10.9122L14.4326 15.7971Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M17.3286 6.27166H14.4817L14.3846 5.7217C14.3523 5.57612 14.2391 5.46289 14.0935 5.46289H10.2761C10.1305 5.46289 10.0173 5.57612 9.98495 5.7217L9.90407 6.27166H7.00869C6.81459 6.27166 6.65283 6.43341 6.65283 6.62751V7.90537C6.65283 8.09947 6.81459 8.26122 7.00869 8.26122H17.3286C17.5227 8.26122 17.6844 8.09947 17.6844 7.90537V6.62751C17.6844 6.43341 17.5227 6.27166 17.3286 6.27166Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              )}
                            </button>
                          </div>
                          <div className="dashboard-payment-container-data-section-content-container-form-file-box">
                            <div className="dashboard-payment-container-data-section-content-container-form-file-content">
                              {idBackStr !== null && (
                                <span className="dashboard-payment-container-data-section-content-container-form-file-description">
                                  {t("Uploaded")}
                                </span>
                              )}
                              <span className="dashboard-payment-container-data-section-content-container-form-file-title">
                                {t("Back side")}
                              </span>
                            </div>
                            <button
                              disabled={confirmedOperation}
                              type="button"
                              className="dashboard-payment-container-data-section-content-container-form-file-button"
                            >
                              <input
                                disabled={confirmedOperation}
                                className="dashboard-payment-container-data-section-content-container-form-file-input"
                                type="file"
                                id="idBackStr"
                                accept="image/*"
                              />
                              {idBackStr === null ? (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="24"
                                    height="24"
                                    rx="3.36842"
                                    fill="#75C958"
                                  />
                                  <path
                                    d="M16.6616 14.8027V16.6674H7.33829V14.8027H5.47363V18.5321H6.40596H7.33829H16.6616H17.5939H18.5263V14.8027H16.6616Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M11.0676 9.0501V14.8026H12.9323V9.0501L16.0556 12.1641V9.52559L13.3285 6.78453L12.014 5.47927H12V5.47461L10.6947 6.78453L7.94434 9.54889V12.1548L7.95366 12.1734L11.0676 9.0501Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <button
                                  className="dashboard-payment-container-data-section-content-container-form-file-delete"
                                  disabled={confirmedOperation}
                                  onClick={() => {
                                    this.setState({ idBackStr: null });
                                  }}
                                >
                                  <svg
                                    type="button"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      rx="3.36842"
                                      fill="#E65252"
                                    />
                                    <path
                                      d="M7.42871 9.1167L7.99485 16.5574C8.0272 17.1235 8.52863 17.5602 9.12712 17.5602H15.209C15.8075 17.5602 16.2928 17.1235 16.3251 16.5574L16.9075 9.1167H7.42871ZM10.2432 16.1044H10.227C10.0653 16.1044 9.91971 15.975 9.90354 15.7971L9.58003 10.9122C9.56385 10.7342 9.70943 10.5725 9.88736 10.5725C10.0653 10.5563 10.227 10.7019 10.227 10.8798L10.5506 15.7648C10.5667 15.9427 10.4211 16.0883 10.2432 16.1044ZM12.4916 15.7809C12.4916 15.9589 12.346 16.1044 12.1681 16.1044C11.9902 16.1044 11.8446 15.9589 11.8446 15.7809V10.896C11.8446 10.7181 11.9902 10.5725 12.1681 10.5725C12.346 10.5725 12.4916 10.7181 12.4916 10.896V15.7809ZM14.4326 15.7971C14.4165 15.9589 14.2871 16.1044 14.1091 16.1044H14.0929C13.915 16.0883 13.7856 15.9427 13.7856 15.7648L14.1091 10.8798C14.1253 10.7019 14.2709 10.5725 14.4488 10.5725C14.6267 10.5887 14.7561 10.7342 14.7561 10.9122L14.4326 15.7971Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M17.3286 6.27166H14.4817L14.3846 5.7217C14.3523 5.57612 14.2391 5.46289 14.0935 5.46289H10.2761C10.1305 5.46289 10.0173 5.57612 9.98495 5.7217L9.90407 6.27166H7.00869C6.81459 6.27166 6.65283 6.43341 6.65283 6.62751V7.90537C6.65283 8.09947 6.81459 8.26122 7.00869 8.26122H17.3286C17.5227 8.26122 17.6844 8.09947 17.6844 7.90537V6.62751C17.6844 6.43341 17.5227 6.27166 17.3286 6.27166Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      {commissionAmount?.feeAmount ? (
                        <>
                          <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                          <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                            <label className="dashboard-payment-container-data-section-content-container-form-label">
                              {t("Card to be paid commission")}{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <OutsideClickHandler
                              onOutsideClick={() => {
                                this.setState({
                                  dropdownVisibilityCommissionCard: false,
                                });
                              }}
                            >
                              <div
                                className={
                                  "dashboard-payment-container-data-section-content-container-form-select-box " +
                                  ((selectedCommissionCardCount > 1 &&
                                    selectedCommissionCardIndex < 0) ||
                                  (selectedCommissionCardCount === 1 &&
                                    selectedCommissionCardIndex < 0 &&
                                    !dropdownVisibilityCommissionCard)
                                    ? "input-outline"
                                    : "")
                                }
                              >
                                <button
                                  type="button"
                                  className={
                                    confirmedOperation
                                      ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable"
                                      : "dashboard-payment-container-data-section-content-container-form-select-box-button"
                                  }
                                  disabled={Boolean(confirmedOperation)}
                                  onClick={
                                    this.changeDropdownVisibilityCommissionCard
                                  }
                                >
                                  {selectedCommissionCardIndex >= 0 ? (
                                    <div className="dashboard-payment-container-data-section-content-container-form-select">
                                      {
                                        cards[selectedCommissionCardIndex]
                                          .organizedCardNumberBalance
                                      }
                                    </div>
                                  ) : cards &&
                                    cards.length === 0 &&
                                    cardData ? (
                                    <div className="dashboard-payment-container-data-section-content-container-form-select">
                                      {t("137")}
                                    </div>
                                  ) : (
                                    <div
                                      className="dashboard-payment-container-data-section-content-container-form-select"
                                      style={{ color: "#BDC3C7" }}
                                    >
                                      {t("Choose")}
                                    </div>
                                  )}
                                  <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                    <svg
                                      className="dashboard-payment-container-data-section-content-container-form-icon-img"
                                      width="13"
                                      height="8"
                                      viewBox="0 0 13 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        id="chevron-down copy 2"
                                        d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z"
                                        fill="#849097"
                                      />
                                    </svg>
                                  </div>
                                </button>
                                <ul
                                  className={
                                    dropdownVisibilityCommissionCard === false
                                      ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"
                                      : "dashboard-payment-container-data-section-content-container-form-select-box-list"
                                  }
                                >
                                  {cards &&
                                    cards.length > 0 &&
                                    cards.map((card, index) => {
                                      return (
                                        <li
                                          className="dashboard-payment-container-data-section-content-container-form-select-box-list-item"
                                          key={index}
                                        >
                                          <button
                                            type="button"
                                            onClick={this.updateCommissionCard.bind(
                                              this,
                                              index
                                            )}
                                            className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                            disabled={!Boolean(card.cardStatus)}
                                          >
                                            {card.organizedCardNumberBalance}
                                          </button>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </OutsideClickHandler>
                          </div>
                        </>
                      ) : null}
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid"></div>
                      <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                        {showText === 1 &&
                          commissionAmount !== null &&
                          commissionAmount?.feeAmount > 0 && (
                            <div
                              className="dashboard-payment-container-data-section-content-container-form-checkbox-description"
                              style={{ minHeight: 16, marginTop: 24 }}
                            >
                              <p
                                style={{
                                  marginLeft: 0,
                                  marginTop: 10,
                                  marginBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className="dashboard-payment-container-data-section-content-container-form-checkbox-description--bold"
                              >
                                {t("note")}
                              </p>
                              <p
                                className="dashboard-payment-container-data-section-content-container-form-checkbox-description--bold"
                                style={{
                                  marginLeft: 0,
                                  marginTop: 10,
                                  marginBottom: 10,
                                  lineHeight: 1.5,
                                }}
                              >
                                {t("earn no commision content", {
                                  minAmount: minAmount,
                                  currency:
                                    specialCards[selectedCardIndex]?.currency,
                                })}
                              </p>
                              <Link
                                className="dashboard-payment-container-data-section-content-container-form-checkbox__increase-balance-card-btn"
                                to="/dashboard/transfer/card-operation/card-to-card/between-internal"
                              >
                                {t("increase balance card")}
                              </Link>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {!confirmedOperation && (
                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                      {selectedCardIndex >= 0 &&
                      selectedBranchIndex >= 0 &&
                      (commissionAmount?.feeAmount
                        ? selectedCommissionCardIndex >= 0
                        : true) &&
                      (smsNotificationTypeId === 1
                        ? mobileNumberValidation
                        : true) &&
                      idFrontStr &&
                      idBackStr ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ confirmedOperation: true });
                            this.calcCommission();
                          }}
                          className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left"
                        >
                          {t("continue")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left"
                        >
                          {t("continue")}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={this.resetForm}
                        className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right"
                      >
                        {t("cancel")}
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>

          {confirmedOperation && (
            <div className="dashboard-payment-container-confirmation-section">
              <div className="dashboard-payment-container-confirmation-section-header">
                <h3 className="dashboard-payment-container-confirmation-section-header-title">
                  {t("Payment confirmation")}
                </h3>
              </div>
              <div className="dashboard-payment-container-confirmation-section-content">
                <h3 className="dashboard-payment-container-confirmation-section-amount">
                  <span
                    className={
                      "dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn"
                    }
                  >
                    {currencyFilter(commissionAmount.currency)}
                  </span>
                  <span className="dashboard-payment-container-confirmation-section-amount-whole">
                    {organizedFeeAmount.wholePart}
                  </span>
                  <span className="dashboard-payment-container-confirmation-section-amount-fractional">
                    {organizedFeeAmount.fractionalPart}
                  </span>
                </h3>
                <ul className="dashboard-payment-container-confirmation-section-list">
                  <li className="dashboard-payment-container-confirmation-section-list-item">
                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                      {t("Type of operation")}
                    </p>
                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                      {activeTabID === 1
                        ? t("Card exention")
                        : t("Card renewal")}

                      {/* {activeTabID === 2 &&
                        orderCardPeriod ==
                          orderCardPeriodType.UNTIL_CURRENT_PERIOD &&
                        "Mövcud müddətə qədər"}
                      {activeTabID === 2 &&
                        orderCardPeriod ==
                          orderCardPeriodType.EXTENSION_NEW_PERIOD &&
                        "Yeni müddət üzrə uzadılma"} */}
                    </p>
                  </li>
                  <li className="dashboard-payment-container-confirmation-section-list-item">
                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                      {t("Card")}
                    </p>
                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                      {specialCards[selectedCardIndex]?.organizedCardNumber}
                    </p>
                  </li>
                  <li className="dashboard-payment-container-confirmation-section-list-item">
                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                      {t("Branch card obtained")}
                    </p>
                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                      {branchs[selectedBranchIndex]?.branchName}
                    </p>
                  </li>
                  {orderCardPeriod ==
                    orderCardPeriodType.EXTENSION_NEW_PERIOD ||
                  activeTabID == 1 ? (
                    <li className="dashboard-payment-container-confirmation-section-list-item">
                      <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                        {t("Card validity period")}
                      </p>
                      <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                        {periods[selectedPeriodIndex].period}{" "}
                        {t(
                          this.getPeriod(
                            periods[selectedPeriodIndex].periodType
                          )
                        )}
                      </p>
                    </li>
                  ) : orderCardPeriod ==
                      orderCardPeriodType.UNTIL_CURRENT_PERIOD &&
                    activeTabID == 2 ? (
                    <li className="dashboard-payment-container-confirmation-section-list-item">
                      <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                        {t("Card validity period")}
                      </p>
                      <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                        {t("Until the current period")}
                      </p>
                    </li>
                  ) : null}
                  <li className="dashboard-payment-container-confirmation-section-list-item">
                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                      {t("amount for card")}
                    </p>
                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                      {commissionAmount?.feeAmount} {commissionAmount?.currency}
                    </p>
                  </li>
                  <li className="dashboard-payment-container-confirmation-section-list-item">
                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                      {t("SMS  notification")}
                    </p>
                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                      {smsNotificationTypeId === 0 && t("No")}
                      {smsNotificationTypeId === 1 && t("Yes")}
                    </p>
                  </li>
                  {smsNotificationTypeId === 1 && (
                    <li className="dashboard-payment-container-confirmation-section-list-item">
                      <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                        {t("SMS  notification number")}
                      </p>
                      <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                        {mobileNumber}
                      </p>
                    </li>
                  )}
                  {commissionAmount?.feeAmount ? (
                    <li className="dashboard-payment-container-confirmation-section-list-item">
                      <p className="dashboard-payment-container-data-section-content-container-form-group-title">
                        {t("Card to be paid commission")}
                      </p>
                      <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                        {
                          cards[selectedCommissionCardIndex]
                            ?.organizedCardNumberBalance
                        }
                      </p>
                    </li>
                  ) : null}
                </ul>
                <div className="dashboard-payment-container-data-section-content-container-form-footer">
                  <button
                    onClick={this.submitForm}
                    className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left"
                  >
                    {t("Confirm")}
                  </button>
                  <button
                    onClick={() => {
                      this.setState({ confirmedOperation: false });
                    }}
                    className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right"
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default translate("translation")(CardExtensionRenewal);
