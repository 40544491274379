import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import $ from 'jquery';
import Cards from './Cards/Cards';
import Card from './Card/Card';
import Accounts from './Accounts/Accounts';
import Account from './Account/Account';
import AccountExtract from './AccountExtract/AccountExtract';
import Credits from './Credits/Credits';
import { translate } from 'react-i18next';
import NotFound from './../../../copms/NotFound/NotFound';
import CardSms from './CardSms/CardSms';
import CardExtract from './CardExtract/CardExtract';
import Credit from './Credit/Credit';
import Deposits from './Deposits/Deposits';
import DepositOrder from './Deposits/DepositOrder';
import FatcaContract from './Deposits/FatcaContract';
import Deposit from './Deposit/Deposit';
import CreditPayment from './Credit/CreditPayment/CreditPayment';
import Bonuses from './Bonuses/Bonuses';
import BonusesConvertation from './Bonuses/Convertation';
import BonusesHistoryBuy from './Bonuses/HistoryBuy';
import BonusesHistoryPay from './Bonuses/HistoryPay';
import PINSettings from './PINSettings/PINSettings';
import CardLimits from './CardLimits/CardLimits';
import DigitalCard from './DigitalCard/DigitalCard';
import AddOtherBankCard from './CardStorage/AddOtherBankCard/AddOtherBankCard';
import CashBackCondition from './CashBack/CashBackCondition';
import Convertation from './Convertation/Convertation';
import CashBackCardExtract from './CardExtract/CashBackCardExtract';
import CardTopUp from './Card/CardTopUp';

class Finance extends Component {
    render() {
        let { updateAppState, updateDashboardState, alertState, logout, t} = this.props;
        return (
            <Switch>
                <Redirect exact from="/dashboard/finance" to="/dashboard/finance/cards"/>
                <Redirect exact from="/dashboard/finance/bonuses" to="/dashboard/finance/bonuses/1"/>
                <Redirect exact from="/dashboard/finance/bonuses/history" to="/dashboard/finance/bonuses/history/1" />
                <Route exact path="/dashboard/finance/cards" render={(routeProps) => <Cards logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/>  } />
                <Route exact path="/dashboard/finance/cards/digital-card" render={(routeProps) => <DigitalCard logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} {...{t}}/>  } />
                <Route exact path="/dashboard/finance/cards/card-storage/add-other-bank-card" render={(routeProps) => <AddOtherBankCard logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} {...{t}}/>  } />
                <Route exact path="/dashboard/finance/cards/:id" render={(routeProps) => <Card logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/cards/income/:id" render={(routeProps) => <CardTopUp logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact  path="/dashboard/finance/cards/limits/:currency/:id" render={(routeProps) => <CardLimits logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} {...{t}}/> } />
                <Route exact path="/dashboard/finance/cards/sms/:id" render={(routeProps) => <CardSms logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/cards/extract/:id" render={(routeProps) => <CardExtract logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/cards/cashback/extract/:id" render={(routeProps) => <CashBackCardExtract logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/accounts" render={(routeProps) => <Accounts logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/accounts/extract/:id" render={(routeProps) => <AccountExtract logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/accounts/:id" render={(routeProps) => <Account logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/credits/" render={(routeProps) => <Credits logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/credits/:id/:iban" render={(routeProps) => <Credit logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/credits/pay/:id/:iban" render={(routeProps) => <CreditPayment logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/deposits/" render={(routeProps) => <Deposits logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/deposits/order" render={(routeProps) => <DepositOrder logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/deposits/fatca-contract" render={(routeProps) => <FatcaContract logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/deposit/:id" render={(routeProps) => <Deposit logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/bonuses/convertation" render={(routeProps) => <BonusesConvertation logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/bonuses/:id" render={(routeProps) => <Bonuses logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/bonuses/history/1" render={(routeProps) => <BonusesHistoryBuy logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/bonuses/history/2" render={(routeProps) => <BonusesHistoryPay logout={() => this.props.logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route exact path="/dashboard/finance/cards/pin/settings/:id" render={(routeProps) => <PINSettings logout={() => this.props.logout()} {...routeProps} {...{updateAppState,updateDashboardState,alertState}} translate={t} /> } />
                <Route exact path="/dashboard/finance/cards/:id/conditions" render={(routeProps) => <CashBackCondition logout={() => this.props.logout()} {...routeProps} {...{updateAppState,updateDashboardState,alertState}} translate={t} /> } />
                <Route exact path="/dashboard/finance/cards/card/convertation/:id" render={(routeProps) => <Convertation logout={() => this.props.logout()} {...routeProps} {...{updateAppState,updateDashboardState,alertState}} translate={t} /> } />
                <Route exact path="/dashboard/finance/cards/pin/instructions/:instructionType" render={(routeProps) => <PINSettings logout={() => this.props.logout()} {...routeProps} {...{updateAppState,updateDashboardState,alertState}} translate={t} /> } />
                <Route render={(routeProps) => (<NotFound/>) }/>
            </Switch>
        )
    }
}


export default translate("translation")(Finance);