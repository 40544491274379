import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { request, ipsRequest } from './../../../../config';
import Dropdown from '../../../../copms/Dropdown/Dropdown';
import { translate } from 'react-i18next';
import Loading from '../../Loading/Loading';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import GeneralModal from './../../../../copms/Modal/GeneralModal';

class Aliases extends Component {

    state = {
        aliases: [],
        selectedId: -1,
        open: false,
        deleteIndex: null,
        deleteAlias: null,
        openAlert: false,
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
        createFeedbackMessage: false,
        title: null
    }
    showAccountsByAliasId = (id, customerType) => {
        let getAccountsByAliasIdRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasId: id
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                aliasId: id
            }

        return fetch(ipsRequest("getAccountsByAliasId", getAccountsByAliasIdRequestBody))
            .then(res => res.text())
            .then((getAccountsByAliasIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountsByAliasIdResponse = JSON.parse(getAccountsByAliasIdResponseJSON);
                if (getAccountsByAliasIdResponse.status.statusCode !== 1 && getAccountsByAliasIdResponse.status.statusCode !== 126) {
                    if (getAccountsByAliasIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                    else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountsByAliasIdResponse.status.statusCode));
                    }
                    return null;
                }
                else {
                    if (getAccountsByAliasIdResponse.status.statusCode === 126 || !getAccountsByAliasIdResponse.accountList &&
                        !getAccountsByAliasIdResponse.accountList.filter(alias => alias.isLinked !== null)) {
                        return null;
                    }
                    else {
                        return getAccountsByAliasIdResponse.accountList.filter(alias => alias.isLinked !== null);
                    }

                }
                // else {
                //     if (getAccountsByAliasIdResponse.status.statusCode === 182) {
                //         return null;
                //     }
                //     else {
                //         return getAccountsByAliasIdResponse.accountList;
                //     }

                // }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }
    getAliases = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAliasesRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        await fetch(ipsRequest("getAliases", getAliasesRequestBody))
            .then(res => res.text())
            .then((getAliasesResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAliasesResponse = JSON.parse(getAliasesResponseJSON);
                if (getAliasesResponse.status.statusCode !== 1 && getAliasesResponse.status.statusCode !== 182) {
                    if (getAliasesResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAliasesResponse.status.statusCode));
                    }
                }
                else {
                    if (getAliasesResponse.status.statusCode === 182) {
                        getAliasesResponse.aliases = [];
                    }
                    getAliasesResponse.aliases && getAliasesResponse.aliases.map((alias, index) => {
                        this.showAccountsByAliasId(alias.id, customerType)
                            .then(accounts => {
                                getAliasesResponse.aliases[index].accounts = accounts ? accounts : [];
                                getAliasesResponse.aliases[index].isDefaultIban = accounts &&
                                    getAliasesResponse.aliases[index].accounts.find(account => (account.isDefault === 1)) &&
                                    getAliasesResponse.aliases[index].accounts.find(account => (account.isDefault === 1)).iban;

                                    getAliasesResponse.aliases[index].isDefaultCardNumber = accounts &&
                                    getAliasesResponse.aliases[index].accounts.find(account => (account.isDefault === 1)) &&
                                    getAliasesResponse.aliases[index].accounts.find(account => (account.isDefault === 1))?.mainCardNumber === null ? "":  getAliasesResponse.aliases[index].accounts.find(account => (account.isDefault === 1))?.mainCardNumber;
                            })
                    });
                    this.setState({ aliases: getAliasesResponse.aliases });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showAliases = async () => {
        this.setState({ isLoading: true });
        await this.getAliases();
        this.setState({ isLoading: false });
    }
    checkDefaultAlias = (aliasIndex, alias) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            feedbackErrorModal: null,
            feedbackSuccessModal: null,
            createFeedbackMessage: false,
            title: null
        });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let checkAliasForDefaultAccountRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            ids: [alias.id],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                ids: [alias.id],
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("checkAliasForDefaultAccount", checkAliasForDefaultAccountRequestBody))
            .then(res => res.text())
            .then((checkAliasForDefaultAccountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let checkAliasForDefaultAccountResponse = JSON.parse(checkAliasForDefaultAccountResponseJSON);
                if (checkAliasForDefaultAccountResponse.status.statusCode !== 1) {
                    if (checkAliasForDefaultAccountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({
                            createFeedbackMessage: false, openAlert: true,
                            feedbackErrorModal: this.props.t(checkAliasForDefaultAccountResponse.status.statusCode)
                        });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let title = this.props.t('Are you sure you want to delete the alias?');
                    if(checkAliasForDefaultAccountResponse.ids[0].isDefault === true) {
                        title = this.props.t(349) + '. ' + title;
                    }
                    this.setState({title: title, deleteIndex: aliasIndex, deleteAlias: alias, open: true});
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }
    deleteAlias = (index, alias) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            feedbackErrorModal: null,
            feedbackSuccessModal: null,
            createFeedbackMessage: false
        });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let deleteIpsAliasRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            aliasIds: [alias.id],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                aliasIds: [alias.id],
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("deleteIpsAlias", deleteIpsAliasRequestBody))
            .then(res => res.text())
            .then((deleteIpsAliasResponseJSON) => {
                const JSON = require('true-json-bigint');
                let deleteIpsAliasResponse = JSON.parse(deleteIpsAliasResponseJSON);
                if (deleteIpsAliasResponse.status.statusCode !== 1) {
                    if (deleteIpsAliasResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({
                            createFeedbackMessage: false, openAlert: true,
                            feedbackErrorModal: this.props.t(deleteIpsAliasResponse.status.statusCode)
                        });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(deleteIpsAliasResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let aliases = this.state.aliases;
                    aliases.splice(index, 1);
                    this.setState({ aliases: aliases });
                    this.setState({
                        createFeedbackMessage: true, openAlert: true,
                        feedbackSuccessModal: this.props.t('Alias was deleted successfully')
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });

    }
    dropdownItemHandler = (index, aliasIndex, alias, url) => {
        switch (index) {
            case 1:
                this.setState({ deleteIndex: aliasIndex, deleteAlias: alias });
                this.checkDefaultAlias(aliasIndex, alias);
                break;
        }
    }
    componentDidMount() {
        this.showAliases();
    }
    render() {
        let { isLoading, open, deleteIndex, deleteAlias, aliases, title,
            openAlert, feedbackErrorModal, feedbackSuccessModal, createFeedbackMessage } = this.state;
        let { updateAppState, t, history } = this.props;
        return (
            <Fragment>
                <GeneralModal open={openAlert} successClose={() => this.setState({ openAlert: false })}
                    errorClose={() => this.setState({ openAlert: false })}
                    createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal}
                    feedbackSuccessModal={feedbackSuccessModal} history={history} />
                <YesNoModal onClose={() => this.setState({ open: false })} title={title} open={open} triggerFunc={() => this.deleteAlias(deleteIndex, deleteAlias)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Aliases')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                                <div className="dashboard-transfer-list-header" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                    <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__right">
                                        <Link to="/dashboard/transfer/ips/aliases/create" className="dashboard-transfer-list-header-button" style={{ color: '#FFFFFF' }}>{t("Create new alias")}</Link>
                                    </div>
                                </div>
                                <div className="dashboard-transfer-list-content" style={{ padding: 0, height: '100%', minHeight: '100vh' }}>
                                    {
                                        aliases && aliases.length !== 0 ?
                                            <table className="dashboard-transfer-list-table">
                                                <thead className="dashboard-transfer-list-table-head">
                                                    <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("Alias name")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("Alias type")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head" style={{ textAlign: 'center' }}>{t("Linked alias account number")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("accounts")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("Linked default alias")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="dashboard-transfer-list-table-body">
                                                    {
                                                        aliases && aliases.map((alias, index) => {
                                                            return (
                                                                <tr className="dashboard-transfer-list-table-row" key={index}>
                                                                    <td className="dashboard-transfer-list-table-cell">{alias.ipsValue}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{alias.aliasName}</td>
                                                                    <td className="dashboard-transfer-list-table-cell" style={{ textAlign: 'center' }}>{alias.accounts && (alias.accounts.length ? alias.accounts.length : 0)}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{alias.accounts && alias.accounts.length > 0 && alias.accounts.map((item, index) => {
                                                                        return <>
                                                                            <p className='dashboard-transfer-list-table-cell-iban'>{item.iban}</p>
                                                                            <p className='dashboard-transfer-list-table-cell-card-number'>{`${item.mainCardNumber !== null ? item.mainCardNumber : ""}`}</p>
                                                                        </>
                                                                    })}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        <p className='dashboard-transfer-list-table-cell-iban'>{alias.isDefaultIban}</p>
                                                                        <p className='dashboard-transfer-list-table-cell-card-number'>{`${alias.isDefaultCardNumber !== null ? alias.isDefaultCardNumber : ""} `}</p>
                                                                    </td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        <Dropdown
                                                                            dropdownItems={[
                                                                                { title: t('Link'), url: "/dashboard/transfer/ips/aliases/link/" + alias.id },
                                                                                { title: t('Delete') }
                                                                            ]}
                                                                            dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, alias)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            :
                                            <div className="dashboard-last-operations-section-content">
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active aliases')}</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </Fragment >
        )
    }
}


export default translate("translation")(Aliases);