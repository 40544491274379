import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { request } from "../../../../config";
import CardLimitsTabs from "./CardLimitsTabs/CardLimitsTabs";
import CardLimitsForm from "./CardLimitsForm/CardLimitsForm";
import Loading from "../../Loading/Loading";

import "./CardLimits.scss";

const CardLimits = ({ logout, alertState, updateAppState, t }) => {
  let history = useHistory();

  const { currency, id } = useParams();

  const [openForm, setOpenForm] = useState(false);
  const [cardLimitList, setCardLimitList] = useState();
  const [singleCardLimit, setSingleCardLimit] = useState({});
  const [loading, setLoading] = useState();

  const COUNTRY_INTERNAL = 1;
  const ABROAD = 2;

  const tabs = [
    { label: t("Intracountry"), zoneType: COUNTRY_INTERNAL },
    { label: t("Intrecountry"), zoneType: ABROAD },
  ];

  const getCardLimitCodesList = () => {
    setLoading(true);
    const requestBody = {
      custId: parseInt(localStorage.getItem("custId")),
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
      currencyCard: currency.toUpperCase(),
      token: localStorage.getItem("token"),
    };

    fetch(request("card/getCardLimitCodes", requestBody))
      .then((res) => res.text())
      .then((checkCardLimitCodesResponseJson) => {
        const JSON = require("true-json-bigint");
        let getCardLimitCodesResponse = JSON.parse(
          checkCardLimitCodesResponseJson
        );
        if (getCardLimitCodesResponse?.status?.statusCode != 1) {
          if (getCardLimitCodesResponse?.status?.statusCode === 200) {
            logout();
          } else {
            setLoading(false);
            alertState(
              true,
              "error",
              t("an error occurred"),
              t(getCardLimitCodesResponse?.status?.statusCode)
            );
          }
        } else {
          setLoading(false);
          setCardLimitList([...getCardLimitCodesResponse?.cardLimitList]);
        }
      });
  };

  const getSingleCardLimit = (id) => {
    let singleCardLimitCode = cardLimitList?.filter(
      (item) => item?.limitCodeId == id
    );
    setSingleCardLimit(singleCardLimitCode[0]);
  };

  useEffect(() => {
    getCardLimitCodesList();
  }, []);

  return (
    <div className="ibank-card-limits">
      <div className="dashboard-header-route">
        <div className="dashboard-header-route-left">
          <button onClick={() => history.goBack()}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z"
                fill="#2E3131"
              />
            </svg>
          </button>
          <h2>{t("Limits management")}</h2>
        </div>
      </div>
      <div className="dashboard-container-main-section">
        {loading ? (
          <Loading />
        ) : (
          <div className="ibank-card-limits__content">
            <div
              className={`ibank-card-limits__tabs ${
                openForm && "ibank-card-limits__tabs__responsive"
              }`}
            >
              <CardLimitsTabs
                {...{
                  tabs,
                  cardLimitList,
                  getSingleCardLimit,
                  setOpenForm,
                  t,
                }}
              />
            </div>
            {openForm && (
              <div
                className={`ibank-card-limits__form ${
                  openForm && "ibank-card-limits__form__responsive"
                }`}
              >
                <CardLimitsForm
                  singleCardLimit={singleCardLimit && singleCardLimit}
                  {...{ setOpenForm, logout, alertState, updateAppState, t }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardLimits;
