import React, { Component, Fragment } from 'react';
import { Switch, Link, NavLink, Redirect, Route } from 'react-router-dom';
import { translate } from 'react-i18next';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import transferInternalIcon from './../../img/transfer-internal-icon.svg';
import { request, ipsRequest } from './../../../../config';
import transferFilter from '../../../../helpers/transferFilter';
import currencyFilter from '../../../../helpers/currencyFilter';
import cardOperationFilter from '../../../../helpers/cardOperationFilter';
import YesNoModal from '../../../../copms/Modal/YesNoModal';
import Loading from './../../Loading/Loading';
import iconIpsRegistration from '../../img/ips-registration.svg';
import iconIpsWallet from '../../img/ips-wallet.svg';
import iconIpsShare from '../../img/ips-share.svg';
import iconIpsPayments from '../../img/ips-payments.svg';
import iconIpsIncoming from '../../img/ips-incoming.svg';
import iconIpsAlias from '../../img/ips-alias.svg';
import iconIpsAccounts from '../../img/ips-accounts.svg';
import paperPlaneIcon from '../../img/paper-plane.svg';
import internalIcon from '../../img/internal.svg';
import PaymentMethodIPS from '../../../../copms/Modal/PaymentMethodIPS';
import { statusCodes } from '../../../../helpers/statusCodes';

class Home extends Component {
    state = {
        signLevel: null,
        signCount: null,
        open: false,
        modalCardObj: {},
        recent_operations: [],
        operations: [],
        isLoading: false,
        aliases: [],
        paymModalOpen: false,
    }


    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    dropdownItemHandler = (index, tempIndex, url) => {
        switch (index) {
            case 1:
                this.setState({ deleteIndex: tempIndex, open: true })
                break;
        }
    }

    deleteTransferTemplate(index) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let removePaymentTempCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.recent_operations[index].id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                tempId: this.state.recent_operations[index].id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("operation/deleteOperationTemp", removePaymentTempCustomerRequestBody))
            .then(res => res.text())
            .then((removePaymentTempCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let removePaymentTempCustomerResponse = JSON.parse(removePaymentTempCustomerResponseJSON);
                if (removePaymentTempCustomerResponse.status.statusCode !== 1) {
                    if (removePaymentTempCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removePaymentTempCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var tempArr = this.state.recent_operations;
                    tempArr.splice(index, 1);
                    this.setState({ recent_operations: tempArr });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }
    getFormatedDate = (date) => {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    componentWillMount() {
        this.setState({ isLoading: true });
        let now = new Date();
        let threeDaysBefore = new Date();
        threeDaysBefore.setDate(now.getDate() - 2);
        let startDate = this.getFormatedDate(threeDaysBefore);
        let endDate = this.getFormatedDate(now);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardOperationTempListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operNameId: 5,
            startDate: startDate,
            endDate: endDate
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operNameId: 5,
                startDate: startDate,
                endDate: endDate
            }
        fetch(ipsRequest("operationAdvancedSearch", getCardOperationTempListRequestBody))
            .then(res => res.text())
            .then((getCardOperationTempListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardOperationTempListResponse = JSON.parse(getCardOperationTempListResponseJSON);
                if (getCardOperationTempListResponse.status.statusCode !== 1 && getCardOperationTempListResponse.status.statusCode !== '????????????') {
                    if (getCardOperationTempListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardOperationTempListResponse.status.statusCode === 1) {
                        for (let i = 0; i < getCardOperationTempListResponse.operationList.length; i++) {
                            // =========AMOUNT=========
                            let amount, currency;

                            amount = getCardOperationTempListResponse.operationList[i].amount;
                            currency = getCardOperationTempListResponse.operationList[i].currency;

                            getCardOperationTempListResponse.operationList[i].currency = currency;
                            if (amount) {
                                if (amount.toString() === "0") {
                                    getCardOperationTempListResponse.operationList[i].organizedAmount = {
                                        wholePart: "0",
                                        fractionalPart: "00"
                                    }
                                }
                                else {
                                    if (amount.toString().indexOf('.') !== -1) {
                                        let amountArray = amount.toString().split('.');
                                        getCardOperationTempListResponse.operationList[i].organizedAmount = {
                                            wholePart: amountArray[0],
                                            fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                        }
                                    }
                                    else {
                                        getCardOperationTempListResponse.operationList[i].organizedAmount = {
                                            wholePart: amount.toString(),
                                            fractionalPart: "00"
                                        }
                                    }
                                }
                            }
                            else {
                                getCardOperationTempListResponse.operationList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                        }
                        this.setState({ recent_operations: getCardOperationTempListResponse.operationList });
                    }
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        this.getAliases();
    }

    checkRegistration() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let individualRegistrationRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                
            }

        fetch(request("ips/checkCustomerRegistration", individualRegistrationRequestBody))
            .then(res => res.text())
            .then((individualRegistrationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let individualRegistrationResponse = JSON.parse(individualRegistrationResponseJSON);
                if (individualRegistrationResponse.status.statusCode !== 1) {
                    if (individualRegistrationResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        individualRegistrationResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ?
                        this.props.alertState(
                            true, 
                            'warning', 
                            this.props.t('Information'), 
                            this.props.t(individualRegistrationResponse.status.statusCode),
                            null, 
                            true
                        ) :  
                        this.props.history.push('/dashboard/transfer/ips');
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getAliases() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getIpsAuthMethodsRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("getAliases", getIpsAuthMethodsRequestBody))
            .then(res => res.text())
            .then((getIpsAuthMethodsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getIpsAuthMethodsResponse = JSON.parse(getIpsAuthMethodsResponseJSON);
                if (getIpsAuthMethodsResponse.status.statusCode !== 1) {
                    if (getIpsAuthMethodsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getIpsAuthMethodsResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ aliases: getIpsAuthMethodsResponse.aliases })

                    // this.setState({ authMethods: getIpsAuthMethodsResponse.authMethodList });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel, signCount: custObject.company.signCount });
        }
        this.checkRegistration();
    }
    render() {
        let { updateAppState, alertState, t, logout } = this.props;
        let { open, recent_operations, paymModalOpen, modalCardObj, dropdownItems, signLevel, signCount, deleteIndex, isLoading, aliases } = this.state;

        return (
            <Fragment>
                <PaymentMethodIPS onClose={() => this.setState({paymModalOpen: false})} open={paymModalOpen}/>
                <YesNoModal onClose={() => this.setState({ open: false })} title={t('Are you sure you want to delete the template?')} open={open} triggerFunc={() => this.deleteTransferTemplate(deleteIndex)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Instant payments')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">

                            <div className="dashboard-new-transfer-template-section responsive-width-full" style={{ width: "calc(55% - 16px)" }}>
                                <div className="dashboard-new-transfer-template-section-background">
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('Make a new payment')}</h3>
                                        </div>
                                        <ul className="dashboard-new-transfer-section-content" style={{justifyContent: 'flex-start'}}>
                                            {/* <Link to="/dashboard/transfer/ips/accounts/register" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsRegistration} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('registration')}</p>
                                            </Link> */}
                                            <button onClick={() => this.setState({paymModalOpen: true})} className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsWallet} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('make a payment')}</p>
                                            </button>
                                            {/* <Link to="/dashboard/transfer/transfers/inland" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsShare} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Send a request')}</p>
                                            </Link> */}
                                            <Link to="/dashboard/transfer/ips/payments" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsPayments} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('My payments')}</p>
                                            </Link>
                                            <Link to="/dashboard/transfer/ips/income" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsIncoming} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Income')}</p>
                                            </Link>
                                            <Link to="/dashboard/transfer/ips/aliases" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsAlias} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('My alias')}</p>
                                            </Link>
                                            <Link to="/dashboard/transfer/ips/accounts" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsAccounts} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('My accounts')}</p>
                                            </Link>
                                            <Link style={{ zIndex: -222 }} to="/dashboard/transfer/transfers/convertation/1" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={iconIpsAccounts} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Exchange')}</p>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="dashboard-transfer-history" style={{ width: "100%" }}>
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('My alias')}</h3>
                                        </div>
                                        <div className="dashboard-payment-operation-section-content">
                                            {
                                                aliases && aliases.length > 0 ?
                                                    this.state.aliases.map((alias, index) => {
                                                        return (
                                                            <div className="dashboard-payment-card-section-content-item" key={index}>
                                                                <div style={{ textDecoration: 'none' }} to="/dashboard/payment/payments/categories" className="dashboard-payment-operation-section-content-item--left-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                        <img src={iconIpsAlias} className="dashboard-payment-operation-section-content-item-icon" />
                                                                    </div>
                                                                    <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                        <div className="dashboard-payment-operation-section-content-item-title">{alias.ipsValue}</div>
                                                                        <div className="dashboard-payment-operation-section-content-item-subtitle">{alias.startDate}</div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="dashboard-payment-card-section-content-item-left-side">
                                                            <div className="dashboard-payment-card-section-content-item-card-icon">
                                                                <img src={cardIconBackground} className="dashboard-payment-card-section-content-item-card-icon-image" alt="" />
                                                            </div>
                                                            <div className="dashboard-payment-card-section-content-item-card-name">
                                                                <h4 className="dashboard-payment-card-section-content-item-card-name">{card.cardType}</h4>
                                                                <p className="dashboard-payment-card-section-content-item-card-number">{card.organizedCardNumber}</p>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-card-section-content-item-right-side">
                                                            <span className="dashboard-payment-card-section-content-item-amount-whole-part">{card.organizedCurrentBalance.wholePart}</span>
                                                            <span className="dashboard-payment-card-section-content-item-amount-fractional-part">{card.organizedCurrentBalance.fractionalPart}</span>
                                                            <span className="dashboard-payment-card-section-content-item-amount-currency">{card.currency}</span>
                                                        </div> */}
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <>
                                                        <li className="dashboard-payment-operation-section-content-item">
                                                            <Link style={{ textDecoration: 'none' }} to="/dashboard/transfer/ips/aliases/create" className="dashboard-payment-operation-section-content-item--left-side">
                                                                <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                    <img src={iconIpsAlias} className="dashboard-payment-operation-section-content-item-icon" />
                                                                </div>
                                                                <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                    <div className="dashboard-payment-operation-section-content-item-title">{t('Create new alias')}</div>
                                                                    <div className="dashboard-payment-operation-section-content-item-subtitle">{t('Type of alias')}</div>
                                                                </div>
                                                            </Link>
                                                            <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                            </div>
                                                        </li>
                                                        {
                                                            [0, 1].map(index => (
                                                                <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                                    <div className="dashboard-payment-operation-section-content-item--left-side">
                                                                        <div className="dashboard-payment-operation-section-content-item-icon-container"></div>
                                                                        <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                            <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                        <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                        <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-new-transfer-section responsive-width-full-top" style={{ width: "calc(45% - 16px)", marginBottom: '0' }}>
                                <div className="dashboard-new-transfer-section-header">
                                    <h3 className="dashboard-new-transfer-section-header-title">{t('recent operations')}</h3>
                                </div>
                                {
                                    recent_operations.length !== 0 ?
                                        <div className="dashboard-finance-section-cards-section-table-section">
                                            <table className="dashboard-finance-section-cards-section-table">
                                                <tbody>
                                                    {
                                                        recent_operations.slice(0, 7).map((operation, index) =>
                                                            <Fragment key={index}>
                                                                <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                    <td className="dashboard-finance-section-cards-section-cell">
                                                                        <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                            <img src={transferFilter(operation.operNameId) && transferFilter(operation.operNameId).iconName} style={{ height: '15px' }} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                        </div>
                                                                        <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                            <div className="dashboard-finance-section-cards-section-cell-content">{operation.operState}</div>
                                                                            <div className="dashboard-finance-section-cards-section-cell-title" style={{ display: 'inline', wordBreak: 'break-all' }}>{operation.operPurpose}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                        <p className="dashboard-finance-section-cards-section-cell-content">
                                                                            <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + operation.currency}>{currencyFilter(operation.currency)}</span>
                                                                            <span className="dashboard-finance-section-cards-section-amount-whole-part">{operation.organizedAmount.wholePart}</span>
                                                                            <span className="dashboard-finance-section-cards-section-amount-fractional-part">{operation.organizedAmount.fractionalPart}</span>
                                                                        </p>
                                                                        <p className="dashboard-finance-section-cards-section-cell-title">{operation.cardOperationTypeValue}</p>
                                                                    </td>
                                                                </tr>

                                                                <tr className="dashboard-finance-row-line">
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div className="dashboard-payment-operation-section-content">
                                            {
                                                [0, 1, 2].map(index => (
                                                    <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                        <div className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container"></div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </div>
                                }
                            </div>

                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(Home);