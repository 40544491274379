import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function SimpleModal(props) {
    const classes = useStyles();
    const history = useHistory()

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.close() }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <div className="simple-modal w-75">
                            <h5 className="simple-modal-head" style={{fontSize: '16px'}}>{props.title}</h5>
                            <div className="d-flex">
                            <button className="simple-modal-button mr-3" onClick={() => history.push("/dashboard")}>{props.t('main page')}</button>
                            <button className="simple-modal-button" onClick={() => props.close() } style={{background: "#F3F5F8", color: '#586268'}}>{props.t('Cavabları dəyişin')}</button>
                            </div>
                           
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(SimpleModal);