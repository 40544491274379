import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import friendImage from './../img/friend-transfer-icon.svg';
import notNotificationIcon from './../img/not-notification-icon.svg';
import { request } from './../../../config';
import blueLogo from './../../../helpers/img/logo-blue.svg';
class Notifications extends Component {
    capitalize = (s) => {
        if (typeof s !== 'string') return 'Az'
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    readIdFunc = async (id) => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let readRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: [id],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: [id],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        return await fetch(request("setting/readNotification", readRequestBody))
            .then(res => res.text())
            .then((readRequestResponseJSON) => {
                const JSON = require('true-json-bigint');
                let readRequestResponse = JSON.parse(readRequestResponseJSON);
                if (readRequestResponse.status.statusCode === 1) {
                    let notifications = this.props.notifications;
                    notifications.find(el => el.id == id).isRead = 1;
                    this.props.updateNotification(notifications);
                    this.props.notificationCountRefresh();
                }
            });
    }
    render() {
        let { t, notificationDropdownVisibility } = this.props;
        return (
            <ul className={"dashboard-notifications-section" + (notificationDropdownVisibility ? " dashboard-notifications-section__visible" : "")} style={{ overflowY: 'auto' }}>
                {
                    this.props.notifications.filter(item => item.isRead === 0).map((item, index) => {
                        return (
                            <Fragment key={index}>
                                <li
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.close();
                                        if ( /* item.click_action === 'FriendNotification' ||  */ item.notificationTypeId === 2) {
                                            if (item.isRead === 0) {
                                                this.readIdFunc(item.id);
                                            }
                                            if(item.notificationType === 'FriendNotificationToFriend') {
                                                this.props.history.push('/dashboard/transfer/card-operation/friend-transfer/history/1');
                                            }
                                            else {
                                                this.props.history.push('/dashboard/transfer/card-operation/friend-transfer/history/2');
                                            }
                                        }
                                        else {
                                            this.props.history.push('/dashboard/notifications/' + item.id);
                                        }
                                    }} style={{ cursor: 'pointer' }} className="dashboard-notifications-section-item" key={index}>
                                    <div className="dashboard-notifications-section-item-icon" style={{ backgroundColor: 'transparent' }}>
                                        <img  
                                            //  OLD LOGO SIZE
                                            // style={{ width: '30px', height: '30px' }} 
                                            
                                            style={{ width: '70px', height: '70px' }}
                                            src={(
                                            /* item.click_action === 'FriendNotification' || */
                                             item.notificationTypeId === 2) ? friendImage : (item.image ? item.image : 
                                           
                                                // OLD LOGO ICON
                                                //  notNotificationIcon

                                            blueLogo
                                            )} />
                                             
                                    </div>
                                    <div className="dashboard-notifications-section-item-content">
                                        {/* <h3 className={"dashboard-notifications-section-item-title" + (item.isRead == 0 ? " dashboard-notifications-section-item-title__unread" : "")}>{item[`title${this.capitalize(localStorage.getItem('i18nextLng'))}`]}</h3>
                                        <p className={"dashboard-notifications-section-item-description" + (item.isRead == 0 ? " dashboard-notifications-section-item__unread" : "")}>{item[`content${this.capitalize(localStorage.getItem('i18nextLng'))}`]}</p> */}
                                        <h3 className={"dashboard-notifications-section-item-title" + (item.isRead == 0 ? " dashboard-notifications-section-item-title__unread" : "")}>{item.title}</h3>
                                        <p className={"dashboard-notifications-section-item-description" + (item.isRead == 0 ? " dashboard-notifications-section-item__unread" : "")}>{item.content}</p>
                                    </div>
                                    {item.isRead == 0 ? <div style={{ width: '8px', height: '8px', borderRadius: '4px', backgroundColor: '#0066B2', alignContent: 'flex-end' }}></div> : null}
                                </li>
                                {
                                    index !== this.props.notifications.length - 1 &&
                                    <li
                                        style={{
                                            listStyleType: 'none',
                                            position: 'absolute',
                                            marginLeft: '16px',
                                            height: '1px',
                                            width: 'calc(100% - 32px)',
                                            backgroundColor: '#E8ECEF'
                                        }}
                                    ></li>
                                }
                            </Fragment>
                        )
                    })
                }


                <button onClick={e => { e.preventDefault(); this.props.close(); this.props.history.push('/dashboard/notifications') }} className="dashboard-notifications-section-item-button">{t('ALL NOTIFICATIONS')}</button>
            </ul>
        )
    }
}

export default translate("translation")(Notifications);
