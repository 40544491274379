import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { request } from '../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import CardOperationModal from '../../../../copms/Modal/CardOperationModal';
import CreateCardOperationTemplate from './../../../../copms/Modal/CreateCardOperationTemplate';
import OutsideClickHandler from 'react-outside-click-handler';


class Convertation extends Component {
    state = {
        bonusTariffInfoList: [],
        quantity: null,
        exchangeAzn: null,
        quantityOutline: false
    }

    checkQuantity = (event) => {
        this.setState({ quantity: event.target.value, exchangeAzn: null });
        if (!Boolean(Number(this.state.quantity))) {
            this.setState({ quantityOutline: false });
        }
    }

    setQuantityOutline = () => {
        if (Boolean(Number(this.state.quantity))) {
            this.setState({ quantityOutline: false });
        }
        else {
            this.setState({ quantityOutline: true });
        }
    }

    getBonusTariffInfoList = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusTariffInfoListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }

        fetch(request("bonus/getBonusTariffInfoList", getBonusTariffInfoListRequestBody))
            .then(res => res.text())
            .then((getBonusTariffInfoListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusTariffInfoListResponse = JSON.parse(getBonusTariffInfoListResponseJSON);
                if (getBonusTariffInfoListResponse.status.statusCode !== 1) {
                    if (getBonusTariffInfoListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusTariffInfoListResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ bonusTariffInfoList: getBonusTariffInfoListResponse.bonusTariffInfoList });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showConvertation = (e) => {
        e.preventDefault();
        if (Boolean(Number(this.state.quantity))) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let convertQuantityToAznRequestBody = customerType == 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                quantity: this.state.quantity
            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                quantity: this.state.quantity
            }

            fetch(request("bonus/convertQuantityToAzn", convertQuantityToAznRequestBody))
                .then(res => res.text())
                .then((convertQuantityToAznResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let convertQuantityToAznResponse = JSON.parse(convertQuantityToAznResponseJSON);
                    if (convertQuantityToAznResponse.status.statusCode !== 1) {
                        if (convertQuantityToAznResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(convertQuantityToAznResponse.status.statusCode));
                            this.setState({ bonusBalanceExchangeAzn: convertQuantityToAznResponse.exchangeAzn ? convertQuantityToAznResponse.exchangeAzn.toFixed(2) : '0.00' });
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                    else {
                        this.setState({ exchangeAzn: convertQuantityToAznResponse.exchangeAzn });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    componentDidMount() {
        this.getBonusTariffInfoList();
    }

    render() {
        let { t, history } = this.props;
        let { quantity, quantityOutline, exchangeAzn, bonusTariffInfoList } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Bonuses')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section">
                        <div className="dashboard-payment-container-data-section-header">
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <h2 className="dashboard-payment-container-data-section-header-title">{t('Conversion')}</h2>
                            </div>
                        </div>
                        <form className="dashboard-payment-container-data-section-content">
                            <div className="dashboard-payment-container-data-section-content-container">
                                <div className='dashboard-bonuses-convertation'>
                                    <div className="dashboard-bonuses-convertation-form">
                                        <div className="dashboard-bonuses-convertation-form-container">
                                            <div className="dashboard-bonuses-convertation-form-group">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Number of stars')}</label>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <input value={quantity} type="number" min='1' className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole " + (quantityOutline ? "input-outline" : "")} onChange={this.checkQuantity} onBlur={this.setQuantityOutline} />
                                                </div>
                                            </div>
                                            <div className="dashboard-bonuses-convertation-form-group-gap">
                                                <div className="dashboard-bonuses-convertation-form-group-gap-icon">-</div>
                                            </div>
                                            <div className="dashboard-bonuses-convertation-form-group">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">AZN</label>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <input value={exchangeAzn} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" />
                                                </div>
                                            </div>
                                        </div>
                                        <button disable={Boolean(Number(quantity))} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue ${Boolean(Number(quantity)) ? '' : 'dashboard-payment-container-data-section-content-container-form-footer-button__disable'}`} style={{ marginTop: '20px' }} onClick={this.showConvertation}>{t('SHOW')}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="dashboard-payment-container-confirmation-section">
                        <div className="dashboard-payment-container-confirmation-section-header">
                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Convertation data')}</h3>
                        </div>
                        <div className="dashboard-payment-container-confirmation-section-content">
                            <ul className='dashboard-bonuses-convertation-details'>
                                {
                                    bonusTariffInfoList?.map((bonus, index) => (
                                        <li className='dashboard-bonuses-convertation-details-item' key={index}>
                                            <p className='dashboard-bonuses-convertation-details-item-title'>{bonus.status === 1 && t('Card payment')} {bonus.status === 0 && t('Mobile payment')}</p>
                                            <p className='dashboard-bonuses-convertation-details-item-content'>
                                                <span>
                                                    {/* 1₼ = {Number.parseFloat(bonus.quantity).toFixed(2).toString().replace('.', ',')} */}
                                                    1 AZN = {bonus.quantity}
                                                </span>
                                                <svg className='dashboard-bonuses-convertation-details-item-content-icon' width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0L10.3511 4.76393L15.6085 5.52786L11.8042 9.23607L12.7023 14.4721L8 12L3.29772 14.4721L4.19577 9.23607L0.391548 5.52786L5.64886 4.76393L8 0Z" fill="#909090" />
                                                </svg>
                                            </p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default translate("translation")(Convertation);
