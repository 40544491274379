import React from "react";

import currencyFilter from "../../../../../../helpers/currencyFilter";

import "./CardLimitsTabsItem.scss";

const CardLimitsTabsItem = ({
  name,
  defaultLimit,
  getSingleCardLimit,
  setOpenForm,
  limitCodeId,
  currencyLimit,
  t
}) => {
  return (
    <div className="ibank-card-limits__tabs__item">
      <div
        className="ibank-card-limits__tabs__item__content"
        onClick={() => {
          getSingleCardLimit(limitCodeId);
          setOpenForm(true);
        }}
      >
        <p className="ibank-card-limits__tabs__item__content__header">{name}</p>
        {/* limit amount */}
        <p className="ibank-card-limits__tabs__item__content__desc">
          {t("Standard limit")}: {defaultLimit} {currencyFilter(currencyLimit)}
        </p>
        <div className="ibank-card-limits__tabs__item__content__icon">
          <svg
            width="22"
            height="12"
            viewBox="0 0 21 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9665 19.4281C20.642 18.7527 20.642 17.807 19.9665 17.1316L4.16085 1.461C3.48539 0.785545 2.40466 0.785545 1.8643 1.461L0.918659 2.40664C0.243203 3.08209 0.243203 4.02773 0.918659 4.70319L14.698 18.2123L0.783569 31.8565C0.243205 32.532 0.243205 33.4776 0.783569 34.1531L1.8643 35.0987C2.40466 35.7741 3.48539 35.7741 4.16085 35.0987L19.9665 19.4281Z"
              fill="#404040"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CardLimitsTabsItem;
