import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import headerLogo from "./../../Registration/img/header-logo.svg";
import userIconForm from "./../../Registration/img/user-icon-form.svg";
import barcodeReadIconForm from "./../../Registration/img/barcode-read-icon-form.svg";
import barcodeAltIconForm from "./../../Registration/img/barcode-alt-icon-form.svg";
import forgotPasswordIcon from './../../Registration/img/forgot-password.svg';
import { translate } from 'react-i18next';
import {request} from '../../../config';

class checkUnblock extends Component {
    state = {
        customerType: 'Individual',
        feedbackMessage: null,
        isDisabled: true,
        pin: null,
        mobile: null,
        taxNo: null,
        validateTaxNo: false,
        validatePin: false,
        validateMobile: false,
    }
    backLogin = () => {
        if(this.state.customerType === 'Juridical') {
            this.props.history.push('/login/juridical/mobile');
        }
        else {
            this.props.history.push('/login/individual/mobile');
        }
    }
    validateData = (inputType, event) => {
        this.setState({ feedbackMessage: null });
        if (inputType === 'pin') {
            let pin = this.pin.value;
            if (this.pin.value.indexOf(' ') !== -1) {
                pin = pin.slice(0, pin.indexOf(' ')) + pin.slice(pin.indexOf(' ') + 1, pin.length);
                this.pin.value = pin;
            }
            if (this.pin.value.length < 7) {
                this.setState({ validatePin: false });
            }
            else {
                if (this.pin.value.length > 7) {
                    this.pin.value = this.pin.value.substring(0, 7);
                }
                this.setState({ validatePin: true });
            }
        }
        if (inputType === 'mobile') {
            setTimeout(() => {
                if (this.mobile.value.indexOf("_") === -1) {
                    this.setState({ validateMobile: true });

                }
                else {
                    this.setState({ validateMobile: false });

                }
            }, 1)
        }
        if (inputType === 'taxNo') {
            let mask = /[^0-9]/g; //temporay
            if (this.taxNo.value !== this.taxNo.value.replace(mask, '')) {
                this.taxNo.value = this.taxNo.value.replace(mask, '');
            }
            if (this.taxNo.value.length < 10) {
                this.setState({ validateTaxNo: false });
            }
            else {
                if (this.taxNo.value.length > 10) {
                    this.taxNo.value = this.taxNo.value.substring(0, 10);
                }
                this.setState({ validateTaxNo: true });
            }

        }
        setTimeout(() => {
            if(this.state.customerType === 'Juridical') {
                if (this.state.validatePin && this.state.validateMobile && this.state.validateTaxNo) {
                    this.setState({ isDisabled: false });
                }
                else {
                    this.setState({ isDisabled: true });
                }
            }
            else {
                if (this.state.validatePin && this.state.validateMobile) {
                    this.setState({ isDisabled: false });
                }
                else {
                    this.setState({ isDisabled: true });
                }
            }
        }, 1);
    }

    checkData = (event) => {
        event.preventDefault();
            this.props.updateAppState('isLoadingTransparentBackground', true);
            let getMobile = (mobile) => {
                ['+', '-', '(', ')', ' '].forEach(char => {
                    while (mobile.indexOf(char) !== -1) {
                        mobile = mobile.slice(0, mobile.indexOf(char)) + mobile.slice(mobile.indexOf(char) + 1, mobile.length);
                    }
                });
                return mobile;
            }
            let checkCostumerRequestBody = this.state.customerType === 'Juridical' ? {
                pin: this.pin.value,
                mobile: getMobile(this.mobile.value),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                taxNo: this.taxNo.value
            } 
            :
            {
                pin: this.pin.value,
                mobile: getMobile(this.mobile.value),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),

            }
            fetch(request("register/checkUnblock" + this.state.customerType + 'v1', checkCostumerRequestBody))
                .then(res => res.text())
                .then((checkCustomerRequestResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let checkCustomerRequestResponse = JSON.parse(checkCustomerRequestResponseJSON);
                    if (checkCustomerRequestResponse.status.statusCode !== 1) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            feedbackMessage: this.props.t(checkCustomerRequestResponse.status.statusCode),
                            isDisabled: true
                        });
                        // console.log(checkCustomerRequestResponse)
                    }
                    else {
                        // console.log(checkCustomerRequestResponse)
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        let custId = "";
                        if (typeof (checkCustomerRequestResponse.custId) === "object" && checkCustomerRequestResponse.custId.hasOwnProperty('c')) {
                            checkCustomerRequestResponse.custId.c.forEach(part => {
                                custId += part;
                            });
                        }
                        else {
                            custId += checkCustomerRequestResponse.custId;
                        }
                        if(this.state.customerType === 'Juridical') {
                            let compId = "";
                            if (typeof (checkCustomerRequestResponse.compId) === "object" && checkCustomerRequestResponse.custId.hasOwnProperty('c')) {
                                checkCustomerRequestResponse.compId.c.forEach(part => {
                                    compId += part;
                                });
                            }
                            else {
                                compId += checkCustomerRequestResponse.compId;
                            }
                            this.props.history.push('/login/individual/mobile/unblock-user/2', {pin: this.pin.value,  mobile: getMobile(this.mobile.value), custId: custId, compId: compId, verifyToken: checkCustomerRequestResponse.verifyToken});
                        }
                        else {
                            this.props.history.push('/login/individual/mobile/unblock-user/2', {pin: this.pin.value,  mobile: getMobile(this.mobile.value), custId: custId, verifyToken: checkCustomerRequestResponse.verifyToken});
                        }
                    }
                },
                (error) => {
                    this.props.history.push("/technical-break");
                }
            )
            
        
    }
    
    componentDidMount() {
        this.props.updateAppState('isLoadingTransparentBackground', false);
        if(this.props.match.url === '/login/juridical/mobile/unblock-user/1') {
            this.setState({customerType: 'Juridical'});
        }
    }

    render() {
        let { feedbackMessage, pin, mobile, taxNo, isDisabled, customerType } = this.state;
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title">{t('119')}</h2>
                    <p className="registration-container-description">{t('enter your mobile number to unblock')}</p>
                    <img src={forgotPasswordIcon} className="registration-container-icon" alt="forgotPasswordIcon" />
                    <div className="registration-container-form-grid">
                        <div className="registration-container-form-group registration-container-form-group-grid">
                            <label className="registration-container-form-group-label">{t('fin code of the id card')}</label>
                            <div className="registration-container-form-group-box">
                                <input type="text" className="registration-container-form-group-box-input" defaultValue={pin} placeholder={t('enter the fin code')} ref={(input) => { this.pin = input }} onInput={this.validateData.bind(this, 'pin')} />
                                <div className="registration-container-form-group-box-icon">
                                    <img src={barcodeReadIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__user" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="registration-container-form-group registration-container-form-group-grid">
                            <label className="registration-container-form-group-label">{t('phone number')}</label>
                            <div className="registration-container-form-group-box">
                                <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" className="registration-container-form-group-box-input" defaultValue={mobile} placeholder="+994 (__) ___-__-__" ref={(input) => { this.mobile = input }} onInput={this.validateData.bind(this, 'mobile')} />
                                <div className="registration-container-form-group-box-icon">
                                    <img src={userIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__user" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        customerType === 'Juridical' ?
                        <div className="registration-container-form">
                            <div className="registration-container-form-group">
                                <label className="registration-container-form-group-label">{t('tin')}</label>
                                <div className="registration-container-form-group-box">
                                    <input type="text" className="registration-container-form-group-box-input" defaultValue={taxNo} placeholder={t('enter the tin')} ref={(input) => { this.taxNo = input }} onInput={this.validateData.bind(this, 'taxNo')} />
                                    <div className="registration-container-form-group-box-icon">
                                        <img src={barcodeAltIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__user" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="registration-container-validation-feedback">
                        {
                            feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage}</p> : null
                        }
                    </div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backLogin}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" onClick={this.checkData} disabled={isDisabled}>{t('next')}</button>
                    </div>
                </form>
            </div>
        )
    }
}



export default translate("translation")(checkUnblock);