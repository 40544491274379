import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next';
import { request } from '../../../../config';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from '../../Loading/Loading';
import currencyFilter from './../../../../helpers/currencyFilter';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import FriendOperationModal from '../../../../copms/Modal/FriendOperationModal';

export class FriendTransferToFriend extends Component {
    state = {
        isLoading: false,
        transfer: {},
        dtCard: {},
        transferExistence: false,
        rejectOpen: false,


        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
    }

    showCard = (id) => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardByIdForCustomerRequestBody = (customerType === 'Individual') ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            cardId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                cardId: id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }


        fetch(request("card/getCardByIdFor" + customerType, getCardByIdForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardByIdForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardByIdForCustomerResponse = JSON.parse(getCardByIdForCustomerResponseJSON);
                if (getCardByIdForCustomerResponse.status.statusCode !== 1) {
                    if (getCardByIdForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    // =========NUMBER=========
                    getCardByIdForCustomerResponse.organizedCardNumber = getCardByIdForCustomerResponse.cardNumber.substring(0, 4)
                        + " **** **** " + getCardByIdForCustomerResponse.cardNumber.substring(12, 16);
                    this.setState({ dtCard: getCardByIdForCustomerResponse });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");

                });
    }

    confirmReject = (value) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null
        })
        if (value) {
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let rejectTransferToFrienddRequestBody = customerType === 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                cardOperId: this.state.transfer.cardOperId,
                dtCardId: this.state.transfer.dtCardId,
                type: 2
            } : {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    cardOperId: this.state.transfer.cardOperId,
                    dtCardId: this.state.transfer.dtCardId,
                    type: 2
                }
            fetch(request("card/rejectTransferToFriendCust", rejectTransferToFrienddRequestBody))
                .then(res => res.text())
                .then((rejectTransferToFrienddResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let rejectTransferToFrienddResponse = JSON.parse(rejectTransferToFrienddResponseJSON);
                    if (rejectTransferToFrienddResponse.status.statusCode !== 1) {
                        if (rejectTransferToFrienddResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                            this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(rejectTransferToFrienddResponse.status.statusCode) });
                        }
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({ createFeedbackMessage: true, open: true });
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    updateOpen = (value) => {
        this.setState({ open: value });
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getTransferByIdRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
            cardOperId: this.props.match.params.id
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                
                cardOperId: this.props.match.params.id
            }
        fetch(request(`card/getTransferToFriendById`, getTransferByIdRequestBody))
            .then(res => res.text())
            .then((getTransferByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferByIdResponse = JSON.parse(getTransferByIdResponseJSON);
                if (getTransferByIdResponse.status.statusCode !== 1) {
                    if (getTransferByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getTransferByIdResponse.status.statusCode) });
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    // =========BALANCE=========
                    if (getTransferByIdResponse.dtAmount.toString() === "0") {
                        getTransferByIdResponse.organizedAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getTransferByIdResponse.dtAmount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = getTransferByIdResponse.dtAmount.toString().split('.');
                            getTransferByIdResponse.organizedAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            getTransferByIdResponse.organizedAmount = {
                                wholePart: getTransferByIdResponse.dtAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    this.setState({ transfer: getTransferByIdResponse, transferExistence: true });
                    this.showCard(getTransferByIdResponse.dtCardId);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { t, history } = this.props;
        let { isLoading, transfer, transferExistence, dtCard, rejectOpen,
            open, createFeedbackMessage, feedbackErrorModal } = this.state;
        return (
            <Fragment>
                <FriendOperationModal open={open} updateOpen={this.updateOpen} successClose={() => { history.push('/dashboard/transfer/card-operation/friend-transfer/history/1') }} errorClose={() => { this.setState({ confirmedOperation: false }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <YesNoModal onClose={() => this.setState({ rejectOpen: false })} title={t('Are you sure you want to reject the operation?')} open={rejectOpen} triggerFunc={val => this.confirmReject(val)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Transfer to friend')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !transferExistence ?
                            <NotFound />
                            :
                            <div className="dashboard-payment-container">
                                <div className="dashboard-payment-container-data-section">
                                    <div className="dashboard-payment-container-data-section-header">
                                        <h3 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content">
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title" style={{ color: '#0179D2' }}>{t('Transfer recipient')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Fullname')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.crCustName}</p>
                                                    </div>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Mobile number of the recipient')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer.crMobileNumber && `+${transfer.crMobileNumber}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title" style={{ color: '#0179D2' }}>{t('Transfer settings')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form">
                                                <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Pay by card')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtCard && dtCard?.organizedCardNumber}, {dtCard && dtCard.balance && dtCard.balance.toFixed(2)} {dtCard && dtCard?.currency}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Amount')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.dtAmount} {transfer && transfer?.dtCCY}</p>
                                                    </div>
                                                </div>
                                                {
                                                    transfer && transfer?.comment &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title" style={{ color: '#586268' }}>{t('Message')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.comment}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form">
                                                {
                                                    transfer && transfer?.operStatus === 4 &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                        <button type="button" onClick={() => { this.setState({ rejectOpen: true }) }}>
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-button-icon" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z" fill="#EF3E42" />
                                                            </svg>
                                                            <span className="dashboard-payment-container-data-section-content-container-form-button-title">{t("reject")}</span>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section">
                                    <div className="dashboard-payment-container-confirmation-section-header">
                                        <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-confirmation-section-content">
                                        <h3 className="dashboard-payment-container-confirmation-section-amount">
                                            <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + transfer.dtCCY?.toLocaleLowerCase()}>{currencyFilter(transfer.dtCCY)}</span>
                                            <span className="dashboard-payment-container-confirmation-section-amount-whole">{transfer?.organizedAmount?.wholePart}</span>
                                            <span className="dashboard-payment-container-confirmation-section-amount-fractional">{transfer?.organizedAmount?.fractionalPart}</span>
                                        </h3>
                                        <ul className="dashboard-payment-container-confirmation-section-list">
                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Recipients name')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer?.crCustName}</p>
                                            </li>
                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Mobile number of the recipient')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer.crMobileNumber && `+${transfer.crMobileNumber}`}</p>
                                            </li>
                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Withdrawal card')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtCard && dtCard?.organizedCardNumber}, {dtCard && dtCard.balance && dtCard.balance.toFixed(2)} {dtCard && dtCard?.currency}</p>
                                            </li>
                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer.dtAmount && transfer.dtAmount.toFixed(2)} {transfer.dtCCY}</p>
                                            </li>
                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer && transfer.dtFeeAmount} {transfer.dtCCY}</p>
                                            </li>
                                            {
                                                transfer && transfer.comment &&
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Message')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{transfer.comment}</p>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(FriendTransferToFriend);
