import React, { useEffect, useState, Component } from "react";
import {Link, useHistory, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import AuthHeader from "../../Auth/AuthHeader/AuthHeader";
import TimerCountdown from "../../Auth/TimerCountdown/TimerCountdown";
import { request } from "../../../config";
import { translate } from 'react-i18next';
import "../../Auth/OTP/OTP.scss";
import headerLogo from "../../AsanLoginConfirmation/img/header-logo.svg";

const LoginWithAsanJuridicalOtp = ({ t, updateAppState, }) => {
  window.addEventListener('beforeunload', function (event) {
    // Check if the event is a refresh
    if (performance.navigation.type === 1) {
      // Redirect the user to the home page
      window.location.href = '/'; // Replace '/' with the URL of your home page
    }
  });

  const [OTPCode, setOTPCode] = useState();
  const [errMessage, setErrMessage] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  const initialMinute = 3,
    initialSeconds = 0,
    numInputs = 6;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [isDisabled, setIsDisabled] = useState(true);
    const case1 = "00:00";
  
    const resetTime = () => {
      setErrMessage("");
      setMinutes(initialMinute);
      setSeconds(initialSeconds);
      setIsDisabled(true);
      sendOtpCode();
    };
  
    const formatTime = (time) => {
      return time < 10 ? `0${time}` : time;
    };
  
    useEffect(() => {
      let timeInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
  
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(timeInterval);
            // console.log("test");
            setIsDisabled(false);
          } else {
            setMinutes(minutes - 1);
  
            setSeconds(59);
          }
        }
      }, 1000);
  
      return () => {
        clearInterval(timeInterval);
      };
    });

  let history = useHistory();
  const { state } = useLocation();
  const location = useLocation();

  const handleOTP = (otp) => setOTPCode(otp);

  if (location.state === undefined) {
    // this.props.history.push('/login/juridical/asan/');
    window.location.href = '/login/juridical/asan/';
    // console.log("error")
}


  let { selectCustCompanyRequestBody, token, verifyToken, mobile } = location.state;
  const [verify, setIsVerify] = useState(verifyToken);

  useEffect(() => {
    setIsVerify(verifyToken);
  }, [verifyToken]);
//   let { getAuthInfoIndividualLoginRequestBody, getAuthInfoIndividualLoginHeaders } = location.state;
//   let getOtpFromAsanRequestBody2 = {
//     ...getAuthInfoIndividualLoginRequestBody, otpCode: OTPCode
//   }

  // console.log(getAuthInfoIndividualLoginRequestBody)
  // console.log(getAuthInfoIndividualLoginHeaders)
  // updateAppState("isLoadingTransparentBackground", false);

  const sendOtpCode = (e) => {
    updateAppState("isLoadingTransparentBackground", true);
    fetch(request("register/selectCustomerCompany", {...selectCustCompanyRequestBody, token: token}))
      .then((res) => res.json())
      .then(
        (getAuthInfoIndividualLoginRequestResponse) => {
          if (getAuthInfoIndividualLoginRequestResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              (getAuthInfoIndividualLoginRequestResponse.status.statusMessage)
            );
            setIsVisible(false)
          } else {
            setIsVerify(getAuthInfoIndividualLoginRequestResponse.customer.verifyToken)
            updateAppState("isLoadingTransparentBackground", false);
            // console.log(getAuthInfoIndividualLoginRequestResponse)
            // history.push("/sign-up/not-a-bank-customer/create-password", {
            //   custId: state.custId,
            // });
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };

  const verifyOtpCode = (e) => {
    e.preventDefault();

    updateAppState("isLoadingTransparentBackground", true);
    setErrMessage("");


    // console.log(getOtpFromAsanRequestBody2)
    // console.log(getAuthInfoIndividualLoginHeaders)

    // registerJuridicalAsan = () => {
        updateAppState('isLoadingTransparentBackground', true);
        if (location.state === undefined) {
            // this.props.history.push('/login/juridical/asan/');
            window.location.href = '/login/juridical/asan/';
            // console.log("error")
        }
        else {
            // let selectCustCompanyRequestBody = this.props.location.state;
            // let selectCustCompanyHeaders = {
            //     ...headers,
            //     'User-Agent': 'YourUserAgent',
            //     'Device-Name': 'Chrome Test4 (Windows 10)',
            //     'Device-Id': 'test4'

            // }
            fetch(request("register/selectCustomerCompany", { ...selectCustCompanyRequestBody, otpCode: OTPCode, token: token, verifyToken: verify }))
                .then(res => res.text())
                .then((selectCustCompanyResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let selectCustCompanyResponse = JSON.parse(selectCustCompanyResponseJSON);
                    if (selectCustCompanyResponse.status.statusCode !== 1) {
                        setErrMessage(selectCustCompanyResponse.status.statusMessage);
                        localStorage.removeItem('userId');
                        localStorage.removeItem('phoneNumber');
                        updateAppState('isLoadingTransparentBackground', false);


                        // this.props.history.push('/login/juridical/asan/', this.props.t(selectCustCompanyResponse.status.statusCode));    
                    }

                    else {
                            // updateAppState('isLoadingTransparentBackground', false);
                            // =========ID=========
                            let custId = "";
                            if (typeof (selectCustCompanyResponse.customer.custId) === "object" && selectCustCompanyResponse.customer.custId.hasOwnProperty('c')) {
                                selectCustCompanyResponse.customer.custId.c.forEach(part => {
                                    custId += part;
                                });
                            }
                            else {
                                custId += selectCustCompanyResponse.customer.custId;
                            }
                            // =========COMPID=========
                            let compId = "";
                            if (typeof (selectCustCompanyResponse.company.id) === "object" && selectCustCompanyResponse.company.id.hasOwnProperty('c')) {
                                selectCustCompanyResponse.company.id.c.forEach(part => {
                                    compId += part;
                                });
                            }
                            else {
                                compId += selectCustCompanyResponse.company.id;
                            }


                            // const JSON = require('true-json-bigint');
                            // let getCompaniesCertResponse = JSON.parse(getCompaniesCertResponseJSON);
                            // if (selectCustCompanyResponse.status.statusCode !== 1) {
                            //     localStorage.removeItem('userId');
                            //     localStorage.removeItem('phoneNumber');
                            //     // this.props.history.push('/login/juridical/asan/', this.props.t(getCompaniesCertResponse.status.statusCode));
                            //     console.log('error')
                            // }
                            // else {
                            //     for (let i = 0; i < selectCustCompanyResponse.company.length; i++) {
                            //         let companyId = "";
                            //         if (typeof (selectCustCompanyResponse.company[i].id) === 'object' && selectCustCompanyResponse.company[i].id.hasOwnProperty('c')) {
                            //             selectCustCompanyResponse.company[i].id.c.forEach(part => {
                            //                 companyId += part;
                            //             });
                            //         }
                            //         else {
                            //             companyId += selectCustCompanyResponse.company[i].id;
                            //         }
                            //         selectCustCompanyResponse.company[i].id = companyId;
                            //     }
                            //     this.setState({ companies: selectCustCompanyResponse.company });
                            //     this.props.updateAppState('isLoading', false);
                            // }
                            // =========FULLNAME=========
                            // let fullname = selectCustCompanyResponse.customer.name[0].toUpperCase() + selectCustCompanyResponse.customer.name.substring(1, selectCustCompanyResponse.customer.name.length).toLowerCase() +
                            //     ' ' + selectCustCompanyResponse.customer.surname[0].toUpperCase() + selectCustCompanyResponse.customer.surname.substring(1, selectCustCompanyResponse.customer.surname.length).toLowerCase() +
                            //     ' ' + selectCustCompanyResponse.customer.patronymic[0].toUpperCase() + selectCustCompanyResponse.customer.patronymic.substring(1, selectCustCompanyResponse.customer.patronymic.length).toLowerCase();
                            
                            let fullname = selectCustCompanyResponse.customer.surname + ' ' + selectCustCompanyResponse.customer.name + ' ' + selectCustCompanyResponse.customer.patronymic;
                            localStorage.setItem("custId", custId);
                            localStorage.setItem("compId", compId);
                            localStorage.setItem("token", selectCustCompanyResponse.customer.token);
                            localStorage.setItem("fullname", fullname);
                            localStorage.setItem("custObject", JSON.stringify(selectCustCompanyResponse));
                            localStorage.setItem("isOperNo", selectCustCompanyResponse.company.isOperNo);
                            localStorage.setItem("compIdEncrypt", selectCustCompanyResponse.company.compIdEncrypt);

                            localStorage.setItem("secretWord", selectCustCompanyResponse.customer.secretWord);
                            localStorage.setItem("custTypeId", selectCustCompanyResponse.customer.custTypeId);
                            localStorage.setItem("bankCustomerType", selectCustCompanyResponse.customer.bankCustomerType);
                            localStorage.setItem("compName", selectCustCompanyResponse.company.name);



                            setIsAuthed(true);
                            // console.log(selectCustCompanyResponse)
                            // history.push('/dashboard/home', );

                            // updateAppState('isLoadingTransparentBackground', false);
                            // if (isAuthed) {
                            //     return <Redirect to='/dashboard/home' />
                            //     // console.log(isAuthed)
                            // }

                    }
                },
                    (error) => {
                        localStorage.removeItem('userId');
                        localStorage.removeItem('phoneNumber');
                        history.push("/technical-break");
                    }
                );
        }
    // }
  };


  useEffect(() => {
    if(location.state === undefined){
      history.push("/sign-up/not-a-bank-customer")
    }
    else{
        updateAppState("isLoadingTransparentBackground", false)
    } ;
  }, []);
  // console.log(initialMinute)
  // const { t } = translate();
  useEffect(() => {
    if (isAuthed) {
      // Redirect to the desired page
      window.location.href = '/dashboard/home';
    }
  }, [isAuthed]);




  return (
    
    <div className="registration">
      <Link to="/">
        <img src={headerLogo} className="registration-logo" alt="headerLogo" />
      </Link>
      <div className="registration-container registration-container--top-border">
        <h2 className="registration-container-title registration-container-title--center">{t('otp code confirmation')}</h2>
        <p className="registration-container-title registration-container-title--center">{mobile}</p>
        <section className="ibank-otp header-sign-section">
          <div className="header-sign-section-container">
            <AuthHeader
              title={t("Enter the one-time code")}
              desc={[
                t("enter the 6-digit confirmation code sent to your phone"),
              ]}
            />
            <form onSubmit={verifyOtpCode}>
              <OtpInput
                className="ibank-otp__body"
                value={OTPCode}
                onChange={handleOTP}
                shouldAutoFocus={true}
                {...{ initialMinute, initialSeconds, numInputs }}
              />
              { isVisible &&
                (
                  <div className="ibank-otp__resend">
                    <button
                      disabled={isDisabled}
                      onClick={() => {
                        resetTime();
                      }}
                      type="button"
                    >
                      {t("resend the code")}
                    </button>
                    <p className="ibank-otp__resend__timer">
                      {minutes === 0 && seconds === 0
                        ? case1
                        : `${formatTime(minutes)} : ${formatTime(seconds)}`}
                    </p>
                  </div>
                )
                // <TimerCountdown
                //   resendTitle={t("resend the code")}
                //   {...{
                //     initialMinute,
                //     initialSeconds,
                //     sendOtpCode,
                //     setErrMessage,
                //   }}
                // />
              }

              <div className="registration-container-validation-feedback">
                {errMessage ? (
                  <p className="registration-container-validation-feedback-message">
                    {errMessage}
                  </p>
                ) : null}
              </div>
              <button
                className="header-sign-section-container-form-button"
                disabled={OTPCode?.length !== numInputs || (minutes === 0 && seconds === 0)}
              >
                {t("continue")}
              </button>
            </form>
          </div>
        </section>
        {/* <div className="registration-container-asan-confirmation">
        <span className="registration-container-asan-confirmation-code">{verificationCode}</span>
    </div> */}
        <div className="registration-container-validation-feedback"></div>
        <div className="registration-container-footer">
          {/* <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.prevPage}>{t('back')}</button> */}
          {/* <button type="button" className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled}>{t('next')}</button> */}
        </div>
      </div>
    </div>
  );
};

export default translate("translation")(LoginWithAsanJuridicalOtp);



