import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { request, ipsRequest } from './../../config';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

export default function ReverseMod(props) {
    const classes = useStyles();
    const [ amountOutline, setAmountOutline ] = useState(false);
    const [ reasonOutline, setReasonOutline ] = useState(false);

    const {
        t, income,
        open, setOpen,
        amount, setAmount,
        reason, setReason,
        index
    } = props;

    const close = () => {
        setOpen(false);
        setAmount('');
        setReason('');
        setAmountOutline(false);
        setReasonOutline(false);
    }
    // const onChangeAmount = (val) => {
    //     if (/^\d*\.{0,1}(\d{1,2})?$/.test(val) || val.length == 0) {
    //         setAmount(val);
    //     }
    // }
    const reversePayment = () => {
        props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let reversePaymentRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
            reverseAmount: amount,
            reverseReason: reason,
            operId: props.income.operId
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                
                reverseAmount: amount,
                reverseReason: reason,
                operId: props.income.operId
            }
        fetch(ipsRequest("reverseIpsOperation", reversePaymentRequestBody))
            .then(res => res.text())
            .then((reversePaymentResponseJSON) => {
                const JSON = require('true-json-bigint');
                let reversePaymentResponse = JSON.parse(reversePaymentResponseJSON);
                if (reversePaymentResponse.status.statusCode !== 1) {
                    if (reversePaymentResponse.status.statusCode === 200) {
                        props.logout();
                    } else {
                        close();
                        props.setCreateFeedbackMessage(false);
                        props.setOpenAlert(true);
                        props.setFeedbackErrorModal(props.t(reversePaymentResponse.status.statusCode));
                            // createFeedbackMessage: false, openAlert: true,
                            // feedbackErrorModal: this.props.t(reversePaymentResponse.status.statusCode)
                        props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    close();
                    let receivePayments = props.receivePayments;
                    receivePayments[props.index].amount = receivePayments[props.index].amount - amount;
                    receivePayments[props.index].reverseAmount = Number(amount);
                    props.setReceivePayments(receivePayments);
                    props.setCreateFeedbackMessage(true);
                    props.setOpenAlert(true);
                    props.setFeedbackSuccessModal(props.t('The income was successfully returned'));
                    props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <Modal
            open={open}
            onClose={close}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            classes={classes.modal}
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description">
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className="dashboard-reverse-modal">
                        <button className="dashboard-reverse-modal-close-button" onClick={close}>
                            <svg className="dashboard-reverse-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <div className="dashboard-reverse-modal-content">
                            <h3 className="dashboard-reverse-modal-content-title">{props.t("Refund of payment")}</h3>
                            <p className="dashboard-reverse-modal-content-subtitle" style={{marginBottom: '5px', marginTop: '10px'}}>{props.t("State the reason for the refund")}</p>
                            <p className="dashboard-reverse-modal-content-subtitle">{props.t("You can return the funds within 14 days")}</p>
                            
                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group">
                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('sum')}</label>
                                    <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline && !(amount && Number(amount) !== 0) ? "input-outline" : "")}>
                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={amount} onChange={e => onChangeAmount(e.target.value)} onBlur={() => { setAmountOutline(true) }} placeholder='0.00' />
                                        <div className={"dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"}>{income && income.currency}</div>
                                    </div>
                                </div> */}
                                <div className="dashboard-payment-container-data-section-content-container-form-input-group" style={{ marginTop: '20px' }}>
                                <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (reasonOutline && !reason ? "input-outline" : "")}>
                                        <textarea type="text" style={{ height: '116px', resize: 'none' }} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={reason} onChange={e => setReason(e.target.value)} onBlur={() => { setReasonOutline(true) }} placeholder={t('Enter the reason')} ></textarea>
                                    </div>
                                </div>
                                <div className="dashboard-payment-container-data-section-content-container-form-footer" style={{ textAlign: 'center' }}>
                                    {
                                        Boolean(amount && Number(amount) !== 0) && Boolean(reason) ?
                                            <button onClick={() => {reversePayment()}} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" >{t('continue')}</button>
                                            :
                                            <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left dashboard-payment-container-data-section-content-container-form-footer-button__disable">{t('continue')}</button>
                                    }
                                    <button onClick={close} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal >
    );
}
