import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import headerLogo from "./../../Registration/img/header-logo.svg";
import forgotPasswordIcon from "./../../Registration/img/forgot-password.svg";
import eyeIconForm from "./../../Registration/img/eye-icon-form.svg";
import eyeSlashIconForm from "./../../Registration/img/eye-slash-icon-form.svg";
import { translate } from 'react-i18next';
import {request} from '../../../config';

class ChangePassword extends Component {
    state = {
        customerType: 'Individual',
        isPasswordVisibility: false,
        isRepeatPasswordVisibility: false,
        isDisabled: true,
        feedbackMessage: null
    }

    backLogin = () => {
        if(this.state.customerType === 'Juridical') {
            this.props.history.push('/login/juridical/mobile');
        }
        else {
            this.props.history.push('/login/individual/mobile');
        }
    }

    checkIsNotNull = () => {
        this.setState({ feedbackMessage: null });

        if (this.password.value.trim() !== "" && this.repeatPassword.value.trim() !== "" && this.password.value === this.repeatPassword.value) {
            this.setState({ isDisabled: false });
        }
        else {
            this.setState({ isDisabled: true });
        }
    }

    setPasswordVisibility = (name) => {
        this.setState({ [name]: !this.state[name] });
    }
    
    setPassword = (event) => {
        event.preventDefault();
        let changePasswordRequestBody = this.state.customerType === 'Justomer' ? {
            password: this.password.value,
            repeatPassword: this.repeatPassword.value,
            // custId: this.props.location.state.custId,
            // compId: this.props.location.state.compId,
            verifyToken: this.props.location.state.verifyToken
        }:{
            password: this.password.value,
            repeatPassword: this.repeatPassword.value,
            // custId: this.props.location.state.custId,
            verifyToken: this.props.location.state.verifyToken
        };
        fetch(request("register/changeForgotPassword" + this.state.customerType, changePasswordRequestBody))
            .then(res => res.json())
            .then((changePasswordResponse) => {
                if (changePasswordResponse.status.statusCode === 1) {
                    if(this.state.customerType === 'Juridical') {
                        this.props.history.push('/login/juridical/mobile/forgot-password/4', {});
                    }
                    else {
                        this.props.history.push('/login/individual/mobile/forgot-password/4', {});
                    }
                }
                else {
                    this.setState({ feedbackMessage: this.props.t(changePasswordResponse.status.statusCode)});
                }
            },
            (error) => {
                this.props.history.push("/technical-break");
            });
    }

    componentDidMount() {
        if(this.props.location.state === undefined) {
            if(this.props.match.url === '/login/juridical/mobile/forgot-password/3') {
                this.props.history.push('/login/juridical/mobile/forgot-password/1');
            }
            else {
                this.props.history.push('/login/individual/mobile/forgot-password/1');
            }
        }
        else {
            if(this.props.match.url === '/login/juridical/mobile/forgot-password/3') {
                this.setState({customerType: 'Juridical'});
            }
            else if(this.props.match.url === '/login/individual/mobile/forgot-password/3') {
                this.setState({customerType: 'Individual'});
            }
        }
    }

    render() {
        let { feedbackMessage, isDisabled, isPasswordVisibility, isRepeatPasswordVisibility } = this.state;
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title">{t('forget password')}</h2>
                    <p className="registration-container-description">{t('enter your new password')}</p>
                    <img src={forgotPasswordIcon} className="registration-container-icon" alt="forgotPasswordIcon" />
                    <div className="registration-container-form-grid">
                        <div className="registration-container-form-group registration-container-form-group-grid">
                            <label className="registration-container-form-group-label">{t('password')}</label>
                            <div className="registration-container-form-group-box">
                                <input type={isPasswordVisibility === false ? "password" : "text"} className="registration-container-form-group-box-input" placeholder={t('enter the password')} ref={(input) => { this.password = input }} onInput={this.checkIsNotNull} />
                                <div className="registration-container-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isPasswordVisibility')}>
                                    {
                                        isPasswordVisibility === false ?
                                        <img src={eyeIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password" alt="" />
                                        :
                                        <img src={eyeSlashIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password-slash" alt="" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="registration-container-form-group registration-container-form-group-grid">
                            <label className="registration-container-form-group-label">{t('repeat password')}</label>
                            <div className="registration-container-form-group-box">
                                <input type={isRepeatPasswordVisibility === false ? "password" : "text"} className="registration-container-form-group-box-input" placeholder={t('enter the password again')} ref={(input) => { this.repeatPassword = input }} onInput={this.checkIsNotNull} />
                                <div className="registration-container-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isRepeatPasswordVisibility')}>
                                    {
                                        isRepeatPasswordVisibility === false ?
                                        <img src={eyeIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password" alt="" />
                                        :
                                        <img src={eyeSlashIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__password-slash" alt="" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="registration-container-validation-feedback">
                        {
                            feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage}</p> : null
                        }
                    </div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backLogin}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled} onClick={this.setPassword}>{t('next')}</button>
                    </div>
                </form>
            </div>
        )
    }
}


export default translate("translation")(ChangePassword);