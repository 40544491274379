import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function ConfirmModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const close = () => {
        setOpen(false);
        props.onClose();
    }
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const triggerFunc = (val) => {
        close()
        if (val) {
            props.triggerFunc(val);
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="modal-confirm-reg">
                            <div className="modal-confirm-reg-head">
                                <h2>{props.t('Rules')}</h2>
                                <button onClick={close}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" fill="white" />
                                        <path d="M13.6776 11.975L18.8357 6.86698L19.8873 5.81534C20.0376 5.6651 20.0376 5.41471 19.8873 5.2144L18.7856 4.11268C18.5853 3.96244 18.3349 3.96244 18.1847 4.11268L12.025 10.3224L5.81534 4.11268C5.6651 3.96244 5.41471 3.96244 5.2144 4.11268L4.11268 5.2144C3.96244 5.41471 3.96244 5.6651 4.11268 5.81534L10.3224 11.975L4.11268 18.1847C3.96244 18.3349 3.96244 18.5853 4.11268 18.7856L5.2144 19.8873C5.41471 20.0376 5.6651 20.0376 5.81534 19.8873L12.025 13.6776L17.133 18.8357L18.1847 19.8873C18.3349 20.0376 18.5853 20.0376 18.7856 19.8873L19.8873 18.7856C20.0376 18.5853 20.0376 18.3349 19.8873 18.1847L13.6776 11.975Z" fill="#2E3131" />
                                    </svg>
                                </button>
                            </div>
                            <p className="modal-confirm-reg-content">
                                {props.t("ips rules")}
                            </p>
                            <div className="modal-confirm-reg-buttons">
                                <button className="modal-confirm-reg-buttons-yes" onClick={() => triggerFunc(true)}>{props.t('agree')}</button>
                                <button className="modal-confirm-reg-buttons-no" onClick={() => triggerFunc(false)}>{props.t('i do not agree')}</button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(ConfirmModal);