import firebase from 'firebase/app';
import 'firebase/messaging';
var config = {
    apiKey: "AIzaSyBn9TZDMwN3clQDfZO2ybbRt-AcRmunVQI",
    authDomain: "mobilbank-1577273874640.firebaseapp.com",
    databaseURL: "https://mobilbank-1577273874640.firebaseio.com",
    projectId: "mobilbank-1577273874640",
    storageBucket: "mobilbank-1577273874640.appspot.com",
    messagingSenderId: "98350583386",
    appId: "1:98350583386:web:e14b721a380b3e56afd263",
    measurementId: "G-MYP8QGPMCD"

};
let messaging = null;
if (firebase.messaging.isSupported()) {
    firebase.initializeApp(config)
    messaging = firebase.messaging()
} else {

}

export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        if (firebase.messaging.isSupported()) {
            messaging
                .requestPermission()
                .then(() => messaging.getToken())
                .then((firebaseToken) => {
                    resolve(firebaseToken);
                })
                .catch((err) => {
                    reject(err);
                });
        } else {
            reject('Not supported')
        }

    });

export const deleteFirebaseToken=()=>{
    messaging.deleteToken();
}
export const onMessageListener = () =>
    new Promise((resolve, reject) => {
        if (firebase.messaging.isSupported()) {
            messaging.onMessage((payload) => {
                resolve(payload);
            });
        } else {
            reject("Not supported")
        }
    });

export const onBackground = () =>
    new Promise((resolve, reject) => {
        if (firebase.messaging.isSupported()) {
            messaging.setBackgroundMessageHandler((payload) => {     
                resolve(payload)
                // return self.registration.showNotification(title, options);
           });
        } else {
            reject("Not supported")
        }
    });

export default messaging;
