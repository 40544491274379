import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { YMaps, Map, Placemark, GeolocationControl, FullscreenControl, ZoomControl, RulerControl, Clusterer, } from 'react-yandex-maps';
import { Link, NavLink, Switch, Route, Redirect, } from 'react-router-dom';
import { request } from '../../../../config';
import bankIcon from './../../img/bank_icon.png';
import currentIcon from './../../img/current-icon.jpg';
import atmIcon from './../../img/atm_icon.png';
import markerIcon from '../../img/marker_icon.svg';
class Branchs extends Component {
    state = {
        branchs: [],
        locatinAvailable: false,
        location: {
            lat: 40.105482,
            long: 47.869358,
            zoom: 8
        }
    }
    componentDidMount() {
        this.showBranchs();
        this.currentLocation()
    }

    currentLocation() {
        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({ location: { lat: position.coords.latitude, long: position.coords.longitude, zoom: 10 }, locatinAvailable: true })
            }, () => {

            });
        } else {

        }
    }

    filterLangMap(val) {
        switch (val) {
            case "az":
                return "az_AZ";
            case "ru":
                return "ru_RU";
            case "en":
                return "en_US";
            default:
                return "az_AZ";
        }
    }

    showBranchs = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBranchMapListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("util/getBranchMapList/" + localStorage.getItem('i18nextLng').toUpperCase(), getBranchMapListRequestBody))
            .then(res => res.text())
            .then((getBranchMapListrResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBranchMapListrResponse = JSON.parse(getBranchMapListrResponseJSON);
                if (getBranchMapListrResponse.status.statusCode !== 1) {
                    if (getBranchMapListrResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBranchMapListrResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ branchs: getBranchMapListrResponse.respBranchMapList });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { t, history, i18n, branchs, atms } = this.props;
        let { locatinAvailable } = this.state;
        return (
            <ul className="dashboard-transfer-history-content" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {
                    branchs && branchs.map((branch, index) => (
                        <li className="dashboard-transfer-history-content-item" key={index}>
                            <div className="dashboard-transfer-history-content-item-container__left-side">
                                <div className="dashboard-transfer-history-content-item-container-icon-section" style={{ background: 'none' }}>
                                    <img src={markerIcon} className="dashboard-last-operations-section-content-image" style={{ width: 21, height: 21 }} alt="lastOperationsIcon" />
                                </div>
                                <Link to={`/dashboard/service-network/branch/${branch.branchId}`} style={{ textDecoration: 'none' }} className="dashboard-transfer-history-content-item-container-content">
                                    <p className="dashboard-transfer-history-content-item-container-name">{branch.branchName}</p>
                                    <p className="dashboard-transfer-history-content-item-container-iban">{branch.address}</p>
                                </Link>
                            </div>

                        </li>
                    ))
                }
                {
                    atms && atms.map((atm, index) => (
                        <li className="dashboard-transfer-history-content-item" key={index}>
                            <div className="dashboard-transfer-history-content-item-container__left-side">
                                <div className="dashboard-transfer-history-content-item-container-icon-section" style={{ background: 'none' }}>
                                    <img src={markerIcon} className="dashboard-last-operations-section-content-image" style={{ width: 21, height: 21 }} alt="lastOperationsIcon" />
                                </div>
                                <button onClick={e => this.props.onSelectAtm([atm.latitude, atm.longitude])} className="dashboard-transfer-history-content-item-container-content">
                                    <p className="dashboard-transfer-history-content-item-container-name">{atm.address}</p>
                                    <p className="dashboard-transfer-history-content-item-container-iban" style={{ textAlign: 'left' }}>{atm.address}</p>
                                </button>
                            </div>

                        </li>
                    ))
                }
            </ul>
        )
    }
}
export default translate("translation")(Branchs);
