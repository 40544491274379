import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import Operations from './Operations/Operations';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import cardsIcon from './../../img/cards-icon.png';
import Loading from './../../Loading/Loading';

class SecondAuthorisation extends Component {
    state = {
        salarys: [],
        operationOpen: false,
        operationType: 'error',
        operationData: [],
        signLevel: null,
        signCount: null
    }

    componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({signLevel: custObject.company.signLevel});
            this.setState({signCount: custObject.company.signCount});
        }
        this.setState({isLoading: true});
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAuthOperationListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("salary/getFirstAuthSalaryList", getAuthOperationListRequestBody))
            .then(res => res.text())
            .then((getAuthOperationListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAuthOperationListResponse = JSON.parse(getAuthOperationListResponseJSON);

                // 214
                if (getAuthOperationListResponse.status.statusCode !== 1 && getAuthOperationListResponse.status.statusCode !== 214) {
                    if (getAuthOperationListResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    this.setState({ salarys: getAuthOperationListResponse.batchOperationList })
                }
                this.setState({isLoading: false});

            },
                (error) => {

                    this.props.history.push("/technical-break");
                });
    }

    dropdownItemHandler = (index, excelIndex, id) => {
        switch (index) {
            case 3:
                this.setState({ id: id }, () => this.setState({ open: true }));
                break;
            case 1:
                this.setState({ id: id, selectedIndex: excelIndex }, () => this.setState({ openDelete: true }));
                break;
        }
    }

    refreshData(data) {
        this.setState({ salarys: data });
    }

    render() {
        let { t, alertState, updateAppState } = this.props;
        let { signLevel, signCount, convertations, salarys, isLoading } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>
                            {
                                (signLevel === 1 && signCount === 1) ?
                                    t('authorization')
                                    :
                                    t('1st authorization')
                            }
                        </h2>
                    </div>
                </div>
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            <Operations data={salarys} refreshData={data => this.refreshData(data)} updateAppState={updateAppState} alertState={alertState} />
                    }
                </div>
            </Fragment>
        )
    }
}

export default translate("translation")(SecondAuthorisation);