import React, { Component } from 'react';
import cardBlockIcon from './../img/card-block-icon.png';
import {request} from '../../../../config';
import { translate } from 'react-i18next';

class BlockCard extends Component {
    state = {
        blockReason: null,
    }
    updateBlockReason = (value) => {
        let blockReason;
        switch(value) {
            case 1: blockReason = this.props.t('I lost my card');
                    break;
            case 2: blockReason = this.props.t('My card was stolen');
                    break;
            case 3: blockReason = this.props.t('For security reasons');
                    break;        
        }
        this.setState({ blockReason: blockReason});
    }
    blockCard = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let blockCardForCustomerRequestBody; 
        blockCardForCustomerRequestBody = (customerType === 'Individual') ? 
        {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            cardId: this.props.modalCardObj.cardId,
            blockReason: this.state.blockReason,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        :
        {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            cardId: this.props.modalCardObj.cardId,
            blockReason: this.state.blockReason,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch( request("card/blockCardFor" + customerType + 'Customer', blockCardForCustomerRequestBody ))
        .then(res => res.text())
        .then((blockCardForCustomerResponseJSON) => {
            const JSON = require('true-json-bigint');
            let blockCardForCustomerResponse = JSON.parse(blockCardForCustomerResponseJSON);
            if(blockCardForCustomerResponse.status.statusCode !== 1 ) {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.props.setErrorModal(true);
                this.props.setFeedbackModal(true);
            }
            else {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.props.setFeedbackModal(true);
            }
        },
        (error) => {
            this.props.updateAppState('isLoadingTransparentBackground', false);
            this.props.setErrorModal(true);
            this.props.setFeedbackModal(true);
        });
    }
    componentWillMount() {
        this.setState({ blockReason: this.props.t('I lost my card') });
    }
    render() {
        let { close, feedbackModal, errorModal, t } = this.props;
        return (
        <>
           { 
            feedbackModal === false &&  
            <div className="block-card-section-modal">
                <button className="block-card-section-modal-close-button" onClick={close}>
                    <svg className="block-card-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131"/>
                    </svg>
                </button>
                <div className="block-card-section-modal-content">
                    <img src={cardBlockIcon} className="block-card-section-modal-content-image"/>
                    <h3 className="block-card-section-modal-content-title">{t('the reason for blocking the card')}</h3>
                    <p className="block-card-section-modal-content-details">{t('Please state your reason for blocking the card')}</p>
                    <form className="block-card-section-modal-content-form">
                        <ul className="block-card-section-modal-content-form-list">
                            <li className="block-card-section-modal-content-form-list-item">
                                <span className="block-card-section-modal-content-form-list-item-title">{t('I lost my card')}</span>
                                <input type="radio" name="card-block-reason" className="block-card-section-modal-content-form-list-item-input" defaultChecked onChange={this.updateBlockReason.bind(this, 1)}/>
                                <span className="block-card-section-modal-content-form-list-item-checkmark"></span>
                            </li>
                            <li className="block-card-section-modal-content-form-list-item">
                                <span className="block-card-section-modal-content-form-list-item-title">{t('My card was stolen')}</span>
                                <input type="radio" name="card-block-reason" className="block-card-section-modal-content-form-list-item-input"  onChange={this.updateBlockReason.bind(this, 2)}/>
                                <span className="block-card-section-modal-content-form-list-item-checkmark"></span>
                            </li>
                            <li className="block-card-section-modal-content-form-list-item">
                                <span className="block-card-section-modal-content-form-list-item-title">{t('For security reasons')}</span>
                                <input type="radio" name="card-block-reason" className="block-card-section-modal-content-form-list-item-input"  onChange={this.updateBlockReason.bind(this, 3)}/>
                                <span className="block-card-section-modal-content-form-list-item-checkmark"></span>
                            </li>
                        </ul>
                        <div className="block-card-section-modal-content-form-button-section">
                           <button type="button" className="block-card-section-modal-content-form-button block-card-section-modal-content-form-button--left-side"  onClick={close}>{t('cancel')}</button>
                            <button className="block-card-section-modal-content-form-button block-card-section-modal-content-form-button--right-side" onClick={this.blockCard}>{t('continue')}</button>
                        </div>
                    </form>
                </div>
            </div>
            }
            {
                feedbackModal === true && errorModal === false &&
                <div className="block-card-section-modal">
                    <button className="block-card-section-modal-close-button" onClick={close}>
                        <svg className="block-card-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131"/>
                        </svg>
                    </button>
                    <div className="block-card-section-modal-content">
                        <img src={cardBlockIcon} className="block-card-section-modal-content-image"/>
                        <h3 className="block-card-section-modal-content-title">{t('your card has been blocked successfully')}</h3>
                        <p className="block-card-section-modal-content-details">{t('dear user, you can activate your blocked card at any time.')}</p>
                        <form className="block-card-section-modal-content-form">
                            <div className="block-card-section-modal-content-form-button-section">
                                <button className="block-card-section-modal-content-form-button block-card-section-modal-content-form-button--right-side" onClick={close}>{t('continue')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            }
            {
                feedbackModal === true && errorModal === true &&
                <div className="change-account-name-error">
                    <button className="change-account-name-error-close-button" onClick={close}>
                        <svg className="change-account-name-error-close-button-icon" width="144" height="145" viewBox="0 0 144 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M72 0.8125C32.3984 0.8125 0.3125 32.8984 0.3125 72.5C0.3125 112.102 32.3984 144.188 72 144.188C111.602 144.188 143.688 112.102 143.688 72.5C143.688 32.8984 111.602 0.8125 72 0.8125ZM72 134.938C37.6016 134.938 9.5625 107.188 9.5625 72.5C9.5625 38.3906 37.3125 10.0625 72 10.0625C106.109 10.0625 134.438 38.1016 134.438 72.5C134.438 106.898 106.398 134.938 72 134.938ZM99.1719 52.5547C100.617 51.1094 100.617 49.0859 99.1719 47.6406L96.8594 45.3281C95.4141 43.8828 93.3906 43.8828 91.9453 45.3281L72 65.2734L51.7656 45.3281C50.6094 43.8828 48.2969 43.8828 46.8516 45.3281L44.5391 47.6406C43.0938 49.0859 43.0938 51.1094 44.5391 52.5547L64.4844 72.5L44.5391 92.7344C43.0938 93.8906 43.0938 96.2031 44.5391 97.6484L46.8516 99.9609C48.2969 101.406 50.6094 101.406 51.7656 99.9609L72 80.0156L91.9453 99.9609C93.3906 101.406 95.4141 101.406 96.8594 99.9609L99.1719 97.6484C100.617 96.2031 100.617 93.8906 99.1719 92.7344L79.2266 72.5L99.1719 52.5547Z" fill="#E54143" />
                        </svg>
                    </button>
                    <div className="change-account-name-error-content">
                        <div className={"change-account-name-error-content-header change-account-name-error-content-header--error"}>
                            <svg className="change-account-name-error-content-header-icon" width="144" height="145" viewBox="0 0 144 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M72 0.8125C32.3984 0.8125 0.3125 32.8984 0.3125 72.5C0.3125 112.102 32.3984 144.188 72 144.188C111.602 144.188 143.688 112.102 143.688 72.5C143.688 32.8984 111.602 0.8125 72 0.8125ZM72 134.938C37.6016 134.938 9.5625 107.188 9.5625 72.5C9.5625 38.3906 37.3125 10.0625 72 10.0625C106.109 10.0625 134.438 38.1016 134.438 72.5C134.438 106.898 106.398 134.938 72 134.938ZM99.1719 52.5547C100.617 51.1094 100.617 49.0859 99.1719 47.6406L96.8594 45.3281C95.4141 43.8828 93.3906 43.8828 91.9453 45.3281L72 65.2734L51.7656 45.3281C50.6094 43.8828 48.2969 43.8828 46.8516 45.3281L44.5391 47.6406C43.0938 49.0859 43.0938 51.1094 44.5391 52.5547L64.4844 72.5L44.5391 92.7344C43.0938 93.8906 43.0938 96.2031 44.5391 97.6484L46.8516 99.9609C48.2969 101.406 50.6094 101.406 51.7656 99.9609L72 80.0156L91.9453 99.9609C93.3906 101.406 95.4141 101.406 96.8594 99.9609L99.1719 97.6484C100.617 96.2031 100.617 93.8906 99.1719 92.7344L79.2266 72.5L99.1719 52.5547Z" fill="#E54143" />
                            </svg>
                            <h3 className="change-account-name-error-content-header-title">{t("An error occurred during the operation.")}</h3>
                            <p className="change-account-name-error-content-header-subtitle">{t("Please try again")}</p>
                        </div>
                    </div>
                    <div className="change-account-name-error-footer">
                        <button className={"change-account-name-error-footer-button change-account-name-error-footer-button--error"} onClick={close}>{t('continue')}</button>
                    </div>
                </div>
            }
        </>            
        )
    }
}

export default translate("translation")(BlockCard);
