import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from './../../../../helpers/currencyFilter';
import AntSwitch from './../../../../copms/Switch/AntSwitch';
import UpdateModal from './../../../../copms/Modal/UpdateModal';
import { timers } from 'jquery';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from './../../Loading/Loading';
import $ from 'jquery';
import OutsideClickHandler from 'react-outside-click-handler';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

class updateInlandOperation extends Component {
    state = {
        feedbackErrorModal: null,
        createFeedbackMessage: false,
        open: false,
        confirmedOperation: false,
        dropdownVisibilityBank: false,
        dropdownVisibilityAccount: false,
        dropdownVisibilityClassification: false,
        dropdownVisibilityLevel: false,
        banks: [],
        accounts: [],
        budgetCodes: [],
        budgetLevels: [],
        selectedAccount: null,
        currency: "",
        selectedBank: {},
        selectedBudgetClassification: {},
        selectedBudgetLevel: {},
        bankFilter: "",
        budgetClassificationFilter: "",
        budgetLevelFilter: "",
        crCustomerName: "",
        crCustomerNameValidation: false,
        crIban: "",
        crIbanValidation: false,
        crTaxNo: "",
        crTaxNoValidation: true,
        amount: "",
        crrAmount: "",
        amountValidation: false,
        organizedAmount: {},
        purpose: "",
        purposeValidation: false,
        dtAccountId: null,
        dtAccountIban: "",
        note: "",
        noteValidation: false,
        showBudget: false,
        budgetCode: "",
        budgetLevel: "",
        showUrgent: false,
        disableCurrency: false,
        switch: false,
        operationId: 0,
        tempHave: false,
        isLoading: false,
        accountData: false,
        custOperNo: null,
        dtCustName: null,
        dtCustTaxid: null,
        files: [],

        crCustPin: "",

        amountOutline: false,
        purposeOutline: false,
        crCustomerNameOutline: false,

        showPaymentTypes: false,
        dropdownVisibilityPaymentType: false,
        paymentTypes: [],
        selectedPaymentType: null,
        paymentTypeValidation: false,
        paymentTypeOpenCount: 0,
        feeAmount: 0
    }
    saveFile = (name, type, data) => {
        if (data !== null && navigator.msSaveBlob)
            return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        var a = $("<a style='display: none;'/>");
        var url = window.URL.createObjectURL(new Blob([data], { type: type }));
        a.attr("href", url);
        a.attr("download", name);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
    getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result, file)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    base64ToArrayBuffer(byteArray, filename) {
        let bstr = atob(byteArray);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename);
    }
    chooseFile = (event) => {
        let files = this.state.files;
        for (let i = 0; i < event.target.files.length; i++) {
            files.push({ files: event.target.files[i], fileName: event.target.files[i].name });
        }
        this.setState({ files: files });
    }
    deleteFile = (index) => {
        let files = this.state.files;
        files.splice(index, 1);
        this.setState({ files: files });
    }
    downloadFile = (index) => {
        let arrrayBuffer = this.state.files[index].files;
        this.saveFile(this.state.files[index].fileName, null, arrrayBuffer);
    }
    amountSplit = (amount) => {
        var splitedAmount = amount.toString().split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    updateSelectedCrIban = (budgetAccount) => {
        this.setState({
            crIban: budgetAccount.iban,
            crCustomerName: budgetAccount.name,
            crTaxNo: budgetAccount.taxNumber,
            crIbanValidation: true,
            crCustomerNameValidation: true,
            crTaxNoValidation: true,
            dropdownVisibilityCrIban: false,
            crCustomerNameOutline: false
        });
        this.showCrIban(budgetAccount.iban);
    }
    updateSelectedCrIbanAll = (budgetAccount) => {
        this.setState({
            crIban: budgetAccount.crIban,
            // crCustomerName: budgetAccount.name,
            // crTaxNo: budgetAccount.taxNumber,
            crIbanValidation: true,
            // crCustomerNameValidation: true,
            // crTaxNoValidation: true,
            dropdownVisibilityCrIban: false
        }, () => {
            this.showBudgetAccountAll();
        });
        // this.showCrIban(budgetAccount.crIban);

    }
    updateBankFilter = (event) => {
        this.setState({ selectedBank: {}, bankFilter: event.target.value, showBudget: false, selectedBudgetClassification: {}, selectedBudgetLevel: {}, showUrgent: false, disableCurrency: false, budgetLevelFilter: "", budgetClassificationFilter: "" });
        // this.setState({ crCustomerName: "", crTaxNo: "", crCustomerNameValidation: false, crTaxNoValidation: true });
    }
    updateSelectedBank = (bank) => {
        this.setState({ selectedBank: bank, bankFilter: bank.title, showBudget: false, selectedBudgetClassification: {}, selectedBudgetLevel: {}, showUrgent: false, disableCurrency: false, budgetLevelFilter: "", budgetClassificationFilter: "", });
        // this.setState({ crCustomerName: "", crTaxNo: "", crCustomerNameValidation: false, crTaxNoValidation: true });
        if (bank.bankCode == "210005") {
            // this.showBudgetCodes();
            // this.showBudgetLevels();
            this.setState({ showBudget: true });
            if (this.state.crIbanValidation === true) {
                this.showBudgetAccount();
            }
        }
        else {
            if (this.state.crIbanValidation === true) {
                this.showBudgetAccountAll();
            }
        }
        if (bank.bankCode !== "210005" && bank.bankCode !== "210027") {
            this.setState({ showUrgent: true });
        }
        else {
            if (this.state.currency !== "AZN") {
                this.setState({ dtAccountId: null, dtAccountIban: "", selectedAccount: null, dtAccontIdValidation: false })
            }
            this.setState({ disableCurrency: true });
        }
        this.changeDropdownVisibilityBank();
    }
    updateSelectedPaymentType = (type, id) => {
        if (id) {
            const selectedPaymentType = this.state.paymentTypes.find(type => type?.id == id)
            this.setState({ selectedPaymentType, paymentTypeValidation: true, purpose: this.state.purpose && this.state.purpose.substring(0, 140 - selectedPaymentType.nameLength).replace(selectedPaymentType.info, '').trim() });
        }
        else
            this.setState({ selectedPaymentType: type, paymentTypeValidation: true, dropdownVisibilityPaymentType: false });
    }
    updateDtAccountId = (dtAccountId, dtAccountIban, currency, event) => {
        this.setState({ dtAccountId: dtAccountId, dtAccountIban: dtAccountIban, selectedAccount: event.target.textContent, currency: currency, dtAccontIdValidation: true, dropdownVisibilityAccount: false });
    }
    updateDtAccountIdFromTemplate = (dtIban) => {
        let account = this.state.accounts.find(account => account.iban === dtIban);
        this.setState({
            dtAccountId: account ? account.accountId : null,
            dtAccountIban: account ? dtIban : null,
            selectedAccount: account ? (account.iban + ' / ' + account.organizedCurrentBalance.wholePart + '.' + account.organizedCurrentBalance.fractionalPart + ' ' + account.currName) : null
        }, () => this.showTransferInland());
    }
    updateClassificationCodeFilter = (event) => {
        this.setState({ selectedBudgetClassification: {}, budgetClassificationFilter: event.target.value });
    }
    updateLevelCodeFilter = (event) => {
        this.setState({ selectedBudgetLevel: {}, budgetLevelFilter: event.target.value });
    }
    updateSelectedClassificationCode = (budgetClassification) => {
        this.setState({ selectedBudgetClassification: { id: budgetClassification.budgetId, budgetValue: budgetClassification.budgetValue }, budgetClassificationFilter: budgetClassification.budgetValue });

        this.changeDropdownVisibilityClassification();
    }
    updateSelectedLevelCode = (budgetLevel) => {
        this.setState({ selectedBudgetLevel: { id: budgetLevel.budgetId, budgetValue: budgetLevel.budgetValue }, budgetLevelFilter: budgetLevel.budgetValue });

        this.changeDropdownVisibilityLevel();
    }
    updateSelectedClassificationCodeFromTemplate = (id) => {
        let budgetClassification = this.state.budgetCodes.find(code => code.budgetId === id);
        this.setState({
            selectedBudgetClassification: budgetClassification ? {
                id: budgetClassification.budgetId,
                budgetValue: budgetClassification.budgetValue
            } : {},
            budgetClassificationFilter: budgetClassification ? budgetClassification.budgetValue : ""
        });
    }
    updateSelectedLevelCodeFromTemplate = (id) => {
        let budgetLevel = this.state.budgetLevels.find(lvl => lvl.budgetId === id);
        this.setState({
            selectedBudgetLevel: budgetLevel ? {
                id: budgetLevel.budgetId,
                budgetValue: budgetLevel.budgetValue
            } : {},
            budgetLevelFilter: budgetLevel ? budgetLevel.budgetValue : ""
        });
    }
    updateSwitch() {
        this.setState({ switch: !this.state.switch });
    }
    changeDropdownVisibilityBank = () => {
        this.setState({ dropdownVisibilityBank: !this.state.dropdownVisibilityBank });
    }
    openDropdownVisibilityBank = () => {
        this.setState({ dropdownVisibilityBank: true });
    }
    closeDropdownVisibilityBank = () => {
        this.setState({ dropdownVisibilityBank: false });
    }
    changeDropdownVisibilityAccount = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityAccount: !this.state.dropdownVisibilityAccount });
    }
    changeDropdownVisibilityPaymentType = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityPaymentType: !this.state.dropdownVisibilityPaymentType }
            , () => {
                if (!this.state.paymentTypeValidation) {
                    this.setState({ paymentTypeOpenCount: ++this.state.paymentTypeOpenCount });
                }
            }
        );
    }
    closeDropdownVisibilityAccount = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityAccount: false });
    }
    closeDropdownVisibilityPaymentType = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityPaymentType: false });
    }
    changeDropdownVisibilityClassification = () => {
        this.setState({ dropdownVisibilityClassification: !this.state.dropdownVisibilityClassification });
    }
    closeDropdownVisibilityClassification = () => {
        this.setState({ dropdownVisibilityClassification: false });
    }
    openDropdownVisibilityClassification = () => {
        this.setState({ dropdownVisibilityClassification: true });
    }
    changeDropdownVisibilityLevel = () => {
        this.setState({ dropdownVisibilityLevel: !this.state.dropdownVisibilityLevel });
    }
    closeDropdownVisibilityLevel = () => {
        this.setState({ dropdownVisibilityLevel: false });
    }
    openDropdownVisibilityLevel = () => {
        this.setState({ dropdownVisibilityLevel: true });
    }
    changeDropdownVisibilityCrIban = () => {
        this.setState({ dropdownVisibilityCrIban: !this.state.dropdownVisibilityCrIban });
    }
    openDropdownVisibilityCrIban = () => {
        this.setState({ dropdownVisibilityCrIban: true });
    }
    closeDropdownVisibilityCrIban = () => {
        this.setState({ dropdownVisibilityCrIban: false });
    }

    checkCrIban = (event) => {
        this.setState({ crIbanValidation: false });
        this.setState({ crCustomerName: "", crTaxNo: "", crCustomerNameValidation: false, crTaxNoValidation: true });
        // this.crCustomerName.value = "";
        // this.crTaxNo.value = "";
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length > 28) {
            event.target.value = event.target.value.substring(0, 28);
        }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length === 28) {
            this.setState({ crIbanValidation: true });
        }
        this.setState({ crIban: event.target.value }, (event) => {
            if (this.state.selectedBank.bankCode == "210005" && this.state.crIban.length === 28) {
                this.showBudgetAccount();
            }
        });
    }
    checkAmount = (event) => {
        this.setState({ amountValidation: false });
        let mask = /[^0-9.]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.indexOf('.') !== -1) {
            let amount = event.target.value;
            let firstPointIndex = amount.indexOf('.');
            let wholePartAmount = amount.substr(0, firstPointIndex);
            let fractionalPartAmount = "";
            let afterPoint = 0;
            for (let i = firstPointIndex + 1; i < amount.length; i++) {
                if (amount[i] !== '.') {
                    fractionalPartAmount += amount[i];
                    afterPoint++;
                }
                if (afterPoint === 2) break;
            }
            if (firstPointIndex === 0) {
                wholePartAmount = 0;
            }
            event.target.value = wholePartAmount + '.' + fractionalPartAmount;
            this.setState({ amount: wholePartAmount + '.' + fractionalPartAmount });
            this.setState({ organizedAmount: { whole: parseInt(wholePartAmount), fractional: parseInt(fractionalPartAmount) } });
        }
        else {
            this.setState({ amount: event.target.value });
        }
        // =========BALANCE=========
        let amount = event.target.value;
        if (amount.toString() === "0") {
            this.setState({ organizedAmount: { whole: "0", fractional: "00" } });
        }
        else {
            if (amount.toString().indexOf('.') !== -1) {
                let currentBalanceArray = amount.toString().split('.');
                let fractional = currentBalanceArray[1].length === 1 ? currentBalanceArray[1] + '0' : currentBalanceArray[1];
                this.setState({ organizedAmount: { whole: currentBalanceArray[0], fractional: fractional } });
            }
            else {
                if (event.target.value !== "") {
                    this.setState({ organizedAmount: { whole: amount.toString(), fractional: "00" } });
                }
            }
        }
        if (isNaN(parseFloat(event.target.value)) === true || parseFloat(amount) <= 0) {
            this.setState({ amountValidation: false });
        }
        else {
            this.setState({ amountValidation: true });
            this.setState({ amountOutline: false });
        }
    }
    checkPurpose = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value.substring(0, (this.state.showPaymentTypes && this.state.selectedPaymentType) ? (140 - this.state.selectedPaymentType?.nameLength) : 140);
        let mask = /[^a-zA-Z0-9. ]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '');
        }
        this.setState({ purpose: value });
        if (value === "") {
            this.setState({ purposeValidation: false });
        }
        else {
            this.setState({ purposeValidation: true });
            this.setState({ purposeOutline: false });
        }
    }
    checkNote = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value.substring(0, 140);
        let mask = /[^a-zA-Z0-9. ]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '');
        }
        this.setState({ note: value });
        if (value === "") {
            this.setState({ noteValidation: false });
        }
        else {
            this.setState({ noteValidation: true })
        }
    }
    showBudgetAccount = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBudgetAccountByIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            iban: this.state.crIban
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            iban: this.state.crIban
        }
        fetch(request("operation/getBudgetAccountByIban", getBudgetAccountByIbanRequestBody))
            .then(res => res.text())
            .then((getBudgetAccountByIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBudgetAccountByIbanResponse = JSON.parse(getBudgetAccountByIbanResponseJSON);
                if (getBudgetAccountByIbanResponse.status.statusCode !== 1) {
                    if (getBudgetAccountByIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            crCustomerName: "",
                            crTaxNo: "",
                            crCustomerNameValidation: false,
                            crCustomerNameOutline: true,
                            crTaxNoValidation: false
                        });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetAccountByIbanResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ crCustomerName: getBudgetAccountByIbanResponse.budgetAccount.name, crTaxNo: getBudgetAccountByIbanResponse.budgetAccount.taxNumber, crCustomerNameValidation: true, crTaxNoValidation: true });
                    // this.crCustomerName.value = getBudgetAccountByIbanResponse.budgetAccount.name;
                    // this.crTaxNo.value = getBudgetAccountByIbanResponse.budgetAccount.taxNumber;
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showBudgetAccountAll = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCustInfoByCrIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: this.state.crIban,
            operNameId: 2
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: this.state.crIban,
            operNameId: 2
        }
        fetch(request("operation/getCustInfoByCrIban", getCustInfoByCrIbanRequestBody))
            .then(res => res.text())
            .then((getCustInfoByCrIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustInfoByCrIbanResponse = JSON.parse(getCustInfoByCrIbanResponseJSON);
                if (getCustInfoByCrIbanResponse.status.statusCode !== 1) {
                    if (getCustInfoByCrIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCustInfoByCrIbanResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let mask = /[^a-zA-Z0-9 ]/g;
                    if (getCustInfoByCrIbanResponse.custName) {
                        if (getCustInfoByCrIbanResponse.custName !== getCustInfoByCrIbanResponse.custName.replace(mask, '')) {
                            getCustInfoByCrIbanResponse.custName = getCustInfoByCrIbanResponse.custName.replace(mask, '');
                        }
                        this.setState({
                            crCustomerName: getCustInfoByCrIbanResponse.custName,
                            crCustomerNameValidation: true,
                            crCustomerNameOutline: false,
                            crCustPin: getCustInfoByCrIbanResponse.crCustPin
                        });
                    }
                    this.setState({
                        crTaxNo: getCustInfoByCrIbanResponse.taxNumber,
                        crTaxNoValidation: true
                    });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    // showTransferInland = () => {
    //     this.setState({ confirmedOperation: true });
    // }
    showTransferInland = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getTransferComissionRequestBody = {
            operationNameId: 2,
            amount: this.state.amount,
            accountId: this.state.dtAccountId,
            crBankCode: this.state.selectedBank.bankCode,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        if (this.state.switch === true) {
            getTransferComissionRequestBody.operationTypeId = 3;
        }
        else {
            getTransferComissionRequestBody.operationTypeId = 2;
        }
        fetch(request("operation/getCommission", getTransferComissionRequestBody))
            .then(res => res.text())
            .then((getTransferComissionResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferComissionResponse = JSON.parse(getTransferComissionResponseJSON);
                if (getTransferComissionResponse.status.statusCode !== 1) {
                    if (getTransferComissionResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getTransferComissionResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ feeAmount: getTransferComissionResponse.commission.amount, crrAmount: this.state.amount });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    cancelTransferInland = () => {
        this.props.history.push('/dashboard/transfer/transfers');
    }
    updateInlandOperation = () => {
        this.setState({ feedbackErrorModal: null });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let updateOperationByIdRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            dtAccountId: this.state.dtAccountId,
            crIban: this.state.crIban,
            amount: this.state.amount,
            purpose: (this.state.showPaymentTypes && this.state.selectedPaymentType && this.state.selectedPaymentType.info) ? (this.state.purpose && (this.state.selectedPaymentType.info + " " + this.state.purpose.trim())) : (this.state.purpose && this.state.purpose.trim()),
            operId: this.props.match.params.id,
            dtIban: this.state.dtAccountIban,
            crCustName: this.state.crCustomerName && this.state.crCustomerName.trim(),
            // ====
            operNameId: this.state.operNameId,
            operStateId: this.state.operStateId,
            custOperNo: this.state.custOperNo,
            crCustTaxid: null,
            crBankCode: this.state.selectedBank.bankCode,
            crBankName: this.state.selectedBank.bankName,
            crBankTaxid: this.state.selectedBank.bankTaxid,
            crBankCorrAcc: this.state.selectedBank.bankCorrAcc,
            crBankSwift: this.state.selectedBank.bankSwift,
            dtCustName: this.state.dtCustName,
            dtCustTaxid: this.state.dtCustTaxid,
            budgetCode: null,
            budgetLvl: null,
            note: this.state.note && this.state.note.trim(),
            operationType: null,
            crTaxNo: null,
            crBankBranch: null,
            crBankAddress: null,
            crBankCountry: null,
            crBankCity: null,
            crCustAddress: null,
            crCustPhone: null,
            crCorrBankName: null,
            crCorrBankSwift: null,
            crCorrBankCountry: null,
            crCorrBankCity: null,
            crCorrBankAccount: null,
            crCorrBankBranch: null,
            dtBranchId: null,
            crBranchId: null,
            dtAmount: null,
            crAmount: null,
            exchangeRate: null,
            exchangeOperationType: null,
            crCustTaxid: this.state.crTaxNo,
            custOperNo: this.state.custOperNo,
            crCustPin: this.state.crCustPin,
            inlandOperationType: this.state.selectedPaymentType?.id
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            dtAccountId: this.state.dtAccountId,
            crIban: this.state.crIban,
            amount: this.state.amount,
            purpose: this.state.purpose && this.state.purpose.trim(),
            operId: this.props.match.params.id,
            dtIban: this.state.dtAccountIban,
            crCustName: this.state.crCustomerName && this.state.crCustomerName.trim(),
            // ====
            operNameId: this.state.operNameId,
            operStateId: this.state.operStateId,
            custOperNo: this.state.custOperNo,
            crCustTaxid: null,
            crBankCode: this.state.selectedBank.bankCode,
            crBankName: this.state.selectedBank.bankName,
            crBankTaxid: this.state.selectedBank.bankTaxid,
            crBankCorrAcc: this.state.selectedBank.bankCorrAcc,
            crBankSwift: this.state.selectedBank.bankSwift,
            dtCustName: this.state.dtCustName,
            dtCustTaxid: this.state.dtCustTaxid,
            budgetCode: null,
            budgetLvl: null,
            note: this.state.note && this.state.note.trim(),
            operationType: null,
            crTaxNo: null,
            crBankBranch: null,
            crBankAddress: null,
            crBankCountry: null,
            crBankCity: null,
            crCustAddress: null,
            crCustPhone: null,
            crCorrBankName: null,
            crCorrBankSwift: null,
            crCorrBankCountry: null,
            crCorrBankCity: null,
            crCorrBankAccount: null,
            crCorrBankBranch: null,
            dtBranchId: null,
            crBranchId: null,
            dtAmount: null,
            crAmount: null,
            exchangeRate: null,
            exchangeOperationType: null,
            crCustTaxid: this.state.crTaxNo,
            custOperNo: this.state.custOperNo,
            crCustPin: this.state.crCustPin
        }
        if (localStorage.getItem("compId") && localStorage.getItem("isOperNo") === "1") {
            updateOperationByIdRequestBody.custOperNo = this.state.custOperNo;
        }
        if (this.state.selectedBank.bankCode == "210005") {
            updateOperationByIdRequestBody.budgetCode = this.state.selectedBudgetClassification.id;
            updateOperationByIdRequestBody.budgetLvl = this.state.selectedBudgetLevel.id;
        }

        if (this.state.taxNumber !== "") {
            updateOperationByIdRequestBody.crTaxNo = this.state.taxNumber;
        }
        if (this.state.switch === true) {
            updateOperationByIdRequestBody.operationType = 3;
        }
        else {
            updateOperationByIdRequestBody.operationType = 2;
        }
        let data = new FormData();
        data.append('reqObject', JSON.stringify(updateOperationByIdRequestBody));
        this.state.files.map(file => {
            data.append('files', file.files, file.name);
        });
        fetch(request("operation/updateOperationByIdWithForm", data, "POST_FORM"))
            .then(res => res.text())
            .then((updateOperationByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let updateOperationByIdResponse = JSON.parse(updateOperationByIdResponseJSON);
                if (updateOperationByIdResponse.status.statusCode !== 1) {
                    if (updateOperationByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(updateOperationByIdResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ createFeedbackMessage: true, open: true, operationId: updateOperationByIdResponse.operationId });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    updateCrIbanFilter = async (event) => {
        event.persist();
        let value = event.target.value;
        this.setState({ crIbanValidation: false });
        this.setState({ crCustomerName: "", crTaxNo: "", crCustomerNameValidation: false, crTaxNoValidation: true });
        let mask = /[^a-zA-Z0-9]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '');
        }
        if (value.length > 28) {
            value = value.substring(0, 28);
        }
        value = value.toUpperCase();
        this.setState({ crIban: value });

        if (value.length > 3) {
            await this.showCrIban(value);
            this.setState({ dropdownVisibilityCrIban: true });
        }
        else {
            this.setState({ dropdownVisibilityCrIban: false, budgetAccounts: [] });
        }
        if (value.length === 28) {
            this.showBudgetAccount();
            this.setState({ crIbanValidation: true, dropdownVisibilityCrIban: false });
        }
    }
    updateCrIbanFilterAll = async (event) => {
        event.persist();
        let value = event.target.value;
        this.setState({ crIbanValidation: false });
        // this.setState({ crCustomerName: "", crTaxNo: "", crCustomerNameValidation: false, crTaxNoValidation: true });
        let mask = /[^a-zA-Z0-9]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '');
        }
        if (value.length > 28) {
            value = value.substring(0, 28);
        }
        value = value.toUpperCase();
        this.setState({ crIban: value });

        if (value.length > 1) {
            await this.showCrIbanAll(value);
            this.setState({ dropdownVisibilityCrIban: true });
        }
        else {
            this.setState({ dropdownVisibilityCrIban: false, budgetAccounts: [] });
        }
        if (value.length === 28) {
            this.showBudgetAccountAll();
            this.setState({ crIbanValidation: true, dropdownVisibilityCrIban: false });
        }
    }
    showCrIban = async (iban) => {
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBudgetAccountListByIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            iban: iban
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            iban: iban
        }

        fetch(request("operation/getBudgetAccountListByIban", getBudgetAccountListByIbanRequestBody))
            .then(res => res.text())
            .then((getBudgetAccountListByIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBudgetAccountListByIbanResponse = JSON.parse(getBudgetAccountListByIbanResponseJSON);
                if (getBudgetAccountListByIbanResponse.status.statusCode !== 1) {
                    if (getBudgetAccountListByIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetAccountListByIbanResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ budgetAccounts: getBudgetAccountListByIbanResponse.budgetAccountList });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showCrIbanAll = async (iban) => {
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBudgetAccountListByIbanRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: iban,
            operNameId: 2
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            crIban: iban,
            operNameId: 2
        }

        fetch(request("operation/getCrIbanListByCrIban", getBudgetAccountListByIbanRequestBody))
            .then(res => res.text())
            .then((getBudgetAccountListByIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBudgetAccountListByIbanResponse = JSON.parse(getBudgetAccountListByIbanResponseJSON);
                if (getBudgetAccountListByIbanResponse.status.statusCode !== 1) {
                    if (getBudgetAccountListByIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ budgetAccounts: [] });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetAccountListByIbanResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ budgetAccounts: getBudgetAccountListByIbanResponse.operationList });
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        if (localStorage.getItem('compId')) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
            let getOperationTypesRequestBody = {
                token: localStorage.getItem('token')
            }
            fetch(request("operation/getInternalOperationtypes", getOperationTypesRequestBody))
                .then(res => res.text())
                .then((getOperationTypesResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getOperationTypesResponse = JSON.parse(getOperationTypesResponseJSON);
                    if (getOperationTypesResponse.status.statusCode !== 1) {
                        if (getOperationTypesResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getOperationTypesResponse.status.statusCode));
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                    else {
                        this.setState({ paymentTypes: getOperationTypesResponse.inlandOperationTypes });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBankListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            ccy: "AZN"
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            ccy: "AZN"
        }
        fetch(request("operation/getBankList", getBankListRequestBody))
            .then(res => res.text())
            .then((getBankListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBankListResponse = JSON.parse(getBankListResponseJSON);
                if (getBankListResponse.status.statusCode !== 1) {
                    if (getBankListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBankListResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    getBankListResponse.bankInfoList.map((bank, index) => {
                        getBankListResponse.bankInfoList[index].title = bank.bankCode + " - " + bank.bankName;
                    });
                    this.setState({ banks: getBankListResponse.bankInfoList });
                    let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
                    let getNoCardAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
                        custId: localStorage.getItem('custId'),
                        compId: localStorage.getItem('compId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        isBalanceAccount: 0
                    } : {
                        custId: localStorage.getItem('custId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        isBalanceAccount: 0
                    }

                    fetch(request("account/getNoCardAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
                        .then(res => res.text())
                        .then((getNoCardAccountListForCustomerResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                            if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                                if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                                    this.setState({ isLoading: false });
                                }
                            }
                            else {
                                if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                                    getNoCardAccountListForCustomerResponse.accountList = [];
                                }
                                for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                                    // // =========ID=========
                                    let accountId = "";
                                    if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                                        getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                            accountId += part;
                                        });
                                    }
                                    else {
                                        accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                                    }
                                    getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                                    // =========BALANCE=========
                                    if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                                        getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                            wholePart: "0",
                                            fractionalPart: "00"
                                        }
                                    }
                                    else {
                                        if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                            let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                                wholePart: currentBalanceArray[0],
                                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                            }
                                        }
                                        else {
                                            let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                                wholePart: getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                                fractionalPart: "00"
                                            }
                                        }
                                    }
                                }
                                this.setState({ accounts: getNoCardAccountListForCustomerResponse.accountList, accountData: true },
                                    () => {
                                        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
                                        let getOperationAllInfoByIdRequestBody = customerType == 'Juridical' ? {
                                            custId: localStorage.getItem('custId'),
                                            compId: localStorage.getItem('compId'),
                                            operId: this.props.match.params.id,
                                            token: localStorage.getItem('token'),
                                            lang: localStorage.getItem('i18nextLng').toUpperCase()
                                        } : {
                                            custId: localStorage.getItem('custId'),
                                            operId: this.props.match.params.id,
                                            token: localStorage.getItem('token'),
                                            lang: localStorage.getItem('i18nextLng').toUpperCase()
                                        }

                                        fetch(request("operation/getOperationAllInfoById", getOperationAllInfoByIdRequestBody))
                                            .then(res => res.text())
                                            .then((getOperationAllInfoByIdResponseJSON) => {
                                                const JSON = require('true-json-bigint');
                                                let getOperationAllInfoByIdResponse = JSON.parse(getOperationAllInfoByIdResponseJSON);
                                                if (getOperationAllInfoByIdResponse.status.statusCode !== 1 && getOperationAllInfoByIdResponse.status.statusCode !== 126) {
                                                    if (getOperationAllInfoByIdResponse.status.statusCode === 200) {
                                                        this.props.logout();
                                                    } else {
                                                        this.setState({ isLoading: false });
                                                    }
                                                }
                                                else {
                                                    let mask = /[^a-zA-Z0-9. ]/g;
                                                    if (getOperationAllInfoByIdResponse.crCustName !== getOperationAllInfoByIdResponse.crCustName.replace(mask, '')) {
                                                        getOperationAllInfoByIdResponse.crCustName = getOperationAllInfoByIdResponse.crCustName.replace(mask, '');
                                                    }
                                                    if (getOperationAllInfoByIdResponse.note) {
                                                        if ((getOperationAllInfoByIdResponse.note !== getOperationAllInfoByIdResponse.note.replace(mask, ''))) {
                                                            getOperationAllInfoByIdResponse.note = getOperationAllInfoByIdResponse.note.replace(mask, '');
                                                        }
                                                    }
                                                    if (getOperationAllInfoByIdResponse.operPurpose !== getOperationAllInfoByIdResponse.operPurpose.replace(mask, '')) {
                                                        getOperationAllInfoByIdResponse.operPurpose = getOperationAllInfoByIdResponse.operPurpose.replace(mask, '');
                                                    }
                                                    getOperationAllInfoByIdResponse.crCustName = getOperationAllInfoByIdResponse.crCustName.substring(0, 35);
                                                    if (getOperationAllInfoByIdResponse.note) {
                                                        getOperationAllInfoByIdResponse.note = getOperationAllInfoByIdResponse.note.replace(mask, '').substring(0, 140);
                                                    }
                                                    getOperationAllInfoByIdResponse.operPurpose = getOperationAllInfoByIdResponse.operPurpose.replace(mask, '').substring(0, 140);

                                                    let files = [];
                                                    getOperationAllInfoByIdResponse.updFile.map(file => {
                                                        files.push({ files: this.base64ToArrayBuffer(file.files, file.fileName), fileName: file.fileName });
                                                    });
                                                    this.setState({
                                                        currency: getOperationAllInfoByIdResponse.amtCcy,
                                                        crCustomerName: getOperationAllInfoByIdResponse.crCustName && getOperationAllInfoByIdResponse.crCustName.trim(),
                                                        crCustomerNameValidation: true,
                                                        crIban: getOperationAllInfoByIdResponse.crIban,
                                                        crIbanValidation: true,
                                                        crTaxNoValidation: true,
                                                        amount: getOperationAllInfoByIdResponse.amt,
                                                        crrAmount: getOperationAllInfoByIdResponse.amt,
                                                        amountValidation: true,
                                                        purpose: getOperationAllInfoByIdResponse.operPurpose && getOperationAllInfoByIdResponse.operPurpose.trim(),
                                                        purposeValidation: true,
                                                        note: getOperationAllInfoByIdResponse.note && getOperationAllInfoByIdResponse.note.trim(),
                                                        noteValidation: true,
                                                        tempHave: true,
                                                        custOperNo: getOperationAllInfoByIdResponse.custOperNo,
                                                        operStateId: getOperationAllInfoByIdResponse.operStateId,
                                                        operNameId: getOperationAllInfoByIdResponse.operNameId,
                                                        dtCustName: getOperationAllInfoByIdResponse.dtCustName,
                                                        dtCustTaxid: getOperationAllInfoByIdResponse.dtCustTaxid,
                                                        files: files
                                                    });
                                                    this.setState({ crCustomerName: getOperationAllInfoByIdResponse.crCustName });
                                                    // this.crCustomerName.value = getOperationAllInfoByIdResponse.crCustName;
                                                    if (getOperationAllInfoByIdResponse.crCustTaxid) {
                                                        this.setState({ crTaxNo: getOperationAllInfoByIdResponse.crCustTaxid });
                                                    }
                                                    if (getOperationAllInfoByIdResponse.crCustPin) {
                                                        this.setState({ crCustPin: getOperationAllInfoByIdResponse.crCustPin });
                                                    }
                                                    if (getOperationAllInfoByIdResponse.operTypeId === 3) {
                                                        this.setState({ switch: true });
                                                    }

                                                    // =====================
                                                    this.setState({
                                                        selectedBank: {
                                                            bankCode: getOperationAllInfoByIdResponse.crBankCode,
                                                            bankCorrAcc: getBankListResponse.crBankCorrAcc,
                                                            bankName: getOperationAllInfoByIdResponse.crBankName,
                                                            bankSwift: getOperationAllInfoByIdResponse.crBankSwift,
                                                            bankTaxid: getOperationAllInfoByIdResponse.crBankTaxid,
                                                            title: getOperationAllInfoByIdResponse.crBankCode + ' - ' + getOperationAllInfoByIdResponse.crBankName
                                                        },
                                                        bankFilter: getOperationAllInfoByIdResponse.crBankCode + ' - ' + getOperationAllInfoByIdResponse.crBankName
                                                    }, () => this.updateDtAccountIdFromTemplate(getOperationAllInfoByIdResponse.dtIban));
                                                    if (getOperationAllInfoByIdResponse.crBankCode == "210005") {
                                                        this.setState({ showBudget: true });
                                                        if (this.state.crIbanValidation === true) {
                                                            this.showBudgetAccount();
                                                        }
                                                    }
                                                    if (getOperationAllInfoByIdResponse.crBankCode !== "210005" && getOperationAllInfoByIdResponse.crBankCode !== "210027") {
                                                        this.setState({ showUrgent: true });
                                                    }
                                                    if (getOperationAllInfoByIdResponse.inlandOperationType) {
                                                        this.updateSelectedPaymentType(null, getOperationAllInfoByIdResponse.inlandOperationType);
                                                    }
                                                    // =====================
                                                    this.setState({ isLoading: false });
                                                    let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
                                                    let getBudgetLevelCodeRequestBody = customerType == 'Juridical' ? {
                                                        custId: localStorage.getItem('custId'),
                                                        compId: localStorage.getItem('compId'),
                                                        token: localStorage.getItem('token'),
                                                        lang: localStorage.getItem('i18nextLng').toUpperCase()
                                                    } : {
                                                        custId: localStorage.getItem('custId'),
                                                        token: localStorage.getItem('token'),
                                                        lang: localStorage.getItem('i18nextLng').toUpperCase()
                                                    }

                                                    fetch(request("operation/getBudgetLevelCode", getBudgetLevelCodeRequestBody))
                                                        .then(res => res.text())
                                                        .then((getBudgetLevelCodeResponseJSON) => {
                                                            const JSON = require('true-json-bigint');
                                                            let getBudgetLevelCodeResponse = JSON.parse(getBudgetLevelCodeResponseJSON);
                                                            if (getBudgetLevelCodeResponse.status.statusCode !== 1) {
                                                                if (getBudgetLevelCodeResponse.status.statusCode === 200) {
                                                                    this.props.logout();
                                                                } else {
                                                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetLevelCodeResponse.status.statusCode));
                                                                    this.setState({ isLoading: false });
                                                                }
                                                            }
                                                            else {
                                                                this.setState({ budgetLevels: getBudgetLevelCodeResponse.budgetCodeList });
                                                                let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
                                                                let getBudgetClassificationCodeRequestBody = customerType == 'Juridical' ? {
                                                                    custId: localStorage.getItem('custId'),
                                                                    compId: localStorage.getItem('compId'),
                                                                    token: localStorage.getItem('token'),
                                                                    lang: localStorage.getItem('i18nextLng').toUpperCase()
                                                                } : {
                                                                    custId: localStorage.getItem('custId'),
                                                                    token: localStorage.getItem('token'),
                                                                    lang: localStorage.getItem('i18nextLng').toUpperCase()
                                                                }

                                                                fetch(request("operation/getBudgetClassificationCode", getBudgetClassificationCodeRequestBody))
                                                                    .then(res => res.text())
                                                                    .then((getBudgetClassificationCodeResponseJSON) => {
                                                                        const JSON = require('true-json-bigint');
                                                                        let getBudgetClassificationCodeResponse = JSON.parse(getBudgetClassificationCodeResponseJSON);
                                                                        if (getBudgetClassificationCodeResponse.status.statusCode !== 1) {
                                                                            if (getBudgetClassificationCodeResponse.status.statusCode === 200) {
                                                                                this.props.logout();
                                                                            } else {
                                                                                this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetClassificationCodeResponse.status.statusCode));
                                                                                this.setState({ isLoading: false });
                                                                            }
                                                                        }
                                                                        else {
                                                                            this.setState({ budgetCodes: getBudgetClassificationCodeResponse.budgetCodeList },
                                                                                () => {
                                                                                    if (getOperationAllInfoByIdResponse.budgetCode) {
                                                                                        this.updateSelectedClassificationCodeFromTemplate(getOperationAllInfoByIdResponse.budgetCode);
                                                                                    }
                                                                                    if (getOperationAllInfoByIdResponse.budgetLvl) {
                                                                                        this.updateSelectedLevelCodeFromTemplate(getOperationAllInfoByIdResponse.budgetLvl);
                                                                                    }
                                                                                    this.setState({ isLoading: false });
                                                                                }
                                                                            );
                                                                        }
                                                                    },
                                                                        (error) => {
                                                                            this.props.history.push("/technical-break");
                                                                        });
                                                            }
                                                        },
                                                            (error) => {
                                                                this.props.history.push("/technical-break");
                                                            });
                                                }
                                            });
                                    },
                                    (error) => {
                                        this.props.history.push("/technical-break");
                                    });
                            }
                        },
                            (error) => {
                                this.props.history.push("/technical-break");
                            });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }
    // checkCrCustomerName = (event) => {
    //     let value = event.target.value.trimStart();
    //     let mask = /[^a-zA-Z0-9. ]/g;
    //     if (value !== value.replace(mask, '')) {
    //         value = value.replace(mask, '');
    //     }
    //     value = value.substring(0, 35);
    //     this.setState({ crCustomerNameValidation: false });
    //     if (value.length > 0) {
    //         this.setState({ crCustomerNameValidation: true });
    //     }
    //     this.setState({ crCustomerName: value });
    // }
    checkCrCustomerName = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value;
        let mask = /[^a-zA-Z0-9. ]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '').substring(0, 35);
        }
        this.setState({ crCustomerNameValidation: false });
        if (value.length > 0) {
            this.setState({ crCustomerNameValidation: true });
            this.setState({ crCustomerNameOutline: false });
        }
        this.setState({ crCustomerName: value });
    }
    checkCrTaxNo = (event) => {
        let mask = /[^0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length < 10) {
            this.setState({ crTaxNoValidation: false });
        }
        else {
            if (event.target.value.length > 10) {
                event.target.value = event.target.value.substring(0, 10);
            }
            this.setState({ crTaxNoValidation: true });
        }
        if (event.target.value.length === 0) {
            this.setState({ crTaxNoValidation: true });
        }
        this.setState({ crTaxNo: event.target.value });
    }
    setAmountOutline = () => {
        if (!this.state.amountValidation) {
            this.setState({ amountOutline: true });
        }
        else {
            this.setState({ amountOutline: false });
        }
    }
    setPurposeOutline = () => {
        if (!this.state.purposeValidation) {
            this.setState({ purposeOutline: true });
        }
        else {
            this.setState({ purposeOutline: false });
        }
    }
    setCrCustomerName = () => {
        if (!this.state.crCustomerNameValidation) {
            this.setState({ crCustomerNameOutline: true });
        }
        else {
            this.setState({ crCustomerNameOutline: false });
        }
    }
    checkCrCustPin = (event) => {
        event.persist();
        let value = event.target.value;
        let mask = /[^a-zA-Z0-9]/g;
        if (value !== value.replace(mask, '')) {
            value = value.replace(mask, '');
        }
        if (value.length > 7) {
            value = value.substring(0, 7);
        }
        value = value.toUpperCase();
        this.setState({ crCustPin: value });
    }
    componentDidUpdate(prevProps, prevState) {
        if (localStorage.getItem('compId') && (this.state.selectedBank.bankCode !== prevState.selectedBank.bankCode)) {
            if (this.state.selectedBank.bankCode && this.state.selectedBank.bankCode !== '210027' && this.state.selectedBank.bankCode !== '210005')
                this.setState({ showPaymentTypes: true });
            else
                this.setState({ showPaymentTypes: false });
            this.setState({ selectedPaymentType: null, paymentTypeOpenCount: 0, paymentTypeValidation: false });
        }
    }
    render() {
        let { t, history } = this.props
        let { custOperNo, open, createFeedbackMessage, feedbackErrorModal, tempHave, currency,
            dropdownVisibilityBank, dropdownVisibilityAccount, dropdownVisibilityClassification, confirmedOperation, selectedAccount, accounts, banks, budgetCodes, budgetLevels, bankFilter, selectedBank, crTaxNo, crCustomerName,
            crCustomerNameValidation, crIbanValidation, crTaxNoValidation, amountValidation, dtAccountId, purposeValidation, noteValidation, crIban, note,
            organizedAmount, dtAccountIban, showBudget, showUrgent, disableCurrency, budgetClassificationFilter, selectedBudgetClassification, purpose,
            dropdownVisibilityLevel, budgetLevelFilter, selectedBudgetLevel, amount, isLoading, accountData, inlandOpNumber,
            budgetAccounts, dropdownVisibilityCrIban,
            inlandOpNumberOutline, amountOutline, purposeOutline, crCustomerNameOutline,
            showPaymentTypes, paymentTypes, dropdownVisibilityPaymentType, selectedPaymentType, paymentTypeValidation, paymentTypeOpenCount, feeAmount, crrAmount } = this.state;
        return (
            <Fragment>
                <UpdateModal open={open} createTemplate={() => this.setState({ confirmedOperation: false, openTemp: true })} successClose={() => { history.push('/dashboard/transfer/history/transfers') }} errorClose={() => this.setState({ confirmedOperation: false })} updateOpenModal={this.updateOpenModal} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <Fragment>
                    <div className="dashboard-header-route">
                        <div className="dashboard-header-route-left">
                            <button onClick={() => this.props.history.goBack()}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                </svg>
                            </button>
                            <h2>{t('Inland')}</h2>
                        </div>
                    </div>
                    {
                        isLoading ?
                            <div className="dashboard-container-main-section">
                                <Loading />
                            </div>
                            :
                            !tempHave ?
                                <NotFound />
                                :
                                <div className="dashboard-payment-container">
                                    <div className="dashboard-payment-container-data-section">
                                        <div className="dashboard-payment-container-data-section-header">
                                            <h3 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h3>
                                        </div>
                                        <form className="dashboard-payment-container-data-section-content" style={{ paddingBottom: '20px' }}>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Receiving funds')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        {
                                                            localStorage.getItem("isOperNo") === "1" &&
                                                            <div style={{ width: '100%' }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Transfer number')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="text" disabled={true} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={custOperNo} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select the recipients bank')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityBank}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (Object.entries(this.state.selectedBank).length === 0 && this.state.selectedBank.constructor === Object ? "input-outline" : "")}>
                                                                    <button type="button" className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={bankFilter} onChange={this.updateBankFilter} onFocus={this.openDropdownVisibilityBank} />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilityBank}>
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    {
                                                                        banks.filter(bank => bank.title && bank.title.toLowerCase().includes(bankFilter.toLowerCase())).length !== 0 &&
                                                                        <ul className={(dropdownVisibilityBank === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                            {
                                                                                banks.filter(bank => bank.title && bank.title.toLowerCase().includes(bankFilter.toLowerCase())).map((bank, index) => (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateSelectedBank.bind(this, bank)}>{bank.title}</button>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('tin')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" defaultValue={selectedBank.bankTaxid} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={true} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Correspondent account')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" defaultValue={selectedBank.bankCorrAcc} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={true} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('SWIFT B.I.C')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" defaultValue={selectedBank.bankSwift} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={true} />
                                                            </div>
                                                        </div>
                                                        {
                                                            showBudget === true ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients account (IBAN)')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityCrIban}>
                                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (!crIbanValidation ? "input-outline" : "")}>
                                                                            <button type="button" className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={crIban} onChange={this.updateCrIbanFilter} onFocus={this.openDropdownVisibilityCrIban} />
                                                                                {/* <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilityCrIban}>
                                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                </svg>
                                                                            </div> */}
                                                                            </button>
                                                                            {
                                                                                budgetAccounts && budgetAccounts.length !== 0 &&
                                                                                <ul className={(dropdownVisibilityCrIban === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                    {
                                                                                        budgetAccounts.map((budgetAccount, index) => (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                                                                                    onClick={this.updateSelectedCrIban.bind(this, budgetAccount)}
                                                                                                >{budgetAccount.iban}</button>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>
                                                                :
                                                                // ====================find iban====================
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients account (IBAN)')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityCrIban}>
                                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (!crIbanValidation ? "input-outline" : "")}>
                                                                            <button type="button" className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={crIban} onChange={this.updateCrIbanFilterAll} onFocus={this.openDropdownVisibilityCrIban} />
                                                                                {/* <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilityCrIban}>
                                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                </svg>
                                                                            </div> */}
                                                                            </button>
                                                                            {
                                                                                budgetAccounts && budgetAccounts.length !== 0 &&
                                                                                <ul className={(dropdownVisibilityCrIban === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                    {
                                                                                        budgetAccounts.map((budgetAccount, index) => (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                                                                                    onClick={this.updateSelectedCrIbanAll.bind(this, budgetAccount)}
                                                                                                >{budgetAccount.crIban}</button>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>
                                                            // <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            //     <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients account (IBAN)')} <span style={{ color: 'red' }}>*</span></label>
                                                            //     <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                            //         <input value={crIban} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={this.checkCrIban} disabled={confirmedOperation} />
                                                            //     </div>
                                                            // </div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={this.state.crCustomerName} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole " + (crCustomerNameOutline ? "input-outline" : "")} onChange={this.checkCrCustomerName} disabled={confirmedOperation || showBudget} onBlur={this.setCrCustomerName} />
                                                            </div>
                                                        </div>
                                                        {
                                                            !showBudget &&
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients PIN')}</label>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                    <input type="text" value={this.state.crCustPin} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={this.checkCrCustPin} disabled={confirmedOperation} />
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients TIN')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crTaxNo} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={this.checkCrTaxNo} disabled={confirmedOperation || showBudget} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('sum')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                                <input type="text" defaultValue={amount} className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} placeholder="0.00" onChange={this.checkAmount} onBlur={this.setAmountOutline} />
                                                                <div className={confirmedOperation ?
                                                                    "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                    "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                }>{disableCurrency ? "AZN" : currency}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Funds will be deducted from the account')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityAccount}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (!dtAccountId ? "input-outline" : "")}>
                                                                    <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation} onClick={this.changeDropdownVisibilityAccount}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                                            {
                                                                                accounts && accounts.length === 0 && accountData ? t('126') : selectedAccount
                                                                            }
                                                                        </div>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    {
                                                                        disableCurrency === false ?
                                                                            accounts.length !== 0 &&
                                                                            <ul className={(dropdownVisibilityAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                {
                                                                                    accounts.map(account => (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                            <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountId.bind(this, account.accountId, account.iban, account.currName)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                            :
                                                                            accounts.filter(account => account.currName === "AZN").length !== 0 &&
                                                                            <ul className={(dropdownVisibilityAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                {
                                                                                    accounts.filter(account => account.currName === "AZN").map(account => (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                            <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountId.bind(this, account.accountId, account.iban, account.currName)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                    }
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Appointment')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (purposeOutline ? "input-outline" : "")}>
                                                                <input value={purpose} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkPurpose} onBlur={this.setPurposeOutline} />
                                                            </div>
                                                        </div> */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Appointment')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box d-flex align-items-center " + (purposeOutline ? "input-outline" : "")}>
                                                                {showPaymentTypes && selectedPaymentType && selectedPaymentType.info && <span className="dashboard-payment-container-data-section-content-container-form-input text-nowrap" style={{ width: 'inherit', lineHeight: '24px' }}>{selectedPaymentType.info}</span>}
                                                                <input value={purpose} type="text" className={`dashboard-payment-container-data-section-content-container-form-input ${showPaymentTypes && selectedPaymentType && selectedPaymentType.info && "pl-1 d-inline-block"}`} disabled={confirmedOperation} onChange={this.checkPurpose} onBlur={this.setPurposeOutline} />
                                                            </div>
                                                        </div>
                                                        {showPaymentTypes &&
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Payment type')} <span style={{ color: 'red' }}>*</span></label>
                                                                <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityPaymentType}>
                                                                    <div className={"dashboard-payment-container-data-section-content-container-form-select-box " +
                                                                        (paymentTypeOpenCount > 1 && !paymentTypeValidation || paymentTypeOpenCount === 1 && !paymentTypeValidation && !dropdownVisibilityPaymentType ? "input-outline" : "")
                                                                    }>
                                                                        <button type="button" onClick={this.changeDropdownVisibilityPaymentType} className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedPaymentType?.name}</div>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                        {
                                                                            paymentTypes &&
                                                                            <ul className={(dropdownVisibilityPaymentType === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                {
                                                                                    paymentTypes.map((type, index) => (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <OverlayTrigger overlay={(props) => (
                                                                                                <Tooltip {...props}>
                                                                                                    {type?.description}
                                                                                                </Tooltip>
                                                                                            )}
                                                                                                placement="right"
                                                                                            >
                                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                                                                                    onClick={() => this.updateSelectedPaymentType(type)}
                                                                                                >{type.name}</button>
                                                                                            </OverlayTrigger>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        }
                                                                    </div>
                                                                </OutsideClickHandler>
                                                            </div>}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Additional Information')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input value={note} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkNote} />
                                                            </div>
                                                        </div>
                                                        {
                                                            showBudget === true &&
                                                            <>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Budget Classification Code')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityClassification}>
                                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (Object.entries(this.state.selectedBudgetClassification).length === 0 && this.state.selectedBudgetClassification.constructor === Object ? "input-outline" : "")}>
                                                                            <button type="button" className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={budgetClassificationFilter} onChange={this.updateClassificationCodeFilter} onFocus={this.dropdownVisibilityClassification} />
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilityClassification}>
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            {
                                                                                budgetClassificationFilter ?
                                                                                    budgetCodes.filter(budgetCode => budgetCode.budgetValue.toLowerCase().includes(budgetClassificationFilter.toLowerCase())).length !== 0 &&
                                                                                    <ul className={(dropdownVisibilityClassification === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                        {
                                                                                            budgetCodes.filter(budgetCode => budgetCode.budgetValue.toLowerCase().includes(budgetClassificationFilter.toLowerCase())).map((budgetCode, index) => (
                                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateSelectedClassificationCode.bind(this, budgetCode)}>{budgetCode.budgetValue}</button>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                                    :
                                                                                    <ul className={(dropdownVisibilityClassification === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                        {
                                                                                            budgetCodes.map((budgetCode, index) => (
                                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateSelectedClassificationCode.bind(this, budgetCode)}>{budgetCode.budgetValue}</button>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Budget Level Code')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={this.closeDropdownVisibilityLevel}>
                                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (Object.entries(this.state.selectedBudgetLevel).length === 0 && this.state.selectedBudgetLevel.constructor === Object ? "input-outline" : "")}>
                                                                            <button type="button" className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation}>
                                                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={budgetLevelFilter} onChange={this.updateLevelCodeFilter} onFocus={this.dropdownVisibilityLevel} />
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon" onClick={this.changeDropdownVisibilityLevel}>
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            {
                                                                                budgetLevelFilter ?
                                                                                    budgetLevels.filter(budgetLevel => budgetLevel.budgetValue.toLowerCase().includes(budgetLevelFilter.toLowerCase())).length !== 0 &&
                                                                                    <ul className={(dropdownVisibilityLevel === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                        {
                                                                                            budgetLevels.filter(budgetLevel => budgetLevel.budgetValue.toLowerCase().includes(budgetLevelFilter.toLowerCase())).map((budgetLevel, index) => (
                                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateSelectedLevelCode.bind(this, budgetLevel)}>{budgetLevel.budgetValue}</button>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                                    :
                                                                                    <ul className={(dropdownVisibilityLevel === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                        {
                                                                                            budgetLevels.map((budgetLevel, index) => (
                                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateSelectedLevelCode.bind(this, budgetLevel)}>{budgetLevel.budgetValue}</button>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Contract and invoices')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" />
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__choose">{t('Choose')}</div>
                                                                <input id="file" type="file" className="dashboard-payment-container-data-section-content-container-form-input__file" accept="image/jpeg,image/png,application/pdf,zip,application/zip,application/x-zip,application/x-zip-compressed,.rar,application/x-rar-compressed" multiple={true} onChange={this.chooseFile} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <ul className="dashboard-file-list">
                                                                {
                                                                    this.state.files.map((item, index) => (
                                                                        <li className="dashboard-file-item" key={index}>
                                                                            <div className="dashboard-file-item-left">
                                                                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M2.09375 14.6562C3.90625 16.4688 6.84375 16.4688 8.65625 14.6562C10.3438 12.9688 13.0938 10.2188 14.7812 8.53125C14.9375 8.375 14.9375 8.15625 14.7812 8L14.25 7.46875C14.0938 7.3125 13.875 7.3125 13.7188 7.46875C12.0312 9.15625 9.28125 11.9062 7.59375 13.5938C6.375 14.8125 4.375 14.8125 3.15625 13.5938C1.9375 12.375 1.9375 10.375 3.15625 9.15625L10.1875 2.125C11 1.3125 12.3125 1.3125 13.125 2.125C13.9375 2.9375 13.9375 4.25 13.125 5.0625L7.21875 10.9688C6.8125 11.375 6.15625 11.375 5.75 10.9688C5.375 10.5938 5.375 9.9375 5.75 9.53125L10.7812 4.53125C10.9375 4.375 10.9375 4.15625 10.7812 4L10.25 3.46875C10.0938 3.3125 9.875 3.3125 9.71875 3.46875L4.71875 8.46875C3.71875 9.46875 3.71875 11.0625 4.71875 12.0312C5.6875 13.0312 7.28125 13.0312 8.25 12.0312L14.1875 6.125C15.5938 4.71875 15.5938 2.46875 14.1875 1.0625C12.7812 -0.34375 10.5312 -0.34375 9.125 1.0625L2.09375 8.09375C0.28125 9.90625 0.28125 12.8438 2.09375 14.6562Z" fill="#849097" />
                                                                                </svg>
                                                                                <button type="button" className="dashboard-file-item-title" onClick={this.downloadFile.bind(this, index)}>{item.fileName}</button>
                                                                            </div>
                                                                            <button type="button" className="dashboard-file-item-right" onClick={this.deleteFile.bind(this, index)}>
                                                                                <svg className="dashboard-transfer-list-table-icon" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z" fill="#586268" />
                                                                                </svg>
                                                                            </button>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                        {
                                                            showUrgent &&
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label" style={{ width: 'max-content' }}>
                                                                    <AntSwitch checked={this.state.switch} disabled={confirmedOperation} onChange={() => this.updateSwitch()} />
                                                                    <span style={{ marginLeft: '4px' }}>{t('Urgent - AZIPS')}</span>
                                                                </label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="dashboard-payment-container-confirmation-section">
                                        <div className="dashboard-payment-container-confirmation-section-header">
                                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Transfer update')}</h3>
                                        </div>
                                        <div className="dashboard-payment-container-confirmation-section-content">

                                            <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + currency.toLocaleLowerCase()}>{currencyFilter(currency)}</span>
                                                <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(amount).part1}</span>
                                                <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(amount).part2}</span>
                                            </h3>
                                            <ul className="dashboard-payment-container-confirmation-section-list">
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Abroad')} {t('Transfer')}</p>
                                                </li>
                                                {
                                                    crTaxNo !== "" &&
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('tin')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crTaxNo}</p>
                                                    </li>
                                                }
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('To account')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crIban}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('From account')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtAccountIban}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('feeAmount')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{feeAmount} {currency}</p>
                                                </li>
                                            </ul>
                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                {(showPaymentTypes ? paymentTypeValidation : true) ?
                                                    <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={() => crrAmount != amount ? this.showTransferInland() : this.updateInlandOperation()}>{crrAmount != amount ? t('Calculate commission') : t('Update')}</button>
                                                    :
                                                    <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left dashboard-payment-container-data-section-content-container-form-footer-button__disable">{t('Update')}</button>
                                                }
                                                <button onClick={this.cancelTransferInland} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                </Fragment>
            </Fragment>
        )
    }
}
export default translate("translation")(updateInlandOperation);
