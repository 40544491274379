import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import headerLogo from "./../../Registration/img/header-logo.svg";
import forgotPasswordIcon from './../../Registration/img/forgot-password.svg';
import commentAltLines from './../../Registration/img/comment-alt-lines.svg';
import { translate } from 'react-i18next';
import { request } from '../../../config';

class VerifyOtpCode extends Component {
    state = {
        customerType: 'Individual',
        isDisabled: true,
        verifyToken: this.props.location.state.verifyToken,
        timer: "03:00",
        minute: 3,
        isDisabledResend: true,
        second: 0,
        timerInterval: null,
        feedbackMessage: null
    }


    backCheckCustomer = () => {
        if(this.state.customerType === 'Juridical') {
            this.props.history.push('/login/juridical/mobile/forgot-password/1');
        }
        else {
            this.props.history.push('/login/individual/mobile/forgot-password/1');
        }
    }
    
    startTimer = () => {
        let { minute, second } = this.state;
        this.setState({
            timerInterval: setInterval(() => {
                let secondPart, minutePart;
                if (second === 0 && minute === 0) {
                    $('.registration-container-sms-confirmation-numbers').prop('disabled', true);
                    $('.registration-container-sms-confirmation-numbers').prop('value', null);
                    clearInterval(this.state.timerInterval);
                    this.setState({
                        timer: null,
                        isDisabledResend: false,
                        isDisabled: true
                    });
                }
                else {
                    if (second !== 0) {
                        second--;
                    }
                    else {
                        if (minute !== 0) {
                            minute--;
                            second = 59;
                        }
                    }
                    if (second < 10) {
                        secondPart = "0" + second;
                    }
                    else {
                        secondPart = second;
                    }
                    if (minute < 10) {
                        minutePart = "0" + minute;
                    }
                    else {
                        minutePart = minute;
                    }
                    this.setState({
                        timer: minutePart + ":" + secondPart
                    })
                }
            }, 1000)
        })
    }

    sendOtpCode = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        clearInterval(this.state.timerInterval);
        this.setState({ feedbackMessage: null, isDisabled: true });
        let sendOtpCodeRequestBody = this.state.customerType === 'Juridical' ? {
            // custId: this.props.location.state.custId,
            compId: this.props.location.state.compId,
            mobile: this.props.location.state.mobile,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            pin: this.props.location.state.pin,

            
        }:{
            pin: this.props.location.state.pin,
            // custId: this.props.location.state.custId,
            mobile: this.props.location.state.mobile,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
        }

        fetch(request("register/checkForgotPass" + this.state.customerType + "v1", sendOtpCodeRequestBody))
        .then(res => res.json())
        .then((sendOtpCodeRequestResponse) => {
            if (sendOtpCodeRequestResponse.status.statusCode === 1) {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.startTimer();
                $('.registration-container-sms-confirmation-numbers').prop('disabled', false);
                $('.registration-container-sms-confirmation-numbers').prop('value', null);
                this.setState({isDisabled: true});
                this.setState({verifyToken: sendOtpCodeRequestResponse.verifyToken})
                // console.log(this.state.verifyToken)
            }
            else {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({ feedbackMessage: this.props.t(sendOtpCodeRequestResponse.status.statusCode) });
                // this.setState({verifyToken: sendOtpCodeRequestResponse.verifyToken})
            }
        },
        (error) => {
            this.props.history.push("/technical-break");
        });   
    }
    deleteCodeNumber = (number, event) => {
        let element = eval("this.code" + number);
        let previousElement = eval("this.code" + (number - 1));
        if(event.keyCode === 8) {
            if(event.target.value === "") {
                if(event.target.previousElementSibling) {
                    previousElement.disabled = false;
                    event.target.previousElementSibling.value = "";
                    event.target.previousElementSibling.focus();
                }

            }
        }
    }
    insertCodeNumber = (number, event) => {
        let element = eval("this.code" + number);
        element.value = event.target.value.replace(/\D/g, '');
        if (event.target.value) {
            if (element.value.length > 1) {
                element.value = element.value.substring(0, 1);
            }
            if (event.target.nextElementSibling) {
                event.target.disabled = true;
                event.target.nextElementSibling.focus();
            }
        }
        else {
            event.target.value = null;
        }
        if (this.code1.value !== "" && this.code2.value !== "" && this.code3.value !== "" && this.code4.value !== "" && this.code5.value !== "" && this.code6.value !== "" ) {
            this.setState({ isDisabled: false });
        }
        else {
            this.setState({ isDisabled: true });
        }
    }

    verifyOtpCode = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        clearInterval(this.state.timerInterval);
        this.setState({ timer: null });
        let verifyOtpCode = this.code1.value + this.code2.value + this.code3.value + this.code4.value + this.code5.value + this.code6.value;
        let verifyOtpCodeRequestBody = this.state.customerType === 'Juridical' ? {
            // custId: this.props.location.state.custId,
            // compId: this.props.location.state.compId,
            confirmCode: verifyOtpCode,
            verifyToken: this.state.verifyToken
        }:{
            // custId: this.props.location.state.custId,
            confirmCode: verifyOtpCode,
            verifyToken: this.state.verifyToken
        }
        this.setState({ feedbackMessage: null, isDisabled: true });
        fetch(request("register/verifyOtpForgotPass", verifyOtpCodeRequestBody))
            .then(res => res.json())
            .then(verifyOtpCodeRequestResponse => {
                if (verifyOtpCodeRequestResponse.status.statusCode !== 1) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ feedbackMessage: this.props.t(verifyOtpCodeRequestResponse.status.statusCode)});
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    if(this.state.customerType === 'Juridical') {
                        this.props.history.push('/login/juridical/mobile/forgot-password/3', { custId: this.props.location.state.custId, compId: this.props.location.state.compId, verifyToken: this.state.verifyToken});
                    }
                    else {
                        this.props.history.push('/login/individual/mobile/forgot-password/3', { custId: this.props.location.state.custId, verifyToken: this.state.verifyToken});
                    }
                }
            },
            (error) => {
                this.props.history.push("/technical-break");
            })

    }

    componentDidMount() {
        this.startTimer();
        if(this.props.location.state === undefined) {
            if(this.props.match.url === '/login/juridical/mobile/forgot-password/2') {
                this.props.history.push('/login/juridical/mobile/forgot-password/1');
            }
            else {
                this.props.history.push('/login/individual/mobile/forgot-password/1');
            }
        }
        else {
            if(this.props.match.url === '/login/juridical/mobile/forgot-password/2') {
                this.setState({customerType: 'Juridical'});
            }
            else if(this.props.match.url === '/login/individual/mobile/forgot-password/2') {
                this.setState({customerType: 'Individual'});
            }
            // this.sendOtpCode();
        }
        // let verifyToken = this.props.location.state.verifyToken

    }
    
    render() {
        let { timer, feedbackMessage, isDisabled, isDisabledResend } = this.state;
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title">{t('forget password')}</h2>
                    <p className="registration-container-description">{t('120')}</p>
                    <img src={forgotPasswordIcon} className="registration-container-icon" alt="forgotPasswordIcon" />
                    <div className="registration-container-sms-confirmation">
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code1 = input }} placeholder="0" autoFocus={true} onInput={this.insertCodeNumber.bind(this, 1)} onKeyDown={this.deleteCodeNumber.bind(this, 1)} />
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code2 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 2)} onKeyDown={this.deleteCodeNumber.bind(this, 2)}/>
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code3 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 3)} onKeyDown={this.deleteCodeNumber.bind(this, 3)}/>
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code4 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 4)} onKeyDown={this.deleteCodeNumber.bind(this, 4)}/>
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code5 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 5)} onKeyDown={this.deleteCodeNumber.bind(this, 5)}/>
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code6 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 6)} onKeyDown={this.deleteCodeNumber.bind(this, 6)}/>
                    </div>
                    <div className="registration-container-validation-feedback">
                        {
                            feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage}</p> : null
                        }
                    </div>
                    <div className="registration-container-resend">
                        <div className="registration-container-resend-container">
                            <div className="registration-container-resend-container-icon">
                                <img src={commentAltLines} className="registration-container-resend-container-icon-img" alt="commentAltLines" />
                            </div>
                            <button type="button" className="registration-container-resend-container-button" disabled={isDisabledResend} onClick={this.sendOtpCode}>{t('resend the code')}</button>
                        </div>
                        <span className="registration-container-resend-timer">{timer}</span>
                    </div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backCheckCustomer}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" onClick={this.verifyOtpCode} disabled={isDisabled}>{t('next')}</button>
                    </div>
                </form>
            </div>
        )
    }
}


export default translate("translation")(VerifyOtpCode);