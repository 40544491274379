import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { request } from "./../../../../config";
import AsanConfirmationIcon from "./../../img/asan-confirmation-icon.svg";
import headerLogo from "./../../img/header-logo.svg";
import { translate } from 'react-i18next';

class AsanConfirmation extends Component {
    state = {
        feedbackMessage: null,
        verificationCode: null,
    }
    
    backCheckCustomer = () => {
        // this.props.history.push('/registration/juridical/asan/1');
        window.location.href= '/registration/juridical/asan/1';
    }

    loginWithAsan = () => {
        let loginWithAsanRequestBody = {
            // transactionId: this.props.location.state.transactionId,
            // certificate: this.props.location.state.certificate,
            // challenge: this.props.location.state.challenge
            mobile: this.props.location.state.mobile,
            userId: this.props.location.state.userId,
            sessionId: this.props.location.state.sessionId,
            token: this.props.location.state.token,
            // verificationCode: this.props.location.state.verificationCode
        };
        // console.log(this.props.location.state.verificationCode)
        fetch(request("register/registrationJuridicalForAsan", loginWithAsanRequestBody))
        .then(res => res.json())
        .then((loginWithAsanResponse) => {
            if (loginWithAsanResponse.status.statusCode === 1) {
                // let getCompaniesCertRequestBody = {
                    // custCompStatus: 2,
                    // pin: this.props.location.state.pin,
                    // mobile: this.props.location.state.mobile,
                    // userId: this.props.location.state.userId,
                    // sessionId: this.props.location.state.sessionId,
                    // token: this.props.location.state.token,

                // };
                // fetch(request("register/getCompaniesCert", getCompaniesCertRequestBody))
                // .then(json => json.text())
                // .then((getCompaniesCertResponse) => {
                //     const JSON = require('true-json-bigint');
                    // let getCompaniesCertResponseObject = JSON.parse(getCompaniesCertResponse);
                    // if (getCompaniesCertResponseObject.status.statusCode === 1) {
                        for (let i = 0; i < loginWithAsanResponse.company.length; i++) {
                            let companyId = "";
                            if (typeof (loginWithAsanResponse.company[i].token) === 'object' && loginWithAsanResponse.company[i].token.hasOwnProperty('c')) {
                                loginWithAsanResponse.company[i].token.c.forEach(part => {
                                    companyId += part;
                                });
                            }
                            else {
                                companyId += loginWithAsanResponse.company[i].token;
                            }
                            loginWithAsanResponse.company[i].token = companyId;
                        }
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.history.push('/registration/juridical/asan/3', {  mobile: this.props.location.state.mobile, userId: this.props.location.state.userId, companies: loginWithAsanResponse.company, getAuthInfoJuridicalRegisterHeaders: this.props.location.state.getAuthInfoJuridicalRegisterHeaders  });
                    // }
                    // else {
                    //     this.props.updateAppState('isLoadingTransparentBackground', false);
                    //     this.props.history.push('/registration/juridical/asan/1', {feedbackMessage: this.props.t(getCompaniesCertResponseObject.status.statusCode)});
                    // }
                // },
                // (error) => {
                //     this.props.history.push("/technical-break");
                // }
                // );
            }
            else {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.props.history.push('/registration/juridical/asan/1', {feedbackMessage: this.props.t(loginWithAsanResponse.status.statusCode)});
            }
        },
        (error) => {
            this.props.history.push("/technical-break");
        });
    }

    componentDidMount = () => {
        if(this.props.location.state === undefined) {
            this.props.history.push('/registration/juridical/asan/1');
        }
        else {
            this.setState({verificationCode: this.props.location.state.verificationCode});
            this.loginWithAsan();
        }
    }

    render() {
        let { t } = this.props;
        let { feedbackMessage, verificationCode } = this.state;

        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <div className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title">{t('asan signature confirmation')}</h2>
                    <p className="registration-container-description">{t('please accept the request sent to your phone')}</p>
                    <img src={AsanConfirmationIcon} className="registration-container-icon" alt="AsanConfirmationIcon" />
                    <div className="registration-container-asan-confirmation">
                        <span className="registration-container-asan-confirmation-code">{verificationCode}</span>
                    </div>
                    <div className="registration-container-validation-feedback">
                        {
                            feedbackMessage ? <p className="registration-container-validation-feedback-message">{feedbackMessage}</p> : null
                        }
                    </div>
                    <div className="registration-container-footer">
                    <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backCheckCustomer}>{t('back')}</button>
                    <button className="registration-container-footer-button registration-container-footer-button--right" disabled={true}>{t('next')}</button>
                    </div>
                </div>
                <div className="registration-range">
                    <p className="registration-range-fraction">
                        <span className="registration-range-fraction-numerator">1</span>/3
                    </p>
                    <div className="registration-range-container">
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                            <div className="registration-range-container-step-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate("translation")(AsanConfirmation);