import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import creditIcon from './../../img/credit-icon.svg';
import depositIcon from './../../img/deposit-icon.svg';
import cardsIcon from './../../img/cards-icon.png';
import { request } from './../../../../config';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import { translate } from 'react-i18next';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';
import DepositWithdrawalRates from '../../../../copms/Modal/DepositWithdrawalRates';
import DepositInterestPaid from '../../../../copms/Modal/DepositInterestPaid';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

const saveFile = (name, type, data) => {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class Deposits extends Component {
    state = {
        deposits: [],
        totalAmountAZN: 0,
        totalAmountUSD: 0,
        totalAmountEUR: 0,
        isLoading: false,
        openDepositRates: false,
        openInterestPaid: false,
        selectedId: 0,
        status: 4
    }

    showDepositProducts = () => {
        window.open('http://www.turanbank.az/' + localStorage.getItem('i18nextLng').toLowerCase() + '/pages/369', "_blank");
    }
    numberToString = (number) => {
        if (number.toString() === "0") {
            return "0.00"
        }
        else {
            if (number.toString().indexOf('.') !== -1) {
                let numberArray = number.toString().split('.');
                let x = (numberArray[1].length === 1) ? (numberArray[1] + '0') : numberArray[1];
                return numberArray[0] + "." + x;
            }
            else {
                return number.toString() + ".00"
            }
        }
    }

    downloadTariffPdf = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadDepositPdf", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Qaydalar') + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    downloadContract = (contract) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let arrrayBuffer = base64ToArrayBuffer(contract); //data is the base64 encoded string
        let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
        let link = window.URL.createObjectURL(blob);
        var newWin = window.open(link, '');
        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            saveFile(this.props.t('Contract') + ".pdf", "application/pdf", arrrayBuffer)
        }
        this.props.updateAppState('isLoadingTransparentBackground', false);
    }

    downloadADOC=(contractADOC,depositName)=>{
        let arrrayBuffer = base64ToArrayBuffer(contractADOC); //data is the base64 encoded string
        saveFile(depositName  + ".adoc", "application/adoc", arrrayBuffer);
    }

    componentDidMount() {
        // console.log(this.props.location.state)
        if (this.props.location.state) {
            this.setState({ status: this.props.location.state?.status }, () => {
                this.props.history.replace();
            });
        }
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let loanRequestBody = customerType === "Individual" ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("deposit/" + customerType.toLocaleLowerCase() + "DepositList", loanRequestBody))
            .then(res => res.text())
            .then((depositResponseJSON) => {
                const JSON = require('true-json-bigint');
                let depositResponse = JSON.parse(depositResponseJSON);
                if (depositResponse.status.statusCode !== 1 && depositResponse.status.statusCode !== 158) {
                    if (depositResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(depositResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (depositResponse.status.statusCode === 158) {
                        depositResponse.deposits = [];
                    }
                    let totalAmount = {
                        azn: 0.00,
                        usd: 0.00,
                        eur: 0.00
                    }
                    for (let i = 0; i < depositResponse.deposits.length; i++) {
                        // // =========ID=========
                        let id = "";
                        if (typeof (depositResponse.deposits[i].id) === 'object' && depositResponse.credit[i].id.hasOwnProperty('c')) {
                            depositResponse.credit[i].id.c.forEach(part => {
                                id += part;
                            });
                        }
                        else {
                            id += depositResponse.deposits[i].id;
                        }
                        depositResponse.deposits[i].id = id;
                        // =========BALANCE=========
                        if (depositResponse.deposits[i].amount.toString() === "0") {
                            depositResponse.deposits[i].amount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (depositResponse.deposits[i].amount.toString().indexOf('.') !== -1) {
                                let loanAmountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].amountParts = {
                                    wholePart: loanAmountArray[0],
                                    fractionalPart: (loanAmountArray[1].length === 1) ? (loanAmountArray[1] + '0') : loanAmountArray[1]
                                }
                            }
                            else {
                                let loanAmountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].amountParts = {
                                    wholePart: depositResponse.deposits[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========AMOUNT=========
                        if (depositResponse.deposits[i].amount.toString() === "0") {
                            depositResponse.deposits[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (depositResponse.deposits[i].amount.toString().indexOf('.') !== -1) {
                                let acountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].organizedAmount = {
                                    wholePart: acountArray[0],
                                    fractionalPart: (acountArray[1].length === 1) ? (acountArray[1] + '0') : acountArray[1]
                                }
                            }
                            else {
                                let acountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].organizedAmount = {
                                    wholePart: depositResponse.deposits[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        depositResponse.deposits[i].currencyIcon = currencyFilter(depositResponse.deposits[i].currency);
                        // =========TOTALAMOUNT=========
                        if (depositResponse.deposits[i].depStatus === 4) {
                            if (depositResponse.deposits[i].currency === "AZN") {
                                totalAmount.azn += depositResponse.deposits[i].amount;
                            }
                            if (depositResponse.deposits[i].currency === "USD") {
                                totalAmount.usd += depositResponse.deposits[i].amount;
                            }
                            if (depositResponse.deposits[i].currencys === "EUR") {
                                totalAmount.eur += depositResponse.deposits[i].amount;
                            }
                        }
                    }
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });
                    this.setState({ deposits: depositResponse.deposits });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    dropdownItemHandler = (index, id, contractPdf, contractADOC, contractName) => {
        switch (index) {
            case 0:
                this.props.updateAppState('isLoadingTransparentBackground', true);

                this.setState({ selectedId: id }, () => {
                    this.setState({ openInterestPaid: true });
                })
                break;
            case 1:
                this.props.updateAppState('isLoadingTransparentBackground', true);
                this.setState({ selectedId: id }, () => {
                    this.setState({ openDepositRates: true });
                });

                break;
            case 2:
                this.downloadContract(contractPdf);
                break;
            case 3:
                this.downloadADOC(contractADOC,contractName);
                break;    
        }
    }

    dropdownItemHandlerForOrderedDeposits= (index, id, contractPdf, contractADOC, contractName) => {
        switch (index) {
            case 0:
                this.props.updateAppState('isLoadingTransparentBackground', true);
                this.setState({ selectedId: id }, () => {
                    this.setState({ openDepositRates: true });
                });

                break;
            case 1:
                this.downloadContract(contractPdf);
                break;
            case 2:
                this.downloadADOC(contractADOC,contractName);
                break;    
        }
    }


    render() {
        let { deposits, totalAmountAZN, totalAmountUSD, totalAmountEUR, isLoading, openDepositRates, openInterestPaid, selectedId, status } = this.state;
        let { t , alertState} = this.props;
  
        return (
            <>
                <div className="dashboard-header-route dashboard-header-route-finance">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('deposits')}</h2>
                    </div>
                    <div>
                        <button onClick={() => this.downloadTariffPdf()} className="dashboard-header-route-link">{t('DEPOSIT TERMS')}</button>
                        {
                            // localStorage.getItem('userId') && 
                            !localStorage.getItem('compId') && localStorage.getItem('checkResident') == "1" && (
                               localStorage.getItem("isAsan") ==="true" ? 
                                  <Link to='/dashboard/finance/deposits/order' class="dashboard-header-route-button">{t('ORDER A DEPOSIT')}</Link> 
                                   : <button onClick={()=> alertState(true, 'warning', null, t('For place an online deposit, log in with Asan İmza'))} class="dashboard-header-route-button">{t('ORDER A DEPOSIT')}</button>
                            )
                           
                        }
                    </div>
                </div>
                <DepositWithdrawalRates {...this.props} open={openDepositRates} id={selectedId} onClose={() => this.setState({ openDepositRates: false })} />
                <DepositInterestPaid {...this.props} open={openInterestPaid} id={selectedId} onClose={() => this.setState({ openInterestPaid: false })} />
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            (deposits.length === 0) ?
                                <div className="dashboard-finance-section-invisible-cards-section">
                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have any active deposits.')}</p>
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('please order a new deposit.')}</p>
                                    </div>
                                    {
                                        localStorage.getItem('userId') && !localStorage.getItem('compId') && localStorage.getItem('checkResident') === "1" &&
                                        <Link to='/dashboard/finance/deposits/order' className="dashboard-finance-section-invisible-cards-section-button">{t('ORDER A DEPOSIT')}</Link>
                                    }
                                </div>
                                :
                                <div className="dashboard-finance-section-cards-section">
                                    <div className="dashboard-finance-section-cards-section-statistics-section">

                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon dashboard-finance-section-cards-section-statistics-icon__azn">₼</span>{totalAmountAZN}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("deposit balance")} (AZN)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#0066B2" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#0066B2" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">$</span>{totalAmountUSD}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("card balance")} (USD)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#6DD230" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#6DD230" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">€</span>{totalAmountEUR}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("card balance")} (EUR)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#FFAB2B" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FFAB2B" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-finance-section-cards-section-table-section">
                                        <div className="dashboard-deposit-buttons">
                                            <button className={`dashboard-deposit-button dashboard-deposit-button--available ${status === 4 ? 'dashboard-deposit-button--active' : ''}`} onClick={() => { this.setState({ status: 4 }) }}>
                                                <div className='dashboard-deposit-button-circle'></div>
                                                <p className='dashboard-deposit-button-title'>{t("Available")}</p>
                                            </button>
                                            <button className={`dashboard-deposit-button dashboard-deposit-button--progress ${status === 2 ? 'dashboard-deposit-button--active' : ''}`} onClick={() => { this.setState({ status: 2 }) }}>
                                                <div className='dashboard-deposit-button-circle'></div>
                                                <p className='dashboard-deposit-button-title'>{t("orders")}</p>
                                            </button>
                                            <button className={`dashboard-deposit-button dashboard-deposit-button--reject ${status === 5 ? 'dashboard-deposit-button--active' : ''}`} onClick={() => { this.setState({ status: 5 }) }}>
                                                <div className='dashboard-deposit-button-circle'></div>
                                                <p className='dashboard-deposit-button-title'>{t("refusal")}</p>
                                            </button>
                                        </div>
                                        <table className="dashboard-finance-section-cards-section-table">
                                            <tbody className="dashboard-finance-section-cards-section-tbody">
                                                {
                                                    deposits.filter(deposit => (deposit.depStatus === status)).length > 0 ?
                                                        deposits.filter(deposit => (deposit.depStatus === status)).map((deposit, index) => (
                                                            deposit.depStatus === 4 ?
                                                                <Fragment key={index}>
                                                                    <tr className="dashboard-finance-section-cards-section-row">
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <img src={depositIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                            <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                <Link to={"/dashboard/finance/deposit/" + deposit.id} className="dashboard-finance-section-cards-section-cell-content">{deposit.name}</Link>
                                                                                <Link to={"/dashboard/finance/deposit/" + deposit.id} className="dashboard-finance-section-cards-section-cell-title">{t('deposit name')}</Link>
                                                                            </div>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{deposit.yearPercent}%</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('deposit interest')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                {/* {deposit.amountParts.wholePart}.{deposit.amountParts.fractionalPart} */}
                                                                                <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + deposit.currency.toLowerCase()}>{deposit.currencyIcon}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-whole-part">{deposit.organizedAmount.wholePart}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-fractional-part">{deposit.organizedAmount.fractionalPart}</span>

                                                                            </p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('amount of deposit')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{deposit.branchName}</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('branch')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{deposit.currency}</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('currency')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <div className="dashboard-finance-section-cards-section-button-group">
                                                                                {
                                                                                    deposit?.contractPdf || deposit?.contract ?
                                                                                        <Dropdown dropdownItems={[
                                                                                            { title: this.props.t('Interest paid') },
                                                                                            { title: this.props.t('Withdrawal rates') },
                                                                                            { title: this.props.t('Contract') },
                                                                                            {title: this.props.t('Download ADOC')}
                                                                                        ]} dropdownItemHandler={(i) => this.dropdownItemHandler(i, deposit.id, deposit?.contractPdf, deposit?.contract, deposit?.name)} />
                                                                                        :
                                                                                        <Dropdown dropdownItems={[
                                                                                            { title: this.props.t('Interest paid') },
                                                                                            { title: this.props.t('Withdrawal rates') }
                                                                                        ]} dropdownItemHandler={(i) => this.dropdownItemHandler(i, deposit.id)} />
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="dashboard-finance-row-line">
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    </tr>
                                                                </Fragment>
                                                                :
                                                                <Fragment key={index}>
                                                                    <tr className="dashboard-finance-section-cards-section-row">
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <img src={depositIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                            <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                <div className="dashboard-finance-section-cards-section-cell-content">{deposit.name}</div>
                                                                                <div className="dashboard-finance-section-cards-section-cell-title">{t('deposit name')}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{deposit.yearPercent}%</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('deposit interest')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                {/* {deposit.amountParts.wholePart}.{deposit.amountParts.fractionalPart} */}
                                                                                <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + deposit.currency.toLowerCase()}>{deposit.currencyIcon}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-whole-part">{deposit.organizedAmount.wholePart}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-fractional-part">{deposit.organizedAmount.fractionalPart}</span>
                                                                            </p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('amount of deposit')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{deposit.branchName}</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('branch')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            <p className="dashboard-finance-section-cards-section-cell-content">{deposit.currency}</p>
                                                                            <p className="dashboard-finance-section-cards-section-cell-title">{t('currency')}</p>
                                                                        </td>
                                                                        <td className="dashboard-finance-section-cards-section-cell">
                                                                            {
                                                                                deposit?.contractPdf || deposit?.contract ?
                                                                                    <Dropdown dropdownItems={[
                                                                                        { title: this.props.t('Withdrawal rates') },
                                                                                        { title: this.props.t('Contract') },
                                                                                        {title: this.props.t('Download ADOC')}
                                                                                    ]} dropdownItemHandler={(i) => this.dropdownItemHandlerForOrderedDeposits(i, deposit.id, deposit?.contractPdf, deposit?.contract,deposit?.name)} />
                                                                                    :
                                                                                    <Dropdown dropdownItems={[
                                                                                        { title: this.props.t('Withdrawal rates') }
                                                                                    ]} dropdownItemHandler={(i) => this.dropdownItemHandlerForOrderedDeposits(i, deposit.id)} />
                                                                            }
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="dashboard-finance-row-line">
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                    </tr>
                                                                </Fragment>
                                                        ))
                                                        :
                                                        <div className="dashboard-finance-section-invisible-cards-section">
                                                            <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                            <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have any active deposits.')}</p>
                                                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('please order a new deposit.')}</p>
                                                            </div>
                                                            {
                                                                localStorage.getItem('userId') && !localStorage.getItem('compId') && localStorage.getItem('checkResident') === "1" &&
                                                                <Link to='/dashboard/finance/deposits/order' className="dashboard-finance-section-invisible-cards-section-button">{t('ORDER A DEPOSIT')}</Link>
                                                            }
                                                        </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                    }
                </div>
            </>
        )
    }
}

export default translate("translation")(Deposits);