import React, { Component } from 'react';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import { request } from "./../../../../config";
import barcodeReadIconForm from "./../../img/barcode-read-icon-form.svg";
import phoneIconForm from "./../../img/phone-icon-form.svg";
import headerLogo from "./../../img/header-logo.svg";
import { translate } from 'react-i18next';

class CheckCustomer extends Component {

    state = {
        isDisabled: true,
        feedbackMessage: null,
        validatePin: false,
        validateMobile: false,
        validateTaxNo: false,
        pin: ''
    }

    backLogin = () => {
        this.props.history.push('/login/juridical/mobile');
    }

    validateData = (inputType) => {
        this.setState({ feedbackMessage: null });
        if (inputType === 'pin') {
            let pin = this.pin.value;
            if (this.pin.value.indexOf(' ') !== -1) {
                pin = pin.slice(0, pin.indexOf(' ')) + pin.slice(pin.indexOf(' ') + 1, pin.length);
                this.pin.value = pin;
            }
            if (this.pin.value.length < 7) {
                this.setState({ validatePin: false });
            }
            else {
                if (this.pin.value.length > 7) {
                    this.pin.value = this.pin.value.substring(0, 7);
                }
                this.setState({ validatePin: true });
            }
        }
        if (inputType === 'mobile') {
            setTimeout(() => {
                if (this.mobile.value.indexOf("_") === -1) {
                    this.setState({ validateMobile: true });

                }
                else {
                    this.setState({ validateMobile: false });

                }
            }, 1)
        }
        setTimeout(() => {
            if (this.state.validatePin && this.state.validateMobile && this.state.validateTaxNo) {
                this.setState({ isDisabled: false });
            }
            else {
                this.setState({ isDisabled: true });
            }
        }, 1)
        if (inputType === 'taxNo') {
            let mask = /[^0-9]/g; //temporay
            if (this.taxNo.value !== this.taxNo.value.replace(mask, '')) {
                this.taxNo.value = this.taxNo.value.replace(mask, '');
            }
            if (this.taxNo.value.length < 10) {
                this.setState({ validateTaxNo: false });
            }
            else {
                if (this.taxNo.value.length > 10) {
                    this.taxNo.value = this.taxNo.value.substring(0, 10);
                }
                this.setState({ validateTaxNo: true });
            }

        }
    }

    checkData = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getMobile = (mobile) => {
            ['+', '-', '(', ')', ' '].forEach(char => {
                while (mobile.indexOf(char) !== -1) {
                    mobile = mobile.slice(0, mobile.indexOf(char)) + mobile.slice(mobile.indexOf(char) + 1, mobile.length);
                }
            });
            return mobile;
        }
        let checkJuridicalCostumerRequestBody = {
            pin: this.pin.value,
            mobile: getMobile(this.mobile.value),
            taxNo: this.taxNo.value,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
            
        }

        fetch(request("register/checkJuridicalCustomerv1", checkJuridicalCostumerRequestBody))
            .then(res => res.text())
            .then((checkCustomerRequestResponseJSON) => {
                const JSON = require('true-json-bigint');
                let checkCustomerRequestResponse = JSON.parse(checkCustomerRequestResponseJSON);
                if (checkCustomerRequestResponse.status.statusCode !== 1 && checkCustomerRequestResponse.status.statusCode !== 113) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({
                        feedbackMessage: this.props.t(checkCustomerRequestResponse.status.statusCode),
                        isDisabled: true
                    });
                }
                else {
                    let custId = "", compId = "";
                    if (typeof (checkCustomerRequestResponse.custId) === "object" && checkCustomerRequestResponse.custId.hasOwnProperty('c')) {
                        checkCustomerRequestResponse.custId.c.forEach(part => {
                            custId += part;
                        });
                    }
                    else {
                        custId += checkCustomerRequestResponse.custId;
                    }
                    if (typeof (checkCustomerRequestResponse.compId) === "object" && checkCustomerRequestResponse.compId.hasOwnProperty('c')) {
                        checkCustomerRequestResponse.compId.c.forEach(part => {
                            compId += part;
                        });
                    }
                    else {
                        compId += checkCustomerRequestResponse.compId;
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.props.history.push('/registration/juridical/mobile/2', { custId: custId, compId: compId, mobile: checkJuridicalCostumerRequestBody.mobile, pin: checkJuridicalCostumerRequestBody.pin, taxNo: this.taxNo.value, verifyToken: checkCustomerRequestResponse.verifyToken });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { isDisabled, feedbackMessage } = this.state;
        let { t } = this.props;

        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container">
                    <h2 className="registration-container-title">{t('registration')}</h2>
                    <p className="registration-container-description">{t('To register, enter the PIN code of your ID card and the mobile number you provided to the bank')}</p>
                    <div className="registration-container-user-type">
                        <Link to="/registration/individual/mobile" className="registration-container-user-type-button registration-container-user-type-button__personal">{t('individual')}</Link>
                        <Link to="/registration/juridical/mobile" className="registration-container-user-type-button registration-container-user-type-button__corporate registration-container-user-type-button--active">{t('juridical')}</Link>
                    </div>
                    <div className="registration-container-registration-type">
                        <Link to="/registration/juridical/mobile" className="registration-container-registration-type-label">
                            <input type="radio" name="registration-type" defaultChecked="true" className="registration-container-registration-type-input" />
                            {t('with mobile number and fin code')}
                            <span className="registration-container-registration-type-checkmark"></span>
                        </Link>
                        <Link to="/registration/juridical/asan" className="registration-container-registration-type-label">
                            <input type="radio" name="registration-type" className="registration-container-registration-type-input" />
                            {t('with asan signature')}
                            <span className="registration-container-registration-type-checkmark"></span>
                        </Link>
                    </div>
                    <div className="registration-container-form">
                        <div className="registration-container-form-group">
                            <label className="registration-container-form-group-label">{t('fin code of the id card')}</label>
                            <div className="registration-container-form-group-box">
                                <input type="text" className="registration-container-form-group-box-input" placeholder={t('enter the fin code')} value={this.state.pin} onChange={val => this.setState({ pin: val.target.value.toUpperCase() })} ref={(input) => { this.pin = input }} onInput={this.validateData.bind(this, 'pin')} />
                                <div className="registration-container-form-group-box-icon">
                                    <img src={barcodeReadIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__fin" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="registration-container-form-group">
                            <label className="registration-container-form-group-label">{t('phone number')}</label>
                            <div className="registration-container-form-group-box">
                                <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" className="registration-container-form-group-box-input" placeholder="+994 (__) ___-__-__" ref={(input) => { this.mobile = input }} onInput={this.validateData.bind(this, 'mobile')} />
                                <div className="registration-container-form-group-box-icon">
                                    <img src={phoneIconForm} className="registration-container-form-group-box-icon-img  registration-container-form-group-box-icon-img__phone" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="registration-container-form-group">
                            <label className="registration-container-form-group-label">{t('tin')}</label>
                            <div className="registration-container-form-group-box">
                                <input type="text" className="registration-container-form-group-box-input" placeholder={t('enter the tin')} ref={(input) => { this.taxNo = input }} onInput={this.validateData.bind(this, 'taxNo')} />
                                <div className="registration-container-form-group-box-icon">
                                    <img src={barcodeReadIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__voen" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="registration-container-validation-feedback">
                        {
                            feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage}</p> : null
                        }
                    </div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backLogin}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled} onClick={this.checkData}>{t('next')}</button>
                    </div>
                </form>
                <div className="registration-range">
                    <p className="registration-range-fraction">
                        <span className="registration-range-fraction-numerator">1</span>/3
                    </p>
                    <div className="registration-range-container">
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                            <div className="registration-range-container-step-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(CheckCustomer);