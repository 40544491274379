import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import $ from 'jquery';

import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import accountIcon from './../../img/account-icon.svg';
import accountExtractIcon from './../../img/account-extract-icon.png';
import { request } from './../../../../config';
import DropdownWithChevron from './../../../../copms/DropdownWithChevron/DropdownWithChevron';
import Loading from './../../Loading/Loading';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class AccountExtract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iban: null,
            statements: [],
            startDate: null,
            endDate: null,
            visibleStartDate: null,
            visibleEndDate: null,
            selected: 'Pdf',
            isLoading: false,
            currentPage: 1,
            lastPage: 0
        }
        this.updateDate = this.updateDate.bind(this);
    }
    updateSelected = (value) => {
        this.setState({ selected: value });
    }
    updateDate(name, value) {
        this.setState({ [name]: value });
    }
    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    showStatements = (startDate = this.state.startDate, endDate = this.state.endDate, initial = false) => {
        if (initial) {
            this.setState({ isLoading: true });
        }
        else {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountStatementForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            startDate: this.getFormatedDate(startDate),
            endDate: this.getFormatedDate(endDate),
            pageNumber: this.state.currentPage,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.props.match.params.id,
                startDate: this.getFormatedDate(startDate),
                endDate: this.getFormatedDate(endDate),
                pageNumber: this.state.currentPage,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("account/getAccStatementFor" + customerType + "Customer", getAccountStatementForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountStatementForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountStatementForCustomerResponse = JSON.parse(getAccountStatementForCustomerResponseJSON);
                if (getAccountStatementForCustomerResponse.status.statusCode !== 1 && getAccountStatementForCustomerResponse.status.statusCode !== 134) {
                    if (getAccountStatementForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                    else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountStatementForCustomerResponse.status.statusCode));
                        if (initial) {
                            this.setState({ isLoading: false });
                        }
                        else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    if (getAccountStatementForCustomerResponse.status.statusCode === 134) {
                        getAccountStatementForCustomerResponse.accountStatementList = [];
                    }
                    this.setState({ lastPage: getAccountStatementForCustomerResponse.pageCount ? getAccountStatementForCustomerResponse.pageCount : 0 })

                    for (let i = 0; i < getAccountStatementForCustomerResponse.accountStatementList.length; i++) {
                        // =========AMOUNT=========
                        if (getAccountStatementForCustomerResponse.accountStatementList[i].dtAmount === 0) {
                            getAccountStatementForCustomerResponse.accountStatementList[i].amount = getAccountStatementForCustomerResponse.accountStatementList[i].crAmount;
                            getAccountStatementForCustomerResponse.accountStatementList[i].account = getAccountStatementForCustomerResponse.accountStatementList[i].dtAccount;

                        }
                        else {
                            if (getAccountStatementForCustomerResponse.accountStatementList[i].crAmount === 0) {
                                getAccountStatementForCustomerResponse.accountStatementList[i].amount = getAccountStatementForCustomerResponse.accountStatementList[i].dtAmount;
                                getAccountStatementForCustomerResponse.accountStatementList[i].account = getAccountStatementForCustomerResponse.accountStatementList[i].crAccount;
                            }
                        }
                        if (getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString() === "0") {
                            getAccountStatementForCustomerResponse.accountStatementList[i].amount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString().indexOf('.') !== -1) {
                                let amountArray = getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString().split('.');
                                getAccountStatementForCustomerResponse.accountStatementList[i].amount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                getAccountStatementForCustomerResponse.accountStatementList[i].amount = {
                                    wholePart: getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ statements: getAccountStatementForCustomerResponse.accountStatementList });
                    if (initial) {
                        this.setState({ isLoading: false });
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    searchStatements = () => {
        this.setState({ visibleStartDate: this.state.startDate, visibleEndDate: this.state.endDate }, () => this.showStatements(this.state.startDate, this.state.endDate));
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountByIdForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("account/getAccountByIdFor" + customerType + "Customer", getAccountByIdForCustomerRequestBody))
            .then(res => res.json())
            .then((getAccountByIdForCustomerResponse) => {
                if (getAccountByIdForCustomerResponse.status.statusCode !== 1) {
                    if (getAccountByIdForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountByIdForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ iban: getAccountByIdForCustomerResponse.iban });
                    let today = new Date();
                    this.setState({ startDate: today, endDate: today, visibleStartDate: today, visibleEndDate: today });
                    this.showStatements(new Date(), new Date(), true);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    extractExcel() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountByIdForCustomerRequestBody = customerType === 'Juridical' ? {
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                startDate: this.getFormatedDate(this.state.startDate),
                endDate: this.getFormatedDate(this.state.endDate),
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("account/getAccountStatement" + customerType + "ExcelByteArray", getAccountByIdForCustomerRequestBody))
            .then(res => res.json())
            .then((getAccountStatementCustomerResponse) => {
                if (getAccountStatementCustomerResponse.status.statusCode !== 1) {
                    if (getAccountStatementCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountStatementCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(getAccountStatementCustomerResponse.bytes); //data is the base64 encoded string

                    saveFile(this.props.t('Account_Extract') + "_" + this.getFormatedDate(this.state.startDate) + "-" + this.getFormatedDate(this.state.endDate) + ".xlsx", "application/xlsx", arrrayBuffer)

                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    extractPdf() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountByIdForCustomerRequestBody = customerType === 'Juridical' ? {
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                startDate: this.getFormatedDate(this.state.startDate),
                endDate: this.getFormatedDate(this.state.endDate),
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("account/getAccountStatement" + customerType + "PdfByteArray", getAccountByIdForCustomerRequestBody))
            .then(res => res.json())
            .then((getAccountStatementCustomerResponse) => {
                if (getAccountStatementCustomerResponse.status.statusCode !== 1) {
                    if (getAccountStatementCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountStatementCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(getAccountStatementCustomerResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Account_Extract') + "_" + this.getFormatedDate(this.state.startDate) + "-" + this.getFormatedDate(this.state.endDate) + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => this.showStatements())
    }

    render() {
        let { iban, statements, visibleStartDate, visibleEndDate, selected, isLoading } = this.state;
        let dateTitle = null;
        let { t } = this.props;

        return (
            <>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('extract')}</h2>
                    </div>
                    <Link to="/dashboard/finance/accounts" className="dashboard-header-route-link">{t('ALL ACCOUNTS')}</Link>
                </div>
                <div className="dashboard-container-main-section dashboard-container-main-section--column dashboard-container-main-section--white">
                    {
                        isLoading ?
                            <Loading />
                            :

                            <div className="dashboard-finance-section-account-extract-section">
                                <div className="dashboard-finance-section-account-extract-section-search-section">
                                    <div className=" dashboard-finance-section-account-extract-section-search-section-box">
                                        <StartDatePicker placeholder="Başlama tarixi" updateDate={this.updateDate} />
                                    </div>
                                    <div className=" dashboard-finance-section-account-extract-section-search-section-box">
                                        <EndDatePicker placeholder="Bitmə tarixi" updateDate={this.updateDate} />
                                    </div>
                                    <button className="dashboard-finance-section-account-extract-section-search-section-box-button" onClick={this.searchStatements}>{t("Search")}</button>
                                </div>
                                <div className="dashboard-finance-section-account-extract-section-main-section">
                                    <div className="dashboard-finance-section-account-extract-section-main-section-header">
                                        <div className="dashboard-finance-section-account-extract-section-main-section-header--left-side">
                                            <img src={accountIcon} className="dashboard-finance-section-account-extract-section-main-section-header-image" alt="accountIcon"/>
                                            <h3 className="dashboard-finance-section-account-extract-section-main-section-header-card-number">{iban}</h3>
                                        </div>
                                        <div className="dashboard-finance-section-account-extract-section-main-section-header--right-side">
                                            <div className="dashboard-finance-section-account-extract-section-main-section-header-history">
                                                <svg className="dashboard-finance-section-account-extract-section-main-section-header-history-icon" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.5312 3.0625L8.9375 0.4375C8.65625 0.15625 8.25 0 7.875 0H1.5C0.65625 0 0 0.6875 0 1.5V14.5C0 15.3438 0.65625 16 1.5 16H10.5C11.3125 16 12 15.3438 12 14.5V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM10.8438 3.78125C10.9062 3.84375 10.9375 3.9375 10.9688 4H8V1.03125C8.0625 1.0625 8.15625 1.09375 8.21875 1.15625L10.8438 3.78125ZM10.5 15H1.5C1.21875 15 1 14.7812 1 14.5V1.5C1 1.25 1.21875 1 1.5 1H7V4.25C7 4.6875 7.3125 5 7.75 5H11V14.5C11 14.7812 10.75 15 10.5 15ZM9 7.375C9 7.1875 8.8125 7 8.625 7H3.375C3.15625 7 3 7.1875 3 7.375V7.625C3 7.84375 3.15625 8 3.375 8H8.625C8.8125 8 9 7.84375 9 7.625V7.375ZM9 9.375C9 9.1875 8.8125 9 8.625 9H3.375C3.15625 9 3 9.1875 3 9.375V9.625C3 9.84375 3.15625 10 3.375 10H8.625C8.8125 10 9 9.84375 9 9.625V9.375ZM9 11.375C9 11.1875 8.8125 11 8.625 11H3.375C3.15625 11 3 11.1875 3 11.375V11.625C3 11.8438 3.15625 12 3.375 12H8.625C8.8125 12 9 11.8438 9 11.625V11.375Z" fill="#849097" />
                                                </svg>
                                                <span className="dashboard-finance-section-account-extract-section-main-section-header-title">{t('date')}</span>
                                                <span className="dashboard-finance-section-account-extract-section-main-section-header-content">{this.getFormatedDate(visibleStartDate)} - {this.getFormatedDate(visibleEndDate)}</span>
                                            </div>
                                            <div className="dashboard-finance-section-account-extract-section-main-section-header-download">
                                                <span className="dashboard-finance-section-account-extract-section-main-section-header-title">{t('download')}</span>
                                                <DropdownWithChevron
                                                    dropdownItems={['Pdf', 'Excel']} updateSelected={this.updateSelected} selected={selected} />
                                                <button disabled={statements.length === 0} onClick={() => selected === "Pdf" ? this.extractPdf() : this.extractExcel()} className="dashboard-finance-section-account-extract-section-main-section-header-download-button">
                                                    <svg className="dashboard-finance-section-account-extract-section-main-section-header-download-icon" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.875 7.25C11.0312 7.125 11.0312 6.875 10.875 6.75L10.6562 6.5C10.5 6.375 10.2812 6.375 10.125 6.5L6.53125 10.125V0.375C6.53125 0.1875 6.34375 0 6.15625 0H5.84375C5.625 0 5.46875 0.1875 5.46875 0.375V10.125L1.84375 6.5C1.6875 6.375 1.46875 6.375 1.3125 6.5L1.09375 6.71875C0.9375 6.875 0.9375 7.125 1.09375 7.25L5.71875 11.9062C5.875 12.0625 6.09375 12.0625 6.25 11.9062L10.875 7.25ZM12 13.625V13.375C12 13.1875 11.8125 13 11.625 13H0.375C0.15625 13 0 13.1875 0 13.375V13.625C0 13.8438 0.15625 14 0.375 14H11.625C11.8125 14 12 13.8438 12 13.625Z" fill="#2E3131" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        statements.length === 0 ?
                                            <div className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section">
                                                <img src={accountExtractIcon} className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section-image" alt="accountExtractIcon" />
                                                <div className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section-paragraph">
                                                    <p className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section-text">{t('Search no matching operation found')}</p>
                                                </div>
                                                {/* <button className="dashboard-finance-section-account-extract-section-main-section-invisible-content-section-button">{t('ORDER A NEW CARD')}</button> */}
                                            </div>
                                            :
                                            <div className="dashboard-finance-section-account-extract-section-main-section-visible-content-section">
                                                <ul className="dashboard-finance-section-account-extract-section-list">
                                                    {
                                                        statements.map((statement, index) => {
                                                            if (dateTitle !== statement.trDate) {
                                                                dateTitle = statement.trDate;
                                                                return [
                                                                    <li className="dashboard-finance-section-account-extract-section-list-date-item" key={index + Math.random(10, 100)}>{statement.trDate}</li>,
                                                                    <li className="dashboard-finance-section-account-extract-section-list-extract-item" key={index}>
                                                                        <div className="dashboard-finance-section-account-extract-section-list-extract-item--left-side">
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-icon-container">
                                                                                <svg className="dashboard-finance-section-account-extract-section-list-extract-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                                                </svg>
                                                                            </div>
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-date-container">
                                                                                <div className="dashboard-finance-section-account-extract-section-list-extract-item-title">{statement.trDate.substring(0, 16)}</div>
                                                                            </div>
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-description-container">
                                                                                <div className="dashboard-finance-section-account-extract-section-list-extract-item-title">{statement.purpose}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dashboard-finance-section-account-extract-section-list-extract-item--right-side">
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-title dashboard-finance-section-account-extract-section-list-extract-item-amount">
                                                                                <span className="dashboard-finance-section-account-extract-section-list-extract-item-amount-whole-part" style={{ color: statement.crAmount > 0 && '#40A900' }}>{statement.amount.wholePart}</span>
                                                                                <span className="dashboard-finance-section-account-extract-section-list-extract-item-amount-fractional-part" style={{ color: statement.crAmount > 0 && '#40A900' }}>{statement.amount.fractionalPart}</span>
                                                                                <span className="dashboard-finance-section-account-extract-section-list-extract-item-amount-currency" style={{ color: statement.crAmount > 0 && '#40A900' }}>{statement.currName}</span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ];
                                                            }
                                                            else {
                                                                return (
                                                                    <li className="dashboard-finance-section-account-extract-section-list-extract-item" key={index}>
                                                                        <div className="dashboard-finance-section-account-extract-section-list-extract-item--left-side">
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-icon-container">
                                                                                <svg className="dashboard-finance-section-account-extract-section-list-extract-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                                                </svg>
                                                                            </div>
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-date-container">
                                                                                <div className="dashboard-finance-section-account-extract-section-list-extract-item-title">{statement.trDate.substring(0, 16)}</div>
                                                                            </div>
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-description-container">
                                                                                <div className="dashboard-finance-section-account-extract-section-list-extract-item-title">{statement.purpose}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dashboard-finance-section-account-extract-section-list-extract-item--right-side">
                                                                            <div className="dashboard-finance-section-account-extract-section-list-extract-item-title dashboard-finance-section-account-extract-section-list-extract-item-amount">
                                                                                <span className="dashboard-finance-section-account-extract-section-list-extract-item-amount-whole-part" style={{ color: statement.crAmount > 0 && '#40A900' }}>{statement.amount.wholePart}</span>
                                                                                <span className="dashboard-finance-section-account-extract-section-list-extract-item-amount-fractional-part" style={{ color: statement.crAmount > 0 && '#40A900' }}>{statement.amount.fractionalPart}</span>
                                                                                <span className="dashboard-finance-section-account-extract-section-list-extract-item-amount-currency" style={{ color: statement.crAmount > 0 && '#40A900' }}>{statement.currName}</span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </ul>
                                                <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                    <ul className="dashboard-pagination">
                                                        {this.state.currentPage !== 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                        {
                                                            Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                                if (this.state.lastPage > 3) {
                                                                    if (this.state.currentPage - 1 === index + 1) {
                                                                        return (
                                                                            <>
                                                                                {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                                {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                                <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    if (this.state.currentPage === index + 1) {
                                                                        return (
                                                                            <>
                                                                                <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    if (this.state.currentPage + 1 === index + 1) {
                                                                        return (
                                                                            <>
                                                                                <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                                {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                            </>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <li className={index + 1 === this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage === 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                    )
                                                                }
                                                            })

                                                        }
                                                        {this.state.currentPage !== this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                </div>
            </>
        )
    }
}

export default translate("translation")(AccountExtract);