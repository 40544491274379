import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";

import { getFormatedDate } from "../../../helpers/fotmatDate";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";
import phoneIcon from "./../../Login/img/phone-icon-form.svg";
import DatePicker from "../../../copms/DatePicker/DatePicker";
import { request } from "../../../config";

const NotABankCustomer = ({ translate, updateAppState }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [errMessage, setErrMessage] = useState();

  let history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    updateAppState("isLoadingTransparentBackground", true);

    let requestBody = {
      name: data.name,
      surname: data.surname,
      pin: data.PINCode.toUpperCase(),
      mobile: formatPhoneNumber(data.phoneNumber),
      birthday: selectedDate,
    };

    fetch(request("register/checkIndividualNotBankCustomerv1", requestBody))
      .then((res) => res.text())
      .then(
        (checkIndividualNotBankCustomerResponseJSON) => {
          const JSON = require("true-json-bigint");
          let checkIndividualNotBankCustomerResponse = JSON.parse(
            checkIndividualNotBankCustomerResponseJSON
          );
          if (checkIndividualNotBankCustomerResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              translate(
                checkIndividualNotBankCustomerResponse.status.statusCode
              )
            );
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            history.push("/sign-up/not-a-bank-customer/otp", {
              custId: checkIndividualNotBankCustomerResponse?.custId,
              verifyToken: checkIndividualNotBankCustomerResponse.verifyToken,
              mobile: formatPhoneNumber(data.phoneNumber),
            });
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };

  return (
    <form
      className="header-sign-section-container-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="header-sign-section-container-form-group">
        <label className="header-sign-section-container-form-group-label">
          {translate("Pin code")}
        </label>
        <div className="header-sign-section-container-form-group-box">
          <input
            type="text"
            maxLength="7"
            className="header-sign-section-container-form-group-box-input"
            {...register("PINCode", { required: true, minLength: 7 })}
          />
        </div>
      </div>
      <div className="header-sign-section-container-form-group">
        <label className="header-sign-section-container-form-group-label">
          {translate("name")}
        </label>
        <div className="header-sign-section-container-form-group-box">
          <input
            type="text"
            className="header-sign-section-container-form-group-box-input"
            {...register("name", { required: true })}
          />
        </div>
      </div>
      <div className="header-sign-section-container-form-group">
        <label className="header-sign-section-container-form-group-label">
          {translate("surname")}
        </label>
        <div className="header-sign-section-container-form-group-box">
          <input
            type="text"
            className="header-sign-section-container-form-group-box-input"
            {...register("surname", { required: true })}
          />
        </div>
      </div>
      <div className="header-sign-section-container-form-group">
        <label className="header-sign-section-container-form-group-label">
          {translate("Date of birth")}
        </label>
        <div className="header-sign-section-container-form-group-box">
          <Controller
            control={control}
            name={"birthday"}
            render={({ field }) => (
              <DatePicker
                format="dd-MM-yyyy"
                disablePast={false}
                disableFuture={true}
                error={false}
                placeholder={translate("Choose")}
                fillColor="#BDC3C7"
                selectedDate={selectedDate}
                updateDate={(e) => {
                  let date = getFormatedDate(e, "-");
                  setSelectedDate(date);
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="header-sign-section-container-form-group">
        <label className="header-sign-section-container-form-group-label">
          {translate("phone number")}
        </label>
        <div className="header-sign-section-container-form-group-box">
          <Controller
            control={control}
            name={"phoneNumber"}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputMask
                mask="+\9\9\4 ( 99 ) 999-99-99"
                className="header-sign-section-container-form-group-box-input"
                placeholder="+994 (__) ___-__-__"
                onChange={onChange}
                onBlur={onBlur}
                checked={value}
                inputRef={ref}
              />
            )}
          />
          <div className="header-sign-section-container-form-group-box-icon">
            <img
              src={phoneIcon}
              className="header-sign-section-container-form-group-box-icon-img  header-sign-section-container-form-group-box-icon-img__phone"
              alt="Phone icon"
            />
          </div>
        </div>
      </div>
      <div className="registration-container-validation-feedback">
        {errMessage ? (
          <p className="registration-container-validation-feedback-message">
            {errMessage}
          </p>
        ) : null}
      </div>
      <button
        className="header-sign-section-container-form-button"
        type="submit"
        disabled={!isDirty || !isValid || !selectedDate}
      >
        {translate("continue")}
      </button>
    </form>
  );
};

export default NotABankCustomer;
