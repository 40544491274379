import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";

import AuthHeader from "../AuthHeader/AuthHeader";
import TimerCountdown from "../TimerCountdown/TimerCountdown";
import { request } from "../../../config";

import "./OTP.scss";

const OTP = ({ translate, updateAppState }) => {
  const [OTPCode, setOTPCode] = useState();
  const [errMessage, setErrMessage] = useState();
  const [verifyToken, setVerifyToken] = useState();

  const initialMinute = 3,
    initialSeconds = 0,
    numInputs = 6;

  let history = useHistory();
  const { state } = useLocation();

  const handleOTP = (otp) => setOTPCode(otp);

  const sendOtpCode = () => {
    updateAppState("isLoadingTransparentBackground", true);

    let requestBody = {
      custId: state?.custId,
      mobile: state?.mobile,
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
    };

    fetch(request("register/checkIndividualNotBankCustomerv1", requestBody))
      .then((res) => res.json())
      .then(
        (sendOtpCodeRequestResponse) => {
          if (sendOtpCodeRequestResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              translate(sendOtpCodeRequestResponse.status.statusCode)
            );
          } else {
            setVerifyToken(sendOtpCodeRequestResponse.verifyToken)
            updateAppState("isLoadingTransparentBackground", false);
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };

  const verifyOtpCode = (e) => {
    e.preventDefault();

    updateAppState("isLoadingTransparentBackground", true);
    setErrMessage("");

    let requestBody = {
      custId: state?.custId,
      confirmCode: OTPCode,
      verifyToken: state.verifyToken
    };

    // console.log(state.verifyToken)

    fetch(request("register/verifyOtpRegister", requestBody))
      .then((res) => res.json())
      .then(
        (verifyOtpCodeRequestResponse) => {
          if (verifyOtpCodeRequestResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              translate(verifyOtpCodeRequestResponse.status.statusCode)
            );
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            history.push("/sign-up/not-a-bank-customer/create-password", {
              custId: state.custId,
              verifyToken: state.verifyToken,
            });
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };

  useEffect(() => {
    if(state === undefined)
    history.push("/sign-up/not-a-bank-customer");
  }, []);

  return (
    <section className="ibank-otp header-sign-section">
      <div className="header-sign-section-container">
        <AuthHeader
          title={translate("Enter the one-time code")}
          desc={[
            translate("enter the 6-digit confirmation code sent to your phone"),
          ]}
        />
        <form onSubmit={verifyOtpCode}>
          <OtpInput
            className="ibank-otp__body"
            value={OTPCode}
            onChange={handleOTP}
            shouldAutoFocus={true}
            {...{ initialMinute, initialSeconds, numInputs }}
          />
          <TimerCountdown
            resendTitle={translate("resend the code")}
            {...{
              initialMinute,
              initialSeconds,
              sendOtpCode,
              setErrMessage,
            }}
          />
          <div className="registration-container-validation-feedback">
            {errMessage ? (
              <p className="registration-container-validation-feedback-message">
                {errMessage}
              </p>
            ) : null}
          </div>
          <button
            className="header-sign-section-container-form-button"
            disabled={OTPCode?.length !== numInputs}
          >
            {translate("continue")}
          </button>
        </form>
      </div>
    </section>
  );
};

export default OTP;
