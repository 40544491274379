import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import { isValid } from 'date-fns';
import { az, enUS, ru } from "date-fns/locale";
import { translate } from 'react-i18next';

function StartDatePicker(props) {
    const currentDate = new Date();
    currentDate.setDate(1);
    const [selectedDate, setSelectedDate] = React.useState(currentDate);

    const handleDateChange = (date) => {
        if (isValid(date)) {
            setSelectedDate(date);
            props.updateDate('startDate', date);
        }
    };
    const { placeholder, i18n, t } = props;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language == "ru" ? ru: i18n.language == 'en' ? enUS : az }>
            <KeyboardDatePicker
                className="dashboard-finance-section-card-section-operation-section-content-pick-date-input w-100 transfer-to-friend"
                placeholder={placeholder}
                format="dd/MM/yyyy"
                value={selectedDate}
                cancelLabel={t('cancel')}
                okLabel={t('ok')}
                onChange={handleDateChange}
                keyboardbuttonprops={{
                    'aria-label': 'change date',
                }}

                keyboardIcon={
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.60059 8.30629H4.57827V7.44763H3.60059V8.30629ZM2.62598 9.16391H5.55904V6.59033H2.62598V9.16391ZM3.60059 11.7389H4.57827V10.8798H3.60059V11.7389ZM2.62598 12.596H5.55904V10.0217H2.62598V12.596ZM7.50781 8.30629H8.48549V7.44763H7.50781V8.30629ZM6.52832 9.16391H9.46141V6.59033H6.52832V9.16391ZM7.50781 11.7389H8.48549V10.8798H7.50781V11.7389ZM6.52832 12.596H9.46141V10.0217H6.52832V12.596ZM11.4268 8.30629H12.4044V7.44763H11.4268V8.30629ZM10.4463 9.16391H13.3794V6.59033H10.4463V9.16391ZM11.4268 11.7389H12.4044V10.8798H11.4268V11.7389ZM10.4463 12.596H13.3794V10.0217H10.4463V12.596ZM14.3565 4.87427H1.64648V13.0252C1.64648 13.2622 1.86549 13.4542 2.13557 13.4542H13.8676C14.1377 13.4542 14.3565 13.2622 14.3565 13.0252V4.87427ZM14.3565 2.72866C14.3565 2.49142 14.1377 2.29944 13.8676 2.29944H11.4234V3.15788H10.4457V2.29944H5.55725V3.15788H4.57956V2.29944H2.13557C1.86549 2.29944 1.64648 2.49142 1.64648 2.72866V4.01569H14.3565V2.72866ZM14.3547 14.3118H1.64468C1.10475 14.3118 0.666992 13.9279 0.666992 13.4538V4.87388V4.01587V2.29962C0.666992 1.82578 1.10475 1.4416 1.64468 1.4416H4.57776V0.583374H5.55545V1.4416H10.4439V0.583374H11.4216V1.4416H14.8435C15.3835 1.4416 15.3324 1.82578 15.3324 2.29962V4.01587V4.87388V13.4538C15.3324 13.9279 14.8946 14.3118 14.3547 14.3118Z" fill="#BDC3C7" />
                    </svg>
                }

            />
        </MuiPickersUtilsProvider>
    )
}

export default translate("translation")(StartDatePicker);