import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import Loading from './components/Loading/Loading';
import LoadingBeforeResponse from './components/LoadingBeforeResponse/LoadingBeforeResponse'
import Home from './components/Home/Home';
import AsanLoginConfirmation from './components/AsanLoginConfirmation/AsanLoginConfirmation';
import AsanJuridicalLoginConfirmation from './components/AsanJuridicalLoginConfirmation/AsanJuridicalLoginConfirmation';
import AsanJuridicalLoginSelectCompany from './components/AsanJuridicalLoginSelectCompany/AsanJuridicalLoginSelectCompany';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Registration from './components/Registration/Registration';
import Dashboard from './components/Dashboard/Dashboard';
import { translate } from 'react-i18next';
import '@material-ui/core/styles';
import NotFoundWithoutLogin from './copms/NotFound/NotFoundWithoutLoign';
import ThechnicalBreak from './copms/NotFound/TechnicalBreak';
import { Helmet } from "react-helmet";
import UnblockUser from './components/UnblockUser/UnblockUser';
import LoginWithAsanIndividualOtp from './components/Login/LoginWithAsanIndividual/LoginWithAsanIndividualOtp';
import LoginWithAsanJuridicalOtp from './components/Login/LoginWithAsanJuridical/LoginWithAsanJuridicalOtp';
import LoginWithMobileIndividualOtp from './components/Login/LoginWithUsrAndPwdIndividual/LoginWithUsrAndPwdIndividualOtp';
import LoginWithMobileJuridicalOtp from './components/Login/LoginWithUsrAndPwdJuridical/LoginWithUsrAndPwdJuridicalOtp';
import CreateTransferInternalOtp from './components/Dashboard/Transfer/Transfers/CreateTransfer/CreateTransferInternal/CreateTransferInternalOtp';
import PlasticCardOtp from './components/Dashboard/Orders/PlasticCardOtp';
import CreditPaymentOtp from './components/Dashboard/Finance/Credit/CreditPayment/CreditPaymentOtp';


class App extends Component {
  state = {
    isAuthed: false,
    isLoading: false,
    isLoadingTransparentBackground: false,
  }
  updateAppState = (name, value) => {
    this.setState({ [name]: value });
  }
  componentWillMount() {
    if (localStorage.getItem("errorExp")) {
      localStorage.removeItem('errorExp')
    }
    if (localStorage.getItem('custId') && localStorage.getItem('fullname') && localStorage.getItem('token')) {
      this.setState({ isAuthed: true });
    }

    document.addEventListener("visibilitychange", () => {
      if (localStorage.getItem('custId') && localStorage.getItem('fullname') && localStorage.getItem('token')) {
        this.setState({ isAuthed: true });
      }
      else {
        this.setState({ isAuthed: false });
      }
    });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousState.isAuthed !== this.state.isAuthed) {
      if (!this.state.isAuthed) {
        //window.location.replace("/login");
      }
    }
  }

  render() {
    let { isAuthed, isLoading, isLoadingTransparentBackground } = this.state;
    let { t, i18n } = this.props;
    return (
      <Router>
        {

          (isLoading) ? <Loading /> : (isLoadingTransparentBackground) ? <LoadingBeforeResponse /> : null
        }
        {
          <Switch>

            <Redirect exact from="/dashboard" to="/dashboard/home" />

            <Redirect exact from='/FriendNotificationToFriend' to="/dashboard/transfer/card-operation/friend-transfer/history/1" />
            <Redirect exact from='/FriendNotificationToMe' to="/dashboard/transfer/card-operation/friend-transfer/history/2" />
            <Redirect exact from='/FriendNotification' to="/dashboard/transfer/card-operation/friend-transfer/history/2" />
            <Redirect exact from="/Notifications" to="/dashboard/notifications" />

            <Redirect exact from="/" to="/login/individual/mobile" />
            <Redirect exact from="/login" to="/login/individual/mobile" />
            <Route path="/login/individual/mobile/unblock-user" render={(routeProps) => <UnblockUser {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route path="/login/juridical/mobile/unblock-user" render={(routeProps) => <UnblockUser {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
          
            <Route path="/login/individual/mobile/forgot-password" render={(routeProps) => <ForgotPassword {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route path="/login/juridical/mobile/forgot-password" render={(routeProps) => <ForgotPassword {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/juridical/mobile" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/individual/asan" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            {/* new */}
            <Route exact path="/login/individual/asan/otp" render={(routeProps) => <LoginWithAsanIndividualOtp {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/juridical/asan/otp" render={(routeProps) => <LoginWithAsanJuridicalOtp {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route path="/dashboard/orders/plastic-card/otp" render={(routeProps) => <PlasticCardOtp {...routeProps} updateAppState={this.updateAppState} />} />
            
            <Route exact path="/dashboard/transfer/transfers/internal/otp" render={(routeProps) => <CreateTransferInternalOtp {...routeProps} updateAppState={this.updateAppState} isAuthed={isAuthed} />} />
            
            <Route exact path="/login/individual/mobile/otp" render={(routeProps) => <LoginWithMobileIndividualOtp {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/juridical/mobile/otp" render={(routeProps) => <LoginWithMobileJuridicalOtp {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            
            <Route exact path="/dashboard/finance/credits/pay/:id/:iban/otp" render={(routeProps) => <CreditPaymentOtp {...routeProps} updateAppState={this.updateAppState} isAuthed={isAuthed} />} />

            <Route exact path="/login/individual/asan/confirmation" render={(routeProps) => <AsanLoginConfirmation {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/individual/mobile" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/juridical/asan" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/juridical/asan/confirmation" render={(routeProps) => <AsanJuridicalLoginConfirmation {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/login/juridical/asan/select-company" render={(routeProps) => <AsanJuridicalLoginSelectCompany {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route path="/registration" render={(routeProps) => <Registration {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/sign-up/not-a-bank-customer" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/sign-up/not-a-bank-customer/otp" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/sign-up/not-a-bank-customer/create-password" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route exact path="/sign-up/not-a-bank-customer/registration/success" render={(routeProps) => <Home {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Redirect exact from="/dashboard" to="/dashboard/home" />
            <Route path="/dashboard" render={(routeProps) => <Dashboard {...routeProps} isAuthed={isAuthed} updateAppState={this.updateAppState} />} />
            <Route path="/technical-break" render={(routeProps) => <ThechnicalBreak  {...routeProps} updateAppState={this.updateAppState} />} />
            <Route render={(routeProps) => <NotFoundWithoutLogin  {...routeProps} updateAppState={this.updateAppState} />} />
            {/* <Route render={(routeProps) => (
              <>
              <h1>{t("welcome")}Bu  səhifə tapılmadı</h1>
              <button onClick={() => i18n.changeLanguage("ru")}>Change</button>
              </>
            
            ) }/> */}
          </Switch>
        }
      </Router>
    );
  }
}

export default translate("translation")(App);