import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { set } from 'date-fns';

const BlueRadioButton = withStyles({
    root: {
        color: "#FFFFFF",
        "& svg": {
            fill: "#5d6169",
            width: "20px",
            height: "20px",
        },
        '&:hover': {
            background: 'transparent',
        },
        '&$checked': {
            "& svg": {
                fill: "#0179D2",
            }
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButton(props) {
    // const [state, setState] = React.useState({
    //     checked: false
    // });

    // React.useEffect(() => {
    //     setState({ checked: props.checked })
    // }, [props.checked])

    const handleChange = (event) => {
        // setState({ ...state, [event.target.name]: event.target.checked });
        
    };

    return (

        <BlueRadioButton
            checked={props.selectedId == props.value}
            onChange={e => props.onChange(e)}
            value={props.value}
            name="checked"
            {...props}
        />

    );
}
