export const getFormatedDate = (date, formatType) => {
  if (date) {
    let formatedDate = {
      day: date.getDate() < 9 ? "0" + date.getDate() : date.getDate(),
      month:
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
      year: date.getFullYear().toString(),
    };
    if (formatedDate)
      switch (formatType) {
        case "-":
          return `${formatedDate.year}-${formatedDate.month}-${formatedDate.day}`;
        case ".":
          return `${formatedDate.year}.${formatedDate.month}.${formatedDate.day}`;
        case "dd.mm.yyyy":
          return `${formatedDate.day}.${formatedDate.month}.${formatedDate.year}`;
        default:
          break;
      }
  }
  return null;
};
