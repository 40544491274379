import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function PaymentModalError(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const close = () => {
        setOpen(false);
        //props.onClose();
    }
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const triggerFunc = (val) => {
        close()
        if (val) {
            props.triggerFunc(val);
        }
    }
    let { t } = props.t;

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    {
                        props.data &&
                        <div className={classes.paper}>
                            <div className="payment-modal-error">
                                <div className="payment-modal-error-left">
                                    <div className="payment-modal-succes-left-head">
                                        <div className="payment-modal-succes-left-head-id">
                                            <span>{props.t('Receipt')} #</span>
                                            <h4>{props.data.receiptNumber}</h4>
                                        </div>
                                        <div className="payment-modal-succes-left-head-status">Ödənilib</div>
                                    </div>
                                    <h2>{props.data.providerName}</h2>
                                    <div className="payment-modal-succes-left-inp">
                                        <label>{props.t('Subscriber')}</label>
                                        <span>{props.data.abonentName}</span>
                                    </div>
                                    {
                                        props.data.abonentCode &&
                                        <div className="payment-modal-succes-left-inp">
                                            <label>{props.t('Subscriber Code')}</label>
                                            <span>{props.data.abonentCode}</span>
                                        </div>
                                    }
                                    <div className="payment-modal-succes-left-inp">
                                        <label>{props.t('Date of operation')}</label>
                                        <span>{props.data.operationDate}</span>
                                    </div>
                                    {
                                        props.data.cardnumber ?
                                            <div className="payment-modal-succes-left-inp">
                                                <label>{props.t('card number')}</label>
                                                <span>{props.data.cardnumber}</span>
                                            </div>
                                            :
                                            <div className="payment-modal-succes-left-inp">
                                                <label>{props.t('fund')}</label>
                                                <span>{props.t('Payment with the stars')}</span>
                                            </div>
                                    }
                                    <div className="payment-modal-succes-left-inp">
                                        <label>{props.t('Category')}</label>
                                        <span>{props.data.category}</span>
                                    </div>
                                    <div className="payment-modal-succes-left-middle">
                                        <div>
                                            <div className="payment-modal-succes-left-middle-amount">
                                                <span className="dashboard-last-operations-section-item-amount-whole-part">{props.data.commission.part1}</span>
                                                <span className="dashboard-last-operations-section-item-amount-fractional-part">{props.data.commission.part2}</span>
                                                <span className="dashboard-last-operations-section-item-amount-currency">AZN</span>
                                            </div>
                                            <span className="payment-modal-succes-left-middle-span">{props.t('Commission')}</span>
                                        </div>
                                        <div>
                                            <div className="payment-modal-succes-left-middle-amount" style={{ textAlign: 'right' }}>
                                                <span className="dashboard-last-operations-section-item-amount-whole-part">{props.data.amount.part1}</span>
                                                <span className="dashboard-last-operations-section-item-amount-fractional-part">{props.data.amount.part2}</span>
                                                <span className="dashboard-last-operations-section-item-amount-currency">AZN</span>
                                            </div>
                                            <span className="payment-modal-succes-left-middle-span">{props.t('Payment amount')}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="payment-modal-error-right" style={{ justifyContent: 'flex-start' }}>
                                    <button style={{ alignSelf: 'flex-end', alignContent: 'flex-start', marginTop: '20px', marginRight: '20px' }} onClick={() => close()}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                        </svg>
                                    </button>
                                    <div style={{ justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z" fill="#FA312C" fill-opacity="0.05" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M62.2463 107.762C87.3841 107.762 107.762 87.3838 107.762 62.246C107.762 37.1083 87.3841 16.7302 62.2463 16.7302C37.1086 16.7302 16.7305 37.1083 16.7305 62.246C16.7305 87.3838 37.1086 107.762 62.2463 107.762Z" fill="#E54143" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.4144 62.3229L77.5509 71.4594C78.6721 72.5815 78.6721 74.3992 77.5509 75.5204L75.5203 77.5509C74.3991 78.6721 72.5804 78.6721 71.4593 77.5509L62.3228 68.4145L53.1864 77.5509C52.0652 78.6721 50.2465 78.6721 49.1254 77.5509L47.0948 75.5204C45.9736 74.3983 45.9736 72.5805 47.0948 71.4594L56.2313 62.3229L47.0948 53.1864C45.9736 52.0643 45.9736 50.2466 47.0948 49.1254L49.1254 47.0949C50.2474 45.9737 52.0652 45.9737 53.1864 47.0949L62.3228 56.2313L71.4593 47.0949C72.5814 45.9737 74.3991 45.9737 75.5203 47.0949L77.5509 49.1254C78.6721 50.2475 78.6721 52.0653 77.5509 53.1864L68.4144 62.3229Z" fill="white" />
                                        </svg>

                                        <div>
                                            <h2>{props.t('an error occurred')}</h2>
                                            <p>{props.t('Please check again')}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(PaymentModalError);