import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { set } from 'date-fns';

const BlueCheckbox = withStyles({
    root: {
        color: "#FFFFFF",
        "& svg": {
            fill: "#5d6169",
            width: "30px",
            height: "30px",
        },
        '&:hover': {
            background: 'transparent',
        },
        '&$disabled' : {
            "& svg": {
                fill: "#8490974d",
            },
            '&$checked': {
                "& svg": {
                    fill: '#849097',
                }
            },
        },
        '&$checked': {
            "& svg": {
                fill: "#0179D2",
            }
        },
    },
    checked: {},
    disabled: {}

})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels(props) {

    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <BlueCheckbox
                        {...props}
                        checked={props.checked}
                        onChange={props.onChange}
                        name="checked"
                    />
                }
            />
        </FormGroup>
    );
}
