import mobileIcon from './img/new-icons/mob.svg';
import homeIcon from './img/new-icons/utl.svg';
import tvIcon from './img/new-icons/tv.svg';
import phoneIcon from './img/new-icons/tel.svg';
import wifiIcon from './img/new-icons/net.svg';
import mortgageIcon from './img/new-icons/crd.svg';
import insuranceIcon from './img/new-icons/ins.svg';
import educationIcon from './img/new-icons/edc.svg';
import donateIcon from './img/new-icons/crt.svg';
import deliveryIcon from './img/new-icons/dlv.svg';
import storesIcon from './img/new-icons/mgz.svg';
import entertainmentIcon from './img/new-icons/etr.svg';
import travelIcon from './img/new-icons/trs.svg';
import regionIcon from './img/new-icons/rgn.svg';
import othersIcon from './img/new-icons/oth.svg';
import percentIcon from './img/percent_icon.svg';
import parkingIcon from './img/new-icons/prk.svg';
import sackIcon from './img/sack.svg';

const lists = [
    {
        categoryId: 582,
        iconName: mobileIcon,
        categoryName: "Mobile"
    },
    {
        categoryId: 1172,
        iconName: homeIcon,
        categoryName: "UTILITY"
    },
    {
        categoryId: 1165,
        iconName: sackIcon,
        categoryName: "BUDGET"
    },
    {
        categoryId: 1173,
        iconName: tvIcon,
        categoryName: "TV"
    },
    {
        categoryId: 1174,
        iconName: wifiIcon,
        categoryName: "INTERNET"
    },
    {
        categoryId: 1170,
        iconName: insuranceIcon,
        categoryName: "INSURANCE"
    },
    {
        categoryId: 1169,
        iconName: educationIcon,
        categoryName: "EDUCATION"
    },
    {
        categoryId: 1161,
        iconName: donateIcon,
        categoryName: "DONATION"
    },
    {
        categoryId: 1166,
        iconName: phoneIcon,
        categoryName: "TELECOM"
    },
    {
        categoryId: 1167,
        iconName: mortgageIcon,
        categoryName: "credit"
    },
    {
        categoryId: 1167,
        iconName: mortgageIcon,
        categoryName: "MORTGAGE"
    },
    {
        categoryId: 1168,
        iconName: othersIcon,
        categoryName: "OTHER"
    },
    {
        categoryId: 1168,
        iconName: deliveryIcon,
        categoryName: "DELIVERY"
    }, 
    {
        categoryId: 1168,
        iconName: storesIcon,
        categoryName: "STORE_CHAINS"
    },    
    {
        categoryId: 1168,
        iconName: entertainmentIcon,
        categoryName: "ENTERTAINMENT"
    },
    {
        categoryId: 1168,
        iconName: travelIcon,
        categoryName: "TOURISM"
    },
    {
        categoryId: 1168,
        iconName: regionIcon,
        categoryName: "NAKHCHIVAN"
    },
    {
        categoryId: 1267,
        iconName: parkingIcon,
        categoryName: "Parking",
    },
    {
        categoryId: 1169,
        iconName: percentIcon,
        categoryName: null
    },
]


function categoryFilter(name) {
    return lists.find(elem => elem.categoryName === name)?lists.find(elem => elem.categoryName === name).iconName : "";
}

export default categoryFilter;