import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import $ from 'jquery';
import { translate } from 'react-i18next';

import { request } from '../../config';

import './scss/style.scss';
import './scss/loading.css'; 


function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function SalaryOperationModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState(true);

    const [loanPaymentPlan, setLoanPaymentPlan] = React.useState([]);
    const close = () => {
        setOpen(false);
        setLoading(true);
        props.onClose();
    }

    useEffect(() => {
        setOpen(props.open)
        
        if (props.open === true) {
            props.updateAppState('isLoadingTransparentBackground', true);
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let getAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                excelId: props.id,
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            } : {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    excelId: props.id,
                    lang: localStorage.getItem('i18nextLng').toUpperCase()
                }

            fetch(request("salary/getExcelDataList", getAccountListForCustomerRequestBody))
                .then(res => res.text())
                .then((getAccountListForCustomerResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                    if (getAccountListForCustomerResponse.status.statusCode !== 1) {
                        setLoanPaymentPlan([]);
                        props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        setLoanPaymentPlan(getAccountListForCustomerResponse.excelData);
                        props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    setLoading(false);
                },
                    (error) => {
                        props.history.push("/technical-break");
                    });
        }
    }, [props.open])

    const downloadPDF = (id) => {
        props.updateAppState('isLoadingTransparentBackground', true);
        let getAsanDocByOperIdRequestBody = {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: id
        }
        fetch(request("util/getPaymentDocByOperId", getAsanDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getAsanDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAsanDocByOperIdResponse = JSON.parse(getAsanDocByOperIdResponseJSON);
                if (getAsanDocByOperIdResponse.status.statusCode !== 1) {
                    if (getAsanDocByOperIdResponse.status.statusCode === 200) {
                        props.logout();
                    } else {
                        props.updateAppState('isLoadingTransparentBackground', false);
                        props.alertState(true, 'error', props.t('an error occurred'), props.t(getAsanDocByOperIdResponse.status.statusCode));
                    }
                }
                else {
                    props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getAsanDocByOperIdResponse.bytes); //data is the base64 encoded string

                    saveFile("salary_employee_" + id + ".pdf", "application/pdf", arrrayBuffer)
                }
            },
                (error) => {
                    props.history.push("/technical-break");
                });
    }


    let signCount = (JSON.parse(localStorage.getItem('custObject'))).company.signCount;
    return (
        <div>
            {
                !loading &&
                <Modal
                    open={open}
                    onClose={close}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    classes={classes.modal}
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description">
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <div className="credit-payment">
                                <div className="credit-payment-head">
                                    <h2>{props.t("Employees list")}</h2>
                                    <button onClick={close}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="credit-payment-container" style={{ width: '100%' }}>
                                    <table className="credit-payment-table">
                                        <tr>
                                            <th style={{ width: '45%' }}>S.A.A</th>
                                            <th style={{ width: '30%' }}>{props.t("STATUS")}</th>
                                            <th style={{ width: '18%' }}>{props.t("AMOUNT")}</th>
                                            <th style={{ width: '7%' }}></th>
                                        </tr>
                                        {
                                            loanPaymentPlan.length > 0 &&
                                            loanPaymentPlan.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{item.fullname}</td>
                                                        <td>
                                                            {
                                                                item.operStateId === 1 && signCount === 1 && props.t('authorization')
                                                            }
                                                            {
                                                                item.operStateId === 1 && signCount === 2 && props.t('2nd authorization')
                                                            }
                                                            {
                                                                item.operStateId === 2 && props.t('1st authorization')
                                                            }
                                                            {
                                                                item.operStateId === 3 && props.t('Confirmation is expected')
                                                            }
                                                            {
                                                                item.operStateId === 4 && props.t('Approved')
                                                            }
                                                            {
                                                                item.operStateId === 5 && props.t('refusal')
                                                            }
                                                        </td>
                                                        <td>{item.amount && item.amount.toFixed(2)} AZN</td>
                                                        <td>
                                                            {
                                                                <button onClick={() => downloadPDF(item.operId)}>
                                                                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#586268" />
                                                                    </svg>
                                                                </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }


                                    </table>
                                </div>

                            </div>
                        </div>
                    </Fade>
                </Modal>
            }
        </div>
    );
}

export default translate("translation")(SalaryOperationModal);