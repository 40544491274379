import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Route, Switch } from "react-router";

import PINSettingsActions from "./PINSettingsActions/PINSettingsActions";
import YesNoModal from "../../../../copms/Modal/YesNoModal";
import PINInstruction from "./PINInstruction/PINInstruction";
import Loading from "../../Loading/Loading";
import PinUnblockSuccess from "../../../../copms/Modal/PinUnblockSuccess";
import { request } from "../../../../config";

const PINSettings = ({ translate, logout, updateAppState, alertState }) => {
  const { id } = useParams();

  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const [cardID, setCardID] = useState(id);
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState();
  const [successModalData, setSuccessModalData] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [unblockPinModalOpen, setUnblockPinModalOpen] = useState(false);

  let history = useHistory();

  const openModal = () => {
    setOpenYesNoModal(true);
  };

  const closeModal = () => {
    setOpenYesNoModal(false);
  };

  const getCardById = () => {
    setIsLoading(true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let getCardByIdForCustomerRequestBody =
      customerType === "Individual"
        ? {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            cardId: id,
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
          }
        : {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            cardId: id,
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
          };

    fetch(
      request(
        "card/getCardByIdFor" + customerType,
        getCardByIdForCustomerRequestBody
      )
    )
      .then((res) => res.text())
      .then(
        (getCardByIdForCustomerResponseJSON) => {
          const JSON = require("true-json-bigint");
          let getCardByIdForCustomerResponse = JSON.parse(
            getCardByIdForCustomerResponseJSON
          );
          if (getCardByIdForCustomerResponse.status.statusCode !== 1) {
            if (getCardByIdForCustomerResponse.status.statusCode === 200) {
              logout();
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            setCardData(getCardByIdForCustomerResponse);
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };

  const unblockPIN = () => {
    updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";
    let cardPinCodeUnblockRequestBody =
      customerType == "Juridical"
        ? {
            custId: localStorage.getItem("custId"),
            compId: localStorage.getItem("compId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            cardNumber: cardData?.cardNumber,
            cardId: cardData?.cardId,
            currency: cardData?.currency,
          }
        : {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            cardNumber: cardData.cardNumber,
            cardId: cardData.cardId,
            currency: cardData.currency,
          };
    fetch(request("card/cardPinCodeUnblock", cardPinCodeUnblockRequestBody))
      .then((res) => res.text())
      .then((cardPinCodeUnblockResponseJSON) => {
        const JSON = require("true-json-bigint");
        let cardPinCodeUnblockResponse = JSON.parse(
          cardPinCodeUnblockResponseJSON
        );
        if (cardPinCodeUnblockResponse.status.statusCode === 1) {
          // =========FEE=========
          let organizedFeeAmount;
          if (cardPinCodeUnblockResponse.feeAmount.toString() === "0") {
            organizedFeeAmount = {
              wholePart: "0",
              fractionalPart: "00",
            };
          } else {
            if (
              cardPinCodeUnblockResponse.feeAmount.toString().indexOf(".") !==
              -1
            ) {
              let currentBalanceArray = cardPinCodeUnblockResponse.feeAmount
                .toString()
                .split(".");
              organizedFeeAmount = {
                wholePart: currentBalanceArray[0],
                fractionalPart:
                  currentBalanceArray[1].length === 1
                    ? currentBalanceArray[1] + "0"
                    : currentBalanceArray[1],
              };
            } else {
              organizedFeeAmount = {
                wholePart: cardPinCodeUnblockResponse.feeAmount.toString(),
                fractionalPart: "00",
              };
            }
          }
          let data = {
            cardNumber: cardData?.organizedCardNumber,
            rrn: cardPinCodeUnblockResponse.rrn,
            feeAmount: organizedFeeAmount,
            billingDate: cardPinCodeUnblockResponse.billingDate,
            pinUnblockAmount: cardPinCodeUnblockResponse.feeAmount,
          };
          setSuccessModal(true);
          setSuccessModalData(data);
        } else {
          if (cardPinCodeUnblockResponse.status.statusCode === 200) {
            logout();
          } else {
            alertState(
              true,
              "error",
              translate("an error occurred"),
              translate(cardPinCodeUnblockResponse.status.statusCode)
            );
          }
        }
        updateAppState("isLoadingTransparentBackground", false);
      });
  };

  // const unblockConfirm = (val) => {
  //   if (val) {
  //     unblockPIN();
  //   }
  // };
  // console.log("173pinUnblockAmount---", cardData?.pinUnblockAmount)
  const unblockPinHandler = () => {
 
    // if (cardData?.pinUnblockAmount) {
      setUnblockPinModalOpen(true);
    // } else {
    //   unblockPIN();
    // }
  };

  const setNewPINHandler = () => {
    updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";

    let cardSetNewPinRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
    };

    if (customerType == "Juridical") {
      cardSetNewPinRequestBody.compId = localStorage.getItem("compId");
    }
    fetch(request("card/getPinDeliveryWeb", cardSetNewPinRequestBody))
      .then((res) => res.text())
      .then((cardSetNewPinResponseJSON) => {
        const JSON = require("true-json-bigint");
        let cardSetNewPinResponse = JSON.parse(cardSetNewPinResponseJSON);

        if (cardSetNewPinResponse.status.statusCode === 1) {
          localStorage.setItem("iframe", cardSetNewPinResponse?.pinDeliveryUrl);
          history.push("/dashboard/finance/cards/pin/instructions/set-new-pin");
        } else {
          if (cardSetNewPinResponse.status.statusCode === 200) {
            logout();
          } else {
            alertState(
              true,
              "error",
              translate("an error occurred"),
              translate(cardSetNewPinResponse.status.statusCode)
            );
          }
        }
        updateAppState("isLoadingTransparentBackground", false);
      });
  };

  const updateCardPINHandler = () => {
    updateAppState("isLoadingTransparentBackground", true);
    let customerType =
      localStorage.getItem("compId") === null ? "Individual" : "Juridical";

    let cardPinChangeRequestBody = {
      custId: localStorage.getItem("custId"),
      token: localStorage.getItem("token"),
      cardId: cardID,
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
      currency: cardData?.currency,
    };

    if (customerType == "Juridical") {
      cardPinChangeRequestBody.compId = localStorage.getItem("compId");
    }
    fetch(request("card/cardPinChange", cardPinChangeRequestBody))
      .then((res) => res.text())
      .then((cardPinChangeResponseJSON) => {
        const JSON = require("true-json-bigint");
        let cardPinChangeResponse = JSON.parse(cardPinChangeResponseJSON);

        if (cardPinChangeResponse.status.statusCode === 1) {
          localStorage.setItem("iframe", cardPinChangeResponse?.pinDeliveryUrl);
          history.push("/dashboard/finance/cards/pin/instructions/update-pin");
        } else {
          if (cardPinChangeResponse.status.statusCode === 200) {
            logout();
          } else {
            alertState(
              true,
              "error",
              translate("an error occurred"),
              translate(cardPinChangeResponse.status.statusCode)
            );
          }
        }
        updateAppState("isLoadingTransparentBackground", false);
      });
  };

  useEffect(() => {
    id && getCardById();
  }, [id]);

  return isLoading ? (
    <div className="dashboard-container-main-section">
      <Loading />
    </div>
  ) : (
    <section className="ibank-pin-settings">
      <div className="dashboard-header-route">
        <div className="dashboard-header-route-left">
          <button onClick={() => history.goBack()}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z"
                fill="#2E3131"
              />
            </svg>
          </button>
          <h2>{translate("pin_settings")}</h2>
        </div>
      </div>
      <div className="dashboard-container-main-section">
        <div className="dashboard-finance-section-cards-section-table-section">
          <Switch>
            <Route
              exact
              path="/dashboard/finance/cards/pin/settings/:id"
              render={(routeProps) => (
                <PINSettingsActions
                  {...routeProps}
                  {...{
                    openModal,
                    translate,
                    setCardID,
                    unblockPinHandler,
                    setNewPINHandler,
                    // setUnblockPinModalOpen,
                    // unblockPIN
                  }}
                  pinUnblockAmount={cardData?.pinUnblockAmount}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/finance/cards/pin/instructions/:instructionType"
              render={(routeProps) => (
                <PINInstruction {...routeProps} {...{ translate }} />
              )}
            />
          </Switch>
        </div>
      </div>
      <YesNoModal
        onClose={() => closeModal()}
        title={translate(
          "Attention! Dear customer, when you re-create your pin code, your current pin code will be revoked. Do you want to continue?"
        )}
        body={`${translate("Service fee for the operation")} ${
          cardData?.pinChangeAmount
        } AZN`}
        open={openYesNoModal}
        triggerFunc={() => updateCardPINHandler()}
      />
      <PinUnblockSuccess
        data={successModalData}
        open={successModal}
        close={() => {
          setSuccessModal(false);
        }}
      />
      <YesNoModal
        onClose={() => setUnblockPinModalOpen(false)}
        title={translate("Unblock pin content", {
          pinUnblockAmount: cardData?.pinUnblockAmount,
        })}
        open={unblockPinModalOpen}
        triggerFunc={() => unblockPIN()}
      />
    </section>
  );
};

export default PINSettings;
