import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import ChangeAccountModal from './../../components/Dashboard/ChangeAccountModal/ChangeAccountModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'inline-block',
    maxHeight: '100vh',
    overflow: 'auto'
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [feedbackModal, setFeedbackModal] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  
  const close = () => {
    setOpen(false);
    setFeedbackModal(false);
    setErrorModal(false);
    props.updateOpenModal(false);
    if(props.showAccounts !== undefined && feedbackModal === true) {
      props.showAccounts();
    }
    if(props.showAccount !== undefined && feedbackModal === true) {
      props.showAccount();
    }
  }
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
      <Modal
        open={open}
        onClose={close}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        classes={classes.modal}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description">
        <Fade in={open}>
          <div className={classes.paper}>
            <ChangeAccountModal close={close} updateAppState={props.updateAppState} modalAccountObj={props.modalAccountObj} feedbackModal={feedbackModal} setFeedbackModal={setFeedbackModal} errorModal={errorModal} setErrorModal={setErrorModal} showAccounts={props.showAccounts} />
          </div> 
        </Fade>
      </Modal>
  );
}