import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Link, Route, Switch, NavLink } from 'react-router-dom';
import userIcon from './../../img/user-icon-form.svg';
import envelopeIcon from './../../img/envelope-icon-form.svg';
import pen from '../../img/pen.svg';
import customer from '../../img/customer.svg';
import notcustomer from '../../img/notcustomer.svg';


import phoneIcon from './../../img/phone-icon-form.svg';
import spinner from './../../img/spinner.svg';
import { request } from './../../../../config';
import { translate } from 'react-i18next';

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custTypeId: '',
            clientType: '',
            isLoading: false,
            messageStatus: 'info',
            // name: null,
            // surname: null,
            email: null,
            secretWord: null,
            mobile: null
        }
    }

    // handleButtonClick = () => {
    //     // this.props.updateAppState('isLoadingTransparentBackground', true);
    //     // this.setState({ isLoading: true });
    //     // this.setState({
    //     //   divVisibility: {
    //     //     div1: true,
    //     //     div2: true,
    //     //     div3: true,
    //     //     div4: true,
    //     //   },
    //     //   buttonVisibility: false,

    //     // //   name: localStorage.getItem('compId') === null ? localStorage.getItem('fullname').split(' ')[0] : JSON.parse(localStorage.getItem('custObject')).company.name,
    //     // //   surname: localStorage.getItem('compId') === null ? localStorage.getItem('fullname').split(' ')[1] : null,
    //     // //   email: this.state.email,
    //     // //   mobile: this.state.mobile,
    //     // });
    //     window.location.reload();
    //     // this.setState({ email, mobile });
    //     // this.setState({ isLoading: false });
    //     //             this.props.updateAppState('isLoadingTransparentBackground', false);
    // };

    clearFeedback = () => {
        this.setState({ messageStatus: 'info' });
    }
    // changeGeneralData = (event) => {
    //     event.preventDefault();
    //     this.props.updateAppState('isLoadingTransparentBackground', true);
    //     this.setState({ isLoading: true });
    //     let getPhoneNumber = (number) => {
    //         ['+', '-', '(', ')', ' '].forEach(char => {
    //             while (number.indexOf(char) !== -1) {
    //                 number = number.slice(0, number.indexOf(char)) + number.slice(number.indexOf(char) + 1, number.length);
    //             }
    //         });
    //         return number;
    //     }
    //     let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
    //     let updateCustomerInfoRequestBody = customerType === 'Juridical' ? {
    //         custId: localStorage.getItem('custId'),
    //         compId: localStorage.getItem('compId'),
    //         token: localStorage.getItem('token'),
    //         mobile: getPhoneNumber(this.mobile.value).trim() === "" ? null : getPhoneNumber(this.mobile.value),
    //         email: this.email.value.trim() === "" ? null : this.email.value,
    //         lang: localStorage.getItem('i18nextLng').toUpperCase()
    //     } : {
    //         // custId: localStorage.getItem('custId'),
    //         // --------------------------
    //         custInfoType: "EMAIL",
    //         token: localStorage.getItem('token'),
    //         mobile: getPhoneNumber(this.mobile.value).trim() === "" ? null : getPhoneNumber(this.mobile.value),
    //         email: this.email.value.trim() === "" ? null : this.email.value,
    //         // secretWord: "11111",
    //         lang: localStorage.getItem('i18nextLng').toUpperCase()
    //         // --------------------------
    //     }

    //     fetch(request("setting/updateCustomerInfo" + customerType, updateCustomerInfoRequestBody))
    //         .then(res => res.text())
    //         .then((getCardListForCustomerResponseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
    //             if (getCardListForCustomerResponse.status.statusCode !== 1) {
    //                 if (getCardListForCustomerResponse.status.statusCode === 200) {
    //                     this.props.logout();
    //                 } else {
    //                     this.props.updateAppState('isLoadingTransparentBackground', false);
    //                     this.setState({ isLoading: false });
    //                     this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
    //                 }
    //             }
    //             else {
    //                 this.setState({ mobile: this.mobile.value, email: this.email.value });
    //                 let changedObj = JSON.parse(localStorage.getItem('custObject'));
    //                 if (changedObj.customer === undefined) {
    //                     changedObj.email = this.email.value;
    //                     changedObj.mobile = getPhoneNumber(this.mobile.value);
    //                 }
    //                 else {
    //                     changedObj.customer.email = this.email.value;
    //                     changedObj.customer.mobile = getPhoneNumber(this.mobile.value);
    //                 }
    //                 localStorage.setItem('custObject', JSON.stringify(changedObj));
    //                 this.setState({ isLoading: false });
    //                 this.props.updateAppState('isLoadingTransparentBackground', false);
    //                 this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('information changed successfully'));
    //             }
    //         },
    //             (error) => {
    //                 this.props.history.push("/technical-break");
    //             });
    // }

    componentWillMount() {
        if (localStorage.getItem('custObject')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ secretWord: localStorage.getItem('secretWord') });
            this.setState({ custTypeId: localStorage.getItem('custTypeId') });
            this.setState({ bankCustomerType: localStorage.getItem('bankCustomerType') });
            this.setState({ customerType: localStorage.getItem('compId') === null ? 'Individual' : 'Juridical' });

            // this.setState({ name: localStorage.getItem('compId') === null ? localStorage.getItem('fullname').split(' ')[0] : JSON.parse(localStorage.getItem('custObject')).company.name });
            // this.setState({ surname: localStorage.getItem('compId') === null && localStorage.getItem('fullname').split(' ')[1] });
            // this.setState({ surname: localStorage.getItem('compId') === null && localStorage.getItem('fullname').split(' ')[1] });
            let email = (custObject.customer !== undefined) ? custObject.customer.email : custObject.email;
            let mobile = (custObject.customer !== undefined) ? custObject.customer.mobile : custObject.mobile;
            if (mobile !== null && mobile !== undefined) {
                mobile = '+' + mobile.slice(0, 3) + ' ( ' + mobile.slice(3, 5) + ' ) ' + mobile.slice(5, 8) + '-' + mobile.slice(8, 10) + '-' + mobile.slice(10, 12);
            }

            this.setState({ email, mobile });
        }

    }


    componentDidMount() {
        this.props.updateSettingsState('settingsTitle', this.props.t('general settings'));
        this.props.updateSettingsState('feedback', this.props.t('general settings info'));

        if (this.state.bankCustomerType == 1) {
            if (this.state.custTypeId == 1) {
                this.setState({ clientType: this.props.t('TuranBank (individual)') })
                // this.setState({ customerType: "TuranBank (fiziki)" })
            }
            else if (this.state.custTypeId == 2) {
                this.setState({ clientType: this.props.t('Individual owner') })
            }
            else if (this.state.custTypeId == 3) {
                this.setState({ clientType:  this.props.t("TuranBank (juridical)") })
            }
        }
        else if (this.state.bankCustomerType == 2) {
            this.setState({ clientType:  this.props.t("Non TuranBank customer") })
        }
    }

    render() {
        let { messageStatus, isLoading, name, customerType, bankCustomerType, surname, email, phoneKey, clientType, secretWord, mobile, divVisibility, buttonVisibility, keywordVisibility, updateAppState, logout, alertState } = this.state;
        let { t } = this.props;
        // let feedbackMessage = {
        //     info: this.props.t('enter a new one to change your phone number'),
        //     success: this.props.t('your phone number has been successfully changed'),
        //     error: this.props.t('an error occurred')
        // };
        return (
            <div className="dashboard-settings-section-general">
                <form className="dashboard-settings-section-general-form">
                    <div className="dashboard-settings-section-general-form-group">
                        <label className="dashboard-settings-section-general-form-group-label">{t('client-type')}</label>
                        <div className="dashboard-settings-section-general-form-group-box">
                            <input type="email" className="dashboard-settings-section-general-form-group-box-input" readOnly ref={(input) => { this.clientType = input }} placeholder="Client type" defaultValue={clientType} onChange={this.clearFeedback} />
                            <div className="dashboard-settings-section-general-form-group-box-icon">
                                {bankCustomerType == 1 ?
                                    <img src={customer} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img__phone" alt="" /> :
                                    <img src={notcustomer} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img__phone" alt="" />
                                }
                            </div>

                        </div>
                    </div>
                    {customerType == "Individual" && bankCustomerType != 2 ?
                        <NavLink exact={customerType === "Individual"} to="/dashboard/settings/general/mobile" activeClassName="dashboard-settings-section-navbar-section-item--active" className="dashboard-settings-section-navbar-section-item p-0">
                            <div className="dashboard-settings-section-general-form-group">
                                <label className="dashboard-settings-section-general-form-group-label">{t('phone number')}</label>
                                <div className="dashboard-settings-section-general-form-group-box">
                                    <InputMask readOnly mask="+\9\9\4 ( 99 ) 999-99-99" className="dashboard-settings-section-general-form-group-box-input" ref={(input) => { this.mobile = input }} placeholder="+994 (__) ___-__-__" defaultValue={mobile} onChange={this.clearFeedback} />

                                    <div className="dashboard-settings-section-general-form-group-box-icon">
                                        <img src={pen} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img__phone" alt="" />
                                    </div>
                                </div>
                            </div>
                        </NavLink> :
                        <div className="dashboard-settings-section-general-form-group">
                            <label className="dashboard-settings-section-general-form-group-label">{t('phone number')}</label>
                            <div className="dashboard-settings-section-general-form-group-box">
                                <InputMask readOnly mask="+\9\9\4 ( 99 ) 999-99-99" className="dashboard-settings-section-general-form-group-box-input" ref={(input) => { this.mobile = input }} placeholder="+994 (__) ___-__-__" defaultValue={mobile} onChange={this.clearFeedback} />
                            </div>
                        </div>
                    }

                    {bankCustomerType != 2 ?
                        <NavLink exact to="/dashboard/settings/general/email" activeClassName="dashboard-settings-section-navbar-section-item--active" className="dashboard-settings-section-navbar-section-item p-0">
                            <div className="dashboard-settings-section-general-form-group">
                                <label className="dashboard-settings-section-general-form-group-label">{t('e-mail')}</label>
                                <div className="dashboard-settings-section-general-form-group-box">
                                    <input type="email" readOnly className="dashboard-settings-section-general-form-group-box-input" ref={(input) => { this.email = input }} placeholder="E-mail" defaultValue={email} onChange={this.clearFeedback} />
                                    <div className="dashboard-settings-section-general-form-group-box-icon">
                                        <img src={pen} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img" alt="" />
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                        :
                        <div className="dashboard-settings-section-general-form-group">
                            <label className="dashboard-settings-section-general-form-group-label">{t('e-mail')}</label>
                            <div className="dashboard-settings-section-general-form-group-box">
                                <input type="email" readOnly className="dashboard-settings-section-general-form-group-box-input" ref={(input) => { this.email = input }} placeholder="E-mail" defaultValue={email} onChange={this.clearFeedback} />

                            </div>
                        </div>
                    }

                    {customerType == "Individual" && bankCustomerType != 2 ?
                        <NavLink exact to="/dashboard/settings/general/keyword" activeClassName="dashboard-settings-section-navbar-section-item--active" className="dashboard-settings-section-navbar-section-item p-0">
                            <div className="dashboard-settings-section-general-form-group">
                                <label className="dashboard-settings-section-general-form-group-label">{t('keyword')}</label>
                                <div className="dashboard-settings-section-general-form-group-box">
                                    <input type="text" readOnly className="dashboard-settings-section-general-form-group-box-input" ref={(input) => { this.secretWord = input }} placeholder="XXXX" defaultValue={'XXXX'} onChange={this.clearFeedback} />
                                    <div className="dashboard-settings-section-general-form-group-box-icon">
                                        <img src={pen} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img" alt="" />
                                    </div>
                                </div>
                            </div>
                        </NavLink> : null}

                </form>
            </div>
        )
    }
}



export default translate("translation")(GeneralSettings);