import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ipsIcon from './../../img/ips_home.svg';
import cardIcon from './../../img/card-icon.png';
import { request, ipsRequest } from './../../../../config';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import ConfirmModal from './../../../../copms/Modal/ConfirmModal';
import { translate } from 'react-i18next';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';
import { statusCodes } from '../../../../helpers/statusCodes';

class Main extends Component {

    state = {
        open: false,
        isLoading: false
    }

    checkRegistration() {
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let individualRegistrationRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                
            }

        fetch(ipsRequest("checkCustomerRegistration", individualRegistrationRequestBody))
            .then(res => res.text())
            .then((individualRegistrationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let individualRegistrationResponse = JSON.parse(individualRegistrationResponseJSON);
                if (individualRegistrationResponse.status.statusCode !== 1) {
                    if (individualRegistrationResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        individualRegistrationResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION &&
                        this.props.alertState(
                            true, 
                            'warning', 
                            this.props.t('Information'), 
                            this.props.t(individualRegistrationResponse.status.statusCode),
                            null, 
                            true
                        );
                        
                    }
                }
                else {
                    this.props.history.push('/dashboard/transfer/ips/home');
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount() {
        this.checkRegistration();
    }
    registration() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'individualRegistration' : 'legalRegistration';
        let mobile = '0';
        if(customerType === 'legalRegistration') {
            mobile = JSON.parse(localStorage.getItem('custObject')).customer.mobile.toString();
            if(mobile[0] == '0') {
                mobile = mobile.substring(1);
            }
            mobile = '994' + mobile.replaceAll('-', '').replaceAll('+', '').replaceAll('9940', '').replaceAll('994', '');
        }
        let individualRegistrationRequestBody = customerType == 'legalRegistration' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
            mobile: mobile
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                
            }

        fetch(ipsRequest("" + customerType, individualRegistrationRequestBody))
            .then(res => res.text())
            .then((individualRegistrationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let individualRegistrationResponse = JSON.parse(individualRegistrationResponseJSON);
                if (individualRegistrationResponse.status.statusCode !== 1) {
                    if (individualRegistrationResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        individualRegistrationResponse.status.statusCode === statusCodes.ACCESS_DENIED_OPERATION ? 
                        this.props.alertState(true, 'warning', this.props.t('Information'), this.props.t(individualRegistrationResponse.status.statusCode)) :
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(individualRegistrationResponse.status.statusCode))
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.history.push('/dashboard/transfer/ips/registration')
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { open, isLoading } = this.state;
        let { updateAppState, t } = this.props;
        return (
            <Fragment>
                <ConfirmModal triggerFunc={val => this.registration()} open={open} onClose={() => this.setState({ open: false })} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Instant payments')}</h2>
                    </div>
                </div>
                <div className="dashboard-container-main-section">
                    <div className="dashboard-finance-section-invisible-cards-section">
                        <img src={ipsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                        <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                            <p className="dashboard-finance-section-invisible-cards-section-text">{t('You can make quick payments without registering with the Instant Payment System.')}</p>
                        </div>
                        <div style={{ flexDirection: 'row' }}>
                            <Link to="/dashboard/transfer/ips/pay/unregistration" className="dashboard-finance-section-invisible-cards-section-button">{t('REGISTRATION PAYMENT')}</Link>
                            <button onClick={() => this.setState({ open: true })} style={{ marginLeft: '24px' }} className="dashboard-finance-section-invisible-cards-section-button">{t('REGISTER')}</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default translate("translation")(Main);