import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { set } from 'date-fns';

const BlueRadioButton = withStyles({
    root: {
        paddingLeft: 0,
        color: "#FFFFFF",
        "& svg": {
            fill: "none",
            width: "22px",
            height: "22px",
            borderRadius: "50%",
            border: "1px solid #a6b0bf",
            transition: '.2s',
        },
        '&:hover': {
            background: 'transparent',
        },
        '&$disabled': {
            "& svg": {
                fill: "none",
                width: "22px",
                height: "22px",
                borderRadius: "50%",
                border: "1px solid #a6b0bf",
            },
            '&$checked': {
                "& svg": {
                    width: '22px',
                    height: '22px',
                    fill: 'none',
                    border: '6px solid rgba(1, 121, 210, .5)'
                }
            },
        },
        '&$checked': {
            "& svg": {
                width: '22px',
                height: '22px',
                fill: 'none',
                border: '6px solid #0179D2'
            }
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButton(props) {

    return (

        <BlueRadioButton
            checked={props.selectedId == props.value}
            onChange={e => props.onChange(e)}
            value={props.value}
            name="checked"
            {...props}
        />

    );
}
