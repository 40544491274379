import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import RouteHeader from "../../../../copms/RouteHeader/RouteHeader";
import DigitalCardInfo from "./DigitalCardInfo/DigitalCardInfo";
import DigitalCardActivation from "./DigitalCardActivation/DigitalCardActivation";
import Loading from "../../Loading/Loading";
import { digitalCardRequest } from "../../../../config";

import "./DigitalCard.scss";

const DigitalCard = ({ t, logout, updateAppState, alertState }) => {
  const [loading, setLoading] = useState();
  const [digitalCardInfo, setDigitalCardInfo] = useState();

  const getDigitalCardInfo = () => {
    setLoading(true);
    const requestBody = {
      lang: localStorage.getItem("i18nextLng").toUpperCase(),
      token: localStorage.getItem("token"),
    };

    fetch(digitalCardRequest("getDigitalCardInfo", requestBody))
      .then((res) => res.text())
      .then((getDigitalCardInfoResponseJson) => {
        const JSON = require("true-json-bigint");
        let getDigitalCardInfoResponse = JSON.parse(
          getDigitalCardInfoResponseJson
        );
        if (getDigitalCardInfoResponse?.status?.statusCode != 1) {
          if (getDigitalCardInfoResponse?.status?.statusCode === 200) {
            logout();
          } else {
            setLoading(false);
            alertState(
              true,
              "error",
              t("an error occurred"),
              t(getDigitalCardInfoResponse?.status?.statusCode)
            );
          }
        } else {
          setLoading(false);
          setDigitalCardInfo(getDigitalCardInfoResponse);
        }
      });
  };

  useEffect(() => {
    getDigitalCardInfo();
  }, []);

  return (
    <section className="ibank-digital-card">
      <RouteHeader
        title={t("Digital card")}
        redirectUrl="/dashboard/finance/cards"
        redirectUrlTitle={t("cards")}
      />
      <div className="dashboard-container-main-section">
        {loading ? (
          <Loading />
        ) : (
          <div className="dashboard-finance-section-cards-section-table-section">
            <Grid container>
              {digitalCardInfo && (
                <>
                  <Grid item xs={12} md={4} lg={4}>
                    <DigitalCardInfo {...{ digitalCardInfo }} translate={t} />
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <DigitalCardActivation
                      translate={t}
                      {...{
                        digitalCardInfo,
                        updateAppState,
                        logout,
                        alertState,
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
      </div>
    </section>
  );
};

export default DigitalCard;
