import React, { Component } from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Rules from "./Rules/Rules";
import RegistrationIndividualMobile from "./RegistrationIndividualMobile/RegistrationIndividualMobile";
import RegistrationJuridicalMobile from "./RegistrationJuridicalMobile/RegistrationJuridicalMobile";
import RegistrationIndividualAsan from "./RegistrationIndividualAsan/RegistrationIndividualAsan";
import RegistrationJuridicalAsan from "./RegistrationJuridicalAsan/RegistrationJuridicalAsan";
import "./scss/style.scss";
import { Helmet } from 'react-helmet';
import AlertPopup from './../../copms/AlertPopup/AlertPopup';


export default class Registration extends Component {
    state = {
        open: false,
        popupType: null,
        title: null,
        feedbackMessage: null
    }

    alertState = (open, popupType, title, feedbackMessage) => {
        this.setState({ open, popupType, title, feedbackMessage })
    }

    render() {
        let { open, popupType, title, feedbackMessage } = this.state;
        let { isAuthed, updateAppState } = this.props;
        if (isAuthed) {
            return <Redirect to='/dashboard' />
        }
        return (
            <div>
                <AlertPopup open={open} closeFunction={() => this.setState({ open: false })} popupType={popupType} title={title} feedbackMessage={feedbackMessage} alertFunction={this.alertFunction} />
                <Switch>
                    <Route exact path="/registration" render={(routeProps) => <Rules {...routeProps} updateAppState={updateAppState} />} />
                    <Route path="/registration/individual/mobile" render={(routeProps) => <RegistrationIndividualMobile {...routeProps} updateAppState={updateAppState} alertState={this.alertState}/>} />
                    <Route path="/registration/individual/asan" render={(routeProps) => <RegistrationIndividualAsan {...routeProps} updateAppState={updateAppState} alertState={this.alertState}/>} />
                    <Route path="/registration/juridical/mobile" render={(routeProps) => <RegistrationJuridicalMobile {...routeProps} updateAppState={updateAppState} alertState={this.alertState}/>} />
                    <Route path="/registration/juridical/asan" render={(routeProps) => <RegistrationJuridicalAsan {...routeProps} updateAppState={updateAppState} alertState={this.alertState}/>} />
                    <Redirect exact from="/registration/" to="/registration/individual/asan" />
                </Switch>
            </div>
        )
    }
}
