import React, { useEffect } from 'react';
import {
    Link,
    Redirect
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function SignOperation(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [type, setType] = React.useState('error');
    const [data, setData] = React.useState([]);

    const close = () => {
        setOpen(false);
    }
    useEffect(() => {
        setOpen(props.open)
        setType(props.type)
        setData(props.data)
    }, [props.open, props.type, props.data])

    return (
        <Modal
            open={open}
            onClose={close}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            classes={classes.modal}
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description">
            <Fade in={open}>
                <div className="sign-operation-section">
                    <div className="sign-operation-section-container sign-operation-section-container__left-side">
                        <button className="sign-operation-section-button">{props.t('Signed')}</button>
                        <h2 className="sign-operation-section-title">{props.t('Signing of documents')}</h2>


                        <table className="sign-operation-section-table">
                            <thead className="sign-operation-section-table-head">
                                <tr className="sign-operation-section-table-row">
                                    <th className="sign-operation-section-table-cell sign-operation-section-table-cell__head"></th>
                                    <th className="sign-operation-section-table-cell sign-operation-section-table-cell__head" style={{ paddingLeft: '10px', paddingRight: '10px' }}>{props.t("Name of the document")}</th>
                                    <th className="sign-operation-section-table-cell sign-operation-section-table-cell__head">{props.t("Its status")}</th>
                                </tr>
                            </thead>
                            <tbody className="sign-operation-section-table-body">
                                {
                                    data && data.map((item, index) => {
                                        return (
                                            <tr className="sign-operation-section-table-row">
                                                <td className="sign-operation-section-table-cell">
                                                    {
                                                        item.type === 'success' ?
                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19.6875 10.5C19.6875 5.1875 15.3125 0.8125 10 0.8125C4.64844 0.8125 0.3125 5.1875 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3125 20.1875 19.6875 15.8516 19.6875 10.5ZM8.86719 15.6562C8.63281 15.8906 8.20312 15.8906 7.96875 15.6562L3.90625 11.5938C3.67188 11.3594 3.67188 10.9297 3.90625 10.6953L4.80469 9.83594C5.03906 9.5625 5.42969 9.5625 5.66406 9.83594L8.4375 12.5703L14.2969 6.71094C14.5312 6.4375 14.9219 6.4375 15.1562 6.71094L16.0547 7.57031C16.2891 7.80469 16.2891 8.23438 16.0547 8.46875L8.86719 15.6562Z" fill="#0179D2" />
                                                            </svg>
                                                            :
                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19.6875 10.5C19.6875 5.1875 15.3125 0.8125 10 0.8125C4.64844 0.8125 0.3125 5.1875 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3125 20.1875 19.6875 15.8516 19.6875 10.5ZM10 12.4531C10.9766 12.4531 11.7969 13.2734 11.7969 14.25C11.7969 15.2656 10.9766 16.0469 10 16.0469C8.98438 16.0469 8.20312 15.2656 8.20312 14.25C8.20312 13.2734 8.98438 12.4531 10 12.4531ZM8.28125 6.00781C8.24219 5.73438 8.47656 5.5 8.75 5.5H11.2109C11.4844 5.5 11.7188 5.73438 11.6797 6.00781L11.4062 11.3203C11.3672 11.5938 11.1719 11.75 10.9375 11.75H9.02344C8.78906 11.75 8.59375 11.5938 8.55469 11.3203L8.28125 6.00781Z" fill="#DF3A4C" />
                                                            </svg>
                                                    }
                                                </td>
                                                <td className="sign-operation-section-table-cell" style={{ paddingLeft: '10px', paddingRight: '10px', width: '65%' }}>{item.batchName ? item.batchName : item.operPurpose ? item.operPurpose : item.dtIban}</td>
                                                {
                                                    <td className="sign-operation-section-table-cell">{item.message ? item.message : item.type === 'success' ? props.t('successful operation') : props.t('Failed operation')}</td>
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>


                    </div>
                    <div className="sign-operation-section-container sign-operation-section-container__right-side" style={{justifyContent: 'flex-start'}}>
                        <button style={{alignSelf: 'flex-end', alignContent: 'flex-start', top: 0}} onClick={() => close()}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <div style={{justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {
                            type === 'error' &&
                            <svg className="sign-operation-section-icon" width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z" fill="#FA312C" fillOpacity="0.05" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M62.2463 107.762C87.3841 107.762 107.762 87.3838 107.762 62.246C107.762 37.1083 87.3841 16.7302 62.2463 16.7302C37.1086 16.7302 16.7305 37.1083 16.7305 62.246C16.7305 87.3838 37.1086 107.762 62.2463 107.762Z" fill="#FA312C" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M68.4144 62.3229L77.5509 71.4594C78.6721 72.5815 78.6721 74.3992 77.5509 75.5204L75.5203 77.5509C74.3991 78.6721 72.5804 78.6721 71.4593 77.5509L62.3228 68.4145L53.1864 77.5509C52.0652 78.6721 50.2465 78.6721 49.1254 77.5509L47.0948 75.5204C45.9736 74.3983 45.9736 72.5805 47.0948 71.4594L56.2313 62.3229L47.0948 53.1864C45.9736 52.0643 45.9736 50.2466 47.0948 49.1254L49.1254 47.0949C50.2474 45.9737 52.0652 45.9737 53.1864 47.0949L62.3228 56.2313L71.4593 47.0949C72.5814 45.9737 74.3991 45.9737 75.5203 47.0949L77.5509 49.1254C78.6721 50.2475 78.6721 52.0653 77.5509 53.1864L68.4144 62.3229Z" fill="white" />
                            </svg>

                        }
                        {
                            type === 'success' &&
                            <svg className="sign-operation-section-icon" width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z" fill="#F2F8FD" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M62.2463 107.762C87.3841 107.762 107.762 87.3838 107.762 62.246C107.762 37.1083 87.3841 16.7302 62.2463 16.7302C37.1086 16.7302 16.7305 37.1083 16.7305 62.246C16.7305 87.3838 37.1086 107.762 62.2463 107.762Z" fill="#0179D2" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M55.8445 75.7892L43.3707 63.3154C42.6212 62.566 42.6212 61.3509 43.3707 60.6014L46.0845 57.8875C46.834 57.138 48.0491 57.138 48.7985 57.8875L57.2014 66.2904L75.1996 48.2922C75.949 47.5428 77.1642 47.5428 77.9136 48.2922L80.6275 51.0062C81.3769 51.7556 81.3769 52.9707 80.6275 53.7201L58.5584 75.7893C57.8089 76.5387 56.5938 76.5387 55.8445 75.7892Z" fill="white" />
                            </svg>
                        }
                        {
                            type === 'warning' &&
                            <svg className="sign-operation-section-icon" width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z" fill="#FFFAEE" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M62.2463 107.762C87.3841 107.762 107.762 87.3838 107.762 62.246C107.762 37.1083 87.3841 16.7302 62.2463 16.7302C37.1086 16.7302 16.7305 37.1083 16.7305 62.246C16.7305 87.3838 37.1086 107.762 62.2463 107.762Z" fill="#EAB82F" />
                                <path d="M69.6895 79.1607C69.6895 74.932 66.2297 71.4722 62.001 71.4722C57.6762 71.4722 54.3125 74.932 54.3125 79.1607C54.3125 83.4855 57.6762 86.8492 62.001 86.8492C66.2297 86.8492 69.6895 83.4855 69.6895 79.1607ZM55.1775 40.1416L56.4268 66.2825C56.5229 67.4358 57.5801 68.3968 58.7334 68.3968H65.1725C66.3258 68.3968 67.3829 67.4358 67.479 66.2825L68.7284 40.1416C68.8245 38.7961 67.7674 37.6429 66.4219 37.6429H57.484C56.1385 37.6429 55.0813 38.7961 55.1775 40.1416Z" fill="white" />
                            </svg>
                        }
                        <h3 className="sign-operation-section-feedback-title">
                            {
                                type === 'error' &&
                                props.t('an error occurred')
                            }
                            {
                                type === 'success' &&
                                props.t('successful operation')
                            }
                            {
                                type === 'warning' &&
                                props.t('successful operation')
                            }
                        </h3>
                        <h3 className="sign-operation-section-feedback-subtitle">
                            {
                                type === 'error' &&
                                props.feedbackMessage
                            }
                            {
                                type === 'success' &&
                                props.t('The operation was completed successfully')
                            }
                            {
                                type === 'warning' &&
                                props.t('Not all documents could be signed')
                            }
                        </h3>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
export default translate("translation")(SignOperation);