import React, { Component } from 'react'
import { Link } from "react-router-dom";
import headerLogo from "./../../img/header-logo.svg";
import registrationSuccessIcon from "./../../img/registration-success-icon.svg";
import { translate } from 'react-i18next';

class RegisterSuccess extends Component {

    backLogin = (event) => {
        event.preventDefault();
        this.props.history.push('/login/juridical/asan');
        // window.location.href= '/login/juridical/asan';
    }

    componentDidMount = () => {
        if(this.props.location.state === undefined) {
            this.props.history.push('/registration/juridical/asan/1');
            // window.location.href= '/registration/juridical/asan/1';
        }
    }

    render() {
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-container registration-container--top-border">
                    <img src={registrationSuccessIcon} className="registration-container-icon" alt="registrationSuccessIcon" />
                    <h2 className="registration-container-title registration-container-title--center">{t('registration completed successfully')}</h2>
                    <p className="registration-container-description registration-container-description--center">{t('you will need to log in with your re-registered information to log in.')}</p>
                    <div className="registration-container-footer">
                        <button className="registration-container-footer-button registration-container-footer-button--center" onClick={this.backLogin}>{t('login')}</button>
                    </div>
                </form>
                <div className="registration-range">
                    <p className="registration-range-fraction">
                        <span className="registration-range-fraction-numerator">3</span>/3
                    </p>
                    <div className="registration-range-container">
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                            <div className="registration-range-container-step-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(RegisterSuccess);