import React from "react";

import { PINSettingsTypes } from "../../../../../helpers/pinSettings";

import "./PINSettingsHeader.scss";

const PINSettingsHeader = ({ type, translate }) => {
  return (
    <div className="ibank-pin-settings__header">
      <h6>
        {type === PINSettingsTypes.SET_NEW_PIN
          ? translate("Setting of the Pin code")
          : translate("Changing of the Pin code")}
      </h6>
    </div>
  );
};

export default PINSettingsHeader;
