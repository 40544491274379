import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";

import RouteHeader from "../../../../../copms/RouteHeader/RouteHeader";
import { requestCardStorage } from "../../../../../config";
import { formPost } from "../../../../../helpers/formPost";

import "./AddOtherBankCard.scss";
import { useState } from "react";
import Loading from "../../../Loading/Loading";
import AddCardStorageModal from "../../../../../copms/Modal/AddCardStorageModal";

const AddOtherBankCard = ({ t, logout, updateAppState, alertState }) => {
  const [cardHolder, setCardHolder] = useState("");
  const [cardPaymentType, setCardPaymentType] = useState("");
  const [cardDetails, setCardDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  let history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const paramAction = urlParams.get("aciton");
  const paramOrder = urlParams.get("order");

  useEffect(() => {
    paramAction &&
      ((paramAction == 0 && paramOrder)
        ? setOpenModal(true)
        : history.push("/dashboard/home"));
  }, [paramAction, paramOrder]);

  const getCardDetails = (cardPrefix) => {
    let card = cardPrefix.split(" ").join("");
    // setCardPrefix(card);

    updateAppState("isLoadingTransparentBackground", true);

    let requestBody = {
      token: localStorage.getItem("token"),
      cardPrefix: card,
    };

    fetch(requestCardStorage("storage/getCardInfoByCardPrefix", requestBody))
      .then((res) => res.text())
      .then((responseJSON) => {
        const JSON = require("true-json-bigint");
        let response = JSON.parse(responseJSON);

        if (response?.status?.statusCode !== 1) {
          if (response?.status?.statusCode === 200) {
            logout();
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            alertState(
              true,
              "error",
              t("an error occurred"),
              t(response?.status?.statusCode)
            );
          }
        } else {
          updateAppState("isLoadingTransparentBackground", false);
          setCardHolder(response?.cardHolder);
          setCardPaymentType(response?.cardPaymentType);
          setCardDetails(true);
        }
      });
  };

  const saveCardToStorage = () => {
    updateAppState("isLoadingTransparentBackground", true);

    let requestBody = {
      token: localStorage.getItem("token"),
      order: paramOrder,
    };

    fetch(requestCardStorage("storage/saveCard", requestBody))
      .then((res) => res.text())
      .then((responseJSON) => {
        const JSON = require("true-json-bigint");
        let response = JSON.parse(responseJSON);

        if (response?.status?.statusCode !== 1) {
          if (response?.status?.statusCode === 200) {
            logout();
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            alertState(
              true,
              "error",
              t("an error occurred"),
              t(response?.status?.statusCode)
            );
          }
        } else {
          updateAppState("isLoadingTransparentBackground", false);
          alertState(
            true,
            "success",
            t("successful operation"),
            t(response?.status?.statusCode),
            null,
            false,
            history.push("/dashboard/home")
          );
          reset();
        }
      });
  };

  const onSubmit = () => {
    updateAppState("isLoadingTransparentBackground", true);

    let requestBody = {
      token: localStorage.getItem("token"),
      cardHolder: cardHolder,
      cardPaymentType: cardPaymentType,
    };

    fetch(requestCardStorage("storage/addStorageCard", requestBody))
      .then((res) => res.text())
      .then((responseJSON) => {
        const JSON = require("true-json-bigint");
        let response = JSON.parse(responseJSON);

        if (response?.status?.statusCode !== 1) {
          if (response?.status?.statusCode === 200) {
            logout();
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            alertState(
              true,
              "error",
              t("an error occurred"),
              t(response?.status?.statusCode)
            );
          }
        } else {
          updateAppState("isLoadingTransparentBackground", false);
          formPost(response?.registrationInfo?.tokenUrl, {
            AMOUNT: response?.registrationInfo?.amount,
            CURRENCY: response?.registrationInfo?.currency,
            ORDER: response?.registrationInfo?.order,
            DESC: response?.registrationInfo?.desc,
            TRTYPE: response?.registrationInfo?.trtype,
            TIMESTAMP: response?.registrationInfo?.timeStamp,
            NONCE: response?.registrationInfo?.nonce,
            BACKREF: response?.registrationInfo?.backref,
            P_SIGN: response?.registrationInfo?.psign,
            MERCH_NAME: response?.registrationInfo?.merchName,
            MERCH_URL: response?.registrationInfo?.merchUrl,
            TERMINAL: response?.registrationInfo?.terminal,
            EMAIL: response?.registrationInfo?.email,
            COUNTRY: response?.registrationInfo?.country,
            MERCH_GMT: response?.registrationInfo?.merchGmt,
            MERCHANT: response?.registrationInfo?.merchName,
            TOKEN_ACTION: response?.registrationInfo?.tokenAction,
            LANG: localStorage.getItem("i18nextLng").toUpperCase(),
          });
        }
      });
  };

  return (
    <section className="ibank-card-storage-add-card">
      <RouteHeader title={t("Add card")} />
      <div className="dashboard-container-main-section">
        {paramAction && paramOrder ? (
          <>
            <Loading />
            <AddCardStorageModal
              triggerFunc={saveCardToStorage}
              onClose={() => setOpenModal(false)}
              title={t("Add card")}
              body={t("Add card storage text")}
              open={openModal}
            />
          </>
        ) : (
          <div className="dashboard-finance-section-cards-section-table-section">
            <div className="ibank-card-storage-add-card-header">
              <h6>{t("New card")}</h6>
            </div>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              className="ibank-card-storage-add-card-content"
            >
              <div className="ibank-card-storage-add-card-content-desc">
                <p>{t("Card information")}</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="header-sign-section-container-form-group">
                  <label className="header-sign-section-container-form-group-label">
                    <span style={{ color: "red", marginRight: "3px" }}>*</span>
                    {t("Pan of the card")}
                  </label>
                  <div className="header-sign-section-container-form-group-box">
                    <Controller
                      control={control}
                      name={"cardPrefix"}
                      render={({ field: { onBlur, ref, onChange } }) => (
                        <InputMask
                          mask="**** **"
                          className="header-sign-section-container-form-group-box-input"
                          onBlur={onBlur}
                          inputRef={ref}
                          maskChar={null}
                          placeholder="**** **"
                          onChange={(e) =>
                            e.target.value.length === 7 &&
                            getCardDetails(e.target.value)
                          }
                        />
                      )}
                    />
                  </div>
                  <p className="ibank-input__info__msg">
                    {t("Enter the first 6 numbers of the card")}
                  </p>
                </div>
                <div className="header-sign-section-container-form-group">
                  <label className="header-sign-section-container-form-group-label">
                    {t("Bank name")}
                  </label>
                  <div className="header-sign-section-container-form-group-box">
                    <input
                      type="text"
                      className="header-sign-section-container-form-group-box-input"
                      placeholder={t("Bank name")}
                      readOnly
                      value={cardHolder}
                    />
                  </div>
                </div>
                <div className="header-sign-section-container-form-group">
                  <label className="header-sign-section-container-form-group-label">
                    {t("Card kind")}
                  </label>
                  <div className="header-sign-section-container-form-group-box">
                    <input
                      type="text"
                      className="header-sign-section-container-form-group-box-input"
                      placeholder={t("Card kind")}
                      readOnly
                      value={cardPaymentType}
                    />
                  </div>
                </div>
                <p className="ibank-input__info__msg">
                  {t(
                    "To successfully add your card, a certain amount will be charged from the card and then returned back."
                  )}
                </p>
                <div className="ibank-card-storage-add-card-form-actions">
                  <button
                    type="submit"
                    className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__${
                      !cardDetails ? "disable" : "blue"
                    } dashboard-payment-container-data-section-content-container-form-footer-button__left`}
                    disabled={!cardDetails}
                  >
                    {t("continue")}
                  </button>
                  <button
                    type="button"
                    className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right"
                    onClick={() => history.goBack()}
                  >
                    {t("refusal")}
                  </button>
                </div>
              </form>
            </Grid>
          </div>
        )}
      </div>
    </section>
  );
};

export default AddOtherBankCard;
