import React, { Component } from 'react';
import {Link, Route, Switch, Redirect} from 'react-router-dom';
import CheckForgotPass from './CheckForgotPass/CheckForgotPass';
import VerifyOtpCode from './VerifyOtpCode/VerifyOtpCode';
import ChangePassword from './ChangePassword/ChangePassword';
import SuccessChange from './SuccessChange/SuccessChange';

export default class ForgotPassword extends Component {
    render() {
        let {isAuthed, updateAppState} = this.props;
        if (isAuthed) {
            return <Redirect to="/dashboard/"/>
        }
        return (   
            <Switch>
                <Route exact path="/login/individual/mobile/forgot-password/1" render={(routeProps) => <CheckForgotPass {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/login/individual/mobile/forgot-password/2" render={(routeProps) => <VerifyOtpCode {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/login/individual/mobile/forgot-password/3" render={(routeProps) => <ChangePassword {...routeProps} updateAppState={updateAppState}/>} />
                <Route exact path="/login/individual/mobile/forgot-password/4" render={(routeProps) => <SuccessChange {...routeProps}/>}/>
                <Redirect exact from="/login/individual/mobile/forgot-password" to="/login/individual/mobile/forgot-password/1"/>
                <Route exact path="/login/juridical/mobile/forgot-password/1" render={(routeProps) => <CheckForgotPass {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/login/juridical/mobile/forgot-password/2" render={(routeProps) => <VerifyOtpCode {...routeProps} updateAppState={updateAppState}/>}/>
                <Route exact path="/login/juridical/mobile/forgot-password/3" render={(routeProps) => <ChangePassword {...routeProps} updateAppState={updateAppState}/>} />
                <Route exact path="/login/juridical/mobile/forgot-password/4" render={(routeProps) => <SuccessChange {...routeProps}/>}/>
                <Redirect exact from="/login/juridical/mobile/forgot-password" to="/login/juridical/mobile/forgot-password/1"/>
            </Switch>
        )
    }
}
