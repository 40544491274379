import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { translate } from 'react-i18next';

export default function CampaignSlider(props) {

    let settings = {
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 500,
        dots: true,
        infinite: true,
        swipeToSlide: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
        ]
    };
    let { campaigns, t } = props;

    return (
        <Slider  {...settings}>
            {
                campaigns &&
                campaigns.map((campaign, index) =>
                    <div className="dashboard-campaigns-slider-item" key={index}>
                        <div className="dashboard-campaigns-slider-item-image" style={{ backgroundImage: `url(${campaign.image})` }}></div>
                        <div className="dashboard-campaigns-slider-item-content">
                            <Link to={`/dashboard/service-network/campaigns/${campaign.campaignId}`} className="dashboard-campaigns-slider-item-content-title">{campaign.name}</Link>
                            <h3 className="dashboard-campaigns-slider-item-content-subtitle">{campaign.note}</h3>
                        </div>
                    </div>
                )
            }
        </Slider>
    );
}
