import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import registrationSuccessIcon from "./../../Registration/img/registration-success-icon.svg";

import "./RegistrationSuccess.scss";

const RegistrationSuccess = ({ translate }) => {
  let history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    state === undefined && history.push("/sign-up/not-a-bank-customer");
  }, []);

  return (
    <section className="ibank-registration-success header-sign-section">
      <div className="header-sign-section-container">
        <div className="registration-container">
          <img
            src={registrationSuccessIcon}
            className="registration-container-icon"
            alt="registrationSuccessIcon"
          />
          <h2 className="registration-container-title registration-container-title--center">
            {translate("registration completed successfully")}
          </h2>
          <p className="registration-container-description registration-container-description--center">
            {translate("Enter your PIN (FIN) code and password to log in")}
          </p>
          <div className="registration-container-footer">
            <Link
              to="/login/individual/mobile"
              className="registration-container-footer-button registration-container-footer-button--center"
            >
              {translate("login")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegistrationSuccess;
