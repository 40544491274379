import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { request } from '../../../config';
import flagFilter from './../../../helpers/flagFilter';
import Calendar from '../../../copms/Calendar/Calendar';
import DropdownWithChevron from './../../../copms/DropdownWithChevron/DropdownWithChevron';
import DropdownWithFlag from './../../../copms/DropdownWithFlag/DropdownWithFlag';
import Loading from './../Loading/Loading';
class ExchangeRates extends Component {
    state = {
        selected: this.props.t('On cash funds'),
        exchanges: [],
        firstAmount: "",
        firstCurrencyIndex: null,
        firstCurrency: null,
        secondCurrency: null,
        secondCurrencyIndex: null,
        isCash: true,
        isLoading: false
    }
    updateSelected = (value) => {
        this.setState({ selected: value });
    }
    updateIsCash = (value) => {
        this.setState({ isCash: value });
    }
    updateFirstCurrency = (value) => {
        this.setState({ firstCurrency: value });
    }
    updateSecondCurrency = (value) => {
        this.setState({ secondCurrency: value });
    }
    getFormatedDate = (date) => {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }

    convertAmount = (event) => {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({ firstAmount: event.target.value });
        }
    }

    async getExchangeData(date) {
        if (date) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        else {
            this.setState({ isLoading: true });
        }
        let exchangeCashList = [], exchangeCashlessList = [], exchangeCentralBankList = [];
        await fetch(request("exchange/cash/" + (date ? this.getFormatedDate(date) : ''), null, 'GET'))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        if (date) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                        else {
                            this.setState({ isLoading: false });
                        }
                    }
                }
                else {
                    exchangeCashList = exchangeResponse.exchangeCashList;
                    if (date) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        await fetch(request("exchange/cashless/" + (date ? this.getFormatedDate(date) : ''), null, 'GET'))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        if (date) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                        else {
                            this.setState({ isLoading: false });
                        }
                    }
                }
                else {
                    exchangeCashlessList = exchangeResponse.exchangeCashlessList;
                    if (date) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        await fetch(request("exchange/centralBank/" + (date ? this.getFormatedDate(date) : ''), null, 'GET'))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        this.props.updateAppState('isLoading', false);
                    }
                }
                else {
                    exchangeCentralBankList = exchangeResponse.exchangeCentralBankList;
                    this.props.updateAppState('isLoading', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        let exchangeResultArray = exchangeCentralBankList;
        if (exchangeCashList) {
            exchangeCashList.map((cashItem) => {
                let hasCurrency = false;
                exchangeResultArray.map((item, index) => {
                    if (cashItem.currency === item.currency) {
                        exchangeResultArray[index].cashSell = cashItem.cashSell;
                        exchangeResultArray[index].cashBuy = cashItem.cashBuy;
                        hasCurrency = true;
                    }
                });
                if (hasCurrency === false) {
                    exchangeResultArray.push({ currency: cashItem.currency, cashSell: cashItem.cashSell, cashBuy: cashItem.cashBuy });
                }
            });
        }
        if (exchangeCashlessList) {
            exchangeCashlessList.map((cashlessItem) => {
                let hasCurrency = false;
                exchangeResultArray.map((item, index) => {
                    if (cashlessItem.currency == item.currency) {
                        exchangeResultArray[index].cashlessSell = cashlessItem.cashlessSell;
                        exchangeResultArray[index].cashlessBuy = cashlessItem.cashlessBuy;
                        hasCurrency = true;
                    }
                });
                if (hasCurrency == false) {
                    exchangeResultArray.push({ currency: cashlessItem.currency, cashlessSell: cashlessItem.cashSell, cashlessBuy: cashlessItem.cashBuy });
                }
            });
        }
        if (exchangeResultArray) {
            const index = exchangeResultArray.findIndex((item, index) => item.currency === 'AZN');
            if (index == -1) {
                exchangeResultArray.push({ currency: 'AZN', cashBuy: 1, cashSell: 1, cashlessBuy: 1, cashlessSell: 1 });
            }
            else {
                exchangeResultArray[index].centralBank = 1;
                exchangeResultArray[index].cashBuy = 1;
                exchangeResultArray[index].cashSell = 1;
                exchangeResultArray[index].cashlessBuy = 1;
                exchangeResultArray[index].cashlessSell = 1;
            }
            exchangeResultArray.sort((prev, next) => {
                return (prev.currency < next.currency) ? -1 : (prev.currency > next.currency) ? 1 : 0;
            })
            this.setState({ exchanges: exchangeResultArray });
        }
        else {
            this.setState({ exchanges: [] });
        }
        this.setState({
            firstCurrency: 'USD',
            secondCurrency: 'AZN'
        }, () => {
            this.updateCurrencyIndex();
        });
    }

    updateDate = (name, date) => {
        this.setState({ [name]: date }, () => {
            this.getExchangeData(date);
        });
    }
    updateCurrencyIndex = () => {
        this.setState({ firstCurrencyIndex: this.state.exchanges.findIndex((exchange) => exchange.currency === this.state.firstCurrency) });
        this.setState({ secondCurrencyIndex: this.state.exchanges.findIndex((exchange) => exchange.currency === this.state.secondCurrency) });
    }
    replaceCurrency = () => {
        this.setState({ firstCurrency: this.state.secondCurrency, secondCurrency: this.state.firstCurrency });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.firstCurrency !== prevState.firstCurrency || this.state.secondCurrency !== prevState.secondCurrency) {
            this.updateCurrencyIndex();
        }
    }
    componentDidMount() {
        // let date = new Date('09/18/2018');
        this.getExchangeData();
    }
    render() {
        let { updateAppState, alertState, t } = this.props;
        let { exchanges, firstCurrency, firstAmount, secondCurrency, firstCurrencyIndex, secondCurrencyIndex, isCash, selected, isLoading } = this.state;

        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Exchange rates')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">
                            <div className="dashboard-exchange-range-section">
                                <div className="dashboard-exchange-range-section-backgrond">
                                    <table className="dashboard-exchange-range-section-table">
                                        <thead>
                                            <tr className="dashboard-exchange-range-section-row dashboard-exchange-range-section-row__head">
                                                <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CURRENCY')}</th>
                                                <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CENTRAL BANK')}</th>
                                                <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CASLESS BUYING')}</th>
                                                <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CASHLESS SELLING')}</th>
                                                <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CASH BUYING')}</th>
                                                <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CASH SELLING')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                exchanges.filter(item => item.currency !== 'AZN').map((exchange, index) => (
                                                    <tr className="dashboard-exchange-range-section-row" key={index}>
                                                        <td className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__currency">
                                                            <img src={flagFilter(exchange.currency)} alt="" className="dashboard-exchange-range-section-cell-image" />
                                                            <span>{exchange.currency}</span>
                                                        </td>
                                                        <td className="dashboard-exchange-range-section-cell">{exchange.centralBank && exchange.centralBank.toFixed(4)}</td>
                                                        <td className="dashboard-exchange-range-section-cell">{exchange.cashlessBuy && exchange.cashlessBuy.toFixed(4)}</td>
                                                        <td className="dashboard-exchange-range-section-cell">{exchange.cashlessSell && exchange.cashlessSell.toFixed(4)}</td>
                                                        <td className="dashboard-exchange-range-section-cell">{exchange.cashBuy && exchange.cashBuy.toFixed(4)}</td>
                                                        <td className="dashboard-exchange-range-section-cell">{exchange.cashSell && exchange.cashSell.toFixed(4)}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dashboard-currency-convertor-calendar-section">
                                <div className="dashboard-currency-convertor-section">
                                    <div className="dashboard-currency-convertor-section-background">
                                        <div className="dashboard-currency-convertor-section-header">
                                            <h3 className="dashboard-currency-convertor-section-header-title">{t('Currency convertor')}</h3>
                                            <DropdownWithChevron dropdownItems={[t('On cash funds'), t('On non-cash funds')]} updateSelected={this.updateSelected} updateIsCash={this.updateIsCash} lowercase={true} selected={selected} />
                                        </div>
                                        <div className="dashboard-currency-convertor-section-content">
                                            <input className="dashboard-currency-convertor-input" value={firstAmount} placeholder={'0.00'} onChange={this.convertAmount} />
                                            <div className="dashboard-currency-convertor-data">
                                                <div className="dashboard-currency-convertor-data-currency-select-box">
                                                    <DropdownWithFlag currency={firstCurrency} updateCurrency={this.updateFirstCurrency}
                                                        dropdownItems={exchanges.filter(exchange =>
                                                            exchange.currency && ((isCash && exchange.cashBuy && exchange.cashSell)
                                                                || (!isCash && exchange.cashlessBuy && exchange.cashlessSell))
                                                            && exchange.currency !== this.state.firstCurrency
                                                            // && exchange.currency !== this.state.secondCurrency
                                                        ).map(exchange => exchange.currency)} />
                                                </div>
                                                <button className="dashboard-currency-convertor-data-convert-button" onClick={this.replaceCurrency}>
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.8672 1.63281L16.8359 3.66406C15.0781 1.90625 12.6562 0.8125 10 0.8125C4.80469 0.8125 0.546875 4.91406 0.3125 10.0312C0.273438 10.3047 0.507812 10.5 0.78125 10.5H1.875C2.10938 10.5 2.30469 10.3438 2.34375 10.0703C2.57812 6.04688 5.89844 2.84375 10 2.84375C12.1094 2.84375 14.0234 3.70312 15.3906 5.10938L13.2812 7.21875C12.9688 7.53125 13.2031 8 13.5938 8H19.2188C19.4531 8 19.6875 7.80469 19.6875 7.53125V1.94531C19.6875 1.55469 19.1797 1.32031 18.8672 1.63281ZM19.1797 10.5H18.0859C17.8516 10.5 17.6562 10.6953 17.6172 10.9688C17.3828 14.9922 14.0625 18.1562 10 18.1562C7.85156 18.1562 5.9375 17.3359 4.57031 15.9297L6.67969 13.8203C6.99219 13.5078 6.75781 13 6.36719 13H0.78125C0.507812 13 0.3125 13.2344 0.3125 13.4688V19.0938C0.3125 19.4844 0.78125 19.7188 1.09375 19.4062L3.125 17.375C4.88281 19.1328 7.30469 20.1875 10 20.1875C15.1562 20.1875 19.4141 16.125 19.6484 11.0078C19.6875 10.7344 19.4531 10.5 19.1797 10.5Z" fill="#0066B2" />
                                                    </svg>
                                                </button>
                                                <div className="dashboard-currency-convertor-data-currency-select-box">
                                                    <DropdownWithFlag currency={secondCurrency} updateCurrency={this.updateSecondCurrency}
                                                        dropdownItems={exchanges.filter(exchange =>
                                                            exchange.currency && ((isCash && exchange.cashBuy && exchange.cashSell)
                                                                || (!isCash && exchange.cashlessBuy && exchange.cashlessSell))
                                                            // && exchange.currency !== this.state.firstCurrency
                                                            && exchange.currency !== this.state.secondCurrency
                                                        ).map(exchange => exchange.currency)} />
                                                </div>
                                            </div>
                                            <input className="dashboard-currency-convertor-input"
                                                value={
                                                    exchanges.length !== 0 && firstCurrencyIndex !== null && secondCurrencyIndex !== null ?
                                                        (isCash) ?
                                                            (exchanges[firstCurrencyIndex].cashBuy) && (exchanges[secondCurrencyIndex].cashBuy) &&
                                                            (this.state.firstAmount * exchanges[firstCurrencyIndex].cashBuy / exchanges[secondCurrencyIndex].cashSell).toFixed(2)
                                                            :
                                                            (exchanges[firstCurrencyIndex].cashlessBuy) && (exchanges[secondCurrencyIndex].cashlessSell) &&
                                                            (this.state.firstAmount * exchanges[firstCurrencyIndex].cashlessBuy / exchanges[secondCurrencyIndex].cashlessSell).toFixed(2)
                                                        :
                                                        ''
                                                }
                                                disabled={true} />
                                            <div className="dashboard-currency-convertor-data-currency-ratio">
                                                {
                                                    firstCurrency !== null && secondCurrency !== null && firstCurrencyIndex !== null && secondCurrencyIndex !== null && exchanges.length !== 0 &&
                                                    <>
                                                        1 {firstCurrency} = &nbsp;
                                                {
                                                            (isCash) ?

                                                                (exchanges[firstCurrencyIndex].cashBuy) && (exchanges[secondCurrencyIndex].cashSell) &&
                                                                (exchanges[firstCurrencyIndex].cashBuy / exchanges[secondCurrencyIndex].cashSell).toFixed(2)
                                                                :
                                                                (exchanges[firstCurrencyIndex].cashlessBuy) && (exchanges[secondCurrencyIndex].cashlessSell) &&
                                                                (exchanges[firstCurrencyIndex].cashlessBuy / exchanges[secondCurrencyIndex].cashlessSell).toFixed(2)
                                                        }
                                                &nbsp;
                                                {
                                                            secondCurrency
                                                        }
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-currency-calendar-section">
                                    <Calendar onSelect={val => this.getExchangeData(val)} />
                                </div>
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}
export default translate("translation")(ExchangeRates);
