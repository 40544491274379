import React, { Component, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import AccountStatementRequisites from './AccountStatementRequisites';
import CardExtensionRenewal from './CardExtensionRenewal';
import NotFound from './../../../copms/NotFound/NotFound';
export default class Services extends Component {
    render() {
        let { updateAppState, updateDashboardState, alertState, logout } = this.props;
        return (
            <Switch>
                <Redirect exact from="/dashboard/services/" to="/dashboard/services/account-statement-requisites"/>
                <Route path="/dashboard/services/account-statement-requisites" render={(routeProps) => <AccountStatementRequisites logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Redirect exact from="/dashboard/services/card-extension-renewal" to="/dashboard/services/card-extension-renewal/extension"/>
                <Route path="/dashboard/services/card-extension-renewal/:type" render={(routeProps) => <CardExtensionRenewal logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState}/> } />
                <Route render={(routeProps) => (<NotFound />)} />
            </Switch>
        )
    }
}
