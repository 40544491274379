import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import { request } from "../../../config";
import headerLogo from '../../Dashboard/img/header-logo.svg';
import smsConfirmationIcon from '../../Dashboard/img/sms-confirmation-icon.svg';
import commentAltLines from '../../Dashboard/img/comment-alt-lines.svg';
import { translate } from 'react-i18next';
import GeneralModal from '../../../copms/Modal/GeneralModal';
import { formPost } from '../../../helpers/formPost';


class PlasticCardOtp extends Component {
    state = {
        timer: "03:00",
        minute: 3,
        second: 0,
        timerInterval: null,
        feedbackMessage: null,
        feedbackMessageSuccess: null,
        isDisabled: true,
        openAlert: false,
    }

    backCheckCustomer = () => {
        // this.props.history.push('/dashboard/orders/plastic-card');
        this.props.history.push("/dashboard/orders/plastic-card", { ...this.props.location?.state})
    }

    startTimer = () => {
        let { minute, second } = this.state;
        this.setState({
            timerInterval: setInterval(() => {
                let secondPart, minutePart;
                if (second === 0 && minute === 0) {
                    $('.registration-container-sms-confirmation-numbers').prop('disabled', true);
                    $('.registration-container-sms-confirmation-numbers').prop('value', null);
                    clearInterval(this.state.timerInterval);
                    this.setState({
                        timer: null
                    });
                }
                else {
                    if (second !== 0) {
                        second--;
                    }
                    else {
                        if (minute !== 0) {
                            minute--;
                            second = 59;
                        }
                    }
                    if (second < 10) {
                        secondPart = "0" + second;
                    }
                    else {
                        secondPart = second;
                    }
                    if (minute < 10) {
                        minutePart = "0" + minute;
                    }
                    else {
                        minutePart = minute;
                    }
                    this.setState({
                        timer: minutePart + ":" + secondPart
                    })
                }
            }, 1000)
        })
    }

    sendOtpCode = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ feedbackMessage: null, isDisabled: true });
        let sendOtpCodeRequestBody = {
            token: localStorage.getItem("token"),
            lang: localStorage.getItem("i18nextLng").toUpperCase()
        }
        fetch(request("setting/sendOtpForCardOrder", sendOtpCodeRequestBody))
            .then(res => res.json())
            .then((sendOtpCodeRequestResponse) => {
                if (sendOtpCodeRequestResponse.status.statusCode !== 1) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ feedbackMessage: this.props.t(sendOtpCodeRequestResponse.status.statusCode) });
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ verifyToken: sendOtpCodeRequestResponse.verifyToken });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

        this.setState({
            timer: "03:00",
            minute: 3,
            second: 0,
        });
        clearInterval(this.state.timerInterval);
        this.startTimer();
        $('.registration-container-sms-confirmation-numbers').prop('disabled', false);
        $('.registration-container-sms-confirmation-numbers').prop('value', null);
        clearInterval(this.state.timerInterval);
        this.setState({
            timer: "03:00"
        });
    }

    insertCodeNumber = (number, event) => {
        let element = eval("this.code" + number);
        element.value = event.target.value.replace(/\D/g, '');
        if (event.target.value) {
            if (element.value.length > 1) {
                element.value = element.value.substring(0, 1);
            }
            if (event.target.nextElementSibling) {
                event.target.disabled = true;
                event.target.nextElementSibling.focus();
            }
        }
        else {
            event.target.value = null;
        }
        if (this.code1.value !== "" && this.code2.value !== "" && this.code3.value !== "" && this.code4.value !== "" && this.code5.value !== "" && this.code6.value !== "") {
            this.setState({ isDisabled: false });
        }
        else {
            this.setState({ isDisabled: true });
        }
    }

    deleteCodeNumber = (number, event) => {
        let element = eval("this.code" + number);
        let previousElement = eval("this.code" + (number - 1));
        if (event.keyCode === 8) {
            if (event.target.value === "") {
                if (event.target.previousElementSibling) {
                    previousElement.disabled = false;
                    event.target.previousElementSibling.value = "";
                    event.target.previousElementSibling.focus();
                }

            }
        }
    }

    formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            return `${day}.${month}.${year}`;
        }
        else {
            return null
        }
    }

    verifyOtpCode = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        clearInterval(this.state.timerInterval);
        this.setState({ timer: null });
        let otpCode = this.code1.value + this.code2.value + this.code3.value + this.code4.value + this.code5.value + this.code6.value;

        this.props.updateAppState('isLoadingTransparentBackground', true);

        let verifyOtpCodeRequestBody = {
            token: localStorage.getItem("token"),
            verifyToken: this.state.verifyToken,
            confirmCode: otpCode,
            cardOrderId: this.props.location.state.orderId
        }

        fetch(request("setting/verifyOtpForCardOrder", verifyOtpCodeRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    this.setState({ feedbackMessage: this.props.t(getCardListForCustomerResponse.status.statusCode) });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.props.history.push("/dashboard/orders/plastic-card", { ...this.props.location?.state, step: 2, questions: getCardListForCustomerResponse.questions, orderId: this.props.location.state.orderId })
                }
            });
    }

    payCardOrder = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        clearInterval(this.state.timerInterval);
        this.setState({ timer: null });
        let otpCode = this.code1.value + this.code2.value + this.code3.value + this.code4.value + this.code5.value + this.code6.value;

        this.props.updateAppState('isLoadingTransparentBackground', true);

        let verifyOtpCodeRequestBody = {
            token: localStorage.getItem("token"),
            verifyToken: this.state.verifyToken,
            confirmCode: otpCode,
            orderId: this.props.location.state.orderId,
            accountId: this.props.location.state.accountId,
            cardId: this.props.location.state.cardId,
            cardKind: this.props.location.state.cardKind,
            paymentType: this.props.location.state.paymentType,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            amount: this.props.location.state.amount
        }
        fetch(request("order/payCardOrder", verifyOtpCodeRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    this.setState({ feedbackMessage: this.props.t(getCardListForCustomerResponse.status.statusCode) });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    if(this.props.location.state.cardKind != 2){
                        this.props.history.push("/dashboard/orders/plastic-card", { ...this.props.location?.state, step: 7, successModal: true, successModalData: {...getCardListForCustomerResponse, currency: this.props.location?.state?.currencyName} })
                    } else {
                        formPost(getCardListForCustomerResponse?.cardStorage?.tokenUrl, {
                            AMOUNT : getCardListForCustomerResponse?.cardStorage?.amount,
                            CURRENCY: getCardListForCustomerResponse?.cardStorage?.currency,
                            ORDER: getCardListForCustomerResponse?.cardStorage?.order,
                            DESC: getCardListForCustomerResponse?.cardStorage?.desc,
                            MERCH_NAME: getCardListForCustomerResponse?.cardStorage?.merchName,
                            MERCH_URL: getCardListForCustomerResponse?.cardStorage?.merchUrl,
                            TERMINAL: getCardListForCustomerResponse?.cardStorage?.terminal,
                            EMAIL: getCardListForCustomerResponse?.cardStorage?.email,
                            TRTYPE: getCardListForCustomerResponse?.cardStorage?.trtype,
                            COUNTRY: getCardListForCustomerResponse?.cardStorage?.country,
                            MERCH_GMT: getCardListForCustomerResponse?.cardStorage?.merchGmt,
                            TIMESTAMP: getCardListForCustomerResponse?.cardStorage?.timeStamp,
                            NONCE: getCardListForCustomerResponse?.cardStorage?.nonce,
                            BACKREF: getCardListForCustomerResponse?.cardStorage?.backref,
                            P_SIGN: getCardListForCustomerResponse?.cardStorage?.psign,
                            TOKEN: getCardListForCustomerResponse?.cardStorage?.token,
                            LANG: localStorage.getItem('i18nextLng').toUpperCase()
                          });
                    }

                }
            });
    }

    componentDidMount = () => {
        if (this.props.location.state === undefined) {
            this.props.history.push('/dashboard/orders/plastic-card');
        }
        else {
            this.sendOtpCode();
        }
    }

    render() {
        let { timer, feedbackMessage, isDisabled, openAlert, feedbackMessageSuccess } = this.state;
        let { t } = this.props;

        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <GeneralModal open={openAlert}
                    successClose={() => (this.setState({ openAlert: false }))}
                    feedbackErrorModal={null}
                    createFeedbackMessage={feedbackMessageSuccess}
                    internalOtp={true} />
                <form className="registration-container registration-container--top-border">
                    <h2 className="registration-container-title">{t('Enter the one-time code')}</h2>
                    <p className="registration-container-description">{t('enter the 6-digit confirmation code sent to your phone')}</p>
                    <img src={smsConfirmationIcon} className="registration-container-icon" alt="smsConfirmationIcon" />
                    <div className="registration-container-sms-confirmation">
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code1 = input }} placeholder="0" autoFocus={true} onInput={this.insertCodeNumber.bind(this, 1)} onKeyDown={this.deleteCodeNumber.bind(this, 1)} />
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code2 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 2)} onKeyDown={this.deleteCodeNumber.bind(this, 2)} />
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code3 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 3)} onKeyDown={this.deleteCodeNumber.bind(this, 3)} />
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code4 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 4)} onKeyDown={this.deleteCodeNumber.bind(this, 4)} />
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code5 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 5)} onKeyDown={this.deleteCodeNumber.bind(this, 5)} />
                        <input className="registration-container-sms-confirmation-numbers" ref={(input) => { this.code6 = input }} placeholder="0" onInput={this.insertCodeNumber.bind(this, 6)} onKeyDown={this.deleteCodeNumber.bind(this, 6)} />
                    </div>
                    <div className="registration-container-validation-feedback">
                        {feedbackMessage ? <p className="registration-container-validation-feedback-message">{t(feedbackMessage)}</p> : null}
                    </div>
                    <div className="registration-container-resend">
                        <div className="registration-container-resend-container">
                            <div className="registration-container-resend-container-icon">
                                <img src={commentAltLines} className="registration-container-resend-container-icon-img" alt="commentAltLines" />
                            </div>
                            <button type="button" className="registration-container-resend-container-button" disabled={timer != null} onClick={this.sendOtpCode}>{t('resend the code')}</button>
                        </div>
                        <span className="registration-container-resend-timer">{timer}</span>
                    </div>
                    <div className="registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backCheckCustomer}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled} onClick={this.props.location?.state?.step == 6 ? this.payCardOrder : this.verifyOtpCode}>{t('next')}</button>
                    </div>
                </form>
            </div>
        )
    }
}



export default translate("translation")(PlasticCardOtp);