import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from './../../../config';
import $ from 'jquery';
import DatePickerInput from './DatePickerInput';
import DownloadFileModal from './../../../copms/Modal/DownloadFileModal';
import SendRepeatMail from './../../../copms/Modal/SendRepeatMail';
import lastOperationsIcon from './../img/last-operations-icon.png';
import adocIcon from './../img/adoc-icon.svg';
import pdfIcon from './../img/pdf-icon.svg';
import smsIcon from './../img/sms-icon.svg';
import Loading from './../Loading/Loading';
import OutsideClickHandler from 'react-outside-click-handler';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

class History extends Component {
    state = {
        isLoading: false,
        orderReceivingTypeId: this.props.match.params.type == "card" ? 1 : (this.props.match.params.type == "account" ? 2 : this.state.orderReceivingTypeId),
        orderTypeId: null,
        startDate: null,
        endDate: null,
        statements: [],
        dropdownVisibilityType: false,
        searchedValidation: false,
        open: false,
        selectedStatement: -1,
        email: '',
        emailValidation: false
    }

    updateEmail = (e) => {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        this.setState({ email: e.target.value });
        if (e.target.value.match(validRegex)) {
            this.setState({ emailValidation: true });
        }
        else {
            this.setState({ emailValidation: false });
        }

    }

    downloadFile = (item) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
        let getCustomerOrderDocumentRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: item.id,

        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: item.id
        }
        fetch(request("order/getCustomerOrderDocument", getCustomerOrderDocumentRequestBody))
            .then(res => res.text())
            .then((getCustomerOrderDocumentResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustomerOrderDocumentResponse = JSON.parse(getCustomerOrderDocumentResponseJSON);
                if (getCustomerOrderDocumentResponse.status.statusCode !== 1) {
                    if (getCustomerOrderDocumentResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCustomerOrderDocumentResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getCustomerOrderDocumentResponse.document);
                    saveFile(getCustomerOrderDocumentResponse.documentName, null, arrrayBuffer);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }


    sendEmail = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
        let sendEmailRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            orderId: this.state.statements[this.state.selectedStatement].id,
            to: this.state.email,
            subject: this.state.statements[this.state.selectedStatement].orderTypeId == 2 ? 'Hesabdan çıxarış' : this.state.statements[this.state.selectedStatement].orderTypeId == 1 ? 'Rekvizit' : null,
            message: 'Sifariş sənədi hazırlanmışdır.'
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            orderId: this.state.statements[this.state.selectedStatement].id,
            to: this.state.email,
            subject: this.state.statements[this.state.selectedStatement].orderTypeId == 2 ? 'Hesabdan çıxarış' : this.state.statements[this.state.selectedStatement].orderTypeId == 1 ? 'Rekvizit' : null,
            message: 'Sifariş sənədi hazırlanmışdır.'
        }
        fetch(request("util/sendEmail", sendEmailRequestBody))
            .then(res => res.text())
            .then((sendEmailResponseJSON) => {
                const JSON = require('true-json-bigint');
                let sendEmailResponse = JSON.parse(sendEmailResponseJSON);
                if (sendEmailResponse.status.statusCode !== 1) {
                    if (sendEmailResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(sendEmailResponse.status.statusCode));
                    }
                }
                else {
                    this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('Sent successfully'));
                }
                this.setState({ open: false });
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateDate = (name, value) => {
        this.setState({ [name]: value }, () => {
            if (this.state.startDate && this.getFormatedDate(this.state.startDate) !== 'NaN.NaN.NaN' && this.state.endDate && this.getFormatedDate(this.state.endDate) !== 'NaN.NaN.NaN') {
                this.getCustomerOrderList(false);
            }
        });
    }

    changeDropdownVisibilityType = () => {
        this.setState({ dropdownVisibilityType: !this.state.dropdownVisibilityType });
    }

    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }

    updateOrderType = (orderTypeId) => {
        this.setState({ orderTypeId: orderTypeId, dropdownVisibilityType: false }, () => {
            this.getCustomerOrderList(false);
        });
    }

    getCustomerOrderList = (initial) => {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        else {
            this.setState({ isLoading: true });
        }
        let getCustomerOrderListRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            orderReceivingTypeId: this.state.orderReceivingTypeId,
            orderTypeId: this.state.orderTypeId,
            beginDate: (this.state.startDate && this.getFormatedDate(this.state.startDate) !== 'NaN.NaN.NaN' && this.state.endDate && this.getFormatedDate(this.state.endDate) !== 'NaN.NaN.NaN') ? this.getFormatedDate(this.state.startDate) : null,
            endDate: (this.state.startDate && this.getFormatedDate(this.state.startDate) !== 'NaN.NaN.NaN' && this.state.endDate && this.getFormatedDate(this.state.endDate) !== 'NaN.NaN.NaN') ? this.getFormatedDate(this.state.endDate) : null
        }
        if (localStorage.getItem('compId')) {
            getCustomerOrderListRequestBody.compId = localStorage.getItem('compId');
        }
        fetch(request("order/getCustomerOrderList", getCustomerOrderListRequestBody))
            .then(res => res.text())
            .then((getCustomerOrderListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustomerOrderListResponse = JSON.parse(getCustomerOrderListResponseJSON);
                if (getCustomerOrderListResponse.status.statusCode !== 1 && getCustomerOrderListResponse.status.statusCode !== 427) {
                    if (getCustomerOrderListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCustomerOrderListResponse.status.statusCode));
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                            this.setState({ searchedValidation: true, statements: [] });
                        }
                    }
                }
                else {
                    if (getCustomerOrderListResponse.status.statusCode === 427) {
                        getCustomerOrderListResponse.respCustomerOrderList = [];
                    }
                    this.setState({ isLoading: false, statements: [] });
                    for (let i = 0; i < getCustomerOrderListResponse.respCustomerOrderList.length; i++) {
                        if (getCustomerOrderListResponse.respCustomerOrderList[i].cardNumber) {
                            // =========NUMBER=========
                            getCustomerOrderListResponse.respCustomerOrderList[i].organizedCardNumber = getCustomerOrderListResponse.respCustomerOrderList[i].cardNumber.substring(0, 4)
                                + " **** **** " + getCustomerOrderListResponse.respCustomerOrderList[i].cardNumber.substring(12, 16);
                        }
                        else {
                            getCustomerOrderListResponse.respCustomerOrderList[i].organizedCardNumber = null;
                        }
                    }
                    this.setState({ statements: getCustomerOrderListResponse.respCustomerOrderList });
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidUpdate(previousProps) {
        if (previousProps.match.params.type !== this.props.match.params.type) {
            this.setState({
                orderTypeId: null,
                searchedValidation: false,
                startDate: null,
                endDate: null,
                dropdownVisibilityType: false,
                open: false,
                selectedStatement: -1,
                email: '',
                emailValidation: false,
                orderReceivingTypeId: this.props.match.params.type == "card" ? 1 : (this.props.match.params.type == "account" ? 2 : this.state.orderReceivingTypeId)
            }, () => {
                this.getCustomerOrderList(true);
            })
        }
    }

    componentDidMount() {
        this.getCustomerOrderList(true);
    }

    render() {
        let { t } = this.props;
        let { searchedValidation, open, orderTypeId, orderReceivingTypeId, dropdownVisibilityType, statements, isLoading, openDownload, email, emailValidation } = this.state;

        return (
            <Fragment>
                <SendRepeatMail open={open} sendEmail={this.sendEmail} close={() => { this.setState({ open: false, selectedStatement: -1, email: '', emailValidation: false }) }} email={email} emailValidation={emailValidation} updateEmail={this.updateEmail} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('history')}</h2>
                    </div>
                </div>
                <div className="dashboard-container-main-section-vertical">
                    <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                        <div className="dashboard-transfer-list-header">
                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                <NavLink exact to="/dashboard/services/account-statement-requisites/history/card" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("By card")}</NavLink>
                                <NavLink exact to="/dashboard/services/account-statement-requisites/history/account" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("By current account")}</NavLink>
                            </div>
                        </div>
                        <>
                            <DownloadFileModal open={openDownload} close={() => this.setState({ openDownload: false })} errorMessage={this.state.errorMessageDownload} />
                            {
                                isLoading ?
                                    <div className="dashboard-transfer-search-form-section">
                                        <Loading />
                                    </div>
                                    :
                                    <>
                                        <div className="dashboard-transfer-search-form-section">
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityType: false }) }}>
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Type of operation')}</label>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                        <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibilityType}>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={orderTypeId == null ? { color: '#BDC3C7' } : {}}>
                                                                {orderTypeId == null && t("all")}
                                                                {orderTypeId == 1 && orderReceivingTypeId == 1 && t("By card details")}
                                                                {orderTypeId == 2 && orderReceivingTypeId == 1 && t("By card  statement")}
                                                                {orderTypeId == 1 && orderReceivingTypeId == 2 && t("By current account details")}
                                                                {orderTypeId == 2 && orderReceivingTypeId == 2 && t("By current account  statement")}
                                                            </div>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        <ul className={(dropdownVisibilityType == false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateOrderType.bind(this, null)}>{t("all")}</button>
                                                            </li>
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateOrderType.bind(this, 1)}>{orderReceivingTypeId == 1 ? t("By card details") : orderReceivingTypeId == 2 ? t("By current account details") : null}</button>
                                                            </li>
                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateOrderType.bind(this, 2)}>{orderReceivingTypeId == 1 ? t("By card  statement") : orderReceivingTypeId == 2 ? t("By current account  statement") : null}</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </OutsideClickHandler>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item"></div>
                                            <div className="dashboard-transfer-search-form-section-item"></div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t("Start date")}</label>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <DatePickerInput placeholder={t("Start date")} updateDate={this.updateDate.bind(this, 'startDate')} />
                                                </div>
                                            </div>
                                            <div className="dashboard-transfer-search-form-section-item">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t("End date")}</label>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                    <DatePickerInput placeholder={t("End date")} updateDate={this.updateDate.bind(this, 'endDate')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard-transfer-list-content" style={{ padding: 0 }}>
                                            {
                                                statements.length !== 0 ?
                                                    <>
                                                        <div className="dashboard-transfer-list-table">
                                                            {
                                                                statements.map((statement, index) =>
                                                                    <li className="dashboard-last-operations-section-item" key={index}>
                                                                        <div className="dashboard-last-operations-section-item-left-side">
                                                                            <div className="dashboard-last-operations-section-item-content">
                                                                                <div className="dashboard-last-operations-section-item-company">
                                                                                    {statement.orderReceivingTypeId == 1 && statement.orderTypeId == 1 && t('By card details')}
                                                                                    {statement.orderReceivingTypeId == 2 && statement.orderTypeId == 1 && t('By current account details')}
                                                                                    {statement.orderReceivingTypeId == 1 && statement.orderTypeId == 2 && t('By card  statement')}
                                                                                    {statement.orderReceivingTypeId == 2 && statement.orderTypeId == 2 && t('By current account  statement')}
                                                                                    {statement.orderTypeId == 2 && ` ${statement.statementDate}`}
                                                                                    {statement.orderTypeId == 2 && `  (${statement.signTypeId == 1 ? t('signed') : t('unsigned')})`}
                                                                                </div>
                                                                                <div className="dashboard-last-operations-section-item-company" style={{ fontSize: '14px' }}>
                                                                                    {statement.orderReceivingTypeId == 1 && `${t('By card')} / ${statement.organizedCardNumber}`}
                                                                                    {statement.orderReceivingTypeId == 2 && `${t('By current account')} / ${statement.iban}`}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dashboard-last-operations-section-item-rigth-side">
                                                                            <div className="dashboard-last-operations-section-item-file-button-container">
                                                                                {
                                                                                    statement.signTypeId == 1 ?
                                                                                        <button onClick={this.downloadFile.bind(this, statement)} className="dashboard-last-operations-section-item-file-button">
                                                                                            <img src={adocIcon} />
                                                                                        </button>
                                                                                        :
                                                                                        <button onClick={this.downloadFile.bind(this, statement)} className="dashboard-last-operations-section-item-file-button">
                                                                                            <img src={pdfIcon} />
                                                                                        </button>
                                                                                }
                                                                                <button className="dashboard-last-operations-section-item-file-button" onClick={() => { this.setState({ selectedStatement: index, open: true, email: '', emailValidation: false }) }}>
                                                                                    <img src={smsIcon} />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    searchedValidation == false ?
                                                        <div className="dashboard-last-operations-section-content">
                                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                                        </div>
                                                        :
                                                        <div className="dashboard-finance-section-cards-section-commission-alert">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 1.03226C11.8065 1.03226 14.9677 4.16129 14.9677 8C14.9677 11.871 11.8387 14.9677 8 14.9677C4.12903 14.9677 1.03226 11.871 1.03226 8C1.03226 4.16129 4.12903 1.03226 8 1.03226ZM8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM6.83871 11.0968C6.6129 11.0968 6.45161 11.2903 6.45161 11.4839V11.7419C6.45161 11.9677 6.6129 12.129 6.83871 12.129H9.16129C9.35484 12.129 9.54839 11.9677 9.54839 11.7419V11.4839C9.54839 11.2903 9.35484 11.0968 9.16129 11.0968H8.77419V6.58065C8.77419 6.3871 8.58064 6.19355 8.3871 6.19355H6.83871C6.6129 6.19355 6.45161 6.3871 6.45161 6.58065V6.83871C6.45161 7.06452 6.6129 7.22581 6.83871 7.22581H7.22581V11.0968H6.83871ZM8 3.35484C7.41935 3.35484 6.96774 3.83871 6.96774 4.3871C6.96774 4.96774 7.41935 5.41935 8 5.41935C8.54839 5.41935 9.03226 4.96774 9.03226 4.3871C9.03226 3.83871 8.54839 3.35484 8 3.35484Z" fill="#2873F0" />
                                                            </svg>
                                                            <p>{t('No results were found matching the information you entered. Please try again.')}</p>
                                                        </div>
                                            }
                                        </div>
                                    </>
                            }
                        </>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default translate("translation")(History);
