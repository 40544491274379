import React, { Component } from 'react'
import { Link } from "react-router-dom";
import headerLogo from "./../img/header-logo.svg";
import { translate } from 'react-i18next';

class Rules extends Component {
    render() {
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <div className="registration-rules-container registration-container">
                    <div className="registration-rules-container-content">
                        <div className="registration-rules-container-content-header">
                            <h2 className="registration-rules-container-content-header-title">{t('get acquainted with the rules')}</h2>
                        </div>
                        <ol className="registration-rules-container-content-list registration-container-description">
                            <li className="registration-rules-container-content-item">{t('rules text1')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text2')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text3')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text4')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text5')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text6')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text7')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text8')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text9')}</li>
                            <li className="registration-rules-container-content-item">{t('rules text10')}</li>
                        </ol>
                    </div>
                    <div className="registration-rules-container-footer registration-container-footer">
                        <Link to="/sign-up/not-a-bank-customer" className="registration-container-footer-button registration-container-footer-button--left">{t('i do not agree')}</Link>
                        <Link to="/registration/individual/mobile" className="registration-container-footer-button registration-container-footer-button--right registration-container-footer-button--active">{t('agree')}</Link>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(Rules);