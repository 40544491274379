import React, { Component, Fragment } from 'react';
import { Switch, Link, NavLink, Redirect, Route } from 'react-router-dom';
import { translate } from 'react-i18next';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import transferInternalIcon from './../../img/transfer-internal-icon.svg';
import cardIcon from './../../img/credit-cardcredit-card.svg';
import qrIcon from './../../img/qrcode.svg';
import friendTransferIcon from './../../img/friend-transfer-icon.svg';
import zkTransferIcon from './../../img/zk-transfer-icon.svg';
import expressIcon from './../../img/express-icon.svg';
import { request } from './../../../../config';
import transferFilter from '../../../../helpers/transferFilter';
import cardOperationFilter from '../../../../helpers/cardOperationFilter';
import YesNoModal from '../../../../copms/Modal/YesNoModal';
import Loading from '../../Loading/Loading';
import currencyFilter from '../../../../helpers/currencyFilter';

class FastMoneyTransfer extends Component {
    state = {
        signLevel: null,
        signCount: null,
        open: false,
        modalCardObj: {},
        templates: [],
        transfers: []
    }

    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    dropdownItemHandler = (index, tempIndex, url) => {
        switch (index) {
            case 1:
                this.setState({ deleteIndex: tempIndex, open: true })
                break;
        }
    }

    deleteCardTemplate(index) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let removePaymentTempCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.templates[index].tempId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.templates[index].tempId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("card/deleteCardOperationTemp", removePaymentTempCustomerRequestBody))
            .then(res => res.text())
            .then((removePaymentTempCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let removePaymentTempCustomerResponse = JSON.parse(removePaymentTempCustomerResponseJSON);
                if (removePaymentTempCustomerResponse.status.statusCode !== 1) {
                    if (removePaymentTempCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removePaymentTempCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var tempArr = this.state.templates;
                    tempArr.splice(index, 1);
                    this.setState({ templates: tempArr });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    changeDateType = (date) => {
        return {
            date: `${date.split(' ')[0].split('-')[2]}.${date.split(' ')[0].split('-')[1]}.${date.split(' ')[0].split('-')[0]}`,
            time: `${date.split(' ')[1].split(':')[0]}:${date.split(' ')[1].split(':')[1]}`
        }

    };

    componentWillMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getOperationTempListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("card/getCardOperationTempList", getOperationTempListCustomerRequestBody))
            .then(res => res.text())
            .then((getOperationTempListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOperationTempListCustomerResponse = JSON.parse(getOperationTempListCustomerResponseJSON);
                if (getOperationTempListCustomerResponse.status.statusCode !== 1 && getOperationTempListCustomerResponse.status.statusCode !== 239) {
                    if (getOperationTempListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    if (getOperationTempListCustomerResponse.status.statusCode === 1) {
                        if (getOperationTempListCustomerResponse.cardTempList) {
                            for (let i = 0; i < getOperationTempListCustomerResponse.cardTempList.length; i++) {
                                // =========AMOUNT=========
                                if (getOperationTempListCustomerResponse.cardTempList[i].amount.toString() === "0") {
                                    getOperationTempListCustomerResponse.cardTempList[i].organizedAmount = {
                                        wholePart: "0",
                                        fractionalPart: "00"
                                    }
                                }
                                else {
                                    if (getOperationTempListCustomerResponse.cardTempList[i].amount.toString().indexOf('.') !== -1) {
                                        let amountArray = getOperationTempListCustomerResponse.cardTempList[i].amount.toString().split('.');
                                        getOperationTempListCustomerResponse.cardTempList[i].organizedAmount = {
                                            wholePart: amountArray[0],
                                            fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                        }
                                    }
                                    else {
                                        getOperationTempListCustomerResponse.cardTempList[i].organizedAmount = {
                                            wholePart: getOperationTempListCustomerResponse.cardTempList[i].amount.toString(),
                                            fractionalPart: "00"
                                        }
                                    }
                                }
                                // =========NUMBER=========
                                if (getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber && getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber.length === 16) {
                                    getOperationTempListCustomerResponse.cardTempList[i].organizedDestinationCardNumber = getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber.substring(0, 4)
                                        + " **** **** " + getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber.substring(12, 16);
                                }
                                else {
                                    getOperationTempListCustomerResponse.cardTempList[i].organizedDestinationCardNumber = getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber;
                                }
                            }
                            this.setState({ templates: getOperationTempListCustomerResponse.cardTempList });
                        }
                    }
                }
                // =========STARTDATE/ENDDATE=========
                const changeDateType = () => {
                    let currentDate = new Date();
                    let date = (currentDate.getDate() < 10) ? ("0" + currentDate.getDate()) : currentDate.getDate();
                    let month = (currentDate.getMonth() + 1 < 10) ? ("0" + (currentDate.getMonth() + 1)) : (currentDate.getMonth() + 1);
                    let year = currentDate.getFullYear();
                    return date + '.' + month + '.' + year;
                }
                let getTransferListRequestBody = customerType === "Juridical" ? {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    // startDate: changeDateType(), //temporary
                    // endDate: changeDateType(),
                    startDate: '09.07.2021',
                    endDate: '09.08.2021',
                    compId: localStorage.getItem('compId'),
                } : {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    // startDate: changeDateType(), //temporary
                    // endDate: changeDateType(),
                    startDate: '09.07.2021',
                    endDate: '09.08.2021',

                }
                fetch(request('koronaPay/getTransferList', getTransferListRequestBody))
                    .then(res => res.text())
                    .then((getTransferListResponseJSON) => {
                        const JSON = require('true-json-bigint');
                        let getTransferListResponse = JSON.parse(getTransferListResponseJSON);
                        if (getTransferListResponse.status.statusCode !== 1 && getTransferListResponse.status.statusCode !== 357) {
                            if (getTransferListResponse.status.statusCode === 200) {
                                this.props.logout();
                            } else {
                                this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getTransferListResponse.status.statusCode));
                                this.setState({ isLoading: false });

                            }
                        }
                        else {
                            if (getTransferListResponse.status.statusCode === 357) {
                                getTransferListResponse.respMainTransferList = [];
                            } else {
                                getTransferListResponse.respMainTransferList.map((item, index) => {
                                    // =========AMOUNT=========
                                    // if (getTransferListResponse.transferToFriendList[index].amount.toString() === "0") {
                                    if (getTransferListResponse.respMainTransferList[index].amount.toString() === "0") {
                                        getTransferListResponse.respMainTransferList[index].organizedAmount = {
                                            wholePart: "0",
                                            fractionalPart: "00"
                                        }
                                    }
                                    else {
                                        if (getTransferListResponse.respMainTransferList[index].amount.toString().indexOf('.') !== -1) {
                                            let amountArray = getTransferListResponse.respMainTransferList[index].amount.toString().split('.');
                                            // getTransferListResponse.transferToFriendList[index].amount.toString().split('.');
                                            getTransferListResponse.respMainTransferList[index].organizedAmount = {
                                                wholePart: amountArray[0],
                                                fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                            }
                                        }
                                        else {
                                            getTransferListResponse.respMainTransferList[index].organizedAmount = {
                                                wholePart: getTransferListResponse.respMainTransferList[index].amount.toString(),
                                                fractionalPart: "00"
                                            }
                                        }
                                    }
                                })
                            }
                            this.setState({ transfers: getTransferListResponse.respMainTransferList });
                            this.setState({ isLoading: false });
                            // this.setState({ transfers: getTransferListResponse.transferToFriendList.filter(item => (item.operStatus !== 0 && item.operStatus !== 2 && item.operStatus !== 3)) });
                        }
                    },
                        (error) => {
                            this.props.history.push("/technical-break");
                        });
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel, signCount: custObject.company.signCount });
        }
    }

    render() {
        let { updateAppState, alertState, t } = this.props;
        let { open, templates, modalCardObj, operations, signLevel, signCount, transfers, deleteIndex, isLoading } = this.state;

        return (
            <Fragment>
                <YesNoModal onClose={() => this.setState({ open: false })} title={t('Are you sure you want to delete the template?')} open={open} triggerFunc={() => this.deleteCardTemplate(deleteIndex)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Fast money transfers')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">
                            <div className="dashboard-new-transfer-template-section">
                                <div className="dashboard-new-transfer-template-section-background">
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('New transfer')}</h3>
                                        </div>
                                        <ul className="dashboard-new-transfer-section-content">
                                            {/* <Link to="/dashboard/transfer/card-operation/zk-transfer" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={zkTransferIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t("Zolotaya Korona")}</p>
                                            </Link> */}
                                            <Link to="/dashboard/transfer/card-operation/express-transfer" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={expressIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t("Express transfer")}</p>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('Templates')}</h3>
                                            <Link to="/dashboard/transfer/templates/card-operation" className="dashboard-new-transfer-section-header-history">{t('all')}</Link>
                                        </div>
                                        {
                                            templates.length !== 0 ?
                                                <div className="dashboard-finance-section-cards-section-table-section">
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody>
                                                            {
                                                                templates.slice(0, 5).map((template, index) =>
                                                                    <Fragment key={index}>
                                                                        <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                                    <img src={cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).iconName} style={{ height: '15px' }} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                                </div>
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <Link to={`/dashboard/transfer/template/card-operation/${cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue}/${template.tempId}`} className="dashboard-finance-section-cards-section-cell-content">{template.tempName}</Link>
                                                                                    <Link to={`/dashboard/transfer/template/card-operation/${cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue}/${template.tempId}`} className="dashboard-finance-section-cards-section-cell-title">{template.organizedDestinationCardNumber}</Link>
                                                                                </div>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + template.currency}>{currencyFilter(template.currency)}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{template.organizedAmount.wholePart}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{template.organizedAmount.fractionalPart}</span>
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{template.cardOperationTypeValue}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                <div className="dashboard-finance-section-cards-section-button-group">
                                                                                    <Dropdown
                                                                                        dropdownItems={[
                                                                                            { title: this.props.t('Create a payment'), url: "/dashboard/transfer/template/card-operation/" + (cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue) + '/' + template.tempId },
                                                                                            { title: this.props.t('Delete') },
                                                                                        ]}
                                                                                        dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, `/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) && transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`)}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div className="dashboard-payment-operation-section-content">
                                                    <Link to="" className="dashboard-payment-operation-section-content-item">
                                                        <div className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                <img src={transferInternalIcon} style={{ height: 'auto' }} className="dashboard-payment-operation-section-content-item-icon" />
                                                            </div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title">{t('Create a new template')}</div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle">{t('card number')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                        </div>
                                                    </Link>
                                                    {
                                                        [0, 1].map(index => (
                                                            <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                                <div className="dashboard-payment-operation-section-content-item--left-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-icon-container"></div>
                                                                    <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                        <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                        <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                    <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-transfer-history">
                                <div className="dashboard-finance-section-card-section-details-section-header">
                                    <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('recent operations')}</h3>
                                    {/* <button className="dashboard-finance-section-card-section-details-section-header-show-all dashboard-last-operations-section-header-history">{t('all history')}</button> */}

                                </div>
                                {
                                    (transfers.length !== 0) ?
                                        <div className="dashboard-last-operations-section-content">
                                            <ul className="dashboard-last-operations-section-list">
                                                {
                                                    transfers.slice(0, 10).map((transfer, index) =>
                                                        <li className="dashboard-last-operations-section-item" key={index}>
                                                            <div className="dashboard-last-operations-section-item-left-side">
                                                                <div className="dashboard-last-operations-section-item-icon-container">
                                                                    <svg className="dashboard-last-operations-section-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                                <div className="dashboard-last-operations-section-item-content">
                                                                    <div className="dashboard-last-operations-section-item-company">{transfer.crName} {transfer.crSurname} {transfer.crFname}</div>
                                                                    {
                                                                        transfer.transferTypeId === 1 &&
                                                                        <div className="dashboard-last-operations-section-item-description">{transfer.country}, {transfer.city}</div>
                                                                    }
                                                                    {
                                                                        transfer.transferTypeId === 2 &&
                                                                        <div className="dashboard-last-operations-section-item-description">{transfer.operationCode}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="dashboard-last-operations-section-item-rigth-side">
                                                                <div className="dashboard-last-operations-section-item-amount">
                                                                    <span className="dashboard-last-operations-section-item-amount-whole-part">{transfer.organizedAmount.wholePart}</span>
                                                                    <span className="dashboard-last-operations-section-item-amount-fractional-part">{transfer.organizedAmount.fractionalPart}</span>
                                                                    <span className="dashboard-last-operations-section-item-amount-currency">{transfer?.currency}</span>
                                                                </div>
                                                                <p className="dashboard-last-operations-section-item-category">{transfer.dataDate && this.changeDateType(transfer.dataDate).date}</p>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        :
                                        <div className="dashboard-last-operations-section-content">
                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                            <ul className="dashboard-last-operations-section-content-invisible-list">
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(FastMoneyTransfer);