import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { request } from '../../../../../config';
import transferConfirmation from './../../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../../helpers/currencyFilter';
import FriendOperationModal from '../../../../../copms/Modal/FriendOperationModal';
import CreateZkTransferModal from '../../../../../copms/Modal/CreateZkOperationModal';
import SendOtpModal from './../../../../../copms/Modal/SendOtpModal';
import NotFound from '../../../../../copms/NotFound/NotFound';
import Loading from './../../../Loading/Loading';
import CreateCardOperationTemplate from './../../../../../copms/Modal/CreateCardOperationTemplate';
import CheckboxTick from '../../../../../copms/Checkbox/CheckboxTickDefault';
import OutsideClickHandler from 'react-outside-click-handler';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
export class ZkTransfer extends Component {
    state = {
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        confirmedOperation: false,
        cards: [],
        selectedCard: "",
        dropdownVisibilityCard: false,
        cardIdCount: 0,
        cardId: null,
        cardNumber: null,
        cardIdValidation: false,
        cardData: false,

        payAmount: "",
        payAmountOrganized: {},
        payAmountValidation: false,
        payAmountOutline: false,

        receiveAmount: "",
        receiveAmountOrganized: {},
        receiveAmountValidation: false,
        receiveAmountOutline: false,

        openCreateModal: false,
        modalData: null,
        successTitle: null,
        errorTitle: null,
        isError: false,

        dropdownVisibilityCountry: false,
        countries: [],
        selectedCountryIndex: -1,
        selectedCountryCount: 0,

        dropdownVisibilityCity: false,
        cities: [],
        selectedCityIndex: -1,
        selectedCityCount: 0,

        dropdownVisibilityCurrency: false,

        currencies: [],
        dropdownVisibilityPayCurrency: false,
        selectedPayCurrencyIndex: -1,
        selectedPayCurrencyCount: 0,

        allCurrencies: [],
        dropdownVisibilityReceiveCurrency: false,
        selectedReceiveCurrencyIndex: -1,
        selectedReceiveCurrencyCount: 0,

        crName: "",
        crSurname: "",
        crFname: "",
        crMobile: "",
        crNameOutline: false,
        crSurnameOutline: false,
        crFnameOutline: false,
        crMobileOutline: false,

        dtFeeAmount: null,

        openOtp: false,

        tempCityId: null,
        tempCurrency: null,

        openOtp: false,
        openTemp: false,
        isLoading: false,

        countryFilter: '',
        cityFilter: '',

        limit: {},

        tranferType: 0,
        readTerm: false,
        confirmTerm: false
    }

    changeDropdownVisibilityCard = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCard: !this.state.dropdownVisibilityCard }, () => {
            if (!this.state.cardIdValidation) {
                this.setState({ cardIdCount: ++this.state.cardIdCount });
            }
        });
    }

    changeDropdownVisibilityPayCurrency = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityPayCurrency: !this.state.dropdownVisibilityPayCurrency }, () => {
            if (!this.state.payCurrencyValidation) {
                this.setState({ payCurrencyCount: ++this.state.payCurrencyCount });
            }
        });
    }

    changeDropdownVisibilityReceiveCurrency = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityReceiveCurrency: !this.state.dropdownVisibilityReceiveCurrency }, () => {
            if (!this.state.receiveCurrencyValidation) {
                this.setState({ receiveCurrencyCount: ++this.state.receiveCurrencyCount });
            }
        });
    }

    updateCard(card, event) {
        event.preventDefault();
        this.setState({
            cardId: card.cardId,
            cardNumber: card.cardNumber,
            selectedCard: event.target.textContent,
            dropdownVisibilityCard: false,
            cardIdValidation: true,
            cardCurrency: card.currency,
            cardCurrencyId: card.cardCcyId,
        });
    }

    showCards = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            currency: this.state.currencies[this.state.selectedPayCurrencyIndex]?.valName,
            curCode: this.state.currencies[this.state.selectedPayCurrencyIndex]?.tbName
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            currency: this.state.currencies[this.state.selectedPayCurrencyIndex]?.valName,
            curCode: this.state.currencies[this.state.selectedPayCurrencyIndex]?.tbName
        }

        return fetch(request("koronaPay/getCardListByCurrency", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }
                    this.setState({ isLoading: false });
                    this.setState({
                        cards: getCardListForCustomerResponse.cardList,
                        limit: {
                            amount: getCardListForCustomerResponse.limitAmount,
                            currency: this.state.currencies.find(item => item.tbName == getCardListForCustomerResponse.limitAmountCcyId)?.valName
                        },
                        cardData: true
                    }, () => {
                        if (this.state.cardNumber) {
                            this.setState({
                                cardId: this.state.cards.find(el => el.cardNumber === this.state.cardNumber).cardId,
                                selectedCard: this.state.cards.find(el => el.cardNumber === this.state.cardNumber).organizedCardNumber + ' / ' + this.state.cards.find(el => el.cardNumber === this.state.cardNumber).balance + ' ' + this.state.cards.find(el => el.cardNumber === this.state.cardNumber).currency,
                                cardCurrency: this.state.cards.find(el => el.cardNumber === this.state.cardNumber).currency,
                                cardCurrencyId: this.state.cards.find(el => el.cardNumber === this.state.cardNumber).cardCcyId,
                                cardIdValidation: true
                            })
                        }
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showAllCurrencies = async () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAllCurrencyListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.cities[this.state.selectedCityIndex].id
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.cities[this.state.selectedCityIndex].id
        }

        return await fetch(request("koronaPay/getAllCurrencyList", getAllCurrencyListRequestBody))
            .then(res => res.text())
            .then((getAllCurrencyListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAllCurrencyListResponse = JSON.parse(getAllCurrencyListResponseJSON);
                if (getAllCurrencyListResponse.status.statusCode !== 1 && getAllCurrencyListResponse.status.statusCode !== '????????') {
                    if (getAllCurrencyListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAllCurrencyListResponse.status.statusCode));
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
                else {
                    if (getAllCurrencyListResponse.status.statusCode === '????????') {
                        getAllCurrencyListResponse.respMainCurrencyList = [];
                    }


                    this.setState({
                        allCurrencies: getAllCurrencyListResponse.respMainCurrencyList, statusData: true
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    fxFeeAmountToPay = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let fxFeeAmountToPayRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            toCurrency: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            payAmount: this.state.payAmount,
            payAmountCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            ccy: this.state.currencies[this.state.selectedPayCurrencyIndex].valName
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            toCurrency: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            payAmount: this.state.payAmount,
            payAmountCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            ccy: this.state.currencies[this.state.selectedPayCurrencyIndex].valName
        }
        fetch(request("koronaPay/fxFeeAmountToPay", fxFeeAmountToPayRequestBody))
            .then(res => res.text())
            .then((fxFeeAmountToPayResponseJSON) => {
                const JSON = require('true-json-bigint');
                let fxFeeAmountToPayResponse = JSON.parse(fxFeeAmountToPayResponseJSON);
                if (fxFeeAmountToPayResponse.status.statusCode !== 1) {
                    if (fxFeeAmountToPayResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ openOtp: false, createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(fxFeeAmountToPayResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            receiveAmount: '',
                            receiveAmountValidation: false,
                            receiveAmountOutline: true,
                            payAmountValidation: false,
                            payAmountOutline: true,
                            dtFeeAmount: {}
                        });
                    }
                }
                else {
                    this.setState({
                        payAmount: fxFeeAmountToPayResponse.payAmount.toString(),
                        receiveAmount: fxFeeAmountToPayResponse.receiveAmount.toString(),
                        receiveAmountValidation: true,
                        receiveAmountOutline: false,
                        dtFeeAmount: {
                            feeTotal: fxFeeAmountToPayResponse.feeTotal,
                            feeTotalCurCode: fxFeeAmountToPayResponse.feeTotalCurCode,
                            currency: this.state.currencies.find(currency => currency.tbName == fxFeeAmountToPayResponse.feeTotalCurCode).valName
                        },
                        payAmountValidation: true,
                        payAmountOutline: false,
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    checkPayAmount = (event) => {
        if (/^[0-9]*$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({ payAmount: event.target.value, tranferType: 1 }, () => {
                if (this.state.payAmount.length === 0 || this.state.payAmount == 0) {
                    this.setState({
                        payAmountValidation: false,
                        payAmountOutline: true
                    });
                }
                else {
                    this.setState({
                        payAmountValidation: true,
                        payAmountOutline: false,
                        receiveAmount: "",
                        receiveAmountValidation: false,
                    })
                }
            })
        }
    }

    fxFeeAmountToReceive = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let fxFeeAmountToReceiveRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            payCurrency: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            receiveAmount: this.state.receiveAmount,
            receiveAmountCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            payCurrency: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            receiveAmount: this.state.receiveAmount,
            receiveAmountCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName
        }
        fetch(request("koronaPay/fxFeeAmountToReceive", fxFeeAmountToReceiveRequestBody))
            .then(res => res.text())
            .then((fxFeeAmountToReceiveResponseJSON) => {
                const JSON = require('true-json-bigint');
                let fxFeeAmountToReceiveResponse = JSON.parse(fxFeeAmountToReceiveResponseJSON);
                if (fxFeeAmountToReceiveResponse.status.statusCode !== 1) {
                    if (fxFeeAmountToReceiveResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ openOtp: false, createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(fxFeeAmountToReceiveResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            payAmount: '',
                            payAmountValidation: false,
                            payAmountOutline: true,
                            receiveAmountValidation: false,
                            payAmountOutline: true,
                            dtFeeAmount: {}
                        });
                    }
                }
                else {
                    this.setState({
                        payAmount: fxFeeAmountToReceiveResponse.payAmount.toString(),
                        payAmountValidation: true,
                        payAmountOutline: false,
                        dtFeeAmount: {
                            feeTotal: fxFeeAmountToReceiveResponse.feeTotal,
                            feeTotalCurCode: fxFeeAmountToReceiveResponse.feeTotalCurCode,
                            currency: this.state.currencies.find(currency => currency.tbName == fxFeeAmountToReceiveResponse.feeTotalCurCode).valName
                        },
                        receiveAmountValidation: true,
                        receiveAmountOutline: false,
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    checkReceiveAmount = (event) => {
        if (/^[0-9]*$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({
                receiveAmount: event.target.value,
                tranferType: (this.state.currencies[this.state.selectedPayCurrencyIndex].tbName === this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName ? 3 : 2)
            }, () => {
                if (this.state.receiveAmount.length === 0 || this.state.receiveAmount == 0) {
                    this.setState({
                        receiveAmountValidation: false,
                    });
                }
                else {
                    this.setState({
                        receiveAmountValidation: true,
                        receiveAmountOutline: false,
                        payAmount: "",
                        payAmountValidation: false,
                    })
                }
            })
        }
    }

    setPayAmountOutline = () => {
        if (!this.state.payAmountValidation) {
            this.setState({ payAmountOutline: true });
        }
        else {
            this.setState({ payAmountOutline: false });
        }
    }

    setReceiveAmountOutline = () => {
        if (!this.state.receiveAmountValidation) {
            this.setState({ receiveAmountOutline: true });
        }
        else {
            this.setState({ receiveAmountOutline: false });
        }
    }

    tariffCalculation = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let tariffCalculationRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            receiveAmount: this.state.receiveAmount,
            receiveAmountCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].name
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            receiveAmount: this.state.receiveAmount,
            receiveAmountCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].name
        }
        fetch(request("koronaPay/tariffCalculation", tariffCalculationRequestBody))
            .then(res => res.text())
            .then((tariffCalculationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let tariffCalculationResponse = JSON.parse(tariffCalculationResponseJSON);
                if (tariffCalculationResponse.status.statusCode !== 1) {
                    if (tariffCalculationResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(tariffCalculationResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({
                            payAmount: '',
                            payAmountValidation: false,
                            payAmountOutline: true,
                            receiveAmountValidation: false,
                            receiveAmountOutline: true,
                            dtFeeAmount: {}
                        })
                    }
                }
                else {
                    this.setState({
                        payAmount: tariffCalculationResponse.payAmount.toString(),
                        payAmountValidation: true,
                        payAmountOutline: false,
                        dtFeeAmount: {
                            feeTotal: tariffCalculationResponse.feeTotal,
                            feeTotalCurCode: tariffCalculationResponse.feeTotalCurCode,
                            currency: this.state.currencies.find(currency => currency.tbName == tariffCalculationResponse.feeTotalCurCode).valName
                        },
                        receiveAmountValidation: true,
                        receiveAmountOutline: false,
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showZkTransfer = () => {
        if (this.state.limit.amount && this.state.limit.currency && this.state.cardCurrencyId != this.state.currencies[this.state.selectedPayCurrencyIndex].tbName && Number(this.state.payAmount) <= this.state.limit.amount) {
            this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t('Zk limit amount content', { limitAmount: this.state.limit.amount, limitCurrency: this.state.limit.currency }) });
        }
        else {
            this.setState({
                open: false,
                createFeedbackMessage: false,
                feedbackErrorModal: null
            });
            let amountOrganized;
            let amount = this.state.payAmount;
            // =========AMOUNT=========
            if (amount.toString() === "0") {
                amountOrganized = {
                    wholePart: "0",
                    fractionalPart: "00"
                }
            }
            else {
                if (amount.toString().indexOf('.') !== -1) {
                    let currentBalanceArray = amount.toString().split('.');
                    amountOrganized = {
                        wholePart: currentBalanceArray[0],
                        fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                    }
                }
                else {
                    amountOrganized = {
                        wholePart: amount.toString(),
                        fractionalPart: "00"
                    }
                }
            }
            this.setState({ payAmountOrganized: amountOrganized, confirmedOperation: true });
        }
    }

    submitConditions = (conditionsStatusId) => {
        let submitConditionsRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            countryId: this.state.countries[this.state.selectedCountryIndex].id,
            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            cityId: this.state.cities[this.state.selectedCityIndex].id,
            toCityID: this.state.cities[this.state.selectedCityIndex].zkCityId,
            city: this.state.cities[this.state.selectedCityIndex].cityName,
            country: this.state.countries[this.state.selectedCountryIndex].name,
            reqCardId: this.state.cardId,
            reqCardNumber: this.state.cardNumber,
            reqCardCurrency: this.state.cardCurrency,
            reqCardCcyId: this.state.cardCurrencyId,
            crSurname: this.state.crSurname,
            crName: this.state.crName,
            crFname: this.state.crFname,
            crMobileNumber: this.state.crMobile,
            payAmount: this.state.payAmount,
            receiveAmount: this.state.receiveAmount,
            payCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            payAmountCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            receiveCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            receiveAmountCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            feeTotal: this.state.dtFeeAmount.feeTotal,
            feeTotalCurCode: this.state.dtFeeAmount.feeTotalCurCode,
            currency: this.state.currencies[this.state.selectedPayCurrencyIndex].valName,
            conditionsStatusId: conditionsStatusId ? 1 : 0
        }
        if (localStorage.getItem('compId')) {
            submitConditionsRequestBody.compId = localStorage.getItem('compId');
        }
        fetch(request(`koronaPay/submitConditions`, submitConditionsRequestBody))
            .then(res => res.text())
            .then((submitConditionsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let submitConditionsResponse = JSON.parse(submitConditionsResponseJSON);
                if (submitConditionsResponse.status.statusCode !== 1) {
                    if (submitConditionsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ openOtp: false, createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(submitConditionsResponse.status.statusCode) });
                    }
                }
                else {
                    if (!conditionsStatusId) {
                        this.cancel();
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    cancel = () => {
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null,
            confirmedOperation: false,
            cards: [],
            selectedCard: "",
            dropdownVisibilityCard: false,
            cardIdCount: 0,
            cardId: null,
            cardNumber: null,
            cardCurrency: null,
            cardCurrencyId: null,
            cardIdValidation: false,
            cardData: false,

            payAmount: "",
            payAmountOrganized: {},
            payAmountValidation: false,
            payAmountOutline: false,

            receiveAmount: "",
            receiveAmountOrganized: {},
            receiveAmountValidation: false,
            receiveAmountOutline: false,

            openCreateModal: false,
            modalData: null,
            successTitle: null,
            errorTitle: null,
            isError: false,

            dropdownVisibilityCountry: false,
            selectedCountryIndex: -1,
            selectedCountryCount: 0,

            dropdownVisibilityCity: false,
            cities: [],
            selectedCityIndex: -1,
            selectedCityCount: 0,

            currencies: [],
            dropdownVisibilityPayCurrency: false,
            selectedPayCurrencyIndex: -1,
            selectedPayCurrencyCount: 0,

            allCurrencies: [],
            dropdownVisibilityReceiveCurrency: false,
            selectedReceiveCurrencyIndex: -1,
            selectedReceiveCurrencyCount: 0,

            crName: "",
            crSurname: "",
            crFname: "",
            crMobile: "+",
            crNameOutline: false,
            crSurnameOutline: false,
            crFnameOutline: false,
            crMobileOutline: false,

            dtFeeAmount: null,

            openOtp: false,
            openTemp: false,

            countryFilter: '',
            cityFilter: '',

            limit: {},

            tranferType: 0,

            readTerm: false,
            confirmTerm: false
        })
    }

    createZkTransfer = (confirmCode) => {
        this.setState({ confirmedOperation: true });
        this.setState({
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null,

            openCreateModal: false,
            modalData: null,
            successTitle: null,
            errorTitle: null,
            isError: false
        });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createZkTransferRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            countryId: this.state.countries[this.state.selectedCountryIndex].id,
            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            cityId: this.state.cities[this.state.selectedCityIndex].id,
            toCityID: this.state.cities[this.state.selectedCityIndex].zkCityId,
            city: this.state.cities[this.state.selectedCityIndex].cityName,
            country: this.state.countries[this.state.selectedCountryIndex].name,
            reqCardId: this.state.cardId,
            reqCardNumber: this.state.cardNumber,
            reqCardCurrency: this.state.cardCurrency,
            reqCardCcyId: this.state.cardCurrencyId,
            crSurname: this.state.crSurname,
            crName: this.state.crName,
            crFname: this.state.crFname,
            crMobileNumber: this.state.crMobile,
            payAmount: this.state.payAmount,
            receiveAmount: this.state.receiveAmount,
            confirmCode: confirmCode,
            payCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            payAmountCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            receiveCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            receiveAmountCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            feeTotal: this.state.dtFeeAmount.feeTotal,
            feeTotalCurCode: this.state.dtFeeAmount.feeTotalCurCode,
            currency: this.state.currencies[this.state.selectedPayCurrencyIndex].valName
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            countryId: this.state.countries[this.state.selectedCountryIndex].id,
            toCountryISO: this.state.cities[this.state.selectedCityIndex].countryCode,
            cityId: this.state.cities[this.state.selectedCityIndex].id,
            toCityID: this.state.cities[this.state.selectedCityIndex].zkCityId,
            city: this.state.cities[this.state.selectedCityIndex].cityName,
            country: this.state.countries[this.state.selectedCountryIndex].name,
            reqCardId: this.state.cardId,
            reqCardNumber: this.state.cardNumber,
            reqCardCurrency: this.state.cardCurrency,
            reqCardCcyId: this.state.cardCurrencyId,
            crSurname: this.state.crSurname,
            crName: this.state.crName,
            crFname: this.state.crFname,
            crMobileNumber: this.state.crMobile,
            payAmount: this.state.payAmount,
            receiveAmount: this.state.receiveAmount,
            confirmCode: confirmCode,
            payCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            payAmountCurCode: this.state.currencies[this.state.selectedPayCurrencyIndex].tbName,
            receiveCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            receiveAmountCurCode: this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName,
            feeTotal: this.state.dtFeeAmount.feeTotal,
            feeTotalCurCode: this.state.dtFeeAmount.feeTotalCurCode,
            currency: this.state.currencies[this.state.selectedPayCurrencyIndex].valName
        }
        fetch(request(`koronaPay/${this.state.tranferType === 1 ? 'fxTransferAmountToPay' : (this.state.tranferType === 2 ? 'fxTransferAmountToReceive' : 'transfer')}`, createZkTransferRequestBody))
            .then(res => res.text())
            .then((createZkTransferResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createZkTransferResponse = JSON.parse(createZkTransferResponseJSON);
                if (createZkTransferResponse.status.statusCode !== 1) {
                    if (createZkTransferResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ openOtp: false, createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createZkTransferResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let organizedAmount, organizedFeeAmount;
                    // =========AMOUNT=========
                    if (createZkTransferResponse.payAmount.toString() === "0") {
                        organizedAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (createZkTransferResponse.payAmount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = createZkTransferResponse.payAmount.toString().split('.');
                            organizedAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            organizedAmount = {
                                wholePart: createZkTransferResponse.payAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    organizedAmount.currency = createZkTransferResponse.currency;

                    // =========FEE=========
                    if (createZkTransferResponse.feeTotal.toString() === "0") {
                        organizedFeeAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (createZkTransferResponse.feeTotal.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = createZkTransferResponse.feeTotal.toString().split('.');
                            organizedFeeAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            organizedFeeAmount = {
                                wholePart: createZkTransferResponse.feeTotal.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    organizedFeeAmount.currency = createZkTransferResponse.currency;

                    // =========================
                    let data = {
                        crName: createZkTransferResponse?.crName,
                        crSurname: createZkTransferResponse?.crSurname,
                        crFname: createZkTransferResponse?.crFname,
                        organizedAmount: organizedAmount,
                        organizedFeeAmount: organizedFeeAmount,
                        crCity: createZkTransferResponse?.city,
                        crCountry: createZkTransferResponse?.country,
                        operationCode: createZkTransferResponse?.operationCode,
                        rrn: createZkTransferResponse?.rrn
                    }

                    this.setState({ openOtp: false, openCreateModal: true, modalData: data, isError: false, successTitle: this.props.t('The payment order was created successfully') });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    downloadTariffPdf = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadTariffPdf", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Qaydalar') + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateOpen = (value) => {
        this.setState({ open: value });
    }

    updateOpenTemp = (value) => {
        this.setState({ openTemp: value });
    }

    showCountries = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCountryListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        return await fetch(request("koronaPay/getCountryList", getCountryListRequestBody))
            .then(res => res.text())
            .then((getCountryListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCountryListResponse = JSON.parse(getCountryListResponseJSON);
                if (getCountryListResponse.status.statusCode !== 1) {
                    if (getCountryListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });

                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCountryListResponse.status.statusCode));
                    }
                }
                else {
                    if (getCountryListResponse.Country && getCountryListResponse.Country.length > 0) {
                        this.setState({
                            countries: getCountryListResponse.Country
                        });
                    }
                    else {
                        this.setState({
                            countries: []
                        });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCities = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCityListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            reqCountry: { id: this.state.countries[this.state.selectedCountryIndex].id }
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            reqCountry: { id: this.state.countries[this.state.selectedCountryIndex].id }
        }
        fetch(request("koronaPay/getCityList", getCityListRequestBody))
            .then(res => res.text())
            .then((getCityListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCityListResponse = JSON.parse(getCityListResponseJSON);
                if (getCityListResponse.status.statusCode !== 1) {
                    if (getCityListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCityListResponse.status.statusCode));
                    }
                }
                else {

                    if (getCityListResponse.respMainCity && getCityListResponse.respMainCity.length > 0) {
                        this.setState({
                            cities: getCityListResponse.respMainCity
                        }, () => {
                            this.setState({
                                selectedCityIndex: this.state.cities.findIndex((city => city.id === this.state.tempCityId))
                            }, () => {
                                if (this.state.selectedCityIndex >= 0) {
                                    this.setState({ cityFilter: this.state.cities[this.state.selectedCityIndex].cityName });
                                    this.showCurrencies();
                                    this.showAllCurrencies();
                                }
                            })
                        });
                    }
                    else {
                        this.setState({
                            cities: []
                        });
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCurrencies = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCurrencyListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.cities[this.state.selectedCityIndex].id
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: this.state.cities[this.state.selectedCityIndex].id
        }
        fetch(request("koronaPay/getCurrencyList", getCurrencyListRequestBody))
            .then(res => res.text())
            .then((getCurrencyListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCurrencyListResponse = JSON.parse(getCurrencyListResponseJSON);
                if (getCurrencyListResponse.status.statusCode !== 1) {
                    if (getCurrencyListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCurrencyListResponse.status.statusCode));
                    }
                }
                else {
                    if (getCurrencyListResponse.respMainCurrencyList && getCurrencyListResponse.respMainCurrencyList.length > 0) {
                        this.setState({
                            currencies: getCurrencyListResponse.respMainCurrencyList
                        }, () => {
                            if (this.state.tempCurrency !== null) {
                                this.setState({
                                    selectedPayCurrencyIndex: this.state.currencies.findIndex((currency => currency.valName === this.state.tempCurrency))
                                }, () => {
                                    if (this.state.selectedPayCurrencyIndex >= 0) {
                                        this.showCards();
                                    }
                                })
                            }
                        });
                    }
                    else {
                        this.setState({
                            currencies: []
                        });
                    }

                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateCountry = (index, event) => {
        event.preventDefault();
        this.setState({
            cityFilter: '',
            countryFilter: this.state.countries[index].name,
            crMobile: '+' + this.state.countries[index].mobileCode,
            selectedCountryIndex: index, cities: [], currencies: [], allCurrencies: [], selectedCityIndex: -1, tempCityId: null, selectedCityCount: 0, selectedPayCurrencyIndex: -1, tempCurrency: null, selectedPayCurrencyCount: 0, selectedReceiveCurrencyIndex: -1, selectedReceiveCurrencyCount: 0, dropdownVisibilityCity: false,
            selectedCard: "",
            cardCurrencyId: null,
            cardIdCount: 0,
            cardId: null,
            cardNumber: null,
            dtCardCurrency: null,
            dtCardCcyId: null,
            cardIdValidation: false,
            payAmount: '',
            payAmountValidation: false,
            receiveAmount: '',
            receiveAmountValidation: false,
            tranferType: 0
        }, () => {
            this.showCities();
        })
    }

    updateCity = (index, event) => {
        event.preventDefault();
        this.setState({
            cityFilter: this.state.cities[index].cityName,
            selectedCityIndex: index, currencies: [], allCurrencies: [], tempCurrency: null, selectedPayCurrencyIndex: -1, selectedPayCurrencyCount: 0, selectedReceiveCurrencyIndex: -1, selectedReceiveCurrencyCount: 0, dropdownVisibilityCity: false,
            selectedCard: "",
            cardCurrencyId: null,
            cardIdCount: 0,
            cardId: null,
            cardNumber: null,
            dtCardCurrency: null,
            dtCardCcyId: null,
            cardIdValidation: false,
            payAmount: '',
            payAmountValidation: false,
            receiveAmount: '',
            receiveAmountValidation: false,
            tranferType: 0
        }, () => {
            this.showCurrencies();
            this.showAllCurrencies();
        });
    }

    updatePayCurrency = (index, event) => {
        event.preventDefault();
        this.setState({
            selectedPayCurrencyIndex: index,
            dropdownVisibilityPayCurrency: false,
            cardIdCount: 0,
            selectedCard: "",
            cardCurrencyId: null,
            cardId: null,
            cardNumber: null,
            dtCardCurrency: null,
            dtCardCcyId: null,
            cardIdValidation: false,
        }, () => {
            this.showCards();
            this.setState({
                payAmount: '',
                payAmountValidation: false,
                receiveAmount: '',
                receiveAmountValidation: false
            });
            // if (this.state.selectedReceiveCurrencyIndex >= 0 && (this.state.receiveAmount.length > 0 && this.state.receiveAmount != 0)) {
            //     this.fxFeeAmountToReceive();
            // }
        });
    }

    updateReceiveCurrency = (index, event) => {
        event.preventDefault();
        this.setState({
            selectedReceiveCurrencyIndex: index,
            dropdownVisibilityReceiveCurrency: false,
        }, () => {
            // if (this.state.selectedPayCurrencyIndex >= 0 && (this.state.payAmount.length > 0 && this.state.payAmount != 0)) {
            //     if (this.state.currencies[this.state.selectedPayCurrencyIndex].tbName === this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex].tbName) {
            //         this.tariffCalculation();
            //     }
            //     else {
            //         this.fxFeeAmountToPay();
            //     }
            // }
            this.setState({
                payAmount: '',
                payAmountValidation: false,
                receiveAmount: '',
                receiveAmountValidation: false
            })
        });
    }

    checkCrName = (e) => {
        let mask = /[^a-zA-Z\- ]/g;
        e.target.value = e.target.value.toUpperCase();
        if (e.target.value !== e.target.value.replace(mask, '')) {
            e.target.value = e.target.value.replace(mask, '');
        }
        this.setState({ crName: e.target.value }, () => {
            if (this.state.crName.trim().length > 0) {
                this.setState({ crNameOutline: false });
            }
        });
    }

    setCrNameOutline = () => {
        if (this.state.crName.trim().length > 0) {
            this.setState({ crNameOutline: false });
        }
        else {
            this.setState({ crNameOutline: true });
        }
    }

    checkCrSurname = (e) => {
        let mask = /[^a-zA-Z\- ]/g;
        e.target.value = e.target.value.toUpperCase();
        if (e.target.value !== e.target.value.replace(mask, '')) {
            e.target.value = e.target.value.replace(mask, '');
        }
        this.setState({ crSurname: e.target.value }, () => {
            if (this.state.crSurname.trim().length > 0) {
                this.setState({ crSurnameOutline: false });
            }
        });
    }

    setCrSurnameOutline = () => {
        if (this.state.crSurname.trim().length > 0) {
            this.setState({ crSurnameOutline: false });
        }
        else {
            this.setState({ crSurnameOutline: true });
        }
    }

    checkCrFname = (e) => {
        let mask = /[^a-zA-Z\- ]/g;
        e.target.value = e.target.value.toUpperCase();
        if (e.target.value !== e.target.value.replace(mask, '')) {
            e.target.value = e.target.value.replace(mask, '');
        }
        this.setState({ crFname: e.target.value }, () => {
            if (this.state.crFname.trim().length > 0) {
                this.setState({ crFnameOutline: false });
            }
        });
    }

    checkCrMobile = (e) => {
        let mask = /[^0-9+]/g; //temporay
        if (e.target.value !== e.target.value.replace(mask, '')) {
            e.target.value = e.target.value.replace(mask, '');
        }
        this.setState({ crMobile: e.target.value }, () => {
            if (this.state.crMobile.trim().length > 0) {
                this.setState({ crMobileOutline: false });
            }
        });
    }

    async getTempById() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        await fetch(request("card/getCardOperationTempById", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardOperationTempByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardOperationTempByIdResponse = JSON.parse(getCardOperationTempByIdResponseJSON);
                if (getCardOperationTempByIdResponse.status.statusCode !== 1 && getCardOperationTempByIdResponse.status.statusCode !== 137) {
                    if (getCardOperationTempByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let amountOrganized = {};
                    if (getCardOperationTempByIdResponse.amount.toString() === "0") {
                        amountOrganized = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getCardOperationTempByIdResponse.amount.toString().indexOf('.') !== -1) {
                            let amountArray = getCardOperationTempByIdResponse.amount.toString().split('.');
                            amountOrganized = {
                                wholePart: amountArray[0],
                                fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                            }
                        }
                        else {
                            amountOrganized = {
                                wholePart: getCardOperationTempByIdResponse.amount.toString(),
                                fractionalPart: "00"
                            }
                        }
                        this.setState({ amountOrganized });
                    }
                    this.setState({

                        tempHave: true,
                        amountValidation: true,
                        // cardIdValidation: true,
                        payAmount: getCardOperationTempByIdResponse.amount?.toString(),
                        payAmountValidation: true,
                        payAmountOutline: false,
                        crName: getCardOperationTempByIdResponse.crName,
                        crSurname: getCardOperationTempByIdResponse.crSurname,
                        crFname: getCardOperationTempByIdResponse.crFname,
                        crMobile: getCardOperationTempByIdResponse.mobile,
                        selectedCountryIndex: this.state.countries.findIndex((country => country.id === getCardOperationTempByIdResponse.crCountryId)),
                        tempCityId: getCardOperationTempByIdResponse.crCityId,
                        tempCurrency: getCardOperationTempByIdResponse.currency,
                        cardNumber: getCardOperationTempByIdResponse.requestorCardNumber,
                    }, () => {
                        this.setState({ countryFilter: this.state.countries[this.state.selectedCountryIndex].name });
                        this.showCities();
                    });
                }
            });

    }
    componentDidMount = async () => {
        this.setState({ isLoading: true });
        this.getCardOperationInfo();
        await this.showCountries();
        await this.getTempById();
        this.props.updateAppState('isLoadingTransparentBackground', false);
    }

    sendOtpCode = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        this.props.updateAppState('isLoadingTransparentBackground', true);
        clearInterval(this.state.timerInterval);
        this.setState({ feedbackMessage: null, isDisabled: true, openOtp: false, buttonDisabled: true });
        let sendOtpCodeRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            mobile: JSON.parse(localStorage.getItem('custObject')).customer.mobile,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

        } : {
            custId: localStorage.getItem('custId'),
            mobile: JSON.parse(localStorage.getItem('custObject')).mobile,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

        }

        fetch(request("register/sendOtpCode", sendOtpCodeRequestBody))
            .then(res => res.json())
            .then((sendOtpCodeRequestResponse) => {
                if (sendOtpCodeRequestResponse.status.statusCode === 1) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ openOtp: true });
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({ feedbackMessage: this.props.t(sendOtpCodeRequestResponse.status.statusCode) });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateCountryFilter = (e) => {
        this.setState({
            countryFilter: e.target.value,
            crMobile: '+',
            selectedCountryIndex: -1,
            cities: [],
            currencies: [],
            selectedCityIndex: -1,
            selectedCityCount: 0,
            selectedPayCurrencyIndex: -1,
            selectedPayCurrencyCount: 0,
            selectedReceiveCurrencyIndex: -1,
            selectedReceiveCurrencyCount: 0,
            selectedCard: "",
            cardCurrencyId: null,
            cardIdCount: 0,
            cardId: null,
            cardNumber: null,
            dtCardCurrency: null,
            dtCardCcyId: null,
            cardIdValidation: false,
            cityFilter: '',
            payAmount: '',
            payAmountValidation: false,
            receiveAmount: '',
            receiveAmountValidation: false,
            tranferType: 0
        })
    }

    updateCountryCount = () => {
        this.setState({ dropdownVisibilityCountry: true });
        if (this.state.selectedCountryIndex < 0) {
            this.setState({ selectedCountryCount: ++this.state.selectedCountryCount });
        }
    }

    updateCityFilter = (e) => {
        this.setState({
            cityFilter: e.target.value,
            selectedCityIndex: -1,
            currencies: [],
            selectedPayCurrencyIndex: -1,
            selectedPayCurrencyCount: 0,
            selectedReceiveCurrencyIndex: -1,
            selectedReceiveCurrencyCount: 0,
            cardIdCount: 0,
            selectedCard: "",
            cardCurrencyId: null,
            cardId: null,
            cardNumber: null,
            dtCardCurrency: null,
            dtCardCcyId: null,
            cardIdValidation: false,
            payAmount: '',
            payAmountValidation: false,
            receiveAmount: '',
            receiveAmountValidation: false,
            tranferType: 0
        })
    }

    updateCityCount = () => {
        this.setState({ dropdownVisibilityCity: true });
        if (this.state.selectedCityIndex < 0) {
            this.setState({ selectedCityCount: ++this.state.selectedCityCount });
        }
    }

    checkTransfer = () => {
        this.submitConditions(true);
        this.sendOtpCode();
        this.setState({ openOtp: true });
    }

    convertation = () => {
        switch (this.state.tranferType) {
            case 1:
                this.fxFeeAmountToPay();
                break;
            case 2:
                this.fxFeeAmountToReceive();
                break;
            case 3:
                this.tariffCalculation();
                break;
            default:
                break;
        }
    }

    getCardOperationInfo = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardOperationInfoRequest = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "ZOLOTAYA_KORONA"
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "ZOLOTAYA_KORONA"
        }
        await fetch(request("util/getCardOperationInfo", getCardOperationInfoRequest))
            .then(res => res.json())
            .then((getCardOperationInfoResponse) => {
                if (getCardOperationInfoResponse.status.statusCode !== 1) {
                    if (getCardOperationInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardOperationInfoResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ info: getCardOperationInfoResponse.info });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    
    openTerm = () => {
        this.setState({ readTerm: true }, () => {
            window.open("https://koronapay.com/transfers/Documents/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D0%BE%D1%81%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%B4%D0%B5%D0%BD%D0%B5%D0%B6%D0%BD%D1%8B%D1%85%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4%D0%BE%D0%B2%20%D0%97%D0%9A%D0%94%D0%9F.pdf", "_blank");
        });
    }

    render() {
        let { t, history } = this.props;
        let { isLoading, tempHave, openTemp, confirmedOperation, open, cards, selectedCard, selectedCurrency, dropdownVisibilityCard,
            createFeedbackMessage, feedbackErrorModal, amountOrganized, payAmount, payAmountValidation, receiveAmount, receiveAmountValidation, payAmountOutline, receiveAmountOutline,
            mobileNumberValidation, cardIdValidation, cardData,
            amountOutline, cardIdCount, currencyCount, mobileNumber, mobileNumberOutline, comment,
            dtFeeAmount, destCustName, cardNumber,
            openCreateModal, modalData, isError, errorTitle, successTitle,
            countries, selectedCountryIndex, dropdownVisibilityCountry, selectedCountryCount,
            dropdownVisibilityCity, cities, selectedCityIndex, selectedCityCount,
            currencies, selectedPayCurrencyIndex, selectedReceiveCurrencyIndex, selectedPayCurrencyCount,
            dropdownVisibilityPayCurrency, selectedReceiveCurrencyCount, dropdownVisibilityReceiveCurrency, allCurrencies,
            crName, crSurname, crFname, crMobile, crNameOutline, crSurnameOutline, crFnameOutline, crMobileOutline, openOtp, countryFilter, cityFilter, limit,
            payAmountOrganized, info, readTerm, confirmTerm
        } = this.state;
        let requestBody = localStorage.getItem('compId') === null ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            mobile: crMobile,
            crSurname: crSurname,
            amount: payAmount,
            cardOperationType: 5,
            crCityId: cities[selectedCityIndex]?.id,
            crCountryId: countries[selectedCountryIndex]?.id,
            currency: currencies[selectedPayCurrencyIndex]?.valName,
            crName: crName,
            requestorCardNumber: cardNumber,
            crFname: crFname,
            requestorCardNumber: this.state.cardNumber
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            mobile: crMobile,
            crSurname: crSurname,
            amount: payAmount,
            cardOperationType: 5,
            crCityId: cities[selectedCityIndex]?.id,
            crCountryId: countries[selectedCountryIndex]?.id,
            currency: currencies[selectedPayCurrencyIndex]?.valName,
            crName: crName,
            requestorCardNumber: cardNumber,
            crFname: crFname,
            requestorCardNumber: this.state.cardNumber
        };
        return (
            <Fragment>
                <CreateCardOperationTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={'saveCardOperationTemp'} requestBody={requestBody} />
                <FriendOperationModal open={open} updateOpen={this.updateOpen} successClose={() => { history.push('/dashboard/transfer/card-operation/friend-transfer/history/1') }} errorClose={() => { this.setState({ confirmedOperation: false }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <SendOtpModal buttonTitle={t('Confirm')} open={openOtp} sendOtpCode={this.sendOtpCode} signSubmit={(val) => this.createZkTransfer(val)} onClose={() => this.setState({ openOtp: false })} />
                <CreateZkTransferModal
                    // data={{}} open={true}
                    data={modalData} open={openCreateModal}
                    close={() => this.setState({ openCreateModal: false })} isError={isError}
                    successTitle={successTitle} errorTitle={errorTitle}
                    errorClose={() => { this.setState({ confirmedOperation: false }) }}
                    successClose={() => { history.push('/dashboard/transfer/card-operation/zk-transfer/history') }} />
                {/* <CreateFriendTransferModal data={modalData} open={openCreateModal}
                    close={() => this.setState({ openCreateModal: false })} isError={isError}
                    successTitle={successTitle} errorTitle={errorTitle}
                    errorClose={() => { this.setState({ confirmedOperation: false }) }}
                    successClose={() => { history.push('/dashboard/transfer/card-operation/friend-transfer/history/1') }} /> */}
                {/* <FriendOperationModal open={open} updateOpen={this.updateOpen} successClose={() => { history.push('/dashboard/transfer/card-operation/friend-transfer/history/1') }} errorClose={() => { this.setState({ confirmedOperation: false }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} /> */}
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !tempHave ?
                            <NotFound />
                            :
                            <Fragment>
                                <div className="dashboard-header-route">
                                    <div className="dashboard-header-route-left">
                                        <button onClick={() => history.goBack()}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                            </svg>
                                        </button>
                                        <h2>{t('New operation')}</h2>
                                    </div>
                                    {/* <button onClick={() => this.downloadTariffPdf()} className="dashboard-header-route-link">{t('Tariffs')}</button> */}
                                </div>
                                <div className="dashboard-payment-container">
                                    <div className="dashboard-payment-container-data-section">
                                        <div className="dashboard-payment-container-data-section-header">
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                <h2 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h2>
                                            </div>
                                            <button className="dashboard-payment-container-data-section-header-button" onClick={() => { this.setState({ openTemp: true }) }} disabled={!Boolean(
                                                selectedCountryIndex >= 0 &&
                                                selectedCityIndex >= 0 &&
                                                selectedPayCurrencyIndex >= 0 &&
                                                selectedReceiveCurrencyIndex >= 0 &&
                                                payAmountValidation &&
                                                receiveAmountValidation &&
                                                cardIdValidation &&
                                                crName.trim().length > 0 &&
                                                crSurname.trim().length > 0
                                            )}>{t("Save as template")}</button>
                                        </div>

                                        <form className="dashboard-payment-container-data-section-content">
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Transfer settings')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a country')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCountry: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " +
                                                                    (selectedCountryCount > 1 &&
                                                                        selectedCountryIndex < 0
                                                                        || selectedCountryCount === 1 && selectedCountryIndex < 0
                                                                        && !dropdownVisibilityCountry ? "input-outline" : "")}>
                                                                    <button type='button' className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation)}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} value={countryFilter}
                                                                            placeholder={t("Choose")}
                                                                            onChange={this.updateCountryFilter}
                                                                            onFocus={this.updateCountryCount} />
                                                                        <button type='button' className="dashboard-payment-container-data-section-content-container-form-icon" onClick={() => { this.setState({ dropdownVisibilityCountry: !dropdownVisibilityCountry }) }}>
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </button>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCountry === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            countries && countries.length > 0 &&
                                                                            countries.map((country, index) => {
                                                                                if (country.name.toLowerCase().includes(countryFilter.toLowerCase())) {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={this.updateCountry.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{country.name}</button>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* ============================= */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a city')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCity: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " +
                                                                    (selectedCityCount > 1 &&
                                                                        selectedCityIndex < 0
                                                                        || selectedCityCount === 1 && selectedCityIndex < 0
                                                                        && !dropdownVisibilityCity ? "input-outline" : "")}>
                                                                    <button type='button' className={(confirmedOperation || selectedCountryIndex < 0) ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation || selectedCountryIndex < 0)}>
                                                                        {/* {
                                                            cities && cities.length > 0 && selectedCityIndex >= 0 ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{cities[selectedCityIndex].cityName}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                        } */}
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation || selectedCountryIndex < 0} value={cityFilter}
                                                                            placeholder={t("Choose")}
                                                                            onChange={this.updateCityFilter}
                                                                            onFocus={this.updateCityCount} />
                                                                        <button type='button' className="dashboard-payment-container-data-section-content-container-form-icon" onClick={() => { this.setState({ dropdownVisibilityCity: !dropdownVisibilityCity }) }}>
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </button>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCity === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            cities && cities.length > 0 &&
                                                                            cities.map((city, index) => {
                                                                                // return (
                                                                                //     <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                //         <button onClick={this.updateCity.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{city.cityName}</button>
                                                                                //     </li>
                                                                                // );
                                                                                if (city.cityName.toLowerCase().includes(cityFilter.toLowerCase())) {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                            <button onClick={this.updateCity.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{city.cityName}</button>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* ============================= */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ===========================
                            ===================================== */}
                                            {/* ====================================================================== */}
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Conversion')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Currency sent')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityPayCurrency: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedPayCurrencyCount > 1 && selectedPayCurrencyIndex < 0 || selectedPayCurrencyCount === 1 && selectedPayCurrencyIndex < 0 && !dropdownVisibilityPayCurrency ? "input-outline" : "")}>
                                                                    <button className={(confirmedOperation || selectedCityIndex < 0) ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation || selectedCityIndex < 0)} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityPayCurrency: !dropdownVisibilityPayCurrency }, () => { if (selectedPayCurrencyIndex < 0) { this.setState({ selectedPayCurrencyCount: ++selectedPayCurrencyCount }) } }) }}>
                                                                        {
                                                                            currencies && currencies.length > 0 && selectedPayCurrencyIndex >= 0 ?
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{currencies[selectedPayCurrencyIndex].valName}</div>
                                                                                :
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                        }
                                                                        <button type='button' className="dashboard-payment-container-data-section-content-container-form-icon" onClick={() => { this.setState({ dropdownVisibilityPayCurrency: !dropdownVisibilityPayCurrency }) }}>
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </button>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityPayCurrency === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            currencies && currencies.length > 0 &&
                                                                            currencies.map((currency, index) => {
                                                                                return (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button onClick={this.updatePayCurrency.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{currency.valName}</button>
                                                                                    </li>
                                                                                );
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Received currency')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityReceiveCurrency: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (selectedReceiveCurrencyCount > 1 && selectedReceiveCurrencyIndex < 0 || selectedReceiveCurrencyCount === 1 && selectedReceiveCurrencyIndex < 0 && !dropdownVisibilityReceiveCurrency ? "input-outline" : "")}>
                                                                    <button className={(confirmedOperation || selectedCityIndex < 0) ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation || selectedCityIndex < 0)} onClick={(event) => { event.preventDefault(); this.setState({ dropdownVisibilityReceiveCurrency: !dropdownVisibilityReceiveCurrency }, () => { if (selectedReceiveCurrencyIndex < 0) { this.setState({ selectedReceiveCurrencyCount: ++selectedReceiveCurrencyCount }) } }) }}>
                                                                        {
                                                                            allCurrencies && allCurrencies.length > 0 && selectedReceiveCurrencyIndex >= 0 ?
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{allCurrencies[selectedReceiveCurrencyIndex].valName}</div>
                                                                                :
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                        }
                                                                        <button type='button' className="dashboard-payment-container-data-section-content-container-form-icon" onClick={() => { this.setState({ dropdownVisibilityReceiveCurrency: !dropdownVisibilityReceiveCurrency }) }}>
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </button>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityReceiveCurrency === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            allCurrencies && allCurrencies.length > 0 &&
                                                                            allCurrencies.map((currency, index) => {
                                                                                return (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button onClick={this.updateReceiveCurrency.bind(this, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{currency.valName}</button>
                                                                                    </li>
                                                                                );
                                                                            })
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                    </div>

                                                    {/* ============================= */}
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount sent')} <span style={{ color: 'red' }}>*</span></label>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (payAmountOutline ? "input-outline" : "")}>
                                                            <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={payAmount} disabled={Boolean(confirmedOperation || selectedPayCurrencyIndex < 0 || selectedReceiveCurrencyIndex < 0 || (selectedPayCurrencyIndex >= 0 && selectedReceiveCurrencyIndex >= 0 && currencies[selectedPayCurrencyIndex].tbName === allCurrencies[selectedReceiveCurrencyIndex].tbName))} placeholder="0" onChange={this.checkPayAmount} onBlur={this.setPayAmountOutline} />
                                                        </div>
                                                    </div>
                                                    <button type='button' className='dashboard-payment-convertation-icon' onClick={this.convertation}>
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path id="e" fill-rule="evenodd" clip-rule="evenodd" d="M13.7437 5.04221L11.4792 8.1262C11.2229 8.47725 10.8074 8.47725 10.5511 8.1262L10.2547 7.72022C9.9934 7.36233 9.9993 6.77961 10.2677 6.43174L11.1007 5.39322H0.65625C0.293812 5.39322 9.31323e-09 4.99079 9.31323e-09 4.49435V3.8951C9.31323e-09 3.39867 0.293812 2.99623 0.65625 2.99623H11.1007L10.2677 1.95775C9.9993 1.60984 9.9934 1.02716 10.2547 0.669272L10.5511 0.263287C10.8074 -0.0877623 11.2229 -0.0877623 11.4792 0.263287L13.7437 3.34728C14.0854 3.81533 14.0854 4.57416 13.7437 5.04221ZM3.74529 15.3307L3.44889 15.7367C3.19262 16.0878 2.77712 16.0878 2.52083 15.7367L0.256288 12.6527C-0.0854292 12.1847 -0.0854292 11.4258 0.256288 10.9578L2.52083 7.8738C2.77712 7.52275 3.19259 7.52275 3.44889 7.8738L3.74529 8.27979C4.0066 8.63767 4.0007 9.22036 3.73234 9.56826L2.89926 10.6067H13.3438C13.7062 10.6067 14 11.0092 14 11.5056V12.1049C14 12.6013 13.7062 13.0037 13.3438 13.0037H2.89926L3.73234 14.0423C4.0007 14.3902 4.0066 14.9728 3.74529 15.3307Z" fill="#0179D2" />
                                                        </svg>
                                                    </button>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Received amount')} <span style={{ color: 'red' }}>*</span></label>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (receiveAmountOutline ? "input-outline" : "")}>
                                                            <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={receiveAmount} disabled={Boolean(confirmedOperation || selectedPayCurrencyIndex < 0 || selectedReceiveCurrencyIndex < 0)} placeholder="0" onChange={this.checkReceiveAmount} onBlur={this.setReceiveAmountOutline} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ====================================================================== */}
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('payment')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by card')} <span style={{ color: 'red' }}>*</span></label>
                                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibilityCard: false }) }}>
                                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (cardIdCount > 1 && !cardIdValidation || cardIdCount === 1 && !cardIdValidation && !dropdownVisibilityCard ? "input-outline" : "")}>
                                                                    <button className={(confirmedOperation || selectedPayCurrencyIndex < 0) ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation || selectedPayCurrencyIndex < 0)} onClick={this.changeDropdownVisibilityCard}>
                                                                        {
                                                                            selectedCard ?
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCard}</div>
                                                                                :
                                                                                (cards && cards.length === 0 && cardData) ?
                                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{t('The card list is empty')}</div>
                                                                                    :
                                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                                        }
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <ul className={(dropdownVisibilityCard === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            cards && cards.length > 0 &&
                                                                            cards.map((card, index) => {
                                                                                return (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button onClick={this.updateCard.bind(this, card)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" disabled={!Boolean(card.cardStatus)}>{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                                    </li>
                                                                                );
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        </div>
                                                        {/* ============================= */}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ====================================================================== */}
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Receiving funds')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')}</label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input type="text" value={destCustName} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} value={selectedCurrency ? selectedCurrency : ''} />
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Message')}</label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input type="text" value={comment} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkComment} />
                                            </div>
                                        </div> */}
                                                        {/* ============================= */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('First name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crNameOutline ? "input-outline" : "")}>
                                                                <input type="text" value={crName} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrName} onBlur={this.setCrNameOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Last name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (crSurnameOutline ? "input-outline" : "")}>
                                                                <input type="text" value={crSurname} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrSurname} onBlur={this.setCrSurnameOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Ancestor name')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crFname} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrFname} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Mobile number')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input maxLength={'15'} type="text" value={crMobile} disabled={Boolean(confirmedOperation)} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrMobile} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                    {
                                        confirmedOperation === false ?
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How to transfer Zolotaya Korona')}</h3>
                                                </div>
                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                                        {/* <p>{t('Zolotaya Korona content')}</p> */}
                                                        <p dangerouslySetInnerHTML={{ __html: info }}></p>
                                                        {
                                                            limit.amount && limit.currency && this.state.cardCurrencyId &&
                                                            this.state.cardCurrencyId != this.state.currencies[this.state.selectedPayCurrencyIndex].tbName &&
                                                            t('Zk limit amount content', { limitAmount: limit.amount, limitCurrency: limit.currency })
                                                        }
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        {
                                                            selectedCountryIndex >= 0 &&
                                                                selectedCityIndex >= 0 &&
                                                                selectedPayCurrencyIndex >= 0 &&
                                                                selectedReceiveCurrencyIndex >= 0 &&
                                                                payAmountValidation &&
                                                                receiveAmountValidation &&
                                                                cardIdValidation &&
                                                                crName.trim().length > 0 &&
                                                                crSurname.trim().length > 0 ?
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.showZkTransfer}>{t('continue')}</button>
                                                                :
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        }
                                                        <button onClick={e => this.props.history.push('/dashboard/transfer/card-operation')} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                                </div>
                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                        <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + currencies[selectedPayCurrencyIndex]?.valName.toLocaleLowerCase()}>{currencyFilter(currencies[selectedPayCurrencyIndex]?.valName)}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{payAmountOrganized.wholePart}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{payAmountOrganized.fractionalPart}</span>
                                                    </h3>
                                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Zolotaya Korona')}</p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Recipients name')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crSurname} {crName} {crFname}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Country')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{countries[selectedCountryIndex].name}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('City')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{cities[selectedCityIndex].cityName}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Withdrawal card')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedCard}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount sent')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{payAmount} {this.state.currencies[this.state.selectedPayCurrencyIndex]?.valName}</p>
                                                        </li>
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount received')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{receiveAmount} {this.state.allCurrencies[this.state.selectedReceiveCurrencyIndex]?.valName}</p>
                                                        </li>
                                                        {
                                                            dtFeeAmount !== null &&
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtFeeAmount?.feeTotal} {dtFeeAmount?.currency}</p>
                                                            </li>
                                                        }
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <CheckboxTick checked={confirmTerm} onChange={() => { this.setState({ confirmTerm: !confirmTerm }) }} />
                                                                <button onClick={this.openTerm} style={{ marginTop: '-8px', marginLeft: '-20px', textDecoration: 'underline', color:'#0179d2', textAlign: 'left', lineHeight: '14px' }} className="dashboard-payment-container-data-section-content-container-form-group-button"><strong>{t('zk condition')}</strong></button>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        {
                                                            !confirmTerm ?
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('agree')}</button>
                                                                :
                                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={() => { this.checkTransfer() }}>{t('agree')}</button>
                                                        }
                                                        <button onClick={this.submitConditions.bind(this, false)} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right ${!confirmTerm ? 'dashboard-payment-container-data-section-content-container-form-footer-button__disable' : ''}`} >{t('i do not agree')}</button>
                                                    </div>
                                                </div>

                                            </div>
                                    }
                                </div>
                            </Fragment>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(ZkTransfer);
