import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './langs/i18n';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js", {scope: '/'})
    .then(function(registration) {
      // console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      // console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(
  //<React.StrictMode>
  <I18nextProvider i18n={i18n}>
      <App />
  </I18nextProvider>,
  //</React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bitbit.ly/CRA-PWA
serviceWorker.unregister();
