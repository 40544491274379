import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import cardsIcon from './../../../../img/cards-icon.png';
import CheckboxMinus from './../../../../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../../../../copms/Checkbox/CheckboxTick';
import { request } from './../../../../../../config';
import YesNoModal from '../../../../../../copms/Modal/YesNoModal';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}


class Operations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            deleteModalOpen: false,
            signModalOpen: false,
        };
    }
    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data })
        }
    }

    downloadExcel(id) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let downloadExcelRequestBody = customerType === 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            excelId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                excelId: id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("salary/getUploadedExcel", downloadExcelRequestBody))
            .then(res => res.text())
            .then((downloadExcelResponseJSON) => {
                const JSON = require('true-json-bigint');
                let downloadExcelResponse = JSON.parse(downloadExcelResponseJSON);
                if (downloadExcelResponse.status.statusCode !== 1) {
                    if (downloadExcelResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelResponse.bytes); //data is the base64 encoded string
                    saveFile("salary_operations_" + id + ".xlsx", "application/xlsx", arrrayBuffer);
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    confirmDelete() {

    }

    render() {
        let { t, signModalOpen } = this.props;
        return (
            <Fragment>
                <YesNoModal open={signModalOpen} title={t('Are you sure you want to sign?')} onClose={() => this.props.signModalOpenFunc()} triggerFunc={val => val && this.props.confirmSign()} />

                {
                    this.state.data && this.state.data.length > 0 ?
                        < div className="dashboard-transfer-list-content">
                            <table className="dashboard-transfer-list-table">
                                <thead className="dashboard-transfer-list-table-head">
                                    <tr className="dashboard-transfer-list-table-row">
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">
                                            <CheckboxMinus onChecked={e => this.props.selectAll(e)} />
                                        </th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("SENDER ACCOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("NAME OF THE SENDER")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("AMOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("EMPLOYEE COUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">EXCEL</th>
                                    </tr>
                                </thead>
                                <tbody className="dashboard-transfer-list-table-body">
                                    {
                                        this.state.data.map((item, index) => {
                                            return (
                                                <tr className="dashboard-transfer-list-table-row">
                                                    <td className="dashboard-transfer-list-table-cell">
                                                        <CheckboxTick checked={item.isSelect} onChecked={val => {
                                                            this.props.selectListFunc(index, val)
                                                        }} />
                                                    </td>
                                                    <td>{item.createDate.split(" ")[0]} {item.createDate.split(" ")[1] && item.createDate.split(" ")[1].substr(0, 5)}</td>
                                                    <td title={item.crIban}>{item.dtIban.length > 17 ? item.dtIban.substr(0, 17) + '...' : item.dtIban}</td>
                                                    <td>{item.dtCustName}</td>
                                                    <td>{item.amount ? this.amountSplit(item.amount.toString()).part1 + '.' + this.amountSplit(item.amount.toString()).part2 : this.amountSplit(item.crAmt.toString()).part1 + '.' + this.amountSplit(item.crAmt.toString()).part2} AZN</td>
                                                    <td>{item.employeeCount}</td>
                                                    <td>
                                                        <button onClick={() => this.downloadExcel(item.excelId)}>
                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#586268" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="dashboard-finance-section-invisible-cards-section">
                            <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" alt="cards-section-image" />
                            <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any active transactions.')}</p>
                            </div>
                            <Link to="/dashboard/transfer/transfers/all" style={{ textDecoration: 'none' }} className="dashboard-finance-section-invisible-cards-section-button">{t('transfers')}</Link>
                        </div>
                }
            </Fragment>

        )
    }
}
export default translate("translation")(Operations);