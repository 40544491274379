import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { translate } from "react-i18next";

import messageIcon from "./../../../img/icon-message.svg";

import "./DigitalCardInfoModal.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
    width: "32%",
  },
}));

const DigitalCardInfoModal = ({
  open,
  translate,
  toggleDigitalCardInfoModal,
  sendDigitalCardInfoSms,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={toggleDigitalCardInfoModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      classes={classes.modal}
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="dashboard-card-modal dashboard-digital-card-info-modal">
            <div className="dashboard-card-modal-title">
              <h4>{translate("Card information")}</h4>
            </div>
            <div className="dashboard-card-modal-actions">
              <div className="dashboard-card-modal-actions-item">
                <div className="dashboard-card-modal-actions-item-name">
                  <button type="button" onClick={sendDigitalCardInfoSms}>
                    {translate("Other information of the card")}
                  </button>
                  <p>{translate("Expiry date, card holder, CVC")}</p>
                </div>
                <div className="dashboard-card-modal-actions-item-icon">
                  <img src={messageIcon} alt="add-new-card" />
                </div>
              </div>
            </div>
            <div className="dashboard-close-modal">
              <button type="button" onClick={toggleDigitalCardInfoModal}>
                {translate("close")}
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default translate("translation")(DigitalCardInfoModal);
