import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import cardIconBackground from './../../img/card-icon-background.svg';
import phoneCompanyIcon from './../../img/phone-company-icon.svg';
import mobileIcon from './../../img/mobile-android.svg';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import categoryFilter from "./../../../../helpers/categoryFilter";
import backcellIcon from '../../img/bakcell.svg';
import PaymentModalSucces from '../../../../copms/Modal/PaymentModalSucces';
import PaymentModalError from '../../../../copms/Modal/PaymentModalError';
import Loading from './../../Loading/Loading';
import searchIcon from './../../img/search-icon.svg';


class Category extends Component {

    state = {
        categories: [],
        merchants: [],
        searchMerchants: [],
        isLoading: false,
        searchVisibility: false
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCategoryListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("pg/getCategoryList" + customerType + 'v1', getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCategoryListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCategoryListCustomerResponse = JSON.parse(getCategoryListCustomerResponseJSON);
                if (getCategoryListCustomerResponse.status.statusCode !== 1) {
                    if (getCategoryListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ categories: getCategoryListCustomerResponse.categoryList })
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        this.getMerchantList(true);
    }

    searchService() {
        const results = [];
        let tempName = this.state.tempName.toLowerCase();

        this.state.merchants.forEach(service => {
            if (service.serviceDisplayName?.toLowerCase().includes(tempName)) {
                results.push(service);
            }
            else if (service.merchants?.find(merchant =>
                merchant.displayName.toLowerCase().includes(tempName))) {
                results.push(service.merchants?.find(merchant =>
                    merchant.displayName.toLowerCase().includes(tempName)))
            }
        });

        this.setState({ searchMerchants: results })
    }

    getMerchantList(initial) {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
            this.setState({ searchMerchants: null, merchants: null })
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getMerchantListCustomerRequestBody = customerType == 'Juridical' ? {
            // custId: localStorage.getItem('custId'),
            // compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            // custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("pg/getServiceList" + customerType, getMerchantListCustomerRequestBody))
            .then(res => res.text())
            .then((getMerchantListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getMerchantListCustomerResponse = JSON.parse(getMerchantListCustomerResponseJSON);
                if (getMerchantListCustomerResponse.status.statusCode !== 1) {
                    if (getMerchantListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    if (document.getElementById('operator_list') && document.getElementById('operator_list').offsetTop && window.screen.width < 768) {
                        window.scrollTo(0, (document.getElementById('operator_list').offsetTop + 100));
                    }
                    this.setState({ merchants: getMerchantListCustomerResponse.services, searchMerchants: getMerchantListCustomerResponse.services })
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidUpdate(prevPros, prevState) {
        if (prevPros.match.params.id !== this.props.match.params.id) {
            this.getMerchantList();
            this.setState({ tempName: '', displayBackButton: false })
        }
    }

    handleBackButton = (e) => {
        e.preventDefault();
        this.setState({ searchMerchants: null }, () => this.setState({ searchMerchants: this.state.merchants, displayBackButton: false, tempName: '' }))
    }

    render() {
        let { categories, merchants, searchMerchants, isLoading, displayBackButton, tempName } = this.state;
        let { updateAppState, t } = this.props;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('payments')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <PaymentModalSucces open={false} />
                            <PaymentModalError open={false} />
                            <div className="dashboard-payment-section-categorys">
                                <div className="dashboard-payment-section" style={{ borderTopRightRadius: 0, width: '65%', height: '100%' }}>
                                    <div className="dashboard-payment-section-header">
                                        <h3 className="dashboard-payment-section-header-title">{t('Category selection')}</h3>
                                        {/* <button></button> */}
                                    </div>

                                    <ul className="dashboard-payment-section-categorys-cards">
                                        {
                                            categories?.map((category, index) => (
                                                <li key={"cat_" + index} className='d-flex align-items-center flex-column'>
                                                    <NavLink to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}payments/categories/${category.categoryId}`}
                                                        activeClassName="dashboard-payment-section-categorys-cards-active-link">
                                                        <img src={categoryFilter(category?.categoryName)} className="dashboard-payment-section-content-icon" />
                                                    </NavLink>
                                                    <p className="dashboard-payment-section-content-title">{category.displayName}</p>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="dashboard-payment-section dashboard-payment-section-right" style={{ borderTopLeftRadius: 0, backgroundColor: '#F8FAFB', width: '35%', position: 'absolute' }} id="operator_list">
                                    <div className="dashboard-payment-section-header dashboard-payment-section-header-redesign mx-4 p-0">
                                        <h3 className="dashboard-payment-section-header-title" style={{ marginLeft: '5px' }}>
                                            {displayBackButton && <button onClick={(e) => this.handleBackButton(e)}>
                                                <svg width="13" height="13" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                                </svg>
                                            </button>} {t('providers')}</h3>
                                        <button style={{ color: '#0179D2' }} onClick={() => this.setState({ searchVisibility: !this.state.searchVisibility })}>{this.state.searchVisibility ? t("close") : t("Search")}</button>
                                    </div>
                                    <div className="dashboard-transfer-search-form-section-redesign row mb-4" style={{ display: !this.state.searchVisibility && 'none' }} >
                                        <div className="col-12 bg-white">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box d-flex">
                                                <img src={searchIcon} alt="" style={{ paddingLeft: '12px' }} />
                                                <input type="text" value={tempName} className="dashboard-payment-container-data-section-content-container-form-input" placeholder={t("Name of provider")} onChange={e => this.setState({ tempName: e.target.value }, () => this.searchService())} />
                                            </div>
                                        </div>
                                        {/* <div className="col-6 m-auto">
                                            <button className="dashboard-transfer-search-form-section-item-button"
                                                onClick={() => this.searchService()}
                                            >{t("Search")}</button>
                                        </div> */}
                                    </div>
                                    <ul className="dashboard-payment-section-categorys-alts" style={{ borderTop: 0 }}>
                                        {
                                            searchMerchants?.map((category, index) => (
                                                (category?.merchantName != 'Absheron' && category?.merchantName != 'Elektromed' && category?.merchantName != 'Gallus' && category?.merchantName != 'Zenner') &&
                                                <li key={index} onClick={() => { this.setState({ searchMerchants: category?.merchants, displayBackButton: true }) }}>
                                                    {(category?.merchants?.length == 1 || category?.merchantId) ?
                                                        <Link to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}payments/categories/${this.props.match.params.id}/providers/${category.merchantId || category.merchants[0].merchantId}`} onClick={() => localStorage.setItem("additionalCodeParamType", category?.additionalCodeParamType)}>
                                                            <img src={category?.img || category?.serviceImage} alt="" />
                                                            <p className="dashboard-payment-section-content-title">{category?.displayName || category?.serviceDisplayName}</p>
                                                        </Link> : <button>
                                                            <img src={category?.img || category?.serviceImage} alt="" />
                                                            <p className="dashboard-payment-section-content-title">{category?.displayName || category?.serviceDisplayName}</p></button>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>
                }
            </Fragment>
        )
    }
}


export default translate("translation")(Category);