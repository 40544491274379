import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { request, ipsRequest } from './../../../../config';
import Dropdown from '../../../../copms/Dropdown/Dropdown';
import { translate } from 'react-i18next';
import Loading from '../../Loading/Loading';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import GeneralModal from './../../../../copms/Modal/GeneralModal';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import { id } from 'date-fns/locale';

class Accounts extends Component {

    state = {
        accounts: [],
        compounds: [],
        open: false,
        index: null,
        accountIndex: null,
        account: null,
        openAlert: false,
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
        createFeedbackMessage: false
    }
    showAccounts = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountsRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("getAccounts", getAccountsRequestBody))
            .then(res => res.text())
            .then((getAccountsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountsResponse = JSON.parse(getAccountsResponseJSON);
                if (getAccountsResponse.status.statusCode !== 1 && getAccountsResponse.status.statusCode !== 126) {
                    if (getAccountsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountsResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getAccountsResponse.status.statusCode === 126) {
                        getAccountsResponse.accounts = [];
                    }
                    else {
                        getAccountsResponse.accounts = getAccountsResponse.accounts.filter(item => item.currency === 'AZN')
                    }
                    getAccountsResponse.accounts.map((account, index) => {
                        getAccountsResponse.accounts[index].openDate = account.openingDate ?
                            account.openingDate.slice(0, 10).split('-')[2] + '.' + account.openingDate.slice(0, 10).split('-')[1] + '.' + account.openingDate.slice(0, 10).split('-')[0]
                            :
                            null
                    });
                    this.setState({ accounts: getAccountsResponse.accounts && getAccountsResponse.accounts.filter(el => el.currency == "AZN") });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    deleteAccount = (index, account) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            feedbackErrorModal: null,
            feedbackSuccessModal: null,
            createFeedbackMessage: false
        });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let deleteIpsAccountRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            accountIds: [account.id],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountIds: [account.id],
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("deleteIpsAccount", deleteIpsAccountRequestBody))
            .then(res => res.text())
            .then((deleteIpsAccountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let deleteIpsAccountResponse = JSON.parse(deleteIpsAccountResponseJSON);
                if (deleteIpsAccountResponse.status.statusCode !== 1) {
                    if (deleteIpsAccountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({
                            createFeedbackMessage: false, openAlert: true,
                            feedbackErrorModal: this.props.t(deleteIpsAccountResponse.status.statusCode)
                        });
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(deleteIpsAccountResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let accounts = this.state.accounts;
                    accounts.splice(index, 1);
                    this.setState({ accounts: accounts });
                    this.setState({
                        createFeedbackMessage: true, openAlert: true,
                        feedbackSuccessModal: this.props.t('Account was deleted from IPS successfully')
                    });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }
    getCompounds = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountsRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(ipsRequest("getCompounds", getAccountsRequestBody))
            .then(res => res.text())
            .then((getAccountsResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountsResponse = JSON.parse(getAccountsResponseJSON);
                if (getAccountsResponse.status.statusCode !== 1 && getAccountsResponse.status.statusCode !== 285) {
                    if (getAccountsResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountsResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getAccountsResponse.status.statusCode === 285) {
                        getAccountsResponse.compounds = [];
                    }
                    this.setState({ compounds: getAccountsResponse.compounds });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    setDefaultAccount = async (index, account) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({
            feedbackErrorModal: null,
            feedbackSuccessModal: null,
            createFeedbackMessage: false
        });
        let compounds = this.state.compounds ?
            this.state.compounds.filter((item) => (item.accountId === account.id))
            : [];
        let count = compounds.length;
        if (compounds && compounds.length !== 0) {
            compounds.map(async (compound) => {
                await this.setDefault(compound.id, count)
                    .then(response => {
                        count = response;
                    });
                if (count) {
                    this.setState({
                        createFeedbackMessage: false, openAlert: true,
                        feedbackErrorModal: this.props.t('Account could not be set by default')
                    });
                }
                else {
                    this.setState({
                        createFeedbackMessage: true, openAlert: true,
                        feedbackSuccessModal: this.props.t('Account has been set as default successfully')
                    });
                }
            });
        }
        else {
            this.setState({
                createFeedbackMessage: false, openAlert: true,
                feedbackErrorModal: this.props.t('No alias are linked to the account')
            });
        }

        this.props.updateAppState('isLoadingTransparentBackground', false);
    }
    setDefault = async (compoundId, count) => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let setDefaultRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            compoundId: compoundId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                compoundId: compoundId,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        return await fetch(ipsRequest("setDefault", setDefaultRequestBody))
            .then(res => res.text())
            .then((setDefaultResponseJSON) => {
                const JSON = require('true-json-bigint');
                let setDefaultResponse = JSON.parse(setDefaultResponseJSON);
                if (setDefaultResponse.status.statusCode !== 1 && setDefaultResponse.status.statusCode !== 271) {
                    if (setDefaultResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    --count;
                }
            });
    }

    dropdownItemHandler = (index, accountIndex, account, url) => {
        switch (index) {
            case 0:
                this.setState({ accountIndex: accountIndex, account: account, index: index, open: true })
                break;
            case 1:
                this.setState({ accountIndex: accountIndex, account: account, index: index, open: true })
                break;
        }
    }
    componentDidMount() {
        this.showAccounts();
        this.getCompounds();
    }
    render() {
        let { isLoading, open, index, accountIndex, account, accounts,
            openAlert, createFeedbackMessage, feedbackErrorModal, feedbackSuccessModal } = this.state;
        let { updateAppState, history, t } = this.props;
        return (
            <Fragment>
                <GeneralModal open={openAlert} successClose={() => this.setState({ openAlert: false })}
                    errorClose={() => this.setState({ openAlert: false })}
                    createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal}
                    feedbackSuccessModal={feedbackSuccessModal} history={history} />
                <YesNoModal onClose={() => this.setState({ open: false })} title={
                    index === 0 ?
                        t('Are you sure you want to delete account from IPS')
                        :
                        index === 1 &&
                        t('Are you sure you want to set account as default')
                }
                    open={open} triggerFunc={
                        index == 0 ?
                            this.deleteAccount.bind(this, accountIndex, account)
                            :
                            index === 1 &&
                            this.setDefaultAccount.bind(this, accountIndex, account)
                    } />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('accounts')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                                <div className="dashboard-transfer-list-header" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                    <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__right">
                                        <Link to="/dashboard/transfer/ips/accounts/register" className="dashboard-transfer-list-header-button" style={{ color: '#FFFFFF' }}>{t("Register account")}</Link>
                                    </div>
                                </div>
                                <div className="dashboard-transfer-list-content" style={{ padding: 0, height: '100%', minHeight: '100vh' }}>
                                    {
                                        accounts && accounts.length !== 0 ?
                                            <table className="dashboard-transfer-list-table">
                                                <thead className="dashboard-transfer-list-table-head">
                                                    <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("Account")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("currency")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("Opening date")}</th>
                                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="dashboard-transfer-list-table-body">
                                                    {
                                                        accounts && accounts.map((account, index) => {
                                                            return (
                                                                <tr className="dashboard-transfer-list-table-row" key={index}>
                                                                    <td className="dashboard-transfer-list-table-cell">{`${account.iban} ${account.mainCardNumber !== null ? '/' + account.mainCardNumber : ""}`}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{account.currency}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">{account.openDate}</td>
                                                                    <td className="dashboard-transfer-list-table-cell">
                                                                        {/* <button onClick={() => { this.dropdownItemHandler(0, index, account) }}>
                                                                            <svg className="dashboard-transfer-list-table-icon" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.34375 9.03125 0 8.5625 0H5.40625C4.9375 0 4.375 0.34375 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.46875 1.5 5.5 1.5H5.53125H8.46875C8.5 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z" fill="#586268" />
                                                                            </svg>
                                                                        </button> */}
                                                                        <Dropdown
                                                                            dropdownItems={[
                                                                                { title: t('Delete') },
                                                                                { title: t('Make the default account') }
                                                                            ]}
                                                                            dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, account)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            :
                                            <div className="dashboard-last-operations-section-content">
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p className="dashboard-last-operations-section-content-status">{t('No registered account found')}</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </Fragment >
        )
    }
}


export default translate("translation")(Accounts);