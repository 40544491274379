import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { translate } from "react-i18next";

import blueCardIcon from "./../../../img/card-blue-icon.svg";
import bluePlusIcon from "./../../../img/blue-plus-icon.svg";

import "./AddNewCardModal.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
    width: "32%",
  },
}));

const AddNewCardModal = ({ open, translate, toggleAddNewCardModal }) => {
  const classes = useStyles();

  const checkDigital = localStorage.getItem("checkDigital");

  return (
    <Modal
      open={open}
      onClose={toggleAddNewCardModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      classes={classes.modal}
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="dashboard-card-modal dashboard-add-new-card-modal">
            <div className="dashboard-card-modal-title">
              <h4>{translate("New card")}</h4>
            </div>
            <div className="dashboard-card-modal-actions">
              {checkDigital === "false" && (
                <div className="dashboard-card-modal-actions-item digital-card-item">
                  <div className="dashboard-card-modal-actions-item-icon">
                    <img src={blueCardIcon} alt="activate-digital-card" />
                  </div>
                  <div className="dashboard-card-modal-actions-item-name">
                    <Link to="/dashboard/finance/cards/digital-card" className="digital-card-active">
                      {translate("Activate Digital card")}
                    </Link>
                  </div>
                </div>
              )
              }
              <div className="dashboard-card-modal-actions-item">
                <div className="dashboard-card-modal-actions-item-icon">
                  <img src={bluePlusIcon} alt="add-new-card" />
                </div>
                <div className="dashboard-card-modal-actions-item-name">
                  <Link to="/dashboard/finance/cards/card-storage/add-other-bank-card">
                    {translate("add a new card")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="dashboard-close-modal">
              <button onClick={toggleAddNewCardModal}>
                {translate("close")}
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default translate("translation")(AddNewCardModal);
