import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import Operations from './Operations/Operations';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import cardsIcon from './../../img/cards-icon.png';
import AsanVerification from '../../../../copms/Modal/AsanVerification';
import SignOperation from '../../../../copms/Modal/SignOperation';
import Loading from './../../Loading/Loading';
class FirstAuthorisation extends Component {
    state = {
        salarys: [],
        selectedList: [],
        buttonDisabled: true,
        verificationOpen: false,
        verificationCode: '',
        failOperId: [],
        operationOpen: false,
        operationType: 'error',
        operationData: [],
        successOperId: [],
        feedbackMessage: null,
        signModalOpen: false,
        isLoading: false,
        signLevel: null,
        signCount: null
    }
    dropdownItemHandler = (index, account) => {
        switch (index) {
            case 2:
                this.setState({
                    open: true,
                    modalAccountObj: account
                });
        }
    }

    selectListFunc(index, val) {
        var salarys = this.state.salarys;
        salarys[index].isSelect = val;
        this.setState({ salarys: salarys, buttonDisabled: !Boolean(salarys.find(el => el.isSelect === true)) });
    }

    selectAll(val) {
        this.setState({
            salarys: this.state.salarys.map((item, index) => {
                item.isSelect = val;
                return item;
            }), buttonDisabled: !val
        });
    }


    componentDidMount() {
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel });
            this.setState({ signCount: custObject.company.signCount });
        }
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAuthOperationListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("salary/getSecondAuthSalaryList", getAuthOperationListRequestBody))
            .then(res => res.text())
            .then((getAuthOperationListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAuthOperationListResponse = JSON.parse(getAuthOperationListResponseJSON);
                if (getAuthOperationListResponse.status.statusCode === 200) {
                    this.props.logout();
                } else {
                    var convertations = [];
                    var transfers = [];

                    this.setState({ salarys: getAuthOperationListResponse.salaryOperation })

                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async myFetch(url, mybody) {
        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 15 * 60 * 1000)
        const res = await fetch(request(url, mybody, 'POST', controller.signal))
        // console.log('timeout waiting')
        // const body = await res.json()
        clearTimeout(timeoutId)
        return res;
    }
    signSubmit() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let listObjects = this.state.salarys;
        let selectList = this.state.salarys.filter(el => el.isSelect === true);
        let phoneNumber = localStorage.getItem('phoneNumber');
        let certCode = customerType == 'Juridical' ? JSON.parse(localStorage.getItem('custObject')).company.certCode : JSON.parse(localStorage.getItem('custObject')).certCode;
        let getSecondSignInfoRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            excelId: selectList.map(item => { return item.excelId }),
            userId: localStorage.getItem('userId'),
            phoneNumber: phoneNumber,
            certCode: certCode,
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                excelId: selectList.map(item => { return item.excelId }),
                userId: localStorage.getItem('userId'),
                phoneNumber: phoneNumber,
                certCode: certCode,
            };


        this.myFetch("salary/getSecondSignInfo", getSecondSignInfoRequestBody)
            .then(res => res.text())
            .then((getSecondSignInfoResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getSecondSignInfoResponse = JSON.parse(getSecondSignInfoResponseJSON);
                if (getSecondSignInfoResponse.status.statusCode !== 1) {
                    if (getSecondSignInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getSecondSignInfoResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ verificationOpen: true, verificationCode: getSecondSignInfoResponse.verificationCode, failOperId: getSecondSignInfoResponse.failOperId, successOperId: getSecondSignInfoResponse.successOperId });
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                    let SignFileRequestBody = customerType == 'Juridical' ? {
                        custId: localStorage.getItem('custId'),
                        compId: localStorage.getItem('compId'),
                        token: localStorage.getItem('token'),
                        lang: localStorage.getItem('i18nextLng').toUpperCase(),
                        operationIds: selectList.map(item => { return item.operId }),
                        userId: localStorage.getItem('userId'),
                        phoneNumber: phoneNumber,
                        certCode: certCode,
                        transactionId: getSecondSignInfoResponse.transactionId,
                        failExcelId: getSecondSignInfoResponse.failExcelId,
                        successExcelId: getSecondSignInfoResponse.successExcelId
                    } : {
                            custId: localStorage.getItem('custId'),
                            token: localStorage.getItem('token'),
                            lang: localStorage.getItem('i18nextLng').toUpperCase(),
                            operationIds: selectList.map(item => { return item.operId }),
                            userId: localStorage.getItem('userId'),
                            phoneNumber: phoneNumber,
                            certCode: certCode,
                            transactionId: getSecondSignInfoResponse.transactionId,
                            failExcelId: getSecondSignInfoResponse.failExcelId,
                            successExcelId: getSecondSignInfoResponse.successExcelId
                        };
                    this.myFetch("salary/signFileSecondAuth", SignFileRequestBody)
                        .then(res => res.text())
                        .then((SignFileResponseJSON) => {
                            const JSON = require('true-json-bigint');
                            let SignFileResponse = JSON.parse(SignFileResponseJSON);
                            if (SignFileResponse.status.statusCode !== 1) {
                                if (SignFileResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.setState({ verificationOpen: false, verificationCode: '', });
                                    var oprationList = selectList.map((item) => {
                                        var inObj = item
                                        inObj.type = 'error';
                                        return inObj;
                                    });
                                    this.setState({ operationOpen: true, selectedList: [], verificationOpen: false, operationType: 'error', operationData: oprationList, feedbackMessage: this.props.t(SignFileResponse.status.statusCode) }, () => this.selectAll(false));
                                }
                            } else {
                                var successList = SignFileResponse.successStatus ? SignFileResponse.successStatus.map((item) => {
                                    listObjects.splice(listObjects.findIndex(el => el.operId === item), 1);
                                    var inObj = selectList.find(el => el.excelId === item.excelId);
                                    inObj.type = 'success';
                                    return inObj;
                                }) : [];
                                var errorList = SignFileResponse.failStatus ? SignFileResponse.failStatus.map((item) => {
                                    var inObj = selectList.find(el => el.excelId === item.excelId);
                                    inObj.type = 'error';
                                    inObj.message = item.message;
                                    return inObj;
                                }) : [];

                                this.setState({ operationOpen: true, verificationOpen: false, selectedList: [], operationType:  'success', operationData: successList.concat(errorList) },
                                    () => this.setState({ salarys: listObjects }, () => this.selectAll(false)));
                            }
                        });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { t, alertState, updateAppState } = this.props;
        let { signLevel, signCount, salarys, buttonDisabled, feedbackMessage, verificationOpen, verificationCode, operationOpen, operationType, operationData, signModalOpen, isLoading } = this.state;
        return (
            <Fragment>
                <AsanVerification open={verificationOpen} verificationCode={verificationCode} />
                <SignOperation feedbackMessage={feedbackMessage} open={operationOpen} auth={2} type={operationType} data={operationData} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>
                            {
                                (signLevel === 1 && signCount === 1) ?
                                    t('authorization')
                                    :
                                    t('2nd authorization')
                            }
                        </h2>
                    </div>
                </div>
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            (salarys && salarys.length > 0) ?
                                <div className="dashboard-transfer-list">
                                    <div className="dashboard-transfer-list-header">
                                        <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left" style={{ height: '50px' }}>

                                        </div>
                                        <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__right">
                                            <button onClick={() => this.setState({ signModalOpen: true })} disabled={buttonDisabled} className="dashboard-transfer-list-header-button">{t("Sign with Asan")}</button>
                                        </div>
                                    </div>
                                    <Operations signModalOpenFunc={() => this.setState({ signModalOpen: !signModalOpen })} signModalOpen={signModalOpen} confirmSign={() => this.signSubmit()} selectListFunc={(index, val) => this.selectListFunc(index, val)} selectAll={val => this.selectAll(val)} data={this.state.salarys} updateAppState={updateAppState} alertState={alertState} />
                                </div>
                                :
                                <div className="dashboard-finance-section-invisible-cards-section" style={{ background: 'white' }}>
                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any active transactions.')}</p>
                                    </div>
                                    <Link to="/dashboard/salary/operations" style={{ textDecoration: 'none' }} className="dashboard-finance-section-invisible-cards-section-button">{t('List of operations')}</Link>
                                </div>
                    }
                </div>
            </Fragment>
        )
    }
}

export default translate("translation")(FirstAuthorisation);