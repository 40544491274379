import React, { Component } from "react";
import { Link } from 'react-router-dom';
import NotFoundImage from "./img/not_found.svg";
import "./scss/style.scss";
import { translate } from 'react-i18next';
import { request } from './../../config';

class NotFound extends Component {
    // componentDidMount() {
    //     this.props.updateAppState('isLoading', false);
    //     this.props.updateAppState('isLoadingTransparentBackground', false);
    //     fetch(request("register/checkToken", {}))
    //         .then(res => {this.props.history.push('/')}).catch(() => {
                
    //         })
    // }
    // orderCredit = () => {
    //     window.open('http://www.turanbank.az/' + (localStorage.getItem('i18nextLng').toLowerCase() ? localStorage.getItem('i18nextLng').toLowerCase() : 'AZ') + '/pages/370', "_blank");
    // }
    render() {
        let { t, i18n } = this.props;
        return (
            <div className="background-notfound">
                <img src={NotFoundImage} />
                <h3 className="notfound-header-title">{t('the page you were looking for was not found')}</h3>
                {
                    i18n.language == 'en' ?
                        <h6 className="notfound-description">An error occurred. Please try again or return to the Home page</h6> :
                        i18n.language == 'ru' ?
                            <h6 className="notfound-description">Произошла ошибка. Пожалуйста, попробуйте еще раз или вернитесь на Главную страницу</h6> :
                            <h6 className="notfound-description">Xəta baş verdi. Yenidən cəhd edin və ya Əsas səhifəyə qayıdın</h6>
                }
                <Link to="/" className="dashboard-finance-section-invisible-cards-section-button">{t('REFRESH PAGE')}</Link>
            </div>
        )
    }
}

export default translate("translation")(NotFound);