import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import cardsIcon from './../../../../img/cards-icon.png';
import CheckboxMinus from './../../../../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../../../../copms/Checkbox/CheckboxTick';
import { request } from './../../../../../../config';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
class Operations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }
    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    showPdf = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getPaymentDocByOperIdRequestBody = {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: id
        }
        fetch(request("operation/getPaymentDocByOperId", getPaymentDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getPaymentDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getPaymentDocByOperIdResponse = JSON.parse(getPaymentDocByOperIdResponseJSON);
                if (getPaymentDocByOperIdResponse.status.statusCode !== 1) {
                    if (getPaymentDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getPaymentDocByOperIdResponse.status.statusCode));
                    }
                } else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getPaymentDocByOperIdResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    window.open(link, '');
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data })
        }
    }
    render() {
        let { t, type } = this.props;
        return (
            <Fragment>
                {
                    this.state.data.length > 0 ?
                        < div className="dashboard-transfer-list-content">
                            <table className="dashboard-transfer-list-table">
                                <thead className="dashboard-transfer-list-table-head">
                                    <tr className="dashboard-transfer-list-table-row">
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">
                                            <CheckboxMinus
                                                onChecked={val => {
                                                    this.props.selectAll(val)
                                                }} />
                                        </th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("SENDER ACCOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("RECİPİENTS NAME")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("RECİPİENTS ACCOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("AMOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                        {type === 1 && <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("TYPE")}</th>}
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">PDF</th>
                                    </tr>
                                </thead>
                                <tbody className="dashboard-transfer-list-table-body">
                                    {
                                        this.state.data.map((item, index) => {
                                            return (
                                                <tr className="dashboard-transfer-list-table-row">
                                                    <td className="dashboard-transfer-list-table-cell">
                                                        <CheckboxTick checked={item.isSelect} onChecked={val => {
                                                            this.props.selectListFunc(index, val)
                                                        }} />
                                                    </td>
                                                    <td className="dashboard-transfer-list-table-cell">{item.createdDate.split(" ")[0]}</td>
                                                    <td className="dashboard-transfer-list-table-cell" title={item.dtIban}>{item.dtIban.length > 17 ? item.dtIban.substr(0, 17) + '...' : item.dtIban}</td>
                                                    <td className="dashboard-transfer-list-table-cell">{item.crName}</td>
                                                    <td className="dashboard-transfer-list-table-cell" title={item.crIban}>{item.crIban.length > 17 ? item.crIban.substr(0, 17) + '...' : item.crIban}</td>
                                                    <td className="dashboard-transfer-list-table-cell">{item.amount ? item.amount.toFixed(2) : item.operTypeId === 6 ? item.dtAmt.toFixed(2) : item.crAmt.toFixed(2)}</td>
                                                    <td className="dashboard-transfer-list-table-cell">{item.currency ? item.currency : item.operTypeId === 6 ? item.dtCcy : item.crCcy}</td>
                                                    {type === 1 && <td className="dashboard-transfer-list-table-cell">{item.operTypeId === 6 ? t('Sales') : t('Buy')}</td>}
                                                    <td className="dashboard-transfer-list-table-cell">
                                                        <button onClick={this.showPdf.bind(this, item.operId)}>
                                                            <svg className="dashboard-transfer-list-table-icon dashboard-transfer-list-table-icon__pdf" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.5312 3.0625L8.9375 0.4375C8.65625 0.15625 8.25 0 7.875 0H1.5C0.65625 0 0 0.6875 0 1.5V14.5C0 15.3438 0.65625 16 1.5 16H10.5C11.3125 16 12 15.3438 12 14.5V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM10.375 4H8V1.625L10.375 4ZM1.5 14.5V1.5H6.5V4.75C6.5 5.1875 6.8125 5.5 7.25 5.5H10.5V14.5H1.5ZM9.3125 10.0312C8.9375 9.65625 7.84375 9.75 7.28125 9.8125C6.75 9.5 6.40625 9.03125 6.15625 8.375C6.25 7.875 6.46875 7.09375 6.3125 6.625C6.1875 5.8125 5.125 5.875 4.96875 6.4375C4.84375 6.9375 4.96875 7.65625 5.1875 8.53125C4.875 9.28125 4.40625 10.2812 4.09375 10.875C3.46875 11.1875 2.625 11.6875 2.5 12.3125C2.40625 12.8125 3.3125 14.0312 4.875 11.3438C5.5625 11.0938 6.34375 10.8125 7 10.6875C7.59375 11.0312 8.28125 11.2188 8.75 11.2188C9.5625 11.2188 9.625 10.3438 9.3125 10.0312ZM3.125 12.4688C3.28125 12.0312 3.875 11.5312 4.0625 11.375C3.46875 12.3125 3.125 12.4688 3.125 12.4688ZM5.65625 6.5C5.90625 6.5 5.875 7.5 5.71875 7.78125C5.59375 7.34375 5.59375 6.5 5.65625 6.5ZM4.90625 10.7812C5.21875 10.25 5.46875 9.625 5.6875 9.0625C5.9375 9.53125 6.25 9.90625 6.625 10.1562C5.96875 10.3125 5.40625 10.5625 4.90625 10.7812ZM9 10.625C9 10.625 8.84375 10.8125 7.84375 10.375C8.9375 10.2812 9.125 10.5312 9 10.625Z" fill="#586268" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="dashboard-finance-section-invisible-cards-section" style={{paddingBottom: '20px'}}>
                            <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                            <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any active transactions.')}</p>
                            </div>
                            <Link to="/dashboard/transfer/transfers/all" style={{ textDecoration: 'none' }} className="dashboard-finance-section-invisible-cards-section-button">{t('transfers')}</Link>
                        </div>
                }
            </Fragment>

        )
    }
}
export default translate("translation")(Operations);