import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import masterCardIcon from './../../img/card-icon.svg';
import visaCardIcon from './../../img/visa-icon.svg';
// import PINSettingsActions from "./PINSettingsActions/PINSettingsActions";
import YesNoModal from "../../../../copms/Modal/YesNoModal";
// import PINInstruction from "./PINInstruction/PINInstruction";
import Loading from "../../Loading/Loading";
import RadioButton from "../../../../copms/RadioButton/RadioButtonSolid";
import icon1 from "../../img/default_cashback.svg"
import icon2 from "../../img/oil_cashback.svg"
import icon3 from "../../img/default_cashback.svg"
import icon4 from "../../img/default_cashback.svg"
// import PinUnblockSuccess from "../../../../copms/Modal/PinUnblockSuccess";
import { request } from "../../../../config";
import { cardTypes, getDigitalCardInfo, maskCardNumber, setPaymentTypeIcon, deleteOtherBankCard } from '../../../../helpers/cards';
import GeneralModal from "../../../../copms/Modal/GeneralModal";
import NotFound from "../../../../copms/NotFound/NotFound";

const CashBackCondition = ({ translate, logout, updateAppState, alertState, props }) => {
    const { id } = useParams();
    const location = useLocation();
    const card = location.state.card;
    const categoryId = location.state.categoryId;

    const [cardID, setCardID] = useState(id);
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState({
        open: false,
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
    });
    const [conditionsData, setConditionsData] = useState();
    const [conditionModal, setConditionModal] = useState(false);
    const [showConditions, setShowConditions] = useState(Array(conditionsData?.length).fill(false));
    const [selectedCondition, setSelectedCondition] = useState();

    let history = useHistory();

    const handleSubmit = () => {
        setIsLoading(true)
        
        let sendCashBackConditionRequestBody = {
            token: localStorage.getItem("token"),
            categoryId: selectedCondition
        };

        fetch(request("bonus/changeCustCashBackCategory", sendCashBackConditionRequestBody))
            .then((res) => res.text())
            .then((cashBackConditionResponseJSON) => {
                const JSON = require("true-json-bigint");
                let cashBackConditionResponse = JSON.parse(cashBackConditionResponseJSON);
                if (cashBackConditionResponse.status.statusCode !== 1) {
                    if (cashBackConditionResponse.status.statusCode === 200) {
                        logout();
                    } else {
                        setResponse({
                            open: true,
                            feedbackSuccessModal: null,
                            feedbackErrorModal: translate(cashBackConditionResponse.status.statusCode)
                        })
                        setIsLoading(false)
                    }
                }
                else {
                    setResponse({
                        open: true,
                        feedbackSuccessModal: translate(cashBackConditionResponse.status.statusCode),
                        feedbackErrorModal: null
                    })
                    // setIsLoading(false)
                }
            });
    }

    const handleShowCondition = (index) => {
        const updatedShowConditions = [...showConditions];
        updatedShowConditions[index] = !updatedShowConditions[index];
        setShowConditions(updatedShowConditions);
    }

    useEffect(() => {
        setSelectedCondition(categoryId)
        setIsLoading(true)
        let getCashBackConditionsRequestBody = {
            token: localStorage.getItem("token"),
            cardId: cardID,
            lang: localStorage.getItem("i18nextLng").toUpperCase()
        };
        fetch(request("bonus/getCashBackCategories", getCashBackConditionsRequestBody))
            .then((res) => res.text())
            .then((cashBackConditionsResponseJSON) => {
                const JSON = require("true-json-bigint");
                let cashBackConditionsResponse = JSON.parse(cashBackConditionsResponseJSON);
                if (cashBackConditionsResponse.status.statusCode !== 1) {
                    if (cashBackConditionsResponse.status.statusCode === 200) {
                        logout();
                    } else {
                        setResponse({ open: true, feedbackErrorModal: translate(cashBackConditionsResponse.status.statusCode) })
                        // this.setState({ createFeedbackMessage: false,  });
                        setIsLoading(false)
                    }
                }
                else {
                    setConditionsData(cashBackConditionsResponse.categories)
                    // setResponse({open: true, feedbackErrorModal: this.props.t(cashBackConditionsResponse.status.statusCode)})
                    // this.setState({ createFeedbackMessage: true, open: true, operationId: createExchangeOperationResponse.operationId });
                    setIsLoading(false)
                }
            });
    }, []);

    return (
        <>
            <YesNoModal onClose={()=>setConditionModal(false)} title={translate("If you change the category before the current month ends, the accrued cashback will be canceled.")} open={conditionModal} triggerFunc={() => handleSubmit()} />
            <GeneralModal open={response.open} successClose={() => { history.push(`/dashboard/finance/cards/${cardID}`) }} errorClose={() => setResponse({ open: false })} feedbackErrorModal={response.feedbackErrorModal} feedbackSuccessModal={response.feedbackSuccessModal} history={history} />
            <div className="dashboard-header-route">
                <div className="dashboard-header-route-left">
                    <button onClick={() => history.goBack()}>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                        </svg>
                    </button>
                    <h2>{translate("Cashback card")}</h2>
                </div>
                <Link to="/dashboard/finance/cards" className="dashboard-header-route-link">{translate('ALL CARDS')}</Link>
            </div>
            {
                isLoading ?
                    <div className="dashboard-container-main-section">
                        <Loading />
                    </div>
                    :
                    !conditionsData ?
                        <NotFound />
                        :
                        <>
                            <div className="dashboard-container-main-section">
                                <div className="dashboard-finance-section-card-section">
                                    <div className="dashboard-finance-section-card-section-details-section w-100">
                                        <div className="dashboard-finance-section-card-section-details-section-background">
                                            <div className="dashboard-finance-section-card-section-details-section-header">
                                                <div className="dashboard-finance-section-card-section-details-section-header-left-side">
                                                    <h3 className="dashboard-finance-section-card-section-details-section-header-card-number">{translate("Please select an offer from the list")}</h3>
                                                </div>
                                                {
                                                    card && card.cardId !== undefined &&
                                                    <div className="dashboard-finance-section-card-section-details-section-header-left-side">
                                                        <img src={setPaymentTypeIcon(card.cardPaymentType, masterCardIcon, visaCardIcon)} className="dashboard-finance-section-card-section-details-section-header-image" alt="cardIcon" />
                                                        <h3 className="dashboard-finance-section-card-section-details-section-header-card-number">{card.cardType === cardTypes.CARD_STORAGE ? maskCardNumber(card.cardNumber) : card.organizedCardNumber}</h3>
                                                    </div>
                                                }
                                            </div>
                                            <div className="dashboard-finance-section-card-section-details-section-content dashboard-finance-section-card-section-details-section-content-cashbacks">
                                                {conditionsData?.map((data, index) => (
                                                    <div className="dashboard-finance-section-card-section-details-section-content-cashback" key={data?.categoryId}>
                                                        <RadioButton
                                                            value={data?.categoryId}
                                                            selectedId={selectedCondition}
                                                            className="dashboard-payment-container-data-section-content-container-form-group-radio "
                                                            onChange={(e) => setSelectedCondition(data?.categoryId)}
                                                        />
                                                        <div className="dashboard-finance-section-card-section-details-section-content-cashback-card">
                                                            <img className="dashboard-finance-section-card-section-details-section-content-cashback-card-img" src={data?.icon ? 'data:image/;base64,' + data?.icon : icon1} alt="" />
                                                            <div className="dashboard-finance-section-card-section-details-section-content-cashback-card-content">
                                                                {data?.categoryName}
                                                                <div className="dashboard-finance-section-card-section-details-section-content-cashback-card-content-info align-items-start mt-2">
                                                                    <div>
                                                                        <p>{translate("Benefit")}</p>
                                                                        {data?.gains?.map((gain) => (
                                                                            <p className="color mb-1" style={{ color: data?.color }}>{gain?.name}</p>
                                                                        ))}
                                                                    </div>
                                                                    <div>
                                                                        <p>{translate("Payment Form")}</p>
                                                                        <p className="color" style={{ color: data?.color }}>{data?.paymentType}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="dashboard-finance-section-card-section-details-section-content-cashback-card-content-info mt-2" style={{ display: !showConditions[index] && 'none' }}>
                                                                    <div>
                                                                        <p>{translate("Condition")}</p>
                                                                        <p className="condition">{data?.term}</p>
                                                                    </div>
                                                                </div>
                                                                <button className="mt-2" onClick={() => handleShowCondition(index)}>{showConditions[index] ? translate('Hide') : translate('Show more')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="my-3">
                                                    <button
                                                        onClick={() => setConditionModal(true)}
                                                        className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{translate('Confirm')}</button>
                                                    <button onClick={() => setSelectedCondition()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{translate('cancel')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
            }
        </>
    );
};

export default CashBackCondition;
