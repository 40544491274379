import React from "react";

import { digitalCardRequest, requestCardStorage } from "../config";

export const cardTypes = {
  DIGITAL_CARD: "DIGITAL CARD",
  CARD_STORAGE: "CARD STORAGE",
};
export const staticCardsTypes = {
  ADD_CARDS_BTN: "AddCardsBtn",
  ACTIVATE_DIGITAL_CARD_BTN: "ActivateDigitalCardBtn",
};

export const cardPaymentTypes = {
  VISA: "Visa",
  MASTER_CARD: "Mastercard",
};

export const getDigitalCardInfo = (
  updateAppState,
  cardId,
  logout,
  alertState,
  translate,
  closeSuccessFunction
) => {
  updateAppState("isLoadingTransparentBackground", true);
  let requestBody = {
    token: localStorage.getItem("token"),
    lang: localStorage.getItem("i18nextLng").toUpperCase(),
    cardId: cardId,
  };

  fetch(digitalCardRequest("sendSmsDigitalCard", requestBody))
    .then((res) => res.text())
    .then((sendSmsDigitalCardResponseJSON) => {
      const JSON = require("true-json-bigint");
      let sendSmsDigitalCardResponse = JSON.parse(
        sendSmsDigitalCardResponseJSON
      );
      if (sendSmsDigitalCardResponse?.status?.statusCode !== 1) {
        if (sendSmsDigitalCardResponse?.status?.statusCode === 200) {
          logout();
        } else {
          updateAppState("isLoadingTransparentBackground", false);
          alertState(
            true,
            "error",
            translate("an error occurred"),
            translate(sendSmsDigitalCardResponse?.status?.statusCode)
          );
        }
      } else {
        updateAppState("isLoadingTransparentBackground", false);
        alertState(
          true,
          "success",
          translate("Sent via sms"),
          translate(
            "Dear customer, your digital card details have been successfully sent via sms"
          )
          // null,
          // false,
          // closeSuccessFunction
        );
      }
    });
};

export const changeDigitalCardRulesByLang = (lang, openModal) => {
  switch (lang) {
    case "az":
      return (
        <p>
          Rəqəmsal <span onClick={openModal}>kartdan istifadə qaydası</span> ilə
          tanış oldum
        </p>
      );
    case "en":
      return (
        <p>
          I agree with the
          <span onClick={openModal}>Digital card usage rules.</span>
        </p>
      );
    case "ru":
      return (
        <p>
          Я согласен с
          <span onClick={openModal}>
            правилами использования цифровой карты.
          </span>
        </p>
      );
    default:
      return (
        <p>
          Rəqəmsal <span onClick={openModal}>kartdan istifadə qaydası</span> ilə
          tanış oldum
        </p>
      );
  }
};

export const maskCardNumber = (cardNumber) => {
  return `${cardNumber.substring(0, 4)} **** **** ${cardNumber.substring(
    12,
    16
  )}`;
};

export const setPaymentTypeIcon = (
  paymentType,
  masterCardIcon,
  visaCardIcon
) => {
  return paymentType === cardPaymentTypes.MASTER_CARD
    ? masterCardIcon
    : visaCardIcon;
};

export const deleteOtherBankCard = (
  updateAppState,
  cardId,
  logout,
  alertState,
  translate,
  goBack,
  closeSuccessFunction,
  history
) => {
  updateAppState("isLoadingTransparentBackground", true);

  let requestBody = {
    token: localStorage.getItem("token"),
    cardId: cardId,
  };

  fetch(requestCardStorage("storage/deleteStorageCard", requestBody))
    .then((res) => res.text())
    .then(
      (responseJSON) => {
        const JSON = require("true-json-bigint");
        let response = JSON.parse(responseJSON);
        if (response.status.statusCode === 1) {
          alertState(
            true,
            "success",
            translate("successful operation"),
            translate("Your card has been successfully deleted"),
            null,
            goBack,
            closeSuccessFunction
          );
        } else {
          if (response.status.statusCode === 200) {
            logout();
          } else {
            alertState(
              true,
              "error",
              translate("an error occurred"),
              translate(response.status.statusCode)
            );
          }
        }
        updateAppState("isLoadingTransparentBackground", false);
      },
      (error) => {
        history.push("/technical-break");
      }
    );
};
