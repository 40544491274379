import React, { Component, Fragment } from 'react';
import { Switch, Link, NavLink, Redirect, Route } from 'react-router-dom';
import { translate } from 'react-i18next';
import starIcon from './../../img/star.svg';
import partnerIcon from './../../img/partner.svg';
import convertationIcon from './../../img/convertation.svg';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import { request } from './../../../../config';
import operationFilter from '../../../../helpers/operationFilter';
import Loading from '../../Loading/Loading';

class Bonuses extends Component {
    state = {
        isLoading: false,
        bonuses: [],
        bonusBalance: null,
        type: null
    }

    getFormatedDate(date) {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }

    getBonusBuySearchList = (initial) => {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        else {
            this.setState({ isLoading: true });
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusBuySearchListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: 1
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: 1
        }

        fetch(request('bonus/getBonusBuySearchList', getBonusBuySearchListRequestBody))
            .then(res => res.text())
            .then((getBonusBuySearchListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusBuySearchListResponse = JSON.parse(getBonusBuySearchListResponseJSON);
                if (getBonusBuySearchListResponse.status.statusCode !== 1 && getBonusBuySearchListResponse.status.statusCode !== 282) {
                    if (getBonusBuySearchListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                        else {
                            this.setState({ isLoading: false });
                        }
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusBuySearchListResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ lastPage: getBonusBuySearchListResponse.pageCount ? getBonusBuySearchListResponse.pageCount : 0 })
                    if (getBonusBuySearchListResponse.status.statusCode === 282) {
                        getBonusBuySearchListResponse.buyBonusHistoryList = [];
                    }
                    for (let i = 0; i < getBonusBuySearchListResponse.buyBonusHistoryList.length; i++) {
                        // =========AMOUNT=========
                        getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount = getBonusBuySearchListResponse.buyBonusHistoryList[i].amount;
                        if (getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount.toString() === "0") {
                            getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount.toString().indexOf('.') !== -1) {
                                let amountArray = getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount.toString().split('.');
                                getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                let amountArray = getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount.toString().split('.');
                                getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount = {
                                    wholePart: getBonusBuySearchListResponse.buyBonusHistoryList[i].organizedAmount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ bonuses: getBonusBuySearchListResponse.buyBonusHistoryList, type: 1 });
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getBonusPaySearchList = (initial) => {
        if (!initial) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        else {
            this.setState({ isLoading: true });
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusPaySearchListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: 1
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: this.getFormatedDate(this.state.startDate),
            endDate: this.getFormatedDate(this.state.endDate),
            pageNumber: 1
        }

        fetch(request('bonus/getBonusPaySearchList', getBonusPaySearchListRequestBody))
            .then(res => res.text())
            .then((getBonusPaySearchListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusPaySearchListResponse = JSON.parse(getBonusPaySearchListResponseJSON);
                if (getBonusPaySearchListResponse.status.statusCode !== 1 && getBonusPaySearchListResponse.status.statusCode !== 282) {
                    if (getBonusPaySearchListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        if (!initial) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                        else {
                            this.setState({ isLoading: false });
                        }
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusPaySearchListResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ lastPage: getBonusPaySearchListResponse.pageCount ? getBonusPaySearchListResponse.pageCount : 0 })
                    if (getBonusPaySearchListResponse.status.statusCode === 282) {
                        getBonusPaySearchListResponse.paymentList = [];
                    }
                    for (let i = 0; i < getBonusPaySearchListResponse.paymentList.length; i++) {
                        // =========AMOUNT=========
                        getBonusPaySearchListResponse.paymentList[i].organizedAmount = getBonusPaySearchListResponse.paymentList[i].amount;
                        if (getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString() === "0") {
                            getBonusPaySearchListResponse.paymentList[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString().indexOf('.') !== -1) {
                                let amountArray = getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString().split('.');
                                getBonusPaySearchListResponse.paymentList[i].organizedAmount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                let amountArray = getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString().split('.');
                                getBonusPaySearchListResponse.paymentList[i].organizedAmount = {
                                    wholePart: getBonusPaySearchListResponse.paymentList[i].organizedAmount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ bonuses: getBonusPaySearchListResponse.paymentList, type: 2 });
                    if (!initial) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showBonusBalance = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusBalanceRequestBody = customerType === "Juridical" ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("bonus/getBonusBalance", getBonusBalanceRequestBody))
            .then(res => res.text())
            .then((getBonusBalanceResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusBalanceResponse = JSON.parse(getBonusBalanceResponseJSON);
                if (getBonusBalanceResponse.status.statusCode !== 1) {
                    if (getBonusBalanceResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusBalanceResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ bonusBalance: getBonusBalanceResponse.bonusBalance });
                }
            },
                (error) => {

                    this.props.history.push("/technical-break");
                });
    }

    showBonusList = (initial) => {
        if (this.props.match.params.id === '2') {
            this.getBonusPaySearchList(initial);
        }
        else {
            this.getBonusBuySearchList(initial);
        }
    }

    componentDidUpdate(previousProps) {
        if (previousProps.match.params.id !== this.props.match.params.id) {
            this.showBonusList(false);
        }
    }

    componentDidMount() {
        this.showBonusBalance();
        this.showBonusList(true);
    }

    render() {
        let { updateAppState, alertState, t } = this.props;
        let { bonuses, bonusBalance, type, isLoading } = this.state;

        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Bonuses')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">
                            <div className="dashboard-new-transfer-template-section dashboard-new-transfer-template-section__bottom">
                                <div className="dashboard-new-transfer-template-section-background">
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('Spend the stars')}</h3>
                                            {
                                                bonusBalance !== null &&
                                                <div className="dashboard-new-transfer-section-header-title">
                                                    <span className='dashboard-new-transfer-section-header-title-light'>
                                                        {t('balance')}
                                                    </span>
                                                    <span>
                                                        {bonusBalance}
                                                    </span>
                                                    <svg className='dashboard-new-transfer-section-header-title-icon' width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z" fill="#0179D2" />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                        <ul className="dashboard-new-transfer-section-content">
                                            <Link to="/dashboard/payment/bonus/payments" className="dashboard-new-transfer-section-content-item dashboard-new-transfer-section-content-item__large">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={starIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t('Pay with the stars')}</p>
                                            </Link>
                                            {/* <Link to="/dashboard/finance/bonuses" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={partnerIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t("Spend on partners")}</p>
                                            </Link> */}
                                            <Link to="/dashboard/finance/bonuses/convertation" className="dashboard-new-transfer-section-content-item dashboard-new-transfer-section-content-item__large">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={convertationIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t("Conversion")}</p>
                                            </Link>
                                            {/* =========================== */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-transfer-history">
                                <div className="dashboard-last-operations-section-header">
                                    {/* ============================= */}
                                    <div>
                                        <NavLink to="/dashboard/finance/bonuses/1" className="dashboard-transfer-history-header-title dashboard-transfer-history-header-title__bold" activeClassName="dashboard-transfer-history-header-title__active">{t("Earned stars")}</NavLink>
                                        <NavLink to="/dashboard/finance/bonuses/2" className="dashboard-transfer-history-header-title dashboard-transfer-history-header-title__bold" activeClassName="dashboard-transfer-history-header-title__active">{t("Spending stars")}</NavLink>
                                    </div>
                                    {/* ============================= */}
                                    <Link to='/dashboard/finance/bonuses/history' className="dashboard-last-operations-section-header-title"><strong>{t('history')}</strong></Link>
                                </div>
                                {
                                    bonuses?.length > 0 ?
                                        <ul className="dashboard-transfer-history-content">
                                            {
                                                bonuses?.slice(0, 8)?.map((bonus, index) => (
                                                    <li className="dashboard-transfer-history-content-item" key={index}>
                                                        {
                                                            type === 2 ?
                                                                <>
                                                                    <div className="dashboard-transfer-history-content-item-container__left-side">
                                                                        <div className="dashboard-transfer-history-content-item-container-icon-section">
                                                                            <img src={starIcon} className="dashboard-transfer-history-content-item-container-icon" />
                                                                        </div>
                                                                        <div className="dashboard-transfer-history-content-item-container-content">
                                                                            <p className="dashboard-transfer-history-content-item-container-name">{bonus.categoryName} {bonus.merchantName}</p>
                                                                            <p className="dashboard-transfer-history-content-item-container-iban">{bonus.billingDate && `${bonus.billingDate.split(' ')[0]}, ${bonus.billingDate.split(' ')[1]}`}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dashboard-transfer-history-content-item-container__right-side">
                                                                        <div className="dashboard-transfer-history-content-item-container-content">
                                                                            <div className="dashboard-transfer-history-content-item-container-bonus">
                                                                                <span className="dashboard-transfer-history-content-item-container-bonus-text">-{bonus.payBonus}</span>
                                                                                <svg className='dashboard-transfer-history-content-item-container-bonus-icon' width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z" fill="#909090" />
                                                                                </svg>
                                                                            </div>
                                                                            <p className="dashboard-transfer-history-content-item-container-type">
                                                                                <div className="dashboard-transfer-history-content-item-container-amount">
                                                                                    <span className="dashboard-transfer-history-content-item-container-amount-whole">{`${bonus?.organizedAmount.wholePart}`}</span>
                                                                                    <span className="dashboard-transfer-history-content-item-container-amount-fractional">{bonus?.organizedAmount.fractionalPart}</span>
                                                                                    <span className="dashboard-transfer-history-content-item-container-amount-currency">{bonus.currency ? bonus.currency : 'AZN'}</span>
                                                                                </div>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="dashboard-transfer-history-content-item-container__left-side">
                                                                        <div className="dashboard-transfer-history-content-item-container-icon-section">
                                                                            <img src={starIcon} className="dashboard-transfer-history-content-item-container-icon" />
                                                                        </div>
                                                                        <div className="dashboard-transfer-history-content-item-container-content">
                                                                            <p className="dashboard-transfer-history-content-item-container-name">{bonus.name}</p>
                                                                            <p className="dashboard-transfer-history-content-item-container-iban">{bonus.operDate && `${bonus.operDate.split(' ')[0]}, ${bonus.operDate.split(' ')[1]}`}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dashboard-transfer-history-content-item-container__right-side">
                                                                        <div className="dashboard-transfer-history-content-item-container-content">
                                                                            <div className="dashboard-transfer-history-content-item-container-bonus">
                                                                                <span className="dashboard-transfer-history-content-item-container-bonus-text">+{bonus.quantity}</span>
                                                                                <svg className='dashboard-transfer-history-content-item-container-bonus-icon' width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z" fill="#0179D2" />
                                                                                </svg>
                                                                            </div>
                                                                            <p className="dashboard-transfer-history-content-item-container-type">
                                                                                <div className="dashboard-transfer-history-content-item-container-amount">
                                                                                    <span className="dashboard-transfer-history-content-item-container-amount-whole">{bonus?.organizedAmount.wholePart}</span>
                                                                                    <span className="dashboard-transfer-history-content-item-container-amount-fractional">{bonus?.organizedAmount.fractionalPart}</span>
                                                                                    <span className="dashboard-transfer-history-content-item-container-amount-currency">{bonus.currency ? bonus.currency : 'AZN'}</span>
                                                                                </div>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        :

                                        <div className="dashboard-last-operations-section-content">
                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                            <ul className="dashboard-last-operations-section-content-invisible-list">
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(Bonuses);