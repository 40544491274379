import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';
import { useState } from 'react';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import printJs from 'print-js';
import Base64 from 'base-64';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function CashbackStatementModal(props) {
    useEffect(() => {
        setData(props.data)
    }, [props.data])
    const classes = useStyles();
    const [data, setData] = useState();

    const saveDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.setDrawColor('#F8FAFB')
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });
    }

    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        const scrollHeight = input.scrollHeight;
        window.scrollTo(0, 0);
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.setDrawColor('#F8FAFB')
                printJs({ printable: Base64.encode(pdf.output()), type: 'pdf', base64: true })
                window.scrollTo(0, scrollHeight);
            });
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.close() }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={props.open}>
                    <div className='bg-white'>
                        <div className="simple-modal-cashback-statement pb-0" id="divToPrint">
                            <img src={`data:image/;base64,${data?.icon}`} alt="" srcset="" width={48} height={48} />
                            <div className="dashboard-last-operations-section-item-amount mt-4">
                                <span className="dashboard-last-operations-section-item-amount-whole-part">{Math.floor(data?.amount)}</span>
                                <span className="dashboard-last-operations-section-item-amount-fractional-part">{(data?.amount - Math.floor(data?.amount)).toFixed(2).slice(2)}</span>
                                <span className="dashboard-last-operations-section-item-amount-currency">{data?.currency}</span>
                            </div>
                            <span className='simple-modal-cashback-statement-cashback'>{props.t('Select the condition')} {data?.cashBackAmount} {data?.currency}</span>
                            <ul className='p-0 mt-3'>
                                {data?.rrn && <li> <span>{props.t('Receipt')} №</span>
                                    <h4>{data?.rrn}</h4>
                                </li>
                                }
                                <li>
                                    <span>{props.t('Payment amount')}</span>
                                    <h4>{data?.amount} {data?.currency}</h4>
                                </li>
                                {data?.cashBackAmount && <li> <span>{props.t('Select the condition')}</span>
                                    <h4>{data?.cashBackAmount} {data?.currency}</h4>
                                </li>
                                }
                                {data?.paymentDate &&
                                    <li>
                                        <span>{props.t('Date of operation')}</span>
                                        <h4>{format(new Date(data?.paymentDate), "dd.MM.yyyy, HH:mm")}</h4>
                                    </li>
                                }
                                {data?.cardNumber &&
                                    <li>
                                        <span>{props.t('Card number')}</span>
                                        <h4>{data?.cardNumber}</h4>
                                    </li>
                                }
                                {data?.mccCode &&
                                    <li> <span>{props.t('MCC')}</span>
                                        <h4>{data?.mccCode}</h4>
                                    </li>
                                }
                                {data?.categoryName &&
                                    <li> <span>{props.t('Category')}</span>
                                        <h4>{data?.categoryName}</h4>
                                    </li>
                                }
                            </ul>

                        </div>
                        <div className="simple-modal-cashback-statement pt-0" >
                            <button className="simple-modal-cashback-statement-button m-0" onClick={() => { props.close() }}>{props.t('close')}</button>
                            <div className="payment-modal-succes-left-footer w-100 mt-4">
                                <button onClick={() => printDocument()}>
                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.25 6V2.88281C12.25 2.63672 12.1406 2.41797 11.9766 2.25391L10.7461 1.02344C10.582 0.859375 10.3633 0.75 10.1172 0.75H2.625C2.13281 0.75 1.75 1.16016 1.75 1.625V6C0.765625 6 0 6.79297 0 7.75V10.8125C0 11.0586 0.191406 11.25 0.4375 11.25H1.75V13.875C1.75 14.3672 2.13281 14.75 2.625 14.75H11.375C11.8398 14.75 12.25 14.3672 12.25 13.875V11.25H13.5625C13.7812 11.25 14 11.0586 14 10.8125V7.75C14 6.79297 13.207 6 12.25 6ZM10.5 13H3.5V10.375H10.5V13ZM10.5 6.875H3.5V2.5H8.75V3.8125C8.75 4.05859 8.94141 4.25 9.1875 4.25H10.5V6.875ZM11.8125 8.84375C11.4297 8.84375 11.1562 8.57031 11.1562 8.1875C11.1562 7.83203 11.4297 7.53125 11.8125 7.53125C12.168 7.53125 12.4688 7.83203 12.4688 8.1875C12.4688 8.57031 12.168 8.84375 11.8125 8.84375Z" fill="#AABDCF" />
                                    </svg>
                                    <span>{props.t('Print the receipt')}</span>
                                </button>
                                <button onClick={() => saveDocument()}>
                                    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.125 4.46875V0.75H0.65625C0.273438 0.75 0 1.05078 0 1.40625V14.0938C0 14.4766 0.273438 14.75 0.65625 14.75H9.84375C10.1992 14.75 10.5 14.4766 10.5 14.0938V5.125H6.78125C6.39844 5.125 6.125 4.85156 6.125 4.46875ZM8.20312 10.2656L5.57812 12.8906C5.38672 13.0547 5.08594 13.0547 4.89453 12.8906L2.26953 10.2656C1.99609 9.99219 2.1875 9.5 2.57031 9.5H4.375V7.3125C4.375 7.09375 4.56641 6.875 4.8125 6.875H5.6875C5.90625 6.875 6.125 7.09375 6.125 7.3125V9.5H7.90234C8.28516 9.5 8.47656 9.99219 8.20312 10.2656ZM10.3086 3.62109L7.62891 0.941406C7.49219 0.832031 7.32812 0.75 7.16406 0.75H7V4.25H10.5V4.08594C10.5 3.92188 10.418 3.75781 10.3086 3.62109Z" fill="#AABDCF" />
                                    </svg>
                                    <span>{props.t('Download')}</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(CashbackStatementModal);