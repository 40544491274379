import React, { useState, useEffect } from "react";

import "./TimerCountdown.scss";

const TimerCountdown = ({
  resendTitle,
  initialMinute,
  initialSeconds,
  sendOtpCode,
  setErrMessage,
}) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isDisabled, setIsDisabled] = useState(true);
  const case1 = "00:00";
  
  const resetTime = () => {
    setErrMessage("");
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
    setIsDisabled(true);
    sendOtpCode();
    // console.log("resettime---");
  };

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  useEffect(() => {
    let timeInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timeInterval);
          // console.log("test");
          setIsDisabled(false);
        } else {
          setMinutes(minutes - 1);

          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  });

  return (
    resendTitle && (
      <div className="ibank-otp__resend">
        <button
          disabled={isDisabled}
          onClick={() => {
            resetTime();
          }}
        >
          {resendTitle}
        </button>
        <p className="ibank-otp__resend__timer">
          {minutes === 0 && seconds === 0
            ? case1
            : `${formatTime(minutes)} : ${formatTime(seconds)}`}
        </p>
      </div>
    )
  );
};

export default TimerCountdown;
