import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import creditIcon from './../../img/credit-icon.svg';
import cardsIcon from './../../img/cards-icon.png';
import { request } from './../../../../config';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import CreditSchedule from './../../../../copms/Modal/CreditSchedule';
import CreditPayment from './../../../../copms/Modal/CreditPayments';
import { translate } from 'react-i18next';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';

class Credits extends Component {
    state = {
        credits: [],
        totalAmountAZN: 0,
        totalAmountUSD: 0,
        totalAmountEUR: 0,
        openCreditSchedule: false,
        openCreditPayment: false,
        loanId: null,
        isLoading: false
    }
    dropdownItemHandler = (index, id) => {
        switch (index) {
            case 1:
                this.props.updateAppState('isLoadingTransparentBackground', true);

                this.setState({ loanId: id }, () => {
                    this.setState({ openCreditSchedule: true });
                });

                break;
            case 2:
                this.props.updateAppState('isLoadingTransparentBackground', true);

                this.setState({ loanId: id }, () => {
                    this.setState({ openCreditPayment: true });
                })
                break;
        }
    }
    numberToString = (number) => {
        if (number.toString() === "0") {
            return "0.00"
        }
        else {
            if (number.toString().indexOf('.') !== -1) {
                let numberArray = number.toString().split('.');
                let x = (numberArray[1].length === 1) ? (numberArray[1] + '0') : numberArray[1];
                return numberArray[0] + "." + x;
            }
            else {
                return number.toString() + ".00"
            }
        }
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let loanRequestBody = customerType === "Individual" ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("loan/loan" + customerType, loanRequestBody))
            .then(res => res.text())
            .then((loanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let loanResponse = JSON.parse(loanResponseJSON);
                if (loanResponse.status.statusCode !== 1 && loanResponse.status.statusCode !== 139) {
                    if (loanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(loanResponse.status.statusCode));
                        this.setState({ isLoading: false });

                    }
                }
                else {
                    if (loanResponse.status.statusCode === 139) {
                        loanResponse.loans = [];
                    }
                    let totalAmount = {
                        azn: 0,
                        usd: 0,
                        eur: 0
                    }
                    for (let i = 0; i < loanResponse.loans.length; i++) {
                        // // =========ID=========
                        let id = "";
                        if (typeof (loanResponse.loans[i].id) === 'object' && loanResponse.credit[i].id.hasOwnProperty('c')) {
                            loanResponse.credit[i].id.c.forEach(part => {
                                id += part;
                            });
                        }
                        else {
                            id += loanResponse.loans[i].id;
                        }
                        loanResponse.loans[i].id = id;
                        // =========BALANCE=========
                        if (loanResponse.loans[i].loanAmount.toString() === "0") {
                            loanResponse.loans[i].organizedLoanAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (loanResponse.loans[i].loanAmount.toString().indexOf('.') !== -1) {
                                let loanAmountArray = loanResponse.loans[i].loanAmount.toString().split('.');
                                loanResponse.loans[i].organizedLoanAmount = {
                                    wholePart: loanAmountArray[0],
                                    fractionalPart: (loanAmountArray[1].length === 1) ? (loanAmountArray[1] + '0') : loanAmountArray[1]
                                }
                            }
                            else {
                                let loanAmountArray = loanResponse.loans[i].loanAmount.toString().split('.');
                                loanResponse.loans[i].organizedLoanAmount = {
                                    wholePart: loanResponse.loans[i].loanAmount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========REST=========
                        if (loanResponse.loans[i].rest.toString() === "0") {
                            loanResponse.loans[i].organizedRestAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (loanResponse.loans[i].rest.toString().indexOf('.') !== -1) {
                                let restArray = loanResponse.loans[i].rest.toString().split('.');
                                loanResponse.loans[i].organizedRestAmount = {
                                    wholePart: restArray[0],
                                    fractionalPart: (restArray[1].length === 1) ? (restArray[1] + '0') : restArray[1]
                                }
                            }
                            else {
                                let restArray = loanResponse.loans[i].rest.toString().split('.');
                                loanResponse.loans[i].organizedRestAmount = {
                                    wholePart: loanResponse.loans[i].rest.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        loanResponse.loans[i].currencyIcon = currencyFilter(loanResponse.loans[i].currency);
                        // =========TOTALAMOUNT=========
                        if (loanResponse.loans[i].currency === "AZN") {
                            totalAmount.azn += loanResponse.loans[i].loanAmount;
                        }
                        if (loanResponse.loans[i].currency === "USD") {
                            totalAmount.usd += loanResponse.loans[i].loanAmount;
                        }
                        if (loanResponse.loans[i].currencys === "EUR") {
                            totalAmount.eur += loanResponse.loans[i].loanAmount;
                        }
                    }
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });
                    this.setState({ credits: loanResponse.loans });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    render() {
        let { credits, totalAmountAZN, totalAmountUSD, totalAmountEUR, openCreditSchedule, openCreditPayment, loanId, isLoading } = this.state;
        let { t } = this.props;

        return (
            <>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('loans')}</h2>
                    </div>
                    <a href={`http://www.turanbank.az/${localStorage.getItem('i18nextLng').toLowerCase() ? localStorage.getItem('i18nextLng').toLowerCase() : 'AZ'}/pages/370`} target="blank" className="dashboard-header-route-link">{t('CREDIT PRODUCTS')}</a>
                </div>
                <CreditSchedule {...this.props} open={openCreditSchedule} id={loanId} onClose={() => this.setState({ openCreditSchedule: false, loanId: null })} />
                <CreditPayment {...this.props} open={openCreditPayment} id={loanId} onClose={() => this.setState({ openCreditPayment: false, loanId: null })} />
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            (credits.length === 0) ?
                                <div className="dashboard-finance-section-invisible-cards-section">
                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have any active credit.')}</p>
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('please order a new loan.')}</p>
                                    </div>
                                    <Link to="/dashboard/orders/credit" className="dashboard-finance-section-invisible-cards-section-button">{t('ORDER A CREDIT')}</Link>
                                </div>
                                :
                                <div className="dashboard-finance-section-cards-section">
                                    <div className="dashboard-finance-section-cards-section-statistics-section">

                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon dashboard-finance-section-cards-section-statistics-icon__azn">₼</span>{totalAmountAZN}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("credit balance")} (AZN)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#0066B2" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#0066B2" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">$</span>{totalAmountUSD}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("card balance")} (USD)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#6DD230" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#6DD230" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="dashboard-finance-section-cards-section-statistics-section-item">
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-balance"><span className="dashboard-finance-section-cards-section-statistics-icon">€</span>{totalAmountEUR}</p>
                                                <p className="dashboard-finance-section-cards-section-statistics-section-item-currency">{t("card balance")} (EUR)</p>
                                            </div>
                                            <div className="dashboard-finance-section-cards-section-statistics-section-item-left-side">
                                                <svg className="dashboard-finance-section-cards-section-statistics-section-item-graphics" width="162" height="61" viewBox="0 0 162 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 56.4446V36.9446L4 37.4446L11.5 36.9446L20.5 35.4446L31 33.4446L39.5 32.9446L47 34.9446L53.5 37.4446L62.5 40.4446L71 42.4446C74.8333 42.9446 78.6 44.4446 79 44.4446C79.4 44.4446 90.1667 43.9446 93.5 43.9446L103.5 41.4446L118.5 36.4446L136.5 28.4446L148 24.4446L154.5 22.9446H161V56.4446H1Z" fill="url(#paint0_linear)" />
                                                    <path d="M1.53467 37.3042C12.0258 38.6957 28.2515 31.6169 40.8712 33.2614C53.2112 35.4517 57.709 40.964 76.1321 43.7489C109.473 48.9169 141.225 20.052 160.419 23.001" stroke="#FFAB2B" strokeWidth="1.5" strokeLinecap="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="0.999999" y1="-0.0554165" x2="1" y2="45.9446" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FFAB2B" />
                                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-finance-section-cards-section-table-section">
                                        <table className="dashboard-finance-section-cards-section-table">
                                            <tbody className="dashboard-finance-section-cards-section-tbody">
                                                {
                                                    credits.map((credit, index) =>

                                                        <Fragment key={index}>
                                                            <tr className="dashboard-finance-section-cards-section-row">
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <img src={creditIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                    <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                        <Link to={"/dashboard/finance/credits/" + credit.id + '/' + credit.iban} className="dashboard-finance-section-cards-section-cell-content">{credit.loanType}</Link>
                                                                        <Link to={"/dashboard/finance/credits/" + credit.id + '/' + credit.iban} className="dashboard-finance-section-cards-section-cell-title">{credit.iban}</Link>
                                                                    </div>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content">{credit.annualInterestRate}%</p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t('interest rate')}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content">
                                                                        <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                        <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedLoanAmount.wholePart}</span>
                                                                        <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedLoanAmount.fractionalPart}</span>
                                                                    </p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t('loan amount')}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedRestAmount.wholePart}</span>
                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedRestAmount.fractionalPart}</span>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t('total debt')}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    <p className="dashboard-finance-section-cards-section-cell-content">{credit.currency}</p>
                                                                    <p className="dashboard-finance-section-cards-section-cell-title">{t('currency')}</p>
                                                                </td>
                                                                <td className="dashboard-finance-section-cards-section-cell">
                                                                    {
                                                                        // TODO 
                                                                        credit.creditSourceType === 0 ?
                                                                            <div className="dashboard-finance-section-cards-section-button-group">
                                                                                <Dropdown dropdownItems={[
                                                                                    { title: this.props.t('make a payment'), url: `/dashboard/finance/credits/pay/${credit.id}/${credit.iban}` },
                                                                                    { title: this.props.t('payment schedule') },
                                                                                    { title: this.props.t('payments') },
                                                                                ]}
                                                                                    dropdownItemHandler={(i) => this.dropdownItemHandler(i, credit.id, credit.iban)} />
                                                                            </div>
                                                                            :
                                                                            <div className="dashboard-finance-section-cards-section-button-group">
                                                                                <Dropdown dropdownItems={[
                                                                                    { title: this.props.t('payment schedule') },
                                                                                    { title: this.props.t('payments') },
                                                                                ]}
                                                                                    dropdownItemHandler={(i) => this.dropdownItemHandler(i + 1, credit.id)} />
                                                                            </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="dashboard-finance-row-line">
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                    }
                </div>
            </>
        )
    }
}

export default translate("translation")(Credits);