import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import accountIcon from './../../img/account-icon.svg';
import cardsIcon from './../../img/cards-icon.png';
import cardBlueBackground from './../../img/card-blue-background.svg';
import cardTransparentBackground from './../../img/card-transparent-background.svg';
import { request } from './../../../../config';
import StartDatePicker from './StartDatePicker/StartDatePicker';
import EndDatePicker from './EndDatePicker/EndDatePicker';
import AccountModal from './../../../../copms/Modal/AccountModal';
import NotFound from '../../../../copms/NotFound/NotFound';
import { translate } from 'react-i18next';
import currencyFilter from './../../../../helpers/currencyFilter';
import Loading from './../../Loading/Loading';
import $ from 'jquery';

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

class Account extends Component {
    state = {
        account: null,
        statements: [],
        open: false,
        isLoading: false
    }
    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    getFormatedDate = (date) => {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }
    showAccount = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountByIdForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("account/getAccountByIdFor" + customerType + 'Customer', getAccountByIdForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountByIdForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountByIdForCustomerResponse = JSON.parse(getAccountByIdForCustomerResponseJSON);
                if (getAccountByIdForCustomerResponse.status.statusCode !== 1) {
                    if (getAccountByIdForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    // =========CURRENCY=========
                    getAccountByIdForCustomerResponse.currencyIcon = currencyFilter(getAccountByIdForCustomerResponse.currName);
                    // =========BALANCE=========
                    if (getAccountByIdForCustomerResponse.currentBalance.toString() === "0") {
                        getAccountByIdForCustomerResponse.organizedCurrentBalance = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getAccountByIdForCustomerResponse.currentBalance.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = getAccountByIdForCustomerResponse.currentBalance.toString().split('.');
                            getAccountByIdForCustomerResponse.organizedCurrentBalance = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            getAccountByIdForCustomerResponse.organizedCurrentBalance = {
                                wholePart: getAccountByIdForCustomerResponse.currentBalance.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    this.setState({ account: getAccountByIdForCustomerResponse });
                    this.showStatements(new Date(), new Date(), true);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showStatements = (startDate = this.state.startDate, endDate = this.state.endDate, initial = false) => {
        if (initial) {
            this.setState({ isLoading: true });
        }
        else {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        this.setState({ startDate, endDate });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountStatementForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.state.account.accountId,
            startDate: this.getFormatedDate(startDate),
            endDate: this.getFormatedDate(endDate),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.state.account.accountId,
                startDate: this.getFormatedDate(startDate),
                endDate: this.getFormatedDate(endDate),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("account/getAccountStatementFor" + customerType + "Customer", getAccountStatementForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountStatementForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountStatementForCustomerResponse = JSON.parse(getAccountStatementForCustomerResponseJSON);
                if (getAccountStatementForCustomerResponse.status.statusCode !== 1 && getAccountStatementForCustomerResponse.status.statusCode !== 134) {
                    if (getAccountStatementForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        if (initial) {
                            this.setState({ isLoading: false });
                        }
                        else {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                }
                else {
                    if (getAccountStatementForCustomerResponse.status.statusCode === 134) {
                        getAccountStatementForCustomerResponse.accountStatementList = [];
                    }
                    for (let i = 0; i < getAccountStatementForCustomerResponse.accountStatementList.length; i++) {
                        // =========AMOUNT=========
                        if (getAccountStatementForCustomerResponse.accountStatementList[i].dtAmount === 0) {
                            getAccountStatementForCustomerResponse.accountStatementList[i].amount = getAccountStatementForCustomerResponse.accountStatementList[i].crAmount;
                            getAccountStatementForCustomerResponse.accountStatementList[i].account = getAccountStatementForCustomerResponse.accountStatementList[i].dtAccount;

                        }
                        else {
                            if (getAccountStatementForCustomerResponse.accountStatementList[i].crAmount === 0) {
                                getAccountStatementForCustomerResponse.accountStatementList[i].amount = getAccountStatementForCustomerResponse.accountStatementList[i].dtAmount;
                                getAccountStatementForCustomerResponse.accountStatementList[i].account = getAccountStatementForCustomerResponse.accountStatementList[i].crAccount;
                            }
                        }
                        if (getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString() === "0") {
                            getAccountStatementForCustomerResponse.accountStatementList[i].amount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString().indexOf('.') !== -1) {
                                let amountArray = getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString().split('.');
                                getAccountStatementForCustomerResponse.accountStatementList[i].amount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                let amountArray = getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString().split('.');
                                getAccountStatementForCustomerResponse.accountStatementList[i].amount = {
                                    wholePart: getAccountStatementForCustomerResponse.accountStatementList[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ statements: getAccountStatementForCustomerResponse.accountStatementList });
                    if (initial) {
                        this.setState({ isLoading: false });
                    }
                    else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showRequisites = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountRequisiteIndividualByteArrayRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                accountId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        fetch(request("account/getAccountRequisite" + customerType + "ByteArray", getAccountRequisiteIndividualByteArrayRequestBody))
            .then(res => res.json())
            .then((getAccountRequisiteByteArrayResponse) => {
                if (getAccountRequisiteByteArrayResponse.status.statusCode !== 1) {
                    if (getAccountRequisiteByteArrayResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(getAccountRequisiteByteArrayResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Account_Requisites') + "_" + this.getFormatedDate(this.state.startDate) + "-" + this.getFormatedDate(this.state.endDate) + ".pdf", "application/pdf", arrrayBuffer)
                    }

                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }
    dropdownItemHandler = () => {
        this.setState({
            open: true,
        });
    }
    componentDidMount() {
        this.showAccount();
    }
    render() {
        let { account, statements, open, isLoading } = this.state;
        let accountId = this.props.match.params.id;
        let { t, updateAppState } = this.props;
        return (
            <>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{account ? account.accountName && account.accountName : t('accounts')}</h2>
                    </div>
                    <Link to="/dashboard/finance/accounts/" className="dashboard-header-route-link">{t('ALL ACCOUNTS')}</Link>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !account ?
                            <NotFound />
                            :
                            <div className="dashboard-container-main-section">
                                <AccountModal open={open} modalAccountObj={account} updateOpenModal={this.updateOpenModal} updateAppState={updateAppState} showAccount={this.showAccount} />
                                <div className="dashboard-finance-section-card-section-details-section">
                                    <div className="dashboard-finance-section-card-section-details-section-background">
                                        <div className="dashboard-finance-section-card-section-details-section-header">
                                            {
                                                account !== null ?
                                                    <div className="dashboard-finance-section-card-section-details-section-header-left-side">
                                                        <img src={accountIcon} className="dashboard-finance-section-card-section-details-section-header-image" alt="accountIcon" />
                                                        <h3 className="dashboard-finance-section-card-section-details-section-header-card-number">{account.iban}</h3>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                account !== null ?
                                                    <div className="dashboard-finance-section-card-section-details-section-header-right-side">
                                                        <span className={"dashboard-finance-section-card-section-details-section-header-amount-currency dashboard-finance-section-card-section-details-section-header-amount-currency__" + account.currName.toLowerCase()}>{account.currencyIcon}</span>
                                                        <span className="dashboard-finance-section-card-section-details-section-header-amount-whole-part">{account.organizedCurrentBalance.wholePart}</span>
                                                        <span className="dashboard-finance-section-card-section-details-section-header-amount-fractional-part">{account.organizedCurrentBalance.fractionalPart}</span>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                        <div className="dashboard-finance-section-card-section-details-section-content">
                                            <div className="dashboard-finance-section-card-section-details-section-content-card">
                                                {
                                                    (account !== null) ?
                                                        <img src={cardBlueBackground} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="cardBlueBackground" />
                                                        :
                                                        <img src={cardTransparentBackground} className="dashboard-finance-section-card-section-details-section-content-card-background" alt="cardTransparentBackground" />
                                                }
                                                <div className="dashboard-finance-section-card-section-details-section-content-card-item">
                                                    {
                                                        account !== null ?
                                                            <div className="dashboard-finance-section-card-section-details-section-content-card-item-account-number-section">
                                                                <h4 className="dashboard-finance-section-card-section-details-section-content-card-item-account-number-content">{account.iban}</h4>
                                                                <p className="dashboard-finance-section-card-section-details-section-content-card-item-account-number-title">{t('account number')}</p>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className="dashboard-finance-section-card-section-details-section-content-button-section">
                                                <Link to="/dashboard/payment/payments" className="dashboard-finance-section-card-section-details-section-content-button-container">
                                                    <button className="dashboard-finance-section-card-section-details-section-content-button">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.518 0.143901L0.505898 8.20172C-0.213471 8.60774 -0.150917 9.66962 0.599729 9.98194L5.00977 11.7934V14.979C5.00977 15.9472 6.16702 16.3532 6.76128 15.6349L8.66917 13.3237L12.6101 14.9478C13.2043 15.1977 13.8924 14.8229 13.9862 14.167L15.988 1.14332C16.1131 0.331292 15.2373 -0.293345 14.518 0.143901ZM6.01063 14.979V12.2306L7.69959 12.9177L6.01063 14.979ZM13.0167 14.0421L6.54234 11.3561L12.7977 4.01665C12.9541 3.82926 12.7039 3.57941 12.5162 3.73557L4.54062 10.5441L1.00633 9.07621L15.0184 0.987162L13.0167 14.0421Z" fill="#849097" />
                                                        </svg>
                                                    </button>
                                                    <p className="dashboard-finance-section-card-section-details-section-content-title">{t('make a payment')}</p>
                                                </Link>
                                                <Link to="/dashboard/transfer/transfers" className="dashboard-finance-section-card-section-details-section-content-button-container">
                                                    <button className="dashboard-finance-section-card-section-details-section-content-button">
                                                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.8752 3.67164C16.0312 3.50882 16.0312 3.28087 15.8752 3.11805L13.0044 0.122117C12.8484 -0.0407056 12.5987 -0.0407056 12.4739 0.122117L12.2243 0.350068C12.0995 0.51289 12.0995 0.740841 12.2243 0.903664L14.1277 2.85753H0.397855C0.179425 2.85753 0.0234032 3.05292 0.0234032 3.2483V3.50882C0.0234032 3.73677 0.179425 3.89959 0.397855 3.89959H14.1277L12.2243 5.88602C12.0995 6.04885 12.0995 6.2768 12.2243 6.43962L12.4739 6.66757C12.5987 6.83039 12.8484 6.83039 13.0044 6.66757L15.8752 3.67164ZM0.117016 8.88195L3.01902 11.8779C3.14383 12.0407 3.39347 12.0407 3.51828 11.8779L3.76792 11.6499C3.89274 11.4871 3.89274 11.2592 3.76792 11.0963L1.86446 9.10991H15.6255C15.8128 9.10991 16 8.94708 16 8.71913V8.45862C16 8.26323 15.8128 8.06784 15.6255 8.06784H1.86446L3.76792 6.11398C3.89274 5.95115 3.89274 5.7232 3.76792 5.56038L3.51828 5.33243C3.39347 5.16961 3.14383 5.16961 3.01902 5.33243L0.117016 8.32836C-0.0390054 8.49118 -0.0390054 8.71913 0.117016 8.88195Z" fill="#849097" />
                                                        </svg>
                                                    </button>
                                                    <p className="dashboard-finance-section-card-section-details-section-content-title">{t('transfer')}</p>
                                                </Link>
                                                <Link to={"/dashboard/finance/accounts/extract/" + accountId} className="dashboard-finance-section-card-section-details-section-content-button-container">
                                                    <button className="dashboard-finance-section-card-section-details-section-content-button">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                        </svg>
                                                    </button>
                                                    <p className="dashboard-finance-section-card-section-details-section-content-title">{t('extract')}</p>
                                                </Link>
                                                <div className="dashboard-finance-section-card-section-details-section-content-button-container" onClick={this.showRequisites}>
                                                    <button className="dashboard-finance-section-card-section-details-section-content-button">
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.5312 6.0625L11.9375 4.46875C11.6875 4.21875 11.2188 4 10.875 4H10V2.5C10 1.6875 9.3125 1 8.5 1H6.40625C6.1875 0.4375 5.625 0 5 0C4.34375 0 3.78125 0.4375 3.5625 1H1.5C0.65625 1 0 1.6875 0 2.5V11.5C0 12.3438 0.65625 13 1.5 13H4V14.5C4 15.3438 4.65625 16 5.5 16H12.5C13.3125 16 14 15.3438 14 14.5V7.125C14 6.78125 13.7812 6.3125 13.5312 6.0625ZM12.8438 6.78125C12.9062 6.84375 12.9375 6.9375 12.9688 7H11V5.03125C11.0625 5.0625 11.1562 5.09375 11.2188 5.15625L12.8438 6.78125ZM5 0.9375C5.28125 0.9375 5.5625 1.21875 5.5625 1.5C5.5625 1.8125 5.28125 2.0625 5 2.0625C4.6875 2.0625 4.4375 1.8125 4.4375 1.5C4.4375 1.21875 4.6875 0.9375 5 0.9375ZM1.5 12C1.21875 12 1 11.7812 1 11.5V2.5C1 2.25 1.21875 2 1.5 2H3.5625C3.78125 2.59375 4.34375 3 5 3C5.625 3 6.1875 2.59375 6.40625 2H8.5C8.75 2 9 2.25 9 2.5V4H5.5C4.65625 4 4 4.6875 4 5.5V12H1.5ZM12.5 15H5.5C5.21875 15 5 14.7812 5 14.5V5.5C5 5.25 5.21875 5 5.5 5H10V7.25C10 7.6875 10.3125 8 10.75 8H13V14.5C13 14.7812 12.75 15 12.5 15Z" fill="#849097" />
                                                        </svg>
                                                    </button>
                                                    <p className="dashboard-finance-section-card-section-details-section-content-title">{t('details')}</p>
                                                </div>
                                                <div className="dashboard-finance-section-card-section-details-section-content-button-container" onClick={this.dropdownItemHandler}>
                                                    <button className="dashboard-finance-section-card-section-details-section-content-button">
                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.4062 1.78125L15.2188 0.59375C14.8438 0.21875 14.3125 0 13.8125 0C13.3125 0 12.7812 0.21875 12.4062 0.59375L1.375 11.625L1 15.1875C0.9375 15.625 1.28125 16 1.71875 16C1.75 16 1.78125 16 1.8125 16L5.375 15.625L16.4062 4.59375C17.1875 3.8125 17.1875 2.5625 16.4062 1.78125ZM4.9375 14.6562L2.03125 14.9688L2.34375 12.0625L10.5938 3.8125L13.1875 6.40625L4.9375 14.6562ZM15.6875 3.90625L13.875 5.71875L11.2812 3.125L13.0938 1.3125C13.2812 1.125 13.5312 1 13.8125 1C14.0938 1 14.3438 1.125 14.5312 1.3125L15.6875 2.46875C16.0938 2.875 16.0938 3.5 15.6875 3.90625Z" fill="#849097" />
                                                        </svg>
                                                    </button>
                                                    <p className="dashboard-finance-section-card-section-details-section-content-title">{t('change name')}</p>
                                                </div>
                                            </div>
                                            <ul className="dashboard-finance-section-card-section-details-section-content-list">
                                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                    <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('account name')}</p>
                                                    {
                                                        account !== null ?
                                                            <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{account.accountName}</p>
                                                            : null
                                                    }

                                                </li>
                                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                    <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('account number')}</p>
                                                    {
                                                        account !== null ?
                                                            <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{account.iban}</p>
                                                            : null
                                                    }
                                                </li>
                                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                    <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('currency')}</p>
                                                    {
                                                        account !== null ?
                                                            <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{account.currName}</p>
                                                            : null
                                                    }
                                                </li>
                                                <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                                    <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">{t('name of the branch')}</p>
                                                    {
                                                        account !== null ?
                                                            <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{account.branchName}</p>
                                                            : null
                                                    }
                                                </li>
                                                {/* <li className="dashboard-finance-section-card-section-details-section-content-list-item">
                                        <p className="dashboard-finance-section-card-section-details-section-content-list-item-title">Bitmə tarixi</p>
                                        {
                                            account !== null ?
                                                <p className="dashboard-finance-section-card-section-details-section-content-list-item-content">{card.organizedExpiryDate.day}.{card.organizedExpiryDate.month}.{card.organizedExpiryDate.fullYear}</p>
                                                : null
                                        }
                                    </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-finance-section-card-section-operation-section">
                                    <div className="dashboard-finance-section-card-section-operation-section-background">
                                        <div className="dashboard-finance-section-card-section-details-section-header">
                                            <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('recent operations')}</h3>
                                            <Link to={"/dashboard/finance/accounts/extract/" + accountId} className="dashboard-finance-section-card-section-details-section-header-show-all dashboard-last-operations-section-header-history">{t('all history')}</Link>

                                        </div>
                                        {
                                            statements.length === 0 ?
                                                <div className="dashboard-last-operations-section-content">
                                                    <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                    <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                                    <ul className="dashboard-last-operations-section-content-invisible-list">
                                                        <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                            </div>
                                                        </li>
                                                        <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                            </div>
                                                        </li>
                                                        <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                            <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                :
                                                <div className="dashboard-finance-section-card-section-operation-section-content">
                                                    <div className="dashboard-finance-section-card-section-operation-section-content-pick-date">
                                                        <p className="dashboard-finance-section-card-section-operation-section-content-pick-date-title">{t('select the extract interval')}</p>
                                                        <div className="dashboard-finance-section-card-section-operation-section-content-pick-date-input-section">
                                                            <StartDatePicker placeholder="Başlama tarixi" showStatements={this.showStatements} />
                                                            <EndDatePicker placeholder="Bitmə tarixi" showStatements={this.showStatements} />
                                                        </div>
                                                    </div>
                                                    <ul className="dashboard-last-operations-section-list">
                                                        {
                                                            statements.slice(0, 10).map((statement, index) =>
                                                                <li className="dashboard-last-operations-section-item" key={index}>
                                                                    <div className="dashboard-last-operations-section-item-left-side">
                                                                        <div className="dashboard-last-operations-section-item-icon-container">
                                                                            <svg className="dashboard-last-operations-section-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="dashboard-last-operations-section-item-content">
                                                                            <div className="dashboard-last-operations-section-item-company">{statement.account}</div>
                                                                            <div className="dashboard-last-operations-section-item-description">{statement.purpose}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dashboard-last-operations-section-item-rigth-side">
                                                                        <div className="dashboard-last-operations-section-item-amount">
                                                                            <span className="dashboard-last-operations-section-item-amount-whole-part">{statement.amount.wholePart}</span>
                                                                            <span className="dashboard-last-operations-section-item-amount-fractional-part">{statement.amount.fractionalPart}</span>
                                                                            <span className="dashboard-last-operations-section-item-amount-currency">{statement.currName}</span>
                                                                        </div>
                                                                        <p className="dashboard-last-operations-section-item-category">{statement.trDate.split(' ')[0]}</p>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>

                }
            </>
        )
    }
}


export default translate("translation")(Account);