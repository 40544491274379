import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Link, Route, Switch, } from 'react-router-dom';
import userIcon from './../../img/user-icon-form.svg';
import envelopeIcon from './../../img/envelope-icon-form.svg';
import eyeIcon from './../../img/eye.svg';
import eyeSlashIcon from './../../img/eye-slash.svg';
import pen from './../../img/pen.svg';
import spinner from './../../img/spinner.svg';
import { request } from './../../../../config';
import { translate } from 'react-i18next';
import SendOtpModal from '../../../../copms/Modal/SendOtpModalForSettings';
import { event } from 'jquery';

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankCustomerType: '',
            phoneKey: false,
            clientType: '',
            isLoading: false,
            messageStatus: 'info',
            name: null,
            surname: null,
            email: null,
            secretWord: '',
            mobile: null,
            symbolLength: null,
            isPrevPasswordVisibility: false,
            openOtp: false,
            otpCode: null,
            verifyToken: null
        }
    }

    handleButtonClick = () => {
        this.props.history.push('/dashboard/settings/general')
    };

    clearFeedback = () => {
        this.setState({ messageStatus: 'info' });
    }


    setPasswordVisibility = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    checkValidationNewPassword = (event) => {
        this.setState({ newPassword: event.target.value });
        this.setState({ successMessage: null, feedbackMessage: null });
        if (event.target.value.length > 3 && event.target.value.length < 9) {
            this.setState({ symbolLength: true });
        }
        else {
            if (event.target.value.length > 0) {
                this.setState({ symbolLength: false });
            }
            else {
                this.setState({ symbolLength: null });
            }
        }
    }

    changeGeneralData = (otpCode, event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ isLoading: true });
        let getPhoneNumber = (number) => {
            ['+', '-', '(', ')', ' '].forEach(char => {
                while (number.indexOf(char) !== -1) {
                    number = number.slice(0, number.indexOf(char)) + number.slice(number.indexOf(char) + 1, number.length);
                }
            });
            return number;
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let updateCustomerInfoRequestBody = customerType === 'Juridical' ? {
            custInfoType: "PHONE_NUMBER",
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            mobile: getPhoneNumber(this.mobile.value).trim() === "" ? null : getPhoneNumber(this.mobile.value),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            secretWord: this.secretWord.value,
            confirmCode: otpCode,
            verifyToken: this.state.verifyToken

        } : {
            // custId: localStorage.getItem('custId'),
            // --------------------------
            custInfoType: "PHONE_NUMBER",
            token: localStorage.getItem('token'),
            mobile: getPhoneNumber(this.mobile.value).trim() === "" ? null : getPhoneNumber(this.mobile.value),
            secretWord: this.secretWord.value,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            confirmCode: otpCode,
            verifyToken: this.state.verifyToken
            // --------------------------
        }

        fetch(request("setting/updateCustomerInfo" + customerType, updateCustomerInfoRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    }
                }
                else if (getCardListForCustomerResponse.otpVisible) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                    // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    this.setState({
                        openOtp: true,
                        verifyToken: getCardListForCustomerResponse.verifyToken,
                    });

                }
                else {
                    this.setState({
                        openOtp: false
                    });
                    this.setState({ mobile: this.mobile.value, secretWord: this.secretWord.value });
                    let changedObj = JSON.parse(localStorage.getItem('custObject'));
                    if (changedObj.customer === undefined) {
                        // changedObj.email = this.email.value;
                        changedObj.mobile = getPhoneNumber(this.mobile.value);
                    }
                    else {
                        // changedObj.customer.email = this.email.value;
                        changedObj.customer.mobile = getPhoneNumber(this.mobile.value);
                    }
                    localStorage.setItem('custObject', JSON.stringify(changedObj));
                    this.setState({ isLoading: false });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('information changed successfully'));
                    this.props.history.push("/dashboard/settings/general");
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentWillMount() {
        if (localStorage.getItem('custObject')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ secretWord: this.state.secretWord });
            // this.setState({ bankCustomerType: localStorage.getItem('bankCustomerType') });
            // this.setState({ name: localStorage.getItem('compId') === null ? localStorage.getItem('fullname').split(' ')[0] : JSON.parse(localStorage.getItem('custObject')).company.name });
            // this.setState({ surname: localStorage.getItem('compId') === null && localStorage.getItem('fullname').split(' ')[1] });
            // this.setState({ surname: localStorage.getItem('compId') === null && localStorage.getItem('fullname').split(' ')[1] });
            // let email = (custObject.customer !== undefined) ? custObject.customer.email : custObject.email;
            let mobile = (custObject.customer !== undefined) ? custObject.customer.mobile : custObject.mobile;
            if (mobile !== null && mobile !== undefined) {
                mobile = '+' + mobile.slice(0, 3) + ' ( ' + mobile.slice(3, 5) + ' ) ' + mobile.slice(5, 8) + '-' + mobile.slice(8, 10) + '-' + mobile.slice(10, 12);
            }
            this.setState({ mobile });
        }

    }


    componentDidMount() {
        this.props.updateSettingsState('settingsTitle', this.props.t('edit number'));
        this.props.updateSettingsState('feedback', this.props.t('edit number info'));

        // this.props.alertState(true, 'info', this.props.t('Information'), this.props.t('edit number info'));

        // if (this.state.bankCustomerType == 1) {
        //     this.setState({ clientType: "TuranBank (fiziki)" })
        // }
        // else if (this.state.bankCustomerType == 2) {
        //     this.setState({ clientType: "TuranBank (hüquqi)" })
        // }
    }

    render() {
        let { messageStatus, isLoading, symbolLength, showInputWindow, openOtp, otpCode, isPrevPasswordVisibility, name, surname, email, phoneKey, clientType, secretWord, mobile, divVisibility, buttonVisibility, keywordVisibility, isp } = this.state;
        let { t } = this.props;
        // let isDisabled;
        let feedbackMessage = {
            info: this.props.t('enter a new one to change your phone number'),
            success: this.props.t('your phone number has been successfully changed'),
            error: this.props.t('an error occurred')
        };

        let isDisabled;
        if (symbolLength === false || symbolLength === null) {
            isDisabled = true;
        }
        else {
            isDisabled = false;
        }
        // if (secretWord == '') {
        //     isDisabled = true;
        // }
        // else {
        //     isDisabled = false;
        // }
        return (
            <>
                <SendOtpModal buttonTitle={t('Sign')} open={openOtp} signSubmit={(otpCode, event) => this.changeGeneralData(otpCode, event)} onClose={() => this.setState({ openOtp: false })} />
                <div className="dashboard-settings-section-general">
                    <form className="dashboard-settings-section-general-form">

                        <div className="dashboard-settings-section-general-form-group" >
                            <label className="dashboard-settings-section-general-form-group-label">{t('phone number')}</label>
                            <div className="dashboard-settings-section-general-form-group-box">
                                <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" className="dashboard-settings-section-general-form-group-box-input default" ref={(input) => { this.mobile = input }} placeholder="+994 (__) ___-__-__" defaultValue={mobile} onChange={this.clearFeedback} />
                                <div className="dashboard-settings-section-general-form-group-box-icon">
                                    {
                                        isLoading === false ?
                                            <img src={pen} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img__phone" alt="" /> :
                                            <img src={spinner} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img__spinner" alt="" />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="dashboard-settings-section-general-form-group">
                            <label className="dashboard-settings-section-general-form-group-label">{t('keyword')}</label>
                            <div className="dashboard-settings-section-general-form-group-box" >
                                <input type={isPrevPasswordVisibility === false ? "password" : "text"} className="dashboard-settings-section-general-form-group-box-input default" ref={(input) => { this.secretWord = input }} placeholder="XXXX" onChange={this.checkValidationNewPassword} />
                                <div className="dashboard-settings-section-general-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isPrevPasswordVisibility')}>
                                    {/* <img src={envelopeIcon} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img__envelope" alt="" /> */}
                                    {
                                        isPrevPasswordVisibility === false ?
                                            <img src={eyeSlashIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password-slash" alt="" />
                                            :
                                            <img src={eyeIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password" alt="" />

                                    }
                                </div>
                            </div>
                        </div>

                        <div className="dashboard-settings-section-general-form-button-section">
                            <button className="dashboard-settings-section-general-form-button dashboard-settings-section-general-form-button__save" disabled={isDisabled} onClick={(event) => this.changeGeneralData(otpCode, event)}>{t('save')}</button>
                            <button className="dashboard-settings-section-general-form-button dashboard-settings-section-general-form-button__cancel" onClick={this.handleButtonClick}>{t('refusal')}</button>
                        </div>

                        {/* {showInputWindow && (
                            <InputWindow
                                title="Input Window"
                                message="Enter your value:"
                                confirmText="Confirm"
                                cancelText="Cancel"
                                onCancel={this.handleCancel}
                                onConfirm={this.handleConfirm}
                            />
                        )} */}
                    </form>
                </div>
            </>
        )
    }
}



export default translate("translation")(GeneralSettings);