import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { translate } from 'react-i18next';
import { request } from '../../../config';
import $ from 'jquery';
import lastOperationsIcon from './../img/last-operations-icon.png';
import adocIcon from './../img/adoc-icon.svg';
import pdfIcon from './../img/pdf-icon.svg';
import smsIcon from './../img/sms-icon.svg';
import statementIcon from './../img/statement-icon.svg';
import requisitesIcon from './../img/requisites-icon.svg';
import Loading from './../Loading/Loading';
import SendRepeatMail from './../../../copms/Modal/SendRepeatMail';


function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
class StatementAccount extends Component {
    state = {
        isLoading: false,
        statements: [],
        open: false,
        selectedStatement: -1,
        email: '',
        emailValidation: false
    }

    updateEmail = (e) => {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        this.setState({ email: e.target.value });
        if (e.target.value.match(validRegex)) {
            this.setState({ emailValidation: true });
        }
        else {
            this.setState({ emailValidation: false });
        }

    }

    downloadFile = (item) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
        let getCustomerOrderDocumentRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: item.id,
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            id: item.id,
        }
        fetch(request("order/getCustomerOrderDocument", getCustomerOrderDocumentRequestBody))
            .then(res => res.text())
            .then((getCustomerOrderDocumentResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustomerOrderDocumentResponse = JSON.parse(getCustomerOrderDocumentResponseJSON);
                if (getCustomerOrderDocumentResponse.status.statusCode !== 1) {
                    if (getCustomerOrderDocumentResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCustomerOrderDocumentResponse.status.statusCode));
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getCustomerOrderDocumentResponse.document);
                    saveFile(getCustomerOrderDocumentResponse.documentName, null, arrrayBuffer);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    sendEmail = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
        let sendEmailRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            orderId: this.state.statements[this.state.selectedStatement].id,
            to: this.state.email,
            subject: this.state.statements[this.state.selectedStatement].orderTypeId == 2 ? 'Hesabdan çıxarış' : this.state.statements[this.state.selectedStatement].orderTypeId == 1 ? 'Rekvizit' : null,
            message: 'Sifariş sənədi hazırlanmışdır.'
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            orderId: this.state.statements[this.state.selectedStatement].id,
            to: this.state.email,
            subject: this.state.statements[this.state.selectedStatement].orderTypeId == 2 ? 'Hesabdan çıxarış' : this.state.statements[this.state.selectedStatement].orderTypeId == 1 ? 'Rekvizit' : null,
            message: 'Sifariş sənədi hazırlanmışdır.'
        }
        fetch(request("util/sendEmail", sendEmailRequestBody))
            .then(res => res.text())
            .then((sendEmailResponseJSON) => {
                const JSON = require('true-json-bigint');
                let sendEmailResponse = JSON.parse(sendEmailResponseJSON);
                if (sendEmailResponse.status.statusCode !== 1) {
                    if (sendEmailResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(sendEmailResponse.status.statusCode));
                    }
                }
                else {
                    this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('Sent successfully'));
                }
                this.setState({ open: false });
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getCustomerOrderList = async () => {
        this.setState({ isLoading: true });
        let getCustomerOrderListRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            orderReceivingTypeId: 2
        }
        if (localStorage.getItem('compId')) {
            getCustomerOrderListRequestBody.compId = localStorage.getItem('compId');
        }
        return await fetch(request("order/getCustomerOrderList", getCustomerOrderListRequestBody))
            .then(res => res.text())
            .then((getCustomerOrderListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustomerOrderListResponse = JSON.parse(getCustomerOrderListResponseJSON);
                if (getCustomerOrderListResponse.status.statusCode !== 1 && getCustomerOrderListResponse.status.statusCode !== 427) {
                    if (getCustomerOrderListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCustomerOrderListResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCustomerOrderListResponse.status.statusCode === 427) {
                        getCustomerOrderListResponse.respCustomerOrderList = [];
                    }
                    this.setState({ statements: getCustomerOrderListResponse.respCustomerOrderList });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentDidMount() {
        this.getCustomerOrderList();
    }

    render() {
        let { t } = this.props;
        let { open, isLoading, statements, email, emailValidation } = this.state;

        return (
            <Fragment>
                <SendRepeatMail open={open} sendEmail={this.sendEmail} close={() => { this.setState({ open: false, selectedStatement: -1, email: '', emailValidation: false }) }} email={email} emailValidation={emailValidation} updateEmail={this.updateEmail} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Account statement')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">
                            <div className="dashboard-new-transfer-template-section">
                                <div className="dashboard-new-transfer-template-section-background">
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-transfer-list-header">
                                            <div className="dashboard-transfer-list-header-container dashboard-transfer-list-header-container__left">
                                                <NavLink exact to="/dashboard/services/account-statement-requisites/card" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("By card")}</NavLink>
                                                <NavLink exact to="/dashboard/services/account-statement-requisites/account" className="dashboard-transfer-list-header-link" activeClassName="dashboard-transfer-list-header-container dashboard-transfer-list-header-link__active">{t("By current account")}</NavLink>
                                            </div>
                                        </div>
                                        <ul className="dashboard-new-transfer-section-content">
                                            <Link to="/dashboard/services/account-statement-requisites/order-statement/account" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={statementIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t("extract")}</p>
                                            </Link>
                                            <Link to="/dashboard/services/account-statement-requisites/order-requisite/account" className="dashboard-new-transfer-section-content-item">
                                                <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                    <img src={requisitesIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                </div>
                                                <p className="dashboard-new-transfer-section-content-item-title">{t("details")}</p>
                                            </Link>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="dashboard-transfer-history">
                                <div className="dashboard-finance-section-card-section-details-section-header">
                                    <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('recent operations')}</h3>
                                    <Link to='/dashboard/services/account-statement-requisites/history/account' className="dashboard-finance-section-card-section-details-section-header-show-all dashboard-last-operations-section-header-history">{t('history')}</Link>
                                </div>
                                {
                                    (statements.length !== 0) ?
                                        <div className="dashboard-last-operations-section-content">
                                            <ul className="dashboard-last-operations-section-list">
                                                {
                                                    statements.slice(0, 10).map((statement, index) =>
                                                        <li className="dashboard-last-operations-section-item" key={index}>
                                                            <div className="dashboard-last-operations-section-item-left-side">
                                                                <div className="dashboard-last-operations-section-item-content">
                                                                    <div className="dashboard-last-operations-section-item-company">
                                                                        {statement.orderTypeId == 1 && t('By current account details')}
                                                                        {statement.orderTypeId == 2 && t('By current account  statement')}
                                                                        {statement.orderTypeId == 2 && ` ${statement.statementDate}`}
                                                                        {statement.orderTypeId == 2 && `  (${statement.signTypeId == 1 ? t('signed') : t('unsigned')})`}                                                                    </div>
                                                                    <div className="dashboard-last-operations-section-item-company" style={{ fontSize: '14px' }}>{t('By current account')} / {statement.iban}</div>
                                                                </div>
                                                            </div>
                                                            <div className="dashboard-last-operations-section-item-rigth-side">
                                                                <div className="dashboard-last-operations-section-item-file-button-container">
                                                                    {
                                                                        statement.signTypeId == 1 ?
                                                                            <button onClick={this.downloadFile.bind(this, statement)} className="dashboard-last-operations-section-item-file-button">
                                                                                <img src={adocIcon} />
                                                                            </button>
                                                                            :
                                                                            <button onClick={this.downloadFile.bind(this, statement)} className="dashboard-last-operations-section-item-file-button">
                                                                                <img src={pdfIcon} />
                                                                            </button>
                                                                    }
                                                                    <button className="dashboard-last-operations-section-item-file-button" onClick={() => { this.setState({ selectedStatement: index, open: true, email: '', emailValidation: false }) }}>
                                                                        <img src={smsIcon} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        :
                                        <div className="dashboard-last-operations-section-content">
                                            <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                            <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                            <ul className="dashboard-last-operations-section-content-invisible-list">
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                                <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                        <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(StatementAccount);