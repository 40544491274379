import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";

import createPINIcon from "../../../../../helpers/img/icons/create-pin.svg";
import updatePINIcon from "../../../../../helpers/img/icons/update-pin.svg";
import unblockPINIcon from "../../../../../helpers/img/icons/unblock.svg";

import "./PINSettingsActions.scss";

const PINSettingsActions = ({
  openModal,
  setCardID,
  unblockPinHandler,
  setNewPINHandler,
  translate,
  pinUnblockAmount,
  setUnblockPinModalOpen,
  unblockPIN
}) => {
  let { id } = useParams();
  const updatePINHandler = () => {
    openModal();
  };

  // const unblockPinHandler =()=>{
  //   // if (pinUnblockAmount) {
  //   //   setUnblockPinModalOpen(true);
  //   // } else {
  //     unblockPIN();
  //   // }
  // }

  const [PINSettingsActions, setPINSettingsActions] = useState([
    {
      icon: updatePINIcon,
      title: translate("Changing of the Pin code"),
      onClickHandler: updatePINHandler,
      alt: "updatePINIcon",
      showForJuridicalCustomer: false,
    },
    {
      icon: createPINIcon,
      title: translate("Setting of the Pin code"),
      onClickHandler: setNewPINHandler,
      alt: "createPINIcon",
    },
    {
      icon: unblockPINIcon,
      title: translate("Unlock the PIN"),
      onClickHandler: unblockPinHandler,
      alt: "unblockPINIcon",
      showForJuridicalCustomer: true,
    },
  ]);

  const filterPINSettingsActions = () => {
    let newPINSettingsActions = [];

    newPINSettingsActions =
      localStorage.getItem("compId") &&
      PINSettingsActions.filter((item) => item.showForJuridicalCustomer);
    setPINSettingsActions(newPINSettingsActions);
  };

  useEffect(() => {
    setCardID(id);
    localStorage.getItem("compId") && filterPINSettingsActions();
  }, [id]);

  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12 }}
      className="ibank-pin-settings__actions"
    >
      {PINSettingsActions.map(({ icon, title, onClickHandler, alt }) => {
        return (
          <Grid item xs={4} sm={3} md={2} lg={2} key={title}>
            <button onClick={onClickHandler}>
              <img src={icon} {...{ alt }} />
              <span>{title}</span>
            </button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PINSettingsActions;
