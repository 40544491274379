import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import AuthHeader from "../../Auth/AuthHeader/AuthHeader";
import { translate } from 'react-i18next';
import "../../Auth/OTP/OTP.scss";
import headerLogo from "../../AsanLoginConfirmation/img/header-logo.svg";
import { request } from "../../../config";

const LoginWithMobileJuridicalOtp = ({ t, updateAppState, }) => {
  const [OTPCode, setOTPCode] = useState();
  const [errMessage, setErrMessage] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);



  const initialMinute = 3,
    initialSeconds = 0,
    numInputs = 6;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isDisabled, setIsDisabled] = useState(true);
  const case1 = "00:00";

  const resetTime = () => {
    setErrMessage("");
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
    setIsDisabled(true);
    sendOtpCode();
    // console.log("resettime---");
  };

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  useEffect(() => {
    let timeInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timeInterval);
          // console.log("test");
          setIsDisabled(false);
        } else {
          setMinutes(minutes - 1);

          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  });

  let history = useHistory();
  const { state } = useLocation();
  const location = useLocation();

  if (location.state === undefined) {
    // this.props.history.push('/login/juridical/asan/');
    window.location.href = '/login/individual/asan/';
    // console.log("error")
  }

  const handleOTP = (otp) => setOTPCode(otp);

  let { loginWithUsrAndPwdJuridicalRequestBody, loginWithUsrAndPwdJuridicalResponse } = location.state;
  const verifyToken = loginWithUsrAndPwdJuridicalResponse.customer.verifyToken
  const mobile = loginWithUsrAndPwdJuridicalResponse.customer.mobile
  // let { selectCustCompanyRequestBody, token, verifyToken } = location.state;
  const [verify, setIsVerify] = useState(verifyToken);

  useEffect(() => {
    setIsVerify(verifyToken);
  }, [verifyToken]);

  const sendOtpCode = (e) => {
    updateAppState("isLoadingTransparentBackground", true);
    fetch(request("register/loginWithUsrAndPwdJuridicalV2", loginWithUsrAndPwdJuridicalRequestBody))
      .then((res) => res.json())
      .then(
        (getAuthInfoIndividualLoginRequestResponse) => {
          if (getAuthInfoIndividualLoginRequestResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              (getAuthInfoIndividualLoginRequestResponse.status.statusMessage)
            );
            setIsVisible(false)
          } else {
            setIsVerify(getAuthInfoIndividualLoginRequestResponse.verifyToken)
            // console.log("əlse")
            updateAppState("isLoadingTransparentBackground", false);
            // console.log(getAuthInfoIndividualLoginRequestResponse)
            // history.push("/sign-up/not-a-bank-customer/create-password", {
            //   custId: state.custId,
            // });
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };
  // console.log(getOtpFromAsanResponse)

  const verifyOtpCode = (e) => {
    e.preventDefault();

    updateAppState("isLoadingTransparentBackground", true);
    setErrMessage("");

    fetch(request("register/loginWithUsrAndPwdJuridicalV2", { ...loginWithUsrAndPwdJuridicalRequestBody, otpCode: OTPCode, verifyToken: verify }))
      .then((res) => res.json())
      .then(
        (getAuthInfoIndividualLoginRequestResponse) => {
          if (getAuthInfoIndividualLoginRequestResponse.status.statusCode !== 1) {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              (getAuthInfoIndividualLoginRequestResponse.status.statusMessage)
            );
            // setIsVisible(false)
            // console.log('5 time')
          } 
          else {
            updateAppState("isLoadingTransparentBackground", false);

            let custId = "";
                    if (typeof (getAuthInfoIndividualLoginRequestResponse.customer.custId) === "object" && getAuthInfoIndividualLoginRequestResponse.customer.custId.hasOwnProperty('c')) {
                        getAuthInfoIndividualLoginRequestResponse.customer.custId.c.forEach(part => {
                            custId += part;
                        });
                    }
                    else {
                        custId += getAuthInfoIndividualLoginRequestResponse.customer.custId;
                    }
                    // =========COMPID=========
                    let compId = "";
                    if (typeof (getAuthInfoIndividualLoginRequestResponse.company.id) === "object" && getAuthInfoIndividualLoginRequestResponse.company.id.hasOwnProperty('c')) {
                        getAuthInfoIndividualLoginRequestResponse.company.id.c.forEach(part => {
                            compId += part;
                        });
                    }
                    else {
                        compId += getAuthInfoIndividualLoginRequestResponse.company.id;
                    }
                    // =========FULLNAME=========
                    // let fullname = getAuthInfoIndividualLoginRequestResponse.customer.name[0].toUpperCase() + getAuthInfoIndividualLoginRequestResponse.customer.name.substring(1, getAuthInfoIndividualLoginRequestResponse.customer.name.length).toLowerCase() +
                    //     ' ' + getAuthInfoIndividualLoginRequestResponse.customer.surname[0].toUpperCase() + getAuthInfoIndividualLoginRequestResponse.customer.surname.substring(1, getAuthInfoIndividualLoginRequestResponse.customer.surname.length).toLowerCase() +
                    //     ' ' + getAuthInfoIndividualLoginRequestResponse.customer.patronymic[0].toUpperCase() + getAuthInfoIndividualLoginRequestResponse.customer.patronymic.substring(1, getAuthInfoIndividualLoginRequestResponse.customer.patronymic.length).toLowerCase();
                    let fullname = getAuthInfoIndividualLoginRequestResponse.customer.surname + ' ' + getAuthInfoIndividualLoginRequestResponse.customer.name + ' ' + getAuthInfoIndividualLoginRequestResponse.customer.patronymic;
                    localStorage.setItem("custId", custId);
                    localStorage.setItem("compId", compId);
                    localStorage.setItem("token", getAuthInfoIndividualLoginRequestResponse.customer.token);
                    localStorage.setItem("secretWord", loginWithUsrAndPwdJuridicalResponse.customer.secretWord);
                    localStorage.setItem("fullname", fullname);
                    localStorage.setItem("custObject", JSON.stringify(getAuthInfoIndividualLoginRequestResponse));
                    localStorage.setItem("isOperNo", getAuthInfoIndividualLoginRequestResponse.company.isOperNo);
                    // this.props.updateAppState('isAuthed', true);
                    localStorage.setItem("compIdEncrypt", getAuthInfoIndividualLoginRequestResponse.company.compIdEncrypt);
                    localStorage.setItem("bankCustomerType",getAuthInfoIndividualLoginRequestResponse.customer.bankCustomerType);
                    localStorage.setItem("compName", getAuthInfoIndividualLoginRequestResponse.company.name);

                    localStorage.setItem("custTypeId", getAuthInfoIndividualLoginRequestResponse.customer.custTypeId);

                    setIsAuthed(true);

            // console.log("success")
            // history.push('/login/individual/asan/confirmation', { loginIndividualForAsanRequestBody });
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };


  // useEffect(() => {
  //   if (location.state === undefined) {
  //     history.push("/sign-up/not-a-bank-customer")
  //   }
  //   else {
  //     updateAppState("isLoadingTransparentBackground", false)
  //   };
  // }, []);
  // console.log(initialMinute)
  // const { t } = translate();

  useEffect(() => {
    if (isAuthed) {
      // Redirect to the desired page
      window.location.href = '/dashboard/home';
    }
  }, [isAuthed]);



  return (
    <div className="registration">
      <Link to="/">
        <img src={headerLogo} className="registration-logo" alt="headerLogo" />
      </Link>
      <div className="registration-container registration-container--top-border">
        <h2 className="registration-container-title registration-container-title--center">{t('otp code confirmation')}</h2>
      <p className="registration-container-title registration-container-title--center">{mobile}</p>

        {/* <p className="registration-container-description registration-container-description--center">please accept the request sent to your phone</p> */}
        <section className="ibank-otp header-sign-section">
          <div className="header-sign-section-container">
            <AuthHeader
              title={t("Enter the one-time code")}
              desc={[
                t("enter the 6-digit confirmation code sent to your phone"),
              ]}
            />
            <form onSubmit={verifyOtpCode}>
              <OtpInput
                className="ibank-otp__body"
                value={OTPCode}
                onChange={handleOTP}
                shouldAutoFocus={true}
                {...{ initialMinute, initialSeconds, numInputs }}
              />
              {isVisible &&
                (
                  <div className="ibank-otp__resend">
                    <button
                      disabled={isDisabled}
                      onClick={() => {
                        resetTime();
                      }}
                      type="button"
                    >
                      {t("resend the code")}
                    </button>
                    <p className="ibank-otp__resend__timer">
                      {minutes === 0 && seconds === 0
                        ? case1
                        : `${formatTime(minutes)} : ${formatTime(seconds)}`}
                    </p>
                  </div>
                )
                // <TimerCountdown
                //   resendTitle={t("resend the code")}
                //   {...{
                //     initialMinute,
                //     initialSeconds,
                //     sendOtpCode,
                //     setErrMessage,
                //   }}
                // />
              }

              <div className="registration-container-validation-feedback">
                {errMessage ? (
                  <p className="registration-container-validation-feedback-message">
                    {errMessage}
                  </p>
                ) : null}
              </div>
              <button
                className="header-sign-section-container-form-button"
                disabled={OTPCode?.length !== numInputs || (minutes === 0 && seconds === 0)}
              >
                {t("continue")}
              </button>
            </form>
          </div>
        </section>
        {/* <div className="registration-container-asan-confirmation">
        <span className="registration-container-asan-confirmation-code">{verificationCode}</span>
    </div> */}
        <div className="registration-container-validation-feedback"></div>
        <div className="registration-container-footer">
          {/* <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.prevPage}>{t('back')}</button> */}
          {/* <button type="button" className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled}>{t('next')}</button> */}
        </div>
      </div>
    </div>
  );
};

export default translate("translation")(LoginWithMobileJuridicalOtp);



