import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import phoneIconForm from './../img/phone-icon-form.svg';
import userIconForm from './../img/user-icon-form.svg';
import { request } from "./../../../config";
import { translate } from 'react-i18next';

class LoginWithAsanJuridical extends Component {
    state = {
        isDisabled: true,
        feedbackMessage: null
    }

    validateData = (inputType, event) => {
        this.setState({ checkedData: false, feedbackMessage: null });
        if (inputType === 'userId') {
            this.userId.value = this.userId.value.replace(/\D/g, '');
            if (this.userId.value.length < 6) {
                this.setState({ validateUserId: false });
            }
            else {
                if (this.userId.value.length > 6) {
                    this.userId.value = this.userId.value.substring(0, 6);
                }
                this.setState({ validateUserId: true });
            }
        }
        if (inputType === 'phoneNumber') {
            setTimeout(() => {
                if (this.phoneNumber.value.indexOf("_") === -1) {
                    this.setState({ validatePhoneNumber: true });

                }
                else {
                    this.setState({ validatePhoneNumber: false });

                }
            }, 1)
        }
        setTimeout(() => {
            if (this.state.validateUserId && this.state.validatePhoneNumber) {
                this.setState({ isDisabled: false });
            }
            else {
                this.setState({ isDisabled: true });
            }
        }, 1);
    }

    loginJuricicalForAsanMethod = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        
        let getPhoneNumber = (phoneNumber) => {
            ['+', '-', '(', ')', ' '].forEach(char => {
                while (phoneNumber.indexOf(char) !== -1) {
                    phoneNumber = phoneNumber.slice(0, phoneNumber.indexOf(char)) + phoneNumber.slice(phoneNumber.indexOf(char) + 1, phoneNumber.length);
                }
            });
            return phoneNumber;
        }

        let getAuthInfoJuridicalLoginRequestBody = {
            userId: this.userId.value,
            phoneNumber: getPhoneNumber(this.phoneNumber.value),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        };

        fetch(request("register/getAuthInfoJuridicalLogin", getAuthInfoJuridicalLoginRequestBody))
            .then(res => res.text())
            .then((getPinFromAsanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getPinFromAsanResponse = JSON.parse(getPinFromAsanResponseJSON);
                if (getPinFromAsanResponse.status.statusCode !== 1) {
                    this.setState({ feedbackMessage: this.props.t(getPinFromAsanResponse.status.statusCode) });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
                else {
                    // console.log(getPinFromAsanResponseJSON)

                    let loginJuridicalForAsanRequestBody = {
                        // certificate: certificate,
                        // transactionId: transactionId,
                        // custId: getAuthInfoRequestBody.custId,
                        // challenge: getAuthInfoResponse.challenge,
                        verificationCode: getPinFromAsanResponse.verificationCode,
                        mobile: getAuthInfoJuridicalLoginRequestBody.phoneNumber,
                        userId: getAuthInfoJuridicalLoginRequestBody.userId,
                        sessionId: getPinFromAsanResponse.sessionId,
                        token: getPinFromAsanResponse.token
                        // device: "device",
                        // location: "location",
                        // lang: localStorage.getItem('i18nextLng').toUpperCase()
                    }
                    // ====================================

                    localStorage.setItem('userId', getAuthInfoJuridicalLoginRequestBody.userId);
                    localStorage.setItem('phoneNumber', getAuthInfoJuridicalLoginRequestBody.phoneNumber);
                    this.props.history.push('/login/juridical/asan/confirmation', { loginJuridicalForAsanRequestBody, getAuthInfoJuridicalLoginRequestBody });
                    // isLogedIn
                    // =============
                    // fetch(request("register/checkPinForAsanJuridicalLogin?pin=" + getPinFromAsanResponse.pin, null, "GET"))
                    //     .then(res => res.json())
                    //     .then((checkPinForAsanLoginResponse) => {
                    //         if (checkPinForAsanLoginResponse.status.statusCode !== 1) {
                    //             this.setState({ feedbackMessage: this.props.t(checkPinForAsanLoginResponse.status.statusCode) });
                    //             this.props.updateAppState('isLoadingTransparentBackground', false);
                    //         }
                    //         else {
                    //             console.log(checkPinForAsanLoginResponse)
                                // ======CUSTID======
                                // let custId = "";
                                // if (typeof (checkPinForAsanLoginResponse.custId) === 'object' && checkPinForAsanLoginResponse.custId.hasOwnProperty('c')) {
                                //     checkPinForAsanLoginResponse.custId.c.forEach(part => {
                                //         custId += part;
                                //     });
                                // }
                                // else {
                                //     custId += checkPinForAsanLoginResponse.custId;
                                // }
                                // localStorage.setItem("bankCustomerType",checkPinForAsanLoginResponse.bankCustomerType)
                                // // =============
                                // let getAuthInfoRequestBody = {
                                //     userId: getAuthInfoJuridicalLoginRequestBody.userId,
                                //     phoneNumber: getAuthInfoJuridicalLoginRequestBody.phoneNumber,
                                //     custId: custId,
                                //     pin: getPinFromAsanResponse.pin,
                                //     lang: localStorage.getItem('i18nextLng').toUpperCase()
                                // }
                                // let getCompaniesCertRequestBody = {
                                //     pin: getPinFromAsanResponse.pin,
                                //     mobile: getAuthInfoJuridicalLoginRequestBody.phoneNumber,
                                //     userId: getAuthInfoJuridicalLoginRequestBody.userId,
                                //     custCompStatus: 1
                                // }
                                // // =============
                                // fetch(request("register/getAuthInfo", getAuthInfoRequestBody))
                                //     .then(res => res.text())
                                //     .then(getAuthInfoResponseJSON => {
                                //         const JSON = require('true-json-bigint');
                                //         let getAuthInfoResponse = JSON.parse(getAuthInfoResponseJSON);
                                //         let loginJuridicalForAsanRequestBody;
                                //         if (getAuthInfoResponse.status.statusCode === 1) {
                                //             let transactionId = "";
                                //             // ======TRANSACTIONID======
                                //             if (typeof (getAuthInfoResponse.transactionId) === 'object' && getAuthInfoResponse.transactionId.hasOwnProperty('c')) {
                                //                 getAuthInfoResponse.transactionId.c.forEach(part => {
                                //                     transactionId += part;
                                //                 });
                                //             }
                                //             else {
                                //                 transactionId += getAuthInfoResponse.transactionId;
                                //             }
                                //             // ======CERTIFICATE=====
                                //             let certificate = getAuthInfoResponse.certificate;
                                //             while (certificate.indexOf("\r\n") !== -1) {
                                //                 certificate = certificate.replace("\r\n", "");
                                //             }
                                //             // =============
                                //             loginJuridicalForAsanRequestBody = {
                                //                 certificate: certificate,
                                //                 transactionId: transactionId,
                                //                 custId: getAuthInfoRequestBody.custId,
                                //                 challenge: getAuthInfoResponse.challenge,
                                //                 verificationCode: getAuthInfoResponse.verificationCode,
                                //                 phoneNumber: getAuthInfoResponse.phoneNumber,
                                //                 userId: getAuthInfoResponse.userId,
                                //                 device: "device",
                                //                 location: "location",
                                //                 lang: localStorage.getItem('i18nextLng').toUpperCase()
                                //             }
                                //             // ====================================
              
                                //             localStorage.setItem('userId', getAuthInfoResponse.userId);
                                //             localStorage.setItem('phoneNumber', getAuthInfoResponse.phoneNumber);
                                //             // this.props.history.push('/login/juridical/asan/confirmation', { getCompaniesCertRequestBody: getCompaniesCertRequestBody, loginJuridicalForAsanRequestBody: loginJuridicalForAsanRequestBody });
                                //         }
                                //         else {
                                //             this.setState({ feedbackMessage: this.props.t(getAuthInfoResponse.status.statusCode) });
                                //             this.props.updateAppState('isLoadingTransparentBackground', false);
                                //         }
                                //     },
                                //         (error) => {
                                //             this.props.history.push("/technical-break");
                                //         });
                            // }
                        // },
                            // (error) => {
                            //     this.props.history.push("/technical-break");
                            // });
                    // =============
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    componentDidMount() {
        this.props.updateAppState('isLoadingTransparentBackground', false);
        this.props.updateAppState('isLoading', false);
        this.props.updateIsAsan(true);
        this.props.updateIsJuridical(true);
        if(this.props.location.state !== undefined) {
            this.setState({feedbackMessage: this.props.location.state});
        }
    }

    render() {
        let { isAuthed, t } = this.props;
        let { isDisabled, feedbackMessage } = this.state;

        return (
            <form className="header-sign-section-container-form">
                <div className="header-sign-section-container-form-group">
                    <label className="header-sign-section-container-form-group-label">{t('phone number')}</label>
                    <div className="header-sign-section-container-form-group-box">
                        <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" className="header-sign-section-container-form-group-box-input" placeholder="+994 (__) ___-__-__" ref={(input) => { this.phoneNumber = input }} onInput={this.validateData.bind(this, 'phoneNumber')} />
                        <div className="header-sign-section-container-form-group-box-icon">
                            <img src={phoneIconForm} className="header-sign-section-container-form-group-box-icon-img  header-sign-section-container-form-group-box-icon-img__phone" alt="Phone icon" />
                        </div>
                    </div>
                </div>
                <div className="header-sign-section-container-form-group">
                    <label className="header-sign-section-container-form-group-label">{t('asan user id')}</label>
                    <div className="header-sign-section-container-form-group-box">
                        <input type="text" className="header-sign-section-container-form-group-box-input" placeholder={t('asan user id')} ref={(input) => { this.userId = input }} onInput={this.validateData.bind(this, 'userId')} />
                        <div className="header-sign-section-container-form-group-box-icon">
                            <img src={userIconForm} className="header-sign-section-container-form-group-box-icon-img header-sign-section-container-form-group-box-icon-img__user" alt="User icon" />
                        </div>
                    </div>
                </div>
                <div className="header-sign-section-container-form-validation-feedback">
                    {
                        feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage} </p> : null
                    }
                </div>
                <button className="header-sign-section-container-form-button" disabled={isDisabled} onClick={this.loginJuricicalForAsanMethod}>{t('login')}</button>
            </form>
        )
    }
}


export default translate("translation")(LoginWithAsanJuridical);