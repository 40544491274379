import React from "react";
import { Link } from "react-router-dom";

import AuthHeader from "../AuthHeader/AuthHeader";
import AuthFooter from "../AuthFooter/AuthFooter";
import NotABankCustomer from "../NotABankCustomer/NotABankCustomer";

const SignUp = ({ translate, updateAppState }) => {
  return (
    <section className="ibank-sign-up header-sign-section">
      <div className="header-sign-section-container">
        <AuthHeader title={translate("registration")} />
        <div className="header-sign-section-container-sign">
          <p className="header-sign-section-container-sign-type header-sign-section-container-sign-type__not-a-bank-customer header-sign-section-container-sign-type--active">
            {translate("Non-TuranBank customer")}
          </p>
          <Link
            to="/registration"
            className="header-sign-section-container-sign-type"
          >
            {translate("TuranBank customer")}
          </Link>
        </div>
        <NotABankCustomer {...{ translate, updateAppState }} />
        <AuthFooter
          title={translate("Already have an account?")}
          path="/login/individual/mobile"
          pathTitle={translate("Log in account")}
        />
      </div>
    </section>
  );
};

export default SignUp;
