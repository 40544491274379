import internalIcon from './../components/Dashboard/img/internal.svg';
import inlandIcon from './../components/Dashboard/img/inland.svg';
import abroadIcon from './../components/Dashboard/img/abroad.svg';
import exchangeIcon from './../components/Dashboard/img/exchange.svg';
import betweenIcon from './../components/Dashboard/img/between.svg';
import ipsIcon from './../components/Dashboard/img/ips.svg';

const lists = [
    {
        cardOperationType: 1,
        cardOperationTypeValue: "internal",
        name: "Internal",
        iconName: internalIcon,
    },
    {
        cardOperationType: 2,
        cardOperationTypeValue: "inland",
        name: "Inland",
        iconName: inlandIcon,
    },
    {
        cardOperationType: 3,
        cardOperationTypeValue: "abroad",
        name: "Abroad",
        iconName: abroadIcon,
    },
    {
        cardOperationType: 4,
        cardOperationTypeValue: "convertation",
        name: "Exchange",
        iconName: exchangeIcon,
    },
    {
        cardOperationType: 5,
        cardOperationTypeValue: "ips",
        name: "IPS",
        iconName: ipsIcon
    },
    {
        cardOperationType: 15,
        cardOperationTypeValue: "internal",
        name: "Internal",
        iconName: betweenIcon
    }
]


function cardOperationFilter(id) {
    return lists.find(elem => elem.cardOperationType === id)?lists.find(elem => elem.cardOperationType === id) : null;
}

export default cardOperationFilter;