import React, { Component } from "react";
import {
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import $ from "jquery";
import LoginWithAsanJuridical from "./LoginWithAsanJuridical/LoginWithAsanJuridical";
import LoginWithUsrAndPwdJuridical from "./LoginWithUsrAndPwdJuridical/LoginWithUsrAndPwdJuridical";
import LoginWithAsanIndividual from "./LoginWithAsanIndividual/LoginWithAsanIndividual";
import LoginWithUsrAndPwdIndividual from "./LoginWithUsrAndPwdIndividual/LoginWithUsrAndPwdIndividual";
import AuthFooter from "../Auth/AuthFooter/AuthFooter";
import { translate } from 'react-i18next';
class Login extends Component {
    state = {
        isJuridical: false,
        isAsan: true
    }

    updateIsJuridical = (type) => {
        $(".header-sign-section-customer-type").removeClass("header-sign-section-customer-type--active");
        if (type) {
            $(".header-sign-section-customer-type__corporate").addClass("header-sign-section-customer-type--active");
        }
        else {
            $(".header-sign-section-customer-type__personal").addClass("header-sign-section-customer-type--active");
        }
        this.setState({ isJuridical: type });
    }

    updateIsAsan = (type) => {
        $(".header-sign-section-container-sign-type").removeClass("header-sign-section-container-sign-type--active");
        if (type) {
            $(".header-sign-section-container-sign-type__asan").addClass("header-sign-section-container-sign-type--active");
        }
        else {
            $(".header-sign-section-container-sign-type__username").addClass("header-sign-section-container-sign-type--active");
        }
        this.setState({ isAsan: type });
        localStorage.setItem("isAsan", type);
    }

    currentLink = (type, value) => {
        let { isJuridical, isAsan } = this.state;
        let customerType, signType;
        if (type == "customer") {
            signType = (isAsan) ? "asan" : "mobile";
            customerType = (value) ? "juridical" : "individual";
        }
        else {
            customerType = (isJuridical) ? "juridical" : "individual";
            signType = (value) ? "asan" : "mobile";
        }
        return `/login/${customerType}/${signType}/`;
    }
    changeLanguageFunc = (val) => {
        this.props.i18n.changeLanguage(val);
        window.location.reload();
    }

    render() {
        let { isAuthed, isAsan } = this.state;
        let { updateAppState, feedbackMessageLoginJuridicalForAsan, t } = this.props;

        return (
            <div className="header-sign-section">
                <div className="header-sign-section-customer">
                    <Link to={this.currentLink.bind(this, "customer", false)} className="header-sign-section-customer-type header-sign-section-customer-type__personal header-sign-section-customer-type--active">{t('individual')}</Link>
                    <span className="header-sign-section-customer-type header-sign-section-customer-type__slash">/</span>
                    <Link to={this.currentLink.bind(this, "customer", true)} className="header-sign-section-customer-type header-sign-section-customer-type__corporate">{t('juridical')}</Link>
                </div>
                <div className="header-sign-section-container">
                    <div className="header-sign-section-container-sign">
                    <Link to={this.currentLink.bind(this, "sign", false)} className="header-sign-section-container-sign-type header-sign-section-container-sign-type__username">{t('with username')}</Link>
                        <Link to={this.currentLink.bind(this, "sign", true)} className="header-sign-section-container-sign-type header-sign-section-container-sign-type__asan header-sign-section-container-sign-type--active">{t('with asan signature')}</Link>
                        {
                            !isAsan ?
                                <div className="header-sign-section-container-sign-type__underline header-sign-section-container-sign-type__underline-asan"></div>
                                :
                                <div className="header-sign-section-container-sign-type__underline header-sign-section-container-sign-type__underline-mobile"></div>
                        }
                    </div>
                    <Switch>
                        <Route exact path="/login/juridical/asan" render={(routeProps) => <LoginWithAsanJuridical {...routeProps} feedbackMessageLoginJuridicalForAsan={feedbackMessageLoginJuridicalForAsan} updateAppState={updateAppState} updateIsAsan={this.updateIsAsan} updateIsJuridical={this.updateIsJuridical} />} />

                        <Route exact path="/login/individual/asan" render={(routeProps) => <LoginWithAsanIndividual {...routeProps} updateAppState={updateAppState} updateIsAsan={this.updateIsAsan} updateIsJuridical={this.updateIsJuridical} />} />

                        <Route exact path="/login/juridical/mobile" render={(routeProps) => <LoginWithUsrAndPwdJuridical {...routeProps} updateAppState={updateAppState} updateIsAsan={this.updateIsAsan} updateIsJuridical={this.updateIsJuridical} />} />

                        <Route exact path="/login/individual/mobile" render={(routeProps) => <LoginWithUsrAndPwdIndividual {...routeProps} updateAppState={updateAppState} updateIsAsan={this.updateIsAsan} updateIsJuridical={this.updateIsJuridical} />} />

                    </Switch>
                    <AuthFooter translate={t} title={t("Don't have an account?")} path="/sign-up/not-a-bank-customer" pathTitle={t("registration")}/>
                    {/* <Link to="/registration" className="header-sign-section-container-registration-button">{t('registration')}</Link>
                    <div className="header-sign-section-container-languages">
                        <button className="header-sign-section-container-languages-button header-sign-section-container-languages-button__az" onClick={this.changeLanguageFunc.bind(this, "az")}>AZ</button>
                        <button className="header-sign-section-container-languages-button header-sign-section-container-languages-button__en" onClick={this.changeLanguageFunc.bind(this, "en")}>EN</button>
                        <button className="header-sign-section-container-languages-button header-sign-section-container-languages-button__ru" onClick={this.changeLanguageFunc.bind(this, "ru")}>RU</button>
                    </div> */}
                </div>
            </div>
        )
    }
}


export default translate("translation")(Login);