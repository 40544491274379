import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        maxWidth: '700px',
    },
}));

function SimpleModal(props) {
    const classes = useStyles();

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.close() }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <div className="simple-modal-cashback">
                            <h2 className="simple-modal-cashback-head">{props.title}</h2>
                            <p className="simple-modal-cashback-body">{props.body}</p>
                            <button className="simple-modal-cashback-button" onClick={() => { props.close() }}>{props.t('Ok')}</button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(SimpleModal);