import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { request } from '../../../../config';
import currencyFilter from '../../../../helpers/currencyFilter';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from './../../Loading/Loading';
import $ from 'jquery';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
export class ZkTransfer extends Component {
    state = {
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        confirmedOperation: false,
        cards: [],
        selectedCard: "",
        dropdownVisibilityCard: false,
        cardIdCount: 0,
        cardId: null,
        cardNumber: null,
        cardIdValidation: false,
        cardData: false,

        amount: "",
        amountOrganized: {},

        receiveAmount: "",
        receiveAmountOrganized: {},

        crName: "",
        crSurname: "",
        crFname: "",
        crMobile: "",
        crCountry: "",
        crCity: "",
        payCurrency: "",
        receiveCurrency: "",
        operationCode: "",
        dtFeeAmount: null,

        // tempHave: true,
        isLoading: false,
    }

    downloadTariffPdf = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadTariffPdf", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Qaydalar') + ".pdf", "application/pdf", arrrayBuffer)
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showCard = (id) => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardByIdForCustomerRequestBody = (customerType === 'Individual') ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            cardId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            cardId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }


        fetch(request("card/getCardByIdFor" + customerType, getCardByIdForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardByIdForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardByIdForCustomerResponse = JSON.parse(getCardByIdForCustomerResponseJSON);
                if (getCardByIdForCustomerResponse.status.statusCode !== 1) {
                    if (getCardByIdForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    // =========HOLDER=========
                    let holderArray = getCardByIdForCustomerResponse.holder.split(' ');
                    let lowercaseName = holderArray[0].toLowerCase();
                    let lowercaseSurname = holderArray[1].toLowerCase();
                    getCardByIdForCustomerResponse.organizedHolder = {
                        name: holderArray[0][0] + lowercaseName.substring(1, lowercaseName.length),
                        surname: holderArray[1][0] + lowercaseSurname.substring(1, lowercaseSurname.length)
                    };
                    // =========NUMBER=========
                    getCardByIdForCustomerResponse.organizedCardNumber = getCardByIdForCustomerResponse.cardNumber.substring(0, 4)
                        + " **** **** " + getCardByIdForCustomerResponse.cardNumber.substring(12, 16);

                    this.setState({ card: getCardByIdForCustomerResponse });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");

                });
    }

    getZkTransferById() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            mtOperId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            mtOperId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("koronaPay/getTransferByOperId", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getTransferByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferByOperIdResponse = JSON.parse(getTransferByOperIdResponseJSON);
                if (getTransferByOperIdResponse.status.statusCode !== 1) {
                    if (getTransferByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    let amountOrganized = {};
                    if (getTransferByOperIdResponse.payAmount === null) {
                        getTransferByOperIdResponse.payAmount = 0;
                    }
                    if (getTransferByOperIdResponse.payAmount.toString() === "0") {
                        amountOrganized = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (getTransferByOperIdResponse.payAmount.toString().indexOf('.') !== -1) {
                            let amountArray = getTransferByOperIdResponse.payAmount.toString().split('.');
                            amountOrganized = {
                                wholePart: amountArray[0],
                                fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                            }
                        }
                        else {
                            amountOrganized = {
                                wholePart: getTransferByOperIdResponse.payAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                        this.setState({ amountOrganized });
                    }
                    this.setState({
                        tempHave: true,
                        payAmount: getTransferByOperIdResponse.payAmount,
                        receiveAmount: getTransferByOperIdResponse.receiveAmount,
                        dtFeeAmount: getTransferByOperIdResponse.cooperationFeeAmount,
                        crName: getTransferByOperIdResponse.crName,
                        crSurname: getTransferByOperIdResponse.crSurname,
                        crFname: getTransferByOperIdResponse.crFname,
                        crMobile: getTransferByOperIdResponse.crMobileNumber,
                        crCountry: getTransferByOperIdResponse.country,
                        operationCode: getTransferByOperIdResponse.operationCode,
                        crCity: getTransferByOperIdResponse.city,
                        payCurrency: getTransferByOperIdResponse.currency,
                        receiveCurrency: getTransferByOperIdResponse.receiveAmountCurrency,
                        }, () => {
                        this.showCard(getTransferByOperIdResponse.reqCardId);
                    });
                }
            });

    }
    componentDidMount() {
        this.getZkTransferById();
    }

    render() {
        let { t, history } = this.props;
        let { isLoading, tempHave, card,
            amountOrganized, payAmount, receiveAmount, payCurrency, receiveCurrency, dtFeeAmount, crName, crSurname, crFname, crMobile, crCountry, crCity, operationCode
        } = this.state;
        return (
            <Fragment>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        !tempHave ?
                            <NotFound />
                            :
                            <Fragment>
                                <div className="dashboard-header-route">
                                    <div className="dashboard-header-route-left">
                                        <button onClick={() => history.goBack()}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                            </svg>
                                        </button>
                                        <h2>{t('Zolotaya Korona')}</h2>
                                    </div>
                                    {/* <button onClick={() => this.downloadTariffPdf()} className="dashboard-header-route-link">{t('Tariffs')}</button> */}
                                </div>
                                <div className="dashboard-payment-container">
                                    <div className="dashboard-payment-container-data-section">
                                        <div className="dashboard-payment-container-data-section-header">
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                <h2 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h2>
                                            </div>
                                        </div>
                                        <form className="dashboard-payment-container-data-section-content">
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Transfer settings')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a country')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crCountry} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a city')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crCity} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Conversion')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Currency sent')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box"}>
                                                                <input type="text" value={payCurrency} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Received currency')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box"}>
                                                                <input type="text" value={receiveCurrency} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount sent')} <span style={{ color: 'red' }}>*</span></label>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-input-box"}>
                                                            <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={payAmount} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <button type='button' className='dashboard-payment-convertation-icon'>
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path id="e" fill-rule="evenodd" clip-rule="evenodd" d="M13.7437 5.04221L11.4792 8.1262C11.2229 8.47725 10.8074 8.47725 10.5511 8.1262L10.2547 7.72022C9.9934 7.36233 9.9993 6.77961 10.2677 6.43174L11.1007 5.39322H0.65625C0.293812 5.39322 9.31323e-09 4.99079 9.31323e-09 4.49435V3.8951C9.31323e-09 3.39867 0.293812 2.99623 0.65625 2.99623H11.1007L10.2677 1.95775C9.9993 1.60984 9.9934 1.02716 10.2547 0.669272L10.5511 0.263287C10.8074 -0.0877623 11.2229 -0.0877623 11.4792 0.263287L13.7437 3.34728C14.0854 3.81533 14.0854 4.57416 13.7437 5.04221ZM3.74529 15.3307L3.44889 15.7367C3.19262 16.0878 2.77712 16.0878 2.52083 15.7367L0.256288 12.6527C-0.0854292 12.1847 -0.0854292 11.4258 0.256288 10.9578L2.52083 7.8738C2.77712 7.52275 3.19259 7.52275 3.44889 7.8738L3.74529 8.27979C4.0066 8.63767 4.0007 9.22036 3.73234 9.56826L2.89926 10.6067H13.3438C13.7062 10.6067 14 11.0092 14 11.5056V12.1049C14 12.6013 13.7062 13.0037 13.3438 13.0037H2.89926L3.73234 14.0423C4.0007 14.3902 4.0066 14.9728 3.74529 15.3307Z" fill="#0179D2" />
                                                        </svg>
                                                    </button>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Received amount')} <span style={{ color: 'red' }}>*</span></label>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-input-box"}>
                                                            <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={receiveAmount} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('payment')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by card')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box"}>
                                                                <input type="text" value={
                                                                    card.organizedCardNumber &&
                                                                    `${card.organizedCardNumber} / ${card.balance} ${card.currency}`
                                                                } disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Receiving funds')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('First name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box"}>
                                                                <input type="text" value={crName} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrName} onBlur={this.setCrNameOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Last name')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box"}>
                                                                <input type="text" value={crSurname} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrSurname} onBlur={this.setCrSurnameOutline} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Ancestor name')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input type="text" value={crFname} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrFname} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Mobile number')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                <input maxLength={'15'} type="text" value={crMobile} disabled={true} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole"} onChange={this.checkCrMobile} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="dashboard-payment-container-confirmation-section">
                                        <div className="dashboard-payment-container-confirmation-section-header">
                                            <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                        </div>
                                        <div className="dashboard-payment-container-confirmation-section-content">
                                            <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + payCurrency?.toLocaleLowerCase()}>{currencyFilter(payCurrency)}</span>
                                                <span className="dashboard-payment-container-confirmation-section-amount-whole">{amountOrganized.wholePart}</span>
                                                <span className="dashboard-payment-container-confirmation-section-amount-fractional">{amountOrganized.fractionalPart}</span>
                                            </h3>
                                            <ul className="dashboard-payment-container-confirmation-section-list">
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Zolotaya Korona')}</p>
                                                </li>

                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Recipients name')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crSurname} {crName} {crFname}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Country')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crCity}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('City')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crCountry}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Withdrawal card')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                                                        {
                                                            card.organizedCardNumber &&
                                                            `${card.organizedCardNumber} / ${card.balance} ${card.currency}`
                                                        }
                                                    </p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount sent')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{payAmount} {payCurrency}</p>
                                                </li>
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount received')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{receiveAmount} {receiveCurrency}</p>
                                                </li>
                                                {
                                                    dtFeeAmount !== null &&
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtFeeAmount} {payCurrency}</p>
                                                    </li>
                                                }
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p style={{ fontWeight: 'bold' }} className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Transfer code')}</p>
                                                    <p style={{ fontWeight: 'bold' }} className="dashboard-payment-container-data-section-content-container-form-group-data">{operationCode}</p>
                                                </li>
                                            </ul>
                                            <div className="dashboard-payment-container-data-section-content-container-form-footer"></div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(ZkTransfer);
