import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from './../../../copms/NotFound/NotFound';
import NewOperation from './NewOperation';
import OperationList from './OperationList';
import FirstAuthorisation from './FirstAuthorisation/FirstAuthorisation';
import SecondAuthorisation from './SecondAuthorisation/SecondAuthorisation';
import OTPFirstAuthorisation from './OTP/FirstAuthorisation/FirstAuthorisation';
import OTPSecondAuthorisation from './OTP/SecondAuthorisation/SecondAuthorisation';

export default class Salary extends Component {

    render() {
        let { updateAppState, updateDashboardState, alertState, signLevel, signCount, logout } = this.props;
        return (
            <Switch>
                <Redirect exact from="/dashboard/salary/" to="/dashboard/salary/new-operation" />
                <Route path="/dashboard/salary/new-operation" render={(routeProps) => <NewOperation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/salary/operations" render={(routeProps) => <OperationList logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/salary/2nd-authorization" render={(routeProps) => <FirstAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                <Route path="/dashboard/salary/1st-authorization" render={(routeProps) => <SecondAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                {
                    localStorage.getItem('custObject') && localStorage.getItem('compId') && JSON.parse(localStorage.getItem('custObject')).customer.isOtpSign == 1 &&
                    <Route path="/dashboard/salary/otp/2nd-authorization" render={(routeProps) => <OTPFirstAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                }
                {
                    localStorage.getItem('custObject') && localStorage.getItem('compId') && JSON.parse(localStorage.getItem('custObject')).customer.isOtpSign == 1 &&
                    <Route path="/dashboard/salary/otp/1st-authorization" render={(routeProps) => <OTPSecondAuthorisation logout={() => logout()} {...routeProps} updateAppState={updateAppState} updateDashboardState={updateDashboardState} alertState={alertState} />} />
                }
                <Route render={(routeProps) => (<NotFound />)} />
            </Switch>
        )
    }
}
