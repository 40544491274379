import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import { request } from "./../../../../config";
import userIconForm from "./../../img/user-icon-form.svg";
import phoneIconForm from "./../../img/phone-icon-form.svg";
import headerLogo from "./../../img/header-logo.svg";
import { translate } from 'react-i18next';

class CheckCustomer extends Component {
    state = {
        isDisabled: true,
        validateUserId: false,
        validatePhoneNumber: false,
        feedbackMessage: null,
    }

    backLogin = () => {
        this.props.history.push('/login/juridical/asan');
        // window.location.href= '/login/juridical/asan/';
    }

    validateData = (inputType) => {
        this.setState({ feedbackMessage: null });
        if (inputType === 'userId') {
            this.userId.value = this.userId.value.replace(/\D/g, '');
            if (this.userId.value.length < 6) {
                this.setState({ validateUserId: false });
            }
            else {
                if (this.userId.value.length > 6) {
                    this.userId.value = this.userId.value.substring(0, 6);
                }
                this.setState({ validateUserId: true });
            }
        }
        if (inputType === 'phoneNumber') {
            setTimeout(() => {
                if (this.phoneNumber.value.indexOf("_") === -1) {
                    this.setState({ validatePhoneNumber: true });

                }
                else {
                    this.setState({ validatePhoneNumber: false });

                }
            }, 1)
        }
        setTimeout(() => {
            if (this.state.validateUserId && this.state.validatePhoneNumber) {
                this.setState({ isDisabled: false });
            }
            else {
                this.setState({ isDisabled: true });
            }
        }, 1)
    }

    checkData = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ feedbackMessage: null });
        let getPhoneNumber = (phoneNumber) => {
            ['+', '-', '(', ')', ' '].forEach(char => {
                while (phoneNumber.indexOf(char) !== -1) {
                    phoneNumber = phoneNumber.slice(0, phoneNumber.indexOf(char)) + phoneNumber.slice(phoneNumber.indexOf(char) + 1, phoneNumber.length);
                }
            });
            return phoneNumber;
        }

        let getAuthInfoJuridicalLoginRequestBody = {
            userId: this.userId.value,
            phoneNumber: getPhoneNumber(this.phoneNumber.value),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }

        fetch(request("register/getAuthInfoJuridicalRegister", getAuthInfoJuridicalLoginRequestBody))
        .then(res => res.json())
        .then((getPinFromAsanRequestResponse) => {
            if (getPinFromAsanRequestResponse.status.statusCode !== 1 && getPinFromAsanRequestResponse.status.statusCode !== 113) {
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({
                    feedbackMessage: this.props.t(getPinFromAsanRequestResponse.status.statusCode),
                    isDisabled: true
                });
            }
            else{
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.props.history.push('/registration/juridical/asan/2', { userId: getAuthInfoJuridicalLoginRequestBody.userId, verificationCode:getPinFromAsanRequestResponse.verificationCode, mobile: getAuthInfoJuridicalLoginRequestBody.phoneNumber, token: getPinFromAsanRequestResponse.token, sessionId: getPinFromAsanRequestResponse.sessionId});

            }
            // else {
            //     fetch(request("register/checkPinForAsanJuridicalRegister?pin=" + getPinFromAsanRequestResponse.pin, null, "GET"))
            //     .then(json => json.text())
            //     .then((checkPinForAsanRegisterJSON) => {
            //         const JSON = require('true-json-bigint');
            //         let checkPinForAsanRegisterResponse = JSON.parse(checkPinForAsanRegisterJSON);
            //         if (checkPinForAsanRegisterResponse.status.statusCode === 1 || checkPinForAsanRegisterResponse.status.statusCode === 113) {
            //             let getAuthInfoRequestBody = {
            //                 userId: getAuthInfoJuridicalLoginRequestBody.userId,
            //                 phoneNumber: getAuthInfoJuridicalLoginRequestBody.phoneNumber,
            //                 lang: localStorage.getItem('i18nextLng').toUpperCase()
            //             };
            //             fetch(request("register/getAuthInfo", getAuthInfoRequestBody))
            //             .then(json => json.text())
            //             .then(getAuthInfoResponse => {
            //                 const JSON = require('true-json-bigint');
            //                 let getAuthInfoResponseObject = JSON.parse(getAuthInfoResponse);
            //                 if (getAuthInfoResponseObject.status.statusCode === 1) {
            //                     let certificate = getAuthInfoResponseObject.certificate;
            //                     while (certificate.indexOf("\r\n") !== -1) {
            //                         certificate = certificate.replace("\r\n", "");
            //                     }
            //                     let transactionId = "";
            //                     if (typeof (getAuthInfoResponseObject.transactionId) === 'object' && getAuthInfoResponseObject.transactionId.hasOwnProperty('c')) {
            //                         getAuthInfoResponseObject.transactionId.c.forEach(part => {
            //                             transactionId += part;
            //                         });
            //                     }
            //                     else {
            //                         transactionId += getAuthInfoResponseObject.transactionId;
            //                     }
            //                     let custCode = "";
            //                     if (typeof (getAuthInfoResponse.custCode) === 'object' && getAuthInfoResponse.custCode.hasOwnProperty('c')) {
            //                         getAuthInfoResponse.custCode.c.forEach(part => {
            //                             custCode += part;
            //                         });
            //                     }
            //                     else {
            //                         custCode += getAuthInfoResponse.custCode;
            //                     }
            //                     this.props.updateAppState('isLoadingTransparentBackground', false);
            //                     this.props.history.push('/registration/juridical/asan/2', {  userId: getAuthInfoJuridicalLoginRequestBody.userId, mobile: getAuthInfoJuridicalLoginRequestBody.phoneNumber, 
            //                         transactionId: transactionId, certificate: certificate, verificationCode: getAuthInfoResponseObject.verificationCode,
            //                         pin: checkPinForAsanRegisterResponse.pin, custCode: getAuthInfoResponseObject.custCode, challenge: getAuthInfoResponseObject.challenge});
                                    
            //                 }
            //                 else {
            //                     this.props.updateAppState('isLoadingTransparentBackground', false);
            //                     this.setState({ feedbackMessage: this.props.t(getAuthInfoResponseObject.status.statusCode)});
            //                 }
            //             },
            //             (error) => {
            //                 this.props.history.push("/technical-break");
            //             })
            //         }
            //         else {
            //             this.props.updateAppState('isLoadingTransparentBackground', false);
            //             this.setState({ feedbackMessage: this.props.t(checkPinForAsanRegisterResponse.status.statusCode)});
            //         }
            //     },
            //     (error) => {
            //         this.props.history.push("/technical-break");
            //     });
            // }
        },
        (error) => {
            this.props.history.push("/technical-break");
        });
    }

    componentDidMount() {
        if(this.props.location.state) {
            if(this.props.location.state.feedbackMessage) {
                this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.location.state.feedbackMessage);
                window.history.replaceState(null, '');
            }
        }
    }

    render() {
        let { isDisabled, feedbackMessage } = this.state;
        let { t } = this.props;
        return (
            <Fragment>
                <div className="registration">
                    <Link to="/">
                        <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                    </Link>
                    <form className="registration-container">
                        <h2 className="registration-container-title">{t('registration')}</h2>
                        <p className="registration-container-description">{t('Enter your Asan Mobile Number and Asan User ID')}</p>
                        <div className="registration-container-user-type">
                            <Link to="/registration/individual/asan" className="registration-container-user-type-button registration-container-user-type-button__personal">{t('individual')}</Link>
                            <Link to="/registration/juridical/asan" className="registration-container-user-type-button registration-container-user-type-button__corporate registration-container-user-type-button--active">{t('juridical')}</Link>
                        </div>
                        <div className="registration-container-registration-type">
                            <Link to="/registration/juridical/mobile" className="registration-container-registration-type-label">
                                <input type="radio" name="registration-type" className="registration-container-registration-type-input" />
                                {t('with mobile number and fin code')}
                                <span className="registration-container-registration-type-checkmark"></span>
                            </Link>
                            <Link to="/registration/juridical/asan" className="registration-container-registration-type-label">
                                <input type="radio" name="registration-type" defaultChecked="true" className="registration-container-registration-type-input" />
                                {t('with asan signature')}
                                <span className="registration-container-registration-type-checkmark"></span>
                            </Link>
                        </div>
                        <div className="registration-container-form">
                            <div className="registration-container-form-group">
                                <label className="registration-container-form-group-label">{t('asan signed mobile number')}</label>
                                <div className="registration-container-form-group-box">
                                    <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" className="registration-container-form-group-box-input" placeholder="+994 (__) ___-__-__" ref={(input) => { this.phoneNumber = input }} onInput={this.validateData.bind(this, 'phoneNumber')} />
                                    <div className="registration-container-form-group-box-icon">
                                        <img src={phoneIconForm} className="registration-container-form-group-box-icon-img  registration-container-form-group-box-icon-img__phone" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="registration-container-form-group">
                                <label className="registration-container-form-group-label">{t('asan user id')}</label>
                                <div className="registration-container-form-group-box">
                                    <input type="text" className="registration-container-form-group-box-input" placeholder={t('enter the user id')} ref={(input) => { this.userId = input }} onInput={this.validateData.bind(this, 'userId')} />
                                    <div className="registration-container-form-group-box-icon">
                                        <img src={userIconForm} className="registration-container-form-group-box-icon-img registration-container-form-group-box-icon-img__user" alt="" />
                                    </div>
                                </div>
                            </div>
                            </div>
                        <div className="registration-container-validation-feedback">
                            {
                                feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage}</p> : null
                            }
                        </div>
                        <div className="registration-container-footer">
                            <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backLogin}>{t('back')}</button>
                            <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled} onClick={this.checkData}>{t('next')}</button>
                        </div>
                    </form>
                    <div className="registration-range">
                        <p className="registration-range-fraction">
                            <span className="registration-range-fraction-numerator">1</span>/3
                        </p>
                        <div className="registration-range-container">
                            <div className="registration-range-container-step registration-range-container-step--active">
                                <div className="registration-range-container-step-circle"></div>
                                <div className="registration-range-container-step-line"></div>
                            </div>
                            <div className="registration-range-container-step">
                                <div className="registration-range-container-step-circle"></div>
                                <div className="registration-range-container-step-line"></div>
                            </div>
                            <div className="registration-range-container-step">
                                <div className="registration-range-container-step-circle"></div>
                                <div className="registration-range-container-step-line"></div>
                                <div className="registration-range-container-step-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default translate("translation")(CheckCustomer);