import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function PaymentModalSucces(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [data, setData] = React.useState(props.data)

    const close = () => {
        setOpen(false);
        props.history.goBack();
    }

    useEffect(() => {
        setOpen(props.open)
        setData(props.data)
    }, [props.open])

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        {
                            props.data &&
                            <div className="payment-modal-succes">
                                <div className="payment-modal-succes-left">
                                    <ul class="dashboard-payment-container-confirmation-section-list">
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Receipt')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.receiptNumber}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('name of the card')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.cardName}</p>
                                        </li>

                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Card currency')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.currency}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('period')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.period}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Payment type')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.paymentMethod}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Amount')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.amount}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('PIN information')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.withPin ? "PİN-kod ilə" : "PİN-kodsuz"}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Delivery method')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.deliveryType}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Address')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.address || data?.branchName}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Payment method')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.paymentType}</p>
                                        </li>
                                        <li class="dashboard-payment-container-confirmation-section-list-item">
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-title">{props.t('Payment card/account')}</p>
                                            <p class="dashboard-payment-container-data-section-content-container-form-group-data">{data?.iban || data?.cardNumber}</p>
                                        </li>
                                    </ul>

                                </div>
                                <div className="payment-modal-succes-right" style={{ justifyContent: 'flex-start' }}>
                                    <div style={{ justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z" fill="#F2F8FD" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M62.2463 107.762C87.3841 107.762 107.762 87.3838 107.762 62.246C107.762 37.1083 87.3841 16.7302 62.2463 16.7302C37.1086 16.7302 16.7305 37.1083 16.7305 62.246C16.7305 87.3838 37.1086 107.762 62.2463 107.762Z" fill="#0179D2" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M55.8465 75.7892L43.3726 63.3154C42.6232 62.566 42.6232 61.3509 43.3726 60.6014L46.0865 57.8875C46.8359 57.138 48.051 57.138 48.8005 57.8875L57.2034 66.2904L75.2016 48.2922C75.951 47.5428 77.1661 47.5428 77.9156 48.2922L80.6294 51.0062C81.3789 51.7556 81.3789 52.9707 80.6294 53.7201L58.5603 75.7893C57.8109 76.5387 56.5958 76.5387 55.8465 75.7892Z" fill="white" />
                                        </svg>
                                        <div>
                                            <h2>{props.t('successful operation')}</h2>
                                            <p>{props.t('The operation was completed successfully')}</p>
                                        </div>
                                        <button className="header-sign-section-container-form-button payment-modal-template-save px-5" onClick={() => props.closeFunction()} >{props.t('close')}</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(PaymentModalSucces);