import React, { useEffect, useRef, Fragment } from 'react';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { translate } from 'react-i18next';
import Slider from "react-slick";
import x from './../../components/Dashboard/img/user-guide.png';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
    },
}));

function News(props) {
    const classes = useStyles();
    const slider = useRef();

    const { open, setOpen } = props;
    let settings = {
        infinite: true,
        swipeToSlide: false,
        slidesToShow: 1,
        slidesToScroll: 1,

    };
    let { campaigns, slideIndex, t } = props;
    const close = () => {
        setOpen(false);
    }
    const next = () => {
        slider.current.slickNext();
    }
    const previous = () => {
        slider.current.slickPrev();
    }
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <Modal
            open={open}
            onClose={close}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            classes={classes.modal}


            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description">
            <Fade in={open}>
                <div className={classes.paper}>
                    {
                        (campaigns.length > 1) &&
                        <button className="dashboard-news-slider-prev" onClick={previous}>
                            <svg className="dashboard-news-slider-arrow-image" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.03349 16.5719C0.358031 17.2473 0.358031 18.193 1.03349 18.8684L16.8392 34.539C17.5146 35.2145 18.5953 35.2145 19.1357 34.539L20.0813 33.5934C20.7568 32.9179 20.7568 31.9723 20.0813 31.2968L6.30204 17.7877L20.2164 4.14349C20.7568 3.46804 20.7568 2.5224 20.2164 1.84694L19.1357 0.901306C18.5953 0.22585 17.5146 0.22585 16.8392 0.901306L1.03349 16.5719Z" fill="#DEE2E8" />
                            </svg>
                        </button>
                    }
                    <div className='dashboard-news-slider'>
                        <Slider ref={slider}  currentSlide={slideIndex} {...settings}>
                            {
                                campaigns &&
                                campaigns.map((campaign, index) => (
                                    <Link to={`/dashboard/service-network/campaigns/${campaign.campaignId}`} className="dashboard-news-slider-container" key={index}>
                                        <div className="dashboard-news-slider-item">
                                            <img className="dashboard-news-slider-image" src={campaign.image}/>
                                        </div>
                                    </Link>
                                ))
                            }
                        </Slider>
                    </div>
                    {
                        (campaigns.length > 1) &&
                        <button className="dashboard-news-slider-next" onClick={next}>
                            <svg className="dashboard-news-slider-arrow-image" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9665 19.4281C20.642 18.7527 20.642 17.807 19.9665 17.1316L4.16085 1.461C3.48539 0.785545 2.40466 0.785545 1.8643 1.461L0.918659 2.40664C0.243203 3.08209 0.243203 4.02773 0.918659 4.70319L14.698 18.2123L0.783569 31.8565C0.243205 32.532 0.243205 33.4776 0.783569 34.1531L1.8643 35.0987C2.40466 35.7741 3.48539 35.7741 4.16085 35.0987L19.9665 19.4281Z" fill="#DEE2E8" />
                            </svg>
                        </button>
                    }
                </div>
            </Fade>
        </Modal>
    );
}

export default translate("translation")(News);