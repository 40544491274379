import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import cardIconBackground from './../../img/card-icon-background.svg';
import paperPlaneIcon from './../../img/paper-plane.svg';
import creditCardIcon from './../../img/credit-card.svg';
import { translate } from 'react-i18next';
import { request } from './../../../../config';
import categoryFilter from "./../../../../helpers/categoryFilter";
import currencyFilter from "./../../../../helpers/currencyFilter";
import Loading from './../../Loading/Loading';

class Payments extends Component {

    state = {
        templates: [],
        payments: [],
        categories: [],
        cards: [],
        isLoading: false
    }

    numberToString = (number) => {
        if (number.toString() === "0") {
            return "0.00"
        }
        else {
            if (number.toString().indexOf('.') !== -1) {
                let numberArray = number.toString().split('.');
                let x = (numberArray[1].length === 1) ? (numberArray[1] + '0') : numberArray[1];
                return numberArray[0] + "." + x;
            }
            else {
                return number.toString() + ".00"
            }
        }
    }

    componentWillMount() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCategoryListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            pageNumber: 1,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            pageNumber: 1,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("pg/getCategoryList" + customerType + 'v1', getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCategoryListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCategoryListCustomerResponse = JSON.parse(getCategoryListCustomerResponseJSON);
                if (getCategoryListCustomerResponse.status.statusCode !== 1) {
                    if (getCategoryListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    this.setState({ categories: getCategoryListCustomerResponse.categoryList })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        // ========================
        // fetch(request("pg/paymentTempList" + customerType, getCategoryListCustomerRequestBody))
        //     .then(res => res.text())
        //     .then((paymentTempListCustomerResponseJSON) => {
        //         const JSON = require('true-json-bigint');
        //         let paymentTempListCustomerResponse = JSON.parse(paymentTempListCustomerResponseJSON);
        //         if (paymentTempListCustomerResponse.status.statusCode !== 1 && paymentTempListCustomerResponse.status.statusCode !== '????????????') {
        //             if (paymentTempListCustomerResponse.status.statusCode === 200) {
        //                 this.props.logout();
        //             }
        //         }
        //         else {
        //             if (paymentTempListCustomerResponse.status.statusCode === 1) {
        //                 for (let i = 0; i < paymentTempListCustomerResponse.paymentTempList.length; i++) {
        //                     // =========AMOUNT=========
        //                     if (paymentTempListCustomerResponse.paymentTempList[i].amount.toString() === "0") {
        //                         paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
        //                             wholePart: "0",
        //                             fractionalPart: "00"
        //                         }
        //                     }
        //                     else {
        //                         if (paymentTempListCustomerResponse.paymentTempList[i].amount.toString().indexOf('.') !== -1) {
        //                             let amountArray = paymentTempListCustomerResponse.paymentTempList[i].amount.toString().split('.');
        //                             paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
        //                                 wholePart: amountArray[0],
        //                                 fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
        //                             }
        //                         }
        //                         else {
        //                             paymentTempListCustomerResponse.paymentTempList[i].organizedAmount = {
        //                                 wholePart: paymentTempListCustomerResponse.paymentTempList[i].amount.toString(),
        //                                 fractionalPart: "00"
        //                             }
        //                         }
        //                     }
        //                 }
        //                 this.setState({ templates: paymentTempListCustomerResponse.paymentTempList });
        //             }
        //         }
        //     },
        //         (error) => {
        //             this.props.history.push("/technical-break");
        //         });
        // ====================
        fetch(request("pg/payAdvancedSearch", getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((paymentListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let paymentListCustomerResponse = JSON.parse(paymentListCustomerResponseJSON);
                if (paymentListCustomerResponse.status.statusCode !== 1 && paymentListCustomerResponse.status.statusCode !== 282) {
                    if (paymentListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    if (paymentListCustomerResponse.status.statusCode === 1) {
                        for (let i = 0; i < paymentListCustomerResponse.paymentList.length; i++) {
                            // =========AMOUNT=========
                            if (paymentListCustomerResponse.paymentList[i].amount.toString() === "0") {
                                paymentListCustomerResponse.paymentList[i].organizedAmount = {
                                    wholePart: "0",
                                    fractionalPart: "00"
                                }
                            }
                            else {
                                if (paymentListCustomerResponse.paymentList[i].amount.toString().indexOf('.') !== -1) {
                                    let amountArray = paymentListCustomerResponse.paymentList[i].amount.toString().split('.');
                                    paymentListCustomerResponse.paymentList[i].organizedAmount = {
                                        wholePart: amountArray[0],
                                        fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                    }
                                }
                                else {
                                    paymentListCustomerResponse.paymentList[i].organizedAmount = {
                                        wholePart: paymentListCustomerResponse.paymentList[i].amount.toString(),
                                        fractionalPart: "00"
                                    }
                                }
                            }
                            // =========NUMBER=========
                            if (paymentListCustomerResponse.paymentList[i].cardNumber)
                                paymentListCustomerResponse.paymentList[i].organizedCardNumber = paymentListCustomerResponse.paymentList[i].cardNumber.substring(0, 4)
                                    + " **** **** " + paymentListCustomerResponse.paymentList[i].cardNumber.substring(12, 16);

                        }
                        this.setState({ payments: paymentListCustomerResponse.paymentList });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        fetch(request("card/getCardListFor" + customerType + "Customer", getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);
                        // =========BALANCE=========
                        if (getCardListForCustomerResponse.cardList[i].balance.toString() === "0") {
                            getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getCardListForCustomerResponse.cardList[i].balance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getCardListForCustomerResponse.cardList[i].balance.toString().split('.');
                                getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                    wholePart: getCardListForCustomerResponse.cardList[i].balance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }

                    this.setState({ cards: getCardListForCustomerResponse.cardList });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    render() {
        let { categories, payments, cards, templates, isLoading } = this.state;
        let { updateAppState, t } = this.props;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('payments')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            {/* <div className="dashboard-template-section">
                                <div className="dashboard-template-section-header">
                                    <h3 className="dashboard-template-section-header-title">{t('Templates')}</h3>
                                    <Link to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}templates`} className="dashboard-payment-section-header-history">{t('All templates')}</Link>
                                    <button className="dashboard-template-section-header-history">{t('Edit')}</button>
                                </div>
                                <div className="dashboard-template-section-content">
                                    <Link to="/dashboard/payment/templates/new-template" className="dashboard-template-section-content-button">
                                        <div className="dashboard-template-section-content-button__left-side dashboard-template-section-content-button-icon-section__new">
                                        </div>
                                        <div className="dashboard-template-section-content-button__right-side">
                                            <p className="dashboard-template-section-content-title">{t("New templates")}</p>
                                        </div>
                                    </Link>
                                    {
                                        templates.length !== 0 ?
                                            templates.slice(0, 4).map(template => (
                                                <Link to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}templates/template-pay/${template.categoryId}/providers/${template.merchantId}/temp/${template.id}`} className="dashboard-template-section-content-button" key={template.id}>
                                                    <div className="dashboard-template-section-content-button__left-side">
                                                        <img src={categoryFilter(template.catName)} className="dashboard-template-section-content-icon" />
                                                    </div>
                                                    <div className="dashboard-template-section-content-button__right-side">
                                                        <p className="dashboard-template-section-content-title">{template.tempName}</p>
                                                        <p className="dashboard-template-section-content-amount-section">
                                                            <span className="dashboard-template-section-content-currency dashboard-template-section-content-currency__azn">{currencyFilter(template.currency)}</span>
                                                            <span className="dashboard-template-section-content-amount">{template.amount}</span>
                                                        </p>
                                                    </div>
                                                </Link>
                                            ))
                                            :
                                            [0, 1, 2, 3].map(index => (
                                                <div className="dashboard-template-section-content-button" key={index}>
                                                    <div className="dashboard-template-section-content-button__left-side"></div>
                                                    <div className="dashboard-template-section-content-button__right-side">
                                                        <p className="dashboard-template-section-content-title dashboard-template-section-content-title__invisible"></p>
                                                        <p className="dashboard-template-section-content-amount-section dashboard-template-section-content-amount-section__invisible"></p>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div> */}
                            <div className="dashboard-payment-section" style={{ marginBottom: '28px' }}>
                                <div className="dashboard-payment-section-header">
                                    <h3 className="dashboard-payment-section-header-title">{t('Make a new payment')}</h3>
                                    <Link to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}payments/categories`} className="dashboard-payment-section-header-history">{t('All payment')}</Link>
                                </div>
                                <div className="dashboard-payment-section-content">
                                    {
                                        categories.slice(0, 10).map(category => (
                                            <div className='d-flex align-items-center flex-column' style={{ width: '128px' }}>
                                                <NavLink to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}payments/categories/${category.categoryId}`} className="dashboard-payment-section-content-button" key={category.categoryId}>
                                                    <img src={categoryFilter(category.categoryName)} className="dashboard-payment-section-content-icon" />
                                                </NavLink>
                                                <p className="dashboard-payment-section-content-title">{category.displayName}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="dashboard-payment-operation-card-section">
                                <div className="dashboard-payment-operation-section">
                                    <div className="dashboard-payment-operation-section-header">
                                        <h3 className="dashboard-payment-operation-section-header-title">{t('recent operations')}</h3>
                                        <Link to="/dashboard/payment/history" className="dashboard-payment-operation-section-header-history">{t('history')}</Link>
                                    </div>
                                    <div className="dashboard-payment-operation-section-content">
                                        {
                                            payments.length !== 0 ?
                                                payments.slice(0, 3).map((payment, index) => (
                                                    <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                        <div className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                <img src={categoryFilter(payment.catName)} className="dashboard-payment-operation-section-content-item-icon" />
                                                            </div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title">{payment.merchantName}</div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-purpose">{payment.billingDate}, {payment.currency}, {payment.organizedCardNumber}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            {payment?.billingStateId !== undefined && <p className="dashboard-finance-section-cards-section-cell-title">Status: {payment.billingStateId == 0 ? t('an error occurred') : payment.billingStateId == 6 ? t('on hold') : payment.billingStateId == 2 ? t('successful operation') : ''}</p>}

                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-amount">
                                                                <span className="dashboard-payment-operation-section-content-item-amount-whole-part">{payment.organizedAmount.wholePart}</span>
                                                                <span className="dashboard-payment-operation-section-content-item-amount-fractional-part">{payment.organizedAmount.fractionalPart}</span>
                                                                <span className="dashboard-payment-operation-section-content-item-amount-currency">{payment.currency}</span>
                                                            </div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__right">{payment.categoryName}</p>
                                                        </div>
                                                    </li>
                                                ))
                                                :
                                                <>
                                                    <li className="dashboard-payment-operation-section-content-item">
                                                        <Link style={{ textDecoration: 'none' }} to={`/dashboard/payment/${this.props.bonus ? 'bonus/' : ''}payments/categories`} className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                <img src={paperPlaneIcon} className="dashboard-payment-operation-section-content-item-icon" />
                                                            </div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title">{t('Make a new payment')}</div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle">{t('card number')}</div>
                                                            </div>
                                                        </Link>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                        </div>
                                                    </li>
                                                    {
                                                        [0, 1].map(index => (
                                                            <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                                <div className="dashboard-payment-operation-section-content-item--left-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-icon-container"></div>
                                                                    <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                        <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                        <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                    <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className="dashboard-payment-card-section">
                                    <div className="dashboard-payment-card-section-header">
                                        <h3 className="dashboard-payment-card-section-header-title">{t('cards')}</h3>
                                        <Link to="/dashboard/finance/cards/" className="dashboard-payment-card-section-header-history">{t('all')}</Link>
                                    </div>
                                    <div className="dashboard-payment-card-section-content">
                                        {
                                            cards.length !== 0 ?
                                                cards.slice(0, 3).map(card => (
                                                    <Link to={"/dashboard/finance/cards/" + card.cardId} className="dashboard-payment-card-section-content-item" key={card.cardId}>
                                                        <div className="dashboard-payment-card-section-content-item-left-side">
                                                            <div className="dashboard-payment-card-section-content-item-card-icon">
                                                                <img src={cardIconBackground} className="dashboard-payment-card-section-content-item-card-icon-image" alt="" />
                                                            </div>
                                                            <div className="dashboard-payment-card-section-content-item-card-name">
                                                                <h4 className="dashboard-payment-card-section-content-item-card-name">{card.cardType}</h4>
                                                                <p className="dashboard-payment-card-section-content-item-card-number">{card.organizedCardNumber}</p>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-card-section-content-item-right-side">
                                                            <span className="dashboard-payment-card-section-content-item-amount-whole-part">{card.organizedCurrentBalance.wholePart}</span>
                                                            <span className="dashboard-payment-card-section-content-item-amount-fractional-part">{card.organizedCurrentBalance.fractionalPart}</span>
                                                            <span className="dashboard-payment-card-section-content-item-amount-currency">{card.currency}</span>
                                                        </div>
                                                    </Link>
                                                ))
                                                :
                                                <>
                                                    <li className="dashboard-payment-operation-section-content-item">
                                                        <div className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                <img src={creditCardIcon} className="dashboard-payment-operation-section-content-item-icon dashboard-payment-operation-section-content-item-icon__card" />
                                                            </div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title">{t('Make a new payment')}</div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle">{t('card number')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                        </div>
                                                    </li>
                                                    {
                                                        [0, 1].map(index => (
                                                            <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                                <div className="dashboard-payment-operation-section-content-item--left-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-icon-container"></div>
                                                                    <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                        <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                        <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                    <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}


export default translate("translation")(Payments);