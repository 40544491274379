import React, { Component, Fragment } from 'react';
import { Link, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { translate } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import $ from 'jquery';

import { request } from "../../../config";
import CardSlider from './CardSlider/CardSlider';
import lastOperationsIcon from "./../img/last-operations-icon.png";
import cardIcon from "./../img/card-icon.svg";
import addIcon from "./../img/add.svg";
import cardBackground from "./../img/card-background.png";
import paperIcon from "./../img/paper.svg";
import accountIcon from './../img/account-icon.svg';
import Dropdown from './../../../copms/Dropdown/Dropdown';
import CardModal from '../../../copms/Modal/CardModal';
import AccountModal from '../../../copms/Modal/AccountModal';
import currencyFilter from './../../../helpers/currencyFilter';
import Loading from './../Loading/Loading';
import News from './../../../copms/Modal/News';
import flagFilter from './../../../helpers/flagFilter';
import Calendar from '../../../copms/Calendar/Calendar';
import creditIcon from './../img/credit-icon.svg';
import depositIcon from './../img/deposit-icon.svg';
import cardsIcon from './../img/cards-icon.png';
import DropdownWithChevron from './../../../copms/DropdownWithChevron/DropdownWithChevron';
import DropdownWithFlag from './../../../copms/DropdownWithFlag/DropdownWithFlag';
import Statements from './Statements';
import YesNoModal from './../../../copms/Modal/YesNoModal';
import PinUnblockSuccess from './../../../copms/Modal/PinUnblockSuccess';
import CampaignPopup from '../../../copms/Modal/CampaignPopup';

function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

class Home extends Component {
    state = {
        campaigns: [],
        cards: [],
        accounts: [],
        statements: [],
        currentCardIndex: 0,
        openCard: false,
        openAccount: false,
        modalCardObj: {},
        currentCard: {
            organizedCurrentBalance: {}
        },
        slideIndex: null,
        sliderModal: false,
        selected: this.props.t('On cash funds'),
        exchanges: [],
        firstAmount: "",
        firstCurrencyIndex: null,
        firstCurrency: null,
        secondCurrency: null,
        secondCurrencyIndex: null,
        isCash: true,
        isLoading: false,
        opens: [true, false, false, false, false],
        credits: [],
        deposits: [],
        signLevel: null,
        signCount: null,
        loadingCount: 0,
        pinUnblockAmount: null,
        visibility:null,
        campaignCustId:null,
        screenTypeId:null,
        image:null,
        campaignId:null,
        note: "",
        imageVersionNoCampaign: !localStorage.getItem('imageVersionNoCampaign')  ? null : localStorage.getItem('imageVersionNoCampaign'),
    }
    updateCurrentCard = (value) => {
        let i = this.state.currentCardIndex;
        if (value === 'prev') {
            if (this.state.currentCardIndex > 0) {
                i--;
            }
            else {
                i = this.state.cards.length - 1;
            }
        }
        else {
            if (value === 'next') {
                if (this.state.currentCardIndex < this.state.cards.length - 1) {
                    i++;
                }
                else {
                    i = 0;
                }
            }
        }
        this.setState({ currentCardIndex: i });
        this.setState({ currentCard: this.state.cards[i] });
    }
    updateCardState = (index) => {
        this.setState({ currentCardIndex: index });
        this.setState({ currentCard: this.state.cards[index] });
    }

    async showStatements() {
        const changeDateType = () => {
            let currentDate = new Date();
            let date = (currentDate.getDate() < 10) ? ("0" + currentDate.getDate()) : currentDate.getDate();
            let month = (currentDate.getMonth() + 1 < 10) ? ("0" + (currentDate.getMonth() + 1)) : (currentDate.getMonth() + 1);
            let year = currentDate.getFullYear();
            return date + '.' + month + '.' + year;
        }
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardStatementForCustomerRequestBody = customerType === "Juridical" ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: changeDateType(), //temporary
            endDate: changeDateType(),
            compId: localStorage.getItem('compId'),
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            startDate: changeDateType(), //temporary
            endDate: changeDateType(),

        }
        await fetch(request("card/getCardStatementFor" + customerType + "Customer", getCardStatementForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardStatementForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardStatementForCustomerResponse = JSON.parse(getCardStatementForCustomerResponseJSON);
                if (getCardStatementForCustomerResponse.status.statusCode !== 1 && getCardStatementForCustomerResponse.status.statusCode !== 137 && getCardStatementForCustomerResponse.status.statusCode !== 165) {
                    if (getCardStatementForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardStatementForCustomerResponse.status.statusCode));
                        // this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardStatementForCustomerResponse.status.statusCode === 137 || getCardStatementForCustomerResponse.status.statusCode === 165) {
                        getCardStatementForCustomerResponse.cardStamentList = [];
                    }
                    for (let i = 0; i < getCardStatementForCustomerResponse.cardStamentList.length; i++) {
                        // =========NUMBER=========
                        getCardStatementForCustomerResponse.cardStamentList[i].organizedCardNumber = getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(12, 16);
                        // =========DATE=========
                        getCardStatementForCustomerResponse.cardStamentList[i].organizedDate = getCardStatementForCustomerResponse.cardStamentList[i].trDate.substring(0, getCardStatementForCustomerResponse.cardStamentList[i].trDate.length - 3);
                        // =========AMOUNT=========
                        if (getCardStatementForCustomerResponse.cardStamentList[i].dtAmount === 0) {
                            getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].crAmount;
                        }
                        else {
                            if (getCardStatementForCustomerResponse.cardStamentList[i].crAmount === 0) {
                                getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].dtAmount;
                            }
                        }
                        if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString() === "0") {
                            getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().indexOf('.') !== -1) {
                                let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                                getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                    wholePart: amountArray[0],
                                    fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                }
                            }
                            else {
                                let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                                getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                    wholePart: getCardStatementForCustomerResponse.cardStamentList[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                    }
                    this.setState({ statements: getCardStatementForCustomerResponse.cardStamentList });
                }
            },
                (error) => {

                    this.props.history.push("/technical-break");
                });
    }
    async showCampains() {
        let getCampaignListRequestBody = {
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            token: localStorage.getItem('token'),
            custId:parseInt(localStorage.getItem('custId')),
            imageVersionNoCampaign: this.state.imageVersionNoCampaign,
        }
        if (localStorage.getItem('compId')) {
            getCampaignListRequestBody.compId = parseInt(localStorage.getItem('compId'));
        }
        fetch(request("util/getCampaignListv1", getCampaignListRequestBody))
            .then(res => res.text())
            .then((getCampaignListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCampaignListResponse = JSON.parse(getCampaignListResponseJSON);
                if (getCampaignListResponse.status.statusCode !== 1) {
                    if (getCampaignListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCampaignListResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    getCampaignListResponse.respCampaignList.map((item, index) => {
                        getCampaignListResponse.respCampaignList[index].image = 'data:image/;base64,' + getCampaignListResponse.respCampaignList[index].image;
                    });

                    localStorage.getItem("imageVersionNoCampaign") !== getCampaignListResponse?.imageVersionNoCampaign && 
                       localStorage.setItem("campaigns", JSON.stringify(getCampaignListResponse?.respCampaignList)) 
                
                       this.setState({ 
                        campaigns: JSON.parse(localStorage.getItem('campaigns')) , 
                        visibility:getCampaignListResponse?.visibility, screenTypeId: getCampaignListResponse?.screenTypeId, campaignCustId: getCampaignListResponse?.campaignCustId, image: 'data:image/;base64,' + getCampaignListResponse?.image, campaignId:getCampaignListResponse?.campaignId, note: getCampaignListResponse?.note  });

                        localStorage.setItem("imageVersionNoCampaign", getCampaignListResponse.imageVersionNoCampaign);
                        localStorage.setItem("sendRequestCampaigns", "0");
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showAccounts = () => {
        // this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        }

        fetch(request("account/getAccountListFor" + customerType + "Customer", getAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                if (getAccountListForCustomerResponse.status.statusCode !== 1 && getAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountListForCustomerResponse.status.statusCode));
                        // this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getAccountListForCustomerResponse.status.statusCode === 126) {
                        getAccountListForCustomerResponse.accountList = [];
                    }
                    let totalAmount = {
                        azn: 0,
                        usd: 0,
                        eur: 0
                    }
                    for (let i = 0; i < getAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        getAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getAccountListForCustomerResponse.accountList[i].currName);
                        // =========TOTALAMOUNT=========
                        if (getAccountListForCustomerResponse.accountList[i].currName === "AZN") {
                            totalAmount.azn += getAccountListForCustomerResponse.accountList[i].currentBalance;
                        }
                        if (getAccountListForCustomerResponse.accountList[i].currName === "USD") {
                            totalAmount.usd += getAccountListForCustomerResponse.accountList[i].currentBalance;
                        }
                        if (getAccountListForCustomerResponse.accountList[i].currNames === "EUR") {
                            totalAmount.eur += getAccountListForCustomerResponse.accountList[i].currentBalance;
                        }
                    }
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });
                    this.setState({ accounts: getAccountListForCustomerResponse.accountList });
                    // this.setState({ isLoading: false });
                }

                this.loadingCheck();

            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showRequisites = (id) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountRequisiteIndividualByteArrayRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            accountId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            accountId: id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("account/getAccountRequisite" + customerType + "ByteArray", getAccountRequisiteIndividualByteArrayRequestBody))
            .then(res => res.json())
            .then((getAccountRequisiteByteArrayResponse) => {
                if (getAccountRequisiteByteArrayResponse.status.statusCode !== 1) {
                    if (getAccountRequisiteByteArrayResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(getAccountRequisiteByteArrayResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    var newWin = window.open(link, '');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                        saveFile(this.props.t('Account_Requisites') + "_" + this.getFormatedDate(this.state.startDate) + "-" + this.getFormatedDate(this.state.endDate) + ".pdf", "application/pdf", arrrayBuffer)
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    showCards = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListForCustomerRequestBody;
        if (customerType === 'Individual') {
            getCardListForCustomerRequestBody = {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        }
        else {
            getCardListForCustomerRequestBody = {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }
        }
        // this.setState({custId: localStorage.getItem('custId'), token: localStorage.getItem('token')});
        fetch(request("card/getCardListFor" + customerType + "Customer", getCardListForCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        // this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    let totalAmount = {
                        azn: 0,
                        usd: 0,
                        eur: 0
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========HOLDER=========
                        let holderArray = getCardListForCustomerResponse.cardList[i].holder.split(' ');
                        let lowercaseName = holderArray[0].toLowerCase();
                        let lowercaseSurname = holderArray[1].toLowerCase();
                        getCardListForCustomerResponse.cardList[i].organizedHolder = {
                            name: holderArray[0][0] + lowercaseName.substring(1, lowercaseName.length),
                            surname: holderArray[1][0] + lowercaseSurname.substring(1, lowercaseSurname.length)
                        };
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);
                        // =========EXPIRYDATE=========
                        let expiryDate = new Date(getCardListForCustomerResponse.cardList[i].expiryDate);
                        getCardListForCustomerResponse.cardList[i].organizedExpiryDate = {
                            month: (expiryDate.getMonth() < 9) ? ('0' + (expiryDate.getMonth() + 1)) : (expiryDate.getMonth() + 1),
                            year: expiryDate.getFullYear().toString().substring(2, 4)
                        }
                        // =========BALANCE=========
                        if (getCardListForCustomerResponse.cardList[i].balance.toString() === "0") {
                            getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getCardListForCustomerResponse.cardList[i].balance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getCardListForCustomerResponse.cardList[i].balance.toString().split('.');
                                getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                getCardListForCustomerResponse.cardList[i].organizedCurrentBalance = {
                                    wholePart: getCardListForCustomerResponse.cardList[i].balance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        getCardListForCustomerResponse.cardList[i].currencyIcon = currencyFilter(getCardListForCustomerResponse.cardList[i].currency);
                        // =========TOTALAMOUNT=========
                        if (getCardListForCustomerResponse.cardList[i].currency === "AZN") {
                            totalAmount.azn += getCardListForCustomerResponse.cardList[i].balance;
                        }
                        if (getCardListForCustomerResponse.cardList[i].currency === "USD") {
                            totalAmount.usd += getCardListForCustomerResponse.cardList[i].balance;

                        }
                        if (getCardListForCustomerResponse.cardList[i].currency === "EUR") {
                            totalAmount.eur += getCardListForCustomerResponse.cardList[i].balance;
                        }
                    }
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });

                    this.setState({ cards: getCardListForCustomerResponse.cardList });
                    this.setState({ pinUnblockAmount: getCardListForCustomerResponse.pinUnblockAmount });
                    // this.setState({ isLoading: false });

                }
                this.loadingCheck();

            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showLoan = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let loanRequestBody = customerType === "Individual" ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("loan/loan" + customerType, loanRequestBody))
            .then(res => res.text())
            .then((loanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let loanResponse = JSON.parse(loanResponseJSON);
                if (loanResponse.status.statusCode !== 1 && loanResponse.status.statusCode !== 139) {
                    if (loanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(loanResponse.status.statusCode));
                        // this.setState({ isLoading: false });

                    }
                }
                else {
                    if (loanResponse.status.statusCode === 139) {
                        loanResponse.loans = [];
                    }
                    let totalAmount = {
                        azn: 0,
                        usd: 0,
                        eur: 0
                    }
                    for (let i = 0; i < loanResponse.loans.length; i++) {
                        // // =========ID=========
                        let id = "";
                        if (typeof (loanResponse.loans[i].id) === 'object' && loanResponse.credit[i].id.hasOwnProperty('c')) {
                            loanResponse.credit[i].id.c.forEach(part => {
                                id += part;
                            });
                        }
                        else {
                            id += loanResponse.loans[i].id;
                        }
                        loanResponse.loans[i].id = id;
                        // =========BALANCE=========
                        if (loanResponse.loans[i].loanAmount.toString() === "0") {
                            loanResponse.loans[i].organizedLoanAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (loanResponse.loans[i].loanAmount.toString().indexOf('.') !== -1) {
                                let loanAmountArray = loanResponse.loans[i].loanAmount.toString().split('.');
                                loanResponse.loans[i].organizedLoanAmount = {
                                    wholePart: loanAmountArray[0],
                                    fractionalPart: (loanAmountArray[1].length === 1) ? (loanAmountArray[1] + '0') : loanAmountArray[1]
                                }
                            }
                            else {
                                let loanAmountArray = loanResponse.loans[i].loanAmount.toString().split('.');
                                loanResponse.loans[i].organizedLoanAmount = {
                                    wholePart: loanResponse.loans[i].loanAmount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========REST=========
                        if (loanResponse.loans[i].rest.toString() === "0") {
                            loanResponse.loans[i].organizedRestAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (loanResponse.loans[i].rest.toString().indexOf('.') !== -1) {
                                let restArray = loanResponse.loans[i].rest.toString().split('.');
                                loanResponse.loans[i].organizedRestAmount = {
                                    wholePart: restArray[0],
                                    fractionalPart: (restArray[1].length === 1) ? (restArray[1] + '0') : restArray[1]
                                }
                            }
                            else {
                                let restArray = loanResponse.loans[i].rest.toString().split('.');
                                loanResponse.loans[i].organizedRestAmount = {
                                    wholePart: loanResponse.loans[i].rest.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        loanResponse.loans[i].currencyIcon = currencyFilter(loanResponse.loans[i].currency);
                        // =========TOTALAMOUNT=========
                        if (loanResponse.loans[i].currency === "AZN") {
                            totalAmount.azn += loanResponse.loans[i].loanAmount;
                        }
                        if (loanResponse.loans[i].currency === "USD") {
                            totalAmount.usd += loanResponse.loans[i].loanAmount;
                        }
                        if (loanResponse.loans[i].currencys === "EUR") {
                            totalAmount.eur += loanResponse.loans[i].loanAmount;
                        }
                    }
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });
                    this.setState({ credits: loanResponse.loans });
                    // this.setState({ isLoading: false });
                }
                this.loadingCheck();

            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showDeposits = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let loanRequestBody = customerType === "Individual" ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("deposit/" + customerType.toLocaleLowerCase() + "DepositList", loanRequestBody))
            .then(res => res.text())
            .then((depositResponseJSON) => {
                const JSON = require('true-json-bigint');
                let depositResponse = JSON.parse(depositResponseJSON);
                if (depositResponse.status.statusCode !== 1 && depositResponse.status.statusCode !== 158) {
                    if (depositResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(depositResponse.status.statusCode));
                        // this.setState({ isLoading: false });
                    }
                }
                else {
                    if (depositResponse.status.statusCode === 158) {
                        depositResponse.deposits = [];
                    }
                    let totalAmount = {
                        azn: 0.00,
                        usd: 0.00,
                        eur: 0.00
                    }
                    for (let i = 0; i < depositResponse.deposits.length; i++) {
                        // // =========ID=========
                        let id = "";
                        if (typeof (depositResponse.deposits[i].id) === 'object' && depositResponse.credit[i].id.hasOwnProperty('c')) {
                            depositResponse.credit[i].id.c.forEach(part => {
                                id += part;
                            });
                        }
                        else {
                            id += depositResponse.deposits[i].id;
                        }
                        depositResponse.deposits[i].id = id;
                        // =========BALANCE=========
                        if (depositResponse.deposits[i].amount.toString() === "0") {
                            depositResponse.deposits[i].amount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (depositResponse.deposits[i].amount.toString().indexOf('.') !== -1) {
                                let loanAmountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].amountParts = {
                                    wholePart: loanAmountArray[0],
                                    fractionalPart: (loanAmountArray[1].length === 1) ? (loanAmountArray[1] + '0') : loanAmountArray[1]
                                }
                            }
                            else {
                                let loanAmountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].amountParts = {
                                    wholePart: depositResponse.deposits[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========AMOUNT=========
                        if (depositResponse.deposits[i].amount.toString() === "0") {
                            depositResponse.deposits[i].organizedAmount = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (depositResponse.deposits[i].amount.toString().indexOf('.') !== -1) {
                                let acountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].organizedAmount = {
                                    wholePart: acountArray[0],
                                    fractionalPart: (acountArray[1].length === 1) ? (acountArray[1] + '0') : acountArray[1]
                                }
                            }
                            else {
                                let acountArray = depositResponse.deposits[i].amount.toString().split('.');
                                depositResponse.deposits[i].organizedAmount = {
                                    wholePart: depositResponse.deposits[i].amount.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        depositResponse.deposits[i].currencyIcon = currencyFilter(depositResponse.deposits[i].currency);
                        // =========TOTALAMOUNT=========
                        if (depositResponse.deposits[i].currency === "AZN") {
                            totalAmount.azn += depositResponse.deposits[i].amount;
                        }
                        if (depositResponse.deposits[i].currency === "USD") {
                            totalAmount.usd += depositResponse.deposits[i].amount;
                        }
                        if (depositResponse.deposits[i].currencys === "EUR") {
                            totalAmount.eur += depositResponse.deposits[i].amount;
                        }
                    }
                    this.setState({ totalAmountAZN: this.numberToString(totalAmount.azn.toFixed(2)) });
                    this.setState({ totalAmountUSD: this.numberToString(totalAmount.usd.toFixed(2)) });
                    this.setState({ totalAmountEUR: this.numberToString(totalAmount.eur.toFixed(2)) });
                    this.setState({ deposits: depositResponse.deposits });
                    // this.setState({ isLoading: false });
                }
                this.loadingCheck();

            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    dropdownLoanItemHandler = (index, id) => {
        // sessionStorage.setItem("currencyCard", "asdf");

        switch (index) {
            case 1:
                this.props.updateAppState('isLoadingTransparentBackground', true);

                this.setState({ loanId: id }, () => {
                    this.setState({ openCreditSchedule: true });
                });
                sessionStorage.setItem("currencyCard", "asdf");
                break;
            case 2:
                this.props.updateAppState('isLoadingTransparentBackground', true);

                this.setState({ loanId: id }, () => {
                    this.setState({ openCreditPayment: true });
                })
                break;
        }
    }

    dropdownItemHandler = (index, card) => {
        sessionStorage.setItem("currencyCard", "asdf");
        localStorage.setItem("asdf","asdsf")

        switch (index) {
            case 2:
                this.setState({
                    openCard: true,
                    modalCardObj: card
                });
                sessionStorage.setItem("currencyCard", "asdf");
                break;
            case 3:
                if (this.state.pinUnblockAmount) {
                    this.setState({ modalCardObj: card, unblockPinModalOpen: true });
                }
                else {
                    this.setState({ modalCardObj: card }, () => {
                        this.unblockFunc();
                    });
                }
        }
    }

    numberToString = (number) => {
        if (number.toString() === "0") {
            return "0.00"
        }
        else {
            if (number.toString().indexOf('.') !== -1) {
                let numberArray = number.toString().split('.');
                let x = (numberArray[1].length === 1) ? (numberArray[1] + '0') : numberArray[1];
                return numberArray[0] + "." + x;
            }
            else {
                return number.toString() + ".00"
            }
        }
    }

    showSlider = (index) => {
        this.setState({
            sliderModal: true,
            slideIndex: index
        });
    }
    async fetchData() {
        this.setState({ isLoading: true });
        await this.showCards();
        await this.showAccounts();
        await this.showStatements();
        await this.showCampains();
        this.setState({ isLoading: false });
    }

    updateSelected = (value) => {
        this.setState({ selected: value });
    }
    updateIsCash = (value) => {
        this.setState({ isCash: value });
    }
    updateFirstCurrency = (value) => {
        this.setState({ firstCurrency: value });
    }
    updateSecondCurrency = (value) => {
        this.setState({ secondCurrency: value });
    }
    getFormatedDate = (date) => {
        if (date) {
            let formatedDate = {
                day: (date.getDate() < 9) ? ('0' + date.getDate()) : date.getDate(),
                month: (date.getMonth() < 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1),
                year: date.getFullYear().toString()
            }
            return formatedDate.day + '.' + formatedDate.month + '.' + formatedDate.year;
        }
        return null;
    }

    convertAmount = (event) => {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({ firstAmount: event.target.value });
        }
    }

    async getExchangeData(date) {
        if (date) {
            this.props.updateAppState('isLoadingTransparentBackground', true);
        }
        else {
            // this.setState({ isLoading: true });
        }
        let exchangeCashList = [], exchangeCashlessList = [], exchangeCentralBankList = [];
        await fetch(request("exchange/cash/" + (date ? this.getFormatedDate(date) : ''), null, 'GET'))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        if (date) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                        else {
                            // this.setState({ isLoading: false });
                        }
                    }
                }
                else {
                    exchangeCashList = exchangeResponse.exchangeCashList;
                    if (date) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        // this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        await fetch(request("exchange/cashless/" + (date ? this.getFormatedDate(date) : ''), null, 'GET'))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        if (date) {
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                        else {
                            // this.setState({ isLoading: false });
                        }
                    }
                }
                else {
                    exchangeCashlessList = exchangeResponse.exchangeCashlessList;
                    if (date) {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                    else {
                        // this.setState({ isLoading: false });
                    }
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        await fetch(request("exchange/centralBank/" + (date ? this.getFormatedDate(date) : ''), null, 'GET'))
            .then(res => res.text())
            .then((exchangeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let exchangeResponse = JSON.parse(exchangeResponseJSON);
                if (exchangeResponse.status.statusCode !== 1 && exchangeResponse.status.statusCode !== 168) {
                    if (exchangeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(exchangeResponse.status.statusCode));
                        this.props.updateAppState('isLoading', false);
                    }
                }
                else {
                    exchangeCentralBankList = exchangeResponse.exchangeCentralBankList;
                    this.props.updateAppState('isLoading', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
        let exchangeResultArray = exchangeCentralBankList;
        if (exchangeCashList) {
            exchangeCashList.map((cashItem) => {
                let hasCurrency = false;
                exchangeResultArray.map((item, index) => {
                    if (cashItem.currency === item.currency) {
                        exchangeResultArray[index].cashSell = cashItem.cashSell;
                        exchangeResultArray[index].cashBuy = cashItem.cashBuy;
                        hasCurrency = true;
                    }
                });
                if (hasCurrency === false) {
                    exchangeResultArray.push({ currency: cashItem.currency, cashSell: cashItem.cashSell, cashBuy: cashItem.cashBuy });
                }
            });
        }
        if (exchangeCashlessList) {
            exchangeCashlessList.map((cashlessItem) => {
                let hasCurrency = false;
                exchangeResultArray.map((item, index) => {
                    if (cashlessItem.currency == item.currency) {
                        exchangeResultArray[index].cashlessSell = cashlessItem.cashlessSell;
                        exchangeResultArray[index].cashlessBuy = cashlessItem.cashlessBuy;
                        hasCurrency = true;
                    }
                });
                if (hasCurrency == false) {
                    exchangeResultArray.push({ currency: cashlessItem.currency, cashlessSell: cashlessItem.cashSell, cashlessBuy: cashlessItem.cashBuy });
                }
            });
        }
        if (exchangeResultArray) {
            const index = exchangeResultArray.findIndex((item, index) => item.currency == 'AZN');
            if (index == -1) {
                exchangeResultArray.push({ currency: 'AZN', cashBuy: 1, cashSell: 1, cashlessBuy: 1, cashlessSell: 1 });
            }
            else {
                exchangeResultArray[index].centralBank = 1;
                exchangeResultArray[index].cashBuy = 1;
                exchangeResultArray[index].cashSell = 1;
                exchangeResultArray[index].cashlessBuy = 1;
                exchangeResultArray[index].cashlessSell = 1;
            }
            exchangeResultArray.sort((prev, next) => {
                return (prev.currency < next.currency) ? -1 : (prev.currency > next.currency) ? 1 : 0;
            })
            this.setState({ exchanges: exchangeResultArray });
        }
        else {
            this.setState({ exchanges: [] });
        }
        this.setState({
            firstCurrency: 'USD',
            secondCurrency: 'AZN'
        }, () => {
            this.updateCurrencyIndex();
        });

        this.loadingCheck();
    }

    updateDate = (name, date) => {
        this.setState({ [name]: date }, () => {
            this.getExchangeData(date);
        });
    }
    updateCurrencyIndex = () => {
        this.setState({ firstCurrencyIndex: this.state.exchanges.findIndex((exchange) => exchange.currency == this.state.firstCurrency) });
        this.setState({ secondCurrencyIndex: this.state.exchanges.findIndex((exchange) => exchange.currency == this.state.secondCurrency) });
    }
    replaceCurrency = () => {
        this.setState({ firstCurrency: this.state.secondCurrency, secondCurrency: this.state.firstCurrency });
    }

    componentDidMount() {
        this.setState({ loadingCount: 5, isLoading: true })
        // this.fetchData();
        this.getExchangeData();
        this.showCards();
        this.showAccounts();
        this.showLoan();
        this.showDeposits();
        JSON.parse(localStorage.getItem('sendRequestCampaigns')) && this.showCampains();
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel, signCount: custObject.company.signCount });
        }
        !JSON.parse(localStorage.getItem('sendRequestCampaigns')) && this.setState({ campaigns: JSON.parse(localStorage.getItem('campaigns')) });
    }

    loadingCheck() {
        this.setState({ loadingCount: this.state.loadingCount - 1 }, () => {
            if (this.state.loadingCount == 0) {
                this.setState({ isLoading: false });
            }
        })
    }

    dropdownAccountItemHandler = (index, account) => {
        switch (index) {
            case 1:
                this.showRequisites(account.accountId);
                break;
            case 2:
                this.setState({
                    openAccount: true,
                    modalAccountObj: account
                });
                break;
        }
    }

    setOpenAcc(type) {
        var opens = this.state.opens;
        for (let i = 0; i < opens.length; i++) {
            if (i !== type) {
                opens[i] = false;
            }
        }
        opens[type] = !opens[type];
        this.setState({ opens: opens })
    }

    unblockFunc = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
        let cardPinCodeUnblockRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardNumber: this.state.modalCardObj.cardNumber,
            cardId: this.state.modalCardObj.cardId,
            currency: this.state.modalCardObj.currency
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardNumber: this.state.modalCardObj.cardNumber,
            cardId: this.state.modalCardObj.cardId,
            currency: this.state.modalCardObj.currency
        }
        fetch(request("card/cardPinCodeUnblock", cardPinCodeUnblockRequestBody))
            .then(res => res.text())
            .then((cardPinCodeUnblockResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cardPinCodeUnblockResponse = JSON.parse(cardPinCodeUnblockResponseJSON);
                if (cardPinCodeUnblockResponse.status.statusCode === 1) {
                    // =========FEE=========
                    let organizedFeeAmount;
                    if (cardPinCodeUnblockResponse.feeAmount.toString() === "0") {
                        organizedFeeAmount = {
                            wholePart: "0",
                            fractionalPart: "00"
                        }
                    }
                    else {
                        if (cardPinCodeUnblockResponse.feeAmount.toString().indexOf('.') !== -1) {
                            let currentBalanceArray = cardPinCodeUnblockResponse.feeAmount.toString().split('.');
                            organizedFeeAmount = {
                                wholePart: currentBalanceArray[0],
                                fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                            }
                        }
                        else {
                            organizedFeeAmount = {
                                wholePart: cardPinCodeUnblockResponse.feeAmount.toString(),
                                fractionalPart: "00"
                            }
                        }
                    }
                    // ===============
                    let data = {
                        cardNumber: this.state.modalCardObj?.organizedCardNumber,
                        rrn: cardPinCodeUnblockResponse.rrn,
                        feeAmount: organizedFeeAmount,
                        billingDate: cardPinCodeUnblockResponse.billingDate,
                        pinUnblockAmount: cardPinCodeUnblockResponse.feeAmount
                    };
                    this.setState({ successModal: true, successModalData: data, isError: false, successTitle: this.props.t('Unblock pin success') });
                }
                else {
                    if (cardPinCodeUnblockResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(cardPinCodeUnblockResponse.status.statusCode));
                    }
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            });
    }

    render() {
        let { cards, accounts, statements, campaigns, currentCard, openCard, openAccount, modalCardObj, sliderModal, slideIndex, isLoading, exchanges, firstCurrency, firstAmount, secondCurrency, firstCurrencyIndex, secondCurrencyIndex, isCash, selected, opens, credits, deposits, signLevel, signCount, successModalData, successModal, unblockPinModalOpen, modalAccountObj, pinUnblockAmount,  visibility, campaignCustId, screenTypeId ,image,campaignId, note } = this.state;
        let { updateAppState, updateDashboardState, alertState, t, logout } = this.props;

        return (
            <Fragment>
                <PinUnblockSuccess history={this.props.history} data={successModalData} open={successModal} close={() => { this.setState({ successModal: false }) }} />
                <YesNoModal onClose={() => this.setState({ unblockPinModalOpen: false })} title={t('Unblock pin content', { pinUnblockAmount: pinUnblockAmount })} open={unblockPinModalOpen} triggerFunc={val => this.unblockConfirm(val)} />
                <News open={sliderModal} setOpen={(value) => { this.setState({ sliderModal: value }) }} slideIndex={slideIndex} campaigns={campaigns} />
                {visibility  &&  <CampaignPopup open={visibility} setOpen={(value) => { this.setState({ visibility: value }) }} {...{screenTypeId, campaignCustId, image,campaignId, note}} /> }
                <CardModal open={openCard} modalCardObj={modalCardObj} updateOpenModal={() => { this.setState({ openCard: false }) }} updateAppState={updateAppState} showCards={this.showCards.bind(this)} />
                <AccountModal open={openAccount} modalAccountObj={modalAccountObj} updateOpenModal={() => { this.setState({ openAccount: false }) }} updateAppState={updateAppState} showAccounts={this.showAccounts} />
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <>
                            <div className="dashboard-news-section">
                                {
                                    campaigns.map((campaign, index) => (
                                        <button className="dashboard-news-section-item" style={{ backgroundImage: `url(${campaign.image})` }} onClick={this.showSlider.bind(this, index)} key={index}></button>
                                    ))
                                }
                            </div>

                            <div className="dashboard-container-main-section" style={{ paddingTop: 0 }}>
                                <div className="dashboard-container-main-section-home-left">
                                    <div className="dashboard-container-main-section-dropdown" >
                                        <Accordion
                                            expanded={opens[0]}
                                            onChange={() => { this.setOpenAcc(0) }}
                                            style={{ backgroundColor: 'none!important', width: '100%', borderBottomLeftRadius: '4px' }}
                                        >
                                            <AccordionSummary aria-controls="panel1a-content" id="2" className={`dashboard-container-main-section-dropdown-accord ${!opens[0] ? 'dashboard-container-main-section-dropdown-accord-disable' : ''}`} >
                                                <button className="dashboard-container-main-section-dropdown-header" >
                                                    <div className="dashboard-container-main-section-dropdown-header-left">
                                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.5 3H11V0.75C11 0.34375 10.6562 0 10.25 0H5.75C5.3125 0 5 0.34375 5 0.75V3H1.5C0.65625 3 0 3.6875 0 4.5V12.5C0 13.3438 0.65625 14 1.5 14H14.5C15.3125 14 16 13.3438 16 12.5V4.5C16 3.6875 15.3125 3 14.5 3ZM6 1H10V3H6V1ZM15 12.5C15 12.7812 14.75 13 14.5 13H1.5C1.21875 13 1 12.7812 1 12.5V8H6V9.25C6 9.6875 6.3125 10 6.75 10H9.25C9.65625 10 10 9.6875 10 9.25V8H15V12.5ZM7 9V8H9V9H7ZM15 7H1V4.5C1 4.25 1.21875 4 1.5 4H14.5C14.75 4 15 4.25 15 4.5V7Z"
                                                                fill={opens[0] ? '#fff' : '#2C81C1'} />
                                                        </svg>

                                                        <h5 style={{ color: !opens[0] ? '#2E3131' : '#fff' }}>{t('accounts')}</h5>
                                                    </div>
                                                    <div className={`dashboard-container-main-section-dropdown-header-right ${opens[0] ? 'dashboard-container-main-section-dropdown-header-icon-deactive' : 'dashboard-container-main-section-dropdown-header-icon-active'}`}>
                                                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.5625 0.945312C7.36719 0.75 7.09375 0.75 6.89844 0.945312L1.11719 6.64844C0.921875 6.84375 0.921875 7.15625 1.11719 7.3125L1.89844 8.09375C2.09375 8.28906 2.36719 8.28906 2.5625 8.09375L7.25 3.48438L11.8984 8.09375C12.0938 8.28906 12.4062 8.28906 12.5625 8.09375L13.3438 7.3125C13.5391 7.15625 13.5391 6.84375 13.3438 6.64844L7.5625 0.945312Z"
                                                                fill={opens[0] ? '#fff' : '#2E3131'} />
                                                        </svg>

                                                    </div>
                                                </button>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="dashboard-finance-section-cards-section-table-section" style={{ width: '100%' }}>
                                                    {
                                                        !accounts || accounts.length === 0 &&
                                                        <div className="dashboard-finance-section-invisible-cards-section">
                                                            <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                            <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have an active account.')}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody>
                                                            {
                                                                accounts.map((account, index) =>
                                                                    <Fragment key={index}>
                                                                        <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <img src={accountIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <Link to={"/dashboard/finance/accounts/" + account.accountId} className="dashboard-finance-section-cards-section-cell-content">{account.accountName}</Link>
                                                                                    <Link to={"/dashboard/finance/accounts/" + account.accountId} className="dashboard-finance-section-cards-section-cell-title">{account.iban}</Link>
                                                                                </div>

                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + account.currName.toLowerCase()}>{account.currencyIcon}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{account.organizedCurrentBalance.wholePart}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{account.organizedCurrentBalance.fractionalPart}</span>
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('balance')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-button-group">
                                                                                    <Dropdown
                                                                                        dropdownItems={[
                                                                                            { title: this.props.t('extract'), url: "/dashboard/finance/accounts/extract/" + account.accountId },
                                                                                            { title: this.props.t('details') },
                                                                                            { title: this.props.t('change name') },
                                                                                        ]}
                                                                                        dropdownItemHandler={(i) => this.dropdownAccountItemHandler(i, account)}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>

                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    <div className="dashboard-container-main-section-dropdown" >
                                        <Accordion
                                            expanded={opens[1]}
                                            onChange={() => { this.setOpenAcc(1) }}
                                            style={{ backgroundColor: 'none!important', width: '100%', borderBottomLeftRadius: '4px' }}
                                        >
                                            <AccordionSummary aria-controls="panel1a-content" id="2" className={`dashboard-container-main-section-dropdown-accord ${!opens[1] ? 'dashboard-container-main-section-dropdown-accord-disable' : ''}`} >
                                                <button className="dashboard-container-main-section-dropdown-header" >
                                                    <div className="dashboard-container-main-section-dropdown-header-left">
                                                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.6667 0H1.33333C0.583333 0 0 0.589286 0 1.28571V10.7143C0 11.4375 0.583333 12 1.33333 12H14.6667C15.3889 12 16 11.4375 16 10.7143V1.28571C16 0.589286 15.3889 0 14.6667 0ZM1.33333 0.857143H14.6667C14.8889 0.857143 15.1111 1.07143 15.1111 1.28571V2.57143H0.888889V1.28571C0.888889 1.07143 1.08333 0.857143 1.33333 0.857143ZM14.6667 11.1429H1.33333C1.08333 11.1429 0.888889 10.9554 0.888889 10.7143V5.14286H15.1111V10.7143C15.1111 10.9554 14.8889 11.1429 14.6667 11.1429ZM5.33333 8.89286C5.33333 8.73214 5.16667 8.57143 5 8.57143H3C2.80556 8.57143 2.66667 8.73214 2.66667 8.89286V9.10714C2.66667 9.29464 2.80556 9.42857 3 9.42857H5C5.16667 9.42857 5.33333 9.29464 5.33333 9.10714V8.89286ZM10.6667 8.89286C10.6667 8.73214 10.5 8.57143 10.3333 8.57143H6.55556C6.36111 8.57143 6.22222 8.73214 6.22222 8.89286V9.10714C6.22222 9.29464 6.36111 9.42857 6.55556 9.42857H10.3333C10.5 9.42857 10.6667 9.29464 10.6667 9.10714V8.89286Z"
                                                                fill={opens[1] ? '#fff' : '#2C81C1'} />
                                                        </svg>


                                                        <h5 style={{ color: !opens[1] ? '#2E3131' : '#fff' }}>{t('cards')}</h5>
                                                    </div>
                                                    <div className={`dashboard-container-main-section-dropdown-header-right ${opens[1] ? 'dashboard-container-main-section-dropdown-header-icon-deactive' : 'dashboard-container-main-section-dropdown-header-icon-active'}`}>
                                                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.5625 0.945312C7.36719 0.75 7.09375 0.75 6.89844 0.945312L1.11719 6.64844C0.921875 6.84375 0.921875 7.15625 1.11719 7.3125L1.89844 8.09375C2.09375 8.28906 2.36719 8.28906 2.5625 8.09375L7.25 3.48438L11.8984 8.09375C12.0938 8.28906 12.4062 8.28906 12.5625 8.09375L13.3438 7.3125C13.5391 7.15625 13.5391 6.84375 13.3438 6.64844L7.5625 0.945312Z"
                                                                fill={opens[1] ? '#fff' : '#2E3131'} />
                                                        </svg>

                                                    </div>
                                                </button>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="dashboard-finance-section-cards-section-table-section" style={{ width: '100%' }}>
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody>
                                                            {
                                                                !cards || cards.length === 0 &&
                                                                <div className="dashboard-finance-section-invisible-cards-section">
                                                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have an active card.')}</p>
                                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('if you want a new card, please order it.')}</p>
                                                                    </div>
                                                                    <Link to="/dashboard/orders/plastic-card" className="dashboard-finance-section-invisible-cards-section-button">{t('ORDER A NEW CARD')}</Link>
                                                                </div>
                                                            }
                                                            {
                                                                cards.map((card, index) =>
                                                                    <Fragment key={index}>
                                                                        <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <img src={cardIcon} className="dashboard-finance-section-cards-section-cell-icon" />

                                                                                {/* card.cardStatus === 1 ?  */}
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <Link to={"/dashboard/finance/cards/" + card.cardId} className="dashboard-finance-section-cards-section-cell-content">{card.organizedCardNumber}</Link>
                                                                                   
                                                                                    <Link to={"/dashboard/finance/cards/limit/" + card.cardId} className="dashboard-finance-section-cards-section-cell-title">limitasdf</Link>
                                                                                </div>

                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + card.currency.toLowerCase()}>{card.currencyIcon}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{card.organizedCurrentBalance.wholePart}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{card.organizedCurrentBalance.fractionalPart}</span>
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('balance')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">{card.organizedExpiryDate.month}/{card.organizedExpiryDate.year}</p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('it is reliable')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    {
                                                                                        (card.cardStatus === 1) ?
                                                                                            <span>{t('active')}</span>
                                                                                            :
                                                                                            <span>{t('blocked')}</span>
                                                                                    }
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t("Status")}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-button-group">
                                                                                    {
                                                                                        (card.cardStatus === 1) ?
                                                                                            <Dropdown
                                                                                                dropdownItems={[
                                                                                                    { title: this.props.t('extract'), url: '/dashboard/finance/cards/extract/' + card.cardId },
                                                                                                    { title: this.props.t('details'), url: '/dashboard/finance/cards/' + card.cardId },
                                                                                                    { title: this.props.t('block'), },
                                                                                                    // { title: this.props.t('Unblock pin') },
                                                                                                    {title: this.props.t("pin_settings"), url:`/dashboard/finance/cards/pin/settings/${card?.cardId}`},
                                                                                                    { title: this.props.t("Limits management"), url: `/dashboard/finance/cards/limits/${card?.currency}/${card.cardId}` },
                                                                                                ]}
                                                                                                dropdownItemHandler={(i) => this.dropdownItemHandler(i, card)} />
                                                                                            :
                                                                                            <Dropdown
                                                                                                dropdownItems={[
                                                                                                    { title: this.props.t('extract'), disable: true },
                                                                                                    { title: this.props.t('details'), disable: true },
                                                                                                    { title: this.props.t('unblock'), },
                                                                                                    // { title: this.props.t('Unblock pin'), disable: true },
                                                                                                    {title: this.props.t("pin_settings"), disable: true},
                                                                                                    { title: this.props.t("Limits management"), disable: true},
                                                                                                ]}
                                                                                                dropdownItemHandler={(i) => this.dropdownItemHandler(i, card)} />
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    <div className="dashboard-container-main-section-dropdown" >
                                        <Accordion
                                            expanded={opens[2]}
                                            onChange={() => { this.setOpenAcc(2) }}
                                            style={{ backgroundColor: 'none!important', width: '100%', borderBottomLeftRadius: '4px' }}
                                        >
                                            <AccordionSummary aria-controls="panel1a-content" id="2" className={`dashboard-container-main-section-dropdown-accord ${!opens[2] ? 'dashboard-container-main-section-dropdown-accord-disable' : ''}`} >
                                                <button className="dashboard-container-main-section-dropdown-header" >
                                                    <div className="dashboard-container-main-section-dropdown-header-left">

                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.44264 6.85714C5.3355 6.85714 6.87121 5.32143 6.87121 3.42857C6.87121 1.53571 5.3355 0 3.44264 0C1.54978 0 0.014067 1.53571 0.014067 3.42857C0.014067 5.32143 1.54978 6.85714 3.44264 6.85714ZM3.44264 1.71429C4.37121 1.71429 5.15692 2.5 5.15692 3.42857C5.15692 4.39286 4.37121 5.14286 3.44264 5.14286C2.47835 5.14286 1.72835 4.39286 1.72835 3.42857C1.72835 2.5 2.47835 1.71429 3.44264 1.71429ZM10.2998 9.14286C8.40692 9.14286 6.87121 10.6786 6.87121 12.5714C6.87121 14.4643 8.40692 16 10.2998 16C12.1926 16 13.7284 14.4643 13.7284 12.5714C13.7284 10.6786 12.1926 9.14286 10.2998 9.14286ZM10.2998 14.2857C9.33549 14.2857 8.58549 13.5357 8.58549 12.5714C8.58549 11.6429 9.33549 10.8571 10.2998 10.8571C11.2284 10.8571 12.0141 11.6429 12.0141 12.5714C12.0141 13.5357 11.2284 14.2857 10.2998 14.2857ZM13.6212 0.678571C13.8355 0.392857 13.6212 0 13.2998 0H12.0141C11.9069 0 11.7641 0.0714286 11.6926 0.178571L0.0854956 15.3571C-0.12879 15.6429 0.0854956 16 0.442638 16H1.69264C1.8355 16 1.94264 15.9643 2.04978 15.8214L13.6212 0.678571Z"
                                                            fill={opens[2] ? '#fff' : '#2C81C1'}></path></svg>


                                                        <h5 style={{ color: !opens[2] ? '#2E3131' : '#fff' }}>{t('loans')}</h5>
                                                    </div>
                                                    <div className={`dashboard-container-main-section-dropdown-header-right ${opens[2] ? 'dashboard-container-main-section-dropdown-header-icon-deactive' : 'dashboard-container-main-section-dropdown-header-icon-active'}`}>
                                                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.5625 0.945312C7.36719 0.75 7.09375 0.75 6.89844 0.945312L1.11719 6.64844C0.921875 6.84375 0.921875 7.15625 1.11719 7.3125L1.89844 8.09375C2.09375 8.28906 2.36719 8.28906 2.5625 8.09375L7.25 3.48438L11.8984 8.09375C12.0938 8.28906 12.4062 8.28906 12.5625 8.09375L13.3438 7.3125C13.5391 7.15625 13.5391 6.84375 13.3438 6.64844L7.5625 0.945312Z"
                                                                fill={opens[2] ? '#fff' : '#2E3131'} />
                                                        </svg>

                                                    </div>
                                                </button>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="dashboard-finance-section-cards-section-table-section" style={{ width: '100%' }}>
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody className="dashboard-finance-section-cards-section-tbody">
                                                            {
                                                                !credits || credits.length === 0 &&
                                                                <div className="dashboard-finance-section-invisible-cards-section">
                                                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have any active credit.')}</p>
                                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('please order a new loan.')}</p>
                                                                    </div>
                                                                    <Link to="/dashboard/orders/credit" className="dashboard-finance-section-invisible-cards-section-button">{t('ORDER A CREDIT')}</Link>
                                                                </div>
                                                            }
                                                            {
                                                                credits.map((credit, index) =>

                                                                    <Fragment key={index}>
                                                                        <tr className="dashboard-finance-section-cards-section-row">
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <img src={creditIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <Link to={"/dashboard/finance/credits/" + credit.id} className="dashboard-finance-section-cards-section-cell-content">{credit.loanType}</Link>
                                                                                    <Link to={"/dashboard/finance/credits/" + credit.id} className="dashboard-finance-section-cards-section-cell-title">{credit.iban}</Link>
                                                                                </div>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">{credit.annualInterestRate}%</p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('interest rate')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedLoanAmount.wholePart}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedLoanAmount.fractionalPart}</span>
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('loan amount')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + credit.currency.toLowerCase()}>{credit.currencyIcon}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-whole-part">{credit.organizedRestAmount.wholePart}</span>
                                                                                <span className="dashboard-finance-section-cards-section-amount-fractional-part">{credit.organizedRestAmount.fractionalPart}</span>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('total debt')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">{credit.currency}</p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('currency')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-button-group">
                                                                                    <Dropdown dropdownItems={[
                                                                                        { title: this.props.t('make a payment'), url: `/dashboard/finance/credits/pay/${credit.id}` },
                                                                                        { title: this.props.t('payment schedule') },
                                                                                        { title: this.props.t('payments') },
                                                                                    ]}
                                                                                        dropdownItemHandler={(i) => this.dropdownLoanItemHandler(i, credit.id)} />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    <div className="dashboard-container-main-section-dropdown" >
                                        <Accordion
                                            expanded={opens[3]}
                                            onChange={() => { this.setOpenAcc(3) }}
                                            style={{ backgroundColor: 'none!important', width: '100%', borderBottomLeftRadius: '4px' }}
                                        >
                                            <AccordionSummary aria-controls="panel1a-content" id="2" className={`dashboard-container-main-section-dropdown-accord ${!opens[3] ? 'dashboard-container-main-section-dropdown-accord-disable' : ''}`} >
                                                <button className="dashboard-container-main-section-dropdown-header" >
                                                    <div className="dashboard-container-main-section-dropdown-header-left">
                                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 14C8.53751 14 11 12.8813 11 11.5V10.9875C13.8031 10.8719 16 9.80313 16 8.5V2.5C16 1.34375 14.0594 0 10.5 0C8.98126 0 7.60626 0.28125 6.60938 0.731262L6.60523 0.73303C6.51167 0.772617 5 1.41226 5 2.5V3.02188C2.37811 3.13126 0 4.10626 0 5.5V11.5C0 12.8813 2.46249 14 5.5 14ZM9.95799 3.97852L9.95798 3.97852L9.84686 3.97186C8.69375 3.29376 7.19061 3.09064 6.23437 3.03436C6.0375 2.86874 5.91876 2.69061 5.91876 2.5C5.91876 1.67187 7.97186 0.999999 10.5031 0.999999C13.0344 0.999999 15.0875 1.67187 15.0875 2.5C15.0875 3.32812 13.0312 4 10.5 4C10.3112 4 10.1353 3.9893 9.95799 3.97852ZM11 5.5V6.97501C13.5906 6.85626 15 5.90936 15 5.5V3.93439C14.0719 4.53751 12.5781 4.9375 10.8719 4.99063C10.9469 5.15625 11 5.32501 11 5.5ZM5.5 7C2.96875 7 0.915614 6.32813 0.915614 5.5C0.915614 4.67188 2.96875 4 5.5 4C8.03125 4 10.0844 4.67188 10.0844 5.5C10.0844 6.32813 8.03125 7 5.5 7ZM1.00001 8.5C1.00001 8.93439 2.57813 10 5.50001 10C8.42188 10 10 8.93439 10 8.5V6.93439C9.00626 7.57812 7.36249 8 5.50001 8C3.63752 8 1.99375 7.57812 1.00001 6.93439V8.5ZM11 9.97812V7.97501C12.65 7.90625 14.0969 7.51874 15 6.93439V8.5C15 8.90936 13.5938 9.86249 11 9.97812ZM1.00001 11.5C1.00001 11.9344 2.57813 13 5.50001 13C8.42188 13 10 11.9344 10 11.5V9.93438C9.00626 10.5781 7.36249 11 5.50001 11C3.63752 11 1.99375 10.5781 1.00001 9.93438V11.5Z"
                                                                fill={opens[3] ? '#fff' : '#2C81C1'} />
                                                        </svg>

                                                        <h5 style={{ color: !opens[3] ? '#2E3131' : '#fff' }}>{t('deposits')}</h5>
                                                    </div>
                                                    <div className={`dashboard-container-main-section-dropdown-header-right ${opens[3] ? 'dashboard-container-main-section-dropdown-header-icon-deactive' : 'dashboard-container-main-section-dropdown-header-icon-active'}`}>
                                                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.5625 0.945312C7.36719 0.75 7.09375 0.75 6.89844 0.945312L1.11719 6.64844C0.921875 6.84375 0.921875 7.15625 1.11719 7.3125L1.89844 8.09375C2.09375 8.28906 2.36719 8.28906 2.5625 8.09375L7.25 3.48438L11.8984 8.09375C12.0938 8.28906 12.4062 8.28906 12.5625 8.09375L13.3438 7.3125C13.5391 7.15625 13.5391 6.84375 13.3438 6.64844L7.5625 0.945312Z"
                                                                fill={opens[3] ? '#fff' : '#2E3131'} />
                                                        </svg>

                                                    </div>
                                                </button>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="dashboard-finance-section-cards-section-table-section" style={{ width: '100%' }}>
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody className="dashboard-finance-section-cards-section-tbody">
                                                            {
                                                                !deposits || deposits.length === 0 &&
                                                                <div className="dashboard-finance-section-invisible-cards-section">
                                                                    <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" />
                                                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('you do not currently have any active deposits.')}</p>
                                                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('please order a new deposit.')}</p>
                                                                    </div>
                                                                    <a href={`http://www.turanbank.az/${localStorage.getItem('i18nextLng').toLowerCase()}/pages/369`} target="blank" className="dashboard-finance-section-invisible-cards-section-button">{t('DEPOSIT PRODUCTS')}</a>
                                                                </div>
                                                            }
                                                            {
                                                                deposits.map((deposit, index) =>
                                                                    <Fragment key={index}>
                                                                        <tr className="dashboard-finance-section-cards-section-row">
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <img src={depositIcon} className="dashboard-finance-section-cards-section-cell-icon" />
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <Link to={"/dashboard/finance/deposit/" + deposit.id} className="dashboard-finance-section-cards-section-cell-content">{deposit.name}</Link>
                                                                                    <Link to={"/dashboard/finance/deposit/" + deposit.id} className="dashboard-finance-section-cards-section-cell-title">{t('deposit name')}</Link>
                                                                                </div>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">{deposit.yearPercent}%</p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('deposit interest')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    {/* {deposit.amountParts.wholePart}.{deposit.amountParts.fractionalPart} */}
                                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + deposit.currency.toLowerCase()}>{deposit.currencyIcon}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{deposit.organizedAmount.wholePart}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{deposit.organizedAmount.fractionalPart}</span>

                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('amount of deposit')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">{deposit.branchName}</p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('branch')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">{deposit.currency}</p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{t('currency')}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-button-group">
                                                                                    <Dropdown dropdownItems={[
                                                                                        { title: this.props.t('make a payment'), url: '/' },
                                                                                        { title: this.props.t('payment schedule'), url: '/' },
                                                                                        { title: this.props.t('payments'), url: '/' },
                                                                                        { title: this.props.t('details'), url: '/' }
                                                                                    ]} />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>

                                    <div className="dashboard-transfer-history" style={{ width: '100%', marginTop: '24px' }}>
                                        <div className="dashboard-transfer-history-header">
                                            <NavLink to="/dashboard/home" exact className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('all')}</NavLink>
                                            {
                                                signCount === 2 && localStorage.getItem('compId') &&
                                                <>
                                                    <NavLink to="/dashboard/home/first-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('2nd authorization')}</NavLink>
                                                    <NavLink to="/dashboard/home/second-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('1st authorization')}</NavLink>
                                                </>
                                            }
                                            {
                                                signLevel === 1 && signCount === 1 && localStorage.getItem('compId') &&
                                                <NavLink to="/dashboard/home/first-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('authorization')}</NavLink>
                                            }
                                            {
                                                !localStorage.getItem('compId') &&
                                                <NavLink to="/dashboard/home/first-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('authorization')}</NavLink>
                                            }
                                            <NavLink to="/dashboard/home/waiting" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('Confirmation is expected')}</NavLink>
                                            <NavLink to="/dashboard/home/confirmed" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('Approved')}</NavLink>
                                            <NavLink to="/dashboard/home/refused" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('refusal')}</NavLink>
                                        </div>
                                        <Switch>
                                            {/* <Redirect exact from="/dashboard/home/" to="/dashboard/home/all" /> */}
                                            <Route exact path="/dashboard/home" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/home/confirmed" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/home/waiting" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/home/first-authorization" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/home/second-authorization" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                            <Route path="/dashboard/home/refused" render={(routeProps) => <Statements logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} isLoading={(value) => { this.setState({ isLoading: value }) }} />} />
                                        </Switch>
                                    </div>

                                </div>



                                <div className="dashboard-currency-convertor-calendar-section" style={{ background: 'none' }}>
                                    <div className="dashboard-currency-calendar-section">
                                        <Calendar onSelect={val => this.getExchangeData(val)} />
                                    </div>
                                    <div className="dashboard-exchange-range-section" style={{ width: '100%' }}>
                                        <div className="dashboard-exchange-range-section-backgrond">
                                            <table className="dashboard-exchange-range-section-table">
                                                <thead>
                                                    <tr className="dashboard-exchange-range-section-row dashboard-exchange-range-section-row__head">
                                                        <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CURRENCY')}</th>
                                                        <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('CENTRAL BANK')}</th>

                                                        <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('BUYING')}</th>
                                                        <th className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__head">{t('SELLING')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        exchanges.filter(item => item.currency !== 'AZN').map((exchange, index) => (
                                                            <tr className="dashboard-exchange-range-section-row" key={index}>
                                                                <td className="dashboard-exchange-range-section-cell dashboard-exchange-range-section-cell__currency">
                                                                    <img src={flagFilter(exchange.currency)} alt="" className="dashboard-exchange-range-section-cell-image" />
                                                                    <span>{exchange.currency}</span>
                                                                </td>
                                                                <td className="dashboard-exchange-range-section-cell">{exchange.centralBank && exchange.centralBank.toFixed(4)}</td>
                                                                <td className="dashboard-exchange-range-section-cell">{exchange.cashBuy && exchange.cashBuy.toFixed(4)}</td>
                                                                <td className="dashboard-exchange-range-section-cell">{exchange.cashSell && exchange.cashSell.toFixed(4)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Fragment>
        )
    }
}


export default translate("translation")(Home);