import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { isValid } from 'date-fns';
import { az, enUS, ru } from "date-fns/locale";
import i18n from "i18next";

import empty from './../../../img/last-operations-icon.png';
import currencyFilter from '../../../../../helpers/currencyFilter';
import { request } from "./../../../../../config";
import PaymentModalSucces from '../../../../../copms/Modal/PaymentModalSuccessMpay';
import PaymentModalError from '../../../../../copms/Modal/PaymentModalError';
import TemplateModalSuccess from '../../../../../copms/Modal/TemplateModalSuccess';
import TemplateInputModal from '../../../../../copms/Modal/TemplateInputModal';
import NotFound from '../../../../../copms/NotFound/NotFound';
import Loading from './../../../Loading/Loading';
import { roundNumberWithDecimal } from '../../../../../helpers/payments'
import { getFormatedDate } from '../../../../../helpers/fotmatDate';

class TemplatePay extends Component {
    state = {
        dropdownVisibility: false,
        userInfo: null,
        cards: [],
        selectedCard: 0,
        selectedValue: -1,
        selectedIndex: -1,
        selectedIndex2: -1,
        selectedShow: false,
        checkPayment: true,
        checkPaymentInvoice: [],
        selectedPaymentType: 0,
        selectedPaymentIndex: -1,
        checkPaymentAvans: [],
        allDisabled: false,
        step: 1,
        items: [],
        transactionId: null,
        succesModal: false,
        succesModalData: null,
        errorModal: false,
        errorModalData: null,
        avansIndex: -1,
        invoiceIndex: -1,
        templateModal: false,
        templateModalSuccess: false,
        tempName: '',
        category: null,
        amount: 0,
        paymentReceiverCodeNameIndex: 0,
        paymentReceiverCodeNameShow: false,
        tempHave: false,
        bonusBalance: null,
        bonusBalanceExchangeAzn: null,
        bonusTariffInfoList: {},
        additionalCode: null,
        additionalCodeParamType: null
    }

    // handleDateChange = (date) => {
    //     isValid(date) && this.setState({additionalCode : getFormatedDate(date, "dd.mm.yyy")});
    // }

    showBonusBalance = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusBalanceRequestBody = customerType === "Juridical" ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("bonus/getBonusBalance", getBonusBalanceRequestBody))
            .then(res => res.text())
            .then((getBonusBalanceResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusBalanceResponse = JSON.parse(getBonusBalanceResponseJSON);
                if (getBonusBalanceResponse.status.statusCode !== 1) {
                    if (getBonusBalanceResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusBalanceResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    this.setState({ bonusBalance: getBonusBalanceResponse.bonusBalance }, () => {
                        let convertQuantityToAznRequestBody = customerType === 'Juridical' ? {
                            custId: localStorage.getItem('custId'),
                            compId: localStorage.getItem('compId'),
                            token: localStorage.getItem('token'),
                            lang: localStorage.getItem('i18nextLng').toUpperCase(),
                            quantity: this.state.bonusBalance
                        } : {
                            custId: localStorage.getItem('custId'),
                            token: localStorage.getItem('token'),
                            lang: localStorage.getItem('i18nextLng').toUpperCase(),
                            quantity: this.state.bonusBalance
                        }

                        fetch(request("bonus/convertQuantityToAzn", convertQuantityToAznRequestBody))
                            .then(res => res.text())
                            .then((convertQuantityToAznResponseJSON) => {
                                const JSON = require('true-json-bigint');
                                let convertQuantityToAznResponse = JSON.parse(convertQuantityToAznResponseJSON);
                                if (convertQuantityToAznResponse.status.statusCode !== 1) {
                                    if (convertQuantityToAznResponse.status.statusCode === 200) {
                                        this.props.logout();
                                    } else {
                                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(convertQuantityToAznResponse.status.statusCode));
                                        // this.setState({ bonusBalanceExchangeAzn: convertQuantityToAznResponse.exchangeAzn ? convertQuantityToAznResponse.exchangeAzn.toFixed(2) : '0.00' });
                                        this.setState({ bonusBalanceExchangeAzn: convertQuantityToAznResponse.exchangeAzn ? roundNumberWithDecimal(convertQuantityToAznResponse.exchangeAzn, 2) : '0.00' });
                                        this.props.updateAppState('isLoadingTransparentBackground', false);
                                    }
                                }
                                else {
                                    // this.setState({ bonusBalanceExchangeAzn: convertQuantityToAznResponse.exchangeAzn ? convertQuantityToAznResponse.exchangeAzn.toFixed(2) : '0.00' });
                                    this.setState({ bonusBalanceExchangeAzn: convertQuantityToAznResponse.exchangeAzn ? roundNumberWithDecimal(convertQuantityToAznResponse.exchangeAzn, 2) : '0.00' });
                                    this.props.updateAppState('isLoadingTransparentBackground', false);
                                }
                            },
                                (error) => {
                                    this.props.history.push("/technical-break");
                                });
                    });
                }
            },
                (error) => {

                    this.props.history.push("/technical-break");
                });
    }

    getBonusTariffInfoList = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBonusTariffInfoListRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }

        fetch(request("bonus/getBonusTariffInfoList", getBonusTariffInfoListRequestBody))
            .then(res => res.text())
            .then((getBonusTariffInfoListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBonusTariffInfoListResponse = JSON.parse(getBonusTariffInfoListResponseJSON);
                if (getBonusTariffInfoListResponse.status.statusCode !== 1) {
                    if (getBonusTariffInfoListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBonusTariffInfoListResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ bonusTariffInfoList: getBonusTariffInfoListResponse.bonusTariffInfoList?.find(bonus => bonus.status === 0) });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentWillMount() {
        this.setState({ isLoading: true });
        this.getBonusTariffInfoList();
        this.showBonusBalance();
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        let getMerchantCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            merchantId: this.props.match.params.merchant,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            merchantId: this.props.match.params.merchant,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }


        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("pg/getCategoryList" + customerType + 'v1', getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCategoryListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCategoryListCustomerResponse = JSON.parse(getCategoryListCustomerResponseJSON);
                if (getCategoryListCustomerResponse.status.statusCode !== 1) {
                    if (getCategoryListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {

                    this.setState({ category: getCategoryListCustomerResponse.categoryList.find(el => el.categoryId == this.props.match.params.id) }, () => {
                        fetch(request("pg/getMerchantParams" + customerType, getMerchantCustomerRequestBody))
                            .then(res => res.text())
                            .then((getMerchantCustomerResponseJSON) => {

                                const JSON = require('true-json-bigint');
                                let getMerchantCustomerResponse = JSON.parse(getMerchantCustomerResponseJSON);
                                // let getMerchantCustomerResponse = {
                //     merchantParamList: [
                //         [
                //             {
                //                 paramId: 2946,
                //                 paramName: "id1",
                //                 paramDisplayName: "Müqavilə nömrəsi",
                //                 paramType: "text",
                //                 format: "string",
                //                 maxLen: 1000,
                //                 merchantDescName: "id1",
                //                 merchantDescId: 2433,
                //                 merchantDescValue: "Müştərinin ad və soyadı",
                //                 providerId: 5,
                //                 merchantDisplayName: "STEP IT Academy",
                //                 paramValue: null,
                //                 paramDesc: null
                //             }
                //         ],
                //         [
                //             {
                //                 paramI: 2947,
                //                 paramName: "id2",
                //                 paramDisplayName: "Müştərinin ad və soyadı",
                //                 paramType: "text",
                //                 format: "string",
                //                 maxLen: 1000,
                //                 merchantDescName: "id2",
                //                 merchantDescId: 2434,
                //                 merchantDescValue: "Müştərinin ad və soyadı",
                //                 providerId: 5,
                //                 merchantDisplayName: "STEP IT Academy",
                //                 paramValue: null,
                //                 paramDesc: null
                //             }
                //         ],
                //         [
                //             {
                //                 paramI: 2948,
                //                 paramName: "id3",
                //                 paramDisplayName: "Müştərinin ad2 və soyadı2",
                //                 paramType: "text",
                //                 format: "string",
                //                 maxLen: 1000,
                //                 merchantDescName: "id3",
                //                 merchantDescId: 2435,
                //                 merchantDescValue: "Müştərinin ad2 və soyadı2",
                //                 providerId: 5,
                //                 merchantDisplayName: "STEP IT Academy",
                //                 paramValue: null,
                //                 paramDesc: null
                //             },
                //             {
                //                 paramI: 2949,
                //                 paramName: "id4",
                //                 paramDisplayName: "Müqavilə nömrəsi2",
                //                 paramType: "text",
                //                 format: "string",
                //                 maxLen: 1000,
                //                 merchantDescName: "id4",
                //                 merchantDescId: 2436,
                //                 merchantDescValue: "Müqavilə nömrəsi2",
                //                 providerId: 5,
                //                 merchantDisplayName: "STEP IT Academy",
                //                 paramValue: null,
                //                 paramDesc: null
                //             }
                //         ]
                //     ],
                //     status: {
                //         statusCode: 1,
                //         statusMessage: "success"
                //     }
                // }


                                if (getMerchantCustomerResponse.status.statusCode !== 1) {
                                    if (getMerchantCustomerResponse.status.statusCode === 200) {
                                        this.props.logout();
                                    } else {
                                        this.setState({ isLoading: false });
                                    }
                                }
                                else {
                                    var items = [];
                                    var items2 = [];
                                    for (var j = 0; j < getMerchantCustomerResponse.merchantParamList?.length; j++) {
                                        for (var i = 0; i < getMerchantCustomerResponse.merchantParamList[j].length; i++) {
                                            if (items.find(el => el.merchantDescName === getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName)) {
                                                getMerchantCustomerResponse.merchantParamList[j][i].params = [];
                                                getMerchantCustomerResponse.merchantParamList[j][i].isShow = false;
                                                if (getMerchantCustomerResponse.merchantParamList[j][i].paramValue && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== "" && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== ",") {
                                                    var paramArr = getMerchantCustomerResponse.merchantParamList[j][i].paramValue.split(',');
                                                    var paramLabelArr = getMerchantCustomerResponse.merchantParamList[j][i].paramDesc.split(',');
                                                    for (var k = 0; k < paramArr.length - 1; k++) {
                                                        if (k == 0) {
                                                            getMerchantCustomerResponse.merchantParamList[j][i].defaultSelect = paramArr[k];
                                                        }
                                                        getMerchantCustomerResponse.merchantParamList[j][i].params.push({
                                                            value: paramArr[k],
                                                            label: paramLabelArr[k]
                                                        });
                                                    }
                                                }
                                                items[items.findIndex(el => el.merchantDescName === getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName)].elements.push(getMerchantCustomerResponse.merchantParamList[j][i]);
                                            } else {
                                                getMerchantCustomerResponse.merchantParamList[j][i].params = [];
                                                getMerchantCustomerResponse.merchantParamList[j][i].isShow = false;
                                                if (getMerchantCustomerResponse.merchantParamList[j][i].paramValue && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== "" && getMerchantCustomerResponse.merchantParamList[j][i].paramValue !== ",") {
                                                    var paramArr = getMerchantCustomerResponse.merchantParamList[j][i].paramValue.split(',');
                                                    var paramLabelArr = getMerchantCustomerResponse.merchantParamList[j][i].paramDesc.split(',');
                                                    for (var k = 0; k < paramArr.length - 1; k++) {
                                                        if (k == 0) {
                                                            getMerchantCustomerResponse.merchantParamList[j][i].defaultSelect = paramArr[k];
                                                        }
                                                        getMerchantCustomerResponse.merchantParamList[j][i].params.push({
                                                            value: paramArr[k],
                                                            label: paramLabelArr[k]
                                                        });
                                                    }
                                                }
                                                if (getMerchantCustomerResponse.merchantParamList[j].length > 1 || this.state.category?.categoryName == "Mobile") {
                                                    items.push({
                                                        merchantDescName: getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName,
                                                        isShow: false,
                                                        elements: [getMerchantCustomerResponse.merchantParamList[j][i]]
                                                    });
                                                } else {
                                                    items2.push({
                                                        merchantDescName: getMerchantCustomerResponse.merchantParamList[j][i].merchantDescName,
                                                        isShow: false,
                                                        elements: [getMerchantCustomerResponse.merchantParamList[j][i]]
                                                    });
                                                }
                                            }
                                        }
                                    }
                                    this.getTemplate(items, items2)
                                }
                            },
                                (error) => {
                                    this.props.history.push("/technical-break");
                                });
                    })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });


        fetch(request("card/getCardListFor" + customerType + "Customer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);

                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }

                    this.setState({ cards: getCardListForCustomerResponse.cardList, selectedValue: this.props.bonus ? -2 : (getCardListForCustomerResponse.cardList && getCardListForCustomerResponse.cardList.length > 0 && 0) });
                    // this.props.updateAppState('isLoading', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });



    }

    reverseParams(items, items2, description, additionalCode) {
        var resultItems = items;
        var resultItems2 = items2;
        var arr = description.split(';');
        this.setState({ selectedIndex: items?.findIndex(item => item.merchantDescName == arr[0]), selectedIndex2: items2?.findIndex(item => item.merchantDescName == arr[0]) });
        var selectedObjectElements = items?.find(item => item.merchantDescName == arr[0])?.elements;
        var selectedObjectElements2 = items2?.find(item => item.merchantDescName == arr[0])?.elements;

        for (var i = 0; i < arr.length; i++) {
            if (i !== 0) {
                var inArr = arr[i].split(':');
                var element = selectedObjectElements?.find(el => el.paramName == inArr[0]);
                var params = element?.params;
                if (params?.length > 0) {
                    for (var j = 0; j < params.length; j++) {

                        if (params)
                            if (inArr[1].substr(0, params[j].value.length) == params[j].value) {
                                element.defaultSelect = params[j].value;
                                element.defaultInput = inArr[1].substring(params[j].value.length, inArr[1].length);
                            }
                    }
                    element = resultItems[items.findIndex(item => item.merchantDescName == arr[0])]?.elements[selectedObjectElements.findIndex(el => el.paramName == inArr[0])];
                } else {
                    if (element) {
                        element.defaultInput = inArr[1];
                    }
                }
            }
        }

        // Split testValue into key-value pairs
        // const keyValuePairs = description.split(';');

        // Loop through the items2 array and set defaultInput based on the key-value pairs
        items2.forEach((item) => {
            const { merchantDescName, elements } = item;
            const key = `${merchantDescName}:`;

            // Find the corresponding element
            const element = elements[0];

            // Find the key-value pair in the keyValuePairs array
            const keyValue = arr.find((pair) => pair.startsWith(key));

            if (keyValue) {
                // Extract the value after the key
                const value = keyValue.slice(key.length);

                // Set the value as defaultInput in the element
                element.defaultInput = value;
            }
        });

        this.setState({
            items: items,
            items2: items2,
            additionalCode: additionalCode
        }, () => this.onSubmitForm())
    }

    getTemplate(items, items2) {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.temp,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.props.match.params.temp,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("pg/paymentTempById" + customerType, getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((paymentTempListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let paymentTempListCustomerResponse = JSON.parse(paymentTempListCustomerResponseJSON);

                if (paymentTempListCustomerResponse.status.statusCode !== 1 && paymentTempListCustomerResponse.status.statusCode !== '????????????') {
                    if (paymentTempListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                    }
                }
                else {

                    this.setState({
                        // amount: paymentTempListCustomerResponse.amount, 
                        tempHave: true
                    })
                    this.reverseParams(items, items2, paymentTempListCustomerResponse?.description, paymentTempListCustomerResponse?.additionalCode);
                    // this.props.updateAppState('isLoading', false);
                    this.setState({
                        additionalCodeParamType: paymentTempListCustomerResponse?.additionalCodeParamType,
                        additionalCode: paymentTempListCustomerResponse?.additionalCode
                    })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    changeDropdownVisibility = (event) => {
        event.preventDefault();
        if (this.state.step > 2) {
            this.setState({ dropdownVisibility: false });
        } else {
            this.setState({ dropdownVisibility: !this.state.dropdownVisibility });
        }


    }

    onSelect = (event, val) => {
        event.preventDefault();
        this.setState({ dropdownVisibility: false, selectedValue: val });
    }
    onSelectPaymentReceiverCodeName = (e, index) => {
        e.preventDefault();
        this.setState({ paymentReceiverCodeNameShow: false, paymentReceiverCodeNameIndex: index });
    }
    onChangeParamsDropdown = (e, index) => {
        e.preventDefault();
        var items = this.state.items;
        if (this.state.step > 1) {
            items[this.state.selectedIndex].elements[index].isShow = false;
        } else {
            items[this.state.selectedIndex].elements[index].isShow = !items[index].elements[index].isShow;
        }
        this.setState({ items: items })
    }
    onSelectParamsDropdown = (e, val, index) => {
        e.preventDefault();
        var items = this.state.items;
        items[this.state.selectedIndex].elements[index].defaultSelect = val;
        items[this.state.selectedIndex].elements[index].isShow = false;
        this.setState({ items: items })
    }

    onChnageInputDropdown = (val, index) => {
        var items = this.state.items;
        items[this.state.selectedIndex].elements[index].defaultInput = val;
        this.setState({ items: items })
    }

    onSubmitForm() {
        // e.preventDefault();
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var items2 = this.state.items2
        var item2 = this.state.items2[this.state.selectedIndex2];
        let inputVal = this.state.inputValue;

        var params = [];
        for (var i = 0; i < item?.elements.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }

        for (var i = 0; i < items2?.length; i++) {
            for (var j = 0; j < items2[i].elements.length; j++) {
                if (items2[i].elements[j].defaultInput) {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: items2[i].elements[j].defaultInput
                    })
                } else {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: items2[i].elements[j].defaultInput,
                        // paramValue: null
                    })
                }
            }
        }

        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            identificationType: item?.merchantDescName
                || item2?.merchantDescName
            ,
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId
                || item2?.elements[0].providerId
            ,
            identificationCode: params,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            identificationType: item?.merchantDescName
                || item2?.merchantDescName
            ,
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId
                || item2?.elements[0].providerId
            ,
            identificationCode: params,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (localStorage.getItem("additionalCodeParamType") !== "null") {
            getCategoryListCustomerRequestBody.additionalCode = getFormatedDate(this.state.selectedDate, "dd.mm.yyyy");
        }
        fetch(request("pg/checkMerchantV1",
            // + (customerType === 'Juridical' ? 'Juridical' : ''), 
            getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        // this.props.updateAppState('isLoading', false);
                        this.setState({ isLoading: false });

                    }
                }
                else {
                    this.setState({ checkPayment: true, invoiceType: getCardListForCustomerResponse.invoice[0]?.invoiceType ? getCardListForCustomerResponse.invoice[0].invoiceType : null, checkPaymentInvoice: getCardListForCustomerResponse.invoice ? getCardListForCustomerResponse.invoice : [], checkPaymentAvans: getCardListForCustomerResponse.avans ? getCardListForCustomerResponse.avans : [], transactionId: getCardListForCustomerResponse.transactionId, step: 2, })
                    // this.props.updateAppState('isLoading', false);
                    this.setState({ isLoading: false });




                }
            });
        localStorage.removeItem("additionalCodeParamType")


    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    pay_process(e) {
        e.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var item2 = this.state.items2[this.state.selectedIndex2];
        var items2 = this.state.items2

        var params = [];
        for (var i = 0; i < item?.elements?.length; i++) {
            if (item?.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }

        for (var i = 0; i < items2?.length; i++) {
            for (var j = 0; j < items2[i].elements.length; j++) {
                if (items2[i].elements[j].defaultInput) {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: items2[i].elements[j].defaultInput
                    })
                } else {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: null
                    })
                }
            }
        }
        let dataList = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        let getCategoryListCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            transactionId: this.state.transactionId,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            reqPaymentDataList: [{
                amount: parseFloat(dataList.amount),
                transactionNumber: dataList.transactionNumber,
                parentTrNumber: dataList.transactionNumber,
                paymentReceiver: dataList.paymentReceiverCodeName ? (this.state.paymentReceiverCodeNameIndex && dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex]) ? dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].code : null : null,
                paymentReceiverDescription: dataList.paymentReceiverCodeName ? (this.state.paymentReceiverCodeNameIndex && dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex]) ? dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].name : dataList.paymentReceiverDescription : dataList.paymentReceiverDescription,
                feeCalculationMethod: dataList.feeCalculationMethod,
                feeAmount: this.commissionCalculator(),
                subscriberName: dataList.fullName,
                serviceName: dataList.serviceName,
                budgetCode: dataList.serviceCode,
                invoiceNumber: dataList.invoiceNumber,
                identificationCode: params,
                identificationType: item?.merchantDescName || item2?.merchantDescName,
            }],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            transactionId: this.state.transactionId,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            reqPaymentDataList: [{
                amount: parseFloat(dataList.amount),
                transactionNumber: dataList.transactionNumber,
                parentTrNumber: dataList.transactionNumber,
                paymentReceiver: dataList.paymentReceiverCodeName ? (this.state.paymentReceiverCodeNameIndex && dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex]) ? dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].code : null : null,
                paymentReceiverDescription: dataList.paymentReceiverCodeName ? (this.state.paymentReceiverCodeNameIndex && dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex]) ? dataList.paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].name : dataList.paymentReceiverDescription : dataList.paymentReceiverDescription,
                feeCalculationMethod: dataList.feeCalculationMethod,
                feeAmount: this.commissionCalculator(),
                subscriberName: dataList.fullName,
                serviceName: dataList.serviceName,
                budgetCode: dataList.serviceCode,
                invoiceNumber: dataList.invoiceNumber,
                identificationCode: params,
                identificationType: item?.merchantDescName || item2?.merchantDescName,
            }],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (this.state.selectedValue >= 0) {
            getCategoryListCustomerRequestBody.cardId = this.state.cards[this.state.selectedValue].cardId;
        }
        if (this.state.checkPaymentInvoice) {
            getCategoryListCustomerRequestBody.reqPaymentDataList[0].invoiceType = this.state.invoiceType
        }
        fetch(request("pg/pay" + customerType + (this.state.selectedValue === -2 ? 'WithBonusv1' : 'V1'), getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {

                    this.setState({ checkPayment: true, succesModal: true, succesModalData: this.getSuccessData(getCardListForCustomerResponse.receiptNumber, getCardListForCustomerResponse.billingDate, getCardListForCustomerResponse.amount, getCardListForCustomerResponse.feeAmount, getCardListForCustomerResponse.earnedBonus, getCardListForCustomerResponse.payBonus, getCardListForCustomerResponse?.billingStateId), step: 2, })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });

    }

    pay_processWithChild(e) {
        e.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var item2 = this.state.items2[this.state.selectedIndex2];
        var items2 = this.state.items2

        var params = [];
        for (var i = 0; i < item?.elements?.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }
        for (var i = 0; i < items2?.length; i++) {
            for (var j = 0; j < items2[i].elements.length; j++) {
                if (items2[i].elements[j].defaultInput) {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: items2[i].elements[j].defaultInput
                    })
                } else {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: null
                    })
                }
            }
        }
        let dataList = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        var childArr = [];
        if (this.state.selectedPaymentType === 1) {
            for (var i = 0; i < dataList.respChildAvansList.length; i++) {
                childArr.push({
                    amount: parseFloat(dataList.respChildAvansList[i].amount),
                    transactionNumber: dataList.respChildAvansList[i].transactionNumber,
                    parentTrNumber: dataList.transactionNumber,
                    paymentReceiver: dataList.respChildAvansList[i].paymentReceiverCodeName ? dataList.respChildAvansList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex] ? dataList.respChildAvansList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].code : null : null,
                    paymentReceiverDescription: dataList.respChildAvansList[i].paymentReceiverCodeName ? dataList.respChildAvansList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex] ? dataList.respChildAvansList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].name : dataList.respChildAvansList[i].paymentReceiverDescription : dataList.respChildAvansList[i].paymentReceiverDescription,
                    feeCalculationMethod: dataList.respChildAvansList[i].feeCalculationMethod,
                    feeAmount: this.commissionChildCalculator(i),
                    subscriberName: dataList.respChildAvansList[i].fullName,
                    serviceName: dataList.respChildAvansList[i].serviceName,
                    budgetCode: dataList.respChildAvansList[i].serviceCode,
                    invoiceNumber: dataList.respChildAvansList[i].invoiceNumber,
                    identificationCode: params,
                    identificationType: item?.merchantDescName || item2?.merchantDescName,
                })
            }
        } else {
            for (var i = 0; i < dataList.respChildInvoiceList.length; i++) {
                childArr.push({
                    amount: parseFloat(dataList.respChildInvoiceList[i].amount),
                    transactionNumber: dataList.respChildInvoiceList[i].transactionNumber,
                    parentTrNumber: dataList.transactionNumber,
                    paymentReceiver: dataList.respChildInvoiceList[i].paymentReceiverCodeName ? dataList.respChildInvoiceList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex] ? dataList.respChildInvoiceList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].code : null : null,
                    paymentReceiverDescription: dataList.respChildInvoiceList[i].paymentReceiverCodeName ? dataList.respChildInvoiceList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex] ? dataList.respChildInvoiceList[i].paymentReceiverCodeName[this.state.paymentReceiverCodeNameIndex].name : dataList.respChildInvoiceList[i].paymentReceiverDescription : dataList.respChildInvoiceList[i].paymentReceiverDescription,
                    feeCalculationMethod: dataList.respChildInvoiceList[i].feeCalculationMethod,
                    feeAmount: this.commissionChildCalculator(i),
                    subscriberName: dataList.respChildInvoiceList[i].fullName,
                    serviceName: dataList.respChildInvoiceList[i].serviceName,
                    budgetCode: dataList.respChildInvoiceList[i].serviceCode,
                    invoiceNumber: dataList.respChildInvoiceList[i].invoiceNumber,
                    identificationCode: params,
                    identificationType: item?.merchantDescName || item2?.merchantDescName,
                    invoiceType: this.state.invoiceType
                })
            }
        }

        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            transactionId: this.state.transactionId,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            reqPaymentDataList: childArr,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            transactionId: this.state.transactionId,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            reqPaymentDataList: childArr,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (this.state.selectedValue >= 0) {
            getCategoryListCustomerRequestBody.cardId = this.state.cards[this.state.selectedValue].cardId;
        }
        fetch(request("pg/pay" + customerType + (this.state.selectedValue === -2 ? 'WithBonusv1' : 'V1'), getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {

                    this.setState({ checkPayment: true, succesModal: true, succesModalData: this.getSuccessData(getCardListForCustomerResponse.receiptNumber, getCardListForCustomerResponse.billingDate, getCardListForCustomerResponse.amount, getCardListForCustomerResponse.feeAmount, getCardListForCustomerResponse.earnedBonus, getCardListForCustomerResponse.payBonus, getCardListForCustomerResponse?.billingStateId), step: 2, })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    createTemplate() {

        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var item2 = this.state.items2[this.state.selectedIndex2];
        var items2 = this.state.items2

        var params = [];
        for (var i = 0; i < item?.elements?.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }
        for (var i = 0; i < items2?.length; i++) {
            for (var j = 0; j < items2[i].elements.length; j++) {
                if (items2[i].elements[j].defaultInput) {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: items2[i].elements[j].defaultInput,
                        paramLabel: items2[i].elements[j].merchantDescValue,
                    })
                } else {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: null,
                        paramLabel: items2[i].elements[j].merchantDescValue,
                    })
                }
            }
        }
        let dataList = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        let mData = this.state.succesModalData;
        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            tempName: this.state.tempName,
            identificationCode: params,
            identificationType: item?.merchantDescName || item2?.merchantDescName,
            // additionalCode: getFormatedDate(this.state.selectedDate, "dd.mm.yyyy"),

            amount: parseFloat(dataList.amount),
            reqPaymentDataList: [{
                amount: parseFloat(dataList.amount),
                transactionNumber: dataList.transactionNumber,
                parentTrNumber: dataList.transactionNumber,
                paymentReceiver: dataList.paymentReceiver,
                paymentReceiverDescription: dataList.paymentReceiverDescription,
                feeCalculationMethod: dataList.feeCalculationMethod,
                feeAmount: this.commissionCalculator(),
                subscriberName: dataList.fullName,
                serviceName: dataList.serviceName,
                budgetCode: dataList.serviceCode,
                invoiceNumber: dataList.invoiceNumber,
                identificationCode: params,
                identificationType: item?.merchantDescName || item2?.merchantDescName,
            }],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            transactionId: this.state.transactionId,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            tempName: this.state.tempName,
            identificationCode: params,
            identificationType: item?.merchantDescName || item2?.merchantDescName,
            // additionalCode: getFormatedDate(this.state.selectedDate, "dd.mm.yyyy"),

            amount: parseFloat(dataList.amount),
            reqPaymentDataList: [{
                amount: parseFloat(dataList.amount),
                transactionNumber: dataList.transactionNumber,
                parentTrNumber: dataList.transactionNumber,
                paymentReceiver: dataList.paymentReceiver,
                paymentReceiverDescription: dataList.paymentReceiverDescription,
                feeCalculationMethod: dataList.feeCalculationMethod,
                feeAmount: this.commissionCalculator(),
                subscriberName: dataList.fullName,
                serviceName: dataList.serviceName,
                budgetCode: dataList.serviceCode,
                invoiceNumber: dataList.invoiceNumber,
                identificationCode: params,
                identificationType: item?.merchantDescName || item2?.merchantDescName,
            }],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (this.state.selectedValue >= 0) {
            getCategoryListCustomerRequestBody.currency = this.state.cards[this.state.selectedValue].currency;
        }
        // if (localStorage.getItem("additionalCodeParamType") !== "null") {
        //     getCategoryListCustomerRequestBody.additionalCode = getFormatedDate(this.state.selectedDate, "dd.mm.yyyy");
        // }

        fetch(request("pg/createPaymentTemp" + customerType, getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {

                    this.setState({ checkPayment: true, succesModal: false, templateModal: false, templateModalSuccess: true, succesModalData: null, step: 2, })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    createTemplateWithChild() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';

        var item = this.state.items[this.state.selectedIndex];
        var item2 = this.state.items2[this.state.selectedIndex2];
        var items2 = this.state.items2;

        var params = [];
        for (var i = 0; i < item?.elements?.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput
                })
            }
        }
        for (var i = 0; i < items2?.length; i++) {
            for (var j = 0; j < items2[i].elements.length; j++) {
                if (items2[i].elements[j].defaultInput) {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: items2[i].elements[j].defaultInput,
                        paramLabel: items2[i].elements[j].merchantDescValue,
                    })
                } else {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: null,
                        paramLabel: items2[i].elements[j].merchantDescValue,
                    })
                }
            }
        }
        let dataList = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        var childArr = [];
        var totalAmout = 0.0;
        for (var i = 0; i < dataList.respChildInvoiceList.length; i++) {
            totalAmout += parseFloat(dataList.respChildInvoiceList[i].amount);
            childArr.push({
                amount: parseFloat(dataList.respChildInvoiceList[i].amount),
                transactionNumber: dataList.respChildInvoiceList[i].transactionNumber,
                parentTrNumber: dataList.transactionNumber,
                paymentReceiver: dataList.respChildInvoiceList[i].paymentReceiver,
                paymentReceiverDescription: dataList.respChildInvoiceList[i].paymentReceiverDescription,
                feeCalculationMethod: dataList.respChildInvoiceList[i].feeCalculationMethod,
                feeAmount: this.commissionChildCalculator(i),
                subscriberName: dataList.respChildInvoiceList[i].fullName,
                serviceName: dataList.respChildInvoiceList[i].serviceName,
                budgetCode: dataList.respChildInvoiceList[i].serviceCode,
                invoiceNumber: dataList.respChildInvoiceList[i].invoiceNumber,
                identificationCode: params,
                identificationType: item?.merchantDescName || item2?.merchantDescName,
            })
        }
        let mData = this.state.succesModalData;
        let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            tempName: this.state.tempName,
            identificationCode: params,
            identificationType: item?.merchantDescName || item2?.merchantDescName,
            reqPaymentDataList: childArr,
            amount: totalAmout,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            categoryId: this.props.match.params.id,
            merchantId: this.props.match.params.merchant,
            providerId: item?.elements[0].providerId || item2?.elements[0].providerId,
            tempName: this.state.tempName,
            identificationCode: params,
            identificationType: item?.merchantDescName || item2?.merchantDescName,
            reqPaymentDataList: childArr,
            amount: totalAmout,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        if (this.state.selectedValue >= 0) {
            getCategoryListCustomerRequestBody.currency = this.state.cards[this.state.selectedValue].currency;
        }
        fetch(request("pg/createPaymentTemp" + customerType, getCategoryListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {

                    this.setState({ checkPayment: true, succesModal: false, templateModal: false, templateModalSuccess: true, succesModalData: null, step: 2, })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }
    paramsList() {
        var item = this.state.items[this.state.selectedIndex];
        var items2 = this.state.items2;
        var params = [];
        for (var i = 0; i < item?.elements?.length; i++) {
            if (item.elements[i].defaultSelect) {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput,
                    paramLabel: item.elements[i].merchantDescValue,
                })
            } else {
                params.push({
                    paramName: item.elements[i].paramName,
                    paramValue: item.elements[i].defaultInput,
                    paramLabel: item.elements[i].merchantDescValue,
                })
            }
        }

        for (var i = 0; i < items2?.length; i++) {
            for (var j = 0; j < items2[i].elements.length; j++) {
                if (items2[i].elements[j].defaultInput) {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: items2[i].elements[j].defaultInput,
                        paramLabel: items2[i].elements[j].merchantDescValue,
                    })
                } else {
                    params.push({
                        paramName: items2[i].elements[j].paramName,
                        paramValue: null,
                        paramLabel: items2[i].elements[j].merchantDescValue,
                    })
                }
            }
        }
        return params;
    }

    getSuccessData(receiptNumber, billingDate, amount, feeAmout, earnedBonus, payBonus, billingStateId) {
        var checkData = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
        let data = {
            receiptNumber: receiptNumber,
            providerName: this.state.items[0]?.elements[0].merchantDisplayName || this.state.items2[0].elements[0].merchantDisplayName,
            abonentName: checkData.fullName,//electedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].fullName : checkPaymentInvoice[selectedPaymentIndex].fullName,
            abonentCode: checkData.payerCode,
            operationDate: billingDate,
            category: this.state.category ? this.state.category.displayName : '',
            amount: this.amountSplit(amount.toString()),
            commission: this.amountSplit(feeAmout.toString()),
            earnedBonus: earnedBonus,
            payBonus: payBonus,
            billingStateId: billingStateId
        }
        if (this.state.selectedValue >= 0) {
            data.cardnumber = this.state.cards[this.state.selectedValue].organizedCardNumber;
            data.currency = this.state.cards[this.state.selectedValue].currency;
        }
        return data;
    }

    commissionCalculator() {
        if (this.state.selectedPaymentType === 1) {
            var avans = this.state.checkPaymentAvans[this.state.selectedPaymentIndex];
            if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 < parseFloat(avans.feeMinAmount)) {
                return roundNumberWithDecimal(parseFloat(avans.feeMinAmount), 2);
            } else if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 > parseFloat(avans.feeMaxAmount)) {

                return roundNumberWithDecimal(parseFloat(avans.feeMaxAmount), 2);
            } else {

                let total = (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100);
                return roundNumberWithDecimal(total, 2)
            }
        } else {
            var invoice = this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
            if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 < parseFloat(invoice.feeMinAmount)) {

                return roundNumberWithDecimal(parseFloat(invoice.feeMinAmount), 2);
            } else if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 > parseFloat(invoice.feeMaxAmount)) {

                return roundNumberWithDecimal(parseFloat(invoice.feeMaxAmount), 2);
            } else {

                let total = (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100);
                return roundNumberWithDecimal(total, 2)
            }
        }
    }

    commissionChildCalculator(index) {
        if (this.state.selectedPaymentType === 1) {
            var avans = this.state.checkPaymentAvans[this.state.selectedPaymentIndex].respChildInvoiceList[index];
            if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 < parseFloat(avans.feeMinAmount)) {

                return roundNumberWithDecimal(parseFloat(avans.feeMinAmount), 2);
            } else if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 > parseFloat(avans.feeMaxAmount)) {

                return roundNumberWithDecimal(parseFloat(avans.feeMaxAmount), 2);
            } else {

                let total = (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100);
                return roundNumberWithDecimal(total, 2);
            }
        } else {
            var invoice = this.state.checkPaymentInvoice[this.state.selectedPaymentIndex].respChildInvoiceList[index];
            if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 < parseFloat(invoice.feeMinAmount)) {

                return roundNumberWithDecimal(parseFloat(invoice.feeMinAmount), 2);
            } else if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 > parseFloat(invoice.feeMaxAmount)) {

                return roundNumberWithDecimal(parseFloat(invoice.feeMaxAmount), 2);
            } else {

                let total = (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100);
                return roundNumberWithDecimal(total, 2)
            }
        }
    }

    childTotalCommision() {
        if (this.state.selectedPaymentType === 1) {
            var childs = this.state.checkPaymentAvans[this.state.selectedPaymentIndex].respChildInvoiceList;
            var total = parseFloat("0.00");
            for (var i = 0; i < childs.length; i++) {
                var avans = childs[i];
                if (parseFloat(avans.amount.toString()) * parseFloat(avans.feePercent.toString()) / 100 < parseFloat(avans.feeMinAmount.toString())) {
                    total = total + parseFloat(avans.feeMinAmount.toString());
                } else if (parseFloat(avans.amount.toString()) * parseFloat(avans.feePercent.toString()) / 100 > parseFloat(avans.feeMaxAmount.toString())) {
                    total = total + parseFloat(avans.feeMaxAmount.toString());
                } else {
                    total = total + (parseFloat(avans.amount.toString()) * parseFloat(avans.feePercent.toString()) / 100);
                }
            }


            return roundNumberWithDecimal(total, 2);
        } else {
            var childs = this.state.checkPaymentInvoice[this.state.selectedPaymentIndex].respChildInvoiceList;
            var total = parseFloat("0.00");
            for (var i = 0; i < childs.length; i++) {
                var invoice = childs[i];
                if (parseFloat(invoice.amount.toString()) * parseFloat(invoice.feePercent.toString()) / 100 < parseFloat(invoice.feeMinAmount.toString())) {
                    total = total + parseFloat(invoice.feeMinAmount.toString());
                } else if (parseFloat(invoice.amount.toString()) * parseFloat(invoice.feePercent.toString()) / 100 > parseFloat(invoice.feeMaxAmount.toString())) {
                    total = total + parseFloat(invoice.feeMaxAmount.toString());
                } else {
                    total = total + (parseFloat(invoice.amount.toString()) * parseFloat(invoice.feePercent.toString()) / 100);
                }
            }


            return roundNumberWithDecimal(total, 2);
        }

    }

    onSubmitTemplate(val) {
        let { checkPaymentAvans, checkPaymentInvoice, selectedPaymentType, selectedPaymentIndex } = this.state;
        this.setState({ tempName: val, }, () => {
            if (selectedPaymentType) {
                if (checkPaymentAvans[selectedPaymentIndex]) {
                    if (checkPaymentAvans[selectedPaymentIndex].respChildInvoiceList) {
                        this.createTemplateWithChild();
                    } else {
                        this.createTemplate();
                    }
                } else {
                    this.createTemplate();
                }
            } else {
                if (checkPaymentInvoice[selectedPaymentIndex]) {
                    if (checkPaymentInvoice[selectedPaymentIndex].respChildInvoiceList) {
                        this.createTemplateWithChild();
                    } else {
                        this.createTemplate();
                    }
                } else {
                    this.createTemplate();
                }
            }
        })
    }

    showAvansInfo = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let showAvansInfoRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            abonCode: this.state.checkPaymentAvans[this.state.avansIndex].abonCode,
            merchantCode: this.state.checkPaymentAvans[this.state.avansIndex].merchantCode,
            serviceCode: this.state.checkPaymentAvans[this.state.avansIndex].serviceCode,
            subType: this.state.checkPaymentAvans[this.state.avansIndex].subType,
            transactionId: this.state.checkPaymentAvans[this.state.avansIndex].transactionId,
            // additionalCode: getFormatedDate(this.state.selectedDate, "dd.mm.yyyy"),
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            abonCode: this.state.checkPaymentAvans[this.state.avansIndex].abonCode,
            merchantCode: this.state.checkPaymentAvans[this.state.avansIndex].merchantCode,
            serviceCode: this.state.checkPaymentAvans[this.state.avansIndex].serviceCode,
            subType: this.state.checkPaymentAvans[this.state.avansIndex].subType,
            transactionId: this.state.checkPaymentAvans[this.state.avansIndex].transactionId,
            // additionalCode: getFormatedDate(this.state.selectedDate, "dd.mm.yyyy"),
        }
        if (localStorage.getItem("additionalCodeParamType") !== "null") {
            showAvansInfoRequestBody.additionalCode = getFormatedDate(this.state.selectedDate, "dd.mm.yyyy");
        }
        fetch(request("pg/showAvansInfo", showAvansInfoRequestBody))
            .then(res => res.text())
            .then((showAvansInfoResponseJSON) => {
                const JSON = require('true-json-bigint');
                let showAvansInfoResponse = JSON.parse(showAvansInfoResponseJSON);
                if (showAvansInfoResponse.status.statusCode !== 1) {
                    if (showAvansInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    let checkPaymentAvans = this.state.checkPaymentAvans;
                    if (showAvansInfoResponse.respAvansList && showAvansInfoResponse.respAvansList.length > 0) {
                        checkPaymentAvans[this.state.avansIndex].amount = Number(showAvansInfoResponse.respAvansList[0].amount) <= 0 ? 0 : showAvansInfoResponse.respAvansList[0].amount;
                        checkPaymentAvans[this.state.avansIndex].minAllowed = showAvansInfoResponse.respAvansList[0].minAllowed ? showAvansInfoResponse.respAvansList[0].minAllowed : 0;
                        checkPaymentAvans[this.state.avansIndex].maxAllowed = showAvansInfoResponse.respAvansList[0].maxAllowed ? showAvansInfoResponse.respAvansList[0].maxAllowed : 9999999;
                        checkPaymentAvans[this.state.avansIndex].transactionNumber = showAvansInfoResponse.respAvansList[0].transactionNumber
                    }
                    else {
                        checkPaymentAvans[this.state.avansIndex].amount = 0;
                        checkPaymentAvans[this.state.avansIndex].minAllowed = 0;
                        checkPaymentAvans[this.state.avansIndex].maxAllowed = 0;
                    }
                    if (showAvansInfoResponse.respAvansList && showAvansInfoResponse.respAvansList?.length > 0) {
                        checkPaymentAvans[this.state.avansIndex].paymentReceiverCodeName = showAvansInfoResponse.respAvansList[0].paymentReceiverCodeName;
                        checkPaymentAvans[this.state.avansIndex].paymentReceiverDescription = showAvansInfoResponse.respAvansList[0].paymentReceiverDescription;
                        this.setState({ checkPaymentAvans });
                    }
                    else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(175));
                        this.setState({ avansIndex: -1 });
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { t } = this.props;
        let { dropdownVisibility, userInfo, cards, selectedValue, items, items2, tempHave, category, succesModal, amount, templateModal, templateModalSuccess, succesModalData, errorModal, errorModalData, selectedIndex, selectedIndex2, selectedShow, checkPayment, checkPaymentAvans, checkPaymentInvoice, invoiceIndex, avansIndex, selectedPaymentType, selectedPaymentIndex, step, allDisabled, paymentReceiverCodeNameIndex, paymentReceiverCodeNameShow, isLoading, bonusBalance, bonusBalanceExchangeAzn, bonusTariffInfoList, additionalCode, additionalCodeParamType } = this.state;
        let custObject = JSON.parse(localStorage.getItem('custObject')).company ? JSON.parse(localStorage.getItem('custObject')).customer : JSON.parse(localStorage.getItem('custObject'));
        return (
            <Fragment>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        (!((items && items.length > 0) || (items2 && items2.length > 0))
                            || !category || !tempHave
                        ) ?
                            <NotFound />
                            :
                            <div className="dashboard-payment-container">
                                <PaymentModalSucces history={this.props.history} templateSave={() => this.setState({ templateModal: true, succesModal: false })} data={succesModalData} open={succesModal} />
                                <PaymentModalError data={errorModalData} open={errorModal} />
                                <TemplateInputModal history={this.props.history} onSubmit={(val) => this.onSubmitTemplate(val)} open={templateModal} />
                                <TemplateModalSuccess history={this.props.history} open={templateModalSuccess} />
                                <div className="dashboard-payment-container-data-section">
                                    <div className="dashboard-payment-container-data-section-header">
                                        <h3 className="dashboard-payment-container-data-section-header-title">{t('Pay')}</h3>
                                        {
                                            this.props.bonus && bonusBalance !== null &&
                                            <div className="dashboard-new-transfer-section-header-title">
                                                <span className='dashboard-new-transfer-section-header-title-light'>
                                                    {t('balance')}
                                                </span>
                                                <span>
                                                    {bonusBalance}
                                                </span>
                                                <svg className='dashboard-new-transfer-section-header-title-icon' width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z" fill="#0179D2" />
                                                </svg>
                                            </div>
                                        }
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content">
                                        {
                                            (checkPaymentAvans.length > 0 || checkPaymentInvoice.length > 0) &&
                                            <div className="dashboard-payment-container-data-section-content-container">
                                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Subscriber information')}</h3>
                                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">

                                                    <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Subscriber')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{
                                                                checkPaymentAvans.length > 0 ?
                                                                    checkPaymentAvans[0].fullName :
                                                                    checkPaymentInvoice[0].fullName
                                                            }</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        (checkPaymentAvans.length + checkPaymentInvoice.length === 1) || (avansIndex >= 0 || invoiceIndex >= 0) &&
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Type of payment service')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                                                                    {
                                                                        checkPaymentAvans.length + checkPaymentInvoice.length === 1 ?
                                                                            (checkPaymentAvans.length > 0 ?
                                                                                checkPaymentAvans[0].serviceName
                                                                                :
                                                                                checkPaymentInvoice[0].serviceName
                                                                            )
                                                                            :
                                                                            avansIndex >= 0 ?
                                                                                checkPaymentAvans[avansIndex].serviceName
                                                                                :
                                                                                checkPaymentInvoice[invoiceIndex].serviceName

                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.props.bonus && bonusTariffInfoList &&
                                                        (checkPaymentAvans.length > 0 || checkPaymentInvoice.length > 0) &&
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group" style={{ marginLeft: '12px' }}>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title dashboard-payment-container-data-section-content-container-form-group-title--light">{t('Mobile payment')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                                                                    <span>
                                                                        {/* 1 AZN = {Number.parseFloat(bonusTariffInfoList.quantity).toFixed(2).toString().replace('.', ',')} */}
                                                                        1 AZN = {bonusTariffInfoList.quantity}
                                                                    </span>
                                                                    <svg className='dashboard-bonuses-convertation-details-item-content-icon' width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8 0L10.3511 4.76393L15.6085 5.52786L11.8042 9.23607L12.7023 14.4721L8 12L3.29772 14.4721L4.19577 9.23607L0.391548 5.52786L5.64886 4.76393L8 0Z" fill="#909090" />
                                                                    </svg>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                            <form className="dashboard-payment-container-data-section-content-container-form">
                                                <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                    {
                                                        step === 1 && items && selectedIndex >= 0 && items.length > 1 &&
                                                        <div style={{ width: '100%' }} className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select the type of operation')}</label>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                                <button onClick={e => { e.preventDefault(); this.setState({ selectedShow: !selectedShow }); }} className="dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" >
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{items[selectedIndex].elements[0].merchantDescValue}</div>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </button>
                                                                <ul style={{ zIndex: 1000 }} className={(!selectedShow) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {items.map((item, index) => {
                                                                        return (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                <button style={{ width: '100%' }} onClick={e => { e.preventDefault(); this.setState({ selectedShow: false, selectedIndex: index }) }} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{item.elements[0].merchantDescValue}</button>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        items.length > 0 && selectedIndex >= 0 && items[selectedIndex].elements.map((item, index) => {
                                                            if (item.format === "string") {
                                                                if (item.params.length > 0) {
                                                                    return (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{item.merchantDescValue}</label>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                                                    <button className="dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" onClick={(e) => this.onChangeParamsDropdown(e, index)}>
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{item.params.find(el => el.value === item.defaultSelect).label}</div>
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                            </svg>
                                                                                        </div>
                                                                                    </button>
                                                                                    <ul style={{ zIndex: 1000 }} className={(item.isShow === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                        {

                                                                                            item.params.map((item_in, index_in) => {
                                                                                                return (
                                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                                        <button onClick={e => this.onSelectParamsDropdown(e, item_in.value, index)} style={{ width: '100%' }} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{item_in.label}</button>
                                                                                                    </li>
                                                                                                );
                                                                                            })
                                                                                        }

                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{item.paramDisplayName}</label>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                    <input defaultValue={item.defaultInput} type={`${item.format === "string" && 'text'}${item.format === "number" && 'number'}`} disabled={allDisabled ? allDisabled : step >= 2} className="dashboard-payment-container-data-section-content-container-form-input" onChange={e => this.onChnageInputDropdown(e.target.value, index)} placeholder={item.paramDisplayName} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">
                                                                                    {
                                                                                        item.paramDisplayName
                                                                                    }
                                                                                </label>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                    <input
                                                                                        defaultValue={
                                                                                            item.defaultInput
                                                                                        }
                                                                                        className="dashboard-payment-container-data-section-content-container-form-input"
                                                                                        disabled={
                                                                                            allDisabled
                                                                                                ? allDisabled
                                                                                                : step >=
                                                                                                2
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.onChnageInputDropdown(
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        placeholder={
                                                                                            item.paramDisplayName
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {additionalCodeParamType !== null &&
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t("Date of birth")}</label>
                                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box disabled-date-picker">
                                                                                        <MuiPickersUtilsProvider
                                                                                            utils={
                                                                                                DateFnsUtils
                                                                                            }
                                                                                            locale={
                                                                                                i18n.language ==
                                                                                                    "ru"
                                                                                                    ? ru
                                                                                                    : i18n.language ==
                                                                                                        "en"
                                                                                                        ? enUS
                                                                                                        : az
                                                                                            }
                                                                                            style={{
                                                                                                width:
                                                                                                    "100%",
                                                                                                color: "#ACB3B8"
                                                                                            }}
                                                                                        >
                                                                                            <KeyboardDatePicker
                                                                                                style={{
                                                                                                    width:
                                                                                                        "100%",
                                                                                                    color: "#ACB3B8"
                                                                                                }}
                                                                                                className="dashboard-finance-section-card-section-operation-section-content-pick-date-input"
                                                                                                placeholder={t(
                                                                                                    "Date of birth"
                                                                                                )}
                                                                                                format="dd.MM.yyyy"
                                                                                                value={
                                                                                                    additionalCode
                                                                                                }
                                                                                                cancelLabel={t(
                                                                                                    "cancel"
                                                                                                )}
                                                                                                okLabel={t(
                                                                                                    "ok"
                                                                                                )}
                                                                                                disabled="true"
                                                                                                //   onChange={
                                                                                                //     this
                                                                                                //       .handleDateChange
                                                                                                //   }
                                                                                                keyboardbuttonprops={{
                                                                                                    "aria-label":
                                                                                                        "change date",
                                                                                                }}
                                                                                                keyboardIcon={
                                                                                                    <svg
                                                                                                        width="16"
                                                                                                        height="15"
                                                                                                        viewBox="0 0 16 15"
                                                                                                        fill="none"
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                    >
                                                                                                        <path
                                                                                                            fillRule="evenodd"
                                                                                                            clipRule="evenodd"
                                                                                                            d="M3.60059 8.30629H4.57827V7.44763H3.60059V8.30629ZM2.62598 9.16391H5.55904V6.59033H2.62598V9.16391ZM3.60059 11.7389H4.57827V10.8798H3.60059V11.7389ZM2.62598 12.596H5.55904V10.0217H2.62598V12.596ZM7.50781 8.30629H8.48549V7.44763H7.50781V8.30629ZM6.52832 9.16391H9.46141V6.59033H6.52832V9.16391ZM7.50781 11.7389H8.48549V10.8798H7.50781V11.7389ZM6.52832 12.596H9.46141V10.0217H6.52832V12.596ZM11.4268 8.30629H12.4044V7.44763H11.4268V8.30629ZM10.4463 9.16391H13.3794V6.59033H10.4463V9.16391ZM11.4268 11.7389H12.4044V10.8798H11.4268V11.7389ZM10.4463 12.596H13.3794V10.0217H10.4463V12.596ZM14.3565 4.87427H1.64648V13.0252C1.64648 13.2622 1.86549 13.4542 2.13557 13.4542H13.8676C14.1377 13.4542 14.3565 13.2622 14.3565 13.0252V4.87427ZM14.3565 2.72866C14.3565 2.49142 14.1377 2.29944 13.8676 2.29944H11.4234V3.15788H10.4457V2.29944H5.55725V3.15788H4.57956V2.29944H2.13557C1.86549 2.29944 1.64648 2.49142 1.64648 2.72866V4.01569H14.3565V2.72866ZM14.3547 14.3118H1.64468C1.10475 14.3118 0.666992 13.9279 0.666992 13.4538V4.87388V4.01587V2.29962C0.666992 1.82578 1.10475 1.4416 1.64468 1.4416H4.57776V0.583374H5.55545V1.4416H10.4439V0.583374H11.4216V1.4416H14.8435C15.3835 1.4416 15.3324 1.82578 15.3324 2.29962V4.01587V4.87388V13.4538C15.3324 13.9279 14.8946 14.3118 14.3547 14.3118Z"
                                                                                                            fill="#BDC3C7"
                                                                                                        />
                                                                                                    </svg>
                                                                                                }
                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                        })
                                                    }

                                                    {items?.length > 0 && items2?.length > 0 && <hr className='w-100 mt-5' />}

                                                    {
                                                        items2?.length > 0 && selectedIndex2 >= 0 && items2.map((item, index) => (
                                                            item.elements.map((item, index2) => (
                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{item.paramDisplayName}</label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input    defaultValue={
                                                                                            item.defaultInput
                                                                                        } type={`${item.format === "string" && 'text'}${item.format === "number" && 'number'}`} disabled={allDisabled ? allDisabled : step >= 2} className="dashboard-payment-container-data-section-content-container-form-input" onChange={e => this.onChnageInputDropdown2(e.target.value, index2, index)} placeholder={item.paramDisplayName} />
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ))
                                                    }

                                                    {
                                                        checkPayment ? ((checkPaymentAvans.length === 1 && checkPaymentInvoice.length === 0) || (checkPaymentInvoice.length === 1 && checkPaymentAvans.length === 0)) ?
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                {step > 1 && <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by card')}</label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                                        <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 3 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={this.changeDropdownVisibility}>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedValue === -2 ? `${t("Stars")}: ${bonusBalance} ☆ / ${bonusBalanceExchangeAzn} AZN` : cards && selectedValue >= 0 && cards.length > 0 && cards[selectedValue].organizedCardNumber + ' / ' + cards[selectedValue].balance + ' ' + cards[selectedValue].currency}{(!cards || cards.length < 1) && t('The card list is empty')}</div>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                        <ul className={(dropdownVisibility === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                            {
                                                                                cards && cards.length > 0 &&
                                                                                cards.map((card, index) => {
                                                                                    return (
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                            <button onClick={e => this.onSelect(e, index)} disabled={!Boolean(card.cardStatus)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                                        </li>
                                                                                    );
                                                                                })
                                                                            }
                                                                            {
                                                                                !localStorage.getItem('compId') &&
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                    <button onClick={e => this.onSelect(e, -2)} className={"dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"}>{`${t("Stars")}: ${bonusBalance} ☆ / ${bonusBalanceExchangeAzn} AZN`}</button>
                                                                                </li>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>}


                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')}</label>
                                                                    {/* <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="number" onChange={val => {
                                                                            if (checkPaymentAvans.length === 1) {
                                                                                var arrAvans = checkPaymentAvans;
                                                                                arrAvans[0].amount = val.target.value;
                                                                                this.setState({ checkPaymentAvans: arrAvans });
                                                                            } else {
                                                                                var arrInvoice = checkPaymentInvoice;
                                                                                arrInvoice[0].amount = val.target.value;
                                                                                this.setState({ checkPaymentInvoice: arrInvoice });
                                                                            }
                                                                        }} disabled={allDisabled ? true : !(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).partialPayment} autoFocus onFocus={(e) => {
                                                                            if (checkPaymentAvans.length === 1) {
                                                                                var arrAvans = checkPaymentAvans;
                                                                                arrAvans[0].amount = amount;
                                                                                this.setState({ checkPaymentAvans: arrAvans });
                                                                            } else {
                                                                                var arrInvoice = checkPaymentInvoice;
                                                                                arrInvoice[0].amount = amount;
                                                                                this.setState({ checkPaymentInvoice: arrInvoice });
                                                                            }
                                                                        }} defaultValue={amount} min={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).minAllowed ? (checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).minAllowed : 1} max={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).maxAllowed !== 0 ? (checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).maxAllowed : 99999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{cards && selectedValue >= 0 && cards.length > 0 && cards[selectedValue].currency}</div>
                                                                    </div> */}
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="number" onChange={val => {
                                                                            if (checkPaymentAvans.length === 1) {
                                                                                var arrAvans = checkPaymentAvans;
                                                                                arrAvans[0].amount = val.target.value;
                                                                                this.setState({ checkPaymentAvans: arrAvans });
                                                                            } else {
                                                                                var arrInvoice = checkPaymentInvoice;
                                                                                arrInvoice[0].amount = val.target.value;
                                                                                this.setState({ checkPaymentInvoice: arrInvoice });
                                                                            }
                                                                        }} disabled={allDisabled ? true : !(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).partialPayment}
                                                                            defaultValue={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).amount} min={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).minAllowed ? (checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).minAllowed : 1} max={(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).maxAllowed !== 0 ? (checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).maxAllowed : 99999999}
                                                                            className={`dashboard-payment-container-data-section-content-container-form-input ${allDisabled ? true : !(checkPaymentAvans.length === 1 ? checkPaymentAvans[0] : checkPaymentInvoice[0]).partialPayment ? 'dashboard-payment-container-data-section-content-container-form-input__disable' : ''}`} placeholder="0" />
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>

                                                                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', maxHeight: '500px', overflowY: 'auto', marginTop: '15px', marginBottom: '10px' }}>
                                                                    {
                                                                        step <= 2 &&
                                                                        checkPaymentAvans.map((avans, index) => {
                                                                            return (
                                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '12px', paddingBottom: '12px', borderBottom: '1px solid #E8ECEF', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <div>{avans.serviceName}</div>
                                                                                    <div style={{ minWidth: '35%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                            {/* <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                                <input disabled={true} type="number" defaultValue={avans.amount} min={avans.minAllowed ? avans.minAllowed : 1} max={avans.maxAllowed ? avans.maxAllowed : 9999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" />
                                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                        {/* <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: 1, selectedPaymentIndex: index, step: 3, allDisabled: true }) }} style={{ alignSelf: 'flex-end', marginLeft: '15px' }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('pay')}</button> */}
                                                                                        <input type="checkbox" checked={index === avansIndex} onChange={() => {
                                                                                            if (index === avansIndex) {
                                                                                                this.setState({ avansIndex: -1, invoiceIndex: -1 });
                                                                                            }
                                                                                            else {
                                                                                                this.setState({ avansIndex: index, invoiceIndex: -1 }, () => {
                                                                                                    this.showAvansInfo();
                                                                                                })
                                                                                            }
                                                                                        }} style={{ width: '20px', height: '20px', marginLeft: '10px', marginRight: '10px' }} />
                                                                                    </div>

                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        step <= 2 &&
                                                                        checkPaymentInvoice.map((invoice, index) => {
                                                                            return (
                                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '12px', paddingBottom: '12px', borderBottom: '1px solid #E8ECEF', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <div>{invoice.serviceName}</div>
                                                                                    <div style={{ minWidth: '35%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                                {/* <input onChange={val => {
                                                                                    var arrInvoice = checkPaymentInvoice;
                                                                                    arrInvoice[index].amount = val.target.value;
                                                                                    this.setState({ checkPaymentInvoice: arrInvoice });
                                                                                }} disabled={true} type="number" defaultValue={invoice.amount} min={invoice.minAllowed ? invoice.minAllowed : 0} max={invoice.maxAllowed ? invoice.maxAllowed : 9999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" /> */}
                                                                                                <input disabled={true} type="number" defaultValue={invoice.amount} min={invoice.minAllowed ? invoice.minAllowed : 1} max={invoice.maxAllowed ? invoice.maxAllowed : 9999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" />
                                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: 2, selectedPaymentIndex: index, step: 3, allDisabled: true }) }} style={{ alignSelf: 'flex-end', marginLeft: '15px' }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('pay')}</button> */}
                                                                                        <input type="checkbox" checked={index === invoiceIndex} onChange={() => {
                                                                                            if (index === invoiceIndex) {
                                                                                                this.setState({ invoiceIndex: -1, avansIndex: -1 });
                                                                                            }
                                                                                            else {
                                                                                                this.setState({ invoiceIndex: index, avansIndex: -1 });
                                                                                            }
                                                                                        }} style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                                                                                    </div>

                                                                                </div>
                                                                            );
                                                                        })
                                                                    }

                                                                </div>
                                                                {
                                                                    step >= 2 && (invoiceIndex >= 0 || avansIndex >= 0) &&
                                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                        {step > 1 && <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by card')}</label>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                                                <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 3 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={this.changeDropdownVisibility}>
                                                                                    <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedValue === -2 ? `${t("Stars")}: ${bonusBalance} ☆ / ${bonusBalanceExchangeAzn} AZN` : cards && selectedValue >= 0 && cards.length > 0 && cards[selectedValue].organizedCardNumber + ' / ' + cards[selectedValue].balance + ' ' + cards[selectedValue].currency}{(!cards || cards.length < 1) && t('The card list is empty')}</div>
                                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </button>
                                                                                <ul className={(dropdownVisibility === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                    {
                                                                                        cards && cards.length > 0 &&
                                                                                        cards.map((card, index) => {
                                                                                            return (
                                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                                    <button onClick={e => this.onSelect(e, index)} disabled={!Boolean(card.cardStatus)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                                                </li>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        !localStorage.getItem('compId') &&
                                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                            <button onClick={e => this.onSelect(e, -2)} className={"dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"}>{`${t("Stars")}: ${bonusBalance} ☆ / ${bonusBalanceExchangeAzn} AZN`}</button>
                                                                                        </li>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>}
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')}</label>
                                                                            {/* <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                <input onChange={val => {
                                                                                    if (avansIndex >= 0) {
                                                                                        var arrAvans = checkPaymentAvans;
                                                                                        arrAvans[avansIndex].amount = val.target.value;
                                                                                        this.setState({ checkPaymentAvans: arrAvans });
                                                                                    } else {
                                                                                        var arrInvoice = checkPaymentInvoice;
                                                                                        arrInvoice[invoiceIndex].amount = val.target.value;
                                                                                        this.setState({ checkPaymentInvoice: arrInvoice });
                                                                                    }
                                                                                }} type="number" disabled={allDisabled ? true : !Boolean((avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).partialPayment)} autoFocus onFocus={() => {
                                                                                    if (Boolean((avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).partialPayment)) {
                                                                                        if (avansIndex >= 0) {
                                                                                            var arrAvans = checkPaymentAvans;
                                                                                            arrAvans[avansIndex].amount = amount;
                                                                                            this.setState({ checkPaymentAvans: arrAvans });
                                                                                        } else {
                                                                                            var arrInvoice = checkPaymentInvoice;
                                                                                            arrInvoice[invoiceIndex].amount = amount;
                                                                                            this.setState({ checkPaymentInvoice: arrInvoice });
                                                                                        }
                                                                                    } else {

                                                                                    }
                                                                                }} defaultValue={amount} value={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).amount ? (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).amount : amount} min={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).minAllowed ? (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).minAllowed : 1} max={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).maxAllowed !== 0 ? (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).maxAllowed : 99999999} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__disable" placeholder="0" />
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                            </div> */}
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                <input onChange={val => {
                                                                                    if (avansIndex >= 0) {
                                                                                        var arrAvans = checkPaymentAvans;
                                                                                        arrAvans[avansIndex].amount = val.target.value;
                                                                                        this.setState({ checkPaymentAvans: arrAvans });
                                                                                    } else {
                                                                                        var arrInvoice = checkPaymentInvoice;
                                                                                        arrInvoice[invoiceIndex].amount = val.target.value;
                                                                                        this.setState({ checkPaymentInvoice: arrInvoice });
                                                                                    }
                                                                                }} type="number" disabled={allDisabled ? true : !Boolean((avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).partialPayment)}
                                                                                    defaultValue={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).amount} value={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).amount} min={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).minAllowed ? (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).minAllowed : 1} max={(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).maxAllowed !== 0 ? (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).maxAllowed : 99999999}
                                                                                    className={`dashboard-payment-container-data-section-content-container-form-input ${allDisabled ? true : !Boolean((avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).partialPayment) ? 'dashboard-payment-container-data-section-content-container-form-input__disable' : ''}`} placeholder="0" />
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    step >= 2 && (invoiceIndex >= 0 || avansIndex >= 0) && (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).paymentReceiverCodeName && (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).paymentReceiverCodeName.length > 0 &&
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by card')}</label>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                                            <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 3 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={e => { e.preventDefault(); this.setState({ paymentReceiverCodeNameShow: step >= 3 ? false : !paymentReceiverCodeNameShow }) }}>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{(avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).paymentReceiverCodeName[paymentReceiverCodeNameIndex].name}</div>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            <ul className={(paymentReceiverCodeNameShow === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                {
                                                                                    (avansIndex >= 0 ? checkPaymentAvans[avansIndex] : checkPaymentInvoice[invoiceIndex]).paymentReceiverCodeName.map((item, index) => {
                                                                                        return (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                                <button onClick={e => this.onSelectPaymentReceiverCodeName(e, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{item.name}</button>
                                                                                            </li>
                                                                                        );
                                                                                    })
                                                                                }

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                }


                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                {
                                                    step === 1 &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={(e) => { e.preventDefault(); this.onSubmitForm() }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        <button onClick={() => this.props.history.goBack()} type='button' className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                }

                                                {
                                                    step === 2 && ((checkPaymentAvans.length === 1 && checkPaymentInvoice.length === 0) || (checkPaymentInvoice.length === 1 && checkPaymentAvans.length === 0)) &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: checkPaymentAvans.length === 1 ? 1 : 2, selectedPaymentIndex: 0, step: 3, allDisabled: true }) }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        <button onClick={() => this.props.history.goBack()} type='button' className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                }
                                                {
                                                    (invoiceIndex >= 0 || avansIndex >= 0) && step === 2 &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: avansIndex >= 0 ? 1 : 2, selectedPaymentIndex: avansIndex >= 0 ? avansIndex : invoiceIndex, step: 3, allDisabled: true }) }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                                        <button onClick={() => this.props.history.goBack()} type='button' className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section">
                                    <div className="dashboard-payment-container-confirmation-section-header">
                                        <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Payment confirmation')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-confirmation-section-content">
                                        {
                                            step > 2 && selectedPaymentType >= 0 ?
                                                ((checkPaymentAvans.length === 1 && checkPaymentInvoice.length === 0) || (checkPaymentInvoice.length === 1 && checkPaymentAvans.length === 0)) ?
                                                    <Fragment>
                                                        <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                            <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter('AZN')}</span>
                                                            <span className="dashboard-payment-container-confirmation-section-amount-whole">{selectedPaymentType === 1 ? this.amountSplit(checkPaymentAvans[selectedPaymentIndex].amount.toString()).part1 : this.amountSplit(checkPaymentInvoice[selectedPaymentIndex].amount.toString()).part1}</span>
                                                            <span className="dashboard-payment-container-confirmation-section-amount-fractional">{selectedPaymentType === 1 ? this.amountSplit(checkPaymentAvans[selectedPaymentIndex].amount.toString()).part2 : this.amountSplit(checkPaymentInvoice[selectedPaymentIndex].amount.toString()).part2}</span>
                                                        </h3>
                                                        <ul className="dashboard-payment-container-confirmation-section-list">
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of provider')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{items[0]?.elements[0].merchantDisplayName || items2[0]?.elements[0].merchantDisplayName}</p>
                                                            </li>

                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Subscriber')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].fullName : checkPaymentInvoice[selectedPaymentIndex].fullName}</p>
                                                            </li>
                                                            {
                                                                this.paramsList().map((item, index) => {
                                                                    return (
                                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{item.paramLabel}</p>
                                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{item.paramValue}</p>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                (selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].respChildInvoiceList : checkPaymentInvoice[selectedPaymentIndex].respChildInvoiceList) && (selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].respChildInvoiceList : checkPaymentInvoice[selectedPaymentIndex].respChildInvoiceList).map((item, index) => {
                                                                    return (
                                                                        <li className="dashboard-payment-container-confirmation-section-list-item" key={index}>
                                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{item.serviceName}</p>
                                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(item.amount.toString()).part1}.{this.amountSplit(item.amount.toString()).part2} {'AZN'}</p>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                !(selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].respChildInvoiceList : checkPaymentInvoice[selectedPaymentIndex].respChildInvoiceList) &&
                                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Type of payment service')}</p>
                                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].serviceName : checkPaymentInvoice[selectedPaymentIndex].serviceName}</p>
                                                                </li>
                                                            }
                                                            {
                                                                selectedValue >= 0 &&
                                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Card')}</p>
                                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{cards && cards.length > 0 && cards[selectedValue].organizedCardNumber}{(!cards || cards.length < 1) && 'Kart siyahısı boşdur'}</p>
                                                                </li>
                                                            }
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                                {
                                                                    (selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].respChildInvoiceList : checkPaymentInvoice[selectedPaymentIndex].respChildInvoiceList) ?
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(this.childTotalCommision().toString()).part1}.{this.amountSplit(this.childTotalCommision().toString()).part2} {selectedValue >= 0 ? cards[selectedValue].currency : 'AZN'}</p>
                                                                        :
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(this.commissionCalculator().toString()).part1}.{this.amountSplit(this.commissionCalculator().toString()).part2} {selectedValue >= 0 ? cards[selectedValue].currency : 'AZN'}</p>
                                                                }

                                                            </li>
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                    <button onClick={e => (selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].respChildInvoiceList : checkPaymentInvoice[selectedPaymentIndex].respChildInvoiceList) ? this.pay_processWithChild(e) : this.pay_process(e)} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t("Pay")}</button>
                                                                    <button onClick={() => this.props.history.goBack()} type='button' className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                            <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter('AZN')}</span>
                                                            <span className="dashboard-payment-container-confirmation-section-amount-whole">{selectedPaymentType === 1 ? this.amountSplit(checkPaymentAvans[selectedPaymentIndex].amount.toString()).part1 : this.amountSplit(checkPaymentInvoice[selectedPaymentIndex].amount.toString()).part1}</span>
                                                            <span className="dashboard-payment-container-confirmation-section-amount-fractional">{selectedPaymentType === 1 ? this.amountSplit(checkPaymentAvans[selectedPaymentIndex].amount.toString()).part2 : this.amountSplit(checkPaymentInvoice[selectedPaymentIndex].amount.toString()).part2}</span>
                                                        </h3>
                                                        <ul className="dashboard-payment-container-confirmation-section-list">
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of provider')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{items[0]?.elements[0].merchantDisplayName || items[0]?.elements[0].merchantDisplayName}</p>
                                                            </li>

                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Subscriber')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].fullName : checkPaymentInvoice[selectedPaymentIndex].fullName}</p>
                                                            </li>

                                                            {
                                                                this.paramsList().map((item, index) => {
                                                                    return (
                                                                        <li className="dashboard-payment-container-confirmation-section-list-item" key={index}>
                                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{item.paramLabel}</p>
                                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{item.paramValue}</p>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Type of payment service')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].serviceName : checkPaymentInvoice[selectedPaymentIndex].serviceName}</p>
                                                            </li>
                                                            {
                                                                selectedValue >= 0 &&
                                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Card')}</p>
                                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{cards && cards.length > 0 && cards[selectedValue].organizedCardNumber}{(!cards || cards.length < 1) && 'Kart siyahısı boşdur'}</p>
                                                                </li>
                                                            }
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(this.commissionCalculator().toString()).part1}.{this.amountSplit(this.commissionCalculator().toString()).part2} {selectedValue >= 0 ? cards[selectedValue].currency : 'AZN'}</p>
                                                            </li>
                                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                    <button onClick={e => (selectedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].respChildInvoiceList : checkPaymentInvoice[selectedPaymentIndex].respChildInvoiceList) ? this.pay_processWithChild(e) : this.pay_process(e)} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t("Pay")}</button>
                                                                    <button onClick={() => this.props.history.goBack()} type='button' className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </Fragment>
                                                :
                                                <div className="dashboard-payment-container-confirmation-section-empty">
                                                    <img src={empty} className="dashboard-payment-container-confirmation-section-content-image" />
                                                    <p className="dashboard-payment-container-confirmation-section-content-description">{t('To complete the payment, please enter the payment settings and continue.')}</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(TemplatePay);
