import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import iconUnregisterIPS from './img/register_ips.svg';
import iconRegisterIPS from './img/unregiste_payment.svg';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function TransferModal(props) {
    const classes = useStyles();
    const close = () => {
        props.onClose();
    }

    return (
        <Modal
            open={props.open}
            onClose={close}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            classes={classes.modal}
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description">
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <div className="create-transfer-modal">

                        <button type="button" className="create-transfer-modal-close-button" onClick={() => { close() }}>
                            <svg className="dashboard-change-account-name-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <div className="create-transfer-modal-content">
                            <div className={"create-transfer-modal-content-header"} style={{ paddingBottom: '25px' }}>
                                <h3 className="create-transfer-modal-content-header-title">{props.t("Ödəniş üsulunu seçin")}</h3>
                                <p className="create-transfer-modal-content-header-subtitle">{props.t("Qeydiyyatsız və ya Qeydiyatdan keçərək sürətli ödənişlər edin")}</p>
                            </div>
                        </div>
                        <div className="create-transfer-modal-footer" style={{ paddingTop: 0, }}>
                            <Link to="/dashboard/transfer/ips/pay/registration/1" className="create-transfer-modal-footer-ips-button" style={{ width: 'calc(50% - 22px)' }} >
                                <img src={iconUnregisterIPS} />
                                <h3 className="create-transfer-modal-footer-ips-button-title">{props.t("Registered payment")}</h3>
                                <p className="create-transfer-modal-footer-ips-button-desc"></p>
                            </Link>
                            <Link to="/dashboard/transfer/ips/pay/unregistration" className="create-transfer-modal-footer-ips-button" style={{ width: 'calc(50% - 22px)', marginLeft: '22px' }} >
                                <img src={iconRegisterIPS} />
                                <h3 className="create-transfer-modal-footer-ips-button-title">{props.t("Unregistered payment")}</h3>
                                <p className="create-transfer-modal-footer-ips-button-desc"></p>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
export default translate("translation")(TransferModal);
