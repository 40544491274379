import React, { Component, Fragment } from 'react';
import { Switch, Link, NavLink, Redirect, Route } from 'react-router-dom';
import { translate } from 'react-i18next';
import Dropdown from './../../../../copms/Dropdown/Dropdown';
import lastOperationsIcon from './../../img/last-operations-icon.png';
import transferInternalIcon from './../../img/transfer-internal-icon.svg';
import cardIcon from './../../img/credit-cardcredit-card.svg';
import historyIcon from './../../img/history.svg';
import qrIcon from './../../img/qrcode.svg';
import friendTransferIcon from './../../img/friend-transfer-icon.svg';
import zkTransferIcon from './../../img/zk-transfer-icon.svg';
import fastMoneyIcon from './../../img/fast-money-icon.svg';
import exchangeIcon from './../../img/exchange_op.svg';
import { request } from './../../../../config';
import transferFilter from '../../../../helpers/transferFilter';
import cardOperationFilter from '../../../../helpers/cardOperationFilter';
import YesNoModal from '../../../../copms/Modal/YesNoModal';
import Loading from '../../Loading/Loading';
import currencyFilter from '../../../../helpers/currencyFilter';
import { customerType } from '../../../../helpers/customer';
import { statusCodes } from '../../../../helpers/statusCodes';

class CardOperation extends Component {
    state = {
        signLevel: null,
        signCount: null,
        open: false,
        modalCardObj: {},
        templates: [],
        statements: [],
        operations: [],
        isCardToCard: false
    }

    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    dropdownItemHandler = (index, tempIndex, url) => {
        switch (index) {
            case 1:
                this.setState({ deleteIndex: tempIndex, open: true })
                break;
        }
    }

    deleteCardTemplate(index) {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let removePaymentTempCustomerRequestBody = customerType == 'Juridical' ? {
            compId: localStorage.getItem('compId'),
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.templates[index].tempId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            tempId: this.state.templates[index].tempId,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("card/deleteCardOperationTemp", removePaymentTempCustomerRequestBody))
            .then(res => res.text())
            .then((removePaymentTempCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let removePaymentTempCustomerResponse = JSON.parse(removePaymentTempCustomerResponseJSON);
                if (removePaymentTempCustomerResponse.status.statusCode !== 1) {
                    if (removePaymentTempCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removePaymentTempCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    var tempArr = this.state.templates;
                    tempArr.splice(index, 1);
                    this.setState({ templates: tempArr });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            });
    }

    componentWillMount() {
        // this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getOperationTempListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("card/getCardOperationTempList", getOperationTempListCustomerRequestBody))
            .then(res => res.text())
            .then((getOperationTempListCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOperationTempListCustomerResponse = JSON.parse(getOperationTempListCustomerResponseJSON);
                if (getOperationTempListCustomerResponse.status.statusCode !== 1 && getOperationTempListCustomerResponse.status.statusCode !== 239) {
                    if (getOperationTempListCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    }
                }
                else {
                    if (getOperationTempListCustomerResponse.status.statusCode === 1) {
                        if (getOperationTempListCustomerResponse.cardTempList) {
                            for (let i = 0; i < getOperationTempListCustomerResponse.cardTempList.length; i++) {
                                // =========AMOUNT=========
                                if (getOperationTempListCustomerResponse.cardTempList[i].amount.toString() === "0") {
                                    getOperationTempListCustomerResponse.cardTempList[i].organizedAmount = {
                                        wholePart: "0",
                                        fractionalPart: "00"
                                    }
                                }
                                else {
                                    if (getOperationTempListCustomerResponse.cardTempList[i].amount.toString().indexOf('.') !== -1) {
                                        let amountArray = getOperationTempListCustomerResponse.cardTempList[i].amount.toString().split('.');
                                        getOperationTempListCustomerResponse.cardTempList[i].organizedAmount = {
                                            wholePart: amountArray[0],
                                            fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                        }
                                    }
                                    else {
                                        getOperationTempListCustomerResponse.cardTempList[i].organizedAmount = {
                                            wholePart: getOperationTempListCustomerResponse.cardTempList[i].amount.toString(),
                                            fractionalPart: "00"
                                        }
                                    }
                                }
                                // =========NUMBER=========
                                if (getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber && getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber.length === 16) {
                                    getOperationTempListCustomerResponse.cardTempList[i].organizedDestinationCardNumber = getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber.substring(0, 4)
                                        + " **** **** " + getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber.substring(12, 16);
                                }
                                else {
                                    getOperationTempListCustomerResponse.cardTempList[i].organizedDestinationCardNumber = getOperationTempListCustomerResponse.cardTempList[i].destinationCardNumber;
                                }
                            }
                            this.setState({ templates: getOperationTempListCustomerResponse.cardTempList });
                        }
                    }
                }
                // =========STARTDATE/ENDDATE=========
                const changeDateType = () => {
                    let currentDate = new Date();
                    let date = (currentDate.getDate() < 10) ? ("0" + currentDate.getDate()) : currentDate.getDate();
                    let month = (currentDate.getMonth() + 1 < 10) ? ("0" + (currentDate.getMonth() + 1)) : (currentDate.getMonth() + 1);
                    let year = currentDate.getFullYear();
                    return date + '.' + month + '.' + year;
                }
                let getCardStatementForCustomerRequestBody = customerType === "Juridical" ? {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    startDate: changeDateType(), //temporary
                    endDate: changeDateType(),
                    compId: localStorage.getItem('compId'),
                } : {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    startDate: changeDateType(), //temporary
                    endDate: changeDateType(),

                }
                fetch(request("card/getCardStatementFor" + customerType + "Customer", getCardStatementForCustomerRequestBody))
                    .then(res => res.text())
                    .then((getCardStatementForCustomerResponseJSON) => {
                        const JSON = require('true-json-bigint');
                        let getCardStatementForCustomerResponse = JSON.parse(getCardStatementForCustomerResponseJSON);
                        if (getCardStatementForCustomerResponse.status.statusCode !== 1 && getCardStatementForCustomerResponse.status.statusCode !== 137 && getCardStatementForCustomerResponse.status.statusCode !== 165) {
                            if (getCardStatementForCustomerResponse.status.statusCode === 200) {
                                this.props.logout();
                            } else {
                                this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardStatementForCustomerResponse.status.statusCode))
                                this.setState({ isLoading: false });
                            }
                        }
                        else {
                            if (getCardStatementForCustomerResponse.status.statusCode === 137 || getCardStatementForCustomerResponse.status.statusCode === 165) {
                                getCardStatementForCustomerResponse.cardStamentList = [];
                            }
                            for (let i = 0; i < getCardStatementForCustomerResponse.cardStamentList.length; i++) {
                                // =========NUMBER=========
                                getCardStatementForCustomerResponse.cardStamentList[i].organizedCardNumber = getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(0, 4)
                                    + " **** **** " + getCardStatementForCustomerResponse.cardStamentList[i].cardNumber.substring(12, 16);
                                // =========DATE=========
                                getCardStatementForCustomerResponse.cardStamentList[i].organizedDate = getCardStatementForCustomerResponse.cardStamentList[i].trDate.substring(0, getCardStatementForCustomerResponse.cardStamentList[i].trDate.length - 3);
                                // =========AMOUNT=========
                                if (getCardStatementForCustomerResponse.cardStamentList[i].dtAmount === 0) {
                                    getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].crAmount;
                                }
                                else {
                                    if (getCardStatementForCustomerResponse.cardStamentList[i].crAmount === 0) {
                                        getCardStatementForCustomerResponse.cardStamentList[i].amount = getCardStatementForCustomerResponse.cardStamentList[i].dtAmount;
                                    }
                                }
                                if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString() === "0") {
                                    getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                        wholePart: "0",
                                        fractionalPart: "00"
                                    }
                                }
                                else {
                                    if (getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().indexOf('.') !== -1) {
                                        let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                                        getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                            wholePart: amountArray[0],
                                            fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                                        }
                                    }
                                    else {
                                        let amountArray = getCardStatementForCustomerResponse.cardStamentList[i].amount.toString().split('.');
                                        getCardStatementForCustomerResponse.cardStamentList[i].amount = {
                                            wholePart: getCardStatementForCustomerResponse.cardStamentList[i].amount.toString(),
                                            fractionalPart: "00"
                                        }
                                    }
                                }
                            }
                            this.setState({ statements: getCardStatementForCustomerResponse.cardStamentList });
                            this.setState({ isLoading: false });
                        }
                    },
                        (error) => {

                            this.props.history.push("/technical-break");
                        });
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    showAccessDeniedOperationPopup = () => {
        let bankCustomerType = Number(localStorage.getItem("bankCustomerType"))
        bankCustomerType === customerType.NON_CUSTOMER &&
            this.props.alertState(
                true,
                'warning',
                this.props.t('Information'),
                this.props.t(statusCodes.ACCESS_DENIED_OPERATION)
            )
    }

    componentDidMount() {
        if (this.props.match.url.includes('card-to-card')) {
            this.setState({ isCardToCard: true })
        }
        if (localStorage.getItem('custObject') && localStorage.getItem('compId')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            this.setState({ signLevel: custObject.company.signLevel, signCount: custObject.company.signCount });
        }
    }

    render() {
        let { updateAppState, alertState, t } = this.props;
        let { open, templates, modalCardObj, operations, signLevel, signCount, statements, deleteIndex, isLoading, isCardToCard } = this.state;
        let bankCustomerType = Number(localStorage.getItem("bankCustomerType"));
        return (
            <Fragment>
                <YesNoModal onClose={() => this.setState({ open: false })} title={t('Are you sure you want to delete the template?')} open={open} triggerFunc={() => this.deleteCardTemplate(deleteIndex)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => (this.props.history.goBack(), this.setState({ isCardToCard: !isCardToCard }))}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{isCardToCard ? t('Card to card') : t('Card operations')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section">
                            <div className="dashboard-new-transfer-template-section">
                                <div className="dashboard-new-transfer-template-section-background">
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('New transfer')}</h3>
                                        </div>
                                        <ul className="dashboard-new-transfer-section-content">
                                            {isCardToCard ? <>
                                                <Link to="/dashboard/transfer/card-operation/card-to-card/between-internal" className="dashboard-new-transfer-section-content-item">
                                                    <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                        <svg width="41" height="34" viewBox="0 0 41 34" fill="none" xmlns="http://www.w3.org/2000/svg" className="dashboard-new-transfer-section-content-item-icon-section-icon">
                                                            <path d="M40.6875 10.2031C41.0781 9.8125 41.0781 9.26562 40.6875 8.875L32.875 1.0625C32.4844 0.671875 31.8594 0.671875 31.5469 1.0625L29.9844 2.625C29.5938 2.9375 29.5938 3.5625 29.9844 3.95312L33.9688 7.625H1.9375C1.39062 7.625 1 8.09375 1 8.5625V10.4375C1 10.9844 1.39062 11.375 1.9375 11.375H33.9688L29.9844 15.125C29.5938 15.5156 29.5938 16.1406 29.9844 16.4531L31.5469 18.0156C31.8594 18.4062 32.4844 18.4062 32.875 18.0156L40.6875 10.2031ZM1.23438 25.2031L9.04688 33.0156C9.4375 33.4062 10.0625 33.4062 10.375 33.0156L11.9375 31.4531C12.3281 31.1406 12.3281 30.5156 11.9375 30.125L7.95312 26.375H40.0625C40.5312 26.375 41 25.9844 41 25.4375V23.5625C41 23.0938 40.5312 22.625 40.0625 22.625H7.95312L11.9375 18.9531C12.3281 18.5625 12.3281 17.9375 11.9375 17.625L10.375 16.0625C10.0625 15.6719 9.4375 15.6719 9.04688 16.0625L1.23438 23.875C0.84375 24.2656 0.84375 24.8125 1.23438 25.2031Z" fill="#0179D2" />
                                                        </svg>
                                                    </div>
                                                    <p className="dashboard-new-transfer-section-content-item-title">{t('Between my own cards')}</p>
                                                </Link>
                                                <Link to="/dashboard/transfer/card-operation/card-to-card/between-external" className="dashboard-new-transfer-section-content-item">
                                                    <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                        <svg width="45" height="36" viewBox="0 0 45 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="dashboard-new-transfer-section-content-item-icon-section-icon">
                                                            <path d="M0 31.75C0 33.8594 1.64062 35.5 3.75 35.5H41.25C43.2812 35.5 45 33.8594 45 31.75V18H0V31.75ZM15 26.4375C15 25.9688 15.3906 25.5 15.9375 25.5H26.5625C27.0312 25.5 27.5 25.9688 27.5 26.4375V29.5625C27.5 30.1094 27.0312 30.5 26.5625 30.5H15.9375C15.3906 30.5 15 30.1094 15 29.5625V26.4375ZM5 26.4375C5 25.9688 5.39062 25.5 5.9375 25.5H11.5625C12.0312 25.5 12.5 25.9688 12.5 26.4375V29.5625C12.5 30.1094 12.0312 30.5 11.5625 30.5H5.9375C5.39062 30.5 5 30.1094 5 29.5625V26.4375ZM45 4.25C45 2.21875 43.2812 0.5 41.25 0.5H3.75C1.64062 0.5 0 2.21875 0 4.25V8H45V4.25Z" fill="#0179D2" />
                                                        </svg>
                                                    </div>
                                                    <p className="dashboard-new-transfer-section-content-item-title">{t("To another bank's card")}</p>
                                                </Link>
                                                <Link to="/dashboard/transfer/card-operation/card-to-card/history" className="dashboard-new-transfer-section-content-item">
                                                    <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="dashboard-new-transfer-section-content-item-icon-section-icon">
                                                            <path d="M39.375 20C39.375 30.7031 30.625 39.375 20 39.375C15.3906 39.4531 11.0938 37.8125 7.8125 35.1562C6.95312 34.4531 6.875 33.125 7.65625 32.3438L8.51562 31.4844C9.21875 30.7812 10.3125 30.7031 11.0156 31.3281C13.5156 33.2812 16.5625 34.375 20 34.375C27.8906 34.375 34.375 27.9688 34.375 20C34.375 12.1094 27.8906 5.625 20 5.625C16.1719 5.625 12.6562 7.10938 10.0781 9.53125L14.0625 13.5156C14.8438 14.2969 14.2969 15.625 13.2031 15.625H1.875C1.17188 15.625 0.625 15.0781 0.625 14.375V3.04688C0.625 1.95312 1.95312 1.40625 2.73438 2.1875L6.5625 6.01562C10.0781 2.73438 14.7656 0.625 20 0.625C30.625 0.625 39.2969 9.29688 39.375 20ZM25.2344 26.1719C24.5312 26.9531 23.3594 27.1094 22.5781 26.4844L17.5 22.5V11.875C17.5 10.8594 18.2812 10 19.375 10H20.625C21.6406 10 22.5 10.8594 22.5 11.875V20.0781L25.625 22.5781C26.4844 23.2031 26.6406 24.375 25.9375 25.1562L25.2344 26.1719Z" fill="#0179D2" />
                                                        </svg>
                                                    </div>
                                                    <p className="dashboard-new-transfer-section-content-item-title">{t("history")}</p>
                                                </Link>
                                            </> : <>
                                                <Link to="/dashboard/transfer/card-operation/card-to-card" onClick={() => this.setState({ isCardToCard: !isCardToCard })} className="dashboard-new-transfer-section-content-item">
                                                    <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                        <img src={cardIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                    </div>
                                                    <p className="dashboard-new-transfer-section-content-item-title">{t("Card to card")}</p>
                                                </Link>
                                                <Link to={bankCustomerType !== customerType.NON_CUSTOMER && "/dashboard/transfer/card-operation/code"} className="dashboard-new-transfer-section-content-item" onClick={this.showAccessDeniedOperationPopup}>
                                                    <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                        <img src={qrIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                    </div>
                                                    <p className="dashboard-new-transfer-section-content-item-title">Cash by code</p>
                                                    {/* <p className="dashboard-new-transfer-section-content-item-title">{t('Cash by code')}</p> */}
                                                </Link>
                                                <Link to={bankCustomerType !== customerType.NON_CUSTOMER && "/dashboard/transfer/card-operation/friend-transfer"} className="dashboard-new-transfer-section-content-item" onClick={this.showAccessDeniedOperationPopup}>
                                                    <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                        <img src={friendTransferIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                    </div>
                                                    <p className="dashboard-new-transfer-section-content-item-title">{t("Friend transfer")}</p>
                                                </Link>
                                                <Link to={bankCustomerType !== customerType.NON_CUSTOMER && "/dashboard/transfer/card-operation/fast-money-transfer"} className="dashboard-new-transfer-section-content-item" onClick={this.showAccessDeniedOperationPopup}>
                                                    <div className="dashboard-new-transfer-section-content-item-icon-section">
                                                        <img src={fastMoneyIcon} className="dashboard-new-transfer-section-content-item-icon-section-icon" />
                                                    </div>
                                                    <p className="dashboard-new-transfer-section-content-item-title">{t("Fast money transfers")}</p>
                                                </Link>
                                            </>}
                                        </ul>
                                    </div>
                                    <div className="dashboard-new-transfer-section">
                                        <div className="dashboard-new-transfer-section-header">
                                            <h3 className="dashboard-new-transfer-section-header-title">{t('Templates')}</h3>
                                            <Link to="/dashboard/transfer/templates/card-operation" className="dashboard-new-transfer-section-header-history">{t('all')}</Link>
                                        </div>
                                        {
                                            templates.length !== 0 ?
                                                <div className="dashboard-finance-section-cards-section-table-section">
                                                    <table className="dashboard-finance-section-cards-section-table">
                                                        <tbody>
                                                            {
                                                                templates.slice(0, 5).map((template, index) =>
                                                                    <Fragment key={index}>
                                                                        <tr className="dashboard-finance-section-cards-section-row" key={index}>
                                                                            <td className="dashboard-finance-section-cards-section-cell">
                                                                                <div className="dashboard-finance-section-cards-section-cell-icon dashboard-finance-section-cards-section-cell-icon__circle">
                                                                                    <img src={cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).iconName} style={{ height: '15px' }} className="dashboard-finance-section-cards-section-cell-icon__circle-img" />
                                                                                </div>
                                                                                <div className="dashboard-finance-section-cards-section-cell-main-section">
                                                                                    <Link to={`/dashboard/transfer/template/card-operation/${cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue}/${template.tempId}`} className="dashboard-finance-section-cards-section-cell-content">{template.tempName}</Link>
                                                                                    <Link to={`/dashboard/transfer/template/card-operation/${cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue}/${template.tempId}`} className="dashboard-finance-section-cards-section-cell-title">{template.organizedDestinationCardNumber}</Link>
                                                                                </div>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                <p className="dashboard-finance-section-cards-section-cell-content">
                                                                                    <span className={"dashboard-finance-section-cards-section-amount-currency dashboard-finance-section-cards-section-amount-currency__" + template.currency}>{currencyFilter(template.currency)}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-whole-part">{template.organizedAmount.wholePart}</span>
                                                                                    <span className="dashboard-finance-section-cards-section-amount-fractional-part">{template.organizedAmount.fractionalPart}</span>
                                                                                </p>
                                                                                <p className="dashboard-finance-section-cards-section-cell-title">{template.cardOperationTypeValue}</p>
                                                                            </td>
                                                                            <td className="dashboard-finance-section-cards-section-cell dashboard-finance-section-cards-section-cell__right">
                                                                                <div className="dashboard-finance-section-cards-section-button-group">
                                                                                    <Dropdown
                                                                                        dropdownItems={[
                                                                                            { title: this.props.t('Create a payment'), url: "/dashboard/transfer/template/card-operation/" + (cardOperationFilter(template.cardOperationType) && cardOperationFilter(template.cardOperationType).cardOperationTypeValue) + '/' + template.tempId },
                                                                                            { title: this.props.t('Delete') },
                                                                                        ]}
                                                                                        dropdownItemHandler={(i) => this.dropdownItemHandler(i, index, `/dashboard/transfer/template/transfer/${transferFilter(template.operNameId) && transferFilter(template.operNameId).cardOperationTypeValue}/${template.operNameId === 4 ? template.operationTypeId === 5 ? '1/' : '2/' : ''}${template.id}`)}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="dashboard-finance-row-line">
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                            <td className="dashboard-finance-row-line-cell"><div className="dashboard-finance-row-line-span"></div></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div className="dashboard-payment-operation-section-content">
                                                    <Link to="" className="dashboard-payment-operation-section-content-item">
                                                        <div className="dashboard-payment-operation-section-content-item--left-side">
                                                            <div className="dashboard-payment-operation-section-content-item-icon-container">
                                                                <img src={transferInternalIcon} style={{ height: 'auto' }} className="dashboard-payment-operation-section-content-item-icon" />
                                                            </div>
                                                            <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                <div className="dashboard-payment-operation-section-content-item-title">{t('Create a new template')}</div>
                                                                <div className="dashboard-payment-operation-section-content-item-subtitle">{t('card number')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-operation-section-content-item--right-side">
                                                            <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                            <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                        </div>
                                                    </Link>
                                                    {
                                                        [0, 1].map(index => (
                                                            <li className="dashboard-payment-operation-section-content-item" key={index}>
                                                                <div className="dashboard-payment-operation-section-content-item--left-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-icon-container"></div>
                                                                    <div className="dashboard-payment-operation-section-content-item-description-container">
                                                                        <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                        <div className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="dashboard-payment-operation-section-content-item--right-side">
                                                                    <div className="dashboard-payment-operation-section-content-item-title dashboard-payment-operation-section-content-item-title__invisible"></div>
                                                                    <p className="dashboard-payment-operation-section-content-item-subtitle dashboard-payment-operation-section-content-item-subtitle__invisible"></p>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-transfer-history">
                                {
                                    operations.length !== 0 ?
                                        <>
                                            <div className="dashboard-transfer-history-header">
                                                <NavLink to="/dashboard/transfer/transfers/all" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('all')}</NavLink>
                                                <NavLink to="/dashboard/transfer/transfers/confirmed" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('Approved')}</NavLink>
                                                <NavLink to="/dashboard/transfer/transfers/waiting" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('Confirmation is expected')}</NavLink>
                                                {
                                                    localStorage.getItem('userId') && signCount === 2 && localStorage.getItem('compId') &&
                                                    <>
                                                        <NavLink to="/dashboard/transfer/transfers/first-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('1st authorization')}</NavLink>
                                                        <NavLink to="/dashboard/transfer/transfers/second-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('2nd authorization')}</NavLink>
                                                    </>
                                                }
                                                {
                                                    localStorage.getItem('userId') && signLevel === 1 && signCount === 1 && localStorage.getItem('compId') &&
                                                    <NavLink to="/dashboard/transfer/transfers/second-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('authorization')}</NavLink>
                                                }
                                                {
                                                    localStorage.getItem('userId') && !localStorage.getItem('compId') &&
                                                    <NavLink to="/dashboard/transfer/transfers/second-authorization" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('authorization')}</NavLink>
                                                }
                                                <NavLink to="/dashboard/transfer/transfers/refused" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('refusal')}</NavLink>
                                            </div>

                                        </>
                                        :
                                        <>
                                            <div className="dashboard-finance-section-card-section-details-section-header">
                                                <h3 className="dashboard-finance-section-card-section-details-section-header-title dashboard-last-operations-section-header-title">{t('recent operations')}</h3>
                                                {/* <button className="dashboard-finance-section-card-section-details-section-header-show-all dashboard-last-operations-section-header-history">{t('all history')}</button> */}

                                            </div>
                                            {
                                                (statements.length !== 0) ?
                                                    <div className="dashboard-last-operations-section-content">
                                                        <ul className="dashboard-last-operations-section-list">
                                                            {
                                                                statements.slice(0, 10).map((statement, index) =>
                                                                    <li className="dashboard-last-operations-section-item" key={index}>
                                                                        <div className="dashboard-last-operations-section-item-left-side">
                                                                            <div className="dashboard-last-operations-section-item-icon-container">
                                                                                <svg className="dashboard-last-operations-section-item-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M0.625 0.75C0.40625 0.75 0.25 0.9375 0.25 1.125V5.625C0.25 5.84375 0.40625 6 0.625 6H5.125C5.3125 6 5.5 5.84375 5.5 5.625V5.3125C5.5 5.125 5.3125 4.9375 5.125 4.9375H2.03125C3.15625 2.8125 5.40625 1.3125 8 1.3125C11.6875 1.3125 14.6875 4.34375 14.6875 8C14.6875 11.7188 11.6562 14.6875 8 14.6875C6.25 14.6875 4.6875 14.0625 3.5 13C3.375 12.8438 3.125 12.875 3 13L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.15625 0.25 2.65625 1.8125 1.3125 4.09375V1.125C1.3125 0.9375 1.125 0.75 0.9375 0.75H0.625ZM10.6562 10.625L10.8125 10.4062C10.9375 10.25 10.9062 10 10.7188 9.90625L8.5 8.25V3.625C8.5 3.4375 8.3125 3.25 8.125 3.25H7.875C7.65625 3.25 7.5 3.4375 7.5 3.625V8.78125L10.125 10.6875C10.3125 10.8125 10.5312 10.7812 10.6562 10.625Z" fill="#849097" />
                                                                                </svg>
                                                                            </div>
                                                                            <div className="dashboard-last-operations-section-item-content">
                                                                                <div className="dashboard-last-operations-section-item-company">{statement.organizedCardNumber}</div>
                                                                                <div className="dashboard-last-operations-section-item-description">{statement.purpose}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dashboard-last-operations-section-item-rigth-side">
                                                                            <div className="dashboard-last-operations-section-item-amount">
                                                                                <span className="dashboard-last-operations-section-item-amount-whole-part">{statement.amount.wholePart}</span>
                                                                                <span className="dashboard-last-operations-section-item-amount-fractional-part">{statement.amount.fractionalPart}</span>
                                                                                <span className="dashboard-last-operations-section-item-amount-currency">{statement.currency}</span>
                                                                            </div>
                                                                            <p className="dashboard-last-operations-section-item-category">{statement.trDate.split(' ')[0]}</p>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <div className="dashboard-last-operations-section-content">
                                                        <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                        <p className="dashboard-last-operations-section-content-status">{t('you do not currently have any active transactions')}</p>
                                                        <ul className="dashboard-last-operations-section-content-invisible-list">
                                                            <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                                </div>
                                                            </li>
                                                            <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                                </div>
                                                            </li>
                                                            <li className="dashboard-last-operations-section-content-invisible-list-item">
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-circle"></div>
                                                                <div className="dashboard-last-operations-section-content-invisible-list-item-content">
                                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content-title"></div>
                                                                    <div className="dashboard-last-operations-section-content-invisible-list-item-content-subtitle"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}

export default translate("translation")(CardOperation);