import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Internal from './Internal';
import Inland from './Inland';
import Convertation from './Convertation';
import Abroad from './Abroad';
import Between from './Between';
export default class Transfers extends Component {

    render() {
        let { updateAppState, updateDashboardState, alertState, signLevel, signCount, logout } = this.props;
        return (
            <Switch>
                <Route path="/dashboard/transfer/template/transfer/between/:id" render={(routeProps) => <Between logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/transfer/internal/:id" render={(routeProps) => <Internal logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/transfer/inland/:id" render={(routeProps) => <Inland logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/transfer/abroad/:id" render={(routeProps) => <Abroad logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/transfer/convertation/:type/:id" render={(routeProps) => <Convertation logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
            </Switch>
        );
    }
}