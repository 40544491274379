import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import { request } from './../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import UpdateModal from './../../../../copms/Modal/UpdateModal';
import NotFound from '../../../../copms/NotFound/NotFound';
import Loading from './../../Loading/Loading';

function roundNumber(number) { // Arguments: number to round, number of decimal places
    return Math.round(number * 100) / 100;
}

class CreateTransferConvertation extends Component {
    state = {
        confirmedOperation: false,
        dropdownVisibilityBank: false,
        dropdownVisibilityBuyingAccount: false,
        dropdownVisibilitySellingAccount: false,
        banks: [],
        accounts: [],
        selectedBuyingAccount: null,
        selectedSellingAccount: null,
        selectedBank: {},
        bankFilter: "",
        crCustomerName: "",
        crCustomerNameValidation: false,
        crIban: "",
        crIbanValidation: false,
        crTaxNo: "",
        crTaxNoValidation: false,
        amount: "",
        amountValidation: false,
        purpose: "",
        purposeValidation: false,
        dtAccountId: null,
        buyingAccounts: [],
        sellingAccounts: [],
        currencyBuying: '',
        currencySelling: '',
        valueBuying: '',
        valueSelling: '',
        exchangeCashlessList: [],
        exchangeRate: '',
        step: 1,
        sellingIndex: -1,
        buyingIndex: -1,
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        openTemp: false,
        operationId: 0,
        tempHave: false,
        exchangeEditable: false,
        converNumber: "",
        custOperNo: '',
        updateData: null,
        dtCustName: null,
        dtCustTaxid: null,
        buyingAccountStatus: 0,
        sellingAccountStatus: 0,
        feeAmount: 0,
    }
    updateBankFilter = (event) => {
        this.setState({ selectedBank: "", bankFilter: event.target.value })
    }
    updateSelectedBank = (bank) => {
        this.setState({ selectedBank: bank, bankFilter: bank.bankName });
        this.bankFilter.value = bank.bankName;
        this.changeDropdownVisibilityBank();
    }
    updateDtAccountBuyingId = (dtAccountId, dtAccountIban, index, event) => {
        this.setState({ dtAccountId: dtAccountId, dtAccountIban: dtAccountIban, buyingIndex: index, selectedBuyingAccount: event.target.textContent, dtAccontIdValidation: true, dropdownVisibilityBuyingAccount: false, currencyBuying: this.state.buyingAccounts[index].currName });
    }
    updateDtAccountSellingId = (dtAccountId, dtAccountIban, index, event) => {
        this.setState({ dtAccountId: dtAccountId, dtAccountIban: dtAccountIban, sellingIndex: index, selectedSellingAccount: event.target.textContent, dtAccontIdValidation: true, dropdownVisibilitySellingAccount: false, currencySelling: this.state.sellingAccounts[index].currName, exchangeRate: this.props.match.params.type == 2 ? this.state.exchangeCashlessList.find(el => el.currency == this.state.sellingAccounts[index].currName).cashlessBuy : (this.state.exchangeCashlessList.find(el => el.currency == this.state.sellingAccounts[index].currName).cashlessSell) }, () => this.onChangeBuyingAmount());
    }
    changeDropdownVisibilityBank = () => {
        this.setState({ dropdownVisibilityBank: !this.state.dropdownVisibilityBank });
    }
    changeDropdownVisibilityBuyingAccount = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityBuyingAccount: !this.state.dropdownVisibilityBuyingAccount, dropdownVisibilitySellingAccount: false, buyingAccountStatus: this.state.dropdownVisibilityBuyingAccount ? this.state.selectedBuyingAccount ? 2 : 1 : this.state.buyingAccountStatus });
    }
    changeDropdownVisibilitySellingAccount = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilitySellingAccount: !this.state.dropdownVisibilitySellingAccount, dropdownVisibilityBuyingAccount: false, sellingAccountStatus: this.state.dropdownVisibilitySellingAccount ? this.state.selectedSellingAccount ? 2 : 1 : this.state.sellingAccountStatus });

    }
    checkCrCustomerName = (event) => {
        this.setState({ crCustomerNameValidation: false });
        let mask = /[^a-zA-Z ]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length > 0) {
            this.setState({ crCustomerNameValidation: true });
        }
        this.setState({ crCustomerName: event.target.value });
    }

    onChangeBuyingAmount() {
        var val = this.state.valueBuying;
        if (/^\d*\.?\d*$/.test(val)) {
            if (this.props.match.params.type == 1) {

                this.setState({ valueSelling: (val.length > 0 && val !== 0) ? roundNumber(parseFloat(val) / parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val !== 0 && this.state.converNumber.length > 0 && this.state.converNumber !== 0 : val.length > 0 && val !== 0) : false, })
            } else {
                this.setState({ valueSelling: (val.length > 0 && val !== 0) ? roundNumber(parseFloat(val) / parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val !== 0 && this.state.converNumber.length > 0 && this.state.converNumber !== 0 : val.length > 0 && val !== 0) : false, })
            }
        }
    }

    onChangeSellingAmount() {
        var val = this.state.valueSelling;

        if (/^\d*\.?\d*$/.test(val)) {
            if (this.props.match.params.type == 1) {
                this.setState({ valueBuying: (val.length > 0 && val !== 0) ? roundNumber(parseFloat(val) * parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val !== 0 && this.state.converNumber.length > 0 && this.state.converNumber !== 0 : val.length > 0 && val !== 0) : false, })
            } else {
                this.setState({ valueBuying: (val.length > 0 && val !== 0) ? roundNumber(parseFloat(val) * parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val !== 0 && this.state.converNumber.length > 0 && this.state.converNumber !== 0 : val.length > 0 && val !== 0) : false, })
            }
        }
    }

    onChangeExchangeRate() {
        if (this.state.exchangeRate.length > 0) {
            if (this.props.match.params.type == 1) {
                this.onChangeSellingAmount();
            } else {
                this.onChangeSellingAmount();
            }
        }
    }

    checkCrIban = (event) => {
        this.setState({ crIbanValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length > 28) {
            event.target.value = event.target.value.substring(0, 28);
        }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length === 28) {
            this.setState({ crIbanValidation: true });
        }
        this.setState({ crIban: event.target.value });
    }
    checkCrTaxNo = (event) => {
        let mask = /[^0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length < 10) {
            this.setState({ crTaxNoValidation: false });
        }
        else {
            if (event.target.value.length > 10) {
                event.target.value = event.target.value.substring(0, 10);
            }
            this.setState({ crTaxNoValidation: true });
        }
        this.setState({ crTaxNo: event.target.value });
    }
    checkAmount = (event) => {
        this.setState({ amountValidation: false });
        let mask = /[^0-9.]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.indexOf('.') !== -1) {
            let amount = event.target.value;
            let firstPointIndex = amount.indexOf('.');
            let wholePartAmount = amount.substr(0, firstPointIndex);
            let fractionalPartAmount = "";
            let afterPoint = 0;
            for (let i = firstPointIndex + 1; i < amount.length; i++) {
                if (amount[i] !== '.') {
                    fractionalPartAmount += amount[i];
                    afterPoint++;
                }
                if (afterPoint === 2) break;
            }
            if (firstPointIndex === 0) {
                wholePartAmount = 0;
            }
            event.target.value = wholePartAmount + '.' + fractionalPartAmount;
            this.setState({ amount: wholePartAmount + '.' + fractionalPartAmount });
            this.setState({ organizedAmount: { whole: parseInt(wholePartAmount), fractional: parseInt(fractionalPartAmount) } });
        }
        else {
            this.setState({ amount: event.target.value });
        }
        // =========BALANCE=========
        let amount = event.target.value;
        if (amount.toString() === "0") {
            this.setState({ organizedAmount: { whole: "0", fractional: "00" } });
        }
        else {
            if (amount.toString().indexOf('.') !== -1) {
                let currentBalanceArray = amount.toString().split('.');
                let fractional = currentBalanceArray[1].length === 1 ? currentBalanceArray[1] + '0' : currentBalanceArray[1];
                this.setState({ organizedAmount: { whole: currentBalanceArray[0], fractional: fractional } });
            }
            else {
                if (event.target.value !== "") {
                    this.setState({ organizedAmount: { whole: amount.toString(), fractional: "00" } });
                }
            }
        }
        if (parseFloat(event.target.value) + " " + isNaN(parseFloat(event.target.value))) {
            this.setState({ amountValidation: true });
        }
        else {
            this.setState({ amountValidation: false });
        }
    }
    checkPurpose = (event) => {
        this.setState({ purpose: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ purposeValidation: false });
        }
        else {
            this.setState({ purposeValidation: true })
        }
    }

    showTransferConvertation = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getTransferComissionRequestBody = {
            operationNameId: 4,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        if (this.props.match.params.type == 1) {
            getTransferComissionRequestBody.operationTypeId = 5;
            getTransferComissionRequestBody.amount = this.state.valueBuying;
            // getTransferComissionRequestBody.accountId = this.state.buyingAccounts[this.state.buyingIndex].accountId;
            getTransferComissionRequestBody.accountId = this.state.sellingAccounts[this.state.sellingIndex].accountId;
            // getTransferComissionRequestBody.crAccountId = this.state.sellingAccounts[this.state.sellingIndex].accountId;
            getTransferComissionRequestBody.crAccountId = this.state.buyingAccounts[this.state.buyingIndex].accountId;
        }
        else {
            getTransferComissionRequestBody.operationTypeId = 6;
            getTransferComissionRequestBody.amount = this.state.valueSelling;
            // getTransferComissionRequestBody.accountId = this.state.sellingAccounts[this.state.sellingIndex].accountId;
            getTransferComissionRequestBody.accountId = this.state.buyingAccounts[this.state.buyingIndex].accountId;
            // getTransferComissionRequestBody.crAccountId = this.state.buyingAccounts[this.state.buyingIndex].accountId;
            getTransferComissionRequestBody.crAccountId = this.state.sellingAccounts[this.state.sellingIndex].accountId;
        }
        fetch(request("operation/getCommission", getTransferComissionRequestBody))
            .then(res => res.text())
            .then((getTransferComissionResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferComissionResponse = JSON.parse(getTransferComissionResponseJSON);
                if (getTransferComissionResponse.status.statusCode !== 1) {
                    if (getTransferComissionResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getTransferComissionResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ feeAmount: getTransferComissionResponse.commission.amount, step: 2 });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async getTempById() {

    }

    async getOperationAllInfoById() {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getOperationAllInfoByIdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            operId: this.props.match.params.id,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            operId: this.props.match.params.id,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        await fetch(request("operation/getOperationAllInfoById", getOperationAllInfoByIdRequestBody))
            .then(res => res.text())
            .then((getOperationAllInfoByIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getOperationAllInfoByIdResponse = JSON.parse(getOperationAllInfoByIdResponseJSON);
                if (getOperationAllInfoByIdResponse.status.statusCode !== 1 && getOperationAllInfoByIdResponse.status.statusCode !== 126) {
                    if (getOperationAllInfoByIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getOperationAllInfoByIdResponse.status.statusCode));
                        // this.props.updateAppState('isLoading', false);
                    }
                }
                else {
                    this.showAccounts(getOperationAllInfoByIdResponse.dtIban, getOperationAllInfoByIdResponse.crIban, getOperationAllInfoByIdResponse.crCcy, getOperationAllInfoByIdResponse.dtCcy, getOperationAllInfoByIdResponse.dtAmt);

                    this.setState({ dtCustName: getOperationAllInfoByIdResponse.dtCustName, dtCustTaxid: getOperationAllInfoByIdResponse.dtCustTaxid, updateData: getOperationAllInfoByIdResponse, converNumber: getOperationAllInfoByIdResponse && getOperationAllInfoByIdResponse.custOperNo, custOperNo: getOperationAllInfoByIdResponse && getOperationAllInfoByIdResponse.custOperNo });
                }
            });
    }

    showAccounts(firstIban, secondIban, firstCurrency, secondCurrnecy, amount) {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 0
        }

        fetch(request("account/getNoCardAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                    }
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }

                        // =========CURRENCY=========
                        // getNoCardAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getNoCardAccountListForCustomerResponse.accountList[i].currName);
                    }
                    var buyingAccounts = [];
                    var sellingAccounts = [];

                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        if (getNoCardAccountListForCustomerResponse.accountList[i].currName === "AZN") {
                            buyingAccounts.push(getNoCardAccountListForCustomerResponse.accountList[i]);
                        } else {
                            sellingAccounts.push(getNoCardAccountListForCustomerResponse.accountList[i]);
                        }
                    }
                    this.setState({ buyingAccounts: buyingAccounts, sellingAccounts: sellingAccounts, confirmedOperation: true, selectedBuyingAccount: "+", selectedSellingAccount: "+" },
                        () => {
                            if (this.props.match.params.type == 1) {
                                this.setState({ buyingIndex: buyingAccounts.findIndex(el => el.iban == firstIban), sellingIndex: sellingAccounts.findIndex(el => el.iban == secondIban), currencySelling: firstCurrency, valueBuying: amount.toString(), currencyBuying: 'AZN', tempHave: true, exchangeRate: this.state.exchangeCashlessList.find(el => el.currency == firstCurrency) && (this.state.exchangeCashlessList.find(el => el.currency == firstCurrency).cashlessSell) }, () => this.onChangeBuyingAmount());
                            } else {
                                this.setState({ buyingIndex: buyingAccounts.findIndex(el => el.iban == secondIban), sellingIndex: sellingAccounts.findIndex(el => el.iban == firstIban), currencySelling: secondCurrnecy, valueSelling: amount.toString(), currencyBuying: 'AZN', tempHave: true, exchangeRate: this.state.exchangeCashlessList.find(el => el.currency == secondCurrnecy) && this.state.exchangeCashlessList.find(el => el.currency == secondCurrnecy).cashlessBuy }, () => this.onChangeSellingAmount());
                            }
                        });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    async testRequest() {
        await fetch(request("exchange/cashless", {}, "GET"))
            .then(res => res.text())
            .then((cashlessResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cashlessResponse = JSON.parse(cashlessResponseJSON);
                this.setState({ exchangeCashlessList: cashlessResponse.exchangeCashlessList })
            })
    }

    componentDidMount() {
        this.resultQuery();
    }

    async resultQuery() {
        this.setState({ isLoading: true });
        await this.testRequest();
        // await this.getTempById();
        await this.getOperationAllInfoById();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.type != this.props.match.params.type && this.state.selectedSellingAccount) {
            this.setState({ exchangeRate: this.props.match.params.type == 2 ? this.state.exchangeCashlessList.find(el => el.currency == this.state.sellingAccounts[this.state.sellingIndex].currName).cashlessBuy : (this.state.exchangeCashlessList.find(el => el.currency == this.state.sellingAccounts[this.state.sellingIndex].currName).cashlessSell) })
        }
    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    onSubmitForm() {
        this.setState({ feedbackErrorModal: null });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        if (this.props.match.params.type == 1) {
            let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
            let createExchangeOperationRequestBody = customerType == 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                crAmount: this.state.valueSelling,
                crBranchId: this.state.sellingAccounts[this.state.sellingIndex].branchId,
                crIban: this.state.sellingAccounts[this.state.sellingIndex].iban,
                exchangeOperationType: 5,
                exchangeRate: this.state.exchangeRate,
                dtAccountId: this.state.buyingAccounts[this.state.buyingIndex].accountId,
                dtAmount: this.state.valueBuying,
                custOperNo: this.state.converNumber,
                dtBranchId: this.state.buyingAccounts[this.state.buyingIndex].branchId,
                operId: this.props.match.params.id,
                operStateId: this.state.updateData.operStateId,
                createdDate: this.state.updateData.createdDate,
                operNameId: this.state.updateData.operNameId,
                dtCustName: this.state.dtCustName,
                dtCustTaxid: this.state.dtCustTaxid
            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                crAmount: this.state.valueSelling,
                crBranchId: this.state.sellingAccounts[this.state.sellingIndex].branchId,
                crIban: this.state.sellingAccounts[this.state.sellingIndex].iban,
                exchangeOperationType: 5,
                exchangeRate: this.state.exchangeRate,

                dtAccountId: this.state.buyingAccounts[this.state.buyingIndex].accountId,
                dtAmount: this.state.valueBuying,
                custOperNo: this.state.converNumber,
                dtBranchId: this.state.buyingAccounts[this.state.buyingIndex].branchId,
                operId: this.props.match.params.id,
                operStateId: this.state.updateData.operStateId,
                createdDate: this.state.updateData.createdDate,
                operNameId: this.state.updateData.operNameId,
                dtCustName: this.state.dtCustName,
                dtCustTaxid: this.state.dtCustTaxid
            }
            if (localStorage.getItem("compId") && localStorage.getItem("isOperNo") === "1") {
                createExchangeOperationRequestBody.converNumber = this.state.converNumber;
            }
            fetch(request("operation/updateOperationById", createExchangeOperationRequestBody))
                .then(res => res.text())
                .then((createExchangeOperationResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let createExchangeOperationResponse = JSON.parse(createExchangeOperationResponseJSON);
                    if (createExchangeOperationResponse.status.statusCode !== 1) {
                        if (createExchangeOperationResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createExchangeOperationResponse.status.statusCode) });
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                    else {
                        this.setState({ createFeedbackMessage: true, open: true, operationId: createExchangeOperationResponse.operationId });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        } else {
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let createExchangeOperationRequestBody = customerType === 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                crAmount: this.state.valueBuying,
                crBranchId: this.state.buyingAccounts[this.state.buyingIndex].branchId,
                crIban: this.state.buyingAccounts[this.state.buyingIndex].iban,
                exchangeOperationType: 6,
                exchangeRate: this.state.exchangeRate,

                dtAccountId: this.state.sellingAccounts[this.state.sellingIndex].accountId,
                dtAmount: this.state.valueSelling,
                dtBranchId: this.state.sellingAccounts[this.state.sellingIndex].branchId,
                custOperNo: this.state.converNumber,
                operId: this.props.match.params.id,
                operStateId: this.state.updateData.operStateId,
                createdDate: this.state.updateData.createdDate,
                operNameId: this.state.updateData.operNameId,
                dtCustName: this.state.dtCustName,
                dtCustTaxid: this.state.dtCustTaxid
            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                crAmount: this.state.valueBuying,
                crBranchId: this.state.buyingAccounts[this.state.buyingIndex].branchId,
                crIban: this.state.buyingAccounts[this.state.buyingIndex].iban,
                exchangeOperationType: 6,
                exchangeRate: this.state.exchangeRate,

                dtAccountId: this.state.sellingAccounts[this.state.sellingIndex].accountId,
                dtAmount: this.state.valueSelling,
                dtBranchId: this.state.sellingAccounts[this.state.sellingIndex].branchId,
                custOperNo: this.state.converNumber,
                operId: this.props.match.params.id,
                operStateId: this.state.updateData.operStateId,
                createdDate: this.state.updateData.createdDate,
                operNameId: this.state.updateData.operNameId,
                dtCustName: this.state.dtCustName,
                dtCustTaxid: this.state.dtCustTaxid
            }
            if (localStorage.getItem("compId") && localStorage.getItem("isOperNo") === "1") {
                createExchangeOperationRequestBody.converNumber = this.state.converNumber;
            }
            fetch(request("operation/updateOperationById", createExchangeOperationRequestBody))
                .then(res => res.text())
                .then((createExchangeOperationResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let createExchangeOperationResponse = JSON.parse(createExchangeOperationResponseJSON);
                    if (createExchangeOperationResponse.status.statusCode !== 1) {
                        if (createExchangeOperationResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createExchangeOperationResponse.status.statusCode) });
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                    else {
                        this.setState({ createFeedbackMessage: true, open: true, operationId: createExchangeOperationResponse.operationId });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    updateOpenTemp = (value) => {
        this.setState({ openTemp: value });
    }


    render() {
        let { t, history } = this.props;
        let { dropdownVisibilityBuyingAccount, tempHave, dropdownVisibilitySellingAccount, confirmedOperation, currencyBuying, buyingIndex, sellingIndex, currencySelling, selectedBuyingAccount, selectedSellingAccount, buyingAccounts, sellingAccounts,
            exchangeRate, step, buyingAccountStatus, sellingAccountStatus, valueBuying, valueSelling, open, createFeedbackMessage, feedbackErrorModal, isLoading, exchangeEditable, custOperNo, feeAmount } = this.state;
        return (
            <Fragment>
                <UpdateModal open={open} updateOpenModal={this.updateOpenModal} createTemplate={() => this.setState({ step: 1, openTemp: true })} errorClose={() => this.setState({ step: 1 })} successClose={() => { history.push('/dashboard/transfer/history/exchange') }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <Fragment>
                    <div className="dashboard-header-route">
                        <div className="dashboard-header-route-left">
                            <button onClick={() => this.props.history.push('/dashboard/transfer/transfers')}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                </svg>
                            </button>
                            <h2>{t('Conversion')}</h2>
                        </div>
                    </div>
                    {
                        isLoading ?
                            <div className="dashboard-container-main-section">
                                <Loading />
                            </div>
                            :
                            !tempHave ?
                                <NotFound />
                                :
                                <div className="dashboard-payment-container">
                                    <div className="dashboard-payment-container-data-section">
                                        <div className="dashboard-payment-container-data-section-header">
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                <Link to={`/dashboard/transfer/edit/convertation/${this.props.match.params.id}/1`} style={{ borderBottom: this.props.match.params.type == '1' && '2px solid #0179D2', height: '56px', lineHeight: '56px', color: this.props.match.params.type == '2' && '#959FA5', textDecoration: 'none', }} className="dashboard-payment-container-data-section-header-title">{t('Purchase of foreign currency')}</Link>
                                                <Link to={`/dashboard/transfer/edit/convertation/${this.props.match.params.id}/2`} style={{ borderBottom: this.props.match.params.type == '2' && '2px solid #0179D2', height: '56px', lineHeight: '56px', color: this.props.match.params.type == '1' && '#959FA5', textDecoration: 'none', marginLeft: '12px', }} className="dashboard-payment-container-data-section-header-title">{t('Sale of foreign currency')}</Link>
                                            </div>
                                        </div>
                                        {
                                            this.props.match.params.type == '1' ?

                                                <form className="dashboard-payment-container-data-section-content">
                                                    <div className="dashboard-payment-container-data-section-content-container">
                                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                                        <div className="dashboard-payment-container-data-section-content-container-form">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                                {
                                                                    localStorage.getItem("isOperNo") === "1" &&
                                                                    <div style={{ width: '100%' }}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Convertation number')} <span style={{ color: 'red' }}>*</span></label>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                <input type="text" disabled={true} value={custOperNo} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={e => this.setState({ converNumber: e.target.value, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (e.target.value.length > 0 && e.target.value !== 0 && valueSelling.length > 0 && valueSelling !== 0) : false })} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Selling account')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ dropdownVisibilityBuyingAccount: false, buyingAccountStatus: dropdownVisibilityBuyingAccount ? selectedBuyingAccount ? 2 : 1 : buyingAccountStatus }) }}>
                                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${buyingAccountStatus === 1 ? "input-outline" : ""}`}>
                                                                            <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} onClick={this.changeDropdownVisibilityBuyingAccount}>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{buyingAccounts && buyingIndex >= 0 && buyingAccounts[buyingIndex].iban + ' / ' + buyingAccounts[buyingIndex].organizedCurrentBalance.wholePart + '.' + buyingAccounts[buyingIndex].organizedCurrentBalance.fractionalPart + ' ' + buyingAccounts[buyingIndex].currName}</div>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            {
                                                                                buyingAccounts.length !== 0 &&
                                                                                <ul className={(dropdownVisibilityBuyingAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                    {
                                                                                        buyingAccounts.map((account, index) => (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountBuyingId.bind(this, account.accountId, account.iban, index)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Buying account')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ dropdownVisibilitySellingAccount: false, sellingAccountStatus: dropdownVisibilitySellingAccount ? selectedSellingAccount ? 2 : 1 : sellingAccountStatus }) }}>
                                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${sellingAccountStatus === 1 ? "input-outline" : ""}`}>
                                                                            <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} onClick={this.changeDropdownVisibilitySellingAccount}>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{sellingAccounts && sellingIndex >= 0 && sellingAccounts[sellingIndex].iban + ' / ' + sellingAccounts[sellingIndex].organizedCurrentBalance.wholePart + '.' + sellingAccounts[sellingIndex].organizedCurrentBalance.fractionalPart + ' ' + sellingAccounts[sellingIndex].currName}</div>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            {
                                                                                sellingAccounts.length !== 0 &&
                                                                                <ul className={(dropdownVisibilitySellingAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                    {
                                                                                        sellingAccounts.map((account, index) => (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountSellingId.bind(this, account.accountId, account.iban, index)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>




                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('AZN amount')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={valueBuying} disabled={true/*step > 1 || !(selectedBuyingAccount && selectedBuyingAccount.length > 0 && selectedSellingAccount && selectedSellingAccount.length > 0)*/} placeholder="0" onChange={e => /^\d*\.?\d*$/.test(e.target.value) && this.setState({ valueBuying: e.target.value }, () => this.onChangeBuyingAmount())} />
                                                                        <div className={step > 1 ?
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                        }>{currencyBuying}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Currency amount')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${(selectedBuyingAccount && selectedBuyingAccount.length > 0 && selectedSellingAccount && selectedSellingAccount.length > 0 && valueSelling === "") ? "input-outline" : ""}`}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={valueSelling} disabled={step > 1 || !(selectedBuyingAccount && selectedBuyingAccount.length > 0 && selectedSellingAccount && selectedSellingAccount.length > 0)} placeholder="0" onChange={e => /^\d*\.{0,1}(\d{1,2})?$/.test(e.target.value) && this.setState({ valueSelling: e.target.value }, () => this.onChangeSellingAmount())} />
                                                                        <div className={step > 1 ?
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                        }>{currencySelling}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Exchange rate')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="text" value={exchangeRate} onChange={e => /^\d*\.?\d*$/.test(e.target.value) && this.setState({ exchangeRate: e.target.value }, () => this.onChangeExchangeRate())} className="dashboard-payment-container-data-section-content-container-form-input" disabled={!Boolean(selectedSellingAccount) || step > 1 || !exchangeEditable} placeholder="" />
                                                                        <button onClick={e => { e.preventDefault(); this.setState({ exchangeEditable: !exchangeEditable }) }} className={!Boolean(selectedSellingAccount) || step > 1 ?
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                        }>
                                                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M16.4062 1.78125L15.2188 0.59375C14.8438 0.21875 14.3125 0 13.8125 0C13.3125 0 12.7812 0.21875 12.4062 0.59375L10.0625 2.9375L9 4L1.375 11.625L1 15.1875C0.9375 15.625 1.28125 16 1.71875 16C1.75 16 1.78125 16 1.8125 16L5.375 15.625L13 8L14.0625 6.9375L16.4062 4.59375C17.1875 3.8125 17.1875 2.5625 16.4062 1.78125ZM4.6875 14.1875L2.59375 14.4062L2.8125 12.3125L10.0312 5.0625L11 4.09375L12.9062 6L11.9375 6.96875L4.6875 14.1875ZM15.3438 3.53125L13.9688 4.9375L12.0625 3.03125L13.4688 1.65625C13.5938 1.53125 13.75 1.5 13.8125 1.5C13.875 1.5 14.0312 1.53125 14.1562 1.65625L15.3438 2.84375C15.5312 3.03125 15.5312 3.34375 15.3438 3.53125Z" fill="#BDC3C7" />
                                                                            </svg>

                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                :
                                                <form className="dashboard-payment-container-data-section-content">
                                                    <div className="dashboard-payment-container-data-section-content-container">
                                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                                        <div className="dashboard-payment-container-data-section-content-container-form">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                                {
                                                                    localStorage.getItem("isOperNo") === "1" &&
                                                                    <div style={{ width: '100%' }}>
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Convertation number')} <span style={{ color: 'red' }}>*</span></label>
                                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                                <input type="text" disabled={true} value={custOperNo} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={e => this.setState({ converNumber: e.target.value, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (e.target.value.length > 0 && e.target.value !== 0 && valueSelling.length > 0 && valueSelling !== 0) : false })} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Selling account')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ dropdownVisibilitySellingAccount: false, sellingAccountStatus: dropdownVisibilitySellingAccount ? selectedSellingAccount ? 2 : 1 : sellingAccountStatus }) }}>
                                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${sellingAccountStatus === 1 ? "input-outline" : ""}`}>
                                                                            <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} onClick={this.changeDropdownVisibilitySellingAccount}>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{sellingAccounts && sellingIndex >= 0 && sellingAccounts[sellingIndex].iban + ' / ' + sellingAccounts[sellingIndex].organizedCurrentBalance.wholePart + '.' + sellingAccounts[sellingIndex].organizedCurrentBalance.fractionalPart + ' ' + sellingAccounts[sellingIndex].currName}</div>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            {
                                                                                sellingAccounts.length !== 0 &&
                                                                                <ul className={(dropdownVisibilitySellingAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                    {
                                                                                        sellingAccounts.map((account, index) => (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountSellingId.bind(this, account.accountId, account.iban, index)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Buying account')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ dropdownVisibilityBuyingAccount: false, buyingAccountStatus: dropdownVisibilityBuyingAccount ? selectedBuyingAccount ? 2 : 1 : buyingAccountStatus }) }}>
                                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${buyingAccountStatus === 1 ? "input-outline" : ""}`}>
                                                                            <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} onClick={this.changeDropdownVisibilityBuyingAccount}>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{buyingAccounts && buyingIndex >= 0 && buyingAccounts[buyingIndex].iban + ' / ' + buyingAccounts[buyingIndex].organizedCurrentBalance.wholePart + '.' + buyingAccounts[buyingIndex].organizedCurrentBalance.fractionalPart + ' ' + buyingAccounts[buyingIndex].currName}</div>
                                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                            {
                                                                                buyingAccounts.length !== 0 &&
                                                                                <ul className={(dropdownVisibilityBuyingAccount === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                                    {
                                                                                        buyingAccounts.map((account, index) => (
                                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountBuyingId.bind(this, account.accountId, account.iban, index)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </OutsideClickHandler>
                                                                </div>

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Currency amount')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${(selectedBuyingAccount && selectedBuyingAccount.length > 0 && selectedSellingAccount && selectedSellingAccount.length > 0 && valueSelling === "") ? "input-outline" : ""}`}>
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={valueSelling} disabled={step > 1 || !(selectedBuyingAccount && selectedBuyingAccount.length > 0 && selectedSellingAccount && selectedSellingAccount.length > 0)} placeholder="0" onChange={e => /^\d*\.{0,1}(\d{1,2})?$/.test(e.target.value) && this.setState({ valueSelling: e.target.value }, () => this.onChangeSellingAmount())} />
                                                                        <div className={step > 1 ?
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                        }>{currencySelling}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('AZN amount')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={valueBuying} disabled={true/*step > 1 || !(selectedBuyingAccount && selectedBuyingAccount.length > 0 && selectedSellingAccount && selectedSellingAccount.length > 0)*/} placeholder="0" onChange={e => /^\d*\.?\d*$/.test(e.target.value) && this.setState({ valueBuying: e.target.value }, () => this.onChangeBuyingAmount())} />
                                                                        <div className={step > 1 ?
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                        }>{currencyBuying}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Exchange rate')} <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                                        <input type="text" value={exchangeRate} onChange={e => /^\d*\.?\d*$/.test(e.target.value) && this.setState({ exchangeRate: e.target.value }, () => this.onChangeExchangeRate())} className="dashboard-payment-container-data-section-content-container-form-input" disabled={!Boolean(selectedSellingAccount) || step > 1 || !exchangeEditable} placeholder="" />
                                                                        <button onClick={e => { e.preventDefault(); this.setState({ exchangeEditable: !exchangeEditable }) }} className={!Boolean(selectedSellingAccount) || step > 1 ?
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                                            "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                                        }>
                                                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M16.4062 1.78125L15.2188 0.59375C14.8438 0.21875 14.3125 0 13.8125 0C13.3125 0 12.7812 0.21875 12.4062 0.59375L10.0625 2.9375L9 4L1.375 11.625L1 15.1875C0.9375 15.625 1.28125 16 1.71875 16C1.75 16 1.78125 16 1.8125 16L5.375 15.625L13 8L14.0625 6.9375L16.4062 4.59375C17.1875 3.8125 17.1875 2.5625 16.4062 1.78125ZM4.6875 14.1875L2.59375 14.4062L2.8125 12.3125L10.0312 5.0625L11 4.09375L12.9062 6L11.9375 6.96875L4.6875 14.1875ZM15.3438 3.53125L13.9688 4.9375L12.0625 3.03125L13.4688 1.65625C13.5938 1.53125 13.75 1.5 13.8125 1.5C13.875 1.5 14.0312 1.53125 14.1562 1.65625L15.3438 2.84375C15.5312 3.03125 15.5312 3.34375 15.3438 3.53125Z" fill="#BDC3C7" />
                                                                            </svg>

                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                    {
                                        step === 1 ?
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How to convert?')}</h3>
                                                </div>
                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" alt="transferConfirmationImg" />
                                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                                        <p>{t('convertation content')}</p>
                                                    </div>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button onClick={this.showTransferConvertation} disabled={!confirmedOperation} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${!confirmedOperation && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('continue')}</button>
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="dashboard-payment-container-confirmation-section">
                                                <div className="dashboard-payment-container-confirmation-section-header">
                                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                                </div>

                                                <div className="dashboard-payment-container-confirmation-section-content">
                                                    {
                                                        step > 1 && this.props.match.params.type == '1' ?
                                                            <Fragment>
                                                                <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter('AZN')}</span>
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(valueBuying).part1}</span>
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(valueBuying).part2}</span>
                                                                </h3>
                                                                <ul className="dashboard-payment-container-confirmation-section-list">
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Conversion')}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Buying account')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{buyingAccounts && buyingIndex >= 0 && buyingAccounts[buyingIndex].iban}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Selling account')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{sellingAccounts && sellingIndex >= 0 && sellingAccounts[sellingIndex].iban}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('AZN amount')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(valueBuying).part1}.{this.amountSplit(valueBuying).part2} AZN</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Currency amount')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(valueSelling).part1}.{this.amountSplit(valueSelling).part2} {currencySelling}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('feeAmount')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{feeAmount} {this.state.sellingAccounts[this.state.sellingIndex].currName}</p>
                                                                    </li>

                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                            <button onClick={e => this.onSubmitForm()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                                            <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter(currencySelling)}</span>
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(valueSelling).part1}</span>
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(valueSelling).part2}</span>
                                                                </h3>
                                                                <ul className="dashboard-payment-container-confirmation-section-list">
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Conversion')}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Buying account')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{buyingAccounts && buyingIndex >= 0 && buyingAccounts[buyingIndex].iban}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Selling account')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{sellingAccounts && sellingIndex >= 0 && sellingAccounts[sellingIndex].iban}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('AZN amount')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(valueBuying).part1}.{this.amountSplit(valueBuying).part2} AZN</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Currency amount')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(valueSelling).part1}.{this.amountSplit(valueSelling).part2} {currencySelling}</p>
                                                                    </li>
                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('feeAmount')}</p>
                                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{feeAmount} {this.state.buyingAccounts[this.state.buyingIndex].currName}</p>
                                                                    </li>

                                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                            <button onClick={e => this.onSubmitForm()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                                            <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                            </Fragment>
                                                    }
                                                </div>
                                            </div>
                                    }
                                </div>
                    }
                </Fragment>
            </Fragment>
        )
    }
}
export default translate("translation")(CreateTransferConvertation);
