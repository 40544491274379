import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import AuthHeader from "../AuthHeader/AuthHeader";
import { request } from "../../../config";
import eyeIcon from "./../../Login/img/eye-icon-form.svg";
import eyeSlashIcon from "./../../Login/img/eye-slash-icon-form.svg";

const CreatePassword = ({ translate, updateAppState }) => {
  const [errMessage, setErrMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(eyeIcon);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [repeatPasswordIcon, setRepeatPasswordIcon] = useState(eyeIcon);

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  let history = useHistory();
  const { state } = useLocation();

  const setPasswordVisibility = (showPwd, setShowPwd, setPwdIcon) => {
    setShowPwd(!showPwd);
    showPwd ? setPwdIcon(eyeIcon) : setPwdIcon(eyeSlashIcon);
  };

  const registerIndividualMobile = (data) => {
    updateAppState("isLoadingTransparentBackground", true);

    let requestBody = {
      ...data,
      custId: state.custId,
      verifyToken: state.verifyToken,
    };

    fetch(request("register/registrationIndividualMobilev1", requestBody))
      .then((res) => res.json())
      .then(
        (registerIndividualMobileResponse) => {
          if (registerIndividualMobileResponse.status.statusCode === 1) {
            updateAppState("isLoadingTransparentBackground", false);
            history.push("/sign-up/not-a-bank-customer/registration/success", {
              custId: state.custId,
            });
          } else {
            updateAppState("isLoadingTransparentBackground", false);
            setErrMessage(
              translate(registerIndividualMobileResponse.status.statusCode)
            );
          }
        },
        (error) => {
          history.push("/technical-break");
        }
      );
  };

  useEffect(() => {
    state === undefined && history.push("/sign-up/not-a-bank-customer");
  }, []);

  return (
    <section className="ibank-create-password header-sign-section">
      <div className="header-sign-section-container">
        <AuthHeader
          title={translate("complate registration")}
          desc={[
            translate("enter your password to complete the registration"),
            translate("password content"),
          ]}
        />
        <form
          className="header-sign-section-container-form"
          onSubmit={handleSubmit(registerIndividualMobile)}
        >
          <div className="header-sign-section-container-form-group">
            <label className="header-sign-section-container-form-group-label">
              {translate("password")}
            </label>
            <div className="header-sign-section-container-form-group-box">
              <input
                type={showPassword ? "password" : "text"}
                className="header-sign-section-container-form-group-box-input"
                placeholder={translate("enter the password")}
                {...register("password", { required: true, minLength: 6 })}
              />
              <div className="header-sign-section-container-form-group-box-icon">
                <img
                  src={passwordIcon}
                  className="header-sign-section-container-form-group-box-icon-img header-sign-section-container-form-group-box-icon-img__password"
                  alt="User password"
                  onClick={() =>
                    setPasswordVisibility(
                      showPassword,
                      setShowPassword,
                      setPasswordIcon
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="header-sign-section-container-form-group">
            <label className="header-sign-section-container-form-group-label">
              {translate("repeat password")}
            </label>
            <div className="header-sign-section-container-form-group-box">
              <input
                type={showRepeatPassword ? "password" : "text"}
                className="header-sign-section-container-form-group-box-input"
                placeholder={translate("enter the password again")}
                {...register("repeatPassword", {
                  required: true,
                  minLength: 6,
                })}
              />
              <div className="header-sign-section-container-form-group-box-icon">
                <img
                  src={repeatPasswordIcon}
                  className="header-sign-section-container-form-group-box-icon-img header-sign-section-container-form-group-box-icon-img__password"
                  alt="repeat password"
                  onClick={() =>
                    setPasswordVisibility(
                      showRepeatPassword,
                      setShowRepeatPassword,
                      setRepeatPasswordIcon
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="registration-container-validation-feedback">
            {errMessage ? (
              <p className="registration-container-validation-feedback-message">
                {errMessage}
              </p>
            ) : null}
          </div>
          <button
            className="header-sign-section-container-form-button"
            disabled={!isDirty || !isValid}
          >
            {translate("continue")}
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreatePassword;
