import React, { Component } from 'react';
import privacyPolicy from './../../img/privacy-policy.png';
import { translate } from 'react-i18next';

class PrivacyPolicy extends Component {

    componentDidMount() {
        this.props.updateSettingsState('settingsTitle', null);
    }

    render() {
        let { t } = this.props;
        return (
            <div className="dashboard-settings-section-other-settings">
                <img src={privacyPolicy} className="dashboard-settings-section-other-settings-image" />
                <h3 className="dashboard-settings-section-other-settings-title">{t('privacy policy title')}</h3>
                <div className="dashboard-settings-section-other-settings-content">
                    <p>{t('privacy policy content')}</p>
                </div>
            </div>
        )
    }
}


export default translate("translation")(PrivacyPolicy);