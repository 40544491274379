import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { set } from 'date-fns';

const BlueCheckbox = withStyles({
    root: {
        color: "#FFFFFF",
        "& svg": {
            fill: "#5d6169",
            width: "30px",
            height: "30px",
        },
        '&:hover': {
            background: 'transparent',
        },
        '&$checked': {
            "& svg": {
                fill: "#0179D2",
            }
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels(props) {
    const [state, setState] = React.useState({
        checked: false
    });

    React.useEffect(() => {
        setState({checked: props.checked})
    }, [props.checked])

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        props.onChecked(event.target.checked)
    };

    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <BlueCheckbox
                        checked={state.checked}
                        onChange={handleChange}
                        name="checked"
                    />
                }
            />
        </FormGroup>
    );
}
