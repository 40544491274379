import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { request } from './../../../config';
import CreateSalaryModal from './../../../copms/Modal/CreateSalaryModal';
import $ from 'jquery';
import Loading from './../Loading/Loading';
import OutsideClickHandler from 'react-outside-click-handler';
import currencyFilter from '../../../helpers/currencyFilter';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
class NewOperation extends Component {
    state = {
        errorMessageCode: null,
        createFeedbackMessage: false,
        open: false,
        accounts: [],
        dropdownVisibility: false,

        dtAccountId: null,
        dtAccountIban: null,
        selectedAccount: null,
        dtAccontIdValidation: false,

        excelFile: null,
        fileName: "",
        excelFileValidation: false,

        amount: null,
        organizedAmount: {},
        amountValidation: false,

        purpose: null,
        purposeValidation: false,

        bytes: null,
        emptyAccount: false,
        accountData: false,

        amountOutline: false,
        purposeOutline: false,
        excelFileOutline: false,

        dtAccontIdCount: 0,
        step: 1,
        feeAmount: 0,
    }
    updateOpen = (value) => {
        this.setState({ open: value });
    }
    updateAccount = (account, event) => {
        this.setState({
            dtAccountId: account.accountId,
            dtAccountIban: account.iban,
            selectedAccount: event.target.textContent,
            dtAccontIdValidation: true,
            dropdownVisibility: false
        });
    }
    showAccounts = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 1
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 1
        }

        fetch(request("account/getNoCardAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                        this.setState({ emptyAccount: true });
                    }
                    getNoCardAccountListForCustomerResponse.accountList = getNoCardAccountListForCustomerResponse.accountList.filter(account => account.currName === "AZN");
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        // getNoCardAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getNoCardAccountListForCustomerResponse.accountList[i].currName);
                    }
                    this.setState({ accounts: getNoCardAccountListForCustomerResponse.accountList });

                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    changeDropdownVisibility = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibility: !this.state.dropdownVisibility });
        if (!this.state.dtAccontIdValidation) {
            this.setState({ dtAccontIdCount: ++this.state.dtAccontIdCount });
        }
    }
    changeFileName = (event) => {
        if (event.keyCode === 46 || event.keyCode === 8) {
            this.setState({ excelFile: null, fileName: "", excelFileValidation: false });
        }
    }
    chooseFile = (event) => {
        if (event && event.target && event.target.files && event.target.files[0] && event.target.files[0].name) {
            this.getBase64(event.target.files[0], (result) => {
                this.setState({ excelFile: result.split('base64,')[1] });
            });
            this.setState({ fileName: event.target.files[0].name, excelFileValidation: true });
            this.setState({ excelFileOutline: false });
        }
    }
    getBase64(file, cb) {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                cb(reader.result)
            };
            reader.onerror = function (error) {

            };
        }
    }
    checkPurpose = (event) => {
        this.setState({ purpose: event.target.value });
        if (event.target.value.trim() === "") {
            this.setState({ purposeValidation: false });
        }
        else {
            this.setState({ purposeValidation: true });
            this.setState({ purposeOutline: false });
        }
    }
    checkAmount = (event) => {
        this.setState({ amountValidation: false });
        let mask = /[^0-9.]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.indexOf('.') !== -1) {
            let amount = event.target.value;
            let firstPointIndex = amount.indexOf('.');
            let wholePartAmount = amount.substr(0, firstPointIndex);
            let fractionalPartAmount = "";
            let afterPoint = 0;
            for (let i = firstPointIndex + 1; i < amount.length; i++) {
                if (amount[i] !== '.') {
                    fractionalPartAmount += amount[i];
                    afterPoint++;
                }
                if (afterPoint === 2) break;
            }
            if (firstPointIndex === 0) {
                wholePartAmount = 0;
            }
            event.target.value = wholePartAmount + '.' + fractionalPartAmount;
            this.setState({ amount: wholePartAmount + '.' + fractionalPartAmount });
            this.setState({ organizedAmount: { whole: parseInt(wholePartAmount), fractional: parseInt(fractionalPartAmount) } });
        }
        else {
            this.setState({ amount: event.target.value });
        }
        // =========BALANCE=========
        let amount = event.target.value;
        if (amount.toString() === "0") {
            this.setState({ organizedAmount: { whole: "0", fractional: "00" } });
        }
        else {
            if (amount.toString().indexOf('.') !== -1) {
                let currentBalanceArray = amount.toString().split('.');
                let fractional = currentBalanceArray[1].length === 1 ? currentBalanceArray[1] + '0' : currentBalanceArray[1];
                this.setState({ organizedAmount: { whole: currentBalanceArray[0], fractional: fractional } });
            }
            else {
                if (event.target.value !== "") {
                    this.setState({ organizedAmount: { whole: amount.toString(), fractional: "00" } });
                }
            }
        }
        if (isNaN(parseFloat(event.target.value)) === true || parseFloat(amount) <= 0) {
            this.setState({ amountValidation: false });
        }
        else {
            this.setState({ amountValidation: true });
            this.setState({ amountOutline: false });
        }
    }
    downloadExcelExample = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        fetch(request("util/downloadExcelExample", null, "GET"))
            .then(res => res.json())
            .then((downloadExcelExampleResponse) => {
                if (downloadExcelExampleResponse.status.statusCode !== 1) {
                    if (downloadExcelExampleResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(downloadExcelExampleResponse.status.statusCode));
                    }
                }
                else {
                    let arrrayBuffer = base64ToArrayBuffer(downloadExcelExampleResponse.bytes); //data is the base64 encoded string
                    saveFile(downloadExcelExampleResponse.fileName, "application/xlsx", arrrayBuffer);

                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    getTransferCommission = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getTransferComissionRequestBody = {
            operationNameId: 6,
            operationTypeId: 14,
            accountId: this.state.dtAccountId,
            amount: this.state.amount,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        fetch(request("operation/getCommission", getTransferComissionRequestBody))
            .then(res => res.text())
            .then((getTransferComissionResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferComissionResponse = JSON.parse(getTransferComissionResponseJSON);
                if (getTransferComissionResponse.status.statusCode !== 1) {
                    if (getTransferComissionResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, errorMessageCode: getTransferComissionResponse.status.statusCode });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ feeAmount: getTransferComissionResponse.commission.amount, step: 2 });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    createSalaryOperation = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createExcelPackCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            dtAccountId: this.state.dtAccountId,
            excel: this.state.excelFile,
            excelPurpose: this.state.purpose,
            amount: this.state.amount
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),

            dtAccountId: this.state.dtAccountId,
            excel: this.state.excelFile,
            excelPurpose: this.state.purpose,
            amount: this.state.amount
        }

        fetch(request("salary/createExcelPack", createExcelPackCustomerRequestBody))
            .then(res => res.text())
            .then((createExcelPackCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createExcelPackCustomerResponse = JSON.parse(createExcelPackCustomerResponseJSON);
                if (createExcelPackCustomerResponse.status.statusCode !== 1) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    if (createExcelPackCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        if (createExcelPackCustomerResponse.status.statusCode === 314) {
                            this.setState({ createFeedbackMessage: false, open: true, errorMessageCode: createExcelPackCustomerResponse.status.statusCode, bytes: createExcelPackCustomerResponse.excel });
                        }
                        else {
                            this.setState({ createFeedbackMessage: false, open: true, errorMessageCode: createExcelPackCustomerResponse.status.statusCode, bytes: null });
                        }
                    }
                }
                else {
                    this.setState({ createFeedbackMessage: true, open: true, errorMessageCode: null, bytes: null });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    setAmountOutline = () => {
        if (!this.state.amountValidation) {
            this.setState({ amountOutline: true });
        }
        else {
            this.setState({ amountOutline: false });
        }
    }
    setPurposeOutline = () => {
        if (!this.state.purposeValidation) {
            this.setState({ purposeOutline: true });
        }
        else {
            this.setState({ purposeOutline: false });
        }
    }
    setFileOutline = () => {
        if (!this.state.excelFileValidation) {
            this.setState({ excelFileOutline: true });
        }
        else {
            this.setState({ excelFileOutline: false });
        }
    }
    componentDidMount() {
        this.showAccounts();
    }
    render() {
        let { t, history, updateAppState } = this.props;
        let { selectedAccount, dropdownVisibility, accounts, errorMessageCode, createFeedbackMessage, open,
            dtAccontIdValidation, excelFileValidation, purposeValidation, amountValidation, bytes, isLoading, emptyAccount,
            amountOutline, purposeOutline, excelFileOutline, dtAccontIdCount, dtAccountIban, step, feeAmount } = this.state;

        return (
            <Fragment>
                {/* <CreateSalaryModal open={true} updateOpen={this.updateOpen} errorClose={() => {}} createFeedbackMessage={createFeedbackMessage} errorMessageCode={errorMessageCode} history={history} /> */}
                <CreateSalaryModal open={open} updateOpen={this.updateOpen} successClose={() => { this.props.history.push('/dashboard/salary/operations') }} errorClose={() => { }} createFeedbackMessage={createFeedbackMessage} errorMessageCode={errorMessageCode} history={history} bytes={bytes} updateAppState={updateAppState} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('New operation')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-payment-container">
                            <div className="dashboard-payment-container-data-section">
                                <div className="dashboard-payment-container-data-section-header">
                                    <h3 className="dashboard-payment-container-data-section-header-title">{t('Card income')}</h3>
                                </div>
                                <form className="dashboard-payment-container-data-section-content">
                                    <div className="dashboard-payment-container-data-section-content-container">
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('The sender of the funds')}</h3>
                                        <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by account')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={() => { this.setState({ dropdownVisibility: false }) }}>
                                                        <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (dtAccontIdCount > 1 && !dtAccontIdValidation || dtAccontIdCount === 1 && !dtAccontIdValidation && !dropdownVisibility ? "input-outline" : "")}>
                                                            <button className="dashboard-payment-container-data-section-content-container-form-select-box-button" onClick={this.changeDropdownVisibility} disabled={step == 2}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                                    {
                                                                        emptyAccount === true ? t(126) : selectedAccount
                                                                    }
                                                                    {/* {selectedAccount} */}
                                                                </div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            {
                                                                accounts.length !== 0 &&
                                                                <ul className={(dropdownVisibility === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        accounts.map(account => (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateAccount.bind(this, account)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content-container">
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('The purpose of the payment')}</h3>
                                        <div className="dashboard-payment-container-data-section-content-container-form">
                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('sum')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" placeholder="0.00" onChange={this.checkAmount} onBlur={this.setAmountOutline} disabled={step == 2} />
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                                    </div>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Appointment')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (purposeOutline ? "input-outline" : "")}>
                                                        <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={this.checkPurpose} onBlur={this.setPurposeOutline} disabled={step == 2} />
                                                    </div>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Salary file')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (excelFileOutline ? "input-outline" : "")}>
                                                        <input type="text" value={this.state.fileName} className="dashboard-payment-container-data-section-content-container-form-input" onKeyDown={this.changeFileName} onBlur={this.setFileOutline} disabled={step == 2} />
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__choose ${step == 2 ? 'disabled' : ''}`}>{t('Choose')}</div>
                                                        <input id="excelFile" type="file" className="dashboard-payment-container-data-section-content-container-form-input__file" multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={step == 2} onChange={this.chooseFile} />
                                                    </div>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable" style={{ marginTop: '48px' }} onClick={this.downloadExcelExample}>
                                                        <svg className="dashboard-payment-container-data-section-content-container-form-button-icon" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.07422 14.75C4.97656 14.75 5.87891 14.4219 6.5625 13.7383L12.5234 7.77734C12.6328 7.64062 12.6328 7.44922 12.5234 7.3125L12.3594 7.14844C12.2227 7.03906 12.0312 7.03906 11.8945 7.14844L5.93359 13.1094C4.89453 14.1484 3.22656 14.1484 2.1875 13.1094C1.14844 12.0703 1.14844 10.4023 2.1875 9.36328L9.35156 2.19922C10.0898 1.46094 11.293 1.46094 12.0039 2.19922C12.7422 2.91016 12.7422 4.11328 12.0039 4.85156L5.93359 10.9492C5.49609 11.3594 4.78516 11.3594 4.34766 10.9492C3.9375 10.5117 3.9375 9.80078 4.34766 9.36328L9.02344 4.71484C9.13281 4.57812 9.13281 4.38672 9.02344 4.25L8.85938 4.08594C8.72266 3.97656 8.53125 3.97656 8.39453 4.08594L3.74609 8.76172C2.95312 9.52734 2.95312 10.7852 3.74609 11.5508C4.51172 12.3438 5.76953 12.3438 6.53516 11.5508L12.6328 5.48047C13.6992 4.38672 13.6992 2.63672 12.6328 1.57031C11.5664 0.503906 9.81641 0.503906 8.72266 1.57031L1.55859 8.73438C0.191406 10.1016 0.191406 12.3438 1.55859 13.7383C2.24219 14.4219 3.17188 14.75 4.07422 14.75Z" fill="black" />
                                                        </svg>
                                                        <span className="dashboard-payment-container-data-section-content-container-form-button-title">{t("Download the sample file")}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                {
                                                    dtAccontIdValidation && excelFileValidation && amountValidation && purposeValidation && step != 2 ?
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.getTransferCommission}>{t('continue')}</button>
                                                        :
                                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__left dashboard-payment-container-data-section-content-container-form-footer-button__disable" disabled>{t('continue')}</button>
                                                }
                                                <button type='button' className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right" onClick={() => this.setState({ step: 1 })}>{t('cancel')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="dashboard-payment-container-confirmation-section">
                                {
                                    step == 2 ?
                                        <>
                                            <div className="dashboard-payment-container-confirmation-section-header">
                                                <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Payment confirmation')}</h3>
                                            </div>
                                            <div className="dashboard-payment-container-confirmation-section-content">
                                                <Fragment>
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                        <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter("AZN")}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(this.state.amount).part1}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(this.state.amount).part2}</span>
                                                    </h3>
                                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('From account')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtAccountIban}</p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Appointment')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.state.purpose}</p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('feeAmount')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{feeAmount} AZN</p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                <button onClick={this.createSalaryOperation} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                                <button onClick={() => this.setState({ step: 1, feeAmount: 0 })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Fragment>
                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                }
            </Fragment>
        )
    }
}
export default translate("translation")(NewOperation);
