import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import phoneIconForm from './../img/phone-icon-form.svg';
import userIconForm from './../img/user-icon-form.svg';
import {request } from "./../../../config";
import { translate } from 'react-i18next';


class LoginIndividualForAsan extends Component {
    state = {
        isDisabled: true,
        feedbackMessage: null,
        showOtpInput: false,
        otpValue: null,
        deviceId: '',
        deviceName: '',
    }


    validateData = (inputType, event) => {
        this.setState({ checkedData: false, feedbackMessage: null });
        if (inputType === 'userId') {
            this.userId.value = this.userId.value.replace(/\D/g, '');
            if (this.userId.value.length < 6) {
                this.setState({ validateUserId: false });
            }
            else {
                if (this.userId.value.length > 6) {
                    this.userId.value = this.userId.value.substring(0, 6);
                }
                this.setState({ validateUserId: true });
            }
        }
        if (inputType === 'phoneNumber') {
            setTimeout(() => {
                if (this.phoneNumber.value.indexOf("_") === -1) {
                    this.setState({ validatePhoneNumber: true });
                }
                else {
                    this.setState({ validatePhoneNumber: false });
                }
            }, 1)
        }
        setTimeout(() => {
            if (this.state.validateUserId && this.state.validatePhoneNumber) {
                this.setState({ isDisabled: false });
            }
            else {
                this.setState({ isDisabled: true });
            }
        }, 1);
    }



    loginIndividualForAsanMethod = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let getPhoneNumber = (phoneNumber) => {
            ['+', '-', '(', ')', ' '].forEach((char) => {
                while (phoneNumber.indexOf(char) !== -1) {
                    phoneNumber =
                        phoneNumber.slice(0, phoneNumber.indexOf(char)) +
                        phoneNumber.slice(phoneNumber.indexOf(char) + 1, phoneNumber.length);
                }
            });
            return phoneNumber;
        };


        let getAuthInfoIndividualLoginRequestBody = {
            userId: this.userId.value,
            phoneNumber: getPhoneNumber(this.phoneNumber.value),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            //   otpCode: null,
        };

        // let getAuthInfoIndividualLoginHeaders = {
        //     ...headers,
        //     'User-Agent': 'YourUserAgent',
        //     'Device-Name': 'Chrome9',
        //     'Device-Id': "chrome9"
        // };
        // console.log(getAuthInfoIndividualLoginHeaders)
        // console.log(updateAppState("isLoadingTransparentBackground", true));

        // console.log(getAuthInfoIndividualLoginHeaders);

        fetch(request("register/getAuthInfoIndividualLogin", getAuthInfoIndividualLoginRequestBody))
            .then((res) => res.json())
            .then((getOtpFromAsanResponse) => {

                if (getOtpFromAsanResponse.status.statusCode !== 1) {
                    // console.log(getOtpFromAsanResponse)
                    // console.log({ ...getAuthInfoIndividualLoginRequestBody, otpCode: null })
                    this.setState({ feedbackMessage: this.props.t(getOtpFromAsanResponse.status.statusCode) });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
                else if (getOtpFromAsanResponse.otpVisible) {
                    // console.log(getOtpFromAsanResponse)

                    localStorage.setItem('userId', getAuthInfoIndividualLoginRequestBody.userId);
                    localStorage.setItem('verificationCode', getOtpFromAsanResponse.verificationCode);
                    localStorage.setItem('phoneNumber', getAuthInfoIndividualLoginRequestBody.phoneNumber);

                    // console.log("success")
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.props.history.push('/login/individual/asan/otp', { getAuthInfoIndividualLoginRequestBody, getOtpFromAsanResponse });
                    // console.log(getAuthInfoIndividualLoginRequestBody)
                    // console.log(getAuthInfoIndividualLoginHeaders)


                    // let loginIndividualForAsanRequestBody = {
                    //     // certificate: certificate,
                    //     // transactionId: transactionId,
                    //     // custId: getAuthInfoRequestBody.custId,
                    //     // challenge: getAuthInfoResponse.challenge,
                    //     verificationCode: getOtpFromAsanResponse.verificationCode,
                    //     phoneNumber: getAuthInfoIndividualLoginRequestBody.phoneNumber,
                    //     userId: getAuthInfoIndividualLoginRequestBody.userId,
                    //     sessionId: getOtpFromAsanResponse.sessionId,
                    //     token: getOtpFromAsanResponse.token,
                    //     device: "device",
                    //     location: "location",
                    //     lang: getAuthInfoIndividualLoginRequestBody.lang
                    // }
                    // console.log(loginIndividualForAsanRequestBody)
                    // // ====================================
                    // localStorage.setItem('userId', getAuthInfoIndividualLoginRequestBody.userId);
                    // localStorage.setItem('verificationCode', getOtpFromAsanResponse.verificationCode);
                    // localStorage.setItem('phoneNumber', getAuthInfoIndividualLoginRequestBody.phoneNumber);

                    // console.log("success")
                    // this.props.history.push('/login/individual/asan/confirmation', {loginIndividualForAsanRequestBody, getAuthInfoIndividualLoginHeaders});
                    // const history = useHistory();
                    // this.props.history.push('/login/individual/asan/otp');
                    // window.location.href = '/login/individual/asan/otp';


                    // this.setState({ showOtpInput: true })
                    // this.onSubmitOtp();
                    // this.props.updateAppState('isLoadingTransparentBackground', false);
                    // shouldContinue = false;

                    // const onSubmitOtp = () => {
                    //     if (shouldContinue) {
                    //       // Perform the remaining code here
                    //     }
                    //   };
                }
                else {
                    // console.log("confirm asan")
                    // console.log(getOtpFromAsanResponse)
                    // ======CUSTID======
                    // let custId = "";
                    // if (typeof (getOtpFromAsanResponse.custId) === 'object' && getOtpFromAsanResponse.custId.hasOwnProperty('c')) {
                    //     getOtpFromAsanResponse.custId.c.forEach(part => {
                    //         custId += part;
                    //     });
                    // }
                    // else {
                    //     custId += getOtpFromAsanResponse.custId;
                    // }
                    // =============
                    // let getAuthInfoRequestBody = {
                    //     userId: getOtpFromAsanRequestBodyWithoutOtp.userId,
                    //     phoneNumber: getOtpFromAsanRequestBodyWithoutOtp.phoneNumber,
                    //     // device: "iphone",
                    //     // location: "baku",
                    //     // custId: custId,
                    //     lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    //     // sessionId: getOtpFromAsanResponse.sessionId,
                    //     // token: getOtpFromAsanResponse.token,
                    //     // token:
                    // }
                    // console.log(getAuthInfoIndividualLoginRequestBody)
                    // =============
                    // fetch(request("register/getAuthInfoIndividualLogin", getAuthInfoIndividualLoginRequestBody, getAuthInfoIndividualLoginHeaders))
                    //     .then(res => res.text())
                    //     .then(getAuthInfoResponseJSON => {
                    //         const JSON = require('true-json-bigint');
                    //         let getAuthInfoResponse = JSON.parse(getAuthInfoResponseJSON);
                    //         if (getAuthInfoResponse.status.statusCode === 1) {
                    //             console.log("success to get user info")
                    //             console.log(getAuthInfoResponse)
                    // let transactionId = "";
                    // ======TRANSACTIONID======
                    // if (typeof (getAuthInfoResponse.transactionId) === 'object' && getAuthInfoResponse.transactionId.hasOwnProperty('c')) {
                    //     getAuthInfoResponse.transactionId.c.forEach(part => {
                    //         transactionId += part;
                    //     });
                    // }
                    // else {
                    //     transactionId += getAuthInfoResponse.transactionId;
                    // }
                    // ======CERTIFICATE=====
                    // let certificate = getAuthInfoResponse.certificate;
                    // while (certificate.indexOf("\r\n") !== -1) {
                    //     certificate = certificate.replace("\r\n", "");
                    // }
                    // =============
                    let loginIndividualForAsanRequestBody = {
                        // certificate: certificate,
                        // transactionId: transactionId,
                        // custId: getAuthInfoRequestBody.custId,
                        // challenge: getAuthInfoResponse.challenge,
                        verificationCode: getOtpFromAsanResponse.verificationCode,
                        phoneNumber: getAuthInfoIndividualLoginRequestBody.phoneNumber,
                        userId: getAuthInfoIndividualLoginRequestBody.userId,
                        sessionId: getOtpFromAsanResponse.sessionId,
                        token: getOtpFromAsanResponse.token,
                        device: "device",
                        location: "location",
                        lang: getAuthInfoIndividualLoginRequestBody.lang
                    }
                    // console.log(loginIndividualForAsanRequestBody)
                    // ====================================
                    localStorage.setItem('userId', getAuthInfoIndividualLoginRequestBody.userId);
                    localStorage.setItem('verificationCode', getOtpFromAsanResponse.verificationCode);
                    localStorage.setItem('phoneNumber', getAuthInfoIndividualLoginRequestBody.phoneNumber);

                    // console.log("success")
                    this.props.history.push('/login/individual/asan/confirmation', { loginIndividualForAsanRequestBody });
                    // }
                    // else {
                    //     console.log("error to get user info")

                    //     this.setState({ feedbackMessage: this.props.t(getAuthInfoResponse.status.statusCode) });
                    //     this.props.updateAppState('isLoadingTransparentBackground', false);
                    // }
                    // },
                    // (error) => {
                    //     this.props.history.push("/technical-break");
                    // }
                    // );
                    // =============

                    // fetch(request("register/getAuthInfoIndividualLogin", getOtpFromAsanRequestBodyWithoutOtp, getAuthInfoIndividualLoginHeaders))
                    //     .then((res) => res.json())
                    //     .then((getOtpFromAsanResponse) => {

                    //         if (getOtpFromAsanResponse.status.statusCode !== 1) {
                    //             console.log("no")
                    //             this.setState({ feedbackMessage: this.props.t(getOtpFromAsanResponse.status.statusCode) });
                    //             this.props.updateAppState('isLoadingTransparentBackground', false);
                    //         }
                    //         else if (getOtpFromAsanResponse.otpVisible) {
                    //             console.log(getOtpFromAsanResponse)
                    //             this.setState({ showOtpInput: true })
                    //             // this.onSubmitOtp();
                    //             this.props.updateAppState('isLoadingTransparentBackground', false);
                    //             // shouldContinue = false;

                    //             // const onSubmitOtp = () => {
                    //             //     if (shouldContinue) {
                    //             //       // Perform the remaining code here
                    //             //     }
                    //             //   };
                    //         }
                    //         else {
                    //             console.log("confirm asan")




                    //         }
                    //     },


                    //         (error) => {
                    //             console.log(error)
                    //             // this.props.history.push("/technical-break");
                    //         });



                    // ======CUSTID======
                    // let custId = "";
                    // if (typeof (getOtpFromAsanResponse.custId) === 'object' && getOtpFromAsanResponse.custId.hasOwnProperty('c')) {
                    //     getOtpFromAsanResponse.custId.c.forEach(part => {
                    //         custId += part;
                    //     });
                    // }
                    // else {
                    //     custId += getOtpFromAsanResponse.custId;
                    // }
                    // // =============
                    // let getAuthInfoRequestBody = {
                    //     userId: getAuthInfoIndividualLoginRequestBody.userId,
                    //     phoneNumber: getAuthInfoIndividualLoginRequestBody.phoneNumber,
                    //     custId: custId,
                    //     otpCode: getOtpFromAsanResponse.otpCode, 
                    //     lang: localStorage.getItem('i18nextLng').toUpperCase()
                    // }
                    // // =============
                    // fetch(request("register/getAuthInfo", getAuthInfoRequestBody))
                    //     .then(res => res.text())
                    //     .then(getAuthInfoResponseJSON => {
                    //         const JSON = require('true-json-bigint');
                    //         let getAuthInfoResponse = JSON.parse(getAuthInfoResponseJSON);
                    //         if (getAuthInfoResponse.status.statusCode === 1) {
                    //             let transactionId = "";
                    //             // ======TRANSACTIONID======
                    //             if (typeof (getAuthInfoResponse.transactionId) === 'object' && getAuthInfoResponse.transactionId.hasOwnProperty('c')) {
                    //                 getAuthInfoResponse.transactionId.c.forEach(part => {
                    //                     transactionId += part;
                    //                 });
                    //             }
                    //             else {
                    //                 transactionId += getAuthInfoResponse.transactionId;
                    //             }
                    //             // ======CERTIFICATE=====
                    //             let certificate = getAuthInfoResponse.certificate;
                    //             while (certificate.indexOf("\r\n") !== -1) {
                    //                 certificate = certificate.replace("\r\n", "");
                    //             }
                    //             // =============
                    //             let loginIndividualForAsanRequestBody = {
                    //                 certificate: certificate,
                    //                 transactionId: transactionId,
                    //                 custId: getAuthInfoRequestBody.custId,
                    //                 challenge: getAuthInfoResponse.challenge,
                    //                 verificationCode: getAuthInfoResponse.verificationCode,
                    //                 phoneNumber: getAuthInfoResponse.phoneNumber,
                    //                 userId: getAuthInfoResponse.userId,
                    //                 device: "device",
                    //                 location: "location",
                    //                 lang: localStorage.getItem('i18nextLng').toUpperCase()
                    //             }
                    //             // ====================================
                    //             localStorage.setItem('userId', getAuthInfoResponse.userId);
                    //             localStorage.setItem('phoneNumber', getAuthInfoResponse.phoneNumber);
                    //             this.props.history.push('/login/individual/asan/confirmation', loginIndividualForAsanRequestBody);
                    //         }
                    //         else {
                    //             this.setState({ feedbackMessage: this.props.t(getAuthInfoResponse.status.statusCode) });
                    //             this.props.updateAppState('isLoadingTransparentBackground', false);
                    //         }
                    //     },
                    //         (error) => {
                    //             this.props.history.push("/technical-break");
                    //         });
                    // =============
                }
            },


                (error) => {
                    // console.log(error)
                    this.props.history.push("/technical-break");
                });



    }


    // onSubmitOtp = (event) => {

    //     event.preventDefault();
    //     this.props.updateAppState('isLoadingTransparentBackground', true);

    //     let getPhoneNumber = (phoneNumber) => {
    //         ['+', '-', '(', ')', ' '].forEach((char) => {
    //             while (phoneNumber.indexOf(char) !== -1) {
    //                 phoneNumber =
    //                     phoneNumber.slice(0, phoneNumber.indexOf(char)) +
    //                     phoneNumber.slice(phoneNumber.indexOf(char) + 1, phoneNumber.length);
    //             }
    //         });
    //         return phoneNumber;
    //     };

    //     let getAuthInfoIndividualLoginRequestBody = {
    //         userId: this.userId.value,
    //         phoneNumber: getPhoneNumber(this.phoneNumber.value),
    //         lang: localStorage.getItem('i18nextLng').toUpperCase(),
    //         //   otpCode: null,
    //     };

    //     let getAuthInfoIndividualLoginHeaders = {
    //         ...headers,
    //         'User-Agent': 'YourUserAgent',
    //         'Device-Name': 'fsfs',
    //         'Device-Id': 'fsfs'
    //     };

    //     console.log(getAuthInfoIndividualLoginHeaders);

    //     let getOtpFromAsanRequestBodyWithOtp = { ...getAuthInfoIndividualLoginRequestBody, otpCode: this.otpCode.value }


    //     console.log("onSubmitOtp")

    //     fetch(requestWithHeader("register/getAuthInfoIndividualLogin", getOtpFromAsanRequestBodyWithOtp, getAuthInfoIndividualLoginHeaders))
    //         // console.log("hel1")

    //         .then(res => res.json())
    //         .then((checkOtpForAsanLoginResponse) => {
    //             console.log("hel2")

    //             if (checkOtpForAsanLoginResponse.status.statusCode !== 1) {
    //                 console.log("hel3")

    //                 console.log(checkOtpForAsanLoginResponse)
    //                 // this.setState({ feedbackMessage: this.props.t(checkOtpForAsanLoginResponse.status.statusCode) });
    //                 // this.props.updateAppState('isLoadingTransparentBackground', false);
    //             }
    //             else {
    //                 console.log("bravo")
    //                 console.log(checkOtpForAsanLoginResponse)

    //                 // ======CUSTID======
    //                 // let custId = "";
    //                 // if (typeof (checkOtpForAsanLoginResponse.custId) === 'object' && checkOtpForAsanLoginResponse.custId.hasOwnProperty('c')) {
    //                 //     checkOtpForAsanLoginResponse.custId.c.forEach(part => {
    //                 //         custId += part;
    //                 //     });
    //                 // }
    //                 // else {
    //                 //     custId += checkOtpForAsanLoginResponse.custId;
    //                 // }
    //                 // // =============
    //                 // let getAuthInfoRequestBody = {
    //                 //     userId: getAuthInfoIndividualLoginRequestBody.userId,
    //                 //     phoneNumber: getAuthInfoIndividualLoginRequestBody.phoneNumber,
    //                 //     custId: custId,
    //                 //     otpCode: checkOtpForAsanLoginResponse.otpCode,
    //                 //     lang: localStorage.getItem('i18nextLng').toUpperCase()
    //                 // }
    //                 // // =============
    //                 // fetch(request("register/getAuthInfo", getAuthInfoRequestBody))
    //                 //     .then(res => res.text())
    //                 //     .then(getAuthInfoResponseJSON => {
    //                 //         const JSON = require('true-json-bigint');
    //                 //         let getAuthInfoResponse = JSON.parse(getAuthInfoResponseJSON);
    //                 //         if (getAuthInfoResponse.status.statusCode === 1) {
    //                 //             let transactionId = "";
    //                 //             // ======TRANSACTIONID======
    //                 //             if (typeof (getAuthInfoResponse.transactionId) === 'object' && getAuthInfoResponse.transactionId.hasOwnProperty('c')) {
    //                 //                 getAuthInfoResponse.transactionId.c.forEach(part => {
    //                 //                     transactionId += part;
    //                 //                 });
    //                 //             }
    //                 //             else {
    //                 //                 transactionId += getAuthInfoResponse.transactionId;
    //                 //             }
    //                 //             // ======CERTIFICATE=====
    //                 //             let certificate = getAuthInfoResponse.certificate;
    //                 //             while (certificate.indexOf("\r\n") !== -1) {
    //                 //                 certificate = certificate.replace("\r\n", "");
    //                 //             }
    //                 //             // =============
    //                 //             let loginIndividualForAsanRequestBody = {
    //                 //                 certificate: certificate,
    //                 //                 transactionId: transactionId,
    //                 //                 custId: getAuthInfoRequestBody.custId,
    //                 //                 challenge: getAuthInfoResponse.challenge,
    //                 //                 verificationCode: getAuthInfoResponse.verificationCode,
    //                 //                 phoneNumber: getAuthInfoResponse.phoneNumber,
    //                 //                 userId: getAuthInfoResponse.userId,
    //                 //                 device: "device",
    //                 //                 location: "location",
    //                 //                 lang: localStorage.getItem('i18nextLng').toUpperCase()
    //                 //             }
    //                 //             // ====================================
    //                 //             localStorage.setItem('userId', getAuthInfoResponse.userId);
    //                 //             localStorage.setItem('phoneNumber', getAuthInfoResponse.phoneNumber);
    //                 //             this.props.history.push('/login/individual/asan/confirmation', loginIndividualForAsanRequestBody);
    //                 //         }
    //                 //         else {
    //                 //             this.setState({ feedbackMessage: this.props.t(getAuthInfoResponse.status.statusCode) });
    //                 //             this.props.updateAppState('isLoadingTransparentBackground', false);
    //                 //         }
    //                 //     },
    //                 //         (error) => {
    //                 //             this.props.history.push("/technical-break");
    //                 //         });
    //                 // =============
    //             }
    //         },
    //             (error) => {
    //                 console.log(error)
    //                 // this.props.history.push("/technical-break");
    //             })
    //     // this.setState({ step: 1 });
    // };

    componentDidMount() {
        this.props.updateAppState('isLoading', false);
        this.props.updateIsAsan(true);
        this.props.updateIsJuridical(false);
        if (this.props.location.state !== undefined) {
            this.setState({ feedbackMessage: this.props.location.state });
        }
        // this.getDeviceInfo()
        // this.generateFingerprint();

        //   this.getData();
        // console.log(data)
    }

    render() {
        let { isDisabled, feedbackMessage, showOtpInput, otpValue } = this.state;
        let { t } = this.props;
        // const { deviceID, browserName, browserOS } = this.getDeviceInfo();

        return (
            <form className="header-sign-section-container-form">
                <div className="header-sign-section-container-form-group">
                    <label className="header-sign-section-container-form-group-label">{t('phone number')}</label>
                    <div className="header-sign-section-container-form-group-box">
                        <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" className="header-sign-section-container-form-group-box-input" placeholder="+994 (__) ___-__-__" ref={(input) => { this.phoneNumber = input }} onInput={this.validateData.bind(this, 'phoneNumber')} />
                        <div className="header-sign-section-container-form-group-box-icon">
                            <img src={phoneIconForm} className="header-sign-section-container-form-group-box-icon-img  header-sign-section-container-form-group-box-icon-img__phone" alt="Phone icon" />
                        </div>
                    </div>
                </div>
                <div className="header-sign-section-container-form-group">
                    <label className="header-sign-section-container-form-group-label">{t('asan user id')}</label>
                    <div className="header-sign-section-container-form-group-box">
                        <input type="text" className="header-sign-section-container-form-group-box-input" placeholder={t('asan user id')} ref={(input) => { this.userId = input }} onInput={this.validateData.bind(this, 'userId')} />
                        <div className="header-sign-section-container-form-group-box-icon">
                            <img src={userIconForm} className="header-sign-section-container-form-group-box-icon-img header-sign-section-container-form-group-box-icon-img__user" alt="User icon" />
                        </div>
                    </div>
                </div>
                <div className="header-sign-section-container-form-validation-feedback">
                    {
                        feedbackMessage ? <p className="header-sign-section-container-form-validation-feedback-message">{feedbackMessage} </p> : null
                    }
                </div>
                <button className="header-sign-section-container-form-button"
                    disabled={isDisabled}
                    onClick={this.loginIndividualForAsanMethod}>{t('login')}</button>
            </form>
        )
    }
}


export default translate("translation")(LoginIndividualForAsan);