import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import Loading from '../Loading/Loading';
import img from './../img/image-999.png';
import CheckboxMinus from './../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../copms/Checkbox/CheckboxTick';
import { request } from "./../../../config";
import YesNoModal from './../../../copms/Modal/YesNoModal';
import iconNotificationNot from '../img/notifications_not.svg';

class Notification extends Component {
    state = {
        selectedList: [],
        deleteOpen: false,
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return 'Az'
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    componentDidMount() {
        if (this.props.match.params.id && this.props.notifications.find(el => el.id === this.props.match.params.id) && this.props.notifications.find(el => el.id === this.props.match.params.id).isRead !== 1) {
            this.readIdFunc(this.props.match.params.id);
        }
    }

    selectFunc(type, index) {
        var nots = this.props.notifications;
        nots[index].isSelected = type;
        this.props.updateNotification(nots);
    }

    selectAllFunc(type) {
        var nots = this.props.notifications;


        this.props.updateNotification(nots.map((item, index) => {
            item.isSelected = type;
            return item;
        }));
    }

    deleteFunc() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        var ids = this.props.notifications.filter(el => el.isSelected === true);
        let deleteRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: ids.map((item) => (item.id)),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: ids.map((item) => (item.id)),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("setting/deleteNotification", deleteRequestBody))
            .then(res => res.text())
            .then((deleteRequestResponseJSON) => {
                const JSON = require('true-json-bigint');
                let deleteRequestResponse = JSON.parse(deleteRequestResponseJSON);
                if (deleteRequestResponse.status.statusCode !== 1) {

                }
                else {
                    var notifications = this.props.notifications;
                    ids.map((item) => notifications.splice(notifications.findIndex(el => el.id === item.id), 1));
                    this.props.updateNotification(notifications);
                    this.props.notificationCountRefresh();
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            });
    }

    readSelectedFunc() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        var ids = this.props.notifications.filter(el => el.isSelected === true);
        let readRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: ids.map((item) => (item.id)),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: ids.map((item) => (item.id)),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("setting/readNotification", readRequestBody))
            .then(res => res.text())
            .then((readRequestResponseJSON) => {
                const JSON = require('true-json-bigint');
                let readRequestResponse = JSON.parse(readRequestResponseJSON);
                if (readRequestResponse.status.statusCode === 1) {
                    let notifications = this.props.notifications;
                    ids.map((item, index) => notifications.find(el => el.id === item.id).isRead = 1);
                    this.props.updateNotification(notifications);
                    this.props.notificationCountRefresh();
                }
                this.props.updateAppState('isLoadingTransparentBackground', false);
            });
    }

    readIdFunc(id) {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let readRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: [id],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            custNotificationTokenId: [id],
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("setting/readNotification", readRequestBody))
            .then(res => res.text())
            .then((readRequestResponseJSON) => {
                const JSON = require('true-json-bigint');
                let readRequestResponse = JSON.parse(readRequestResponseJSON);
                if (readRequestResponse.status.statusCode === 1) {
                    let notifications = this.props.notifications;
                    notifications.find(el => el.id === id).isRead = 1;
                    this.props.updateNotification(notifications);
                    this.props.notificationCountRefresh();
                }
            });
    }



    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            if (this.props.notifications.find(el => el.id === this.props.match.params.id) && this.props.notifications.find(el => el.id === this.props.match.params.id).isRead !== 1) {
                this.readIdFunc(this.props.match.params.id);
            }
        }
    }

    deleteConfirm(val) {
        if (val) {
            this.deleteFunc();
        }
    }

    render() {
        let { deleteOpen } = this.state;
        let { t, isLoading } = this.props;
        return (
            <Fragment>
                <YesNoModal onClose={() => this.setState({ deleteOpen: false })} title={t('Are you sure you want to delete the notifications?')} open={deleteOpen} triggerFunc={val => this.deleteConfirm(val)} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('notifications')}</h2>
                    </div>
                    {/* <a href="http://turanbank.az/" className="dashboard-header-route-link">{t('ORDER A NEW CARD')}</a> */}
                </div>
                <div className="dashboard-container-main-section">
                    {
                        isLoading ?
                            <Loading />
                            :
                            this.props.notifications && this.props.notifications.length > 0 ?
                                <div className="dashboard-notification-section" style={{ width: '100%' }}>
                                    <div className="dashboard-notification-section-left">
                                        {/* <div className="dashboard-notification-section"> */}
                                        <div className="dashboard-notification-section-header">
                                            <div className="dashboard-notification-section-header-left">
                                                <CheckboxMinus
                                                    onChecked={val => this.selectAllFunc(val)} />
                                                <span className="dashboard-notification-section-header-title">{t('CHOOSE ALL')}</span>
                                            </div>
                                            <div className="dashboard-notification-section-header-right">
                                                <button title={t("Mark as read")} className="dashboard-notification-section-header-button" style={{ padding: 5, cursor: this.props.notifications.filter(el => el.isSelected === true).length > 0 ? 'pointer' : 'auto', }} disabled={this.props.notifications.filter(el => el.isSelected === true).length > 0 ? false : true} onClick={() => this.readSelectedFunc()}>
                                                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.6786 1.48936C11.8393 1.48936 12 1.35638 12 1.17021V0.319149C12 0.159574 11.8393 0 11.6786 0H0.321429C0.133929 0 0 0.159574 0 0.319149V1.17021C0 1.35638 0.133929 1.48936 0.321429 1.48936H11.6786ZM11.6786 5.74468C11.8393 5.74468 12 5.6117 12 5.42553V4.57447C12 4.41489 11.8393 4.25532 11.6786 4.25532H0.321429C0.133929 4.25532 0 4.41489 0 4.57447V5.42553C0 5.6117 0.133929 5.74468 0.321429 5.74468H11.6786ZM11.6786 10C11.8393 10 12 9.86702 12 9.68085V8.82979C12 8.67021 11.8393 8.51064 11.6786 8.51064H0.321429C0.133929 8.51064 0 8.67021 0 8.82979V9.68085C0 9.86702 0.133929 10 0.321429 10H11.6786Z"
                                                            fill={this.props.notifications.filter(el => el.isSelected === true).length > 0 ? "#0066B2" : "#b7b7b7"} />
                                                    </svg>
                                                </button>
                                                <button title={t("Delete selected ones")} className="dashboard-notification-section-header-button" style={{ padding: 5, cursor: this.props.notifications.filter(el => el.isSelected === true).length > 0 ? 'pointer' : 'auto', }} disabled={this.props.notifications.filter(el => el.isSelected === true).length > 0 ? false : true} onClick={() => this.setState({ deleteOpen: true })}>
                                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.92857 10.9688H8.35714C8.46429 10.9688 8.57143 10.8926 8.57143 10.7656V3.85938C8.57143 3.75781 8.46429 3.65625 8.35714 3.65625H7.92857C7.79464 3.65625 7.71429 3.75781 7.71429 3.85938V10.7656C7.71429 10.8926 7.79464 10.9688 7.92857 10.9688ZM3.64286 10.9688H4.07143C4.17857 10.9688 4.28571 10.8926 4.28571 10.7656V3.85938C4.28571 3.75781 4.17857 3.65625 4.07143 3.65625H3.64286C3.50893 3.65625 3.42857 3.75781 3.42857 3.85938V10.7656C3.42857 10.8926 3.50893 10.9688 3.64286 10.9688ZM11.7857 1.625H9L8.08929 0.507812C7.875 0.228516 7.41964 0 7.07143 0H4.92857C4.55357 0 4.09821 0.228516 3.88393 0.507812L3 1.625H0.214286C0.0803571 1.625 0 1.72656 0 1.82812V2.23438C0 2.36133 0.0803571 2.4375 0.214286 2.4375H0.857143V11.7812C0.857143 12.4668 1.41964 13 2.14286 13H9.85714C10.5536 13 11.1429 12.4668 11.1429 11.7812V2.4375H11.7857C11.8929 2.4375 12 2.36133 12 2.23438V1.82812C12 1.72656 11.8929 1.625 11.7857 1.625ZM4.58036 0.990234C4.63393 0.888672 4.79464 0.837891 4.92857 0.8125H7.07143C7.17857 0.837891 7.33929 0.888672 7.39286 0.990234L7.92857 1.625H4.07143L4.58036 0.990234ZM10.2857 11.7812C10.2857 12.0098 10.0714 12.1875 9.85714 12.1875H2.14286C1.90179 12.1875 1.71429 12.0098 1.71429 11.7812V2.4375H10.2857V11.7812ZM5.78571 10.9688H6.21429C6.32143 10.9688 6.42857 10.8926 6.42857 10.7656V3.85938C6.42857 3.75781 6.32143 3.65625 6.21429 3.65625H5.78571C5.65179 3.65625 5.57143 3.75781 5.57143 3.85938V10.7656C5.57143 10.8926 5.65179 10.9688 5.78571 10.9688Z"
                                                            fill={this.props.notifications.filter(el => el.isSelected === true).length > 0 ? "#0066B2" : "#b7b7b7"} />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <ul className="dashboard-notification-section-list">
                                            {
                                                this.props.notifications.map((item, index) => {
                                                    return (
                                                        <li key={index} className={`dashboard-notification-section-item ${this.props.match.params.id === item.id ? 'dashboard-notification-section-item__selected' : ''}`}>
                                                            <div className="dashboard-notification-section-item-left">
                                                                <CheckboxTick checked={Boolean(item.isSelected)} onChecked={val => this.selectFunc(val, index)} />
                                                            </div>
                                                            <div onClick={() => {
                                                                if (item.notificationTypeId === 2) {
                                                                    this.readIdFunc(item.id);
                                                                    if(item.notificationType === 'FriendNotificationToFriend') {
                                                                        this.props.history.push(`/dashboard/transfer/card-operation/friend-transfer/history/1`);
                                                                    }
                                                                    else {
                                                                        this.props.history.push(`/dashboard/transfer/card-operation/friend-transfer/history/2`);
                                                                    }
                                                                }
                                                                else {
                                                                    this.props.history.push(`/dashboard/notifications/${item.id}`);
                                                                }
                                                            }} style={{ cursor: 'pointer', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} className="dashboard-notification-section-item-right">
                                                                <div style={{ width: '94%' }}>
                                                                    <h4 className="dashboard-notification-section-item-title" style={{ fontWeight: item.isRead === 1 ? 'normal' : 'bold', color: item.isRead ? '#849097' : '#2E3131' }}>{item.title}</h4>
                                                                    <p className="dashboard-notification-section-item-description" style={{ color: item.isRead ? '#849097' : '#586268' }}>{item.content}</p>
                                                                </div>
                                                                {item.isRead === 0 ? <div style={{ width: '8px', height: '8px', borderRadius: '4px', backgroundColor: '#0066B2', alignContent: 'flex-end' }}></div> : null}
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="dashboard-notification-section-right" >
                                        {
                                            this.props.notifications && this.props.notifications.find(el => el.id === this.props.match.params.id) ?
                                                <>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {this.props.notifications && this.props.notifications.find(el => el.id === this.props.match.params.id) ? <img style={{ width: 'auto', maxWidth: '100%' }} src={this.props.notifications && this.props.notifications.find(el => el.id === this.props.match.params.id) && this.props.notifications.find(el => el.id === this.props.match.params.id).image} /> : null}
                                                    </div>
                                                    <h3 className="dashboard-notification-section-title">{this.props.notifications && this.props.notifications.find(el => el.id === this.props.match.params.id) && this.props.notifications.find(el => el.id === this.props.match.params.id).title}</h3>
                                                    <p className="dashboard-notification-section-content">
                                                        {this.props.notifications && this.props.notifications.find(el => el.id === this.props.match.params.id) && this.props.notifications.find(el => el.id === this.props.match.params.id).body}
                                                    </p>
                                                </>
                                                :
                                                <div className="dashboard-finance-section-invisible-cards-section">
                                                    <img style={{ maxWidth: '400px' }} src={iconNotificationNot} className="dashboard-finance-section-invisible-cards-section-image" alt="iconNotificationNot" />
                                                </div>
                                        }

                                    </div>
                                </div>
                                :
                                <div className="dashboard-finance-section-invisible-cards-section">
                                    <img src={iconNotificationNot} className="dashboard-finance-section-invisible-cards-section-image" alt="iconNotificationNot" />
                                    <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                        <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any notifications.')}</p>
                                    </div>
                                    <Link to="/dashboard/home" className="dashboard-finance-section-invisible-cards-section-button">{t('main page')}</Link>
                                </div>
                    }
                </div>
            </Fragment>
        )
    }
}

export default translate("translation")(Notification);