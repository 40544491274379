import base64 from "base-64";
import UAParser from "ua-parser-js";
import Fingerprint2 from "fingerprintjs2";
const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const SERVER_TYPE = process.env.REACT_APP_SERVER_TYPE;

let auth = base64.encode(`internet:${SERVER_TYPE}`);
const encoded = "Basic " + auth;

const parser = new UAParser();
const userAgent = parser.getResult();
const deviceName = `${userAgent.browser.name} (${userAgent.os.name} ${userAgent.os.version})`
localStorage.setItem("device", deviceName)

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

let deviceId = getCookie('deviceId');


let headers = {};

if (!deviceId) {
  // Generate a new device ID
  Fingerprint2.get((components) => {
    const values = components.map((component) => component.value);
    deviceId = Fingerprint2.x64hash128(values.join(''), 31);
    setCookie('deviceId', deviceId, 365);

    // Update the headers object with the generated device ID
    headers = {
      "User-Agent": navigator.userAgent,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": BASE_URL,
      "Access-Control-Allow-Credentials": true,
      Authorization: encoded,
      'Device-Name': deviceName,
      'Device-Id': deviceId
    };

    // Make your initial request with the updated headers
    // Example: request(url, body, method, signal);
    // request("/api/some-url", { data: "example" }, "POST", signal);
  });
} else {
  // Device ID already exists, so set the headers object without updating the device ID
  headers = {
    "User-Agent": navigator.userAgent,
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": BASE_URL,
    "Access-Control-Allow-Credentials": true,
    Authorization: encoded,
    'Device-Name': deviceName,
    'Device-Id': deviceId
  };
}


export function request(url, body, method = "POST", signal) {
  if (method === "POST" && signal) {
    return new Request(BASE_URL + "/mlb/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
      signal: signal,
    });
  } else if (method === "POST") {
    return new Request(BASE_URL + "/mlb/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
  } else if (method === "GET") {
    return new Request(BASE_URL + "/mlb/" + url, {
      method: method,
      headers: headers,
    });
  } else if (method === "POST_FORM") {
    return new Request(BASE_URL + "/mlb/" + url, {
      method: "POST",
      headers: {
        "User-Agent": navigator.userAgent,
        "Access-Control-Allow-Origin": BASE_URL,
        "Access-Control-Allow-Credentials": true,
        Authorization: encoded,
        'Device-Name': deviceName,
        'Device-Id': deviceId
      },
      body: body,
    });
  }
}

export function ipsRequest(url, body, method = "POST", signal) {
  if (method === "POST" && signal) {
    return new Request(BASE_URL + "/ips/ips/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
      signal: signal,
    });
  } else if (method === "POST") {
    return new Request(BASE_URL + "/ips/ips/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
  } else if (method === "GET") {
    return new Request(BASE_URL + "/ips/ips/" + url, {
      method: method,
      headers: headers,
    });
  } else if (method === "POST_FORM") {
    return new Request(BASE_URL + "/ips/ips/" + url, {
      method: "POST",
      headers: {
        "User-Agent": navigator.userAgent,
        // 'Accept': 'application/json',
        // "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": BASE_URL,
        "Access-Control-Allow-Credentials": true,
        Authorization: encoded,
        'Device-Name': deviceName,
        'Device-Id': deviceId
      },
      body: body,
    });
  }
}

export function generalRequest(url, body, method = "POST", signal) {
  if (method === "POST" && signal) {
    return new Request(BASE_URL + "/ips/general/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
      signal: signal,
    });
  } else if (method === "POST") {
    return new Request(BASE_URL + "/ips/general/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
  } else if (method === "GET") {
    return new Request(BASE_URL + "/ips/general/" + url, {
      method: method,
      headers: headers,
    });
  } else if (method === "POST_FORM") {
    return new Request(BASE_URL + "/ips/general/" + url, {
      method: "POST",
      headers: {
        "User-Agent": navigator.userAgent,
        // 'Accept': 'application/json',
        // "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": BASE_URL,
        "Access-Control-Allow-Credentials": true,
        Authorization: encoded,
        'Device-Name': deviceName,
        'Device-Id': deviceId
      },
      body: body,
    });
  }
}

export function digitalCardRequest(url, body, method = "POST", signal) {
  if (method === "POST" && signal) {
    return new Request(BASE_URL + "/digitalcard/digital/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
      signal: signal,
    });
  } else if (method === "POST") {
    return new Request(BASE_URL + "/digitalcard/digital/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
  } else if (method === "GET") {
    return new Request(BASE_URL + "/digitalcard/digital/" + url, {
      method: method,
      headers: headers,
    });
  } else if (method === "POST_FORM") {
    return new Request(BASE_URL + "/digitalcard/digital/" + url, {
      method: "POST",
      headers: {
        "User-Agent": navigator.userAgent,
        // 'Accept': 'application/json',
        // "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": BASE_URL,
        "Access-Control-Allow-Credentials": true,
        Authorization: encoded,
        'Device-Name': deviceName,
        'Device-Id': deviceId
      },
      body: body,
    });
  }
}

export function requestCardStorage(url, body, method = "POST", signal) {
  if (method === "POST" && signal) {
    return new Request(BASE_URL + "/cardStorage/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
      signal: signal,
    });
  } else if (method === "POST") {
    return new Request(BASE_URL + "/cardStorage/" + url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
  } else if (method === "GET") {
    return new Request(BASE_URL + "/cardStorage/" + url, {
      method: method,
      headers: headers,
    });
  } else if (method === "POST_FORM") {
    return new Request(BASE_URL + "/cardStorage/" + url, {
      method: "POST",
      headers: headers,
      body: body,
    });
  }
}

export function tokenUrl(url, body, method = "POST", signal) {
  if (method === "POST" && signal) {
    return new Request(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
      signal: signal,
    });
  } else if (method === "POST") {
    return new Request(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
  } else if (method === "GET") {
    return new Request(url, {
      method: method,
      headers: headers,
    });
  } else if (method === "POST_FORM") {
    return new Request(url, {
      method: "POST",
      headers: headers,
      body: body,
    });
  }
}

export {headers}

