import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const BlueCheckbox = withStyles({
    root: {
        color: "#FFFFFF",
        "& svg": {
            // fill: "#E4E8EE",
            width: "30px",
            height: "30px",
            "& path": {
                 fill: "#0179D2",
                stroke: "#E4E8EE"
            }
        },
        '&:hover': {
            background: 'transparent',
        },
        '&$checked': {
            "& svg": {
                fill: "#0179D2",
            }
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels(props) {
    const [state, setState] = React.useState({
        checked: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        props.onChecked(event.target.checked)
    };

    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <BlueCheckbox
                        checked={state.checked}
                        onChange={handleChange}
                        name="checked"
                        indeterminate
                    />
                }
            />
        </FormGroup>
    );
}
