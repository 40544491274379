import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import BetweenExternal from './BetweenExternal';
import BetweenInternal from './BetweenInternal';
import Code from './Code';
import FriendTransfer from './FriendTransfer';
import ZkTransfer from './ZkTransfer';
import ExpressTransfer from './ExpressTransfer';

export default class CardOperation extends Component {

    render() {
        let { updateAppState, updateDashboardState, alertState, signLevel, signCount, logout } = this.props;
        return (
            <Switch>
                <Route path="/dashboard/transfer/template/card-operation/between-external/:id" render={(routeProps) => <BetweenExternal logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/card-operation/between-internal/:id" render={(routeProps) => <BetweenInternal logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/card-operation/code/:id" render={(routeProps) => <Code logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/card-operation/friend/:id" render={(routeProps) => <FriendTransfer logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/card-operation/zk-transfer/:id" render={(routeProps) => <ZkTransfer logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                <Route path="/dashboard/transfer/template/card-operation/express-transfer/:id" render={(routeProps) => <ExpressTransfer logout={() => logout()} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
            </Switch>
        );
    }
}