import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import trash from './../../../img/trashRed.svg';

import YesNoModal from './../../../../../copms/Modal/YesNoModal';


import { request } from '../../../../../config';
import { translate } from 'react-i18next';

class Security extends Component {
  state = {
    password: '',
    successMessage: null,
    feedbackMessage: null,
    devices: [],
    deleteOpen: false,
    key: null

  }

  removeDevice = (deviceId) => {
    // event.preventDefault();
    // this.props.updateAppState('isLoadingTransparentBackground', true);

    let removeDevice = {
      deviceInfoId: deviceId,
      token: localStorage.getItem('token')
    };

    fetch(request("setting/removeDevice", removeDevice))
      .then(res => res.json())
      .then(removeDeviceResponse => {
        if (removeDeviceResponse.status.statusCode !== 1) {
          if (removeDeviceResponse.status.statusCode === 200) {
            this.props.logout();
          } else {
            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(removeDeviceResponse.status.statusCode));
            this.props.updateAppState('isLoadingTransparentBackground', false);
          }
        } else {
          // this.props.updateAppState('isLoadingTransparentBackground', true);
          this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('information changed successfully'));
          this.props.updateAppState('isLoadingTransparentBackground', false);

          //   this.props.updateAppState('isLoadingTransparentBackground', false);

          this.props.history.push("/dashboard/settings/security");

        }
      }, (error) => {
        this.props.history.push("/technical-break");
      });
  };


  // componentDidMount() {
  //     this.props.updateSettingsState('settingsTitle', this.props.t('change the login password'));
  // }
  componentDidMount() {
    this.props.updateSettingsState('settingsTitle', this.props.t('devices'));
    this.props.updateSettingsState('feedback', this.props.t('device info'));

    this.props.updateAppState('isLoadingTransparentBackground', true);

    let getDevices = {
      token: localStorage.getItem('token')
    }

    fetch(request("setting/confirmedDevices", getDevices))
      .then(res => res.json())
      .then(getDevicesResponse => {
        if (getDevicesResponse.status.statusCode !== 1) {
          if (getDevicesResponse.status.statusCode === 200) {
            this.props.logout();
          } else {
            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getDevicesResponse.status.statusCode));
            this.props.updateAppState('isLoadingTransparentBackground', false);
          }
        }
        else {
          this.props.updateAppState('isLoadingTransparentBackground', false);
          this.setState({ devices: getDevicesResponse.devices }, () => {
            localStorage.setItem('devices', JSON.stringify(this.state.devices));
            // console.log(this.state.devices);
          });
          // console.log(this.state.devices)
        }
      }, (error) => {
        this.props.history.push("/technical-break");
      });

    // this.props.updateAppState('isLoadingTransparentBackground', false);

  }
  render() {
    let { devices, deleteOpen, key } = this.state;

    let { t } = this.props;
    // let { devices } = this.props.location.state || [];

    return (
      <form className="dashboard-settings-section-form">
        <YesNoModal onClose={() => this.setState({ deleteOpen: false })} title={t('Are you sure you want to delete your device?')} open={deleteOpen} triggerFunc={() => this.removeDevice(key)} />
        {devices.map(device => (
          <>
            <div className="dashboard-settings-section-form-group" key={device.id}>
              <div className="dashboard-settings-section-form-group-box">
                <label className="dashboard-settings-section-form-group-box-input-label pb-0">{device.currentDevice ? t('current device') : t('additional device')}</label>
                <input
                  readOnly
                  type="text"
                  className="dashboard-settings-section-form-group-box-input default"
                  defaultValue={device.deviceName}
                  placeholder={t('devices')}
                  onChange={this.checkValidationNewPassword}
                  onBlur={this.setNewPasswordOutline}
                />

                {device.currentDevice ? null :
                  <button type='submit' className="dashboard-settings-section-form-group-box-icon" onClick={(e) => {e.preventDefault(); this.setState({ deleteOpen: true, key: device.id }); }}>
                    <img src={trash} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img" alt="" />
                  </button>
                }

              </div>
            </div>
          </>
        ))}
      </form>


    )
  }
}



export default translate("translation")(Security);