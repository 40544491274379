import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { request, ipsRequest } from '../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import CardOperationModal from '../../../../copms/Modal/CardOperationModal';
import CreateCardOperationTemplate from './../../../../copms/Modal/CreateCardOperationTemplate';
import OutsideClickHandler from 'react-outside-click-handler';
import { cardTypes } from '../../../../helpers/cards';
import { formPost } from '../../../../helpers/formPost';
import { setNumberToDecimal } from '../../../../helpers/payments';
import InputMask from 'react-input-mask';
import Loading from './../../Loading/Loading';
import TopUpSuccessModal from '../../../../copms/Modal/TopUpSuccessModal';

class CardTopUp extends Component {
    state = {
        confirmedOperation: false,
        dropdownVisibilityBank: false,
        dropdownVisibilityBuyingAccount: false,
        dropdownVisibilitySellingAccount: false,
        banks: [],
        accounts: [],
        selectedBuyingAccount: null,
        selectedSellingAccount: null,
        selectedBank: {},
        bankFilter: "",
        crCustomerName: "",
        crCustomerNameValidation: false,
        crIban: "",
        crIbanValidation: false,
        crTaxNo: "",
        crTaxNoValidation: false,
        amount: "",
        amountValidation: false,
        purpose: "",
        purposeValidation: false,
        dtAccountId: null,
        buyingAccounts: [],
        sellingAccounts: [],
        currencyBuying: '',
        currencySelling: '',
        valueBuying: '',
        valueSelling: '',
        exchangeCashlessList: [],
        exchangeRate: '',
        step: 1,
        sellingIndex: -1,
        buyingIndex: -1,
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        firstCards: [],
        secondCards: [],
        cards: [],
        firstCardsShow: false,
        firstCardsIndex: -1,
        secondCardsShow: false,
        secondCardsIndex: -1,
        currencyIndex: -1,
        currencyShow: false,
        openTemp: false,
        cardData: false,
        firstCardsStatus: 0,
        secondCardsStatus: 0,
        exchangeStatus: 0,
        amountStatus: 0,
        commission: null,
        convertation: null,
        commissionCurr: "",
        convertationCurr: "",
        info: null,

        fromAnotherCard: false,
        newCreditCardNumber: '',
        newCreditCardExpDate: '',
        newCreditCardCVV: '',
        prevCardPrefix: '',
        cardHolderInfo: null,
        card: null,
        isLoading: false,
        succesModal: false,
        succesModalData: null
    }

    updateOpen = (value) => {
        this.setState({ open: value });
    }

    showCards = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(request("card/getCardListFor" + customerType + "Customer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    let cardList = [];
                    if (getCardListForCustomerResponse.otherCardList) {
                        cardList = [...getCardListForCustomerResponse.cardList.filter(card => card.cardType !== cardTypes.DIGITAL_CARD), ...getCardListForCustomerResponse.otherCardList]
                    } else {
                        cardList = [...getCardListForCustomerResponse.cardList.filter(card => card.cardType !== cardTypes.DIGITAL_CARD)]
                    }
                    for (let i = 0; i < cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += cardList[i].cardId;
                        }
                        cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        cardList[i].organizedCardNumber = cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + cardList[i].cardNumber.substring(12, 16);
                    }
                    this.setState({
                        firstCards: cardList, cards: cardList,
                        // this.state.card?.currency != 'AZN' ? cardList.filter(card => card.cardId != this.props.match.params.id && card.currency != 'AZN') : 
                        // cardList.filter(card => card.cardId != this.props.match.params.id), 
                        secondCards: cardList, cardData: true
                    }, () => {
                        const cardIndex = cardList.findIndex(item => item.cardId === this.props.match.params.id);
                        const cardType = cardList.find(item => item.cardId === this.props.match.params.id)?.cardType;

                        cardType === "CARD STORAGE" ? this.selectFirstCard(cardIndex) : this.selectSecondCard(cardIndex);
                    })
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    // showCard = () => {
    //     this.setState({ isLoading: true });
    //     let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';
    //     let getCardByIdForCustomerRequestBody = (customerType === 'Individual') ? {
    //         custId: localStorage.getItem('custId'),
    //         token: localStorage.getItem('token'),
    //         cardId: this.props.match.params.id,
    //         lang: localStorage.getItem('i18nextLng').toUpperCase()
    //     } : {
    //         custId: localStorage.getItem('custId'),
    //         compId: localStorage.getItem('compId'),
    //         token: localStorage.getItem('token'),
    //         cardId: this.props.match.params.id,
    //         lang: localStorage.getItem('i18nextLng').toUpperCase()
    //     }

    //     fetch(request("card/getCardByIdFor" + customerType, getCardByIdForCustomerRequestBody))
    //         .then(res => res.text())
    //         .then((getCardByIdForCustomerResponseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let getCardByIdForCustomerResponse = JSON.parse(getCardByIdForCustomerResponseJSON);
    //             if (getCardByIdForCustomerResponse.status.statusCode !== 1) {
    //                 if (getCardByIdForCustomerResponse.status.statusCode === 200) {
    //                     this.props.logout();
    //                 } else {
    //                     this.setState({ isLoading: false });
    //                 }
    //             }
    //             else {
    //                 if (getCardByIdForCustomerResponse?.otherCard) {
    //                     this.setState({ card: getCardByIdForCustomerResponse.otherCard, isLoading: false, isCardStorage: true }, () => {
    //                         this.showCards();
    //                     });
    //                 } else {
    //                     // =========HOLDER=========
    //                     let holderArray = getCardByIdForCustomerResponse.holder.split(' ');
    //                     let lowercaseName = holderArray[0].toLowerCase();
    //                     let lowercaseSurname = holderArray[1].toLowerCase();
    //                     getCardByIdForCustomerResponse.organizedHolder = {
    //                         name: holderArray[0][0] + lowercaseName.substring(1, lowercaseName.length),
    //                         surname: holderArray[1][0] + lowercaseSurname.substring(1, lowercaseSurname.length)
    //                     };
    //                     // =========NUMBER=========
    //                     getCardByIdForCustomerResponse.organizedCardNumber = getCardByIdForCustomerResponse.cardNumber.substring(0, 4)
    //                         + " **** **** " + getCardByIdForCustomerResponse.cardNumber.substring(12, 16);
    //                     // =========EXPIRYDATE=========
    //                     let expiryDate = new Date(getCardByIdForCustomerResponse.expiryDate);
    //                     getCardByIdForCustomerResponse.organizedExpiryDate = {
    //                         month: (expiryDate.getMonth() < 9) ? ('0' + (expiryDate.getMonth() + 1)) : (expiryDate.getMonth() + 1),
    //                         year: expiryDate.getFullYear().toString().substring(2, 4),
    //                         fullYear: expiryDate.getFullYear(),
    //                         day: expiryDate.getDate() < 10 ? '0' + expiryDate.getDate().toString() : expiryDate.getDate().toString()
    //                     }
    //                     // =========CURRENCY=========
    //                     getCardByIdForCustomerResponse.currencyIcon = currencyFilter(getCardByIdForCustomerResponse.currency);
    //                     // =========BALANCE=========
    //                     if (getCardByIdForCustomerResponse.balance.toString() === "0") {
    //                         getCardByIdForCustomerResponse.organizedCurrentBalance = {
    //                             wholePart: "0",
    //                             fractionalPart: "00"
    //                         }
    //                     }
    //                     else {
    //                         if (getCardByIdForCustomerResponse.balance.toString().indexOf('.') !== -1) {
    //                             let currentBalanceArray = getCardByIdForCustomerResponse.balance.toString().split('.');
    //                             getCardByIdForCustomerResponse.organizedCurrentBalance = {
    //                                 wholePart: currentBalanceArray[0],
    //                                 fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
    //                             }
    //                         }
    //                         else {
    //                             getCardByIdForCustomerResponse.organizedCurrentBalance = {
    //                                 wholePart: getCardByIdForCustomerResponse.balance.toString(),
    //                                 fractionalPart: "00"
    //                             }
    //                         }
    //                     }
    //                     this.setState({ card: getCardByIdForCustomerResponse, isLoading: false }, () => {
    //                         this.showCards();
    //                     });
    //                 }
    //             }
    //         },
    //             (error) => {
    //                 this.props.history.push("/technical-break");

    //             });
    // }

    selectFirstCard(index, checkNewCard) {
        var cards = Object.create(this.state.secondCards);
        for (let i = 0; i < cards.length; i++) {
            cards[i].unselectable = false;
        }
        if (!checkNewCard) {
            cards[index].unselectable = true;
        } else {
            if (cards[this.state.secondCardsIndex]) {
                cards[this.state.secondCardsIndex].unselectable = true;
            }
        }
        this.setState({ secondCards: cards, firstCardsIndex: index, firstCardsShow: false, firstCardsStatus: 2, fromAnotherCard: checkNewCard ?? false }, () => {
            this.checkFields();
            // this.cardOperationCurrency();
        });
    }

    // selectSecondCard(e, index) {
    //     e.preventDefault();
    //     var cards = Object.create(this.state.firstCards);
    //     for (let i = 0; i < cards.length; i++) {
    //         cards[i].unselectable = false;
    //     }
    //     cards[index].unselectable = true;
    //     this.setState({ secondCardsIndex: index, secondCardsShow: false, firstCards: cards, secondCardsStatus: 2 }, () => this.checkFields());
    // }

    // selectFirstCard(e, index) {
    //     e.preventDefault();
    //     var cards = Object.create(this.state.secondCards);
    //     for (let i = 0; i < cards.length; i++) {
    //         cards[i].unselectable = false;
    //     }
    //     cards[index].unselectable = true;
    //     this.setState({ firstCardsIndex: index, secondCards: cards, firstCardsShow: false, firstCardsStatus: 2 }, () => {
    //         this.setState({ currencyIndex: this.state.firstCards[index].currency ? this.state.exchangeCashlessList.findIndex(el => el.currency == this.state.firstCards[index].currency) : this.state.exchangeCashlessList.findIndex(el => el.currency == "AZN") })
    //         this.checkFields();
    //     });
    // }

    selectSecondCard(index) {
        var cards = Object.create(this.state.firstCards);
        for (let i = 0; i < cards.length; i++) {
            cards[i].unselectable = false;
        }
        cards[index].unselectable = true;
        if (this.state.secondCards[index].currency !== 'AZN') {
            cards = cards.map(card => (card.currency && card.currency === 'AZN') ? { ...card, unselectable: true } : card)
            if (this.state.firstCards[this.state.firstCardsIndex] && this.state.firstCards[this.state.firstCardsIndex].currency && this.state.firstCards[this.state.firstCardsIndex].currency == 'AZN') {
                this.setState({ firstCardsIndex: -1 })
            }
        }
        this.setState({ secondCardsIndex: index, secondCardsShow: false, firstCards: cards, secondCardsStatus: 2 }, () => this.checkFields());
    }

    selectExchange(e, index) {
        e.preventDefault();
        this.setState({ currencyIndex: index, currencyShow: false, exchangeStatus: 2 });
    }

    onChangeAmount(val) {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(val) || val.length === 0) {
            this.setState({ amount: val }, () => this.checkFields())
        }
    }

    setNewCreditCardNumber = (value) => {
        if (value?.length >= 6 && value.slice(0, 6) != this.state.prevCardPrefix) {
            this.setState({ prevCardPrefix: value.slice(0, 6), cardHolderInfo: null })
            this.getCardInfoByCardPrefix(value.slice(0, 6));
        }
        this.setState({ newCreditCardNumber: value })
        // var matches = v.match(/\d{4,16}/g);
        // var match = matches && matches[0] || '';
        // var parts = [];
        // for (var i = 0, len = match.length; i < len; i += 4) {
        //     parts.push(match.substring(i, i + 4));
        // }
        // if (parts.length) {
        //     return parts.join(' ');
        // } else {
        //     return value;
        // }
    }

    getCardInfoByCardPrefix(cardPrefix) {
        let cardInfoByCardPrefixRequestBody = {
            token: localStorage.getItem('token'),
            cardPrefix
        }
        fetch(request("card/getCardInfoByCardPrefix", cardInfoByCardPrefixRequestBody))
            .then(res => res.text())
            .then((cardInfoByCardPrefixResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cardInfoByCardPrefixResponse = JSON.parse(cardInfoByCardPrefixResponseJSON);
                if (cardInfoByCardPrefixResponse.status.statusCode === 200)
                    this.props.logout();
                else
                    this.setState({ cardHolderInfo: cardInfoByCardPrefixResponse });
            })
    }

    formatExpiryDate = (value) => {
        const sanitizedValue = value.replace(/\D/g, '');

        let formattedValue = sanitizedValue.slice(0, 2);
        if (formattedValue > 12) {
            formattedValue = '12'; // Cap it at 12
        }

        if (sanitizedValue.length > 2) {
            formattedValue += '/' + sanitizedValue.slice(2);
        }

        return formattedValue;
    };

    // checkFields() {
    //     let { card, firstCardsIndex, amount } = this.state;
    //     if (card && firstCardsIndex >= 0 && parseFloat(amount) > 0) {
    //         this.setState({ confirmedOperation: true });
    //     } else {
    //         this.setState({ confirmedOperation: false });
    //     }
    // }

    checkFields() {
        let { firstCardsIndex, secondCardsIndex, amount } = this.state;
        if (firstCardsIndex >= 0 && secondCardsIndex >= 0 && parseFloat(amount) > 0) {
            this.setState({ confirmedOperation: true });
        } else {
            this.setState({ confirmedOperation: false });
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        // this.getCardOperationInfo();
        // this.cardOperationCurrency();
        // this.showCards();
        this.showCards();
    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');

        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    showFeeAmount = () => {
        this.setState({ step: 2, confirmedOperation: true, loadingFee: true });
        // this.props.updateAppState('isLoadingTransparentBackground', true);
        // console.log(this.state.firstCards[this.state.firstCardsIndex].cardType == cardTypes.CARD_STORAGE)
        // let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getFeeAmountRequestBody = {
            // custId: localStorage.getItem('custId'),
            // compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            // lang: localStorage.getItem('i18nextLng').toUpperCase(),
            // cardOperationType: 1,
            dtAmount: parseFloat(this.state.amount),
            dtCardType: this.state.fromAnotherCard ? 3 : this.state.firstCards[this.state.firstCardsIndex]?.cardType == cardTypes.CARD_STORAGE ? 2 : 1,
            // senderType: 1,
            // custType: 2,
            // cardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
            dtCardId: this.state.firstCards[this.state.firstCardsIndex]?.cardId,
            dtCardNumber: this.state.newCreditCardNumber ?? null,
            crCardId: this.state.secondCards[this.state.secondCardsIndex]?.cardId,
            // cardCcyCode: this.state.exchangeCashlessList.find(currency => currency.currency === this.state.firstCards[this.state.firstCardsIndex].currency).code,
            // ccyCode: this.state.exchangeCashlessList[this.state.currencyIndex].code
        }
        // : {
        //     custId: localStorage.getItem('custId'),
        //     token: localStorage.getItem('token'),
        //     lang: localStorage.getItem('i18nextLng').toUpperCase(),
        //     cardOperationType: 1,
        //     amount: parseFloat(this.state.amount),
        //     senderType: 1,
        //     custType: 1,
        //     cardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
        //     cardId: this.state.firstCards[this.state.firstCardsIndex].cardId,
        //     destCardNumber: this.state.secondCards[this.state.secondCardsIndex].cardNumber,
        //     cardCcyCode: this.state.exchangeCashlessList.find(currency => currency.currency === this.state.firstCards[this.state.firstCardsIndex].currency).code,
        //     ccyCode: this.state.exchangeCashlessList[this.state.currencyIndex].code
        // }
        fetch(request("card/topUpToCardFee", getFeeAmountRequestBody))
            .then(res => res.text())
            .then((getFeeAmountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getFeeAmountResponse = JSON.parse(getFeeAmountResponseJSON);
                if (getFeeAmountResponse.status.statusCode !== 1) {
                    if (getFeeAmountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getFeeAmountResponse.status.statusCode) });
                    }
                }
                else {
                    this.setState({
                        commission: this.amountSplit(String(getFeeAmountResponse.fee ?? 0)),
                        commissionCurr: getFeeAmountResponse?.feeCurrency,
                        convertation: getFeeAmountResponse.exFee ? this.amountSplit(String(getFeeAmountResponse.exFee)) : null,
                        convertationCurr: getFeeAmountResponse?.exFeeCurrency
                    })
                    this.setState({ loadingFee: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    onSubmitForm() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let topUpToCardRequestBody = {
            token: localStorage.getItem('token'),
            dtAmount: parseFloat(this.state.amount),
            dtCardType: this.state.fromAnotherCard ? 3 : this.state.firstCards[this.state.firstCardsIndex]?.cardType == cardTypes.CARD_STORAGE ? 2 : 1,
            dtCardId: this.state.firstCards[this.state.firstCardsIndex]?.cardId,
            dtCardNumber: this.state.newCreditCardNumber ?? null,
            crCardId: this.state.secondCards[this.state.secondCardsIndex]?.cardId,
        }
        fetch(request("card/topUpToCard", topUpToCardRequestBody))
            .then(res => res.text())
            .then((topUpToCardResponseJSON) => {
                const JSON = require('true-json-bigint');
                let topUpToCardResponse = JSON.parse(topUpToCardResponseJSON);
                if (topUpToCardResponse.status.statusCode !== 1) {
                    if (topUpToCardResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(topUpToCardResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (topUpToCardResponse.cardStorage) {
                        const formData = {
                            AMOUNT: topUpToCardResponse.cardStorage.amount,
                            CURRENCY: topUpToCardResponse.cardStorage.currency,
                            ORDER: topUpToCardResponse.cardStorage.order,
                            DESC: topUpToCardResponse.cardStorage.desc,
                            TRTYPE: topUpToCardResponse.cardStorage.trtype,
                            TIMESTAMP: topUpToCardResponse.cardStorage.timeStamp,
                            NONCE: topUpToCardResponse.cardStorage.nonce,
                            BACKREF: topUpToCardResponse.cardStorage.backref,
                            P_SIGN: topUpToCardResponse.cardStorage.psign,
                            MERCH_NAME: topUpToCardResponse.cardStorage.merchName,
                            MERCH_URL: topUpToCardResponse.cardStorage.merchUrl,
                            TERMINAL: topUpToCardResponse.cardStorage.terminal,
                            EMAIL: topUpToCardResponse.cardStorage.email,
                            COUNTRY: topUpToCardResponse.cardStorage.country,
                            MERCH_GMT: topUpToCardResponse.cardStorage.merchGmt,
                            LANG: localStorage.getItem("i18nextLng").toUpperCase(),
                        };

                        if (this.state.fromAnotherCard) {
                            formData.MERCHANT = topUpToCardResponse.cardStorage.merchName;
                            formData.TOKEN_ACTION = topUpToCardResponse.cardStorage.tokenAction;
                        } else {
                            formData.TOKEN = topUpToCardResponse.cardStorage.token;
                        }

                        formPost(topUpToCardResponse.cardStorage.tokenUrl, formData);
                    } else {
                        this.setState({
                            succesModal: true,
                            succesModalData: {
                                receiptNumber: topUpToCardResponse.receiptNo,
                                operationDate: topUpToCardResponse.operationDate,
                                currency: topUpToCardResponse.currency,
                                destCardNumber: topUpToCardResponse.destCardNumber,
                                requestorCardNumber: topUpToCardResponse.requestorCardNumber,
                                amount: this.amountSplit(String(topUpToCardResponse.amount)),
                                feeAmount: this.amountSplit(String(topUpToCardResponse.feeAmount) ?? 0),
                                feeCurrency: topUpToCardResponse.feeCurrency,
                                exchangeFeeCurrency: topUpToCardResponse.exchangeFeeCurrency,
                                exchangeFeeAmount: topUpToCardResponse.exchangeFeeAmount ? this.amountSplit(String(topUpToCardResponse.exchangeFeeAmount)) : null
                            }
                        });
                    }

                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    // this.setState({ createFeedbackMessage: true, open: true });
                }
            })
    }

    // cardToCardWithOtherCards() {
    //     this.props.updateAppState('isLoadingTransparentBackground', true);

    //     let requestBody = {
    //         custId: localStorage.getItem('custId'),
    //         token: localStorage.getItem('token'),
    //         requestorCardId: this.state.firstCards[this.state.firstCardsIndex].cardId,
    //         destinationCardId: this.state.secondCards[this.state.secondCardsIndex].cardId,
    //         currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency,
    //         // amount: parseFloat(this.state.amount),
    //         amount: (this.state.amount).toString().length === 1 || (this.state.amount).toString().slice(-1) === "0" ? setNumberToDecimal(this.state.amount, 1) : this.state.amount,
    //         lang: localStorage.getItem('i18nextLng').toUpperCase(),
    //     }

    //     fetch(request("card/cardStorageToCard", requestBody))
    //         .then(res => res.text())
    //         .then((responseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let response = JSON.parse(responseJSON);
    //             if (response.status.statusCode !== 1) {
    //                 if (response.status.statusCode === 200) {
    //                     this.props.logout();
    //                 } else {
    //                     this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(response.status.statusCode) });
    //                     this.props.updateAppState('isLoadingTransparentBackground', false);
    //                 }
    //             }
    //             else {
    //                 this.props.updateAppState('isLoadingTransparentBackground', false);
    //                 formPost(response?.cardStorage?.tokenUrl, {
    //                     AMOUNT: response?.cardStorage?.amount,
    //                     CURRENCY: response?.cardStorage?.currency,
    //                     ORDER: response?.cardStorage?.order,
    //                     DESC: response?.cardStorage?.desc,
    //                     MERCH_NAME: response?.cardStorage?.merchName,
    //                     MERCH_URL: response?.cardStorage?.merchUrl,
    //                     // MERCHANT: response?.cardStorage?.merchName,
    //                     TERMINAL: response?.cardStorage?.terminal,
    //                     EMAIL: response?.cardStorage?.email,
    //                     TRTYPE: response?.cardStorage?.trtype,
    //                     COUNTRY: response?.cardStorage?.country,
    //                     MERCH_GMT: response?.cardStorage?.merchGmt,
    //                     TIMESTAMP: response?.cardStorage?.timeStamp,
    //                     NONCE: response?.cardStorage?.nonce,
    //                     BACKREF: response?.cardStorage?.backref,
    //                     P_SIGN: response?.cardStorage?.psign,
    //                     TOKEN: response?.cardStorage?.token,
    //                     LANG: localStorage.getItem('i18nextLng').toUpperCase()
    //                 })
    //             }
    //         })
    // }

    // updateOpen = (value) => {
    //     this.setState({ open: value });
    // }

    // updateOpenTemp = (value) => {
    //     this.setState({ openTemp: value });
    // }

    cancel() {
        this.setState({ step: 1, firstCardsIndex: -1, amount: "", currencyIndex: -1, confirmedOperation: false, fromAnotherCard: false })
    }

    render() {
        let { t, history } = this.props;
        let { confirmedOperation, step, open, firstCards, firstCardsIndex, firstCardsShow, secondCards, secondCardsIndex, secondCardsShow, createFeedbackMessage, feedbackErrorModal, amount, exchangeCashlessList, currencyShow, currencyIndex,
            openTemp, cardData, firstCardsStatus, secondCardsStatus, exchangeStatus, amountStatus, commission, commissionCurr, info, convertation, convertationCurr, fromAnotherCard, newCreditCardNumber, newCreditCardExpDate, newCreditCardCVV, cardHolderInfo, card, isLoading,
            loadingFee, succesModalData, succesModal } = this.state;
        // let requestBody;
        // if (this.state.firstCards[this.state.firstCardsIndex] !== undefined
        //     && this.state.secondCards[this.state.secondCardsIndex] !== undefined
        //     && this.state.exchangeCashlessList[this.state.currencyIndex] !== undefined) {
        //     requestBody = localStorage.getItem('compId') === null ? {
        //         custId: localStorage.getItem('custId'),
        //         token: localStorage.getItem('token'),
        //         lang: localStorage.getItem('i18nextLng').toUpperCase(),
        //         requestorCardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
        //         destinationCardNumber: this.state.secondCards[this.state.secondCardsIndex].cardNumber,
        //         cardOperationType: 1,
        //         currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency,
        //         amount: parseFloat(this.state.amount),
        //         requestorCardId: Number(this.state.firstCards[this.state.firstCardsIndex].cardId),
        //         destinationCardId: Number(this.state.secondCards[this.state.secondCardsIndex].cardId),
        //     } : {
        //         custId: localStorage.getItem('custId'),
        //         compId: localStorage.getItem('compId'),
        //         token: localStorage.getItem('token'),
        //         lang: localStorage.getItem('i18nextLng').toUpperCase(),
        //         requestorCardNumber: this.state.firstCards[this.state.firstCardsIndex].cardNumber,
        //         destinationCardNumber: this.state.secondCards[this.state.secondCardsIndex].cardNumber,
        //         cardOperationType: 1,
        //         currency: this.state.exchangeCashlessList[this.state.currencyIndex].currency,
        //         amount: parseFloat(this.state.amount),
        //         requestorCardId: Number(this.state.firstCards[this.state.firstCardsIndex].cardId),
        //         destinationCardId: Number(this.state.secondCards[this.state.secondCardsIndex].cardId),
        //     };
        // }
        return (
            <>

                <Fragment>
                    {/* <CreateCardOperationTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={'saveCardOperationTemp'} requestBody={requestBody} /> */}
                    <TopUpSuccessModal id={this.props.match.params.id} history={history} data={succesModalData} open={succesModal} />

                    <CardOperationModal open={open} updateOpen={this.updateOpen} successClose={() => this.props.history.goBack()} errorClose={() => { this.setState({ step: 1 }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                    <div className="dashboard-header-route">
                        <div className="dashboard-header-route-left">
                            <button onClick={() => this.props.history.goBack()}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                </svg>
                            </button>
                            <h2>{t('Income')}</h2>
                        </div>
                    </div>
                    {isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-payment-container">
                            <div className={`dashboard-payment-container-data-section ${step == 1 && 'w-100'}`}>
                                <div className="dashboard-payment-container-data-section-header">
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <h2 className="dashboard-payment-container-data-section-header-title">{t('Income')}</h2>
                                    </div>
                                    {/* <button className="dashboard-payment-container-data-section-header-button" onClick={() => { this.setState({ openTemp: true }) }} disabled={!confirmedOperation}>{t("Save as template")}</button> */}
                                </div>
                                {/* {fromAnotherCard ?
                                    <form className="dashboard-payment-container-data-section-content">
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <div className="dashboard-payment-container-data-section-content-container-form">
                                                <div className="w-100">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__fromAnotherCard">
                                                        <h6 className=" text-dark">{newCreditCardNumber?.replace(/\D/g, '').length >= 6 && cardHolderInfo?.cardHolder}</h6>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Card number')}</label>
                                                            <div className={`dashboard-payment-container-data-section-content-container-form-input-box`}>
                                                                <InputMask mask="9999 9999 9999 9999" placeholder="0000 0000 0000 0000" onBlur={() => this.setState({ amountStatus: 1 })} onChange={e => this.setNewCreditCardNumber(e.target.value.replace(/\D/g, ''))} value={newCreditCardNumber} type="text" className={`dashboard-payment-container-data-section-content-container-form-input w-100`} />
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group__fromAnotherCard-group">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('expiration date')}</label>
                                                                <div className={`dashboard-payment-container-data-section-content-container-form-input-box`}>
                                                                    <InputMask mask="99/99" onBlur={() => this.setState({ amountStatus: 1 })} onChange={e => this.setState({ newCreditCardExpDate: this.formatExpiryDate(e.target.value.replace(/\D/g, '')) })} value={newCreditCardExpDate} type="text" className={`dashboard-payment-container-data-section-content-container-form-input w-100`} placeholder="00/00" />
                                                                </div>
                                                            </div>
                                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('CVV')}</label>
                                                                <div className={`dashboard-payment-container-data-section-content-container-form-input-box`}>
                                                                    <InputMask mask="999" onBlur={() => this.setState({ amountStatus: 1 })} onChange={e => this.setState({ newCreditCardCVV: e.target.value.replace(/\D/g, '') })} value={newCreditCardCVV} type="text" className={`dashboard-payment-container-data-section-content-container-form-input w-100`} placeholder="***" />
                                                                </div>
                                                            </div>
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label text-dark font-weight-bold font-italic dashboard-payment-container-data-section-content-container-form-input-group__grid">{newCreditCardNumber?.replace(/\D/g, '').length >= 6 && cardHolderInfo?.cardPaymentType}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                <button type='button' onClick={e => console.log(newCreditCardNumber, newCreditCardExpDate, newCreditCardCVV)} disabled={newCreditCardNumber.length < 16 || newCreditCardExpDate.length < 5 || newCreditCardCVV.length < 3} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${(newCreditCardNumber.length < 16 || newCreditCardExpDate.length < 5 || newCreditCardCVV.length < 3) && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('continue')}</button>
                                                <button type='button' onClick={e => this.setState({ newCreditCardNumber: '', newCreditCardExpDate: '', newCreditCardCVV: '', fromAnotherCard: false })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                            </div>
                                        </div>
                                    </form>
                                    : */}
                                <form className="dashboard-payment-container-data-section-content">
                                    <div className="dashboard-payment-container-data-section-content-container">
                                        <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                        <div className="dashboard-payment-container-data-section-content-container-form">
                                            <div className="w-100">

                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group___grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Withdrawal card')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ firstCardsShow: false, firstCardsStatus: firstCardsShow ? firstCardsIndex >= 0 ? 2 : 1 : firstCardsStatus }) }}>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${firstCardsStatus == 1 ? "input-outline" : ""}`}>
                                                            <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 2 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={e => { e.preventDefault(); this.setState({ firstCardsShow: !firstCardsShow, firstCardsStatus: firstCardsShow ? firstCardsIndex >= 0 ? 2 : 1 : firstCardsStatus }); }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={firstCardsIndex >= 0 || (cardData === true && (!firstCards || firstCards.length === 0)) || fromAnotherCard ? {} : { color: '#BDC3C7' }}>
                                                                    {
                                                                        cardData === true &&
                                                                        firstCards
                                                                        && firstCardsIndex >= 0
                                                                        && firstCards.length > 0
                                                                        && !fromAnotherCard
                                                                        && firstCards[firstCardsIndex].organizedCardNumber + ' / ' + (firstCards[firstCardsIndex].cardType === cardTypes.CARD_STORAGE ? firstCards[firstCardsIndex].holder : `${firstCards[firstCardsIndex].balance} ${firstCards[firstCardsIndex].currency}`)
                                                                    }
                                                                    {
                                                                        cardData === true &&
                                                                        (!firstCards || firstCards.length === 0)
                                                                        && t('The card list is empty')
                                                                    }
                                                                    {
                                                                        ((cardData === true
                                                                            && (firstCards && firstCards.length > 0)
                                                                            && firstCardsIndex < 0 && !fromAnotherCard)
                                                                            || cardData === false)
                                                                        && t('Choose')
                                                                    }
                                                                    {
                                                                        fromAnotherCard
                                                                        && t('Another bank card')
                                                                    }
                                                                </div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(firstCardsShow === false || step >= 2) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    (firstCards && firstCards.length > 0 &&
                                                                        firstCards.map((card, index) => {
                                                                            if (!card.unselectable)
                                                                                return (
                                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                        <button type='button' onClick={() => this.selectFirstCard(index)} disabled={!Boolean(card.cardStatus)} className={!Boolean(card.cardStatus) ? "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"}>{card.organizedCardNumber} / {card.cardType === cardTypes.CARD_STORAGE ? card.holder : `${card.balance} ${card.currency}`}</button>
                                                                                    </li>
                                                                                );
                                                                        }))
                                                                }
                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                    <button
                                                                        type='button'
                                                                        onClick={() => this.selectFirstCard(firstCards?.length + 1 || 0, true)}
                                                                        className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="30" viewBox="0 0 44 30" fill="none">
                                                                            <path d="M0.5 6.4C0.5 5.27164 0.500389 4.45545 0.552836 3.81352C0.604807 3.17744 0.705391 2.75662 0.881477 2.41103C1.21703 1.75247 1.75247 1.21703 2.41103 0.881477C2.75662 0.705391 3.17744 0.604807 3.81352 0.552836C4.45545 0.500389 5.27164 0.5 6.4 0.5H36.6C37.7284 0.5 38.5446 0.500389 39.1865 0.552836C39.8226 0.604807 40.2434 0.705391 40.589 0.881477L40.8157 0.436534L40.589 0.881478C41.2475 1.21703 41.783 1.75247 42.1185 2.41103C42.2946 2.75662 42.3952 3.17744 42.4472 3.81352C42.4996 4.45545 42.5 5.27164 42.5 6.4V23.6C42.5 24.7284 42.4996 25.5446 42.4472 26.1865C42.3952 26.8226 42.2946 27.2434 42.1185 27.589C41.783 28.2475 41.2475 28.783 40.589 29.1185C40.2434 29.2946 39.8226 29.3952 39.1865 29.4472C38.5446 29.4996 37.7284 29.5 36.6 29.5H6.4C5.27165 29.5 4.45545 29.4996 3.81352 29.4472C3.17744 29.3952 2.75662 29.2946 2.41103 29.1185C1.75247 28.783 1.21703 28.2475 0.881477 27.589C0.705391 27.2434 0.604807 26.8226 0.552836 26.1865C0.500389 25.5446 0.5 24.7284 0.5 23.6V6.4Z" fill="#1D77FD" fill-opacity="0.1" stroke="white" />
                                                                            <path d="M22.6875 8.3125V14.5625H28.9375C29.4453 14.5625 29.875 14.9922 29.875 15.5C29.875 16.0469 29.4453 16.4375 28.9375 16.4375H22.6875V22.6875C22.6875 23.2344 22.2578 23.625 21.75 23.625C21.2031 23.625 20.8125 23.2344 20.8125 22.6875V16.4375H14.5625C14.0156 16.4375 13.625 16.0469 13.625 15.5C13.625 14.9922 14.0156 14.5625 14.5625 14.5625H20.8125V8.3125C20.8125 7.80469 21.2031 7.375 21.75 7.375C22.2578 7.375 22.6875 7.80469 22.6875 8.3125Z" fill="#1D77FD" />
                                                                        </svg> {t('Another bank card')}
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group___grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Incoming card')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ secondCardsShow: false, secondCardsStatus: secondCardsShow ? secondCardsIndex >= 0 ? 2 : 1 : secondCardsStatus }) }}>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${secondCardsStatus == 1 ? "input-outline" : ""}`}>
                                                            <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 2 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={e => { e.preventDefault(); this.setState({ secondCardsShow: !secondCardsShow, secondCardsStatus: secondCardsShow ? secondCardsIndex >= 0 ? 2 : 1 : secondCardsStatus }); }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={secondCardsIndex >= 0 || (cardData === true && (!secondCards || secondCards.length === 0)) ? {} : { color: '#BDC3C7' }}>
                                                                    {
                                                                        cardData === true &&
                                                                        secondCards
                                                                        && secondCardsIndex >= 0
                                                                        && secondCards.length > 0
                                                                        && secondCards[secondCardsIndex].organizedCardNumber + ' / ' + secondCards[secondCardsIndex].balance + ' ' + secondCards[secondCardsIndex].currency
                                                                    }
                                                                    {
                                                                        cardData === true &&
                                                                        (!secondCards || secondCards.length === 0)
                                                                        && t('The card list is empty')
                                                                    }
                                                                    {
                                                                        ((cardData === true
                                                                            && (secondCards && secondCards.length > 0)
                                                                            && secondCardsIndex < 0)
                                                                            || cardData === false)
                                                                        && t('Choose')
                                                                    }
                                                                </div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(secondCardsShow === false || step >= 2) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    secondCards && secondCards.length > 0 &&
                                                                    secondCards.map((card, index) => {
                                                                        if (!card.unselectable && card.cardType !== cardTypes.CARD_STORAGE)
                                                                            return (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type='button' onClick={() => this.selectSecondCard(index)} disabled={!Boolean(card.cardStatus)} className={!Boolean(card.cardStatus) ? "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"}>{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                                </li>
                                                                            );
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                                {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> */}
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group___grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${amountStatus == 1 && (amount.length < 1 || amount == "0") ? "input-outline" : ""}`}>
                                                        <input onBlur={() => this.setState({ amountStatus: 1 })} onChange={e => this.onChangeAmount(e.target.value)} value={amount} type="text" maxLength={12} disabled={step > 1} className={`dashboard-payment-container-data-section-content-container-form-input w-100 ${step > 1 && " dashboard-payment-container-data-section-content-container-form-input__disable"}`} placeholder="0.00" />
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{(exchangeCashlessList && exchangeCashlessList.length > 0) && currencyIndex >= 0 && exchangeCashlessList[currencyIndex]?.currency}</div> */}
                                                    </div>
                                                </div>

                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group___grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')} <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ currencyShow: false, exchangeStatus: currencyShow ? currencyIndex >= 0 ? 2 : 1 : exchangeStatus }) }}> */}
                                                    <div className={`dashboard-payment-container-data-section-content-container-form-select-box dashboard-payment-container-data-section-content-container-form-select-box-button ${exchangeStatus == 1 ? "input-outline" : ""}`}>
                                                        {/* <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step >= 2 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={e => { e.preventDefault(); this.setState({ currencyShow: !currencyShow, exchangeStatus: currencyShow ? currencyIndex >= 0 ? 2 : 1 : exchangeStatus }); }}> */}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{secondCards[secondCardsIndex]?.currency ?? 'AZN'}</div>
                                                        {/* <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div> */}
                                                        {/* </button> */}
                                                        {/* <ul className={(currencyShow === false || step >= 2) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                        {
                                                            exchangeCashlessList && exchangeCashlessList.length > 0 &&
                                                            exchangeCashlessList.map((item, index) => {
                                                                return (
                                                                    <li key={index} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                        <button onClick={e => this.selectExchange(e, index)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{item.currency}</button>
                                                                    </li>
                                                                );
                                                            })
                                                        }

                                                    </ul> */}
                                                    </div>
                                                    {/* </OutsideClickHandler> */}
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                            <button type='button' onClick={e => this.showFeeAmount()} disabled={!confirmedOperation || step == 2} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${(!confirmedOperation || step == 2) && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('continue')}</button>
                                            <button type='button' onClick={e => this.props.history.goBack()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                        </div>
                                    </div>
                                </form>
                                {/* } */}

                            </div >
                            {
                                step == 2 &&
                                // <div className="dashboard-payment-container-confirmation-section">
                                //     <div className="dashboard-payment-container-confirmation-section-header">
                                //         <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How do I transfer between my own cards?')}</h3>
                                //     </div>
                                //     <div className="dashboard-payment-container-confirmation-section-content">
                                //         <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                //         <div className="dashboard-payment-container-confirmation-section-content-description">
                                //             {/* <p className="dashboard-payment-container-confirmation-section-content-description-title">Where does it come from?</p> */}
                                //             {/* <p>{t('between my card content')}</p> */}
                                //             <p dangerouslySetInnerHTML={{ __html: info }}></p>
                                //         </div>
                                //         <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                //             <button onClick={e =>  this.showFeeAmount()} disabled={!confirmedOperation} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${!confirmedOperation && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('continue')}</button>
                                //             <button onClick={e => this.props.history.goBack()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                //         </div>
                                //     </div>
                                // </div>
                                // :
                                <div className="dashboard-payment-container-confirmation-section">
                                    {loadingFee ?
                                        <Loading /> : <>
                                            <div className="dashboard-payment-container-confirmation-section-header">
                                                <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                            </div>
                                            <div className="dashboard-payment-container-confirmation-section-content">
                                                <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                    <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter(secondCards[secondCardsIndex].currency ?? 'AZN')}</span>
                                                    <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(amount).part1}</span>
                                                    <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(amount).part2}</span>
                                                </h3>
                                                <ul className="dashboard-payment-container-confirmation-section-list">
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Withdrawal card')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{firstCards && firstCardsIndex >= 0 && firstCards.length > 0 && (fromAnotherCard ? t('Another bank card') : firstCards[firstCardsIndex].organizedCardNumber)}</p>
                                                    </li>
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Incoming card')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{secondCards && secondCardsIndex >= 0 && secondCards.length > 0 && secondCards[secondCardsIndex].organizedCardNumber}</p>
                                                    </li>
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('currency')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{secondCards[secondCardsIndex].currency ?? 'AZN'}</p>
                                                    </li>
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(amount).part1}.{this.amountSplit(amount).part2} {exchangeCashlessList && exchangeCashlessList.length > 0 && currencyIndex >= 0 && exchangeCashlessList[currencyIndex].currency}</p>
                                                    </li>
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission deducted from the incoming card')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{commission.part1}.{commission.part2} {commissionCurr}</p>
                                                    </li>
                                                    {
                                                        convertation !== null &&
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Exchange')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{convertation.part1}.{convertation.part2} {convertationCurr}</p>
                                                        </li>
                                                    }
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                            <button onClick={e =>
                                                                // firstCards[firstCardsIndex].cardType === cardTypes.CARD_STORAGE ? this.cardToCardWithOtherCards() : 
                                                                this.onSubmitForm()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                            <button onClick={e => this.cancel()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div></>
                                    }
                                </div>
                            }
                        </div>
                    }
                </Fragment >
            </>
        )
    }
}
export default translate("translation")(CardTopUp);
