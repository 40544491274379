import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import cardsIcon from './../../../img/cards-icon.png';
import CheckboxMinus from './../../../../../copms/Checkbox/CheckboxMinus';
import CheckboxTick from './../../../../../copms/Checkbox/CheckboxTick';

class Operations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }
    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data })
        }
    }
    render() {
        let { t } = this.props;
        return (
            <Fragment>
                {
                    this.state.data.length > 0 ?
                        <div className="dashboard-transfer-list-content">
                            <table className="dashboard-transfer-list-table">
                                <thead className="dashboard-transfer-list-table-head">
                                    <tr className="dashboard-transfer-list-table-row">
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">
                                            <CheckboxMinus
                                                onChecked={val => {
                                                    this.props.selectAll(val)
                                                }} />
                                        </th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("SENDER ACCOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("RECİPİENTS NAME")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("RECİPİENTS ACCOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("AMOUNT")}</th>
                                        <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                    </tr>
                                </thead>
                                <tbody className="dashboard-transfer-list-table-body">
                                    {
                                        this.state.data.map((item, index) => {
                                            return (
                                                <tr className="dashboard-transfer-list-table-row">
                                                    <td className="dashboard-transfer-list-table-cell">
                                                        <CheckboxTick checked={item.isSelect} onChecked={val => {
                                                            this.props.selectListFunc(index, val)
                                                        }} />
                                                    </td>
                                                    <td>{item.createdDate.split(" ")[0]}</td>
                                                    <td title={item.dtIban}>{item.dtIban.length > 17 ? item.dtIban.substr(0, 17) + '...' : item.dtIban}</td>
                                                    <td>{item.crName}</td>
                                                    <td title={item.crIban}>{item.crIban.length > 17 ? item.crIban.substr(0, 17) + '...' : item.crIban}</td>
                                                    <td>{item.amount || (typeof item.amount) === 'number' ? this.amountSplit(item.amount.toString()).part1 + '.' + this.amountSplit(item.amount.toString()).part2 : this.amountSplit(item.crAmt.toString()).part1 + '.' + this.amountSplit(item.crAmt.toString()).part2}</td>
                                                    <td>{item.currency ? item.currency : item.crCcy}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="dashboard-finance-section-invisible-cards-section">
                            <img src={cardsIcon} className="dashboard-finance-section-invisible-cards-section-image" alt="invisible-cards"/>
                            <div className="dashboard-finance-section-invisible-cards-section-paragraph">
                                <p className="dashboard-finance-section-invisible-cards-section-text">{t('You do not currently have any active transactions.')}</p>
                            </div>
                            <Link to="/dashboard/transfer/transfers/all" style={{ textDecoration: 'none' }} className="dashboard-finance-section-invisible-cards-section-button">{t('transfers')}</Link>
                        </div>
                }
            </Fragment>

        )
    }
}
export default translate("translation")(Operations);