import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next';
import TransferModal from './../../../../../../copms/Modal/TransferModal';
import transferConfirmation from './../../../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../../../helpers/currencyFilter';
import { request } from './../../../../../../config';
import CreateTransferTemplate from './../../../../../../copms/Modal/CreateTransferTemplate';
import OutsideClickHandler from 'react-outside-click-handler';
import TransferBetweenModal from '../../../../../../copms/Modal/TransferBetweenModal';

class CreateTransferBetween extends Component {
    state = {
        feedbackErrorModal: null,
        createFeedbackMessage: false,
        open: false,
        confirmedOperation: false,
        iban: "",
        amount: "",
        organizedAmount: {},
        custName: "",
        taxNumber: "",
        currency: "",
        purpose: "",
        accounts: [],
        noCardAccounts: [],
        feedbackMessage: null,
        dropdownVisibility: false,
        crDropdownVisibility: false,
        dtAccountId: null,
        dtAccountIban: null,
        selectedAccount: null,
        crIbanValidation: false,
        dtAccontIdValidation: false,
        amountValidation: false,
        purposeValidation: false,
        operationId: 0,
        accountData: false,
        noCardAccountData: false,
        interOpNumber: null,

        interOpNumberOutline: null,
        amountOutline: false,
        purposeOutline: false,

        crIbanOpenCount: 0,
        dtAccontIdOpenCount: 0,

    }
    updateDtAccountId = (dtAccountId, dtAccountIban, event) => {
        this.setState({ dtAccountId: dtAccountId, dtAccountIban: dtAccountIban, selectedAccount: event.target.textContent, dtAccontIdValidation: true, dropdownVisibility: false });
    }
    updateCrAccountId = (crAccountId, crAccountIban, event) => {
        this.setState({ crAccountId: crAccountId, crAccountIban: crAccountIban, selectedCrAccount: event.target.textContent, crAccontIdValidation: true, crDropdownVisibility: false });
        this.showCrData(crAccountIban);
    }
    checkIban = (event) => {
        this.setState({
            feedbackMessage: null,
            custName: "",
            taxNumber: "",
            currency: "",
            crIbanValidation: false,
            // accounts: [],
            // accountData: false
        });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length > 28) {
            event.target.value = event.target.value.substring(0, 28);
        }
        event.target.value = event.target.value.toUpperCase();
        this.setState({ iban: event.target.value });
        if (event.target.value.length === 28) {
            // ========
        }
    }
    checkAmount = (event) => {
        this.setState({ amountValidation: false });
        let mask = /[^0-9.]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.indexOf('.') !== -1) {
            let amount = event.target.value;
            let firstPointIndex = amount.indexOf('.');
            let wholePartAmount = amount.substr(0, firstPointIndex);
            let fractionalPartAmount = "";
            let afterPoint = 0;
            for (let i = firstPointIndex + 1; i < amount.length; i++) {
                if (amount[i] !== '.') {
                    fractionalPartAmount += amount[i];
                    afterPoint++;
                }
                if (afterPoint === 2) break;
            }
            if (firstPointIndex === 0) {
                wholePartAmount = 0;
            }
            event.target.value = wholePartAmount + '.' + fractionalPartAmount;
            this.setState({ amount: wholePartAmount + '.' + fractionalPartAmount });
            this.setState({ organizedAmount: { whole: parseInt(wholePartAmount), fractional: parseInt(fractionalPartAmount) } });
        }
        else {
            this.setState({ amount: event.target.value });
        }
        // =========BALANCE=========
        let amount = event.target.value;
        if (amount.toString() === "0") {
            this.setState({ organizedAmount: { whole: "0", fractional: "00" } });
        }
        else {
            if (amount.toString().indexOf('.') !== -1) {
                let currentBalanceArray = amount.toString().split('.');
                let fractional = currentBalanceArray[1].length === 1 ? currentBalanceArray[1] + '0' : currentBalanceArray[1];
                this.setState({ organizedAmount: { whole: currentBalanceArray[0], fractional: fractional } });
            }
            else {
                if (event.target.value !== "") {
                    this.setState({ organizedAmount: { whole: amount.toString(), fractional: "00" } });
                }
            }
        }
        if (isNaN(parseFloat(event.target.value)) === true || parseFloat(amount) <= 0) {
            this.setState({ amountValidation: false });
        }
        else {
            this.setState({ amountValidation: true });
            this.setState({ amountOutline: false });
        }
    }
    checkPurpose = (event) => {
        if (event.target.value[0] === " ") event.target.value = event.target.value.substring(1);
        let value = event.target.value;
        this.setState({ purpose: value });
        if (value === "") {
            this.setState({ purposeValidation: false });
        }
        else {
            this.setState({ purposeValidation: true });
            this.setState({ purposeOutline: false });
        }
    }
    showNoCardAccounts = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 1
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                isBalanceAccount: 1
            }

        fetch(request("account/getNoCardAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                    }
                    // getNoCardAccountListForCustomerResponse.accountList = getNoCardAccountListForCustomerResponse.accountList.filter(account => account.currName === this.state.currency);
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        // getNoCardAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getNoCardAccountListForCustomerResponse.accountList[i].currName);
                    }
                    this.setState({ noCardAccounts: getNoCardAccountListForCustomerResponse.accountList, noCardAccountData: true });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showAccounts = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            isBalanceAccount: 1
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                isBalanceAccount: 1
            }

        fetch(request("account/getAccountListFor" + customerType + "Customer", getAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAccountListForCustomerResponse = JSON.parse(getAccountListForCustomerResponseJSON);
                if (getAccountListForCustomerResponse.status.statusCode !== 1 && getAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAccountListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getAccountListForCustomerResponse.status.statusCode === 126) {
                        getAccountListForCustomerResponse.accountList = [];
                    }
                    // getAccountListForCustomerResponse.accountList = getAccountListForCustomerResponse.accountList.filter(account => account.currName === this.state.currency);
                    for (let i = 0; i < getAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                let currentBalanceArray = getAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        // getAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getAccountListForCustomerResponse.accountList[i].currName);
                    }
                    this.setState({ accounts: getAccountListForCustomerResponse.accountList, accountData: true });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showTransferInternal = () => {
        this.setState({ confirmedOperation: true });
    }
    createTransferInternal = () => {
        this.setState({ feedbackErrorModal: null });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createInternalOperationCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            
            dtAccountId: this.state.dtAccountId,
            crAccountId: this.state.crAccountId,
            amount: this.state.amount,
            purpose: this.state.purpose && this.state.purpose.trim(),

        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                
                dtAccountId: this.state.dtAccountId,
                crAccountId: this.state.crAccountId,
                amount: this.state.amount,
                purpose: this.state.purpose && this.state.purpose.trim(),
            }
        if (localStorage.getItem("compId") && localStorage.getItem("isOperNo") === "1") {
            createInternalOperationCustomerRequestBody.interOpNumber = this.state.interOpNumber;
        }

        if (this.state.taxNumber !== "") {
            createInternalOperationCustomerRequestBody.taxNo = this.state.taxNumber;
        }

        fetch(request(customerType == "Juridical" ? "operation/betweenCustomerAccountsOperationJuridical" : "operation/betweenCustomerAccountsOperationAutoConfirmIndividual", createInternalOperationCustomerRequestBody))
            .then(res => res.text())
            .then((createInternalOperationCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createInternalOperationCustomerResponse = JSON.parse(createInternalOperationCustomerResponseJSON);
                if (createInternalOperationCustomerResponse.status.statusCode !== 1) {
                    if (createInternalOperationCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createInternalOperationCustomerResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ createFeedbackMessage: true, open: true, feedbackErrorModal: null, operationId: createInternalOperationCustomerResponse.operationId });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    changeDropdownVisibility = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibility: !this.state.dropdownVisibility }, () => {
            if(!this.state.dtAccontIdValidation) {
                this.setState({ dtAccontIdOpenCount: ++ this.state.dtAccontIdOpenCount });
            }
        });
    }
    closeDropdownVisibility = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibility: false });
    }
    changeCrDropdownVisibility = (event) => {
        event.preventDefault();
        this.setState({ crDropdownVisibility: !this.state.crDropdownVisibility }, () => {
            if(!this.state.crAccontIdValidation) {
                this.setState({ crIbanOpenCount: ++ this.state.crIbanOpenCount});
            }
        });
    }
    closeCrDropdownVisibility = (event) => {
        event.preventDefault();
        this.setState({ crDropdownVisibility: false});
    }
    cancelTransferInternal = () => {
        this.props.history.push('/dashboard/transfer/transfers');
    }
    updateOpenTemp = (value) => {
        this.setState({ openTemp: value });
    }
    updateOpenModal = (value) => {
        this.setState({ open: value });
    }
    showCrData = (iban) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCustInfoByIbanRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            iban: iban
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                iban: iban
            }
        fetch(request("operation/getCustInfoByIban", getCustInfoByIbanRequestBody))
            .then(res => res.text())
            .then((getCustInfoByIbanResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCustInfoByIbanResponse = JSON.parse(getCustInfoByIbanResponseJSON);
                if (getCustInfoByIbanResponse.status.statusCode !== 1) {
                    if (getCustInfoByIbanResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({ feedbackMessage: this.props.t(getCustInfoByIbanResponse.status.statusCode) });
                    }
                }
                else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.setState({
                        crIbanValidation: true,
                        custName: getCustInfoByIbanResponse.custName,
                        currency: getCustInfoByIbanResponse.currName
                    });
                    if (getCustInfoByIbanResponse.taxNumber) {
                        this.setState({ taxNumber: getCustInfoByIbanResponse.taxNumber });
                    }
                    this.props.updateAppState('isLoading', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

    }

    checkInterOpNumber = (event) => {
        this.setState({ interOpNumber: event.target.value }, () => {
            if (this.state.interOpNumber) {
                this.setState({ interOpNumberOutline: false });
            }
        });
    }

    setAmountOutline = () => {
        if (!this.state.amountValidation) {
            this.setState({ amountOutline: true });
        }
        else {
            this.setState({ amountOutline: false });
        }
    }

    setInterOpNumberOutline = (event) => {
        if (!this.state.interOpNumber) {
            this.setState({ interOpNumberOutline: true });
        }
        else {
            this.setState({ interOpNumberOutline: false });
        }
    }

    setPurposeOutline = () => {
        if (!this.state.purposeValidation) {
            this.setState({ purposeOutline: true });
        }
        else {
            this.setState({ purposeOutline: false });
        }
    }

    componentDidMount() {
        // this.showNoCardAccounts();
        this.showAccounts();
    }

    render() {
        let { t, history } = this.props;
        let { open, openTemp, dropdownVisibility, feedbackMessage, custName, taxNumber, accounts, noCardAccounts, selectedAccount, currency, organizedAmount, createFeedbackMessage, feedbackErrorModal,
            crIbanValidation, dtAccountIban, purpose, dtAccontIdValidation, amountValidation, purposeValidation, confirmedOperation, accountData, noCardAccountData, interOpNumber,
            selectedCrAccount, crDropdownVisibility, crAccountIban,
            interOpNumberOutline, amountOutline, purposeOutline,
            crIbanOpenCount, dtAccontIdOpenCount } = this.state;
        let requestBody = localStorage.getItem('compId') === null ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: this.state.operationId,
        } : {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                operId: this.state.operationId,
            };
        return (
            <Fragment>
                <CreateTransferTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={localStorage.getItem('compId') ? 'addOperationTempJuridical' : 'addOperationTempIndividual'} requestBody={requestBody} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} />
                <TransferBetweenModal open={open} createTemplate={() => this.setState({ confirmedOperation: false, openTemp: true })} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} errorClose={() => this.setState({ confirmedOperation: false })} updateOpenModal={this.updateOpenModal} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Between')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section">
                        <div className="dashboard-payment-container-data-section-header">
                            <h3 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h3>
                        </div>
                        <form className="dashboard-payment-container-data-section-content">
                            <div className="dashboard-payment-container-data-section-content-container">
                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Receiving funds')}</h3>
                                <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                        {
                                            localStorage.getItem("isOperNo") === "1" &&
                                            <div style={{ width: '100%' }}>
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Transfer number')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                        <input type="text" disabled={confirmedOperation} className={"dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole " + (interOpNumberOutline ? "input-outline" : "")} onChange={this.checkInterOpNumber} onBlur={this.setInterOpNumberOutline} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* accounts.filter((item) => (item.iban !== crAccountIban)) */}
                                        {/* ============================= */}
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients account (IBAN)')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={this.closeCrDropdownVisibility}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (crIbanOpenCount > 1 && !crIbanValidation || crIbanOpenCount === 1 && !crIbanValidation && !crDropdownVisibility ? "input-outline" : "")}>
                                                    <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation} onClick={this.changeCrDropdownVisibility}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                            {
                                                                accounts && accounts.filter((item) => (item.iban !== dtAccountIban)).length === 0 && accountData ? t('126') : selectedCrAccount
                                                            }
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        accounts.length !== 0 &&
                                                        <ul className={(crDropdownVisibility === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                accounts && accounts.filter((item) => (item.iban !== dtAccountIban)).map(account => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateCrAccountId.bind(this, account.accountId, account.iban)}>{`${account.iban} / ${account?.mainCardNumber ? account?.mainCardNumber + "/" : ""} ${account.organizedCurrentBalance.wholePart}.${account.organizedCurrentBalance.fractionalPart} ${account.currName}`}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                        {/* ============================= */}
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients name')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={custName} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('tin')}</label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={taxNumber} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" value={currency} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box" style={{ border: 0 }}>
                                                <div className="dashboard-settings-section-form-validation-feedback">
                                                    {
                                                        feedbackMessage && <p className="dashboard-settings-section-form-validation-feedback-message">{feedbackMessage}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-payment-container-data-section-content-container">
                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>
                                <div className="dashboard-payment-container-data-section-content-container-form">
                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('sum')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" disabled={confirmedOperation} placeholder="0.00" onChange={this.checkAmount} onBlur={this.setAmountOutline} />
                                                <div className={confirmedOperation ?
                                                    "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency dashboard-payment-container-data-section-content-container-form-icon__disable" :
                                                    "dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency"
                                                }>{currency}</div>
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by account')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={this.closeDropdownVisibility}>
                                                <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (dtAccontIdOpenCount > 1 && !dtAccontIdValidation || dtAccontIdOpenCount === 1 && !dtAccontIdValidation && !dropdownVisibility ? "input-outline" : "")}>
                                                    <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={confirmedOperation} onClick={this.changeDropdownVisibility}>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                            {
                                                                accounts && accounts.filter((item) => (item.iban !== crAccountIban)).length === 0 && accountData ? t('126') : selectedAccount
                                                            }
                                                        </div>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    {
                                                        accounts.length !== 0 &&
                                                        <ul className={(dropdownVisibility === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                            {
                                                                accounts.filter((item) => (item.iban !== crAccountIban)).map(account => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateDtAccountId.bind(this, account.accountId, account.iban)}>{`${account.iban} / ${account?.mainCardNumber ? account?.mainCardNumber + "/" : ""} ${account.organizedCurrentBalance.wholePart}.${account.organizedCurrentBalance.fractionalPart} ${account.currName}`}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Appointment')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (purposeOutline ? "input-outline" : "")}>
                                                <input maxLength="250" value={purpose} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={confirmedOperation} onChange={this.checkPurpose} onBlur={this.setPurposeOutline} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                        confirmedOperation === false ?
                            <div className="dashboard-payment-container-confirmation-section">
                                <div className="dashboard-payment-container-confirmation-section-header">
                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How should the bank make between my own accounts payment')}</h3>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section-content">
                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" alt="confirmation-content-image" />
                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                        {t('Between content')}
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        {
                                            crIbanValidation && dtAccontIdValidation && amountValidation && purposeValidation
                                                && ((localStorage.getItem("isOperNo") === "1" && interOpNumber) || (localStorage.getItem("isOperNo") !== "1"))
                                                ?
                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.showTransferInternal}>{t('continue')}</button>
                                                :
                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left dashboard-payment-container-data-section-content-container-form-footer-button__disable">{t('continue')}</button>
                                        }
                                        <button onClick={this.cancelTransferInternal} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="dashboard-payment-container-confirmation-section">
                                <div className="dashboard-payment-container-confirmation-section-header">
                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section-content">

                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                        <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + currency.toLocaleLowerCase()}>{currencyFilter(currency)}</span>
                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{organizedAmount.whole}</span>
                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{organizedAmount.fractional}</span>
                                    </h3>
                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Between')} {t('Transfer')}</p>
                                        </li>
                                        {
                                            taxNumber &&
                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('tin')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{taxNumber}</p>
                                            </li>
                                        }
                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('To account')}</p>
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{crAccountIban}</p>
                                        </li>
                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('From account')}</p>
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{dtAccountIban}</p>
                                        </li>
                                    </ul>
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.createTransferInternal}>{t('Create operation')}</button>
                                        <button onClick={this.cancelTransferInternal} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </Fragment>
        )
    }
}

export default translate("translation")(CreateTransferBetween);