import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { request } from '../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import CardOperationModal from '../../../../copms/Modal/CardOperationModal';
import CreateCardOperationTemplate from './../../../../copms/Modal/CreateCardOperationTemplate';
import OutsideClickHandler from 'react-outside-click-handler';
import { cardTypes } from '../../../../helpers/cards';
class Code extends Component {
    state = {
        confirmedOperation: false,
        dropdownVisibilityCard: false,
        dropdownVisibilityCurrency: false,
        selectedCard: "",
        destinationPhoneNumber: "",
        destinationPhoneNumberValidation: false,
        amount: "",
        amountOrganized: {},
        amountValidation: false,
        card: null,
        requestorCardIdValidation: false,
        currency: {},
        currencyValidation: false,
        exchangeCashlessList: [],
        cards: [],
        open: false,
        openTemp: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        cardData: false,

        amountOutline: false,
        destinationPhoneNumberOutline: false,
        requestorCardIdCount: 0,
        currencyCount: 0,
        commission: null,
        info: null
    }

    getPhoneNumber = (number) => {
        ['+', '-', '(', ')', ' '].forEach(char => {
            while (number.indexOf(char) !== -1) {
                number = number.slice(0, number.indexOf(char)) + number.slice(number.indexOf(char) + 1, number.length);
            }
        });
        return number;
    }

    showCards = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardListCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        fetch(request("card/getCardListFor" + customerType + "Customer", getCardListCustomerRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getCardListForCustomerResponse.status.statusCode === 137) {
                        getCardListForCustomerResponse.cardList = [];
                    }
                    for (let i = 0; i < getCardListForCustomerResponse.cardList.length; i++) {
                        // =========ID=========
                        let cardId = "";
                        if (typeof (getCardListForCustomerResponse.cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                            getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                cardId += part;
                            });
                        }
                        else {
                            cardId += getCardListForCustomerResponse.cardList[i].cardId;
                        }
                        getCardListForCustomerResponse.cardList[i].cardId = cardId;
                        // =========NUMBER=========
                        getCardListForCustomerResponse.cardList[i].organizedCardNumber = getCardListForCustomerResponse.cardList[i].cardNumber.substring(0, 4)
                            + " **** **** " + getCardListForCustomerResponse.cardList[i].cardNumber.substring(12, 16);

                    }
                    this.setState({ cards: getCardListForCustomerResponse.cardList, cardData: true });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    // showCurrencies() {
    //     fetch(request("exchange/cashless", {}, "GET"))
    //         .then(res => res.text())
    //         .then((cashlessResponseJSON) => {
    //             const JSON = require('true-json-bigint');
    //             let cashlessResponse = JSON.parse(cashlessResponseJSON);
    //             if (cashlessResponse.status.statusCode !== 1) {
    //                 if (cashlessResponse.status.statusCode === 200) {
    //                     this.props.logout();
    //                 } else {
    //                     this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(cashlessResponse.status.statusCode));
    //                     this.props.updateAppState('isLoadingTransparentBackground', false);
    //                 }
    //             }
    //             else {
    //                 let currencies = ["AZN"];
    //                 for (let i = 0; i < cashlessResponse.exchangeCashlessList.length; i++) {
    //                     currencies.push(cashlessResponse.exchangeCashlessList[i].currency);
    //                 }
    //                 this.setState({ currencies: currencies });
    //             }
    //         },
    //             (error) => {
    //                 this.props.history.push("/technical-break");
    //             });
    // }

    changeDropdownVisibilityCard = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCard: !this.state.dropdownVisibilityCard }, () => {
            if(!this.state.requestorCardIdValidation) {
                this.setState({ requestorCardIdCount: ++ this.state.requestorCardIdCount});
            }
        });
    }

    changeDropdownVisibilityCurrency = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityCurrency: !this.state.dropdownVisibilityCurrency }, () => {
            if(!this.state.currencyValidation) {
                this.setState({ currencyCount: ++ this.state.currencyCount});
            }
        });
    }

    updateCard(card, event) {
        event.preventDefault();
        this.setState({ card: card, selectedCard: event.target.textContent, dropdownVisibilityCard: false, requestorCardIdValidation: true });
    }

    updateCurrency(currency, event) {
        event.preventDefault();
        this.setState({ currency: currency, dropdownVisibilityCurrency: false, currencyValidation: true });
    }

    checkAmount = (event) => {
        if (/^\d+$/.test(event.target.value) || event.target.value.length === 0) {
            this.setState({ amount: event.target.value }, () => {
                let amount = this.state.amount, amountOrganized = {};
                if (amount.toString() === "0") {
                    amountOrganized = {
                        wholePart: "0",
                        fractionalPart: "00"
                    }
                }
                else {
                    if (amount.toString().indexOf('.') !== -1) {
                        let amountArray = amount.toString().split('.');
                        amountOrganized = {
                            wholePart: amountArray[0],
                            fractionalPart: (amountArray[1].length === 1) ? (amountArray[1] + '0') : amountArray[1]
                        }
                    }
                    else {
                        amountOrganized = {
                            wholePart: amount.toString(),
                            fractionalPart: "00"
                        }
                    }
                }
                this.setState({ amountOrganized });
                if (amount.length === 0 || amount == 0) {
                    this.setState({ amountValidation: false });
                }
                else {
                    this.setState({ amountValidation: true });
                    this.setState({amountOutline: false});
                }
            });
        }
    }

    checkDestinationPhoneNumber = (event) => {
        if (event.target.value.indexOf("_") === -1) {
            this.setState({ destinationPhoneNumberValidation: true });
            this.setState({destinationPhoneNumberOutline: false});
        }
        else {
            this.setState({ destinationPhoneNumberValidation: false });
        }
        this.setState({ destinationPhoneNumber: event.target.value });
    }

    showCashByCode = () => {
        this.setState({ confirmedOperation: true });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getFeeAmountRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: 3,
            amount: parseFloat(this.state.amount),
            senderType: 1,
            custType: 2,
            cardNumber: this.state.card.cardNumber,
            cardId: this.state.card.cardId,
            // cardCcyCode: this.state.currency.code,
            cardCcyCode: this.state.exchangeCashlessList.find(currency => currency.currency === this.state.card.currency).code,
            ccyCode: this.state.currency.code
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: 3,
            amount: parseFloat(this.state.amount),
            senderType: 1,
            custType: 1,
            cardNumber: this.state.card.cardNumber,
            cardId: this.state.card.cardId,
            cardCcyCode: this.state.exchangeCashlessList.find(currency => currency.currency === this.state.card.currency).code,
            // cardCcyCode: this.state.currency.code,
            ccyCode: this.state.currency.code
        }
        fetch(request("card/getFeeAmount", getFeeAmountRequestBody))
            .then(res => res.text())
            .then((getFeeAmountResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getFeeAmountResponse = JSON.parse(getFeeAmountResponseJSON);
                if (getFeeAmountResponse.status.statusCode !== 1) {
                    if (getFeeAmountResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getFeeAmountResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({
                        commission: getFeeAmountResponse.feeAmount
                    })
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    createCashByCode = () => {
        this.setState({ confirmedOperation: true });
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let cashByCodeRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            destinationPhoneNumber: this.getPhoneNumber(this.state.destinationPhoneNumber),
            requestorCardId: this.state.card.cardId,
            amount: this.state.amount,
            currency: this.state.currency.currency,
            
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                destinationPhoneNumber: this.getPhoneNumber(this.state.destinationPhoneNumber),
                requestorCardId: this.state.card.cardId,
                amount: this.state.amount,
                currency: this.state.currency.currency,
                
            }
        fetch(request("card/cashByCode", cashByCodeRequestBody))
            .then(res => res.text())
            .then((cashByCodeResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cashByCodeResponse = JSON.parse(cashByCodeResponseJSON);
                if (cashByCodeResponse.status.statusCode !== 1) {
                    if (cashByCodeResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(cashByCodeResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ createFeedbackMessage: true, open: true });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateOpen = (value) => {
        this.setState({ open: value });
    }

    updateOpenTemp = (value) => {
        this.setState({ openTemp: value });
    }

    cancel = () => {
        this.setState({
            confirmedOperation: false,
            dropdownVisibilityCard: false,
            dropdownVisibilityCurrency: false,
            selectedCard: "",
            destinationPhoneNumber: "",
            destinationPhoneNumberValidation: false,
            amount: "",
            amountOrganized: {},
            amountValidation: false,
            card: null,
            requestorCardIdValidation: false,
            currency: "",
            currencyValidation: false,
            open: false,
            createFeedbackMessage: false,
            feedbackErrorModal: null,
        })
    }

    setDestinationPhoneNumberOutline = () => {
        if (!this.state.destinationPhoneNumberValidation) {
            this.setState({ destinationPhoneNumberOutline: true });
        }
        else {
            this.setState({ destinationPhoneNumberOutline: false });
        }
    }

    setAmountOutline = () => {
        if (!this.state.amountValidation) {
            this.setState({ amountOutline: true });
        }
        else {
            this.setState({ amountOutline: false });
        }
    }

    componentDidMount() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.getCardOperationInfo();
        fetch(request("exchange/cashless", {}, "GET"))
            .then(res => res.text())
            .then((cashlessResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cashlessResponse = JSON.parse(cashlessResponseJSON);
                if (cashlessResponse && cashlessResponse.exchangeCashlessList) {

                    if (cashlessResponse.exchangeCashlessList.findIndex(item => item.currency == 'AZN') < 0) {
                        cashlessResponse.exchangeCashlessList.push({ currency: "AZN", code: 944, cashlessBuy: 1, cashlessSell: 1 });
                    }
                    this.setState({ exchangeCashlessList: cashlessResponse.exchangeCashlessList });
                }
                this.showCards();
            })
    }

    getCardOperationInfo = async () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getCardOperationInfoRequest = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "CASH_BY_CODE"
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            cardOperationType: "CASH_BY_CODE"
        }
        await fetch(request("util/getCardOperationInfo", getCardOperationInfoRequest))
            .then(res => res.json())
            .then((getCardOperationInfoResponse) => {
                if (getCardOperationInfoResponse.status.statusCode !== 1) {
                    if (getCardOperationInfoResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardOperationInfoResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ info: getCardOperationInfoResponse.info });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    render() {
        let { t, history } = this.props;
        let { confirmedOperation, open, openTemp, cards, currencies, selectedCard, dropdownVisibilityCard, dropdownVisibilityCurrency, createFeedbackMessage, feedbackErrorModal, amountOrganized, amount, currency, destinationPhoneNumber, card,
            destinationPhoneNumberValidation, amountValidation, requestorCardIdValidation, currencyValidation, cardData,
            destinationPhoneNumberOutline, amountOutline, requestorCardIdCount, currencyCount, commission, exchangeCashlessList, currencyIndex, info } = this.state;
        let requestBody = localStorage.getItem('compId') === null ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            destinationPhoneNumber: this.getPhoneNumber(destinationPhoneNumber),
            requestorCardNumber: this.state.card?.cardNumber,
            requestorCardId: Number(this.state.card?.cardId),
            currency: currency.currency,
            amount
        } : {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                destinationPhoneNumber: this.getPhoneNumber(destinationPhoneNumber),
                requestorCardNumber: this.state.card?.cardNumber,
                requestorCardId: Number(this.state.card?.cardId),
                currency: currency.currency,
                amount
            };

        return (
            <Fragment>
                <CreateCardOperationTemplate open={openTemp} updateOpen={this.updateOpenTemp} requestURL={'saveCashByCodeTemp'} requestBody={requestBody} />
                <CardOperationModal open={open} updateOpen={this.updateOpen} successClose={() => { history.push('/dashboard/transfer/card-operation') }} errorClose={() => { this.setState({ confirmedOperation: false }) }} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />

                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Cash by Code')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section">
                        <div className="dashboard-payment-container-data-section-header">
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <h2 className="dashboard-payment-container-data-section-header-title">{t('Make a transfer')}</h2>
                            </div>
                            <button className="dashboard-payment-container-data-section-header-button" onClick={() => { this.setState({ openTemp: true }) }} disabled={!Boolean(destinationPhoneNumberValidation && amountValidation && requestorCardIdValidation && currencyValidation)}>{t("Save as template")}</button>
                        </div>

                        <form className="dashboard-payment-container-data-section-content">
                            <div className="dashboard-payment-container-data-section-content-container">
                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Transfer settings')}</h3>
                                <div className="dashboard-payment-container-data-section-content-container-form">
                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Recipients mobile number')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (destinationPhoneNumberOutline ? "input-outline" : "")}>
                                                <InputMask mask="+\9\9\4 ( 99 ) 999-99-99" value={destinationPhoneNumber} disabled={Boolean(confirmedOperation)} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" placeholder="+994 (__) ___-__-__" onChange={this.checkDestinationPhoneNumber} onBlur={this.setDestinationPhoneNumberOutline} />
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Pay by card')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => {this.setState({dropdownVisibilityCard: false})}}>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (requestorCardIdCount > 1 && !requestorCardIdValidation || requestorCardIdCount === 1 && !requestorCardIdValidation && !dropdownVisibilityCard ? "input-outline" : "")}>
                                                <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation)} onClick={this.changeDropdownVisibilityCard}>
                                                    {
                                                        selectedCard ?
                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{selectedCard}</div>
                                                            :
                                                            (cards && cards.length === 0 && cardData) ?
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{t('The card list is empty')}</div>
                                                                :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                    }                                                    
                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                        </svg>
                                                    </div>
                                                </button>
                                                <ul className={(dropdownVisibilityCard === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                    {
                                                        cards && cards.length > 0 &&
                                                        cards.map((card, index) => {
                                                            return (
                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                    <button onClick={this.updateCard.bind(this, card)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" disabled={!Boolean(card.cardStatus)}>{card.organizedCardNumber} / {card.balance} {card.currency}</button>
                                                                </li>
                                                            );
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                            </OutsideClickHandler>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('currency')} <span style={{ color: 'red' }}>*</span></label>
                                            <OutsideClickHandler onOutsideClick={() => {this.setState({dropdownVisibilityCurrency: false})}}>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-select-box " + (currencyCount > 1 && !currencyValidation || currencyCount === 1 && !currencyValidation && !dropdownVisibilityCurrency ? "input-outline" : "")}>
                                                <button className={confirmedOperation ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={Boolean(confirmedOperation)} onClick={this.changeDropdownVisibilityCurrency}>
                                                    {
                                                        currency ?
                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{currency.currency}</div>
                                                            :
                                                            <div className="dashboard-payment-container-data-section-content-container-form-select" style={{ color: '#BDC3C7' }}>{t("Choose")}</div>
                                                    }
                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                        </svg>
                                                    </div>
                                                </button>
                                                <ul className={(dropdownVisibilityCurrency === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                    {
                                                        exchangeCashlessList && exchangeCashlessList.length > 0 &&
                                                        exchangeCashlessList.map((currency, index) => {
                                                            return (
                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                    <button onClick={this.updateCurrency.bind(this, currency)} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button">{currency.currency}</button>
                                                                </li>
                                                            );
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                            </OutsideClickHandler>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className={"dashboard-payment-container-data-section-content-container-form-input-box " + (amountOutline ? "input-outline" : "")}>
                                                <input type="text" className="dashboard-payment-container-data-section-content-container-form-input" value={amount} disabled={Boolean(confirmedOperation)} placeholder="0" onChange={this.checkAmount} value={amount} onBlur={this.setAmountOutline}/>
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{currency.currency}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                        confirmedOperation === false ?
                            <div className="dashboard-payment-container-confirmation-section">
                                <div className="dashboard-payment-container-confirmation-section-header">
                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('How to transfer Cash by code?')}</h3>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section-content">
                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                        {/* <p>{t('cash by code content')}</p> */}
                                        <p dangerouslySetInnerHTML={{ __html: info }}></p>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        {
                                            destinationPhoneNumberValidation && amountValidation && requestorCardIdValidation && currencyValidation ?
                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.showCashByCode}>{t('continue')}</button>
                                                :
                                                <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__disable dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                        }
                                        <button onClick={e => this.props.history.push('/dashboard/transfer/card-operation')} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="dashboard-payment-container-confirmation-section">
                                <div className="dashboard-payment-container-confirmation-section-header">
                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Confirmation of transfer')}</h3>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section-content">
                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                        <span className={"dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__" + currency.currency.toLocaleLowerCase()}>{currencyFilter(currency.currency)}</span>
                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{amountOrganized.wholePart}</span>
                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{amountOrganized.fractionalPart}</span>
                                    </h3>
                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Name of the service')}</p>
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{t('Cash by Code')} {t('operation')}</p>
                                        </li>
                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Withdrawal card')}</p>
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedCard}</p>
                                        </li>
                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Mobile number of the recipient')}</p>
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.getPhoneNumber(destinationPhoneNumber)}</p>
                                        </li>
                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{amountOrganized.wholePart}.{amountOrganized.fractionalPart} {currency.currency}</p>
                                        </li>
                                        {
                                            commission !== null &&
                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{commission} {this.state.exchangeCashlessList.find(currency => currency.currency === this.state.card.currency).currency}</p>
                                            </li>
                                        }
                                    </ul>
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left" onClick={this.createCashByCode}>{t('Create operation')}</button>
                                        <button onClick={this.cancel} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </Fragment>
        )
    }
}
export default translate("translation")(Code);
