import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import eyeIcon from './../../../img/eye.svg';
import eyeSlashIcon from './../../../img/eye-slash.svg';

// import pen from './../../img/eye-slash.svg';
import pen from './../../../img/pen.svg';
// import checkCircleDisable from './../../../img/check-circle-disable.svg';
// import checkCircleSuccess from './../../../img/check-circle-success.svg';
// import checkCircleError from './../../img/check-circle-error.svg';
import { request } from '../../../../../config';
import { translate } from 'react-i18next';

class ChangePassword extends Component {
    state = {
        prevPassword: "",
        newPassword: "",
        newPasswordRepeat: "",

        symbolLength: null,
        letterSymbol: null,
        numericSymbol: null,
        successMessage: null,
        feedbackMessage: null,
        isRepeatPasswordSame: false,
        isPrevPasswordNotEmpty: false,
        isPrevPasswordVisibility: false,
        isNewPasswordVisibility: false,
        isNewPasswordRepeatVisibility: false,

        prevPasswordOutline: false,
        newPasswordOutline: false,
        newPasswordRepeatOutline: false,
    }
    handleInputClick = (divName) => {

        const { divVisibility, div1 } = this.state;

        // Create a new object by setting the visibility of all divs to false, except the clicked one
        const updatedVisibility = Object.keys(divVisibility).reduce((acc, key) => {
            acc[key] = key === divName;
            return acc;
        }, {});
        (divName == 'div1') ?
            this.setState({ divVisibility: updatedVisibility, buttonVisibility: true })
            : this.setState({ divVisibility: updatedVisibility, deviceVisibility: true })

    };
    handleButtonClick = () => {
        this.props.history.push('/dashboard/settings/security');
    };
    checkPrevPasswordNotEmpty = (event) => {
        this.setState({ successMessage: null, feedbackMessage: null })
        if (event.target.value.trim().length > 0) {
            this.setState({ isPrevPasswordNotEmpty: true });
            this.setState({ prevPasswordOutline: false });
        }
        else {
            this.setState({ isPrevPasswordNotEmpty: false });
        }
        this.setState({ prevPassword: event.target.value });
    }

    checkValidationNewPassword = (event) => {
        this.setState({ newPassword: event.target.value });
        this.setState({ successMessage: null, feedbackMessage: null, newPasswordOutline: false });
        let mask = /[^a-zA-Z0-9]/g; //temporay
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (/\d/.test(event.target.value)) {
            this.setState({ numericSymbol: true });
        }
        else {
            if (event.target.value.length === 0) {
                this.setState({ numericSymbol: null });
            }
            else {
                this.setState({ numericSymbol: false });
            }
        }
        if (/[a-zA-Z]/.test(event.target.value)) {
            this.setState({ letterSymbol: true });
        }
        else {
            if (event.target.value.length === 0) {
                this.setState({ letterSymbol: null });
            }
            else {
                this.setState({ letterSymbol: false });
            }
        }
        if (event.target.value.length > 5) {
            this.setState({ symbolLength: true });
        }
        else {
            if (event.target.value.length > 0) {
                this.setState({ symbolLength: false });
            }
            else {
                this.setState({ symbolLength: null });
            }
        }
        if (this.state.newPasswordRepeat === event.target.value) {
            this.setState({ isRepeatPasswordSame: true });
        }
        else {
            this.setState({ isRepeatPasswordSame: false });
        }
    }

    checkRepeatPasswordSame = (event) => {
        this.setState({ newPasswordRepeat: event.target.value });
        this.setState({ successMessage: null, feedbackMessage: null })
        if (event.target.value === this.state.newPassword) {
            this.setState({ isRepeatPasswordSame: true });
        }
        else {
            this.setState({ isRepeatPasswordSame: false });
        }
    }

    setPasswordVisibility = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    changePassword = (event) => {
        event.preventDefault()
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let changePasswordRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            oldPassword: this.state.prevPassword,
            password: this.state.newPassword,
            repeatPassword: this.state.newPasswordRepeat,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            oldPassword: this.state.prevPassword,
            password: this.state.newPassword,
            repeatPassword: this.state.newPasswordRepeat,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("setting/changePassword" + customerType, changePasswordRequestBody))
            .then(res => res.json())
            .then(changePasswordResponse => {
                if (changePasswordResponse.status.statusCode !== 1) {
                    if (changePasswordResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(changePasswordResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('information changed successfully'));
                    this.setState({ successMessage: this.props.t(changePasswordResponse.status.statusCode) });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.props.history.push("/dashboard/settings/security");
                }
            }, (error) => {
                this.props.history.push("/technical-break");
            });
    }
    setPrevPasswordOutline = () => {
        if (this.state.isPrevPasswordNotEmpty) {
            this.setState({ prevPasswordOutline: false });
        }
        else {
            this.setState({ prevPasswordOutline: true });
        }
    }
    setNewPasswordOutline = () => {
        this.setState({ newPasswordOutline: true });
    }
    setNewPasswordRepeatOutline = () => {
        this.setState({ newPasswordRepeatOutline: true });
    }
    // prevPasswordOutline: false,
    // newPasswordOutline: false,
    // newPasswordRepeatOutline: false,
    componentDidMount() {
        this.props.updateSettingsState('settingsTitle', this.props.t('change the login password'));
        this.props.updateSettingsState('feedback', this.props.t('edit password info'));

    }

    render() {
        let {numericSymbol, letterSymbol, symbolLength, successMessage, feedbackMessage, isPrevPasswordVisibility, isNewPasswordVisibility, isNewPasswordRepeatVisibility, isRepeatPasswordSame, isPrevPasswordNotEmpty, prevPasswordOutline, newPasswordOutline, newPasswordRepeatOutline, newPassword, newPasswordRepeat, prevPassword } = this.state;
        let isDisabled;
        let { t } = this.props;
        if (symbolLength === false || symbolLength === null || letterSymbol === false || letterSymbol === null || numericSymbol === false || numericSymbol === null || isRepeatPasswordSame === false || isPrevPasswordNotEmpty === false || prevPassword == newPassword) {
            isDisabled = true;
        }
        else {
            isDisabled = false;
        }
        return (
            <form className="dashboard-settings-section-form">
                <div className="dashboard-settings-section-password">
                    <div className="dashboard-settings-section-password-change-section">
                                <div className="dashboard-settings-section-form-group" >
                                    <label className="dashboard-settings-section-form-group-label">{t('old password')}</label>
                                    <div className={"dashboard-settings-section-form-group-box " + (prevPasswordOutline ? "input-outline" : "")}>
                                        <input type={isPrevPasswordVisibility === false ? "password" : "text"} className="dashboard-settings-section-form-group-box-input default" placeholder={t('enter the old password')} onChange={this.checkPrevPasswordNotEmpty} onBlur={this.setPrevPasswordOutline} />
                                        <div className="dashboard-settings-section-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isPrevPasswordVisibility')} >
                                            {
                                                isPrevPasswordVisibility === false ?
                                                <img src={eyeSlashIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password-slash" alt="" />
                                                :
                                                <img src={eyeIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password" alt="" />
    
                                           }
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-settings-section-form-group">
                                    <label className="dashboard-settings-section-form-group-label">{t('new password')}</label>
                                    <div className={"dashboard-settings-section-form-group-box " + (!newPasswordOutline ? "" : !(numericSymbol && letterSymbol && symbolLength && prevPassword != newPassword) ? "input-outline" : "")}>
                                        <input type={isNewPasswordVisibility === false ? "password" : "text"} className="dashboard-settings-section-form-group-box-input default" placeholder={t('enter the new password')} onChange={this.checkValidationNewPassword} onBlur={this.setNewPasswordOutline} />
                                        <div className="dashboard-settings-section-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isNewPasswordVisibility')}>
                                            {
                                                isNewPasswordVisibility === false ?
                                                <img src={eyeSlashIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password-slash" alt="" />
                                                :
                                                <img src={eyeIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password" alt="" />
    
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-settings-section-form-group">
                                    <label className="dashboard-settings-section-form-group-label">{t('repeat the new password')}</label>
                                    <div className={"dashboard-settings-section-form-group-box " + (!newPasswordRepeatOutline ? "" : !isRepeatPasswordSame ? "input-outline" : "")}>
                                        <input type={isNewPasswordRepeatVisibility === false ? "password" : "text"} className="dashboard-settings-section-form-group-box-input default" placeholder={t('re-enter the new password')} onChange={this.checkRepeatPasswordSame} onBlur={this.setNewPasswordRepeatOutline} />
                                        <div className="dashboard-settings-section-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isNewPasswordRepeatVisibility')}>
                                            {
                                                isNewPasswordRepeatVisibility === false ?
                                                <img src={eyeSlashIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password-slash" alt="" />
                                                :
                                                <img src={eyeIcon} className="dashboard-settings-section-form-group-box-icon-img dashboard-settings-section-form-group-box-icon-img__password" alt="" />
    
                                           }
                                        </div>
                                    </div>
                                </div>
    
                        {/* <div className="dashboard-settings-section-general-form-container">
                            <div className="dashboard-settings-section-general-form-group">
                                <div className="dashboard-settings-section-general-form-group-feedback">
                                    <p className="dashboard-settings-section-general-form-group-feedback-message dashboard-settings-section-general-form-group-feedback-message__info" style={{ position: 'static', height: 'auto' }}>{t('If you change the password, an information message will be sent to your mobile number.')}</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="dashboard-settings-section-password-detail-section">
                        <div className="dashboard-settings-section-password-detail-section-item">
                            {
                                (symbolLength === null) ? <img src={checkCircleDisable} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (symbolLength === false) ? <img src={checkCircleError} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (symbolLength === true) ? <img src={checkCircleSuccess} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            <span className="dashboard-settings-section-password-detail-section-content">{t('must be at least 6 characters long')}</span>
                        </div>
                        <div className="dashboard-settings-section-password-detail-section-item">
                            {
                                (letterSymbol === null) ? <img src={checkCircleDisable} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (letterSymbol === false) ? <img src={checkCircleError} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (letterSymbol === true) ? <img src={checkCircleSuccess} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            <span className="dashboard-settings-section-password-detail-section-content">{t('must be at least one letter')}</span>
                        </div>
                        <div className="dashboard-settings-section-password-detail-section-item">
                            {
                                (numericSymbol === null) ? <img src={checkCircleDisable} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (numericSymbol === false) ? <img src={checkCircleError} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            {
                                (numericSymbol === true) ? <img src={checkCircleSuccess} className="dashboard-settings-section-password-detail-section-icon" alt="" /> : null
                            }
                            <span className="dashboard-settings-section-password-detail-section-content">{t('must be at least one digit')}</span>
                        </div>
                    </div> */}
                </div>
  
                    <div className="dashboard-settings-section-form-button-section">
                        <button className="dashboard-settings-section-form-button dashboard-settings-section-form-button__save" disabled={isDisabled} onClick={this.changePassword} >{t('save')}</button>
                        <button className="dashboard-settings-section-form-button dashboard-settings-section-form-button__cancel" onClick={this.handleButtonClick}>{t('refusal')}</button>
                    </div>
               
                {/* {!deviceVisibility && (

                    <div className="dashboard-settings-section-form-group" style={{ display: divVisibility.div2 ? 'block' : 'none' }}>
                        <label className="dashboard-settings-section-form-group-label">{t('devices')}</label>
                        <div className={"dashboard-settings-section-form-group-box " + (prevPasswordOutline ? "input-outline" : "")}>
                            <input readOnly className="dashboard-settings-section-form-group-box-input" placeholder={t('devices')} onChange={this.checkPrevPasswordNotEmpty} onBlur={this.setPrevPasswordOutline} onClick={() => this.handleInputClick('div2')} />
                            <div className="dashboard-settings-section-form-group-box-icon" >
                                <path d="M5.7373 5.84668C5.93262 5.65137 5.93262 5.37793 5.7373 5.18262L1.16699 0.651367C0.971679 0.456055 0.659179 0.456055 0.502929 0.651367L0.229492 0.924805C0.0341793 1.12012 0.0341793 1.39355 0.229492 1.58887L4.21387 5.49512L0.19043 9.44043C0.0341796 9.63574 0.0341797 9.90918 0.19043 10.1045L0.50293 10.3779C0.65918 10.5732 0.97168 10.5732 1.16699 10.3779L5.7373 5.84668Z" fill="#2B81C1" />
                            </div>
                        </div>
                    </div>
                )}
                {deviceVisibility && (
                    <>
                        <div className="dashboard-settings-section-form-group" >
                            <label className="dashboard-settings-section-form-group-label">{t('current devices')}</label>
                            <div className={"dashboard-settings-section-form-group-box " + (prevPasswordOutline ? "input-outline" : "")}>
                                <input readonly type="text" className="dashboard-settings-section-form-group-box-input" placeholder={t('devices')} onChange={this.checkPrevPasswordNotEmpty} onBlur={this.setPrevPasswordOutline} />
                                <div className="dashboard-settings-section-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isPrevPasswordVisibility')} >

                                </div>
                            </div>
                        </div>
                        <div className="dashboard-settings-section-form-group">
                            <label className="dashboard-settings-section-form-group-label">{t('current devices')}</label>
                            <div className={"dashboard-settings-section-form-group-box " + (!newPasswordOutline ? "" : !(numericSymbol && letterSymbol && symbolLength) ? "input-outline" : "")}>
                                <input readonly type="text" className="dashboard-settings-section-form-group-box-input" placeholder={t('devices')} onChange={this.checkValidationNewPassword} onBlur={this.setNewPasswordOutline} />
                                <div className="dashboard-settings-section-form-group-box-icon" onClick={this.setPasswordVisibility.bind(this, 'isNewPasswordVisibility')}>
                                </div>
                            </div>
                        </div>
                    </>
                )} */}

            </form>
        )
    }
}



export default translate("translation")(ChangePassword);