import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { YMaps, Map, Placemark, GeolocationControl, FullscreenControl, ZoomControl, RulerControl, Clusterer, } from 'react-yandex-maps';
import { Link, NavLink, Switch, Route, Redirect, } from 'react-router-dom';
import { request } from '../../../../config';
import bankIcon from './../../img/bank_icon.png';
import currentIcon from './../../img/current-icon.jpg';
import markerBoldIcon from '../../img/marker_bold_icon.svg';
import atmIcon from './../../img/atm_icon.png';
import BranchList from './BranchList';
import Loading from './../../Loading/Loading';

class Branchs extends Component {
    state = {
        branchs: [],
        atms: [],
        locatinAvailable: false,
        location: {
            lat: 40.105482,
            long: 47.869358,
            zoom: 7
        },
        pathname: this.props.location.pathname,
        isLoading: false
    }
    componentDidMount() {
        this.showBranchs();
        this.currentLocation()
    }

    currentLocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({ location: { lat: position.coords.latitude, long: position.coords.longitude, zoom: 10 }, locatinAvailable: true })
            });
        }
    }

    filterLangMap(val) {
        switch (val) {
            case "az":
                return "az_AZ";
            case "ru":
                return "ru_RU";
            case "en":
                return "en_US";
            default:
                return "az_AZ";
        }
    }

    async showBranchs() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBranchMapListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

        await fetch(request("util/getBranchMapList/" + localStorage.getItem('i18nextLng').toUpperCase(), getBranchMapListRequestBody))
            .then(res => res.text())
            .then((getBranchMapListrResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBranchMapListrResponse = JSON.parse(getBranchMapListrResponseJSON);
                if (getBranchMapListrResponse.status.statusCode !== 1) {
                    if (getBranchMapListrResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBranchMapListrResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ branchs: getBranchMapListrResponse.respBranchMapList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });

        await fetch(request("util/getAtmMapList/" + localStorage.getItem('i18nextLng').toUpperCase(), getBranchMapListRequestBody))
            .then(res => res.text())
            .then((getBranchMapListrResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBranchMapListrResponse = JSON.parse(getBranchMapListrResponseJSON);
                if (getBranchMapListrResponse.status.statusCode !== 1) {
                    if (getBranchMapListrResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBranchMapListrResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ atms: getBranchMapListrResponse.respBranchMapList });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
                this.props.updateAppState('isLoadingTransparentBackground', false);
            }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ pathname: this.props.location.pathname })
        }
    }

    render() {
        let { t, history, i18n, updateAppState, alertState, logout } = this.props;
        let { branchs, atms, locatinAvailable, pathname, location, isLoading } = this.state;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('Branch network')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                    <div className="dashboard-container-main-section">
                        <Loading/>
                    </div>
                    :
                    <div className="dashboard-container-main-section">
                        <div className="dashboard-new-transfer-template-section">
                            <div className="dashboard-transfer-history-header">
                                <NavLink style={{fontSize: '14px'}} to="/dashboard/service-network/branchs/build" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('branch')}</NavLink>
                                <NavLink style={{fontSize: '14px'}} to="/dashboard/service-network/branchs/atm" className="dashboard-transfer-history-header-title" activeClassName="dashboard-transfer-history-header-title__active">{t('atm')}</NavLink>
                            </div>
                            <Switch>
                                <Redirect exact from="/dashboard/service-network/branchs/" to="/dashboard/service-network/branchs/build" />
                                <Route path="/dashboard/service-network/branchs/build" render={(routeProps) => <BranchList logout={() => logout()} branchs={branchs} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                                <Route path="/dashboard/service-network/branchs/atm" render={(routeProps) => <BranchList logout={() => logout()} atms={atms} onSelectAtm={e => { this.mapRef.setCenter(e); this.mapRef.setZoom(10) }} {...routeProps} updateAppState={updateAppState} alertState={alertState} />} />
                            </Switch>
                        </div>
                        <div className="dashboard-transfer-history">

                            <YMaps preload={true} key={this.filterLangMap(i18n.language)} query={{ lang: this.filterLangMap(i18n.language) }}>
                                <Map instanceRef={ref => this.mapRef = ref} width={window.screen.width > 768 ? '100%' : window.screen.width -104} height="100%" style={{ height: window.screen.width > 768 ? '100%' : window.screen.width }} modules={["geolocation", "geocode"]} className={window.screen.width > 768 ? 'map-container':''} onLoad={e => {}} defaultState={{ center: [location.lat, location.long], zoom: location.zoom }} >
                                    <Clusterer
                                        options={{
                                            preset: 'islands#invertedVioletClusterIcons',
                                            groupByCoordinates: false,
                                        }}

                                    >
                                        {
                                            (pathname === '/dashboard/service-network/branchs/atm' ? atms : branchs).map((item, index) => {
                                                return (
                                                    <Placemark
                                                        key={index}
                                                        properties={{
                                                            hintContent: item.branchName,
                                                            balloonContent: item.branchName,
                                                            balloonPanelMaxMapArea: 1,
                                                            // openEmptyBalloon: true
                                                        }}
                                                        geometry={[item.latitude, item.longitude]}
                                                        options={{
                                                            iconLayout: 'default#image',

                                                            iconImageHref: markerBoldIcon,
                                                            iconImageSize: [30, 30],

                                                        }}

                                                        modules={["geoObject.addon.balloon"]}
                                                    />
                                                )
                                            })

                                        }
                                    </Clusterer>
                                    {
                                        locatinAvailable === true ?
                                            <Placemark
                                                properties={{


                                                    // openEmptyBalloon: true
                                                }}
                                                geometry={[this.state.location.lat, this.state.location.long]}
                                                options={{
                                                    iconLayout: 'default#image',

                                                    iconImageHref: currentIcon,
                                                    iconImageSize: [35, 35],
                                                    zIndex: 1000
                                                }}

                                                modules={["geoObject.addon.balloon"]}
                                            />
                                            : null
                                    }
                                    <GeolocationControl options={{ float: 'left' }} onClick={e => this.currentLocation()} />
                                    <FullscreenControl />
                                    <RulerControl options={{ float: 'right' }} />
                                    <ZoomControl options={{ float: 'right' }} />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}
export default translate("translation")(Branchs);
