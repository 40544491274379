import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';

// Import the info.svg image
import infoSvg from '../img/info.svg';
import info from '../../components/Dashboard/img/info-icon.svg';

// Custom styles for the Tooltip
const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#0179D2!important', // Customize the background color
        color: 'white!important', // Text color
        fontSize: '12px!important', // Font size
        borderRadius: '8px!important', // Border radius
        padding: '15px 8px!important', // Padding
        minWidth: '400px'
    },
    arrow: {
        color: '#0179D2!important', // Customize the arrow color
    },
}))((props) => <Tooltip {...props} />);

export default function RadioButton(props) {
    return (
        <div className="tooltip-icon">

            <CustomTooltip title={<div className='d-flex justify-content-center text-center'>
                <div className="tooltip-image">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 19C14.747 19 19 14.7467 19 9.5C19 4.25326 14.747 0 9.5 0C4.25304 0 0 4.25326 0 9.5C0 14.7467 4.25304 19 9.5 19ZM10.0278 3.325C10.9024 3.325 11.6111 4.03391 11.6111 4.90833C11.6111 5.78276 10.9024 6.49167 10.0278 6.49167C9.15314 6.49167 8.44444 5.78276 8.44444 4.90833C8.44444 4.03391 9.15314 3.325 10.0278 3.325ZM9.5 7.23056C11.0833 7.23056 11.4673 8.16472 11.29 9.31718L10.5556 14.0917C10.5556 14.0917 11.0833 14.0917 12.1389 13.5639C12.1389 13.5639 11.6111 15.675 9.5 15.675C7.91667 15.675 7.53266 14.7408 7.70999 13.5884L8.44444 8.81389C8.44444 8.81389 7.91667 8.81389 6.86111 9.34167C6.86111 9.34167 7.38889 7.23056 9.5 7.23056Z" fill="white" />
                    </svg>
                </div>
                {props.name}</div>} arrow placement="top">
                <img src={info} alt="" />
            </CustomTooltip>
        </div>

    );
}
