import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { request, ipsRequest } from './../../../../config';
import Dropdown from '../../../../copms/Dropdown/Dropdown';
import { translate } from 'react-i18next';
import Loading from '../../Loading/Loading';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import GeneralModal from './../../../../copms/Modal/GeneralModal';
import ReverseModal from './../../../../copms/Modal/ReverseModal';
import lastOperationsIcon from './../../img/last-operations-icon.png';

function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
class Payments extends Component {

    state = {
        payments: [],
        openAlert: false,
        feedbackErrorModal: null,
        feedbackSuccessModal: null,
        createFeedbackMessage: false,

        currentPage: 1,
        lastPage: 0
    }
    showPayments = () => {
        this.setState({ isLoading: true });

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let operationAdvancedSearchRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operNameId: 5,
            pageNumber: this.state.currentPage
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operNameId: 5,
            pageNumber: this.state.currentPage
        }

        fetch(ipsRequest("operationAdvancedSearch", operationAdvancedSearchRequestBody))
            .then(res => res.text())
            .then((operationAdvancedSearchResponseJSON) => {
                const JSON = require('true-json-bigint');
                let operationAdvancedSearchResponse = JSON.parse(operationAdvancedSearchResponseJSON);
                if (operationAdvancedSearchResponse.status.statusCode !== 1 && operationAdvancedSearchResponse.status.statusCode !== 240) {
                    if (operationAdvancedSearchResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(operationAdvancedSearchResponse.status.statusCode));
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    if (operationAdvancedSearchResponse.status.statusCode === 240) {
                        operationAdvancedSearchResponse.operationList = [];
                    }
                    this.setState({ payments: operationAdvancedSearchResponse.operationList });
                    this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    showPdf = (item) => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getPaymentDocByOperIdRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: item.operId
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: item.operId
        }
        fetch(request("operation/getPaymentDocByOperId", getPaymentDocByOperIdRequestBody))
            .then(res => res.text())
            .then((getPaymentDocByOperIdResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getPaymentDocByOperIdResponse = JSON.parse(getPaymentDocByOperIdResponseJSON);
                if (getPaymentDocByOperIdResponse.status.statusCode !== 1) {
                    if (getPaymentDocByOperIdResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getPaymentDocByOperIdResponse.status.statusCode));
                    }
                } else {
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    let arrrayBuffer = base64ToArrayBuffer(getPaymentDocByOperIdResponse.bytes); //data is the base64 encoded string
                    let blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    let link = window.URL.createObjectURL(blob);
                    window.open(link, '');
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }
    selectPage(e, page) {
        e.preventDefault();
        this.setState({ currentPage: page }, () => {
            this.searchTransfers(false);
        });
    }
    componentDidMount() {
        this.showPayments();
    }
    render() {
        let { isLoading, open, index, incomeIndex, income, payments, reverseAmount, reverseReason,
            openAlert, createFeedbackMessage, feedbackErrorModal, feedbackSuccessModal } = this.state;
        let { updateAppState, logout, history, t } = this.props;
        return (
            <Fragment>
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('payments')}</h2>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <div className="dashboard-container-main-section-vertical">
                            <div className="dashboard-transfer-search-list-section" style={{ marginTop: '0' }}>
                                <div className="dashboard-transfer-list-content" style={{ padding: 0, height: '100%', minHeight: '100vh' }}>
                                    {
                                        payments && payments.length !== 0 ?
                                            <>
                                                <table className="dashboard-transfer-list-table">
                                                    <thead className="dashboard-transfer-list-table-head">
                                                        <tr className="dashboard-transfer-list-table-row dashboard-transfer-list-table-row__head">
                                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("HİSTORY")}</th>
                                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("SENDER PERSON")}</th>
                                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("RECİPİENTS PERSON")}</th>
                                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("AMOUNT")}</th>
                                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("CURRENCY")}</th>
                                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head">{t("STATUS")}</th>
                                                            <th className="dashboard-transfer-list-table-cell dashboard-transfer-list-table-cell__head"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="dashboard-transfer-list-table-body">
                                                        {
                                                            payments && payments.map((receivePayment, index) => {
                                                                return (
                                                                    <tr className="dashboard-transfer-list-table-row" key={index}>
                                                                        <td className="dashboard-transfer-list-table-cell">
                                                                            <div className="dashboard-last-operations-section-item-amount" style={{ textAlign: 'left' }}>{receivePayment.createdDate && receivePayment.createdDate.split(' ')[0]}</div>
                                                                            <div class="dashboard-last-operations-section-item-category" style={{ textAlign: 'left' }}>{receivePayment.createdDate && receivePayment.createdDate.split(' ')[1]}</div>
                                                                        </td>
                                                                        <td className="dashboard-transfer-list-table-cell">{receivePayment.dtName}</td>
                                                                        <td className="dashboard-transfer-list-table-cell">{receivePayment.crName}</td>
                                                                        <td className="dashboard-transfer-list-table-cell">{receivePayment.amount ? receivePayment.amount.toFixed(2) : '0.00'}</td>
                                                                        <td className="dashboard-transfer-list-table-cell">{receivePayment.currency}</td>
                                                                        <td className="dashboard-transfer-list-table-cell">
                                                                            {receivePayment.operState}
                                                                            {/* {
                                                                            receivePayment.operStateId === 1 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__confirmed"></div>
                                                                        }
                                                                        {
                                                                            receivePayment.operStateId === 2 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__waiting"></div>
                                                                        }
                                                                        {
                                                                            receivePayment.operStateId === 3 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__first-authorization"></div>
                                                                        }
                                                                        {
                                                                            receivePayment.operStateId === 4 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__second-authorization"></div>
                                                                        }
                                                                        {
                                                                            receivePayment.operStateId === 5 && <div className="dashboard-transfer-history-content-item-container-type-circle dashboard-transfer-history-content-item-container-type-circle__refused"></div>
                                                                        }                                                                     */}
                                                                        </td>
                                                                        <td className="dashboard-transfer-list-table-cell">
                                                                            <button onClick={this.showPdf.bind(this, receivePayment)}>
                                                                                <svg className="dashboard-transfer-list-table-icon" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M6.75 7.40625C6.75 7.1875 6.5625 7.03125 6.375 7.03125H5.625C5.40625 7.03125 5.25 7.1875 5.25 7.40625V10.0312H3.71875C3.375 10.0312 3.21875 10.4375 3.4375 10.6562L5.6875 12.9062C5.84375 13.0625 6.125 13.0625 6.28125 12.9062L8.53125 10.6562C8.75 10.4375 8.59375 10.0312 8.25 10.0312H6.75V7.40625ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" fill="#586268" />
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                                <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                                    <ul className="dashboard-pagination">
                                                        {this.state.currentPage != 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage - 1)}>{t('previous-page')}</a></li>}
                                                        {
                                                            Array.from({ length: this.state.lastPage }).map((item, index) => {
                                                                if (this.state.lastPage > 3) {
                                                                    if (this.state.currentPage - 1 == index + 1) {
                                                                        return (
                                                                            <>
                                                                                {this.state.currentPage > 2 && <li><a href="#" onClick={(e) => this.selectPage(e, 1)}>1</a></li>}

                                                                                {this.state.currentPage > 3 && <li><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}
                                                                                <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    if (this.state.currentPage == index + 1) {
                                                                        return (
                                                                            <>
                                                                                <li className="active" ><a href="#" style={{ cursor: 'default' }} onClick={e => e.preventDefault()}>{index + 1}</a></li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    if (this.state.currentPage + 1 == index + 1) {
                                                                        return (
                                                                            <>
                                                                                <li><a href="#" onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                                {this.state.currentPage < this.state.lastPage - 2 && <li ><a href="#" style={{ cursor: 'default' }} onClick={(e) => { e.preventDefault(); }}>...</a></li>}

                                                                                {this.state.currentPage + 1 < this.state.lastPage && <li ><a href="#" onClick={(e) => this.selectPage(e, this.state.lastPage)}>{this.state.lastPage}</a></li>}
                                                                            </>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <li className={index + 1 == this.state.currentPage ? "active" : ""}><a href="#" style={this.state.lastPage == 1 ? { borderRadius: '5px' } : {}} onClick={(e) => this.selectPage(e, index + 1)}>{index + 1}</a></li>
                                                                    )
                                                                }
                                                            })

                                                        }
                                                        {this.state.currentPage != this.state.lastPage && this.state.lastPage > 1 && <li><a href="#" onClick={(e) => this.selectPage(e, this.state.currentPage + 1)}>{t('next-page')}</a></li>}
                                                    </ul>
                                                </div>
                                            </>
                                            :
                                            <div className="dashboard-last-operations-section-content">
                                                <img src={lastOperationsIcon} className="dashboard-last-operations-section-content-image" alt="lastOperationsIcon" />
                                                <p className="dashboard-last-operations-section-content-status">{t('You do not currently have any payments.')}</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </Fragment >
        )
    }
}


export default translate("translation")(Payments);