import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { request } from '../../../config';
class Innovations extends Component {
    state = {

    }

    render() {
        let { t, history } = this.props;
        let { } = this.state;
        return (
            <div>Innovations</div>
        )
    }
}
export default translate("translation")(Innovations);
