import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Link, Route, Switch, } from 'react-router-dom';
import userIcon from './../../img/user-icon-form.svg';
import pen from './../../img/pen.svg';

import phoneIcon from './../../img/phone-icon-form.svg';
import spinner from './../../img/spinner.svg';
import { request } from './../../../../config';
import { translate } from 'react-i18next';
import SendOtpModal from '../../../../copms/Modal/SendOtpModalForSettingsEmail';


class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankCustomerType: '',
            clientType: '',
            isLoading: false,
            messageStatus: 'info',
            email: null,
            openOtp: false,
            otpCode: null,
            verifyToken: null
        }
    }

    handleButtonClick = () => {
        this.props.history.push('/dashboard/settings/general')
    };

    clearFeedback = () => {
        this.setState({ messageStatus: 'info' });
    }
    changeGeneralData = (otpCode, event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let updateCustomerInfoRequestBody = customerType === 'Juridical' ? {
            custInfoType: "EMAIL",
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            // mobile: getPhoneNumber(this.mobile.value).trim() === "" ? null : getPhoneNumber(this.mobile.value),
            email: this.email.value.trim() === "" ? null : this.email.value,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            confirmCode: otpCode,
            verifyToken: this.state.verifyToken

        } : {
            // custId: localStorage.getItem('custId'),
            // --------------------------
            custInfoType: "EMAIL",
            token: localStorage.getItem('token'),
            // mobile: getPhoneNumber(this.mobile.value).trim() === "" ? null : getPhoneNumber(this.mobile.value),
            email: this.email.value.trim() === "" ? null : this.email.value,
            // secretWord: "11111",
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            confirmCode: otpCode,
            verifyToken: this.state.verifyToken


            // --------------------------
        }

        fetch(request("setting/updateCustomerInfo" + customerType, updateCustomerInfoRequestBody))
            .then(res => res.text())
            .then((getCardListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                if (getCardListForCustomerResponse.status.statusCode !== 1) {
                    if (getCardListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    }
                }
                else if (getCardListForCustomerResponse.otpVisible) {
                    this.props.updateAppState('isLoadingTransparentBackground', false);

                    // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                    this.setState({
                        openOtp: true,
                        verifyToken: getCardListForCustomerResponse.verifyToken,
                    });

                }
                else {
                    this.setState({
                        openOtp: false
                    });
                    this.setState({email: this.email.value });
                    let changedObj = JSON.parse(localStorage.getItem('custObject'));
                    if (changedObj.customer === undefined) {
                        changedObj.email = this.email.value;
                        // changedObj.mobile = getPhoneNumber(this.mobile.value);
                    }
                    else {
                        changedObj.customer.email = this.email.value;
                        // changedObj.customer.mobile = getPhoneNumber(this.mobile.value);
                    }
                    localStorage.setItem('custObject', JSON.stringify(changedObj));
                    this.setState({ isLoading: false });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                    this.props.alertState(true, 'success', this.props.t('successful operation'), this.props.t('information changed successfully'));
                    this.props.history.push("/dashboard/settings/general");

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    componentWillMount() {
        if (localStorage.getItem('custObject')) {
            let custObject = JSON.parse(localStorage.getItem('custObject'));
            // this.setState({ secretWord: localStorage.getItem('secretWord') });
            // this.setState({ bankCustomerType: localStorage.getItem('bankCustomerType') });
            // this.setState({ name: localStorage.getItem('compId') === null ? localStorage.getItem('fullname').split(' ')[0] : JSON.parse(localStorage.getItem('custObject')).company.name });
            // this.setState({ surname: localStorage.getItem('compId') === null && localStorage.getItem('fullname').split(' ')[1] });
            // this.setState({ surname: localStorage.getItem('compId') === null && localStorage.getItem('fullname').split(' ')[1] });
            let email = (custObject.customer !== undefined) ? custObject.customer.email : custObject.email;

            this.setState({ email });
        }

    }


    componentDidMount() {
        this.props.updateSettingsState('settingsTitle', this.props.t('edit email'));
        this.props.updateSettingsState('feedback', this.props.t('edit email info'));

    }

    render() {
        let { messageStatus, isLoading, name, surname, openOtp, otpCode, email } = this.state;
        let { t } = this.props;
        let feedbackMessage = {
            info: this.props.t('enter a new one to change your phone number'),
            success: this.props.t('your phone number has been successfully changed'),
            error: this.props.t('an error occurred')
        };
        return (
            <>
                <SendOtpModal buttonTitle={t('Sign')} open={openOtp} signSubmit={(otpCode, event) => this.changeGeneralData(otpCode, event)} onClose={() => this.setState({ openOtp: false })} />
                <div className="dashboard-settings-section-general">
                    <form className="dashboard-settings-section-general-form">
                        <div className="dashboard-settings-section-general-form-group">
                            <label className="dashboard-settings-section-general-form-group-label">{t('e-mail')}</label>
                            <div className="dashboard-settings-section-general-form-group-box">
                                <input type="email" className="dashboard-settings-section-general-form-group-box-input default" ref={(input) => { this.email = input }} placeholder="E-mail" defaultValue={email} onChange={this.clearFeedback} />
                                <div className="dashboard-settings-section-general-form-group-box-icon">
                                    <img src={pen} className="dashboard-settings-section-general-form-group-box-icon-img dashboard-settings-section-general-form-group-box-icon-img" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="dashboard-settings-section-general-form-button-section">
                            <button className="dashboard-settings-section-general-form-button dashboard-settings-section-general-form-button__save" onClick={(event) => this.changeGeneralData(otpCode, event)}>{t('save')}</button>
                            <button className="dashboard-settings-section-general-form-button dashboard-settings-section-general-form-button__cancel" onClick={this.handleButtonClick}>{t('refusal')}</button>
                        </div>
                    </form>
                </div>
            </>

        )
    }
}



export default translate("translation")(GeneralSettings);