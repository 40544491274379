import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { request, ipsRequest } from './../../../../config';
import transferConfirmation from './../../img/transfer-confirmation.svg';
import currencyFilter from '../../../../helpers/currencyFilter';
import PayRegisterModal from '../../../../copms/Modal/PayRegisterModal';
import CreateTransferTemplate from '../../../../copms/Modal/CreateTransferTemplate';
import RadioButton from '../../../../copms/RadioButton/RadioButton';
import OutsideClickHandler from 'react-outside-click-handler';

function roundNumber(number) {
    return Math.round(number * 100) / 100;
}


class PayIPS extends Component {
    state = {
        confirmedOperation: false,
        dropdownVisibilityBank: false,
        dropdownVisibilityBuyingAccount: false,
        dropdownVisibilitySellingAccount: false,
        banks: [],
        types: [],
        banksAll: [],
        typesAll: [],
        selectedBankIndex: -1,
        selectedTypeIndex: -1,
        previousSelectedTypeIndex: -1,
        bankFilter: "",
        bankCode: "",
        typeFilter: "",
        bankVisible: false,
        typeVisible: false,
        accounts: [],
        accountsVisible: false,
        accountsSelectedIndex: -1,
        amount: "",
        amountValidation: false,
        purpose: "",
        purposeValidation: false,
        exchangeCashlessList: [],
        exchangeRate: '',
        step: 1,
        sellingIndex: -1,
        showUrgent: true,
        showType: true,
        buyingIndex: -1,
        open: false,
        createFeedbackMessage: false,
        feedbackErrorModal: null,
        currencyShow: false,
        currencyIndex: -1,
        message: "",
        ibanReceiver: "",
        reciverType: 0,
        aliases: [],
        aliasVisible: false,
        aliasSelectedIndex: -1,
        aliasTypes: [],
        aliasTypeSelectIndex: 0,
        aliasTypeVisible: false,
        aliasValue: "",
        infoReciverAlias: null,
        reciverName: "",
        infoSenderAlias: null,

        budgetCodeList: [],
        budgetCodeListAll: [],
        selectedBudgetCodeListIndex: -1,
        budgetCodeListFilter: "",
        budgetCodeListVisible: false,

        budgetLevelList: [],
        budgetLevelListAll: [],
        selectBudgetLevelListIndex: -1,
        budgetLevelListFilter: "",
        budgetLevelListVisible: false,
        infoReciverAccount: null,
        nameValue: "",
        taxNumberValue: "",
        accountsStatus: 0,
        amountStatus: 0,
        purposeStatus: 0,
        ibanReceiverStatus: 0,
        bankStatus: 0,
        typeStatus: 0,
        aliasesStatus: 0,
        aliasValueStatus: 0,
        nameStatus: 0,
        voenStatus: 0,
        aliasReciverStatus: 0,
        budgetCodeStatus: 0,
        budgetLevelStatus: 0,
        feeAmount: 0,
    }
    updateBankFilter = (event) => {
        this.setState({ selectedBank: "", bankFilter: event.target.value })
    }
    updateDtAccountBuyingId = (dtAccountId, dtAccountIban, index, event) => {
        this.setState({ dtAccountId: dtAccountId, dtAccountIban: dtAccountIban, buyingIndex: index, selectedBuyingAccount: event.target.textContent, dtAccontIdValidation: true, dropdownVisibilityBuyingAccount: false, currencyBuying: this.state.buyingAccounts[index].currName });
    }
    updateDtAccountSellingId = (dtAccountId, dtAccountIban, index, event) => {
        this.setState({ dtAccountId: dtAccountId, dtAccountIban: dtAccountIban, sellingIndex: index, selectedSellingAccount: event.target.textContent, dtAccontIdValidation: true, dropdownVisibilitySellingAccount: false, currencySelling: this.state.sellingAccounts[index].currName, exchangeRate: this.props.match.params.type == 2 ? this.state.exchangeCashlessList.find(el => el.currency == this.state.sellingAccounts[index].currName).cashlessBuy : (this.state.exchangeCashlessList.find(el => el.currency == this.state.sellingAccounts[index].currName).cashlessSell) }, () => this.onChangeBuyingAmount());
    }
    changeDropdownVisibilityBank = () => {
        this.setState({ dropdownVisibilityBank: !this.state.dropdownVisibilityBank });
    }
    changeDropdownVisibilityBuyingAccount = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilityBuyingAccount: !this.state.dropdownVisibilityBuyingAccount, dropdownVisibilitySellingAccount: false, });
    }
    changeDropdownVisibilitySellingAccount = (event) => {
        event.preventDefault();
        this.setState({ dropdownVisibilitySellingAccount: !this.state.dropdownVisibilitySellingAccount, dropdownVisibilityBuyingAccount: false, });

    }
    checkCrCustomerName = (event) => {
        this.setState({ crCustomerNameValidation: false });
        let mask = /[^a-zA-Z ]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length > 0) {
            this.setState({ crCustomerNameValidation: true });
        }
        this.setState({ crCustomerName: event.target.value });
    }

    onChangeBuyingAmount() {
        var val = this.state.valueBuying;
        if (/^\d*\.?\d*$/.test(val)) {
            if (this.props.match.params.type == 1) {
                this.setState({ valueSelling: (val.length > 0 && val != 0) ? roundNumber(parseFloat(val) / parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val != 0 && this.state.converNumber.length > 0 && this.state.converNumber != 0 : val.length > 0 && val != 0) : false, })
            } else {
                this.setState({ valueSelling: (val.length > 0 && val != 0) ? roundNumber(parseFloat(val) / parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val != 0 && this.state.converNumber.length > 0 && this.state.converNumber != 0 : val.length > 0 && val != 0) : false, })
            }
        }
    }
    onChangeSellingAmount() {
        var val = this.state.valueSelling;
        if (/^\d*\.?\d*$/.test(val)) {
            if (this.props.match.params.type == 1) {
                this.setState({ valueBuying: (val.length > 0 && val != 0) ? roundNumber(parseFloat(val) * parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val != 0 && this.state.converNumber.length > 0 && this.state.converNumber != 0 : val.length > 0 && val != 0) : false, })
            } else {
                this.setState({ valueBuying: (val.length > 0 && val != 0) ? roundNumber(parseFloat(val) * parseFloat(this.state.exchangeRate)).toString() : 0, confirmedOperation: (this.state.selectedSellingAccount && this.state.selectedBuyingAccount) ? (localStorage.getItem("isOperNo") === '1' ? val.length > 0 && val != 0 && this.state.converNumber.length > 0 && this.state.converNumber != 0 : val.length > 0 && val != 0) : false, })
            }
        }
    }

    checkCrIban = (event) => {
        this.setState({ crIbanValidation: false });
        let mask = /[^a-zA-Z0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length > 28) {
            event.target.value = event.target.value.substring(0, 28);
        }
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length === 28) {
            this.setState({ crIbanValidation: true });
        }
        this.setState({ crIban: event.target.value });
    }
    checkCrTaxNo = (event) => {
        let mask = /[^0-9]/g;
        if (event.target.value !== event.target.value.replace(mask, '')) {
            event.target.value = event.target.value.replace(mask, '');
        }
        if (event.target.value.length < 10) {
            this.setState({ crTaxNoValidation: false });
        }
        else {
            if (event.target.value.length > 10) {
                event.target.value = event.target.value.substring(0, 10);
            }
            this.setState({ crTaxNoValidation: true });
        }
        this.setState({ crTaxNo: event.target.value });
    }

    showAccounts = () => {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(ipsRequest("getAccounts", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accounts = [];
                    }

                    this.setState({ accounts: getNoCardAccountListForCustomerResponse.accounts && getNoCardAccountListForCustomerResponse.accounts.filter(el => el.currency == "AZN") });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getBankList() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAllBanksRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(ipsRequest("getAllBanks", getAllBanksRequestBody))
            .then(res => res.text())
            .then((getAllBanksResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAllBanksResponse = JSON.parse(getAllBanksResponseJSON);
                if (getAllBanksResponse.status.statusCode !== 1) {
                    if (getAllBanksResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAllBanksResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getAllBanksResponse.bankInfos && getAllBanksResponse.bankInfos.length > 0) {
                        this.setState({ banks: getAllBanksResponse.bankInfos, banksAll: getAllBanksResponse.bankInfos })
                    } else {
                        this.setState({ banks: [] })
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getTypeList() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let customerType = localStorage.getItem('compId') == null ? 'Individual' : 'Juridical';

        customerType == 'Individual' ? this.setState({ showType: false, selectedTypeIndex: null }) : this.setState({ showType: true });

        let getAllTypesRequestBody = customerType == 'Juridical' ? {
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }


        fetch(ipsRequest("getIpsOperationtypes", getAllTypesRequestBody))
            .then(res => res.text())
            .then((getAllTypesResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAllTypesResponse = JSON.parse(getAllTypesResponseJSON);
                if (getAllTypesResponse.status.statusCode !== 1) {
                    if (getAllTypesResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAllTypesResponse.status.statusCode));
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    if (getAllTypesResponse.ipsOperationTypes && getAllTypesResponse.ipsOperationTypes.length > 0) {
                        this.setState({ types: getAllTypesResponse.ipsOperationTypes, typesAll: getAllTypesResponse.ipsOperationTypes })
                    } else {
                        this.setState({ types: [] })
                    }
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    testRequest() {
        fetch(request("exchange/cashless", {}, "GET"))
            .then(res => res.text())
            .then((cashlessResponseJSON) => {
                const JSON = require('true-json-bigint');
                let cashlessResponse = JSON.parse(cashlessResponseJSON);
                cashlessResponse.exchangeCashlessList.push({
                    cashlessBuy: 1,
                    cashlessSell: 1,
                    currency: "AZN"

                })
                this.setState({ exchangeCashlessList: cashlessResponse.exchangeCashlessList })
            })
    }

    componentDidMount() {
        this.showAccounts();
        this.getBankList();
        this.getTypeList();
        this.testRequest();
        this.getAliases();
        this.getAliasTypes();
        this.getBudgetCodeList();
        this.getBudgetLvlList();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.type != this.props.match.params.type || this.state.reciverType != prevState.reciverType) {
            this.checkFields();
        }
    }

    amountSplit(amount) {
        var splitedAmount = amount.split('.');
        if (splitedAmount.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }
    onChangeExchangeRate() {
        if (this.state.exchangeRate.length > 0) {
            if (this.props.match.params.type == 1) {
                this.onChangeSellingAmount();
            } else {
                this.onChangeSellingAmount();
            }
        }
    }

    onCancel() {
        this.setState({ step: 1 });
    }
    onSubmitForm() {
        this.setState({ feedbackErrorModal: null });
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let createIpsOperationRequestBody = {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operNameId: 5,
            ipsOperationTypeId: this.state.selectedTypeIndex,

            ipsPayment: {
                additionalInfoAboutPayment: this.state.message,
                additionalInfoFromCustomer: "",
                remittanceInfo: this.state.purpose,
                paymentAmount: this.state.amount,
                // receiverCustomerIban: "AZ76TURA41010005104300800944",
                // receiverCustomerName: "ÇİNGİZ PAŞAYEV",
                // senderCustomerIban: "AZ17TURA41010006880300200944",
                // senderCustomerName: "FAMİL NƏBİYEV"
            }

        };

        if (customerType == 'Juridical') {
            createIpsOperationRequestBody.compId = localStorage.getItem('compId');
            createIpsOperationRequestBody.ipsPayment.senderCustomerTaxNumber = JSON.parse(localStorage.getItem('custObject')).company.taxNo;
            createIpsOperationRequestBody.ipsPaymentTypeId = 13;
        }
        else {
            createIpsOperationRequestBody.ipsPaymentTypeId = 1;
            createIpsOperationRequestBody.ipsOperationTypeId = null;
        }

        if (this.props.match.params.type == 1) {
            if (this.state.reciverType == 0) {
                createIpsOperationRequestBody.operTypeId = 7;
            } else {
                createIpsOperationRequestBody.operTypeId = 8;
            }
            createIpsOperationRequestBody.senderAccountBranchId = this.state.accounts[this.state.accountsSelectedIndex].branchId;
            createIpsOperationRequestBody.senderAccountBranchName = this.state.accounts[this.state.accountsSelectedIndex].branchName;
            createIpsOperationRequestBody.ipsPayment.senderCustomerIban = this.state.accounts[this.state.accountsSelectedIndex].iban;
            createIpsOperationRequestBody.ipsPayment.senderCustomerName = this.state.accounts[this.state.accountsSelectedIndex].accountName;
        } else {
            if (this.state.reciverType == 0) {
                createIpsOperationRequestBody.operTypeId = 10;
            } else {
                createIpsOperationRequestBody.operTypeId = 9;
            }
            // createIpsOperationRequestBody.ipsPayment.senderCustomerIban = this.state.infoSenderAlias.id.iban;
            createIpsOperationRequestBody.ipsPayment.senderCustomerName = this.state.infoSenderAlias.nickName;
            createIpsOperationRequestBody.senderAliasId = this.state.aliases[this.state.aliasSelectedIndex].id;
        }

        if (this.state.reciverType == 0) {
            createIpsOperationRequestBody.receiverBankId = this.state.banksAll[this.state.selectedBankIndex].id;
            createIpsOperationRequestBody.branchId = this.state.banksAll[this.state.selectedBankIndex].branchId;
            createIpsOperationRequestBody.branchName = this.state.banksAll[this.state.selectedBankIndex].branchName;
            createIpsOperationRequestBody.ipsPayment.budgetCode = this.state.banksAll[this.state.selectedBankIndex].showBudgetLevel == 1 ? this.state.budgetCodeList[this.state.selectedBudgetCodeListIndex].budgetId : "";
            createIpsOperationRequestBody.ipsPayment.budgetLvl = this.state.banksAll[this.state.selectedBankIndex].showBudgetLevel == 1 ? this.state.budgetLevelList[this.state.selectBudgetLevelListIndex].budgetId : "";
            createIpsOperationRequestBody.ipsPayment.receiverCustomerIban = this.state.ibanReceiver;
            createIpsOperationRequestBody.ipsPayment.receiverCustomerName = this.state.ibanReceiver;
            if (this.state.infoReciverAccount && this.state.infoReciverAccount.taxNumber) {
                createIpsOperationRequestBody.ipsPayment.receiverCustomerTaxNumber = this.state.infoReciverAccount.taxNumber;
            } else if (this.state.taxNumberValue.length >= 10) {
                createIpsOperationRequestBody.ipsPayment.receiverCustomerTaxNumber = this.state.taxNumberValue;
            }
            if (this.state.infoReciverAccount && this.state.infoReciverAccount.nickName) {
                createIpsOperationRequestBody.ipsPayment.receiverCustomerName = this.state.infoReciverAccount.nickName;
            } else {
                createIpsOperationRequestBody.ipsPayment.receiverCustomerName = this.state.nameValue;
            }
        } else {
            createIpsOperationRequestBody.receiverAliasType = this.state.aliasTypes[this.state.aliasTypeSelectIndex].name;
            createIpsOperationRequestBody.receiverAlias = this.state.aliasValue;
            createIpsOperationRequestBody.receiverBankId = this.state.banksAll.find(el => el.swiftCode == this.state.infoReciverAlias.servicer.bic).id;
            createIpsOperationRequestBody.branchId = 0;
            createIpsOperationRequestBody.branchName = "";
            createIpsOperationRequestBody.ipsPayment.budgetCode = this.state.banksAll.find(el => el.swiftCode == this.state.infoReciverAlias.servicer.bic).showBudgetLevel == 1 ? this.state.budgetCodeList[this.state.selectedBudgetCodeListIndex].budgetId : "";
            createIpsOperationRequestBody.ipsPayment.budgetLvl = this.state.banksAll.find(el => el.swiftCode == this.state.infoReciverAlias.servicer.bic).showBudgetLevel == 1 ? this.state.budgetLevelList[this.state.selectBudgetLevelListIndex].budgetId : "";
            // createIpsOperationRequestBody.ipsPayment.receiverCustomerIban = this.state.infoReciverAlias.id.iban;
            // createIpsOperationRequestBody.ipsPayment.receiverCustomerName = this.state.infoReciverAlias.nickName;
            // if (this.state.infoReciverAlias && this.state.infoReciverAlias.taxNumber) {
            createIpsOperationRequestBody.ipsPayment.receiverCustomerTaxNumber = null;
            // } else if (localStorage.getItem('compId')) {
            //     createIpsOperationRequestBody.ipsPayment.receiverCustomerTaxNumber = this.state.taxNumberValue;
            // }
        }

        if (this.state.banksAll && this.state.banksAll.length > 0 && this.state.selectedBankIndex >= 0 && this.state.banksAll[this.state.selectedBankIndex].showBudgetLevel == 1) {
            createIpsOperationRequestBody.ipsPaymentTypeId = 2;
        }

        fetch(ipsRequest("createIpsOperation", createIpsOperationRequestBody))
            .then(res => res.text())
            .then((createIpsOperationResponseJSON) => {
                const JSON = require('true-json-bigint');
                let createIpsOperationResponse = JSON.parse(createIpsOperationResponseJSON);
                if (createIpsOperationResponse.status.statusCode !== 1) {
                    if (createIpsOperationResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(createIpsOperationResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);


                    }
                }
                else {
                    this.setState({ createFeedbackMessage: true, open: true, });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getAliasTypes() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAliasTypesRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(ipsRequest("getAliasTypes", getAliasTypesRequestBody))
            .then(res => res.text())
            .then((getAliasTypesResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAliasTypesResponse = JSON.parse(getAliasTypesResponseJSON);
                if (getAliasTypesResponse.status.statusCode !== 1) {
                    if (getAliasTypesResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAliasTypesResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ aliasTypes: getAliasTypesResponse.types })

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getAliases() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAliasesRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(ipsRequest("getAliases", getAliasesRequestBody))
            .then(res => res.text())
            .then((getAliasesResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAliasesResponse = JSON.parse(getAliasesResponseJSON);
                if (getAliasesResponse.status.statusCode !== 1) {
                    if (getAliasesResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        // this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAliasesResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ aliases: getAliasesResponse.aliases })

                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getBudgetCodeList() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBudgetCodeListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(ipsRequest("getBudgetCodeList", getBudgetCodeListRequestBody))
            .then(res => res.text())
            .then((getBudgetCodeListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBudgetCodeListResponse = JSON.parse(getBudgetCodeListResponseJSON);
                if (getBudgetCodeListResponse.status.statusCode !== 1) {
                    if (getBudgetCodeListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetCodeListResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ budgetCodeListAll: getBudgetCodeListResponse.budgetCodeList, budgetCodeList: getBudgetCodeListResponse.budgetCodeList })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getBudgetLvlList() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getBudgetLvlListRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }

        fetch(ipsRequest("getBudgetLvlList", getBudgetLvlListRequestBody))
            .then(res => res.text())
            .then((getBudgetLvlListResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getBudgetLvlListResponse = JSON.parse(getBudgetLvlListResponseJSON);
                if (getBudgetLvlListResponse.status.statusCode !== 1) {
                    if (getBudgetLvlListResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getBudgetLvlListResponse.status.statusCode));
                        // this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ budgetLevelListAll: getBudgetLvlListResponse.budgetCodeList, budgetLevelList: getBudgetLvlListResponse.budgetCodeList })
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    updateOpenModal = (value) => {
        this.setState({ open: value });
    }

    updateOpenTemp = (value) => {
        this.setState({ openTemp: value });
    }

    onChangeBanksInput(value) {
        this.setState({ banks: value.length > 0 ? this.state.banksAll.filter(el => el && el.name && el.name.toUpperCase().includes(value.toUpperCase())) : this.state.banksAll, selectedBankIndex: -1, bankFilter: value })
        if (value === "") {
            this.setState({ purposeValidation: false }, () => this.checkFields());
        }
        else {
            this.setState({ purposeValidation: true }, () => this.checkFields())
        }
    }

    onChangeTypesInput(value) {
        this.setState({ types: value.length > 0 ? this.state.typesAll.filter(el => el && el.name && el.name.toUpperCase().includes(value.toUpperCase())) : this.state.typesAll, selectedTypeIndex: -1, previousSelectedTypeIndex: -1, typeFilter: value })
        if (value === "") {
            this.setState({ purposeValidation: false }, () => this.checkFields());
        }
        else {
            this.setState({ purposeValidation: true }, () => this.checkFields())
        }
    }

    onChangebudgetCode(value) {
        this.setState({ budgetCodeList: value.length > 0 ? this.state.budgetCodeListAll.filter(el => el && el.budgetValue && el.budgetValue.toUpperCase().includes(value.toUpperCase())) : this.state.budgetCodeListAll, selectedBudgetCodeListIndex: -1, budgetCodeListFilter: value })
        if (value === "") {
            this.setState({ purposeValidation: false }, () => this.checkFields());
        }
        else {
            this.setState({ purposeValidation: true }, () => this.checkFields())
        }
    }

    onChangebudgetLevel(value) {
        this.setState({ budgetLevelList: value.length > 0 ? this.state.budgetLevelListAll.filter(el => el && el.budgetValue && el.budgetValue.toUpperCase().includes(value.toUpperCase())) : this.state.budgetLevelListAll, selectBudgetLevelListIndex: -1, budgetLevelListFilter: value })
        if (value === "") {
            this.setState({ purposeValidation: false }, () => this.checkFields());
        }
        else {
            this.setState({ purposeValidation: true }, () => this.checkFields())
        }
    }

    selectExchange(e, index) {
        e.preventDefault();
        this.setState({ currencyIndex: index, currencyShow: false }, () => this.checkFields());
    }

    onChangeAmount(val) {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(val) || val.length === 0) {
            this.setState({ amount: val }, () => this.checkFields())
        }
    }

    checkFields() {
        let { reciverType, amount, selectedBankIndex, banks, banksAll, selectedTypeIndex, types, typesAll, infoReciverAlias, selectBudgetLevelListIndex, selectedBudgetCodeListIndex, currencyIndex, purpose, ibanReceiver, accountsSelectedIndex, aliasSelectedIndex, infoReciverAccount, nameValue, taxNumberValue, infoSenderAlias } = this.state;

        if (this.props.match.params.type == '1') {
            if (reciverType == 0) {
                if (selectedBankIndex >= 0 && (selectedBankIndex >= 0 && banksAll[selectedBankIndex].showBudgetLevel == 1 ? (selectBudgetLevelListIndex >= 0 && selectedBudgetCodeListIndex >= 0) ? true : false : true) && ibanReceiver.length >= 28 && accountsSelectedIndex >= 0 && (localStorage.getItem('compId') ? selectedTypeIndex >= 0 : true) && purpose.length > 0 && parseFloat(amount) > 0 && (localStorage.getItem('compId') ? (infoReciverAccount && infoReciverAccount.taxNumber) ? infoReciverAccount.taxNumber : localStorage.getItem('compId') ? taxNumberValue.length == 10 : false : true) && (!infoReciverAccount ? (nameValue.length > 0) : true)) {
                    this.setState({ confirmedOperation: true });
                } else {
                    this.setState({ confirmedOperation: false });
                }
            } else {
                if ((infoReciverAlias && banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic)) && (banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic).showBudgetLevel == 1 ? selectBudgetLevelListIndex && selectedBudgetCodeListIndex : true) && accountsSelectedIndex >= 0 && (localStorage.getItem('compId') ? selectedTypeIndex >= 0 : true) && accountsSelectedIndex >= 0 && purpose.length > 0 && parseFloat(amount) > 0) {
                    this.setState({ confirmedOperation: true });
                } else {
                    this.setState({ confirmedOperation: false });
                }
            }
        } else {
            if (reciverType == 0) {
                if (infoSenderAlias && selectedBankIndex >= 0 && (selectedBankIndex >= 0 && banksAll[selectedBankIndex].showBudgetLevel == 1 ? (selectBudgetLevelListIndex >= 0 && selectedBudgetCodeListIndex >= 0) ? true : false : true) && ibanReceiver.length >= 28 && aliasSelectedIndex >= 0 && (localStorage.getItem('compId') ? selectedTypeIndex >= 0 : true) && purpose.length > 0 && parseFloat(amount) > 0 && (localStorage.getItem('compId') ? (infoReciverAccount && infoReciverAccount.taxNumber) ? infoReciverAccount.taxNumber : localStorage.getItem('compId') ? taxNumberValue.length == 10 : false : true) && (!infoReciverAccount ? (nameValue.length > 0) : true)) {
                    this.setState({ confirmedOperation: true });
                } else {
                    this.setState({ confirmedOperation: false });
                }
            } else {
                if (infoSenderAlias && (infoReciverAlias && banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic)) && (banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic).showBudgetLevel == 1 ? selectBudgetLevelListIndex && selectedBudgetCodeListIndex : true) && aliasSelectedIndex >= 0 && (localStorage.getItem('compId') ? selectedTypeIndex >= 0 : true) && purpose.length > 0 && parseFloat(amount) > 0) {
                    this.setState({ confirmedOperation: true });
                } else {
                    this.setState({ confirmedOperation: false });
                }
            }
        }
    }

    checkPurpose = (event) => {
        let value = event.target.value.trimLeft();
        this.setState({ purpose: value });
        if (value === "") {
            this.setState({ purposeValidation: false }, () => this.checkFields());
        }
        else {
            this.setState({ purposeValidation: true }, () => this.checkFields())
        }
    }

    onChangeIbanReceiver(val) {
        if (/^[a-zA-z0-9]{1,}$/.test(val)) {
            this.setState({ ibanReceiver: val.toUpperCase() }, () => this.onChangeAccountValue())
        } else if (val.length === 0) {
            this.setState({ ibanReceiver: "" }, () => this.onChangeAccountValue())
        }
    }

    onChangeAccountValue() {
        if (this.state.ibanReceiver.length >= 28 && this.state.selectedBankIndex >= 0) {
            if (this.state.banksAll[this.state.selectedBankIndex].showBudgetLevel == 1) {
                let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
                let getAllBanksRequestBody = customerType == 'Juridical' ? {
                    custId: localStorage.getItem('custId'),
                    compId: localStorage.getItem('compId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    iban: this.state.ibanReceiver,

                } : {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    iban: this.state.ibanReceiver,

                }
                fetch(request("operation/getBudgetAccountByIban", getAllBanksRequestBody))
                    .then(res => res.text())
                    .then((getAllBanksResponseJSON) => {
                        const JSON = require('true-json-bigint');
                        let getAllBanksResponse = JSON.parse(getAllBanksResponseJSON);
                        if (getAllBanksResponse.status.statusCode !== 1) {
                            if (getAllBanksResponse.status.statusCode === 200) {
                                this.props.logout();
                            } else {
                                this.setState({ infoReciverAccount: null }, () => this.checkFields());
                            }
                        }
                        else {
                            this.setState({ infoReciverAccount: getAllBanksResponse.budgetAccount }, () => this.checkFields())
                        }
                    },
                        (error) => {
                            this.props.history.push("/technical-break");
                        });
            } else {
                let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
                let getAllBanksRequestBody = customerType == 'Juridical' ? {
                    custId: localStorage.getItem('custId'),
                    compId: localStorage.getItem('compId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    iban: this.state.ibanReceiver,
                    swiftCode: this.state.banksAll[this.state.selectedBankIndex].swiftCode,

                } : {
                    custId: localStorage.getItem('custId'),
                    token: localStorage.getItem('token'),
                    lang: localStorage.getItem('i18nextLng').toUpperCase(),
                    iban: this.state.ibanReceiver,
                    swiftCode: this.state.banksAll[this.state.selectedBankIndex].swiftCode,

                }

                fetch(ipsRequest("getAccountAndCustomerInfo", getAllBanksRequestBody))
                    .then(res => res.text())
                    .then((getAllBanksResponseJSON) => {
                        const JSON = require('true-json-bigint');
                        let getAllBanksResponse = JSON.parse(getAllBanksResponseJSON);
                        if (getAllBanksResponse.status.statusCode !== 1) {
                            if (getAllBanksResponse.status.statusCode === 200) {
                                this.props.logout();
                            } else {
                                this.setState({ infoReciverAccount: null }, () => this.checkFields());
                            }
                        }
                        else {
                            this.setState({ infoReciverAccount: getAllBanksResponse }, () => this.checkFields())
                        }
                    },
                        (error) => {
                            this.props.history.push("/technical-break");
                        });
            }
        } else {
            this.setState({ infoReciverAccount: null }, () => this.checkFields());
        }
    }

    onChangeAliasValue(val) {
        this.setState({ aliasValue: val }, () => this.getInfoAlias())
    }

    getInfoAlias() {
        if (this.state.aliasValue.length > 4 && this.state.aliasTypeSelectIndex >= 0) {
            this.setState({ infoReciverAlias: null })
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let getAllBanksRequestBody = customerType == 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                aliasType: this.state.aliasTypes[this.state.aliasTypeSelectIndex].name,
                value: this.state.aliasValue,

            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                aliasType: this.state.aliasTypes[this.state.aliasTypeSelectIndex].name,
                value: this.state.aliasValue,

            }

            fetch(ipsRequest("getAccountAndCustomerInfoByAliasv1", getAllBanksRequestBody))
                .then(res => res.text())
                .then((getAllBanksResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getAllBanksResponse = JSON.parse(getAllBanksResponseJSON);
                    if (getAllBanksResponse.status.statusCode !== 1) {
                        if (getAllBanksResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.checkFields()
                        }
                    }
                    else {
                        this.setState({ infoReciverAlias: getAllBanksResponse }, () => this.checkFields())
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        } else {
            this.checkFields()
        }
    }

    onSelectAliasValue(val, type) {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getAllBanksRequestBody = customerType == 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasType: type,
            value: val,

        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            aliasType: type,
            value: val,

        }

        fetch(ipsRequest("getAccountAndCustomerInfoByAliasv1", getAllBanksRequestBody))
            .then(res => res.text())
            .then((getAllBanksResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getAllBanksResponse = JSON.parse(getAllBanksResponseJSON);
                if (getAllBanksResponse.status.statusCode !== 1) {
                    if (getAllBanksResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getAllBanksResponse.status.statusCode));
                        this.setState({ infoSenderAlias: null, aliasesStatus: 1 }, () => this.checkFields());
                    }
                }
                else {
                    this.setState({ infoSenderAlias: getAllBanksResponse }, () => this.checkFields())
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    getTransferCommission() {
        this.props.updateAppState('isLoadingTransparentBackground', true);
        let getTransferComissionRequestBody = {
            operationNameId: 5,
            amount: this.state.amount,
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
        }
        if (this.props.match.params.type == 1) {
            if (this.state.reciverType == 0) {
                getTransferComissionRequestBody.operationTypeId = 7;
            } else {
                getTransferComissionRequestBody.operationTypeId = 8;
            }
            getTransferComissionRequestBody.senderIban = this.state.accounts[this.state.accountsSelectedIndex].iban;
        } else {
            if (this.state.reciverType == 0) {
                getTransferComissionRequestBody.operationTypeId = 10;
            } else {
                getTransferComissionRequestBody.operationTypeId = 9;
            }
            getTransferComissionRequestBody.senderAliasId = this.state.aliases[this.state.aliasSelectedIndex].id;
        }
        fetch(ipsRequest("getCommission", getTransferComissionRequestBody))
            .then(res => res.text())
            .then((getTransferComissionResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getTransferComissionResponse = JSON.parse(getTransferComissionResponseJSON);
                if (getTransferComissionResponse.status.statusCode !== 1) {
                    if (getTransferComissionResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ createFeedbackMessage: false, open: true, feedbackErrorModal: this.props.t(getTransferComissionResponse.status.statusCode) });
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                }
                else {
                    this.setState({ feeAmount: getTransferComissionResponse.commission.amount, step: 2 });
                    this.props.updateAppState('isLoadingTransparentBackground', false);
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    onChangeTinValue(value) {
        if (/^[0-9]{1,10}$/.test(value)) {
            this.setState({ taxNumberValue: value }, () => this.checkFields());
        } else if (value.length === 0) {
            this.setState({ taxNumberValue: "" }, () => this.checkFields());
        }
    }

    onChangeNameValue(value) {
        this.setState({ nameValue: value }, () => this.checkFields());
    }


    render() {
        let { t, history } = this.props;
        let currency = "AZN";
        let { accounts, accountsSelectedIndex, amount, bankVisible, typeVisible, accountsVisible, confirmedOperation, currencyBuying, currencySelling, banks, types, banksAll, typesAll, bankFilter, bankCode, typeFilter, selectedBankIndex, selectedTypeIndex, previousSelectedTypeIndex, currencyIndex, currencyShow,
            exchangeRate, step, showUrgent, amountValidation, dtAccountId, ibanReceiver, showType, reciverType, aliasValue, aliases, aliasVisible, aliasReciverStatus, aliasSelectedIndex, aliasTypes, aliasTypeSelectIndex, aliasTypeVisible, purpose, valueBuying, valueSelling, exchangeCashlessList, open, createFeedbackMessage, feedbackErrorModal,
            openTemp, exchangeEditable, infoReciverAlias, infoReciverAccount, amountStatus, budgetCodeStatus, budgetLevelStatus, selectBudgetLevelListIndex, selectedBudgetCodeListIndex, bankStatus, typeStatus, ibanReceiverStatus, purposeStatus, accountsStatus, aliasesStatus, aliasValueStatus, nameStatus, voenStatus, taxNumberValue, nameValue,
            budgetCodeList, budgetCodeListAll, budgetCodeListFilter, budgetCodeListVisible, budgetLevelList, budgetLevelListAll, budgetLevelListFilter, budgetLevelListVisible, feeAmount } = this.state;
        let requestBody = localStorage.getItem('compId') === null ? {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: this.state.operationId,
        } : {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            operId: this.state.operationId,
        };


        return (
            <Fragment>
                <PayRegisterModal open={open} updateOpenModal={this.updateOpenModal} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} errorClose={() => this.setState({ step: 1 })} createFeedbackMessage={createFeedbackMessage} feedbackErrorModal={feedbackErrorModal} history={history} />
                <div className="dashboard-header-route">
                    <div className="dashboard-header-route-left">
                        <button onClick={() => this.props.history.goBack()}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                            </svg>
                        </button>
                        <h2>{t('make a payment')}</h2>
                    </div>
                </div>
                <div className="dashboard-payment-container">
                    <div className="dashboard-payment-container-data-section">
                        <div className="dashboard-payment-container-data-section-header">
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <Link to="/dashboard/transfer/ips/pay/registration/1" style={{ borderBottom: this.props.match.params.type == '1' && '2px solid #0179D2', height: '56px', lineHeight: '56px', color: this.props.match.params.type == '2' && '#959FA5', textDecoration: 'none', }} className="dashboard-payment-container-data-section-header-title">{t('Account')}</Link>
                                <Link to="/dashboard/transfer/ips/pay/registration/2" style={{ borderBottom: this.props.match.params.type == '2' && '2px solid #0179D2', height: '56px', lineHeight: '56px', color: this.props.match.params.type == '1' && '#959FA5', textDecoration: 'none', marginLeft: '12px', }} className="dashboard-payment-container-data-section-header-title">{t('Alias')}</Link>
                            </div>
                        </div>


                        <form className="dashboard-payment-container-data-section-content" >
                            <div className="dashboard-payment-container-data-section-content-container">
                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment settings')}</h3>

                                {
                                    this.props.match.params.type == '1' ?
                                        <div className="dashboard-payment-container-data-section-content-container-form" style={{ borderBottom: '1px solid #E8ECEF', marginBottom: '10px' }}>
                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('From account')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ accountsVisible: false, accountsStatus: accountsVisible ? accountsSelectedIndex >= 0 ? 2 : 1 : accountsStatus }) }}>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${accountsStatus == 1 ? "input-outline" : ""}`} >
                                                            <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} onClick={(e) => { e.preventDefault(); this.setState({ accountsVisible: !accountsVisible, accountsStatus: accountsVisible ? accountsSelectedIndex >= 0 ? 2 : 1 : accountsStatus }) }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{accounts && accounts.length > 0 ? accountsSelectedIndex >= 0 ? accounts[accountsSelectedIndex].iban + (accounts[accountsSelectedIndex].mainCardNumber !== null ? ' / ' + accounts[accountsSelectedIndex].mainCardNumber : "") + ' / ' + accounts[accountsSelectedIndex]?.currentBalance + accounts[accountsSelectedIndex]?.currency : t('Choose') : t('124')}</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            {
                                                                accounts.length !== 0 &&
                                                                <ul className={(accountsVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        accounts.map((account, index) => (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={(e) => { e.preventDefault(); this.setState({ accountsVisible: false, accountsSelectedIndex: index, accountsStatus: 2 }, () => this.checkFields()) }}>{account.iban} /  {account?.mainCardNumber === null ? '' : account?.mainCardNumber + " / "}
                                                                                    {this.amountSplit(account.currentBalance.toString()).part1}.{this.amountSplit(account.currentBalance.toString()).part2} {account?.currency}</button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="dashboard-payment-container-data-section-content-container-form" style={{ borderBottom: '1px solid #E8ECEF', marginBottom: '10px' }}>
                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('From Alias')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ aliasVisible: false, aliasesStatus: aliasVisible ? aliasSelectedIndex >= 0 ? 2 : 1 : aliasesStatus }) }}>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${aliasesStatus == 1 ? "input-outline" : ""}`}>
                                                            <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} onClick={(e) => { e.preventDefault(); this.setState({ aliasVisible: !aliasVisible, aliasesStatus: aliasVisible ? aliasSelectedIndex >= 0 ? 2 : 1 : aliasesStatus }) }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{aliases && aliases.length > 0 ? aliasSelectedIndex >= 0 ? aliases[aliasSelectedIndex].ipsValue : t('Choose') : t('124')}</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            {
                                                                aliases.length !== 0 &&
                                                                <ul className={(aliasVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        aliases.map((alias, index) => (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={(e) => { e.preventDefault(); this.setState({ aliasVisible: false, aliasesStatus: 2, aliasSelectedIndex: index }, () => this.onSelectAliasValue(alias.ipsValue, alias.ipsType)) }}>{alias.ipsValue}</button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Parameters of the recipient')}</h3>

                                <div className="dashboard-ips-container-radio-content">
                                    <RadioButton value={0} disabled={step > 1} selectedId={reciverType} onChange={(e) => this.setState({ reciverType: e.target.value }, () => this.checkFields())} />
                                    <h5 className="dashboard-ips-container-radio-h5">{t('Account')}</h5>
                                    <RadioButton value={1} disabled={step > 1} selectedId={reciverType} onChange={(e) => this.setState({ reciverType: e.target.value }, () => this.checkFields())} />
                                    <h5 className="dashboard-ips-container-radio-h5">{t('Alias')}</h5>
                                </div>
                                {
                                    reciverType == 0 ?
                                        <div className="dashboard-payment-container-data-section-content-container-form" style={{ borderBottom: '1px solid #E8ECEF', marginBottom: '10px' }}>
                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Receipt account')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${ibanReceiverStatus == 1 && ibanReceiver.length < 28 ? "input-outline" : ""}`}>
                                                        <input onBlur={() => this.setState({ ibanReceiverStatus: 1 })} value={ibanReceiver} maxLength={28} type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={e => this.onChangeIbanReceiver(e.target.value)} disabled={step > 1} />
                                                    </div>
                                                </div>

                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Receiving bank')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${bankStatus == 1 ? "input-outline" : ""}`}>
                                                        <div onClick={(e) => { e.preventDefault(); this.setState({ bankVisible: !bankVisible }) }} className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} >
                                                            {banksAll && banksAll.length > 0 ?
                                                                <input type="text" onBlur={() => this.setState({ bankVisible: false, bankStatus: bankVisible ? selectedBankIndex >= 0 ? 2 : 1 : bankStatus })} value={bankFilter} disabled={step > 1} onChange={e => this.onChangeBanksInput(e.target.value)} className="dashboard-payment-container-data-section-content-container-form-input" /> :
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{t('124')}</div>}
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        {
                                                            banks.length !== 0 &&
                                                            <ul className={(bankVisible === true && step == 1) ? "dashboard-payment-container-data-section-content-container-form-select-box-list" : "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"}>
                                                                {
                                                                    banks.map((bank, index) => (
                                                                        <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                            <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"
                                                                                onMouseDown={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({ bankVisible: false, bankStatus: 2, selectedBankIndex: banksAll.findIndex(el => el.id == bank.id), bankFilter: bank.name, bankCode: bank.code },
                                                                                        () => { (this.state.bankCode === "210005" || this.state.bankCode === "210027") ? this.setState({ showUrgent: false, selectedTypeIndex: null }) : this.setState({ showUrgent: true, selectedTypeIndex: previousSelectedTypeIndex }); this.onChangeAccountValue() })
                                                                                }}
                                                                            >{bank.name}</button>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    ((infoReciverAccount && infoReciverAccount.nickName) || (!infoReciverAccount && ibanReceiver.length == 28 && selectedBankIndex >= 0)) &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('name')}</label>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${(infoReciverAccount ? infoReciverAccount.nickName : nameValue).length > 0 ? "" : nameStatus == 0 ? "" : "input-outline"}`}>
                                                            <input onBlur={() => this.setState({ nameStatus: 1 })} type="text" value={infoReciverAccount ? infoReciverAccount.nickName : nameValue} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={e => this.onChangeNameValue(e.target.value)} disabled={step > 1 ? true : (infoReciverAccount && infoReciverAccount.nickName)} />
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    ((infoReciverAccount && infoReciverAccount.taxNumber) || (!infoReciverAccount && ibanReceiver.length == 28 && selectedBankIndex >= 0) || (infoReciverAccount && localStorage.getItem('compId'))) &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('TIN')} {localStorage.getItem('compId') && <span style={{ color: 'red' }}>*</span>}</label>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${(infoReciverAccount && infoReciverAccount.taxNumber ? infoReciverAccount.taxNumber : taxNumberValue).length > 9 ? "" : voenStatus == 0 ? "" : "input-outline"}`}>
                                                            <input onBlur={() => this.setState({ voenStatus: 1 })} type="text" value={(infoReciverAccount && infoReciverAccount.taxNumber) ? infoReciverAccount.taxNumber : taxNumberValue} maxLength={10} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={e => this.onChangeTinValue(e.target.value)} disabled={step > 1 ? true : (infoReciverAccount && infoReciverAccount.taxNumber)} />
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    banksAll && selectedBankIndex >= 0 && banksAll[selectedBankIndex].showBudgetLevel == 1 &&
                                                    <>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Budget code list')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${budgetCodeStatus == 1 ? "input-outline" : ""}`}>
                                                                <div onClick={(e) => { e.preventDefault(); this.setState({ budgetCodeListVisible: !budgetCodeListVisible }) }} className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} >
                                                                    {budgetCodeListAll && budgetCodeListAll.length > 0 ?
                                                                        <input type="text" onBlur={() => this.setState({ budgetCodeListVisible: false, budgetCodeStatus: budgetCodeListVisible ? selectedBudgetCodeListIndex >= 0 ? 2 : 1 : budgetCodeStatus })} value={budgetCodeListFilter} onChange={e => { this.setState({ selectedBudgetCodeListIndex: -1 }, () => { this.checkFields() }); this.onChangebudgetCode(e.target.value) }} className="dashboard-payment-container-data-section-content-container-form-input" disabled={step > 1} /> :
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{t('124')}</div>}
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    budgetCodeList.length !== 0 &&
                                                                    <ul className={(budgetCodeListVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            budgetCodeList.map((budgetCode, index) => (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onMouseDown={(e) => { e.preventDefault(); this.setState({ budgetCodeListVisible: false, budgetCodeStatus: 2, selectedBudgetCodeListIndex: index, budgetCodeListFilter: budgetCode.budgetValue }, () => this.checkFields()) }}>{budgetCode.budgetValue}</button>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Budget level')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${budgetLevelStatus == 1 ? "input-outline" : ""}`}>
                                                                <div onClick={(e) => { e.preventDefault(); this.setState({ budgetLevelListVisible: !budgetLevelListVisible }) }} className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} >
                                                                    {budgetLevelListAll && budgetLevelListAll.length > 0 ?
                                                                        <input type="text" onBlur={() => this.setState({ budgetLevelListVisible: false, budgetLevelStatus: budgetLevelListVisible ? selectBudgetLevelListIndex >= 0 ? 2 : 1 : budgetLevelStatus })} value={budgetLevelListFilter} onChange={e => { this.setState({ selectBudgetLevelListIndex: -1 }, () => { this.checkFields() }); this.onChangebudgetLevel(e.target.value) }} className="dashboard-payment-container-data-section-content-container-form-input" disabled={step > 1} /> :
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{t('124')}</div>}
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    budgetLevelList.length !== 0 &&
                                                                    <ul className={(budgetLevelListVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            budgetLevelList.map((budgetLevel, index) => (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onMouseDown={(e) => { e.preventDefault(); this.setState({ budgetLevelListVisible: false, budgetLevelStatus: 2, selectBudgetLevelListIndex: index, budgetLevelListFilter: budgetLevel.budgetValue }, () => this.checkFields()) }}>{budgetLevel.budgetValue}</button>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        :
                                        <div className="dashboard-payment-container-data-section-content-container-form" style={{ borderBottom: '1px solid #E8ECEF', marginBottom: '10px' }}>
                                            <div className="dashboard-payment-container-data-section-content-container-form-container">
                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Type of alias')} <span style={{ color: 'red' }}>*</span></label>
                                                    <OutsideClickHandler onOutsideClick={(e) => { e.preventDefault(); this.setState({ aliasTypeVisible: false, aliasReciverStatus: aliasTypeVisible ? aliasTypeSelectIndex >= 0 ? 2 : 1 : aliasReciverStatus }) }}>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${aliasReciverStatus == 1 ? "input-outline" : ""}`}>
                                                            <button className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} onClick={(e) => { e.preventDefault(); this.setState({ aliasTypeVisible: !aliasTypeVisible, aliasReciverStatus: aliasTypeVisible ? aliasTypeSelectIndex >= 0 ? 2 : 1 : aliasReciverStatus }) }}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{aliasTypes && aliasTypes.length > 0 ? aliasTypeSelectIndex >= 0 ? aliasTypes[aliasTypeSelectIndex].description : t('Choose') : t('124')}</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>

                                                            {
                                                                aliasTypes.length !== 0 &&
                                                                <ul className={(aliasTypeVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                    {
                                                                        aliasTypes.map((aliasType, index) => (
                                                                            <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={(e) => { e.preventDefault(); this.setState({ aliasTypeVisible: false, aliasReciverStatus: 2, aliasTypeSelectIndex: index }, () => this.getInfoAlias()) }}>{aliasType.description}</button>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </OutsideClickHandler>
                                                </div>

                                                <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                    <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Alias')} <span style={{ color: 'red' }}>*</span></label>
                                                    <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${aliasValue.length > 4 && infoReciverAlias ? "" : aliasValueStatus == 0 ? "" : "input-outline"}`}>
                                                        <input type="text" onBlur={() => this.setState({ aliasValueStatus: 1 })} value={aliasValue} onChange={e => this.onChangeAliasValue(e.target.value)} placeholder={aliasTypeSelectIndex >= 0 && aliasTypes[aliasTypeSelectIndex].name == "MOBILE" ? "+994 xx xxx xx xx" : aliasTypes[aliasTypeSelectIndex].name == "TIN" ? "xxxxxxxxxx" : aliasTypes[aliasTypeSelectIndex].name == "PIN" ? "XXXXXXX" : aliasTypes[aliasTypeSelectIndex].name == "EMAIL" ? "example@mail.com" : ""} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={step > 1} />
                                                    </div>
                                                </div>

                                                {
                                                    infoReciverAlias && infoReciverAlias.nickName &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('name')}</label>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${(infoReciverAlias ? infoReciverAlias.nickName : nameValue).length > 0 ? "" : nameStatus == 0 ? "" : "input-outline"}`}>
                                                            <input onBlur={() => this.setState({ nameStatus: 1 })} type="text" value={infoReciverAlias ? infoReciverAlias.nickName : nameValue} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={true} />
                                                        </div>
                                                    </div>
                                                }

                                                {/* {
                                                    ((infoReciverAlias && infoReciverAlias.taxNumber) || localStorage.getItem('compId')) &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('TIN')} <span style={{ color: 'red' }}>*</span></label>
                                                        <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${((infoReciverAlias && infoReciverAlias.taxNumber) ? infoReciverAlias.taxNumber : taxNumberValue).length > 9 ? "" : voenStatus == 0 ? "" : "input-outline"}`}>
                                                            <input onBlur={() => this.setState({ voenStatus: 1 })} type="text" value={(infoReciverAlias && infoReciverAlias.taxNumber) ? infoReciverAlias.taxNumber : taxNumberValue} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" onChange={e => this.onChangeTinValue(e.target.value)} disabled={(infoReciverAlias && infoReciverAlias.taxNumber)} />
                                                        </div>
                                                    </div>
                                                } */}

                                                {
                                                    infoReciverAlias != null && infoReciverAlias.servicer && banksAll && banksAll.length > 0 &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Receiving bank')} <span style={{ color: 'red' }}>*</span></label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                            <input type="text" value={banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic) && banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic).name} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={true} />
                                                        </div>
                                                    </div>
                                                }


                                                {
                                                    banksAll && infoReciverAlias && banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic) && banksAll.find(el => el.swiftCode == infoReciverAlias.servicer.bic).showBudgetLevel == 1 &&
                                                    <>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Budget code list')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${budgetCodeStatus == 1 && selectedBudgetCodeListIndex >= 0 ? "input-outline" : ""}`}>
                                                                <div onClick={(e) => { e.preventDefault(); this.setState({ budgetCodeListVisible: !budgetCodeListVisible }) }} className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} >
                                                                    {budgetCodeListAll && budgetCodeListAll.length > 0 ?
                                                                        <input type="text" onBlur={() => this.setState({ budgetCodeListVisible: false, budgetCodeStatus: budgetCodeListVisible ? selectedBudgetCodeListIndex >= 0 ? 2 : 1 : budgetCodeStatus })} value={budgetCodeListFilter} onChange={e => { this.setState({ selectedBudgetCodeListIndex: -1 }, () => { this.checkFields() }); this.onChangebudgetCode(e.target.value) }} className="dashboard-payment-container-data-section-content-container-form-input" disabled={step > 1} /> :
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{t('124')}</div>}
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    budgetCodeList.length !== 0 &&
                                                                    <ul className={(budgetCodeListVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            budgetCodeList.map((budgetCode, index) => (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onMouseDown={(e) => { e.preventDefault(); this.setState({ budgetCodeListVisible: false, budgetCodeStatus: 2, selectedBudgetCodeListIndex: index, budgetCodeListFilter: budgetCode.budgetValue }) }}>{budgetCode.budgetValue}</button>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Budget level')} <span style={{ color: 'red' }}>*</span></label>
                                                            <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${budgetLevelStatus == 1 && selectBudgetLevelListIndex ? "input-outline" : ""}`}>
                                                                <div onClick={(e) => { e.preventDefault(); this.setState({ budgetLevelListVisible: !budgetLevelListVisible }) }} className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} >
                                                                    {budgetLevelListAll && budgetLevelListAll.length > 0 ?
                                                                        <input type="text" onBlur={() => this.setState({ budgetLevelListVisible: false, budgetLevelStatus: budgetLevelListVisible ? selectBudgetLevelListIndex >= 0 ? 2 : 1 : budgetLevelStatus })} value={budgetLevelListFilter} onChange={e => { this.setState({ selectBudgetLevelListIndex: -1 }, () => { this.checkFields() }); this.onChangebudgetLevel(e.target.value) }} className="dashboard-payment-container-data-section-content-container-form-input" disabled={step > 1} /> :
                                                                        <div className="dashboard-payment-container-data-section-content-container-form-select">{t('124')}</div>}
                                                                    <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                        <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    budgetLevelList.length !== 0 &&
                                                                    <ul className={(budgetLevelListVisible === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                        {
                                                                            budgetLevelList.map((budgetLevel, index) => (
                                                                                <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index}>
                                                                                    <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onMouseDown={(e) => { e.preventDefault(); this.setState({ budgetLevelListVisible: false, budgetLevelStatus: 2, selectBudgetLevelListIndex: index, budgetLevelListFilter: budgetLevel.budgetValue }, () => this.checkFields()) }}>{budgetLevel.budgetValue}</button>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                }




                                <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Transfer settings')}</h3>
                                <div className="dashboard-payment-container-data-section-content-container-form">
                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${amountStatus == 1 && amount.length < 1 ? "input-outline" : ""}`}>
                                                <input onBlur={() => this.setState({ amountStatus: 1 })} onChange={e => this.onChangeAmount(e.target.value)} value={amount} type="text" maxLength={12} disabled={step > 1} className={`dashboard-payment-container-data-section-content-container-form-input ${step > 1 && " dashboard-payment-container-data-section-content-container-form-input__disable"}`} placeholder="0.00" />
                                                <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">AZN</div>
                                            </div>
                                        </div>

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Appointment')} <span style={{ color: 'red' }}>*</span></label>
                                            <div className={`dashboard-payment-container-data-section-content-container-form-input-box ${purposeStatus == 1 && purpose.length < 1 ? "input-outline" : ""}`}>
                                                <input onBlur={() => this.setState({ purposeStatus: 1 })} maxLength="250" type="text" defaultValue={purpose} className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={step > 1} onChange={this.checkPurpose} />
                                            </div>
                                        </div>

                                        {/* Ödənişin tipi */}
                                        {
                                            showUrgent && showType &&
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Type of payment')} <span style={{ color: 'red' }}>*</span></label>
                                                <div className={`dashboard-payment-container-data-section-content-container-form-select-box ${typeStatus == 1 ? "input-outline" : ""}`}>
                                                    <div onClick={(e) => { e.preventDefault(); this.setState({ typeVisible: !typeVisible }) }} className={step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"} disabled={step > 1} >
                                                        {typesAll && typesAll.length > 0 ?
                                                            <input type="text" onBlur={() => this.setState({ typeVisible: false, typeStatus: typeVisible ? selectedTypeIndex >= 0 ? 2 : 1 : typeStatus })} value={typeFilter} disabled={step > 1} onChange={e => this.onChangeTypesInput(e.target.value)} className="dashboard-payment-container-data-section-content-container-form-input" /> :
                                                            <div className="dashboard-payment-container-data-section-content-container-form-select">{t('124')}</div>}
                                                        <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                            <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    {
                                                        types.length !== 0 &&
                                                        <ul className={(typeVisible === true && step == 1) ? "dashboard-payment-container-data-section-content-container-form-select-box-list" : "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden"}>
                                                            {
                                                                types.map((types, index) => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={index} >
                                                                        <OverlayTrigger overlay={(props) => (
                                                                            <Tooltip {...props}>
                                                                                {types.info}
                                                                            </Tooltip>
                                                                        )}
                                                                            placement="right"
                                                                        >
                                                                            <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onMouseDown={(e) => { e.preventDefault(); this.setState({ typeVisible: false, typeStatus: 2, selectedTypeIndex: types.id, previousSelectedTypeIndex: types.id, typeFilter: types.name }, () => this.onChangeAccountValue()) }}>{types.name}</button>
                                                                        </OverlayTrigger>
                                                                    </li>

                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {/* Ödənişin tipi end */}

                                        <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Additional note')}</label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                <input maxLength="250" type="text" className="dashboard-payment-container-data-section-content-container-form-input dashboard-payment-container-data-section-content-container-form-input__whole" disabled={step > 1} onChange={val => this.setState({ message: val.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    {
                        step == 1 ?
                            <div className="dashboard-payment-container-confirmation-section">
                                <div className="dashboard-payment-container-confirmation-section-header">
                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('What is the Instant Payment System?')}</h3>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section-content">
                                    <img src={transferConfirmation} className="dashboard-payment-container-confirmation-section-content-image" />
                                    <div className="dashboard-payment-container-confirmation-section-content-description">
                                        <p>{t("ips content 1")}</p>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        <button onClick={e => this.getTransferCommission()} disabled={!confirmedOperation} className={`dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left ${!confirmedOperation && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}`}>{t('continue')}</button>
                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="dashboard-payment-container-confirmation-section">
                                <div className="dashboard-payment-container-confirmation-section-header">
                                    <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Payment confirmation')}</h3>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section-content">

                                    <Fragment>
                                        <h3 className="dashboard-payment-container-confirmation-section-amount">
                                            <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter("AZN")}</span>
                                            <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(this.state.amount).part1}</span>
                                            <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(this.state.amount).part2}</span>
                                        </h3>
                                        <ul className="dashboard-payment-container-confirmation-section-list">
                                            {
                                                this.props.match.params.type == '1' ?
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('From account')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.state.accounts[this.state.accountsSelectedIndex].iban}</p>
                                                    </li>
                                                    :
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('From account')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.state.aliases[this.state.aliasSelectedIndex].ipsValue}</p>
                                                    </li>
                                            }
                                            {
                                                reciverType == 0 ?
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Receipt account')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.state.ibanReceiver}</p>
                                                    </li>
                                                    :
                                                    <li className="dashboard-payment-container-confirmation-section-list-item">
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Alias')}</p>
                                                        <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.state.aliasValue}</p>
                                                    </li>
                                            }

                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.amountSplit(this.state.amount).part1}.{this.amountSplit(this.state.amount).part2} AZN</p>
                                            </li>

                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Appointment')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.state.purpose}</p>
                                            </li>

                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('feeAmount')}</p>
                                                <p className="dashboard-payment-container-data-section-content-container-form-group-data">{feeAmount} AZN</p>
                                            </li>

                                            {
                                                showUrgent && showType &&
                                                <li className="dashboard-payment-container-confirmation-section-list-item">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Type of payment')}</p>
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-data">{this.state.typeFilter}</p>
                                                </li>
                                            }

                                            <li className="dashboard-payment-container-confirmation-section-list-item">
                                                <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                    <button onClick={e => this.onSubmitForm()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('Confirm')}</button>
                                                    <button onClick={e => this.onCancel()} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                                </div>
                                            </li>
                                        </ul>

                                    </Fragment>
                                </div>
                            </div>
                    }
                </div>
            </Fragment>
        )
    }
}
export default translate("translation")(PayIPS);
