import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import './scss/style.scss';
import { translate } from 'react-i18next';
import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function DownloadFileModal(props) {
    const classes = useStyles();

    let { t, errorMessage } = props;

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <div className="create-transfer-modal">
                            <button type="button" className="create-transfer-modal-close-button" onClick={props.close}>
                                <svg className="dashboard-change-account-name-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                </svg>
                            </button>
                            <div className="create-transfer-modal-content">
                                <div className={"create-transfer-modal-content-header"}>
                                    <svg style={{ marginBottom: '32px' }} width="200" height="152" viewBox="0 0 200 152" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path d="M10.405 12.752C9.70282 12.752 9.13146 13.32 9.12879 14.0213L8.81641 150.139C8.81641 150.84 9.3851 151.411 10.0873 151.413L119.878 151.667C120.58 151.667 121.151 151.099 121.154 150.397L121.466 14.28C121.466 13.5787 120.897 13.008 120.195 13.0053L10.405 12.752Z" fill="#E6E6E6" />
                                            <path d="M14.4713 16.8347C13.7691 16.8347 13.1977 17.4027 13.1951 18.104L12.9014 146.08C12.9014 146.781 13.4701 147.352 14.1723 147.355L115.811 147.589C116.513 147.589 117.085 147.021 117.087 146.32L117.381 18.344C117.381 17.6427 116.812 17.072 116.11 17.0694L14.4713 16.8347Z" fill="white" />
                                            <path d="M102.611 72.4053L27.7198 72.232C26.6652 72.2293 25.8108 71.3734 25.8135 70.32C25.8162 69.2667 26.6732 68.4134 27.7278 68.416L102.619 68.5894C103.674 68.592 104.528 69.448 104.526 70.5014C104.523 71.5547 103.666 72.408 102.611 72.4053Z" fill="#E6E6E6" />
                                            <path d="M103.334 36.8507L53.6496 36.736C52.9501 36.7334 52.3841 35.88 52.3867 34.824C52.3894 33.7707 52.9581 32.9174 53.6576 32.92L103.342 33.0347C104.042 33.0374 104.608 33.8907 104.605 34.9467C104.605 36 104.034 36.8534 103.334 36.8507Z" fill="#E6E6E6" />
                                            <path d="M103.31 47.792L53.6252 47.6773C52.9257 47.6747 52.3596 46.8213 52.3623 45.7653C52.365 44.7093 52.9337 43.8587 53.6332 43.8613L103.318 43.976C104.017 43.9787 104.584 44.832 104.581 45.888C104.578 46.944 104.009 47.792 103.31 47.792Z" fill="#E6E6E6" />
                                            <path d="M102.581 84.744L27.6895 84.5707C26.6349 84.568 25.7805 83.712 25.7832 82.6587C25.7859 81.6053 26.6429 80.752 27.6976 80.7547L102.589 80.928C103.644 80.9307 104.498 81.7867 104.495 82.84C104.493 83.896 103.636 84.7467 102.581 84.744Z" fill="#E6E6E6" />
                                            <path d="M102.552 97.0853L27.66 96.912C26.6054 96.912 25.751 96.056 25.751 95.0027C25.751 93.9493 26.608 93.096 27.6626 93.096C27.6653 93.096 27.668 93.096 27.6707 93.096L102.562 93.2693C103.617 93.2693 104.471 94.1253 104.471 95.1787C104.471 96.232 103.614 97.0853 102.56 97.0853C102.557 97.0853 102.554 97.0853 102.552 97.0853Z" fill="#E6E6E6" />
                                            <path d="M102.525 109.424L27.6336 109.251C26.579 109.251 25.7246 108.395 25.7246 107.341C25.7246 106.288 26.5817 105.435 27.6363 105.435C27.639 105.435 27.6416 105.435 27.6443 105.435L102.536 105.608C103.591 105.613 104.442 106.469 104.44 107.523C104.432 108.576 103.577 109.424 102.525 109.424Z" fill="#E6E6E6" />
                                            <path d="M102.496 121.763L27.6048 121.589C26.5502 121.584 25.6985 120.728 25.7012 119.675C25.7065 118.624 26.5609 117.773 27.6128 117.773L102.504 117.947C103.559 117.952 104.411 118.808 104.408 119.861C104.403 120.915 103.548 121.765 102.496 121.763Z" fill="#E6E6E6" />
                                            <path d="M102.467 134.104L27.575 133.931C26.5204 133.931 25.666 133.075 25.666 132.021C25.666 130.968 26.5231 130.115 27.5777 130.115C27.5804 130.115 27.583 130.115 27.583 130.115L102.475 130.288C103.529 130.288 104.384 131.144 104.384 132.197C104.384 133.251 103.527 134.104 102.472 134.104C102.472 134.104 102.469 134.104 102.467 134.104Z" fill="#E6E6E6" />
                                            <path d="M35.9429 50.4347C41.5005 50.4347 46.0058 45.9348 46.0058 40.384C46.0058 34.8332 41.5005 30.3333 35.9429 30.3333C30.3852 30.3333 25.8799 34.8332 25.8799 40.384C25.8799 45.9348 30.3852 50.4347 35.9429 50.4347Z" fill="#3F3D56" />
                                            <path d="M93.6299 37.4267V13.648C93.7393 6.21868 87.7961 0.109344 80.3576 1.0337e-05C72.9966 -0.106656 66.9145 5.70668 66.6982 13.056H69.6619C69.8808 7.42401 74.5131 2.96534 80.1574 2.95734C80.2081 2.95734 80.2615 2.95734 80.3122 2.95734C86.1193 3.05068 90.7543 7.82401 90.6662 13.624V37.4347C90.6662 37.496 90.6662 37.5547 90.6662 37.6107C90.6155 41.4613 87.4783 44.56 83.6203 44.5627C83.5909 44.5627 83.5615 44.5627 83.5322 44.5627C79.6367 44.5093 76.5209 41.312 76.5717 37.4213L76.5877 13.8027C76.6197 11.8453 78.2324 10.2827 80.1921 10.3147C82.0984 10.344 83.6416 11.8747 83.6844 13.7813V31.816C83.6844 32.632 84.3465 33.296 85.1662 33.296C85.9858 33.296 86.648 32.6347 86.648 31.816V13.7573C86.5919 10.1653 83.631 7.29868 80.0346 7.35734C76.5102 7.41334 73.6694 10.2613 73.624 13.7813L73.608 37.4053C73.5466 42.9253 77.9707 47.4507 83.4948 47.5227C83.5375 47.5227 83.5776 47.5227 83.6176 47.5227C89.0963 47.5147 93.5551 43.112 93.6245 37.64C93.6325 37.568 93.6325 37.4907 93.6299 37.4267Z" fill="#0179D2" />
                                            <path d="M148.745 151.437H0V151.971H148.745V151.437Z" fill="#3F3D56" />
                                            <path d="M153.182 32.9067H173.908V22.6133C173.916 19.832 172.824 17.1627 170.873 15.1787C168.948 13.2053 166.304 12.096 163.544 12.0987C157.881 12.0987 153.328 16.5947 153.184 22.336C153.182 22.4293 153.182 22.52 153.182 22.6107V32.9067Z" fill="#2F2E41" />
                                            <path d="M148.998 88.9307C150.696 89.1441 152.245 87.9387 152.456 86.2427C152.493 85.9494 152.488 85.6507 152.44 85.36L151.652 80.5547H147.767L146.419 84.9547C145.917 86.5894 146.838 88.3201 148.475 88.8187C148.646 88.8721 148.819 88.9094 148.998 88.9307Z" fill="#FFB9B9" />
                                            <path d="M196.563 146.264L191.949 146.829L168.157 97.0427L154.725 146.829L149.871 145.373L154.402 65.5227L175.764 64.392C183.87 73.9734 190.238 115.259 196.563 146.264Z" fill="#2F2E41" />
                                            <path d="M152.122 152H142.443C141.514 152 140.748 151.267 140.71 150.339C140.684 149.677 141.033 149.059 141.616 148.744L147.182 145.72L150.61 144.805C152.119 146.533 153.563 146.541 154.954 145.147L155.411 148.184C155.683 149.997 154.431 151.691 152.616 151.963C152.45 151.989 152.287 152 152.122 152Z" fill="#2F2E41" />
                                            <path d="M193.714 152H184.036C183.107 152 182.341 151.267 182.303 150.339C182.276 149.677 182.626 149.059 183.208 148.744L188.775 145.72L192.203 144.805C193.712 146.533 195.156 146.541 196.547 145.147L197.004 148.184C197.276 149.997 196.024 151.691 194.208 151.963C194.046 151.989 193.88 152 193.714 152Z" fill="#2F2E41" />
                                            <path d="M146.473 81.6853L152.461 81.3627L156.183 38.2027L153.366 38.624C151.214 38.9467 149.554 40.6827 149.332 42.8453L146.473 81.6853Z" fill="#0179D2" />
                                            <path d="M162.009 30.4453C166.388 30.4453 169.938 26.8994 169.938 22.5253C169.938 18.1513 166.388 14.6053 162.009 14.6053C157.629 14.6053 154.079 18.1513 154.079 22.5253C154.079 26.8994 157.629 30.4453 162.009 30.4453Z" fill="#FFD5D5" />
                                            <path d="M171.559 36.104C165.557 53.6027 160.281 54.8267 155.374 47.7413C155.807 42.464 157.561 38.1867 160.714 34.9707C162.545 33.7173 161.464 31.3333 160.068 28.8293L167.674 26.2427C167.891 29.4347 169.29 32.7307 171.559 36.104Z" fill="#FFD5D5" />
                                            <path d="M176.17 65.1174C167.483 71.1094 159.665 72.216 154.483 66.5734C152.67 59.08 152.296 51.224 153.177 43.4614C153.46 41.0027 154.824 38.8 156.902 37.4507L160.714 34.9707L162.575 40.8694L169.776 33.0294L175.217 33.6854C177.404 33.9494 178.969 35.92 178.728 38.1067L176.17 65.1174Z" fill="#0179D2" />
                                            <path d="M170.889 16.5413L168.918 22.8L151.89 22.4987C152.045 16.6933 156.88 12.1147 162.692 12.2667C165.939 12.352 168.961 13.9307 170.889 16.5413Z" fill="#2F2E41" />
                                            <path d="M174.266 82.2053C174.106 82.2053 173.978 82.3333 173.975 82.4933L173.903 113.419C173.903 113.579 174.031 113.707 174.192 113.709L199.137 113.768C199.297 113.768 199.425 113.64 199.428 113.48L199.5 82.5547C199.5 82.3947 199.372 82.2667 199.212 82.264L174.266 82.2053Z" fill="#E6E6E6" />
                                            <path d="M175.19 83.1334C175.03 83.1334 174.901 83.2614 174.899 83.4214L174.832 112.496C174.832 112.656 174.96 112.784 175.12 112.787L198.213 112.84C198.373 112.84 198.501 112.712 198.504 112.552L198.57 83.4774C198.57 83.3174 198.442 83.1894 198.282 83.1867L175.19 83.1334Z" fill="white" />
                                            <path d="M195.215 95.7573L178.199 95.7173C177.959 95.7173 177.764 95.5227 177.767 95.2827C177.767 95.0427 177.962 94.848 178.202 94.8507C178.202 94.8507 178.202 94.8507 178.205 94.8507L195.22 94.8907C195.46 94.8907 195.655 95.0853 195.652 95.3253C195.652 95.5653 195.458 95.7573 195.215 95.7573C195.217 95.7573 195.217 95.7573 195.215 95.7573Z" fill="#E6E6E6" />
                                            <path d="M195.381 87.68L184.092 87.6533C183.932 87.6533 183.804 87.4587 183.807 87.2187C183.809 86.9787 183.938 86.7867 184.095 86.7867L195.383 86.8133C195.544 86.8133 195.672 87.008 195.669 87.248C195.667 87.488 195.541 87.68 195.381 87.68Z" fill="#E6E6E6" />
                                            <path d="M195.375 90.1653L184.087 90.1386C183.926 90.1386 183.798 89.944 183.801 89.704C183.803 89.464 183.932 89.272 184.089 89.272L195.378 89.2986C195.538 89.2986 195.666 89.4933 195.663 89.7333C195.661 89.9733 195.535 90.1653 195.375 90.1653Z" fill="#E6E6E6" />
                                            <path d="M195.21 98.5627L178.194 98.5227C177.954 98.5227 177.762 98.3254 177.762 98.088C177.762 97.8507 177.957 97.656 178.194 97.656L195.21 97.696C195.45 97.696 195.642 97.8907 195.642 98.1307C195.642 98.368 195.45 98.5627 195.21 98.5627Z" fill="#E6E6E6" />
                                            <path d="M195.205 101.365L178.189 101.325C177.949 101.323 177.757 101.128 177.757 100.888C177.76 100.651 177.952 100.459 178.189 100.456L195.205 100.496C195.445 100.499 195.637 100.693 195.635 100.933C195.635 101.173 195.442 101.365 195.205 101.365Z" fill="#E6E6E6" />
                                            <path d="M195.196 104.168L178.18 104.128C177.94 104.128 177.745 103.933 177.745 103.696C177.745 103.456 177.94 103.261 178.178 103.261H178.18L195.196 103.301C195.436 103.301 195.631 103.496 195.631 103.736C195.634 103.973 195.439 104.168 195.196 104.168C195.198 104.168 195.196 104.168 195.196 104.168Z" fill="#E6E6E6" />
                                            <path d="M195.191 106.971L178.175 106.933C177.935 106.933 177.74 106.739 177.74 106.499C177.74 106.259 177.935 106.064 178.175 106.064H178.178L195.194 106.104C195.434 106.104 195.626 106.299 195.626 106.539C195.626 106.779 195.431 106.973 195.191 106.971Z" fill="#E6E6E6" />
                                            <path d="M195.182 109.776L178.167 109.736C177.927 109.736 177.734 109.539 177.734 109.301C177.734 109.064 177.929 108.869 178.17 108.869L195.185 108.909C195.425 108.912 195.618 109.107 195.618 109.347C195.615 109.581 195.423 109.776 195.182 109.776Z" fill="#E6E6E6" />
                                            <path d="M180.068 90.7653C181.33 90.7653 182.353 89.7434 182.353 88.4827C182.353 87.222 181.33 86.2 180.068 86.2C178.805 86.2 177.782 87.222 177.782 88.4827C177.782 89.7434 178.805 90.7653 180.068 90.7653Z" fill="#3F3D56" />
                                            <path d="M182.629 84.4054C180.931 84.616 179.382 83.4134 179.171 81.7174C179.134 81.424 179.139 81.1254 179.187 80.8347L179.975 76.0294H183.859L185.208 80.4294C185.71 82.064 184.789 83.7947 183.152 84.2934C182.981 84.3467 182.805 84.384 182.629 84.4054Z" fill="#FFB9B9" />
                                            <path d="M185.152 77.16L179.163 76.8374L175.441 33.6773L178.258 34.0987C180.41 34.4213 182.071 36.1573 182.292 38.32L185.152 77.16Z" fill="#0179D2" />
                                            <path d="M140.804 58.1067L139.859 57.1627C138.711 56.016 136.852 56.016 135.704 57.1627L117.383 75.4613L99.0622 57.1627C97.9141 56.016 96.0558 56.016 94.9078 57.1627L93.9626 58.1067C92.8145 59.2533 92.8145 61.1093 93.9626 62.256L112.284 80.5547L93.9626 98.8534C92.8145 100 92.8145 101.856 93.9626 103.003L94.9078 103.947C96.0558 105.093 97.9141 105.093 99.0622 103.947L117.383 85.648L135.704 103.947C136.852 105.093 138.711 105.093 139.859 103.947L140.804 103.003C141.952 101.856 141.952 100 140.804 98.8534L122.483 80.5547L140.804 62.256C141.949 61.1093 141.949 59.2507 140.804 58.1067Z" fill="#F55050" />
                                            <path opacity="0.1" d="M93.3057 99.848L111.085 82.0907L110.914 81.92L93.9598 98.8534C93.6768 99.1387 93.4525 99.4773 93.3057 99.848Z" fill="black" />
                                            <path opacity="0.1" d="M93.7062 58.7013C94.8542 57.5547 96.7125 57.5547 97.8606 58.7013L116.182 77L134.503 58.7013C135.651 57.5547 137.509 57.5547 138.657 58.7013L139.602 59.6453C140.43 60.472 140.686 61.712 140.256 62.8L140.801 62.256C141.949 61.1093 141.949 59.2533 140.801 58.1067L139.856 57.1627C138.708 56.016 136.85 56.016 135.702 57.1627L117.38 75.4613L99.0594 57.1627C97.9113 56.016 96.053 56.016 94.905 57.1627L93.9598 58.1067C93.6768 58.3893 93.4525 58.728 93.3057 59.1013L93.7062 58.7013Z" fill="black" />
                                            <path opacity="0.1" d="M122.651 80.7253L121.281 82.0933L139.602 100.392C140.43 101.219 140.686 102.459 140.256 103.547L140.801 103.003C141.949 101.856 141.949 100 140.801 98.8533L122.651 80.7253Z" fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="199.5" height="152" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <h3 className="create-transfer-modal-content-header-title">{t("No files found")}</h3>
                                    <p className="create-transfer-modal-content-header-subtitle">{errorMessage}</p>
                                </div>
                            </div>
                            <div className="create-transfer-modal-footer"></div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(DownloadFileModal);

