import React from "react";
import loading from "./img/loading.svg";

import "./Loading.scss";

function Loading() {
  return (
    <div className="ibank-loading">
      <img src={loading} className="background-loading-img" alt="Loading" />
    </div>
  );
}

export default Loading;
