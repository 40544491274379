import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { translate } from 'react-i18next';

import cardBlueBackground from "./../../img/card-blue-background.svg";
import otherBankCard from "./../../img/other-bank-card.svg";
import cardTransparentBackground from './../../img/card-transparent-background.svg';
import digitalCard from './../../img/digital_card.svg';
// import ArrowLeft from "./../../img/angle-previous.svg";
// import ArrowRight from "./../../img/angle-next.svg";
import masterCardIcon from "./../../img/card-icon.svg";
import visaCardIconWhite from "./../../img/visa-icon-white.svg";
import { cardTypes , staticCardsTypes, setPaymentTypeIcon } from "../../../../helpers/cards";

class CardSlider extends Component {
    constructor(props) {
        super(props);
    }
    next = () => {
        this.slider.slickNext();
        this.props.updateCurrentCard('next');
    }
    previous = () => {
        this.slider.slickPrev();
        this.props.updateCurrentCard('prev');
    }
    afterChangeHandler = (currentSlide) => {
        this.props.updateCardState(currentSlide);
    }
    setCardsBackground=(status,type)=>{
        if(status){
          if(type === cardTypes.DIGITAL_CARD || type === staticCardsTypes.ACTIVATE_DIGITAL_CARD_BTN){
            return <div><img src={digitalCard} className="dashboard-card-section-content-card-background" alt="active-digital-card" /></div>
          }else if(type === staticCardsTypes.ADD_CARDS_BTN){
            return <div className="dashboard-card-section-content-add-card-background"></div>; 
          }else if (type === cardTypes.CARD_STORAGE){
            return <div><img src={otherBankCard} className="dashboard-card-section-content-card-background" alt="otherBankCard" /></div>
          }
          else{
            return <div><img src={cardBlueBackground} className="dashboard-card-section-content-card-background" alt="activeCard" /></div>
          }
        }else{
            if(type === cardTypes.DIGITAL_CARD){
                return <div className="deactive-digital-card"><img src={digitalCard} className="dashboard-card-section-content-card-background" alt="deactive-digital-card" /></div>
            }else{
                return <div><img src={cardTransparentBackground} className="dashboard-card-section-content-card-background" alt="deactiveCard" /></div>
            }
        }
    }


    render() {
        let settings = {
            dots: true,
            infinite: true,
            swipeToSlide: false,
            slidesToShow: 1,
            slidesToScroll: 1,

        };
        let { cards, t , toggleAddNewCardModal} = this.props;
        return (
            <div className="dashboard-card-section-content-slider-container">
                {
                    (cards.length > 1) ?
                        <button className="dashboard-card-section-content-slider-prev" onClick={this.previous}>
                            <svg className="dashboard-card-section-content-slider-arrow-image" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.03349 16.5719C0.358031 17.2473 0.358031 18.193 1.03349 18.8684L16.8392 34.539C17.5146 35.2145 18.5953 35.2145 19.1357 34.539L20.0813 33.5934C20.7568 32.9179 20.7568 31.9723 20.0813 31.2968L6.30204 17.7877L20.2164 4.14349C20.7568 3.46804 20.7568 2.5224 20.2164 1.84694L19.1357 0.901306C18.5953 0.22585 17.5146 0.22585 16.8392 0.901306L1.03349 16.5719Z" fill="#DEE2E8" />
                            </svg>
                        </button> : null
                }
                <div className="dashboard-card-section-content-slider">
                    <Slider ref={c => (this.slider = c)} {...settings} afterChange={this.afterChangeHandler}>
                        {
                            cards.map((card, index) =>
                                <div className="dashboard-card-section-content-card" key={index}> 
                                    { this.setCardsBackground(card.cardStatus, card.cardType) }
                                    <div className="dashboard-card-section-content-card-item">
                                       {card.cardType !== staticCardsTypes.ACTIVATE_DIGITAL_CARD_BTN && card.cardType !== staticCardsTypes.ADD_CARDS_BTN  && 
                                       <div className="dashboard-card-section-content-card-item-name">
                                                { card.cardType === cardTypes.DIGITAL_CARD && <p>{card.cardType}</p> }
                                                <img src={setPaymentTypeIcon(
                                                    card.cardPaymentType, 
                                                    masterCardIcon,
                                                    visaCardIconWhite)} 
                                                    className="dashboard-card-section-content-card-item-logo" 
                                                    alt="cardPaymentTypeIcon"
                                                 /> 
                                       </div>
                                       }
                                        {
                                            card?.organizedCardNumber &&  
                                                <div className="dashboard-card-section-content-card-item-card-number-section">
                                                    <h4 className="dashboard-card-section-content-card-item-card-number-content">{card?.organizedCardNumber}</h4>
                                                    <p className="dashboard-card-section-content-card-item-card-number-title">{t('card number')}</p>
                                                </div>
                                        }
                                                <div className="dashboard-card-section-content-card-item-card-details-section">
                                                    {  
                                                        card.cardType === cardTypes.CARD_STORAGE ? 
                                                            <div className="dashboard-card-section-content-card-item-card-owner-section">
                                                                <p className="dashboard-card-section-content-card-item-card-owner-name">{card.holder}</p>
                                                                <p className="dashboard-card-section-content-card-item-card-owner-title">{t("Bank name")}</p>
                                                            </div> :
                                                            card.organizedHolder && 
                                                            <div className="dashboard-card-section-content-card-item-card-owner-section">
                                                                <p className="dashboard-card-section-content-card-item-card-owner-name">{card.organizedHolder?.name} {card.organizedHolder?.surname}</p>
                                                            <p className="dashboard-card-section-content-card-item-card-owner-title">{t("owner's name")}</p>
                                                            </div>
                                                    }
                                                    {
                                                        card.organizedExpiryDate &&
                                                        <div className="dashboard-card-section-content-card-item-card-valid-section">
                                                            <p className="dashboard-card-section-content-card-item-card-valid-date">{card.organizedExpiryDate?.month} / {card.organizedExpiryDate?.year}</p>
                                                            <p className="dashboard-card-section-content-card-item-card-valid-title">{t('it is reliable')}</p>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                        {
                                           card.cardType === "ActivateDigitalCardBtn" && 
                                                <div className="dashboard-card-section-content-card-item-order-digital-card">
                                                    <p className="dashboard-card-section-content-card-item-order-digital-card-title">{t("Digital card")}</p>
                                                    <Link 
                                                    to ="/dashboard/finance/cards/digital-card"
                                                     className="dashboard-card-section-content-card-item-order-digital-card-btn">+ {t("Activate Digital card")}</Link>
                                                </div>
                                        }


                                        {
                                           card.cardType === "AddCardsBtn" && 
                                                <div className="dashboard-card-section-content-card-item-add-card">
                                                    <button className="dashboard-card-section-content-card-item-add-card-btn-icon" onClick={toggleAddNewCardModal}>+</button>
                                                    <p className="dashboard-card-section-content-card-item-add-card-btn-title">{t("add a new card")}</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </Slider>
                </div>
                {
                    (cards.length > 1) ?
                        <button className="dashboard-card-section-content-slider-next" onClick={this.next}>
                            {/* <img className="dashboard-card-section-content-slider-arrow-image" src={ArrowRight}/> */}
                            <svg className="dashboard-card-section-content-slider-arrow-image" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9665 19.4281C20.642 18.7527 20.642 17.807 19.9665 17.1316L4.16085 1.461C3.48539 0.785545 2.40466 0.785545 1.8643 1.461L0.918659 2.40664C0.243203 3.08209 0.243203 4.02773 0.918659 4.70319L14.698 18.2123L0.783569 31.8565C0.243205 32.532 0.243205 33.4776 0.783569 34.1531L1.8643 35.0987C2.40466 35.7741 3.48539 35.7741 4.16085 35.0987L19.9665 19.4281Z" fill="#DEE2E8" />
                            </svg>
                        </button> : null
                }
            </div>
        );
    }
}


export default translate("translation")(CardSlider);