import React, { Component } from 'react';
import YesNoModal from './../../../../copms/Modal/YesNoModal';
import $ from 'jquery';
import defaultProfilePhoto from "./../../img/default-profile-photo2.svg";
import penIcon from "./../../img/penicon.svg";
import { request } from './../../../../config';
import { translate } from 'react-i18next';

class ChangeProfilePhoto extends Component {

    state = {
        deleteOpen: false
    }

    componentDidMount() {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
    }


    changeProfilePhotoMethod = () => {
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        const readURL = (input) => {
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.onload = (event) => {
                    let updateProfileImageRequestBody;
                    let bytes = event.target.result;
                    let imageBytes = bytes.split('base64,')[1];

                    // let index = bytesStr.indexOf('base64,');
                    // bytesStr = bytesStr.slice(index + 7, bytesStr.length);
                    if (customerType === 'Juridical') {
                        updateProfileImageRequestBody = {
                            custInfoType: "PROFILE_IMAGE",
                            custId: localStorage.getItem('custId'),
                            lang: localStorage.getItem('i18nextLng').toUpperCase(),
                            compId: localStorage.getItem('compId'),
                            token: localStorage.getItem('token'),
                            imageBytes: imageBytes
                        };
                    }
                    else {
                        updateProfileImageRequestBody = {
                            custInfoType: "PROFILE_IMAGE",
                            custId: localStorage.getItem('custId'),
                            lang: localStorage.getItem('i18nextLng').toUpperCase(),
                            token: localStorage.getItem('token'),
                            imageBytes: imageBytes
                        };
                    }
                    fetch(request("setting/updateCustomerInfo" + customerType, updateProfileImageRequestBody))
                        .then(res => res.json())
                        .then(gupdateProfileImageResponse => {
                            if (gupdateProfileImageResponse.status.statusCode !== 1) {
                                if (gupdateProfileImageResponse.status.statusCode === 200) {
                                    this.props.logout();
                                } else {
                                    this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(gupdateProfileImageResponse.status.statusCode));
                                }
                            }
                            else {
                                this.props.updateDashboardState('userProfilePhoto', event.target.result);
                            }
                        }, (error) => {
                            // this.props.history.push("/technical-break");  
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t("File upload problem"));
                        });
                }
                reader.readAsDataURL(input.files[0]); // convert to base64 string
            }
        }

        // $("#uploadProfilePicture").change(function () {
        //     readURL(this);
        // });
        $("#uploadProfilePicture").off('change').on('change', function () {
            readURL(this);
          });
    }
    render() {
        let { userProfilePhoto, t } = this.props;
        let { deleteOpen } = this.state;
        return (
            <>
                <YesNoModal onClose={() => this.setState({ deleteOpen: false })} title={t('Are you sure you want to delete your profile picture?')} open={deleteOpen} triggerFunc={() => this.deletePhoto()} />
                <div className="dashboard-settings-section-profile">

                    <div className="dashboard-settings-section-profile-left">

                        <div className="dashboard-settings-section-profile-circle">
                            <div className="dashboard-settings-section-profile-user-image-container">
                                {userProfilePhoto ? <img src={userProfilePhoto} className="dashboard-settings-section-profile-user-image" /> : <img src={defaultProfilePhoto} className="dashboard-settings-section-profile-user-image" />}
                            </div>
                            <input type="file" className="dashboard-settings-section-profile-user-input" id="uploadProfilePicture" accept="image/*" onClick={this.changeProfilePhotoMethod} />
                            <div className="dashboard-settings-section-profile-user-label">
                                <img src={penIcon} className="dashboard-settings-section-profile-user-label-image" width="22px" />
                            </div>

                        </div>
                        <div className="dashboard-settings-section-profile-user-data">
                            <h3 className="dashboard-settings-section-profile-user-data-name">{localStorage.getItem("custObject") && localStorage.getItem("compName") ? localStorage.getItem("compName") : localStorage.getItem("fullname")}</h3>
                            <p className="dashboard-settings-section-profile-user-data-title">{t("Your client code")}:
                                <span> {localStorage.getItem("compId") ? localStorage.getItem("compIdEncrypt") : localStorage.getItem("custIdEncrypt")}</span>
                            </p>
                        </div>
                    </div>
                
                </div>
            </>
        )
    }
}


export default translate("translation")(ChangeProfilePhoto);