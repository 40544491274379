import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { request } from "./../../../../config";
import CompanyListItem from "./CompanyListItem/CompanyListItem";
import headerLogo from "./../../img/header-logo.svg";
import { translate } from 'react-i18next';

class SelectCompany extends Component {
    state = {
        companies: null,
        compId: null,
        taxNo: null,
        feedbackMessage: null,
        isDisabled: true
    }
    backCheckCustomer = () => {
        this.props.history.push('/registration/juridical/asan/1');
        // window.location.href= '/registration/juridical/asan/1';
    }
    updateCheckedCompany = (token, taxNo) => {
        this.setState({ isDisabled: false });
        this.setState({compId: token, taxNo: taxNo});
    }
    getCustCompany = (event) => {
        event.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);
        this.setState({ feedbackMessage: null });
        let getCustCompanyRequestBody = {
            // pin: this.props.location.state.pin,
            mobile: this.props.location.state.mobile,
            userId: this.props.location.state.userId,
            // token: this.props.location.state.userId
            // taxNo: this.state.taxNo
        };
        fetch(request("register/getCustomerCompany", {...getCustCompanyRequestBody, token: this.state.compId}))
        .then(json => json.text())
        .then(getCustCompanyResponse => {
            const JSON = require('true-json-bigint');
            let getCustCompanyResponseObject = JSON.parse(getCustCompanyResponse);
            if (getCustCompanyResponseObject.status.statusCode === 1) {

                // console.log(this.props.location.state.getAuthInfoJuridicalRegisterHeaders)
                // console.log({...getCustCompanyRequestBody, token: this.state.compId})
                // let custId = "";
                // if (typeof (getCustCompanyResponseObject.customer.custId) === 'object' && getCustCompanyResponseObject.customer.custId.hasOwnProperty('c')) {
                //     getCustCompanyResponseObject.customer.custId.c.forEach(part => {
                //         custId += part;
                //     });
                // }
                // else {
                //     custId += getCustCompanyResponseObject.customer.custId;
                // }
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.props.history.push('/registration/juridical/asan/4', {token: this.state.compId });
            }
            else {
                // console.log(this.props.location.state.getAuthInfoJuridicalRegisterHeaders)
                // console.log({...getCustCompanyRequestBody, token: this.state.compId})
                this.props.updateAppState('isLoadingTransparentBackground', false);
                this.setState({ feedbackMessage: this.props.t(getCustCompanyResponseObject.status.statusCode)});
                this.props.history.push('/registration/juridical/asan/1', {feedbackMessage: this.props.t(getCustCompanyResponseObject.status.statusCode)});
            }
        },
        (error) => {
            this.props.history.push("/technical-break");
        });
    }
 
    componentDidMount = () => {
        if(this.props.location.state === undefined) {
            this.props.history.push('/registration/juridical/asan/1');
            // window.location.href= '/registration/juridical/asan/1';
        }
        else {
            this.setState({companies: this.props.location.state.companies, compId: this.props.location.state.compId})
        }
    }

    render() {
        let { isDisabled, feedbackMessage, companies, compId } = this.state;
        let { t } = this.props;
        return (
            <div className="registration">
                <Link to="/">
                    <img src={headerLogo} className="registration-logo" alt="headerLogo" />
                </Link>
                <form className="registration-companies-container registration-container">
                    <div className="registration-companies-container-header">
                        <h2 className="registration-container-title">{t('choose a company')}</h2>
                        <p className="registration-container-description">{t('please select the company you want to register')}</p>
                    </div>
                    <div className="registration-companies-container-content">
                        <ul className="registration-companies-container-list">
                            {
                                companies !== null ?
                                companies.map(comp => <CompanyListItem key={comp.token} companyId={comp.token} companyName={comp.name} companyTaxNo={comp.taxNo} updateCheckedCompany={this.updateCheckedCompany} compIdResponse={compId} />)
                                :
                                null
                            }
                        </ul>
                        <div className="registration-container-validation-feedback">
                            {
                                feedbackMessage ? <p className="registration-container-validation-feedback-message">{feedbackMessage}</p> : null
                            }
                        </div>
                    </div>
                    <div className="registration-companies-container-footer registration-container-footer">
                        <button type="button" className="registration-container-footer-button registration-container-footer-button--left" onClick={this.backCheckCustomer}>{t('back')}</button>
                        <button className="registration-container-footer-button registration-container-footer-button--right" disabled={isDisabled} onClick={this.getCustCompany}>{t('next')}</button>
                    </div>
                </form>
                <div className="registration-range">
                    <p className="registration-range-fraction">
                        <span className="registration-range-fraction-numerator">1</span>/3
                    </p>
                    <div className="registration-range-container">
                        <div className="registration-range-container-step registration-range-container-step--active">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                        </div>
                        <div className="registration-range-container-step">
                            <div className="registration-range-container-step-circle"></div>
                            <div className="registration-range-container-step-line"></div>
                            <div className="registration-range-container-step-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default translate("translation")(SelectCompany);