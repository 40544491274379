import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { translate } from 'react-i18next';

import './scss/style.scss';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'inline-block',
        maxHeight: '100vh',
        overflow: 'auto'
    },
}));

function TemplateInputModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [tempName, setTempName] = React.useState(true);

    const close = () => {
        setOpen(false);
        props.history.goBack();

        //props.onClose();
    }
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const submitForm = (e) => {
        e.preventDefault();
        props.onSubmit(tempName);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                classes={classes.modal}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description">
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="dashboard-change-account-name-section-modal">
                            <button className="dashboard-change-account-name-section-modal-close-button" onClick={close}>
                                <svg className="dashboard-change-account-name-section-modal-close-button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.67762 7.97496L14.8357 2.86698L15.8873 1.81534C16.0376 1.6651 16.0376 1.41471 15.8873 1.2144L14.7856 0.112676C14.5853 -0.0375587 14.3349 -0.0375587 14.1847 0.112676L8.02504 6.32238L1.81534 0.112676C1.6651 -0.0375587 1.41471 -0.0375587 1.2144 0.112676L0.112676 1.2144C-0.0375587 1.41471 -0.0375587 1.6651 0.112676 1.81534L6.32238 7.97496L0.112676 14.1847C-0.0375587 14.3349 -0.0375587 14.5853 0.112676 14.7856L1.2144 15.8873C1.41471 16.0376 1.6651 16.0376 1.81534 15.8873L8.02504 9.67762L13.133 14.8357L14.1847 15.8873C14.3349 16.0376 14.5853 16.0376 14.7856 15.8873L15.8873 14.7856C16.0376 14.5853 16.0376 14.3349 15.8873 14.1847L9.67762 7.97496Z" fill="#2E3131" />
                                </svg>
                            </button>
                            <div className="dashboard-change-account-name-section-modal-content">
                                <form onSubmit={submitForm} className="dashboard-payment-container-data-section-content-container-form">
                                    <div className="dashboard-payment-container-data-section-content-container-form-container">
                                        <div style={{ width: '100%', minWidth: '250px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{props.t('template name')}</label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-input-box" style={{ width: '100%' }}>
                                                <input className="dashboard-payment-container-data-section-content-container-form-input" onChange={e => setTempName(e.target.value)} placeholder={props.t('template name')} />
                                            </div>
                                        </div>
                                        <div className="dashboard-change-account-name-section-modal-content-form-button-section">
                                            <button className="dashboard-change-account-name-section-modal-content-form-button dashboard-change-account-name-section-modal-content-form-button--right-side">{props.t('save')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </Fade>
            </Modal>
        </div>
    );
}

export default translate("translation")(TemplateInputModal);

