import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import translationEN from './en.json';
import translationRU from './ru.json';
import translationAZ from './az.json';


const resources = {
    az: {
        translation: translationAZ
    },
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    },
};


i18n
    .use(detector)
    .init({
        resources,
        lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng'): 'az' ,
        fallbackLng: ['az', 'en', 'ru'],
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
