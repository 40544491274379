import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next';
import empty from './../../../img/last-operations-icon.png';
import schedule from './../../../img/schedule.svg'
import currencyFilter from '../../../../../helpers/currencyFilter';
import { request } from "./../../../../../config";
import PaymentModalSuccesPartial from '../../../../../copms/Modal/CreditPaymentModalSuccessPartial';
import PaymentModalError from '../../../../../copms/Modal/PaymentModalError';
import Loading from './../../../Loading/Loading';
import { OverlayTrigger } from 'react-bootstrap';
import ToolTip from '../../../../../copms/ToolTip/ToolTip';
import { roundNumberWithDecimal, setNumberToDecimal } from '../../../../../helpers/payments';
import { cardTypes } from '../../../../../helpers/cards';
import { formPost } from '../../../../../helpers/formPost';

import RadioButton from "../../../../../copms/RadioButton/RadioButtonSolid";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CreditPaymentSchedule from '../../../../../copms/Modal/CreditPaymentSchedule';
import YesNoModal from '../../../../../copms/Modal/YesNoModal';
import CheckboxTick from '../../../../../copms/Checkbox/CheckboxTick';
import TransferModal from '../../../../../copms/Modal/TransferModal';
import SimpleModal from '../../../../../copms/Modal/SimpleModal';



class CreditPayment extends Component {
    state = {
        isLoading: true,
        dropdownVisibility: false,
        dropdownVisibilityCancellation: false,
        userInfo: null,
        cards: [],
        accounts: [],
        selectedCard: 0,
        selectedValue: -1,
        selectedIndex: -1,
        selectedShow: false,
        checkPayment: true,
        checkPaymentInvoice: [],
        selectedPaymentType: 0,
        selectedPaymentIndex: -1,
        checkPaymentAvans: [],
        allDisabled: false,
        step: 1,
        items: [],
        transactionId: null,
        succesModal: false,
        succesModalData: null,
        openYesNo: false,
        errorModal: false,
        errorModalData: null,
        avansIndex: -1,
        invoiceIndex: -1,
        defaultValue: null,
        credit: null,
        amount: null,
        moreThanInfo: false,
        // selectedPaymentTypeNew: 1,
        selectedNewPaymentDate: null,
        selectedPaymentSource: 1,
        openCancellation: false,
        openCreditPayment: false,
        dtAccountId: null,
        dtAccountIban: null,
        selectedAccount: null,
        dtAccontIdValidation: false,
        emptyAccount: false,
        selectedType: null,
        requestPaymentPlan: null,
        penaltyAmount: null,
        totalAmount: null,
        loanPaymentPlan: [],
        isPenaltyAmount: false,
        openTransferModal: false,
        feedbackErrorModal: null,
        payWithCardAndAccountRequestBody: null,
        paymentTypes: [],
    }

    componentWillMount() {
        this.setState({ isLoading: true });

        if (this.props.location.state?.succesModal) {
            this.setState({ succesModal: true, succesModalData: this.props.location.state.succesModalData, isLoading: false })
        }
        else if (this.props.location.state?.openTransferModal) {
            this.setState({ openTransferModal: true, feedbackErrorModal: null, isLoading: false });
        }
        else {
            this.showAccounts();
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            let getCategoryListCustomerRequestBody = customerType === 'Juridical' ? {
                custId: localStorage.getItem('custId'),
                compId: localStorage.getItem('compId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            } : {
                custId: localStorage.getItem('custId'),
                token: localStorage.getItem('token'),
                lang: localStorage.getItem('i18nextLng').toUpperCase()
            }

            // ---------------- Fetch üçün bundan istifadə edəcəksən ---------------
            fetch(request("card/getCardListFor" + customerType + "Customer", getCategoryListCustomerRequestBody))
                .then(res => res.text())
                .then((getCardListForCustomerResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                    if (getCardListForCustomerResponse.status.statusCode !== 1 && getCardListForCustomerResponse.status.statusCode !== 137) {
                        if (getCardListForCustomerResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                            this.setState({ isLoading: false });
                        }
                    }
                    else {
                        if (getCardListForCustomerResponse.status.statusCode === 137) {
                            getCardListForCustomerResponse.cardList = [];
                        }
                        let cardList = [];
                        if (localStorage.getItem('compId') === null && getCardListForCustomerResponse.otherCardList) {
                            cardList = [...getCardListForCustomerResponse.cardList, ...getCardListForCustomerResponse.otherCardList]
                        } else {
                            cardList = [...getCardListForCustomerResponse.cardList]
                        }

                        for (let i = 0; i < cardList.length; i++) {
                            // =========ID=========
                            let cardId = "";
                            if (typeof (cardList[i].cardId) === 'object' && getCardListForCustomerResponse.account[i].cardId.hasOwnProperty('c')) {
                                getCardListForCustomerResponse.account[i].id.c.forEach(part => {
                                    cardId += part;
                                });
                            }
                            else {
                                cardId += cardList[i].cardId;
                            }
                            cardList[i].cardId = cardId;
                            // =========NUMBER=========
                            cardList[i].organizedCardNumber = cardList[i].cardNumber.substring(0, 4)
                                + " **** **** " + cardList[i].cardNumber.substring(12, 16);

                        }

                        this.setState({
                            cards: cardList,
                            // , selectedValue: getCardListForCustomerResponse.cardList && getCardListForCustomerResponse.cardList.length > 0 && 0
                        });
                        this.showCredit();
                        // this.props.location.state?.receiptData && this.setState({ succesModalData: this.props.location.state?.receiptData, succesModal: true })
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");
                    });
        }
    }

    showAccounts = () => {
        this.setState({ isLoading: true });
        let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
        let getNoCardAccountListForCustomerRequestBody = customerType === 'Juridical' ? {
            custId: localStorage.getItem('custId'),
            compId: localStorage.getItem('compId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            // isBalanceAccount: 1
        } : {
            custId: localStorage.getItem('custId'),
            token: localStorage.getItem('token'),
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            // isBalanceAccount: 1
        }

        fetch(request("account/getNoCardAccountListFor" + customerType + "Customer", getNoCardAccountListForCustomerRequestBody))
            .then(res => res.text())
            .then((getNoCardAccountListForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getNoCardAccountListForCustomerResponse = JSON.parse(getNoCardAccountListForCustomerResponseJSON);
                if (getNoCardAccountListForCustomerResponse.status.statusCode !== 1 && getNoCardAccountListForCustomerResponse.status.statusCode !== 126) {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getNoCardAccountListForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    if (getNoCardAccountListForCustomerResponse.status.statusCode === 126) {
                        getNoCardAccountListForCustomerResponse.accountList = [];
                        this.setState({ emptyAccount: true });
                    }
                    getNoCardAccountListForCustomerResponse.accountList = getNoCardAccountListForCustomerResponse.accountList.filter(account => account.currName === "AZN");
                    for (let i = 0; i < getNoCardAccountListForCustomerResponse.accountList.length; i++) {
                        // // =========ID=========
                        let accountId = "";
                        if (typeof (getNoCardAccountListForCustomerResponse.accountList[i].accountId) === 'object' && getNoCardAccountListForCustomerResponse.account[i].accountId.hasOwnProperty('c')) {
                            getNoCardAccountListForCustomerResponse.account[i].id.c.forEach(part => {
                                accountId += part;
                            });
                        }
                        else {
                            accountId += getNoCardAccountListForCustomerResponse.accountList[i].accountId;
                        }
                        getNoCardAccountListForCustomerResponse.accountList[i].accountId = accountId;
                        // =========BALANCE=========
                        if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString() === "0") {
                            getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                wholePart: "0",
                                fractionalPart: "00"
                            }
                        }
                        else {
                            if (getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().indexOf('.') !== -1) {
                                let currentBalanceArray = getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString().split('.');
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: currentBalanceArray[0],
                                    fractionalPart: (currentBalanceArray[1].length === 1) ? (currentBalanceArray[1] + '0') : currentBalanceArray[1]
                                }
                            }
                            else {
                                getNoCardAccountListForCustomerResponse.accountList[i].organizedCurrentBalance = {
                                    wholePart: getNoCardAccountListForCustomerResponse.accountList[i].currentBalance.toString(),
                                    fractionalPart: "00"
                                }
                            }
                        }
                        // =========CURRENCY=========
                        // getNoCardAccountListForCustomerResponse.accountList[i].currencyIcon = currencyFilter(getNoCardAccountListForCustomerResponse.accountList[i].currName);
                    }
                    this.setState({ accounts: customerType === 'Juridical' ? getNoCardAccountListForCustomerResponse.accountList : getNoCardAccountListForCustomerResponse.accountList?.filter(item => item?.iban != this.props.match.params?.iban) });

                    // this.setState({ isLoading: false });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");
                });
    }

    formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            return `${day}.${month}.${year}`;
        }
        else {
            return null
        }
    }

    showCredit = () => {
        let getLoanByIdForCustomerRequestBody = {
            token: localStorage.getItem('token'),
            loanId: this.props.match.params.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase()
        }
        fetch(request("loan/getCustLoanPaymentInfo", getLoanByIdForCustomerRequestBody))
            .then(res => res.text())
            .then((getLoanByIdForCustomerResponseJSON) => {
                const JSON = require('true-json-bigint');
                let getLoanByIdForCustomerResponse = JSON.parse(getLoanByIdForCustomerResponseJSON);
                if (getLoanByIdForCustomerResponse.status.statusCode !== 1) {
                    if (getLoanByIdForCustomerResponse.status.statusCode === 200) {
                        this.props.logout();
                    } else {
                        this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getLoanByIdForCustomerResponse.status.statusCode));
                    }
                }
                else {
                    this.setState({ isLoading: false });
                    this.setState({ credit: getLoanByIdForCustomerResponse.loan, paymentTypes: getLoanByIdForCustomerResponse.loan?.paymentTypes, defaultValue: getLoanByIdForCustomerResponse.payForMonth, amount: getLoanByIdForCustomerResponse.payForMonth });
                    getLoanByIdForCustomerResponse.loan?.paymentTypes && this.setState({ selectedType: getLoanByIdForCustomerResponse.loan?.paymentTypes[0], penaltyAmount: getLoanByIdForCustomerResponse.loan?.paymentTypes[0]?.penaltyAmount, defaultValue: getLoanByIdForCustomerResponse.loan?.paymentTypes[0]?.paymentForAmount, amount: getLoanByIdForCustomerResponse.loan?.paymentTypes[0]?.paymentForAmount });
                }
            },
                (error) => {
                    this.props.history.push("/technical-break");

                });
    }

    changeDropdownVisibility = (event) => {
        event.preventDefault();
        if (this.state.step >= 2) {
            this.setState({ dropdownVisibility: false });
        } else {
            this.setState({ dropdownVisibility: !this.state.dropdownVisibility });
        }
    }

    changeDropdownVisibilityCancellation = (event) => {
        event.preventDefault();
        if (this.state.step >= 2) {
            this.setState({ dropdownVisibilityCancellation: false });
        } else {
            this.setState({ dropdownVisibilityCancellation: !this.state.dropdownVisibilityCancellation });
        }
    }

    onSelect = (event, val) => {
        event.preventDefault();
        this.setState({ dropdownVisibility: false, selectedValue: val });
    }

    onSelectNewPaymentDate = (event, date) => {
        event.preventDefault();
        this.state.paymentTypes.find((type) => this.state.selectedType?.id == type?.id).paymentForAmount = date?.amount
        this.setState({
            dropdownVisibilityCancellation: false,
            amount: date?.amount,
            defaultValue: date?.amount,
            isPenaltyAmount: false,
            selectedType: {
                ...this.state.selectedType,
                firstPaymentDate: date?.planDate,
                paymentPlanType: date?.paymentPlanType
            }
        });
    }

    // onChangeParamsDropdown = (e, index) => {
    //     e.preventDefault();
    //     var items = this.state.items;
    //     if (this.state.step > 1) {
    //         items[this.state.selectedIndex].elements[index].isShow = false;
    //     } else {
    //         items[this.state.selectedIndex].elements[index].isShow = !items[index].elements[index].isShow;
    //     }
    //     this.setState({ items: items })
    // }

    // onSelectParamsDropdown = (e, val, index) => {
    //     e.preventDefault();
    //     var items = this.state.items;
    //     items[this.state.selectedIndex].elements[index].defaultSelect = val;
    //     items[this.state.selectedIndex].elements[index].isShow = false;
    //     this.setState({ items: items })
    // }

    // onChnageInputDropdown = (val, index) => {
    //     var items = this.state.items;
    //     items[this.state.selectedIndex].elements[index].defaultInput = val;
    //     this.setState({ items: items })
    // }

    amountSplit(amount) {
        let splitedAmount = amount?.toString().split('.');
        if (splitedAmount?.length > 1) {
            return {
                part1: splitedAmount[0],
                part2: splitedAmount[1].length > 1 ? splitedAmount[1] : splitedAmount[1] + '0'
            }
        } else {
            return {
                part1: amount,
                part2: '00'
            }
        }
    }

    pay_process(e) {
        // e.preventDefault();
        this.props.updateAppState('isLoadingTransparentBackground', true);

        let payWithCardAndAccountRequestBody
        payWithCardAndAccountRequestBody = {
            token: localStorage.getItem('token'),
            loanId: this.state.credit?.id,
            amount: this.state.amount,
            paymentTypeId: this.state.selectedType?.id,
            lang: localStorage.getItem('i18nextLng').toUpperCase(),
            nextPaymentDate: this.state.selectedType?.firstPaymentDate
        }
        if (this.state.selectedPaymentSource == 1) {
            payWithCardAndAccountRequestBody.cardId = Number(this.state.cards[this.state.selectedValue]?.cardId);
            payWithCardAndAccountRequestBody.payType = 1
        }
        else if (this.state.selectedPaymentSource == 2) {
            payWithCardAndAccountRequestBody.dtAccountId = this.state.dtAccountId;
            payWithCardAndAccountRequestBody.crIban = this.props.match.params.iban;
            payWithCardAndAccountRequestBody.purpose = "Kreditödənişi";
        }
        if (this.state.selectedType?.visibleOtp) {
            this.props.history.push(`${this.props.match.params.iban}/otp`, { selectedPaymentSource: this.state.selectedPaymentSource, requestBody: payWithCardAndAccountRequestBody, paymentTypes: this.state.paymentTypes, payWith: 1 })
        }
        else {
            let customerType = localStorage.getItem('compId') === null ? 'Individual' : 'Juridical';
            fetch(request((this.state.selectedPaymentSource == 1 ? "pay/pay" + customerType + 'v1' : "operation/payLoanAccount" + customerType), payWithCardAndAccountRequestBody))
                .then(res => res.text())
                .then((getCardListForCustomerResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getCardListForCustomerResponse = JSON.parse(getCardListForCustomerResponseJSON);
                    if (getCardListForCustomerResponse.status.statusCode !== 1) {
                        if (getCardListForCustomerResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getCardListForCustomerResponse.status.statusCode));
                            this.props.updateAppState('isLoadingTransparentBackground', false);
                        }
                    }
                    else {
                        if (this.state.selectedPaymentSource == 2 && customerType === "Juridical") {
                            this.setState({ openTransferModal: true, feedbackErrorModal: null });
                        }
                        else {
                            this.setState({
                                succesModal: true, succesModalData: {
                                    fullName: getCardListForCustomerResponse.fullName,
                                    contractNumber: getCardListForCustomerResponse.contractNumber || getCardListForCustomerResponse.operationNo,
                                    description: getCardListForCustomerResponse?.description,
                                    purpose: getCardListForCustomerResponse?.purpose,
                                    // trDate: getCardListForCustomerResponse.trDate,
                                    cardnumber: this.state.cards[this.state.selectedValue]?.organizedCardNumber,
                                    dtIban: getCardListForCustomerResponse?.dtIban,
                                    commission: getCardListForCustomerResponse.commission,
                                    amount: getCardListForCustomerResponse.amount,
                                    currency: getCardListForCustomerResponse.currency,
                                    credit: getCardListForCustomerResponse.merchantName,
                                    method: this.state.paymentTypes.find((item) => item.id == payWithCardAndAccountRequestBody.paymentTypeId).name,
                                    nextPaymentDate: this.formatDate(payWithCardAndAccountRequestBody.nextPaymentDate)
                                }
                            })
                        }
                        this.props.updateAppState('isLoadingTransparentBackground', false);
                    }
                });
        }

        // let dataList = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
    }

    // paramsList() {
    //     var item = this.state.items[this.state.selectedIndex];
    //     var params = [];
    //     for (var i = 0; i < item.elements.length; i++) {
    //         if (item.elements[i].defaultSelect) {
    //             params.push({
    //                 paramName: item.elements[i].paramName,
    //                 paramValue: item.elements[i].defaultSelect + "" + item.elements[i].defaultInput,
    //                 paramLabel: item.elements[i].merchantDescValue,
    //             })
    //         } else {
    //             params.push({
    //                 paramName: item.elements[i].paramName,
    //                 paramValue: item.elements[i].defaultInput,
    //                 paramLabel: item.elements[i].merchantDescValue,
    //             })
    //         }
    //     }
    //     return params;
    // }

    // getSuccessData(receiptNumber, billingDate, amount, feeAmout) {
    //     var checkData = this.state.selectedPaymentType === 1 ? this.state.checkPaymentAvans[this.state.selectedPaymentIndex] : this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
    //     return {
    //         receiptNumber: receiptNumber,
    //         providerName: this.state.items[0].elements[0].merchantDisplayName,
    //         abonentName: checkData.fullName,//electedPaymentType === 1 ? checkPaymentAvans[selectedPaymentIndex].fullName : checkPaymentInvoice[selectedPaymentIndex].fullName,
    //         operationDate: billingDate,
    //         cardnumber: this.state.cards[this.state.selectedValue].organizedCardNumber,
    //         category: 'Telefon',
    //         amount: this.amountSplit(amount.toString()),
    //         commission: this.amountSplit(feeAmout.toString())
    //     }
    // }

    // commissionCalculator() {
    //     if (this.state.selectedPaymentType === 1) {
    //         var avans = this.state.checkPaymentAvans[this.state.selectedPaymentIndex];
    //         if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 < parseFloat(avans.feeMinAmount)) {
    //             return roundNumberWithDecimal(parseFloat(avans.feeMinAmount), 2);
    //         } else if (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100 > parseFloat(avans.feeMaxAmount)) {
    //             return roundNumberWithDecimal(parseFloat(avans.feeMaxAmount), 2);
    //         } else {
    //             let total = (parseFloat(avans.amount) * parseFloat(avans.feePercent) / 100);
    //             return roundNumberWithDecimal(total, 2);
    //         }
    //     } else {
    //         var invoice = this.state.checkPaymentInvoice[this.state.selectedPaymentIndex];
    //         if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 < parseFloat(invoice.feeMinAmount)) {
    //             return roundNumberWithDecimal(parseFloat(invoice.feeMinAmount), 2);
    //         } else if (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100 > parseFloat(invoice.feeMaxAmount)) {
    //             return roundNumberWithDecimal(parseFloat(invoice.feeMaxAmount), 2);
    //         } else {
    //             let total = (parseFloat(invoice.amount) * parseFloat(invoice.feePercent) / 100);
    //             return roundNumberWithDecimal(total, 2);
    //         }
    //     }
    // }

    handleInputChange = (e) => {
        const inputValue = e.target.value;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(inputValue)) {
            this.setState({ amount: inputValue })
        }
        else if (inputValue == "") {
            this.setState({ amount: null })
        }
        this.setState({ isPenaltyAmount: false })
    };

    getPaymentPlan = (e, getTempScheduleForPay, boolean) => {
        e.preventDefault();
        if (e.target.checked) { getTempScheduleForPay = true }
        // this.setState({isPenaltyAmount: !this.state.isPenaltyAmount});
        if (e.target.checked || getTempScheduleForPay || boolean) {
            this.props.updateAppState('isLoadingTransparentBackground', true)
            let getLoanTempPaymentPlan = {
                token: localStorage.getItem('token'),
                loanId: this.props.match.params.id,
                lang: localStorage.getItem('i18nextLng').toUpperCase(),
                paymentAmount: this.state.amount,
                paymentPlanType: this.state.selectedType.paymentPlanType,
                firstPaymentDate: this.state.selectedType.firstPaymentDate,
                paymentTypeId: this.state.selectedType?.id,
            }
            fetch(request("loan/loanTempPaymentPlan", getLoanTempPaymentPlan))
                .then(res => res.text())
                .then((getLoanByIdForCustomerResponseJSON) => {
                    const JSON = require('true-json-bigint');
                    let getLoanByIdForCustomerResponse = JSON.parse(getLoanByIdForCustomerResponseJSON);
                    if (getLoanByIdForCustomerResponse.status.statusCode !== 1) {
                        if (getLoanByIdForCustomerResponse.status.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.props.updateAppState('isLoadingTransparentBackground', false)
                            this.props.alertState(true, 'error', this.props.t('an error occurred'), this.props.t(getLoanByIdForCustomerResponse.status.statusCode));
                        }
                    }
                    else {
                        this.setState({
                            isPenaltyAmount: true, penaltyAmount: getLoanByIdForCustomerResponse.penaltyAmount, totalAmount: getLoanByIdForCustomerResponse.totalAmount, loanPaymentPlan: getLoanByIdForCustomerResponse.loanPaymentPlan
                        }, () => {
                            if (getTempScheduleForPay) {
                                this.setState({
                                    openCreditPayment: true
                                })
                            }
                        });
                        this.props.updateAppState('isLoadingTransparentBackground', false)
                    }
                },
                    (error) => {
                        this.props.history.push("/technical-break");

                    });
        }
        else {
            this.setState({ isPenaltyAmount: false })
        }
    }

    updateAccount = (account, event) => {
        this.setState({
            dtAccountId: account.accountId,
            dtAccountIban: account.iban,
            selectedAccount: event.target.textContent,
            dtAccontIdValidation: true,
            dropdownVisibility: false
        });
    }

    payWithOtherBankCards(e) {
        // e.preventDefault();
        this.props.updateAppState("isLoadingTransparentBackground", true);

        let requestBody = {
            custId: localStorage.getItem("custId"),
            token: localStorage.getItem("token"),
            payType: 1,
            loanId: this.state.credit.id,
            amount: this.state.amount.toString().slice(-1) === "0" ? setNumberToDecimal(this.state.amount, 1) : this.state.amount,
            cardId: Number(this.state.cards[this.state.selectedValue].cardId),
            lang: localStorage.getItem("i18nextLng").toUpperCase(),
            paymentTypeId: this.state.selectedType?.id,
            nextPaymentDate: this.state.selectedType?.firstPaymentDate,
            verifyToken: this.state.verifyToken || null,
            confirmCode: this.state.confirmCode || null
        };
        if (this.state.selectedType?.visibleOtp) {
            this.props.history.push(`${this.props.match.params.iban}/otp`, { selectedPaymentSource: this.state.selectedPaymentSource, requestBody: requestBody, paymentTypes: this.state.paymentTypes, payWith: 2 })
        }
        else {
            fetch(request("pay/payCardStorageIndividualv1", requestBody))
                .then((res) => res.text())
                .then((responseJSON) => {
                    const JSON = require("true-json-bigint");
                    let response = JSON.parse(responseJSON);
                    if (response?.status?.statusCode != 1) {
                        if (response?.status?.statusCode === 200) {
                            this.props.logout();
                        } else {
                            this.setState({ isLoading: false });
                            this.props.alertState(
                                true,
                                "error",
                                this.props.t("an error occurred"),
                                this.props.t(response?.status?.statusCode)
                            );
                        }
                    } else {
                        this.props.updateAppState(
                            "isLoadingTransparentBackground",
                            false
                        );
                        formPost(response?.cardStorage?.tokenUrl, {
                            AMOUNT: response?.cardStorage?.amount,
                            CURRENCY: response?.cardStorage?.currency,
                            ORDER: response?.cardStorage?.order,
                            DESC: response?.cardStorage?.desc,
                            TRTYPE: response?.cardStorage?.trtype,
                            TIMESTAMP: response?.cardStorage?.timeStamp,
                            NONCE: response?.cardStorage?.nonce,
                            BACKREF: response?.cardStorage?.backref,
                            P_SIGN: response?.cardStorage?.psign,
                            MERCH_NAME: response?.cardStorage?.merchName,
                            MERCH_URL: response?.cardStorage?.merchUrl,
                            TERMINAL: response?.cardStorage?.terminal,
                            EMAIL: response?.cardStorage?.email,
                            COUNTRY: response?.cardStorage?.country,
                            MERCH_GMT: response?.cardStorage?.merchGmt,
                            TOKEN: response?.cardStorage?.token,
                            MERCHANT: response?.cardStorage?.merchName,
                            LANG: localStorage.getItem('i18nextLng').toUpperCase()
                        });
                    }
                });
        }
    }

    render() {
        let { t, history } = this.props;
        let { isLoading, amount, defaultValue, dropdownVisibility, openYesNo, dropdownVisibilityCancellation, openCreditPayment, emptyAccount, accounts, selectedAccount, paymentTypes, selectedType, loanPaymentPlan, requestPaymentPlan, penaltyAmount, isPenaltyAmount, totalAmount, openCancellation, userInfo, cards, moreThanInfo, selectedValue, selectedPaymentSource, selectedPaymentTypeNew, selectedNewPaymentDate, items, succesModal, credit, succesModalData, errorModal, errorModalData, selectedIndex, selectedShow, checkPayment, checkPaymentAvans, checkPaymentInvoice, invoiceIndex, avansIndex, selectedPaymentType, selectedPaymentIndex, step, allDisabled,
            feedbackErrorModal, openTransferModal } = this.state;
        let custObject = JSON.parse(localStorage.getItem('custObject'));
        return (
            <>
                {
                    isLoading ?
                        <div className="dashboard-container-main-section">
                            <Loading />
                        </div>
                        :
                        <>
                            <div className="dashboard-header-route">
                                <div className="dashboard-header-route-left">
                                    <Link to="/dashboard/finance/credits"
                                    //  onClick={() => this.props.history.goBack()}
                                    >
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.7344 21.2188L12.6719 20.3281C12.9062 20.0938 12.9062 19.7188 12.6719 19.5312L5.40625 12.2188H21.4375C21.7188 12.2188 22 11.9844 22 11.6562V10.3438C22 10.0625 21.7188 9.78125 21.4375 9.78125H5.40625L12.6719 2.51562C12.9062 2.32812 12.9062 1.95312 12.6719 1.71875L11.7344 0.828125C11.5469 0.59375 11.1719 0.59375 10.9375 0.828125L1.14062 10.625C0.90625 10.8594 0.90625 11.1875 1.14062 11.4219L10.9375 21.2188C11.1719 21.4531 11.5469 21.4531 11.7344 21.2188Z" fill="#2E3131" />
                                        </svg>
                                    </Link>
                                    <h2>{t('Loan payment')}</h2>
                                </div>
                            </div>
                            <div className="dashboard-payment-container">
                                <PaymentModalSuccesPartial data={succesModalData}
                                    open={succesModal}
                                    triggerFunc
                                />
                                <TransferModal updateOpenModal={() => this.setState({ openTransferModal: false })} open={openTransferModal} successClose={() => { history.push('/dashboard/transfer/transfers/first-authorization') }} errorClose={() => this.setState({ openTransferModal: false })} feedbackErrorModal={feedbackErrorModal} history={history} />
                                <SimpleModal open={moreThanInfo} close={() => { this.setState({ step: 2, moreThanInfo: false }) }} title={t("Attention")} body={t("The portion paid in excess of the standard repayment will be deposited into your checking account.")} />
                                <PaymentModalError data={errorModalData} open={errorModal} />
                                <CreditPaymentSchedule {...this.props} open={openCreditPayment} loanPaymentPlan={loanPaymentPlan} onClose={() => this.setState({ openCreditPayment: false })} />
                                <YesNoModal
                                    title={`${t("The amount of compensation to be applied for overpayment")} ${penaltyAmount || 0} ${credit?.currency}. ${t("The total amount to be paid is")} ${totalAmount || 0} ${credit?.currency}.`}
                                    ok={t('agree')}
                                    notOk={t('reject')}
                                    open={openYesNo}
                                    triggerFunc={(e) => cards[selectedValue]?.cardType === cardTypes.CARD_STORAGE ? this.payWithOtherBankCards(e) : this.pay_process(e)}
                                    onClose={() => this.setState({ openYesNo: false })}
                                // body={`${translate("Service fee for the operation")} ${cardData?.pinChangeAmount
                                //     } AZN`}
                                // open={YesNoModal}
                                // triggerFunc={() => updateCardPINHandler()}
                                />

                                <div className="dashboard-payment-container-data-section">
                                    <div className="dashboard-payment-container-data-section-header">
                                        <h3 className="dashboard-payment-container-data-section-header-title">{t('Loan payment')}</h3>
                                    </div>
                                    <div className="dashboard-payment-container-data-section-content">
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">{t('Payment type')}</h3>
                                            <div className="dashboard-payment-container-data-section-content-container-form dashboard-payment-container-data-section-content-container-form__border-bottom">
                                                {/* <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                <svg className="dashboard-payment-container-data-section-content-container-form-group-icon" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.21429 7.86109H2.78571C2.65179 7.86109 2.57143 7.97043 2.57143 8.07977V8.95453C2.57143 9.09121 2.65179 9.17322 2.78571 9.17322H9.21429C9.32143 9.17322 9.42857 9.09121 9.42857 8.95453V8.07977C9.42857 7.97043 9.32143 7.86109 9.21429 7.86109ZM10.7143 0.152314L9.5625 0.999732L8.51786 0.261658C8.0625 -0.0663749 7.47321 -0.0663749 7.01786 0.261658L6 0.999732L4.95536 0.261658C4.76786 0.124978 4.41964 0.0156333 4.20536 0.0156333C3.96429 0.0156333 3.64286 0.124978 3.45536 0.261658L2.41071 0.999732L1.25893 0.152314C0.723214 -0.230391 0 0.152314 0 0.808379V13.1916C0 13.8203 0.723214 14.2304 1.25893 13.8477L2.41071 12.9729L3.45536 13.7383C3.91071 14.0664 4.5 14.0664 4.95536 13.7383L6 12.9729L7.01786 13.7383C7.47321 14.0664 8.0625 14.0664 8.51786 13.7383L9.5625 12.9729L10.7143 13.8477C11.25 14.2304 12 13.8477 12 13.1916V0.808379C12 0.152314 11.25 -0.230391 10.7143 0.152314ZM10.7143 12.2075L9.5625 11.3601L7.76786 12.6722L5.97321 11.3601L4.20536 12.6722L2.41071 11.3601L1.28571 12.2075V1.79248L2.41071 2.6399L4.20536 1.32776L6 2.6399L7.76786 1.32776L9.5625 2.6399L10.7143 1.79248V12.2075ZM9.21429 4.79945H2.78571C2.65179 4.79945 2.57143 4.90879 2.57143 5.01813V5.89289C2.57143 6.02957 2.65179 6.11158 2.78571 6.11158H9.21429C9.32143 6.11158 9.42857 6.02957 9.42857 5.89289V5.01813C9.42857 4.90879 9.32143 4.79945 9.21429 4.79945Z" fill="#BDC3C7" />
                                                </svg>

                                                <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Amount to be paid for the current month')}</p>
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount" style={{ margin: '0px', fontSize: 0, textAlign: 'left' }}>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn" style={{ fontSize: '24px' }}>{currencyFilter('AZN')}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole" style={{ fontSize: '22px' }}>{credit && this.amountSplit(credit.payForMonth ? credit.payForMonth.toString() : '0').part1}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional" style={{ fontSize: '18px' }}>{credit && this.amountSplit(credit.payForMonth ? credit.payForMonth.toString() : '0').part2}</span>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="dashboard-payment-container-data-section-content-container-form-group">
                                                <svg className="dashboard-payment-container-data-section-content-container-form-group-icon" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.21429 7.86109H2.78571C2.65179 7.86109 2.57143 7.97043 2.57143 8.07977V8.95453C2.57143 9.09121 2.65179 9.17322 2.78571 9.17322H9.21429C9.32143 9.17322 9.42857 9.09121 9.42857 8.95453V8.07977C9.42857 7.97043 9.32143 7.86109 9.21429 7.86109ZM10.7143 0.152314L9.5625 0.999732L8.51786 0.261658C8.0625 -0.0663749 7.47321 -0.0663749 7.01786 0.261658L6 0.999732L4.95536 0.261658C4.76786 0.124978 4.41964 0.0156333 4.20536 0.0156333C3.96429 0.0156333 3.64286 0.124978 3.45536 0.261658L2.41071 0.999732L1.25893 0.152314C0.723214 -0.230391 0 0.152314 0 0.808379V13.1916C0 13.8203 0.723214 14.2304 1.25893 13.8477L2.41071 12.9729L3.45536 13.7383C3.91071 14.0664 4.5 14.0664 4.95536 13.7383L6 12.9729L7.01786 13.7383C7.47321 14.0664 8.0625 14.0664 8.51786 13.7383L9.5625 12.9729L10.7143 13.8477C11.25 14.2304 12 13.8477 12 13.1916V0.808379C12 0.152314 11.25 -0.230391 10.7143 0.152314ZM10.7143 12.2075L9.5625 11.3601L7.76786 12.6722L5.97321 11.3601L4.20536 12.6722L2.41071 11.3601L1.28571 12.2075V1.79248L2.41071 2.6399L4.20536 1.32776L6 2.6399L7.76786 1.32776L9.5625 2.6399L10.7143 1.79248V12.2075ZM9.21429 4.79945H2.78571C2.65179 4.79945 2.57143 4.90879 2.57143 5.01813V5.89289C2.57143 6.02957 2.65179 6.11158 2.78571 6.11158H9.21429C9.32143 6.11158 9.42857 6.02957 9.42857 5.89289V5.01813C9.42857 4.90879 9.32143 4.79945 9.21429 4.79945Z" fill="#BDC3C7" />
                                                </svg>
                                                <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('total debt')}</p>
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount" style={{ margin: '0px', fontSize: 0, textAlign: 'left' }}>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn" style={{ fontSize: '24px' }}>{currencyFilter('AZN')} </span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole" style={{ fontSize: '22px' }}>{credit && this.amountSplit(credit.restAmount ? credit.restAmount.toString() : '0').part1}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional" style={{ fontSize: '18px' }}>{credit && this.amountSplit(credit.restAmount ? credit.restAmount.toString() : '0').part2}</span>
                                                    </h3>
                                                </div>
                                            </div> */}
                                                {paymentTypes && paymentTypes.map((type) => (
                                                    type?.paymentForAmount && (
                                                        <div className="dashboard-payment-container-data-section-content-container-form-group mr-5">
                                                            <RadioButton
                                                                value={type?.id}
                                                                selectedId={selectedType?.id}
                                                                disabled={Boolean(step > 1)}
                                                                className={`dashboard-payment-container-data-section-content-container-form-group-radio ${step > 1 && "dashboard-payment-container-data-section-content-container-form-group-radio__disable"}`}
                                                                onChange={(e) => {
                                                                    this.setState(
                                                                        {
                                                                            selectedType: type,
                                                                            amount: type?.paymentForAmount,
                                                                            defaultValue: type?.paymentForAmount,
                                                                            isPenaltyAmount: false,
                                                                            penaltyAmount: type?.penaltyAmount
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                            <div className="dashboard-payment-container-data-section-content-container-form-group-box">
                                                                <div className='d-flex align-items-center'>
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-group-icon" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M9.21429 7.86109H2.78571C2.65179 7.86109 2.57143 7.97043 2.57143 8.07977V8.95453C2.57143 9.09121 2.65179 9.17322 2.78571 9.17322H9.21429C9.32143 9.17322 9.42857 9.09121 9.42857 8.95453V8.07977C9.42857 7.97043 9.32143 7.86109 9.21429 7.86109ZM10.7143 0.152314L9.5625 0.999732L8.51786 0.261658C8.0625 -0.0663749 7.47321 -0.0663749 7.01786 0.261658L6 0.999732L4.95536 0.261658C4.76786 0.124978 4.41964 0.0156333 4.20536 0.0156333C3.96429 0.0156333 3.64286 0.124978 3.45536 0.261658L2.41071 0.999732L1.25893 0.152314C0.723214 -0.230391 0 0.152314 0 0.808379V13.1916C0 13.8203 0.723214 14.2304 1.25893 13.8477L2.41071 12.9729L3.45536 13.7383C3.91071 14.0664 4.5 14.0664 4.95536 13.7383L6 12.9729L7.01786 13.7383C7.47321 14.0664 8.0625 14.0664 8.51786 13.7383L9.5625 12.9729L10.7143 13.8477C11.25 14.2304 12 13.8477 12 13.1916V0.808379C12 0.152314 11.25 -0.230391 10.7143 0.152314ZM10.7143 12.2075L9.5625 11.3601L7.76786 12.6722L5.97321 11.3601L4.20536 12.6722L2.41071 11.3601L1.28571 12.2075V1.79248L2.41071 2.6399L4.20536 1.32776L6 2.6399L7.76786 1.32776L9.5625 2.6399L10.7143 1.79248V12.2075ZM9.21429 4.79945H2.78571C2.65179 4.79945 2.57143 4.90879 2.57143 5.01813V5.89289C2.57143 6.02957 2.65179 6.11158 2.78571 6.11158H9.21429C9.32143 6.11158 9.42857 6.02957 9.42857 5.89289V5.01813C9.42857 4.90879 9.32143 4.79945 9.21429 4.79945Z" fill="#BDC3C7" />
                                                                    </svg>
                                                                    <p className="dashboard-payment-container-data-section-content-container-form-group-title">{type?.name}</p>
                                                                    <ToolTip name={type?.info} />
                                                                </div>

                                                                <h3 className="dashboard-payment-container-confirmation-section-amount" style={{ margin: '0px', fontSize: 0, textAlign: 'left' }}>
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-whole" style={{ fontSize: '22px' }}>{this.amountSplit(type?.paymentForAmount.toString() || '0').part1}</span>
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-fractional" style={{ fontSize: '18px' }}>{this.amountSplit(type?.paymentForAmount.toString() || '0').part2} </span>
                                                                    <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn__new">{currencyFilter('azn')}</span>
                                                                </h3>
                                                            </div>
                                                        </div>)
                                                ))}
                                            </div>
                                        </div>
                                        <div className="dashboard-payment-container-data-section-content-container">
                                            <h3 className="dashboard-payment-container-data-section-content-container-title">
                                                {t('Repay')}
                                            </h3>
                                            <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a payment source')}</label>
                                            <div className="dashboard-payment-container-data-section-content-container-form-radioBtns">
                                                <div className="dashboard-payment-container-data-section-content-container-form-radioBtn">
                                                    <RadioButton
                                                        value={1}
                                                        selectedId={selectedPaymentSource}
                                                        disabled={Boolean(step > 1)}
                                                        className={step > 1 && "dashboard-payment-container-data-section-content-container-form-group-radio__disable"}
                                                        onChange={(e) => {
                                                            this.setState(
                                                                {
                                                                    selectedPaymentSource: e.target.value,
                                                                    selectedAccount: null
                                                                }
                                                            );
                                                        }}
                                                    />
                                                    <span className="dashboard-payment-container-data-section-content-container-form-radioBtn-title">
                                                        {t("Card")}
                                                    </span>
                                                </div>
                                                <div className="dashboard-payment-container-data-section-content-container-form-radioBtn">
                                                    <RadioButton
                                                        value={2}
                                                        selectedId={selectedPaymentSource}
                                                        disabled={Boolean(step > 1)}
                                                        className={step > 1 && "dashboard-payment-container-data-section-content-container-form-group-radio__disable"}
                                                        onChange={(e) => {
                                                            this.setState(
                                                                {
                                                                    selectedPaymentSource: e.target.value,
                                                                    selectedValue: -1
                                                                }
                                                            );
                                                        }}
                                                    />
                                                    <span className="dashboard-payment-container-data-section-content-container-form-radioBtn-title">
                                                        {t("Account")}
                                                    </span>
                                                </div>
                                            </div>
                                            <form className="dashboard-payment-container-data-section-content-container-form">
                                                <div className="dashboard-payment-container-data-section-content-container-form-container flex-column">
                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select funds')}</label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                            <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={this.changeDropdownVisibility}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">
                                                                    {selectedPaymentSource == 1 && cards && selectedValue >= 0 && cards.length > 0 && cards[selectedValue].organizedCardNumber + ' / ' + (cards[selectedValue].cardType === cardTypes.CARD_STORAGE ? cards[selectedValue].holder : cards[selectedValue].balance + ' ' + cards[selectedValue].currency)}
                                                                    {selectedPaymentSource == 2 && emptyAccount === true ? t(126) : selectedAccount}
                                                                </div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(dropdownVisibility === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {selectedPaymentSource == 1 && (cards && cards.length > 0 &&
                                                                    cards.map((card, index) => {
                                                                        return (
                                                                            card.correspondentAccount !== credit?.iban ?
                                                                                <li key={index} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                    <button onClick={e => this.onSelect(e, index)} disabled={!Boolean(card.cardStatus)} className={!Boolean(card.cardStatus) ? "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"}>{card.organizedCardNumber} / {card.cardType === cardTypes.CARD_STORAGE ? card.holder : `${card.balance} ${card.currency}`}</button>
                                                                                </li>
                                                                                :
                                                                                null
                                                                        )
                                                                    }))}
                                                                {selectedPaymentSource == 2 && accounts.map(account => (
                                                                    <li className="dashboard-payment-container-data-section-content-container-form-select-box-list-item" key={account.accountId}>
                                                                        <button type="button" className="dashboard-payment-container-data-section-content-container-form-select-box-list-item-button" onClick={this.updateAccount.bind(this, account)}>{account.iban} / {account.organizedCurrentBalance.wholePart}.{account.organizedCurrentBalance.fractionalPart} {account.currName}</button>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>


                                                    {/* <div > */}

                                                    <div style={{ display: !selectedType?.visibleNextPayment && 'none' }} className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Select a repayment date')}</label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-select-box">
                                                            <button className={`dashboard-payment-container-data-section-content-container-form-select-box-button ${step > 1 ? "dashboard-payment-container-data-section-content-container-form-select-box-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-button"}`} onClick={this.changeDropdownVisibilityCancellation}>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-select">{this.formatDate(selectedType?.firstPaymentDate)}</div>
                                                                <div className="dashboard-payment-container-data-section-content-container-form-icon">
                                                                    <svg className="dashboard-payment-container-data-section-content-container-form-icon-img" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="chevron-down copy 2" d="M12.4336 1.34375L11.9141 0.796875C11.7773 0.660156 11.5586 0.660156 11.4492 0.796875L6.5 5.74609L1.52344 0.796875C1.41406 0.660156 1.19531 0.660156 1.05859 0.796875L0.539063 1.34375C0.402344 1.45312 0.402344 1.67188 0.539063 1.80859L6.25391 7.52344C6.39063 7.66016 6.58203 7.66016 6.71875 7.52344L12.4336 1.80859C12.5703 1.67187 12.5703 1.45312 12.4336 1.34375Z" fill="#849097" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <ul className={(dropdownVisibilityCancellation === false) ? "dashboard-payment-container-data-section-content-container-form-select-box-list dashboard-payment-container-data-section-content-container-form-select-box-list__hidden" : "dashboard-payment-container-data-section-content-container-form-select-box-list"}>
                                                                {
                                                                    selectedType?.paymentDates?.length > 0 &&
                                                                    selectedType?.paymentDates?.map((date, index) => {
                                                                        return (
                                                                            // form.correspondentAccount !== credit.iban ?
                                                                            // <li key={index}>
                                                                            //     <button
                                                                            //     // onClick={e => this.onSelect(e, index)} disabled={!Boolean(card.cardStatus)} 
                                                                            //     >{form.name}</button>
                                                                            // </li>
                                                                            <li key={index} className="dashboard-payment-container-data-section-content-container-form-select-box-list-item">
                                                                                <button onClick={e => this.onSelectNewPaymentDate(e, date)}
                                                                                    // disabled={!Boolean(form.cardStatus)}
                                                                                    className={false ? "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button__disable" : "dashboard-payment-container-data-section-content-container-form-select-box-list-item-button"}>{this.formatDate(date?.planDate)}</button>
                                                                            </li>
                                                                            // :
                                                                            // null
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="dashboard-payment-container-data-section-content-container-form-input-group dashboard-payment-container-data-section-content-container-form-input-group__grid">
                                                        <label className="dashboard-payment-container-data-section-content-container-form-label">{t('Amount')}</label>
                                                        <div className="dashboard-payment-container-data-section-content-container-form-input-box">
                                                            <input disabled={step > 1 || selectedType?.checkPaymentAmount == 1} type="number" value={amount} onChange={e => this.handleInputChange(e)
                                                                // this.setState({ amount: e.target.value })
                                                            } className={`dashboard-payment-container-data-section-content-container-form-input ${step > 1 || selectedType?.checkPaymentAmount == 1 ? 'dashboard-payment-container-data-section-content-container-form-input__disable' : (selectedType?.checkPaymentAmount == 2 && amount < defaultValue) && 'input-outline'}`} placeholder="0.00" />
                                                            <div className="dashboard-payment-container-data-section-content-container-form-icon dashboard-payment-container-data-section-content-container-form-icon__currency">{this.state.credit && this.state.credit.currency}</div>
                                                        </div>
                                                    </div>
                                                    {(selectedType?.checkPaymentAmount == 2 && amount < defaultValue) && <p className='mt-2' style={{ color: "#EF3E42", fontSize: '14px' }}>{t('The amount must not be less than specified minimum.')}</p>}

                                                    <div style={{
                                                        display: !selectedType?.visibleNextPayment ? 'none' : 'flex',
                                                        cursor: !selectedType?.firstPaymentDate && 'not-allowed',
                                                        opacity: !selectedType?.firstPaymentDate && '.6' // Change cursor style
                                                    }} className='align-items-center mt-4'>
                                                        <input type="checkbox" onChange={(e) => this.getPaymentPlan(e)} disabled={!selectedType?.firstPaymentDate || Boolean(step > 1)} value={isPenaltyAmount} checked={isPenaltyAmount} style={{ width: '20px', height: '20px' }} />
                                                        <button
                                                            disabled={!selectedType?.firstPaymentDate}
                                                            className='ml-2 credit-payment-modal-data-section-content-schedule' onClick={(e) => this.getPaymentPlan(e, true)}> <img src={schedule} alt="" />{t('See repayment plan')}
                                                        </button>
                                                    </div>
                                                </div>

                                                {
                                                    step === 1 &&
                                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                        <button
                                                            disabled={amount == null || (selectedType?.checkPaymentAmount == 2 && amount < defaultValue) || (selectedType?.visiblePaymentPlan && !selectedType?.firstPaymentDate) ||
                                                                (selectedPaymentSource == 1 ?
                                                                    selectedValue == -1 : selectedAccount == null) || (selectedType?.visiblePaymentPlan && !isPenaltyAmount)
                                                            }
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                selectedType?.visibleNextPayment && this.getPaymentPlan(e, false, true);
                                                                (!selectedType.visiblePaymentPlan && amount > selectedType.paymentForAmount) ? this.setState({ moreThanInfo: true }) : this.setState({ step: 2 })
                                                            }}
                                                            className={`${(amount == null || (selectedType?.checkPaymentAmount == 2 && amount < defaultValue) || (selectedType?.visiblePaymentPlan && !selectedType?.firstPaymentDate) || (selectedPaymentSource == 1 ? selectedValue == -1 : selectedAccount == null) || (selectedType?.visiblePaymentPlan && !isPenaltyAmount)) && "dashboard-payment-container-data-section-content-container-form-footer-button__disable"}  dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left`}>{t('continue')}</button>
                                                        {/* className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button> */}
                                                        <button onClick={(e) => this.setState({ step: 1 })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('reject')}</button>
                                                    </div>
                                                }

                                                {/* {
                                    step === 2 && ((checkPaymentAvans.length === 1 && checkPaymentInvoice.length === 0) || (checkPaymentInvoice.length === 1 && checkPaymentAvans.length === 0)) &&
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: checkPaymentAvans.length === 1 ? 1 : 2, selectedPaymentIndex: 0, step: 3, allDisabled: true }) }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                    </div>
                                }
                                {
                                    (invoiceIndex >= 0 || avansIndex >= 0) && step === 2 &&
                                    <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                        <button onClick={e => { e.preventDefault(); this.setState({ selectedPaymentType: avansIndex >= 0 ? 1 : 2, selectedPaymentIndex: avansIndex >= 0 ? avansIndex : invoiceIndex, step: 3, allDisabled: true }) }} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left">{t('continue')}</button>
                                        <button className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('cancel')}</button>
                                    </div>
                                } */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-payment-container-confirmation-section">
                                    <div className="dashboard-payment-container-confirmation-section-header">
                                        <h3 className="dashboard-payment-container-confirmation-section-header-title">{t('Payment confirmation')}</h3>
                                    </div>

                                    <div className="dashboard-payment-container-confirmation-section-content py-5 px-2">
                                        {
                                            step >= 2 ?
                                                <Fragment>
                                                    <h3 className="dashboard-payment-container-confirmation-section-amount">
                                                        <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn">{currencyFilter(credit.currency)}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-whole">{this.amountSplit(amount).part1}</span>
                                                        <span className="dashboard-payment-container-confirmation-section-amount-fractional">{this.amountSplit(amount).part2}</span>
                                                    </h3>
                                                    <ul className="dashboard-payment-container-confirmation-section-list">
                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Total amount owed')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                                                                <span style={{ fontSize: '14px' }}>{credit?.loanAmount}</span>
                                                                <span className="dashboard-payment-container-confirmation-section-amount-currency dashboard-payment-container-confirmation-section-amount-currency__azn__new" style={{ fontSize: '14px' }}>{currencyFilter('azn')}</span>
                                                            </p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('credit')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{credit.loanType}</p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Commission')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{(penaltyAmount ? penaltyAmount : '0.00') + ' ' + credit.currency}</p>
                                                        </li>

                                                        {/* <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('credit')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{credit.loanType}</p>
                                                        </li> */}

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('fund')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">
                                                                {selectedPaymentSource == 1 && (cards && selectedValue >= 0 && cards.length > 0 && cards[selectedValue].organizedCardNumber)}
                                                                {selectedPaymentSource == 2 && selectedAccount?.split(" / ")[0]}
                                                            </p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-title">{t('Repayment method')}</p>
                                                            <p className="dashboard-payment-container-data-section-content-container-form-group-data">{selectedType?.name}</p>
                                                        </li>

                                                        <li className="dashboard-payment-container-confirmation-section-list-item">
                                                            <div className="dashboard-payment-container-data-section-content-container-form-footer">
                                                                {
                                                                    (selectedPaymentSource == 1 ? cards && selectedValue >= 0 && cards?.length > 0 && cards[0]?.organizedCardNumber : selectedAccount) ?
                                                                        <button onClick={e => { isPenaltyAmount && penaltyAmount > 0 ? this.setState({ openYesNo: true }) : (cards[selectedValue]?.cardType === cardTypes.CARD_STORAGE ? this.payWithOtherBankCards(e) : this.pay_process(e)) }} className={"dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__blue dashboard-payment-container-data-section-content-container-form-footer-button__left"}>{t('make a payment')}</button>
                                                                        :
                                                                        null
                                                                }
                                                                <button onClick={(e) => this.setState({ step: 1 })} className="dashboard-payment-container-data-section-content-container-form-footer-button dashboard-payment-container-data-section-content-container-form-footer-button__gray dashboard-payment-container-data-section-content-container-form-footer-button__right">{t('refusal')}</button>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                </Fragment>
                                                :
                                                <div className="dashboard-payment-container-confirmation-section-empty">
                                                    <img src={empty} className="dashboard-payment-container-confirmation-section-content-image" alt="_image" />
                                                    <p className="dashboard-payment-container-confirmation-section-content-description">{t('To complete the payment, please enter the payment settings and continue.')}</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                }

            </>
        )
    }
}

export default translate("translation")(CreditPayment);
